import gql from "graphql-tag";

export const getCustomOdeContents = gql`
  query GetCustomOdeContent($where: CustomOdeContentWhereInput, $odeId: ID) {
    customOdeContents(where: $where) {
      id
      contentGroupsSort
      contentGroups {
        id
        title
        draft
        description
        contentsSort
        creationConfirmed
        contents {
          id
          type
          name
          draft
          materials {
            id
            url,
            type,
            uploadDate,
            fileName
          }
          description
          dueDate
          instructions
          placeType
          placeDirection
          creationConfirmed
          odeContents(
            where: {
              ode: { id: $odeId }
            }
          ) {
            id
            status
            finishDate
            deliverables {
              id
              fileName
              url
              type
              uploadDate
            }
            events {
              id
              event
              description
              createdAt
              blame {
                id
                fullName
                profilePicUrl
              }
            }
          }
        }
      }
    }
  }
`;
