import React, { useContext, useState } from "react";
import { Form } from "antd";
import { TeamFormContent } from "../../../../shared";
import { BatchContentsContext } from "../../../../../contexts/admin/batches";

export const MembersForm = () => {
  const [state, setState] = useState({
    leader: "",
    team: ""
  });

  const [form] = Form.useForm();

  const {
    data,
    loading,
    error,
    onSubmit,
    onDelete
  } = useContext(BatchContentsContext);

  const handleSubmit = async type => {
    await form.validateFields([type]);
    onSubmit && onSubmit(state[type], type);
    form.resetFields();
  };

  const handleChange = (value, type) => {
    setState({ ...state, [type]: value });
  };

  const batchContextValue = {
    data,
    loading,
    error,
    handleChange,
    handleSubmit,
    onDelete
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="NewBatch__MembersForm">
      <TeamFormContent
        batchContextValue={batchContextValue} />
    </Form>
  );
};
