import React from "react";
import { SidebarLayout } from "../../../../../components/shared";
import {
  BatchOfferContent
} from "../../../../../components/entrepreneur/batches/show/offer";
import { ServicesLayerProvider } from "../../../../../contexts/shared";
import {
  BatchesService,
  CompanyService,
  BatchMentorService,
  ExpertServiceService,
  AssignedExpertServiceService,
  ScheduledExpertServiceService,
  EntrepreneurSessionsServicesService,
  OdesService
} from "../../../../../services";

const BatchOfferIndex = () => {

  /*
    The services Layer is a POC of implementation intended  write
    testable components.
    Please don't try to do the same in other modules in case that
    we decide to do the previous pattern.
  */
  return (
    <ServicesLayerProvider services={{
      BatchesService,
      CompanyService,
      BatchMentorService,
      ExpertServiceService,
      AssignedExpertServiceService,
      ScheduledExpertServiceService,
      EntrepreneurSessionsServicesService,
      OdesService
    }}>
      <SidebarLayout>
        <BatchOfferContent defaultActiveTab="information" />
      </SidebarLayout>
    </ServicesLayerProvider>
  );
};

export default BatchOfferIndex;
