import gql from "graphql-tag";

export const getActiveScheduledMentorshipServicesByEntrepreneurId = gql`
  fragment assignedExpertServiceFragment on AssignedExpertService {
    id
    expertService {
      id
      expert {
        id
      }
    }
  }

  query GetActiveScheduledMentorshipServicesByEntrepreneurId(
    $id: ID!
    $expertId: ID!
    $serviceId: ID!
    $odeId: ID!
  ) {
    scheduledExpertServices(
      where: {
        status_in: [
          "ON_GOING",
          "DELIVERED",
          "SCHEDULED"
        ],
        requestedBy: {
          id: $id
        },
        assignedExpertService: {
          ode: { id: $odeId }
          expertService: {
            id: $serviceId
            type: "MENTORSHIP",
            expert: { id: $expertId }
          }
        }
      }
    ) {
      id
      assignedExpertService {
        ...assignedExpertServiceFragment
      }
    }
  }
`;
