import { useState } from "react";

export function useQueryFilters({
  defaultFilters = {},
  refetch
}) {
  const [state, setState] = useState({
    ...defaultFilters
  });

  /**
   * @deprecated Should use the applyFilters method instead
   * Updates the filter variables
   * @param {*} filters [{ key, value }] || { key, value }
   */
  const setFilters = (filters = {}) => {
    const updatedFilters = { ...state };

    if (Array.isArray(filters)) {
      filters.forEach(({ key, value }) => {
        updatedFilters[key] = value;
      });
    } else {
      const { key, value } = filters || {};
      updatedFilters[key] = value;
    }

    setState({ ...updatedFilters });
    refetch && refetch({ ...updatedFilters });
  };

/**
 * @callback FiltersUpdater
 * @param {object} prevFilters
 * @returns {object} updated filters
 */
  /**
   * @description Allows to update the filters and refetch query
   * @param {FiltersUpdater} filtersUpdater
   */
  const applyFilters = filtersUpdater => {
    const updatedFilters = filtersUpdater && filtersUpdater(state);
    setState(updatedFilters);
    refetch && refetch(updatedFilters);
  };

  return {
    filters: state,
    setFilters,
    applyFilters
  };
}
