/* eslint-disable max-lines */
import { client3 } from "../../../graphql";
import {
  importExperiencesCatalogMutation,
  importPersonExperienceAttendance,
  importPersonsEnrolledInSemester,
  importPersonSubjectEnrollment,
  importPersonSubjectScore
} from "./graphql";
import { getStudents } from "./graphql";
import {
  getPersonExperienceAttendances
} from "./get-person-experience-attendances";
import {
  getDashboardInvolvementLevels
} from "./get-dashboard-involvement-levels";
import { getSearchPerson } from "./get-search-person";
import { getPersons } from "./get-persons";
import {
  getPersonExperienceAttendanceByAttendanceId
} from "./get-person-experience-attendance-by-attendanceId";
import { getCampusExperiences } from "./get-campus-experiences";
import {
  getCampusInvolvementLevelsImpact
} from "./get-campus-involvement-levels-impact";
import { getImpactedCommunityTable } from "./get-impacted-community-table";

async function students (variables = {}) {
  const result = await client3.query({
    query: getStudents,
    fetchPolicy: "network-only",
    variables
  });

  return result?.data || [];
}

export const importEnrollments = async enrollmentInput => {
  const successfullyUpdated = [];
  const failedToUpdate = [];

  const result = await importPersonsEnrollments(enrollmentInput);

  if (result)
    successfullyUpdated.push(enrollmentInput);
  else
    failedToUpdate.push(enrollmentInput);

  return {
    successfullyUpdated,
    failedToUpdate
  };
};

const importPersonsEnrollments = async data => {
  try {
    await client3.mutate({
      mutation: importPersonsEnrolledInSemester,
      variables: {
        data
      }
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const importExperiences = async experiencesInput => {
  const successfullyUpdated = [];
  const failedToUpdate = [];

  const result = await importExperiencesCatalog(experiencesInput);

  if (result)
    successfullyUpdated.push(experiencesInput);
  else
    failedToUpdate.push(experiencesInput);

  return {
    successfullyUpdated,
    failedToUpdate
  };
};

const importExperiencesCatalog = async data => {
  try {
    await client3.mutate({
      mutation: importExperiencesCatalogMutation,
      variables: {
        data
      }
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const importedAttendances = async attendancesInput => {
  const successfullyUpdated = [];
  const failedToUpdate = [];

  const result = await importAttendances(attendancesInput);

  if (result)
    successfullyUpdated.push(attendancesInput);
  else
    failedToUpdate.push(attendancesInput);

  return {
    successfullyUpdated,
    failedToUpdate
  };
};

const importAttendances = async data => {
  try {
    await client3.mutate({
      mutation: importPersonExperienceAttendance,
      variables: {
        data
      }
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const importSubjects = async subjectsInput => {
  const successfullyUpdated = [];
  const failedToUpdate = [];

  const result = await importSubjectsEnrollments(subjectsInput);

  if (result)
    successfullyUpdated.push(subjectsInput);
  else
    failedToUpdate.push(subjectsInput);

  return {
    successfullyUpdated,
    failedToUpdate
  };
};

const importSubjectsEnrollments = async data => {
  try {
    await client3.mutate({
      mutation: importPersonSubjectEnrollment,
      variables: {
        data
      }
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const importSubjectsScores = async subjectsScoresInput => {
  const successfullyUpdated = [];
  const failedToUpdate = [];

  const result = await importedSubjectsScores(subjectsScoresInput);

  if (result)
    successfullyUpdated.push(subjectsScoresInput);
  else
    failedToUpdate.push(subjectsScoresInput);

  return {
    successfullyUpdated,
    failedToUpdate
  };
};

const importedSubjectsScores = async data => {
  try {
    await client3.mutate({
      mutation: importPersonSubjectScore,
      variables: {
        data
      }
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

export const EntrepreneurSpiritService = {
  students,
  getPersons,
  getSearchPerson,
  getDashboardInvolvementLevels,
  importSubjects,
  importEnrollments,
  importExperiences,
  importedAttendances,
  importSubjectsScores,
  getCampusExperiences,
  importedSubjectsScores,
  getImpactedCommunityTable,
  getPersonExperienceAttendances,
  getCampusInvolvementLevelsImpact,
  getPersonExperienceAttendanceByAttendanceId
};
