import React from "react";
import { Avatar } from "../../../avatar";
import { Space, Typography, Button } from "antd";
import { useHistory } from "react-router-dom";

export function BatchHeroContent({ data }) {
  const history = useHistory();
  return (
    <Space
      className="BatchHeroContent"
      direction="vertical"
      align="center">
      <Space size={20}>
        <Avatar
          src={data?.logoUrl}
          size={60} />
        <Typography.Title level={3}>
          {data?.name}
        </Typography.Title>
        <Button
          type="default"
          onClick={() =>
            history.push(`../../odes/${data?.id}/general-information`)}>
          Ver perfil
        </Button>
      </Space>
      <Space className="BatchHeroContent__Description">
        <Typography.Paragraph type="secondary" ellipsis={{ rows: 3 }}>
          {data?.description}
        </Typography.Paragraph>
      </Space>
    </Space>
  );
}

