import React from "react";
import { Col, Image, Row, Space, Typography } from "antd";
import { TagList } from "../tag-list";
import { COLORS_TAGS } from "../../../helpers";
import moment from "moment";
import { FlexContainer } from "../flex-container";

export function Hero({
  organization,
  isAsemAdmin = false
}) {
  const colorLabel =  organization?.status === "APPROVED" ? "success" : "danger";
  const labelStatus = organization?.status === "APPROVED"
    ? "Solicitud aprobada" : organization?.status === "REJECTED"
    ? "Solicitud rechazada" : "";

  const dateToShow = organization?.startedAt
    ? moment(organization?.startedAt).format("DD/MM/YYYY")
    : moment(organization?.createdAt).format("DD/MM/YYYY");

  return (
    <Row>
      <Col span={8}>
        <Image
          preview={false}
          width={200}
          alt={organization?.name}
          src={organization?.logo} />
      </Col>
      <Col span={16}>
        <FlexContainer
          style={{gap: ".5rem"}}
          direction="vertical">
          <Typography.Title
            className="OrganizationModal__title"
            level={3}>
            {organization?.name}
            {isAsemAdmin &&
              <Typography.Title
                type={colorLabel}
                level={5}>
                {labelStatus}
              </Typography.Title>
            }
          </Typography.Title>
          <Typography.Text
            className="OrganizationModal__primary"
            type="primary">
            {organization?.services.join("  •  ")}
          </Typography.Text>
          {
            organization?.ranByWomen &&
            <Typography.Paragraph style={{
              color: "#F23B88",
              marginBottom: 0
            }}>
              Cuenta con servicios o iniciativas dirigidas a mujeres
            </Typography.Paragraph>
          }
          <TagList
            expandTags
            className="tags"
            colors={COLORS_TAGS}
            mapColors={true}
            tags={organization?.stages} />
          <Typography.Paragraph type="secondary">
            {organization?.description}
          </Typography.Paragraph>
          <FlexContainer
            direction="horizontal"
            justifyContent="space-between">
            <Typography.Paragraph type="secondary">
              {`Fecha de fundación: ${dateToShow}`}
            </Typography.Paragraph>
            <Typography.Paragraph
              style={{alignSelf: "flex-end"}}
              type="secondary">
              {`Fecha de última actualización: ${moment(organization?.createdAt).format("DD/MM/YYYY")}`}
            </Typography.Paragraph>
          </FlexContainer>
        </FlexContainer>
      </Col>
    </Row>
  );
}