import React from "react";
import { TagList } from "../../index";
import { CardListItem } from "../../list-item/card-list-item";
import { ModalButton } from "../../buttons";
import { MentoringAssignModal } from "../../modals";

export function CompanyMentoringCard({ mentor = {} }) {
  const skills = mentor?.expertProfile?.professionalExperience?.skills || [];
  const tags = skills.length
    ? <TagList
        labelMaxLength={10}
        colors={["blue", "magenta"]}
        maxCount={2}
        tags={mentor?.expertProfile?.professionalExperience?.skills} />
    : <div style={{minHeight: "24px"}} />;

  return (
    <CardListItem
      footerItems={[
        <ModalButton
          style={{alignSelf: "flex-end"}}
          item={mentor}
          ghost
          key="assign-btn"
          modal={MentoringAssignModal}
          title="Asignar" />
      ]}
      extra={tags}
      coverUrl={mentor?.profilePicUrl}
      title={mentor?.fullName}>
      {
        mentor?.expertProfile?.professionalExperience?.areas[0]
        ? mentor?.expertProfile?.professionalExperience?.areas[0]
        : <div style={{minHeight: "24px"}} />
      }
    </CardListItem>
  );
}
