import gql from "graphql-tag";

// IMPORTANT: We should enhance the login security
export const login = gql`
  query Login ($email: String, $password: String) {
    login(where: { email: $email, password: $password }) {
      email
      session
    }
  }
`;
