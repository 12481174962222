import React from "react";
import { FlexContainer } from "../flex-container";
import { WarningTwoTone } from "@ant-design/icons";

export function MissingProfileDataAlert({ missingInfo = true, children }) {
  if (!missingInfo) return children;

  return (
    <FlexContainer yAlign="center" style={{ gap: "1rem" }}>
      <div>{children}</div>
      <FlexContainer yAlign="center" style={{ gap: "0.2rem" }}>
        <WarningTwoTone twoToneColor="#faad14" />
        <p style={{ fontSize: "10px" }}>
          Hay campos obligatorios sin llenar
        </p>
      </FlexContainer>
    </FlexContainer>
  );
}
