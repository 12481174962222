import moment from "moment";

export const setUpdateEntrepreneurships = (
  entrepreneurships = [],
  state,
  setState
) => {
  const updatedEntrepreneurships = [
    ...state.entrepreneurships,
    ...entrepreneurships?.map(({period = [], ...e}) => ({
      ...e,
      startDate: period[0] && moment(period[0]).toISOString(),
      endDate: period[1] ? moment(period[1]).toISOString() : null
    }))
  ];

  setState({
    ...state,
    entrepreneurships: updatedEntrepreneurships
  });
};
