import React, { useState, useContext, useEffect } from "react";
import { Form, Input, Select } from "antd";
import { FormContainer } from "../../../../../shared";
import {
  EditProfileContext
} from "../../../../../../contexts/shared/edit-profile";
import {
  countries,
  states,
  fieldValidationMessages
} from "../../../../../../utils";

export function LocationForm({ form }) {
  const [state, setState] = useState({
    countryId: undefined,
    stateName: undefined
  });

  const { data } = useContext(EditProfileContext);

  useEffect(() => {
    const location = data?.user?.expertProfile?.location;
    if (location) {
      const countryId = parseInt(location.country || 0);

      setState({
        countryId,
        stateName: location.state
      });

      form.setFieldsValue({
        ...location,
        country: countryId
      });
    }
  }, [data]);

  const onSelectChange = (data = {}) => {
    setState(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const countryStates = states
    .filter(s => s.id_country == state.countryId)
    .map(s => ({ value: s.name, label: s.name }));

  return (
    <FormContainer>
      <Form
        validateMessages={fieldValidationMessages}
        layout="vertical"
        form={form}>
        <Form.Item
          name="country"
          label="País"
          rules={[{ required: true }]}>
          <Select
            options={countries.map(c => ({ value: c.id, label: c.name }))}
            showSearch
            onChange={countryId => onSelectChange({ countryId })}
            optionFilterProp="label"
            placeholder="Seleccionar país" />
        </Form.Item>
        <Form.Item
          name="state"
          label="Estado"
          rules={[{ required: true }]}>
          <Select
            options={countryStates}
            onChange={stateName => onSelectChange({ stateName })}
            showSearch
            optionFilterProp="label"
            disabled={state.countryId === undefined}
            placeholder="Seleccionar estado" />
        </Form.Item>
        <Form.Item
          name="city"
          label="Ciudad"
          rules={[{ required: true }]}>
          <Input
            placeholder="Ingresar ciudad"
            disabled={!state.stateName} />
        </Form.Item>
      </Form>
    </FormContainer>
  );
}
