import React from "react";
import { List, Space, Typography } from "antd";
import { Avatar } from "../../../../../components/shared";
import { LinkOutlined } from "@ant-design/icons";
import { getClickableLink } from "../../../../../helpers";

export function CommentListItem ({ item }) {
  const {
    description, fullName, profilePicUrl, type
  } = item;

  let listItem = null;

  if (type === "COMMENT") {
    listItem = (
      <>
        <List.Item.Meta
          avatar={
            <Avatar popover={true}>{profilePicUrl || fullName}</Avatar>
          } />
        <Typography.Text strong>{description}</Typography.Text>
      </>
    );
  } else if (type === "file") {
    listItem = (
      <Space>
        <LinkOutlined />
        <a
          href={getClickableLink(item.url)}>
          {item.fileName}
        </a>
      </Space>
    );
  } else {
    listItem = (
      <>
        <List.Item.Meta
          avatar={
            <Avatar popover={true}>{profilePicUrl || fullName}</Avatar>
          } />
        <Space>
          <Typography.Text>{fullName}</Typography.Text>
          <Typography.Text type="secondary">{description}</Typography.Text>
        </Space>
      </>
    );
  }

  return (
    <List.Item className={type}>
      {listItem}
    </List.Item>
  );
}
