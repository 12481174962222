import gql from "graphql-tag";

export const createUserByInvitation = gql`
  mutation CreateUserByInvitation(
    $email: String!
    $systemRole: SystemRole
    $batches: BatchCreateManyInput
    $odes: ODECreateManyInput
  ) {
    createInvitation(
      data: {
        email: $email,
        systemRole: $systemRole,
        batches: $batches,
        odes: $odes
      }
     ) {
      id
      email
      systemRole
    }
  }
`;
