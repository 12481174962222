import gql from "graphql-tag";

export const getUsersBySystemRoleWithExpertSegmentation = gql`
 query getUsersBySystemRoleWithExpertSegmentation (
   $ifullName: String,
   $systemRoles: [SystemRole!],
   $batchId: ID!,
   $filterUsers: [ID!]
 ) {
  users(
    where: {
      OR: [
        {
          ifullName_contains: $ifullName
          systemRole_in: $systemRoles
          id_not_in: $filterUsers
          NOT: {
            expertProfile: {
            	private: true
          	}
          }
        },
        {
          ifullName_contains: $ifullName
          expertProfile: {
            private: true
            privateBatches_some: {id_in: [$batchId]}
          }
        }
      ]
    }) {
    id
    systemRole
    fullName
    email
    phone
    profilePicUrl
    expertProfile {
      id
      resume
    }
  }
}
`;
