import React, { useState, createContext, useEffect } from "react";
import { useContext } from "react";
import { Visibility } from "../visibility";
import { renderDocs } from "./render-docs";

export const DocsWrapperContext = createContext(null);

export const useDocsContext = pageId => {
  const { loadPageDocs } = useContext(DocsWrapperContext);

  useEffect(() => {
    loadPageDocs(pageId);
  }, [pageId]);
};

export const DocsWrapper = ({ children, style }) => {
  const [docs, setDocs] = useState(null);
  const [error, setError] = useState(null);
  const [pageId, setPageId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDocs, setShowDocs] = useState(false);

  const developmentMode = process.env.REACT_APP_NODE_ENV === "development";

  const loadPageDocs = pageId => setPageId(pageId);

  useEffect(() => {
    if (!pageId || !showDocs) return;
    const baseUrl = process.env.REACT_APP_DOCS_BASE_URL;

    setLoading(true);
    setError(null);

    const loadDocs = async () => {
      fetch(`${baseUrl}/api/page-docs?page_id=${pageId}`)
      .then(async response => {
        await response.json()
          .then(docs => setDocs(docs))
          .catch(e => { throw e; });
      })
      .catch(e => {
        setError(e);
        console.error(e);
      })
      .finally(() => setLoading(false));
    };

    loadDocs();
  }, [pageId, showDocs]);

  return (
    <DocsWrapperContext.Provider value={{ loadPageDocs }}>
      <div style={{  width: "100%", height: "100%", ...style }}>
        {developmentMode
              ? <div
                  id="DevelopmentToolbar"
                  style={{
                    color: "#fff",
                    padding: "10px",
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#eee",
                    justifyContent: "space-between"
                  }}>
                <button
                  style={{
                    border: "none",
                    cursor: "pointer",
                    backgroundColor: "#6348E5"
                  }}
                  onClick={() => setShowDocs(prevState => !prevState)}>
                  {showDocs ? "Ocultar docs" : "Ver docs"}
                </button>
                <code style={{ color: "#444" }}>Entorno de pruebas</code>
              </div>
              : null}
        <div style={{ display: "flex" }}>
          <Visibility visible={showDocs}>
            <div
              style={{
                height: "100vh",
                minWidth: "500px",
                maxWidth: "500px",
                overflowY: "auto",
                wordWrap: "break-word"
              }}>
              {loading ? pageId ? "Cargando docs..." : "No hay docs" : null}
              {error ? "Ocurrió un error al cargar los docs" : null}
              {renderDocs(docs)}
            </div>
          </Visibility>
          {children}
        </div>
      </div>
    </DocsWrapperContext.Provider>
  );
};
