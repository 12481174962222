import React, { useContext } from "react";
import { Table } from "antd";
import {
  expertServiceTypes,
  getReadableValue,
  paginationConfig
} from "../../../../../../../helpers";
import { Format, ServiceCost, StatusTag } from "../../../../../../shared";
import { PaginationContext } from "../../../../../../../contexts/shared";

export function ScheduledTable({
  loading = false,
  items: assignations = [],
  currentPage,
  onChangePage,
  totalRecordsCount,
  paginationRefetchName,
  openModal,
  selectedRows = [],
  setSelectedRows
}) {
  const { onPaginationChange } = useContext(PaginationContext);

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange(paginationRefetchName)(page, pageSize);
  };

  const handleOnRowClick = scheduledExpertService => ({
    onClick: () =>  openModal({ scheduledExpertService }),
    className: "clickable"
  });

  const handleOnCheck = (_, selectedRowKeys = []) => {
    const onlyIdAndStatus =
      selectedRowKeys.map(({ id, status }) => ({ id, status }));
    setSelectedRows && setSelectedRows(onlyIdAndStatus);
  };

  return (
    <Table
      loading={loading}
      scroll={{ x: true }}
      style={{ width: "100%" }}
      dataSource={assignations || []}
      rowKey={(row, index) => row?.id || index}
      onRow={handleOnRowClick}
      pagination={{
        ...paginationConfig,
        current: currentPage,
        defaultPageSize: 12,
        onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
        total: totalRecordsCount
      }}
      rowSelection={{
        type: "checkbox",
        onChange: handleOnCheck,
        selectedRowKeys: selectedRows.map(el => el.id)
      }}>
      <Table.Column
        title="Compañía"
        render={({ ode, expertServiceData }) =>
          expertServiceData.type === "WORKSHOP" ? null : ode?.name
        } />
      <Table.Column
        title="Programa"
        render={({ batch }) => batch?.program?.name} />
      <Table.Column
        title="Batch"
        render={({ batch }) => batch?.name} />
      <Table.Column
        title="Producto"
        render={({ expertServiceData }) => expertServiceData?.name} />
      <Table.Column
        title="Experto"
        render={assignedExpertService =>
          assignedExpertService?.expertServiceData?.expert?.fullName} />
      <Table.Column
        title="Tipo de producto"
        render={({ expertServiceData }) =>
          getReadableValue(expertServiceTypes, expertServiceData?.type) } />
      <Table.Column
        align="center"
        title="Costo total"
        render={({ duration, ...assignedExpertService }) =>
          <ServiceCost
            assignedExpertService={assignedExpertService}
            duration={duration} /> } />
      <Table.Column
        align="center"
        title="Fecha de asignación"
        render={({ assignmentDate }) =>
          <Format type="date">{assignmentDate}</Format>
        } />
      <Table.Column
        align="center"
        title="Fecha límite de consumo"
        render={({ limitDate }) =>
          <span className="bold">
            <Format type="date">{limitDate}</Format>
          </span>
        } />
      <Table.Column
        align="center"
        title="Estado"
        render={({ status }) => {
          return (<StatusTag status={status} />);
        }} />
    </Table>
  );
}
