import React from "react";
import { Steps as ASteps } from "antd";
import { withRouter } from "../../../../../helpers";

function Steps({ current, navigate, match }) {
  const { id } = match.params;

  const handleOnClickStep = (path = "") => () => {
    navigate(path);
  };

  return (
    <ASteps
      size="small"
      type="navigation"
      current={current}>
      <ASteps.Step
        className="clickable"
        onClick={() => navigate("./personal-information")}
        title="Información personal" />
      <ASteps.Step
        className={id && "clickable"}
        onClick={id && handleOnClickStep("./professional-information")}
        title="Información profesional" />
      <ASteps.Step
        className={id && "clickable"}
        onClick={id && handleOnClickStep("./accounts")}
        title="Cuentas ligadas" />
      <ASteps.Step
        className={id && "clickable"}
        onClick={id && handleOnClickStep("./entrepreneurships")}
        title="Emprendimientos" />
    </ASteps>
  );
}

export default withRouter(Steps);
