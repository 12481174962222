import React, { useContext } from "react";
import { Table } from "antd";
import {
  expertServiceTypes,
  getReadableValue
} from "../../../../../helpers/select-options";
import { Format, ServiceCost, StatusTag } from "../../../../shared";
import {
  maskedAssignedUnits,
  maskedStatus,
  paginationConfig
} from "../../../../../helpers";
import {
  PaginationContext
} from "../../../../../contexts/shared";

export function AssignationsTable({
  loading = false,
  items: assignations = [],
  currentPage,
  onChangePage,
  totalRecordsCount,
  paginationRefetchName
}) {
  const { onPaginationChange } = useContext(PaginationContext);

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange(paginationRefetchName)(page, pageSize);
  };

  return (
    <Table
      loading={loading}
      scroll={{ x: true }}
      style={{ width: "100%" }}
      dataSource={assignations || []}
      rowKey={(row, index) => row?.id || index}
      pagination={{
        ...paginationConfig,
        current: currentPage,
        defaultPageSize: 12,
        onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
        total: totalRecordsCount
      }}>
      <Table.Column
        title="Compañía"
        render={({ ode, expertServiceData }) =>
          expertServiceData.type === "WORKSHOP" ? null : ode?.name
        } />
      <Table.Column
        title="Programa"
        render={({ batch }) => batch?.program?.name} />
      <Table.Column
        title="Edición"
        render={({ batch }) => batch?.name} />
      <Table.Column
        title="Producto"
        render={({ expertServiceData }) => expertServiceData?.name} />
      <Table.Column
        title="Tipo de producto"
        render={({ expertServiceData }) =>
          getReadableValue(expertServiceTypes, expertServiceData?.type) } />
      <Table.Column
        align="center"
        title="Cantidad"
        render={({ expertServiceData: { method }, assignedUnits }) =>
          maskedAssignedUnits({ method, duration: assignedUnits })} />
      <Table.Column
        align="center"
        title="Costo total"
        render={({ duration, ...assignedExpertService }) =>
          <ServiceCost
            assignedExpertService={assignedExpertService}
            duration={duration} /> } />
      <Table.Column
        align="center"
        title="Fecha de asignación"
        render={({ assignmentDate }) =>
          <Format type="date">{assignmentDate}</Format>
        } />
      <Table.Column
        align="center"
        title="Fecha límite de consumo"
        render={({ limitDate }) =>
          <span className="bold">
            <Format type="date">{limitDate}</Format>
          </span>
        } />
      <Table.Column
        align="center"
        title="Estado"
        render={({ status, limitDate, availableUnits}) => {
          const newStatus = maskedStatus({
            status,
            limitDate,
            availableUnits
          });
          return (<StatusTag status={newStatus} />);
        }} />
    </Table>
  );
}
