import React from "react";
import { Card, Col, Row, Typography } from "antd";
import { useNumeraliaByCampus } from "../../../../../hooks";

export const ResumeByCampusNumeralia = ({
  experiences,
  persons,
  audienceTypes
}) => {
  const {
    act,
    know,
    learn,
    impactedCommunity,
    totalCommunity
  } = useNumeraliaByCampus(experiences || [], audienceTypes, persons);

  const cardStyles = {
    width: "300",
    height: 120,
    border: "solid 1px #5F39DE",
    fontSize: "12px"
  };

  return(
    <div style={{width: "inherit"}}>
      <Row
        gutter={[16]}
        justify="space-around"
        align="middle">
        <Col flex={1}>
          <Card
            bordered
            style={cardStyles}>
            <Typography.Text
              type="secondary">
              Comunidad total
            </Typography.Text>
            <Typography.Title
              style={{marginTop: 0}}
              level={5}>
              {totalCommunity}
            </Typography.Title>
          </Card>
        </Col>
        <Col flex={1}>
          <Card
            style={cardStyles}>
            <Typography.Text
              type="secondary">
              Comunidad impactada
            </Typography.Text>
            <Typography.Title
              style={{marginTop: 0}}
              level={5}>
              {impactedCommunity}
            </Typography.Title>
          </Card>
        </Col>
        <Col flex={1}>
          <Card
            style={cardStyles}>
            <Typography.Text
              type="secondary">
              Conocer
            </Typography.Text>
            <Typography.Title
              style={{marginTop: 0}}
              level={5}>
              {know}
            </Typography.Title>
          </Card>
        </Col>
        <Col flex={1}>
          <Card
            style={cardStyles}>
            <Typography.Text
              type="secondary">
              Aprender
            </Typography.Text>
            <Typography.Title
              style={{marginTop: 0}}
              level={5}>
              {learn}
            </Typography.Title>
          </Card>
        </Col>
        <Col flex={1}>
          <Card
            style={cardStyles}>
            <Typography.Text
              type="secondary">
              Accionar
            </Typography.Text>
            <Typography.Title
              style={{marginTop: 0}}
              level={5}>
              {act}
            </Typography.Title>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
