import React from "react";
import { Select } from "antd";
import { expertServiceCategories } from "../../../helpers/select-options";

export function ServiceCategoriesDropdown({ onChange, style = {} }) {

  return (
    <Select
      allowClear
      showSearch
      optionFilterProp="label"
      options={expertServiceCategories}
      style={{ width: "180px", ...style }}
      onChange={onChange}
      placeholder="Categoría" />
  );
}
