import React, { useContext, useEffect } from "react";
import { client } from "../../../../graphql";
import { useQuery } from "@apollo/react-hooks";
import { MenuContext } from "../../../contexts/shared";
import { entrepreneur } from "../../../graphql/entrepreneur";
import { CurrentUserContext } from "../../../contexts/shared";
import { MainMenu } from "../../../components/entrepreneur/shared";
import { Section, SidebarLayout } from "../../../components/shared";
import { useQueryFilters, useControlledPagination } from "../../../helpers";
import {
  default as BatchesList
} from "../../../components/entrepreneur/batches/list";
import {
  FilterControls
} from "../../../components/admin/batches/filter-controls";

export function BatchesIndex() {
  const { setMenuState } = useContext(MenuContext);
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const { loading, data, error, refetch } = useQuery(
    entrepreneur.queries.getBatchesByEntrepreneurId,
    { client, variables: { id: currentUser.id } }
  );

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: { id: currentUser.id }
  });

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 10
  });

  const onFilter = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  return (
    <SidebarLayout menu={<MainMenu />}>
      <Section
        title="Batches"
        spaced
        extra={<FilterControls onChange={onFilter} />}>
        <BatchesList
          data={data}
          error={error}
          isLoading={loading}
          onChangePage={onPaginationChange}
          currentPage={currentPage} />
      </Section>
    </SidebarLayout>
  );
}
