import gql from "graphql-tag";

export const getAssignedServicesByBatchQuery = gql`
  query GetCompanyAssignedServicesByBatchQuery(
      $batchId: ID!,
      $companyId: ID!,
      $types: [String!],
      $todayDate: DateTime,
      $availableUnits_gt: Int
    ) {
    assignedExpertServices(
      where: {
        status_not: "CANCELLED"
        batch: { id: $batchId }
        ode: { id: $companyId }
        limitDate_gte: $todayDate
        availableUnits_gt: $availableUnits_gt
        expertService: {
          OR: [{ disabled: false }, { disabled: null }],
          type_in: $types
        }
      }
    ) {
      id
      status
      limitDate
      assignedUnits
      availableUnits
      expertServiceData
      expertService {
        id
        name
        coverPicUrl
        type
        category
        expert {
          id
          fullName
        }
      }
    }
  }
`;
