import gql from "graphql-tag";

export const getOdeTeamUsers = gql`
  query GetOdeTeamUsers($id: ID!, $ifullName: String) {
    oDE(where: {
      id: $id,
      
    }) {
      id
      team(where: { ifullName_contains: $ifullName }){
        id
        fullName
        email
        phone
        systemRole
        profilePicUrl
      }
      batches {
        id
      }
    }
  }
`;
