import React, { useEffect, useContext } from "react";
import {
  MenuContext,
  ServicesLayerProvider
} from "../../../../contexts/shared";
import { Section, SidebarLayout } from "../../../../components/shared";
import {
  ServicesMonitoringPanedTables
} from "../../../../components/expert/services/monitoring";
import { ScheduledExpertServiceService } from "../../../../services";

export function AssignationsMonitoringServicesIndex() {
  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["experts"],
      selectedKeys: ["experts/monitoring"]
    });
  }, []);

  return (
    <ServicesLayerProvider services={{
      ScheduledExpertServiceService
    }}>
      <SidebarLayout>
        <Section spaced>
          <ServicesMonitoringPanedTables
            tableType="ASSIGNATIONS" />
        </Section>
      </SidebarLayout>
    </ServicesLayerProvider>
  );
}
