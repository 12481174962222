import React, {useState} from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Space,
  Typography
} from "antd";
import {Avatar, UploadButton} from "../../../../shared";

export const InformationForm = ({ form, onNext, loadingNext, profilePic }) => {
  const [state, setState] = useState({ profilePicUrl: profilePic });

  const getPhotoUrl = e => {
    const profilePicUrl = e?.file?.response?.imageUrl;

    setState(prevState => ({
      ...prevState,
      profilePicUrl
    }));

    onFormChange();

    return e.fileList;
  };

  const onFormChange = () => {
    try {
      const values = form.getFieldsValue();

      values.fullName = [values.firstName, values.middleName, values.lastName]
        .filter(e => e !== undefined)
        .join(" ");

      if (values.profilePicUrl?.length) {
        values.profilePicUrl = values.profilePicUrl[0]?.url
          || values.profilePicUrl[0].response?.imageUrl;
      }
    }
    catch (e) {
      console.error(e);
    }
  };

  const passwordValidator = (_, confirmPassword) => {
    const { password } = form.getFieldsValue();
    if(confirmPassword === password) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error());
    }
  };

  return (
    <Card bordered={false} style={{ maxWidth: "719px", margin: "0 auto" }}>
      <Space direction="vertical" className="wide-input">
        <div>
          <Typography.Title
            level={2}
            style={{ fontSize: "24px" }}>
            Hola, estás en EOS 👋
          </Typography.Title>
          <Typography.Text style={{ fontSize: "16px" }}>
            Crear tu cuenta es muy fácil ¡Comencemos!
          </Typography.Text>
        </div>
        <Form
          form={form}
          layout="vertical"
          style={{ marginTop: "19px" }}>
          <Form.Item>
            <Avatar
              size={64}
              src={state.profilePicUrl} />
          </Form.Item>
          <Form.Item
            name="profilePicUrl"
            getValueFromEvent={getPhotoUrl}>
            <UploadButton.Crop
              aspect={0.9}
              multiple={false}
              maxFiles={1}>
              Subir fotografía
            </UploadButton.Crop>
          </Form.Item>
          <Form.Item
            label="Nombre(s)"
            name="firstName"
            rules={[{ required: true }]}>
            <Input type="text" placeholder="Nombre(s)" />
          </Form.Item>
          <Row justify="space-between">
            <Col span={12}>
              <Form.Item
                label="Apellido paterno"
                name="middleName"
                style={{ marginRight: "10px" }}
                rules={[{ required: true }]}>
                <Input type="text" placeholder="Apellido paterno" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Apellido materno"
                name="lastName"
                style={{ marginLeft: "10px" }}
                rules={[{ required: true }]}>
                <Input type="text" placeholder="Apellido materno" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Email"
            name="email">
            <Input
              disabled
              type="text"
              placeholder="Correo electrónico"
              style={{ backgroundColor: "#F7F5FD" }} />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Contraseña"
                name="password"
                style={{ marginRight: "10px" }}
                rules={[{ required: true }]}>
                <Input type="password" placeholder="Contraseña" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Confirmar contraseña"
                name="confirmPassword"
                style={{ marginLeft: "10px" }}
                rules={[{
                  required: true,
                  message: "Las contraseñas deben de ser iguales",
                  validator: passwordValidator}]}>
                <Input type="password" placeholder="Contraseña" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              name="acceptedTermsAndConditions"
              valuePropName="checked"
              rules={[{
                required: true,
                message: "Debe aceptar los términos y condiciones"
              }]}>
              <Checkbox>
                Acepto&nbsp;
                <a
                  target="_blank"
                  href="https://tec.mx/es/aviso-de-privacidad-instituto-de-emprendimiento">
                  términos y condiciones
                </a> y&nbsp;
                <a
                  target="_blank"
                  href="https://tec.mx/es/aviso-de-privacidad-instituto-de-emprendimiento">
                  políticas de privacidad.
                </a>
              </Checkbox>
            </Form.Item>
          </Row>
        </Form>
        <Button
          type="primary"
          onClick={onNext}
          loading={loadingNext}
          style={{
            position: "absolute",
            right: "24px"}}>
          Siguiente
        </Button>
      </Space>
    </Card>
  );
};
