import React, { useContext } from "react";
import { AddButton } from "../../../index";
import { ContentGroupModalContext } from "../../../../../contexts/shared";

const addButtonStyle = {
  width: "100%",
  marginBottom: "24px",
  marginTop: "24px"
};

export function AddContentGroupButton() {
  const { openModal } = useContext(ContentGroupModalContext);

  return (
    <AddButton style={addButtonStyle} onClick={() => openModal()}>
      Agregar nueva sección
    </AddButton>
  );
}

