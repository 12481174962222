import { message } from "antd";

export function useAcceptDelivery({
  ScheduledExpertServiceService
}) {
  /**
   * we can improve it
   */
  const acceptDelivery = async ({
    afterSuccess,
    entrepreneurId,
    scheduledServiceId
  }) => {
    const dismissLoader = message.loading("Guardando...");
    try {
      await ScheduledExpertServiceService.acceptDelivery({
        entrepreneurId,
        scheduledServiceId
      });
      message.success("Entrega aceptada");

      afterSuccess && await afterSuccess();
    } catch (e) {
      console.error(e);
      message.error("Ha ocurrido un error");
    } finally {
      dismissLoader();
    }
  };

  return {
    acceptDelivery
  };
}
