import React, { useContext } from "react";
import { Button, Form, Divider, List, Modal, Typography, Tooltip } from "antd";
import { SendOutlined, ExclamationCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import { CompletedCheck, Format, Visibility } from "../../../components/shared";
import { FileLinkItem } from "../../../components/shared/file-link-item";
import { FileLinkField } from "../../../components/shared/file-link-field";
import { CurrentUserContext } from "../current-user";
import { CommentsList } from "../../../components/shared/comment-list";

export function TaskDetailsModal({
  task,
  visible,
  closeModal,
  deleteDeliverable,
  markTaskAsCompleted,
  markTaskAsUncompleted,
  sendDeliverables,
  addComment
}) {

  const [ form ] = Form.useForm();
  const { Title, Text } = Typography;
  const { currentUser } = useContext(CurrentUserContext);

  const onSendDeliverables = () => {
    const {deliverables} = form.getFieldsValue();
    if(deliverables) {
      const formatedFiles = deliverables.map(file => {
        delete file.uuid;
        return file;
      });
      sendDeliverables(formatedFiles);
      form.resetFields();
    } else {
      return Modal.info({
        icon: <ExclamationCircleOutlined />,
        title: "Advertencia",
        content: "No se puede enviar si no hay archivos"
      });
    }
  };

  const onSendComment = comment => {
    addComment(currentUser.id, comment);
  };

  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      onOk={markTaskAsCompleted}
      cancelText="Cerrar"
      okText="Marcar como completado"
      width={850}
      title={<h4><b>Detalles de la tarea</b></h4>}
      footer={[
        <Button
          key="1"
          onClick={closeModal}>
          Cerrar
        </Button>,
        <Visibility
          key="2"
          visible={!task?.isCompleted}>
          <Tooltip
            placement="right"
            title="Marcar como completado">
            <CheckCircleTwoTone
              style={{ fontSize: "20px", marginLeft: "15px" }}
              className="clickable"
              twoToneColor="#52c41a"
              onClick={markTaskAsCompleted} />
          </Tooltip>
        </Visibility>,
        <CompletedCheck
          key="3"
          style={{ marginLeft: "15px" }}
          isComplete={task?.isCompleted}
          date={task?.deliveredDate}
          onRestore={markTaskAsUncompleted} />
      ]}>
      <Title level={4}>{task?.name}</Title>
      <Text>Fecha límite <Format type="date">{task?.deliveryDate}</Format></Text>
      <br /><br />
      <Text style={{ whiteSpace: "pre-wrap" }}>{task?.description}</Text>
      <br /><br />
      <Text>Responsable: {task?.responsable?.fullName}</Text>
      {/* TODO: Agregar CompletedCheck */}
      <Divider orientation="left">Recursos</Divider>
      <List
        dataSource={task?.resources}
        renderItem={resource => <FileLinkItem
          item={resource}
          canDelete={true} />} />
      <Divider orientation="left">Entregables</Divider>
      <Text type="secondary">
        Sube tus entregables y envialos antes de marcar una actividad como completada.
      </Text>
      <div style={{ marginBottom: "15px" }}>
        <List
          dataSource={task?.deliverables}
          renderItem={deliverables =>
            <FileLinkItem
              item={deliverables}
              onDelete={deleteDeliverable}
              canDelete={task?.isCompleted} />} />
      </div>
      <Visibility visible={["ENTREPRENEUR"]
        .includes(currentUser.systemRole) && !task?.isCompleted}>
        <Form
          form={form}>
          <Form.Item
            name="deliverables">
            <FileLinkField />
          </Form.Item>
        </Form>
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={onSendDeliverables}>
          Enviar entregables
        </Button>
      </Visibility>
      <Divider orientation="left">Comentarios</Divider>
      <CommentsList
        loading={false}
        comments={task?.comments}
        sendComment={onSendComment} />
    </Modal>
  );
}