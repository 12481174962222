import { client3 } from "../../../graphql";
import { getSearchPersonQuery } from "./graphql";

export const getSearchPerson = async ({ searchText }) => {
  const result = await client3.query({
    query: getSearchPersonQuery,
    fetchPolicy: "network-only",
    variables: { searchText }
  });

  return result?.data?.searchPerson || [];
};