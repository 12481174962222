// queries for functions added in feature/company branch
import { client } from "../../../graphql";
import { createMilestoneWithOdeId } from "./graphql/create-milestone-with-ode-id";
import { deleteMilestoneByOdeIdAndMilestoneId } from "./graphql/delete-milestone-by-ode-id-and-milestone-id";
import { updateCompanyWithId } from "./graphql/update-company-with-id";
import { updateMilestoneWithMilestoneIdAndOdeId } from "./graphql/update-milestone-with-milestone-id";
import { addCompanyMultimedia } from "./graphql/add-company-multimedia";
import { transformOdeToCompanyMutation } from "./graphql/transform-ode-into-company";
import { deleteCompanyMultimedia } from "./graphql/delete-company-mutimedia";
import { getCompanies } from "./graphql/get-companies-with-same-name";
import { getPublishedContent } from "./get-published-content";

// functions already in v2 branch
import { getCompaniesByIds } from "./get-companies-by-ids";
import {
  getEntrepreneurCompaniesInBatch
} from "./get-entrepreneur-companies-in-batch";
import { getAssignedServicesByBatch } from "./get-assigned-services-by-batch";
import {
  getScheduledServicesByBatch
} from "./get-scheduled-services-by-batch";
import {
  registerCompanyEnrolledWithBatchEvent
} from "./register-company-enrolled-with-batch-event";
import {
  registerCompanyDroppedOutFromBatchEvent
} from "./register-company-dropped-out-from-batch-event";
import { getOdeContentsQuery } from "./graphql/get-ode-contents-query";

function createCompany ({ company, coordinatorId, entrepreneurId }) {
  //TODO: Finish this
  client.mutate({
    mutation: "",
    variables: { data: company }
  });
}

async function createMultimedia({ odeId, multimedia = []}) {
  await client.mutate({
    mutation: addCompanyMultimedia,
    variables: {
      id: odeId,
      data: {
          create: multimedia?.map(m => {
            return{
              fileName: m.fileName,
              type: m.type,
              url: m.url
            };})
        }
    }
  });
}

async function deleteMultimedia({ odeId, fileId = ""}) {
  await client.mutate({
    mutation: deleteCompanyMultimedia,
    variables: { id: odeId, fileId: fileId }
  });
}

async function updateCompany({ company, odeId }) {
  await client.mutate({
    mutation: updateCompanyWithId,
    variables: { data: company, id: odeId }
  });
}

async function createMilestone ({ milestone, odeId }) {
  await client.mutate({
    mutation: createMilestoneWithOdeId,
    variables: { data: milestone, id: odeId }
  });
}

async function updateMilestone ({ milestone, milestoneId, odeId }) {
  await client.mutate({
    mutation: updateMilestoneWithMilestoneIdAndOdeId,
    variables: { data: milestone, odeId, milestoneId }
  });
}

async function deleteMilestone({ milestoneId, odeId }) {
  await client.mutate({
    mutation: deleteMilestoneByOdeIdAndMilestoneId,
    variables: { milestoneId, odeId }
  });
}

function getCompaniesWithSameName({ name }) {
  const where = { name };
  return client.query({
    query: getCompanies,
    variables: { where },
    fetchPolicy: "network-only"
  });
}

function transformOdeToCompany({ odeId }) {
  return client.mutate({
    mutation: transformOdeToCompanyMutation,
    variables: { odeId }
  });
}

async function getOdeContents({ odeId, batchId }) {
  const result = await client.query({
    query: getOdeContentsQuery,
    variables: { odeId, batchId },
    fetchPolicy: "network-only"
  });
  return result.data.odeContents;
}

export const CompanyService = {
  getOdeContents,
  getCompaniesByIds,
  getAssignedServicesByBatch,
  getScheduledServicesByBatch,
  getEntrepreneurCompaniesInBatch,
  registerCompanyEnrolledWithBatchEvent,
  registerCompanyDroppedOutFromBatchEvent,
  getPublishedContent,

  createCompany,
  updateCompany,
  createMilestone,
  updateMilestone,
  deleteMilestone,
  createMultimedia,
  deleteMultimedia,
  getCompaniesWithSameName,
  transformOdeToCompany
};
