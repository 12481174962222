import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Checkbox
} from "antd";
import moment from "moment";

import { FormContainer } from "../../../../../../shared";
import { DatePicker } from "./date-picker";

const { Item, useForm } = Form;

export const CoursesAndCertificationsModal = ({
  visible = false,
  onCancel,
  onConfirm,
  course = {}
}) => {
  const [ongoing, setOngoing] = useState(false);

  const [form] = Form.useForm();
  const validationMessages = {
    required: "El campo '${label}' es requerido."
  };

  useEffect(() => {
    if (course.startDate) {
      if (course.endDate === null && !ongoing) {
        setOngoing(true);
      }
    }

    form.resetFields();
  }, [visible]);

  const handleConfirm = async () => {
    try {
      const values = await form.validateFields();
      const { dates, ...valuesWithoutDates } = values;
      const dateValues = Array.isArray(dates)
        ? {
          startDate: dates[0] ? moment(dates[0]).toISOString() : null,
          endDate: dates[1] ? moment(dates[1]).toISOString() : null
        }
        : {
          startDate: dates ? moment(dates).toISOString() : null,
          endDate: null
        };

      onConfirm({
        ...course,
        ...valuesWithoutDates,
        ...dateValues
      });
    } catch (error) {
      // TODO: Report to bugsnag
    } finally {
      setOngoing(false);
    }
  };

  const toggleOngoing = () => {
    setOngoing(!ongoing);
    form.setFieldsValue({ dates: undefined });
  };

  const handleOnCancel = () => {
    onCancel();
    setOngoing(false);
  };

  return (
    <Modal
      forceRender
      style={{ width: "600px" }}
      closable
      visible={visible}
      onCancel={handleOnCancel}
      footer={[
        <Button
          type="primary"
          onClick={handleConfirm}>
          Guardar
        </Button>,
        <Button
          onClick={onCancel}>
          Cancelar
        </Button>
      ]}
      title="Cursos y certificaciones">
      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          validateMessages={validationMessages}>
          <Item
            initialValue={course.name}
            name="name"
            label="Nombre"
            rules={[{ required: true }]}>
            <Input />
          </Item>
          <Item
            initialValue={course.endorsingInstitution}
            name="endorsingInstitution"
            label="Institución que avaló el curso"
            rules={[{ required: true }]}>
            <Input />
          </Item>
          <Item label="En curso">
            <Checkbox
              checked={ongoing}
              onChange={toggleOngoing} />
          </Item>
          <Item
            initialValue={[course.startDate, course.endDate]}
            valuePropName="dates"
            name="dates"
            label="Fechas">
            <DatePicker ongoing={ongoing} />
          </Item>
        </Form>
      </FormContainer>
    </Modal>
  );
};
