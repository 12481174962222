import React from "react";
import { Page, View } from "@react-pdf/renderer";
import { Header } from "../../components/header";
import { PeopleInvolved } from "./people-involved";
import { Status } from "./status";

export const PageTwo = ({ data }) => {
  return (
    <Page
      size="A4"
      style={{
        padding: "25px",
        display: "flex",
        justifyContent: "space-between",
        fontFamily: "Roboto" }}>
      <View>
        <Header data={data.header} />
        <PeopleInvolved data={data.involvedPeople} />
      </View>
      {/* <Status /> */}
    </Page>
  );
};
