import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { sectorsToChart } from "./sectors-to-chart";
import { sectorsChartOptions } from "./sectors-chart-options";
import { Visibility } from "../../../../components/shared";
import { Card } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const SectorsChart = ({ cubeClient, filters }) => {
  const [sectorsData, setSectorsData] = useState();

  useEffect(() => {
    const loadCubeQuery = async () => {
      const cubeFilters = [
        {
          "member": "Company.companySector",
          "operator": "set"
        }
      ];

      if (filters.batchId) {
        cubeFilters.push({
          "member": "Company.batchId",
          "operator": "equals",
          "values": [filters.batchId]
        });
      }

      if (filters.programId) {
        cubeFilters.push({
          "member": "Company.programId",
          "operator": "equals",
          "values": [filters.programId]
        });
      }

      if (filters.batchRangeDates.since && filters.batchRangeDates.until) {
        const { since, until } = filters.batchRangeDates;
        const filter = {
          "member": "Company.batchStartDate",
          "operator": "inDateRange",
          "values": [since, until]
        };
        cubeFilters.push(filter);
      }

      const resultSet = await cubeClient.load({
        "measures": [
          "Company.companiesCount"
        ],
        "order": {
          "Company.companiesCount": "desc"
        },
        "dimensions": [
          "Company.companySector"
        ],
        filters: cubeFilters
      });

      const queryData = resultSet.loadResponse.results[0].data;
      const chartData = sectorsToChart(queryData);
      setSectorsData(chartData);
    };


    loadCubeQuery()
      .then()
      .catch(console.error);
  }, [filters]);

  return (
    <Visibility visible={!!sectorsData}>
      <Card
        style={{ marginTop: "2rem" }}
        title="Por sectores">
        <Bar
          data={sectorsData}
          type={"bar"}
          height={400}
          options={sectorsChartOptions} />
      </Card>
    </Visibility>
  );
};
