import React from "react";
import { Card, Divider, Space, Tooltip, Typography } from "antd";
import { PictureOutlined } from "@ant-design/icons";
import { useDecorator } from "../../../../../helpers/use-decorator";
import {
  Format,
  CoverImage,
  ActionButton,
  AvatarList,
  TagList,
  RoleGuard,
  Visibility
} from "../../../../shared";
import { getAvatarsAndTags, getStatus } from "../helpers";

export function BatchItem({
  actions,
  batch,
  onAddOde = undefined,
  onClick,
  clickable
}) {
  batch = useDecorator("batch", batch);
  const { avatars, tags } = getAvatarsAndTags(batch);
  // Todo: When statusBatch eq undefined the status value under the object
  const [statusBatch] = batch?.object?.odeStatusBatch || [];
  const isActive = statusBatch
    ? statusBatch?.status === "ACTIVE"
    : !statusBatch?.length;

  const status = getStatus(batch, isActive);
  const hoverSettings = (clickable && isActive) ? "pointer" : "default";
  const handleOnClick = isActive ? onClick : undefined;

  return (
    <Card
      className="batch-item"
      style={{ height: "410px", borderRadius: "5px" }}
      bodyStyle={{ padding: "10px" }}
      cover={
        <CoverImage
          onClick={handleOnClick}
          text={batch?.name}
          url={batch?.coverPicUrl}
          icon={PictureOutlined} />
      }>
      <Card.Meta
        title={
          <Space style={{ justifyContent: "space-between", width: "100%" }}>
            <Tooltip title={batch?.name} arrowPointAtCenter>
              <a
                style={{cursor: hoverSettings}}
                onClick={handleOnClick}>
                <Format size={20} type="prune">
                  {
                    batch?.name.length > 20
                      ? `${batch?.name.substring(0, 20)}...`
                      : batch?.name
                  }
                </Format>
              </a>
            </Tooltip>
            <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR"]}>
              <Visibility visible={actions}>
                <ActionButton
                  type="text"
                  confirmDeleteText="¿Deseas eliminar este batch?
                  No se puede recuperar. Si el batch tiene contenidos
                  no se podrá eliminar."
                  {...actions} />
              </Visibility>
            </RoleGuard>
          </Space>
        }
        description={
          <>
            <a
              style={{cursor: hoverSettings}}
              onClick={handleOnClick}>
              <Space direction="vertical">
                <Divider />
                <Typography.Text>
                  {batch?.program?.name}
                </Typography.Text>
                <Format type="secondary">
                  Fecha: {batch?.period}
                </Format>
                <Typography.Text className={status.className}>
                  {status.label}
                </Typography.Text>
                {
                  !!tags.length
                    ? <TagList
                      className="tags"
                      maxCount={2}
                      tags={tags} />
                    : <div style={{height: "24px"}} />
                }
                <Divider />
              </Space>
            </a>
            <Visibility visible={avatars?.length > 0 || onAddOde}>
              <AvatarList
                covers={avatars}
                className="avatars"
                onClick={onAddOde} />
            </Visibility>
          </>
        } />
    </Card>
  );
}
