import React from "react";
import { Space, Select } from "antd";
import { remove as removeAccents } from "remove-accents";
import { debounce } from "lodash";
import {
  expertServiceCategories
} from "../../../../helpers/select-options";
import { SearchToolbar } from "../../../../components/shared";

export function FilterControls({ onChange }) {
  const selectStyle = { width: "180px" };

  const handleOnChange = (filter = {}) =>
    onChange && onChange(filter);

  const handleOnSearchChange = debounce(text => {
    const stringValue = removeAccents(text).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ searchString: value });
  }, 500);

  return (
    <Space>
      <Select
        allowClear
        showSearch
        optionFilterProp="label"
        style={selectStyle}
        onChange={category => handleOnChange({ category })}
        options={expertServiceCategories}
        placeholder="Categoría" />
      <Select
        allowClear
        style={selectStyle}
        onChange={batch => handleOnChange({ batch })}
        placeholder="Todos los batches" />
      <SearchToolbar
        onSearchChange={e => handleOnSearchChange(e.target.value)} />
    </Space>
  );
}
