import React, { useContext, useEffect } from "react";
import moment from "moment";
import { Space, Alert, Button, Input, Form, Select, DatePicker } from "antd";
import { Avatar } from "../../../../shared";
import { EditProfileContext } from "../../../../../contexts/shared/edit-profile";
import { genders } from "../../../../../helpers/select-options/shared";
import { handleFormOnChange } from "./helpers";
import {
  userDecorator
} from "../../../../../decorators/admin/experts/show/user-decorator";

export function EditPersonalInfoForm({ data, setEditing, editing }) {
  const {
    updatePersonalInformation,
    saveChanges
  } = useContext(EditProfileContext);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...data?.user,
      resume: data?.user?.expertProfile?.resume,
      birthday: moment(data?.user?.birthday),
      city: data?.user?.expertProfile?.location?.city,
      state: data?.user?.expertProfile?.location?.state,
      country: data?.user?.expertProfile?.location?.country
    });
  }, [data, editing]);

  const save = async () => {
    await saveChanges(false, "personalInformation");
    setEditing(false);
  };

  if(!data.user) {
    return (
      <Alert
        type="error"
        message="Ha ocurrido un error al cargar la información,
        por favor intenta de nuevo en unos segundos."
        showIcon />
    );
  }

  const decoratedUser = Object.assign(userDecorator, data.user);

  const handleOnValuesChange = (field, allFields) =>
    handleFormOnChange(allFields, updatePersonalInformation);

  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={handleOnValuesChange}>
      <Space
        direction="horizontal"
        size={20}
        align="start">
        <Avatar size={60}>
          {decoratedUser.profilePicUrl || decoratedUser.fullName}
        </Avatar>
        <Space direction="vertical" size={5}>
          <Space direction="horizontal">
            <Form.Item label="Nombre" name="firstName">
              <Input />
            </Form.Item>
            <Form.Item label="Apellido paterno" name="middleName">
              <Input />
            </Form.Item>
            <Form.Item label="Apellido materno" name="lastName">
              <Input />
            </Form.Item>
            <Button type="primary" onClick={save}>
              Guardar
            </Button>
            <Button type="default" onClick={() => setEditing(false)}>
              Cancelar
            </Button>
          </Space>
          <Form.Item label="Semblanza" name="resume">
            <Input.TextArea rows={3} />
          </Form.Item>
          <Space direction="horizontal">
            <Form.Item label="Teléfono" name="phone">
              <Input />
            </Form.Item>
            <Form.Item label="Género" name="gender">
              <Select
                style={{ width: "217px" }}
                options={genders} />
            </Form.Item>
            <Form.Item label="Fecha de nacimiento" name="birthday">
              <DatePicker
                style={{ width: "217px" }} />
            </Form.Item>
          </Space>
          <Space direction="horizontal">
            <Form.Item label="País" name="country">
              <Input />
            </Form.Item>
            <Form.Item label="Estado" name="state">
              <Input />
            </Form.Item>
            <Form.Item label="Ciudad" name="city">
              <Input />
            </Form.Item>
          </Space>
        </Space>
      </Space>
    </Form>
  );
}
