export const getImpactedCommunityTableData = ({ tableData = {} }) => {

  const {
    studentsWithCoCurricularExperiences,
    studentsWithExtraCurricularExperiences,
    totalCommunity,
    gestorStudents,
    gestorTeachers,
    teachersCommunity,
    studentsCommunity
  } = tableData || {};

  const getPercentage = (value, total) => {
    if(value && total)
      return `${((value / total) * 100).toFixed(2)}%`;
    return "-";
  };

  const dataSource = [
    {
      key: "1",
      type: "Alumnos en experiencia Co-curriculares",
      community: studentsWithCoCurricularExperiences || "-",
      percentage:
        getPercentage(studentsWithCoCurricularExperiences,totalCommunity)
    },
    {
      key: "2",
      type: "Alumnos con experiencia Extracurriculares",
      community: studentsWithExtraCurricularExperiences || "-",
      percentage:
        getPercentage(studentsWithExtraCurricularExperiences,totalCommunity)
    },
    {
      key: "3",
      type: "Alumnos gestores",
      community: gestorStudents || "-",
      percentage:
        getPercentage(gestorStudents,studentsCommunity)
    },
    {
      key: "4",
      type: "Profesores gestores",
      community: gestorTeachers || "-",
      percentage:
        getPercentage(gestorTeachers,teachersCommunity)
    }
  ];

  const columns = [
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => {
        if(a.type < b.type) { return -1; }
        if(a.type > b.type) { return 1; }
        return 0;
      }
    },
    {
      title: "Comunidad",
      dataIndex: "community",
      key: "community",
      sorter: (a, b) => a.community - b.community
    },
    {
      title: "Porcentaje",
      dataIndex: "percentage",
      key: "percentage",
      sorter: (a, b) => a.percentage - b.percentage
    }
  ];

  return {
    dataSource,
    columns
  };
};
