import gql from "graphql-tag";

export const deleteTask = gql`
  mutation DeleteTask($id: ID) {
    deleteTask(where: { id: $id }){
      id
      name
      description
    }
  }
`;
