import { validateEmail } from "../../../helpers";
import { Tag } from "antd";
import React from "react";

/**
 * returns a `Tag` component from `antd` with a few props
 * @returns A tag component with a label, value, closable, and onClose props.
 */

export const tagRender = props => {
  const { label, value, closable, onClose } = props;
  const isValid = validateEmail(value)
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Tag
      id={value}
      key={value}
      color={isValid ? "default" : "red"}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{
        marginRight: 3,
      }}>
      {label}
    </Tag>
  );
};