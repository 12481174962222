import React from "react";
import { useHistory } from "react-router-dom";
import { List, Space, Typography } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Avatar, Format, IconLabel } from "../../../shared";

export default function EntrepreneurItem({ entrepreneur }) {

  const history = useHistory();

  const onEntrepreneurClick = () => {
    history.push(`./entrepreneurs/${entrepreneur?.id}/personal-information`);
  };

  return (
    <List.Item>
      <div
        className="clickable"
        onClick={() => onEntrepreneurClick()}>
        <div>
          <List.Item.Meta
            avatar={<Avatar size={50}>{entrepreneur.profilePicUrl || entrepreneur.fullName}</Avatar>}
            title={entrepreneur.fullName}
            description={
              <div>
                <div>
                  <Typography.Text type="secondary">
                    {entrepreneur.odes?.[0]?.ode?.[0]?.name}
                  </Typography.Text>
                </div>
                <Space>
                  <IconLabel icon={<MailOutlined />}>{entrepreneur.email}</IconLabel>
                  <IconLabel icon={<PhoneOutlined />}>
                    <Format type="phone">{entrepreneur.phone}</Format>
                  </IconLabel>
                </Space>
              </div>
            }>
          </List.Item.Meta>
        </div>
      </div>
    </List.Item>
  );
}