import React from "react";
import { Menu } from "antd";
import { withRouter } from "../../../../../helpers";
import { useFeatureFlags } from "../../../../../hooks";

function Navbar({ navigate, selectedKeys = [], routes = {}, ...props }) {
  const featureFlags = useFeatureFlags();

  return (
    <Menu
      defaultOpenKeys={["objectives"]}
      selectedKeys={selectedKeys}
      style={{ borderBottom: "none" }}
      mode="horizontal"
      {...props}>
      {featureFlags.batch.expertsTab.isEnabled
          ? <Menu.Item
              onClick={() => navigate(routes.experts || "./experts")}
              key="experts">
            Expertos
          </Menu.Item>
          : null}
      <Menu.Item
        onClick={() => navigate(routes.odes || "./odes")}
        key="odes">
        Compañías
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.entrepreneurs || "./entrepreneurs")}
        key="entrepreneurs">
        Emprendedores
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.coordinators || "./coordinators")}
        key="coordinators">
        Coordinadores
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.board || "./board")}
        key="board">
        Tablero
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.perks || "./perks")}
        key="perks">
        Perks
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.trainings || "./trainings")}
        key="trainings">
        Capacitación
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.calendar || "./calendar")}
        key="calendar">
        Calendario
      </Menu.Item>
      {
        featureFlags.batch.tasksTab.isEnabled
          ? <Menu.Item
              onClick={() => navigate(routes.tasks || "./tasks")}
              key="tasks">
            Tareas
          </Menu.Item>
          : null
      }
      <Menu.Item
        onClick={() => navigate(routes.odesUpdates || "./odes-updates")}
        key="odes-updates">
        Actualización
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.content || "./content")}
        key="content">
        Contenido
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.announcements || "./announcements")}
        key="announcements">
        Avisos
      </Menu.Item>
      {featureFlags.batch.analyticsTab.isEnabled
        ? <Menu.Item
            onClick={() => navigate(
              routes.batchCompaniesProgress || "./batch-companies-progress"
            )}
            key="batch-companies-progress">
          Avance general
        </Menu.Item>
          : null}
    </Menu>
  );
}

export default withRouter(Navbar);
