import gql from "graphql-tag";

export const updateTask = gql`
  mutation UpdateTask($data: TaskUpdateInput!, $id: ID!) {
    updateTask(
      data: $data,
      where: {
        id: $id
      }
    ){
      id
    }
  }
`;