import React from "react";
import { Progress, Typography } from "antd";

export const ProgressBar = ({ progressPercentage = 0 }) => {
  return (
    <div
      style={{
        gap: "30px",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        flexDirection: "row",
        marginBottom: "30px"
    }}>
      <Typography.Title
        level={4}
        style={{
          width: "20%",
          margin: "0px",
          color: "#704AED",
          minWidth: "240px",
          maxWidth: "240px"
      }}>
        Avance general global
      </Typography.Title>
      <Progress
        percent={progressPercentage}
        style={{
          width: "60%",
          minWidth: "240px"
      }} />
    </div>
  );
};
