import React from "react";
import { Modal, Form, Input, message } from "antd";
import { client } from "../../../../graphql";
import { admin } from "../../../graphql/admin";
import { useMutation } from "@apollo/react-hooks";

export function BatchObjectiveModal({onSave, onCancel, batchId, ...props}) {
  const [form] = Form.useForm();

  const [createBatchObjective] = useMutation(
    admin.mutations.createBatchObjective,
    {
      refetchQueries: [{
        query: admin.queries.getObjectivesByBatchId,
        variables: { id: batchId }
      }],
      client
    }
  );

  const onOk = async() => {
    const dismissLoading = message.loading("Guardando...");

    try {
      const values = await form.getFieldsValue();

      values.batch = { connect: { id: batchId } };

      const newObjective = await createBatchObjective({ variables: { data: values } });

      message.success("Objetivo creado.");

      onSave && onSave(newObjective);
      form.resetFields();
    }
    catch(e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    }
    finally {
      dismissLoading();
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  return (
    <Modal
      destroyOnClose
      okText="Guardar"
      cancelText="Cancelar"
      title="Nuevo objetivo"
      onOk={onOk}
      onCancel={onCancelModal}
      {...props}>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          name="name"
          label="Nombre del objetivo">
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descripción">
          <Input.TextArea rows={5} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
