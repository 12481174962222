import gql from "graphql-tag";

export const connectEntrepreneursToOde = gql`
  mutation ConnectEntrepreneursToOde($odeId: ID!, $data: ODEUpdateInput!) {
    updateODE(
      where: { id: $odeId }
      data: $data
    ) {
      id
    }
  }
`;
