import React, { useContext } from "react";
import { Collapse } from "antd";
import { PanelHeading } from "./panel-heading";
import {
  ObjectiveItemContextProvider
} from "../../../contexts/shared/objective-item";
import { Toolbar } from "./toolbar";
import { AchievementsList } from "../achievements-list";
import { Visibility } from "../visibility";
import { CurrentUserContext } from "../../../contexts/shared";

export function ObjectiveItem({ objective }) {
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <ObjectiveItemContextProvider objective={objective}>
      <Collapse style={{marginBottom: "20px"}}>
        <Collapse.Panel
          collapsible={objective.new}
          style={{backgroundColor: "#F8FBFF"}}
          header={
            <PanelHeading objective={objective} />
          }>
          <AchievementsList achievements={objective.achievements} />
          <Visibility
            visible={!["ENTREPRENEUR"]
            .includes(currentUser.systemRole)}>
            <Toolbar achievements={objective.achievements} />
          </Visibility>
        </Collapse.Panel>
      </Collapse>
    </ObjectiveItemContextProvider>
  );
}
