export const formatValues = (data, user) => {
  const { title, image, text, files } = data;
  return {
    title,
    image,
    body: text,
    documents: {
      create: files.map(file => ({
        fileName: file.name,
        url: file.response.imageUrl,
        type: file.type,
        uploadDate: new Date()
      }))
    },
    date: new Date(),
    createdBy: { connect: { id: user.id } }
  }
};