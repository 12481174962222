import gql from "graphql-tag";

export const getOdeAchievementsByAchievementIdAndOdeId = gql`
  query GetOdeContentByContentIdAndOdesId($achievementsIds: [ID!], $odeId: ID!){
    odeAchievements(
      where:{
        achievement: {id_in: $achievementsIds}
        ode: {id: $odeId}
      }
    ){
      id
      ode{
        id
      }
      achievement{
        id
      }
      isComplete
      finishDate
    }
  }
`;