import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { shared } from "../../../graphql/shared";
import { admin } from "../../../graphql/admin";
import { client } from "../../../../graphql/";
import { ContentModalContext } from "./context";
import { CurrentUserContext } from "../current-user";
import { ContentModal } from "./modal";
import { message } from "antd";
import { formatDuplicateValues } from "./format-values";

export function ContentModalContextProvider({
  children,
  batchId,
  isPublished,
  refetch
}) {
  const [state, setState] = useState({
    isModalOpen: false,
    editing: false,
    contentGroupId: undefined,
    contentGroup: {},
    contentId: undefined,
    content: undefined
  });

  const { currentUser } = useContext(CurrentUserContext);

  const [updateContentGroupMutation] = useMutation(
    shared.mutations.updateContentGroup, { client }
  );

  const [deleteContent] = useMutation(
    admin.mutations.deleteContent, { client }
  );

  const onSave = async data => {
    try {
      await updateContentGroupMutation({
        variables: {
          data,
          where: { id: state.contentGroupId }
        }
      });
      await refetch();
    } catch (e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
        en unos segundos.");
      console.error(e);
    }
  };

  const duplicateContent = async (content, contentGroupId) => {
    const dismissLoading = message.loading("Guardando...");
    const data = formatDuplicateValues(content, currentUser);
    try {
      await updateContentGroupMutation({
        variables: {
          data,
          where: { id: contentGroupId }
        }
      });
      await refetch();
    } catch (e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
        en unos segundos.");
      console.error(e);
    } finally {
      dismissLoading();
    }
  };

  const removeContent = async (contentId, contenGroupId) => {
    const dismissLoading = message.loading("Eliminando...");
    try {
      if(isPublished) {
        const data = {
          contents: {
            update: {
              where: { id: contentId },
              data: { shouldBeDeleted: true }
            }
          }
        };
        await updateContentGroupMutation({
          variables: {
            data,
            where: { id: contenGroupId }
          }
        });
        await refetch();
      } else {
        await deleteContent({ variables: { id: contentId } });
        await refetch();
      }
      message.success("Datos eliminados.");
    } catch (e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    } finally {
      dismissLoading();
    }
  };

  const openModal = (contentGroupId, contentGroup, contentId) => {
    setState(state => ({
      ...state,
      contentGroupId,
      contentGroup,
      contentId,
      editing: false,
      isModalOpen: true
    }));
  };

  const closeModal = () => {
    setState({
      isModalOpen: false,
      editing: false,
      contentId: undefined,
      contentGroupId: undefined,
      contentGroup: {}
    });
  };

  const openEditModal = (contentGroup, content) => {
    setState(state => ({
      ...state,
      isModalOpen: true,
      editing: true,
      contentId: content.id,
      content,
      contentGroupId: contentGroup.id,
      contentGroup
    }));
  };

  const injectActions = {
    openModal,
    openEditModal,
    closeModal,
    duplicateContent,
    removeContent
  };

  return (
    <ContentModalContext.Provider value={injectActions}>
      <ContentModal
        content={state.content}
        contentId={state.contentId}
        batchId={batchId}
        contentGroup={state.contentGroup}
        visible={state.isModalOpen}
        isPublished={isPublished}
        editing={state.editing}
        onSave={onSave}
        onCancel={closeModal} />
      { children }
    </ContentModalContext.Provider>
  );
}
