import React, { useEffect } from "react";
import { cloneDeep } from "lodash";
import {
  Modal,
  Form,
  Space,
  Typography,
  Tag, Divider
} from "antd";
import {Avatar} from "../../avatar";
import {IconLabel} from "../../icon-label";
import {MailOutlined, PhoneOutlined} from "@ant-design/icons";
import { AssignMentoringForm } from "./form";
import { Section } from "../../section";
import { useService } from "../../../../hooks/shared";
import { ExpertService } from "../../../../services/expert-service";

export function MentoringAssignModal({
  onOk, // should return true if you want to skip the modalClose
  afterOk,
  onCancel,
  afterCancel,
  selectedItem: mentor,
  ...props
  }) {
  const [form] = props.form ? [props.form] : Form.useForm();

  const { data: expertServices } = useService(ExpertService.getMentorings,
    {
      expertId: mentor?.id
    },
    {
      shouldFetch: !!mentor?.id
    });

  const { data: user } = useService(ExpertService.getById,
    {
      expertId: mentor?.id
    },
    {
      shouldFetch: !!mentor?.id
    });

  useEffect(() => {
    if (mentor) {
      form.setFieldsValue(cloneDeep(mentor));
    }
  }, [props.isVisible, mentor]);

  const handleOnCancel = async () => {
    form.resetFields();
    afterCancel && await afterCancel();
    onCancel();
  };

  const handleOnOk = async () => {
    await form.validateFields();

    const shouldInterrupt = await onOk(form.getFieldsValue());
    afterOk && await afterOk();
    if (shouldInterrupt) return;
    await handleOnCancel();
  };

  return (
    <Modal
      width={900}
      okText={"Asignar"}
      cancelText="Cancelar"
      onOk={handleOnOk}
      onCancel={() => handleOnCancel()}
      {...props}>
      <Space
        size={20}
        align="start"
        direction="horizontal">
        <Avatar size={60}>
          {mentor?.profilePicUrl || mentor?.fullName }
        </Avatar>
        <Space direction="vertical" size={5}>
          <Typography.Title
            level={4}
            style={{marginBottom: "0px"}}>
            Asignación de mentoría
          </Typography.Title>
          <Typography.Text
            strong
            level={3}
            style={{marginBottom: "0px"}}>
            {`Experto: ${mentor?.fullName}`}
          </Typography.Text>
          <Space
            style={{color: "#8C8C8C"}}
            direction="horizontal">
            <IconLabel icon={<PhoneOutlined />}>
              {mentor?.phone}
            </IconLabel>
            <IconLabel icon={<MailOutlined />}>
              {mentor?.email}
            </IconLabel>
          </Space>
          <Tag color="cyan">
            Mentoría
          </Tag>
          <Typography.Text style={{color: "rgba(0, 0, 0, 0.65)"}}>
            {`Costo: $${mentor?.price || 0}/hora`}
          </Typography.Text>
        </Space>
      </Space>
      <Divider />
      <Section>
        <AssignMentoringForm
          form={form}
          expert={user}
          expertServices={expertServices} />
      </Section>
    </Modal>
  );
}
