import { v4 as uuid } from "uuid";

export const handleOnAdd = stateSetter =>
  stateSetter({ visible: true });

export const handleOnEditItem = (stateSetter, item) =>
  stateSetter({ visible: true, item });

export const handleOnRemoveItem = (stateSetter, items, removed) =>
  stateSetter(items.filter(item => item !== removed));

export const handleOnSave = (
  modalState,
  modalStateSetter,
  globalStateSetter,
  items,
  newItem
) => {
  if (modalState.item) {
    const newItems = [...items];
    const itemIdx = newItems.findIndex(item => item.id === newItem.id);
    newItems[itemIdx] = newItem;
    globalStateSetter(newItems);
  } else {
    const item = { uuid: uuid(), ...newItem };
    globalStateSetter([...items, item]);
  }

  modalStateSetter({ visible: false });
};

export const handleOnCancel = stateSetter =>
  stateSetter({ visible: false });
