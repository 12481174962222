export function formatMonitoringReportFilters(filters = {}) {
  let where = null;

  // Old monitoring
  if (filters.where) {
    where = {
      status: filters.where.status,
      acceptedAt_gte: filters.where.fromAcceptedDate,
      acceptedAt_lte: filters.where.toAcceptedDate,
      assignedExpertService: {
        batch: { program: { id: filters.where.programId } },
        expertService: {
          category: filters.where.category,
          type: filters.where.type
        }
      }
    };

    // this restriction allows to get the services accesible
    // by the coordinator only
    if (filters.where.coordinatorId) {
      where.assignedExpertService.batch = {
        ...where.assignedExpertService.batch,
        users_some: {
          id: filters.where.coordinatorId,
          systemRole: "COORDINATOR"
        }
      };
    }

  } else {
    // New monitoring

    where = {
      status: filters.status,
      acceptedAt_gte: filters.fromAcceptedDate,
      acceptedAt_lte: filters.toAcceptedDate,
      assignedExpertService: {
        batch: { program: { id: filters.programId } },
        expertService: {
          category: filters.category,
          type: filters.serviceType
        }
      }
    };

    // this restriction allows to get the services accesible
    // by the coordinator only
    if (filters.coordinatorId) {
      where.assignedExpertService.batch = {
        ...where.assignedExpertService.batch,
        users_some: {
          id: filters.coordinatorId,
          systemRole: "COORDINATOR"
        }
      };
    }
  }

  return where;
}