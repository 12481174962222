import React, { useContext } from "react";
import { ContentModalContext } from "../../../../../contexts/shared/content-modal/context";
import { AddButton } from "../../../add-button";

export const AddContentButton = contentGroup => {

  const addButtonStyle = {
    width: "100%",
    color: "#704AED"
  };

  const { openModal } = useContext(ContentModalContext);

  return (
    <AddButton
      type="dashed"
      style={addButtonStyle}
      onClick={() => openModal(contentGroup.id, contentGroup)}>
      Agregar nuevo contenido
    </AddButton>
  );
};
