import React, { useContext, useState, useEffect } from "react";
import { Card, Space, Typography, Form } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  EnvironmentOutlined
} from "@ant-design/icons";
import { toFileList, withLoader } from "../../../../helpers";
import { userDecorator } from "../../../../decorators/admin/experts/show/user-decorator";
import { EditProfileContext } from "../../../../contexts/shared/edit-profile";
import { Avatar, EditableLabel, Format, IconLabel } from "../../../shared";
import { EditableField } from "./editable-field";

function SummarySection({ editable = false }) {

  const {
    data,
    saveChanges
  } = useContext(EditProfileContext);

  const [state, setState] = useState({
    profilePicUrl: undefined
  });

  const [form] = Form.useForm();
  const decoratedUser = Object.assign(userDecorator, data?.user);

  useEffect(() => {
    const contactFields = {
      profilePicUrl: data?.user?.profilePicUrl
    };

    setState(contactFields);

    form.setFieldsValue({
      profilePicUrl: data?.user?.profilePicUrl ?
        toFileList([{ url: data?.user?.profilePicUrl }])
        : undefined
    });
  }, [data]);

  const onSave = async values => {
    await saveChanges(values, "summary");
  };

  return (
    <Card
      bordered={false}
      style={{ borderBottom: "solid 1px #f4f4f4" }}>
      <EditableLabel
        editable={editable}
        name="profile"
        onSave={onSave}
        editableField={onChange =>
          <EditableField user={data?.user} onChange={onChange} />
        }>
        <Space
          direction="horizontal"
          size={20}
          align="start">
          <Space direction="vertical" align="center">
            <Avatar
              size={64}
              src={state.profilePicUrl}>
            </Avatar>
          </Space>
          <Space direction="vertical" size={5}>
            <Space direction="horizontal">
              <Typography.Title
                level={4}
                style={{ marginBottom: "0px" }}>
                {data?.user?.fullName || decoratedUser?.firstName}
              </Typography.Title>
            </Space>
            <Space direction="horizontal">
              <IconLabel icon={<MailOutlined />}>
                <Format type="secondary">
                  {decoratedUser.displayEmail}
                </Format>
              </IconLabel>
              <IconLabel icon={<PhoneOutlined />}>
                <Format type="secondary">
                  {decoratedUser.displayPhone}
                </Format>
              </IconLabel>
            </Space>
          </Space>
        </Space>
      </EditableLabel>
    </Card>
  );
}

export default withLoader(SummarySection);
