import React from "react";
import { Alert } from "antd";

export function AlertSection({ message = "", description = "", ...props }) {
  return (
    <Alert
      style={{ margin: "15px" }}
      message={<strong>{message}</strong>}
      description={description}
      type="error"
      {...props} />
  );
}
