import React from "react";
import { Menu } from "antd";
import { withRouter } from "../../../../../helpers";

function Navbar({navigate, selectedKeys, ...props}) {
  return (
    <Menu
      defaultOpenKeys={["profile"]}
      selectedKeys={selectedKeys}
      style={{ borderBottom: "none" }}
      mode="horizontal"
      {...props}>
      <Menu.Item
        onClick={() => navigate("./profile")}
        key="profile">
        Perfil
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate("./services")}
        key="services">
        Productos y servicios
      </Menu.Item>
    </Menu>
  );
}

export default withRouter(Navbar);
