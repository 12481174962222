import gql from "graphql-tag";

export const disconnectService = gql`
  mutation DisconnectService($batchId: ID!, $serviceId: ID!) {
    updateBatch(
      where: { id: $batchId }
      data: {
        services: {
          disconnect: { id: $serviceId }
        }
      }
    ) {
      id
    }
  }
`;
