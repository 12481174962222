import React, { useContext } from "react";
import { List } from "antd";
import { cardGridSizes } from "../../../../../../../../../../helpers";
import {
  ServicesLayerContext
} from "../../../../../../../../../../contexts/shared";
import { AssignedServiceListItem } from "./assigned-service-list-item";
import { useService } from "../../../../../../../../../../hooks/shared";

export function AssignedServices({ batch = null, company = null }) {
  const { CompanyService } = useContext(ServicesLayerContext);
  const shouldFetchServices = !!batch && !!company;

  const {
    data: assignedServices,
    loading: loadingAssigned,
    refetch: refetchAssigned
  } = useService(
    CompanyService.getAssignedServicesByBatch,
    {
      types: ["PRODUCT"],
      batchId: batch?.id,
      companyId: company?.id
    },
    { shouldFetch: shouldFetchServices }
  );

  const {
    data: scheduledServices,
    loading: loadingScheduled,
    refetch: refetchScheduled
  } = useService(
    CompanyService.getScheduledServicesByBatch,
    {
      types: ["PRODUCT"],
      batchId: batch?.id,
      companyId: company?.id
    },
    { shouldFetch: shouldFetchServices }
  );

  const refetchScheduledServices = () => {
    refetchScheduled();
    refetchAssigned();
  };

  const datasource = (assignedServices || [])
    .concat((scheduledServices || [])
      .map(ss => ({
        ...ss.assignedExpertService || {},
        scheduledExpertService: ss
      }))
    );

  return (
    <List
      loading={loadingAssigned || loadingScheduled}
      grid={cardGridSizes}
      itemLayout="horizontal"
      dataSource={datasource}
      renderItem={assignedService => (
        <List.Item>
          <AssignedServiceListItem
            assignedService={assignedService}
            refetchScheduledServices={refetchScheduledServices} />
        </List.Item>
      )} />
  );
}
