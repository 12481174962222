import gql from "graphql-tag";

export const getByIdQuery = gql`
  query GetScheduledExpertService($id: ID!) {
    scheduledExpertService(where: { id: $id }) {
      id
      links
      status
      duration
      serviceDate
      assignedExpertService {
        id
        limitDate
        limitPerDay
        assignedUnits
        availableUnits
        assignmentDate
        expertServiceData
      }
      attachments {
        id
        fileName
        url
        type
      }
      events(where: { event_in: ["X"] }){
        id
        event
        description
        createdAt
        blame{
          id
          fullName
          profilePicUrl
        }
      }
      comments{
        id
        author{
          id
          fullName
          profilePicUrl
        }
        comment
        type
        edited
        createdAt
        attachments{
          id
          fileName
          url
        }
      }
    }
  }
`;
