import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { MainMenu } from "../../../../../components/admin/shared";
import { SidebarLayout } from "../../../../../components/shared";
import { HeaderBreadcrumbs } from "../../../../../components/shared/batch-offer/header-breadcrumbs";
import { StepsNavigation } from "../../../../../components/admin/batches/new/steps-navigation";
import { MembersForm } from "../../../../../components/admin/batches/new/members";
import { HeaderTexts } from "../../../../../components/admin/batches/new/header-texts";
import { useQuery } from "@apollo/react-hooks";
import { admin } from "../../../../../graphql/admin";
import { client } from "../../../../../../graphql";
import { useParams } from "react-router";
import { BatchContentsContext } from "../../../../../contexts/admin/batches";
import { ControllerButtons } from "../../../../../components/admin/batches/new/controller-buttons";
import { useBatchMembers } from "../../../../../hooks/shared";

export const NewBatchMembersIndex = () => {
  const history = useHistory();
  const { id: idBatch } = useParams();

  const {
    data, error, loading, refetch
  } = useQuery(admin.queries.getBatchById, {
    client,
    variables: {
      id: idBatch
    }
  });

  const { connectMember, disconnectMember } = useBatchMembers();

  const onSubmit = async (idUser, type) => connectMember({
    idBatch,
    idUser,
    type,
    refetch
  });

  const onDelete = async (idUser, type) => disconnectMember({
    idBatch,
    idUser,
    type,
    refetch
  });

  const onNext = () => history.push("./content");
  const onPrev = () => history.push(`/v2/coordinator/batches/new/${idBatch}`);

  const injectActions = useMemo(() => ({
    data,
    error,
    loading,
    refetch,
    onSubmit,
    onDelete
  }), [data, loading]);

  const isNext = data?.batch?.leader && (data?.batch?.users?.length || 0) - 1;

  return (
    <BatchContentsContext.Provider value={injectActions}>
      <SidebarLayout menu={<MainMenu />}>
        <HeaderBreadcrumbs
          onPrev={onPrev}
          title="Batches"
          name="Integrantes" />
        <StepsNavigation current={1} />
        <div style={{ margin: "30px 13px" }}>
          <HeaderTexts
            title="Agrega a las personas involucradas a tu batch"
            description="Agrega al coordinador y el personal de apoyo." />
          <MembersForm />
        </div>
        <ControllerButtons
          disabled={!isNext}
          onPrev={onPrev}
          onNext={onNext} />
      </SidebarLayout>
    </BatchContentsContext.Provider>
  );
};
