import React from "react";
import { Form, Select, Input, Col, Row } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { AmountInput } from "./amount-input";
import { odeFundingSources } from "../../../../../helpers/select-options";
import { Visibility } from "../../../../../components/shared";

export function FundingListItem({
  funding,
  idx,
  removeItem,
  onChange,
  viewMode = false
}) {
  const [form] = Form.useForm();
  form.setFieldsValue(funding);

  return (
    <Row gutter={2} style={{ width: "100%" }}>
      <Visibility
        visible={!viewMode}>
        <Col span={1}>
          <CloseCircleOutlined onClick={removeItem(idx)} />
        </Col>
      </Visibility>
      <Col span={23}>
        <Form
          name={`funding-input-form-${idx}`}
          onValuesChange={(_, allValues) => onChange(allValues)}
          form={form}>
          <Form.Item
            name="source"
            label="Fuente">
            <Select
              disabled={viewMode}
              showSearch
              optionFilterProp="label"
              options={odeFundingSources}
              placeholder="Fuente"
              className="wide-input" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Nombre del fondo">
            <Input disabled={viewMode} placeholder="Nombre del fondo" />
          </Form.Item>
          <Form.Item
            name="receivedAmount"
            label="Monto obtenido">
            <AmountInput viewMode={viewMode} />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
