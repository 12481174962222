import { institutions } from "../../../../../utils/maps";

/**
 * @param {{ batch_id: string, Institución: string }[]} batchesRows
 */
export const formatBatchInstitutionsForImportation = (batchesRows = []) => {
  return batchesRows.map(({ batch_id, Institución }) => ({
    batchId: batch_id,
    institutionNames: Institución.split(",")
      .map(institution => getValueFromEnum(institution.trim()))
      // skip not found institutions in the enum
      .filter(institutionLetters => !!institutionLetters)
  }));
};

/**
 * @param {string} institutionName
 * @returns {string} the enum Value that corresponds to the institution
 */
const getValueFromEnum = institutionName => {
  const enumItem = institutions
    .find(institutionMapItem =>
      institutionMapItem.label.toLowerCase() === institutionName.toLowerCase());

  return enumItem?.value;
};
