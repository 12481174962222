import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Descriptions } from "antd";

import { FormContainer } from "../../../../../../components/shared";
import { withLoader } from "../../../../../../helpers";
import { NewExpertContext } from "../../../../../../contexts/experts/new/context";
import { fieldValidationMessages } from "../../../../../../utils/fieldValidationMessages";

const { Item } = Form;

export const FiscalForm = ({ editable = true }) => {
  const [state, setState] = useState({
    rfc: "N/A",
    businessName: "N/A"
  })
  const {
    updatePersonalInformation,
    loading,
    error,
    data,
    form
  } = useContext(NewExpertContext);

  useEffect(() => {
    const { user = {} } = data || {};
    const expertProfile = user?.expertProfile || {};
    setState({
      rfc: expertProfile?.rfc || "N/A",
      businessName: expertProfile?.businessName || "N/A"
    });

    form.setFieldsValue({ ...expertProfile });
  }, [data, loading, error]);

  const handleOnChange = (changedFields = []) => {
    const { name = [], value } = changedFields[0] || {};
    const fieldName = name[0];

    updatePersonalInformation({
      [fieldName]: value
    });
  };

  if (editable) {
    return (
      <FormContainer>
        <Form
          validateMessages={fieldValidationMessages}
          onFieldsChange={handleOnChange}
          layout="vertical"
          form={form}>
          <Item
            name="businessName"
            label="Razón social"
            rules={[{ required: true }]}>
            <Input />
          </Item>
          <Item
            name="rfc"
            label="RFC"
            rules={[{ required: true }]}>
            <Input />
          </Item>
        </Form>
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <Descriptions column={1}>
        <Descriptions.Item label="Razón social">
          {state.businessName}
        </Descriptions.Item>
        <Descriptions.Item label="RFC">
          {state.rfc}
        </Descriptions.Item>
      </Descriptions>
    </FormContainer>
  );
};

export default FiscalForm;
