import gql from "graphql-tag";

export const getBatches = gql`
  query GetBatches($iname: String, $programId: ID, $first: Int, $skip: Int) {
    batches(
      where: {
        iname_contains: $iname
        program: { id: $programId }
      },
      first: $first,
      skip: $skip
    ) {
      id    
      name
      iname
      batchID
      startDate
      finishDate
      description
      odes {
        name
        sector
        logoUrl
      }
      program {
        id
        name
        type
        coverPicUrl
      }
    }
    meta: batchesConnection(
      where: {
        iname_contains: $iname
        program: { id: $programId }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
