import React, { useEffect } from "react";
import { cloneDeep } from "lodash";
import { Modal, Form } from "antd";
import { AddMultimediaForm } from "./form";

export function AddCompanyMultimediaModal({
  onOk, // should return true if you want to skip the modalClose
  afterOk,
  onCancel,
  afterCancel,
  selectedItem,
  ...props
}) {
  const [form] = props.form ? [props.form] : Form.useForm();

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue(cloneDeep(selectedItem));
    }
  }, [props.isVisible, selectedItem]);

  const handleOnCancel = async () => {
    form.resetFields();
    afterCancel && await afterCancel();
    onCancel();
  };

  const handleOnOk = async () => {
    await form.validateFields();

    const shouldInterrupt = await onOk(form.getFieldsValue());
    afterOk && await afterOk();
    if (shouldInterrupt) return;
    await handleOnCancel();
  };
  return (
    <Modal
      okText={"Agregar"}
      cancelText="Cancelar"
      title="Archivos multimedia"
      onOk={handleOnOk}
      onCancel={() => handleOnCancel()}
      {...props}>
      <AddMultimediaForm form={form} />
    </Modal>
  );
}
