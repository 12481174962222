import React, { useContext } from "react";
import { Form, message } from "antd";
import { SidebarLayout, Section } from "../../../../components/shared";
import { StepsWrapper } from "../../../../components/expert/shared";
import { CurrentUserProvider } from "../../../../contexts/shared/current-user";
import { MenuContext } from "../../../../contexts/shared/menu";
import { ContactForm, LocationForm } from "../../../../components/admin/experts/new";
import { FiscalForm } from "../../../../components/shared/profile-forms";
import { EditProfileProvider, EditProfileContext } from "../../../../contexts/shared/edit-profile";
import { UserService } from "../../../../services/user-service";
import { withRouter } from "../../../../helpers/with-router";
import {
  formatCreateValues,
  formatUpdateValues
} from "./format-values";
import { MainMenu } from "../../../../components/coordinator/shared";
import { PageLeavePromptProvider } from "../../../../contexts/shared";

function NewExpertIndex({ match, navigate }) {
  const { setMenuState } = useContext(MenuContext);
  const expertId = match?.params?.id;

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/profiles"]
  });

  const forms = {
    fiscalForm: Form.useForm()[0],
    contactForm: Form.useForm()[0],
    locationForm: Form.useForm()[0]
  };

  const createNewExpert = async values => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      const userService = new UserService();
      const formatedValues = formatCreateValues(values);

      const { data } = await userService
        .createExpert(formatedValues);

      if (data?.createUser?.id) {
        navigate(`/v2/coordinator/experts/new/${data?.createUser?.id}/professional-information`);
        message.success("Se ha creado un nuevo usuario");
      } else {
        throw "The createExpert method didn't return the user id";
      }
    } catch (error) {
      message.error("Ocurrió un error");
      console.error(error);
    } finally {
      dismissLoader();
    }
  };

  const validateForms = async () => {
    try {
      await Promise.all(
        Object.values(forms).map(form => form.validateFields())
      );
    } catch (error) {
      message.warning("Campos requeridos faltantes");
      throw error;
    }
  };

  const onNext = saveChanges => async function(event) {
    event.preventDefault();
    await validateForms();

    try {
      const contactValues = forms.contactForm.getFieldsValue();
      const fiscalValues = forms.fiscalForm.getFieldsValue();
      const locationValues = forms.locationForm.getFieldsValue();

      locationValues.country = locationValues.country?.toString();
      contactValues.fullName = [
        contactValues.firstName,
        contactValues.middleName,
        contactValues.lastName
      ].filter(name => name !== undefined).join(" ");

      const values = {
        contact: contactValues,
        fiscal: fiscalValues,
        location: locationValues
      };

      if (expertId) {
        saveChanges && await saveChanges(formatUpdateValues(values));
        navigate(`/v2/coordinator/experts/new/${expertId}/professional-information`);
      } else {
        await createNewExpert(values);
      }
    } catch(error) {
      message.error("Ocurrió un error");
      console.error(error);
    }
  };

  return (
    <PageLeavePromptProvider>
      <EditProfileProvider userId={expertId}>
        <EditProfileContext.Consumer>
          {({ saveChanges, editing, loading }) => (
            <CurrentUserProvider>
              <SidebarLayout menu={<MainMenu />}>
                <StepsWrapper onNext={onNext(saveChanges, editing)}>
                  <Section
                    spaced
                    loading={loading}
                    title="Datos de contacto">
                    <ContactForm form={forms.contactForm} />
                  </Section>
                  <Section
                    spaced
                    loading={loading}
                    title="Ubicación">
                    <LocationForm form={forms.locationForm} />
                  </Section>
                  <Section
                    spaced
                    loading={loading}
                    title="Fiscal">
                    <FiscalForm
                      editable
                      form={forms.fiscalForm} />
                  </Section>
                </StepsWrapper>
              </SidebarLayout>
            </CurrentUserProvider>
          )}
        </EditProfileContext.Consumer>
      </EditProfileProvider>
    </PageLeavePromptProvider>
  );
}

export default withRouter(NewExpertIndex);
