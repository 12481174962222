import React from 'react';
import { Card, Typography } from "antd";

const Title = () => {
  return (
    <Card
      bordered={false}
      style={{ borderBottom: "solid 1px #f4f4f4" }}>
      <Typography.Title
        style={{ margin: "0px" }}
        level={4}>
        Mi perfil
      </Typography.Title>
    </Card>
  );
};

export default Title;
