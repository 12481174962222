import { client } from "../../../graphql";
import { shared } from "../../graphql/shared";
import { getCustomOdesContents } from "./get-custom-ode-contents";
import {
  getBatchContentsProgressBreakdown
} from "./get-batch-contents-progress-breakdown";
import {
  getBatchPublishedMasterContents
} from "./get-batch-published-contents";

const getMasterContent = async args => {
  const result = await client.query({
    query: shared.queries.getMasterContentByBatchId,
    fetchPolicy: "network-only",
    variables: {
      ...args
    }
  });

  return result?.data?.masterContents;
};

export const BatchContentService = {
  getMasterContent,
  getCustomOdesContents,
  getBatchPublishedMasterContents,
  getBatchContentsProgressBreakdown
};
