export const convertFromFileList = fileList => {

  const currentTime = new Date().toISOString();

  return fileList.map(file => (
    {
      fileName: file.name,
      url: file.response.imageUrl,
      type: file.type,
      uploadDate: currentTime
    }
  ));
};
