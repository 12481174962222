import React, { useContext } from "react";
import { List, Modal } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Avatar, IconLabel, ActionButton } from "../../../../../shared";
import { EditOdeContext } from "../../../../../../contexts/shared/edit-ode";

export function UserItem({ user = {} }) {
  const { disconnectOdeTeamMember } = useContext(EditOdeContext);

  const {
    fullName = "Sin nombre",
    email = "N/A",
    phone = "N/A",
    profilePicUrl
  } = user;

  const onDelete = () => {
    Modal.confirm({
      okText: "Remover",
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: "Estás a punto de remover un miembro del equipo\
        ¿Deseas continuar?",
      onOk: async () => {
        await disconnectOdeTeamMember(user.id);
      }
    });
  };

  return (
    <List.Item
      style={{position: "relative"}}
      actions={[
        <IconLabel
          key="ent-mail"
          icon={<MailOutlined />}>
          {email}
        </IconLabel>,
        <IconLabel
          key="ent-phone"
          icon={<PhoneOutlined />}>
          {phone}
        </IconLabel>
      ]}>
      <List.Item.Meta
        avatar={<Avatar>{profilePicUrl || fullName}</Avatar>}
        title={fullName} />
      <ActionButton
        style={{ position: "absolute", top: "15px", right: "0px" }}
        onDelete={onDelete}
        onDeleteText="Quitar" />
    </List.Item>
  );
}
