import React from "react";
import { Row, Col } from "antd";
import img from "../../../../assets/login-image-entrepreneurs.png";

export function LoginLayout({ children }) {
  const responsiveValues = {
    left: {
      xs: 24,
      sm: 24,
      md: 12,
      lg: 12,
      xl: 12,
      xxl: 12
    },
    right: {
      xs: 0,
      sm: 0,
      md: 12,
      lg: 12,
      xl: 12,
      xxl: 12
    }
  };

  const rightColStyles = {
    height: "100%",
    width: "100%",
    backgroundColor: "rgb(83, 93, 180)",
    backgroundImage: `url(${img})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  };

  const rowStyle = {
    height: "100vh",
    width: "100vw"
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={rowStyle}>
      <Col
        style={{ padding: "10pt" }}
        {...responsiveValues.left}>
        { children }
      </Col>
      <Col
        style={rightColStyles}
        {...responsiveValues.right}>
      </Col>
    </Row>
  );
}
