import React from "react";
import {
  ListItem, TagList
} from "../../../../../../../../../shared";
import { RightLabel } from "./right-label";
import { FooterButton } from "./footer-button";
import {
  getReadableValue,
  expertServiceTypes,
  expertServiceCategories
} from "../../../../../../../../../../helpers/select-options";
import { truncateText } from "../../../../../../../../../../helpers";

export function AssignedServiceListItem({
  assignedService = null,
  refetchScheduledServices
}) {
  // the expertServiceData is a snapshot of the service at assignment moment
  const expertService = assignedService?.expertServiceData || {};
  const { expert = {} } = assignedService?.expertService || {};
  const scheduledService = assignedService?.scheduledExpertService;

  const type = expertService.type
    && truncateText(
      getReadableValue(expertServiceTypes, expertService.type),
      15
    );
  const category = expertService.category
    && truncateText(
      getReadableValue(expertServiceCategories, expertService.category),
      15
    );

  return (
    <ListItem.CardListItem
      customOptionsComponent={<RightLabel
        status={scheduledService?.status || assignedService?.status}
        isScheduledService={!!scheduledService}
        availableUnits={assignedService.availableUnits} />}
      coverUrl={expertService.coverPicUrl}
      title={expertService.name}
      titleMaxSize={10}
      footerItems={[<FooterButton
        key="footer-btn"
        assignedService={assignedService}
        scheduledService={scheduledService}
        refetchScheduledServices={refetchScheduledServices} />]}
      extra={
        <TagList
          colors={["blue", "magenta"]}
          tags={[type, category].filter(tag => !!tag)} />
      }>
      {expert.fullName}
    </ListItem.CardListItem>
  );
}
