import React, { useContext, useEffect, useState } from "react";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { AppService } from "../../../services";
import { TermsAndConditionsModal } from "./modal";
import { CurrentUserContext } from "../../../contexts/shared";

export const TermsAndConditionsModalWrapper = ({ children }) => {

  const history = useHistory();
  const { currentUser, refetchCurrentUser } = useContext(CurrentUserContext);

  const [loading, setLoading] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const onSave = async accepted => {
    try {
      if(!accepted){
        return message.warning(
          "Tienes que aceptar los términos y condiciones para seguir navegando"
        );
      }
      setLoading(true);
      await AppService.acceptTermsAndConditions(currentUser.id);
      await refetchCurrentUser();
      setModalIsVisible(false);
      message.success("Gracias por aceptar nuestros términos y condiciones", 2);
    } catch (e) {
      console.error(e);
      message.error(
        "Ocurrió un error, intente de nuevo en unos segundos"
      );
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(
      currentUser?.id
      && currentUser?.acceptedTermsAndConditions !== undefined
    ) {
      const hasAcceptedTermsAndConditions =
        currentUser?.acceptedTermsAndConditions === true;

      if(hasAcceptedTermsAndConditions === false) {
        setModalIsVisible(true);
      }
    }

  }, [currentUser?.acceptedTermsAndConditions ,history.location?.pathname]);



  return(
    <>
      <TermsAndConditionsModal
        loading={loading}
        visible={modalIsVisible}
        onSave={onSave} />
      {children}
    </>
  );
};
