import React, { useCallback, useContext, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { shared } from "../../../../../../graphql/shared";
import { client } from "../../../../../../../graphql";
import { withRouter } from "../../../../../../helpers";
import {
  ContentWrapper,
  SidebarLayout
} from "../../../../../../components/shared";
import { entrepreneur } from "../../../../../../graphql/entrepreneur";
import { BatchOdesContext } from "../../../../../../contexts/admin/batches";
import {
  MenuContext,
  ContentViewSwitchProvider,
  ContentModalContextProvider,
  ContentGroupModalContextProvider,
  ContentDetailsModalContextProvider,
  DraggableListProvider
} from "../../../../../../contexts/shared";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import {
  EditCompanyProvider
} from "../../../../../../contexts/shared/edit-company";
import { cloneDeep } from "lodash";
import { useService } from "../../../../../../hooks/shared";
import { OdesService } from "../../../../../../services/odes-service";
import {
  Breadcrumbs
} from "../../../../../../components/shared/odes/show/batch-contents/breadcrumbs";
import {
  BatchContents
} from "../../../../../../components/shared/odes/show/batch-contents";
import { CompanyService } from "../../../../../../services";

function BatchContentsIndex() {
  const { id: odeId = "", batchId = "" } = useParams();
  const history = useHistory();

  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/odes"]
  });

  const { loading, data, refetch: odeRefetch } = useService(
    OdesService.getById,
    { id: odeId }
  );

  // @todo Remove this because it's not needed
  // when using the CompanyService.getPublishedContent service
  const { data: publishContentData } = useQuery(
    entrepreneur.queries.getPublishedContent,
    {
      client,
      variables: { odeId, batchId }
    }
  );

  // @todo Remove this because it's not needed
  // when using the CompanyService.getPublishedContent service
  const {
    data: customContentsData,
    loading: customContentsLoading,
    error: customContentsError,
    refetch: refetchCustomOdeContents
  } = useQuery(
    shared.queries.getCustomOdeContents,
    {
      fetchPolicy: "network-only",
      variables: { where: { ode: { id: odeId }, batch: { id: batchId } } },
      client
    }
  );

  const {
    data: odeContents,
    refetch: refetchOdeContents
  } = useService(
    CompanyService.getOdeContents,
    { odeId, batchId }
  );

  const {
    data: publishedContent,
    refetch: refetchPublishedContent,
    loading: loadingPublishedContent
  } = useService(
    CompanyService.getPublishedContent,
    { companyId: odeId, batchId },
    { shouldFetch: !!odeId && !!batchId }
  );

  const refetch = async () => {
    try {
      await refetchOdeContents();
      await refetchPublishedContent();
      await refetchCustomOdeContents();
    } catch (e) {
      console.error(e);
    }
  };

  // @todo Move this kind of logic to a service and a hook
  const [createCustomOdeContent] = useMutation(
    shared.mutations.createCustomOdeContent, { client }
  );

  // @todo Move this kind of logic to a service and a hook
  const [updateCustomOdeContent] = useMutation(
    shared.mutations.updateCustomOdeContent, { client }
  );

  // @todo Move this kind of logic to a service and a hook
  const [updateContentGroup] = useMutation(
    shared.mutations.updateContentGroup, { client }
  );

  const onUpdateCustomOdeContent = useCallback(async create => {
    if (customContentsData?.customOdeContents?.[0]) {
      await updateCustomOdeContent({
        variables: {
          where: { id: customContentsData?.customOdeContents?.[0]?.id },
          data: { contentGroups: { create } }
        }
      });
    } else {
      await createCustomOdeContent({
        variables: {
          data: {
            contentGroups: { create },
            batch: { connect: { id: batchId } },
            ode: { connect: { id: odeId } }
          }
        }
      });
    }
    await refetch();
  }, [customContentsData]);

  const onUpdateContentGroup = useCallback(async (groupId, values) => {
    await updateContentGroup({
      variables: { where: { id: groupId }, data: values }
    });
    await refetch();
  }, []);

  const getBatchNameFromOdeData = data => {
    const batches = data?.batches || [];
    const batch = batches.filter(batch => batch.id === batchId);
    return batch[0]?.name || "";
  };

  const injectActions = useMemo(() => ({
    data: customContentsData,
    published: publishContentData,
    error: customContentsError,
    loading: customContentsLoading,
    batchId,
    refetch
  }), [customContentsData, customContentsLoading, publishContentData]);

  return (
    <EditCompanyProvider
      refetch={odeRefetch}
      data={data ? cloneDeep(data) : null}>
      <BatchOdesContext.Provider value={injectActions}>
        <SidebarLayout>
          <Breadcrumbs
            odeName={data?.name}
            batchName={getBatchNameFromOdeData(data)}
            history={history} />
          <DraggableListProvider>
            <ContentWrapper>
              <ContentGroupModalContextProvider
                onSave={onUpdateCustomOdeContent}
                onUpdate={onUpdateContentGroup}>
                <ContentModalContextProvider
                  batchId={batchId}
                  refetch={refetch}>
                  <ContentDetailsModalContextProvider
                    batchId={batchId}
                    odeId={odeId}
                    refetch={refetch}>
                    <ContentViewSwitchProvider>
                      <BatchContents
                        odeContents={odeContents}
                        ode={data}
                        data={data}
                        loading={loadingPublishedContent || loading}
                        allowEdit={false}
                        publishedContent={publishedContent} />
                    </ContentViewSwitchProvider>
                  </ContentDetailsModalContextProvider>
                </ContentModalContextProvider>
              </ContentGroupModalContextProvider>
            </ContentWrapper>
          </DraggableListProvider>
        </SidebarLayout>
      </BatchOdesContext.Provider>
    </EditCompanyProvider>
  );
}

export default withRouter(BatchContentsIndex);
