import React, { useContext, useEffect } from "react";
import { Form } from "antd";
import { cloneDeep } from "lodash";
import { FundingInput } from "./funding-input";
import { genericFieldValidationMsgs } from "../../../../../utils";
import { ReportFormModalContext } from "../../../../../contexts/shared";

export function FundingsForm() {
  const {
    updateChanges,
    report,
    viewMode
  } = useContext(ReportFormModalContext);

  const [form] = Form.useForm();

  useEffect(() => {
    const data = cloneDeep(report);
    form.setFieldsValue(data);
  }, []);

  const onFormChange = (_, values) => {
    updateChanges(values);
  };

  return (
    <Form
      onValuesChange={onFormChange}
      name="fundings-form"
      layout="vertical"
      form={form}
      validateMessages={genericFieldValidationMsgs}>
      <Form.Item
        name="fundings">
        <FundingInput viewMode={viewMode} />
      </Form.Item>
    </Form>
  );
}
