import React, { useState } from "react";
import { CloneBatchModalContext } from "./context";
import { CloneBatchModal } from "./modal";

export function CloneBatchModalProvider({ children, refetch }) {
  const [state, setState] = useState({
    isModalOpen: false,
    batch: false
  });

  const openModal = batch => {
    setState({
      isModalOpen: true,
      batch
    });
  };

  const closeModal = () => {
    setState({
      isModalOpen: false,
      batch: false
    });
  };

  const onSave = async () => {
    closeModal();
    refetch && await refetch();
  };

  return (
    <CloneBatchModalContext.Provider value={{openModal, closeModal}}>
      <CloneBatchModal
        visible={state.isModalOpen}
        batch={state.batch}
        onCancel={closeModal}
        onSave={onSave} />
      {children}
    </CloneBatchModalContext.Provider>
  );
}
