import React from "react";
import { Typography } from "antd";
import {
  FacebookFilled,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined
} from "@ant-design/icons";

export function SocialLinksSection({ organization }) {
  return (
    <>
      {organization?.facebook &&
        <Typography.Link
          type="secondary"
          href={`https://${organization?.facebook}`}
          target="_blank">
          <FacebookFilled />
        </Typography.Link>}
      {organization?.twitter &&
        <Typography.Link
          type="secondary"
          href={`https://${organization?.twitter}`}
          target="_blank">
          <TwitterOutlined />
        </Typography.Link>}
      {organization?.instagram &&
        <Typography.Link
          type="secondary"
          href={`https://${organization?.instagram}`}
          target="_blank">
          <InstagramOutlined />
        </Typography.Link>}
      {organization?.linkedin &&
        <Typography.Link
          type="secondary"
          href={`https://${organization?.linkedin}`}
          target="_blank">
          <LinkedinOutlined />
        </Typography.Link>}
    </>
  );
}