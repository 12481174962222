import React, { useState } from "react";
import { Form, message } from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import { CompanyMilestonesModal } from "./modal";
import { CompanyMilestonesModalContext } from ".";
import { useCompany } from "../../../hooks";
import { validateUrl } from "../../../helpers/validate-url";

const initialState = {
  isOpen: false,
  type: "none",
  milestoneId: undefined
};

export function CompanyMilestonesModalProvider({ children, refetch }) {
  const { id = "" } = useParams();
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const { createMilestone, updateMilestone, deleteMilestone } = useCompany();

  const [form] = Form.useForm();

  const onCreateOpen = type => {
    setState(prev => ({
      ...prev,
      isOpen: true,
      type
    }));
  };

  const onCloseModal = () => {
    setState(initialState);
  };

  const onOk = async () => {
    await form.validateFields();
    setLoading(true);
    const values = form.getFieldsValue();
    values.date = new Date(values.date).toISOString();
    values.milestoneType = state.type;

    const afterSuccess = async () => {
      await refetch();
      onCancel();
    };

    if(state.type === "NEWS") {
      const validatedUrl = validateUrl(values.link);
      if(validatedUrl === null) {
        message.error("El enlace introducido no es válido");
        setLoading(false);
        return;
      }
    }

    if(state.milestoneId) {
      await updateMilestone({
        data: values,
        odeId: id,
        milestoneId: state.milestoneId,
        afterSuccess
      });
    } else {
      await createMilestone({
        data: values,
        odeId: id,
        afterSuccess
      });
    }
    setLoading(false);
  };

  const onEdit = async (type, milestoneId, milestoneData) => {
    form.setFieldsValue({...milestoneData, date: moment(milestoneData.date) });
    setState(prev => ({
      ...prev,
      isOpen: true,
      type,
      milestoneId,
      milestoneData
    }));
  };

  const onDelete = async milestoneId => {
    await deleteMilestone({
      milestoneId,
      odeId: id,
      afterSuccess: async () => {
        await refetch();
    }});
  };

  const onCancel = () => {
    form.resetFields();
    onCloseModal();
  };

  const injectActions = {
    onCreateOpen,
    onEdit,
    onDelete
  };

  return (
    <CompanyMilestonesModalContext.Provider value={injectActions}>
      <CompanyMilestonesModal
        form={form}
        open={state.isOpen}
        loading={loading}
        onOk={onOk}
        onCancel={onCancel}
        type={state.type} />
      {children}
    </CompanyMilestonesModalContext.Provider>
  );
}