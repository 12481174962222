import gql from "graphql-tag";

export const createInvitedUser = gql`
  mutation CreateInvitedUser($data: InvitedUserCreateInput!) {
    createInvitedUser(
      data: $data
    ) {
      id
    }
  }
`;
