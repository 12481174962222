import React from "react";
import { List } from "antd";
import { CardListLayout } from "../card-list-layout";
import { useService } from "../../../../hooks/shared";
import { BatchMentorService } from "../../../../services";
import { CompanyMentoringCard } from "../assignation-card";
import { Loader } from "../../loader";

export function AvailableMentors({ batchId = "", odeId = "" }) {
  const { data, loading, error } = useService(
    BatchMentorService.getAvailableForOde,
    { batchId, odeId }
  );

  return (
    <Loader
      data={data}
      loading={loading}
      error={error}>
      <CardListLayout title={"Mentorías disponibles"}>
        <List
          style={{width: "100%"}}
          grid={{
            gutter: 16,
            xl: 3,
            xxl: 4
          }}
          dataSource={data || []}
          renderItem={item => (
            <List.Item>
              <CompanyMentoringCard
                mentor={item} />
            </List.Item>
          )} />
      </CardListLayout>
    </Loader>
  );
}
