import gql from "graphql-tag";

export const getAvailableMentorsForOde = gql`
  query GetBatchServices($batchId: ID!, $odeId: ID! ) {
    users(
      where: {
        systemRole: ADVISER
        batches_some: {
          id: $batchId,
          odes_some: {
            id: $odeId
          }
        }
      }
    ) {
      id
      fullName
      profilePicUrl
      phone
      school
      career
      email
      expertProfile {
        id
        resume
        businessName
        rfc
        website
        facebook
        twitter
        instagram
        linkedin
        otherUrl
        private
        professionalExperience {
          id
          areas
          skills
          industries
        }
      }
      expertProfile {
        id
        resume
      }
    }
  }
`;
