import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import {
  useOrganizationsCharts
} from "../../../../../hooks/organizations/charts/use-organizations-charts";
import { Card, Typography } from "antd";
import { FlexContainer } from "../../../../shared";
import { sortDatasetsData, generateSortingGuide, OPTIONS } from "./helpers";
import { InfoCircleOutlined } from "@ant-design/icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const ServiceByStageChart = ({ organizations }) => {

  const { datasets, labels } = useOrganizationsCharts(organizations);

  const sortingGuide = generateSortingGuide(datasets, labels);
  const sortedLabels = sortingGuide.map(sum => sum.label);

  const data = {
    labels: sortedLabels,
    datasets: sortDatasetsData(datasets, sortingGuide)
  };

  return (
    <Card
      style={{ marginTop: "2rem" }}
      title={
        <FlexContainer
          direction="vertical">
          <Typography.Title
            level={5}>
            Servicios por etapa
          </Typography.Title>
          <Typography.Text
            type={"secondary"}
            style={{ fontSize: "12px" }}>
            <InfoCircleOutlined />  Haz hover en una barra para ver el numero de servicios por etapa
          </Typography.Text>
        </FlexContainer>
      }
    >
      <FlexContainer direction="vertical" style={{height: "700px"}}>
        <Typography.Text strong style={{marginLeft: "5rem"}}>
          Servicios
        </Typography.Text>
        <div style={{height: "670px", width: "100%"}}>
          <Bar
            data={data}
            type={"bar"}
            height={800}
            options={OPTIONS} />
        </div>
        <Typography.Text strong style={{alignSelf: "center"}}>
          Etapas
        </Typography.Text>
      </FlexContainer>
    </Card>
  );
};
