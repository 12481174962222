import React, { useContext } from "react";
import { Table } from "antd";
import {
  getReadableValue,
  expertServiceTypes
} from "../../../../../../../helpers/select-options";
import {
  PaginationContext,
  ServiceDetailModalContext
} from "../../../../../../../contexts/shared";
import { Format, ServiceCost } from "../../../../../../shared";
import { StatusTag, DoubleScrollbarTable } from "../../../../../../../components/shared";
import { paginationConfig } from "../../../../../../../helpers";

export function MonitoringServicesTable({
  loading,
  count = 0,
  monitoringExpertServices = [],
  currentPage,
  onChangePage
}) {
  const { openModal } = useContext(ServiceDetailModalContext);
  const { onPaginationChange } = useContext(PaginationContext);

  const handleOnRowClick = scheduledExpertService => {
    let data = {};

    // The assigned service is passed as an scheduled service,
    // so if the scheduled and assigned ids are identicall, it means
    // it is actually an assigned service
    const isAssignedExpertService =
      scheduledExpertService.assignedExpertService?.id
      === scheduledExpertService.id;

    if (isAssignedExpertService) {
      data = {
        assignedExpertService: scheduledExpertService.assignedExpertService
      };
    } else {
      data = { scheduledExpertService };
    }

    return ({
      onClick: () =>  openModal(data),
      className: "clickable"
    });
  };

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange("monitoring")(page, pageSize);
  };

  return (
    <DoubleScrollbarTable
      style={{ width: "100%" }}
      loading={loading}
      rowKey={(row, index) => row?.id || index}
      dataSource={monitoringExpertServices}
      onRow={handleOnRowClick}
      pagination={{
        ...paginationConfig,
        current: currentPage,
        defaultPageSize: 100,
        onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
        total: count
      }}>
      <Table.Column
        dataIndex="assignedExpertService"
        title="Compañía"
        render={({ ode, expertServiceData }) =>
        expertServiceData.type === "WORKSHOP" ? null : ode?.name
        } />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Programa"
        render={({ batch }) => batch?.program?.name} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Batch"
        render={({ batch }) => batch?.name} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Producto"
        render={({ expertServiceData }) => expertServiceData?.name} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Experto"
        render={assignedExpertService =>
          assignedExpertService?.expertServiceData?.expert?.fullName} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Tipo de producto"
        render={({ expertServiceData }) =>
          getReadableValue(expertServiceTypes, expertServiceData?.type) } />
      <Table.Column
        align="center"
        dataIndex="duration"
        title="Cantidad"
        render={(duration, { assignedExpertService: { expertServiceData } }) =>
        expertServiceData?.method === "HOURS" ?
            `${duration} horas` : "1 servicio"} />
      <Table.Column
        align="center"
        dataIndex="duration"
        title="Costo total"
        render={(duration, { assignedExpertService }) =>
          <ServiceCost
            assignedExpertService={assignedExpertService}
            duration={duration} /> } />
      <Table.Column
        align="center"
        dataIndex="assignedExpertService"
        title="Fecha de asignación"
        render={({ assignmentDate }) =>
          <Format type="date">{assignmentDate}</Format>
        } />
      <Table.Column
        align="center"
        dataIndex="acceptedAt"
        title="Fecha de aceptación"
        render={acceptedAt =>
          <Format type="date">{ acceptedAt }</Format>
        } />
      <Table.Column
        align="center"
        dataIndex="assignedExpertService"
        title="Fecha límite de consumo"
        render={({ limitDate }) =>
          <span className="bold">
            <Format type="date">{limitDate}</Format>
          </span>
        } />
      <Table.Column
        align="center"
        title="Estado"
        render={({ assignedExpertService, status, id }) => {
          const isAssignedService = id === assignedExpertService.id;

          let maskedStatus = status;

          if (isAssignedService) {
            maskedStatus = status === "CANCELLED"
              ? "CANCELLED_ASSIGNATION"
              : status;
          }

         return (<StatusTag status={maskedStatus} />);
        }} />
    </DoubleScrollbarTable>
  );
}
