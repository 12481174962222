import gql from "graphql-tag";

export const getExpertById = gql`
  query GetExpertById($id: ID!) {
    user (
      where: { id: $id }
    ) {
      id
    	fullName
      firstName
      fullName
      middleName
      lastName
      email
      systemRole
      userID
      phone
      gender
      birthday
      profilePicUrl
      hasTecRelationship
      tecRelationship
      tecRelationships{
        id
        campus
        careerName
        payroll
        enrollment
        type
        grade
        graduation
      }
      category
      enrollment
      payroll
      careerName
      campus
      specialtyArea
      occupation
      companies
      country
      state
      city
      expertProfile {
        id
        resume
        businessName
        rfc
        website
        facebook
        twitter
        instagram
        linkedin
        otherUrl
        private
        privateBatches {
          id
          name
        }
        location {
          id
          city
          state
          country
        }
        professionalExperience {
          id
          curriculumVitae {
            id
            url
            type
            fileName
          }
          areas
          skills
          industries
          education {
            id
            onGoing
          	graduationDate
            degree
            specialityArea
            institution
          }
          courses {
            id
            name
            startDate
            endDate
            endorsingInstitution
          }
          papers {
            id
            type
            title
            institutionName
            publishedDate
          }
          volunteerings {
            id
          	name
            date
            description
          }
          entrepreneurships {
            id
            name
          	startDate
            endDate
            achievements
            position
          }
          languages {
            id
            level
            name
            canDeliverServices
          }
        }
      }
    }
  }
`;
