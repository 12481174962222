import React, { useContext } from "react";
import { Form } from "antd";
import { Section, Visibility } from "../../..";
import { ActionButton } from "../../action-button";
import { SocialNetworksForm } from "../form";
import {
  EditOdeContext
} from "../../../../../contexts/shared/edit-ode";

export function SocialNetworksEditSection({ editable = false }) {
  const {
    saveChanges,
    editing,
    setEditing
  } = useContext(EditOdeContext);

  const sectionName = "socialNetworks";

  const onSave = () => {
    const values = form.getFieldsValue();
    saveChanges(values, sectionName);
  };

  const [form] = Form.useForm();

  return (
    <Section
      spaced
      title="Redes sociales"
      extra={
        <Visibility visible={editable}>
          <ActionButton
            editing={editing[sectionName]}
            setEditing={setEditing}
            formName={sectionName}
            visible
            onSave={onSave} />
        </Visibility>}>
      <SocialNetworksForm
        form={form}
        editable={editing[sectionName] && editable} />
    </Section>
  );
}
