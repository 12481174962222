import gql from "graphql-tag";

export const restoreCompletedAchievement = gql`
  mutation RestoreCompletedAchievement(
    $id: ID
  ){
    deleteOdeAchievement(
      where: {
        id: $id
      }
    ){
      id
    }
  }
`;