import React from "react";
import { EmptyImage } from "../empty-image";

export function Image({src, style = {}, text, ...props}) {
  if(!src) {
    return <EmptyImage text={text} />;
  }

  style = {
    width: "100%",
    height: "160px",
    background: `url(${src})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    ...style
  };

  return (
    <div style={style} {...props}></div>
  );
}
