import React from "react";
import { withLoader } from "../../../../../helpers";
import {
  MiPitchEditSection
} from "../../../ode-forms/pitch/section";

function Pitch({ editable = false }) {
  return(
    <>
      <MiPitchEditSection editable={editable} />
    </>
  );
}

export default withLoader(Pitch);