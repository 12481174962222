import gql from "graphql-tag";

export const getScheduledServicesByEntrepreneurId = gql`
  fragment assignedExpertServiceFragment on AssignedExpertService {
    id
    status
    finished
    availableUnits
    assignedUnits
    assignmentNumber
    assignationIdentifier
    expertServicePrice
    expertServiceData
    expertService {
      id
      name
      type
      price
      method
      category
      expert {
        id
        fullName
        firstName
        middleName
        lastName
        email
        phone
      }
    }
    batch {
      id
      name
      coordinators: users(where: { systemRole_in: [COORDINATOR, SUPER_ADMIN] }) {
        id
        fullName
        phone
        email
      }
      program {
        id
        name
        coordinator {
          id
          fullName
          phone
          email
        }
      }
    }
    ode {
      id
      name
    }
  }

  query GetScheduledServicesByEntrepreneurId(
    $searchString: String
    $status: [String!]
  	$entrepreneurId: ID!
    $first: Int
    $category: String
    $programId: ID
    $type: String
  ) {
    scheduledExpertServices(
      first: $first,
      orderBy: updatedAt_DESC
      where: {
        status_in: $status,
        assignedExpertService: {
          expertService: { type: $type }
          batch: { program: { id: $programId } }
          ode: {
            team_some: {
              id: $entrepreneurId
            }
          }
          OR: [
            {
              expertService: {
                category: $category
                OR: [
                  { iname_contains: $searchString },
                  { idescription_contains: $searchString },
                  { expert: { ifullName_contains: $searchString } }
                ]
              }
            },
            {
              batch: { program: { iname_contains: $searchString } }
            },
            {
              batch: { iname_contains: $searchString }
            },
            {
              ode: { iname_contains: $searchString }
            }
          ]
        }
      }
    ) {
      id
      serviceDate
      status
      duration
      expertComments
      acceptedAt
      deliveredAt
      links
      attachments {
        id
        fileName
        type
        url
      }
      cancelledBy
      requestedBy {
        id
        fullName
        email
        phone
      }
      assignedExpertService {
        ...assignedExpertServiceFragment
      }
    }
  }
`;
