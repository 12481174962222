import gql from "graphql-tag";

export const getExpertsByCoordinatorId = gql`
  query GetExpertsByCoordinatorId ($id: ID!, $ifullName: String) {
    users(
      where: {
        ifullName_contains: $ifullName
        systemRole: ADVISER,
        OR: [
          { organisms_some: { coordinator: { id: $id } } },
          {
            odes_some: {
              ode_some: {
                batches_some: {
                  users_some: {
                    id: $id,
                    systemRole: COORDINATOR
                  }
                }
              } 
            }
          },
          {
            odes_some: {
              ode_some: {
                batches_some: {
                  program: {
                    coordinator: {
                      id: $id
                    }
                  }
                }
              } 
            }
          },
          {
            odes_some: {
              ode_some: {
                organisms_some: {
                  coordinator: {
                    id: $id
                  }
                }
              } 
            }
          },
          {
            odes_some: {
              ode_some: {
                OR: [
                  {
                    team_some: {
                      id: $id,
                      systemRole: COORDINATOR
                    },
                    network_some: {
                      id: $id,
                      systemRole: COORDINATOR
                    }
                  }
                ]
              }
            }
          }
        ]
      }) {
      id
      fullName
      email
      phone
      profilePicUrl
      expertProfile {
        id
        resume
      }
    }
  }
`;
