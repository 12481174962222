import gql from "graphql-tag";

export const getEntrepreneurs = gql`
  query GetEntrepreneurs($where: UserWhereInput) {
    users(where: $where){
      id
      fullName
    }
  }
`;
