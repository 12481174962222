import React, { useState, useEffect } from "react";
import axios from "axios";
import ImgCrop from "antd-img-crop";
import { Visibility } from "../visibility";
import { Upload, Button, message } from "antd";
import { FlexContainer } from "../flex-container";
import { UploadOutlined } from "@ant-design/icons";
import { remove as removeAccents } from "remove-accents";

function UploadButton({
  children,
  onChange,
  maxFiles,
  ...props}) {
  const [state, setState] = useState({
    fileList: [],
    currentUrl: ""
  });

  useEffect(() => {
    if (props.value) {
      setState(prevState => ({ ...prevState, fileList: props.value }));
    }
  }, [props.value]);

  const onUploadChange = info => {
    if(maxFiles && maxFiles > 0) {
      info.fileList = info.fileList.slice(-maxFiles);
    }

    setState(prevState =>  ({...prevState, fileList: [...info.fileList] }));

    const response = { imageUrl: state.currentUrl };

    if(info.file.status === "done") {
      info.file = { ...info.file, response };
      info.fileList = info.fileList?.map(fileItem => (
        {
          ...fileItem, response
        }));
      onChange && onChange({ ...info, url: state.currentUrl });
    }
    else if(info.file.status === "error") {
      message.error("Ha ocurrido un error al subir el archivo, inténtalo de\
      nuevo en unos segundos.");
    }
  };

  const getDataParams = async file => {
    try {
      const { data } = await axios
        .get(`${process.env.REACT_APP_ASSETS_PRESIGNED}?file=${removeAccents(file.name)}`);
      const { fields, upload: url } = data;

      setState(prevState => ({
        ...prevState,
        currentUrl: url
      }));

      return fields;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Upload
      data={getDataParams}
      onChange={onUploadChange}
      fileList={state.fileList}
      action={process.env.REACT_APP_ASSETS_AWS}
      {...props}>
      <Visibility visible={props.listType === "picture-card"}>
        <UploadOutlined /> {children}
      </Visibility>
      <Visibility visible={props.listType === "custom"}>
        {children}
      </Visibility>
      <Visibility visible={props.listType !== "picture-card" && props.listType !== "custom"}>
        <Button type="default">
          <UploadOutlined /> {children}
        </Button>
      </Visibility>
    </Upload>
  );
}

/**
 * @param {object} props
 * @param {number} props.aspect
 * @param {*} props.children
 * @param {"start" | "center" | "end"} props.align "start" | "center" | "end"
 */
UploadButton.Crop = function({aspect, children, align = "start", ...props}) {
  return (
    <FlexContainer direction="horizontal" xAlign={align}>
      <ImgCrop aspect={aspect} modalTitle="Editar imagen">
        <UploadButton {...props}>{children}</UploadButton>
      </ImgCrop>
    </FlexContainer>
  );
};

export default UploadButton;
