import React, { useEffect } from "react";
import { Form, Select, Input, Col, Row } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  contestPosition,
  prizeType
} from "../../../../../helpers/select-options";
import { Visibility } from "../../../../../components/shared";

export function ContestListItem({
  contest,
  idx,
  removeItem,
  onChange,
  viewMode = false
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(contest);
  }, [contest]);

  return (
    <Row gutter={2} style={{ width: "100%" }}>
      <Visibility
        visible={!viewMode}>
        <Col span={1}>
          <CloseCircleOutlined onClick={removeItem(idx)} />
        </Col>
      </Visibility>
      <Col span={23}>
        <Form
          name={`contest_input_form_${idx}`}
          onValuesChange={(_, allValues) => onChange(allValues)}
          form={form}>
          <Form.Item
            name="name"
            label="Nombre de la competencia">
            <Input disabled={viewMode} placeholder="Nombre de la competencia" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Descripción de la competencia">
            <Input.TextArea
              disabled={viewMode}
              placeholder="Descripción de la competencia"
              rows={3} />
          </Form.Item>
          <Form.Item
            name="socialProblemsFocus"
            label="Competencia enfocada en problemas sociales">
            <Select
              disabled={viewMode}
              className="wide-input"
              placeholder="Selecciona una opción"
              options={[
                { label: "Sí", value: true },
                { label: "No", value: false }
              ]} />
          </Form.Item>
          <Form.Item
            name="positionAchieved"
            label="Lugar obtenido">
            <Select
              disabled={viewMode}
              className="wide-input"
              placeholder="Selecciona una opción"
              options={contestPosition} />
          </Form.Item>
          <Form.Item
            name="prizeType"
            label="Premio obtenido">
            <Select
              disabled={viewMode}
              className="wide-input"
              placeholder="Selecciona una opción"
              options={prizeType} />
          </Form.Item>
          <Form.Item
            name="prizeDescription"
            label="Describe el premio">
            <Input.TextArea
              disabled={viewMode}
              placeholder="Describe el premio"
              rows={3} />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
