import { admin } from "../../../graphql/admin";

export async function createExpert(data) {
  return await this.client.mutate({
    mutation: admin.mutations.createUser,
    variables: {
      data: {
        ...data,
        systemRole: "ADVISER"
      }
    }
  });
}
