import React from "react";
import { Button, Card, Popover, Space, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const ChartCard = ({ style, title, chartInfo, className, children }) => (
  <Card
    style={style}
    title={
      <Space size="medium">
        <Typography.Title level={5} style={{ margin: 0, fontSize: "13.5px" }}>
          {title}
        </Typography.Title>
        <Popover
          overlayStyle={{ width: "300px"}}
          content={chartInfo}>
          <Button
            primary
            type="text"
            size="small"
            shape="circle"
            icon={<ExclamationCircleOutlined />} />
        </Popover>
      </Space>
    }
    bodyStyle={{ padding: "0 1rem" }}
    className={className}>
    {children}
  </Card>
);