import React, { useContext, useEffect } from "react";
import { Form, Button, Select } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { UploadButton, FormContainer, InputTooltip } from "../../../";
import { fieldValidationMessages } from "../../../../../utils";
import { toFileList } from "../../../../../helpers";
import {
  EditProfileContext
} from "../../../../../contexts/shared/edit-profile";
const { Item } = Form;

export const WorkExperienceForm = ({ editable = false, form }) => {
  const { data } = useContext(EditProfileContext);

  const inputRules = editable && [{ required: true }];
  const expertProfile = data?.user?.expertProfile;
  const cv = expertProfile?.professionalExperience?.curriculumVitae;

  useEffect(() => {
    const {
      curriculumVitae,
      ...values
    } = expertProfile?.professionalExperience || {};

    if (curriculumVitae) {
      values.curriculumVitae = toFileList([curriculumVitae]);
    }

    form.setFieldsValue(values);
  }, [data]);

  const getValueFromEvent = e => {
    return e.fileList;
  };

  return (
    <FormContainer>
      <Form
        form={form}
        validateMessages={fieldValidationMessages}
        layout="vertical">
        <Item
          getValueFromEvent={getValueFromEvent}
          name="curriculumVitae"
          label="Curriculum Vitae"
          rules={inputRules}>
          { editable ? (
            <UploadButton maxFiles={1}>
              Subir CV
            </UploadButton>
            )
            : (
              <Button
                href={cv?.url}
                target="_blank"
                type="primary"
                icon={<DownloadOutlined />}>
                Descargar
              </Button>) }
        </Item>
        <InputTooltip
          title="Ingresa las habilidades separadas por una coma ( , )">
          <Item
            name="skills"
            label="Habilidades"
            rules={inputRules}>
            <Select
              disabled={!editable}
              tokenSeparators={[","]}
              notFoundContent={null}
              dropdownStyle={{display: "none"}}
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Habilidades" />
          </Item>
        </InputTooltip>
        <InputTooltip
          title="Ingresa las industrias separadas por una coma ( , )">
          <Item
            name="industries"
            label="Industrias"
            rules={inputRules}>
            <Select
              disabled={!editable}
              tokenSeparators={[","]}
              notFoundContent={null}
              dropdownStyle={{display: "none"}}
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Industrias" />
          </Item>
        </InputTooltip>
        <InputTooltip
          title="Ingresa las áreas de experiencia separadas por una coma ( , )">
          <Item
            name="areas"
            label="Áreas de experiencia"
            rules={inputRules}>
            <Select
              disabled={!editable}
              tokenSeparators={[","]}
              notFoundContent={null}
              dropdownStyle={{display: "none"}}
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Áreas de experiencia" />
          </Item>
        </InputTooltip>
      </Form>
    </FormContainer>
  );
};
