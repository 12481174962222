import { useState } from "react";

export function useChartFiltering({ onChange }) {
  const [filters, setFilters] = useState({});

  const handleOnChange = handler => {
    return async (value, afterApplyFilters) => {
      const updatedFilters = await handler(value, filters, setFilters);
      onChange && onChange(updatedFilters);
      afterApplyFilters && afterApplyFilters(updatedFilters);
    };
  };

  return {
    filters,
    handleOnChange
  };
}
