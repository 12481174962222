import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { MainMenu } from "../../../components/admin/shared";
import { Section, SidebarLayout } from "../../../components/shared";
import { InvitationModalProvider } from "../../../contexts/shared/invitation-modal";
import { MenuContext } from "../../../contexts/shared/menu";
import { EntrepreneurProfilesContext } from "../../../contexts/entrepreneur/entrepreneurs/profiles";
import EntrepreneurList from "../../../components/admin/entrepreneurs/list";
import { admin } from "../../../graphql/admin";
import { client } from "../../../../graphql";
import { useControlledPagination, useQueryFilters } from "../../../helpers";
import { FilterControls } from "../../../components/admin/entrepreneurs/list/filter-controls";
import { PaginationContext } from "../../../contexts/shared";

function ProfilesIndex() {

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["entrepreneurs"],
      selectedKeys: ["entrepreneurs/profiles"]
    });
  }, []);

  const {
    data,
    loading,
    error,
    refetch,
    variables
  } = useQuery(
    admin.queries.getEntrepreneurs,
    {
      client,
      variables: {
        first: 10
      }
    }
  );

  const { registerRefetch } = useContext(PaginationContext);

  registerRefetch("entrepreneurs", refetch, variables);

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 10
  });

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilter: {
      first: 10
    }
  });

  const onFilter = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);

    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  return (
    <InvitationModalProvider>
      <EntrepreneurProfilesContext.Provider value={{ data, loading, error}}>
        <SidebarLayout menu={<MainMenu />}>
          <Section
            title="Perfiles"
            extra={
              <FilterControls onChange={onFilter} />}
            style={{ margin: "15px 20px" }}>
            <EntrepreneurList
              onChangePage={onPaginationChange}
              currentPage={currentPage} />
          </Section>
        </SidebarLayout>
      </EntrepreneurProfilesContext.Provider>
    </InvitationModalProvider>
  );
}

export default withRouter(ProfilesIndex);
