import React from "react";
import { Breadcrumb as ABreadcrumb, Card } from "antd";

export function Breadcrumbs({children}) {
  return (
    <Card bordered={false}>
      <ABreadcrumb>
        { children }
      </ABreadcrumb>
    </Card>
  );
}
