import React from "react";
import { Typography } from "antd";
import defaultTeam from "../../../../assets/defaultTeam.png";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";

export const TeamAvatar = ({
  team,
  isLeader = false
}) => {
  const { fullName, profilePicUrl, email, phone } = !!team && team;
  const memberType = isLeader ? "Líder del batch" : "Equipo de apoyo";

  return (
    <div className="Team">
      <div
        className="Team__Avatar"
        style={{
          backgroundImage: `url(${profilePicUrl || defaultTeam})`
        }}>
      </div>
      <Typography.Title level={3}>
        {fullName}
      </Typography.Title>
      <Typography.Title level={4}>
        {memberType}
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        <MailOutlined /> {email}
      </Typography.Paragraph>
      <Typography.Paragraph type="secondary">
        <PhoneOutlined /> {phone}
      </Typography.Paragraph>
    </div>
  );
};
