import React, { useContext } from "react";
import { useService } from "../../../../hooks/shared";
import {
  PaginationContext,
  ServicesLayerContext
} from "../../../../contexts/shared";
import { useControlledPagination } from "../../../../helpers";

export function OrganizationsTableWrapper({
  children,
  tableType = "REVIEW_REQUESTED"
}) {
  const { AsemOrganizationService } = useContext(ServicesLayerContext);

  const initialPageSize = 12;
  const variables = {
    first: initialPageSize,
    status: tableType
  };

  const {
    data,
    loading,
    refetch
  } = useService(AsemOrganizationService.organizationsByStatus, variables);

  const { registerRefetch, refetchList } = useContext(PaginationContext);
  registerRefetch(tableType, refetch, variables);

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize
  });

  const allRefetch = async () => {
    refetchList?.REVIEW_REQUESTED && refetchList.REVIEW_REQUESTED.refetch();
    refetchList?.REJECTED && refetchList.REJECTED.refetch();
    refetchList?.APPROVED && refetchList.APPROVED.refetch();
  };

  return React.cloneElement(children, {
    data,
    loading,
    currentPage,
    onChangePage: onPaginationChange,
    totalRecordsCount: data?.meta?.aggregate?.count,
    paginationRefetchName: tableType,
    allRefetch
  });
}
