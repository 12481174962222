import gql from "graphql-tag";

export const activeStatusOdesFromBatch = gql`
  mutation ActiveStatusOdesFromBatch(
    $data: StatusOdesFromBatch!
  ) {
    statusOdesFromBatch(
      data: $data
    )
  }
`;