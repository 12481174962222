import React from "react";
import { Empty, Button, Card, Skeleton } from "antd";
import EMPTY from "../../../../assets/empty.svg";
import { Visibility } from "../visibility";

/**
 * @params { object } props
 * @param {*|JSX.Element} children
 * @param {boolean} isEmpty
 * @param {boolean} isLoading
 * @param {string} image
 * @param {*|JSX.Element} description
 * @param {string} buttonTitle
 * @param {object} style
 * @param {object} imageStyle
 * @param {function} onClick
 * @returns {*|JSX.Element}
 * @constructor
 */
export function EmptyView({
  children,
  isEmpty = true,
  isLoading = false,
  image = EMPTY,
  description = "",
  buttonTitle = "Comenzar" ,
  style = { maxWidth: "450px", margin: "auto" },
  imageStyle = { height: 260 },
  onClick = undefined
}) {
  if(isLoading) {
    return <Card bordered={false}><Skeleton loading active /></Card>;
  }

  return (
    !isEmpty ? children : (
      <Empty
        style={style}
        image={image}
        imageStyle={imageStyle}
        description={description}>
        <Visibility visible={onClick !== undefined}>
          <Button type="primary" onClick={onClick}>{buttonTitle}</Button>
        </Visibility>
      </Empty>
    )
  );
}
