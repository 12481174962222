import gql from "graphql-tag";

export const getObjectiveById = gql`
  query GetObjectiveById($id: ID!) {
    objective(
      where: {
        id: $id
      }
    ) {
      id
      name
      description
    }
  }
`;
