import gql from "graphql-tag";

export const getOdeById = gql`
  query GetOdeById($id: ID!) {
    oDE(where: { id: $id }) {
      id
      name
      legalName
      description
      technology
      commercialBussines
      sector
      industry

      economicIndustry
      economicSector
      economicActivity

      keywords
      logoUrl
      phone
      email
      trl
      technologyLevel
      socialFunction
      profitMotives
      businessName

      team{
        id
        fullName
        email
        phone
        systemRole
        profilePicUrl
      }
      files{
        id
        fileName
        url
        type
        uploadDate
      }
      pitch{
        id
        problem
        demoUrl
        market
        businessModel
        solution
      }
      fiscalAddress {
        id
        country
        state
        city
        street
        neighborhood
        zipCode
      }
      address {
        id
        country
        state
        city
        street
        neighborhood
        zipCode
      }
      reports{
        id
        startDate
        endDate
        companyStatus
        presales
        monthlySales
        productInDevelopment
        patentStatus
        productPhase
        trlLevels
        fundings
        contests
        hasEmployees
        currentEmployeesNumber
        terminatedEmployees
        hiredEmployees
        achievements
      }

      website
      facebook
      twitter
      instagram
      linkedin

      tradeName
      fiscalRepresentative
      fiscalEntryDate
      rfc
    }
  }
`;
