import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { EditOdeProvider } from "../../../../../contexts/shared/edit-ode";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { withRouter, useQueryFilters } from "../../../../../helpers";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql";
import { SidebarLayout } from "../../../../../components/shared";
import { Breadcrumbs, Navbar } from "../../../../../components/shared/odes/show";
import { MainMenu } from "../../../../../components/admin/shared";
import { SummaryEditSection } from "../../../../../components/shared/ode-forms";
import { ReportsList, Resume } from "../../../../../components/shared/odes/show/metrics";
import { ReportFormModalProvider } from "../../../../../contexts/shared";

function MetricsIndex({ match }) {
  const { params = {} } = match;
  const odeId = params?.id;

  const { loading, error, data = {} } = useQuery(
    shared.queries.getOdeById,
    {
      client,
      variables: { id: odeId },
      fetchPolicy: "network-only"
    }
  );

  const {
    loading: odeReportsLoading,
    data: odeReportsData = {},
    refetch: odeReportsRefetch
  } = useQuery(
    shared.queries.getOdeReports,
    {
      client,
      variables: { odeId: odeId },
      fetchPolicy: "network-only"
    }
  );

  const { applyFilters } = useQueryFilters({ refetch: odeReportsRefetch });

  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: ["odes"],
    selectedKeys: ["profiles"]
  });

  const onFiltersChange = (filters = {}) => {
    applyFilters(prevFilters => {
      const updatedFilters = Object.assign(prevFilters, filters);
      return updatedFilters;
    });
  };

  return(
    <EditOdeProvider
      odeId={odeId}
      data={data}
      loading={loading}
      error={error}>
      <SidebarLayout menu={<MainMenu />}>
        <Breadcrumbs
          odeName={data?.oDE?.name} />
        <SummaryEditSection
          editable
          data={data}
          isLoading={loading}
          error={error} />
        <Navbar selectedKeys={["metrics"]} />
        <Resume
          onFiltersChange={onFiltersChange}
          loading={odeReportsLoading}
          reports={odeReportsData?.odeReports} />
        <ReportFormModalProvider>
          <ReportsList
            odeId={odeId}
            reports={odeReportsData?.odeReports}
            loading={odeReportsLoading} />
        </ReportFormModalProvider>
      </SidebarLayout>
    </EditOdeProvider>
  );
}

export default withRouter(MetricsIndex);
