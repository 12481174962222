import React, { useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Input,
  Radio
} from "antd";

import { FormContainer } from "../../../../../../shared";

const { Item, useForm } = Form;

export const LanguageModal = ({
  visible = false,
  onCancel,
  onConfirm,
  language = {}
}) => {
  const [form] = Form.useForm();
  const validationMessages = {
    required: "El campo '${label}' es requerido."
  };

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const handleConfirm = async () => {
    try {
      const values = await form.validateFields();
      onConfirm({
        ...language,
        ...values
      });
    } catch (error) {
      // TODO: Report to bugsnag
    }
  };

  return (
    <Modal
      forceRender
      style={{ width: "600px" }}
      closable
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          type="primary"
          onClick={handleConfirm}>
          Guardar
        </Button>,
        <Button
          onClick={onCancel}>
          Cancelar
        </Button>
      ]}
      title="Idiomas">
      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          validateMessages={validationMessages}>
          <Item
            initialValue={language.name}
            name="name"
            label="Idioma"
            rules={[{ required: true }]}>
            <Input />
          </Item>
          <Item
            initialValue={language.level}
            name="level"
            label="Nivel"
            rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value="BASIC">Básico</Radio>
              <Radio value="INTERMEDIATE">Intermedio</Radio>
              <Radio value="EXPERT">Experto</Radio>
            </Radio.Group>
          </Item>
        </Form>
      </FormContainer>
    </Modal>
  );
};
