import gql from "graphql-tag";

export const createUser = gql`
  mutation CreateUser($data: UserCreateInput!) {
    createUser(
      data: $data
    ) {
      id
      fullName
      email
    }
  }
`;
