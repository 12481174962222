import React, { useState, useEffect, useContext } from "react";
import { message } from "antd";
import { useQuery } from "react-apollo-hooks";
import { EditProfileContext } from "./context";
import { initialState } from "./helpers";
import { shared } from "../../../graphql/shared";
import { PageLeavePromptContext } from "../../../contexts/shared";
import { client } from "../../../../graphql";
import { useMutation } from "@apollo/react-hooks";

export function EditProfileProvider({ children, userId = ""}) {
  const { setShouldPrompt } = useContext(PageLeavePromptContext);
  const [state, setState] = useState({
    ...initialState
  });

  const { data, loading, error, refetch } = useQuery(
    shared.queries.getProfileDataByUserId,
    {
      client,
      variables: { id: userId },
      fetchPolicy: "network-only"
    });

  useEffect(() => {
    if(userId) {
      setState({ ...state, userId });
    }
  }, [userId]);

  const setEditing = (editing = true, formName) => {
    if (editing)
      setShouldPrompt(true);
    else
      setShouldPrompt(false);

    setState(prevState => {
      prevState.editing[formName] = editing;
      prevState[formName] = undefined; // discard modified data
      return { ...prevState };
    });

    if (!editing) {
      setState(prevState => {
        prevState[formName] = undefined;
        return { ...prevState };
      });
    }
  };

  const [updateProfile] = useMutation(
    shared.mutations.updateProfile, {
      client
    });

  const saveChanges = async (
    data = {},
    formName = "",
    ignoreEditing = false
  ) => {
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      await updateProfile({ variables: { id: state.userId, data } });
      !ignoreEditing && setEditing(false, formName);
      message.success("Cambios guardados.");
      await refetch();
    } catch (error) {
      console.error(error?.message);
      message.error("Ocurrió un error.");
    } finally {
      dismissLoader();
    }
  };

  const injectActions = {
    ...state,
    saveChanges,
    editing: state.editing,
    setEditing,
    data,
    loading,
    error
  };

  return (
    <EditProfileContext.Provider value={injectActions}>
      { children }
    </EditProfileContext.Provider>
  );
}
