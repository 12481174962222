import React from "react";
import { CardListItem } from "../list-item/card-list-item";
import { Button, List, Typography } from "antd";
import { TagList } from "../tag-list";
import { Section } from "../section";
import { cardGridSizes } from "../../../helpers";
import {
  expertServiceCategories,
  getReadableValue
} from "../../../helpers/select-options";

export function CompanyAssignedServicesList({
  assignedServices = [],
  onRemoveAssignedExpert = null,
  modalRef
}) {
  const statusConsumed = [
    "REJECTED",
    "AUTHORIZED_FOR_PAYMENT",
    "PAID",
    "ACCEPTED"
  ];

  const onRemove = async id => {
    onRemoveAssignedExpert && await onRemoveAssignedExpert(id);
  };

  return (
    <Section
      className="CompanyServiceList"
      spaced
      title="Servicios asignados">
      <List
        grid={cardGridSizes}
        style={{ marginTop: "24px" }}
        dataSource={assignedServices}
        renderItem={assignedService => {
          const scheduled = assignedService?.scheduledExpertService;
          const consumed = statusConsumed.includes(scheduled?.status)
            || scheduled ? false : assignedService?.availableUnits === 0;

          const expired = scheduled
            ? scheduled?.status === "EXPIRED"
            : assignedService.status === "EXPIRED";

          const cancelled = scheduled
            ? scheduled?.status === "CANCELLED"
            : assignedService.status === "CANCELLED";

          const labelStatus = consumed
            ? "Consumido" : cancelled
            ? "Cancelado" : expired
            ? "Asignación vencida" : "En curso";

          const color = cancelled
            ? "secondary" : (consumed || expired) ? "danger" : "success";

          const props = (scheduled || consumed || cancelled || expired) ? {
            customOptionsComponent:
            <Typography.Text type={color}>
              {labelStatus}
            </Typography.Text>
          } : { onRemove: () => onRemove(assignedService?.id) };

          return (
            <List.Item>
              <CardListItem
                {...props}
                footerItems={[
                  <Button
                    type="link"
                    key="btn-assigned"
                    onClick={() =>
                      modalRef.current.openModal(assignedService)}>
                    Ver detalle
                  </Button>
                ]}
                extra={
                  <TagList
                    colors={["blue", "red"]}
                    maxCount={2}
                    tags={[
                      getReadableValue(
                        expertServiceCategories,
                        assignedService?.expertService?.category
                      ),
                      "Servicio"
                    ]} />}
                coverUrl={assignedService?.expertService?.coverPicUrl}
                title={assignedService?.expertService?.name}>
                {assignedService?.expertService?.expert?.fullName}
              </CardListItem>
            </List.Item>
          );
        }
        } />
    </Section>
  );
}
