import React from "react";
import { Progress, Typography } from "antd";
import { AssignedMentorings } from "./assigned-mentorings";
import { AvailableMentorings } from "./available-mentorings";
import { FlexContainer } from "../../../../../../../../shared";

export function EntrepreneurMentoringSection({
  batch = null,
  company = null
}) {
  return (
    <div>
      <FlexContainer
        yAlign="center"
        xAlign="center"
        direction="vertical"
        style={{ gap: "2rem" }}>
        <Typography.Title level={4} style={{ color: "#6348E5" }}>
          Consumo de mentorías
        </Typography.Title>
        <Progress
          percent={0}
          style={{ width: "400px" }} />
      </FlexContainer>
      <Typography.Title level={4}>
        Mentorías asignadas
      </Typography.Title>
      <AssignedMentorings
        batch={batch}
        company={company} />
      <Typography.Title level={4}>
        Mentores disponibles
      </Typography.Title>
      <AvailableMentorings
        batch={batch}
        company={company} />
    </div>
  );
}