import {
  Title,
  Legend,
  Tooltip as ChartJsTooltip,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  registerables,
  Chart as ChartJS
} from "chart.js";
import React from "react";
import {Button, Card, Popover, Space, Typography} from "antd";
import { Chart } from "react-chartjs-2";
import { getShareComparisonChartCongif } from "./helpers";
import { ExclamationCircleOutlined } from "@ant-design/icons";

ChartJS.register(
  ...registerables,
  Title,
  Legend,
  ChartJsTooltip,
  BarElement,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale
);

export const ResumeByPersonShareComparisonChart = ({
  involvementNationalData,
  attendances
}) => {

  const { data, options } = getShareComparisonChartCongif({
    involvementNationalData,
    attendances
  });

  return(
    <Card
      style={{ width:"100%" }}
      title={
        <Space size="medium">
          <Typography.Title level={5} style={{ margin: 0 }}>
            Comparativa de participaciones
          </Typography.Title>
          <Popover
            overlayStyle={{ width: "300px"}}
            content={
              `Experiencias a las que asistió la persona en el periodo filtrado,
              agrupadas por nivel de involucramiento, comparadas con el promedio
              nacional de asistencias y el máximo alcanzado por nivel.`}>
            <Button
              primary
              type="text"
              size="small"
              shape="circle"
              icon={<ExclamationCircleOutlined />} />
          </Popover>
        </Space>
      }
      bodyStyle={{ padding: "0 1rem" }}
      className="dashboard-graphs">
      <Chart
        height={500}
        type="bar"
        data={data}
        options={options} />
    </Card>
  );
};
