import React from "react";
import { Descriptions } from "antd";

export function ContactInfo({ user = {}, title = "", ...props }) {
  return (
    <Descriptions
      title={title}
      layout="vertical"
      column={2}
      {...props}>
      <Descriptions.Item label="Teléfono">
        {user?.phone || "N/A"}
      </Descriptions.Item>
      <Descriptions.Item label="Correo">
        {user?.email || "N/A"}
      </Descriptions.Item>
    </Descriptions>
  );
}
