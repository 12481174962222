
export const industriesChartOptions = {
  plugins: {
    legend: {
      position: "bottom",
      display: false
    },
    datalabels: {
      color: "#000"
    }
  },
  scales: {
    x: {
      padding: 5
    }
  },
  maintainAspectRatio: false
};
