import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { SidebarLayout, useDocsContext } from "../../../../../components/shared";
import { Summary } from "../../../../../components/admin/batches/show/summary";
import {
  default as Navbar
} from "../../../../../components/admin/batches/show/navbar";
import {
  BatchOdesList
} from "../../../../../components/admin/batches/show/odes/list";
import {
  BatchOdesContext
} from "../../../../../contexts/admin/batches/odes";
import {
  BatchDetailsContextProvider
} from "../../../../../contexts/admin/batches/batch-details";
import { admin } from "../../../../../graphql/admin";
import { client } from "../../../../../../graphql";
import { useQueryFilters } from "../../../../../helpers";
import { withRouter } from "../../../../../helpers";
import { MenuContext } from "../../../../../contexts/shared";
import {
  NOTION_PAGES_ENUM
} from "../../../../../components/shared/docs-wapper/notion-pages-enum";

export function BatchODEsIndex() {
  useDocsContext(NOTION_PAGES_ENUM.BATCHES.pages.COMPANIES_TAB.pageId);

  const { id = "" } = useParams();

  const {
    data,
    loading,
    refetch,
    error,
    variables
  } = useQuery(admin.queries.getOdesByBatchId, {
    client,
    variables: {
      id: id
    }
  });

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: variables
  });

  const injectActions = {
    applyFilters,
    data,
    loading,
    error,
    batchId: id
  };

  return (
    <BatchDetailsContextProvider>
      <BatchOdesContext.Provider value={injectActions}>
        <SidebarLayout>
          <Summary />
          <Navbar selectedKeys={["odes" ]} />
          <BatchOdesList />
        </SidebarLayout>
      </BatchOdesContext.Provider>
    </BatchDetailsContextProvider>
  );
}

export default withRouter(BatchODEsIndex);
