import React, { useEffect, useState } from "react";
import { Button, Form, Input, List, Space, Row, message } from "antd";
import { LinkOutlined, DeleteOutlined } from "@ant-design/icons";
import { CompositeField } from "../composite-field";
import { urlPatterns } from "../../../utils";
import { getClickableLink, validateUrl } from "../../../helpers";
import { UploadSection } from "../upload-section";

export function FileLinkField({ onChange, value }) {
  const [form] = Form.useForm();
  const [state, setState] = useState({ values: [] });

  const currentTime = new Date().toISOString();

  const handleOnChange = values => {
    onChange(values);
  };

  useEffect(() => {
    if (value) {
      setState({ values: value });
    }
  }, [value]);

  const onAddLink = (addNew, replaceItemAtIndex) =>
    async () => {
      const link = form.getFieldValue("link");
      if (!link) {
        return;
      }
      const validatedLink = validateUrl(link);
      if(validatedLink === null) {
        message.error("El enlace introducido no es válido");
        return;
      }
      const values = {
        fileName: link,
        url: link,
        type: "link",
        uploadDate: currentTime
      };

      await form.validateFields();
      if (typeof (values.index) === "undefined") {
        addNew(values);
      } else {
        const index = values.index;
        delete values.index;
        replaceItemAtIndex(index, values);
      }
      form.resetFields();
    };

  const onUploadFile = addNew => e => {
    const file = {
      fileName: e.file.name,
      url: e.url,
      type: e.file.type,
      uploadDate: currentTime
    };
    addNew(file);
  };

  return (
    <CompositeField
      isAddDisabled
      onChange={handleOnChange}
      value={state.values}>
      {({ items, addNew, removeItem, replaceItemAtIndex }) => {
        return (
          <>
            <UploadSection
              onChange={onUploadFile(addNew)}
              reviewImage={false}
              showUploadList={false}>
              Adjuntar archivos
              <br />
              <span>Puedes adjuntar：.rar .zip .doc .docx .pdf .jpg...</span>
            </UploadSection>
            <Form form={form}>
              <Form.Item
                label="Agrega un enlace aquí"
                name="link"
                style={{ margin: "15px 0 5px 0" }}
                rules={[{
                  pattern: urlPatterns.url,
                  message: "El formato de la URL no es válido"
                }]}>
                <Row
                  justify="space-between"
                  style={{ margin: "0" }}>
                  <Input
                    placeholder="wikipedia.com"
                    addonBefore="http://"
                    style={{ width: "85%" }}
                    onPressEnter={onAddLink(addNew, replaceItemAtIndex)} />
                  <Button
                    onClick={onAddLink(addNew, replaceItemAtIndex)}
                    style={{ width: "13%", color: "#704AED", border: "1px solid #704AED" }}>
                    Agregar
                  </Button>
                </Row>
              </Form.Item>
            </Form>
            <List>
              {items.map((item, index) =>
                <List.Item key={item.uuid || index.toString()}>
                  <Space
                    align="baseline"
                    style={{ justifyContent: "space-between", width: "100%" }}>
                    <Space
                      style={{
                        width: "450px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}>
                      <LinkOutlined />
                      <a
                        target="_blank"
                        href={getClickableLink(item.url)}>
                        {item.fileName}
                      </a>
                    </Space>
                    <DeleteOutlined onClick={removeItem(index)} />
                  </Space>
                </List.Item>
              )}
            </List>
          </>);
      }
      }
    </CompositeField>
  );
}
