import React, { useContext } from "react";
import { List, Space } from "antd";
import { ExpertServiceCard, AddButton } from "../../../shared";
import {
  ExpertServiceModalContext
} from "../../../../contexts/shared/expert-service-modal";
import {
  AssignedExpertServiceModalProvider
} from "../../../../contexts/shared/assigned-expert-service-modal";
import {
  paginationConfig,
  withLoader,
  cardGridSizes
} from "../../../../helpers";
import {
  PaginationContext,
  CurrentUserContext
} from "../../../../contexts/shared";

export function ExpertServicesList({
  data,
  refetchQueriesVars = {},
  onChangePage,
  currentPage,
  isLoading
}) {
  const { currentUser } = useContext(CurrentUserContext);

  const {
    openModal,
    onDisableExpertService
  } = useContext(ExpertServiceModalContext);

  const { onPaginationChange } = useContext(PaginationContext);

  const onEditService = (expertService, index) => () => {
    expertService.index = index;

    openModal({
      expertService,
      expertId: currentUser?.id,
      expertMode: currentUser.systemRole === "ADVISER"
    });
  };

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange("services")(page, pageSize);
  };

  return (
    <AssignedExpertServiceModalProvider>
      <Space style={{width: "100%"}} direction="vertical">
        <AddButton
          style={{ width: "100%", marginBottom: "24px" }}
          onClick={() => openModal({
            expertId: currentUser?.id,
            newService: true })}>
          Añadir producto
        </AddButton>
        <List
          grid={cardGridSizes}
          dataSource={data?.expertServices}
          loading={isLoading}
          pagination={{
            ...paginationConfig,
            showSizeChanger: true,
            current: currentPage,
            defaultPageSize: 12,
            onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
            total: data?.meta?.aggregate?.count || 0
          }}
          renderItem={(s, i) => {
            return (
              <List.Item>
                <ExpertServiceCard
                  service={s}
                  refetchQueriesVars={refetchQueriesVars}
                  onDisableExpertService={() => onDisableExpertService({
                    id: s.id,
                    expertId: s.expert?.id
                  })}
                  onEdit={onEditService(s, i)} />
              </List.Item>
            );
          }} />
      </Space>
    </AssignedExpertServiceModalProvider>
  );
}

export default withLoader(ExpertServicesList);
