import { client } from "../../../graphql";
import { shared } from "../../graphql/shared";
import {
  markCompanyContentAsDelivered
} from "./mark-company-content-as-delivered";
import {
  changeCompanyContentStatus
} from "./change-company-content-status";

async function getById(id) {
  return await client.query({
    query: shared.queries.getContentById,
    fetchPolicy: "network-only",
    variables: {
      id: id
    }
  });
}

async function getByIdWithOdeContent(id, odeId) {
  return await client.query({
    query: shared.queries.getContentByIdWithOdeContent,
    fetchPolicy: "network-only",
    variables: {
      id: id,
      odeId: odeId
    }
  });
}

export const contentService = {
  getById,
  getByIdWithOdeContent,
  changeCompanyContentStatus,
  markCompanyContentAsDelivered
};
