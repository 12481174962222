
export const INDUSTRIES_CHART_OPTIONS = {
  barPercentage: 0.7,
  categoryPercentage: .7,
  plugins: {
    legend: {
      position: "bottom"
    },
    datalabels: {
      color: "#000"
    }
  },
  scales: {
    x: {
      padding: 5,
      stacked: true,
      ticks: {
        font: {
        }
      }
    },
    y: {
      stacked: true,
      fontSize: 5
    }
  },
  maintainAspectRatio: false
};
