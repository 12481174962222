import React from "react";
import { Format } from "../";

export function ServiceCost({ duration, assignedExpertService = {} }) {
  const {
    expertServiceData = {},
    expertServicePrice,
    isProbono
  } = assignedExpertService;

  const calcultedCost = parseInt(duration || 0) * parseFloat(expertServicePrice);

  return (
    <Format type="money">
      {
        isProbono
          ? 0
          : (expertServiceData?.method === "FIXED_COST")
            ? expertServicePrice
            : calcultedCost
      }
    </Format>
  );
}
