import React, { useContext, useEffect, useState } from "react";
import { Select, Typography } from "antd";
import { shared } from "../../../graphql/shared";
import { coordinator } from "../../../graphql/coordinator";
import { entrepreneur } from "../../../graphql/entrepreneur";
import { client } from "../../../../graphql";
import { CurrentUserContext } from "../../../contexts/shared/current-user";

export function ProgramsSelect({ onChange, ...props }) {
  const [state, setState] = useState({
    programs: [],
    options: [],
    loading: true,
    error: false
  });

  const queries = {
    COORDINATOR: coordinator.queries.getAvailablePrograms,
    ENTREPRENEUR: entrepreneur.queries.getProgramsByEntrepreneurId,
    OTHER: shared.queries.getPrograms
  };

  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const query = queries[currentUser.systemRole] || queries.OTHER;

        const { data } = await client.query(
          {
            variables: {
              coordinatorId: currentUser.id || "",
              id: currentUser.id || "",
              orderBy: "name_ASC"
            },
            fetchPolicy: "network-only",
            query
          }
        );

        setState({
          ...state,
          programs: data.programs,
          options: data.programs.map(p => ({ label: p.name, value: p.id })),
          loading: false
        });
      } catch(e) {
        console.error(e);
        setState({ ...state, loading: false, error: true });
      }
    };

    fetchPrograms();
  }, []);

  const handleOnChange = programId => {
    onChange &&
      onChange(programId, state.programs.find(p => p.id === programId));
  };

  return (
    <>
      <Select
        data-cy="programs-select"
        placeholder="Seleccionar programa"
        optionFilterProp="label"
        allowClear
        showSearch
        disabled={state.loading || state.error}
        loading={state.loading}
        onChange={handleOnChange}
        options={state.options}
        {...props} />
      <Typography.Text type="danger">
        {state.error && "No se pudieron cargar los programs"}
      </Typography.Text>
    </>
  );
}
