import React, { useContext } from "react";
import moment from "moment";
import { Table, Tag } from "antd";
import { Format } from "../../../../../../shared";
import { statusTagColors } from "./status-tag-colors";
import {
  ServiceDetailModalContext
} from "../../../../../../../contexts/shared";
import {
  getReadableValue,
  expertServiceTypes,
  scheduledServicesStatus
} from "../../../../../../../helpers";
import {
  ServiceAcceptanceModalContext
} from "../../../../../../../contexts/entrepreneur/experts/services/monitoring/service-acceptance-modal";

export function MonitoringServicesTable({
  loading,
  scheduledExpertServices = []
}) {
  const { openModal } = useContext(ServiceDetailModalContext);
  const { openModal: openAcceptanceModal } =
    useContext(ServiceAcceptanceModalContext);

  const statusesToMask = [
    "AUTHORIZED_FOR_PAYMENT",
    "PAID",
    "PAYMENT_IN_PROCESS"
  ];

  const maskStatus = status => {
    if (statusesToMask.includes(status)) {
      // non-admin user should not see payment status info
      status = "ACCEPTED";
    }
    return status;
  };

  const handleOnClickRow = scheduledExpertService => {
    if (scheduledExpertService?.status === "DELIVERED") {
      openAcceptanceModal(scheduledExpertService);
    } else {
      openModal({ scheduledExpertService });
    }
  };

  return (
    <Table
      title={() => `Resultados: ${scheduledExpertServices.length}`}
      style={{ overflowX: "auto" }}
      loading={loading}
      rowKey={(row, index) => row?.id || index}
      dataSource={scheduledExpertServices}
      onRow={scheduledExpertService => ({
        onClick: () => handleOnClickRow(scheduledExpertService),
        className: "clickable"
      })}>
      <Table.Column
        dataIndex="assignedExpertService"
        title="Compañía"
        render={assignedExpertService => assignedExpertService?.ode?.name} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Programa"
        render={({ batch }) => batch?.program?.name} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Batch"
        render={({ batch }) => batch?.name} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Producto"
        render={({ expertServiceData }) => expertServiceData?.name} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Experto"
        render={assignedExpertService =>
          assignedExpertService?.expertService?.expert?.fullName} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Tipo"
        render={({ expertServiceData }) =>
          getReadableValue(expertServiceTypes, expertServiceData?.type) } />
      <Table.Column
        align="center"
        dataIndex="assignedExpertService"
        title="Cantidad"
        render={({ expertServiceData: { method } }, { duration }) =>
          method === "HOURS" ? `${duration} horas` : "1 servicio"} />
      <Table.Column
        align="center"
        dataIndex="serviceDate"
        title="Fecha de ejecución"
        render={serviceDate =>
          serviceDate ?
            moment(serviceDate).format("DD/MM/YYYY")
            : "--"} />
      <Table.Column
        align="center"
        dataIndex="acceptedAt"
        title="Fecha de aceptación"
        render={acceptedAt =>
          <Format type="date">{ acceptedAt }</Format>} />
      <Table.Column
        align="center"
        dataIndex="status"
        title="Estado"
        render={status => {
          const maskedStatus = maskStatus(status);

          return (
            <Tag
              style={{ margin: 0 }}
              color={statusTagColors[maskedStatus]}>
              {getReadableValue(scheduledServicesStatus, maskedStatus)}
            </Tag>
          );
        }} />
    </Table>
  );
}
