import React from "react";
import { Divider, Typography, Collapse, Row, Col, Switch } from "antd";
import { ActionButton, FlexContainer, Format, Visibility } from "../../..";
import { getReadableValue } from "../../../../../helpers/select-options";
import { fundTypeOptions } from "../select-options/fund-type";
import { sourceOptions } from "../select-options/source";
import { placeOptions } from "../select-options/place";
import { prizeOptions } from "../select-options/prize";
import "./styles.sass";

const Title = ({ children }) => (
  <Typography.Title
    level={5}
    style={{ color: "#5F39DE" }}>
    {children}
  </Typography.Title>
);

const Text = ({ children }) => (
  <Typography.Text
    style={{
      padding: "18px",
      fontSize: "16px",
      display: "block"
    }}>
    {children}
  </Typography.Text>
);

export const CreateCompanyMilestonesList = ({
  milestones = [],
  onEdit,
  onDelete
}) => {

  const funds = milestones.filter(milestone => milestone.milestoneType === "FUND");
  const competences = milestones.filter(milestone => milestone.milestoneType === "COMPETENCE");

  if (milestones.length === 0) return null;

  return (
    <div style={{ padding: "0 16px" }}>
      <Visibility visible={funds.length > 0}>
        <Title>Fondos</Title>
        <Collapse expandIconPosition="right" ghost>
          {
            funds.map(fund => (
              <>
                <Collapse.Panel
                  key={fund.id}
                  className="milestone-panel"
                  header={(<>
                    <FlexContainer direction="horizontal" justifyContent="start">
                      <FlexContainer direction="vertical" xAlign="start">
                        <Typography.Text>Inversor</Typography.Text>
                        <Typography.Title
                          level={5}
                          style={{
                            padding: "18px 0px",
                            marginTop: "0",
                            textAlign: "left"
                          }}>
                          {fund.investor}
                        </Typography.Title>
                      </FlexContainer>
                      <FlexContainer direction="vertical" xAlign="start">
                        <Typography.Text>Monto obtenido</Typography.Text>
                        <Typography.Title
                          level={5}
                          style={{
                            padding: "18px 0px",
                            marginTop: "0"
                          }}>
                          {`$${Number(fund.amount).toLocaleString()} ${fund.currency}`}
                        </Typography.Title>
                      </FlexContainer>
                      <ActionButton
                        onEdit={() => onEdit(fund, "addFundForm")}
                        onDelete={() => onDelete(fund.id)} />
                    </FlexContainer>
                  </>)}>
                  <Row>
                    <Col span={11}>
                      <Typography.Text>Tipo de fondo</Typography.Text>
                      <div style={{ fontSize: "16px" }}>{getReadableValue(fundTypeOptions, fund.fundType)}</div>
                    </Col>
                    <Col span={11}>
                      <Typography.Text>Fuente</Typography.Text>
                      <div style={{ fontSize: "16px" }}>{getReadableValue(sourceOptions, fund.source)}</div>
                    </Col>
                  </Row>
                  <div style={{ marginTop: "24px" }}>
                    <Typography.Text>Fecha</Typography.Text>
                    <div style={{ textTransform: "capitalize" }}>
                      <div style={{ fontSize: "16px" }}>
                        <Format type="date" format="MMMM, YYYY">
                          {fund.date}
                        </Format>
                      </div>
                    </div>
                  </div>
                </Collapse.Panel>
                <Divider />
              </>
            ))
          }
        </Collapse>
      </Visibility>
      <div style={{ height: "21px" }} />
      <Visibility visible={competences.length > 0}>
        <Title>Competencias</Title>
        <Collapse expandIconPosition="right" ghost>
          {
            competences.map(competence => (
              <>
                <Collapse.Panel
                  key={competence.id}
                  className="milestone-panel"
                  header={(<>
                    <FlexContainer direction="horizontal" style={{ gap: "15px" }}>
                      <FlexContainer direction="vertical">
                        <Typography.Text>
                          Nombre de la competencia
                        </Typography.Text>
                        <Typography.Title
                          level={5}
                          style={{
                            padding: "18px 0px",
                            marginTop: "0"
                          }}>
                          {competence.name}
                        </Typography.Title>
                      </FlexContainer>
                      <FlexContainer direction="vertical">
                        <Typography.Text>Fecha de la competencia</Typography.Text>
                        <Typography.Title
                          level={5}
                          style={{
                            padding: "18px 0px",
                            marginTop: "0",
                            textTransform: "capitalize"
                          }}>
                          <Format
                            type="date" format="MMMM, YYYY">
                            {competence.date}
                          </Format>
                        </Typography.Title>
                      </FlexContainer>
                      <FlexContainer direction="vertical">
                        <Typography.Text>Competencia enfocada en problemas sociales</Typography.Text>
                        <Switch
                          disabled={true}
                          checked={competence.socialCompetence}
                          style={{ marginTop: "18px" }} />
                      </FlexContainer>
                      <FlexContainer direction="vertical" xAlign="end">
                        <ActionButton
                          onEdit={() => onEdit(competence, "addCompetenceForm")}
                          onDelete={() => onDelete(competence.id)} />
                      </FlexContainer>
                    </FlexContainer>
                  </>)}>
                  <>
                    <Col span={24}>
                      <Typography.Text>Descripción de la competencia</Typography.Text>
                      <div style={{ fontSize: "16px" }}>
                        {competence.description}
                      </div>
                    </Col>
                    <Row style={{ marginTop: "15px" }}>
                      <Col span={11}>
                        <Typography.Text>Lugar obtenido</Typography.Text>
                        <div style={{ fontSize: "16px" }}>{getReadableValue(placeOptions, competence.place)}</div>
                      </Col>
                      <Col span={11}>
                        <Typography.Text>Premio</Typography.Text>
                        <div style={{ fontSize: "16px" }}>{getReadableValue(prizeOptions, competence.prize)}</div>
                      </Col>
                    </Row>
                    <Col style={{ marginTop: "15px" }}>
                      <Typography.Text>Monto obtenido</Typography.Text>
                      <div style={{ fontSize: "16px" }}>
                        {`$${Number(competence.amount).toLocaleString()} ${competence.currency}`}
                      </div>
                    </Col>
                  </>
                </Collapse.Panel>
                <Divider />
              </>
            ))
          }
        </Collapse>
      </Visibility>
    </div>
  );
};
