import React, { useContext } from "react";
import { Skeleton } from "antd";
import { DetailContent } from "./detail-content";
import { ScheduledProductHeader } from "../components";
import { useService } from "../../../../../hooks/shared";
import { BaseServiceDetailModal } from "../../../../shared/modals";
import { ServicesLayerContext } from "../../../../../contexts/shared";
import { AlertError } from "../../../../shared";
import { Footer } from "./footer";

export function EntrepreneurAssignedProductDetailModal({
  // Could be injected by the modal provider
  selectedItem, // assigned service
  ...props
}) {
  const { AssignedExpertServiceService } = useContext(ServicesLayerContext);

  const {
    loading,
    data: assignedExpertService
  } = useService(
    AssignedExpertServiceService.getById,
    { id: selectedItem?.id },
    { shouldFetch: !!selectedItem?.id && props.visible }
  );

  const renderAlertError = () => {
    const { availableUnits } = assignedExpertService || {};
    return availableUnits === 0 ? (
      <AlertError
        title="No es posible agendar. Has consumido tu servico"
        description="Si lo deseas puede solicitar más." />
    ) : null;
  };

  const renderFooter = () => {
    const { availableUnits } = assignedExpertService || {};
    return availableUnits === 0 ? (
      <Footer />
    ) : null;
  };

  return (
    <BaseServiceDetailModal
      header={<ScheduledProductHeader
        scheduledExpertService={{
          assignedExpertService: assignedExpertService || undefined
        }} />}
      footer={renderFooter()}
      alert={!loading && renderAlertError()}
      {...props}>
      {loading ?
        <Skeleton paragraph={{ rows: 3 }} />
        : <DetailContent assignedExpertService={assignedExpertService} /> }
    </BaseServiceDetailModal>
  );
}
