import moment from "moment";

export function date(value, props) {
  const { format = "DD/MMMM/YYYY" } = props;

  if(!value) {
    return "Sin fecha";
  }

  return moment(value).format(format);
}
