import React from "react";
import { Space, Button } from "antd";
import {
  TwitterOutlined,
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  LinkOutlined
} from "@ant-design/icons";
import { LargeIcon } from "./large-icon";

export const ReadOnlyItem = ({ value: url = "", socialNetwork = ""}) => {

  const getIcon = () => {
    const iconsMap = {
      "twitter": TwitterOutlined,
      "facebook": FacebookFilled,
      "instagram": InstagramOutlined,
      "linkedin": LinkedinFilled,
      "other": LinkOutlined
    };

    return iconsMap[socialNetwork];
  };

  return (
    <Space>
      <LargeIcon icon={getIcon()} />
      <Button type="link">{url}</Button>
    </Space>
  );
};
