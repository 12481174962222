import gql from "graphql-tag";

export const getEntrepreneurById = gql`
  query GetEntrepreneurById($id: ID!) {
    user (
      where: { id: $id }
    ) {
      id
      fullName
      firstName
      middleName
      lastName
      email
      systemRole
      userID
      phone
      gender
      birthday
      profilePicUrl
      tecRelationship
      category
      enrollment
      payroll
      careerName
      campus
      specialtyArea
      networkOdes {
        id
      }
      teamOdes {
        id
        name
        logoUrl
      }
      # Stop using the "odes" field in future updates. Use teamOdes
      # instead for entrepreneurs or networkOdes for users part of the network.
      odes{
        id
        ode{
          id
          name
        }
      }
    }
  }
`;
