import gql from "graphql-tag";

export const getNotificationCountByUserId = gql`
  query GetNotificationCountByUserById($id: ID!) {
    notificationsConnection(where: { user: { id: $id }, read: false }) {
      aggregate {
        count
      }
    }
  }
`;
