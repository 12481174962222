import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";

const authLink = setContext((_, prevContext) => {
  const jwt = localStorage.getItem("token");

  return jwt
    ? {
      ...prevContext,
      headers: {
        ...prevContext.headers,
        authorization: `Bearer ${jwt}`
      }
    }
    : prevContext;
});

/**
 * Useful for tests
 * @param serverUri
 * @returns {ApolloClient<NormalizedCacheObject>}
 */
export const getClient = serverUri => {
  return new ApolloClient({
    link: ApolloLink.from([
      authLink,
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.map(({ message, locations, path }) =>
            console.error(
              // eslint-disable-next-line max-len
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );

          if (graphQLErrors.some(err => err.message.match(/INVALID_TOKEN/))) {
            localStorage.clear();
            window.location.reload();
          }
        }
        if (networkError) console.error(`[Network error]: ${networkError}`);
      }),
      setContext((operation, prevContext) => {
        if(operation.variables.searchEnabled)
          prevContext.headers["X-Search-Enabled"] = "1";

        return prevContext;
      }),
      new HttpLink({
        uri: serverUri || process.env.REACT_APP_GQL_ENDPOINT,
        credentials: "same-origin"
      })
    ]),
    cache: new InMemoryCache()
  });
};

export const graphqlApolloClient = getClient();
export const graphqlApolloClient3 = getClient(
  process.env.REACT_APP_GQL_ENDPOINT_EE
);
