import { Typography } from "antd";
import React, { useContext, useEffect } from "react";
import { MenuContext } from "../../../contexts/shared";
import { Section, SidebarLayout } from "../../../components/shared";
import { DynamicDashboard } from "../../../components/admin/analytics";

export function AnalyticsIndex() {
  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["analytics"],
      selectedKeys: ["analytics"]
    });
  }, []);

  return (
    <SidebarLayout>
      <Section
        spaced
        className="NewBatch__Publish"
        title={<Title />}>
        <DynamicDashboard />
      </Section>
    </SidebarLayout>
  );
}

const Title = () => (
  <Typography.Title level={3} style={{ color: "#5F39DE" }}>
    Reportes
  </Typography.Title>
);
