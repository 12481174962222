export const getCampusInvolvementsLevelChartConfig = ({
  involvementLevelsImpact = {}
}) => {

  const { IMPACT, TOTAL } = involvementLevelsImpact;

  const campusInvolvementLevelsImpact = {
    IMPACT: IMPACT?.CAMPUS?.count / TOTAL?.campus,
    KNOW: IMPACT?.KNOW?.campus / TOTAL?.campus,
    LEARN: IMPACT?.LEARN?.campus / TOTAL?.campus,
    ACT: IMPACT?.ACT?.campus / TOTAL?.campus
  };

  const nationalInvolvementLevelsImpact = {
    IMPACT: IMPACT?.NATIONAL?.count / TOTAL?.national,
    KNOW: IMPACT?.KNOW?.national / TOTAL?.national,
    LEARN: IMPACT?.LEARN?.national / TOTAL?.national,
    ACT: IMPACT?.ACT?.national / TOTAL?.national
  };

  const campusImpactDataset = {
    label: "Campus",
    backgroundColor: "#FFD9A0",
    data: Object.keys(EXPERIENCE_LEVEL)
      .map(el => campusInvolvementLevelsImpact[el] || 0)
  };

  const nationalImpactDataset = {
    label: "Nacional",
    backgroundColor: "#B3EEE8",
    data: Object.keys(EXPERIENCE_LEVEL)
      .map(el => nationalInvolvementLevelsImpact[el] || 0)
  };

  const data = {
    labels: Object.values(EXPERIENCE_LEVEL),
    datasets: [campusImpactDataset, nationalImpactDataset]
  };

  return { data, options };
};

export const EXPERIENCE_LEVEL = {
  IMPACT: "Impactados",
  KNOW: "Conocer",
  LEARN: "Aprender",
  ACT: "Accionar"
};

const options = {
  barPercentage: .7,
  categoryPercentage: .5,
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 0
    }
  },
  plugins : {
    tooltip: {
      titleColor: "#000",
      bodyColor: "#000",
      backgroundColor: "rgba(255, 255, 255, 0.75)",
      titleFont: { weight: "bold" },
      titleFontSize: 16,
      bodyFontSize: 14,
      displayColors: false,
      callbacks: {
        title: tooltipItem => {
          return tooltipItem[0].label;
        },
        label: tooltipItem => {
          const { formattedValue } = tooltipItem;
          const number = Number.parseFloat(formattedValue);
          return `${(number * 100).toFixed(2) || 0}%`;
        }
      }
    }
  },
  scales: {
    x: {
      ticks: {
        callback: value => `${(value * 100) || 0}%`
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false
};