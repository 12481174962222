import React from "react";
import { Row, Col, Divider, List, Space } from "antd";
import { Format, Visibility } from "../../../../../components/shared";

function ListItem({ label, total }) {
  return (
    <List.Item>
      <Space style={{ justifyContent: "space-between", width: "100%" }}>
        <strong>{label}</strong>
        <strong>{total}</strong>
      </Space>
    </List.Item>
  );
}

export function Summay({
  isProbono,
  price = 0,
  totalUnits = 0,
  serviceType,
  serviceMethod
}) {
  const getType = () => {
    const types = {
      MENTORSHIP: "horas",
      PRODUCT: "productos"
    };

    return types[serviceType] || "unidades";
  };

  const getTotalCost = () => {
    if (isProbono) {
      return 0;
    }

    if (serviceType === "WORKSHOP" && serviceMethod === "FIXED_COST") {
      return price;
    }
    return totalUnits * price;
  };

  return (
    <Row>
      <Col span={24}>
        <Divider orientation="left">Resumen final</Divider>
        <List
          size="small"
          bordered>
          <Visibility visible={serviceType !== "WORKSHOP"}>
            <ListItem
              label={`Total de ${getType()}`}
              total={totalUnits.toString()} />
          </Visibility>
          <ListItem
            label="Costo total"
            total={
              <Format type="money">
                {getTotalCost()}
              </Format>} />
        </List>
      </Col>
    </Row>
  );
}