import React from "react";
import { Menu } from "antd";
import { withRouter } from "../../../../../helpers";

function Navbar({ navigate, selectedKeys, routes = {}, ...props }) {
  return (
    <Menu
      defaultOpenKeys={["objectives"]}
      selectedKeys={selectedKeys}
      style={{ borderBottom: "none" }}
      mode="horizontal"
      {...props}>
      <Menu.Item
        onClick={() => navigate(routes.experts || "./batches")}
        key="batches">
        Batches
      </Menu.Item>
    </Menu>
  );
}

export default withRouter(Navbar);
