import { client } from "../../../graphql";
import { disableOdeInBatch, activeOdeInBatch } from "./graphql";

export async function toggleStatusCompany({
  companyId,
  batchId,
  odeStatusBatch,
  status
}) {
  const noHaveStatus = !odeStatusBatch.length;
  const statusId = odeStatusBatch[0]?.id || undefined;
  let result = null;

  if (noHaveStatus || status === "INACTIVE") {
    result = await client.mutate({
      mutation: disableOdeInBatch,
      variables: { batchId, companyId, statusId }
    });

    return result?.data?.updateODE || {};
  }

  result = await client.mutate({
    mutation: activeOdeInBatch,
    variables: { companyId, statusId }
  });

  return result?.data?.updateOdeStatusBatch || {};
}