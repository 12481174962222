import { expert } from "../../../../graphql/expert";
import { eventTypeEnum } from "../../../../helpers";

export async function deliver(
  assignedExpertService,
  expertId,
  { id, ...data }
) {
  const assignationIdentifier = assignedExpertService?.assignationIdentifier;

  // I'm throwing an exception because passing an undefined value in the where
  // constraint could update all the services
  if (!id) {
    throw new Error("id not specified");
  }

  const isWorkshop = assignedExpertService
    ?.expertServiceData
    ?.type === "WORKSHOP";

  const where = { };
  const status = isWorkshop ? "ACCEPTED" : "DELIVERED";

  if (isWorkshop) {
    // the workshops have a shared assignationIdentifier which allow us to
    // accept and deliver them together. If the this UID doesn't exists it means
    // there is an error
    if (!assignationIdentifier) {
      throw new Error("assignationIdentifier not specified");
    }

    where.assignedExpertService = { assignationIdentifier };
  } else {
    where.id = id;
  }

  if(data.links?.length) {
    data.links = { set: data.links };
  }

  return await this.client.mutate({
    mutation: expert.mutations.deliverManyScheduledServices,
    variables: {
      where,
      data: {
        cancelledBy: null,
        status: status,
        events: { // log event
          create: {
            event: eventTypeEnum.STATUS_CHANGED,
            description: `Status changed to ${status}`,
            blame: { connect: { id: expertId } }
          }
        },
        ...data
      }
    }
  });
}
