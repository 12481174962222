import gql from "graphql-tag";

export const updateManyScheduledStatusPaymentMutation = gql`
  mutation SendCommentMutation(
    $ids: [ID]!,
    $status: SupportedStatus!
    $userId: ID!
  ) {
    updateManyScheduledExpertServicesCheckBoxPerStatus(
      where: { ids: $ids }
      data: {
        status: $status
        userId: $userId  
      }
    ){
      count
    }
  }
`;
