import React, { useContext } from "react";
import { withLoader, paginationConfig } from "../../../../../../helpers";
import { AddButton, Visibility } from "../../../../../shared";
import { List } from "antd";
import { CoordinatorItem } from "./item";
import {
  CurrentUserContext,
  ConnectUserToBatchContext
} from "../../../../../../contexts/shared";

function CoordinatorsList({
  loading,
  data,
  currentPage = 1,
  onChangePage,
  ...props }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { openModal } = useContext(ConnectUserToBatchContext);

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
  };

  return (
    <>
      <Visibility
        visible={!["ENTREPRENEUR"]
          .includes(currentUser.systemRole)}>
        <AddButton
          style={{ width: "100%" }}
          onClick={openModal}>
          Añadir coordinador
        </AddButton>
      </Visibility>
      <List
        style={{ marginTop: "20px" }}
        loading={loading}
        itemLayout="vertical"
        dataSource={data.users}
        pagination={{
          ...paginationConfig,
          current: currentPage,
          onChange: (page, pageSize) => handlePaginationChange(page, pageSize)
        }}
        renderItem={i =>
          <CoordinatorItem user={i} />
        }
        {...props} />
    </>
  );
}

export default withLoader(CoordinatorsList);
