import React, { useState } from "react";
import { Space } from "antd";
import { remove as removeAccents } from "remove-accents";
import { debounce } from "lodash";
import { ProgramsSelect, SearchToolbar, BatchesSelect, Visibility } from "../../../components/shared";

export function FilterControlsOdes({ onChange, programs = true, batches = true }) {
  const [state, setState] = useState({
    programId: undefined,
    batchId: undefined
  });

  const handleOnSearchChange = debounce((text = "") => {
    const stringValue = removeAccents(text).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ searchString: value });
  }, 500);

  const handleOnChange = (filter = {}) => {
    setState(prevState => {
      let updatedState = undefined;

      if ("programId" in filter) {
        updatedState = { ...prevState, programId: filter.programId };
      }

      if ("batchId" in filter) {
        updatedState = { ...prevState, batchId: filter.batchId };
      }

      onChange && onChange(updatedState);
      return updatedState;
    });
  };

  return (
    <Space>
      {
        // TODO: This visibility is hiding things cause it was replacing
        // the FilterControlsOdes because it didn't exist in the Master branch
      }
      <Visibility visible={programs}>
        <ProgramsSelect
          onChange={val => handleOnChange({ programId: val })}
          placeholder="Programa"
          style={{ width: "350px" }} />
      </Visibility>
      <Visibility visible={batches}>
        <BatchesSelect
          onChange={val => handleOnChange({ batchId: val })}
          programId={state.programId}
          style={{ width: "350px" }} />
      </Visibility>
      <SearchToolbar
        onSearchChange={e => handleOnSearchChange(e.target.value)} />
    </Space>
  );
}

/**
 * @deprecated Use FilterControlsOdes or create your own component
 * with the right implementation
 * @type {function({onChange?: *, programs?: *, batches?: *})}
 */
export const FilterControls = FilterControlsOdes;
