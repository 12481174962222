import React, { useContext, useEffect } from "react";
import { Form } from "antd";
import { FormContainer } from "../../../";
import { fieldValidationMessages } from "../../../../../utils";
import {
  EditProfileContext
} from "../../../../../contexts/shared/edit-profile";
import {LanguagesInput } from "./languages-input";

const { Item } = Form;

export const LanguagesForm = ({ editable = false, form }) => {
  const { data } = useContext(EditProfileContext);

  const {
    languages = []
  } = data?.user?.expertProfile?.professionalExperience || {};

  useEffect(() => {
    form.setFieldsValue({
      languages
    });
  }, [data]);

  return (
    <FormContainer>
      <Form
        validateMessages={fieldValidationMessages}
        form={form}>
        <Item
          name="languages"
          initialValue={languages}>
          <LanguagesInput editable={editable} />
        </Item>
      </Form>
    </FormContainer>
  );
};
