import React, { useState, useEffect } from "react";
import { Select as ASelect, Typography, Form } from "antd";
import { normalize, schema } from "normalizr";

export function Select({
  onSave,
  odes = [],
  loading = true,
  error,
  batchId,
  items // from CompositeComponent
}) {
  const [state, setState] = useState({
    odesOptions: []
  });

  useEffect(() => {
    const selectedODEs = items.map(item => item.ode?.connect?.id);

    const odesOptions = odes
      .map(ode => ({ label: ode.name, value: ode.id }))
      .filter(option => !selectedODEs.includes(option.value));

    setState(prevState => ({
      ...prevState,
      odesOptions
    }));
  }, [odes, items]);

  const [form] = Form.useForm();

  const odesSchema =  new schema.Entity("odes");
  const normalizedOdes = normalize(odes, [odesSchema]);

  const handleOnODEsChange = odeId => {
    if (odeId) {
      const ode = normalizedOdes.entities.odes[odeId];
      delete ode.__typename;

      const selectedOde = { name: ode.name };
      selectedOde.ode = { connect: { id: ode.id } };

      onSave(selectedOde);
    }

    form.resetFields(); // clears the ODEs select
  };

  return (
    <>
      <Form form={form}>
        <Form.Item name="odes">
          <ASelect
            data-cy="odes-picker-select"
            optionFilterProp="label"
            allowClear
            showSearch
            placeholder="Selecciona las compañías"
            disabled={loading || error || !batchId}
            loading={loading}
            onChange={handleOnODEsChange}
            options={state.odesOptions} />
        </Form.Item>
      </Form>
      <Typography.Text type="danger">
        {error && "No se pudieron cargar las compañías"}
      </Typography.Text>
    </>
  );
}
