import { client } from "../../../graphql";
import { getExpertMentoringsQuery } from "./graphql/get-expert-mentorings";
import {getByIdQuery} from "./graphql/getById";


export async function getMentorings({expertId}) {
  const result = await client.query({
    query: getExpertMentoringsQuery,
    variables: {
      expertId
    },
    fetchPolicy: "network-only"
  });
  return result.data?.expertServices || [];
}

export async function getById({expertId}) {
  const result = await client.query({
    query: getByIdQuery,
    variables: {
      expertId
    },
    fetchPolicy: "network-only"
  });
  return result.data?.user;
}

export const ExpertService = {
  getById,
  getMentorings
};

