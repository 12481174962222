import gql from "graphql-tag";

export const getCalendarEventsQuery = gql`
  query BatchCalendarEvents(
    $batchId: ID!
    $month: Int!
    $year: Int!
  ) {
    batchCalendarEvents(
      batchId: $batchId
      month: $month
      year: $year
    ) {
      id
      name
      date
      type
      link
      location
      description
      meetingType
      expertServiceData
      assignedExpertService {
        expertServiceData
      }
      attachments {
        id
        fileName
        url
        type
      }
      materials {
        id
        fileName
        url
        type
        uploadDate
      }
    }
  }
`;
