import React from "react";
import { DatePicker, Form, Input } from "antd";
import { UploadArea } from "../../../../../components/shared";
import { SelectablePrograms } from "../../../batch-modal/selectable-program";

export const DetailsForm = ({ onChange }) => {
  return (
    <>
      <Form.Item
        rules={[{ required: false }]}
        name="coverPicUrl">
        <UploadArea.Crop
          multiple={false}
          maxFiles={1}
          aspect={360 / 160}>
          Puedes utilizar archivos de formato .PNG, .JPG y .JPEG
        </UploadArea.Crop>
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        label="Nombre del perk"
        name="name">
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        label="Caducidad"
        name="expiration">
        <DatePicker className="wide-input" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        label="Programa"
        name="program">
        <SelectablePrograms onChange={onChange} />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        label="Ingresa la categoría"
        name="category">
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        label="Descripción"
        name="description">
        <Input.TextArea rows={6} />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        label="Contacto"
        name="contactInfo">
        <Input />
      </Form.Item>
    </>
  );
};

