import gql from "graphql-tag";

export const getBatchesByCoordinatorIdAndProgramId = gql`
    query GetBatches($id: ID!,$programId: ID) {
    batches(
        where: {
            users_some: {
                systemRole: COORDINATOR,
                id: $id
            }
            program: { id: $programId }
        }
    ) {
        id
        name
        iname
        batchID
        startDate
        finishDate
        description
        program {
            id
            name
            type
            coverPicUrl
        }
    }
    }
`;
