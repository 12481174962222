import { useParams } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import React, { useEffect, useContext } from "react";
import { Section } from "../../../../shared";
import { client } from "../../../../../../graphql";
import { admin } from "../../../../../graphql/admin";
import { BatchesBoardCharts } from "../../show/board";
import { MenuContext } from "../../../../../contexts/shared";
import { ContentModalContextProvider } from "../../../../../contexts/shared";
import { BatchDetailsContextProvider } from "../../../../../contexts/admin/batches/batch-details";
import { BatchObjectiveModalProvider } from "../../../../../contexts/shared/batch-objective-modal";

export function BatchAnalyticsIndex() {
  const { id } = useParams();

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const {
    loading: odesLoading,
    error: odesError,
    data: odesData
  } = useQuery(admin.queries.getBatchOdesForCharts,
    {
      fetchPolicy: "cache-first",
      variables: { id: id },
      client
    });

  return (
    <BatchDetailsContextProvider>
      <ContentModalContextProvider batchId={id}>
        <BatchObjectiveModalProvider>
          <Section
            title="Tablero"
            spaced>
            <BatchesBoardCharts
              loading={odesLoading}
              error={odesError}
              odes={odesData?.oDEs} />
          </Section>
        </BatchObjectiveModalProvider>
      </ContentModalContextProvider>
    </BatchDetailsContextProvider>
  );
}
