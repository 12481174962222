import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup
} from "react-simple-maps";
import { Card, Tooltip, Typography } from "antd";
import { default as mex_map } from "./mex_map.json";
import { STATES } from "../../../../../hooks/organizations/charts/constants";
import {
  usePresenceMap
} from "../../../../../hooks/organizations/charts/use-presence-map";
import { FlexContainer } from "../../../../shared";
import { InfoCircleOutlined } from "@ant-design/icons";

const geoUrl = mex_map;

export const OrganizationsMapChart = ({ organizations }) => {

  const {
    ratioByPresence,
    ratioByNationalPresence
  } = usePresenceMap(organizations);

  const titleHandler = markName => {
    return ratioByPresence[markName] !== 0
      ? `${markName} tiene ${ratioByPresence[markName]} organización(es) estatales y hay ${ratioByNationalPresence} organizaciones con presencia nacional`
      : `${markName}, ${ratioByNationalPresence} organizaciones con presencia nacional`;
  };

  return (
    <Card
      style={{
        marginTop: "2rem" }}
        title={
            <FlexContainer
                direction="vertical">
                <Typography.Title
                    level={5}>
                    Mapa de presencias
                </Typography.Title>
                <Typography.Text
                    type={"secondary"}
                    style={{ fontSize: "12px" }}>
                    <InfoCircleOutlined /> Haz hover en alguna región del mapa para ver más detalles
                </Typography.Text>
            </FlexContainer>
        }
    >
      <ComposableMap
        height={150}
        width={300}
        projection="geoMercator">
        <ZoomableGroup
          //Choose this location because it's the center of the country
          center={[-103.109770, 24.520711]}
          zoom={2.7}>
          <Geographies
            style={{pointerEvents: "none"}}
            geography={geoUrl}>
            {({ geographies }) =>
              geographies.map(geo => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill= "#DDD"
                  stroke= "#FFF"
                  strokeWidth= ".1" />
              ))
            }
          </Geographies>
          {
            STATES.map(mark => (
              <Tooltip
                key={mark.name}
                title={titleHandler(mark.name)}>
                <Marker coordinates={mark.location}>
                  <circle
                    stroke="#6BB5EB"
                    strokeWidth={.15}
                    fill="#7BC5FB"
                    r={
                      (ratioByNationalPresence / 70) + ((ratioByPresence[mark.name]) / 5)
                    } />
                </Marker>
              </Tooltip>
            ))
          }
        </ZoomableGroup>
      </ComposableMap>
    </Card>
  );
};

