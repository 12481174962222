import React from "react";
import { Tabs } from "antd";
import { EntrepreneursCharts } from "./entrepreneurs-charts";
import { CompaniesCharts } from "./companies-charts";
import { useService } from "../../../hooks/shared";
import { ProgramService } from "../../../services/program-service";
import { ChartsDataProvider } from "./charts-data-provider";
import { AnalyticsService } from "../../../services/analytics-service";

export const DynamicDashboard = () => {
  const { data: programs, loading: loadingPrograms } = useService(
    ProgramService.getAll
  );

  const { data: careers, loading: loadingCareers } = useService(
    AnalyticsService.getEntrepreneursCareers
  );

  const { data: campus, loading: loadingCampus } = useService(
    AnalyticsService.getEntrepreneursCampusList
  );

  const chartsDataProps = {
    programs,
    loadingPrograms,
    careers,
    loadingCareers,
    campus,
    loadingCampus
  };

  return (
    <ChartsDataProvider dataProps={chartsDataProps}>
      <Tabs
        className="NewBatch__Tabs">
        <Tabs.TabPane
          tab="Dashboard de emprendedores"
          key="entrepreneurs-dashboard">
          <EntrepreneursCharts />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab="Dashboard de compañías"
          key="companies-dashboard">
          <CompaniesCharts />
        </Tabs.TabPane>
      </Tabs>
    </ChartsDataProvider>
  );
};