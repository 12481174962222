import gql from "graphql-tag";

export const getBatchesByProgramId = gql`
  query getBatchesByProgramId($id: ID!, $orderBy: BatchOrderByInput) {
    batches(
      orderBy: $orderBy,
      where: { program: { id: $id } }
    ) {
      id    
      name
      iname
      batchID
      startDate
      finishDate
      description
      program {
        id
        name
        type
        coverPicUrl
      }
    }
  }
`;
