import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Select } from "antd";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql";

export function AchievementsSelect({ batchId, excludedAchievements, ...props }) {
  const { data , loading, refetch } = useQuery(
    shared.queries.getAchievements, {
    client,
    variables: {
      where: {
        id_not_in: excludedAchievements?.map(achievement => achievement.id),
        objective:{
          batch: {id: batchId}
        }
      }
    }
  });

  const options = data ?
    data.achievements?.map(achievement => ({
        label: achievement?.name,
        value: achievement?.id
      }))
    : [];

  return(
    <Select
      {...props}
      loading={loading}
      showSearch
      optionFilterProp="label"
      placeholder="Buscar logros"
      options={options}>
    </Select>
  );
}