import React, { useContext, useMemo, useState } from "react";
import {
  SidebarLayout,
  Section,
  AddButton
} from "../../../components/shared";
import {
  FilterControls
} from "../../../components/admin/experts/list/filter-controls";
import { default as ExpertsList } from "../../../components/admin/experts/list";
import { AdminExpertsContext } from "../../../contexts/admin/experts";
import { withRouter } from "../../../helpers";
import { MenuContext } from "../../../contexts/shared/menu";
import {
  InvitationModalProvider
} from "../../../contexts/shared/invitation-modal";
import { MainMenu } from "../../../components/coordinator/shared";
import {
  ProfileVerifierProvider
} from "../../../contexts/shared/profile-verifier";
import {
  CurrentUserContext,
  PaginationContext
} from "../../../contexts/shared";
import { useQueryFilters, useControlledPagination } from "../../../helpers";
import { useService } from "../../../hooks/shared";
import {
  ExpertServiceContext
} from "../../../contexts/experts/new/service/context";

function ExpertsIndex({ navigate }) {
  const { setMenuState } = useContext(MenuContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { ExpertsService } = useContext(ExpertServiceContext);

  const userBatches = currentUser?.batches || [];

  const batchesIds = userBatches.map(batch => batch.id);
  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/profiles"]
  });

  const [refetchVariables, setRefetchVariables] = useState({
    first: 10,
    systemRole: "ADVISER",
    batches: batchesIds
  });

  const { refetch, data , error, loading } = useService(
    ExpertsService.getExperts, { ...refetchVariables, first: 10 }
  );

  const { registerRefetch } = useContext(PaginationContext);

  registerRefetch("experts", refetch, refetchVariables);

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: {
      first: 10,
      batches: batchesIds,
      systemRole: "ADVISER"
    }
  });

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 10
  });

  const onFilterChange = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));

    setRefetchVariables(prev => ({ ...prev, ...filter }));
  };

  const onClickExpert = expert => {
    navigate(`/v2/coordinator/experts/${expert.id}/profile`);
  };

  const injectActions = useMemo(() => ({
    error,
    loading,
    total: data?.data?.searchUsers?.count || 0,
    data: data?.data?.searchUsers?.users || []
  }), [data, error, loading]);

  return (
    <InvitationModalProvider>
      <AdminExpertsContext.Provider value={injectActions}>
        <SidebarLayout menu={<MainMenu />}>
          <ProfileVerifierProvider>
            <Section
              title="Expertos"
              extra={
                <FilterControls onChange={onFilterChange} />
              }
              style={{ margin: "15px 20px" }}>
              <AddButton
                onClick={() =>
                  navigate("/v2/coordinator/experts/new/personal-information")}
                style={{ width: "100%" }}>
                Agregar experto
              </AddButton>
              <ExpertsList
                onClickExpert={onClickExpert}
                onChangePage={onPaginationChange}
                currentPage={currentPage} />
            </Section>
          </ProfileVerifierProvider>
        </SidebarLayout>
      </AdminExpertsContext.Provider>
    </InvitationModalProvider>
  );
}

export default withRouter(ExpertsIndex);
