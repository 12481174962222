export const setUpdateAccounts = (accounts, state, setState) => {
  const updatedAccounts = {
    ...state.accounts,
    ...accounts
  };

  setState({
    ...state,
    accounts: updatedAccounts
  });
};
