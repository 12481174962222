import React from "react";
import { List, Typography } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { Avatar, IconLabel } from "../../../../shared";

export const TeamList = ({ team, loading }) => {
  return (
    <List
      loading={loading}
      itemLayout="vertical"
      dataSource={team}
      renderItem={member => 
        <TeamMember user={member} />
      }/>
  )
}

export const TeamMember = ({ user }) => {
  return (
    <List.Item>
      <List.Item.Meta
        avatar={<Avatar style={{ marginTop: "12px"}}>{user.profilePicUrl || user.fullName}</Avatar>}
        description={<>
          <Typography.Title level={5}>{ user.fullName }</Typography.Title>   
          <IconLabel key="ent-mail" icon={<MailOutlined />}>{user.email}</IconLabel>,
          <IconLabel key="ent-phone" icon={<PhoneOutlined />}>{user.phone}</IconLabel>
        </>}/>
    </List.Item>
  )
}