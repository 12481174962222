import gql from "graphql-tag";

export const getPrograms = gql`
  query GetPrograms($iname: String, $skip: Int, $first: Int) {
    programs(
      where: { iname_contains: $iname },
      orderBy: createdAt_DESC,
      skip: $skip,
      first: $first
    ) {
      id
      name
      type
      coverPicUrl
      disabled
      batches{
        name
      }
    }
    meta: programsConnection(
      where: { iname_contains: $iname }
    ) {
      aggregate {
        count
      }
    }
  }
`;
