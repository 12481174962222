import { client3 } from "../../../graphql";
import { getPersonsQuery } from "./graphql/get-persons";

export const getPersons = async variables => {
  const result = await client3.query({
    query: getPersonsQuery,
    fetchPolicy: "network-only",
    variables: { criteria: { ...variables } }
  });

  return result?.data?.persons || [];
};