import React from "react";
import { getBatchTeam, withLoader } from "../../../../../helpers";
import { PublishBatchLearn, PublishBatchTeam } from "../../../../shared";

const BatchPublishInformation = ({
  batch,
  data: masterContents,
  hideAlertContent,
  openModalInvitation,
  showAddTeamButton,
  refetchBatch
}) => {
  const teams = getBatchTeam(batch)?.filter(el => el.systemRole !== "ADVISER");
  const contentGroups = masterContents[0]?.contentGroups || [];
  const sort = masterContents?.[0]?.contentGroupsSort || [];

  return (
    <>
      <PublishBatchLearn
        style={{ paddingTop: 0 }}
        className="NewBatch__Publish-Learn"
        contentGroups={contentGroups}
        sort={sort}
        hideAlertContent={hideAlertContent} />
      <PublishBatchTeam
        className="NewBatch__Publish-Team"
        teams={teams}
        batch={batch}
        showAddTeamButton={showAddTeamButton}
        openModalInvitation={openModalInvitation}
        refetchBatch={refetchBatch} />
    </>
  );
};

export default withLoader(BatchPublishInformation);
