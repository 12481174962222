import React from "react";
import { Descriptions, List } from "antd";
import { FormContainer, Visibility } from "../../../../shared";
import {
  getReadableValue,
  gradesStudentList
} from "../../../../../helpers";

export const RelationshipInfo = ({ user = {} }) => (
  <FormContainer>
    <List
      dataSource={user.tecRelationships}
      renderItem={item => (
        <List.Item>
          <Visibility visible={["STUDENT", "Alumno"].includes(item.type)}>
            <Descriptions
              column={4}
              layout="vertical"
              title={getReadableValue("TEC_RELATIONSHIP_TYPES", item.type)}>
              <Descriptions.Item
                label="Matrícula"
                labelStyle={{ fontWeight: "bold" }}>
                {item?.enrollment}
              </Descriptions.Item>
              <Descriptions.Item
                label="Nivel"
                labelStyle={{ fontWeight: "bold" }}>
                {getReadableValue(gradesStudentList, item.grade)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Campus"
                labelStyle={{ fontWeight: "bold" }}>
                {getReadableValue("EDUCATION_INSTITUTIONS", item.campus)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Carrera"
                labelStyle={{ fontWeight: "bold" }}>
                {getReadableValue("CAREERS", item.careerName)}
              </Descriptions.Item>
            </Descriptions>
          </Visibility>
          <Visibility
            visible={["PROFESSOR", "COLABORATOR", "Profesor", "Colaborador"]
              .includes(item.type)}>
            <Descriptions
              column={3}
              layout="vertical"
              title={getReadableValue("TEC_RELATIONSHIP_TYPES", item.type)}>
              <Descriptions.Item
                label="Nómina"
                labelStyle={{ fontWeight: "bold" }}>
                {item?.payroll}
              </Descriptions.Item>
              <Descriptions.Item
                label="Campus"
                labelStyle={{ fontWeight: "bold" }}>
                {getReadableValue("EDUCATION_INSTITUTIONS", item.campus)}
              </Descriptions.Item>
            </Descriptions>
          </Visibility>
          <Visibility visible={["EXATEC"].includes(item.type)}>
            <Descriptions
              column={3}
              layout="vertical"
              title={getReadableValue("TEC_RELATIONSHIP_TYPES", item.type)}>
              <Descriptions.Item
                label="Matrícula"
                labelStyle={{ fontWeight: "bold" }}>
                {item?.enrollment}
              </Descriptions.Item>
              <Descriptions.Item
                label="Carrera"
                labelStyle={{ fontWeight: "bold" }}>
                {getReadableValue("CAREERS", item.careerName)}
              </Descriptions.Item>
              <Descriptions.Item
                label="Año de graduación"
                labelStyle={{ fontWeight: "bold" }}>
                {item?.graduation}
              </Descriptions.Item>
            </Descriptions>
          </Visibility>
        </List.Item>
        )} />
  </FormContainer>
);
