import { client } from "../../../graphql";
import { getAllQuery } from "./graphql";

export async function getAll() {
  const result = await client.query({
    query: getAllQuery,
    fetchPolicy: "network-only"
  });

  return result.data?.batches || [];
}