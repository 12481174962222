import React, { useState } from "react";
import { Button, Tabs } from "antd";
import { AssignationsTable } from "./tables";
import { useHistory, useLocation } from "react-router-dom";
import { MonitoringTableWrapper } from "./table-wrapper";
import {
  FilterControlsAssigned,
  FilterControlsScheduled
} from "./filter-controls";
import { ScheduledServicesList } from "./tables/scheduled-services-list";

/**
 * @param {object} props
 * @param {"ASSIGNATIONS" | "TRACKING"} props.tableType
 */
export function ServicesMonitoringPanedTables({
  tableType = "ASSIGNATIONS"
}) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const typeFromNotification = params.get("type");
  const [selectedServiceType, setSelectedServiceType] = useState(
    typeFromNotification || "MENTORSHIP"
  );

  const history = useHistory();

  const btnType = type => type === tableType
    ? "MonitoringExpert__Secondary" : "MonitoringExpert__Default";

  const ServicesTableComponent = tableType === "ASSIGNATIONS"
    ? AssignationsTable
    : ScheduledServicesList;

  const navigate = (path = "") => history.push(path);

  const FilterControls = tableType === "ASSIGNATIONS"
    ? FilterControlsAssigned
    : FilterControlsScheduled;

  const handleChangedTab = tabName => {
    setSelectedServiceType && setSelectedServiceType(tabName);
  };

  return (
    <div className="MonitoringExpert">
      <Button
        onClick={() => navigate("./assignations")}
        className={btnType("ASSIGNATIONS")}>
        Asignadas
      </Button>
      <Button
        onClick={() => navigate("./tracking")}
        className={btnType("TRACKING")}>
        En curso
      </Button>
      <Tabs
        defaultActiveKey="MENTORSHIP"
        activeKey={selectedServiceType}
        onChange={handleChangedTab}>
        <Tabs.TabPane tab="Mentoria" key="MENTORSHIP">
          <MonitoringTableWrapper
            filterControls={FilterControls}
            tableType={tableType}
            serviceType={selectedServiceType}>
            <ServicesTableComponent
              serviceType={selectedServiceType} />
          </MonitoringTableWrapper>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Capacitaciones" key="WORKSHOP">
          <MonitoringTableWrapper
            filterControls={FilterControls}
            tableType={tableType}
            serviceType={selectedServiceType}>
            <ServicesTableComponent
              serviceType={selectedServiceType} />
          </MonitoringTableWrapper>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Servicios" key="PRODUCT">
          <MonitoringTableWrapper
            filterControls={FilterControls}
            tableType={tableType}
            serviceType={selectedServiceType}>
            <ServicesTableComponent
              serviceType={selectedServiceType} />
          </MonitoringTableWrapper>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
