import React from "react";
import { Select } from "antd";
import { TEC_RELATIONSHIP_TYPES } from "../../../../../helpers";

export const TecRelTypeSelector = props => {
  return (
    <Select
      {...props}
      placeholder="Tipo de emprendedor"
      options={TEC_RELATIONSHIP_TYPES} />
  );
};
