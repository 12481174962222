import React from "react";
import { Route, Switch } from "react-router";
import { BatchesIndex } from "../../../pages/coordinator/batches";
import { BatchODEsIndex } from "../../../pages/coordinator/batches/show/odes";
import { ObjectivesIndex } from "../../../pages/coordinator/batches/show/objectives";
import {
  EntrepreneursIndex
} from "../../../pages/coordinator/batches/show/entrepreneurs";
import {
  ExpertsIndex
} from "../../../pages/coordinator/batches/show/experts";
import {
  CoordinatorsIndex
} from "../../../pages/coordinator/batches/show/coordinators";
import { BatchODEContent } from "../../../pages/admin/batches/show/odes/show";
import { PerksIndex } from "../../../pages/coordinator/batches/show/perks";
import { TasksIndex } from "../../../pages/coordinator/batches/show/tasks";
import { OdesUpdatesIndex } from "../../../pages/coordinator/batches/show/odes-updates";
import { BatchBoardIndex } from "../../../pages/coordinator/batches/show/board";
import { TrainingsIndex } from "../../../pages/coordinator/batches/show/trainings";
import { CalendarIndex } from "../../../pages/coordinator/batches/show/calendar";
import { ContentIndex } from "../../../pages/coordinator/batches/show/content";
import { AnnouncementsIndex } from "../../../pages/coordinator/batches/show/announcements";
import { AnnouncementsIndexDetail } from "../../../pages/coordinator/batches/show/announcements/detail";
import { NewBatchInformationUpdateIndex } from "../../../pages/coordinator/batches/new/information/update";
import { BatchPublishIndex } from "../../../pages/coordinator/batches/publish";
import {
    NewBatchInformationIndex,
    NewBatchContentIndex,
    NewBatchMembersIndex,
    NewBatchPublishIndex
} from "../../../pages/coordinator/batches/new";
import BatchCompaniesProgress from "../../../pages/admin/batches/show/batch-companies-progress";

export const CoordinatorBatchesRoutes = (
  <Switch>
    <Route
      exact
      path="/v2/coordinator/batches"
      component={BatchesIndex} />
    <Route
      exact
      path="/v2/coordinator/batches/:id/offer"
      component={BatchPublishIndex} />
    <Route
      exact
      path="/v2/coordinator/batches/new"
      component={NewBatchInformationIndex} />
    <Route
      exact
      path="/v2/coordinator/batches/new/:id"
      component={NewBatchInformationUpdateIndex} />
    <Route
      exact
      path="/v2/coordinator/batches/new/:id/members"
      component={NewBatchMembersIndex} />
    <Route
      exact
      path="/v2/coordinator/batches/new/:id/content"
      component={NewBatchContentIndex} />
    <Route
      exact
      path="/v2/coordinator/batches/new/:id/publish"
      component={NewBatchPublishIndex} />
    <Route
      exact
      path="/v2/coordinator/batches/:id/odes"
      component={BatchODEsIndex} />
    <Route
      path="/v2/coordinator/batches/:id/odes/:odeId/content"
      component={BatchODEContent} />
    <Route
      path="/v2/coordinator/batches/:id/objectives"
      component={ObjectivesIndex} />
    <Route
      path="/v2/coordinator/batches/:id/entrepreneurs"
      component={EntrepreneursIndex} />
    <Route
      path="/v2/coordinator/batches/:id/experts"
      component={ExpertsIndex} />
    <Route
      path="/v2/coordinator/batches/:id/coordinators"
      component={CoordinatorsIndex} />
    <Route
      path="/v2/coordinator/batches/:id/perks"
      component={PerksIndex} />
    <Route
      path="/v2/coordinator/batches/:id/tasks"
      component={TasksIndex} />
    <Route
      path="/v2/coordinator/batches/:id/odes-updates"
      component={OdesUpdatesIndex} />
    <Route
      path="/v2/coordinator/batches/:id/board"
      component={BatchBoardIndex} />
    <Route
      path="/v2/coordinator/batches/:id/trainings"
      component={TrainingsIndex} />
    <Route
      path="/v2/coordinator/batches/:id/calendar"
      component={CalendarIndex} />
    <Route
      path="/v2/coordinator/batches/:id/content"
      component={ContentIndex} />
    <Route
      path="/v2/coordinator/batches/:id/announcements"
      exact={true}
      component={AnnouncementsIndex} />
    <Route
      path="/v2/coordinator/batches/:id/announcements/:announcementsId"
      component={AnnouncementsIndexDetail} />
    <Route
      path="/v2/coordinator/batches/:id/batch-companies-progress"
      component={BatchCompaniesProgress} />
  </Switch>
);
