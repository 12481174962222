import { Card } from "antd";
import { Bar } from "react-chartjs-2";
import React, { useMemo } from "react";
import { ChartDataFeedback } from "../../chart-data-feedback";
import { useCompaniesByDimensions } from "../use-companies-by-dimensions";
import { SALES_STAGE_CHART_OPTIONS } from "./sales-stage-chart-options";
import { salesStageToChart } from "./sales-stage-to-chart";

export const SalesStageChart = ({ filters = {} }) => {
  const { loading, results } = useCompaniesByDimensions({
    dimensions: ["companySalesStage"],
    mustBeSet: ["companySalesStage"],
    ...filters
  });

  const chartData = useMemo(() => {
    if (!results) return ({ labels: [], datasets: [] });

    const [result] = results;
    return salesStageToChart(result.data);
  }, [results]);

  return (
    <Card
      spaced
      bordered
      style={sectionStyle}
      bodyStyle={{ height: "max-content" }}
      title="Compañías por etapa de ventas">
      <ChartDataFeedback
        loading={loading}
        isEmpty={!chartData?.datasets?.length}>
        <Bar
          height={300}
          type={"bar"}
          data={chartData}
          options={SALES_STAGE_CHART_OPTIONS} />
      </ChartDataFeedback>
    </Card>
  );
};

const sectionStyle = {
  marginTop: 16,
  boxShadow: "none",
  borderRadius: "0"
};
