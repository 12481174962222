import React, { useState, useContext } from "react";
import { useMutation } from "react-apollo-hooks";
import { CreateOdeModalContext } from "./context";
import { CreateOdeModal } from "./modal";
import { shared } from "../../../graphql/shared";
import { coordinator } from "../../../graphql/coordinator";
import { CurrentUserContext } from "../../../contexts/shared";
import { PaginationContext } from "../../../contexts/shared";
import { systemRoleEnum } from "../../../helpers";
import { UsageTrackingService } from "../../../services";

export function CreateOdeModalProvider({ children }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { currentUser } = useContext(CurrentUserContext);

  const { refetchList } = useContext(PaginationContext);

  const [createOde] = useMutation(shared.mutations.createOde, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: shared.queries.getOdes,
        variables: {
          ...refetchList.odes?.variables
        }
      },
      {
        query: shared.queries.getOdesByUserId,
        variables: {
          id: currentUser.id,
          ...refetchList.odes?.variables
        }
      },
      {
        query: coordinator.queries.getOdesByCoordinatorId,
        variables: {
          id: currentUser.id,
          ...refetchList.odes?.variables
        }
      }
    ]
  });

  const openModal = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setVisible(false);
  };

  const save = async (data = {}) => {
    if (currentUser.systemRole === systemRoleEnum.COORDINATOR)
      data.network = { connect: { id: currentUser.id } };

    if (currentUser.systemRole === systemRoleEnum.ENTREPRENEUR)
      data.team = { connect: { id: currentUser.id } };

    const result = await createOde({ variables: { data } });

    try {
      UsageTrackingService.trackNewCompany({
        company: {
          id: result.data?.createODE.id,
          name: data?.name,
          logoUrl: data?.logoUrl
        },
        createdBy: {
          id: currentUser?.id,
          systemRole: currentUser?.systemRole,
          email: currentUser?.email,
          fullName: currentUser?.fullName
        },
        creationType: "MANUAL_CREATION"
      });
    } catch (err) {
      console.error(err);
    }

    return result;
  };

  const value = {
    openModal,
    onCancel,
    save,
    loading,
    setLoading
  };

  return(
    <CreateOdeModalContext.Provider value={value}>
      <CreateOdeModal onCancel={onCancel} visible={visible} />
      {children}
    </CreateOdeModalContext.Provider>
  );
}
