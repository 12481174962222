import React from "react";
import { List } from "antd";
import styled from "styled-components";
import moment from "moment";

import { educationalDegrees } from "../../../../../../../../utils/maps";

const { Item: ANTItem } = List;
const Item = styled(ANTItem)`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: left;
`;

export const EducationListItem = ({ educationRecord = {} }) => {
  const {
    institution = {},
    degree,
    specialityArea,
    graduationDate
  } = educationRecord;
  return (
    <Item>
      <div>
        <div style={{ fontWeight: "bold" }}>
          {institution?.name}
        </div>
        <div>
          {educationalDegrees[degree]} - {specialityArea}
        </div>
        <div>
          {moment(graduationDate).format("yyyy")}
        </div>
      </div>
    </Item>
  );
};
