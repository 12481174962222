import gql from "graphql-tag";

export const getPerksQuery = gql`
  query GetPerksByBatchId ( $id: ID! ) {
    perks(
      where: {
        batches_some: {id:$id}
      }
    ) {
      id
      coverPicUrl
      name
      expiration
      program{
        id
        name
        coverPicUrl
      }
      category
      description
      contactInfo
      batches{
        id
        name
        program{
          id
          coverPicUrl
        }
      }
      odes{
        id
        name
        logoUrl
      }
    }
  } 
`;
