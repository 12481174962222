import React from "react";
import { Skeleton } from "antd";

export function ParagraphSkeleton({ paragraphs = 1 }) {

  const renderSkeletons = (p = 1) => {
    const nodes = [];

    for(let i = 0; i < p; i++) {
      nodes.push({});
    }

    return nodes.map((SkeletonParagraph, idx) =>
      <Skeleton active key={idx.toString()} />);
  };

  return (
    <div>
      {renderSkeletons(paragraphs)}
    </div>);
}
