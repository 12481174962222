import React from "react";
import { Route, Switch } from "react-router";
import { AsemOrganizationIndex } from "../../pages/asem/organizations";

export const AsemRoutes = (
  <Switch>
    <Route
      exact
      path="/v2/asem"
      component={AsemOrganizationIndex} />
  </Switch>
);