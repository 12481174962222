import React from "react";
import { Button } from "antd";
import { AssignationsTable, ScheduledTable } from "./tables";
import { useHistory } from "react-router-dom";
import {
  FilterControlsAssigned,
  FilterControlsScheduled
} from "./filter-controls";
import { SessionsTableWrapper } from "./table-wrapper";

/**
 * @param {object} props
 * @param {"ASSIGNATIONS" | "TRACKING"} props.tableType
 */
export function ServicesSessionsTables({
  tableType = "ASSIGNATIONS"
}) {
  const history = useHistory();

  const btnType = type => type === tableType ? "default" : "secondary";

  const ServicesTableComponent = tableType === "ASSIGNATIONS"
    ? AssignationsTable
    : ScheduledTable;

  const navigate = (path = "") => history.push(path);

  const FilterControls = tableType === "ASSIGNATIONS"
    ? FilterControlsAssigned
    : FilterControlsScheduled;

  return (
    <div className="SessionsExpert">
      <Button
        onClick={() => navigate("./assignations")}
        type={btnType("ASSIGNATIONS")}>
        Asignadas
      </Button>
      <Button
        type={btnType("TRACKING")}
        onClick={() => navigate("./tracking")}>
        En curso
      </Button>
      <SessionsTableWrapper
        filterControls={FilterControls}
        tableType={tableType}>
        <ServicesTableComponent />
      </SessionsTableWrapper>
    </div>
  );
}
