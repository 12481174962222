import React, { useCallback } from "react";
import {
  Tag,
  Modal,
  Button,
  Divider,
  Skeleton,
  Typography,
  Descriptions
} from "antd";
import moment from "moment";
import {
  getReadableValue,
  expertServiceCategories,
  expertServiceTypes
} from "../../../../../helpers/select-options";
import {
  OdeTag,
  ContactInfo,
  Visibility,
  LinksDisplay
} from "../../../../../components/shared";

export function ServiceApprovalModal({
  onSave,
  onCancel,
  onReject,
  scheduledService = {},
  loading = false,
  ...props
}) {
  const { assignedExpertService = {} } = scheduledService;
  const { expertServiceData = {} } = assignedExpertService;
  const { program } = assignedExpertService;

  const isWorkshop = expertServiceData?.type === "WORKSHOP";

  const modalTitle = useCallback(serviceType => {
    const readableType = getReadableValue(
      expertServiceTypes,
      serviceType
    )?.toLowerCase();

    const finalLetter = readableType?.split("").pop();
    return `${readableType} agendad${finalLetter}`;
  });

  return (
    <Modal
      onCancel={onCancel}
      title={modalTitle(expertServiceData?.type)}
      footer={[
        <Button
          loading={loading}
          danger
          key="reject"
          type="primary"
          onClick={() => onReject(scheduledService)}>
          Rechazar solicitud
        </Button>,
        <Button
          loading={loading}
          key="ok"
          type="primary"
          onClick={() => onSave(scheduledService)}>
          Aceptar solicitud
        </Button>
      ]}
      {...props}>
      <Visibility visible={loading}>
        <Skeleton />
        <Skeleton />
      </Visibility>
      <Visibility visible={!loading}>
        <Typography.Title level={3}>
          {expertServiceData?.name}
        </Typography.Title>
        <Typography.Text>
          {expertServiceData?.description}
        </Typography.Text>
        <Descriptions
          layout="vertical"
          column={1}
          style={{ marginTop: "20px" }}>
          <Descriptions.Item label="Categoría">
            <Tag color="purple">
              {getReadableValue(expertServiceCategories, expertServiceData?.category)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Número de asignación">
            {assignedExpertService?.assignmentNumber || "N/A"}
          </Descriptions.Item>
          { !isWorkshop &&
            <Descriptions.Item label="Compañía">
              <OdeTag odeName={assignedExpertService?.ode?.name} />
            </Descriptions.Item> }
        </Descriptions>
        <Descriptions
          layout="vertical"
          column={2}>
          <Descriptions.Item label="Fecha y hora propuesta por la Compañía">
            {scheduledService?.serviceDate ?
              moment(scheduledService?.serviceDate)
                .format("DD/MM/YYYY, h:mm a")
              : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Cantidad">
            {scheduledService
              .assignedExpertService
              ?.expertServiceData?.method === "HOURS" ?
              `${scheduledService.duration} horas`
              : "1 servicio"}
          </Descriptions.Item>
        </Descriptions>
        <ContactInfo
          title="Coordinador del programa"
          user={
            program?.coordinator ||
            assignedExpertService?.batch?.coordinators?.find(Boolean)
          } />
        <Visibility visible={!isWorkshop}>
          <ContactInfo
            title="Emprendedor"
            user={scheduledService?.requestedBy} />
        </Visibility>
        <Visibility visible={!!scheduledService.calendarEventUri}>
          <Divider>Evento</Divider>
          <LinksDisplay
            links={[{
              fileName: "evento.ics",
              url: scheduledService.calendarEventUri || ""
            }]} />
        </Visibility>
      </Visibility>
    </Modal>
  );
}
