import gql from "graphql-tag";

export const cloneBatch = gql`
  mutation CloneBatch($id: ID!, $data: BatchCloneInput) {
    cloneBatch(where: { id: $id }, data: $data) {
      id
      name
    }
  }
`;
