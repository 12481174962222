export const statusTagColors = {
  ASSIGNED: "default",
  REQUESTED: "#108ee9",
  SCHEDULED: "magenta",
  APPROVED: "purple",
  ON_GOING: "gold",
  FINISHED: "cyan",
  AUTHORIZED_PAYMENT: "green",
  PAID: "blue"
};
