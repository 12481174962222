import { client } from "../../../graphql";
import { getAvailableServices } from "./graphql";

export const getAvailableServicesService = async ({ batchId }) => {
  const result = await client.query({
    query: getAvailableServices,
    variables: {
      id: batchId
    },
    fetchPolicy: "network-only"
  });

  return result?.data?.batch?.services || [];
};
