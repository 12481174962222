import React, { useState } from "react";
import { BatchModalContext } from "./context";
import { BatchModal } from "./modal";

export function BatchModalProvider({children}) {
  const [state, setState] = useState({
    isModalOpen: false,
    programId: undefined
  });

  const openModal = ({ programId }) => {
    setState({
      isModalOpen: true,
      programId
    });
  };

  const closeModal = () => {
    setState({
      isModalOpen: false,
      programId: undefined
    });
  };

  const onSave = () => {
    closeModal();
  };

  return (
    <BatchModalContext.Provider value={{openModal, closeModal}}>
      <BatchModal
        programId={state.programId}
        visible={state.isModalOpen}
        onCancel={closeModal}
        onSave={onSave} />
      {children}
    </BatchModalContext.Provider>
  );
}
