import gql from "graphql-tag";

export const GET_ORGANIZATIONS_BY_FILTERS = gql`
  query GetOrganizationsByFilters(
      $searchText: String
      $origin: [String]
      $states: [String]
      $services: [String]
      $stages: [String]
      $ranByWomen: [Boolean]
      $skip: Int
      $first: Int
  ) {
      organizationsPaginationCount(
        criteria: {
            searchText: $searchText
            origin: $origin
            states: $states
            services: $services
            stages: $stages
            ranByWomen: $ranByWomen
        }
      ) {
          count
      }
      
      organizationsByFilters(
        criteria: {
          searchText: $searchText
          origin: $origin
          states: $states
          services: $services
          stages: $stages
          ranByWomen: $ranByWomen
        }
        pagination: {
          skip: $skip
          first: $first
        }
      ) {
          id
          logo
          name
          iname
          startedAt
          private
          stages
          istages
          ranByWomen
          nationalPresence
          states {
              name
          }
          services
          iservices
      }
  }
`;