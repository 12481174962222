import gql from "graphql-tag";

export const getServicesLightQuery = gql`
  query GetServicesLightQuery {
    expertServices {
      id
      name
    }
  }
`;
