import gql from "graphql-tag";

export const updateContent = gql`
  mutation UpdateContent(
    $data: ContentUpdateInput!,
    $where: ContentWhereUniqueInput!
  ) {
    updateContent(data: $data, where: $where) {
      id
    }
  }
`;
