import gql from "graphql-tag";

export const getUsersBySystemRole = gql`
 query GetUsersBySystemRole (
   $ifullName: String, 
   $systemRoles: [SystemRole!]
   $filterUsers: [ID!]
 ) {
  users(
    where: {
      ifullName_contains: $ifullName
      systemRole_in: $systemRoles
      id_not_in: $filterUsers
    }) {
    id
    systemRole
    fullName
    email
    phone
    profilePicUrl
    expertProfile {
      id
      resume
    }
  }
}
`;
