import React, { useState } from "react";
import { ObjectiveModalContext } from "./context";
import { ObjectiveModal } from "./modal";

export function ObjectiveModalProvider({children}) {
  const [state, setState] = useState({ isModalOpen: false, programId: undefined });

  const openModal = programId => {
    setState({ isModalOpen: true, programId: programId });
  };

  const closeModal = () => {
    setState({ isModalOpen: false, programId: undefined });
  };

  const onSave = () => {
    closeModal();
  };

  return (
    <ObjectiveModalContext.Provider value={{openModal, closeModal}}>
      <ObjectiveModal
        visible={state.isModalOpen}
        onCancel={closeModal}
        onSave={onSave}
        programId={state.programId} />
      {children}
    </ObjectiveModalContext.Provider>
  );
}
