import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, Input, Typography, message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { client } from "../../../../graphql";
import { admin } from "../../../graphql/admin";
import {
  genericFieldValidationMsgs
} from "../../../utils/fieldValidationMessages";
import { CurrentUserContext } from "../current-user";

export function CloneBatchModal({
  onSave,
  onCancel,
  batch,
  ...props
}) {
  const [form] = Form.useForm();
  const [state, setState] = useState({
    batch: undefined
  });

  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    form.setFieldsValue({ name: `Copia de ${batch.name}` });
    setState(prevState => ({  ...prevState, batch }));
  }, [batch]);

  const [cloneBatch] = useMutation(
    admin.mutations.cloneBatch, {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: admin.queries.getBatches,
        variables: {
          first: 10
        }
      }]
    }
  );

  const onOk = async() => {
    const dismissLoading = message.loading("Guardando...");
    let values = form.getFieldsValue();

    try {
      values = {
        ...values,
        users: {
          connect: {
            id: currentUser.id
          }
        }
      };

      const newBatch = await cloneBatch({
        variables: {
          id: state.batch.id,
          data: values
        }
      });

      message.success("Batch creado.");

      onSave && onSave(newBatch);
      form.resetFields();
    } catch(e) {
      if (e.message.match(/NAME_ALREADY_TAKEN/)) {
        return message.warning(`Ya existe un batch con el nombre\
           "${values.name}". Por favor, ingresa un nombre diferente.`);
      }

      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    } finally {
      dismissLoading();
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  return (
    <Modal
      destroyOnClose
      okText="Guardar"
      cancelText="Cancelar"
      title="Copiar batch"
      onOk={onOk}
      onCancel={onCancelModal}
      {...props}>
      <Form
        validateMessages={genericFieldValidationMsgs}
        form={form}
        layout="vertical">
        <Form.Item>
          <Typography.Text>
            Escribe el nombre para la copia del batch
          </Typography.Text>
        </Form.Item>
        <Form.Item
          label="Nombre del batch"
          name="name">
          <Input
            placeholder="Nombre del batch" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
