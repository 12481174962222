import React, { useState } from "react";
import {} from "antd";
import { AddButton } from "../../../../../shared/add-button/index";
import { AddOdeModal } from "./add-ode-modal";

const addButtonStyle = {
  width: "100%",
  marginBottom: "24px",
  marginTop: "24px"
};

export function AddOdeButton() {
  const [state, setState] = useState({
    isModalVisible: false
  });

  const openModal = () =>
    setState({ isModalVisible: true });

  const closeModal = () =>
    setState({ isModalVisible: false });

  return (
    <>
      <AddOdeModal
        closeModal={closeModal}
        visible={state.isModalVisible} />
      <AddButton style={addButtonStyle} onClick={openModal}>
        Agregar Compañía
      </AddButton>
    </>
  );
}

