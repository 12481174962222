
export const BUSINESS_MODEL_CHART_OPTIONS = {
  plugins: {
    legend: {
      position: "bottom"
    },
    datalabels: {
      color: "#000"
    }
  },
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  },
  maintainAspectRatio: false
};
