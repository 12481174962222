import React from "react";
import { AddCompanyTeamMembersModal } from "../../../../modals";
import { ModalButton } from "../../../../buttons";
import { capitalize } from "lodash";

export const AddTeamMemberButton = ({
  type,
  title = "Agregar miembros",
  onAddCompanyTeamMembers,
  availableUsers = [],
  loadingAvailableUsers,
  refetchAvailableUsers
}) => {
  const mapping = {
    "ENTREPRENEUR": "emprendedores",
    "ADVISER": "expertos"
  };

  const onAdd = async ({ users }) => {
    await onAddCompanyTeamMembers({ users, type });
  };

  return (
    <ModalButton
      ghost
      type="primary"
      title={title}
      modal={AddCompanyTeamMembersModal}
      modalProps={{
        title: `Agregar ${mapping[type]}`,
        description:
          `Selecciona a los ${mapping[type]} que quieras agregar a la compañía.`,
        inputLabel: capitalize(mapping[type]),
        inputPlaceholder: `Buscar ${mapping[type]}`,
        availableUsers,
        loadingAvailableUsers,
        refetchAvailableUsers,
        onOk: onAdd
      }} />
  );
};

