import React from "react";
import { ListViewButton, RoleGuard } from "../..";
import { AddContentGroupButton } from "./add-button";
import { PublishButton } from "./publish-button";
import { Space, Typography } from "antd";
import { Visibility } from "../../visibility";

export function BatchContentHeader({
  batchId,
  data,
  isListView,
  isHeaderVisible = true,
  afterPublishContentChanges
}) {
  return (
    <>
      <Visibility visible={isHeaderVisible}>
        <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR"]}>
          <PublishButton
            batchId={batchId}
            masterContent={data?.masterContents?.[0]}
            afterPublishContentChanges={afterPublishContentChanges} />
        </RoleGuard>
      </Visibility>
      <Space style={{ width: "100%", justifyContent: "space-between" }}>
        <Typography.Title level={5}>
          {isListView ? "Bloques de contenido" : "Tabla de contenidos"}
        </Typography.Title>
        <Visibility visible={isHeaderVisible}>
          <ListViewButton />
        </Visibility>
      </Space>
      <br />
      <Typography.Text>
        {isListView
          ? `Aquí verás los contenidos de tu batch divididos en secciones.
              Dentro de ellas están los recursos y actividades que te ayudarán a
              lograr cada una de las secciones.`
          : `Aquí verás los contenidos de tu batch en forma de lista, da
              click en cada uno para ver el detalle.`
        }
      </Typography.Text>
      <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR"]}>
        <AddContentGroupButton />
      </RoleGuard>
    </>
  );
}
