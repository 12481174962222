import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Avatar, IconLabel } from "../../../../../shared";
import { List } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

export function EntrepreneurItem({ entrepreneur }) {
  const {
    fullName = "Sin nombre",
    email = "N/A",
    phone = "N/A",
    profilePicUrl
  } = entrepreneur;

  const { id } = useParams();

  const history = useHistory();

  const handleEntrepreneurClick = () => {
    history.push(`../../entrepreneurs/${entrepreneur.id}/personal-information`);
  };

  const getCurrentODEName = (entrepreneurOdes = []) => {
    const odeNames = [];

      entrepreneurOdes.forEach(({ ode }) => {
        const batchesWithCurrentId = ode[0]?.batches
          .filter(batch => batch?.id === id);

        if(batchesWithCurrentId) {
          batchesWithCurrentId.forEach(() => {
            odeNames.push(ode[0]?.name);
          });
        }
      });
    return odeNames;
  };



  return (
    <List.Item
      onClick={handleEntrepreneurClick}
      className="clickable"
      actions={[
        <IconLabel key="ent-mail" icon={<MailOutlined />}>{email}</IconLabel>,
        <IconLabel key="ent-phone" icon={<PhoneOutlined />}>{phone}</IconLabel>
      ]}>
      <List.Item.Meta
        avatar={<Avatar>{profilePicUrl || fullName}</Avatar>}
        title={fullName}
        description={`${getCurrentODEName(entrepreneur?.odes)}`} />
    </List.Item>
  );
}
