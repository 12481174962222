import React from "react";
import { OnboardingLayout } from "../../../../components/shared";
import {
  OnboardingContextProvider
} from "../../../../contexts/coordinator/onboarding/";
import {
  default as PersonalInformation
} from "../../../../components/coordinator/onboarding/personal-information";

export const PersonalInformationIndex = () => {
  return (
    <OnboardingContextProvider>
      <OnboardingLayout>
        <PersonalInformation />
      </OnboardingLayout>
    </OnboardingContextProvider>
  );
};
