import React from "react";
import moment from "moment";
import { Section } from "../../../../../shared";
import { validationMessages } from "./validation-messages";
import { Col, DatePicker, Form, Input, Row, Select, Typography } from "antd";
import {
  SelectablePrograms
} from "../../../../../../contexts/shared/batch-modal/selectable-program";
import { institutions } from "../../../../../../utils/maps";

export const InformationForm = ({ form, program, onSelectProgram }) => {
  const { Text } = Typography;
  const { TextArea } = Input;

  const getLabel = text =>
    <Text style={{ fontSize: "14px"}} strong>{text}</Text>;

  return (
    <Form form={form} layout="vertical">
      <Section style={{ marginBottom: "11px" }}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="name"
              label={getLabel("Nombre del batch")}
              rules={[validationMessages.name]}>
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="programId"
              label={getLabel("Programa al que pertenece el batch")}
              rules={[validationMessages.programId]}>
              <SelectablePrograms program={program} onChange={onSelectProgram} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="institution"
              label={getLabel("Institución a la que pertenece")}
              rules={[validationMessages.institution]}>
              <Select
                showSearch
                optionFilterProp="label"
                placeholder="Selecciona una institución"
                options={institutions} />
            </Form.Item>
          </Col>
        </Row>
      </Section>
      <Section style={{ marginBottom: "11px" }}>
        <Form.Item
          name="description"
          label={getLabel("Acerca del batch")}
          rules={[validationMessages.description]}>
          <TextArea
            rows={8}
            placeholder="Agrega una breve descripción acerca del batch" />
        </Form.Item>
      </Section>
      <Section>
        <Form.Item
          name="dates"
          label={getLabel("Fecha de inicio y fin")}
          rules={[validationMessages.dates]}>
          <DatePicker.RangePicker
            className="wide-input"
            disabled={!program}
            format={"DD/MM/YYYY"}
            ranges={{
              Hoy: [moment(), moment()]
            }} />
        </Form.Item>
      </Section>
    </Form>
  );
};
