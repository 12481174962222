import React, { useContext, useEffect } from "react";
import {Tabs, Typography} from "antd";
import { MenuContext } from "../../../contexts/shared";
import { CurrentUserContext } from "../../../contexts/shared";
import { MainMenu } from "../../../components/coordinator/shared";
import {
  Section,
  SidebarLayout,
  AttendanceImporter,
  ExperiencesImporter,
  SubjectEnrollmentImporter,
  EnrollmentAcademicsImporter, SubjectScoresImporter
} from "../../../components/shared";

export const EntrepreneurSpiritImportersIndex = () => {

  const { setMenuState } = useContext(MenuContext);
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["entrepreneur-spirit"],
      selectedKeys: ["entrepreneur-spirit"]
    });
  }, []);

  const tabsProps = {
    className: "asem__Tabs",
    defaultActiveKey: "list"
  };

  return (
    <SidebarLayout menu={<MainMenu />}>
      <Section
        spaced
        className="asem OrganizationIndex">
        <Typography.Title
          level={4}
          style={{
            color: "#5F39DE",
            marginBottom: "1rem"
        }}>
          Dashboard Espíritu Emprendedor
        </Typography.Title>
        <Tabs {...tabsProps}>
          <Tabs.TabPane tab="Importador de Inscritos/Académica" key="enrollment">
            <EnrollmentAcademicsImporter />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Importador de Experiencias" key="experiences">
            <ExperiencesImporter />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Importador de Asistencias" key="attendance">
            <AttendanceImporter />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Importador de Alumnos inscritos a materias de emprendimiento"
            key="subject">
            <SubjectEnrollmentImporter />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Importador de Calificaciones" key="scores">
            <SubjectScoresImporter />
          </Tabs.TabPane>
        </Tabs>
      </Section>
    </SidebarLayout>
  );
};
