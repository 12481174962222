import React from "react";
import {
  LoginLayout,
  PasswordResetRequestForm
} from "../../../components/shared";

export function RequestIndex() {
  return (
    <LoginLayout>
      <PasswordResetRequestForm />
    </LoginLayout>
  );
}
