import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Typography, Form, Button, Divider, message } from "antd";
import {
  RegisterOrganizationForm
} from "../../../components/shared/register-organization-form";
import {
  formatValues
} from "../../../components/shared/register-organization-form/format-values";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";
import { HeroAsem } from "../../../components/shared";

export const RegisterOrganizationIndex = () => {
  const [selectedStates, setSelectedStates] = useState([]);
  const [nationalPresence, setNationalPresence] = useState(false);
  const [stateErrorMessage, setStateErrorMessage] = useState({
    validateStatus: "",
    validateMessage: ""
  });

  const history = useHistory();
  const [form] = Form.useForm();
  const { Title } = Typography;

  const [createOrganization, { loading }] = useMutation(
    shared.mutations.createOrganization, { client }
  );

  const onSend = async () => {
    if(!nationalPresence && selectedStates.length === 0) {
      setStateErrorMessage({
        validateStatus: "error",
        validateMessage: "Agrega al menos una presencia"
      });
    } else {
      await form.validateFields();
      const values = form.getFieldsValue();
      const formatedValues = formatValues(values, selectedStates);

      try {
        await createOrganization({
          variables: {
            data: formatedValues
          }
        });
        onCancel();
        history.push("./register-organization/success");
      } catch {
        message.error("Ha ocurrido un error, inténtalo de nuevo\
        en unos segundos.");
      }
    }
  };

  const onCancel = () => {
    setSelectedStates([]);
    form.resetFields();
    history.push("/v2/organizations");
  };

  return (
    <div>
      <HeroAsem />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "33px"
        }}>
        <div style={{
          width: "895px",
          display: "flex",
          flexDirection: "column"
        }}>
          <Title
            level={2}
            style={{ color: "#5F39DE", marginBottom: 0 }}>
            Registra tu organización
          </Title>
          <RegisterOrganizationForm
            form={form}
            selectedStates={selectedStates}
            setSelectedStates={setSelectedStates}
            stateErrorMessage={stateErrorMessage}
            setStateErrorMessage={setStateErrorMessage}
            nationalPresence={nationalPresence}
            setNationalPresence={setNationalPresence} />
          <Divider />
          <div style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginBottom: "50px"}}>
            <Button
              loading={loading} onClick={onSend}
              type="primary">Enviar solicitud de registro</Button>
            <Button
              onClick={onCancel}
              style={{ marginRight: "8px" }}>Cancelar</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
