import React, { useEffect } from "react";
import { Modal, Form, Input, DatePicker, Radio } from "antd";
import { merge } from "lodash";
import moment from "moment";

export function PaperModal({ onSave, onCancel, edit, ...props }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        ...edit,
        publishedDate: moment(edit?.publishedDate)
      });
    }
  }, [edit]);

  const onOk = async() => {
    try {
      await form.validateFields();
      let values = form.getFieldsValue();

      if (typeof edit?.index !== "undefined") {
        values.index = edit.index;
        values = merge(edit, values);
      }

      await onSave(values);
      form.resetFields();
    }
    catch(e) {
      console.error(e);
    }
  };

  const handleOnCancel = e => {
    if (onCancel) {
      onCancel(e);
    }
    form.resetFields();
  };

  return (
    <Modal
      className="modal-investigation"
      okText="Guardar"
      cancelText="Cancelar"
      title="Educación"
      onOk={onOk}
      onCancel={handleOnCancel}
      {...props}>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          name="title"
          label="Titulo"
          rules={[{ required: true }]}>
          <Input placeholder="Título" />
        </Form.Item>
        <Form.Item
          name="type"
          label="Tipo"
          rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value="RESEARCH">Investigación</Radio>
            <Radio value="PUBLISHING">Publicación</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="institutionName"
          label="Institución"
          rules={[{ required: true }]}>
          <Input placeholder="Institución" />
        </Form.Item>
        <Form.Item
          name="publishedDate"
          label="Fecha de publicación"
          rules={[{ required: true }]}>
          <DatePicker className="wide-input" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
