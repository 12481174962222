import { remove as removeAccents } from "remove-accents";

export const formatValues = (values, invitationId) => {
  if (values.profilePicUrl?.length) {
    values.profilePicUrl = values.profilePicUrl[0]?.url
      || values.profilePicUrl[0].response?.imageUrl;
  }
  const fullName = [values.firstName.trim(), values.middleName.trim(), values.lastName.trim()]
  .filter(e => e !== undefined)
  .join(" ");
  values.fullName = fullName;
  values.ifullName = removeAccents(values.fullName.toLowerCase());
  if(invitationId) {
    values.invitationId = invitationId;
  }
  delete values.confirmPassword;

  return values;
};

