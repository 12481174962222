/**
 * @param {rawData[]} subjectScoreRows
 * @return {subjectScoresInput[]}
 */

export const formatSubjectsScoresForImportation = (subjectScoreRows = []) => {
  return subjectScoreRows.map(raw => ({
    enrollmentSchoolControlNumber: raw.Matricula,
    subjectCode: String(raw["Clave Materia"]),
    originalScore: String(raw["Calificación Final"]),
    score: parseInt(raw["Valor calificación final"])
  }));
};

/**
 * @typedef subjectScoresInput
 * @property enrollmentSchoolControlNumber: string
 * @property subjectCode: string
 * @property originalScore: string
 * @property score: number
 */

/**
 * @typedef rawData
 * @property {string} Matricula
 * @property {string} Clave Materia
 * @property {string} Nombre Materia Largo
 * @property {string} Calificación Final
 * @property {string} Valor calificación final
 */
