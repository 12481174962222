import { gql } from "graphql-tag";

export const getScheduledWorkshopServices = gql`
  query GetScheduledWorkshopServices(
    $skip: Int
    $first: Int
    $category: String
    $status: String
    $programId: ID
    $searchString: String
    $fromAcceptedDate: DateTime
    $toAcceptedDate: DateTime
  ) {
    scheduledExpertServices: monitoringScheduledWorkshops(
      orderBy: createdAt_DESC
      where: {
        status: $status
        acceptedAt_gte: $fromAcceptedDate
        acceptedAt_lte: $toAcceptedDate
        assignedExpertService: { 
          expertService: { 
            category: $category 
          }
          batch: {
            program: {
              id: $programId
            }
          }
          OR: [
            {
              expertService: {
                OR: [
                  { iname_contains: $searchString },
                  { idescription_contains: $searchString },
                  { expert: { ifullName_contains: $searchString } }
                ]
              }
            },
            {
              batch: { program: { iname_contains: $searchString } }
            },
            {
              batch: { iname_contains: $searchString }
            },
            {
              ode: { iname_contains: $searchString }
            }
          ]
        }
      }
      skip: $skip
      first: $first
    ) {
      id
      status
      duration
      assignedExpertService{
        id
        assignationIdentifier
        isProbono
        expertServicePrice
        assignedUnits
        expertServiceData
        assignmentDate
        limitDate
        status
        batch {
          id
          name
          program {
            id
            name
          }
        }
        expertService {
          id
          name
          type
          category
          duration
        }
        ode {
          id
          name
        }
      }
    }
    meta: monitoringScheduledWorkshopsConnection(
      where: {
        status: $status
        acceptedAt_gte: $fromAcceptedDate
        acceptedAt_lte: $toAcceptedDate
        assignedExpertService: { 
          expertService: { 
            category: $category 
          }
          batch: {
            program: {
              id: $programId
            }
          }
          OR: [
            {
              expertService: {
                OR: [
                  { iname_contains: $searchString },
                  { idescription_contains: $searchString },
                  { expert: { ifullName_contains: $searchString } }
                ]
              }
            },
            {
              batch: { program: { iname_contains: $searchString } }
            },
            {
              batch: { iname_contains: $searchString }
            },
            {
              ode: { iname_contains: $searchString }
            }
          ]
        }
      }
    ){
      aggregate
    }
  }
`;
