import React from "react";
import { Form, Input, Typography } from "antd";
import {
  fieldValidationMessages
} from "../../../utils/fieldValidationMessages";
import { urlPatterns } from "../../../utils";

export function ContactForm({ form, editable = true }) {
  const patternMsg = "El formato de la URL no es válido";

  return (
    <Form
      validateMessages={fieldValidationMessages}
      form={form}
      layout="vertical">
      <Form.Item>
        <Typography.Text>
          Llena los datos de contacto oficiales del programa.
        </Typography.Text>
      </Form.Item>
      <Form.Item
        rules={[{ required: editable, type: "email" }]}
        name="email"
        label="Correo">
        <Input type="email" disabled={!editable} />
      </Form.Item>
      <Form.Item
        rules={[{ required: editable }]}
        name="phone"
        label="Teléfono">
        <Input disabled={!editable} />
      </Form.Item>
      <Form.Item
        name="website"
        label="URL de la página web"
        rules={[{
          pattern: urlPatterns.url,
          message: patternMsg
        }]}>
        <Input disabled={!editable} />
      </Form.Item>
      <Form.Item
        name="facebook"
        label="Facebook"
        rules={[{
          pattern: urlPatterns.facebook,
          message: patternMsg
        }]}>
        <Input disabled={!editable} />
      </Form.Item>
    </Form>
  );
}
