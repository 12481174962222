import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import { List, Typography } from "antd";
import { BatchItem } from "../../../../../admin/batches/list/item";
import { cardGridSizes } from "../../../../../../helpers";
import { FlexContainer } from "../../../..";
import useTreatment from "../../../../../../hooks/feature-flags/use-treatment";
import { CurrentUserContext } from "../../../../../../contexts/shared";

export const Batches = ({ data }) => {
  const history = useHistory();
  const { currentUser } = useContext(CurrentUserContext);

  const disableBatchOfferTreatmentValue =
    useTreatment("DisableBatchOffer");

  const navigateToBatchDetail = (batchId, odeId) => {
    if (disableBatchOfferTreatmentValue === "ON") {
      if(
        currentUser.systemRole === "ENTREPRENEUR" ||
        currentUser.systemRole === "ADVISER"
      ){
        history.push(`./batches/${batchId}`);
      } else {
        history.push(`../../batches/${batchId}/odes/${odeId}/content`);
      }
    } else if (disableBatchOfferTreatmentValue === "OFF") {
      history.push(`../../batches/${batchId}/offer?section=companies`);
    }
  };

  return (
    <FlexContainer direction="vertical">
      <Typography.Title level={5}>
        Batches
      </Typography.Title>
      <List
        style={{ width: "100%", marginTop: "15px" }}
        className="batches-list"
        grid={cardGridSizes}
        dataSource={data?.batches}
        renderItem={batch =>
          <List.Item>
            <BatchItem
              clickable={currentUser.systemRole !== "ADVISER"}
              batch={batch}
              onClick = {() => navigateToBatchDetail(batch?.id, data?.id)} />
          </List.Item>
        } />
    </FlexContainer>
  );
};
