import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  DatePicker,
  Row,
  Col,
  Divider,
  Space,
  Select,
  Switch,
  Typography
} from "antd";
import moment from "moment";
import { genericFieldValidationMsgs } from "../../../../../utils";
import { Summay } from "./summary";
import { OdesPicker } from "../odes-picker";
import { mediumTypes } from "../../../../../helpers/select-options";
import { Visibility } from "../../../../../components/shared";

export function AssignExpertServiceForm({ form, expertService = {} }) {
  const [isUrl, setIsUrl] = useState(false);
  const [state, setState] = useState({
    totalUnits: 0,
    isProbono: false,
    isLimited: false
  });

  const onSwitchChange = value => {
    form.setFieldsValue({ isProbono: value });
    setState(prevState => ({ ...prevState, isProbono: value }));
  };

  const onLimitedChange = value => {
    setState(prevState => ({ ...prevState, isLimited: value }));
  };

  const disabledDate = current => {
    return current && current < moment().subtract(1, "days");
  };

  const updateTotalUnits = (totalUnits = 0) =>
    setState(prevState => ({ ...prevState, totalUnits }));

  useEffect(() => {
    form.setFieldsValue({ assignmentDate: moment() });
  }, []);

  const onChangeType = value => {
    setIsUrl(value === "ONLINE");

    return value;
  };

  const hasLimitHoursPerDay =
    ["MENTORSHIP"].includes(expertService.type) &&
    expertService?.method === "HOURS";

  return (
    <Form
      form={form}
      validateMessages={genericFieldValidationMsgs}
      layout="vertical"
      className="assigned-expert-service-form">
      <Divider orientation="left">Asignación</Divider>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="isProbono"
            valuePropName="checked">
            <Switch
              defaultChecked={state.status}
              onChange={onSwitchChange}
              checkedChildren="Si"
              unCheckedChildren="No" />
            <Typography.Text
              type="secondary"
              style={{ marginLeft: "1rem", verticalAlign: "bottom" }}>
              Probono
            </Typography.Text>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="assignmentNumber"
            rules={[{ required: true }]}
            label="Número de asignación">
            <Input
              className="wide-input"
              placeholder="Ej. 1239840" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="limitDate"
            rules={[{ required: true }]}
            label="Fecha límite de consumo">
            <DatePicker
              disabledDate={disabledDate}
              placeholder="DD-MM-YYYY"
              format="LL"
              className="wide-input" />
          </Form.Item>
        </Col>
        <Visibility visible={hasLimitHoursPerDay}>
          <Col span={24}>
            <Row justify="space-between" style={{ width: "100%" }}>
              <Form.Item>
                <Switch
                  defaultChecked={state.isLimited}
                  onChange={onLimitedChange}
                  checkedChildren="Si"
                  unCheckedChildren="No" />
                <Typography.Text
                  type="secondary"
                  style={{ marginLeft: "1rem", verticalAlign: "bottom" }}>
                  Límitar la cantidad de consumo por sesión
                </Typography.Text>
              </Form.Item>
              <Visibility visible={state.isLimited}>
                <Form.Item
                  name="limitPerDay"
                  rules={[{ required: state.isLimited }]}
                  style={{ marginRight: "130px" }}>
                  <InputNumber
                    min={1}
                    type="number"
                    style={{ width: "153px" }} />
                </Form.Item>
              </Visibility>
            </Row>
          </Col>
        </Visibility>
        <Col span={24}>
          <Divider orientation="left">
            {["PRODUCT", "WORKSHOP"].includes(expertService.type) ? "Programa" : "Compañías"}
          </Divider>
          <Form.Item
            name="oDEs"
            rules={[{
              required: true,
              message: "La cantidad de horas a asignar no puede ser 0"
            }]}>
            <OdesPicker
              expertService={expertService}
              updateTotalUnits={updateTotalUnits} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Visibility visible={expertService.type === "WORKSHOP"}>
            <Divider orientation="left">
              Lugar
            </Divider>
            <Space className="place" style={{ width: "100%" }}>
              <Form.Item
                label="Modalidad"
                name="medium"
                rules={[{ required: true }]}
                getValueFromEvent={onChangeType}>
                <Select
                  placeholder="Selecciona tipo..."
                  options={mediumTypes}
                  style={{ minWidth: "200px" }} />
              </Form.Item>
              <Form.Item
                label={isUrl ? "Link" : "Ubicación"}
                name="mediumDescription"
                rules={[{
                  required: true,
                  type: isUrl ? "url" : "string",
                  message: isUrl
                    ? "Ingresa una url válida"
                    : "Este campo es requerido."
                }]}>
                <Input placeholder={isUrl
                    ? "Ingresa el link"
                    : "Ingresa la dirección"} />
              </Form.Item>
            </Space>
          </Visibility>
        </Col>
      </Row>
      <Summay
        isProbono={state.isProbono}
        totalUnits={state.totalUnits}
        serviceType={expertService.type}
        serviceMethod={expertService.method}
        price={expertService.price || undefined} />
    </Form>
  );
}
