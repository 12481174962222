import React, { useState, useRef } from "react";
import "./styles.sass";
import { LocationsList } from "./locations-list";
import { UploadSection } from "../upload-section";
import {
  organizationServicesOptions
} from "../../../helpers/select-options";
import {
  organizationStagesOptions
} from "../../../helpers/select-options";
import {
  Row,
  Col,
  Form,
  Radio,
  Input,
  Button,
  Switch,
  Select,
  Divider,
  Typography,
  DatePicker,
  Checkbox
} from "antd";
import {
  allStateOption,
  statesWithCitysOptions
} from "../../../helpers/select-options";
import {
  MailOutlined,
  GlobalOutlined,
  FacebookFilled,
  LinkedinFilled,
  TwitterOutlined,
  InstagramOutlined,
  PhoneOutlined
} from "@ant-design/icons";
const { Text, Link } = Typography;

const initialState = {
  currentState: undefined,
  currentSelectedCities: [],
  selectedCitiesValues: [],
  currentCitiesList: []
};

export const RegisterOrganizationForm = ({
  form,
  setSelectedStates,
  selectedStates,
  stateErrorMessage,
  setStateErrorMessage,
  nationalPresence,
  setNationalPresence
}) => {
  const [
    citiesSelectorHandler,
    setCitiesSelectorHanlder
  ] = useState(initialState);

  const citiesSelectRef = useRef();

  const {
    currentState,
    currentSelectedCities,
    currentCitiesList,
    selectedCitiesValues
  } = citiesSelectorHandler;

  const onNationalPresenceChange = e => {
    if(e.target.value) {
      setSelectedStates([]);
    }
    setNationalPresence(e.target.value);
  };

  const onStateChange = stateValue => {
    setStateErrorMessage({ validateStatus: "" });
    const [selectedState] =
      statesWithCitysOptions.filter(state => state.value === stateValue);
    setCitiesSelectorHanlder({
      currentState: selectedState,
      currentCitiesList: allStateOption.concat(selectedState.children),
      currentSelectedCities: [],
      selectedCitiesValues: []
    });
    citiesSelectRef.current.focus();
  };

  const onCitiesChange = (e, citiesValues) => {
    if (e[0] === "ALL") {
      citiesSelectRef.current.blur();
    }
    setCitiesSelectorHanlder(state => ({
      ...state,
      selectedCitiesValues: e,
      currentSelectedCities: citiesValues
    }));
  };

  const onAddState = () => {
    if (currentState) {
      let newState = {
        name: currentState.label,
        iname: currentState.label.toLowerCase()
          .normalize("NFD").replace(/[\u0300-\u036f]/g, ""),
        value: currentState.value,
        allCities: true,
        cities: ["Todo el estado"]
      };
      if (selectedCitiesValues.length > 0) {
        const allCities = selectedCitiesValues.filter(city => city === "ALL");
        if (allCities.length === 0) {
          newState = {
            ...newState,
            allCities: allCities.length > 0,
            cities: currentSelectedCities?.map(city => city.label)
          };
        }
      }
      setSelectedStates(selectedStates.concat(newState));
      setCitiesSelectorHanlder(initialState);
    } else {
      setStateErrorMessage({
        validateStatus: "error",
        validateMessage: "Selecciona un estado"
      });
    }
  };

  const onDeleteStateCity = (state, city) => {
    const newCitiesList = state.cities.filter(oldCity => oldCity !== city);
    let newSelectedStates = selectedStates.map(selectedState => {
      if (selectedState.value !== state.value) return selectedState;
      return {
        ...state,
        cities: newCitiesList
      };
    });
    if (newCitiesList.length === 0) {
      const filteredStates =
        selectedStates?.filter(({ value }) => value !== state.value);
      newSelectedStates = filteredStates;
    }
    setSelectedStates(newSelectedStates);
  };

  const handleCheckbox = ({ target }) => {
    if (target?.checked === undefined || target?.checked === false) {
      return undefined;
    }
    return target?.checked;
  };

  const handleSwitch = value => {
    return value;
  };

  return (
    <Form
      className="asem__register-form"
      form={form}
      layout="vertical"
      autoComplete="off">
      <Text style={{
        fontWeight: "500",
        fontSize: "18px",
        marginTop: "12px"
      }}>
        Información básica de tu organización
      </Text>
      <Divider style={{ marginTop: "11px", marginBottom: "20px" }} />
      <Form.Item
        label="Logotipo"
        name="logo"
        rules={[{ required: true }]}>
        <UploadSection>
          <Text>Adjuntar archivos</Text>
          <br />
          <span>Puedes adjuntar：.png, .jpg tamaño recomendado 1080 x 960</span>
        </UploadSection>
      </Form.Item>
      <Form.Item
        label="Nombre"
        name="name"
        rules={[{ required: true }]}>
        <Input className="input-background" />
      </Form.Item>
      <Form.Item
        label="Etapas en las que apoya"
        name="stages"
        rules={[{ required: true }]}>
        <Select
          mode="multiple"
          placeholder="Selecciona las etapas de la lista"
          options={organizationStagesOptions} />
      </Form.Item>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Año de fundación"
            name="startedAt"
            rules={[{ required: true }]}>
            <DatePicker
              picker="year"
              className="input-background"
              style={{ width: "95%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Origen"
            name="private"
            rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value={false}>Público</Radio>
              <Radio value={true}>Privado</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Servicios que ofrece"
        name="services"
        rules={[{ required: true }]}>
        <Select
          mode="multiple"
          placeholder="Selecciona los servicios que ofrece"
          options={organizationServicesOptions} />
      </Form.Item>
      <Form.Item
        required
        initialValue={false}
        name="ranByWomen"
        label="¿Tu organización cuenta con al menos una iniciativa o servicio diseñado y dirigido específicamente a mujeres?"
        getValueFromEvent={handleSwitch}>
        <Switch
          checkedChildren="Sí"
          unCheckedChildren="No"
          defaultChecked={false} />
      </Form.Item>
      <Form.Item
        label={
          <Text>Descripción *
            <Text type="secondary" style={{ fontSize: "12px" }}>
              Máximo 300 caracteres
            </Text>
          </Text>}
        name="description"
        rules={[{ required: true, message: "Por favor ingresar Descripción" }]}>
        <Input.TextArea
          maxLength={300}
          className="input-background"
          autoSize={{ minRows: 5, maxRows: 7 }} />
      </Form.Item>
      <Text style={{ fontWeight: "500", fontSize: "18px", marginTop: "12px" }}>
        Lugares en donde tiene presencia
      </Text>
      <Divider style={{ marginTop: "11px", marginBottom: "20px" }} />
      <Form.Item
        label="Presencia nacional"
        name="nationalPresence">
        <Radio.Group
          value={nationalPresence}
          defaultValue={nationalPresence}
          onChange={onNationalPresenceChange}>
          <Radio value={true}>Si</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </Form.Item>
      <Row justify="space-between" align="top">
        <Col span={5}>
          <Form.Item
            label="Estado"
            validateStatus={stateErrorMessage.validateStatus}
            help={stateErrorMessage.validateMessage}
            required>
            <Select
              showSearch
              autoComplete="off"
              disabled={nationalPresence}
              value={currentState?.value}
              onChange={onStateChange}
              options={statesWithCitysOptions} />
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item
            label="Ciudades">
            <Select
              showSearch
              mode="multiple"
              className="asem-select input-background"
              disabled={nationalPresence}
              ref={citiesSelectRef}
              aria-autocomplete="none"
              onChange={onCitiesChange}
              value={selectedCitiesValues}
              options={currentCitiesList} />
          </Form.Item>
        </Col>
        <Button
          onClick={onAddState}
          disabled={nationalPresence}
          style={{
            color: "#5F39DE",
            border: "1px solid #5F39DE",
            marginTop: "30px"
          }}>
          Agregar presencia</Button>
      </Row>
      <LocationsList
        states={selectedStates}
        onCloseCity={onDeleteStateCity} />
      <br />
      <Text style={{ fontWeight: "500", fontSize: "18px", marginTop: "12px" }}>
        Sitio web y contacto
      </Text>
      <br />
      <Text type="secondary">
        Si tu organización tiene presencia en más de una ubicación y los datos
        de contacto son distintos te recomendamos llenar varios formularios.
      </Text>
      <Divider style={{ marginTop: "11px", marginBottom: "20px" }} />
      <Row justify="space-between">
        <Form.Item
          label={<Text><GlobalOutlined /> Sitio web</Text>}
          name="website"
          style={{ width: "437px" }}
          rules={[{ required: true }]}>
          <Input
            type="email" placeholder="www.example.com"
            className="input-background" />
        </Form.Item>
        <Form.Item
          label={<Text><MailOutlined /> Email</Text>}
          name="email"
          style={{ width: "437px" }}
          rules={[{ required: true }]}>
          <Input
            className="input-background"
            placeholder="example@example.com" />
        </Form.Item>
      </Row>
      <Row justify="space-between">
        <Form.Item
          label={<Text><FacebookFilled /> Facebook</Text>}
          name="facebook"
          style={{ width: "437px" }}>
          <Input
            className="input-background"
            placeholder="www.facebook.com/example" />
        </Form.Item>
        <Form.Item
          label={<Text><InstagramOutlined /> Instagram</Text>}
          name="instagram"
          style={{ width: "437px" }}>
          <Input
            className="input-background"
            placeholder="www.instagram.com/example" />
        </Form.Item>
      </Row>
      <Row justify="space-between">
        <Form.Item
          label={<Text><TwitterOutlined /> Twitter</Text>}
          name="twitter"
          style={{ width: "437px" }}>
          <Input
            className="input-background"
            placeholder="www.twitter.com/example" />
        </Form.Item>
        <Form.Item
          label={<Text><LinkedinFilled /> Linkedin</Text>}
          name="linkedin"
          style={{ width: "437px" }}>
          <Input
            className="input-background"
            placeholder="www.linkedin.com/example" />
        </Form.Item>
      </Row>
      <Text style={{
        fontWeight: "500",
        fontSize: "18px",
        marginTop: "12px"
      }}>
        Datos de contacto de la persona que registra
      </Text>
      <br />
      <Divider style={{ marginTop: "11px", marginBottom: "20px" }} />
      <Row justify="space-between">
        <Form.Item
          label={<Text><PhoneOutlined /> Teléfono</Text>}
          name="contactPhone"
          style={{ width: "437px" }}
          rules={[{
            required: true,
            message: "Por favor ingresar el teléfono del contacto"
          }]}>
          <Input placeholder="000-000-0000" className="input-background" />
        </Form.Item>
        <Form.Item
          label={<Text><MailOutlined /> Email</Text>}
          name="contactEmail"
          style={{ width: "437px" }}
          rules={[{
            required: true,
            message: "Por favor ingresar el email del contacto"
          }]}>
          <Input
            placeholder="contacto@tusitio.com"
            className="input-background" />
        </Form.Item>
      </Row>
      <br />
      <Form.Item
        name="termsAndConditionsAccepted"
        getValueFromEvent={handleCheckbox}
        rules={[{
          required: true,
          message: "Debes de aceptar los términos y condiciones"
        }]}>
        <Checkbox>
          <Text type="secondary">
            Acepto <Link
              target="_blank"
              href="https://tec.mx/es/aviso-de-privacidad-instituto-de-emprendimiento">
              <Text
                underline
                type="secondary"
                strong>
                términos y condiciones
              </Text>
            </Link> y <Link
              target="_blank"
              href="https://tec.mx/es/aviso-de-privacidad-instituto-de-emprendimiento">
              <Text
                underline
                type="secondary"
                strong>
                políticas de privacidad
              </Text>
            </Link>.
          </Text>
        </Checkbox>
      </Form.Item>
      <br />
      <Text strong>
        Si necesitas ayuda ponte en contacto con: support@eossolution.io
      </Text>
    </Form>
  );
};
