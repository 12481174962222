import React, { useContext } from "react";
import { Space, Typography } from "antd";
import { Section } from "../../../components/shared";
import {
  default as OrganizationList
} from "../../../components/public/organization/organization-list/list";
import { HeroAsem } from "../../../components/shared";
import { shared } from "../../../graphql/shared";
import {
  OrganizationModalProvider,
  PaginationContext
} from "../../../contexts/shared";
import { useControlledPagination, useQueryFilters } from "../../../helpers";
import { client } from "../../../../graphql";
import { useQuery } from "@apollo/react-hooks";
import {
  FilterControls
} from "../../../components/public/organization/organization-list/filter-controls";
import OrganizationLayout from "../../../components/shared/organization/layout";
import { AuthContext } from "../../../../AuthContext";

export function OrganizationIndex() {
  const { isAuth } = useContext(AuthContext);

  const {
    loading,
    data,
    error,
    refetch,
    variables
  } = useQuery(
    shared.queries.getOrganizations,
    {
      client,
      fetchPolicy: "network-only",
      variables: {
        first: 12
      }
    }
  );

  const { registerRefetch } = useContext(PaginationContext);
  registerRefetch("organization", refetch, variables);

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: { first: 12 }
  });

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 12
  });

  const onFilter = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  return (
    <OrganizationModalProvider>
      <OrganizationLayout userIsLogged={isAuth}>
        <Space
          className="asem OrganizationIndex"
          direction="vertical">
          <HeroAsem />
          <Section
            className="OrganizationIndex__controls"
            spaced
            extra={<FilterControls onChange={onFilter} />}>
            <Typography.Title
              className="OrganizationIndex__title"
              level={2}>
              Organizaciones
            </Typography.Title>
            <OrganizationList
              error={error}
              data={data}
              isLoading={loading}
              onChangePage={onPaginationChange}
              currentPage={currentPage} />
          </Section>
        </Space>
      </OrganizationLayout>
    </OrganizationModalProvider>
  );
}
