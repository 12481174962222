import React, { useState, useContext } from "react";
import { message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { ServiceDeliveryModalContext } from "./context";
import { ServiceDeliveryModal } from "../../../../../components/expert/services/requests/service-delivery-modal";
import { entrepreneur } from "../../../../../graphql/entrepreneur";
import { expert } from "../../../../../graphql/expert";
import { client } from "../../../../../../graphql";
import { CurrentUserContext } from "../../../../shared";
import { cancelledByEnum as CANCELLED_BY } from "../../../../../helpers/constants";
import { shared } from "../../../../../graphql/shared";
import { ScheduledExpertServiceService } from "../../../../../services/deprecated/scheduled-expert-service-service";

export function ServiceDeliveryModalProvider({ children, refetch = null }) {
  const [state, setState] = useState({
    isModalOpen: false,
    scheduledService: undefined,
    loading: false
  });

  const { currentUser } = useContext(CurrentUserContext);

  const [cancelScheduledExpertService] = useMutation(
    entrepreneur.mutations.cancelScheduledExpertService, {
      client,
      refetchQueries: [{
        query: expert.queries.getRequestedServicesByExpertId,
        variables: { expertId: currentUser?.id }
      }]
    }
  );

  const getScheduledExpertService = async id => {
    return await client.query({
      query: shared.queries.getFullScheduledExpertServiceById,
      variables: { id },
      fetchPolicy: "network-only"
    });
  };

  const onSaveListeners = [];

  const openModal = async (scheduledService = undefined)  => {
    setState(prevState => ({
      ...prevState,
      isModalOpen: true,
      loading: true
    }));

    const result = await getScheduledExpertService(scheduledService.id);

    setState(prevState => ({
      ...prevState,
      loading: false,
      scheduledService: result.data.scheduledExpertService
    }));
  };

  const closeModal = () =>
    setState({
      isModalOpen: false,
      scheduledService: undefined
    });

  const onSave = values => {
    onSaveListeners.forEach(i => i(values));
    closeModal();
  };

  const addOnSaveListener = func => {
    onSaveListeners.push(func);
  };

  const onCancel = () => {
    closeModal();
  };

  const onCancelRequest = async scheduledExpertService => {
    const dismissLoader = message.loading("Guardando...", 0);
    const scheduledExpertServiceService = new ScheduledExpertServiceService();

    try {
      const assignedServiceId = scheduledExpertService.assignedExpertService.id;
      const units = scheduledExpertService.duration;

      await cancelScheduledExpertService({
        variables: {
          id: scheduledExpertService.id,
          cancelledBy: CANCELLED_BY.EXPERT
        }
      });

      await scheduledExpertServiceService.refundAssignedUnits({
        assignedServiceId,
        units
      });

      refetch && await refetch();
      message.success("Solicitud cancelada.");
      closeModal();
    } catch (e) {
      console.error(e);
      message.error("Ocurrio un error.\
        Por favor intenta de nuevo en unos momentos.");
    } finally {
      dismissLoader();
    }
  };

  const injectActions = {
    openModal,
    closeModal,
    addOnSaveListener
  };

  return (
    <ServiceDeliveryModalContext.Provider value={injectActions}>
      <ServiceDeliveryModal
        loading={state.loading}
        onSave={onSave}
        visible={state.isModalOpen}
        onCancel={onCancel}
        onCancelRequest={onCancelRequest}
        scheduledService={state.scheduledService} />
      {children}
    </ServiceDeliveryModalContext.Provider>
  );
}
