import { client } from "../../../graphql";
import { getCompanyPerksQuery } from "./graphql";

/**
 * Returns a list of the Batch Perks assigned to a Company.
 * @param {string} batchId
 * @param {string} companyId
 * @returns {Promise<any[]>} A list of the Batch perks
 */
export async function getCompanyPerks({
  batchId,
  companyId
}) {
  const result = await client.query({
    query: getCompanyPerksQuery,
    fetchPolicy: "network-only",
    variables: { batchId, companyId }
  });

  return result.data?.perks || [];
}
