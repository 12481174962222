import React, { useContext } from "react";
import { Space, Typography, Button } from "antd";
import { useHistory } from "react-router-dom";
import { MailOutlined, PhoneOutlined, FilePdfOutlined } from "@ant-design/icons";
import {
  Avatar,
  EditButton,
  IconLabel,
  Format,
  Visibility
} from "../../../../shared";
import {
  EditOdeContext
} from "../../../../../contexts/shared/edit-ode";
import { useDecorator } from "../../../../../helpers/use-decorator";

export const Details = ({
  allowEdit = false
}) => {
  const {
    setEditing,
    data
  } = useContext(EditOdeContext);

  const decoratedOde = useDecorator("ode", data?.oDE);

  const handleOnePagerClick = () => {
    const win = window.open("./one-pager", "_blank");
    win.focus();
  };

  return (
    <Space
      direction="horizontal"
      size={20}
      align="start">
      <Avatar size={60}>
        {decoratedOde.logoUrl || decoratedOde.name}
      </Avatar>
      <Space direction="vertical" size={5}>
        <Space direction="horizontal">
          <Typography.Title
            level={4}
            style={{marginBottom: "0px"}}>
            {decoratedOde.name}
          </Typography.Title>
          <Visibility visible={allowEdit}>
            <EditButton onClick={() => setEditing(true, "summary")} />
          </Visibility>
        </Space>
        <Format type="secondary">
          <div style={{maxWidth: "700px"}}>
            {decoratedOde.description}
          </div>
        </Format>
        <Space direction="horizontal">
          <IconLabel icon={<MailOutlined />}>
            <Format type="secondary">
              {decoratedOde.email}
            </Format>
          </IconLabel>
          <IconLabel icon={<PhoneOutlined />}>
            <Format type="secondary">
              {decoratedOde.phone}
            </Format>
          </IconLabel>
        </Space>
        <Button
          onClick={handleOnePagerClick}
          style={{marginTop: "15px"}}
          icon={<FilePdfOutlined />}>One Pager</Button>
      </Space>
    </Space>
  );
};
