import React from "react";
import { Select, Space } from "antd";
import { BatchesSelect } from "../../shared";

export function CalendarFilter({ onChange, odes = [] }) {
  return (
    <Space>
      <Select
        showSearch
        allowClear
        data-cy="ode-select"
        style={{ width: "300px" }}
        placeholder="Seleccionar organización"
        onChange={companyId => onChange({ companyId })}
        options={odes.map(ode => ({ label: ode.name, value: ode.id })) } />
      <BatchesSelect
        style={{ width: "300px" }}
        onChange={batchId => onChange({ batchId })} />
    </Space>
  );
}
