import React from "react";
import { useHistory } from "react-router-dom";
import { PictureOutlined } from "@ant-design/icons";
import { CoverImage, TagList, AvatarList } from "../../shared";
import { Card, Space, Typography, Divider, Tooltip } from "antd";
import {
  getReadableValue, programTypes
} from "../../../helpers";
import { filterTags } from "../tag-list/filter-tags";

export const OdeItem = ({ ode }) => {
  const programs = ode.batches?.map(el =>
    getReadableValue(programTypes, el.program.type)
  );
  const avatars = ode.team?.map(el =>
    ({ name: el.fullName || "", url: el.profilePicUrl || undefined })
  );

  const history = useHistory();

  const onClick = () => {
    history.push(`./odes/${ode?.id}/general-information`);
  };

  const filteredPrograms = filterTags(programs);

  return (
    <Card
      style={{height: "350px"}}
      className="OdeItem"
      cover={
        <CoverImage
          text={ode.name}
          onClick={onClick}
          url={ode?.logoUrl}
          icon={PictureOutlined} />}>
      <Card.Meta
        description={
          <>
            <Space
              className="OdeItem__Wrapper">
              <Tooltip title={ode?.name}>
                <Typography.Title
                  level={4}
                  ellipsis={true}
                  className="clickable"
                  onClick={onClick}>
                  {
                    ode?.name.length > 16
                      ? `${ode?.name.substring(0,16)}...`
                      : ode?.name
                  }
                </Typography.Title>
              </Tooltip>
            </Space>
            <Divider />
            {
              !!filteredPrograms.length
                ? <TagList
                  maxCount={2}
                  className="OdeItem__Tags"
                  tags={filteredPrograms} />
                : <div style={{height: "56px"}} />
            }
            <Divider />
            <AvatarList
              covers={avatars}
              className="OdeItem__Avatars"
              onClick={undefined} />
          </>
        } />
    </Card>
  );
};
