import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { industriesToChart } from "./industries-to-chart";
import { industriesChartOptions } from "./industries-chart-options";
import { Visibility } from "../../../../components/shared";
import { Card } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const IndustriesChart = ({ cubeClient, filters }) => {
  const [industriesData, setIndustriesData] = useState();

  useEffect(() => {
    const loadCubeQuery = async () => {
      const cubeFilters = [];

      if (filters.batchId) {
        cubeFilters.push({
          "member": "Company.batchId",
          "operator": "equals",
          "values": [filters.batchId]
        });
      }

      if (filters.programId) {
        cubeFilters.push({
          "member": "Company.programId",
          "operator": "equals",
          "values": [filters.programId]
        });
      }

      if (filters.batchRangeDates.since && filters.batchRangeDates.until) {
        const { since, until } = filters.batchRangeDates;
        const filter = {
          "member": "Company.batchStartDate",
          "operator": "inDateRange",
          "values": [since, until]
        };
        cubeFilters.push(filter);
      }

      const resultSet = await cubeClient.load({
        "measures":["Company.companiesCount"],
        "dimensions": ["Company.companyIndustry"],
        filters: cubeFilters
      });

      const queryData = resultSet.loadResponse.results[0].data;
      const chartData = industriesToChart(queryData);
      setIndustriesData(chartData);
    };


    loadCubeQuery()
      .then()
      .catch(console.error);
  }, [filters]);

  return (
    <Visibility visible={!!industriesData}>
      <Card
        style={{ marginTop: "2rem" }}
        title="Por industria">
        <Bar
          data={industriesData}
          type={"bar"}
          height={800}
          options={industriesChartOptions} />
      </Card>
    </Visibility>
  );
};
