import gql from "graphql-tag";
export const getOdesByBatchIdAndUserId = gql`
  query GetOdesByBatchIdAndUserId($batchId: ID, $userId: ID) {
    oDEs( 
      where: {
        batches_some: {
          id: $batchId,
        }
        team_some: {
          id: $userId
        }
    	}
    ){
      id
    }
  }
`;