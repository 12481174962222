import React from "react";
import { ApolloProvider } from "react-apollo";
import { SplitFactory } from "@splitsoftware/splitio-react";
import { ApolloProvider as ApolloProviderHooks } from "react-apollo-hooks";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AuthProvider } from "./AuthContext";
import { client } from "./graphql";
import { ErrorBoundary } from "./bugsnag";
import { NotFoundPage } from "./v2/pages/public/not-found";
import { ErrorFallbackIndex } from "./v2/pages/error-fallback";

import { V2 } from "./v2";
import { PaginationContextProvider } from "./v2/contexts/shared";
import { OrganizationDashboardIndex } from "./v2/pages/public/dashboard";
import { MaintenanceFallbackWrapper } from "./v2/components/shared/maintenance-fallback-wrapper";
import { DocsWrapper } from "./v2/components/shared";

export default () => {
  const splitToken = process.env.REACT_APP_SPLIT_KEY;

  return (
    <div>
      <DocsWrapper>
        <ApolloProvider client={client}>
          <ApolloProviderHooks client={client}>
            <SplitFactory config={{
              core: {
                authorizationKey: splitToken,
                key: "CUSTOMER_ID",
                updateOnSdkUpdate: true
              }
            }}>
              <ErrorBoundary FallbackComponent={ErrorFallbackIndex}>
                <BrowserRouter>
                  <AuthProvider>
                    <PaginationContextProvider>
                      <MaintenanceFallbackWrapper>
                        <Switch>
                          <Route
                            path="/" exact
                            component={OrganizationDashboardIndex} />
                          <Route
                            path="/v2"
                            component={V2} />
                          <Route component={NotFoundPage} />
                        </Switch>
                      </MaintenanceFallbackWrapper>
                    </PaginationContextProvider>
                  </AuthProvider>
                </BrowserRouter>
              </ErrorBoundary>
            </SplitFactory>
          </ApolloProviderHooks>
        </ApolloProvider>
      </DocsWrapper>
    </div>
  );
};
