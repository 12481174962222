export const setUpdateProfessionalInformation = (professionalInformation, state, setState) => {
  const updatedProfessionalInformation = {
    ...state.professionalInformation,
    ...professionalInformation
  };

  setState({
    ...state,
    professionalInformation: updatedProfessionalInformation
  });
};
