import { cubeJSClient } from "../../api-clients";
import { getSocialTechCompanies } from "./get-social-tech-companies";
import {
  getBatchEnrollmentStatusCompaniesCount
} from "./get-batch-enrollment-status-companies-count";

/**
 * @param {object} args
 * @param {string} args.batchId
 * @param {string} args.programId
 * @param {string} args.from
 * @param {string} args.until
 */
export const getCompaniesStatistics = async ({
  batchId,
  programId,
  from,
  until,
  teamMemberCampus
} = {}) => {
  const companiesCount = await getBatchCompaniesCount({
    batchId,
    programId,
    from,
    until,
    teamMemberCampus
  });

  const unEnrolledCompaniesCount = await
    getBatchEnrollmentStatusCompaniesCount({
      batchId,
      programId,
      batchFinished: false,
      enrollmentStatus: "INACTIVE",
      from,
      until,
      teamMemberCampus
    });

  const completedBatchCompaniesCount = await
    getBatchEnrollmentStatusCompaniesCount({
      batchId,
      programId,
      batchFinished: true,
      enrollmentStatus: "ACTIVE",
      from,
      until,
      teamMemberCampus
    });

  const techSocialCompaniesResult = await getSocialTechCompanies({
    batchId,
    programId,
    from,
    until,
    teamMemberCampus
  });

  return {
    companiesCount,
    unEnrolledCompaniesCount,
    completedBatchCompaniesCount,
    techBasedCompaniesCount: techSocialCompaniesResult.techBased,
    socialCompaniesCount: techSocialCompaniesResult.social
  };
};

const getBatchCompaniesCount = async ({
  batchId, programId, from, until, teamMemberCampus
}) => {
  const filters = [];

  if (batchId) {
    filters.push({
      "member": "Company.batchId",
      "operator": "equals",
      "values": [batchId]
    });
  }

  if (teamMemberCampus) {
    filters.push({
      "member": "Company.teamMemberCampus",
      "operator": "equals",
      "values": [teamMemberCampus]
    });
  }

  if (programId) {
    filters.push({
      "member": "Company.programId",
      "operator": "equals",
      "values": [programId]
    });
  }

  if (from && until) {
    filters.push({
      "member": "Company.batchStartDate",
      "operator": "inDateRange",
      "values": [from, until]
    });
  }


  const resultSet = await cubeJSClient.load({
    "measures": [
      "Company.companiesCount"
    ],
    "timeDimensions": [],
    "order": {
      "Company.batchCreatedAt": "asc"
    },
    "dimensions": [],
    filters,
    "limit": 5000
  });

  const [queryData] = resultSet.loadResponse.results[0].data;
  return parseInt(queryData["Company.companiesCount"] || "0");
};
