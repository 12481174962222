import React from "react";
import { Form, Input } from "antd";
const { TextArea } = Input;

export function RejectedSection({ form, organization }) {
  const isRejected = organization?.status === "REJECTED";
  return (
    <Form
      form={form}
      layout="vertical">
      <Form.Item
        label="Motivo de rechazo"
        name="rejectionReason"
        rules={[{
          required: true,
          message: "Es necesario la razón del rechazo"
        }]}>
        <TextArea
          disabled={isRejected}
          rows={4} />
      </Form.Item>
    </Form>
  );
}