import React, {  useMemo, useState, useEffect } from "react";
import { GridContainer } from "../../../../shared";
import { StatisticCard } from "../../statistic-card";
import {
  useEntrepreneursByDimensions
} from "../use-entrepreneurs-by-dimensions";
import {
  entrepreneursPerGenderToTable
} from "./entrepreneurs-per-gender-to-table";

export const StatisticsCards = ({ filters = {} }) => {
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState({
    MALE: { count: 0, percentage: 0 },
    FEMALE: { count: 0, percentage: 0 },
    OTHER: { count: 0, percentage: 0 },
    NOT_SAY: { count: 0, percentage: 0 },
    usersWithNoData: { count: 0, percentage: 0 }
  });

  const {
    loading: loadingTotal,
    results: resultsTotal
  } = useEntrepreneursByDimensions({
    ...filters
  });

  const { loading: loadingDimensions, results } = useEntrepreneursByDimensions({
    dimensions: ["teamMemberGender"],
    mustBeSet: ["teamMemberGender"],
    ...filters
  });

  useEffect(() => {
    if (loadingDimensions || loadingTotal)
      return ({ labels: [], datasets: [] });

    const [dimensionResult] = results;
    const [totalResult] = resultsTotal;
    const [totalDatum] = totalResult.data;

    const total = parseInt(totalDatum["Company.entrepreneursCount"]);
    setTotal(total);

    const rows = entrepreneursPerGenderToTable(dimensionResult.data, total);
    setRows(rows);
  }, [results, resultsTotal, loadingDimensions, loadingTotal]);

  const loading = useMemo(
    () => loadingTotal || loadingDimensions,
    [loadingTotal, loadingDimensions]
  );

  return (
    <div style={{ marginTop: 32 }}>
      <GridContainer
        gutter={[16, 16]}
        customColWidth={"20%"}>
        <StatisticCard
          loading={loading}
          value={rows.MALE?.count}
          title="Hombres"
          percentage={rows.MALE?.percentage} />
        <StatisticCard
          loading={loading}
          value={rows.FEMALE?.count}
          title="Mujeres"
          percentage={rows.FEMALE?.percentage} />
        <StatisticCard
          loading={loading}
          value={rows.OTHER?.count}
          title="Otro"
          percentage={rows.OTHER?.percentage} />
        <StatisticCard
          loading={loading}
          value={rows.NOT_SAY?.count}
          title="Prefiero no decirlo"
          percentage={rows.NOT_SAY?.percentage} />
        <StatisticCard
          loading={loading}
          value={rows.usersWithNoData?.count}
          title="Sin información"
          percentage={rows.usersWithNoData?.percentage} />
      </GridContainer>
    </div>
  );
};
