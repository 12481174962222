import React from "react";
import { Descriptions } from "antd";
import { FormContainer } from "../../..";
import { useDecorator } from "../../../../../helpers/use-decorator";

export function Details({ data = {} }) {
  data = useDecorator("ode", data);

  return (
    <FormContainer>
      <Descriptions column={1}>
        <Descriptions.Item label="Razón social">
          {data.businessName}
        </Descriptions.Item>
        <Descriptions.Item label="RFC/TAX ID">
          {data.rfc}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha de alta fiscal">
          {data.fiscalEntryDate}
        </Descriptions.Item>
        <Descriptions.Item label="Nombre del representante fiscal">
          {data.fiscalRepresentative}
        </Descriptions.Item>
      </Descriptions>
    </FormContainer>
  );
}
