import React, { useContext } from "react";
import { Menu } from "antd";
import { MenuTitle } from "../../../shared";
import {
  BookOutlined,
  GlobalOutlined,
  StarOutlined,
  TeamOutlined,
  SmileOutlined,
  AuditOutlined,
  DashboardOutlined
} from "@ant-design/icons";
import { withRouter } from "../../../../helpers";
import { MenuContext } from "../../../../contexts/shared";
import useTreatment from "../../../../hooks/feature-flags/use-treatment";

function MainMenu({ navigate }) {
  const { openKeys, selectedKeys } = useContext(MenuContext);

  const EntrepreneurSpiritTreatmentValue =
    useTreatment("EntrepreneurSpiritTab");

  const showEntrepreneurSpiritMenuItem =
    EntrepreneurSpiritTreatmentValue === "ON";
  //Server release is needed to use this field
   //&& currentUser?.isEntrepreneurSpiritCoordinator;

  const DynamicDashboardsModule = useTreatment(
    "DynamicDashboardsModule"
  );
  const showDynamicDashboardsMenuItem = DynamicDashboardsModule === "ON";

  return (
    <>
      <Menu
        mode="inline"
        openKeys={openKeys}
        selectedKeys={selectedKeys}>
        <Menu.SubMenu
          onTitleClick={() => navigate("/v2/coordinator/programs")}
          title={<MenuTitle text="Programas" icon={<GlobalOutlined />} />}
          key="programs">
          <Menu.Item
            key="programs/all"
            onClick={() => navigate("/v2/coordinator/programs")}>
            Todos
          </Menu.Item>
          <Menu.Item
            key="batches"
            onClick={() => navigate("/v2/coordinator/batches")}>
            Batches
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item
          key="odes"
          onClick={() => navigate("/v2/coordinator/odes")}>
          <MenuTitle text="Compañías" icon={<StarOutlined />} />
        </Menu.Item>
        <Menu.SubMenu
          onTitleClick={() => navigate("/v2/coordinator/entrepreneurs")}
          title={<MenuTitle text="Emprendedores" icon={<TeamOutlined />} />}
          key="entrepreneurs">
          <Menu.Item
            key="entrepreneurs/profiles"
            onClick={() => navigate("/v2/coordinator/entrepreneurs")}>
            Perfiles
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          onTitleClick={() => navigate("/v2/coordinator/experts")}
          title={<MenuTitle text="Expertos" icon={<BookOutlined />} />}
          key="experts">
          <Menu.Item
            key="experts/profiles"
            onClick={() => navigate("/v2/coordinator/experts")}>
            Perfiles
          </Menu.Item>
          <Menu.Item
            key="experts/services"
            onClick={() => navigate("/v2/coordinator/experts/services")}>
            Productos
          </Menu.Item>
          <Menu.Item
            key="experts/services/monitoring"
            onClick={() =>
              navigate("/v2/coordinator/experts/services/monitoring")}>
            Monitoreo
          </Menu.Item>
          <Menu.Item
            key="experts/services/monitoring/assignations"
            onClick={() =>
              navigate(
                "/v2/coordinator/experts/services/monitoring/assignations"
              )}>
            Monitoreo (Beta)
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          onTitleClick={() => navigate("/v2/coordinator/perks")}
          title={<MenuTitle text="Perks" icon={<SmileOutlined />} />}
          key="perks">
        </Menu.SubMenu>
        {
          showEntrepreneurSpiritMenuItem
            ? <Menu.Item
                key="entrepreneur-spirit"
                onClick={() =>
                  navigate("/v2/coordinator/entrepreneur-spirit")}>
              <MenuTitle text="Espíritu emprendedor" icon={<AuditOutlined />} />
            </Menu.Item>
            : null
        }
        {showDynamicDashboardsMenuItem
          ? <Menu.Item
              onClick={() => navigate("/v2/coordinator/analytics")}
              key="analytics">
            <MenuTitle
              text="Reportes"
              icon={<DashboardOutlined />} />
          </Menu.Item>
          : null}
      </Menu>
    </>
  );
}

export default withRouter(MainMenu);
