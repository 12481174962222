import gql from "graphql-tag";

export const getContentByIdWithOdeContent = gql`
  query GetContentByIdWithOdeContent($id: ID!, $odeId: ID!) {
    content(
      where: {
        id: $id
      }
    ) {
      id
      draft
      type
      name
      deliveryDate
      description
      instructions
      materials{
        id
        fileName
        url
        type
        uploadDate
      }
      createdBy{
        id
        fullName
        systemRole
      }
      odeContents(
        where: {
          ode: { id: $odeId }
        }
      ){
        id
        status
        finishDate
        deliverables {
          id
          fileName
          url
          type
          uploadDate
        }
        events {
          id
          event
          description
          createdAt
          blame {
            id
            fullName
            profilePicUrl
          }
        }
      }
    }
  }
`;
