export const buildCubeFilters = ({
  programId,
  batchId,
  from,
  until,
  mustBeSet = [],
  teamMemberCampus,
  teamMemberCareer,
  teamMemberTecRelationshipType
}) => {
  const filters = [];

  mustBeSet.forEach(notNullableProperty => {
    filters.push({
      "member": `Company.${notNullableProperty}`,
      "operator": "set"
    });
  });

  if (teamMemberCampus) {
    filters.push({
      "member": "Company.teamMemberCampus",
      "operator": "equals",
      "values": [teamMemberCampus]
    });
  }

  if (teamMemberCareer) {
    filters.push({
      "member": "Company.teamMemberCareer",
      "operator": "equals",
      "values": [teamMemberCareer]
    });
  }

  if (teamMemberTecRelationshipType) {
    filters.push({
      "member": "Company.teamMemberTecRelationshipType",
      "operator": "equals",
      "values": [teamMemberTecRelationshipType]
    });
  }

  if (batchId) {
    filters.push({
      "member": "Company.batchId",
      "operator": "equals",
      "values": [batchId]
    });
  }

  if (programId) {
    filters.push({
      "member": "Company.programId",
      "operator": "equals",
      "values": [programId]
    });
  }

  if (from && until) {
    filters.push({
      "member": "Company.batchStartDate",
      "operator": "inDateRange",
      "values": [from, until]
    });
  }

  return filters;
};
