import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { Summary } from "../../../../../../components/admin/batches/show/summary";
import { MainMenu } from "../../../../../../components/entrepreneur/shared";
import { SidebarLayout } from "../../../../../../components/shared";
import { BatchDetailsContextProvider } from "../../../../../../contexts/admin/batches/batch-details";
import {
  default as Navbar
} from "../../../../../../components/entrepreneur/batches/show/navbar";
import { MenuContext } from "../../../../../../contexts/shared/menu";
import { ObjectivesContent } from "../../../../../../components/entrepreneur/batches/show/objectives/objectives-content";
import { client } from "../../../../../../../graphql";
import { ContentListAlternativeViewProvider } from "../../../../../../contexts/entrepreneur/content-list-alternative-view/provider";
import { BatchObjectiveModalProvider } from "../../../../../../contexts/shared/batch-objective-modal";
import { ContentModalContextProvider } from "../../../../../../contexts/shared/content-modal/provider";
import { shared } from "../../../../../../graphql/shared";


export function ObjectiveOdeIndex() {

  const { id, odeId } = useParams();

  const { loading, error, data } = useQuery(
    shared.queries.getBatchObjectives,
    {
      fetchPolicy: "network-only",
      variables: {
        batchId: id,
        odeId: odeId
      },
      client
    }
  );

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const routes = {
    objectives: "../../objectives",
    odes: "../../odes",
    entrepreneurs: "../../entrepreneurs",
    experts: "../../experts",
    coordinators: "../../coordinators",
    perks: "../../perks",
    tasks: "../../tasks"
  };

  return (
    <BatchDetailsContextProvider>
      <ContentModalContextProvider batchId={id}>
        <BatchObjectiveModalProvider>
          <ContentListAlternativeViewProvider>
            <SidebarLayout menu={<MainMenu />}>
              <Summary />
              <Navbar selectedKeys={["objectives"]} routes={routes} />
              <ObjectivesContent
                data={data}
                loading={loading}
                error={error}
                odeId={odeId} />
            </SidebarLayout>
          </ContentListAlternativeViewProvider>
        </BatchObjectiveModalProvider>
      </ContentModalContextProvider>
    </BatchDetailsContextProvider>
  );
}