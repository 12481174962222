import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { SidebarLayout } from "../../../../../components/shared/sidebar-layout";
import { Summary } from "../../../../../components/admin/batches/show/summary";
import {
  default as Navbar
} from "../../../../../components/entrepreneur/batches/show/navbar";
import {
  BatchOdesList
} from "../../../../../components/admin/batches/show/odes/list";
import {
  BatchOdesContext
} from "../../../../../contexts/admin/batches/odes";
import {
  BatchDetailsContextProvider
} from "../../../../../contexts/admin/batches/batch-details";
import { entrepreneur } from "../../../../../graphql/entrepreneur";
import { client } from "../../../../../../graphql";
import { useQueryFilters } from "../../../../../helpers/use-query-filters";
import { withRouter } from "../../../../../helpers";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { MainMenu } from "../../../../../components/entrepreneur/shared";

export function BatchODEsIndex() {
  const { id = "" } = useParams();

  const {
    data,
    loading,
    refetch,
    error
  } = useQuery(entrepreneur.queries.getBatchOdes, {
    client,
    variables: {
      id: id
    }
  });

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const { applyFilters } = useQueryFilters({ refetch });

  const injectActions = {
    applyFilters,
    data,
    loading,
    error,
    batchId: id
  };

  return (
    <BatchDetailsContextProvider>
      <BatchOdesContext.Provider value={injectActions}>
        <SidebarLayout menu={<MainMenu />}>
          <Summary />
          <Navbar selectedKeys={["odes"]} />
          <BatchOdesList />
        </SidebarLayout>
      </BatchOdesContext.Provider>
    </BatchDetailsContextProvider>
  );
}

export default withRouter(BatchODEsIndex);
