import React, { useEffect, useContext } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { SidebarLayout, Section } from "../../../../../components/shared";
import { admin } from "../../../../../graphql/admin";
import { client } from "../../../../../../graphql";
import { Summary } from "../../../../../components/admin/batches/show/summary";
import { default as Navbar } from "../../../../../components/admin/batches/show/navbar";
import { BatchDetailsContextProvider } from "../../../../../contexts/admin/batches/batch-details";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { OdeUpdateCommentsModalProvider, OdeUpdateModalProvider } from "../../../../../contexts/shared";
import { OdeUpdatesList, FilterControls } from "../../../../../components/shared/ode-updates-list";
import { useQueryFilters } from "../../../../../helpers";

export function OdesUpdatesIndex() {
  const { id } = useParams();

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const {
    loading: batchLoading,
    error: batchError,
    data: batchData
  } = useQuery(
    admin.queries.getBatchById,
    {
      variables: { id: id },
      client
    }
  );

  const {
    loading: odeUpdatesLoading,
    data: odeUpdatesData,
    refetch: refetchOdeUpdates
  } = useQuery(admin.queries.getOdeUpdatesByBatchId, {
    variables: { id },
    client
  });

  const { applyFilters } = useQueryFilters({ refetch: refetchOdeUpdates });

  const onFilter = (values = {}) => {
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...values
    }));
  };

  return (
    <BatchDetailsContextProvider>
      <SidebarLayout>
        <Summary
          isLoading={batchLoading}
          error={batchError}
          data={batchData} />
        <Navbar selectedKeys={["odes-updates"]} />
        <OdeUpdateModalProvider batchId={id}>
          <OdeUpdateCommentsModalProvider>
            <Section
              spaced
              title="Historial"
              extra={<FilterControls
                onFilter={onFilter}
                batchId={id} />
              }>
              <OdeUpdatesList
                loading={odeUpdatesLoading}
                odeUpdates={odeUpdatesData?.odeUpdates} />
            </Section>
          </OdeUpdateCommentsModalProvider>
        </OdeUpdateModalProvider>
      </SidebarLayout>
    </BatchDetailsContextProvider>
  );
}
