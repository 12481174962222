import React, { useContext, useState } from "react";
import { useMutation } from "react-apollo-hooks";
import { OdeUpdateModalContext } from "./context";
import { OdeUpdateModal } from "./modal";
import { shared } from "../../../graphql/shared";
import { CurrentUserContext } from "../current-user";
import { admin } from "../../../graphql/admin";
import { coordinator } from "../../../graphql/coordinator";
import { entrepreneur } from "../../../graphql/entrepreneur";

/**
 * @param {object} props
 * @param {string} props.odeId Helps to determine if the Update is being created
 * from the ODE details page. If so, the modal will show a Batch selector
 * @param {string} props.batchId Helps to determine if the Update is being
 * created from the Batch details page. If so, the modal will show an ODE
 * selector
 */
export function OdeUpdateModalProvider({
  children,
  odeId = undefined,
  batchId = undefined
}) {
  const [state, setState] = useState({
    isModalOpen: false,
    odeUpdateId: undefined
  });

  const { currentUser } = useContext(CurrentUserContext);

  const getRefetchQueries = () => {
    const queries = {
      SUPER_ADMIN: [{
        query: admin.queries.getOdeUpdatesByBatchId,
        variables: { id: batchId }
      }],
      COORDINATOR: [{
        query: coordinator.queries.getOdeUpdatesByCoordinatorId,
        variables: { id: currentUser.id, batchId }
      }],
      ENTREPRENEUR: [{
        query: entrepreneur.queries.getOdeUpdatesByEntrepreneurId,
        variables: { id: currentUser.id, odeId }
      }]
    };

    return queries[currentUser.systemRole];
  };

  const [upsertOdeUpdate] = useMutation(shared.mutations.upsertOdeUpdate, {
    refetchQueries: getRefetchQueries(),
    awaitRefetchQueries: true
  });

  /**
   * Sets the modal visivility to true
   * @param {string | undefined} odeUpdateId Allows to fetch the Update data
   * if necessary
   */
  const openModal = odeUpdateId => {
    setState({ isModalOpen: true, odeUpdateId: odeUpdateId });
  };

  const closeModal = () => {
    setState({ isModalOpen: false, odeUpdateId: undefined });
  };

  const onSave = async (values = {}) => {
    const upsertValues = {
      ...values,
      ode: { connect: { id: values.ode } },
      batch: { connect: { id: values.batch } }
    };

    await upsertOdeUpdate({
      variables: {
        id: state.odeUpdateId || "",
        create: upsertValues,
        update: upsertValues
      }
    });

    closeModal();
  };

  const providerValue = {
    openModal,
    closeModal,
    odeId,
    batchId
  };

  return (
    <OdeUpdateModalContext.Provider value={providerValue}>
      <OdeUpdateModal
        visible={state.isModalOpen}
        onCancel={closeModal}
        onSave={onSave}
        odeUpdateId={state.odeUpdateId} />
      {children}
    </OdeUpdateModalContext.Provider>
  );
}
