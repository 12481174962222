import { getDeletedIdsFromTwoArrays } from "../../../../helpers/get-deleted-ids-from-two-arrays";

export const formatValues = (values, oldResources, currentUser, batchId) => {
    const resourcesWithId = values.resources?.filter(resource => resource.id);
    const newResources = values.resources?.filter(resource => !resource.id);

    const deletedResources = getDeletedIdsFromTwoArrays(
      resourcesWithId,
      oldResources
    );

    const upsertResources = newResources?.map(resource => {
        delete resource.uuid;
        delete resource.__typename;

        return ({
            where: { id: "" },
            create: { ...resource },
            update: { ...resource }
        });
    });

    return({
        ...values,
        batch: {connect: {id: batchId}},
        achievement: {connect: {id: values.achievement}},
        ode: {connect: {id: values.ode}},
        responsable: {connect: {id: values.responsable}},
        createdBy: {connect: {id: currentUser.id}},
        resources: {
            upsert: upsertResources,
            deleteMany: { id_in: deletedResources }
        }
    });
};