import React, { useContext, useState } from "react";
import { useService } from "../../../../../hooks/shared";
import {
  FilterControlsProvider,
  CurrentUserContext,
  PaginationContext
} from "../../../../../contexts/shared";
import { useControlledPagination } from "../../../../../helpers";
import {
  EntrepreneurSessionsServicesService
} from "../../../../../services/entrepreneur-sessions-services-service";

/**
 * @param {Object} props
 * @param {React.ReactElement} props.children
 * @param {"ASSIGNATIONS" | "TRACKING"}  props.tableType
 * @param {React.ReactElement} props.filterControls
 * @returns {JSX.Element}
 * @constructor
 */
export function SessionsTableWrapper({
  children,
  tableType = "ASSIGNATIONS",
  filterControls = null
}) {
  const { currentUser } = useContext(CurrentUserContext);
  const [filters, setFilters] = useState({});

  const dataServicesMap = {
    ASSIGNATIONS: EntrepreneurSessionsServicesService.getAssignedServices,
    TRACKING: EntrepreneurSessionsServicesService.getScheduledServices
  };

  const initialPageSize = 12;
  const variables = {
    first: initialPageSize,
    entrepreneurId: currentUser?.id,
    ...filters
  };

  const {
    data,
    loading,
    refetch
  } = useService(dataServicesMap[tableType], variables);

  const paginationRefetchName = `monitoring${tableType}`;

  const { registerRefetch } = useContext(PaginationContext);
  registerRefetch(paginationRefetchName, refetch, variables);

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize
  });

  const items = (data?.assignedExpertServices || data?.scheduledExpertServices) || [];

  return (
    <>
      <FilterControlsProvider
        refetch={refetch}
        onPaginationChange={onPaginationChange}
        defaultFilters={variables}
        afterApplyFilters={setFilters}>
        {filterControls}
      </FilterControlsProvider>
      {React.cloneElement(children, {
        items,
        loading,
        currentPage,
        onChangePage: onPaginationChange,
        totalRecordsCount: data?.count,
        paginationRefetchName
      })}
    </>
  );
}
