import React from "react";
import { Steps } from "antd";

export const CreateCompanySteps = ({ current }) => {
  const { Step } = Steps;
  return (
    <Steps
      size="small"
      current={current}
      style={{ padding: "0 70px" }}>
      <Step title="Información general" />
      <Step title="Sobre tu compañía" />
      <Step title="Contacto" />
      <Step title="Hitos" />
    </Steps>
  );
};
