import React from "react";
import { Input, Row, Col } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Visibility } from "../../../../../components/shared";

export function AchievementListItem({
  idx,
  removeItem,
  onChange,
  viewMode = false ,
  value: achievement
}) {

  const handleOnChange = value => {
    achievement.value = value;
    onChange(achievement);
  };

  return (
    <Row
      gutter={[10, 10]}
      align="middle"
      style={{ width: "100%" }}>
      <Visibility
        visible={!viewMode}>
        <Col span={2} style={{ textAlign: "center" }}>
          <CloseCircleOutlined
            onClick={removeItem(idx)} />
        </Col>
      </Visibility>
      <Col span={22}>
        <Input
          disabled={viewMode}
          onChange={e => handleOnChange(e.target.value)}
          className="wide-input"
          placeholder="Logro"
          defaultValue={achievement?.value} />
      </Col>
    </Row>
    );
}
