import { useQuery } from "@apollo/react-hooks";
import React, { useContext, useEffect } from "react";
import { client } from "../../../../../graphql";
import { WorkspacesList } from "../../../../components/admin/configuration/workspaces";
import { MainMenu } from "../../../../components/admin/shared";
import { Section, SidebarLayout } from "../../../../components/shared";
import { WorkspaceModalProvider } from "../../../../contexts/admin/configuration/workspaces/workspace-modal";
import { MenuContext } from "../../../../contexts/shared/menu";
import { getWorkspaces } from "../../../../graphql/admin/queries";

export function WorkspacesIndex() {
  const { setMenuState } = useContext(MenuContext);
  const { data, loading } = useQuery(getWorkspaces, { client });

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["configuration"],
      selectedKeys: ["configuration/workspaces"]
    });
  }, []);

  return (
    <WorkspaceModalProvider>
      <SidebarLayout menu={<MainMenu />}>
        <Section
          title="Workspaces"
          spaced>
          <WorkspacesList
            data={data}
            loading={loading} />
        </Section>
      </SidebarLayout>
    </WorkspaceModalProvider>
  );
}
