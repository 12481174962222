import React, { useState, useEffect, useContext } from "react";
import { Button, Divider, Form, Input, message, Modal, Row, Select, TimePicker, Typography } from "antd";
import * as moment from "moment";
import { USER_ROLE } from "../../../helpers";
import { DatePicker, Visibility } from "../../../components/shared";
import { FileLinkField } from "../../../components/shared/file-link-field";
import { contentTypes } from "../../../helpers/select-options/content";
import { CurrentUserContext } from "../current-user";
import { formatValues } from "./format-values";
import { placeholders } from "./placeholders";

export function ContentModal({
  onSave,
  onCancel,
  contentGroup,
  content,
  isPublished,
  editing,
  ...props
}) {

  const [form] = Form.useForm();
  const { currentUser } = useContext(CurrentUserContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [selectedType, setSelectedType] = useState("ACTIVITY");

  const { fullName, systemRole } = currentUser;

  const { Title, Text } = Typography;

  const onOk = async () => {
    await form.validateFields();
    const dismissLoading = message.loading("Guardando...");
    setSaveLoading(true);
    try {
      const values = form.getFieldsValue();
      values.oldMaterials = content?.materials;
      const formatedValues = formatValues(
        values,
        currentUser,
        content?.id,
        editing,
        isPublished
      );
      onSave(formatedValues);
      form.resetFields();
      onCancel && onCancel();
      message.success("Cambios guardados.");
    } catch (e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
        en unos segundos.");
      console.error(e);
    } finally {
      setSaveLoading(false);
      dismissLoading();
    }
  };

  const onCancelModal = () => {
    setSaveLoading(false);
    form.resetFields();
    onCancel && onCancel();
  };

  const onTypeChange = value => {
    setSelectedType(value);
  };

  useEffect(() => {
    if(content){
      form.setFieldsValue({ ...content, dueDateTime: moment(content.dueDate) });
      setSelectedType(content?.type);
    }
  }, [content]);

  const ModalHeader = (
    <div style={{
      backgroundColor: "#F5F3FD",
      padding: "20px"
    }}>
      <Title level={5}>{contentGroup.title}</Title>
      <div
        style={{
          fontWeight: "400",
          display: "flex",
          flexDirection: "column"
        }}>
        <Text>{contentGroup.description}</Text>
        <Text
          style={{
            fontSize: "14px",
            marginTop: "7px"
          }}>
          Asignado por <span style={{ color: "#704AED" }}>{fullName} ({USER_ROLE[systemRole]})</span></Text>
      </div>
    </div>
  );

  return (
    <Modal
      className="create-content-modal"
      width={841}
      destroyOnClose
      onCancel={onCancelModal}
      title={ModalHeader}
      footer={[
        <Button
          key="cancel"
          onClick={onCancelModal}>Cancelar</Button>,
        <Button
          key="guardar"
          type="primary"
          onClick={onOk}
          loading={saveLoading}>Guardar</Button>
      ]}
      {...props}>
      <Title level={5}>Agregar contenido</Title>
      <Form
        form={form}
        layout="vertical"
        style={{
          paddingLeft: "25px",
          paddingRight: "25px"
        }}>
        <Text type="secondary">Agrega contenidos o recursos de apoyo para tu sección. Los contenidos son una unidad básica de acción o conocimiento que tus compañías deben ir realizando durante el curso del batch.</Text>
        <Form.Item
          label="Tipo de contenido"
          name="type"
          initialValue={selectedType}
          rules={[{ required: true }]}>
          <Select
            placeholder="Seleccionar"
            value={selectedType}
            onChange={onTypeChange}
            options={contentTypes} />
        </Form.Item>
        <Form.Item
          label="Nombre del contenido"
          name="name"
          rules={[{ required: true }]}>
          <Input
            type="text"
            placeholder={placeholders.name} />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description"
          rules={[{ required: true }]}>
          <Input.TextArea rows={3} placeholder={placeholders.description[selectedType]} />
        </Form.Item>
        <Visibility visible={selectedType === "ACTIVITY"}>
          <Form.Item
            label="Instrucciones"
            name="instructions">
            <Input.TextArea rows={3} placeholder={placeholders.instructions} />
          </Form.Item>
        </Visibility>
        <Visibility visible={["ACTIVITY", "WORKSHOP"].includes(selectedType) }>
          <Row justify="space-between">
            <Form.Item
              label={
                selectedType === "WORKSHOP"
                  ? "Fecha"
                  : "Fecha límite de entrega"}
              name="dueDate"
              rules={[{ required: true }]}
              style={{ width: "49%" }}>
              <DatePicker />
            </Form.Item>
            <Form.Item
              label="Hora"
              name="dueDateTime"
              style={{ width: "49%" }}>
              <TimePicker
                placeholder={placeholders.releaseTime}
                className="wide-input" />
            </Form.Item>
          </Row>
          <Row justify="space-between">
            <Form.Item
              label="Lugar"
              name="placeType"
              style={{ width: "30%" }}>
              <Select
                placeholder={placeholders.placeType}
                options={[{ label: "Presencial", value: "PRESENCIAL"}, { label: "Digital", value: "DIGITAL" }]} />
            </Form.Item>
            <Form.Item
              label="Link o ubicación"
              name="placeDirection"
              style={{ width: "68%" }}>
              <Input placeholder={placeholders.placeDirection} />
            </Form.Item>
          </Row>
        </Visibility>
        <Divider />
        <Form.Item
          label={<b>Recursos</b>}
          name="materials"
          style={{ marginBottom: "0" }}>
          <FileLinkField />
        </Form.Item>
      </Form>
    </Modal>
  );
}
