import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  SidebarLayout,
  ContentCompaniesProgressTable, Section
} from "../../../../../components/shared";
import {
  BatchDetailsContextProvider
} from "../../../../../contexts/admin/batches";
import { Summary } from "../../../../../components/admin/batches/show";
import {
  default as Navbar
} from "../../../../../components/admin/batches/show/navbar";
import {
  useBatchContentsProgressTable
} from "./use-batch-companies-contents-progress-table";
import { useService } from "../../../../../hooks/shared";
import {
  BatchContentService
} from "../../../../../services/batch-content-service";
import { BatchesService } from "../../../../../services";
import {
  ProgressBar
} from "../../../../../components/shared/content-companies-progress-table/progress-bar";
import {
  Filters
} from "../../../../../components/shared/content-companies-progress-table/filters";

export default () => {
  const { id: batchId } = useParams();

  const { data: batchCompanies, loading: loadingCompanies } = useService(
    BatchesService.getLightBatchCompanies,
    { batchId }
  );

  const { data: breakdown, loading: loadingBreakdown } = useService(
    BatchContentService.getBatchContentsProgressBreakdown,
    { batchId }
  );

  const { data: publishedMasterContents, loading: masterContents } = useService(
    BatchContentService.getBatchPublishedMasterContents,
    { batchId }
  );

  const { data: customContents, loading: loadingCustomContents } = useService(
    BatchContentService.getCustomOdesContents,
    { batchId, batchOdesIds: batchCompanies?.map(({ id }) => id) || [] },
    { shouldFetch: batchCompanies !== null }
  );

  const loadingData = loadingCompanies
    || loadingBreakdown
    || masterContents
    || loadingCustomContents;

  const contentsSorting = useMemo(() => {
    return (publishedMasterContents?.contentGroups || [])
      .reduce((contentsSortingMap, section) => {
        contentsSortingMap[section.id] = section.contentsSort || [];
        return contentsSortingMap;
      }, {});
  }, [publishedMasterContents]);

  const {
    companiesRows,
    contentColumns,
    filterContent
  } = useBatchContentsProgressTable({
    batchProgress: breakdown,
    companies: batchCompanies,
    customOdeContents: customContents,
    batchContentSections: publishedMasterContents?.contentGroups || [],
    sectionsSorting: publishedMasterContents?.contentGroupsSort || [],
    contentsSorting
  });

  const batchProgressPercentage = breakdown
    ?.batch
    ?.globalProgress
    ?.overallPercentage || 0;

  const generateGoToCompanyUrl = companyId => {
    return `../${batchId}/odes/${companyId}/content`;
  };

  const handleChange = filter => {
    setFilter && setFilter(prevState => ({ ...prevState, ...filter }));
  };

  const [filter, setFilter] = useState({
    selectedCompanies: [],
    selectedSections: [],
    selectedContentType: undefined
  });

  const filteredCompaniesRows = useMemo(() => {
    if (filter.selectedCompanies.length === 0) {
      return companiesRows;
    } else {
      return companiesRows.filter(
        companyRow => filter.selectedCompanies.includes(companyRow.key)
      );
    }
  }, [companiesRows, filter]);

  const filteredContentColumns = useMemo(() => {
    if (filter.selectedSections.length === 0) {
      return contentColumns;
    } else {
      return contentColumns.filter(
        column => filter.selectedSections.includes(column.sectionId)
      );
    }
  }, [contentColumns, filter]);

  useEffect(() => {
    if (companiesRows.length) {
      filterContent(filter.selectedContentType);
    }
  }, [filter.selectedContentType]);

  return (
    <BatchDetailsContextProvider>
      <SidebarLayout>
        <Summary />
        <Navbar selectedKeys={["batch-companies-progress" ]} />
        <Section title="Analytics" spaced>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <ProgressBar progressPercentage={batchProgressPercentage} />
            <Filters
              onChange={handleChange}
              setFilter={setFilter}
              batchCompanies={batchCompanies}
              contentColumns={contentColumns} />
            <ContentCompaniesProgressTable
              loading={loadingData}
              companiesRows={filteredCompaniesRows}
              contentColumns={filteredContentColumns}
              generateGoToCompanyUrl={generateGoToCompanyUrl}
              batchProgressPercentage={batchProgressPercentage} />
          </div>
        </Section>
      </SidebarLayout>
    </BatchDetailsContextProvider>
  );
};
