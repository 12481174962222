import React, { useState, useContext, useEffect } from "react";
import { Form, Input, Select } from "antd";
import { FormContainer } from "../../../../../shared";
import { filterOption, countries, states } from "../../../../../../utils";
import {
  NewExpertContext
} from "../../../../../../contexts/experts/new/context";
import {
  fieldValidationMessages
} from "../../../../../../utils/fieldValidationMessages";

const { Item } = Form;

const LocationForm = () => {
  const {
    updatePersonalInformation,
    loading,
    error,
    data,
    form
  } = useContext(NewExpertContext);

  const [countryId, setCountryId] = useState();
  const countryStates = states.filter(state => state.id_country === countryId);

  useEffect(() => {
    const { user = {} } = data || {};
    const countryId = user?.expertProfile?.location?.country;
    const personalInfo = {
      country: countryId ?
        parseInt(user?.expertProfile?.location?.country)
        : undefined,
      state: user?.expertProfile?.location?.state,
      city: user?.expertProfile?.location?.city
    };
    form.setFieldsValue(personalInfo);
  }, [data, loading, error]);

  const handleOnChange = (changedFields = []) => {
    const { name = [], value } = changedFields[0] || {};
    const fieldName = name[0];

    updatePersonalInformation({
      [fieldName]: value
    });
  };

  return (
    <FormContainer>
      <Form
        validateMessages={fieldValidationMessages}
        onFieldsChange={handleOnChange}
        layout="vertical"
        form={form}>
        <Item
          name="country"
          label="País"
          rules={[{ required: true }]}>
          <Select
            showSearch
            onChange={setCountryId}
            filterOption={filterOption}
            placeholder="Seleccionar">
            {countries.map(country => (
              <Select.Option
                key={country.id}
                value={country.id}>
                {country.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Item
          name="state"
          label="Estado"
          rules={[{ required: true }]}>
          <Select
            showSearch
            filterOption={filterOption}
            disabled={countryId === undefined}
            placeholder="Seleccionar">
            {countryStates.map(state => (
              <Select.Option key={state.id} value={state.name}>
                {state.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Item
          name="city"
          label="Ciudad"
          rules={[{ required: true }]}>
          <Input disabled={countryId === undefined} />
        </Item>
      </Form>
    </FormContainer>
  );
};

export default LocationForm;
