import { limitDateReached } from "../../helpers";

export const maskedStatus = ({
  status,
  limitDate,
  availableUnits,
  assignedUnits
}) => {
  if (status === "CANCELLED") {
    return "CANCELLED_ASSIGNATION";
  }

  if (limitDateReached(limitDate)) {
    return "EXPIRED";
  }

  if (+availableUnits === 0) {
    return "CONSUMED";
  }

  if (assignedUnits) {
    if ((+assignedUnits !== +availableUnits)) {
      return "CONSUMING";
    }
  }

  return status;
};

export const maskedStatusEntrepreneurSessions = ({ status }) => {
  if ([
    "AUTHORIZED_FOR_PAYMENT",
    "PAYMENT_IN_PROCESS",
    "PAID"
  ].includes(status)) {
    return "ACCEPTED";
  }

  return status;
};
