import gql from "graphql-tag";

/**
 * @legacy from v1
 * @type {DocumentNode}
 */
export const LOGIN = gql`
  query($email: String, $password: String) {
    login(where: { email: $email, password: $password }) {
      email
      session
    }
  }
`;
