import React, { useState, useContext } from "react";
import { Empty, List } from "antd";
import moment from "moment";
import { CompositeField, FormContainer } from "../../../../../shared";
import {
  NewExpertContext
} from "../../../../../../contexts/experts/new/context";
import { EntrepreneurshipModal } from "../modal";
import { EntrepreneurshipItem } from "../item";

export function EntrepreneurshipsForm({ editable = true }) {
  const [state, setState] = useState({ isModalOpen: false, edit: false });
  const {
    updateEntrepreneurships,
    data = {}
  } = useContext(NewExpertContext);

  const {
    entrepreneurships = []
  } = data?.user?.expertProfile?.professionalExperience || {};

  const defaultValue = entrepreneurships
    .map(({ startDate, endDate, ...e }) => ({
      ...e,
      period: [ // period is saved as "start" and "end" dates in the DB
        startDate ? moment(startDate) : null,
        endDate ? moment(endDate) : null
      ]
    }));

  const onClickAdd = () => {
    setState({ isModalOpen: true });
  };

  const onCancel = () => {
    setState({ isModalOpen: false });
  };

  const onSave = (addNew, replaceItemAtIndex) => values => {
    if (typeof values.index === "undefined") {
      addNew(values);
    } else {
      const index = values.index;
      delete values.index;

      replaceItemAtIndex(index, values);
    }
    onCancel();
  };

  const onEdit = (item, index) => () => {
    item.index = index;
    setState({ isModalOpen: true, edit: item });
  };

  const handleOnChange = values => {
    if (updateEntrepreneurships) {
      updateEntrepreneurships(values);
    }
  };

  return (
    <FormContainer>
      <CompositeField
        onChange={handleOnChange}
        isAddDisabled={!editable}
        defaultValue={defaultValue}
        onClickAdd={onClickAdd}
        addLabel="Agregar emprendimiento">
        {({items, addNew, removeItem, replaceItemAtIndex}) =>
          <>
            <EntrepreneurshipModal
              onSave={onSave(addNew, replaceItemAtIndex)}
              onCancel={onCancel}
              visible={state.isModalOpen}
              edit={state.edit} />
            <List>
              { items.map((item, index) =>
                <EntrepreneurshipItem
                  editable={editable}
                  key={item.uuid}
                  entrepreneurship={item}
                  onDelete={removeItem(index)}
                  onEdit={onEdit(item, index)} />
              ) }
              { (items.length === 0) ?
                <Empty />
              : null }
            </List>
          </>
        }
      </CompositeField>
    </FormContainer>
  );
}
