import React from "react";
import { Col, Form, Row, Select } from "antd";
import {
  organizationPrivateOptions,
  organizationServicesOptions,
  organizationsStatesOptions,
  organizationStagesOptions
} from "../../../../../helpers";

export function DashboardFilterControls({ onChange }) {

  const handleChange = data => {
    onChange && onChange(data);
  };

  return (
    <div className="FilterControls_organization">
      <Form layout="vertical">
        <Row
          gutter={[16]}
          justify="space-between"
          align="middle">
          <Col span={5}>
            <Form.Item label="Origen">
              <Select
                mode="multiple"
                allowClear={true}
                onChange={value => handleChange({ origin: value })}
                placeholder="Selecciona el origen"
                options={organizationPrivateOptions} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Servicios que ofrece">
              <Select
                mode="multiple"
                allowClear={true}
                onChange={services => handleChange({services})}
                placeholder="Selecciona los servicios que ofrece"
                options={organizationServicesOptions} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Etapa en la que apoya">
              <Select
                mode="multiple"
                allowClear={true}
                onChange={stages => handleChange({stages})}
                placeholder="Selecciona las etapas de la lista"
                options={organizationStagesOptions} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              label="Presencia">
              <Select
                mode="multiple"
                showSearch
                allowClear={true}
                autoComplete="off"
                onChange={states => handleChange({states})}
                placeholder="Nacional"
                options={organizationsStatesOptions} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Dirigida a mujeres">
              <Select
                mode="multiple"
                showSearch
                allowClear={true}
                autoComplete="off"
                onChange={ranByWomen => handleChange({ranByWomen})}
                placeholder="Selecciona una opcion"
                options={[
                    {
                        label: "Sí",
                        value: true,
                    },
                    {
                        label: "No",
                        value: false,
                    },
                ]} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
