import { shared } from "../../../graphql/shared";

export async function updateProfile(data, userId) {
  return await this.client.mutate({
    mutation: shared.mutations.updateProfile,
    awaitRefetchQueries: true,
    variables: {
      id: userId,
      data: data
    },
    refetchQueries: [
      {
        query: shared.queries.getExpertById,
        variables: { id: userId }
      },
      {
        query: shared.queries.getProfileDataByUserId,
        variables: { id: userId }
      }
    ]
  });
}
