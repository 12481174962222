import React from "react";
import { Avatar, IconLabel } from "../../../../../shared";
import { List } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

export function ExpertItem({expert}) {
  const {
    fullName = "Sin nombre",
    email = "N/A",
    phone = "N/A",
    profilePicUrl
  } = expert;

  return (
    <List.Item
      actions={[
        <IconLabel
          key="ent-mail"
          icon={<MailOutlined />}>
          {email}
        </IconLabel>,
        <IconLabel
          key="ent-phone"
          icon={<PhoneOutlined />}>
          {phone}
        </IconLabel>
      ]}>
      <List.Item.Meta
        avatar={<Avatar>{profilePicUrl || fullName}</Avatar>}
        title={fullName} />
    </List.Item>
  );
}
