import React from "react";
import { Steps as ASteps } from "antd";

export function VerificationSteps({ current }) {
  return (
    <ASteps
      size="small"
      style={{
        paddingLeft: "100px"
      }}
      direction={"vertical"}
      current={current}>
      <ASteps.Step title="Creación de la cuenta" />
      <ASteps.Step title="Completar perfil" />
      <ASteps.Step title="Completar perfil de la compañía" />
    </ASteps>
  );
}
