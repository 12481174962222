import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  CurrentUserContext,
  MenuContext
} from "../../../../../../contexts/shared";
import { Summary } from "../../../../../../components/admin/batches/show";
import {
  SidebarLayout,
  AnnouncementsSectionDetail
} from "../../../../../../components/shared";
import {
  BatchDetailsContextProvider
} from "../../../../../../contexts/admin/batches";
import {
  MainMenu as CoordMenu
} from "../../../../../../components/coordinator/shared";
import {
  MainMenu as EntrepreneurMenu
} from "../../../../../../components/entrepreneur/shared";
import {
  CreateAnnouncementModalProvider
} from "../../../../../../contexts/shared/create-annoucement-modal";
import {
  default as EntrepreneurNavbar
} from "../../../../../../components/entrepreneur/batches/show/navbar";
import {
  default as CoordNavbar
} from "../../../../../../components/admin/batches/show/navbar";

export const AnnouncementsIndexDetail = () => {
  const { id: batchId } = useParams();
  const { setMenuState } = useContext(MenuContext);
  const { currentUser } = useContext(CurrentUserContext);

  setMenuState({
    openKeys: ["programs"],
    selectedKeys: ["batches"]
  });

  const routes = {
    objectives: "../objectives",
    experts: "../experts",
    odes: "../odes",
    entrepreneurs: "../entrepreneurs",
    coordinators: "../coordinators",
    board: "../board",
    trainings: "../trainings",
    tasks: "../tasks",
    calendar: "../calendar",
    perks: "../perks",
    content: "../content",
    announcements: "../announcements",
    odesUpdates: "../odes-updates"
  };

  const MainMenu = currentUser.systemRole === "COORDINATOR" ?
    CoordMenu
    : currentUser.systemRole === "ENTREPRENEUR" ? EntrepreneurMenu : null;
  const Navbar = currentUser.systemRole === "COORDINATOR" ?
    CoordNavbar
    : currentUser.systemRole === "ENTREPRENEUR" ? EntrepreneurNavbar : null;

  return (
    <BatchDetailsContextProvider>
      <CreateAnnouncementModalProvider batchId={batchId}>
        <SidebarLayout menu={<MainMenu />}>
          <Summary />
          <Navbar routes={routes} selectedKeys={["announcements"]} />
          <AnnouncementsSectionDetail />
        </SidebarLayout>
      </CreateAnnouncementModalProvider>
    </BatchDetailsContextProvider>
  );
};
