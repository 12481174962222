import React from "react";
import { DragIcon, Format } from "../../../";
import { textContentTypes } from "../../../../../helpers/select-options/content";
import { TextTag } from "../../../text-tag";
import { Visibility } from "../../../visibility";

export const ContentItem = ({ content, isDragable, dragHandleProps, ...props }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%" }}
      {...dragHandleProps}
      {...props}>
      <div style={{ height: "fit-content" }}>
        {isDragable && <DragIcon style={{ marginRight: "10px" }} />}
        <p style={{ marginRight: "10px" }}>{content.name}</p>
        <TextTag options={textContentTypes}>
          {content.type}
        </TextTag>
      </div>
      <div style={{ height: "fit-content" }}>
        <Visibility visible={!!content.dueDate}>
          <div style={{ marginRight: "20px" }}>
            <Format type="date">{content.dueDate}</Format>
          </div>
        </Visibility>
      </div>
    </div>
  );
};
