import gql from "graphql-tag";

export const connectTeamMemberToOde = gql`
  mutation ConnectTeamMemberToOde($odeId: ID!, $data: addOdeTeamMembersInput!) {
    addOdeTeamMembers(
      where: { id: $odeId }
      data: $data
    ) {
      count
    }
  }
`;
