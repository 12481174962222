import gql from "graphql-tag";

export const getImpactedCommunityTableQuery = gql`
    query GetImpactedCommunityTableQuery(
        $campus: String
        $startDate: DateTime
        $endDate: DateTime
        $audienceTypes: [String]
    ) {
        impactedCommunityTable(
            criteria: {
                campus: $campus
                audienceTypes: $audienceTypes
                attendance: {
                    experience: {
                        startDate: $startDate
                        endDate: $endDate
                    }
                }
            }
        )
    }
`;
