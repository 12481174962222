import gql from "graphql-tag";

export const updateScheduledExpertService = gql`
  mutation UpdateScheduledExpertService(
    $id: ID!,
    $data: ScheduledExpertServiceUpdateInput!
  ) {
    updateScheduledExpertService(where: { id: $id }, data: $data) {
      id
    }
  }
`;
