import React, { useContext } from "react";
import { List, Alert } from "antd";
import {
  EntrepreneurExpertsContext
} from "../../../../contexts/entrepreneur/experts";
import { withRouter, paginationConfig } from "../../../../helpers";
import { ExpertCard } from "../../../shared";
import { PaginationContext } from "../../../../contexts/shared";

function ExpertsList({
  navigate,
  onChangePage,
  currentPage
}) {
  const { data, error, loading } = useContext(EntrepreneurExpertsContext);
  const { onPaginationChange } = useContext(PaginationContext);

  if(error) {
    return (
      <Alert
        type="error"
        message="Ha ocurrido un error al cargar los expertos,
        por favor intentalo de nuevo en unos segundos."
        showIcon />
    );
  }

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange("experts")(page, pageSize);
  };

  return (
    <List
      renderItem={user =>
        <List.Item
          key={`entr-experts-list-item-${user?.id}`}
          className="clickable"
          onClick={() => navigate(`./experts/${user.id}/profile`)}>
          <ExpertCard expert={user} />
        </List.Item>
      }
      itemLayout="vertical"
      pagination={{
        ...paginationConfig,
        current: currentPage,
        defaultPageSize: 12,
        onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
        total: data?.meta?.aggregate?.count || 0
      }}
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 3,
        xl: 4,
        xxl: 4
      }}
      dataSource={data?.users}
      loading={loading} />
  );
}

export default withRouter(ExpertsList);
