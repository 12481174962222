import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Checkbox } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { merge } from "lodash";
import { states } from "../../../../../../utils";

export function EntrepreneurshipModal({onSave, onCancel, edit, ...props}) {
  const [form] = Form.useForm();
  const [state, setState] = useState({
    allowEmpty: [false, false],
    placeholder: undefined,
    current: false
  });

  useEffect(() => {
    if(edit) {
      form.setFieldsValue(edit);
    }
  }, [edit]);

  const onOk = async() => {
    try {
      let values = await form.getFieldsValue();

      if (typeof edit?.index !== "undefined") {
        values.index = edit.index;
        values = merge(edit, values);
      }

      onSave(values);
      form.resetFields();
    }
    catch(e) {
      console.error(e);
    }
  };

  const handleOnCancel = e => {
    if (onCancel) {
      onCancel(e);
    }
    form.resetFields();
  };

  const onCheckboxChange = e => {
    if(e.target.checked) {
      setState({
        ...state,
        allowEmpty: [false, true],
        placeholder: ["Fecha inicial", "Actualidad"],
        current: e.target.checked
      });
      const values = form.getFieldsValue();
      form.setFieldsValue({ period: [values.period?.[0], null] });
    }
    else {
      setState({
        ...state,
        allowEmpty: [false, false],
        placeholder: ["Fecha inicial", "Fecha final"],
        current: e.target.checked
      });
    }
  };

  const onCalendarChange = e => {
    if(e[1] !== null) {
      setState({
        ...state,
        current: false
      });
    }
  };

  return (
    <Modal
      className="modal-entrepreneurship"
      okText="Guardar"
      cancelText="Cancelar"
      title="Emprendimiento"
      onOk={onOk}
      onCancel={handleOnCancel}
      {...props}>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          name="name"
          label="Nombre de emprendimiento / Proyecto">
          <Input />
        </Form.Item>
        <Form.Item
          name="period"
          label="Periodo de emprendimiento">
          <DatePicker.RangePicker
            onCalendarChange={onCalendarChange}
            style={{width: "100%"}}
            renderExtraFooter={() =>
              <Checkbox
                onChange={onCheckboxChange}
                checked={state.current}
                style={{float: "right"}}>
                Hasta la actualidad
              </Checkbox>
            }
            allowEmpty={state.allowEmpty}
            placeholder={state.placeholder} />
        </Form.Item>
        <Form.Item
          name="position"
          label="Puesto">
          <Input />
        </Form.Item>
        <Form.Item
          name="achievements"
          label="Logros alcanzados">
          <TextArea></TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
}
