import React  from "react";
import { Typography, Space, PageHeader, List } from "antd";
import { withLoader } from "../../../helpers";
import { Section } from "../section";
import parse from "html-react-parser";
import { Avatar } from "../avatar";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { FileLinkItem } from "../file-link-item";

function AnnouncementsTable({ data, onGoBack }) {
  const { announcement = "" } = data;
  const history = useHistory();

  const handleOnGoBack = () => {
    if (onGoBack) {
      onGoBack();
    } else {
      history.goBack();
    }
  };

  return (
    <Section
      spaced
      title={
        <PageHeader
          backIcon={<ArrowLeftOutlined />}
          subTitle="Avisos"
          onBack={handleOnGoBack} />
      }>
      <Space style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Title level={2}>
          {announcement?.title}
        </Typography.Title>
        <Space>
          <Avatar
            style={{ backgroundColor: "#87d068" }}
            src={announcement?.createdBy?.profilePicUrl}>
            {announcement?.createdBy?.fullName}
          </Avatar>
          <p>{announcement?.createdBy?.fullName}</p>
        </Space>
      </Space>
      {
        !announcement?.image ? null :
        <img
          width="100%"
          height={500}
          alt="Cover del aviso"
          src={announcement?.image}
          style={{ objectFit: "contain" }} />
      }
      {parse(announcement?.body)}
      <Typography.Paragraph strong={true}>
        Archivos adjuntos
      </Typography.Paragraph>
      <List
        dataSource={announcement?.documents}
        renderItem={item => <FileLinkItem item={item} />} />
    </Section>
  );
}

export default withLoader(AnnouncementsTable);
