import gql from "graphql-tag";

export const getCompanyPerksQuery = gql`
  query GetBatchPerksByCompanyId (
    $batchId: ID!,
    $companyId: ID!
  ) {
    perks(
      where: {
        batches_some: { id: $batchId }
        odes_some: { id: $companyId }
      }
    ) {
      id
      coverPicUrl
      name
      expiration
      program{
        id
        name
        coverPicUrl
      }
      category
      description
      contactInfo
      batches{
        id
        name
        program{
          id
          coverPicUrl
        }
      }
      odes{
        id
        name
        logoUrl
      }
    }
  } 
`;
