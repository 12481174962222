import React, { useEffect } from "react";
import { Col, Divider, Modal, Row } from "antd";
import { Section } from "../../../";
import { GeneralInformation } from "./general-information";
import { CompanyNavBar } from "./navbar";
import { AboutYourCompany } from "./about-your-company";
import { Milestones } from "./milestones";
import {
  CompanyDetailIndex,
  CompanyStatisticsIndex,
  CompanyTimelineIndex
} from "../";
import { TeamMembers } from "./team-members";
import { CompanyMultimedia } from "./multimedia";
import { Batches } from "./batches";
import { useCompany } from "../../../../../hooks";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { cloneDeep } from "lodash";
import { getUncompletedCompanyData } from "../../../../../helpers";

export const OdeDetail = ({
  data,
  loading,
  refetch,
  companyId,
  allowEdit = true
}) => {
  const {
    updateCompany,
    transformOdeToCompany
  } = useCompany(
    { odeId: data?.id }
  );

  const showDashboardAlert = () => {
    const onCancel = async () => {
      await updateCompany({
        data: { hideDashboardAlert: true },
        odeId: data?.id
      });
    };

    const onOk = async () => {
      await transformOdeToCompany({ odeId: data?.id });
    };

    return Modal.confirm({
      width: 600,
      icon: <ExclamationCircleOutlined style={{ color: "#0170fe" }} />,
      title: "Hay una nueva versión del perfil de la compañía y la información solicitada es diferente.",
      content: "¿Autorizas la recuperación de tu información del perfil anterior o deseas comenzar de cero?",
      okText: "Sí, recuperar la información",
      cancelText: "No, prefiero llenarlo manual",
      okButtonProps: { style: { backgroundColor: "#0170fe" }},
      onOk,
      onCancel
    });
  };

  useEffect(() => {
    if(data) {
      if(!data?.hideDashboardAlert) {
        showDashboardAlert();
      }
    }
  }, [data]);

  const uncompletedCompany = getUncompletedCompanyData(data || {});

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Section>
              <CompanyDetailIndex
                loading={loading}
                allowEdit={allowEdit}
                data={data ? cloneDeep(data) : null}
                contactAlert={uncompletedCompany?.contact}
                detailAlert={uncompletedCompany?.generalInformation} />
            </Section>
          </Col>
          <Col span={24}>
            <Section title="Timeline">
              <CompanyTimelineIndex
                loading={loading}
                data={data} />
            </Section>
          </Col>
        </Row>
      </Col>
      <Col span={18}>
        <Section>
          <CompanyNavBar />
          <div id="general-information">
            <CompanyStatisticsIndex
              data={data} />
          </div>
          <Divider />
          <div>
            <GeneralInformation
              data={data}
              loading={loading}
              allowEdit={allowEdit}
              visible={uncompletedCompany?.generalInformation} />
          </div>
          <Divider id="about" />
          <div>
            <AboutYourCompany
              data={data}
              loading={loading}
              allowEdit={allowEdit}
              aboutYourCompanyAlert={uncompletedCompany?.aboutYourCompany} />
          </div>
          <div>
            <CompanyMultimedia
              refetch={refetch}
              data={data}
              loading={loading}
              allowEdit={allowEdit} />
          </div>
          <div
            id="hitos"
            style={{height: "10px"}} />
          <Divider />
          <div>
            <Milestones
              data={data?.milestones || []}
              refetch={refetch}
              allowEdit={allowEdit} />
          </div>
          <div
            id="team"
            style={{height: "10px"}} />
          <Divider />
          <div>
            <TeamMembers
              companyId={companyId}
              allowEdit={allowEdit} />
          </div>
          <div
            id="batches"
            style={{height: "10px"}} />
          <Divider />
          <div>
            <Batches data={data} />
          </div>
        </Section>
      </Col>
    </Row>
  );
};
