export const CAMPUS_LIST = [
  "Campus Aguascalientes",
  "Campus Central de Veracruz",
  "Campus Chiapas",
  "Campus Chihuahua",
  "Campus Ciudad de México",
  "Campus Ciudad Juárez",
  "Campus Ciudad Obregón",
  "Campus Cuernavaca",
  "Campus Estado de México",
  "Campus Guadalajara",
  "Campus Hidalgo",
  "Campus Irapuato",
  "Campus Laguna",
  "Campus León",
  "Campus Monterrey",
  "Campus Morelia",
  "Campus Puebla",
  "Campus Querétaro",
  "Campus Saltillo",
  "Campus San Luis Potosí",
  "Campus Santa Fe",
  "Campus Sinaloa",
  "Campus Sonora Norte",
  "Campus Tampico",
  "Campus Toluca",
  "Campus Zacatecas"
];
