import React from "react";
import * as processors from "./processors";

export function Format({ children, type, ...props }) {
  if(children === null) {
    children = undefined;
  }

  return (
    <>{processors[type](children, props)}</>
  );
}
