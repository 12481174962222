import gql from "graphql-tag";

export const getOdeUpdatesByEntrepreneurId = gql`
  query GetEntrepreneurOdeUpdates(
    $id: ID!
    $batchId: ID
    $odeId: ID
  ) {
      odeUpdates(where: {
        batch: { id: $batchId }
        ode: {
          id: $odeId
          team_some: {
            id: $id
            systemRole: ENTREPRENEUR
          }
        }
      }) {
        id
        period
        comments {
          id
        }
      }
  }
`;
