/**
 * @param {Entrepreneur} data
 * @returns {Object}
 */
export function getUncompletedEntrepreneurProfileData(data = {}) {
  const { expertProfile } = data || {};
  const { location } = expertProfile || {};

  return {
    personalInfo: !checkFields([
      data.fullName,
      data.firstName,
      data.middleName,
      data.lastName,
      data.email,
      data.gender,
      data.birthday,
      data.phone,
      location?.city,
      location?.country,
      location?.state
    ]),
    tecRelationship: !checkFields([
      data.hasTecRelationship?.toString() || null
    ]),
    occupation: !checkFields([
      data.specialtyArea,
      data.occupation
    ])
  };
}

/**
 * @param {*[]} fields
 * @returns {boolean}
 */
function checkFields(fields = []) {
  return fields.every(field => field);
}

/**
 * @typedef Entrepreneur
 * @property {string} fullName
 * @property {string} firstName
 * @property {string} middleName
 * @property {string} lastName
 * @property {string} email
 * @property {string} specialtyArea
 * @property {string} occupation
 * @property {string} companies
 * @property {string} gender
 * @property {string} birthday
 * @property {string} phone
 * @property {boolean} hasTecRelationship
 * @property {EntrepreneurRelationship[]} tecRelationships
 * @property {ExpertProfile} expertProfile
 */

/**
 * @typedef EntrepreneurRelationship
 * @field {string} id
 */

/**
 * @typedef ExpertProfile
 * @property {Location} location
 */

/**
 * @typedef Location
  * @property {string} city
  * @property {string} country
  * @property {string} state
 */
