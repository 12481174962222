/* eslint-disable max-len */
/* eslint-disable max-lines */

// It was campus.
// We renamed it because EOS is trying to be open to other Organizations, not Tec de Monterrey only.
export const EDUCATION_INSTITUTIONS = [
  {
    "value": "Madata Accelerator",
    "label": "Madata Accelerator",
    "group": "N/A",
    "legacyValues": ["MADATA"]
  },
  {
    value: "Campus Ciudad Juárez",
    label: "Campus Ciudad Juárez",
    group: "Desarrollo Regional",
    legacyValues: ["JUA"]
  },
  {
    value: "Universidad Técnica Particular de Loja",
    label: "Universidad Técnica Particular de Loja",
    group: "N/A",
    legacyValues:["UTPDL"]
  },
  {
    value: "Institute for the Future of Education",
    label: "Institute for the Future of Education",
    group: "N/A",
    legacyValues: ["TM_IFE"]
  },
  {
    value: "Oficina de Transferencia de Tecnología",
    label: "Oficina de Transferencia de Tecnología",
    region: "N/A",
    legacyValues: ["TM_OTT"]
  },
  {
    "value": "Campus Aguascalientes",
    "label": "Campus Aguascalientes",
    "group": "Región Occidente",
    "legacyValues": ["AGU"]
  },
  {
    "value": "Campus Chihuahua",
    "label": "Campus Chihuahua",
    group: "Región Occidente",
    legacyValues: ["CHH", "Chihuahua"]
  },
  {
    "value": "Campus Ciudad Obregón",
    "label": "Campus Ciudad Obregón",
    group: "Desarrollo Regional",
    legacyValues: ["SON"]
  },
  {
    "value": "Campus Guadalajara",
    "label": "Campus Guadalajara",
    group: "Región Occidente",
    legacyValues: ["JAL"]
  },
  {
    "value": "Campus Laguna",
    "label": "Campus Laguna",
    group: "Región Monterrey",
    legacyValues: ["LAG", "LAGUNA"]
  },
  {
    "value": "Campus Morelia",
    "label": "Campus Morelia",
    group: "Región Occidente",
    legacyValues: ["MIC"]
  },
  {
    "value": "Campus Saltillo",
    "label": "Campus Saltillo",
    group: "Región Monterrey",
    legacyValues: ["COAH"]
  },
  {
    "value": "Campus Sinaloa",
    "label": "Campus Sinaloa",
    group: "Región Occidente",
    legacyValues: ["SIN"]
  },
  {
    "value": "Campus Toluca",
    "label": "Campus Toluca",
    group: "Región Centro/Sur",
    legacyValues: ["TOL"]
  },
  {
    "value": "Campus Central de Veracruz",
    "label": "Campus Central de Veracruz",
    "group": "Desarrollo Regional",
    "legacyValues": ["VER"]
  },
  {
    "value": "Campus Ciudad de México",
    "label": "Campus Ciudad de México",
    group: "Región Ciudad de México",
    legacyValues: ["CDMX", "CMX"]
  },
  {
    "value": "Campus Cuernavaca",
    "label": "Campus Cuernavaca",
    group: "Región Centro/Sur",
    legacyValues: ["MOR"]
  },
  {
    "value": "Campus Hidalgo",
    "label": "Campus Hidalgo",
    group: "Región Centro/Sur",
    legacyValues: ["HID"]
  },
  {
    "value": "Campus León",
    "label": "Campus León",
    group: "Región Occidente",
    legacyValues: ["GUA"]
  },
  {
    "value": "Campus Puebla",
    "label": "Campus Puebla",
    group: "Región Centro/Sur",
    legacyValues: ["PUE"]
  },
  {
    "value": "Campus San Luis Potosí",
    "label": "Campus San Luis Potosí",
    group: "Región Centro/Sur",
    legacyValues: ["SLP"]
  },
  {
    "value": "Campus Sonora norte",
    "label": "Campus Sonora norte",
    group: "Región Occidente",
    legacyValues: ["SNT"]
  },
  {
    "value": "Campus Zacatecas",
    "label": "Campus Zacatecas",
    group: "Desarrollo Regional",
    legacyValues: ["ZAC"]
  },
  {
    "value": "Campus Chiapas",
    "label": "Campus Chiapas",
    "group": "Desarrollo Regional",
    "legacyValues": ["CHP"]
  },
  {
    "value": "Campus Estado de México",
    "label": "Campus Estado de México",
    group: "Región Ciudad de México",
    legacyValues: ["MEX"]
  },
  {
    "value": "Campus Irapuato",
    "label": "Campus Irapuato",
    group: "Desarrollo Regional",
    legacyValues: ["IRA"]
  },
  {
    "value": "Campus Monterrey",
    "label": "Campus Monterrey",
    group: "Región Monterrey",
    legacyValues: ["NLE", "TEC_MONT", "Monterrey"]
  },
  {
    "value": "Campus Querétaro",
    "label": "Campus Querétaro",
    group: "Región Centro/Sur",
    legacyValues: ["QUE"]
  },
  {
    "value": "Campus Santa fe",
    "label": "Campus Santa fe",
    group: "Región Ciudad de México",
    legacyValues: ["STF"]
  },
  {
    "value": "Campus Tampico",
    "label": "Campus Tampico",
    group: "Región Centro/Sur",
    legacyValues: ["TAM"]
  }
];
