/**
 * @param {string} limitDate
 */
export function assignationHasExpired(limitDate) {
  const limitDateSeconds = new Date(limitDate).getTime();

  const today = new Date();
  today.setHours(0,0,0,0);

  return limitDateSeconds < today.getTime();
}