import React, { useContext } from "react";
import { List, Space } from "antd";
import { withRouter, cardGridSizes, paginationConfig } from "../../../helpers";
import { OdeItem } from "../ode-item";
import {
  CreateOdeModalContext,
  PaginationContext,
  ImportOdeModalContext
} from "../../../contexts/shared";
import { AddButton, RoleGuard } from "../../../components/shared";

function OdesList({
  data,
  isLoading,
  onChangePage,
  currentPage
}) {
  const { openModal } = useContext(CreateOdeModalContext);
  const { openModalImport } = useContext(ImportOdeModalContext);
  const { onPaginationChange } = useContext(PaginationContext);

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange("odes")(page, pageSize);
  };

  return(
    <>
      <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR", "ENTREPRENEUR"]}>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <AddButton
            onClick={() => openModal()}
            className="wide-input">
            Agregar nueva compañía
          </AddButton>
          <AddButton
            onClick={() => openModalImport()}
            className="wide-input">
            Importar compañía
          </AddButton>
        </Space>
      </RoleGuard>
      <List
        grid={cardGridSizes}
        style={{ marginTop: "24px" }}
        pagination={{
          ...paginationConfig,
          current: currentPage,
          defaultPageSize: 12,
          onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
          total: data?.meta?.aggregate?.count || 0
        }}
        loading={isLoading}
        dataSource={data?.oDEs}
        renderItem={ode =>
          <List.Item>
            <OdeItem ode={ode} />
          </List.Item>
        } />
    </>
  );
}

export default withRouter(OdesList);
