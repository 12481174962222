import React from "react";
import { Typography, Tag } from "antd";
import { MailOutlined } from "@ant-design/icons";

export const DetailsInfo = ({ perk = {} }) => {
  const { Text, Title } = Typography;
  return (
    <div>
      <div style={{
        backgroundImage: `url(${perk.coverPicUrl})`,
        backgroundSize: "cover",
        height: "200px"
      }}></div>
      <Title level={3} style={{marginTop: "20px"}}>{perk.name}</Title>
      <Tag>{perk.category}</Tag>
      <br /><br />
      <Text>{perk.description}</Text>
      <br /><br />
      <Text>Contacto: </Text>
      <br />
      <MailOutlined /> <Text>{perk.contactInfo}</Text>
    </div>
  );
};

