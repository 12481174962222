import React from "react";
import { Tag, Space } from "antd";
import { Avatar } from "../avatar/index";

export function OdeTag({ odeName = "N/A" }) {
  return (
    <Tag
      style={{padding: "3px"}}
      color="default">
      <Space align="baseline">
        <Avatar size={18}>{odeName}</Avatar>
        {odeName}
      </Space>
    </Tag>
  );
}
