import gql from "graphql-tag";

export const deleteObjectiveContent = gql`
  mutation DeleteObjectiveContent(
    $objectiveId: ID!,
    $contentId: ID!
  ) {
    updateObjective(
      where:{ id: $objectiveId }
      data: {
        contents: {
          delete: {
            id: $contentId
          }
        }
      }
    ) {
      id
    }
  }
`;
