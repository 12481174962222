import React, { useState } from "react";
import { Row, Button, Typography, Form } from "antd";
import uuid from "uuid/v4";
import { Visibility } from "../../visibility";
import { CompanyCompetenceForm } from "./competence";
import { CompanyFundForm } from "./fund";
import { CreateCompanyMilestonesList } from "./milestones-list";

const initialState = {
  addFundForm: false,
  addCompetenceForm: false,
  isEditing: false
};

export const CompanyMilestonesForm = ({
  milestones,
  setNewMilestone,
  deleteMilestone
}) => {
  const [state, setState] = useState(initialState);
  const [isSocial, setIsSocial] = useState(true);
  const [nonePlace, setNonePlace] = useState(false);

  const [form] = Form.useForm();
  const { Text } = Typography;

  const onIsSocialChange = value => {
    setIsSocial(value);
  };

  const onPlaceChange = value => {
    setNonePlace(value === "NONE");
  };

  const onAddMilestone = name => {
    if (name === "addCompetenceForm") {
      form.setFieldsValue({ socialCompetence: true });
      setIsSocial(true);
      setNonePlace(false);
    }
    setState(state => ({
      ...state,
      [name]: true
    }));
  };

  const onCancel = () => {
    setState(initialState);
    form.resetFields();
  };

  const onSave = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    values.milestoneType = state.addFundForm ? "FUND" : "COMPETENCE";
    if (state.isEditing) {
      deleteMilestone(state.milestoneId);
    }
    values.id = uuid();
    setNewMilestone(values);
    setState(initialState);
    setIsSocial(true);
    form.resetFields();
  };

  const onEdit = (values, type) => {
    setState(prev => ({
      ...prev,
      [type]: true,
      isEditing: true,
      milestoneId: values.id
    }));
    setIsSocial(values?.socialCompetence);
    setNonePlace(values?.place === "NONE");
    form.setFieldsValue(values);
  };

  const onDelete = id => {
    deleteMilestone(id);
  };

  return (
    <div>
      <Visibility visible={!state.isEditing}>
        <CreateCompanyMilestonesList
          milestones={milestones}
          onEdit={onEdit}
          onDelete={onDelete} />
        <div style={{ height: "16px" }} />
      </Visibility>
      <Visibility visible={state.addFundForm}>
        <CompanyFundForm form={form} />
      </Visibility>
      <Visibility visible={state.addCompetenceForm}>
        <CompanyCompetenceForm
          form={form}
          isSocial={isSocial}
          nonePlace={nonePlace}
          onPlaceChange={onPlaceChange}
          onIsSocialChange={onIsSocialChange} />
      </Visibility>
      <div
        style={{ marginLeft: "16px" }}>
        <Text>Agrega un hito</Text>
        <Row
          style={{ paddingTop: "10px" }}>
          <Visibility visible={!(state.addFundForm || state.addCompetenceForm)}>
            <Button
              className="secundary-btn"
              onClick={() => onAddMilestone("addFundForm")}>Agregar fondo</Button>
            <Button
              className="secundary-btn"
              onClick={() => onAddMilestone("addCompetenceForm")}
              style={{ marginLeft: "16px" }}>Agregar competencia</Button>
          </Visibility>
          <Visibility visible={state.addCompetenceForm || state.addFundForm}>
            <Button
              type="primary"
              onClick={onSave}>
              Guardar
            </Button>
            <Button
              className="secundary-btn"
              onClick={onCancel}
              style={{ marginLeft: "16px" }}>
              Cancelar
            </Button>
          </Visibility>
        </Row>
      </div>
    </div>
  );
};
