import { counter } from "./counter";
import { SUPPORTED_DIMENSIONS } from "./supported-dimensions";

/**
 * This is a function that will perform a query against the CubeJS platform
 * to get the Companies measurement. It receives a set of parameters that will
 * be converted to filters.
 */
export const cubeMeasureCounter = ({
  by = [],
  filters = []
} = {}) => {
  const dimensions = by.map(dimension => SUPPORTED_DIMENSIONS[dimension]);
  const params = { filters, dimensions };

  return {
    countCompanies: () => counter(["Company.companiesCount"], params),
    entrepreneursCount: () => counter(["Company.entrepreneursCount"], params)
  };
};
