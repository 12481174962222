import React from "react";
import { OnboardingLayout } from "../../../../components/shared";
import {
  WelcomeSection
} from "../../../../components/entrepreneur/invitation/welcome";
import {
  StepsNavigation
} from "../../../../components/entrepreneur/invitation/steps-navigation";

export const WelcomeIndex = () => (
  <OnboardingLayout>
    <StepsNavigation current={2} />
    <WelcomeSection />
  </OnboardingLayout>
);
