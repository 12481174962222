import { useEffect, useState } from "react";

/**
 * Returns info about the resize and size events and values
 * @returns
 */

export const useWindowSize = () => {
  const [state, setState] = useState({
    windowWidth: 0,
    windowHeight: 0,
    windowSize: getWindowSize(window.innerWidth || 0)
  });

  useEffect(() => {
    function updateDimensions() {
      const windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
      const windowHeight = typeof window !== "undefined"
        ? window.innerHeight
        : 0;

      const windowSize = getWindowSize(windowWidth || 0);

      setState({
        windowHeight,
        windowWidth,
        windowSize
      });
    }

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function getWindowSize(windowWidth) {
    if (windowWidth < 576)
      return "EXTRA_SMALL";
    if (windowWidth < 768)
      return "SMALL";
    if (windowWidth < 992)
      return "MEDIUM";
    if (windowWidth < 1200)
      return "LARGE";
    if (windowWidth < 1600)
      return "EXTRA_LARGE";
    else
      return "EXTRA_EXTRA_LARGE";
  }

  return { ...state };
};