import React, { useContext, useEffect } from "react";
import { Form } from "antd";
import { FormContainer } from "../../../";
import {
  EditProfileContext
} from "../../../../../contexts/shared";
import { EducationInput } from "./education-input";
import { EducationItem } from "./education-input/item";

export function EducationForm({ editable = false, form, setStateForm }) {
  const { data, editing } = useContext(EditProfileContext);

  useEffect(() => {
    const { education = [] } = data?.user?.expertProfile?.professionalExperience ?? {};
    if (education.length) {
      form.setFieldsValue({ education });
    }
  }, [data]);

  if (!editing.education && !editable) {
    return <EducationItem user={data?.user} />;
  }

  return (
    <FormContainer>
      <Form form={form}>
        <Form.Item
          name="education">
          <EducationInput
            setStateForm={setStateForm} />
        </Form.Item>
      </Form>
    </FormContainer>
  );
}
