import gql from "graphql-tag";

export const getOdeNameById = gql`
  query GetOdeNameById($id: ID!) {
    oDE(where: { id: $id }) {
      id
      name
    }
  }
`;
