import React from "react";
import { Format } from "../format";
import { Avatar } from "../avatar";
import ColorHash from "color-hash";
import { Space } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import h2p from "html2plaintext";

const colorHash = new ColorHash();

const subs = text => text
  .split(" ")
  .slice(0, 2)
  .map(s => s[0])
  .join("")
  .toUpperCase();

export const columns = [
  {
    title: "Asunto",
    dataIndex: "title",
    key: "title",
    render: text => <p>{text}</p>,
    sorter: (a, b) => a.title.length - b.title.length
  },
  {
    title: "Contenido",
    dataIndex: "body",
    key: "body",
    render: text =>
      <p>{h2p(text || "").trim().split("\n")?.[0].slice(0,30).concat("...")}</p>,
    sorter: (a, b) => a.body.length - b.body.length
  },
  {
    title: "",
    dataIndex: "documents",
    key: "documents",
    render: text =>
      text.url || <PaperClipOutlined style={{ fontSize: "1.5rem" }} />,
    sorter: (a, b) => a.documents.length - b.documents.length
  },
  {
    title: "Fecha",
    dataIndex: "date",
    key: "date",
    render: date => <Format type="date">{date}</Format>,
    sorter: (a, b) => a.date < b.date
  },
  {
    title: "De",
    dataIndex: "createdBy",
    key: "createdBy",
    render: text => <Space>
      <Avatar
        key={text.fullName}
        style={
          !text.profilePicUrl
          && { backgroundColor: colorHash.hex(text.fullName) }
        }
        src={text.profilePicUrl}>
        {subs(text.fullName)}
      </Avatar>
      <p>{text.fullName}</p>
    </Space>,
    sorter: (a, b) =>
      a.createdBy?.fullName.length - b.createdBy?.fullName.length
  }
];
