import React from "react";
import { Form, DatePicker, Row, Col, InputNumber, Space } from "antd";
import moment from "moment";
import { genericFieldValidationMsgs } from "../../../../../utils";
import { Visibility } from "../../../../../components/shared";

export function ScheduleExpertServiceForm({ form, assignedService, hoursPerDay }) {
  const type = assignedService?.expertServiceData?.type;
  const hasHours = type === "MENTORSHIP";
  const dateLabel = type === "MENTORSHIP" ? "propuesta" : "de consumo";
  const maxHours = Math.min(parseInt(assignedService?.availableUnits), 5);

  const disabledDate = current => {
    const limitDate = moment(assignedService.object.limitDate);
    const assignmentDate = moment(assignedService.object.assignmentDate);

    limitDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    assignmentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    limitDate.add(1, "days"); // workaround

    return moment(current).isBefore(assignmentDate)
      || current.isAfter(limitDate);
  };

  const hoursPerDayValidator = (_, value) => {
    if (value === 5) {
      return Promise.reject(new Error());
    } else {
      if(!hoursPerDay || hoursPerDay === 0) {
        return Promise.resolve();
      }
      if(value > hoursPerDay) {
        return Promise.reject(new Error());
      } else {
        return Promise.resolve();
      }
    }
  };

  const transformToCompleteHours = value => parseInt(value);

  return (
    <Form
      form={form}
      validateMessages={genericFieldValidationMsgs}
      layout="vertical">
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="serviceDate"
            rules={[{ required: true }]}
            label={`Fecha ${dateLabel}`}>
            <DatePicker
              disabledDate={disabledDate}
              placeholder="DD-MM-YYYY"
              format="LL"
              className="wide-input" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="serviceHour"
            rules={[{ required: true }]}
            label={`Hora ${dateLabel}`}>
            <DatePicker
              picker="time"
              className="wide-input" />
          </Form.Item>
        </Col>
        <Visibility visible={hasHours}>
          <Col span={6}>
            <Space direction="vertical">
              <Form.Item
                initialValue={1}
                getValueFromEvent={transformToCompleteHours}
                name="duration"
                rules={[{
                  required: true,
                  message : hoursPerDay
                    ? `Solo tienes ${hoursPerDay} horas disponibles por sesión`
                    : "No puedes agendar más de 5 horas seguidas.",
                  validator: hoursPerDayValidator
                }]}
                style={{ marginBottom: 0 }}
                label="Solicitar">
                <InputNumber
                  min={1}
                  max={maxHours}
                  step="1"
                  suffix="Horas"
                  style={{ width: "100%" }}
                  type="number" />
              </Form.Item>
            </Space>
          </Col>
        </Visibility>
      </Row>
    </Form>
  );
}
