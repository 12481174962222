import gql from "graphql-tag";

export const markAchievementAsCompleted = gql`
  mutation MarkAchievementAsCompleted(
    $date: DateTime
    $achievementId: ID
    $odeId: ID
  ) {
    createOdeAchievement(
      data: {
        ode: {connect: {id:$odeId}}
        achievement: {connect: {id: $achievementId}}
        isComplete: true
        finishDate: $date
      }
    ){
      id
      ode{
        id
      }
      achievement{
        id
      }
      isComplete
      finishDate
    }
  }
`;