import gql from "graphql-tag";

export const createScheduledExpertService = gql`
  mutation CreateScheduledExpertService(
    $data: ScheduledExpertServiceCreateInput!
  ) {
    createScheduledExpertService(data: $data) {
      id
    }
  }
`;
