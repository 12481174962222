export const placeOptions = [
  { label: "Primero", value: "FIRST" },
  { label: "Segundo", value: "SECOND" },
  { label: "Tercero", value: "THIRD" },
  { label: "Niguno", value: "NONE" }
];

export const placeOptionsDetails = [
  { label: "Primer Lugar", value: "FIRST" },
  { label: "Segundo Lugar", value: "SECOND" },
  { label: "Tercer Lugar", value: "THIRD" },
  { label: "Nigún Lugar", value: "NONE" }
];