/* eslint-disable max-lines */
import React, { useState } from "react";
import { CAMPUS_LIST } from "../../../campus-list";
import { Col, Row, Select, Form, DatePicker } from "antd";

export const ResumeByCampusFilterControls = ({
  onFilterAttendances
}) => {
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [disableFields, setDisableFields] = useState(true);
  const [lockPeriod, setLockPeriod] = useState(true);
  const [audiences, setAudiences] = useState([]);
  const [dates, setDates] = useState({
    startDate: null,
    endDate:null
  });

  const [form] = Form.useForm();

  const onCampusChange = campus => {
    form.setFieldsValue({
      year: undefined,
      period: undefined,
      audience: undefined
    });
    setSelectedCampus(campus);
    setDisableFields(!campus);
    setLockPeriod(true);
    onFilterAttendances && onFilterAttendances({ campus });
    handleOnFilterAttendances({ selectedCampus: campus });
    return campus;
  };

  const handleOnFilterAttendances = ({
    startDate,
    endDate,
    selectedCampus,
    audienceTypes
  }) => {
    setAudiences(audienceTypes);
    setDates({endDate, startDate});
    onFilterAttendances && onFilterAttendances({
      startDate,
      endDate,
      selectedCampus,
      audienceTypes
    });
  };

  const onYearChange = year => {
    form.setFieldsValue({ period: undefined, audience: undefined });
    const selectedYear = new Date(year).getFullYear() || 0;

    if (!year || selectedYear === 1969) {
      setLockPeriod(true);
      handleOnFilterAttendances({
        selectedCampus,
        startDate: null,
        endDate: null
      });
      return year;
    }

    handleOnFilterAttendances({
      selectedCampus,
      audienceTypes: audiences,
      startDate: new Date(selectedYear, 0, 1, 0, 0, 0, 0).toISOString(),
      endDate: new Date(selectedYear, 11, 31, 23, 59, 59, 999).toISOString()
    });

    if (selectedYear) {
      setLockPeriod(false);
    } else {
      setLockPeriod(true);
    }

    return year;
  };

  const onPeriodChange = period => {
    const selectedYear =
      new Date(form.getFieldValue("year")).getFullYear();

    if (!period) {
      handleOnFilterAttendances({
        selectedCampus,
        audienceType: audiences,
        startDate: new Date(selectedYear, 0, 1, 0, 0, 0, 0).toISOString(),
        endDate: new Date(selectedYear, 11, 31, 23, 59, 59, 999).toISOString()
      });
    }

    if (period === 1) {
      handleOnFilterAttendances({
        selectedCampus,
        audienceType: audiences,
        startDate: new Date(selectedYear, 0, 1, 0, 0, 0, 0).toISOString(),
        endDate: new Date(selectedYear, 5, 31, 23, 59, 59, 999).toISOString()
      });
    }

    if (period === 2) {
      handleOnFilterAttendances({
        selectedCampus,
        audienceType: audiences,
        startDate: new Date(selectedYear, 6, 1, 0, 0, 0, 0).toISOString(),
        endDate: new Date(selectedYear, 11, 31, 23, 59, 59, 999).toISOString()
      });
    }

    return period;
  };

  const onAudienceChange = audiences => {
    if (!audiences.length) {
      handleOnFilterAttendances({
        selectedCampus,
        startDate: dates.startDate || null,
        endDate: dates.endDate || null
      });
      return audiences;
    }
    handleOnFilterAttendances({
      selectedCampus,
      audienceTypes: audiences,
      endDate: dates.endDate || null,
      startDate: dates.startDate || null
    });

    return audiences;
  };

  return(
    <Form
      form={form}
      layout="vertical"
      style={{ width: "100%" }}>
      <Row gutter={16}>
        <Col span={6}>
          <Form.Item
            name="campus"
            label="Campus / institución"
            getValueFromEvent={onCampusChange}>
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="Seleccionar"
              options={CAMPUS_LIST.map(c =>
                ({ value: c.slice(7), label: c })
              )} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="year"
            getValueFromEvent={val => onYearChange(val)}
            label="Años">
            <DatePicker
              picker="year"
              disabled={disableFields}
              style={{ width: "100%" }}
              placeholder="Seleccionar año" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="period"
            label="Periodo"
            getValueFromEvent={onPeriodChange}>
            <Select
              allowClear
              disabled={lockPeriod}
              style={{ width: "100%" }}
              placeholder="Periodo"
              options={[
                { value: 1, label: "1er semestre" },
                { value: 2, label: "2do semestre" }
              ]} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="audiences"
            getValueFromEvent={onAudienceChange}
            rules={[{type: "array"}]}
            label="Tipo de audiencia">
            <Select
              allowClear
              mode="multiple"
              disabled={disableFields}
              style={{ width: "100%" }}
              placeholder="Audiencia">
              <Select.Option value= "Preparatoria">Preparatoria</Select.Option>
              <Select.Option value= "Profesional">Profesional</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
