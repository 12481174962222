import React from "react";
import { Form, Select } from "antd";
import { GridContainer } from "../grid-container";
import {
  contentTypes
} from "../../../helpers/select-options/content";

export const Filters = ({ batchCompanies, contentColumns, onChange }) => {
  const companiesList = (batchCompanies || []).map(
    company => ({ label: company.name, value: company.id })
  );

  const sectionsList = (contentColumns || []).map(
    section => ({ label: section.sectionName, value: section.sectionId })
  );

  return (
    <Form layout="vertical">
      <GridContainer responsiveValues={{
        xs: 24,
        sm: 24,
        md: 8
      }}>
        <Form.Item name="company" label="Compañía">
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            placeholder="Seleccionar"
            mode="multiple"
            options={companiesList}
            onChange={value => onChange({ selectedCompanies: value })} />
        </Form.Item>
        <Form.Item name="section" label="Sección">
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            placeholder="Seleccionar"
            mode="multiple"
            options={sectionsList}
            onChange={value => onChange({ selectedSections: value })} />
        </Form.Item>
        <Form.Item name="contentType" label="Tipo de contenido">
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            placeholder="Seleccionar"
            options={contentTypes}
            onChange={value => onChange({ selectedContentType: value })} />
        </Form.Item>
      </GridContainer>
    </Form>
  );
};
