import React, { useContext } from "react";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";
import { AdminExpertsContext } from "../../../contexts/admin/experts";
import {
  SidebarLayout,
  ContentWrapper,
  FormContainer
} from "../../../components/shared";
import {
  SummaryEditSection
} from "../../../components/shared/profile-forms/summary";
import { Profile } from "../../../components/admin/experts/show";
import { useQuery } from "@apollo/react-hooks";
import { MenuContext } from "../../../contexts/shared/menu";
import { EditProfileProvider } from "../../../contexts/shared/edit-profile";
import { PageLeavePromptProvider } from "../../../contexts/shared";

export default function ProfileIndex() {
  const userId = window.localStorage.getItem("id");
  const { loading, error, data = {} } = useQuery(
    shared.queries.getExpertById,
    {
      client,
      variables: { id: window.localStorage.getItem("id") },
      fetchPolicy: "network-only"
    }
  );

  const { setMenuState } = useContext(MenuContext);

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/profile"]
  });

  return (
    <PageLeavePromptProvider>
      <AdminExpertsContext.Provider value={{ data }}>
        <EditProfileProvider userId={userId} data={data}>
          <SidebarLayout>
            <SummaryEditSection
              editable
              data={data}
              error={error}
              isLoading={loading} />
            <ContentWrapper style={{ paddingTop: "0rem" }}>
              <FormContainer>
                <Profile
                  editable
                  data={data}
                  error={error}
                  isLoading={loading} />
              </FormContainer>
            </ContentWrapper>
          </SidebarLayout>
        </EditProfileProvider>
      </AdminExpertsContext.Provider>
    </PageLeavePromptProvider>
  );
}
