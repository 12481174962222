import React from "react";
import { Card, Space, Typography, Col, Row, Tag, message } from "antd";
import { Format } from "../format";
import { CoverImage } from "../cover-image";
import { expertServiceTypes } from "../../../helpers";
import {
  getReadableValue
} from "../../../helpers";
import { Visibility } from "../visibility";
import { ActionButton, RoleGuard } from "../index";
import { useMutation } from "@apollo/react-hooks";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";

export function ExpertServiceCard({
  onDisableExpertService,
  showActions = true,
  service,
  onEdit,
  extras,
  refetchQueriesVars
}) {
  const expertName = service?.expert?.fullName || "N/A";
  const canDelete = service?.assignedExpertServices?.length === 0;

  const [deleteExpertServiceById] = useMutation(
    shared.mutations.deleteExpertServiceById,
    {
      client
    }
  );

  /**
   * @todo Move method to the provider
   */
  const onDeleteExpertService = async () => {
    const dismissLoader = message.loading("Eliminando...");
    try {
      await deleteExpertServiceById({
        variables: { id: service.id },
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: shared.queries.getServices
        },
        {
          query: shared.queries.getServicesByExpertId,
          variables: {
            expertId: service.expert.id,
            ...(refetchQueriesVars ?? {})
          }
        }]
      });
      message.success("Servicio eliminado");
    }
    catch (e) {
      console.error(e);
      message.error("Ha ocurrido un error");
    }
    finally {
      dismissLoader();
    }
  };

  return (
    <Card
      cover={
        <CoverImage
          text={service?.name}
          url={service?.coverPicUrl} />
      }>
      <Card.Meta
        style={{ display: "block" }}
        title={service?.name}
        description={
          <Space
            direction="vertical"
            style={{ width: "100%" }}>
            <Typography.Text>{expertName}</Typography.Text>
            <Format type="secondary">
              <Tag color="purple">
                {getReadableValue(expertServiceTypes, service?.type)}
              </Tag>
            </Format>
            <Row
              style={{ paddingTop: "20px" }}
              justify="space-between"
              align="bottom">
              <Col span={12}>
                {extras}
              </Col>
              <Col span={12}>
                <Visibility visible={showActions}>
                  <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR"]}>
                    <ActionButton
                      needStopPropagation={false}
                      data-cy={"action-button"}
                      onEdit={onEdit}
                      onDisable={onDisableExpertService}
                      onDelete={canDelete && onDeleteExpertService}
                      style={{ float: "right" }} />
                  </RoleGuard>
                  <RoleGuard roles={["ADVISER"]}>
                    <ActionButton
                      needStopPropagation={false}
                      data-cy={"action-button"}
                      extra={[
                        {
                          label: "Editar",
                          action: () => onEdit()
                        }
                      ]}
                      style={{ float: "right" }} />
                  </RoleGuard>
                </Visibility>
              </Col>
            </Row>
          </Space>
        } />
    </Card>
  );
}
