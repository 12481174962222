import React, { useState, useEffect } from "react";
import { Form, Select, Col, Row, message, Button, Radio } from "antd";
import { Visibility } from "../../../../shared";
import { withLoader } from "../../../../../helpers";
import { TecRelationshipInput } from "../../../../shared/profile-forms/tec-relationship/form/tec-relationship-input";
import { formatValues } from "../../../../shared/profile-forms/tec-relationship/section/format-values";

function ExperienceForm({ data, onChange }) {
  const [state, setState] = useState({ hasRelationship: undefined, data: undefined });
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      try {
        if (data?.user?.expertProfile?.professionalExperience) {
          const {
            industries,
            skills
          } = data.user.expertProfile.professionalExperience;

          data.user.skills = skills;
          data.user.industries = industries;
        }

        const hasTecRelationship = data.user.hasTecRelationship === 1 ? true
          : data.user.hasTecRelationship === 0 ? false : undefined;

        form.setFieldsValue(data?.user);
        setState({ ...state, data: data, hasRelationship: hasTecRelationship });
      }
      catch (e) {
        message.error(
          <>
            Ha ocurrido un error al cargar los datos. <Button type="link" onClick={() => window.location.reload()}>Reintentar</Button>.
          </>
          , 0);
      }
    }
  }, [data]);

  const onFormChange = async () => {
    try {
      const values = await form.getFieldsValue();

      /** TODO: Research better way to generate prisma gql data structures */

      const expertProfileAction = (data?.expertProfile) ? "update" : "create";
      const professionalExperienceAction = (data?.expertProfile?.professionalExperience) ? "update" : "create";

      values.expertProfile = {};
      values.expertProfile[expertProfileAction] = { professionalExperience: {} };
      values.expertProfile[expertProfileAction].professionalExperience[professionalExperienceAction] = {};


      values.expertProfile[expertProfileAction].professionalExperience[professionalExperienceAction].industries =
        { set: values.industries };
      values.expertProfile[expertProfileAction].professionalExperience[professionalExperienceAction].skills =
        { set: values.skills };

      delete values.skills;
      delete values.industries;

      const oldTecRelationships = data?.user?.tecRelationships || [];

      const tecInfo = formatValues(values.tecRelationships, oldTecRelationships);

      onChange && onChange({
        ...values,
        ...tecInfo,
        hasTecRelationship: values.hasTecRelationship
      });
    }
    catch (e) {
      console.error(e);
    }
  };

  const onSwitchChange = ({ target: { value } }) => {
    setState({ hasRelationship: value === 1 ? true : false });
  };

  return (
    <Form
      form={form}
      onChange={onFormChange}
      layout="vertical">
      <Form.Item
        label="Habilidades"
        name="skills"
        required>
        <Select
          mode="tags"
          tokenSeparators={[","]}
          notFoundContent={null}
          dropdownStyle={{ display: "none" }} />
      </Form.Item>
      <Form.Item
        label="Industrias"
        name="industries"
        required>
        <Select
          mode="tags"
          tokenSeparators={[","]}
          notFoundContent={null}
          dropdownStyle={{ display: "none" }} />
      </Form.Item>
      <Form.Item
        getValueFromEvent={e => Number(e.target.value || 0)}
        name="hasTecRelationship"
        required>
        <Row>
          <Col span={12}>Vínculo con el TEC DE MONTERREY</Col>
          <Col
            style={{ textAlign: "right" }}
            span={12}>
            <Radio.Group
              value={state.hasRelationship ? 1 : 0}
              onChange={onSwitchChange}>
              <Radio value={1}>Si</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          </Col>
        </Row>
      </Form.Item>
      <Visibility visible={state.hasRelationship}>
        <Form.Item
          name="tecRelationships">
          <TecRelationshipInput onChange={onFormChange} />
        </Form.Item>
      </Visibility>
    </Form>
  );
}

export default withLoader(ExperienceForm);
