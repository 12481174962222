/**
 * @param {rawData[]} attendanceRows
 * @return {attendanceInput[]}
 */

export const formatAttendancesForImportation = (attendanceRows = []) => {
  return attendanceRows.map(raw => ({
    npsScore: parseInt(raw.NPS),
    npsScoreReason: raw["¿Por qué?"],
    personExperienceAudience: raw.Audiencia,
    enrollmentSchoolControlNumber: raw.Matricula,
    payroll: raw["Nómina"] ? String(raw["Nómina"]) : undefined,
    name: raw["Experiencia asistida"]
  }));
};

/**
 * @typedef attendanceInput
 * @property npsScore: number
 * @property npsScoreReason: string
 * @property personExperienceAudience: string
 * @property enrollmentSchoolControlNumber: string
 * @property payroll: string
 * @property name: string
 */

/**
 * @typedef rawData
 * @property {string} Matricula
 * @property {string} Nómina
 * @property {string} Nombres
 * @property {string} Apellido Paterno
 * @property {string} Apellido Materno
 * @property {string} Audiencia
 * @property {string} Correo electrónico
 * @property {string} Campus/institución
 * @property {string} NPS
 * @property {string} ¿Por qué?
 * @property {string} Experiencia asistida
 * @property {string} Cruce nivel de involucramiento
 * @property {string} Cruce campus
 */
