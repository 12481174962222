import React from "react";
import { Space, Typography } from "antd";
import { Format } from "../../..";

export function CurrencyLabel({ children , prefix = ""}) {
  return (
    <Space style={{ justifyContent: "space-between", width: "100%" }}>
      <Typography.Text
        type="secondary"
        style={{ fontSize: "1.5rem" }}>
        {prefix}
      </Typography.Text>
      <Typography.Text
        type="secondary"
        style={{ fontSize: "1.5rem" }}>
        <Format type="money">{children}</Format>
      </Typography.Text>
    </Space>
  );
}
