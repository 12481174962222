import gql from "graphql-tag";

export const disconnectCoordinatorFromBatch = gql`
  mutation DisconnectCoordinatorFromBatch($userId: ID!, $batchId: ID!){
    updateUser(
      where: { id: $userId }
      data: {
        batches: { disconnect: {id: $batchId } }
      }
    ){
      id
    }
  }
`;
