export const validationMessages = {
  name: {
    required: true,
    message: "Por favor ingresar Nombre"
  },
  programId: {
    required: true,
    message: "Por favor seleccione un Programa"
  },
  institution: {
    required: true,
    message: "Por favor seleccione una institución"
  },
  description: {
    required: true,
    message: "Por favor ingresar Descripción"
  },
  dates: {
    required: true,
    message: "Por favor seleccione una fecha de inicio y de fin"
  }
};