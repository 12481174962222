import React from "react";
import { STATUS_ICONS } from "./status-circles";
import "./styles.sass";

export const buildContentColumns = (contentSections = [], type, companyId) => {
  const collectedContents = [];
  const contentColumns = [];

  contentSections.forEach(section => {
    // Build the columns with the batch contents
    const sectionContentsColumns = section
      .contents
      .filter(content => {
        if (!type) return true; // no filter applied
        return content.type === type;
      })
      .map(content => {
        // Fill the contents list with the batch contents.
        collectedContents
          .push({
            ...content,
            // Allow to know what company owns the content when it's custom.
            belongsToCompany: companyId,
            section
          });

        return ({
          title: content.name,
          dataIndex: content.id,
          key: content.id,
          contentId: content.id,
          align: "center",
          width: "400px",
          render: ({ status }) => {
            const circleIndicator = STATUS_ICONS[status];
            return <CircleContainer>{circleIndicator}</CircleContainer>;
          }
        });
      });

    contentColumns.push({
      sectionId: section.id,
      sectionName: section.title,
      title: <SectionTitleContainer>
        {section.title}
      </SectionTitleContainer>,
      className: "SectionColumnHeader",
      children: sectionContentsColumns.length
        ? sectionContentsColumns
        // inject an empty column to avoid weird vertical alignments
        : [{ width: "400px" }]
    });
  });

  return [contentColumns, collectedContents];
};

const CircleContainer = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center"
      }}>
      {children}
    </div>
  );
};

const SectionTitleContainer = ({ children }) => {
  return (
    <div style={{ color: "#5F39DE", fontWeight: "bold", textAlign: "center"  }}>
      {children}
    </div>
  );
};
