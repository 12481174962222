import React, { useContext } from "react";
import { SidebarLayout, Section } from "../../../../components/shared";
import { AdminExpertsContext } from "../../../../contexts/admin/experts";
import {
  ExpertServiceModalProvider
} from "../../../../contexts/shared/expert-service-modal";
import {
  ExpertServicesList
} from "../../../../components/admin/experts/services/list";
import { shared } from "../../../../graphql/shared";
import { client } from "../../../../../graphql";
import { useQuery } from "@apollo/react-hooks";
import { MenuContext } from "../../../../contexts/shared/menu";
import {
  AssignedExpertServiceModalProvider
} from "../../../../contexts/shared/assigned-expert-service-modal";
import {
  FilterControls
} from "../../../../components/admin/experts/services/filter-controls";
import { MainMenu } from "../../../../components/admin/shared";
import { useQueryFilters, useControlledPagination } from "../../../../helpers";
import {
  CurrentUserContext,
  PaginationContext
} from "../../../../contexts/shared";

export function ServicesIndex() {
  const { setMenuState } = useContext(MenuContext);
  const { currentWorkspace } = useContext(CurrentUserContext);
  const { registerRefetch } = useContext(PaginationContext);

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/services"]
  });

  const variables = { first: 12 };

  if(currentWorkspace.id) {
    variables.expert = {
      memberships_some: {
        workspace: {
          id: currentWorkspace.id
        }
      }
    };
  }

  const {
    loading,
    error,
    data,
    refetch,
    variables:
    queryVariables
  } = useQuery(
    shared.queries.getServices,
    {
      client,
      variables,
      fetchPolicy: "network-only"
    }
  );

  const { applyFilters, filters } = useQueryFilters({
    refetch,
    defaultFilters: {
      first: 12
    }
  });

  registerRefetch("services", refetch, queryVariables);

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 12
  });

  const onFilterChange = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  return (
    <ExpertServiceModalProvider refetchVars={filters}>
      <AssignedExpertServiceModalProvider>
        <AdminExpertsContext.Provider value={{ data }}>
          <SidebarLayout menu={<MainMenu />}>
            <Section
              title="Productos y servicios"
              extra={<FilterControls onChange={onFilterChange} />}
              spaced>
              <ExpertServicesList
                data={data}
                isLoading={loading}
                error={error}
                onChangePage={onPaginationChange}
                currentPage={currentPage} />
            </Section>
          </SidebarLayout>
        </AdminExpertsContext.Provider>
      </AssignedExpertServiceModalProvider>
    </ExpertServiceModalProvider>
  );
}
