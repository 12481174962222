import React from "react";

/**
 * Wraps the element in an anchor tag if an href is specified
 */
export const AnchorWrapper = ({ href, children, key }) => {
  if (href) {
    return <a key={key} href={href}>{children}</a>;
  }

  return children;
};
