import React, { useState } from "react";
import { message } from "antd";
import { useMutation } from "react-apollo-hooks";
import { EditOdeContext } from "./context";
import { initialState } from "./helpers";
import { shared } from "../../../graphql/shared";

export function EditOdeProvider({
  children,
  odeId = "",
  data,
  loading,
  error
}) {
  const [state, setState] = useState({
    ...initialState
  });

  const [updateOde] = useMutation(shared.mutations.updateOde, {
    refetchQueries: [{
      query: shared.queries.getOdeById,
      variables: { id: odeId }
    }]
  });

  const setEditing = (editing = true, formName) => {
    setState(prevState => {
      prevState.editing[formName] = editing;
      return { ...prevState };
    });
  };

  const saveChanges = async (data = {}, formName = "") => { // injectable
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      await updateOde({
        variables: {
          data,
          id: odeId
        }
      });

      setEditing(false, formName);
      message.success("Cambios guardados.");
    } catch (error) {
      if (error.message.match(/NAME_ALREADY_TAKEN/)) {
        return message.warning(`Ya existe una Compañía con el nombre\
           "${data.name}". Por favor, ingresa un nombre diferente.`);
      }

      console.error(error?.message);
      message.error("Ocurrió un error.");
    } finally {
      dismissLoader();
    }
  };

  const disconnectOdeTeamMember = async id => {
    try {
      await updateOde({
        awaitRefetchQueries: true,
        variables: {
          id: odeId,
          data: { team: { disconnect: [{ id }] } }
        },
        refetchQueries: [{
          query: shared.queries.getOdeTeamUsers,
          variables: { id: odeId }
        }]
      });
      message.success("Cambios guardados.");
    } catch(error) {
      console.error(error);
      message.error("Ha ocurrido un error, inténtalo de nuevo\
      en unos segundos.");
    }
  };

  const injectActions = {
    saveChanges,
    editing: state.editing,
    setEditing,
    data,
    loading,
    error,
    disconnectOdeTeamMember
  };

  return (
    <EditOdeContext.Provider value={injectActions}>
      { children }
    </EditOdeContext.Provider>
  );
}
