import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql";
import { withRouter } from "../../../../../helpers";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { EditOdeProvider } from "../../../../../contexts/shared/edit-ode";
import { Section, SidebarLayout } from "../../../../../components/shared";
import { Breadcrumbs, Navbar } from "../../../../../components/shared/odes/show";
import { MainMenu } from "../../../../../components/coordinator/shared";
import { TaskDetailsModalContextProvider } from "../../../../../contexts/shared/task-details-modal/provider";
import { SummaryEditSection } from "../../../../../components/shared/ode-forms";
import { FilterControls } from "../../../../../components/admin/batches/show/tasks/filter-controls";
import {
  default as TaskTable
} from "../../../../../components/shared/task-table";


function TasksIndex({ match }) {
  const { params = {} } = match;
  const odeId = params?.id;

  const { setMenuState } = useContext(MenuContext);

  const [filters, setSelectedFilters] = useState({
    selectedBatch: undefined,
    selectedState: "all",
    inputSearch: ""
  });

  const { loading, error, data = {} } = useQuery(
    shared.queries.getOdeById,
    {
      client,
      variables: { id: odeId },
      fetchPolicy: "network-only"
    }
  );

  const { data: taskData, loading: taskLoading, error: taskError, refetch } = useQuery(
    shared.queries.getTasks,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          batch: {id: filters.selectedBatch},
          ode: {
            id: odeId
          }
        }
      },
      client
    }
  );

  useEffect(() => {
    setMenuState({
      openKeys: ["odes"]
    });
  }, []);

  useEffect(() => {
    refetch();
  }, [filters.selectedBatch])

  const handleBatchSelect = batchId => {
    setSelectedFilters(state => ({
      ...state,
      selectedBatch: batchId
    }));
  };

  const handleSelectorChange = value => {
    setSelectedFilters(state => ({
      ...state,
      selectedState: value
    }));
  };

  const handleInputSearch = value => {
    setSelectedFilters(state => ({
      ...state,
      inputSearch: value
    }));
  };

  const handleSearchChange = e => {
    if (e?.target?.value === "") {
      setSelectedFilters(state => ({
        ...state,
        inputSearch: ""
      }));
    }
  };

  return (
    <EditOdeProvider
      odeId={odeId}
      data={data}
      loading={loading}
      error={error}>
      <SidebarLayout menu={<MainMenu />}>
        <TaskDetailsModalContextProvider>
          <Breadcrumbs
            odeName={data?.oDE?.name} />
          <SummaryEditSection
            editable
            data={data}
            isLoading={loading}
            error={error} />
          <Navbar selectedKeys={["tasks"]} />
          <Section
            title="Tareas"
            spaced
            extra={<FilterControls
              hideFilters={{odeSelect: true}}
              handleBatchSelect={handleBatchSelect}
              handleSelectorChange={handleSelectorChange}
              handleInputSearch={handleInputSearch}
              resetTableContent={handleSearchChange} />}>
            <TaskTable
              data={taskData}
              error={taskError}
              isLoading={taskLoading}
              selectedState={filters.selectedState}
              inputSearch={filters.inputSearch} />
          </Section>
        </TaskDetailsModalContextProvider>
      </SidebarLayout>
    </EditOdeProvider>
  );
}

export default withRouter(TasksIndex);