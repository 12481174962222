import React, { useState } from "react";
import axios from "axios";
import { message, Space, Upload, Progress } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Format } from "../format";

export const UploadSection = ({
  children,
  icon = <PlusOutlined style={{fontSize: "24px"}} />,
  style,
  onChange,
  ...props
}) => {
  const [state, setState] = useState({
    backgroundImage: undefined,
    fileList: undefined,
    currentUrl: ""
  });
  const [loading, setLoading] = useState({
    isLoading: false,
    percent: 0
  });

  const onUploadChange = info => {
    setState(prevState => ({
      ...prevState,
      fileList: [...info.fileList]
    }));
    if(info.file.status === "uploading") {
      setLoading({
        isLoading: true,
        percent: parseInt(info?.event?.percent)
      });
    } else if(info.file.status === "done") {
      finishLoading();
      onChange && onChange({ ...info, url: state.currentUrl });
    } else if(info.file.status === "error") {
      finishLoading();
      message.error("Ha ocurrido un error al subir el archivo, inténtalo de\
      nuevo en unos segundos.");
    }
  };

  const getDataParams = async file => {
    try {
      const { data } = await axios
        .get(`${process.env.REACT_APP_ASSETS_PRESIGNED}?file=${file.name}`);

      const { fields, upload: url } = data;

      setState(prevState => ({
        ...prevState,
        currentUrl: url
      }));
      return fields;
    } catch (err) {
      console.error(err);
    }
  };

  const startLoading = () => {
    setLoading({
      isLoading: true,
      percent: 0
    });
  };

  const finishLoading = () => {
    setLoading({
      isLoading: false,
      percent: 0
    });
  };

  return (
    <Upload.Dragger
      action={process.env.REACT_APP_ASSETS_AWS}
      className="upload-dragger"
      onChange={onUploadChange}
      data={getDataParams}
      beforeUpload={startLoading}
      fileList={state.fileList}
      style={{ ...style, backgroundImage: state.backgroundImage }}
      {...props}>
      <Space direction="vertical">
        {loading.isLoading
          ? <Progress
            strokeColor="#704AED" type="circle"
            percent={loading.percent} width={80} />
          : <>{icon}<Format type="secondary">{children}</Format></>}
      </Space>
    </Upload.Dragger>
  );
};
