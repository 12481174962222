import React from "react";
import { List, Empty, Button } from "antd";
import { CompositeField } from "../../../../composite-field";
import { EducationItem } from "./item/form";
import { PlusOutlined } from "@ant-design/icons";
import * as uuid from "uuid";

export function EducationInput({
  setStateForm,
  onChange,
  value
}) {
  const onItemChange = ({ education, values, idx, replaceItemAtIndex }) => {
    values.uid = education.uid;
    replaceItemAtIndex(idx, values);
  };

  return (
    <CompositeField
      onChange={onChange}
      isAddDisabled={true}
      value={value}>
      {({items, addNew, removeItem, replaceItemAtIndex}) =>
        <>
          <List>
            {(items.length === 0) ?
              <Empty />
              : items.map((education, idx) => {
                return (
                  <List.Item key={`education-item-${education.uid}`}>
                    <EducationItem
                      setStateForm={setStateForm}
                      education={education}
                      idx={idx}
                      onChange={values =>
                        onItemChange({
                          education,
                          values,
                          idx,
                          replaceItemAtIndex
                        })}
                      removeItem={removeItem} />
                  </List.Item>
                );
              })
            }
          </List>
          <Button
            type="dashed"
            icon={<PlusOutlined />}
            onClick={() => addNew({ uid: uuid.v4() })}
            block>
            Agregar educación
          </Button>
        </>
      }
    </CompositeField>
  );
}
