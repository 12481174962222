import React from "react";
import { Popover, Tag } from "antd";

export function HiddenTagsPopover({
  children,
  colors = [],
  tags = [],
  mapColors
}) {
  const content = (
    <div>
      {tags.map((label, index) => (
        <Tag
          style={{ padding: "1px 8px" }}
          key={label}
          color={mapColors ? (colors[label] || "default") : colors[index]}>
          {label}
        </Tag>
      ))}
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="hover">
      {children}
    </Popover>
  );
}
