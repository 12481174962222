import React from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Format } from "../format";
import {
  entrepreneurContentTypes
} from "../../../helpers/select-options/content";

export const columns = [
  {
    title: "Tareas",
    dataIndex: "name",
    key: "name",
    render: text => <p>{text}</p>,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ["descend"]
  },
  {
    title: "Tipo",
    dataIndex: "type",
    key: "type",
    render: type => <Format
      type="tag"
      options={entrepreneurContentTypes}>
      {type}
    </Format>
  },
  {
    title: "Objetivo",
    dataIndex: "achievement",
    key: "objective",
    render: achievement => <p>{achievement?.objective?.name}</p>
  },
  {
    title: "Logro",
    dataIndex: "achievement",
    key: "achievement",
    render: achievement => <p>{achievement?.name}</p>
  },
  {
    title: "Responsable",
    dataIndex: "responsable",
    key: "responsable",
    render: user => <p>{user.fullName}</p>
  },
  {
    title: "Creada por",
    dataIndex: "createdBy",
    key: "createdBy",
    render: user => <p>{user.fullName}</p>
  },
  {
    title: "Estado",
    dataIndex: "isCompleted",
    key: "isCompleted",
    render: completed => <CheckCircleTwoTone
      twoToneColor={completed ? "#52c41a" : "#D7D7D7"} />
  },
  {
    title: "Fecha de entrega",
    dataIndex: "deliveryDate",
    key: "deliveryDate",
    render: date => <Format type="date">{date}</Format>,
    sorter: (a, b) => a.deliveryDate < b.deliveryDate,
    sortDirections: ["descend"]
  }
];
