import React from "react";
import { ServicesLayerContext } from "./context";

export function ServicesLayerProvider({ services = {}, children }) {
  return (
    <ServicesLayerContext.Provider value={services}>
      {children}
    </ServicesLayerContext.Provider>
  );
}
