import { countCompaniesBy } from "./count-companies-by";
import { countEntrepreneursBy } from "./count-entrepreneurs-by";
import { getCompaniesStatistics } from "./get-companies-statistics";
import { getEntrepreneursCareers } from "./get-entrepreneurs-careers";
import { getEntrepreneursCampusList } from "./get-entrepreneurs-campus-list";
import {
  getEntrepreneursTecRelationshipTypes
} from "./get-entrepreneurs-tec-relationship-types";

export const AnalyticsService = {
  countCompaniesBy,
  countEntrepreneursBy,
  getCompaniesStatistics,
  getEntrepreneursCareers,
  getEntrepreneursCampusList,
  getEntrepreneursTecRelationshipTypes
};
