import React from "react";
import { Avatar as AntAvatar, Popover } from "antd";
import ColorHash from "color-hash";
import { UserOutlined } from "@ant-design/icons";

export function Avatar({
  children,
  size = "default",
  src: imageSource,
  color = "#CCCCCC",
  icon,
  popover = false,
  style = {},
  ...props
}) {
  let src;
  let name = null;

  if(typeof children === "string") {
    if(children.toString().indexOf("http") !== -1) {
      src = children;
      children = undefined;
    }
    else {
      name = children;
      const colorHash = new ColorHash();
      color = colorHash.hex(children);
      children = children
        .split(" ")
        .slice(0, 2)
        .map(s => s[0])
        .join("")
        .toUpperCase();
    }
  }

  return (
    <Popover trigger={(popover && children) ? "hover" : "none"} content={name}>
      <AntAvatar
        size={size}
        style={{ backgroundColor: color, ...style }}
        src={imageSource || src}
        className={children ? null : "placeholder"}
        {...props}>
        { children || (icon || <UserOutlined />) }
      </AntAvatar>
    </Popover>
  );
}
