import React from "react";
import { withLoader } from "../../../../helpers";
import { AccountsEditSection } from "../../../shared/profile-forms";
import {
  MissingProfileDataAlertProvider
} from "../../../../contexts/entrepreneur/missing-profile-data-alert";

function PersonalInformation({ editable = false, data = {} }) {

  return (
    <MissingProfileDataAlertProvider user={data?.user}>
      <AccountsEditSection editable={editable} />
    </MissingProfileDataAlertProvider>
  );
}

export default withLoader(PersonalInformation);