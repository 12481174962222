/* eslint-disable max-len */
/* eslint-disable max-lines */

export const CAREERS = [
  {
    label: "Ambiente Construido/ Exploración",
    value: "Ambiente Construido/ Exploración",
    legacyValues: ["AMC"]
  },
  {
    label: "Estudios Creativos / Exploración",
    value: "Estudios Creativos / Exploración",
    legacyValues: ["ESC"]
  },
  {
    label: "Arquitectura",
    value: "Arquitectura",
    legacyValues: ["ARQ"]
  },
  {
    label: "Licenciatura en Arte Digital",
    value: "Licenciatura en Arte Digital",
    legacyValues: ["LAD"]
  },
  {
    label: "Licenciatura en Diseño",
    value: "Licenciatura en Diseño",
    legacyValues: ["LDI"]
  },
  {
    label: "Licenciatura en Urbanismo",
    value: "Licenciatura en Urbanismo",
    legacyValues: ["LUB"]
  },
  {
    label: "Ciencias Sociales / Exploración",
    value: "Ciencias Sociales / Exploración",
    legacyValues: ["CIS"]
  },
  {
    label: "Licenciatura en Economía",
    value: "Licenciatura en Economía",
    legacyValues: ["LEC"]
  },
  {
    label: "Licenciatura en Derecho",
    value: "Licenciatura en Derecho",
    legacyValues: ["LED"]
  },
  {
    label: "Licenciatura en Relaciones Internacionales",
    value: "Licenciatura en Relaciones Internacionales",
    legacyValues: ["LRI"]
  },
  {
    label: "Licenciatura en Gobierno y Transformación Pública",
    value: "Licenciatura en Gobierno y Transformación Pública",
    legacyValues: ["LTP"]
  },
  {
    label: "Licenciatura en Comunicación",
    value: "Licenciatura en Comunicación",
    legacyValues: ["LC"]
  },
  {
    label: "Licenciatura en Innovación Educativa",
    value: "Licenciatura en Innovación Educativa",
    legacyValues: ["LEI"]
  },
  {
    label: "Licenciatura en Letras Hispánicas",
    value: "Licenciatura en Letras Hispánicas",
    legacyValues: ["LLE"]
  },
  {
    label: "Licenciatura en Periodismo",
    value: "Licenciatura en Periodismo",
    legacyValues: ["LPE"]
  },
  {
    label: "Licenciatura en Tecnología y Producción Musical",
    value: "Licenciatura en Tecnología y Producción Musical",
    legacyValues: ["LTM"]
  },
  {
    label: "Ingeniería - Bioingeniería y Procesos Químicos (avenida) / Exploración",
    value: "Ingeniería - Bioingeniería y Procesos Químicos (avenida) / Exploración",
    legacyValues: ["IBQ"]
  },
  {
    label: "Ingeniería en Biosistemas Agroalimentarios",
    value: "Ingeniería en Biosistemas Agroalimentarios",
    legacyValues: ["IAG"]
  },
  {
    label: "Ingeniería en Alimentos",
    value: "Ingeniería en Alimentos",
    legacyValues: ["IAL"]
  },
  {
    label: "Ingeniería en Biotecnología",
    value: "Ingeniería en Biotecnología",
    legacyValues: ["IBT"]
  },
  {
    label: "Ingeniería en Desarrollo Sustentable",
    value: "Ingeniería en Desarrollo Sustentable",
    legacyValues: ["IDS"]
  },
  {
    label: "Ingeniería Químico",
    value: "Ingeniería Químico",
    legacyValues: ["IQ"]
  },
  {
    label: "Ingeniería - Ciencias Aplicadas (avenida) / Exploración",
    value: "Ingeniería - Ciencias Aplicadas (avenida) / Exploración",
    legacyValues: ["ICI"]
  },
  {
    label: "Ingeniería en Ciencia de Datos y Matemáticas",
    value: "Ingeniería en Ciencia de Datos y Matemáticas",
    legacyValues: ["IDM"]
  },
  {
    label: "Ingeniería Físico Industrial",
    value: "Ingeniería Físico Industrial",
    legacyValues: ["IFI"]
  },
  {
    label: "Ingeniería en Nanotecnología",
    value: "Ingeniería en Nanotecnología",
    legacyValues: ["INA"]
  },
  {
    label: "Ingeniería - Computación y Tecnologías de Información (avenida) / Exploración",
    value: "Ingeniería - Computación y Tecnologías de Información (avenida) / Exploración",
    legacyValues: ["ICT"]
  },
  {
    label: "Ingeniería en Robótica y Sistemas Digitales",
    value: "Ingeniería en Robótica y Sistemas Digitales",
    legacyValues: ["IRS"]
  },
  {
    label: "Ingeniería en Tecnologías Computacionales",
    value: "Ingeniería en Tecnologías Computacionales",
    legacyValues: ["ITC"]
  },
  {
    label: "Ingeniería en Transformación Digital de Negocios",
    value: "Ingeniería en Transformación Digital de Negocios",
    legacyValues: ["ITD"]
  },
  {
    label: "Ingeniería - Innovación y Transformación (avenida) / Exploración",
    value: "Ingeniería - Innovación y Transformación (avenida) / Exploración",
    legacyValues: ["IIT"]
  },
  {
    label: "Ingeniería Civil",
    value: "Ingeniería Civil",
    legacyValues: ["IC"]
  },
  {
    label: "Ingeniería en Electrónica",
    value: "Ingeniería en Electrónica",
    legacyValues: ["IE"]
  },
  {
    label: "Ingeniería en Innovación y Desarrollo",
    value: "Ingeniería en Innovación y Desarrollo",
    legacyValues: ["IID"]
  },
  {
    label: "Ingeniería Industrial y de Sistemas",
    value: "Ingeniería Industrial y de Sistemas",
    legacyValues: ["IIS"]
  },
  {
    label: "Ingeniería Mecánico",
    value: "Ingeniería Mecánico",
    legacyValues: ["IM"]
  },
  {
    label: "Ingeniería Biomédico",
    value: "Ingeniería Biomédico",
    legacyValues: ["IMD"]
  },
  {
    label: "Ingeniería en Mecatrónica",
    value: "Ingeniería en Mecatrónica",
    legacyValues: ["IMT"]
  },
  {
    label: "Salud / Exploración",
    value: "Salud / Exploración",
    legacyValues: ["SLD"]
  },
  {
    label: "Licenciatura en Biociencias",
    value: "Licenciatura en Biociencias",
    legacyValues: ["LBC"]
  },
  {
    label: "Licenciatura en Nutrición y Bienestar Integral",
    value: "Licenciatura en Nutrición y Bienestar Integral",
    legacyValues: ["LNB"]
  },
  {
    label: "Licenciatura en Psicología Clínica y de la Salud",
    value: "Licenciatura en Psicología Clínica y de la Salud",
    legacyValues: ["LPS"]
  },
  {
    label: "Médico Cirujano",
    value: "Médico Cirujano",
    legacyValues: ["MC"]
  },
  {
    label: "Médico Cirujano Odontólogo",
    value: "Médico Cirujano Odontólogo",
    legacyValues: ["MO"]
  },
  {
    label: "Negocios / Exploración",
    value: "Negocios / Exploración",
    legacyValues: ["NEG", "Negocios"]
  },
  {
    label: "Licenciatura en Negocios Internacionales",
    value: "Licenciatura en Negocios Internacionales",
    legacyValues: ["BGB"]
  },
  {
    label: "Licenciatura en Estrategia y Transformación de Negocios",
    value: "Licenciatura en Estrategia y Transformación de Negocios",
    legacyValues: ["LAE"]
  },
  {
    label: "Licenciatura en Finanzas",
    value: "Licenciatura en Finanzas",
    legacyValues: ["LAF"]
  },
  {
    label: "Licenciatura en Contaduría Pública y Finanzas",
    value: "Licenciatura en Contaduría Pública y Finanzas",
    legacyValues: ["LCPF"]
  },
  {
    label: "Licenciatura en Emprendimiento",
    value: "Licenciatura en Emprendimiento",
    legacyValues: ["LDE"]
  },
  {
    label: "Licenciatura en Desarrollo de Talento y Cultura Organizacional",
    value: "Licenciatura en Desarrollo de Talento y Cultura Organizacional",
    legacyValues: ["LDO"]
  },
  {
    label: "Licenciatura en Mercadotecnia",
    value: "Licenciatura en Mercadotecnia",
    legacyValues: ["LEM"]
  },
  {
    label: "Licenciatura en Negocios Internacionales",
    value: "Licenciatura en Negocios Internacionales",
    legacyValues: ["LIN"]
  },
  {
    label: "Licenciatura en Inteligencia de Negocios",
    value: "Licenciatura en Inteligencia de Negocios",
    legacyValues: ["LIT"]
  }
];
