import React from "react";
import { Route, Switch } from "react-router";
import ProfilesIndex from "../../../pages/coordinator/entrepreneurs/";
import PersonalInformationIndex from "../../../pages/coordinator/entrepreneurs/show";

export const CoordinatorEntrepreneursRoutes = (
  <Switch>
    <Route
      exact
      path="/v2/coordinator/entrepreneurs"
      component={ProfilesIndex} />
    <Route
      path="/v2/coordinator/entrepreneurs/:id/personal-information"
      component={PersonalInformationIndex} />
  </Switch>
);