import React from "react";
import { OnboardingLayout } from "../../../../components/shared";
import { GeneralInformation } from "../../../../components/expert/onboarding";
import {
  OnBoardingContextProvider
} from "../../../../contexts/expert/onboarding";

export function GeneralInformationIndex() {
  return (
    <OnBoardingContextProvider>
      <OnboardingLayout>
        <GeneralInformation />
      </OnboardingLayout>
    </OnBoardingContextProvider>
  );
}
