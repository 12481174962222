import React, { useContext, useEffect, useState } from "react";
import { Space, Alert, Button, Input, Form } from "antd";
import { Avatar, UploadButton } from "../../../../shared";
import { toFileList } from "../../../../../helpers";
import {
  EditOdeContext
} from "../../../../../contexts/shared/edit-ode";
import {
  userDecorator
} from "../../../../../decorators/admin/experts/show/user-decorator";

export function EditSummaryForm() {
  const {
    saveChanges,
    editing,
    setEditing,
    data
  } = useContext(EditOdeContext);

  const [state, setState] = useState({
    logoUrl: undefined
  });

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...data?.oDE,
      logoUrl: data.oDE?.logoUrl ?
        toFileList([{ url: data.oDE?.logoUrl }])
        : undefined
    });

    setState({
      logoUrl: data?.oDE?.logoUrl
    });
  }, [data, editing]);

  const save = async () => {
    await form.validateFields();
    const values = form?.getFieldsValue();
    await saveChanges({
      ...values,
      logoUrl: state.logoUrl
    },
    "summary");
  };

  if(!data.oDE) {
    return (
      <Alert
        type="error"
        message="Ha ocurrido un error al cargar la información,
        por favor intenta de nuevo en unos segundos."
        showIcon />
    );
  }

  const decoratedUser = Object.assign(userDecorator, data.user);

  const getPhotoUrl = e => {
    const logoUrl = e?.file?.response?.imageUrl;

    setState(prevState => ({
      ...prevState,
      logoUrl
    }));

    return e.fileList;
  };

  return (
    <Form
      form={form}
      layout="vertical">
      <Space
        direction="horizontal"
        size={20}
        align="start">
        <Space direction="vertical" align="center">
          <Form.Item>
            <Avatar
              size={64}
              src={state.logoUrl}>
              { decoratedUser.fullName }
            </Avatar>
          </Form.Item>
          <Form.Item
            name="logoUrl"
            getValueFromEvent={getPhotoUrl}>
            <UploadButton.Crop
              type="primary"
              aspect={0.9}
              multiple={false}
              maxFiles={1}
              showUploadList={false}>
              Subir logo
            </UploadButton.Crop>
          </Form.Item>
        </Space>
        <Space direction="vertical" size={5}>
          <Space direction="horizontal">
            <Form.Item
              rules={[{ required: true }]}
              label="Nombre"
              name="name">
              <Input placeholder="Nombre" style={{ width: "250px" }} />
            </Form.Item>
            <Button type="primary" onClick={save}>
              Guardar
            </Button>
            <Button type="default" onClick={() => setEditing(false, "summary")}>
              Cancelar
            </Button>
          </Space>
          <Form.Item label="Descripción" name="description">
            <Input.TextArea
              placeholder="Descripción"
              className="wide-input"
              rows={3} />
          </Form.Item>
          <Space direction="horizontal">
            <Form.Item label="E-mail" name="email">
              <Input placeholder="E-mail" className="wide-input" />
            </Form.Item>
            <Form.Item label="Teléfono" name="phone">
              <Input placeholder="Teléfono" className="wide-input" />
            </Form.Item>
          </Space>
        </Space>
      </Space>
    </Form>
  );
}
