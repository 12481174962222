import React from "react";
import { Typography } from "antd";

export const HeaderTexts = ({ title, description }) => {
  const { Title, Text } = Typography;
  return (
    <div style={{ marginLeft: "24px", marginBottom: "17px" }}>
      <Title level={5} style={{ marginBottom: "0" }}>{title}</Title>
      <Text>{description}</Text>
    </div>
  )
}
