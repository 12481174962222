import gql from "graphql-tag";

export const getOdesByCoordinatorId = gql`
  query GetOdesByCoordinatorId(
    $id: ID,
    $searchString: String,
    $batches_some: BatchWhereInput,
    $skip: Int,
    $first: Int
  ){
    oDEs(
      where: {
        iname_contains: $searchString
        batches_some: $batches_some
        OR: [
          {
            OR:[
              { team_some: { id: $id } }
              { network_some: {id: $id } }
            ]
          },
          { batches_some: { users_some: { id: $id } } },
          { batches_some: $batches_some }
        ]
      },
      skip: $skip,
      first: $first
    ){
      id
      iname
      name
      logoUrl
      sector
      team {
        fullName
        profilePicUrl
      }
      batches {
        program {
          type
        }
      }
    }
    meta: oDEsConnection(
      where: {
        iname_contains: $searchString
        batches_some: $batches_some
        OR: [
          {
            OR:[
              { team_some: { id: $id } }
              { network_some: {id: $id } }
            ]
          },
          { batches_some: { users_some: { id: $id } } },
          { batches_some: $batches_some }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
