import React, { useContext } from "react";
import { List } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import { BatchItem } from "../../../batches/list/item";
import { shared } from "../../../../../graphql/shared";
import useTreatment from "../../../../../hooks/feature-flags/use-treatment";
import {
  withRouter,
  withLoader,
  cardGridSizes,
  paginationConfig
} from "../../../../../helpers";
import {
  CloneBatchModalContext
} from "../../../../../contexts/shared";

function BatchesList({ data, loading, navigate, refetch }) {
  const [deleteBatchById] = useMutation(shared.mutations.deleteBatchById, {
    client
  });

  const { openModal } = useContext(CloneBatchModalContext);

  const onDelete = id => async () => {
    try {
      await deleteBatchById({ variables: { id } });
      await refetch();
    }
    catch(e) {
      console.error(e);
    }
  };

  const disableBatchOfferTreatmentValue =
    useTreatment("DisableBatchOffer");

  const goToBatchOffer = id => {
    if (disableBatchOfferTreatmentValue === "ON") {
      navigate(`./../../batches/${id}/odes`);
    } else if(disableBatchOfferTreatmentValue === "OFF") {
      navigate(`../../batches/${id}/offer?section=information`);
    }
  };

  return (
    <List
      pagination={{
        ...paginationConfig,
        defaultPageSize: 12
      }}
      loading={loading}
      grid={cardGridSizes}
      dataSource={data?.batches}
      renderItem={batch =>
        <List.Item>
          <BatchItem
            batch={batch}
            onClick={() => goToBatchOffer(batch.id)}
            actions={{
              extra: [{
                action: () => openModal(batch), label: "Copiar"
              }],
              onDelete: onDelete(batch.id)
            }} />
        </List.Item>
      } />
  );

}

export default withLoader(withRouter(BatchesList));
