import React from "react";
import { Layout as ANTLayout } from "antd";
import styled from "styled-components";

const { Header: ANTHeader, Content: ANTContent } = ANTLayout;

const BaseLayout = styled(ANTLayout)`
`;

const Header = styled(ANTHeader)`
  background-color: #ffffff !important;
  border-bottom: solid 1px #f4f4f4;
`;

const Content = styled(ANTContent)`
  background-color: #f4f4f4;
`;

export const Layout = ({ children }) => {
  return (
    <BaseLayout>
      <Header></Header>
      <Content>{children}</Content>
    </BaseLayout>
  );
};
