export const onBatchChangeHandler = (batchId, filters, filtersUpdater) => {
  let updatedFilters = {};

  filtersUpdater(prevFilters => {
    updatedFilters = {
      ...prevFilters,
      batchId
    };
    return updatedFilters;
  });

  return updatedFilters;
};