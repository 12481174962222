import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, Space, message, Modal } from "antd";
import { Format } from "../format";

export function DraggerUploadArea({
  children,
  onChange,
  icon = <PlusOutlined style={{fontSize: "24px"}} />,
  style,
  ...props
}) {
  const onUploadChange = info => {
    if(info.file.status === "done") {
      onChange && onChange(info);
    } else if(info.file.status === "error") {
      message.error("Ha ocurrido un error al subir el archivo, inténtalo de\
      nuevo en unos segundos.");
    }
  };

  const onRemove = async file => {
    return new Promise(resolve => {
      Modal.confirm({
        title: "¿Deseas eliminar este archivo?",
        okText: "Eliminar",
        cancelText: "Cancelar",
        onOk: () => {
          resolve(file);
        },
        onCancel: () => {
          resolve(false);
        }
      });
    });
  };

  return (
    <Upload.Dragger
      onChange={onUploadChange}
      action={process.env.REACT_APP_ASSETS_ENDPOINT}
      multiple={false}
      style={{ ...style }}
      onRemove={onRemove}
      {...props}>
      <Space direction="vertical">
        { icon }
        <Format type="secondary">{children}</Format>
      </Space>
    </Upload.Dragger>
  );
}
