import React from "react";
import { List, Descriptions } from "antd";
import moment from "moment";
import { FormContainer } from "../../../../../../shared";
import {
  educationDegreesList,
  getReadableValue
} from "../../../../../../../helpers/select-options";

export function EducationItem({ user = {} }) {
  const education = user?.expertProfile?.professionalExperience?.education;
  return (
    <FormContainer>
      <List
        dataSource={education}
        renderItem={item => (
          <List.Item style={{position: "relative"}}>
            <Descriptions
              column={4}
              layout="vertical"
              title={getReadableValue(educationDegreesList, item.degree)}>
              <Descriptions.Item
                label="Área de especialidad"
                labelStyle={{ fontWeight: "bold" }}>
                {item?.specialityArea}
              </Descriptions.Item>
              <Descriptions.Item
                label="Institución"
                labelStyle={{ fontWeight: "bold" }}>
                {item?.institution}
              </Descriptions.Item>
              <Descriptions.Item
                label="Fecha de graduación"
                labelStyle={{ fontWeight: "bold" }}>
                {item?.graduationDate
                  ? moment(item?.graduationDate || "").format("yyyy")
                  : ""}
              </Descriptions.Item>
            </Descriptions>
          </List.Item>
        )} />
    </FormContainer>
  );
}
