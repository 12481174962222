import React, { useEffect } from "react";
import { Modal, Form, Input, DatePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { merge } from "lodash";

export function EntrepreneurshipModal({onSave, onCancel, edit, ...props}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if(edit) {
      form.setFieldsValue(edit);
    }
  }, [edit]);

  const onOk = async() => {
    try {
      let values = await form.getFieldsValue()

      if (typeof edit?.index !== "undefined") {
        values.index = edit.index
        values = merge(edit, values)
      }

      onSave(values)
      form.resetFields()
    }
    catch(e) {
      console.error(e)
    }
  };

  const handleOnCancel = (e) => {
    if (onCancel) {
      onCancel(e);
    }
    form.resetFields();
  };

  return (
    <Modal
      okText="Guardar"
      cancelText="Cancelar"
      title="Emprendimiento"
      onOk={onOk}
      onCancel={handleOnCancel}
      {...props}>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          name="name"
          label="Nombre de emprendimiento / Proyecto">
          <Input />
        </Form.Item>
        <Form.Item
          name="period"
          label="Periodo de emprendimiento">
          <DatePicker.RangePicker style={{width: "100%"}} />
        </Form.Item>
        <Form.Item
          name="position"
          label="Puesto">
          <Input />
        </Form.Item>
        <Form.Item
          name="achievements"
          label="Logros alcanzados">
          <TextArea></TextArea>
        </Form.Item>
      </Form>
    </Modal>
  );
}
