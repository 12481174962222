import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";

import { client } from "../../../../../../graphql";
import { shared } from "../../../../../graphql/shared";
import { AdminExpertsContext } from "../../../../../contexts/admin/experts";
import {
  SidebarLayout,
  ContentWrapper
} from "../../../../../components/shared";
import {
  Navbar,
  Profile,
  Breadcrumbs
} from "../../../../../components/admin/experts/show";
import {
  SummaryEditSection
} from "../../../../../components/shared/profile-forms/summary";
import {
  userDecorator
} from "../../../../../decorators/admin/experts/show/user-decorator";
import { CurrentUserProvider } from "../../../../../contexts/shared/current-user";
import { EditProfileProvider } from "../../../../../contexts/shared/edit-profile";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { PageLeavePromptProvider } from "../../../../../contexts/shared";

function ProfileIndex({ match }) {
  const { params = {} } = match;
  const userId = params?.expertId;

  const { loading, error, data = {} } = useQuery(
    shared.queries.getExpertById,
    {
      client,
      variables: { id: userId },
      fetchPolicy: "network-only"
    });

  const decoratedUser = Object.assign(userDecorator, data?.user);

  const { setMenuState } = useContext(MenuContext);

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/profiles"]
  });

  return (
    <PageLeavePromptProvider>
      <CurrentUserProvider>
        <EditProfileProvider
          userId={userId}
          data={data}>
          <AdminExpertsContext.Provider value={{ data }}>
            <SidebarLayout>
              <Breadcrumbs
                expertName={decoratedUser.fullName} />
              <SummaryEditSection
                editable
                data={data}
                isLoading={loading}
                error={error} />
              <Navbar selectedKeys={["profile"]} />
              <ContentWrapper>
                <Profile
                  editable
                  data={data}
                  error={error}
                  isLoading={loading} />
              </ContentWrapper>
            </SidebarLayout>
          </AdminExpertsContext.Provider>
        </EditProfileProvider>
      </CurrentUserProvider>
    </PageLeavePromptProvider>
  );
}

export default withRouter(ProfileIndex);
