import gql from "graphql-tag";

export const getSingleBatchQuery = gql`
  query GetSingleBatchQuery(
    $batchId: ID!
  ) {
    batch(
      where: {
        id: $batchId
      }
    ) {
      id
      name
      # leader is the leader id
      leader
      description
      startDate
      finishDate
      program {
        id
        name
        description
        coverPicUrl
      }
      users {
        id
        fullName
        email
        phone
        profilePicUrl
        systemRole
        careerName
      }
    }
  }
`;
