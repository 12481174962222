import { message } from "antd";
import { BatchesService } from "../../services/batch-service";

export const useBatchServices = ({ batchId }) => {
  const connectServices = async ({ serviceIds = [], afterSuccess }) => {
    const dismissLoader = message.loading("Guardando...");
    try {
      await BatchesService.connectServices({
        batchId,
        serviceIds
      });

      message.success("Servicios agregados");
      afterSuccess && afterSuccess();
    } catch (err) {
      console.error(err);
      message.error("Ocurrió un error");
    } finally {
      dismissLoader();
    }
  };

  const removeService = async ({ serviceId = "", afterSuccess }) => {
    const dismissLoader = message.loading("Guardando...");
    try {
      await BatchesService.diconnectService({ batchId, serviceId });
      message.success("Servicio removido");
      afterSuccess && afterSuccess();
    } catch (err) {
      message.error("Ocurrió un error");
      console.error(err);
    } finally {
      dismissLoader();
    }
  };

  return {
    connectServices,
    removeService
  };
};