import gql from "graphql-tag";

export const getTasks = gql`
  query GetTasks($where: TaskWhereInput) {
    tasks(where: $where){
      id
      name
      description
      deliveredDate
      deliveryDate
      type
      achievement{
        id
        name
        objective{
          id
          name
        }
      }
      ode{
        id
        name
      }
      responsable{
        id
        fullName
      }
      resources{
        id
        fileName
        url
        type
      }
      createdBy{
        id
        fullName
      }
      deliverables {
        id
        fileName
        url
        type
      }
      comments{
        id
        author{
          profilePicUrl
          firstName
          middleName
        }
        comment
        edited
        createdAt
        updatedAt
      }
      isCompleted
    }
  }
`;
