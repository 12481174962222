import gql from "graphql-tag";

export const updateScheduledServices = gql`
  mutation UpdateScheduledServices(
    $data: ScheduledExpertServiceUpdateInput!,
    $where: ScheduledExpertServiceWhereInput!
  ) {
    updateManyScheduledExpertServicesWithNestedEntities(
      data: $data
      where: $where
    ) {
      count
    }
  }
`;
