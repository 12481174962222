// This object can be used by an AntDesign form
// to show a custom message.
export const fieldValidationMessages = {
  required: "El campo '${label}' es requerido.",
  types: {
    email: "Ingresa un correo válido.",
    number: "Ingresa un número válido."
  }
};

export const genericFieldValidationMsgs = {
  required: "Este campo es requerido.",
  pattern: {
    mismatch:"El formato no es correcto."
  },
  types: {
    email: "Ingresa un correo válido.",
    number: "Ingresa un número válido."
  }
};
