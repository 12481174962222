/* eslint-disable max-lines */
import React, { useEffect, useState } from "react";
import {
  Tag,
  Modal,
  Button,
  message,
  Divider,
  Typography,
  Descriptions
} from "antd";
import moment from "moment";
import { getReadableValue } from "../../../../../../helpers/select-options";
import {
  EntrepreneurSpiritService
} from "../../../../../../services/entrepreneur-spirit";

export const ExperienceDetailModal = ({
  visible,
  selectedItem,
  onCancel,
  ...props
}) => {

  const [experience, setExperience] = useState({});

  const { selectedExperience } = selectedItem || "";

  useEffect(() => {
    if (!selectedExperience)
      return;
    (async () => {
      try {
        const experience = await EntrepreneurSpiritService
          .getPersonExperienceAttendanceByAttendanceId(selectedExperience);
        setExperience(experience?.experience);
      } catch (e) {
        console.error(e);
        message.error(`Error ${e}`);
      }
    })();
  }, [selectedExperience]);

  const formatDate = (startDate, endDate) => startDate
    ? `Del ${moment(
      startDate
    ).format("DD/MMMM/YYYY")} al ${moment(
      endDate
    ).format("DD/MMMM/YYYY")}`
    : "";

  const involvementLevelOptions = [
    { label: "Conocer", value: "KNOW"},
    { label: "Aprender", value: "LEARN"},
    { label: "Accionar", value: "ACT"}
  ];

  return (
    <Modal
      {...props}
      width={1000}
      visible={visible}
      onCancel={onCancel}
      title={"Detalles de la experiencia"}
      footer={[
        <Button key="close" onClick={onCancel}>
          Cerrar
        </Button>
      ]}
      className="entrepreneur-spirit-timeline-modal">
      <Descriptions
        colon={false}
        column={2}
        layout="vertical">
        <Descriptions.Item label="Nombre">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {experience?.name}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Descripción">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {experience?.description}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={2}>
          <Divider />
        </Descriptions.Item>
        <Descriptions.Item label="Fecha">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {formatDate(experience?.startDate, experience?.endDate)}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Duración">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {
              experience?.durationInHours > 1
              ? `${experience?.durationInHours} horas`
              : `${experience?.durationInHours} hora`
            }
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={2}>
          <Divider />
        </Descriptions.Item>
        <Descriptions.Item label="Nivel de involucramiento">
          <Typography.Text style={{color: "#8C8C8C"}}>
            <Tag>{getReadableValue(
              involvementLevelOptions,experience?.involvementLevel
            )}</Tag>
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Tipo de experiencia">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {experience?.type}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={2}>
          <Divider />
        </Descriptions.Item>
        <Descriptions.Item label="Categoría de la experiencia">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {experience?.category}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Audiencia">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {experience?.audience}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={2}>
          <Divider />
        </Descriptions.Item>
        <Descriptions.Item label="Semestre mínimo">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {
              experience?.minimumAudienceSemester
                ? `${experience?.minimumAudienceSemester} semestre`
                : "No aplica"
            }
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Semestre máximo">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {
              experience?.maximumAudienceSemester
                ? `${experience?.maximumAudienceSemester} semestre`
                : "No aplica"
            }
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={2}>
          <Divider />
        </Descriptions.Item>
        <Descriptions.Item label="Perfil del proyecto">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {
              experience?.projectProfiles !== []
                ? "No aplica"
                : experience?.projectProfiles.split(", ")
            }
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Input">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {experience?.input}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={2}>
          <Divider />
        </Descriptions.Item>
        <Descriptions.Item label="Output">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {experience?.output}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Call to action">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {experience?.callToAction}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={2}>
          <Divider />
        </Descriptions.Item>
        <Descriptions.Item label="Curricularidad">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {experience?.curriculumType}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Clave UF">
          <Typography.Text style={{color: "#8C8C8C"}}>
            {experience?.ufCode}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item span={2}>
          <Divider />
        </Descriptions.Item>
        <Descriptions.Item label="Campus" span={2}>
          <Typography.Text style={{color: "#8C8C8C"}}>
            {experience?.campus}
          </Typography.Text>
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};
