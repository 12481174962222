import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  GeneralInformationIndex
} from "../../../pages/expert/onboarding/general-information";
import { ExperienceIndex } from "../../../pages/expert/onboarding/experience";
import { AddServiceIndex } from "../../../pages/expert/onboarding/add-service";
import { SuccessIndex } from "../../../pages/expert/onboarding/success";

export const ExpertOnboardingRoutes = <Switch>
  <Route
    path="/v2/expert/onboarding/general-information"
    component={GeneralInformationIndex} />
  <Route
    path="/v2/expert/onboarding/experience"
    component={ExperienceIndex} />
  <Route
    path="/v2/expert/onboarding/add-service"
    component={AddServiceIndex} />
  <Route
    path="/v2/expert/onboarding/success"
    component={SuccessIndex} />
</Switch>;
