import React, { useState, useCallback, useContext, useEffect } from "react";
import { List } from "antd";
import { withLoader } from "../../../../helpers";
import { BatchContentGroupItem } from "./item";
import "./styles.sass";
import {
  CurrentUserContext,
  DraggableListContext
} from "../../../../contexts/shared";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function BatchContentGroupList({
  refetch,
  isLoading,
  odeContents,
  data: contentGroups,
  isDragable = true,
  emptyText = "Sin contenido",
  ...props
}) {
  const [sections, setSections] = useState(contentGroups);
  const { moveItemPosition, isMovingGroups } = useContext(DraggableListContext);
  const { currentUser } = useContext(CurrentUserContext);
  const hasPermissionDragging = ["ENTREPRENEUR", "ADVISER"]
    .includes(currentUser.systemRole);

  const moveItem = useCallback(({ destination, source })  => {
    if(!destination || !source || destination.index === source.index) return;
    moveItemPosition
      && moveItemPosition(
        sections, setSections, source.index, destination.index
    );
  }, [sections]);

  useEffect(() => {
    if (isMovingGroups) return;
    setSections(contentGroups);
  }, [contentGroups]);

  return (
    <DragDropContext onDragEnd={moveItem}>
      <Droppable droppableId="sections">
        {droppableProvided =>
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}>
            <List
              {...props}
              loading={isLoading}
              locale={{ emptyText }}
              dataSource={sections || []}
              className="content-group-list"
              grid={{ gutter: 10, column: 1 }}
              renderItem={(contentGroup, index) =>
                <Draggable
                  index={index}
                  key={contentGroup.id}
                  isDragDisabled={!isDragable}
                  draggableId={contentGroup.id}>
                  {draggableProvided =>
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}>
                      <List.Item key={contentGroup.id}>
                        <BatchContentGroupItem
                          refetch={refetch}
                          odeContents={odeContents}
                          contentGroup={contentGroup}
                          isDragable={isDragable && !hasPermissionDragging}
                          dragHandleProps={draggableProvided.dragHandleProps} />
                      </List.Item>
                    </div>
                  }
                </Draggable>
              } />
            {droppableProvided.placeholder}
          </div>
        }
      </Droppable>
    </DragDropContext>
  );
}

export default withLoader(BatchContentGroupList);