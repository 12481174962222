import { getReadableValue } from "../../../../../helpers/select-options";
import {
  productStageOptions
} from "../../../../shared/company-forms/general/select-options/product-stage";

export function productStageToChart (items = []) {
  const labels = [];
  const datasets = [];

  items.forEach((item, idx) => {
    const rawLabel = item["Company.companyProductStage"];
    const readableLabel = getReadableValue(
      productStageOptions,
      rawLabel,
      rawLabel
    );

    labels.push(readableLabel);

    const data = new Array(items.length);
    data.fill(0, 0, items.length - 1);
    data[idx] = parseInt(item["Company.companiesCount"]);

    datasets.push({
      label: readableLabel,
      data,
      backgroundColor: COLORS[idx],
      barThickness: 20
    });
  });

  return {
    labels,
    datasets
  };
}

const COLORS = [
  "#FEB7D6",
  "#FDDCDC",
  "#D7B7F6",
  "#EEE3F9",
  "#B7CCFE",
  "#EBF1FE"
];
