import gql from "graphql-tag";

export const disconnectStatusOdeFromBatch = gql`
  mutation DisconnectStatusOdeFromBatch(
    $companyId: ID!,
    $odeStatusFromBatchIds: [ID!]
  ) {
    updateODE(
      where: { id: $companyId }
      data: {
        odeStatusBatch: {
          deleteMany: { id_in: $odeStatusFromBatchIds }
        }
      }
    ) {
      id
    }
  }
`;
