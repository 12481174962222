export const statesWithCitysOptions = [
  {
    label: "Aguascalientes",
    value: "ST_AGUASCALIENTES",
    children: [
      { label: 'Aguascalientes', value: 'CT_AGUASCALIENTES' },
      { label: 'Asientos', value: 'CT_ASIENTOS' },
      { label: 'Calvillo', value: 'CT_CALVILLO' },
      { label: 'Cosío', value: 'CT_COSIO' },
      { label: 'Jesús María', value: 'CT_JESUS_MARIA' },
      { label: 'Pabellón de Arteaga', value: 'CT_PABELLON_DE_ARTEAGA' },
      { label: 'Rincón de Romos', value: 'CT_RINCON_DE_ROMOS' },
      { label: 'San José de Gracia', value: 'CT_SAN_JOSE_DE_GRACIA' },
      { label: 'Tepezalá', value: 'CT_TEPEZALA' },
      { label: 'El Llano', value: 'CT_EL_LLANO' },
      { label: 'San Francisco de los Romo', value: 'CT_SAN_FRANCISCO_DE_LOS_ROMO' }
    ]
  },
  {
    label: "Baja California",
    value: "ST_BAJA_CALIFORNIA",
    children: [
      { label: 'Ensenada', value: 'CT_ENSENADA' },
      { label: 'Mexicali', value: 'CT_MEXICALI' },
      { label: 'Tecate', value: 'CT_TECATE' },
      { label: 'Tijuana', value: 'CT_TIJUANA' },
      { label: 'Playas de Rosarito', value: 'CT_PLAYAS_DE_ROSARITO' }
    ]
  },
  {
    label: "Baja California Sur",
    value: "ST_BAJA_CALIFORNIA_SUR",
    children: [
      { label: 'Comondú', value: 'CT_COMONDU' },
      { label: 'Mulegé', value: 'CT_MULEGE' },
      { label: 'La Paz', value: 'CT_LA_PAZ' },
      { label: 'Los Cabos', value: 'CT_LOS_CABOS' },
      { label: 'Loreto', value: 'CT_LORETO' }
    ]
  },
  {
    label: "Campeche",
    value: "ST_CAMPECHE",
    children: [
      { label: 'Calkiní', value: 'CT_CALKINI' },
      { label: 'Campeche', value: 'CT_CAMPECHE' },
      { label: 'Carmen', value: 'CT_CARMEN' },
      { label: 'Champotón', value: 'CT_CHAMPOTON' },
      { label: 'Hecelchakán', value: 'CT_HECELCHAKAN' },
      { label: 'Hopelchén', value: 'CT_HOPELCHEN' },
      { label: 'Palizada', value: 'CT_PALIZADA' },
      { label: 'Tenabo', value: 'CT_TENABO' },
      { label: 'Escárcega', value: 'CT_ESCARCEGA' },
      { label: 'Calakmul', value: 'CT_CALAKMUL' },
      { label: 'Candelaria', value: 'CT_CANDELARIA' }
    ]
  },
  {
    label: "Coahuila de Zaragoza",
    value: "ST_COAHUILA_DE_ZARAGOZA",
    children: [
      { label: 'Abasolo', value: 'CT_ABASOLO' },
      { label: 'Acuña', value: 'CT_ACUNA' },
      { label: 'Allende', value: 'CT_ALLENDE' },
      { label: 'Arteaga', value: 'CT_ARTEAGA' },
      { label: 'Candela', value: 'CT_CANDELA' },
      { label: 'Castaños', value: 'CT_CASTANOS' },
      { label: 'Cuatro Ciénegas', value: 'CT_CUATRO_CIENEGAS' },
      { label: 'Escobedo', value: 'CT_ESCOBEDO' },
      { label: 'Francisco I. Madero', value: 'CT_FRANCISCO_I._MADERO' },
      { label: 'Frontera', value: 'CT_FRONTERA' },
      { label: 'General Cepeda', value: 'CT_GENERAL_CEPEDA' },
      { label: 'Guerrero', value: 'CT_GUERRERO' },
      { label: 'Hidalgo', value: 'CT_HIDALGO' },
      { label: 'Jiménez', value: 'CT_JIMENEZ' },
      { label: 'Juárez', value: 'CT_JUAREZ' },
      { label: 'Lamadrid', value: 'CT_LAMADRID' },
      { label: 'Matamoros', value: 'CT_MATAMOROS' },
      { label: 'Monclova', value: 'CT_MONCLOVA' },
      { label: 'Morelos', value: 'CT_MORELOS' },
      { label: 'Múzquiz', value: 'CT_MUZQUIZ' },
      { label: 'Nadadores', value: 'CT_NADADORES' },
      { label: 'Nava', value: 'CT_NAVA' },
      { label: 'Ocampo', value: 'CT_OCAMPO' },
      { label: 'Parras', value: 'CT_PARRAS' },
      { label: 'Piedras Negras', value: 'CT_PIEDRAS_NEGRAS' },
      { label: 'Progreso', value: 'CT_PROGRESO' },
      { label: 'Ramos Arizpe', value: 'CT_RAMOS_ARIZPE' },
      { label: 'Sabinas', value: 'CT_SABINAS' },
      { label: 'Sacramento', value: 'CT_SACRAMENTO' },
      { label: 'Saltillo', value: 'CT_SALTILLO' },
      { label: 'San Buenaventura', value: 'CT_SAN_BUENAVENTURA' },
      { label: 'San Juan de Sabinas', value: 'CT_SAN_JUAN_DE_SABINAS' },
      { label: 'San Pedro', value: 'CT_SAN_PEDRO' },
      { label: 'Sierra Mojada', value: 'CT_SIERRA_MOJADA' },
      { label: 'Torreón', value: 'CT_TORREON' },
      { label: 'Viesca', value: 'CT_VIESCA' },
      { label: 'Villa Unión', value: 'CT_VILLA_UNION' },
      { label: 'Zaragoza', value: 'CT_ZARAGOZA' }
    ]
  },
  {
    label: "Colima",
    value: "ST_COLIMA",
    children: [
      { label: 'Armería', value: 'CT_ARMERIA' },
      { label: 'Colima', value: 'CT_COLIMA' },
      { label: 'Comala', value: 'CT_COMALA' },
      { label: 'Coquimatlán', value: 'CT_COQUIMATLAN' },
      { label: 'Cuauhtémoc', value: 'CT_CUAUHTEMOC' },
      { label: 'Ixtlahuacán', value: 'CT_IXTLAHUACAN' },
      { label: 'Manzanillo', value: 'CT_MANZANILLO' },
      { label: 'Minatitlán', value: 'CT_MINATITLAN' },
      { label: 'Tecomán', value: 'CT_TECOMAN' },
      { label: 'Villa de Álvarez', value: 'CT_VILLA_DE_ALVAREZ' }
    ]
  },
  {
    label: "Chiapas",
    value: "ST_CHIAPAS",
    children: [
      { label: 'Acacoyagua', value: 'CT_ACACOYAGUA' },
      { label: 'Acala', value: 'CT_ACALA' },
      { label: 'Acapetahua', value: 'CT_ACAPETAHUA' },
      { label: 'Altamirano', value: 'CT_ALTAMIRANO' },
      { label: 'Amatán', value: 'CT_AMATAN' },
      { label: 'Amatenango de la Frontera', value: 'CT_AMATENANGO_DE_LA_FRONTERA' },
      { label: 'Amatenango del Valle', value: 'CT_AMATENANGO_DEL_VALLE' },
      { label: 'Angel Albino Corzo', value: 'CT_ANGEL_ALBINO_CORZO' },
      { label: 'Arriaga', value: 'CT_ARRIAGA' },
      { label: 'Bejucal de Ocampo', value: 'CT_BEJUCAL_DE_OCAMPO' },
      { label: 'Bella Vista', value: 'CT_BELLA_VISTA' },
      { label: 'Berriozábal', value: 'CT_BERRIOZABAL' },
      { label: 'Bochil', value: 'CT_BOCHIL' },
      { label: 'El Bosque', value: 'CT_EL_BOSQUE' },
      { label: 'Cacahoatán', value: 'CT_CACAHOATAN' },
      { label: 'Catazajá', value: 'CT_CATAZAJA' },
      { label: 'Cintalapa', value: 'CT_CINTALAPA' },
      { label: 'Coapilla', value: 'CT_COAPILLA' },
      { label: 'Comitán de Domínguez', value: 'CT_COMITAN_DE_DOMINGUEZ' },
      { label: 'La Concordia', value: 'CT_LA_CONCORDIA' },
      { label: 'Copainalá', value: 'CT_COPAINALA' },
      { label: 'Chalchihuitán', value: 'CT_CHALCHIHUITAN' },
      { label: 'Chamula', value: 'CT_CHAMULA' },
      { label: 'Chanal', value: 'CT_CHANAL' },
      { label: 'Chapultenango', value: 'CT_CHAPULTENANGO' },
      { label: 'Chenalhó', value: 'CT_CHENALHO' },
      { label: 'Chiapa de Corzo', value: 'CT_CHIAPA_DE_CORZO' },
      { label: 'Chiapilla', value: 'CT_CHIAPILLA' },
      { label: 'Chicoasén', value: 'CT_CHICOASEN' },
      { label: 'Chicomuselo', value: 'CT_CHICOMUSELO' },
      { label: 'Chilón', value: 'CT_CHILON' },
      { label: 'Escuintla', value: 'CT_ESCUINTLA' },
      {
        label: 'Francisco León',
        value: 'CT_FRANCISCO_LEON'
      },
      {
        label: 'Frontera Comalapa',
        value: 'CT_FRONTERA_COMALAPA'
      },
      {
        label: 'Frontera Hidalgo',
        value: 'CT_FRONTERA_HIDALGO'
      },
      { label: 'La Grandeza', value: 'CT_LA_GRANDEZA' },
      { label: 'Huehuetán', value: 'CT_HUEHUETAN' },
      { label: 'Huixtán', value: 'CT_HUIXTAN' },
      { label: 'Huitiupán', value: 'CT_HUITIUPAN' },
      { label: 'Huixtla', value: 'CT_HUIXTLA' },
      {
        label: 'La Independencia',
        value: 'CT_LA_INDEPENDENCIA'
      },
      { label: 'Ixhuatán', value: 'CT_IXHUATAN' },
      { label: 'Ixtacomitán', value: 'CT_IXTACOMITAN' },
      { label: 'Ixtapa', value: 'CT_IXTAPA' },
      {
        label: 'Ixtapangajoya',
        value: 'CT_IXTAPANGAJOYA'
      },
      { label: 'Jiquipilas', value: 'CT_JIQUIPILAS' },
      { label: 'Jitotol', value: 'CT_JITOTOL' },
      { label: 'Juárez', value: 'CT_JUAREZ' },
      { label: 'Larráinzar', value: 'CT_LARRAINZAR' },
      { label: 'La Libertad', value: 'CT_LA_LIBERTAD' },
      { label: 'Mapastepec', value: 'CT_MAPASTEPEC' },
      {
        label: 'Las Margaritas',
        value: 'CT_LAS_MARGARITAS'
      },
      {
        label: 'Mazapa de Madero',
        value: 'CT_MAZAPA_DE_MADERO'
      },
      { label: 'Mazatán', value: 'CT_MAZATAN' },
      { label: 'Metapa', value: 'CT_METAPA' },
      { label: 'Mitontic', value: 'CT_MITONTIC' },
      { label: 'Motozintla', value: 'CT_MOTOZINTLA' },
      {
        label: 'Nicolás Ruíz',
        value: 'CT_NICOLAS_RUIZ'
      },
      { label: 'Ocosingo', value: 'CT_OCOSINGO' },
      { label: 'Ocotepec', value: 'CT_OCOTEPEC' },
      {
        label: 'Ocozocoautla de Espinosa',
        value: 'CT_OCOZOCOAUTLA_DE_ESPINOSA'
      },
      { label: 'Ostuacán', value: 'CT_OSTUACAN' },
      { label: 'Osumacinta', value: 'CT_OSUMACINTA' },
      { label: 'Oxchuc', value: 'CT_OXCHUC' },
      { label: 'Palenque', value: 'CT_PALENQUE' },
      { label: 'Pantelhó', value: 'CT_PANTELHO' },
      { label: 'Pantepec', value: 'CT_PANTEPEC' },
      { label: 'Pichucalco', value: 'CT_PICHUCALCO' },
      { label: 'Pijijiapan', value: 'CT_PIJIJIAPAN' },
      { label: 'El Porvenir', value: 'CT_EL_PORVENIR' },
      {
        label: 'Villa Comaltitlán',
        value: 'CT_VILLA_COMALTITLAN'
      },
      {
        label: 'Pueblo Nuevo Solistahuacán',
        value: 'CT_PUEBLO_NUEVO_SOLISTAHUACAN'
      },
      { label: 'Rayón', value: 'CT_RAYON' },
      { label: 'Reforma', value: 'CT_REFORMA' },
      { label: 'Las Rosas', value: 'CT_LAS_ROSAS' },
      { label: 'Sabanilla', value: 'CT_SABANILLA' },
      {
        label: 'Salto de Agua',
        value: 'CT_SALTO_DE_AGUA'
      },
      {
        label: 'San Cristóbal de las Casas',
        value: 'CT_SAN_CRISTOBAL_DE_LAS_CASAS'
      },
      {
        label: 'San Fernando',
        value: 'CT_SAN_FERNANDO'
      },
      { label: 'Siltepec', value: 'CT_SILTEPEC' },
      { label: 'Simojovel', value: 'CT_SIMOJOVEL' },
      { label: 'Sitalá', value: 'CT_SITALA' },
      {
        label: 'Socoltenango',
        value: 'CT_SOCOLTENANGO'
      },
      {
        label: 'Solosuchiapa',
        value: 'CT_SOLOSUCHIAPA'
      },
      { label: 'Soyaló', value: 'CT_SOYALO' },
      { label: 'Suchiapa', value: 'CT_SUCHIAPA' },
      { label: 'Suchiate', value: 'CT_SUCHIATE' },
      { label: 'Sunuapa', value: 'CT_SUNUAPA' },
      { label: 'Tapachula', value: 'CT_TAPACHULA' },
      { label: 'Tapalapa', value: 'CT_TAPALAPA' },
      { label: 'Tapilula', value: 'CT_TAPILULA' },
      { label: 'Tecpatán', value: 'CT_TECPATAN' },
      { label: 'Tenejapa', value: 'CT_TENEJAPA' },
      { label: 'Teopisca', value: 'CT_TEOPISCA' },
      { label: 'Tila', value: 'CT_TILA' },
      { label: 'Tonalá', value: 'CT_TONALA' },
      { label: 'Totolapa', value: 'CT_TOTOLAPA' },
      {
        label: 'La Trinitaria',
        value: 'CT_LA_TRINITARIA'
      },
      { label: 'Tumbalá', value: 'CT_TUMBALA' },
      {
        label: 'Tuxtla Gutiérrez',
        value: 'CT_TUXTLA_GUTIERREZ'
      },
      {
        label: 'Tuxtla Chico',
        value: 'CT_TUXTLA_CHICO'
      },
      { label: 'Tuzantán', value: 'CT_TUZANTAN' },
      { label: 'Tzimol', value: 'CT_TZIMOL' },
      {
        label: 'Unión Juárez',
        value: 'CT_UNION_JUAREZ'
      },
      {
        label: 'Venustiano Carranza',
        value: 'CT_VENUSTIANO_CARRANZA'
      },
      { label: 'Villa Corzo', value: 'CT_VILLA_CORZO' },
      { label: 'Villaflores', value: 'CT_VILLAFLORES' },
      { label: 'Yajalón', value: 'CT_YAJALON' },
      { label: 'San Lucas', value: 'CT_SAN_LUCAS' },
      { label: 'Zinacantán', value: 'CT_ZINACANTAN' },
      {
        label: 'San Juan Cancuc',
        value: 'CT_SAN_JUAN_CANCUC'
      },
      { label: 'Aldama', value: 'CT_ALDAMA' },
      {
        label: 'Benemérito de las Américas',
        value: 'CT_BENEMERITO_DE_LAS_AMERICAS'
      },
      {
        label: 'Maravilla Tenejapa',
        value: 'CT_MARAVILLA_TENEJAPA'
      },
      {
        label: 'Marqués de Comillas',
        value: 'CT_MARQUES_DE_COMILLAS'
      },
      {
        label: 'Montecristo de Guerrero',
        value: 'CT_MONTECRISTO_DE_GUERRERO'
      },
      {
        label: 'San Andrés Duraznal',
        value: 'CT_SAN_ANDRES_DURAZNAL'
      },
      {
        label: 'Santiago el Pinar',
        value: 'CT_SANTIAGO_EL_PINAR'
      },
      {
        label: 'Capitán Luis Ángel Vidal',
        value: 'CT_CAPITAN_LUIS_ANGEL_VIDAL'
      },
      {
        label: 'Rincón Chamula San Pedro',
        value: 'CT_RINCON_CHAMULA_SAN_PEDRO'
      },
      { label: 'El Parral', value: 'CT_EL_PARRAL' },
      {
        label: 'Emiliano Zapata',
        value: 'CT_EMILIANO_ZAPATA'
      },
      { label: 'Mezcalapa', value: 'CT_MEZCALAPA' }
    ]
  },
  {
    label: "Chihuahua",
    value: "ST_CHIHUAHUA",
    children: [
      { label: 'Ahumada', value: 'CT_AHUMADA' },
      { label: 'Aldama', value: 'CT_ALDAMA' },
      { label: 'Allende', value: 'CT_ALLENDE' },
      {
        label: 'Aquiles Serdán',
        value: 'CT_AQUILES_SERDAN'
      },
      { label: 'Ascensión', value: 'CT_ASCENSION' },
      { label: 'Bachíniva', value: 'CT_BACHINIVA' },
      { label: 'Balleza', value: 'CT_BALLEZA' },
      {
        label: 'Batopilas de Manuel Gómez Morín',
        value: 'CT_BATOPILAS_DE_MANUEL_GOMEZ_MORIN'
      },
      { label: 'Bocoyna', value: 'CT_BOCOYNA' },
      {
        label: 'Buenaventura',
        value: 'CT_BUENAVENTURA'
      },
      { label: 'Camargo', value: 'CT_CAMARGO' },
      { label: 'Carichí', value: 'CT_CARICHI' },
      {
        label: 'Casas Grandes',
        value: 'CT_CASAS_GRANDES'
      },
      { label: 'Coronado', value: 'CT_CORONADO' },
      {
        label: 'Coyame del Sotol',
        value: 'CT_COYAME_DEL_SOTOL'
      },
      { label: 'La Cruz', value: 'CT_LA_CRUZ' },
      { label: 'Cuauhtémoc', value: 'CT_CUAUHTEMOC' },
      {
        label: 'Cusihuiriachi',
        value: 'CT_CUSIHUIRIACHI'
      },
      { label: 'Chihuahua', value: 'CT_CHIHUAHUA' },
      { label: 'Chínipas', value: 'CT_CHINIPAS' },
      { label: 'Delicias', value: 'CT_DELICIAS' },
      {
        label: 'Dr. Belisario Domínguez',
        value: 'CT_DR._BELISARIO_DOMINGUEZ'
      },
      { label: 'Galeana', value: 'CT_GALEANA' },
      {
        label: 'Santa Isabel',
        value: 'CT_SANTA_ISABEL'
      },
      {
        label: 'Gómez Farías',
        value: 'CT_GOMEZ_FARIAS'
      },
      {
        label: 'Gran Morelos',
        value: 'CT_GRAN_MORELOS'
      },
      { label: 'Guachochi', value: 'CT_GUACHOCHI' },
      { label: 'Guadalupe', value: 'CT_GUADALUPE' },
      {
        label: 'Guadalupe y Calvo',
        value: 'CT_GUADALUPE_Y_CALVO'
      },
      { label: 'Guazapares', value: 'CT_GUAZAPARES' },
      { label: 'Guerrero', value: 'CT_GUERRERO' },
      {
        label: 'Hidalgo del Parral',
        value: 'CT_HIDALGO_DEL_PARRAL'
      },
      { label: 'Huejotitán', value: 'CT_HUEJOTITAN' },
      {
        label: 'Ignacio Zaragoza',
        value: 'CT_IGNACIO_ZARAGOZA'
      },
      { label: 'Janos', value: 'CT_JANOS' },
      { label: 'Jiménez', value: 'CT_JIMENEZ' },
      { label: 'Juárez', value: 'CT_JUAREZ' },
      { label: 'Julimes', value: 'CT_JULIMES' },
      { label: 'López', value: 'CT_LOPEZ' },
      { label: 'Madera', value: 'CT_MADERA' },
      { label: 'Maguarichi', value: 'CT_MAGUARICHI' },
      {
        label: 'Manuel Benavides',
        value: 'CT_MANUEL_BENAVIDES'
      },
      { label: 'Matachí', value: 'CT_MATACHI' },
      { label: 'Matamoros', value: 'CT_MATAMOROS' },
      { label: 'Meoqui', value: 'CT_MEOQUI' },
      { label: 'Morelos', value: 'CT_MORELOS' },
      { label: 'Moris', value: 'CT_MORIS' },
      { label: 'Namiquipa', value: 'CT_NAMIQUIPA' },
      { label: 'Nonoava', value: 'CT_NONOAVA' },
      {
        label: 'Nuevo Casas Grandes',
        value: 'CT_NUEVO_CASAS_GRANDES'
      },
      { label: 'Ocampo', value: 'CT_OCAMPO' },
      { label: 'Ojinaga', value: 'CT_OJINAGA' },
      {
        label: 'Praxedis G. Guerrero',
        value: 'CT_PRAXEDIS_G._GUERRERO'
      },
      {
        label: 'Riva Palacio',
        value: 'CT_RIVA_PALACIO'
      },
      { label: 'Rosales', value: 'CT_ROSALES' },
      { label: 'Rosario', value: 'CT_ROSARIO' },
      {
        label: 'San Francisco de Borja',
        value: 'CT_SAN_FRANCISCO_DE_BORJA'
      },
      {
        label: 'San Francisco de Conchos',
        value: 'CT_SAN_FRANCISCO_DE_CONCHOS'
      },
      {
        label: 'San Francisco del Oro',
        value: 'CT_SAN_FRANCISCO_DEL_ORO'
      },
      {
        label: 'Santa Bárbara',
        value: 'CT_SANTA_BARBARA'
      },
      { label: 'Satevó', value: 'CT_SATEVO' },
      { label: 'Saucillo', value: 'CT_SAUCILLO' },
      { label: 'Temósachic', value: 'CT_TEMOSACHIC' },
      { label: 'El Tule', value: 'CT_EL_TULE' },
      { label: 'Urique', value: 'CT_URIQUE' },
      { label: 'Uruachi', value: 'CT_URUACHI' },
      {
        label: 'Valle de Zaragoza',
        value: 'CT_VALLE_DE_ZARAGOZA'
      }
    ]
  },
  {
    label: "Ciudad de México",
    value: "ST_CIUDAD_DE_MEXICO",
    children: [
      {
        label: 'Azcapotzalco',
        value: 'CT_AZCAPOTZALCO'
      },
      { label: 'Coyoacán', value: 'CT_COYOACAN' },
      {
        label: 'Cuajimalpa de Morelos',
        value: 'CT_CUAJIMALPA_DE_MORELOS'
      },
      {
        label: 'Gustavo A. Madero',
        value: 'CT_GUSTAVO_A._MADERO'
      },
      { label: 'Iztacalco', value: 'CT_IZTACALCO' },
      { label: 'Iztapalapa', value: 'CT_IZTAPALAPA' },
      {
        label: 'La Magdalena Contreras',
        value: 'CT_LA_MAGDALENA_CONTRERAS'
      },
      { label: 'Milpa Alta', value: 'CT_MILPA_ALTA' },
      {
        label: 'Álvaro Obregón',
        value: 'CT_ALVARO_OBREGON'
      },
      { label: 'Tláhuac', value: 'CT_TLAHUAC' },
      { label: 'Tlalpan', value: 'CT_TLALPAN' },
      { label: 'Xochimilco', value: 'CT_XOCHIMILCO' },
      {
        label: 'Benito Juárez',
        value: 'CT_BENITO_JUAREZ'
      },
      { label: 'Cuauhtémoc', value: 'CT_CUAUHTEMOC' },
      {
        label: 'Miguel Hidalgo',
        value: 'CT_MIGUEL_HIDALGO'
      },
      {
        label: 'Venustiano Carranza',
        value: 'CT_VENUSTIANO_CARRANZA'
      }
    ]
  },
  {
    label: "Durango",
    value: "ST_DURANGO",
    children: [
      { label: 'Canatlán', value: 'CT_CANATLAN' },
      { label: 'Canelas', value: 'CT_CANELAS' },
      {
        label: 'Coneto de Comonfort',
        value: 'CT_CONETO_DE_COMONFORT'
      },
      { label: 'Cuencamé', value: 'CT_CUENCAME' },
      { label: 'Durango', value: 'CT_DURANGO' },
      {
        label: 'General Simón Bolívar',
        value: 'CT_GENERAL_SIMON_BOLIVAR'
      },
      {
        label: 'Gómez Palacio',
        value: 'CT_GOMEZ_PALACIO'
      },
      {
        label: 'Guadalupe Victoria',
        value: 'CT_GUADALUPE_VICTORIA'
      },
      { label: 'Guanaceví', value: 'CT_GUANACEVI' },
      { label: 'Hidalgo', value: 'CT_HIDALGO' },
      { label: 'Indé', value: 'CT_INDE' },
      { label: 'Lerdo', value: 'CT_LERDO' },
      { label: 'Mapimí', value: 'CT_MAPIMI' },
      { label: 'Mezquital', value: 'CT_MEZQUITAL' },
      { label: 'Nazas', value: 'CT_NAZAS' },
      {
        label: 'Nombre de Dios',
        value: 'CT_NOMBRE_DE_DIOS'
      },
      { label: 'Ocampo', value: 'CT_OCAMPO' },
      { label: 'El Oro', value: 'CT_EL_ORO' },
      { label: 'Otáez', value: 'CT_OTAEZ' },
      {
        label: 'Pánuco de Coronado',
        value: 'CT_PANUCO_DE_CORONADO'
      },
      {
        label: 'Peñón Blanco',
        value: 'CT_PENON_BLANCO'
      },
      { label: 'Poanas', value: 'CT_POANAS' },
      {
        label: 'Pueblo Nuevo',
        value: 'CT_PUEBLO_NUEVO'
      },
      { label: 'Rodeo', value: 'CT_RODEO' },
      {
        label: 'San Bernardo',
        value: 'CT_SAN_BERNARDO'
      },
      { label: 'San Dimas', value: 'CT_SAN_DIMAS' },
      {
        label: 'San Juan de Guadalupe',
        value: 'CT_SAN_JUAN_DE_GUADALUPE'
      },
      {
        label: 'San Juan del Río',
        value: 'CT_SAN_JUAN_DEL_RIO'
      },
      {
        label: 'San Luis del Cordero',
        value: 'CT_SAN_LUIS_DEL_CORDERO'
      },
      {
        label: 'San Pedro del Gallo',
        value: 'CT_SAN_PEDRO_DEL_GALLO'
      },
      { label: 'Santa Clara', value: 'CT_SANTA_CLARA' },
      {
        label: 'Santiago Papasquiaro',
        value: 'CT_SANTIAGO_PAPASQUIARO'
      },
      { label: 'Súchil', value: 'CT_SUCHIL' },
      { label: 'Tamazula', value: 'CT_TAMAZULA' },
      { label: 'Tepehuanes', value: 'CT_TEPEHUANES' },
      { label: 'Tlahualilo', value: 'CT_TLAHUALILO' },
      { label: 'Topia', value: 'CT_TOPIA' },
      {
        label: 'Vicente Guerrero',
        value: 'CT_VICENTE_GUERRERO'
      },
      { label: 'Nuevo Ideal', value: 'CT_NUEVO_IDEAL' }
    ]
  },
  {
    label: "Guanajuato",
    value: "ST_GUANAJUATO",
    children: [
      { label: 'Abasolo', value: 'CT_ABASOLO' },
      { label: 'Acámbaro', value: 'CT_ACAMBARO' },
      {
        label: 'San Miguel de Allende',
        value: 'CT_SAN_MIGUEL_DE_ALLENDE'
      },
      {
        label: 'Apaseo el Alto',
        value: 'CT_APASEO_EL_ALTO'
      },
      {
        label: 'Apaseo el Grande',
        value: 'CT_APASEO_EL_GRANDE'
      },
      { label: 'Atarjea', value: 'CT_ATARJEA' },
      { label: 'Celaya', value: 'CT_CELAYA' },
      {
        label: 'Manuel Doblado',
        value: 'CT_MANUEL_DOBLADO'
      },
      { label: 'Comonfort', value: 'CT_COMONFORT' },
      { label: 'Coroneo', value: 'CT_CORONEO' },
      { label: 'Cortazar', value: 'CT_CORTAZAR' },
      { label: 'Cuerámaro', value: 'CT_CUERAMARO' },
      { label: 'Doctor Mora', value: 'CT_DOCTOR_MORA' },
      {
        label: 'Dolores Hidalgo Cuna de la Independencia Nacional',
        value: 'CT_DOLORES_HIDALGO_CUNA_DE_LA_INDEPENDENCIA_NACIONAL'
      },
      { label: 'Guanajuato', value: 'CT_GUANAJUATO' },
      { label: 'Huanímaro', value: 'CT_HUANIMARO' },
      { label: 'Irapuato', value: 'CT_IRAPUATO' },
      {
        label: 'Jaral del Progreso',
        value: 'CT_JARAL_DEL_PROGRESO'
      },
      { label: 'Jerécuaro', value: 'CT_JERECUARO' },
      { label: 'León', value: 'CT_LEON' },
      { label: 'Moroleón', value: 'CT_MOROLEON' },
      { label: 'Ocampo', value: 'CT_OCAMPO' },
      { label: 'Pénjamo', value: 'CT_PENJAMO' },
      {
        label: 'Pueblo Nuevo',
        value: 'CT_PUEBLO_NUEVO'
      },
      {
        label: 'Purísima del Rincón',
        value: 'CT_PURISIMA_DEL_RINCON'
      },
      { label: 'Romita', value: 'CT_ROMITA' },
      { label: 'Salamanca', value: 'CT_SALAMANCA' },
      { label: 'Salvatierra', value: 'CT_SALVATIERRA' },
      {
        label: 'San Diego de la Unión',
        value: 'CT_SAN_DIEGO_DE_LA_UNION'
      },
      { label: 'San Felipe', value: 'CT_SAN_FELIPE' },
      {
        label: 'San Francisco del Rincón',
        value: 'CT_SAN_FRANCISCO_DEL_RINCON'
      },
      {
        label: 'San José Iturbide',
        value: 'CT_SAN_JOSE_ITURBIDE'
      },
      {
        label: 'San Luis de la Paz',
        value: 'CT_SAN_LUIS_DE_LA_PAZ'
      },
      {
        label: 'Santa Catarina',
        value: 'CT_SANTA_CATARINA'
      },
      {
        label: 'Santa Cruz de Juventino Rosas',
        value: 'CT_SANTA_CRUZ_DE_JUVENTINO_ROSAS'
      },
      {
        label: 'Santiago Maravatío',
        value: 'CT_SANTIAGO_MARAVATIO'
      },
      {
        label: 'Silao de la Victoria',
        value: 'CT_SILAO_DE_LA_VICTORIA'
      },
      { label: 'Tarandacuao', value: 'CT_TARANDACUAO' },
      { label: 'Tarimoro', value: 'CT_TARIMORO' },
      {
        label: 'Tierra Blanca',
        value: 'CT_TIERRA_BLANCA'
      },
      { label: 'Uriangato', value: 'CT_URIANGATO' },
      {
        label: 'Valle de Santiago',
        value: 'CT_VALLE_DE_SANTIAGO'
      },
      { label: 'Victoria', value: 'CT_VICTORIA' },
      { label: 'Villagrán', value: 'CT_VILLAGRAN' },
      { label: 'Xichú', value: 'CT_XICHU' },
      { label: 'Yuriria', value: 'CT_YURIRIA' }
    ]
  },
  {
    label: "Guerrero",
    value: "ST_GUERRERO",
    children: [
      {
        label: 'Acapulco de Juárez',
        value: 'CT_ACAPULCO_DE_JUAREZ'
      },
      {
        label: 'Ahuacuotzingo',
        value: 'CT_AHUACUOTZINGO'
      },
      {
        label: 'Ajuchitlán del Progreso',
        value: 'CT_AJUCHITLAN_DEL_PROGRESO'
      },
      {
        label: 'Alcozauca de Guerrero',
        value: 'CT_ALCOZAUCA_DE_GUERRERO'
      },
      { label: 'Alpoyeca', value: 'CT_ALPOYECA' },
      { label: 'Apaxtla', value: 'CT_APAXTLA' },
      { label: 'Arcelia', value: 'CT_ARCELIA' },
      {
        label: 'Atenango del Río',
        value: 'CT_ATENANGO_DEL_RIO'
      },
      {
        label: 'Atlamajalcingo del Monte',
        value: 'CT_ATLAMAJALCINGO_DEL_MONTE'
      },
      { label: 'Atlixtac', value: 'CT_ATLIXTAC' },
      {
        label: 'Atoyac de Álvarez',
        value: 'CT_ATOYAC_DE_ALVAREZ'
      },
      {
        label: 'Ayutla de los Libres',
        value: 'CT_AYUTLA_DE_LOS_LIBRES'
      },
      { label: 'Azoyú', value: 'CT_AZOYU' },
      {
        label: 'Benito Juárez',
        value: 'CT_BENITO_JUAREZ'
      },
      {
        label: 'Buenavista de Cuéllar',
        value: 'CT_BUENAVISTA_DE_CUELLAR'
      },
      {
        label: 'Coahuayutla de José María Izazaga',
        value: 'CT_COAHUAYUTLA_DE_JOSE_MARIA_IZAZAGA'
      },
      { label: 'Cocula', value: 'CT_COCULA' },
      { label: 'Copala', value: 'CT_COPALA' },
      { label: 'Copalillo', value: 'CT_COPALILLO' },
      { label: 'Copanatoyac', value: 'CT_COPANATOYAC' },
      {
        label: 'Coyuca de Benítez',
        value: 'CT_COYUCA_DE_BENITEZ'
      },
      {
        label: 'Coyuca de Catalán',
        value: 'CT_COYUCA_DE_CATALAN'
      },
      {
        label: 'Cuajinicuilapa',
        value: 'CT_CUAJINICUILAPA'
      },
      { label: 'Cualác', value: 'CT_CUALAC' },
      { label: 'Cuautepec', value: 'CT_CUAUTEPEC' },
      {
        label: 'Cuetzala del Progreso',
        value: 'CT_CUETZALA_DEL_PROGRESO'
      },
      {
        label: 'Cutzamala de Pinzón',
        value: 'CT_CUTZAMALA_DE_PINZON'
      },
      {
        label: 'Chilapa de Álvarez',
        value: 'CT_CHILAPA_DE_ALVAREZ'
      },
      {
        label: 'Chilpancingo de los Bravo',
        value: 'CT_CHILPANCINGO_DE_LOS_BRAVO'
      },
      {
        label: 'Florencio Villarreal',
        value: 'CT_FLORENCIO_VILLARREAL'
      },
      {
        label: 'General Canuto A. Neri',
        value: 'CT_GENERAL_CANUTO_A._NERI'
      },
      {
        label: 'General Heliodoro Castillo',
        value: 'CT_GENERAL_HELIODORO_CASTILLO'
      },
      {
        label: 'Huamuxtitlán',
        value: 'CT_HUAMUXTITLAN'
      },
      {
        label: 'Huitzuco de los Figueroa',
        value: 'CT_HUITZUCO_DE_LOS_FIGUEROA'
      },
      {
        label: 'Iguala de la Independencia',
        value: 'CT_IGUALA_DE_LA_INDEPENDENCIA'
      },
      { label: 'Igualapa', value: 'CT_IGUALAPA' },
      {
        label: 'Ixcateopan de Cuauhtémoc',
        value: 'CT_IXCATEOPAN_DE_CUAUHTEMOC'
      },
      {
        label: 'Zihuatanejo de Azueta',
        value: 'CT_ZIHUATANEJO_DE_AZUETA'
      },
      {
        label: 'Juan R. Escudero',
        value: 'CT_JUAN_R._ESCUDERO'
      },
      {
        label: 'Leonardo Bravo',
        value: 'CT_LEONARDO_BRAVO'
      },
      {
        label: 'Malinaltepec',
        value: 'CT_MALINALTEPEC'
      },
      {
        label: 'Mártir de Cuilapan',
        value: 'CT_MARTIR_DE_CUILAPAN'
      },
      { label: 'Metlatónoc', value: 'CT_METLATONOC' },
      { label: 'Mochitlán', value: 'CT_MOCHITLAN' },
      { label: 'Olinalá', value: 'CT_OLINALA' },
      { label: 'Ometepec', value: 'CT_OMETEPEC' },
      {
        label: 'Pedro Ascencio Alquisiras',
        value: 'CT_PEDRO_ASCENCIO_ALQUISIRAS'
      },
      { label: 'Petatlán', value: 'CT_PETATLAN' },
      { label: 'Pilcaya', value: 'CT_PILCAYA' },
      { label: 'Pungarabato', value: 'CT_PUNGARABATO' },
      {
        label: 'Quechultenango',
        value: 'CT_QUECHULTENANGO'
      },
      {
        label: 'San Luis Acatlán',
        value: 'CT_SAN_LUIS_ACATLAN'
      },
      { label: 'San Marcos', value: 'CT_SAN_MARCOS' },
      {
        label: 'San Miguel Totolapan',
        value: 'CT_SAN_MIGUEL_TOTOLAPAN'
      },
      {
        label: 'Taxco de Alarcón',
        value: 'CT_TAXCO_DE_ALARCON'
      },
      { label: 'Tecoanapa', value: 'CT_TECOANAPA' },
      {
        label: 'Técpan de Galeana',
        value: 'CT_TECPAN_DE_GALEANA'
      },
      { label: 'Teloloapan', value: 'CT_TELOLOAPAN' },
      {
        label: 'Tepecoacuilco de Trujano',
        value: 'CT_TEPECOACUILCO_DE_TRUJANO'
      },
      { label: 'Tetipac', value: 'CT_TETIPAC' },
      {
        label: 'Tixtla de Guerrero',
        value: 'CT_TIXTLA_DE_GUERRERO'
      },
      {
        label: 'Tlacoachistlahuaca',
        value: 'CT_TLACOACHISTLAHUACA'
      },
      { label: 'Tlacoapa', value: 'CT_TLACOAPA' },
      { label: 'Tlalchapa', value: 'CT_TLALCHAPA' },
      {
        label: 'Tlalixtaquilla de Maldonado',
        value: 'CT_TLALIXTAQUILLA_DE_MALDONADO'
      },
      {
        label: 'Tlapa de Comonfort',
        value: 'CT_TLAPA_DE_COMONFORT'
      },
      { label: 'Tlapehuala', value: 'CT_TLAPEHUALA' },
      {
        label: 'La Unión de Isidoro Montes de Oca',
        value: 'CT_LA_UNION_DE_ISIDORO_MONTES_DE_OCA'
      },
      {
        label: 'Xalpatláhuac',
        value: 'CT_XALPATLAHUAC'
      },
      {
        label: 'Xochihuehuetlán',
        value: 'CT_XOCHIHUEHUETLAN'
      },
      {
        label: 'Xochistlahuaca',
        value: 'CT_XOCHISTLAHUACA'
      },
      {
        label: 'Zapotitlán Tablas',
        value: 'CT_ZAPOTITLAN_TABLAS'
      },
      { label: 'Zirándaro', value: 'CT_ZIRANDARO' },
      { label: 'Zitlala', value: 'CT_ZITLALA' },
      {
        label: 'Eduardo Neri',
        value: 'CT_EDUARDO_NERI'
      },
      { label: 'Acatepec', value: 'CT_ACATEPEC' },
      { label: 'Marquelia', value: 'CT_MARQUELIA' },
      {
        label: 'Cochoapa el Grande',
        value: 'CT_COCHOAPA_EL_GRANDE'
      },
      {
        label: 'José Joaquín de Herrera',
        value: 'CT_JOSE_JOAQUIN_DE_HERRERA'
      },
      { label: 'Juchitán', value: 'CT_JUCHITAN' },
      { label: 'Iliatenco', value: 'CT_ILIATENCO' }
    ]
  },
  {
    label: "Hidalgo",
    value: "ST_HIDALGO",
    children: [
      { label: 'Acatlán', value: 'CT_ACATLAN' },
      {
        label: 'Acaxochitlán',
        value: 'CT_ACAXOCHITLAN'
      },
      { label: 'Actopan', value: 'CT_ACTOPAN' },
      {
        label: 'Agua Blanca de Iturbide',
        value: 'CT_AGUA_BLANCA_DE_ITURBIDE'
      },
      { label: 'Ajacuba', value: 'CT_AJACUBA' },
      { label: 'Alfajayucan', value: 'CT_ALFAJAYUCAN' },
      { label: 'Almoloya', value: 'CT_ALMOLOYA' },
      { label: 'Apan', value: 'CT_APAN' },
      { label: 'El Arenal', value: 'CT_EL_ARENAL' },
      { label: 'Atitalaquia', value: 'CT_ATITALAQUIA' },
      { label: 'Atlapexco', value: 'CT_ATLAPEXCO' },
      {
        label: 'Atotonilco el Grande',
        value: 'CT_ATOTONILCO_EL_GRANDE'
      },
      {
        label: 'Atotonilco de Tula',
        value: 'CT_ATOTONILCO_DE_TULA'
      },
      { label: 'Calnali', value: 'CT_CALNALI' },
      { label: 'Cardonal', value: 'CT_CARDONAL' },
      {
        label: 'Cuautepec de Hinojosa',
        value: 'CT_CUAUTEPEC_DE_HINOJOSA'
      },
      { label: 'Chapantongo', value: 'CT_CHAPANTONGO' },
      {
        label: 'Chapulhuacán',
        value: 'CT_CHAPULHUACAN'
      },
      { label: 'Chilcuautla', value: 'CT_CHILCUAUTLA' },
      {
        label: 'Eloxochitlán',
        value: 'CT_ELOXOCHITLAN'
      },
      {
        label: 'Emiliano Zapata',
        value: 'CT_EMILIANO_ZAPATA'
      },
      { label: 'Epazoyucan', value: 'CT_EPAZOYUCAN' },
      {
        label: 'Francisco I. Madero',
        value: 'CT_FRANCISCO_I._MADERO'
      },
      {
        label: 'Huasca de Ocampo',
        value: 'CT_HUASCA_DE_OCAMPO'
      },
      { label: 'Huautla', value: 'CT_HUAUTLA' },
      { label: 'Huazalingo', value: 'CT_HUAZALINGO' },
      { label: 'Huehuetla', value: 'CT_HUEHUETLA' },
      {
        label: 'Huejutla de Reyes',
        value: 'CT_HUEJUTLA_DE_REYES'
      },
      { label: 'Huichapan', value: 'CT_HUICHAPAN' },
      { label: 'Ixmiquilpan', value: 'CT_IXMIQUILPAN' },
      {
        label: 'Jacala de Ledezma',
        value: 'CT_JACALA_DE_LEDEZMA'
      },
      { label: 'Jaltocán', value: 'CT_JALTOCAN' },
      {
        label: 'Juárez Hidalgo',
        value: 'CT_JUAREZ_HIDALGO'
      },
      { label: 'Lolotla', value: 'CT_LOLOTLA' },
      { label: 'Metepec', value: 'CT_METEPEC' },
      {
        label: 'San Agustín Metzquititlán',
        value: 'CT_SAN_AGUSTIN_METZQUITITLAN'
      },
      { label: 'Metztitlán', value: 'CT_METZTITLAN' },
      {
        label: 'Mineral del Chico',
        value: 'CT_MINERAL_DEL_CHICO'
      },
      {
        label: 'Mineral del Monte',
        value: 'CT_MINERAL_DEL_MONTE'
      },
      { label: 'La Misión', value: 'CT_LA_MISION' },
      {
        label: 'Mixquiahuala de Juárez',
        value: 'CT_MIXQUIAHUALA_DE_JUAREZ'
      },
      {
        label: 'Molango de Escamilla',
        value: 'CT_MOLANGO_DE_ESCAMILLA'
      },
      {
        label: 'Nicolás Flores',
        value: 'CT_NICOLAS_FLORES'
      },
      {
        label: 'Nopala de Villagrán',
        value: 'CT_NOPALA_DE_VILLAGRAN'
      },
      {
        label: 'Omitlán de Juárez',
        value: 'CT_OMITLAN_DE_JUAREZ'
      },
      {
        label: 'San Felipe Orizatlán',
        value: 'CT_SAN_FELIPE_ORIZATLAN'
      },
      { label: 'Pacula', value: 'CT_PACULA' },
      {
        label: 'Pachuca de Soto',
        value: 'CT_PACHUCA_DE_SOTO'
      },
      { label: 'Pisaflores', value: 'CT_PISAFLORES' },
      {
        label: 'Progreso de Obregón',
        value: 'CT_PROGRESO_DE_OBREGON'
      },
      {
        label: 'Mineral de la Reforma',
        value: 'CT_MINERAL_DE_LA_REFORMA'
      },
      {
        label: 'San Agustín Tlaxiaca',
        value: 'CT_SAN_AGUSTIN_TLAXIACA'
      },
      {
        label: 'San Bartolo Tutotepec',
        value: 'CT_SAN_BARTOLO_TUTOTEPEC'
      },
      {
        label: 'San Salvador',
        value: 'CT_SAN_SALVADOR'
      },
      {
        label: 'Santiago de Anaya',
        value: 'CT_SANTIAGO_DE_ANAYA'
      },
      {
        label: 'Santiago Tulantepec de Lugo Guerrero',
        value: 'CT_SANTIAGO_TULANTEPEC_DE_LUGO_GUERRERO'
      },
      { label: 'Singuilucan', value: 'CT_SINGUILUCAN' },
      { label: 'Tasquillo', value: 'CT_TASQUILLO' },
      { label: 'Tecozautla', value: 'CT_TECOZAUTLA' },
      {
        label: 'Tenango de Doria',
        value: 'CT_TENANGO_DE_DORIA'
      },
      { label: 'Tepeapulco', value: 'CT_TEPEAPULCO' },
      {
        label: 'Tepehuacán de Guerrero',
        value: 'CT_TEPEHUACAN_DE_GUERRERO'
      },
      {
        label: 'Tepeji del Río de Ocampo',
        value: 'CT_TEPEJI_DEL_RIO_DE_OCAMPO'
      },
      { label: 'Tepetitlán', value: 'CT_TEPETITLAN' },
      { label: 'Tetepango', value: 'CT_TETEPANGO' },
      {
        label: 'Villa de Tezontepec',
        value: 'CT_VILLA_DE_TEZONTEPEC'
      },
      {
        label: 'Tezontepec de Aldama',
        value: 'CT_TEZONTEPEC_DE_ALDAMA'
      },
      {
        label: 'Tianguistengo',
        value: 'CT_TIANGUISTENGO'
      },
      { label: 'Tizayuca', value: 'CT_TIZAYUCA' },
      {
        label: 'Tlahuelilpan',
        value: 'CT_TLAHUELILPAN'
      },
      { label: 'Tlahuiltepa', value: 'CT_TLAHUILTEPA' },
      { label: 'Tlanalapa', value: 'CT_TLANALAPA' },
      { label: 'Tlanchinol', value: 'CT_TLANCHINOL' },
      { label: 'Tlaxcoapan', value: 'CT_TLAXCOAPAN' },
      { label: 'Tolcayuca', value: 'CT_TOLCAYUCA' },
      {
        label: 'Tula de Allende',
        value: 'CT_TULA_DE_ALLENDE'
      },
      {
        label: 'Tulancingo de Bravo',
        value: 'CT_TULANCINGO_DE_BRAVO'
      },
      { label: 'Xochiatipan', value: 'CT_XOCHIATIPAN' },
      {
        label: 'Xochicoatlán',
        value: 'CT_XOCHICOATLAN'
      },
      { label: 'Yahualica', value: 'CT_YAHUALICA' },
      {
        label: 'Zacualtipán de Ángeles',
        value: 'CT_ZACUALTIPAN_DE_ANGELES'
      },
      {
        label: 'Zapotlán de Juárez',
        value: 'CT_ZAPOTLAN_DE_JUAREZ'
      },
      { label: 'Zempoala', value: 'CT_ZEMPOALA' },
      { label: 'Zimapán', value: 'CT_ZIMAPAN' }
    ]
  },
  {
    label: "Jalisco",
    value: "ST_JALISCO",
    children: [
      { label: 'Acatic', value: 'CT_ACATIC' },
      {
        label: 'Acatlán de Juárez',
        value: 'CT_ACATLAN_DE_JUAREZ'
      },
      {
        label: 'Ahualulco de Mercado',
        value: 'CT_AHUALULCO_DE_MERCADO'
      },
      { label: 'Amacueca', value: 'CT_AMACUECA' },
      { label: 'Amatitán', value: 'CT_AMATITAN' },
      { label: 'Ameca', value: 'CT_AMECA' },
      {
        label: 'San Juanito de Escobedo',
        value: 'CT_SAN_JUANITO_DE_ESCOBEDO'
      },
      { label: 'Arandas', value: 'CT_ARANDAS' },
      { label: 'El Arenal', value: 'CT_EL_ARENAL' },
      {
        label: 'Atemajac de Brizuela',
        value: 'CT_ATEMAJAC_DE_BRIZUELA'
      },
      { label: 'Atengo', value: 'CT_ATENGO' },
      { label: 'Atenguillo', value: 'CT_ATENGUILLO' },
      {
        label: 'Atotonilco el Alto',
        value: 'CT_ATOTONILCO_EL_ALTO'
      },
      { label: 'Atoyac', value: 'CT_ATOYAC' },
      {
        label: 'Autlán de Navarro',
        value: 'CT_AUTLAN_DE_NAVARRO'
      },
      { label: 'Ayotlán', value: 'CT_AYOTLAN' },
      { label: 'Ayutla', value: 'CT_AYUTLA' },
      { label: 'La Barca', value: 'CT_LA_BARCA' },
      { label: 'Bolaños', value: 'CT_BOLANOS' },
      {
        label: 'Cabo Corrientes',
        value: 'CT_CABO_CORRIENTES'
      },
      {
        label: 'Casimiro Castillo',
        value: 'CT_CASIMIRO_CASTILLO'
      },
      { label: 'Cihuatlán', value: 'CT_CIHUATLAN' },
      {
        label: 'Zapotlán el Grande',
        value: 'CT_ZAPOTLAN_EL_GRANDE'
      },
      { label: 'Cocula', value: 'CT_COCULA' },
      { label: 'Colotlán', value: 'CT_COLOTLAN' },
      {
        label: 'Concepción de Buenos Aires',
        value: 'CT_CONCEPCION_DE_BUENOS_AIRES'
      },
      {
        label: 'Cuautitlán de García Barragán',
        value: 'CT_CUAUTITLAN_DE_GARCIA_BARRAGAN'
      },
      { label: 'Cuautla', value: 'CT_CUAUTLA' },
      { label: 'Cuquío', value: 'CT_CUQUIO' },
      { label: 'Chapala', value: 'CT_CHAPALA' },
      { label: 'Chimaltitán', value: 'CT_CHIMALTITAN' },
      {
        label: 'Chiquilistlán',
        value: 'CT_CHIQUILISTLAN'
      },
      { label: 'Degollado', value: 'CT_DEGOLLADO' },
      { label: 'Ejutla', value: 'CT_EJUTLA' },
      {
        label: 'Encarnación de Díaz',
        value: 'CT_ENCARNACION_DE_DIAZ'
      },
      { label: 'Etzatlán', value: 'CT_ETZATLAN' },
      { label: 'El Grullo', value: 'CT_EL_GRULLO' },
      { label: 'Guachinango', value: 'CT_GUACHINANGO' },
      { label: 'Guadalajara', value: 'CT_GUADALAJARA' },
      {
        label: 'Hostotipaquillo',
        value: 'CT_HOSTOTIPAQUILLO'
      },
      { label: 'Huejúcar', value: 'CT_HUEJUCAR' },
      {
        label: 'Huejuquilla el Alto',
        value: 'CT_HUEJUQUILLA_EL_ALTO'
      },
      { label: 'La Huerta', value: 'CT_LA_HUERTA' },
      {
        label: 'Ixtlahuacán de los Membrillos',
        value: 'CT_IXTLAHUACAN_DE_LOS_MEMBRILLOS'
      },
      {
        label: 'Ixtlahuacán del Río',
        value: 'CT_IXTLAHUACAN_DEL_RIO'
      },
      {
        label: 'Jalostotitlán',
        value: 'CT_JALOSTOTITLAN'
      },
      { label: 'Jamay', value: 'CT_JAMAY' },
      { label: 'Jesús María', value: 'CT_JESUS_MARIA' },
      {
        label: 'Jilotlán de los Dolores',
        value: 'CT_JILOTLAN_DE_LOS_DOLORES'
      },
      { label: 'Jocotepec', value: 'CT_JOCOTEPEC' },
      { label: 'Juanacatlán', value: 'CT_JUANACATLAN' },
      { label: 'Juchitlán', value: 'CT_JUCHITLAN' },
      {
        label: 'Lagos de Moreno',
        value: 'CT_LAGOS_DE_MORENO'
      },
      { label: 'El Limón', value: 'CT_EL_LIMON' },
      { label: 'Magdalena', value: 'CT_MAGDALENA' },
      {
        label: 'Santa María del Oro',
        value: 'CT_SANTA_MARIA_DEL_ORO'
      },
      {
        label: 'La Manzanilla de la Paz',
        value: 'CT_LA_MANZANILLA_DE_LA_PAZ'
      },
      { label: 'Mascota', value: 'CT_MASCOTA' },
      { label: 'Mazamitla', value: 'CT_MAZAMITLA' },
      { label: 'Mexticacán', value: 'CT_MEXTICACAN' },
      { label: 'Mezquitic', value: 'CT_MEZQUITIC' },
      { label: 'Mixtlán', value: 'CT_MIXTLAN' },
      { label: 'Ocotlán', value: 'CT_OCOTLAN' },
      {
        label: 'Ojuelos de Jalisco',
        value: 'CT_OJUELOS_DE_JALISCO'
      },
      { label: 'Pihuamo', value: 'CT_PIHUAMO' },
      { label: 'Poncitlán', value: 'CT_PONCITLAN' },
      {
        label: 'Puerto Vallarta',
        value: 'CT_PUERTO_VALLARTA'
      },
      {
        label: 'Villa Purificación',
        value: 'CT_VILLA_PURIFICACION'
      },
      { label: 'Quitupan', value: 'CT_QUITUPAN' },
      { label: 'El Salto', value: 'CT_EL_SALTO' },
      {
        label: 'San Cristóbal de la Barranca',
        value: 'CT_SAN_CRISTOBAL_DE_LA_BARRANCA'
      },
      {
        label: 'San Diego de Alejandría',
        value: 'CT_SAN_DIEGO_DE_ALEJANDRIA'
      },
      {
        label: 'San Juan de los Lagos',
        value: 'CT_SAN_JUAN_DE_LOS_LAGOS'
      },
      { label: 'San Julián', value: 'CT_SAN_JULIAN' },
      { label: 'San Marcos', value: 'CT_SAN_MARCOS' },
      {
        label: 'San Martín de Bolaños',
        value: 'CT_SAN_MARTIN_DE_BOLANOS'
      },
      {
        label: 'San Martín Hidalgo',
        value: 'CT_SAN_MARTIN_HIDALGO'
      },
      {
        label: 'San Miguel el Alto',
        value: 'CT_SAN_MIGUEL_EL_ALTO'
      },
      {
        label: 'Gómez Farías',
        value: 'CT_GOMEZ_FARIAS'
      },
      {
        label: 'San Sebastián del Oeste',
        value: 'CT_SAN_SEBASTIAN_DEL_OESTE'
      },
      {
        label: 'Santa María de los Ángeles',
        value: 'CT_SANTA_MARIA_DE_LOS_ANGELES'
      },
      { label: 'Sayula', value: 'CT_SAYULA' },
      { label: 'Tala', value: 'CT_TALA' },
      {
        label: 'Talpa de Allende',
        value: 'CT_TALPA_DE_ALLENDE'
      },
      {
        label: 'Tamazula de Gordiano',
        value: 'CT_TAMAZULA_DE_GORDIANO'
      },
      { label: 'Tapalpa', value: 'CT_TAPALPA' },
      { label: 'Tecalitlán', value: 'CT_TECALITLAN' },
      { label: 'Tecolotlán', value: 'CT_TECOLOTLAN' },
      {
        label: 'Techaluta de Montenegro',
        value: 'CT_TECHALUTA_DE_MONTENEGRO'
      },
      { label: 'Tenamaxtlán', value: 'CT_TENAMAXTLAN' },
      { label: 'Teocaltiche', value: 'CT_TEOCALTICHE' },
      {
        label: 'Teocuitatlán de Corona',
        value: 'CT_TEOCUITATLAN_DE_CORONA'
      },
      {
        label: 'Tepatitlán de Morelos',
        value: 'CT_TEPATITLAN_DE_MORELOS'
      },
      { label: 'Tequila', value: 'CT_TEQUILA' },
      { label: 'Teuchitlán', value: 'CT_TEUCHITLAN' },
      {
        label: 'Tizapán el Alto',
        value: 'CT_TIZAPAN_EL_ALTO'
      },
      {
        label: 'Tlajomulco de Zúñiga',
        value: 'CT_TLAJOMULCO_DE_ZUNIGA'
      },
      {
        label: 'San Pedro Tlaquepaque',
        value: 'CT_SAN_PEDRO_TLAQUEPAQUE'
      },
      { label: 'Tolimán', value: 'CT_TOLIMAN' },
      { label: 'Tomatlán', value: 'CT_TOMATLAN' },
      { label: 'Tonalá', value: 'CT_TONALA' },
      { label: 'Tonaya', value: 'CT_TONAYA' },
      { label: 'Tonila', value: 'CT_TONILA' },
      { label: 'Totatiche', value: 'CT_TOTATICHE' },
      { label: 'Tototlán', value: 'CT_TOTOTLAN' },
      { label: 'Tuxcacuesco', value: 'CT_TUXCACUESCO' },
      { label: 'Tuxcueca', value: 'CT_TUXCUECA' },
      { label: 'Tuxpan', value: 'CT_TUXPAN' },
      {
        label: 'Unión de San Antonio',
        value: 'CT_UNION_DE_SAN_ANTONIO'
      },
      {
        label: 'Unión de Tula',
        value: 'CT_UNION_DE_TULA'
      },
      {
        label: 'Valle de Guadalupe',
        value: 'CT_VALLE_DE_GUADALUPE'
      },
      {
        label: 'Valle de Juárez',
        value: 'CT_VALLE_DE_JUAREZ'
      },
      { label: 'San Gabriel', value: 'CT_SAN_GABRIEL' },
      {
        label: 'Villa Corona',
        value: 'CT_VILLA_CORONA'
      },
      {
        label: 'Villa Guerrero',
        value: 'CT_VILLA_GUERRERO'
      },
      {
        label: 'Villa Hidalgo',
        value: 'CT_VILLA_HIDALGO'
      },
      {
        label: 'Cañadas de Obregón',
        value: 'CT_CANADAS_DE_OBREGON'
      },
      {
        label: 'Yahualica de González Gallo',
        value: 'CT_YAHUALICA_DE_GONZALEZ_GALLO'
      },
      {
        label: 'Zacoalco de Torres',
        value: 'CT_ZACOALCO_DE_TORRES'
      },
      { label: 'Zapopan', value: 'CT_ZAPOPAN' },
      { label: 'Zapotiltic', value: 'CT_ZAPOTILTIC' },
      {
        label: 'Zapotitlán de Vadillo',
        value: 'CT_ZAPOTITLAN_DE_VADILLO'
      },
      {
        label: 'Zapotlán del Rey',
        value: 'CT_ZAPOTLAN_DEL_REY'
      },
      { label: 'Zapotlanejo', value: 'CT_ZAPOTLANEJO' },
      {
        label: 'San Ignacio Cerro Gordo',
        value: 'CT_SAN_IGNACIO_CERRO_GORDO'
      }
    ]
  },
  {
    label: "México",
    value: "ST_MEXICO",
    children: [
      {
        label: 'Acambay de Ruíz Castañeda',
        value: 'CT_ACAMBAY_DE_RUIZ_CASTANEDA'
      },
      { label: 'Acolman', value: 'CT_ACOLMAN' },
      { label: 'Aculco', value: 'CT_ACULCO' },
      {
        label: 'Almoloya de Alquisiras',
        value: 'CT_ALMOLOYA_DE_ALQUISIRAS'
      },
      {
        label: 'Almoloya de Juárez',
        value: 'CT_ALMOLOYA_DE_JUAREZ'
      },
      {
        label: 'Almoloya del Río',
        value: 'CT_ALMOLOYA_DEL_RIO'
      },
      { label: 'Amanalco', value: 'CT_AMANALCO' },
      { label: 'Amatepec', value: 'CT_AMATEPEC' },
      { label: 'Amecameca', value: 'CT_AMECAMECA' },
      { label: 'Apaxco', value: 'CT_APAXCO' },
      { label: 'Atenco', value: 'CT_ATENCO' },
      { label: 'Atizapán', value: 'CT_ATIZAPAN' },
      {
        label: 'Atizapán de Zaragoza',
        value: 'CT_ATIZAPAN_DE_ZARAGOZA'
      },
      { label: 'Atlacomulco', value: 'CT_ATLACOMULCO' },
      { label: 'Atlautla', value: 'CT_ATLAUTLA' },
      { label: 'Axapusco', value: 'CT_AXAPUSCO' },
      { label: 'Ayapango', value: 'CT_AYAPANGO' },
      { label: 'Calimaya', value: 'CT_CALIMAYA' },
      { label: 'Capulhuac', value: 'CT_CAPULHUAC' },
      {
        label: 'Coacalco de Berriozábal',
        value: 'CT_COACALCO_DE_BERRIOZABAL'
      },
      {
        label: 'Coatepec Harinas',
        value: 'CT_COATEPEC_HARINAS'
      },
      { label: 'Cocotitlán', value: 'CT_COCOTITLAN' },
      { label: 'Coyotepec', value: 'CT_COYOTEPEC' },
      { label: 'Cuautitlán', value: 'CT_CUAUTITLAN' },
      { label: 'Chalco', value: 'CT_CHALCO' },
      {
        label: 'Chapa de Mota',
        value: 'CT_CHAPA_DE_MOTA'
      },
      { label: 'Chapultepec', value: 'CT_CHAPULTEPEC' },
      { label: 'Chiautla', value: 'CT_CHIAUTLA' },
      { label: 'Chicoloapan', value: 'CT_CHICOLOAPAN' },
      { label: 'Chiconcuac', value: 'CT_CHICONCUAC' },
      {
        label: 'Chimalhuacán',
        value: 'CT_CHIMALHUACAN'
      },
      {
        label: 'Donato Guerra',
        value: 'CT_DONATO_GUERRA'
      },
      {
        label: 'Ecatepec de Morelos',
        value: 'CT_ECATEPEC_DE_MORELOS'
      },
      { label: 'Ecatzingo', value: 'CT_ECATZINGO' },
      { label: 'Huehuetoca', value: 'CT_HUEHUETOCA' },
      { label: 'Hueypoxtla', value: 'CT_HUEYPOXTLA' },
      {
        label: 'Huixquilucan',
        value: 'CT_HUIXQUILUCAN'
      },
      {
        label: 'Isidro Fabela',
        value: 'CT_ISIDRO_FABELA'
      },
      { label: 'Ixtapaluca', value: 'CT_IXTAPALUCA' },
      {
        label: 'Ixtapan de la Sal',
        value: 'CT_IXTAPAN_DE_LA_SAL'
      },
      {
        label: 'Ixtapan del Oro',
        value: 'CT_IXTAPAN_DEL_ORO'
      },
      { label: 'Ixtlahuaca', value: 'CT_IXTLAHUACA' },
      { label: 'Xalatlaco', value: 'CT_XALATLACO' },
      { label: 'Jaltenco', value: 'CT_JALTENCO' },
      { label: 'Jilotepec', value: 'CT_JILOTEPEC' },
      { label: 'Jilotzingo', value: 'CT_JILOTZINGO' },
      { label: 'Jiquipilco', value: 'CT_JIQUIPILCO' },
      { label: 'Jocotitlán', value: 'CT_JOCOTITLAN' },
      { label: 'Joquicingo', value: 'CT_JOQUICINGO' },
      { label: 'Juchitepec', value: 'CT_JUCHITEPEC' },
      { label: 'Lerma', value: 'CT_LERMA' },
      { label: 'Malinalco', value: 'CT_MALINALCO' },
      {
        label: 'Melchor Ocampo',
        value: 'CT_MELCHOR_OCAMPO'
      },
      { label: 'Metepec', value: 'CT_METEPEC' },
      {
        label: 'Mexicaltzingo',
        value: 'CT_MEXICALTZINGO'
      },
      { label: 'Morelos', value: 'CT_MORELOS' },
      {
        label: 'Naucalpan de Juárez',
        value: 'CT_NAUCALPAN_DE_JUAREZ'
      },
      {
        label: 'Nezahualcóyotl',
        value: 'CT_NEZAHUALCOYOTL'
      },
      { label: 'Nextlalpan', value: 'CT_NEXTLALPAN' },
      {
        label: 'Nicolás Romero',
        value: 'CT_NICOLAS_ROMERO'
      },
      { label: 'Nopaltepec', value: 'CT_NOPALTEPEC' },
      { label: 'Ocoyoacac', value: 'CT_OCOYOACAC' },
      { label: 'Ocuilan', value: 'CT_OCUILAN' },
      { label: 'El Oro', value: 'CT_EL_ORO' },
      { label: 'Otumba', value: 'CT_OTUMBA' },
      { label: 'Otzoloapan', value: 'CT_OTZOLOAPAN' },
      { label: 'Otzolotepec', value: 'CT_OTZOLOTEPEC' },
      { label: 'Ozumba', value: 'CT_OZUMBA' },
      { label: 'Papalotla', value: 'CT_PAPALOTLA' },
      { label: 'La Paz', value: 'CT_LA_PAZ' },
      { label: 'Polotitlán', value: 'CT_POLOTITLAN' },
      { label: 'Rayón', value: 'CT_RAYON' },
      {
        label: 'San Antonio la Isla',
        value: 'CT_SAN_ANTONIO_LA_ISLA'
      },
      {
        label: 'San Felipe del Progreso',
        value: 'CT_SAN_FELIPE_DEL_PROGRESO'
      },
      {
        label: 'San Martín de las Pirámides',
        value: 'CT_SAN_MARTIN_DE_LAS_PIRAMIDES'
      },
      {
        label: 'San Mateo Atenco',
        value: 'CT_SAN_MATEO_ATENCO'
      },
      {
        label: 'San Simón de Guerrero',
        value: 'CT_SAN_SIMON_DE_GUERRERO'
      },
      { label: 'Santo Tomás', value: 'CT_SANTO_TOMAS' },
      {
        label: 'Soyaniquilpan de Juárez',
        value: 'CT_SOYANIQUILPAN_DE_JUAREZ'
      },
      { label: 'Sultepec', value: 'CT_SULTEPEC' },
      { label: 'Tecámac', value: 'CT_TECAMAC' },
      { label: 'Tejupilco', value: 'CT_TEJUPILCO' },
      { label: 'Temamatla', value: 'CT_TEMAMATLA' },
      { label: 'Temascalapa', value: 'CT_TEMASCALAPA' },
      {
        label: 'Temascalcingo',
        value: 'CT_TEMASCALCINGO'
      },
      {
        label: 'Temascaltepec',
        value: 'CT_TEMASCALTEPEC'
      },
      { label: 'Temoaya', value: 'CT_TEMOAYA' },
      { label: 'Tenancingo', value: 'CT_TENANCINGO' },
      {
        label: 'Tenango del Aire',
        value: 'CT_TENANGO_DEL_AIRE'
      },
      {
        label: 'Tenango del Valle',
        value: 'CT_TENANGO_DEL_VALLE'
      },
      { label: 'Teoloyucan', value: 'CT_TEOLOYUCAN' },
      { label: 'Teotihuacán', value: 'CT_TEOTIHUACAN' },
      {
        label: 'Tepetlaoxtoc',
        value: 'CT_TEPETLAOXTOC'
      },
      { label: 'Tepetlixpa', value: 'CT_TEPETLIXPA' },
      { label: 'Tepotzotlán', value: 'CT_TEPOTZOTLAN' },
      { label: 'Tequixquiac', value: 'CT_TEQUIXQUIAC' },
      {
        label: 'Texcaltitlán',
        value: 'CT_TEXCALTITLAN'
      },
      { label: 'Texcalyacac', value: 'CT_TEXCALYACAC' },
      { label: 'Texcoco', value: 'CT_TEXCOCO' },
      { label: 'Tezoyuca', value: 'CT_TEZOYUCA' },
      {
        label: 'Tianguistenco',
        value: 'CT_TIANGUISTENCO'
      },
      { label: 'Timilpan', value: 'CT_TIMILPAN' },
      { label: 'Tlalmanalco', value: 'CT_TLALMANALCO' },
      {
        label: 'Tlalnepantla de Baz',
        value: 'CT_TLALNEPANTLA_DE_BAZ'
      },
      { label: 'Tlatlaya', value: 'CT_TLATLAYA' },
      { label: 'Toluca', value: 'CT_TOLUCA' },
      { label: 'Tonatico', value: 'CT_TONATICO' },
      { label: 'Tultepec', value: 'CT_TULTEPEC' },
      { label: 'Tultitlán', value: 'CT_TULTITLAN' },
      {
        label: 'Valle de Bravo',
        value: 'CT_VALLE_DE_BRAVO'
      },
      {
        label: 'Villa de Allende',
        value: 'CT_VILLA_DE_ALLENDE'
      },
      {
        label: 'Villa del Carbón',
        value: 'CT_VILLA_DEL_CARBON'
      },
      {
        label: 'Villa Guerrero',
        value: 'CT_VILLA_GUERRERO'
      },
      {
        label: 'Villa Victoria',
        value: 'CT_VILLA_VICTORIA'
      },
      { label: 'Xonacatlán', value: 'CT_XONACATLAN' },
      { label: 'Zacazonapan', value: 'CT_ZACAZONAPAN' },
      { label: 'Zacualpan', value: 'CT_ZACUALPAN' },
      {
        label: 'Zinacantepec',
        value: 'CT_ZINACANTEPEC'
      },
      { label: 'Zumpahuacán', value: 'CT_ZUMPAHUACAN' },
      { label: 'Zumpango', value: 'CT_ZUMPANGO' },
      {
        label: 'Cuautitlán Izcalli',
        value: 'CT_CUAUTITLAN_IZCALLI'
      },
      {
        label: 'Valle de Chalco Solidaridad',
        value: 'CT_VALLE_DE_CHALCO_SOLIDARIDAD'
      },
      { label: 'Luvianos', value: 'CT_LUVIANOS' },
      {
        label: 'San José del Rincón',
        value: 'CT_SAN_JOSE_DEL_RINCON'
      },
      { label: 'Tonanitla', value: 'CT_TONANITLA' }
    ]
  },
  {
    label: "Michoacán de Ocampo",
    value: "ST_MICHOACAN",
    children: [
      { label: 'Acuitzio', value: 'CT_ACUITZIO' },
      { label: 'Aguililla', value: 'CT_AGUILILLA' },
      {
        label: 'Álvaro Obregón',
        value: 'CT_ALVARO_OBREGON'
      },
      {
        label: 'Angamacutiro',
        value: 'CT_ANGAMACUTIRO'
      },
      { label: 'Angangueo', value: 'CT_ANGANGUEO' },
      { label: 'Apatzingán', value: 'CT_APATZINGAN' },
      { label: 'Aporo', value: 'CT_APORO' },
      { label: 'Aquila', value: 'CT_AQUILA' },
      { label: 'Ario', value: 'CT_ARIO' },
      { label: 'Arteaga', value: 'CT_ARTEAGA' },
      { label: 'Briseñas', value: 'CT_BRISENAS' },
      { label: 'Buenavista', value: 'CT_BUENAVISTA' },
      { label: 'Carácuaro', value: 'CT_CARACUARO' },
      { label: 'Coahuayana', value: 'CT_COAHUAYANA' },
      {
        label: 'Coalcomán de Vázquez Pallares',
        value: 'CT_COALCOMAN_DE_VAZQUEZ_PALLARES'
      },
      { label: 'Coeneo', value: 'CT_COENEO' },
      { label: 'Contepec', value: 'CT_CONTEPEC' },
      { label: 'Copándaro', value: 'CT_COPANDARO' },
      { label: 'Cotija', value: 'CT_COTIJA' },
      { label: 'Cuitzeo', value: 'CT_CUITZEO' },
      { label: 'Charapan', value: 'CT_CHARAPAN' },
      { label: 'Charo', value: 'CT_CHARO' },
      { label: 'Chavinda', value: 'CT_CHAVINDA' },
      { label: 'Cherán', value: 'CT_CHERAN' },
      { label: 'Chilchota', value: 'CT_CHILCHOTA' },
      { label: 'Chinicuila', value: 'CT_CHINICUILA' },
      { label: 'Chucándiro', value: 'CT_CHUCANDIRO' },
      { label: 'Churintzio', value: 'CT_CHURINTZIO' },
      { label: 'Churumuco', value: 'CT_CHURUMUCO' },
      { label: 'Ecuandureo', value: 'CT_ECUANDUREO' },
      {
        label: 'Epitacio Huerta',
        value: 'CT_EPITACIO_HUERTA'
      },
      {
        label: 'Erongarícuaro',
        value: 'CT_ERONGARICUARO'
      },
      {
        label: 'Gabriel Zamora',
        value: 'CT_GABRIEL_ZAMORA'
      },
      { label: 'Hidalgo', value: 'CT_HIDALGO' },
      { label: 'La Huacana', value: 'CT_LA_HUACANA' },
      { label: 'Huandacareo', value: 'CT_HUANDACAREO' },
      { label: 'Huaniqueo', value: 'CT_HUANIQUEO' },
      { label: 'Huetamo', value: 'CT_HUETAMO' },
      { label: 'Huiramba', value: 'CT_HUIRAMBA' },
      { label: 'Indaparapeo', value: 'CT_INDAPARAPEO' },
      { label: 'Irimbo', value: 'CT_IRIMBO' },
      { label: 'Ixtlán', value: 'CT_IXTLAN' },
      { label: 'Jacona', value: 'CT_JACONA' },
      { label: 'Jiménez', value: 'CT_JIMENEZ' },
      { label: 'Jiquilpan', value: 'CT_JIQUILPAN' },
      { label: 'Juárez', value: 'CT_JUAREZ' },
      { label: 'Jungapeo', value: 'CT_JUNGAPEO' },
      { label: 'Lagunillas', value: 'CT_LAGUNILLAS' },
      { label: 'Madero', value: 'CT_MADERO' },
      { label: 'Maravatío', value: 'CT_MARAVATIO' },
      {
        label: 'Marcos Castellanos',
        value: 'CT_MARCOS_CASTELLANOS'
      },
      {
        label: 'Lázaro Cárdenas',
        value: 'CT_LAZARO_CARDENAS'
      },
      { label: 'Morelia', value: 'CT_MORELIA' },
      { label: 'Morelos', value: 'CT_MORELOS' },
      { label: 'Múgica', value: 'CT_MUGICA' },
      { label: 'Nahuatzen', value: 'CT_NAHUATZEN' },
      { label: 'Nocupétaro', value: 'CT_NOCUPETARO' },
      {
        label: 'Nuevo Parangaricutiro',
        value: 'CT_NUEVO_PARANGARICUTIRO'
      },
      {
        label: 'Nuevo Urecho',
        value: 'CT_NUEVO_URECHO'
      },
      { label: 'Numarán', value: 'CT_NUMARAN' },
      { label: 'Ocampo', value: 'CT_OCAMPO' },
      { label: 'Pajacuarán', value: 'CT_PAJACUARAN' },
      {
        label: 'Panindícuaro',
        value: 'CT_PANINDICUARO'
      },
      { label: 'Parácuaro', value: 'CT_PARACUARO' },
      { label: 'Paracho', value: 'CT_PARACHO' },
      { label: 'Pátzcuaro', value: 'CT_PATZCUARO' },
      { label: 'Penjamillo', value: 'CT_PENJAMILLO' },
      { label: 'Peribán', value: 'CT_PERIBAN' },
      { label: 'La Piedad', value: 'CT_LA_PIEDAD' },
      { label: 'Purépero', value: 'CT_PUREPERO' },
      { label: 'Puruándiro', value: 'CT_PURUANDIRO' },
      { label: 'Queréndaro', value: 'CT_QUERENDARO' },
      { label: 'Quiroga', value: 'CT_QUIROGA' },
      {
        label: 'Cojumatlán de Régules',
        value: 'CT_COJUMATLAN_DE_REGULES'
      },
      { label: 'Los Reyes', value: 'CT_LOS_REYES' },
      { label: 'Sahuayo', value: 'CT_SAHUAYO' },
      { label: 'San Lucas', value: 'CT_SAN_LUCAS' },
      {
        label: 'Santa Ana Maya',
        value: 'CT_SANTA_ANA_MAYA'
      },
      {
        label: 'Salvador Escalante',
        value: 'CT_SALVADOR_ESCALANTE'
      },
      { label: 'Senguio', value: 'CT_SENGUIO' },
      { label: 'Susupuato', value: 'CT_SUSUPUATO' },
      { label: 'Tacámbaro', value: 'CT_TACAMBARO' },
      { label: 'Tancítaro', value: 'CT_TANCITARO' },
      {
        label: 'Tangamandapio',
        value: 'CT_TANGAMANDAPIO'
      },
      {
        label: 'Tangancícuaro',
        value: 'CT_TANGANCICUARO'
      },
      { label: 'Tanhuato', value: 'CT_TANHUATO' },
      { label: 'Taretan', value: 'CT_TARETAN' },
      { label: 'Tarímbaro', value: 'CT_TARIMBARO' },
      {
        label: 'Tepalcatepec',
        value: 'CT_TEPALCATEPEC'
      },
      { label: 'Tingambato', value: 'CT_TINGAMBATO' },
      { label: 'Tingüindín', value: 'CT_TINGUINDIN' },
      {
        label: 'Tiquicheo de Nicolás Romero',
        value: 'CT_TIQUICHEO_DE_NICOLAS_ROMERO'
      },
      { label: 'Tlalpujahua', value: 'CT_TLALPUJAHUA' },
      { label: 'Tlazazalca', value: 'CT_TLAZAZALCA' },
      { label: 'Tocumbo', value: 'CT_TOCUMBO' },
      { label: 'Tumbiscatío', value: 'CT_TUMBISCATIO' },
      { label: 'Turicato', value: 'CT_TURICATO' },
      { label: 'Tuxpan', value: 'CT_TUXPAN' },
      { label: 'Tuzantla', value: 'CT_TUZANTLA' },
      {
        label: 'Tzintzuntzan',
        value: 'CT_TZINTZUNTZAN'
      },
      { label: 'Tzitzio', value: 'CT_TZITZIO' },
      { label: 'Uruapan', value: 'CT_URUAPAN' },
      {
        label: 'Venustiano Carranza',
        value: 'CT_VENUSTIANO_CARRANZA'
      },
      { label: 'Villamar', value: 'CT_VILLAMAR' },
      {
        label: 'Vista Hermosa',
        value: 'CT_VISTA_HERMOSA'
      },
      { label: 'Yurécuaro', value: 'CT_YURECUARO' },
      { label: 'Zacapu', value: 'CT_ZACAPU' },
      { label: 'Zamora', value: 'CT_ZAMORA' },
      { label: 'Zináparo', value: 'CT_ZINAPARO' },
      { label: 'Zinapécuaro', value: 'CT_ZINAPECUARO' },
      {
        label: 'Ziracuaretiro',
        value: 'CT_ZIRACUARETIRO'
      },
      { label: 'Zitácuaro', value: 'CT_ZITACUARO' },
      {
        label: 'José Sixto Verduzco',
        value: 'CT_JOSE_SIXTO_VERDUZCO'
      }
    ]
  },
  {
    label: "Morelos",
    value: "ST_MORELOS",
    children: [
      { label: 'Amacuzac', value: 'CT_AMACUZAC' },
      {
        label: 'Atlatlahucan',
        value: 'CT_ATLATLAHUCAN'
      },
      { label: 'Axochiapan', value: 'CT_AXOCHIAPAN' },
      { label: 'Ayala', value: 'CT_AYALA' },
      {
        label: 'Coatlán del Río',
        value: 'CT_COATLAN_DEL_RIO'
      },
      { label: 'Cuautla', value: 'CT_CUAUTLA' },
      { label: 'Cuernavaca', value: 'CT_CUERNAVACA' },
      {
        label: 'Emiliano Zapata',
        value: 'CT_EMILIANO_ZAPATA'
      },
      { label: 'Huitzilac', value: 'CT_HUITZILAC' },
      { label: 'Jantetelco', value: 'CT_JANTETELCO' },
      { label: 'Jiutepec', value: 'CT_JIUTEPEC' },
      { label: 'Jojutla', value: 'CT_JOJUTLA' },
      {
        label: 'Jonacatepec de Leandro Valle',
        value: 'CT_JONACATEPEC_DE_LEANDRO_VALLE'
      },
      { label: 'Mazatepec', value: 'CT_MAZATEPEC' },
      { label: 'Miacatlán', value: 'CT_MIACATLAN' },
      { label: 'Ocuituco', value: 'CT_OCUITUCO' },
      {
        label: 'Puente de Ixtla',
        value: 'CT_PUENTE_DE_IXTLA'
      },
      { label: 'Temixco', value: 'CT_TEMIXCO' },
      { label: 'Tepalcingo', value: 'CT_TEPALCINGO' },
      { label: 'Tepoztlán', value: 'CT_TEPOZTLAN' },
      { label: 'Tetecala', value: 'CT_TETECALA' },
      {
        label: 'Tetela del Volcán',
        value: 'CT_TETELA_DEL_VOLCAN'
      },
      {
        label: 'Tlalnepantla',
        value: 'CT_TLALNEPANTLA'
      },
      {
        label: 'Tlaltizapán de Zapata',
        value: 'CT_TLALTIZAPAN_DE_ZAPATA'
      },
      {
        label: 'Tlaquiltenango',
        value: 'CT_TLAQUILTENANGO'
      },
      { label: 'Tlayacapan', value: 'CT_TLAYACAPAN' },
      { label: 'Totolapan', value: 'CT_TOTOLAPAN' },
      { label: 'Xochitepec', value: 'CT_XOCHITEPEC' },
      { label: 'Yautepec', value: 'CT_YAUTEPEC' },
      { label: 'Yecapixtla', value: 'CT_YECAPIXTLA' },
      { label: 'Zacatepec', value: 'CT_ZACATEPEC' },
      {
        label: 'Zacualpan de Amilpas',
        value: 'CT_ZACUALPAN_DE_AMILPAS'
      },
      { label: 'Temoac', value: 'CT_TEMOAC' }
    ]
  },
  {
    label: "Nayarit",
    value: "ST_NAYARIT",
    children: [
      { label: 'Acaponeta', value: 'CT_ACAPONETA' },
      { label: 'Ahuacatlán', value: 'CT_AHUACATLAN' },
      {
        label: 'Amatlán de Cañas',
        value: 'CT_AMATLAN_DE_CANAS'
      },
      { label: 'Compostela', value: 'CT_COMPOSTELA' },
      { label: 'Huajicori', value: 'CT_HUAJICORI' },
      {
        label: 'Ixtlán del Río',
        value: 'CT_IXTLAN_DEL_RIO'
      },
      { label: 'Jala', value: 'CT_JALA' },
      { label: 'Xalisco', value: 'CT_XALISCO' },
      { label: 'Del Nayar', value: 'CT_DEL_NAYAR' },
      { label: 'Rosamorada', value: 'CT_ROSAMORADA' },
      { label: 'Ruíz', value: 'CT_RUIZ' },
      { label: 'San Blas', value: 'CT_SAN_BLAS' },
      {
        label: 'San Pedro Lagunillas',
        value: 'CT_SAN_PEDRO_LAGUNILLAS'
      },
      {
        label: 'Santa María del Oro',
        value: 'CT_SANTA_MARIA_DEL_ORO'
      },
      {
        label: 'Santiago Ixcuintla',
        value: 'CT_SANTIAGO_IXCUINTLA'
      },
      { label: 'Tecuala', value: 'CT_TECUALA' },
      { label: 'Tepic', value: 'CT_TEPIC' },
      { label: 'Tuxpan', value: 'CT_TUXPAN' },
      { label: 'La Yesca', value: 'CT_LA_YESCA' },
      {
        label: 'Bahía de Banderas',
        value: 'CT_BAHIA_DE_BANDERAS'
      }
    ]
  },
  {
    label: "Nuevo León",
    value: "ST_NUEVO_LEON",
    children: [
      { label: 'Abasolo', value: 'CT_ABASOLO' },
      { label: 'Agualeguas', value: 'CT_AGUALEGUAS' },
      { label: 'Los Aldamas', value: 'CT_LOS_ALDAMAS' },
      { label: 'Allende', value: 'CT_ALLENDE' },
      { label: 'Anáhuac', value: 'CT_ANAHUAC' },
      { label: 'Apodaca', value: 'CT_APODACA' },
      { label: 'Aramberri', value: 'CT_ARAMBERRI' },
      { label: 'Bustamante', value: 'CT_BUSTAMANTE' },
      {
        label: 'Cadereyta Jiménez',
        value: 'CT_CADEREYTA_JIMENEZ'
      },
      { label: 'El Carmen', value: 'CT_EL_CARMEN' },
      { label: 'Cerralvo', value: 'CT_CERRALVO' },
      {
        label: 'Ciénega de Flores',
        value: 'CT_CIENEGA_DE_FLORES'
      },
      { label: 'China', value: 'CT_CHINA' },
      {
        label: 'Doctor Arroyo',
        value: 'CT_DOCTOR_ARROYO'
      },
      { label: 'Doctor Coss', value: 'CT_DOCTOR_COSS' },
      {
        label: 'Doctor González',
        value: 'CT_DOCTOR_GONZALEZ'
      },
      { label: 'Galeana', value: 'CT_GALEANA' },
      { label: 'García', value: 'CT_GARCIA' },
      {
        label: 'San Pedro Garza García',
        value: 'CT_SAN_PEDRO_GARZA_GARCIA'
      },
      {
        label: 'General Bravo',
        value: 'CT_GENERAL_BRAVO'
      },
      {
        label: 'General Escobedo',
        value: 'CT_GENERAL_ESCOBEDO'
      },
      {
        label: 'General Terán',
        value: 'CT_GENERAL_TERAN'
      },
      {
        label: 'General Treviño',
        value: 'CT_GENERAL_TREVINO'
      },
      {
        label: 'General Zaragoza',
        value: 'CT_GENERAL_ZARAGOZA'
      },
      {
        label: 'General Zuazua',
        value: 'CT_GENERAL_ZUAZUA'
      },
      { label: 'Guadalupe', value: 'CT_GUADALUPE' },
      {
        label: 'Los Herreras',
        value: 'CT_LOS_HERRERAS'
      },
      { label: 'Higueras', value: 'CT_HIGUERAS' },
      { label: 'Hualahuises', value: 'CT_HUALAHUISES' },
      { label: 'Iturbide', value: 'CT_ITURBIDE' },
      { label: 'Juárez', value: 'CT_JUAREZ' },
      {
        label: 'Lampazos de Naranjo',
        value: 'CT_LAMPAZOS_DE_NARANJO'
      },
      { label: 'Linares', value: 'CT_LINARES' },
      { label: 'Marín', value: 'CT_MARIN' },
      {
        label: 'Melchor Ocampo',
        value: 'CT_MELCHOR_OCAMPO'
      },
      {
        label: 'Mier y Noriega',
        value: 'CT_MIER_Y_NORIEGA'
      },
      { label: 'Mina', value: 'CT_MINA' },
      {
        label: 'Montemorelos',
        value: 'CT_MONTEMORELOS'
      },
      { label: 'Monterrey', value: 'CT_MONTERREY' },
      { label: 'Parás', value: 'CT_PARAS' },
      { label: 'Pesquería', value: 'CT_PESQUERIA' },
      { label: 'Los Ramones', value: 'CT_LOS_RAMONES' },
      { label: 'Rayones', value: 'CT_RAYONES' },
      {
        label: 'Sabinas Hidalgo',
        value: 'CT_SABINAS_HIDALGO'
      },
      {
        label: 'Salinas Victoria',
        value: 'CT_SALINAS_VICTORIA'
      },
      {
        label: 'San Nicolás de los Garza',
        value: 'CT_SAN_NICOLAS_DE_LOS_GARZA'
      },
      { label: 'Hidalgo', value: 'CT_HIDALGO' },
      {
        label: 'Santa Catarina',
        value: 'CT_SANTA_CATARINA'
      },
      { label: 'Santiago', value: 'CT_SANTIAGO' },
      { label: 'Vallecillo', value: 'CT_VALLECILLO' },
      { label: 'Villaldama', value: 'CT_VILLALDAMA' }
    ]
  },
  {
    label: "Oaxaca",
    value: "ST_OAXACA",
    children: [
      { label: 'Abejones', value: 'CT_ABEJONES' },
      {
        label: 'Acatlán de Pérez Figueroa',
        value: 'CT_ACATLAN_DE_PEREZ_FIGUEROA'
      },
      {
        label: 'Asunción Cacalotepec',
        value: 'CT_ASUNCION_CACALOTEPEC'
      },
      {
        label: 'Asunción Cuyotepeji',
        value: 'CT_ASUNCION_CUYOTEPEJI'
      },
      {
        label: 'Asunción Ixtaltepec',
        value: 'CT_ASUNCION_IXTALTEPEC'
      },
      {
        label: 'Asunción Nochixtlán',
        value: 'CT_ASUNCION_NOCHIXTLAN'
      },
      {
        label: 'Asunción Ocotlán',
        value: 'CT_ASUNCION_OCOTLAN'
      },
      {
        label: 'Asunción Tlacolulita',
        value: 'CT_ASUNCION_TLACOLULITA'
      },
      {
        label: 'Ayotzintepec',
        value: 'CT_AYOTZINTEPEC'
      },
      {
        label: 'El Barrio de la Soledad',
        value: 'CT_EL_BARRIO_DE_LA_SOLEDAD'
      },
      { label: 'Calihualá', value: 'CT_CALIHUALA' },
      {
        label: 'Candelaria Loxicha',
        value: 'CT_CANDELARIA_LOXICHA'
      },
      {
        label: 'Ciénega de Zimatlán',
        value: 'CT_CIENEGA_DE_ZIMATLAN'
      },
      {
        label: 'Ciudad Ixtepec',
        value: 'CT_CIUDAD_IXTEPEC'
      },
      {
        label: 'Coatecas Altas',
        value: 'CT_COATECAS_ALTAS'
      },
      {
        label: 'Coicoyán de las Flores',
        value: 'CT_COICOYAN_DE_LAS_FLORES'
      },
      { label: 'La Compañía', value: 'CT_LA_COMPANIA' },
      {
        label: 'Concepción Buenavista',
        value: 'CT_CONCEPCION_BUENAVISTA'
      },
      {
        label: 'Concepción Pápalo',
        value: 'CT_CONCEPCION_PAPALO'
      },
      {
        label: 'Constancia del Rosario',
        value: 'CT_CONSTANCIA_DEL_ROSARIO'
      },
      { label: 'Cosolapa', value: 'CT_COSOLAPA' },
      { label: 'Cosoltepec', value: 'CT_COSOLTEPEC' },
      {
        label: 'Cuilápam de Guerrero',
        value: 'CT_CUILAPAM_DE_GUERRERO'
      },
      {
        label: 'Cuyamecalco Villa de Zaragoza',
        value: 'CT_CUYAMECALCO_VILLA_DE_ZARAGOZA'
      },
      { label: 'Chahuites', value: 'CT_CHAHUITES' },
      {
        label: 'Chalcatongo de Hidalgo',
        value: 'CT_CHALCATONGO_DE_HIDALGO'
      },
      {
        label: 'Chiquihuitlán de Benito Juárez',
        value: 'CT_CHIQUIHUITLAN_DE_BENITO_JUAREZ'
      },
      {
        label: 'Heroica Ciudad de Ejutla de Crespo',
        value: 'CT_HEROICA_CIUDAD_DE_EJUTLA_DE_CRESPO'
      },
      {
        label: 'Eloxochitlán de Flores Magón',
        value: 'CT_ELOXOCHITLAN_DE_FLORES_MAGON'
      },
      { label: 'El Espinal', value: 'CT_EL_ESPINAL' },
      {
        label: 'Tamazulápam del Espíritu Santo',
        value: 'CT_TAMAZULAPAM_DEL_ESPIRITU_SANTO'
      },
      {
        label: 'Fresnillo de Trujano',
        value: 'CT_FRESNILLO_DE_TRUJANO'
      },
      {
        label: 'Guadalupe Etla',
        value: 'CT_GUADALUPE_ETLA'
      },
      {
        label: 'Guadalupe de Ramírez',
        value: 'CT_GUADALUPE_DE_RAMIREZ'
      },
      {
        label: 'Guelatao de Juárez',
        value: 'CT_GUELATAO_DE_JUAREZ'
      },
      {
        label: 'Guevea de Humboldt',
        value: 'CT_GUEVEA_DE_HUMBOLDT'
      },
      {
        label: 'Mesones Hidalgo',
        value: 'CT_MESONES_HIDALGO'
      },
      {
        label: 'Villa Hidalgo',
        value: 'CT_VILLA_HIDALGO'
      },
      {
        label: 'Heroica Ciudad de Huajuapan de León',
        value: 'CT_HEROICA_CIUDAD_DE_HUAJUAPAN_DE_LEON'
      },
      { label: 'Huautepec', value: 'CT_HUAUTEPEC' },
      {
        label: 'Huautla de Jiménez',
        value: 'CT_HUAUTLA_DE_JIMENEZ'
      },
      {
        label: 'Ixtlán de Juárez',
        value: 'CT_IXTLAN_DE_JUAREZ'
      },
      {
        label: 'Heroica Ciudad de Juchitán de Zaragoza',
        value: 'CT_HEROICA_CIUDAD_DE_JUCHITAN_DE_ZARAGOZA'
      },
      { label: 'Loma Bonita', value: 'CT_LOMA_BONITA' },
      {
        label: 'Magdalena Apasco',
        value: 'CT_MAGDALENA_APASCO'
      },
      {
        label: 'Magdalena Jaltepec',
        value: 'CT_MAGDALENA_JALTEPEC'
      },
      {
        label: 'Santa Magdalena Jicotlán',
        value: 'CT_SANTA_MAGDALENA_JICOTLAN'
      },
      {
        label: 'Magdalena Mixtepec',
        value: 'CT_MAGDALENA_MIXTEPEC'
      },
      {
        label: 'Magdalena Ocotlán',
        value: 'CT_MAGDALENA_OCOTLAN'
      },
      {
        label: 'Magdalena Peñasco',
        value: 'CT_MAGDALENA_PENASCO'
      },
      {
        label: 'Magdalena Teitipac',
        value: 'CT_MAGDALENA_TEITIPAC'
      },
      {
        label: 'Magdalena Tequisistlán',
        value: 'CT_MAGDALENA_TEQUISISTLAN'
      },
      {
        label: 'Magdalena Tlacotepec',
        value: 'CT_MAGDALENA_TLACOTEPEC'
      },
      {
        label: 'Magdalena Zahuatlán',
        value: 'CT_MAGDALENA_ZAHUATLAN'
      },
      {
        label: 'Mariscala de Juárez',
        value: 'CT_MARISCALA_DE_JUAREZ'
      },
      {
        label: 'Mártires de Tacubaya',
        value: 'CT_MARTIRES_DE_TACUBAYA'
      },
      {
        label: 'Matías Romero Avendaño',
        value: 'CT_MATIAS_ROMERO_AVENDANO'
      },
      {
        label: 'Mazatlán Villa de Flores',
        value: 'CT_MAZATLAN_VILLA_DE_FLORES'
      },
      {
        label: 'Miahuatlán de Porfirio Díaz',
        value: 'CT_MIAHUATLAN_DE_PORFIRIO_DIAZ'
      },
      {
        label: 'Mixistlán de la Reforma',
        value: 'CT_MIXISTLAN_DE_LA_REFORMA'
      },
      { label: 'Monjas', value: 'CT_MONJAS' },
      { label: 'Natividad', value: 'CT_NATIVIDAD' },
      {
        label: 'Nazareno Etla',
        value: 'CT_NAZARENO_ETLA'
      },
      {
        label: 'Nejapa de Madero',
        value: 'CT_NEJAPA_DE_MADERO'
      },
      {
        label: 'Ixpantepec Nieves',
        value: 'CT_IXPANTEPEC_NIEVES'
      },
      {
        label: 'Santiago Niltepec',
        value: 'CT_SANTIAGO_NILTEPEC'
      },
      {
        label: 'Oaxaca de Juárez',
        value: 'CT_OAXACA_DE_JUAREZ'
      },
      {
        label: 'Ocotlán de Morelos',
        value: 'CT_OCOTLAN_DE_MORELOS'
      },
      { label: 'La Pe', value: 'CT_LA_PE' },
      {
        label: 'Pinotepa de Don Luis',
        value: 'CT_PINOTEPA_DE_DON_LUIS'
      },
      {
        label: 'Pluma Hidalgo',
        value: 'CT_PLUMA_HIDALGO'
      },
      {
        label: 'San José del Progreso',
        value: 'CT_SAN_JOSE_DEL_PROGRESO'
      },
      {
        label: 'Putla Villa de Guerrero',
        value: 'CT_PUTLA_VILLA_DE_GUERRERO'
      },
      {
        label: 'Santa Catarina Quioquitani',
        value: 'CT_SANTA_CATARINA_QUIOQUITANI'
      },
      {
        label: 'Reforma de Pineda',
        value: 'CT_REFORMA_DE_PINEDA'
      },
      { label: 'La Reforma', value: 'CT_LA_REFORMA' },
      { label: 'Reyes Etla', value: 'CT_REYES_ETLA' },
      {
        label: 'Rojas de Cuauhtémoc',
        value: 'CT_ROJAS_DE_CUAUHTEMOC'
      },
      { label: 'Salina Cruz', value: 'CT_SALINA_CRUZ' },
      {
        label: 'San Agustín Amatengo',
        value: 'CT_SAN_AGUSTIN_AMATENGO'
      },
      {
        label: 'San Agustín Atenango',
        value: 'CT_SAN_AGUSTIN_ATENANGO'
      },
      {
        label: 'San Agustín Chayuco',
        value: 'CT_SAN_AGUSTIN_CHAYUCO'
      },
      {
        label: 'San Agustín de las Juntas',
        value: 'CT_SAN_AGUSTIN_DE_LAS_JUNTAS'
      },
      {
        label: 'San Agustín Etla',
        value: 'CT_SAN_AGUSTIN_ETLA'
      },
      {
        label: 'San Agustín Loxicha',
        value: 'CT_SAN_AGUSTIN_LOXICHA'
      },
      {
        label: 'San Agustín Tlacotepec',
        value: 'CT_SAN_AGUSTIN_TLACOTEPEC'
      },
      {
        label: 'San Agustín Yatareni',
        value: 'CT_SAN_AGUSTIN_YATARENI'
      },
      {
        label: 'San Andrés Cabecera Nueva',
        value: 'CT_SAN_ANDRES_CABECERA_NUEVA'
      },
      {
        label: 'San Andrés Dinicuiti',
        value: 'CT_SAN_ANDRES_DINICUITI'
      },
      {
        label: 'San Andrés Huaxpaltepec',
        value: 'CT_SAN_ANDRES_HUAXPALTEPEC'
      },
      {
        label: 'San Andrés Huayápam',
        value: 'CT_SAN_ANDRES_HUAYAPAM'
      },
      {
        label: 'San Andrés Ixtlahuaca',
        value: 'CT_SAN_ANDRES_IXTLAHUACA'
      },
      {
        label: 'San Andrés Lagunas',
        value: 'CT_SAN_ANDRES_LAGUNAS'
      },
      {
        label: 'San Andrés Nuxiño',
        value: 'CT_SAN_ANDRES_NUXINO'
      },
      {
        label: 'San Andrés Paxtlán',
        value: 'CT_SAN_ANDRES_PAXTLAN'
      },
      {
        label: 'San Andrés Sinaxtla',
        value: 'CT_SAN_ANDRES_SINAXTLA'
      },
      {
        label: 'San Andrés Solaga',
        value: 'CT_SAN_ANDRES_SOLAGA'
      },
      {
        label: 'San Andrés Teotilálpam',
        value: 'CT_SAN_ANDRES_TEOTILALPAM'
      },
      {
        label: 'San Andrés Tepetlapa',
        value: 'CT_SAN_ANDRES_TEPETLAPA'
      },
      {
        label: 'San Andrés Yaá',
        value: 'CT_SAN_ANDRES_YAA'
      },
      {
        label: 'San Andrés Zabache',
        value: 'CT_SAN_ANDRES_ZABACHE'
      },
      {
        label: 'San Andrés Zautla',
        value: 'CT_SAN_ANDRES_ZAUTLA'
      },
      {
        label: 'San Antonino Castillo Velasco',
        value: 'CT_SAN_ANTONINO_CASTILLO_VELASCO'
      },
      {
        label: 'San Antonino el Alto',
        value: 'CT_SAN_ANTONINO_EL_ALTO'
      },
      {
        label: 'San Antonino Monte Verde',
        value: 'CT_SAN_ANTONINO_MONTE_VERDE'
      },
      {
        label: 'San Antonio Acutla',
        value: 'CT_SAN_ANTONIO_ACUTLA'
      },
      {
        label: 'San Antonio de la Cal',
        value: 'CT_SAN_ANTONIO_DE_LA_CAL'
      },
      {
        label: 'San Antonio Huitepec',
        value: 'CT_SAN_ANTONIO_HUITEPEC'
      },
      {
        label: 'San Antonio Nanahuatípam',
        value: 'CT_SAN_ANTONIO_NANAHUATIPAM'
      },
      {
        label: 'San Antonio Sinicahua',
        value: 'CT_SAN_ANTONIO_SINICAHUA'
      },
      {
        label: 'San Antonio Tepetlapa',
        value: 'CT_SAN_ANTONIO_TEPETLAPA'
      },
      {
        label: 'San Baltazar Chichicápam',
        value: 'CT_SAN_BALTAZAR_CHICHICAPAM'
      },
      {
        label: 'San Baltazar Loxicha',
        value: 'CT_SAN_BALTAZAR_LOXICHA'
      },
      {
        label: 'San Baltazar Yatzachi el Bajo',
        value: 'CT_SAN_BALTAZAR_YATZACHI_EL_BAJO'
      },
      {
        label: 'San Bartolo Coyotepec',
        value: 'CT_SAN_BARTOLO_COYOTEPEC'
      },
      {
        label: 'San Bartolomé Ayautla',
        value: 'CT_SAN_BARTOLOME_AYAUTLA'
      },
      {
        label: 'San Bartolomé Loxicha',
        value: 'CT_SAN_BARTOLOME_LOXICHA'
      },
      {
        label: 'San Bartolomé Quialana',
        value: 'CT_SAN_BARTOLOME_QUIALANA'
      },
      {
        label: 'San Bartolomé Yucuañe',
        value: 'CT_SAN_BARTOLOME_YUCUANE'
      },
      {
        label: 'San Bartolomé Zoogocho',
        value: 'CT_SAN_BARTOLOME_ZOOGOCHO'
      },
      {
        label: 'San Bartolo Soyaltepec',
        value: 'CT_SAN_BARTOLO_SOYALTEPEC'
      },
      {
        label: 'San Bartolo Yautepec',
        value: 'CT_SAN_BARTOLO_YAUTEPEC'
      },
      {
        label: 'San Bernardo Mixtepec',
        value: 'CT_SAN_BERNARDO_MIXTEPEC'
      },
      {
        label: 'San Blas Atempa',
        value: 'CT_SAN_BLAS_ATEMPA'
      },
      {
        label: 'San Carlos Yautepec',
        value: 'CT_SAN_CARLOS_YAUTEPEC'
      },
      {
        label: 'San Cristóbal Amatlán',
        value: 'CT_SAN_CRISTOBAL_AMATLAN'
      },
      {
        label: 'San Cristóbal Amoltepec',
        value: 'CT_SAN_CRISTOBAL_AMOLTEPEC'
      },
      {
        label: 'San Cristóbal Lachirioag',
        value: 'CT_SAN_CRISTOBAL_LACHIRIOAG'
      },
      {
        label: 'San Cristóbal Suchixtlahuaca',
        value: 'CT_SAN_CRISTOBAL_SUCHIXTLAHUACA'
      },
      {
        label: 'San Dionisio del Mar',
        value: 'CT_SAN_DIONISIO_DEL_MAR'
      },
      {
        label: 'San Dionisio Ocotepec',
        value: 'CT_SAN_DIONISIO_OCOTEPEC'
      },
      {
        label: 'San Dionisio Ocotlán',
        value: 'CT_SAN_DIONISIO_OCOTLAN'
      },
      {
        label: 'San Esteban Atatlahuca',
        value: 'CT_SAN_ESTEBAN_ATATLAHUCA'
      },
      {
        label: 'San Felipe Jalapa de Díaz',
        value: 'CT_SAN_FELIPE_JALAPA_DE_DIAZ'
      },
      {
        label: 'San Felipe Tejalápam',
        value: 'CT_SAN_FELIPE_TEJALAPAM'
      },
      {
        label: 'San Felipe Usila',
        value: 'CT_SAN_FELIPE_USILA'
      },
      {
        label: 'San Francisco Cahuacuá',
        value: 'CT_SAN_FRANCISCO_CAHUACUA'
      },
      {
        label: 'San Francisco Cajonos',
        value: 'CT_SAN_FRANCISCO_CAJONOS'
      },
      {
        label: 'San Francisco Chapulapa',
        value: 'CT_SAN_FRANCISCO_CHAPULAPA'
      },
      {
        label: 'San Francisco Chindúa',
        value: 'CT_SAN_FRANCISCO_CHINDUA'
      },
      {
        label: 'San Francisco del Mar',
        value: 'CT_SAN_FRANCISCO_DEL_MAR'
      },
      {
        label: 'San Francisco Huehuetlán',
        value: 'CT_SAN_FRANCISCO_HUEHUETLAN'
      },
      {
        label: 'San Francisco Ixhuatán',
        value: 'CT_SAN_FRANCISCO_IXHUATAN'
      },
      {
        label: 'San Francisco Jaltepetongo',
        value: 'CT_SAN_FRANCISCO_JALTEPETONGO'
      },
      {
        label: 'San Francisco Lachigoló',
        value: 'CT_SAN_FRANCISCO_LACHIGOLO'
      },
      {
        label: 'San Francisco Logueche',
        value: 'CT_SAN_FRANCISCO_LOGUECHE'
      },
      {
        label: 'San Francisco Nuxaño',
        value: 'CT_SAN_FRANCISCO_NUXANO'
      },
      {
        label: 'San Francisco Ozolotepec',
        value: 'CT_SAN_FRANCISCO_OZOLOTEPEC'
      },
      {
        label: 'San Francisco Sola',
        value: 'CT_SAN_FRANCISCO_SOLA'
      },
      {
        label: 'San Francisco Telixtlahuaca',
        value: 'CT_SAN_FRANCISCO_TELIXTLAHUACA'
      },
      {
        label: 'San Francisco Teopan',
        value: 'CT_SAN_FRANCISCO_TEOPAN'
      },
      {
        label: 'San Francisco Tlapancingo',
        value: 'CT_SAN_FRANCISCO_TLAPANCINGO'
      },
      {
        label: 'San Gabriel Mixtepec',
        value: 'CT_SAN_GABRIEL_MIXTEPEC'
      },
      {
        label: 'San Ildefonso Amatlán',
        value: 'CT_SAN_ILDEFONSO_AMATLAN'
      },
      {
        label: 'San Ildefonso Sola',
        value: 'CT_SAN_ILDEFONSO_SOLA'
      },
      {
        label: 'San Ildefonso Villa Alta',
        value: 'CT_SAN_ILDEFONSO_VILLA_ALTA'
      },
      {
        label: 'San Jacinto Amilpas',
        value: 'CT_SAN_JACINTO_AMILPAS'
      },
      {
        label: 'San Jacinto Tlacotepec',
        value: 'CT_SAN_JACINTO_TLACOTEPEC'
      },
      {
        label: 'San Jerónimo Coatlán',
        value: 'CT_SAN_JERONIMO_COATLAN'
      },
      {
        label: 'San Jerónimo Silacayoapilla',
        value: 'CT_SAN_JERONIMO_SILACAYOAPILLA'
      },
      {
        label: 'San Jerónimo Sosola',
        value: 'CT_SAN_JERONIMO_SOSOLA'
      },
      {
        label: 'San Jerónimo Taviche',
        value: 'CT_SAN_JERONIMO_TAVICHE'
      },
      {
        label: 'San Jerónimo Tecóatl',
        value: 'CT_SAN_JERONIMO_TECOATL'
      },
      {
        label: 'San Jorge Nuchita',
        value: 'CT_SAN_JORGE_NUCHITA'
      },
      {
        label: 'San José Ayuquila',
        value: 'CT_SAN_JOSE_AYUQUILA'
      },
      {
        label: 'San José Chiltepec',
        value: 'CT_SAN_JOSE_CHILTEPEC'
      },
      {
        label: 'San José del Peñasco',
        value: 'CT_SAN_JOSE_DEL_PENASCO'
      },
      {
        label: 'San José Estancia Grande',
        value: 'CT_SAN_JOSE_ESTANCIA_GRANDE'
      },
      {
        label: 'San José Independencia',
        value: 'CT_SAN_JOSE_INDEPENDENCIA'
      },
      {
        label: 'San José Lachiguiri',
        value: 'CT_SAN_JOSE_LACHIGUIRI'
      },
      {
        label: 'San José Tenango',
        value: 'CT_SAN_JOSE_TENANGO'
      },
      {
        label: 'San Juan Achiutla',
        value: 'CT_SAN_JUAN_ACHIUTLA'
      },
      {
        label: 'San Juan Atepec',
        value: 'CT_SAN_JUAN_ATEPEC'
      },
      {
        label: 'Ánimas Trujano',
        value: 'CT_ANIMAS_TRUJANO'
      },
      {
        label: 'San Juan Bautista Atatlahuca',
        value: 'CT_SAN_JUAN_BAUTISTA_ATATLAHUCA'
      },
      {
        label: 'San Juan Bautista Coixtlahuaca',
        value: 'CT_SAN_JUAN_BAUTISTA_COIXTLAHUACA'
      },
      {
        label: 'San Juan Bautista Cuicatlán',
        value: 'CT_SAN_JUAN_BAUTISTA_CUICATLAN'
      },
      {
        label: 'San Juan Bautista Guelache',
        value: 'CT_SAN_JUAN_BAUTISTA_GUELACHE'
      },
      {
        label: 'San Juan Bautista Jayacatlán',
        value: 'CT_SAN_JUAN_BAUTISTA_JAYACATLAN'
      },
      {
        label: 'San Juan Bautista Lo de Soto',
        value: 'CT_SAN_JUAN_BAUTISTA_LO_DE_SOTO'
      },
      {
        label: 'San Juan Bautista Suchitepec',
        value: 'CT_SAN_JUAN_BAUTISTA_SUCHITEPEC'
      },
      {
        label: 'San Juan Bautista Tlacoatzintepec',
        value: 'CT_SAN_JUAN_BAUTISTA_TLACOATZINTEPEC'
      },
      {
        label: 'San Juan Bautista Tlachichilco',
        value: 'CT_SAN_JUAN_BAUTISTA_TLACHICHILCO'
      },
      {
        label: 'San Juan Bautista Tuxtepec',
        value: 'CT_SAN_JUAN_BAUTISTA_TUXTEPEC'
      },
      {
        label: 'San Juan Cacahuatepec',
        value: 'CT_SAN_JUAN_CACAHUATEPEC'
      },
      {
        label: 'San Juan Cieneguilla',
        value: 'CT_SAN_JUAN_CIENEGUILLA'
      },
      {
        label: 'San Juan Coatzóspam',
        value: 'CT_SAN_JUAN_COATZOSPAM'
      },
      {
        label: 'San Juan Colorado',
        value: 'CT_SAN_JUAN_COLORADO'
      },
      {
        label: 'San Juan Comaltepec',
        value: 'CT_SAN_JUAN_COMALTEPEC'
      },
      {
        label: 'San Juan Cotzocón',
        value: 'CT_SAN_JUAN_COTZOCON'
      },
      {
        label: 'San Juan Chicomezúchil',
        value: 'CT_SAN_JUAN_CHICOMEZUCHIL'
      },
      {
        label: 'San Juan Chilateca',
        value: 'CT_SAN_JUAN_CHILATECA'
      },
      {
        label: 'San Juan del Estado',
        value: 'CT_SAN_JUAN_DEL_ESTADO'
      },
      {
        label: 'San Juan del Río',
        value: 'CT_SAN_JUAN_DEL_RIO'
      },
      {
        label: 'San Juan Diuxi',
        value: 'CT_SAN_JUAN_DIUXI'
      },
      {
        label: 'San Juan Evangelista Analco',
        value: 'CT_SAN_JUAN_EVANGELISTA_ANALCO'
      },
      {
        label: 'San Juan Guelavía',
        value: 'CT_SAN_JUAN_GUELAVIA'
      },
      {
        label: 'San Juan Guichicovi',
        value: 'CT_SAN_JUAN_GUICHICOVI'
      },
      {
        label: 'San Juan Ihualtepec',
        value: 'CT_SAN_JUAN_IHUALTEPEC'
      },
      {
        label: 'San Juan Juquila Mixes',
        value: 'CT_SAN_JUAN_JUQUILA_MIXES'
      },
      {
        label: 'San Juan Juquila Vijanos',
        value: 'CT_SAN_JUAN_JUQUILA_VIJANOS'
      },
      {
        label: 'San Juan Lachao',
        value: 'CT_SAN_JUAN_LACHAO'
      },
      {
        label: 'San Juan Lachigalla',
        value: 'CT_SAN_JUAN_LACHIGALLA'
      },
      {
        label: 'San Juan Lajarcia',
        value: 'CT_SAN_JUAN_LAJARCIA'
      },
      {
        label: 'San Juan Lalana',
        value: 'CT_SAN_JUAN_LALANA'
      },
      {
        label: 'San Juan de los Cués',
        value: 'CT_SAN_JUAN_DE_LOS_CUES'
      },
      {
        label: 'San Juan Mazatlán',
        value: 'CT_SAN_JUAN_MAZATLAN'
      },
      {
        label: 'San Juan Mixtepec',
        value: 'CT_SAN_JUAN_MIXTEPEC'
      },
      {
        label: 'San Juan Mixtepec',
        value: 'CT_SAN_JUAN_MIXTEPEC'
      },
      {
        label: 'San Juan Ñumí',
        value: 'CT_SAN_JUAN_NUMI'
      },
      {
        label: 'San Juan Ozolotepec',
        value: 'CT_SAN_JUAN_OZOLOTEPEC'
      },
      {
        label: 'San Juan Petlapa',
        value: 'CT_SAN_JUAN_PETLAPA'
      },
      {
        label: 'San Juan Quiahije',
        value: 'CT_SAN_JUAN_QUIAHIJE'
      },
      {
        label: 'San Juan Quiotepec',
        value: 'CT_SAN_JUAN_QUIOTEPEC'
      },
      {
        label: 'San Juan Sayultepec',
        value: 'CT_SAN_JUAN_SAYULTEPEC'
      },
      {
        label: 'San Juan Tabaá',
        value: 'CT_SAN_JUAN_TABAA'
      },
      {
        label: 'San Juan Tamazola',
        value: 'CT_SAN_JUAN_TAMAZOLA'
      },
      {
        label: 'San Juan Teita',
        value: 'CT_SAN_JUAN_TEITA'
      },
      {
        label: 'San Juan Teitipac',
        value: 'CT_SAN_JUAN_TEITIPAC'
      },
      {
        label: 'San Juan Tepeuxila',
        value: 'CT_SAN_JUAN_TEPEUXILA'
      },
      {
        label: 'San Juan Teposcolula',
        value: 'CT_SAN_JUAN_TEPOSCOLULA'
      },
      {
        label: 'San Juan Yaeé',
        value: 'CT_SAN_JUAN_YAEE'
      },
      {
        label: 'San Juan Yatzona',
        value: 'CT_SAN_JUAN_YATZONA'
      },
      {
        label: 'San Juan Yucuita',
        value: 'CT_SAN_JUAN_YUCUITA'
      },
      { label: 'San Lorenzo', value: 'CT_SAN_LORENZO' },
      {
        label: 'San Lorenzo Albarradas',
        value: 'CT_SAN_LORENZO_ALBARRADAS'
      },
      {
        label: 'San Lorenzo Cacaotepec',
        value: 'CT_SAN_LORENZO_CACAOTEPEC'
      },
      {
        label: 'San Lorenzo Cuaunecuiltitla',
        value: 'CT_SAN_LORENZO_CUAUNECUILTITLA'
      },
      {
        label: 'San Lorenzo Texmelúcan',
        value: 'CT_SAN_LORENZO_TEXMELUCAN'
      },
      {
        label: 'San Lorenzo Victoria',
        value: 'CT_SAN_LORENZO_VICTORIA'
      },
      {
        label: 'San Lucas Camotlán',
        value: 'CT_SAN_LUCAS_CAMOTLAN'
      },
      {
        label: 'San Lucas Ojitlán',
        value: 'CT_SAN_LUCAS_OJITLAN'
      },
      {
        label: 'San Lucas Quiaviní',
        value: 'CT_SAN_LUCAS_QUIAVINI'
      },
      {
        label: 'San Lucas Zoquiápam',
        value: 'CT_SAN_LUCAS_ZOQUIAPAM'
      },
      {
        label: 'San Luis Amatlán',
        value: 'CT_SAN_LUIS_AMATLAN'
      },
      {
        label: 'San Marcial Ozolotepec',
        value: 'CT_SAN_MARCIAL_OZOLOTEPEC'
      },
      {
        label: 'San Marcos Arteaga',
        value: 'CT_SAN_MARCOS_ARTEAGA'
      },
      {
        label: 'San Martín de los Cansecos',
        value: 'CT_SAN_MARTIN_DE_LOS_CANSECOS'
      },
      {
        label: 'San Martín Huamelúlpam',
        value: 'CT_SAN_MARTIN_HUAMELULPAM'
      },
      {
        label: 'San Martín Itunyoso',
        value: 'CT_SAN_MARTIN_ITUNYOSO'
      },
      {
        label: 'San Martín Lachilá',
        value: 'CT_SAN_MARTIN_LACHILA'
      },
      {
        label: 'San Martín Peras',
        value: 'CT_SAN_MARTIN_PERAS'
      },
      {
        label: 'San Martín Tilcajete',
        value: 'CT_SAN_MARTIN_TILCAJETE'
      },
      {
        label: 'San Martín Toxpalan',
        value: 'CT_SAN_MARTIN_TOXPALAN'
      },
      {
        label: 'San Martín Zacatepec',
        value: 'CT_SAN_MARTIN_ZACATEPEC'
      },
      {
        label: 'San Mateo Cajonos',
        value: 'CT_SAN_MATEO_CAJONOS'
      },
      {
        label: 'Capulálpam de Méndez',
        value: 'CT_CAPULALPAM_DE_MENDEZ'
      },
      {
        label: 'San Mateo del Mar',
        value: 'CT_SAN_MATEO_DEL_MAR'
      },
      {
        label: 'San Mateo Yoloxochitlán',
        value: 'CT_SAN_MATEO_YOLOXOCHITLAN'
      },
      {
        label: 'San Mateo Etlatongo',
        value: 'CT_SAN_MATEO_ETLATONGO'
      },
      {
        label: 'San Mateo Nejápam',
        value: 'CT_SAN_MATEO_NEJAPAM'
      },
      {
        label: 'San Mateo Peñasco',
        value: 'CT_SAN_MATEO_PENASCO'
      },
      {
        label: 'San Mateo Piñas',
        value: 'CT_SAN_MATEO_PINAS'
      },
      {
        label: 'San Mateo Río Hondo',
        value: 'CT_SAN_MATEO_RIO_HONDO'
      },
      {
        label: 'San Mateo Sindihui',
        value: 'CT_SAN_MATEO_SINDIHUI'
      },
      {
        label: 'San Mateo Tlapiltepec',
        value: 'CT_SAN_MATEO_TLAPILTEPEC'
      },
      {
        label: 'San Melchor Betaza',
        value: 'CT_SAN_MELCHOR_BETAZA'
      },
      {
        label: 'San Miguel Achiutla',
        value: 'CT_SAN_MIGUEL_ACHIUTLA'
      },
      {
        label: 'San Miguel Ahuehuetitlán',
        value: 'CT_SAN_MIGUEL_AHUEHUETITLAN'
      },
      {
        label: 'San Miguel Aloápam',
        value: 'CT_SAN_MIGUEL_ALOAPAM'
      },
      {
        label: 'San Miguel Amatitlán',
        value: 'CT_SAN_MIGUEL_AMATITLAN'
      },
      {
        label: 'San Miguel Amatlán',
        value: 'CT_SAN_MIGUEL_AMATLAN'
      },
      {
        label: 'San Miguel Coatlán',
        value: 'CT_SAN_MIGUEL_COATLAN'
      },
      {
        label: 'San Miguel Chicahua',
        value: 'CT_SAN_MIGUEL_CHICAHUA'
      },
      {
        label: 'San Miguel Chimalapa',
        value: 'CT_SAN_MIGUEL_CHIMALAPA'
      },
      {
        label: 'San Miguel del Puerto',
        value: 'CT_SAN_MIGUEL_DEL_PUERTO'
      },
      {
        label: 'San Miguel del Río',
        value: 'CT_SAN_MIGUEL_DEL_RIO'
      },
      {
        label: 'San Miguel Ejutla',
        value: 'CT_SAN_MIGUEL_EJUTLA'
      },
      {
        label: 'San Miguel el Grande',
        value: 'CT_SAN_MIGUEL_EL_GRANDE'
      },
      {
        label: 'San Miguel Huautla',
        value: 'CT_SAN_MIGUEL_HUAUTLA'
      },
      {
        label: 'San Miguel Mixtepec',
        value: 'CT_SAN_MIGUEL_MIXTEPEC'
      },
      {
        label: 'San Miguel Panixtlahuaca',
        value: 'CT_SAN_MIGUEL_PANIXTLAHUACA'
      },
      {
        label: 'San Miguel Peras',
        value: 'CT_SAN_MIGUEL_PERAS'
      },
      {
        label: 'San Miguel Piedras',
        value: 'CT_SAN_MIGUEL_PIEDRAS'
      },
      {
        label: 'San Miguel Quetzaltepec',
        value: 'CT_SAN_MIGUEL_QUETZALTEPEC'
      },
      {
        label: 'San Miguel Santa Flor',
        value: 'CT_SAN_MIGUEL_SANTA_FLOR'
      },
      {
        label: 'Villa Sola de Vega',
        value: 'CT_VILLA_SOLA_DE_VEGA'
      },
      {
        label: 'San Miguel Soyaltepec',
        value: 'CT_SAN_MIGUEL_SOYALTEPEC'
      },
      {
        label: 'San Miguel Suchixtepec',
        value: 'CT_SAN_MIGUEL_SUCHIXTEPEC'
      },
      {
        label: 'Villa Talea de Castro',
        value: 'CT_VILLA_TALEA_DE_CASTRO'
      },
      {
        label: 'San Miguel Tecomatlán',
        value: 'CT_SAN_MIGUEL_TECOMATLAN'
      },
      {
        label: 'San Miguel Tenango',
        value: 'CT_SAN_MIGUEL_TENANGO'
      },
      {
        label: 'San Miguel Tequixtepec',
        value: 'CT_SAN_MIGUEL_TEQUIXTEPEC'
      },
      {
        label: 'San Miguel Tilquiápam',
        value: 'CT_SAN_MIGUEL_TILQUIAPAM'
      },
      {
        label: 'San Miguel Tlacamama',
        value: 'CT_SAN_MIGUEL_TLACAMAMA'
      },
      {
        label: 'San Miguel Tlacotepec',
        value: 'CT_SAN_MIGUEL_TLACOTEPEC'
      },
      {
        label: 'San Miguel Tulancingo',
        value: 'CT_SAN_MIGUEL_TULANCINGO'
      },
      {
        label: 'San Miguel Yotao',
        value: 'CT_SAN_MIGUEL_YOTAO'
      },
      { label: 'San Nicolás', value: 'CT_SAN_NICOLAS' },
      {
        label: 'San Nicolás Hidalgo',
        value: 'CT_SAN_NICOLAS_HIDALGO'
      },
      {
        label: 'San Pablo Coatlán',
        value: 'CT_SAN_PABLO_COATLAN'
      },
      {
        label: 'San Pablo Cuatro Venados',
        value: 'CT_SAN_PABLO_CUATRO_VENADOS'
      },
      {
        label: 'San Pablo Etla',
        value: 'CT_SAN_PABLO_ETLA'
      },
      {
        label: 'San Pablo Huitzo',
        value: 'CT_SAN_PABLO_HUITZO'
      },
      {
        label: 'San Pablo Huixtepec',
        value: 'CT_SAN_PABLO_HUIXTEPEC'
      },
      {
        label: 'San Pablo Macuiltianguis',
        value: 'CT_SAN_PABLO_MACUILTIANGUIS'
      },
      {
        label: 'San Pablo Tijaltepec',
        value: 'CT_SAN_PABLO_TIJALTEPEC'
      },
      {
        label: 'San Pablo Villa de Mitla',
        value: 'CT_SAN_PABLO_VILLA_DE_MITLA'
      },
      {
        label: 'San Pablo Yaganiza',
        value: 'CT_SAN_PABLO_YAGANIZA'
      },
      {
        label: 'San Pedro Amuzgos',
        value: 'CT_SAN_PEDRO_AMUZGOS'
      },
      {
        label: 'San Pedro Apóstol',
        value: 'CT_SAN_PEDRO_APOSTOL'
      },
      {
        label: 'San Pedro Atoyac',
        value: 'CT_SAN_PEDRO_ATOYAC'
      },
      {
        label: 'San Pedro Cajonos',
        value: 'CT_SAN_PEDRO_CAJONOS'
      },
      {
        label: 'San Pedro Coxcaltepec Cántaros',
        value: 'CT_SAN_PEDRO_COXCALTEPEC_CANTAROS'
      },
      {
        label: 'San Pedro Comitancillo',
        value: 'CT_SAN_PEDRO_COMITANCILLO'
      },
      {
        label: 'San Pedro el Alto',
        value: 'CT_SAN_PEDRO_EL_ALTO'
      },
      {
        label: 'San Pedro Huamelula',
        value: 'CT_SAN_PEDRO_HUAMELULA'
      },
      {
        label: 'San Pedro Huilotepec',
        value: 'CT_SAN_PEDRO_HUILOTEPEC'
      },
      {
        label: 'San Pedro Ixcatlán',
        value: 'CT_SAN_PEDRO_IXCATLAN'
      },
      {
        label: 'San Pedro Ixtlahuaca',
        value: 'CT_SAN_PEDRO_IXTLAHUACA'
      },
      {
        label: 'San Pedro Jaltepetongo',
        value: 'CT_SAN_PEDRO_JALTEPETONGO'
      },
      {
        label: 'San Pedro Jicayán',
        value: 'CT_SAN_PEDRO_JICAYAN'
      },
      {
        label: 'San Pedro Jocotipac',
        value: 'CT_SAN_PEDRO_JOCOTIPAC'
      },
      {
        label: 'San Pedro Juchatengo',
        value: 'CT_SAN_PEDRO_JUCHATENGO'
      },
      {
        label: 'San Pedro Mártir',
        value: 'CT_SAN_PEDRO_MARTIR'
      },
      {
        label: 'San Pedro Mártir Quiechapa',
        value: 'CT_SAN_PEDRO_MARTIR_QUIECHAPA'
      },
      {
        label: 'San Pedro Mártir Yucuxaco',
        value: 'CT_SAN_PEDRO_MARTIR_YUCUXACO'
      },
      {
        label: 'San Pedro Mixtepec',
        value: 'CT_SAN_PEDRO_MIXTEPEC'
      },
      {
        label: 'San Pedro Mixtepec',
        value: 'CT_SAN_PEDRO_MIXTEPEC'
      },
      {
        label: 'San Pedro Molinos',
        value: 'CT_SAN_PEDRO_MOLINOS'
      },
      {
        label: 'San Pedro Nopala',
        value: 'CT_SAN_PEDRO_NOPALA'
      },
      {
        label: 'San Pedro Ocopetatillo',
        value: 'CT_SAN_PEDRO_OCOPETATILLO'
      },
      {
        label: 'San Pedro Ocotepec',
        value: 'CT_SAN_PEDRO_OCOTEPEC'
      },
      {
        label: 'San Pedro Pochutla',
        value: 'CT_SAN_PEDRO_POCHUTLA'
      },
      {
        label: 'San Pedro Quiatoni',
        value: 'CT_SAN_PEDRO_QUIATONI'
      },
      {
        label: 'San Pedro Sochiápam',
        value: 'CT_SAN_PEDRO_SOCHIAPAM'
      },
      {
        label: 'San Pedro Tapanatepec',
        value: 'CT_SAN_PEDRO_TAPANATEPEC'
      },
      {
        label: 'San Pedro Taviche',
        value: 'CT_SAN_PEDRO_TAVICHE'
      },
      {
        label: 'San Pedro Teozacoalco',
        value: 'CT_SAN_PEDRO_TEOZACOALCO'
      },
      {
        label: 'San Pedro Teutila',
        value: 'CT_SAN_PEDRO_TEUTILA'
      },
      {
        label: 'San Pedro Tidaá',
        value: 'CT_SAN_PEDRO_TIDAA'
      },
      {
        label: 'San Pedro Topiltepec',
        value: 'CT_SAN_PEDRO_TOPILTEPEC'
      },
      {
        label: 'San Pedro Totolápam',
        value: 'CT_SAN_PEDRO_TOTOLAPAM'
      },
      {
        label: 'Villa de Tututepec',
        value: 'CT_VILLA_DE_TUTUTEPEC'
      },
      {
        label: 'San Pedro Yaneri',
        value: 'CT_SAN_PEDRO_YANERI'
      },
      {
        label: 'San Pedro Yólox',
        value: 'CT_SAN_PEDRO_YOLOX'
      },
      {
        label: 'San Pedro y San Pablo Ayutla',
        value: 'CT_SAN_PEDRO_Y_SAN_PABLO_AYUTLA'
      },
      {
        label: 'Villa de Etla',
        value: 'CT_VILLA_DE_ETLA'
      },
      {
        label: 'San Pedro y San Pablo Teposcolula',
        value: 'CT_SAN_PEDRO_Y_SAN_PABLO_TEPOSCOLULA'
      },
      {
        label: 'San Pedro y San Pablo Tequixtepec',
        value: 'CT_SAN_PEDRO_Y_SAN_PABLO_TEQUIXTEPEC'
      },
      {
        label: 'San Pedro Yucunama',
        value: 'CT_SAN_PEDRO_YUCUNAMA'
      },
      {
        label: 'San Raymundo Jalpan',
        value: 'CT_SAN_RAYMUNDO_JALPAN'
      },
      {
        label: 'San Sebastián Abasolo',
        value: 'CT_SAN_SEBASTIAN_ABASOLO'
      },
      {
        label: 'San Sebastián Coatlán',
        value: 'CT_SAN_SEBASTIAN_COATLAN'
      },
      {
        label: 'San Sebastián Ixcapa',
        value: 'CT_SAN_SEBASTIAN_IXCAPA'
      },
      {
        label: 'San Sebastián Nicananduta',
        value: 'CT_SAN_SEBASTIAN_NICANANDUTA'
      },
      {
        label: 'San Sebastián Río Hondo',
        value: 'CT_SAN_SEBASTIAN_RIO_HONDO'
      },
      {
        label: 'San Sebastián Tecomaxtlahuaca',
        value: 'CT_SAN_SEBASTIAN_TECOMAXTLAHUACA'
      },
      {
        label: 'San Sebastián Teitipac',
        value: 'CT_SAN_SEBASTIAN_TEITIPAC'
      },
      {
        label: 'San Sebastián Tutla',
        value: 'CT_SAN_SEBASTIAN_TUTLA'
      },
      {
        label: 'San Simón Almolongas',
        value: 'CT_SAN_SIMON_ALMOLONGAS'
      },
      {
        label: 'San Simón Zahuatlán',
        value: 'CT_SAN_SIMON_ZAHUATLAN'
      },
      { label: 'Santa Ana', value: 'CT_SANTA_ANA' },
      {
        label: 'Santa Ana Ateixtlahuaca',
        value: 'CT_SANTA_ANA_ATEIXTLAHUACA'
      },
      {
        label: 'Santa Ana Cuauhtémoc',
        value: 'CT_SANTA_ANA_CUAUHTEMOC'
      },
      {
        label: 'Santa Ana del Valle',
        value: 'CT_SANTA_ANA_DEL_VALLE'
      },
      {
        label: 'Santa Ana Tavela',
        value: 'CT_SANTA_ANA_TAVELA'
      },
      {
        label: 'Santa Ana Tlapacoyan',
        value: 'CT_SANTA_ANA_TLAPACOYAN'
      },
      {
        label: 'Santa Ana Yareni',
        value: 'CT_SANTA_ANA_YARENI'
      },
      {
        label: 'Santa Ana Zegache',
        value: 'CT_SANTA_ANA_ZEGACHE'
      },
      {
        label: 'Santa Catalina Quierí',
        value: 'CT_SANTA_CATALINA_QUIERI'
      },
      {
        label: 'Santa Catarina Cuixtla',
        value: 'CT_SANTA_CATARINA_CUIXTLA'
      },
      {
        label: 'Santa Catarina Ixtepeji',
        value: 'CT_SANTA_CATARINA_IXTEPEJI'
      },
      {
        label: 'Santa Catarina Juquila',
        value: 'CT_SANTA_CATARINA_JUQUILA'
      },
      {
        label: 'Santa Catarina Lachatao',
        value: 'CT_SANTA_CATARINA_LACHATAO'
      },
      {
        label: 'Santa Catarina Loxicha',
        value: 'CT_SANTA_CATARINA_LOXICHA'
      },
      {
        label: 'Santa Catarina Mechoacán',
        value: 'CT_SANTA_CATARINA_MECHOACAN'
      },
      {
        label: 'Santa Catarina Minas',
        value: 'CT_SANTA_CATARINA_MINAS'
      },
      {
        label: 'Santa Catarina Quiané',
        value: 'CT_SANTA_CATARINA_QUIANE'
      },
      {
        label: 'Santa Catarina Tayata',
        value: 'CT_SANTA_CATARINA_TAYATA'
      },
      {
        label: 'Santa Catarina Ticuá',
        value: 'CT_SANTA_CATARINA_TICUA'
      },
      {
        label: 'Santa Catarina Yosonotú',
        value: 'CT_SANTA_CATARINA_YOSONOTU'
      },
      {
        label: 'Santa Catarina Zapoquila',
        value: 'CT_SANTA_CATARINA_ZAPOQUILA'
      },
      {
        label: 'Santa Cruz Acatepec',
        value: 'CT_SANTA_CRUZ_ACATEPEC'
      },
      {
        label: 'Santa Cruz Amilpas',
        value: 'CT_SANTA_CRUZ_AMILPAS'
      },
      {
        label: 'Santa Cruz de Bravo',
        value: 'CT_SANTA_CRUZ_DE_BRAVO'
      },
      {
        label: 'Santa Cruz Itundujia',
        value: 'CT_SANTA_CRUZ_ITUNDUJIA'
      },
      {
        label: 'Santa Cruz Mixtepec',
        value: 'CT_SANTA_CRUZ_MIXTEPEC'
      },
      {
        label: 'Santa Cruz Nundaco',
        value: 'CT_SANTA_CRUZ_NUNDACO'
      },
      {
        label: 'Santa Cruz Papalutla',
        value: 'CT_SANTA_CRUZ_PAPALUTLA'
      },
      {
        label: 'Santa Cruz Tacache de Mina',
        value: 'CT_SANTA_CRUZ_TACACHE_DE_MINA'
      },
      {
        label: 'Santa Cruz Tacahua',
        value: 'CT_SANTA_CRUZ_TACAHUA'
      },
      {
        label: 'Santa Cruz Tayata',
        value: 'CT_SANTA_CRUZ_TAYATA'
      },
      {
        label: 'Santa Cruz Xitla',
        value: 'CT_SANTA_CRUZ_XITLA'
      },
      {
        label: 'Santa Cruz Xoxocotlán',
        value: 'CT_SANTA_CRUZ_XOXOCOTLAN'
      },
      {
        label: 'Santa Cruz Zenzontepec',
        value: 'CT_SANTA_CRUZ_ZENZONTEPEC'
      },
      {
        label: 'Santa Gertrudis',
        value: 'CT_SANTA_GERTRUDIS'
      },
      {
        label: 'Santa Inés del Monte',
        value: 'CT_SANTA_INES_DEL_MONTE'
      },
      {
        label: 'Santa Inés Yatzeche',
        value: 'CT_SANTA_INES_YATZECHE'
      },
      {
        label: 'Santa Lucía del Camino',
        value: 'CT_SANTA_LUCIA_DEL_CAMINO'
      },
      {
        label: 'Santa Lucía Miahuatlán',
        value: 'CT_SANTA_LUCIA_MIAHUATLAN'
      },
      {
        label: 'Santa Lucía Monteverde',
        value: 'CT_SANTA_LUCIA_MONTEVERDE'
      },
      {
        label: 'Santa Lucía Ocotlán',
        value: 'CT_SANTA_LUCIA_OCOTLAN'
      },
      {
        label: 'Santa María Alotepec',
        value: 'CT_SANTA_MARIA_ALOTEPEC'
      },
      {
        label: 'Santa María Apazco',
        value: 'CT_SANTA_MARIA_APAZCO'
      },
      {
        label: 'Santa María la Asunción',
        value: 'CT_SANTA_MARIA_LA_ASUNCION'
      },
      {
        label: 'Heroica Ciudad de Tlaxiaco',
        value: 'CT_HEROICA_CIUDAD_DE_TLAXIACO'
      },
      {
        label: 'Ayoquezco de Aldama',
        value: 'CT_AYOQUEZCO_DE_ALDAMA'
      },
      {
        label: 'Santa María Atzompa',
        value: 'CT_SANTA_MARIA_ATZOMPA'
      },
      {
        label: 'Santa María Camotlán',
        value: 'CT_SANTA_MARIA_CAMOTLAN'
      },
      {
        label: 'Santa María Colotepec',
        value: 'CT_SANTA_MARIA_COLOTEPEC'
      },
      {
        label: 'Santa María Cortijo',
        value: 'CT_SANTA_MARIA_CORTIJO'
      },
      {
        label: 'Santa María Coyotepec',
        value: 'CT_SANTA_MARIA_COYOTEPEC'
      },
      {
        label: 'Santa María Chachoápam',
        value: 'CT_SANTA_MARIA_CHACHOAPAM'
      },
      {
        label: 'Villa de Chilapa de Díaz',
        value: 'CT_VILLA_DE_CHILAPA_DE_DIAZ'
      },
      {
        label: 'Santa María Chilchotla',
        value: 'CT_SANTA_MARIA_CHILCHOTLA'
      },
      {
        label: 'Santa María Chimalapa',
        value: 'CT_SANTA_MARIA_CHIMALAPA'
      },
      {
        label: 'Santa María del Rosario',
        value: 'CT_SANTA_MARIA_DEL_ROSARIO'
      },
      {
        label: 'Santa María del Tule',
        value: 'CT_SANTA_MARIA_DEL_TULE'
      },
      {
        label: 'Santa María Ecatepec',
        value: 'CT_SANTA_MARIA_ECATEPEC'
      },
      {
        label: 'Santa María Guelacé',
        value: 'CT_SANTA_MARIA_GUELACE'
      },
      {
        label: 'Santa María Guienagati',
        value: 'CT_SANTA_MARIA_GUIENAGATI'
      },
      {
        label: 'Santa María Huatulco',
        value: 'CT_SANTA_MARIA_HUATULCO'
      },
      {
        label: 'Santa María Huazolotitlán',
        value: 'CT_SANTA_MARIA_HUAZOLOTITLAN'
      },
      {
        label: 'Santa María Ipalapa',
        value: 'CT_SANTA_MARIA_IPALAPA'
      },
      {
        label: 'Santa María Ixcatlán',
        value: 'CT_SANTA_MARIA_IXCATLAN'
      },
      {
        label: 'Santa María Jacatepec',
        value: 'CT_SANTA_MARIA_JACATEPEC'
      },
      {
        label: 'Santa María Jalapa del Marqués',
        value: 'CT_SANTA_MARIA_JALAPA_DEL_MARQUES'
      },
      {
        label: 'Santa María Jaltianguis',
        value: 'CT_SANTA_MARIA_JALTIANGUIS'
      },
      {
        label: 'Santa María Lachixío',
        value: 'CT_SANTA_MARIA_LACHIXIO'
      },
      {
        label: 'Santa María Mixtequilla',
        value: 'CT_SANTA_MARIA_MIXTEQUILLA'
      },
      {
        label: 'Santa María Nativitas',
        value: 'CT_SANTA_MARIA_NATIVITAS'
      },
      {
        label: 'Santa María Nduayaco',
        value: 'CT_SANTA_MARIA_NDUAYACO'
      },
      {
        label: 'Santa María Ozolotepec',
        value: 'CT_SANTA_MARIA_OZOLOTEPEC'
      },
      {
        label: 'Santa María Pápalo',
        value: 'CT_SANTA_MARIA_PAPALO'
      },
      {
        label: 'Santa María Peñoles',
        value: 'CT_SANTA_MARIA_PENOLES'
      },
      {
        label: 'Santa María Petapa',
        value: 'CT_SANTA_MARIA_PETAPA'
      },
      {
        label: 'Santa María Quiegolani',
        value: 'CT_SANTA_MARIA_QUIEGOLANI'
      },
      {
        label: 'Santa María Sola',
        value: 'CT_SANTA_MARIA_SOLA'
      },
      {
        label: 'Santa María Tataltepec',
        value: 'CT_SANTA_MARIA_TATALTEPEC'
      },
      {
        label: 'Santa María Tecomavaca',
        value: 'CT_SANTA_MARIA_TECOMAVACA'
      },
      {
        label: 'Santa María Temaxcalapa',
        value: 'CT_SANTA_MARIA_TEMAXCALAPA'
      },
      {
        label: 'Santa María Temaxcaltepec',
        value: 'CT_SANTA_MARIA_TEMAXCALTEPEC'
      },
      {
        label: 'Santa María Teopoxco',
        value: 'CT_SANTA_MARIA_TEOPOXCO'
      },
      {
        label: 'Santa María Tepantlali',
        value: 'CT_SANTA_MARIA_TEPANTLALI'
      },
      {
        label: 'Santa María Texcatitlán',
        value: 'CT_SANTA_MARIA_TEXCATITLAN'
      },
      {
        label: 'Santa María Tlahuitoltepec',
        value: 'CT_SANTA_MARIA_TLAHUITOLTEPEC'
      },
      {
        label: 'Santa María Tlalixtac',
        value: 'CT_SANTA_MARIA_TLALIXTAC'
      },
      {
        label: 'Santa María Tonameca',
        value: 'CT_SANTA_MARIA_TONAMECA'
      },
      {
        label: 'Santa María Totolapilla',
        value: 'CT_SANTA_MARIA_TOTOLAPILLA'
      },
      {
        label: 'Santa María Xadani',
        value: 'CT_SANTA_MARIA_XADANI'
      },
      {
        label: 'Santa María Yalina',
        value: 'CT_SANTA_MARIA_YALINA'
      },
      {
        label: 'Santa María Yavesía',
        value: 'CT_SANTA_MARIA_YAVESIA'
      },
      {
        label: 'Santa María Yolotepec',
        value: 'CT_SANTA_MARIA_YOLOTEPEC'
      },
      {
        label: 'Santa María Yosoyúa',
        value: 'CT_SANTA_MARIA_YOSOYUA'
      },
      {
        label: 'Santa María Yucuhiti',
        value: 'CT_SANTA_MARIA_YUCUHITI'
      },
      {
        label: 'Santa María Zacatepec',
        value: 'CT_SANTA_MARIA_ZACATEPEC'
      },
      {
        label: 'Santa María Zaniza',
        value: 'CT_SANTA_MARIA_ZANIZA'
      },
      {
        label: 'Santa María Zoquitlán',
        value: 'CT_SANTA_MARIA_ZOQUITLAN'
      },
      {
        label: 'Santiago Amoltepec',
        value: 'CT_SANTIAGO_AMOLTEPEC'
      },
      {
        label: 'Santiago Apoala',
        value: 'CT_SANTIAGO_APOALA'
      },
      {
        label: 'Santiago Apóstol',
        value: 'CT_SANTIAGO_APOSTOL'
      },
      {
        label: 'Santiago Astata',
        value: 'CT_SANTIAGO_ASTATA'
      },
      {
        label: 'Santiago Atitlán',
        value: 'CT_SANTIAGO_ATITLAN'
      },
      {
        label: 'Santiago Ayuquililla',
        value: 'CT_SANTIAGO_AYUQUILILLA'
      },
      {
        label: 'Santiago Cacaloxtepec',
        value: 'CT_SANTIAGO_CACALOXTEPEC'
      },
      {
        label: 'Santiago Camotlán',
        value: 'CT_SANTIAGO_CAMOTLAN'
      },
      {
        label: 'Santiago Comaltepec',
        value: 'CT_SANTIAGO_COMALTEPEC'
      },
      {
        label: 'Santiago Chazumba',
        value: 'CT_SANTIAGO_CHAZUMBA'
      },
      {
        label: 'Santiago Choápam',
        value: 'CT_SANTIAGO_CHOAPAM'
      },
      {
        label: 'Santiago del Río',
        value: 'CT_SANTIAGO_DEL_RIO'
      },
      {
        label: 'Santiago Huajolotitlán',
        value: 'CT_SANTIAGO_HUAJOLOTITLAN'
      },
      {
        label: 'Santiago Huauclilla',
        value: 'CT_SANTIAGO_HUAUCLILLA'
      },
      {
        label: 'Santiago Ihuitlán Plumas',
        value: 'CT_SANTIAGO_IHUITLAN_PLUMAS'
      },
      {
        label: 'Santiago Ixcuintepec',
        value: 'CT_SANTIAGO_IXCUINTEPEC'
      },
      {
        label: 'Santiago Ixtayutla',
        value: 'CT_SANTIAGO_IXTAYUTLA'
      },
      {
        label: 'Santiago Jamiltepec',
        value: 'CT_SANTIAGO_JAMILTEPEC'
      },
      {
        label: 'Santiago Jocotepec',
        value: 'CT_SANTIAGO_JOCOTEPEC'
      },
      {
        label: 'Santiago Juxtlahuaca',
        value: 'CT_SANTIAGO_JUXTLAHUACA'
      },
      {
        label: 'Santiago Lachiguiri',
        value: 'CT_SANTIAGO_LACHIGUIRI'
      },
      {
        label: 'Santiago Lalopa',
        value: 'CT_SANTIAGO_LALOPA'
      },
      {
        label: 'Santiago Laollaga',
        value: 'CT_SANTIAGO_LAOLLAGA'
      },
      {
        label: 'Santiago Laxopa',
        value: 'CT_SANTIAGO_LAXOPA'
      },
      {
        label: 'Santiago Llano Grande',
        value: 'CT_SANTIAGO_LLANO_GRANDE'
      },
      {
        label: 'Santiago Matatlán',
        value: 'CT_SANTIAGO_MATATLAN'
      },
      {
        label: 'Santiago Miltepec',
        value: 'CT_SANTIAGO_MILTEPEC'
      },
      {
        label: 'Santiago Minas',
        value: 'CT_SANTIAGO_MINAS'
      },
      {
        label: 'Santiago Nacaltepec',
        value: 'CT_SANTIAGO_NACALTEPEC'
      },
      {
        label: 'Santiago Nejapilla',
        value: 'CT_SANTIAGO_NEJAPILLA'
      },
      {
        label: 'Santiago Nundiche',
        value: 'CT_SANTIAGO_NUNDICHE'
      },
      {
        label: 'Santiago Nuyoó',
        value: 'CT_SANTIAGO_NUYOO'
      },
      {
        label: 'Santiago Pinotepa Nacional',
        value: 'CT_SANTIAGO_PINOTEPA_NACIONAL'
      },
      {
        label: 'Santiago Suchilquitongo',
        value: 'CT_SANTIAGO_SUCHILQUITONGO'
      },
      {
        label: 'Santiago Tamazola',
        value: 'CT_SANTIAGO_TAMAZOLA'
      },
      {
        label: 'Santiago Tapextla',
        value: 'CT_SANTIAGO_TAPEXTLA'
      },
      {
        label: 'Villa Tejúpam de la Unión',
        value: 'CT_VILLA_TEJUPAM_DE_LA_UNION'
      },
      {
        label: 'Santiago Tenango',
        value: 'CT_SANTIAGO_TENANGO'
      },
      {
        label: 'Santiago Tepetlapa',
        value: 'CT_SANTIAGO_TEPETLAPA'
      },
      {
        label: 'Santiago Tetepec',
        value: 'CT_SANTIAGO_TETEPEC'
      },
      {
        label: 'Santiago Texcalcingo',
        value: 'CT_SANTIAGO_TEXCALCINGO'
      },
      {
        label: 'Santiago Textitlán',
        value: 'CT_SANTIAGO_TEXTITLAN'
      },
      {
        label: 'Santiago Tilantongo',
        value: 'CT_SANTIAGO_TILANTONGO'
      },
      {
        label: 'Santiago Tillo',
        value: 'CT_SANTIAGO_TILLO'
      },
      {
        label: 'Santiago Tlazoyaltepec',
        value: 'CT_SANTIAGO_TLAZOYALTEPEC'
      },
      {
        label: 'Santiago Xanica',
        value: 'CT_SANTIAGO_XANICA'
      },
      {
        label: 'Santiago Xiacuí',
        value: 'CT_SANTIAGO_XIACUI'
      },
      {
        label: 'Santiago Yaitepec',
        value: 'CT_SANTIAGO_YAITEPEC'
      },
      {
        label: 'Santiago Yaveo',
        value: 'CT_SANTIAGO_YAVEO'
      },
      {
        label: 'Santiago Yolomécatl',
        value: 'CT_SANTIAGO_YOLOMECATL'
      },
      {
        label: 'Santiago Yosondúa',
        value: 'CT_SANTIAGO_YOSONDUA'
      },
      {
        label: 'Santiago Yucuyachi',
        value: 'CT_SANTIAGO_YUCUYACHI'
      },
      {
        label: 'Santiago Zacatepec',
        value: 'CT_SANTIAGO_ZACATEPEC'
      },
      {
        label: 'Santiago Zoochila',
        value: 'CT_SANTIAGO_ZOOCHILA'
      },
      {
        label: 'Nuevo Zoquiápam',
        value: 'CT_NUEVO_ZOQUIAPAM'
      },
      {
        label: 'Santo Domingo Ingenio',
        value: 'CT_SANTO_DOMINGO_INGENIO'
      },
      {
        label: 'Santo Domingo Albarradas',
        value: 'CT_SANTO_DOMINGO_ALBARRADAS'
      },
      {
        label: 'Santo Domingo Armenta',
        value: 'CT_SANTO_DOMINGO_ARMENTA'
      },
      {
        label: 'Santo Domingo Chihuitán',
        value: 'CT_SANTO_DOMINGO_CHIHUITAN'
      },
      {
        label: 'Santo Domingo de Morelos',
        value: 'CT_SANTO_DOMINGO_DE_MORELOS'
      },
      {
        label: 'Santo Domingo Ixcatlán',
        value: 'CT_SANTO_DOMINGO_IXCATLAN'
      },
      {
        label: 'Santo Domingo Nuxaá',
        value: 'CT_SANTO_DOMINGO_NUXAA'
      },
      {
        label: 'Santo Domingo Ozolotepec',
        value: 'CT_SANTO_DOMINGO_OZOLOTEPEC'
      },
      {
        label: 'Santo Domingo Petapa',
        value: 'CT_SANTO_DOMINGO_PETAPA'
      },
      {
        label: 'Santo Domingo Roayaga',
        value: 'CT_SANTO_DOMINGO_ROAYAGA'
      },
      {
        label: 'Santo Domingo Tehuantepec',
        value: 'CT_SANTO_DOMINGO_TEHUANTEPEC'
      },
      {
        label: 'Santo Domingo Teojomulco',
        value: 'CT_SANTO_DOMINGO_TEOJOMULCO'
      },
      {
        label: 'Santo Domingo Tepuxtepec',
        value: 'CT_SANTO_DOMINGO_TEPUXTEPEC'
      },
      {
        label: 'Santo Domingo Tlatayápam',
        value: 'CT_SANTO_DOMINGO_TLATAYAPAM'
      },
      {
        label: 'Santo Domingo Tomaltepec',
        value: 'CT_SANTO_DOMINGO_TOMALTEPEC'
      },
      {
        label: 'Santo Domingo Tonalá',
        value: 'CT_SANTO_DOMINGO_TONALA'
      },
      {
        label: 'Santo Domingo Tonaltepec',
        value: 'CT_SANTO_DOMINGO_TONALTEPEC'
      },
      {
        label: 'Santo Domingo Xagacía',
        value: 'CT_SANTO_DOMINGO_XAGACIA'
      },
      {
        label: 'Santo Domingo Yanhuitlán',
        value: 'CT_SANTO_DOMINGO_YANHUITLAN'
      },
      {
        label: 'Santo Domingo Yodohino',
        value: 'CT_SANTO_DOMINGO_YODOHINO'
      },
      {
        label: 'Santo Domingo Zanatepec',
        value: 'CT_SANTO_DOMINGO_ZANATEPEC'
      },
      {
        label: 'Santos Reyes Nopala',
        value: 'CT_SANTOS_REYES_NOPALA'
      },
      {
        label: 'Santos Reyes Pápalo',
        value: 'CT_SANTOS_REYES_PAPALO'
      },
      {
        label: 'Santos Reyes Tepejillo',
        value: 'CT_SANTOS_REYES_TEPEJILLO'
      },
      {
        label: 'Santos Reyes Yucuná',
        value: 'CT_SANTOS_REYES_YUCUNA'
      },
      {
        label: 'Santo Tomás Jalieza',
        value: 'CT_SANTO_TOMAS_JALIEZA'
      },
      {
        label: 'Santo Tomás Mazaltepec',
        value: 'CT_SANTO_TOMAS_MAZALTEPEC'
      },
      {
        label: 'Santo Tomás Ocotepec',
        value: 'CT_SANTO_TOMAS_OCOTEPEC'
      },
      {
        label: 'Santo Tomás Tamazulapan',
        value: 'CT_SANTO_TOMAS_TAMAZULAPAN'
      },
      {
        label: 'San Vicente Coatlán',
        value: 'CT_SAN_VICENTE_COATLAN'
      },
      {
        label: 'San Vicente Lachixío',
        value: 'CT_SAN_VICENTE_LACHIXIO'
      },
      {
        label: 'San Vicente Nuñú',
        value: 'CT_SAN_VICENTE_NUNU'
      },
      {
        label: 'Silacayoápam',
        value: 'CT_SILACAYOAPAM'
      },
      {
        label: 'Sitio de Xitlapehua',
        value: 'CT_SITIO_DE_XITLAPEHUA'
      },
      {
        label: 'Soledad Etla',
        value: 'CT_SOLEDAD_ETLA'
      },
      {
        label: 'Villa de Tamazulápam del Progreso',
        value: 'CT_VILLA_DE_TAMAZULAPAM_DEL_PROGRESO'
      },
      {
        label: 'Tanetze de Zaragoza',
        value: 'CT_TANETZE_DE_ZARAGOZA'
      },
      { label: 'Taniche', value: 'CT_TANICHE' },
      {
        label: 'Tataltepec de Valdés',
        value: 'CT_TATALTEPEC_DE_VALDES'
      },
      {
        label: 'Teococuilco de Marcos Pérez',
        value: 'CT_TEOCOCUILCO_DE_MARCOS_PEREZ'
      },
      {
        label: 'Teotitlán de Flores Magón',
        value: 'CT_TEOTITLAN_DE_FLORES_MAGON'
      },
      {
        label: 'Teotitlán del Valle',
        value: 'CT_TEOTITLAN_DEL_VALLE'
      },
      { label: 'Teotongo', value: 'CT_TEOTONGO' },
      {
        label: 'Tepelmeme Villa de Morelos',
        value: 'CT_TEPELMEME_VILLA_DE_MORELOS'
      },
      {
        label: 'Heroica Villa Tezoatlán de Segura y Luna, Cuna de la Independencia de Oaxaca',
        value: 'CT_HEROICA_VILLA_TEZOATLAN_DE_SEGURA_Y_LUNA,_CUNA_DE_LA_INDEPENDENCIA_DE_OAXACA'
      },
      {
        label: 'San Jerónimo Tlacochahuaya',
        value: 'CT_SAN_JERONIMO_TLACOCHAHUAYA'
      },
      {
        label: 'Tlacolula de Matamoros',
        value: 'CT_TLACOLULA_DE_MATAMOROS'
      },
      {
        label: 'Tlacotepec Plumas',
        value: 'CT_TLACOTEPEC_PLUMAS'
      },
      {
        label: 'Tlalixtac de Cabrera',
        value: 'CT_TLALIXTAC_DE_CABRERA'
      },
      {
        label: 'Totontepec Villa de Morelos',
        value: 'CT_TOTONTEPEC_VILLA_DE_MORELOS'
      },
      {
        label: 'Trinidad Zaachila',
        value: 'CT_TRINIDAD_ZAACHILA'
      },
      {
        label: 'La Trinidad Vista Hermosa',
        value: 'CT_LA_TRINIDAD_VISTA_HERMOSA'
      },
      {
        label: 'Unión Hidalgo',
        value: 'CT_UNION_HIDALGO'
      },
      {
        label: 'Valerio Trujano',
        value: 'CT_VALERIO_TRUJANO'
      },
      {
        label: 'San Juan Bautista Valle Nacional',
        value: 'CT_SAN_JUAN_BAUTISTA_VALLE_NACIONAL'
      },
      {
        label: 'Villa Díaz Ordaz',
        value: 'CT_VILLA_DIAZ_ORDAZ'
      },
      { label: 'Yaxe', value: 'CT_YAXE' },
      {
        label: 'Magdalena Yodocono de Porfirio Díaz',
        value: 'CT_MAGDALENA_YODOCONO_DE_PORFIRIO_DIAZ'
      },
      { label: 'Yogana', value: 'CT_YOGANA' },
      {
        label: 'Yutanduchi de Guerrero',
        value: 'CT_YUTANDUCHI_DE_GUERRERO'
      },
      {
        label: 'Villa de Zaachila',
        value: 'CT_VILLA_DE_ZAACHILA'
      },
      {
        label: 'San Mateo Yucutindoo',
        value: 'CT_SAN_MATEO_YUCUTINDOO'
      },
      {
        label: 'Zapotitlán Lagunas',
        value: 'CT_ZAPOTITLAN_LAGUNAS'
      },
      {
        label: 'Zapotitlán Palmas',
        value: 'CT_ZAPOTITLAN_PALMAS'
      },
      {
        label: 'Santa Inés de Zaragoza',
        value: 'CT_SANTA_INES_DE_ZARAGOZA'
      },
      {
        label: 'Zimatlán de Álvarez',
        value: 'CT_ZIMATLAN_DE_ALVAREZ'
      }
    ]
  },
  {
    label: "Puebla",
    value: "ST_PUEBLA",
    children: [
      { label: 'Acajete', value: 'CT_ACAJETE' },
      { label: 'Acateno', value: 'CT_ACATENO' },
      { label: 'Acatlán', value: 'CT_ACATLAN' },
      { label: 'Acatzingo', value: 'CT_ACATZINGO' },
      { label: 'Acteopan', value: 'CT_ACTEOPAN' },
      { label: 'Ahuacatlán', value: 'CT_AHUACATLAN' },
      { label: 'Ahuatlán', value: 'CT_AHUATLAN' },
      { label: 'Ahuazotepec', value: 'CT_AHUAZOTEPEC' },
      {
        label: 'Ahuehuetitla',
        value: 'CT_AHUEHUETITLA'
      },
      { label: 'Ajalpan', value: 'CT_AJALPAN' },
      {
        label: 'Albino Zertuche',
        value: 'CT_ALBINO_ZERTUCHE'
      },
      { label: 'Aljojuca', value: 'CT_ALJOJUCA' },
      { label: 'Altepexi', value: 'CT_ALTEPEXI' },
      { label: 'Amixtlán', value: 'CT_AMIXTLAN' },
      { label: 'Amozoc', value: 'CT_AMOZOC' },
      { label: 'Aquixtla', value: 'CT_AQUIXTLA' },
      { label: 'Atempan', value: 'CT_ATEMPAN' },
      { label: 'Atexcal', value: 'CT_ATEXCAL' },
      { label: 'Atlixco', value: 'CT_ATLIXCO' },
      { label: 'Atoyatempan', value: 'CT_ATOYATEMPAN' },
      { label: 'Atzala', value: 'CT_ATZALA' },
      {
        label: 'Atzitzihuacán',
        value: 'CT_ATZITZIHUACAN'
      },
      { label: 'Atzitzintla', value: 'CT_ATZITZINTLA' },
      { label: 'Axutla', value: 'CT_AXUTLA' },
      {
        label: 'Ayotoxco de Guerrero',
        value: 'CT_AYOTOXCO_DE_GUERRERO'
      },
      { label: 'Calpan', value: 'CT_CALPAN' },
      { label: 'Caltepec', value: 'CT_CALTEPEC' },
      { label: 'Camocuautla', value: 'CT_CAMOCUAUTLA' },
      { label: 'Caxhuacan', value: 'CT_CAXHUACAN' },
      { label: 'Coatepec', value: 'CT_COATEPEC' },
      { label: 'Coatzingo', value: 'CT_COATZINGO' },
      { label: 'Cohetzala', value: 'CT_COHETZALA' },
      { label: 'Cohuecan', value: 'CT_COHUECAN' },
      { label: 'Coronango', value: 'CT_CORONANGO' },
      { label: 'Coxcatlán', value: 'CT_COXCATLAN' },
      { label: 'Coyomeapan', value: 'CT_COYOMEAPAN' },
      { label: 'Coyotepec', value: 'CT_COYOTEPEC' },
      {
        label: 'Cuapiaxtla de Madero',
        value: 'CT_CUAPIAXTLA_DE_MADERO'
      },
      { label: 'Cuautempan', value: 'CT_CUAUTEMPAN' },
      { label: 'Cuautinchán', value: 'CT_CUAUTINCHAN' },
      {
        label: 'Cuautlancingo',
        value: 'CT_CUAUTLANCINGO'
      },
      {
        label: 'Cuayuca de Andrade',
        value: 'CT_CUAYUCA_DE_ANDRADE'
      },
      {
        label: 'Cuetzalan del Progreso',
        value: 'CT_CUETZALAN_DEL_PROGRESO'
      },
      { label: 'Cuyoaco', value: 'CT_CUYOACO' },
      {
        label: 'Chalchicomula de Sesma',
        value: 'CT_CHALCHICOMULA_DE_SESMA'
      },
      { label: 'Chapulco', value: 'CT_CHAPULCO' },
      { label: 'Chiautla', value: 'CT_CHIAUTLA' },
      { label: 'Chiautzingo', value: 'CT_CHIAUTZINGO' },
      {
        label: 'Chiconcuautla',
        value: 'CT_CHICONCUAUTLA'
      },
      { label: 'Chichiquila', value: 'CT_CHICHIQUILA' },
      { label: 'Chietla', value: 'CT_CHIETLA' },
      {
        label: 'Chigmecatitlán',
        value: 'CT_CHIGMECATITLAN'
      },
      {
        label: 'Chignahuapan',
        value: 'CT_CHIGNAHUAPAN'
      },
      { label: 'Chignautla', value: 'CT_CHIGNAUTLA' },
      { label: 'Chila', value: 'CT_CHILA' },
      {
        label: 'Chila de la Sal',
        value: 'CT_CHILA_DE_LA_SAL'
      },
      { label: 'Honey', value: 'CT_HONEY' },
      { label: 'Chilchotla', value: 'CT_CHILCHOTLA' },
      { label: 'Chinantla', value: 'CT_CHINANTLA' },
      {
        label: 'Domingo Arenas',
        value: 'CT_DOMINGO_ARENAS'
      },
      {
        label: 'Eloxochitlán',
        value: 'CT_ELOXOCHITLAN'
      },
      { label: 'Epatlán', value: 'CT_EPATLAN' },
      { label: 'Esperanza', value: 'CT_ESPERANZA' },
      {
        label: 'Francisco Z. Mena',
        value: 'CT_FRANCISCO_Z._MENA'
      },
      {
        label: 'General Felipe Ángeles',
        value: 'CT_GENERAL_FELIPE_ANGELES'
      },
      { label: 'Guadalupe', value: 'CT_GUADALUPE' },
      {
        label: 'Guadalupe Victoria',
        value: 'CT_GUADALUPE_VICTORIA'
      },
      {
        label: 'Hermenegildo Galeana',
        value: 'CT_HERMENEGILDO_GALEANA'
      },
      { label: 'Huaquechula', value: 'CT_HUAQUECHULA' },
      {
        label: 'Huatlatlauca',
        value: 'CT_HUATLATLAUCA'
      },
      {
        label: 'Huauchinango',
        value: 'CT_HUAUCHINANGO'
      },
      { label: 'Huehuetla', value: 'CT_HUEHUETLA' },
      {
        label: 'Huehuetlán el Chico',
        value: 'CT_HUEHUETLAN_EL_CHICO'
      },
      { label: 'Huejotzingo', value: 'CT_HUEJOTZINGO' },
      { label: 'Hueyapan', value: 'CT_HUEYAPAN' },
      { label: 'Hueytamalco', value: 'CT_HUEYTAMALCO' },
      { label: 'Hueytlalpan', value: 'CT_HUEYTLALPAN' },
      {
        label: 'Huitzilan de Serdán',
        value: 'CT_HUITZILAN_DE_SERDAN'
      },
      {
        label: 'Huitziltepec',
        value: 'CT_HUITZILTEPEC'
      },
      {
        label: 'Atlequizayan',
        value: 'CT_ATLEQUIZAYAN'
      },
      {
        label: 'Ixcamilpa de Guerrero',
        value: 'CT_IXCAMILPA_DE_GUERRERO'
      },
      { label: 'Ixcaquixtla', value: 'CT_IXCAQUIXTLA' },
      {
        label: 'Ixtacamaxtitlán',
        value: 'CT_IXTACAMAXTITLAN'
      },
      { label: 'Ixtepec', value: 'CT_IXTEPEC' },
      {
        label: 'Izúcar de Matamoros',
        value: 'CT_IZUCAR_DE_MATAMOROS'
      },
      { label: 'Jalpan', value: 'CT_JALPAN' },
      { label: 'Jolalpan', value: 'CT_JOLALPAN' },
      { label: 'Jonotla', value: 'CT_JONOTLA' },
      { label: 'Jopala', value: 'CT_JOPALA' },
      {
        label: 'Juan C. Bonilla',
        value: 'CT_JUAN_C._BONILLA'
      },
      {
        label: 'Juan Galindo',
        value: 'CT_JUAN_GALINDO'
      },
      {
        label: 'Juan N. Méndez',
        value: 'CT_JUAN_N._MENDEZ'
      },
      { label: 'Lafragua', value: 'CT_LAFRAGUA' },
      { label: 'Libres', value: 'CT_LIBRES' },
      {
        label: 'La Magdalena Tlatlauquitepec',
        value: 'CT_LA_MAGDALENA_TLATLAUQUITEPEC'
      },
      {
        label: 'Mazapiltepec de Juárez',
        value: 'CT_MAZAPILTEPEC_DE_JUAREZ'
      },
      { label: 'Mixtla', value: 'CT_MIXTLA' },
      { label: 'Molcaxac', value: 'CT_MOLCAXAC' },
      {
        label: 'Cañada Morelos',
        value: 'CT_CANADA_MORELOS'
      },
      { label: 'Naupan', value: 'CT_NAUPAN' },
      { label: 'Nauzontla', value: 'CT_NAUZONTLA' },
      { label: 'Nealtican', value: 'CT_NEALTICAN' },
      {
        label: 'Nicolás Bravo',
        value: 'CT_NICOLAS_BRAVO'
      },
      { label: 'Nopalucan', value: 'CT_NOPALUCAN' },
      { label: 'Ocotepec', value: 'CT_OCOTEPEC' },
      { label: 'Ocoyucan', value: 'CT_OCOYUCAN' },
      { label: 'Olintla', value: 'CT_OLINTLA' },
      { label: 'Oriental', value: 'CT_ORIENTAL' },
      { label: 'Pahuatlán', value: 'CT_PAHUATLAN' },
      {
        label: 'Palmar de Bravo',
        value: 'CT_PALMAR_DE_BRAVO'
      },
      { label: 'Pantepec', value: 'CT_PANTEPEC' },
      { label: 'Petlalcingo', value: 'CT_PETLALCINGO' },
      { label: 'Piaxtla', value: 'CT_PIAXTLA' },
      { label: 'Puebla', value: 'CT_PUEBLA' },
      { label: 'Quecholac', value: 'CT_QUECHOLAC' },
      { label: 'Quimixtlán', value: 'CT_QUIMIXTLAN' },
      {
        label: 'Rafael Lara Grajales',
        value: 'CT_RAFAEL_LARA_GRAJALES'
      },
      {
        label: 'Los Reyes de Juárez',
        value: 'CT_LOS_REYES_DE_JUAREZ'
      },
      {
        label: 'San Andrés Cholula',
        value: 'CT_SAN_ANDRES_CHOLULA'
      },
      {
        label: 'San Antonio Cañada',
        value: 'CT_SAN_ANTONIO_CANADA'
      },
      {
        label: 'San Diego la Mesa Tochimiltzingo',
        value: 'CT_SAN_DIEGO_LA_MESA_TOCHIMILTZINGO'
      },
      {
        label: 'San Felipe Teotlalcingo',
        value: 'CT_SAN_FELIPE_TEOTLALCINGO'
      },
      {
        label: 'San Felipe Tepatlán',
        value: 'CT_SAN_FELIPE_TEPATLAN'
      },
      {
        label: 'San Gabriel Chilac',
        value: 'CT_SAN_GABRIEL_CHILAC'
      },
      {
        label: 'San Gregorio Atzompa',
        value: 'CT_SAN_GREGORIO_ATZOMPA'
      },
      {
        label: 'San Jerónimo Tecuanipan',
        value: 'CT_SAN_JERONIMO_TECUANIPAN'
      },
      {
        label: 'San Jerónimo Xayacatlán',
        value: 'CT_SAN_JERONIMO_XAYACATLAN'
      },
      {
        label: 'San José Chiapa',
        value: 'CT_SAN_JOSE_CHIAPA'
      },
      {
        label: 'San José Miahuatlán',
        value: 'CT_SAN_JOSE_MIAHUATLAN'
      },
      {
        label: 'San Juan Atenco',
        value: 'CT_SAN_JUAN_ATENCO'
      },
      {
        label: 'San Juan Atzompa',
        value: 'CT_SAN_JUAN_ATZOMPA'
      },
      {
        label: 'San Martín Texmelucan',
        value: 'CT_SAN_MARTIN_TEXMELUCAN'
      },
      {
        label: 'San Martín Totoltepec',
        value: 'CT_SAN_MARTIN_TOTOLTEPEC'
      },
      {
        label: 'San Matías Tlalancaleca',
        value: 'CT_SAN_MATIAS_TLALANCALECA'
      },
      {
        label: 'San Miguel Ixitlán',
        value: 'CT_SAN_MIGUEL_IXITLAN'
      },
      {
        label: 'San Miguel Xoxtla',
        value: 'CT_SAN_MIGUEL_XOXTLA'
      },
      {
        label: 'San Nicolás Buenos Aires',
        value: 'CT_SAN_NICOLAS_BUENOS_AIRES'
      },
      {
        label: 'San Nicolás de los Ranchos',
        value: 'CT_SAN_NICOLAS_DE_LOS_RANCHOS'
      },
      {
        label: 'San Pablo Anicano',
        value: 'CT_SAN_PABLO_ANICANO'
      },
      {
        label: 'San Pedro Cholula',
        value: 'CT_SAN_PEDRO_CHOLULA'
      },
      {
        label: 'San Pedro Yeloixtlahuaca',
        value: 'CT_SAN_PEDRO_YELOIXTLAHUACA'
      },
      {
        label: 'San Salvador el Seco',
        value: 'CT_SAN_SALVADOR_EL_SECO'
      },
      {
        label: 'San Salvador el Verde',
        value: 'CT_SAN_SALVADOR_EL_VERDE'
      },
      {
        label: 'San Salvador Huixcolotla',
        value: 'CT_SAN_SALVADOR_HUIXCOLOTLA'
      },
      {
        label: 'San Sebastián Tlacotepec',
        value: 'CT_SAN_SEBASTIAN_TLACOTEPEC'
      },
      {
        label: 'Santa Catarina Tlaltempan',
        value: 'CT_SANTA_CATARINA_TLALTEMPAN'
      },
      {
        label: 'Santa Inés Ahuatempan',
        value: 'CT_SANTA_INES_AHUATEMPAN'
      },
      {
        label: 'Santa Isabel Cholula',
        value: 'CT_SANTA_ISABEL_CHOLULA'
      },
      {
        label: 'Santiago Miahuatlán',
        value: 'CT_SANTIAGO_MIAHUATLAN'
      },
      {
        label: 'Huehuetlán el Grande',
        value: 'CT_HUEHUETLAN_EL_GRANDE'
      },
      {
        label: 'Santo Tomás Hueyotlipan',
        value: 'CT_SANTO_TOMAS_HUEYOTLIPAN'
      },
      { label: 'Soltepec', value: 'CT_SOLTEPEC' },
      {
        label: 'Tecali de Herrera',
        value: 'CT_TECALI_DE_HERRERA'
      },
      {
        label: 'Tecamachalco',
        value: 'CT_TECAMACHALCO'
      },
      { label: 'Tecomatlán', value: 'CT_TECOMATLAN' },
      { label: 'Tehuacán', value: 'CT_TEHUACAN' },
      { label: 'Tehuitzingo', value: 'CT_TEHUITZINGO' },
      { label: 'Tenampulco', value: 'CT_TENAMPULCO' },
      { label: 'Teopantlán', value: 'CT_TEOPANTLAN' },
      { label: 'Teotlalco', value: 'CT_TEOTLALCO' },
      {
        label: 'Tepanco de López',
        value: 'CT_TEPANCO_DE_LOPEZ'
      },
      {
        label: 'Tepango de Rodríguez',
        value: 'CT_TEPANGO_DE_RODRIGUEZ'
      },
      {
        label: 'Tepatlaxco de Hidalgo',
        value: 'CT_TEPATLAXCO_DE_HIDALGO'
      },
      { label: 'Tepeaca', value: 'CT_TEPEACA' },
      { label: 'Tepemaxalco', value: 'CT_TEPEMAXALCO' },
      { label: 'Tepeojuma', value: 'CT_TEPEOJUMA' },
      { label: 'Tepetzintla', value: 'CT_TEPETZINTLA' },
      { label: 'Tepexco', value: 'CT_TEPEXCO' },
      {
        label: 'Tepexi de Rodríguez',
        value: 'CT_TEPEXI_DE_RODRIGUEZ'
      },
      {
        label: 'Tepeyahualco',
        value: 'CT_TEPEYAHUALCO'
      },
      {
        label: 'Tepeyahualco de Cuauhtémoc',
        value: 'CT_TEPEYAHUALCO_DE_CUAUHTEMOC'
      },
      {
        label: 'Tetela de Ocampo',
        value: 'CT_TETELA_DE_OCAMPO'
      },
      {
        label: 'Teteles de Avila Castillo',
        value: 'CT_TETELES_DE_AVILA_CASTILLO'
      },
      { label: 'Teziutlán', value: 'CT_TEZIUTLAN' },
      {
        label: 'Tianguismanalco',
        value: 'CT_TIANGUISMANALCO'
      },
      { label: 'Tilapa', value: 'CT_TILAPA' },
      {
        label: 'Tlacotepec de Benito Juárez',
        value: 'CT_TLACOTEPEC_DE_BENITO_JUAREZ'
      },
      {
        label: 'Tlacuilotepec',
        value: 'CT_TLACUILOTEPEC'
      },
      { label: 'Tlachichuca', value: 'CT_TLACHICHUCA' },
      { label: 'Tlahuapan', value: 'CT_TLAHUAPAN' },
      { label: 'Tlaltenango', value: 'CT_TLALTENANGO' },
      { label: 'Tlanepantla', value: 'CT_TLANEPANTLA' },
      { label: 'Tlaola', value: 'CT_TLAOLA' },
      { label: 'Tlapacoya', value: 'CT_TLAPACOYA' },
      { label: 'Tlapanalá', value: 'CT_TLAPANALA' },
      {
        label: 'Tlatlauquitepec',
        value: 'CT_TLATLAUQUITEPEC'
      },
      { label: 'Tlaxco', value: 'CT_TLAXCO' },
      { label: 'Tochimilco', value: 'CT_TOCHIMILCO' },
      { label: 'Tochtepec', value: 'CT_TOCHTEPEC' },
      {
        label: 'Totoltepec de Guerrero',
        value: 'CT_TOTOLTEPEC_DE_GUERRERO'
      },
      { label: 'Tulcingo', value: 'CT_TULCINGO' },
      {
        label: 'Tuzamapan de Galeana',
        value: 'CT_TUZAMAPAN_DE_GALEANA'
      },
      {
        label: 'Tzicatlacoyan',
        value: 'CT_TZICATLACOYAN'
      },
      {
        label: 'Venustiano Carranza',
        value: 'CT_VENUSTIANO_CARRANZA'
      },
      {
        label: 'Vicente Guerrero',
        value: 'CT_VICENTE_GUERRERO'
      },
      {
        label: 'Xayacatlán de Bravo',
        value: 'CT_XAYACATLAN_DE_BRAVO'
      },
      { label: 'Xicotepec', value: 'CT_XICOTEPEC' },
      { label: 'Xicotlán', value: 'CT_XICOTLAN' },
      { label: 'Xiutetelco', value: 'CT_XIUTETELCO' },
      { label: 'Xochiapulco', value: 'CT_XOCHIAPULCO' },
      { label: 'Xochiltepec', value: 'CT_XOCHILTEPEC' },
      {
        label: 'Xochitlán de Vicente Suárez',
        value: 'CT_XOCHITLAN_DE_VICENTE_SUAREZ'
      },
      {
        label: 'Xochitlán Todos Santos',
        value: 'CT_XOCHITLAN_TODOS_SANTOS'
      },
      { label: 'Yaonáhuac', value: 'CT_YAONAHUAC' },
      { label: 'Yehualtepec', value: 'CT_YEHUALTEPEC' },
      { label: 'Zacapala', value: 'CT_ZACAPALA' },
      { label: 'Zacapoaxtla', value: 'CT_ZACAPOAXTLA' },
      { label: 'Zacatlán', value: 'CT_ZACATLAN' },
      { label: 'Zapotitlán', value: 'CT_ZAPOTITLAN' },
      {
        label: 'Zapotitlán de Méndez',
        value: 'CT_ZAPOTITLAN_DE_MENDEZ'
      },
      { label: 'Zaragoza', value: 'CT_ZARAGOZA' },
      { label: 'Zautla', value: 'CT_ZAUTLA' },
      { label: 'Zihuateutla', value: 'CT_ZIHUATEUTLA' },
      { label: 'Zinacatepec', value: 'CT_ZINACATEPEC' },
      { label: 'Zongozotla', value: 'CT_ZONGOZOTLA' },
      { label: 'Zoquiapan', value: 'CT_ZOQUIAPAN' },
      { label: 'Zoquitlán', value: 'CT_ZOQUITLAN' }
    ]
  },
  {
    label: "Querétaro",
    value: "ST_QUERETARO",
    children: [
      {
        label: 'Amealco de Bonfil',
        value: 'CT_AMEALCO_DE_BONFIL'
      },
      {
        label: 'Pinal de Amoles',
        value: 'CT_PINAL_DE_AMOLES'
      },
      { label: 'Arroyo Seco', value: 'CT_ARROYO_SECO' },
      {
        label: 'Cadereyta de Montes',
        value: 'CT_CADEREYTA_DE_MONTES'
      },
      { label: 'Colón', value: 'CT_COLON' },
      { label: 'Corregidora', value: 'CT_CORREGIDORA' },
      {
        label: 'Ezequiel Montes',
        value: 'CT_EZEQUIEL_MONTES'
      },
      { label: 'Huimilpan', value: 'CT_HUIMILPAN' },
      {
        label: 'Jalpan de Serra',
        value: 'CT_JALPAN_DE_SERRA'
      },
      {
        label: 'Landa de Matamoros',
        value: 'CT_LANDA_DE_MATAMOROS'
      },
      { label: 'El Marqués', value: 'CT_EL_MARQUES' },
      {
        label: 'Pedro Escobedo',
        value: 'CT_PEDRO_ESCOBEDO'
      },
      { label: 'Peñamiller', value: 'CT_PENAMILLER' },
      { label: 'Querétaro', value: 'CT_QUERETARO' },
      { label: 'San Joaquín', value: 'CT_SAN_JOAQUIN' },
      {
        label: 'San Juan del Río',
        value: 'CT_SAN_JUAN_DEL_RIO'
      },
      {
        label: 'Tequisquiapan',
        value: 'CT_TEQUISQUIAPAN'
      },
      { label: 'Tolimán', value: 'CT_TOLIMAN' }
    ]
  },
  {
    label: "Quintana Roo",
    value: "ST_QUINTANA_ROO",
    children: [
      { label: 'Cozumel', value: 'CT_COZUMEL' },
      {
        label: 'Felipe Carrillo Puerto',
        value: 'CT_FELIPE_CARRILLO_PUERTO'
      },
      {
        label: 'Isla Mujeres',
        value: 'CT_ISLA_MUJERES'
      },
      {
        label: 'Othón P. Blanco',
        value: 'CT_OTHON_P._BLANCO'
      },
      {
        label: 'Benito Juárez',
        value: 'CT_BENITO_JUAREZ'
      },
      {
        label: 'José María Morelos',
        value: 'CT_JOSE_MARIA_MORELOS'
      },
      {
        label: 'Lázaro Cárdenas',
        value: 'CT_LAZARO_CARDENAS'
      },
      { label: 'Solidaridad', value: 'CT_SOLIDARIDAD' },
      { label: 'Tulum', value: 'CT_TULUM' },
      { label: 'Bacalar', value: 'CT_BACALAR' },
      {
        label: 'Puerto Morelos',
        value: 'CT_PUERTO_MORELOS'
      }
    ]
  },
  {
    label: "San Luis Potosí",
    value: "ST_SAN_LUIS_POTOSI",
    children: [
      { label: 'Ahualulco', value: 'CT_AHUALULCO' },
      { label: 'Alaquines', value: 'CT_ALAQUINES' },
      { label: 'Aquismón', value: 'CT_AQUISMON' },
      {
        label: 'Armadillo de los Infante',
        value: 'CT_ARMADILLO_DE_LOS_INFANTE'
      },
      { label: 'Cárdenas', value: 'CT_CARDENAS' },
      { label: 'Catorce', value: 'CT_CATORCE' },
      { label: 'Cedral', value: 'CT_CEDRAL' },
      { label: 'Cerritos', value: 'CT_CERRITOS' },
      {
        label: 'Cerro de San Pedro',
        value: 'CT_CERRO_DE_SAN_PEDRO'
      },
      {
        label: 'Ciudad del Maíz',
        value: 'CT_CIUDAD_DEL_MAIZ'
      },
      {
        label: 'Ciudad Fernández',
        value: 'CT_CIUDAD_FERNANDEZ'
      },
      { label: 'Tancanhuitz', value: 'CT_TANCANHUITZ' },
      {
        label: 'Ciudad Valles',
        value: 'CT_CIUDAD_VALLES'
      },
      { label: 'Coxcatlán', value: 'CT_COXCATLAN' },
      { label: 'Charcas', value: 'CT_CHARCAS' },
      { label: 'Ebano', value: 'CT_EBANO' },
      { label: 'Guadalcázar', value: 'CT_GUADALCAZAR' },
      { label: 'Huehuetlán', value: 'CT_HUEHUETLAN' },
      { label: 'Lagunillas', value: 'CT_LAGUNILLAS' },
      { label: 'Matehuala', value: 'CT_MATEHUALA' },
      {
        label: 'Mexquitic de Carmona',
        value: 'CT_MEXQUITIC_DE_CARMONA'
      },
      { label: 'Moctezuma', value: 'CT_MOCTEZUMA' },
      { label: 'Rayón', value: 'CT_RAYON' },
      { label: 'Rioverde', value: 'CT_RIOVERDE' },
      { label: 'Salinas', value: 'CT_SALINAS' },
      { label: 'San Antonio', value: 'CT_SAN_ANTONIO' },
      {
        label: 'San Ciro de Acosta',
        value: 'CT_SAN_CIRO_DE_ACOSTA'
      },
      {
        label: 'San Luis Potosí',
        value: 'CT_SAN_LUIS_POTOSI'
      },
      {
        label: 'San Martín Chalchicuautla',
        value: 'CT_SAN_MARTIN_CHALCHICUAUTLA'
      },
      {
        label: 'San Nicolás Tolentino',
        value: 'CT_SAN_NICOLAS_TOLENTINO'
      },
      {
        label: 'Santa Catarina',
        value: 'CT_SANTA_CATARINA'
      },
      {
        label: 'Santa María del Río',
        value: 'CT_SANTA_MARIA_DEL_RIO'
      },
      {
        label: 'Santo Domingo',
        value: 'CT_SANTO_DOMINGO'
      },
      {
        label: 'San Vicente Tancuayalab',
        value: 'CT_SAN_VICENTE_TANCUAYALAB'
      },
      {
        label: 'Soledad de Graciano Sánchez',
        value: 'CT_SOLEDAD_DE_GRACIANO_SANCHEZ'
      },
      { label: 'Tamasopo', value: 'CT_TAMASOPO' },
      {
        label: 'Tamazunchale',
        value: 'CT_TAMAZUNCHALE'
      },
      { label: 'Tampacán', value: 'CT_TAMPACAN' },
      {
        label: 'Tampamolón Corona',
        value: 'CT_TAMPAMOLON_CORONA'
      },
      { label: 'Tamuín', value: 'CT_TAMUIN' },
      { label: 'Tanlajás', value: 'CT_TANLAJAS' },
      {
        label: 'Tanquián de Escobedo',
        value: 'CT_TANQUIAN_DE_ESCOBEDO'
      },
      {
        label: 'Tierra Nueva',
        value: 'CT_TIERRA_NUEVA'
      },
      { label: 'Vanegas', value: 'CT_VANEGAS' },
      { label: 'Venado', value: 'CT_VENADO' },
      {
        label: 'Villa de Arriaga',
        value: 'CT_VILLA_DE_ARRIAGA'
      },
      {
        label: 'Villa de Guadalupe',
        value: 'CT_VILLA_DE_GUADALUPE'
      },
      {
        label: 'Villa de la Paz',
        value: 'CT_VILLA_DE_LA_PAZ'
      },
      {
        label: 'Villa de Ramos',
        value: 'CT_VILLA_DE_RAMOS'
      },
      {
        label: 'Villa de Reyes',
        value: 'CT_VILLA_DE_REYES'
      },
      {
        label: 'Villa Hidalgo',
        value: 'CT_VILLA_HIDALGO'
      },
      {
        label: 'Villa Juárez',
        value: 'CT_VILLA_JUAREZ'
      },
      {
        label: 'Axtla de Terrazas',
        value: 'CT_AXTLA_DE_TERRAZAS'
      },
      { label: 'Xilitla', value: 'CT_XILITLA' },
      { label: 'Zaragoza', value: 'CT_ZARAGOZA' },
      {
        label: 'Villa de Arista',
        value: 'CT_VILLA_DE_ARISTA'
      },
      { label: 'Matlapa', value: 'CT_MATLAPA' },
      { label: 'El Naranjo', value: 'CT_EL_NARANJO' }
    ]
  },
  {
    label: "Sinaloa",
    value: "ST_SINALOA",
    children: [
      { label: 'Ahome', value: 'CT_AHOME' },
      { label: 'Angostura', value: 'CT_ANGOSTURA' },
      { label: 'Badiraguato', value: 'CT_BADIRAGUATO' },
      { label: 'Concordia', value: 'CT_CONCORDIA' },
      { label: 'Cosalá', value: 'CT_COSALA' },
      { label: 'Culiacán', value: 'CT_CULIACAN' },
      { label: 'Choix', value: 'CT_CHOIX' },
      { label: 'Elota', value: 'CT_ELOTA' },
      { label: 'Escuinapa', value: 'CT_ESCUINAPA' },
      { label: 'El Fuerte', value: 'CT_EL_FUERTE' },
      { label: 'Guasave', value: 'CT_GUASAVE' },
      { label: 'Mazatlán', value: 'CT_MAZATLAN' },
      { label: 'Mocorito', value: 'CT_MOCORITO' },
      { label: 'Rosario', value: 'CT_ROSARIO' },
      {
        label: 'Salvador Alvarado',
        value: 'CT_SALVADOR_ALVARADO'
      },
      { label: 'San Ignacio', value: 'CT_SAN_IGNACIO' },
      { label: 'Sinaloa', value: 'CT_SINALOA' },
      { label: 'Navolato', value: 'CT_NAVOLATO' }
    ]
  },
  {
    label: "Sonora",
    value: "ST_SONORA",
    children: [
      { label: 'Aconchi', value: 'CT_ACONCHI' },
      { label: 'Agua Prieta', value: 'CT_AGUA_PRIETA' },
      { label: 'Alamos', value: 'CT_ALAMOS' },
      { label: 'Altar', value: 'CT_ALTAR' },
      { label: 'Arivechi', value: 'CT_ARIVECHI' },
      { label: 'Arizpe', value: 'CT_ARIZPE' },
      { label: 'Atil', value: 'CT_ATIL' },
      {
        label: 'Bacadéhuachi',
        value: 'CT_BACADEHUACHI'
      },
      { label: 'Bacanora', value: 'CT_BACANORA' },
      { label: 'Bacerac', value: 'CT_BACERAC' },
      { label: 'Bacoachi', value: 'CT_BACOACHI' },
      { label: 'Bácum', value: 'CT_BACUM' },
      { label: 'Banámichi', value: 'CT_BANAMICHI' },
      { label: 'Baviácora', value: 'CT_BAVIACORA' },
      { label: 'Bavispe', value: 'CT_BAVISPE' },
      {
        label: 'Benjamín Hill',
        value: 'CT_BENJAMIN_HILL'
      },
      { label: 'Caborca', value: 'CT_CABORCA' },
      { label: 'Cajeme', value: 'CT_CAJEME' },
      { label: 'Cananea', value: 'CT_CANANEA' },
      { label: 'Carbó', value: 'CT_CARBO' },
      { label: 'La Colorada', value: 'CT_LA_COLORADA' },
      { label: 'Cucurpe', value: 'CT_CUCURPE' },
      { label: 'Cumpas', value: 'CT_CUMPAS' },
      { label: 'Divisaderos', value: 'CT_DIVISADEROS' },
      { label: 'Empalme', value: 'CT_EMPALME' },
      { label: 'Etchojoa', value: 'CT_ETCHOJOA' },
      { label: 'Fronteras', value: 'CT_FRONTERAS' },
      { label: 'Granados', value: 'CT_GRANADOS' },
      { label: 'Guaymas', value: 'CT_GUAYMAS' },
      { label: 'Hermosillo', value: 'CT_HERMOSILLO' },
      { label: 'Huachinera', value: 'CT_HUACHINERA' },
      { label: 'Huásabas', value: 'CT_HUASABAS' },
      { label: 'Huatabampo', value: 'CT_HUATABAMPO' },
      { label: 'Huépac', value: 'CT_HUEPAC' },
      { label: 'Imuris', value: 'CT_IMURIS' },
      { label: 'Magdalena', value: 'CT_MAGDALENA' },
      { label: 'Mazatán', value: 'CT_MAZATAN' },
      { label: 'Moctezuma', value: 'CT_MOCTEZUMA' },
      { label: 'Naco', value: 'CT_NACO' },
      {
        label: 'Nácori Chico',
        value: 'CT_NACORI_CHICO'
      },
      {
        label: 'Nacozari de García',
        value: 'CT_NACOZARI_DE_GARCIA'
      },
      { label: 'Navojoa', value: 'CT_NAVOJOA' },
      { label: 'Nogales', value: 'CT_NOGALES' },
      { label: 'Onavas', value: 'CT_ONAVAS' },
      { label: 'Opodepe', value: 'CT_OPODEPE' },
      { label: 'Oquitoa', value: 'CT_OQUITOA' },
      { label: 'Pitiquito', value: 'CT_PITIQUITO' },
      {
        label: 'Puerto Peñasco',
        value: 'CT_PUERTO_PENASCO'
      },
      { label: 'Quiriego', value: 'CT_QUIRIEGO' },
      { label: 'Rayón', value: 'CT_RAYON' },
      { label: 'Rosario', value: 'CT_ROSARIO' },
      { label: 'Sahuaripa', value: 'CT_SAHUARIPA' },
      {
        label: 'San Felipe de Jesús',
        value: 'CT_SAN_FELIPE_DE_JESUS'
      },
      { label: 'San Javier', value: 'CT_SAN_JAVIER' },
      {
        label: 'San Luis Río Colorado',
        value: 'CT_SAN_LUIS_RIO_COLORADO'
      },
      {
        label: 'San Miguel de Horcasitas',
        value: 'CT_SAN_MIGUEL_DE_HORCASITAS'
      },
      {
        label: 'San Pedro de la Cueva',
        value: 'CT_SAN_PEDRO_DE_LA_CUEVA'
      },
      { label: 'Santa Ana', value: 'CT_SANTA_ANA' },
      { label: 'Santa Cruz', value: 'CT_SANTA_CRUZ' },
      { label: 'Sáric', value: 'CT_SARIC' },
      { label: 'Soyopa', value: 'CT_SOYOPA' },
      {
        label: 'Suaqui Grande',
        value: 'CT_SUAQUI_GRANDE'
      },
      { label: 'Tepache', value: 'CT_TEPACHE' },
      { label: 'Trincheras', value: 'CT_TRINCHERAS' },
      { label: 'Tubutama', value: 'CT_TUBUTAMA' },
      { label: 'Ures', value: 'CT_URES' },
      {
        label: 'Villa Hidalgo',
        value: 'CT_VILLA_HIDALGO'
      },
      {
        label: 'Villa Pesqueira',
        value: 'CT_VILLA_PESQUEIRA'
      },
      { label: 'Yécora', value: 'CT_YECORA' },
      {
        label: 'General Plutarco Elías Calles',
        value: 'CT_GENERAL_PLUTARCO_ELIAS_CALLES'
      },
      {
        label: 'Benito Juárez',
        value: 'CT_BENITO_JUAREZ'
      },
      {
        label: 'San Ignacio Río Muerto',
        value: 'CT_SAN_IGNACIO_RIO_MUERTO'
      }
    ]
  },
  {
    label: "Tabasco",
    value: "ST_TABASCO",
    children: [
      { label: 'Balancán', value: 'CT_BALANCAN' },
      { label: 'Cárdenas', value: 'CT_CARDENAS' },
      { label: 'Centla', value: 'CT_CENTLA' },
      { label: 'Centro', value: 'CT_CENTRO' },
      { label: 'Comalcalco', value: 'CT_COMALCALCO' },
      { label: 'Cunduacán', value: 'CT_CUNDUACAN' },
      {
        label: 'Emiliano Zapata',
        value: 'CT_EMILIANO_ZAPATA'
      },
      {
        label: 'Huimanguillo',
        value: 'CT_HUIMANGUILLO'
      },
      { label: 'Jalapa', value: 'CT_JALAPA' },
      {
        label: 'Jalpa de Méndez',
        value: 'CT_JALPA_DE_MENDEZ'
      },
      { label: 'Jonuta', value: 'CT_JONUTA' },
      { label: 'Macuspana', value: 'CT_MACUSPANA' },
      { label: 'Nacajuca', value: 'CT_NACAJUCA' },
      { label: 'Paraíso', value: 'CT_PARAISO' },
      { label: 'Tacotalpa', value: 'CT_TACOTALPA' },
      { label: 'Teapa', value: 'CT_TEAPA' },
      { label: 'Tenosique', value: 'CT_TENOSIQUE' }
    ]
  },
  {
    label: "Tamaulipas",
    value: "ST_TAMAULIPAS",
    children: [
      { label: 'Abasolo', value: 'CT_ABASOLO' },
      { label: 'Aldama', value: 'CT_ALDAMA' },
      { label: 'Altamira', value: 'CT_ALTAMIRA' },
      {
        label: 'Antiguo Morelos',
        value: 'CT_ANTIGUO_MORELOS'
      },
      { label: 'Burgos', value: 'CT_BURGOS' },
      { label: 'Bustamante', value: 'CT_BUSTAMANTE' },
      { label: 'Camargo', value: 'CT_CAMARGO' },
      { label: 'Casas', value: 'CT_CASAS' },
      {
        label: 'Ciudad Madero',
        value: 'CT_CIUDAD_MADERO'
      },
      { label: 'Cruillas', value: 'CT_CRUILLAS' },
      {
        label: 'Gómez Farías',
        value: 'CT_GOMEZ_FARIAS'
      },
      { label: 'González', value: 'CT_GONZALEZ' },
      { label: 'Güémez', value: 'CT_GUEMEZ' },
      { label: 'Guerrero', value: 'CT_GUERRERO' },
      {
        label: 'Gustavo Díaz Ordaz',
        value: 'CT_GUSTAVO_DIAZ_ORDAZ'
      },
      { label: 'Hidalgo', value: 'CT_HIDALGO' },
      { label: 'Jaumave', value: 'CT_JAUMAVE' },
      { label: 'Jiménez', value: 'CT_JIMENEZ' },
      { label: 'Llera', value: 'CT_LLERA' },
      { label: 'Mainero', value: 'CT_MAINERO' },
      { label: 'El Mante', value: 'CT_EL_MANTE' },
      { label: 'Matamoros', value: 'CT_MATAMOROS' },
      { label: 'Méndez', value: 'CT_MENDEZ' },
      { label: 'Mier', value: 'CT_MIER' },
      {
        label: 'Miguel Alemán',
        value: 'CT_MIGUEL_ALEMAN'
      },
      { label: 'Miquihuana', value: 'CT_MIQUIHUANA' },
      {
        label: 'Nuevo Laredo',
        value: 'CT_NUEVO_LAREDO'
      },
      {
        label: 'Nuevo Morelos',
        value: 'CT_NUEVO_MORELOS'
      },
      { label: 'Ocampo', value: 'CT_OCAMPO' },
      { label: 'Padilla', value: 'CT_PADILLA' },
      { label: 'Palmillas', value: 'CT_PALMILLAS' },
      { label: 'Reynosa', value: 'CT_REYNOSA' },
      { label: 'Río Bravo', value: 'CT_RIO_BRAVO' },
      { label: 'San Carlos', value: 'CT_SAN_CARLOS' },
      {
        label: 'San Fernando',
        value: 'CT_SAN_FERNANDO'
      },
      { label: 'San Nicolás', value: 'CT_SAN_NICOLAS' },
      {
        label: 'Soto la Marina',
        value: 'CT_SOTO_LA_MARINA'
      },
      { label: 'Tampico', value: 'CT_TAMPICO' },
      { label: 'Tula', value: 'CT_TULA' },
      {
        label: 'Valle Hermoso',
        value: 'CT_VALLE_HERMOSO'
      },
      { label: 'Victoria', value: 'CT_VICTORIA' },
      { label: 'Villagrán', value: 'CT_VILLAGRAN' },
      { label: 'Xicoténcatl', value: 'CT_XICOTENCATL' }
    ]
  },
  {
    label: "Tlaxcala",
    value: "ST_TLAXCALA",
    children: [
      {
        label: 'Amaxac de Guerrero',
        value: 'CT_AMAXAC_DE_GUERRERO'
      },
      {
        label: 'Apetatitlán de Antonio Carvajal',
        value: 'CT_APETATITLAN_DE_ANTONIO_CARVAJAL'
      },
      {
        label: 'Atlangatepec',
        value: 'CT_ATLANGATEPEC'
      },
      { label: 'Atltzayanca', value: 'CT_ATLTZAYANCA' },
      { label: 'Apizaco', value: 'CT_APIZACO' },
      { label: 'Calpulalpan', value: 'CT_CALPULALPAN' },
      {
        label: 'El Carmen Tequexquitla',
        value: 'CT_EL_CARMEN_TEQUEXQUITLA'
      },
      { label: 'Cuapiaxtla', value: 'CT_CUAPIAXTLA' },
      { label: 'Cuaxomulco', value: 'CT_CUAXOMULCO' },
      { label: 'Chiautempan', value: 'CT_CHIAUTEMPAN' },
      {
        label: 'Muñoz de Domingo Arenas',
        value: 'CT_MUNOZ_DE_DOMINGO_ARENAS'
      },
      { label: 'Españita', value: 'CT_ESPANITA' },
      { label: 'Huamantla', value: 'CT_HUAMANTLA' },
      { label: 'Hueyotlipan', value: 'CT_HUEYOTLIPAN' },
      {
        label: 'Ixtacuixtla de Mariano Matamoros',
        value: 'CT_IXTACUIXTLA_DE_MARIANO_MATAMOROS'
      },
      { label: 'Ixtenco', value: 'CT_IXTENCO' },
      {
        label: 'Mazatecochco de José María Morelos',
        value: 'CT_MAZATECOCHCO_DE_JOSE_MARIA_MORELOS'
      },
      {
        label: 'Contla de Juan Cuamatzi',
        value: 'CT_CONTLA_DE_JUAN_CUAMATZI'
      },
      {
        label: 'Tepetitla de Lardizábal',
        value: 'CT_TEPETITLA_DE_LARDIZABAL'
      },
      {
        label: 'Sanctórum de Lázaro Cárdenas',
        value: 'CT_SANCTORUM_DE_LAZARO_CARDENAS'
      },
      {
        label: 'Nanacamilpa de Mariano Arista',
        value: 'CT_NANACAMILPA_DE_MARIANO_ARISTA'
      },
      {
        label: 'Acuamanala de Miguel Hidalgo',
        value: 'CT_ACUAMANALA_DE_MIGUEL_HIDALGO'
      },
      { label: 'Natívitas', value: 'CT_NATIVITAS' },
      { label: 'Panotla', value: 'CT_PANOTLA' },
      {
        label: 'San Pablo del Monte',
        value: 'CT_SAN_PABLO_DEL_MONTE'
      },
      {
        label: 'Santa Cruz Tlaxcala',
        value: 'CT_SANTA_CRUZ_TLAXCALA'
      },
      { label: 'Tenancingo', value: 'CT_TENANCINGO' },
      { label: 'Teolocholco', value: 'CT_TEOLOCHOLCO' },
      { label: 'Tepeyanco', value: 'CT_TEPEYANCO' },
      { label: 'Terrenate', value: 'CT_TERRENATE' },
      {
        label: 'Tetla de la Solidaridad',
        value: 'CT_TETLA_DE_LA_SOLIDARIDAD'
      },
      {
        label: 'Tetlatlahuca',
        value: 'CT_TETLATLAHUCA'
      },
      { label: 'Tlaxcala', value: 'CT_TLAXCALA' },
      { label: 'Tlaxco', value: 'CT_TLAXCO' },
      { label: 'Tocatlán', value: 'CT_TOCATLAN' },
      { label: 'Totolac', value: 'CT_TOTOLAC' },
      {
        label: 'Ziltlaltépec de Trinidad Sánchez Santos',
        value: 'CT_ZILTLALTEPEC_DE_TRINIDAD_SANCHEZ_SANTOS'
      },
      {
        label: 'Tzompantepec',
        value: 'CT_TZOMPANTEPEC'
      },
      { label: 'Xaloztoc', value: 'CT_XALOZTOC' },
      { label: 'Xaltocan', value: 'CT_XALTOCAN' },
      {
        label: 'Papalotla de Xicohténcatl',
        value: 'CT_PAPALOTLA_DE_XICOHTENCATL'
      },
      { label: 'Xicohtzinco', value: 'CT_XICOHTZINCO' },
      {
        label: 'Yauhquemehcan',
        value: 'CT_YAUHQUEMEHCAN'
      },
      { label: 'Zacatelco', value: 'CT_ZACATELCO' },
      {
        label: 'Benito Juárez',
        value: 'CT_BENITO_JUAREZ'
      },
      {
        label: 'Emiliano Zapata',
        value: 'CT_EMILIANO_ZAPATA'
      },
      {
        label: 'Lázaro Cárdenas',
        value: 'CT_LAZARO_CARDENAS'
      },
      {
        label: 'La Magdalena Tlaltelulco',
        value: 'CT_LA_MAGDALENA_TLALTELULCO'
      },
      {
        label: 'San Damián Texóloc',
        value: 'CT_SAN_DAMIAN_TEXOLOC'
      },
      {
        label: 'San Francisco Tetlanohcan',
        value: 'CT_SAN_FRANCISCO_TETLANOHCAN'
      },
      {
        label: 'San Jerónimo Zacualpan',
        value: 'CT_SAN_JERONIMO_ZACUALPAN'
      },
      {
        label: 'San José Teacalco',
        value: 'CT_SAN_JOSE_TEACALCO'
      },
      {
        label: 'San Juan Huactzinco',
        value: 'CT_SAN_JUAN_HUACTZINCO'
      },
      {
        label: 'San Lorenzo Axocomanitla',
        value: 'CT_SAN_LORENZO_AXOCOMANITLA'
      },
      {
        label: 'San Lucas Tecopilco',
        value: 'CT_SAN_LUCAS_TECOPILCO'
      },
      {
        label: 'Santa Ana Nopalucan',
        value: 'CT_SANTA_ANA_NOPALUCAN'
      },
      {
        label: 'Santa Apolonia Teacalco',
        value: 'CT_SANTA_APOLONIA_TEACALCO'
      },
      {
        label: 'Santa Catarina Ayometla',
        value: 'CT_SANTA_CATARINA_AYOMETLA'
      },
      {
        label: 'Santa Cruz Quilehtla',
        value: 'CT_SANTA_CRUZ_QUILEHTLA'
      },
      {
        label: 'Santa Isabel Xiloxoxtla',
        value: 'CT_SANTA_ISABEL_XILOXOXTLA'
      }
    ]
  },
  {
    label: "Veracruz",
    value: "ST_VERACRUZ",
    children: [
      { label: 'Acajete', value: 'CT_ACAJETE' },
      { label: 'Acatlán', value: 'CT_ACATLAN' },
      { label: 'Acayucan', value: 'CT_ACAYUCAN' },
      { label: 'Actopan', value: 'CT_ACTOPAN' },
      { label: 'Acula', value: 'CT_ACULA' },
      { label: 'Acultzingo', value: 'CT_ACULTZINGO' },
      {
        label: 'Camarón de Tejeda',
        value: 'CT_CAMARON_DE_TEJEDA'
      },
      { label: 'Alpatláhuac', value: 'CT_ALPATLAHUAC' },
      {
        label: 'Alto Lucero de Gutiérrez Barrios',
        value: 'CT_ALTO_LUCERO_DE_GUTIERREZ_BARRIOS'
      },
      { label: 'Altotonga', value: 'CT_ALTOTONGA' },
      { label: 'Alvarado', value: 'CT_ALVARADO' },
      { label: 'Amatitlán', value: 'CT_AMATITLAN' },
      {
        label: 'Naranjos Amatlán',
        value: 'CT_NARANJOS_AMATLAN'
      },
      {
        label: 'Amatlán de los Reyes',
        value: 'CT_AMATLAN_DE_LOS_REYES'
      },
      {
        label: 'Angel R. Cabada',
        value: 'CT_ANGEL_R._CABADA'
      },
      { label: 'La Antigua', value: 'CT_LA_ANTIGUA' },
      { label: 'Apazapan', value: 'CT_APAZAPAN' },
      { label: 'Aquila', value: 'CT_AQUILA' },
      { label: 'Astacinga', value: 'CT_ASTACINGA' },
      { label: 'Atlahuilco', value: 'CT_ATLAHUILCO' },
      { label: 'Atoyac', value: 'CT_ATOYAC' },
      { label: 'Atzacan', value: 'CT_ATZACAN' },
      { label: 'Atzalan', value: 'CT_ATZALAN' },
      { label: 'Tlaltetela', value: 'CT_TLALTETELA' },
      { label: 'Ayahualulco', value: 'CT_AYAHUALULCO' },
      { label: 'Banderilla', value: 'CT_BANDERILLA' },
      {
        label: 'Benito Juárez',
        value: 'CT_BENITO_JUAREZ'
      },
      {
        label: 'Boca del Río',
        value: 'CT_BOCA_DEL_RIO'
      },
      { label: 'Calcahualco', value: 'CT_CALCAHUALCO' },
      {
        label: 'Camerino Z. Mendoza',
        value: 'CT_CAMERINO_Z._MENDOZA'
      },
      {
        label: 'Carrillo Puerto',
        value: 'CT_CARRILLO_PUERTO'
      },
      { label: 'Catemaco', value: 'CT_CATEMACO' },
      {
        label: 'Cazones de Herrera',
        value: 'CT_CAZONES_DE_HERRERA'
      },
      { label: 'Cerro Azul', value: 'CT_CERRO_AZUL' },
      {
        label: 'Citlaltépetl',
        value: 'CT_CITLALTEPETL'
      },
      {
        label: 'Coacoatzintla',
        value: 'CT_COACOATZINTLA'
      },
      { label: 'Coahuitlán', value: 'CT_COAHUITLAN' },
      { label: 'Coatepec', value: 'CT_COATEPEC' },
      {
        label: 'Coatzacoalcos',
        value: 'CT_COATZACOALCOS'
      },
      { label: 'Coatzintla', value: 'CT_COATZINTLA' },
      { label: 'Coetzala', value: 'CT_COETZALA' },
      { label: 'Colipa', value: 'CT_COLIPA' },
      { label: 'Comapa', value: 'CT_COMAPA' },
      { label: 'Córdoba', value: 'CT_CORDOBA' },
      {
        label: 'Cosamaloapan de Carpio',
        value: 'CT_COSAMALOAPAN_DE_CARPIO'
      },
      {
        label: 'Cosautlán de Carvajal',
        value: 'CT_COSAUTLAN_DE_CARVAJAL'
      },
      {
        label: 'Coscomatepec',
        value: 'CT_COSCOMATEPEC'
      },
      {
        label: 'Cosoleacaque',
        value: 'CT_COSOLEACAQUE'
      },
      { label: 'Cotaxtla', value: 'CT_COTAXTLA' },
      { label: 'Coxquihui', value: 'CT_COXQUIHUI' },
      { label: 'Coyutla', value: 'CT_COYUTLA' },
      { label: 'Cuichapa', value: 'CT_CUICHAPA' },
      { label: 'Cuitláhuac', value: 'CT_CUITLAHUAC' },
      {
        label: 'Chacaltianguis',
        value: 'CT_CHACALTIANGUIS'
      },
      { label: 'Chalma', value: 'CT_CHALMA' },
      { label: 'Chiconamel', value: 'CT_CHICONAMEL' },
      {
        label: 'Chiconquiaco',
        value: 'CT_CHICONQUIACO'
      },
      { label: 'Chicontepec', value: 'CT_CHICONTEPEC' },
      { label: 'Chinameca', value: 'CT_CHINAMECA' },
      {
        label: 'Chinampa de Gorostiza',
        value: 'CT_CHINAMPA_DE_GOROSTIZA'
      },
      { label: 'Las Choapas', value: 'CT_LAS_CHOAPAS' },
      { label: 'Chocamán', value: 'CT_CHOCAMAN' },
      { label: 'Chontla', value: 'CT_CHONTLA' },
      { label: 'Chumatlán', value: 'CT_CHUMATLAN' },
      {
        label: 'Emiliano Zapata',
        value: 'CT_EMILIANO_ZAPATA'
      },
      { label: 'Espinal', value: 'CT_ESPINAL' },
      {
        label: 'Filomeno Mata',
        value: 'CT_FILOMENO_MATA'
      },
      { label: 'Fortín', value: 'CT_FORTIN' },
      {
        label: 'Gutiérrez Zamora',
        value: 'CT_GUTIERREZ_ZAMORA'
      },
      {
        label: 'Hidalgotitlán',
        value: 'CT_HIDALGOTITLAN'
      },
      { label: 'Huatusco', value: 'CT_HUATUSCO' },
      {
        label: 'Huayacocotla',
        value: 'CT_HUAYACOCOTLA'
      },
      {
        label: 'Hueyapan de Ocampo',
        value: 'CT_HUEYAPAN_DE_OCAMPO'
      },
      {
        label: 'Huiloapan de Cuauhtémoc',
        value: 'CT_HUILOAPAN_DE_CUAUHTEMOC'
      },
      {
        label: 'Ignacio de la Llave',
        value: 'CT_IGNACIO_DE_LA_LLAVE'
      },
      { label: 'Ilamatlán', value: 'CT_ILAMATLAN' },
      { label: 'Isla', value: 'CT_ISLA' },
      { label: 'Ixcatepec', value: 'CT_IXCATEPEC' },
      {
        label: 'Ixhuacán de los Reyes',
        value: 'CT_IXHUACAN_DE_LOS_REYES'
      },
      {
        label: 'Ixhuatlán del Café',
        value: 'CT_IXHUATLAN_DEL_CAFE'
      },
      {
        label: 'Ixhuatlancillo',
        value: 'CT_IXHUATLANCILLO'
      },
      {
        label: 'Ixhuatlán del Sureste',
        value: 'CT_IXHUATLAN_DEL_SURESTE'
      },
      {
        label: 'Ixhuatlán de Madero',
        value: 'CT_IXHUATLAN_DE_MADERO'
      },
      {
        label: 'Ixmatlahuacan',
        value: 'CT_IXMATLAHUACAN'
      },
      {
        label: 'Ixtaczoquitlán',
        value: 'CT_IXTACZOQUITLAN'
      },
      { label: 'Jalacingo', value: 'CT_JALACINGO' },
      { label: 'Xalapa', value: 'CT_XALAPA' },
      { label: 'Jalcomulco', value: 'CT_JALCOMULCO' },
      { label: 'Jáltipan', value: 'CT_JALTIPAN' },
      { label: 'Jamapa', value: 'CT_JAMAPA' },
      {
        label: 'Jesús Carranza',
        value: 'CT_JESUS_CARRANZA'
      },
      { label: 'Xico', value: 'CT_XICO' },
      { label: 'Jilotepec', value: 'CT_JILOTEPEC' },
      {
        label: 'Juan Rodríguez Clara',
        value: 'CT_JUAN_RODRIGUEZ_CLARA'
      },
      {
        label: 'Juchique de Ferrer',
        value: 'CT_JUCHIQUE_DE_FERRER'
      },
      {
        label: 'Landero y Coss',
        value: 'CT_LANDERO_Y_COSS'
      },
      {
        label: 'Lerdo de Tejada',
        value: 'CT_LERDO_DE_TEJADA'
      },
      { label: 'Magdalena', value: 'CT_MAGDALENA' },
      { label: 'Maltrata', value: 'CT_MALTRATA' },
      {
        label: 'Manlio Fabio Altamirano',
        value: 'CT_MANLIO_FABIO_ALTAMIRANO'
      },
      {
        label: 'Mariano Escobedo',
        value: 'CT_MARIANO_ESCOBEDO'
      },
      {
        label: 'Martínez de la Torre',
        value: 'CT_MARTINEZ_DE_LA_TORRE'
      },
      { label: 'Mecatlán', value: 'CT_MECATLAN' },
      { label: 'Mecayapan', value: 'CT_MECAYAPAN' },
      {
        label: 'Medellín de Bravo',
        value: 'CT_MEDELLIN_DE_BRAVO'
      },
      { label: 'Miahuatlán', value: 'CT_MIAHUATLAN' },
      { label: 'Las Minas', value: 'CT_LAS_MINAS' },
      { label: 'Minatitlán', value: 'CT_MINATITLAN' },
      { label: 'Misantla', value: 'CT_MISANTLA' },
      {
        label: 'Mixtla de Altamirano',
        value: 'CT_MIXTLA_DE_ALTAMIRANO'
      },
      { label: 'Moloacán', value: 'CT_MOLOACAN' },
      { label: 'Naolinco', value: 'CT_NAOLINCO' },
      { label: 'Naranjal', value: 'CT_NARANJAL' },
      { label: 'Nautla', value: 'CT_NAUTLA' },
      { label: 'Nogales', value: 'CT_NOGALES' },
      { label: 'Oluta', value: 'CT_OLUTA' },
      { label: 'Omealca', value: 'CT_OMEALCA' },
      { label: 'Orizaba', value: 'CT_ORIZABA' },
      { label: 'Otatitlán', value: 'CT_OTATITLAN' },
      { label: 'Oteapan', value: 'CT_OTEAPAN' },
      {
        label: 'Ozuluama de Mascareñas',
        value: 'CT_OZULUAMA_DE_MASCARENAS'
      },
      { label: 'Pajapan', value: 'CT_PAJAPAN' },
      { label: 'Pánuco', value: 'CT_PANUCO' },
      { label: 'Papantla', value: 'CT_PAPANTLA' },
      {
        label: 'Paso del Macho',
        value: 'CT_PASO_DEL_MACHO'
      },
      {
        label: 'Paso de Ovejas',
        value: 'CT_PASO_DE_OVEJAS'
      },
      { label: 'La Perla', value: 'CT_LA_PERLA' },
      { label: 'Perote', value: 'CT_PEROTE' },
      {
        label: 'Platón Sánchez',
        value: 'CT_PLATON_SANCHEZ'
      },
      {
        label: 'Playa Vicente',
        value: 'CT_PLAYA_VICENTE'
      },
      {
        label: 'Poza Rica de Hidalgo',
        value: 'CT_POZA_RICA_DE_HIDALGO'
      },
      {
        label: 'Las Vigas de Ramírez',
        value: 'CT_LAS_VIGAS_DE_RAMIREZ'
      },
      {
        label: 'Pueblo Viejo',
        value: 'CT_PUEBLO_VIEJO'
      },
      {
        label: 'Puente Nacional',
        value: 'CT_PUENTE_NACIONAL'
      },
      {
        label: 'Rafael Delgado',
        value: 'CT_RAFAEL_DELGADO'
      },
      {
        label: 'Rafael Lucio',
        value: 'CT_RAFAEL_LUCIO'
      },
      { label: 'Los Reyes', value: 'CT_LOS_REYES' },
      { label: 'Río Blanco', value: 'CT_RIO_BLANCO' },
      {
        label: 'Saltabarranca',
        value: 'CT_SALTABARRANCA'
      },
      {
        label: 'San Andrés Tenejapan',
        value: 'CT_SAN_ANDRES_TENEJAPAN'
      },
      {
        label: 'San Andrés Tuxtla',
        value: 'CT_SAN_ANDRES_TUXTLA'
      },
      {
        label: 'San Juan Evangelista',
        value: 'CT_SAN_JUAN_EVANGELISTA'
      },
      {
        label: 'Santiago Tuxtla',
        value: 'CT_SANTIAGO_TUXTLA'
      },
      {
        label: 'Sayula de Alemán',
        value: 'CT_SAYULA_DE_ALEMAN'
      },
      { label: 'Soconusco', value: 'CT_SOCONUSCO' },
      { label: 'Sochiapa', value: 'CT_SOCHIAPA' },
      {
        label: 'Soledad Atzompa',
        value: 'CT_SOLEDAD_ATZOMPA'
      },
      {
        label: 'Soledad de Doblado',
        value: 'CT_SOLEDAD_DE_DOBLADO'
      },
      { label: 'Soteapan', value: 'CT_SOTEAPAN' },
      { label: 'Tamalín', value: 'CT_TAMALIN' },
      { label: 'Tamiahua', value: 'CT_TAMIAHUA' },
      {
        label: 'Tampico Alto',
        value: 'CT_TAMPICO_ALTO'
      },
      { label: 'Tancoco', value: 'CT_TANCOCO' },
      { label: 'Tantima', value: 'CT_TANTIMA' },
      { label: 'Tantoyuca', value: 'CT_TANTOYUCA' },
      { label: 'Tatatila', value: 'CT_TATATILA' },
      {
        label: 'Castillo de Teayo',
        value: 'CT_CASTILLO_DE_TEAYO'
      },
      { label: 'Tecolutla', value: 'CT_TECOLUTLA' },
      { label: 'Tehuipango', value: 'CT_TEHUIPANGO' },
      {
        label: 'Álamo Temapache',
        value: 'CT_ALAMO_TEMAPACHE'
      },
      { label: 'Tempoal', value: 'CT_TEMPOAL' },
      { label: 'Tenampa', value: 'CT_TENAMPA' },
      {
        label: 'Tenochtitlán',
        value: 'CT_TENOCHTITLAN'
      },
      { label: 'Teocelo', value: 'CT_TEOCELO' },
      { label: 'Tepatlaxco', value: 'CT_TEPATLAXCO' },
      { label: 'Tepetlán', value: 'CT_TEPETLAN' },
      { label: 'Tepetzintla', value: 'CT_TEPETZINTLA' },
      { label: 'Tequila', value: 'CT_TEQUILA' },
      { label: 'José Azueta', value: 'CT_JOSE_AZUETA' },
      { label: 'Texcatepec', value: 'CT_TEXCATEPEC' },
      { label: 'Texhuacán', value: 'CT_TEXHUACAN' },
      { label: 'Texistepec', value: 'CT_TEXISTEPEC' },
      { label: 'Tezonapa', value: 'CT_TEZONAPA' },
      {
        label: 'Tierra Blanca',
        value: 'CT_TIERRA_BLANCA'
      },
      { label: 'Tihuatlán', value: 'CT_TIHUATLAN' },
      { label: 'Tlacojalpan', value: 'CT_TLACOJALPAN' },
      { label: 'Tlacolulan', value: 'CT_TLACOLULAN' },
      { label: 'Tlacotalpan', value: 'CT_TLACOTALPAN' },
      {
        label: 'Tlacotepec de Mejía',
        value: 'CT_TLACOTEPEC_DE_MEJIA'
      },
      {
        label: 'Tlachichilco',
        value: 'CT_TLACHICHILCO'
      },
      { label: 'Tlalixcoyan', value: 'CT_TLALIXCOYAN' },
      {
        label: 'Tlalnelhuayocan',
        value: 'CT_TLALNELHUAYOCAN'
      },
      { label: 'Tlapacoyan', value: 'CT_TLAPACOYAN' },
      { label: 'Tlaquilpa', value: 'CT_TLAQUILPA' },
      { label: 'Tlilapan', value: 'CT_TLILAPAN' },
      { label: 'Tomatlán', value: 'CT_TOMATLAN' },
      { label: 'Tonayán', value: 'CT_TONAYAN' },
      { label: 'Totutla', value: 'CT_TOTUTLA' },
      { label: 'Tuxpan', value: 'CT_TUXPAN' },
      { label: 'Tuxtilla', value: 'CT_TUXTILLA' },
      {
        label: 'Ursulo Galván',
        value: 'CT_URSULO_GALVAN'
      },
      {
        label: 'Vega de Alatorre',
        value: 'CT_VEGA_DE_ALATORRE'
      },
      { label: 'Veracruz', value: 'CT_VERACRUZ' },
      {
        label: 'Villa Aldama',
        value: 'CT_VILLA_ALDAMA'
      },
      { label: 'Xoxocotla', value: 'CT_XOXOCOTLA' },
      { label: 'Yanga', value: 'CT_YANGA' },
      { label: 'Yecuatla', value: 'CT_YECUATLA' },
      { label: 'Zacualpan', value: 'CT_ZACUALPAN' },
      { label: 'Zaragoza', value: 'CT_ZARAGOZA' },
      { label: 'Zentla', value: 'CT_ZENTLA' },
      { label: 'Zongolica', value: 'CT_ZONGOLICA' },
      {
        label: 'Zontecomatlán de López y Fuentes',
        value: 'CT_ZONTECOMATLAN_DE_LOPEZ_Y_FUENTES'
      },
      {
        label: 'Zozocolco de Hidalgo',
        value: 'CT_ZOZOCOLCO_DE_HIDALGO'
      },
      { label: 'Agua Dulce', value: 'CT_AGUA_DULCE' },
      { label: 'El Higo', value: 'CT_EL_HIGO' },
      {
        label: 'Nanchital de Lázaro Cárdenas del Río',
        value: 'CT_NANCHITAL_DE_LAZARO_CARDENAS_DEL_RIO'
      },
      { label: 'Tres Valles', value: 'CT_TRES_VALLES' },
      {
        label: 'Carlos A. Carrillo',
        value: 'CT_CARLOS_A._CARRILLO'
      },
      {
        label: 'Tatahuicapan de Juárez',
        value: 'CT_TATAHUICAPAN_DE_JUAREZ'
      },
      { label: 'Uxpanapa', value: 'CT_UXPANAPA' },
      { label: 'San Rafael', value: 'CT_SAN_RAFAEL' },
      {
        label: 'Santiago Sochiapan',
        value: 'CT_SANTIAGO_SOCHIAPAN'
      }
    ]
  },
  {
    label: "Yucatán",
    value: "ST_YUCATAN",
    children: [
      { label: 'Abalá', value: 'CT_ABALA' },
      { label: 'Acanceh', value: 'CT_ACANCEH' },
      { label: 'Akil', value: 'CT_AKIL' },
      { label: 'Baca', value: 'CT_BACA' },
      { label: 'Bokobá', value: 'CT_BOKOBA' },
      { label: 'Buctzotz', value: 'CT_BUCTZOTZ' },
      { label: 'Cacalchén', value: 'CT_CACALCHEN' },
      { label: 'Calotmul', value: 'CT_CALOTMUL' },
      { label: 'Cansahcab', value: 'CT_CANSAHCAB' },
      { label: 'Cantamayec', value: 'CT_CANTAMAYEC' },
      { label: 'Celestún', value: 'CT_CELESTUN' },
      { label: 'Cenotillo', value: 'CT_CENOTILLO' },
      { label: 'Conkal', value: 'CT_CONKAL' },
      { label: 'Cuncunul', value: 'CT_CUNCUNUL' },
      { label: 'Cuzamá', value: 'CT_CUZAMA' },
      { label: 'Chacsinkín', value: 'CT_CHACSINKIN' },
      { label: 'Chankom', value: 'CT_CHANKOM' },
      { label: 'Chapab', value: 'CT_CHAPAB' },
      { label: 'Chemax', value: 'CT_CHEMAX' },
      {
        label: 'Chicxulub Pueblo',
        value: 'CT_CHICXULUB_PUEBLO'
      },
      { label: 'Chichimilá', value: 'CT_CHICHIMILA' },
      {
        label: 'Chikindzonot',
        value: 'CT_CHIKINDZONOT'
      },
      { label: 'Chocholá', value: 'CT_CHOCHOLA' },
      { label: 'Chumayel', value: 'CT_CHUMAYEL' },
      { label: 'Dzán', value: 'CT_DZAN' },
      { label: 'Dzemul', value: 'CT_DZEMUL' },
      { label: 'Dzidzantún', value: 'CT_DZIDZANTUN' },
      {
        label: 'Dzilam de Bravo',
        value: 'CT_DZILAM_DE_BRAVO'
      },
      {
        label: 'Dzilam González',
        value: 'CT_DZILAM_GONZALEZ'
      },
      { label: 'Dzitás', value: 'CT_DZITAS' },
      { label: 'Dzoncauich', value: 'CT_DZONCAUICH' },
      { label: 'Espita', value: 'CT_ESPITA' },
      { label: 'Halachó', value: 'CT_HALACHO' },
      { label: 'Hocabá', value: 'CT_HOCABA' },
      { label: 'Hoctún', value: 'CT_HOCTUN' },
      { label: 'Homún', value: 'CT_HOMUN' },
      { label: 'Huhí', value: 'CT_HUHI' },
      { label: 'Hunucmá', value: 'CT_HUNUCMA' },
      { label: 'Ixil', value: 'CT_IXIL' },
      { label: 'Izamal', value: 'CT_IZAMAL' },
      { label: 'Kanasín', value: 'CT_KANASIN' },
      { label: 'Kantunil', value: 'CT_KANTUNIL' },
      { label: 'Kaua', value: 'CT_KAUA' },
      { label: 'Kinchil', value: 'CT_KINCHIL' },
      { label: 'Kopomá', value: 'CT_KOPOMA' },
      { label: 'Mama', value: 'CT_MAMA' },
      { label: 'Maní', value: 'CT_MANI' },
      { label: 'Maxcanú', value: 'CT_MAXCANU' },
      { label: 'Mayapán', value: 'CT_MAYAPAN' },
      { label: 'Mérida', value: 'CT_MERIDA' },
      { label: 'Mocochá', value: 'CT_MOCOCHA' },
      { label: 'Motul', value: 'CT_MOTUL' },
      { label: 'Muna', value: 'CT_MUNA' },
      { label: 'Muxupip', value: 'CT_MUXUPIP' },
      { label: 'Opichén', value: 'CT_OPICHEN' },
      { label: 'Oxkutzcab', value: 'CT_OXKUTZCAB' },
      { label: 'Panabá', value: 'CT_PANABA' },
      { label: 'Peto', value: 'CT_PETO' },
      { label: 'Progreso', value: 'CT_PROGRESO' },
      {
        label: 'Quintana Roo',
        value: 'CT_QUINTANA_ROO'
      },
      {
        label: 'Río Lagartos',
        value: 'CT_RIO_LAGARTOS'
      },
      { label: 'Sacalum', value: 'CT_SACALUM' },
      { label: 'Samahil', value: 'CT_SAMAHIL' },
      { label: 'Sanahcat', value: 'CT_SANAHCAT' },
      { label: 'San Felipe', value: 'CT_SAN_FELIPE' },
      { label: 'Santa Elena', value: 'CT_SANTA_ELENA' },
      { label: 'Seyé', value: 'CT_SEYE' },
      { label: 'Sinanché', value: 'CT_SINANCHE' },
      { label: 'Sotuta', value: 'CT_SOTUTA' },
      { label: 'Sucilá', value: 'CT_SUCILA' },
      { label: 'Sudzal', value: 'CT_SUDZAL' },
      { label: 'Suma', value: 'CT_SUMA' },
      { label: 'Tahdziú', value: 'CT_TAHDZIU' },
      { label: 'Tahmek', value: 'CT_TAHMEK' },
      { label: 'Teabo', value: 'CT_TEABO' },
      { label: 'Tecoh', value: 'CT_TECOH' },
      {
        label: 'Tekal de Venegas',
        value: 'CT_TEKAL_DE_VENEGAS'
      },
      { label: 'Tekantó', value: 'CT_TEKANTO' },
      { label: 'Tekax', value: 'CT_TEKAX' },
      { label: 'Tekit', value: 'CT_TEKIT' },
      { label: 'Tekom', value: 'CT_TEKOM' },
      {
        label: 'Telchac Pueblo',
        value: 'CT_TELCHAC_PUEBLO'
      },
      {
        label: 'Telchac Puerto',
        value: 'CT_TELCHAC_PUERTO'
      },
      { label: 'Temax', value: 'CT_TEMAX' },
      { label: 'Temozón', value: 'CT_TEMOZON' },
      { label: 'Tepakán', value: 'CT_TEPAKAN' },
      { label: 'Tetiz', value: 'CT_TETIZ' },
      { label: 'Teya', value: 'CT_TEYA' },
      { label: 'Ticul', value: 'CT_TICUL' },
      { label: 'Timucuy', value: 'CT_TIMUCUY' },
      { label: 'Tinum', value: 'CT_TINUM' },
      {
        label: 'Tixcacalcupul',
        value: 'CT_TIXCACALCUPUL'
      },
      { label: 'Tixkokob', value: 'CT_TIXKOKOB' },
      { label: 'Tixmehuac', value: 'CT_TIXMEHUAC' },
      { label: 'Tixpéhual', value: 'CT_TIXPEHUAL' },
      { label: 'Tizimín', value: 'CT_TIZIMIN' },
      { label: 'Tunkás', value: 'CT_TUNKAS' },
      { label: 'Tzucacab', value: 'CT_TZUCACAB' },
      { label: 'Uayma', value: 'CT_UAYMA' },
      { label: 'Ucú', value: 'CT_UCU' },
      { label: 'Umán', value: 'CT_UMAN' },
      { label: 'Valladolid', value: 'CT_VALLADOLID' },
      { label: 'Xocchel', value: 'CT_XOCCHEL' },
      { label: 'Yaxcabá', value: 'CT_YAXCABA' },
      { label: 'Yaxkukul', value: 'CT_YAXKUKUL' },
      { label: 'Yobaín', value: 'CT_YOBAIN' }
    ]
  },
  {
    label: "Zacatecas",
    value: "ST_ZACATECAS",
    children: [
      { label: 'Apozol', value: 'CT_APOZOL' },
      { label: 'Apulco', value: 'CT_APULCO' },
      { label: 'Atolinga', value: 'CT_ATOLINGA' },
      {
        label: 'Benito Juárez',
        value: 'CT_BENITO_JUAREZ'
      },
      { label: 'Calera', value: 'CT_CALERA' },
      {
        label: 'Cañitas de Felipe Pescador',
        value: 'CT_CANITAS_DE_FELIPE_PESCADOR'
      },
      {
        label: 'Concepción del Oro',
        value: 'CT_CONCEPCION_DEL_ORO'
      },
      { label: 'Cuauhtémoc', value: 'CT_CUAUHTEMOC' },
      {
        label: 'Chalchihuites',
        value: 'CT_CHALCHIHUITES'
      },
      { label: 'Fresnillo', value: 'CT_FRESNILLO' },
      {
        label: 'Trinidad García de la Cadena',
        value: 'CT_TRINIDAD_GARCIA_DE_LA_CADENA'
      },
      {
        label: 'Genaro Codina',
        value: 'CT_GENARO_CODINA'
      },
      {
        label: 'General Enrique Estrada',
        value: 'CT_GENERAL_ENRIQUE_ESTRADA'
      },
      {
        label: 'General Francisco R. Murguía',
        value: 'CT_GENERAL_FRANCISCO_R._MURGUIA'
      },
      {
        label: 'El Plateado de Joaquín Amaro',
        value: 'CT_EL_PLATEADO_DE_JOAQUIN_AMARO'
      },
      {
        label: 'General Pánfilo Natera',
        value: 'CT_GENERAL_PANFILO_NATERA'
      },
      { label: 'Guadalupe', value: 'CT_GUADALUPE' },
      { label: 'Huanusco', value: 'CT_HUANUSCO' },
      { label: 'Jalpa', value: 'CT_JALPA' },
      { label: 'Jerez', value: 'CT_JEREZ' },
      {
        label: 'Jiménez del Teul',
        value: 'CT_JIMENEZ_DEL_TEUL'
      },
      { label: 'Juan Aldama', value: 'CT_JUAN_ALDAMA' },
      { label: 'Juchipila', value: 'CT_JUCHIPILA' },
      { label: 'Loreto', value: 'CT_LORETO' },
      { label: 'Luis Moya', value: 'CT_LUIS_MOYA' },
      { label: 'Mazapil', value: 'CT_MAZAPIL' },
      {
        label: 'Melchor Ocampo',
        value: 'CT_MELCHOR_OCAMPO'
      },
      {
        label: 'Mezquital del Oro',
        value: 'CT_MEZQUITAL_DEL_ORO'
      },
      { label: 'Miguel Auza', value: 'CT_MIGUEL_AUZA' },
      { label: 'Momax', value: 'CT_MOMAX' },
      {
        label: 'Monte Escobedo',
        value: 'CT_MONTE_ESCOBEDO'
      },
      { label: 'Morelos', value: 'CT_MORELOS' },
      {
        label: 'Moyahua de Estrada',
        value: 'CT_MOYAHUA_DE_ESTRADA'
      },
      {
        label: 'Nochistlán de Mejía',
        value: 'CT_NOCHISTLAN_DE_MEJIA'
      },
      {
        label: 'Noria de Ángeles',
        value: 'CT_NORIA_DE_ANGELES'
      },
      { label: 'Ojocaliente', value: 'CT_OJOCALIENTE' },
      { label: 'Pánuco', value: 'CT_PANUCO' },
      { label: 'Pinos', value: 'CT_PINOS' },
      { label: 'Río Grande', value: 'CT_RIO_GRANDE' },
      { label: 'Sain Alto', value: 'CT_SAIN_ALTO' },
      { label: 'El Salvador', value: 'CT_EL_SALVADOR' },
      { label: 'Sombrerete', value: 'CT_SOMBRERETE' },
      { label: 'Susticacán', value: 'CT_SUSTICACAN' },
      { label: 'Tabasco', value: 'CT_TABASCO' },
      { label: 'Tepechitlán', value: 'CT_TEPECHITLAN' },
      { label: 'Tepetongo', value: 'CT_TEPETONGO' },
      {
        label: 'Teúl de González Ortega',
        value: 'CT_TEUL_DE_GONZALEZ_ORTEGA'
      },
      {
        label: 'Tlaltenango de Sánchez Román',
        value: 'CT_TLALTENANGO_DE_SANCHEZ_ROMAN'
      },
      { label: 'Valparaíso', value: 'CT_VALPARAISO' },
      { label: 'Vetagrande', value: 'CT_VETAGRANDE' },
      {
        label: 'Villa de Cos',
        value: 'CT_VILLA_DE_COS'
      },
      {
        label: 'Villa García',
        value: 'CT_VILLA_GARCIA'
      },
      {
        label: 'Villa González Ortega',
        value: 'CT_VILLA_GONZALEZ_ORTEGA'
      },
      {
        label: 'Villa Hidalgo',
        value: 'CT_VILLA_HIDALGO'
      },
      { label: 'Villanueva', value: 'CT_VILLANUEVA' },
      { label: 'Zacatecas', value: 'CT_ZACATECAS' },
      { label: 'Trancoso', value: 'CT_TRANCOSO' },
      {
        label: 'Santa María de la Paz',
        value: 'CT_SANTA_MARIA_DE_LA_PAZ'
      }
    ]
  }
]

export const allStateOption = [{label: "Todo el estado", value: "ALL"}];