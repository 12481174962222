import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { Form, message } from "antd";
import moment from "moment";
import { client } from "../../../../../../graphql";
import { MainMenu } from "../../../../../components/admin/shared";
import { SidebarLayout } from "../../../../../components/shared";
import { HeaderBreadcrumbs } from "../../../../../components/shared/batch-offer/header-breadcrumbs";
import { StepsNavigation } from "../../../../../components/admin/batches/new/steps-navigation";
import { InformationForm } from "../../../../../components/admin/batches/new/information/information-form";
import { HeaderTexts } from "../../../../../components/admin/batches/new/header-texts";
import { shared } from "../../../../../graphql/shared";
import { admin } from "../../../../../graphql/admin";
import { ControllerButtons } from "../../../../../components/admin/batches/new/controller-buttons";
import { UsageTrackingService } from "../../../../../services";

export const NewBatchInformationIndex = () => {
  const [state, setState] = useState({
    programId: undefined,
    program: undefined
  });
  const history = useHistory();
  const [form] = Form.useForm();
  const [createBatch, { loading }] = useMutation(
    admin.mutations.createBatch, { client }
  );
  const [createMasterContent, { loading: loadingMasterContent }] = useMutation(
    shared.mutations.createMasterContent, { client }
  );

  const onNext = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();

    try {
      values.program = {
        connect: {
          id: state.programId
        }
      };

      values.startDate = moment(values.dates[0]).toISOString();
      values.finishDate = moment(values.dates[1]).toISOString();

      delete values.dates;
      delete values.programId;

      const newBatch = await createBatch({ variables: { data: values } });
      const batchId = newBatch.data.createBatch.id;
      const masterContentData = { batch: { connect: { id: batchId } } };
      await createMasterContent({ variables: { data: masterContentData } });

      UsageTrackingService.trackNewBatch({
        batch: { ...values, id: batchId }
      }).catch(console.error);

      history.push(`/v2/admin/batches/new/${batchId}/members`);
    } catch(e) {
      if (e.message.match(/NAME_ALREADY_TAKEN/)) {
        return message.warning(`Ya existe un batch con el nombre\
           "${values.name}". Por favor, ingresa un nombre diferente.`);
      }
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    }
  };

  const onSelectProgram = value => {
    setState({ ...state, programId: value });
  };

  useEffect(() => {
    const fetchProgram = async () => {
      const { data } = await client.query({
        query: shared.queries.getProgramById,
        variables: { id: state.programId }
      });

      setState({ ...state, program: data.program });
    };

    if(state.programId) {
      fetchProgram();
    }
  }, [state.programId]);

  return (
    <SidebarLayout menu={<MainMenu />}>
      <HeaderBreadcrumbs
        title="Batches"
        name="Crear nuevo batch" />
      <StepsNavigation current={0} />
      <div style={{ margin: "30px 13px" }}>
        <HeaderTexts
          title="Agrega la información básica de tu batch"
          description="Explica brevemente tu batch" />
        <InformationForm
          form={form}
          program={state.program}
          onSelectProgram={onSelectProgram} />
      </div>
      <ControllerButtons loading={loading || loadingMasterContent} onNext={onNext} />
    </SidebarLayout>
  );
};
