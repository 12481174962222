import React, { useContext } from "react";
import { default as ExperienceForm } from "./form";
import { FormNavigation } from "../../../shared";
import { Card, Space, Progress, Typography, message } from "antd";
import { withRouter } from "../../../../helpers";
import { OnboardingContext } from "../../../../contexts/expert/onboarding";

function Experience({ navigate }) {
  const {
    data,
    error,
    loading,
    updateExpert,
    save
  } = useContext(OnboardingContext);

  const onNext = route => async () => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      await save();
      navigate(route);
    }
    catch(e) {
      console.error(e);
      message.error("Ha ocurrido un error, \
      inténtalo de nuevo en unos segundos.");
    }
    finally {
      dismissLoader();
    }
  };

  const onChange = values => {
    updateExpert(values);
  };

  return (
    <Card bordered={false} style={{maxWidth: "600px", margin: "0 auto"}}>
      <Space direction="vertical" size="large">
        <Progress percent={50} showInfo={false} />
        <>
          <Typography.Title>Experiencia 📖</Typography.Title>
          <Typography.Text>
            Describe toda la experiencia que has acumulado, en qué industrias
            has participado.
          </Typography.Text>
        </>
        <ExperienceForm
          data={data}
          error={error}
          isLoading={loading}
          onChange={onChange} />
        <FormNavigation
          onPrev={() =>
            navigate("./general-information")
          }
          onNext={onNext("./add-service")}
          onSkip={() =>
            navigate("./success")
          } />
      </Space>
    </Card>
  );
}

export default withRouter(Experience);
