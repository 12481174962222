import React, { useEffect, useContext, useMemo, useCallback } from "react";
import { BatchContent } from "../../../../shared";
import { useMutation } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import { shared } from "../../../../../graphql/shared";
import { useParams } from "react-router";
import {
  BatchDetailsContextProvider
} from "../../../../../contexts/admin/batches/batch-details";
import {
  BatchContentsContext
} from "../../../../../contexts/admin/batches";
import {
  MenuContext,
  ContentViewSwitchProvider,
  ContentModalContextProvider,
  ContentGroupModalContextProvider,
  ContentDetailsModalContextProvider,
  DraggableListProvider
} from "../../../../../contexts/shared";
import { message } from "antd";

export function BatchContentIndex({
  masterContents,
  refetchMasterContents,
  errorMasterContent,
  loadingMasterContent
}) {
  const { id } = useParams();
  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const [updateMasterContent] = useMutation(
    shared.mutations.updateMasterContent, { client }
  );

  const [updateContentGroup] = useMutation(
    shared.mutations.updateContentGroup, { client }
  );

  const onUpdateMasterContent = useCallback(async create => {
    await updateMasterContent({
      variables: {
        where: { id: masterContents?.[0]?.id },
        data: { contentGroups: { create } }
      }
    });
    await refetchMasterContents();
  }, [masterContents]);

  const onUpdateContentSorted = useCallback(async (uuids, groupId) => {
    if (!groupId) {
      await updateMasterContent({
        variables: {
          where: { id: masterContents?.[0]?.id },
          data: { contentGroupsSort: { set: uuids } }
        }
      });
    } else {
      await updateContentGroup({
        variables: {
          where: { id: groupId },
          data: { contentsSort: { set: uuids } }
        }
      });
    }
    await refetchMasterContents();
    message.success("Ordenamiento guardado.");
  }, [masterContents]);

  const onUpdateContentGroup = useCallback(async (groupId, values) => {
    await updateContentGroup({
      variables: { where: { id: groupId }, data: values }
    });
    await refetchMasterContents();
  }, [masterContents]);

  const injectActions = useMemo(() => ({
    data: { masterContents },
    error: errorMasterContent,
    loading: loadingMasterContent,
    refetch: refetchMasterContents,
    batchId: id
  }), [masterContents, loadingMasterContent]);

  const isPublished = masterContents?.[0]?.publishedContents?.length > 0;

  return (
    <BatchDetailsContextProvider>
      <BatchContentsContext.Provider value={injectActions}>
        <DraggableListProvider onUpdate={onUpdateContentSorted}>
          <ContentGroupModalContextProvider
            isPublished={isPublished}
            refetch={refetchMasterContents}
            onSave={onUpdateMasterContent}
            onUpdate={onUpdateContentGroup}>
            <ContentModalContextProvider
              isPublished={isPublished}
              batchId={id}
              refetch={refetchMasterContents}>
              <ContentDetailsModalContextProvider
                batchId={id}
                showDraft
                refetch={refetchMasterContents}>
                <ContentViewSwitchProvider>
                  <BatchContent />
                </ContentViewSwitchProvider>
              </ContentDetailsModalContextProvider>
            </ContentModalContextProvider>
          </ContentGroupModalContextProvider>
        </DraggableListProvider>
      </BatchContentsContext.Provider>
    </BatchDetailsContextProvider>
  );
}
