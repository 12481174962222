import gql from "graphql-tag";

export const getContentById = gql`
  query GetContentById($id: ID!) {
    content(
      where: {
        id: $id
      }
    ) {
      id
      type
      name
      draft
      dueDate
      placeType
      description
      instructions
      materials{
        id
        fileName
        url
        type
        uploadDate
      }
      createdBy{
        id
        fullName
        systemRole
      }
      placeDirection
    }
  }
`;
