import React from "react";
import { FlexContainer } from "../../components/shared/flex-container";

export function uploadButtonStatusLabel(_, file) {
  if (file.status === "done" && file.percent == 100)
    return <FlexContainer xAlign="center">Archivo cargado</FlexContainer>;

  if (file.status === "uploading")
    return `Subiendo archivo ${parseFloat(file.percent).toFixed(0) || 0}%`;

  return null;
}
