import React from "react";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
import { countries } from "../../../../../../utils";

const geoUrl = "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

export function OdeLocationChart({odes = []}) {
  const accumulate = (key, object) => {
    if(key) {
      if(Object.prototype.hasOwnProperty.call(object, key)) {
        object[key]++;
      }
      else {
        object[key] = 1;
      }
    }

    return object;
  };

  const lerp = (value1, value2, amount) => {
    amount = amount < 0 ? 0 : amount;
    amount = amount > 1 ? 1 : amount;
    return value1 + (value2 - value1) * amount;
  };

  odes = odes.filter(ode => ode?.address?.country);

  let max = 0;
  let min = 9999;

  let locations = odes.reduce((acc, ode) => {
    return accumulate(ode.address.country, acc);
  }, {});

  Object.keys(locations).forEach(country => {
    const count = locations[country];

    if(count > max) {
      max = count;
    }
    else if(count < min) {
      min = count;
    }
  });

  const calculateRadiusAmount = (min, max, value) => {
    const diff = max - min;
    const percentage = 100 * value / diff;
    return percentage * 0.01;
  };

  locations = Object.keys(locations).map(country => {
    const count = locations[parseInt(country)];
    return {
      id: country,
      coordinates: countries.find(c => c.id == country)?.coordinates,
      radius: lerp(5, 15, calculateRadiusAmount(min, max, count))
    };
  });

  locations = locations.filter(l => l.coordinates);

  return (
    <ComposableMap
      projection="geoMercator"
      projectionConfig={{scale: 125}}>
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map(geo => (
            <Geography
              key={geo.rsmKey}
              geography={geo}
              fill="#EAEAEA"
              stroke="#D6D6D6" />
          ))
        }
      </Geographies>
      {locations.map(location => (
        <Marker
          key={location.id}
          coordinates={location.coordinates}>
          <circle r={location.radius} fill="#6348E590" />
        </Marker>
      ))}
    </ComposableMap>
  );
}
