import React from "react";
import { Avatar, Button, Divider, Form, List, Space, Typography } from "antd";
import { AddButton, Visibility } from "../../../../../components/shared";
import {
  FilterableOdesSelect
} from "../../../../../components/shared";

export const OdeSection = ({ odes, selectedProgram = "", viewMode, saveAssignments, removedAssigned }) => {

  const [form] = Form.useForm();

  const saveOdes = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    saveAssignments(values.odes, "odes");
    form.resetFields();
  };

  return (
    <Form
      form={form}>
      <Divider orientation="left">Compañía</Divider>
      <List
        dataSource={odes}
        renderItem={ode =>
          <List.Item key={ode.id}>
            <Space
              style={{ justifyContent: "space-between", width: "100%" }}>
              <div
                style={{ display: "flex" }}>
                <Avatar src={ode.logoUrl}>{ode.logoUrl}</Avatar>
                <Typography.Title
                  level={5}
                  style={{ marginLeft: "15px" }}>
                  {ode.name}
                </Typography.Title>
              </div>
              <Visibility visible={!viewMode}>
                <Button
                  onClick={() => removedAssigned(ode.id, "odes")}
                  danger>
                  Eliminar
                </Button>
              </Visibility>
            </Space>
          </List.Item>} />
      <Visibility visible={!viewMode}>
        <Form.Item
          style={{
            marginTop: "25px",
            marginBottom: "10px"
          }}
          rules={[{
            required: true,
            message: "Seleccione al menos una Compañía"
          }]}
          name="odes">
          <FilterableOdesSelect
            allowClear
            className="wide-input"
            model="multiple"
            programId={selectedProgram}
            excludedOdes={odes} />
        </Form.Item>
        <AddButton
          className="wide-input add-odes"
          type="dashed"
          onClick={saveOdes}>
          Agregar Compañías
        </AddButton>
      </Visibility>
    </Form>
  );
};
