/* eslint-disable max-lines */
import React, { useState, useEffect } from "react";
import { Form, Input, Row, Select, DatePicker, InputNumber } from "antd";
import { Avatar, FlexContainer, UploadButton, Visibility } from "../..";
import {
  getCompanyMissingRequiredFields,
  uploadButtonStatusLabel
} from "../../../../helpers";
import { fiscalStateOptions } from "./select-options/fiscal-state";
import { businessModelOptions } from "./select-options/business-model";
import { productStageOptions } from "./select-options/product-stage";
import { salesStageOptions } from "./select-options/sales-stage";
import { annualSalesOptions } from "./select-options/annual-sales";
import { financingStageOptions } from "./select-options/financing-stage";

export const CompanyGeneralForm = ({
  form,
  logoUrl,
  company,
  setLogoUrl,
  nameValidations,
  initialFiscalState = "",
  initialSalesStage = "" }) => {

  const [fiscalState, setFiscalState] = useState(initialFiscalState);
  const [salesStage, setSalesStage] = useState();
  const [annualSalesOptionsState, setAnnualSalesOptionsState] =
    useState(annualSalesOptions);

  useEffect(() => {
    const { salesStage } = form.getFieldsValue(["salesStage"]);
    setSalesStage(initialSalesStage || salesStage);
    if(salesStage === "NO_SALES"){
      setAnnualSalesOptionsState([{label: "$0", value: "0"}]);
    }
  }, []);

  const getPhotoUrl = e => {
    const profilePicUrl = e?.file?.response?.imageUrl;
    setLogoUrl(profilePicUrl);
    return e.fileList;
  };

  const onFiscalStateChange = value => {
    setFiscalState(value);
    setValidation(prev => ({
      ...prev,
      fiscalState: {
        msg: undefined,
        status: undefined
      }
    }));
  };

  const onSalesStageChange = value => {
    if(value === "NO_SALES") {
      form.resetFields(["annualSales"]);
      setAnnualSalesOptionsState([{label: "$0", value: "0"}]);
      form.setFieldsValue({"annualSales": "0"});
    } else {
      form.resetFields(["annualSales"]);
      setAnnualSalesOptionsState(annualSalesOptions);
    }
    setSalesStage(value);
    return value;
  };

  const [validation, setValidation] = useState({});

  useEffect(() => {
    if (company) {
      const { requiredFieldsValidation } =
        getCompanyMissingRequiredFields(company);
      setValidation(requiredFieldsValidation);
    }
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ padding: "0 16px" }}>
      <Form.Item>
        <FlexContainer direction="horizontal" xAlign="center">
          <Avatar
            size={64}
            src={logoUrl}>
          </Avatar>
        </FlexContainer>
      </Form.Item>
      <Form.Item
        name="logoUrl"
        getValueFromEvent={getPhotoUrl}>
        <UploadButton.Crop
          align="center"
          itemRender={uploadButtonStatusLabel}
          aspect={0.9}
          multiple={false}
          maxFiles={1}>
          Subir logotipo
        </UploadButton.Crop>
      </Form.Item>
      <Form.Item
        name="name"
        label="Compañía"
        validateStatus={nameValidations?.status}
        help={nameValidations?.msg}
        rules={[{ required: true }]}>
        <Input autoFocus placeholder="Escribe el nombre de tu compañía." />
      </Form.Item>
      <Row justify="space-between">
        <Form.Item
          label="Fundada en"
          name="startDate"
          help={validation?.startDate?.msg}
          validateStatus={validation?.startDate?.status}
          rules={[{ required: true }]}>
          <DatePicker
            onChange={() => setValidation(prev => ({
              ...prev,
              startDate: {
                msg: undefined,
                status: undefined
              }
            }))}
            style={{ width: "357px "}}
            picker="month"
            format="MMMM-YYYY" />
        </Form.Item>
        <Form.Item
          label="Situación fiscal"
          name="fiscalState"
          help={validation?.fiscalState?.msg}
          validateStatus={validation?.fiscalState?.status}
          rules={[{ required: true }]}>
          <Select
            options={fiscalStateOptions}
            onChange={onFiscalStateChange}
            placeholder="Seleccionar"
            style={{ width: "357px" }} />
        </Form.Item>
      </Row>
      <Visibility visible={fiscalState && fiscalState !== "NOT_REGISTERED" }>
        <Form.Item
          label="Razón social"
          name="businessName"
          help={validation?.businessName?.msg}
          validateStatus={validation?.businessName?.status}
          rules={[{
            required:
              fiscalState && fiscalState === "PHYSICAL_PERSON" ||
              fiscalState === "MORAL_PERSON"
          }]}>
          <Input
            onChange={() => setValidation(prev => ({
              ...prev,
              businessName: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
        <Row justify="space-between">
          <Form.Item
            label="RFC/TAX ID"
            name="fiscalId"
            help={validation?.fiscalId?.msg}
            validateStatus={validation?.fiscalId?.status}
            rules={[
              {
                required:
                  fiscalState && fiscalState === "PHYSICAL_PERSON"
                  || fiscalState === "MORAL_PERSON"
              },
              {
              type: "string",
              min: 12
              }
            ]}>
            <Input
              style={{ width: "357px "}}
              onChange={() => setValidation(prev => ({
                ...prev,
                fiscalId: {
                  msg: undefined,
                  status: undefined
                }
              }))} />
          </Form.Item>
          <Form.Item
            label="Fecha de alta fiscal"
            name="fiscalEntryDate"
            help={validation?.fiscalEntryDate?.msg}
            validateStatus={validation?.fiscalEntryDate?.status}
            rules={[{
              required: fiscalState && fiscalState === "PHYSICAL_PERSON" ||
                fiscalState === "MORAL_PERSON"
            }]}>
            <DatePicker
              style={{ width: "357px "}}
              onChange={() => setValidation(prev => ({
                ...prev,
                fiscalEntryDate: {
                  msg: undefined,
                  status: undefined
                }
              }))} />
          </Form.Item>
        </Row>
        <Visibility visible={fiscalState === "MORAL_PERSON"}>
          <Form.Item
            label="Nombre del representante legal"
            name="fiscalRepresentative"
            help={validation?.fiscalRepresentative?.msg}
            validateStatus={validation?.fiscalRepresentative?.status}
            rules={[{
              required: fiscalState && fiscalState === "MORAL_PERSON"
            }]}>
            <Input
              onChange={() => setValidation(prev => ({
                ...prev,
                fiscalRepresentative: {
                  msg: undefined,
                  status: undefined
                }
              }))} />
          </Form.Item>
        </Visibility>
      </Visibility>
      <Row justify="space-between">
        <Form.Item
          label="Modelo de negocio"
          name="businessModel"
          help={validation?.businessModel?.msg}
          validateStatus={validation?.businessModel?.status}
          rules={[{ required: true }]}>
          <Select
            placeholder="Seleccionar"
            options={businessModelOptions}
            style={{ width: "357px "}}
            onChange={() => setValidation(prev => ({
              ...prev,
              businessModel: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
        <Form.Item
          label="Etapa del producto"
          name="productStage"
          help={validation?.productStage?.msg}
          validateStatus={validation?.productStage?.status}
          rules={[{ required: true }]}>
          <Select
            placeholder="Seleccionar"
            options={productStageOptions}
            style={{ width: "357px "}}
            onChange={() => setValidation(prev => ({
              ...prev,
              productStage: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
      </Row>
      <Row justify="space-between">
        <Form.Item
          getValueFromEvent={onSalesStageChange}
          label="Etapa de ventas"
          name="salesStage"
          help={validation?.salesStage?.msg}
          validateStatus={validation?.salesStage?.status}
          rules={[{ required: true }]}>
          <Select
            placeholder="Seleccionar"
            options={salesStageOptions}
            style={{ width: "357px "}}
            onChange={() => setValidation(prev => ({
              ...prev,
              salesStage: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
        <Form.Item
          label="Ventas anuales MXN"
          name="annualSales"
          help={salesStage !== "NO_SALES"
            ? validation?.annualSales?.msg
            : undefined}
          validateStatus={salesStage !== "NO_SALES"
            ? validation?.annualSales?.status
            : undefined}
          rules={[{ required: salesStage !== "NO_SALES" }]}>
          <Select
            placeholder="Seleccionar"
            options={annualSalesOptionsState}
            disabled={salesStage === "NO_SALES"}
            style={{ width: "357px "}}
            onChange={() => setValidation(prev => ({
              ...prev,
              annualSales: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
      </Row>
      <Row justify="space-between">
        <Form.Item
          label="Etapa de financiación"
          name="financingStage"
          help={validation?.financingStage?.msg}
          validateStatus={validation?.financingStage?.status}
          rules={[{ required: true }]}>
          <Select
            placeholder="Seleccionar"
            options={financingStageOptions}
            style={{ width: "357px "}}
            onChange={() => setValidation(prev => ({
              ...prev,
              financingStage: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
        <Form.Item
          label="Número de empleados"
          name="employeesAmount"
          help={validation?.employeesAmount?.msg}
          validateStatus={validation?.employeesAmount?.status}
          rules={[{ required: true }]}>
          <InputNumber
            min={0}
            stringMode
            type="number"
            style={{ width: "357px "}}
            onChange={() => setValidation(prev => ({
              ...prev,
              employeesAmount: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
      </Row>
    </Form>
  );
};
