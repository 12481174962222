import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { admin } from "../../../graphql/admin";
import { client } from "../../../../graphql";
import {
  SidebarLayout,
  Section,
  useDocsContext
} from "../../../components/shared";
import {
  BatchModalProvider,
  MenuContext,
  CloneBatchModalProvider,
  PaginationContext
} from "../../../contexts/shared";
import { MainMenu } from "../../../components/admin/shared";
import { useQueryFilters, useControlledPagination } from "../../../helpers";
import {
  default as BatchesList
} from "../../../components/admin/batches/list";
import {
  FilterControls
} from "../../../components/admin/batches/filter-controls";
import {
  NOTION_PAGES_ENUM
} from "../../../components/shared/docs-wapper/notion-pages-enum";

export function BatchesIndex() {
  const { setMenuState } = useContext(MenuContext);
  useDocsContext(NOTION_PAGES_ENUM.BATCHES.pageId);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const {
    loading,
    data,
    error,
    refetch,
    variables
  } = useQuery(
    admin.queries.getBatches,
    {
      client,
      fetchPolicy: "network-only",
      variables: {
        first: 12
      }
    }
  );

  const { registerRefetch } = useContext(PaginationContext);
  registerRefetch("batches", refetch, variables);

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: { first: 12 }
  });

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 12
  });

  const onFilter = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
        ...prevFilters,
        ...filter,
        skip,
        first
    }));
  };

  return (
    <BatchModalProvider>
      <CloneBatchModalProvider>
        <SidebarLayout menu={<MainMenu />}>
          <Section
            title="Batches"
            spaced
            extra={<FilterControls onChange={onFilter} />}>
            <BatchesList
              error={error}
              data={data}
              isLoading={loading}
              onChangePage={onPaginationChange}
              currentPage={currentPage} />
          </Section>
        </SidebarLayout>
      </CloneBatchModalProvider>
    </BatchModalProvider>
  );
}
