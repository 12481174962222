import React, { useContext } from "react";
import { List, Alert } from "antd";
import { AdminExpertsContext } from "../../../../contexts/admin/experts";
import { default as ExpertItem } from "./item";
import { paginationConfig } from "../../../../helpers";
import { PaginationContext } from "../../../../contexts/shared";

export default function ExpertsList({
  onChangePage,
  currentPage,
  onClickExpert = () => {}
}) {
  const { data, loading, error, total } = useContext(AdminExpertsContext);
  const { onPaginationChange } = useContext(PaginationContext);

  if(error) {
    return (
      <Alert
        type="error"
        message="Ha ocurrido un error al cargar los expertos,
        por favor intentalo de nuevo en unos segundos."
        showIcon />
    );
  }

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange("experts")(page, pageSize);
  };

  return (
    <List
      loading={loading}
      dataSource={data}
      itemLayout="vertical"
      renderItem={i =>
        <ExpertItem expert={i} onClickExpert={() => onClickExpert(i)} />}
      pagination={{
        ...paginationConfig,
        total,
        current: currentPage,
        onChange: (page, pageSize) => handlePaginationChange(page, pageSize)
      }} />
  );
}
