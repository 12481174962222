import gql from "graphql-tag";

export const removeCompanyFromBatchMutation = gql`
  mutation RemoveCompanyFromBatchMutation(
    $batchId: ID!, 
    $companyId: ID!,
    $odesSorted: [String!]
  ) {
    removeOdeInBatch(
      data: {
        companyId: $companyId
        batchId: $batchId
        odesSorted: $odesSorted
      }
    )
  }
`;
