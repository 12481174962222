import React from "react";
import { Button, Space } from "antd";

export const ActionButtonForm = ({
  formName,
  onSave,
  setEditing
}) => {
  return (
    <Space>
      <Button
        type="default"
        onClick={() => setEditing(false, formName)}>
        Cancelar
      </Button>
      <Button
        type="primary"
        onClick={() => onSave(false, formName)}>
        Guardar
      </Button>
    </Space>
  );
};
