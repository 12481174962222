import React, { useContext } from "react";
import { Space, Typography } from "antd";
import { BatchOdesContext } from "../../../../../../contexts/admin/batches";

export function OdeProgress({ ode }) {

  const { data } = useContext(BatchOdesContext);

  const masterContent = data?.masterContents?.[0];
  const [customOdeContent] =
    data?.customOdeContents.filter(
      cstmOdeContent => cstmOdeContent.ode.id === ode.id
    );
  const contentGroups =
    masterContent?.contentGroups.concat(
      customOdeContent?.contentGroups || []
    ) || [];
  const odeContents = ode?.contents;

  const getOdeProgress = () => {
    let contentsLength = 0;
    let completedContents = 0;

    contentGroups.forEach(contentGroup => {
      contentsLength = contentsLength + (contentGroup?.contents?.length || 0);
    });

    odeContents.forEach(odeContent => {
      if(odeContent.content) { //Verifies that the content was not removed
        if(odeContents.length > 0 && odeContent.status === "VERIFIED") {
          completedContents ++;
        }
      }
    });

    if(completedContents > contentsLength) return 100;

    if (contentsLength < 1) return 0;

    return parseInt((completedContents / contentsLength) * 100);
  };

  const progress = getOdeProgress();

  return (
    <Space direction="vertical">
      <Typography.Text>
        Contenido
      </Typography.Text>
      <Typography.Title level={4} style={{ color: "#87d068" }}>
        {progress} %
      </Typography.Title>
    </Space>
  );
}
