import React, { useContext } from "react";
import { Typography, List, message, Modal } from "antd";
import { useParams } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Section, AddButton } from "../../../../../../shared";
import {
  AchievementModalContext
} from "../../../../../../../contexts/shared/achievement-modal";
import { AchievementItem } from "./item";
import { FilterControls } from "../filter-controls";
import { paginationConfig, withLoader } from "../../../../../../../helpers";
import { admin } from "../../../.././../../../graphql/admin";
import { client } from "../../../.././../../../../graphql";
import { ActionButton } from "../../../../../../shared";

function AchievementsList({ data, isLoading, setFilters, ...props }) {
  const { openModal } = useContext(AchievementModalContext);
  const { objectiveId = ""} = useParams();

  const handleOnFilterChange = filters => {
    setFilters(filters);
  };

  const [deleteObjectiveAchievement] = useMutation(
    admin.mutations.deleteObjectiveAchievement,
    {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: admin.queries.getAchievements,
        variables: {
          where: {
            objective: { id: objectiveId }
          }
        }
      }]
    });

  const deleteAchievement = async achievementId => {
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      await deleteObjectiveAchievement({
        variables: {
          objectiveId,
          achievementId
        }
      });
      message.success("Cambios guardados");
    } catch (error) {
      message.error("Ha ocurrido un error, inténtalo de nuevo\
      en unos segundos.");
    } finally {
      dismissLoader();
    }
  };

  const handleOnDelete = async achievementId => {
    Modal.confirm({
      okText: "Eliminar",
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: "Estás a punto de eliminar un logro de este objetivo\
        ¿Deseas continuar?",
      onOk: () => deleteAchievement(achievementId)
    });
  };

  return (
    <Section
      extra={
        <FilterControls
          objectiveId={objectiveId}
          onChange={handleOnFilterChange} /> }
      title="Logros"
      spaced>
      <Typography.Text>
        Los logros son incentivos para los emprendedores. Estos ayudan a
        entusiasmar y levantar la moral para lograr un objetivo.
      </Typography.Text>
      <AddButton
        onClick={() => openModal(objectiveId)}
        style={{ width: "100%", marginTop: "20px" }}>
        Agregar logro
      </AddButton>
      <List
        loading={isLoading}
        style={{ marginTop: "20px" }}
        itemLayout="horizontal"
        dataSource={data?.achievements}
        pagination={{
          ...paginationConfig,
          pageSize: 10
        }}
        renderItem={i =>
          <AchievementItem
            achievement={i}
            extra={<ActionButton onDelete={() => handleOnDelete(i?.id)} />} /> }
        {...props} />
    </Section>
  );
}

export default withLoader(AchievementsList);
