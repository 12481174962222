import React from "react";
import { Route, Switch } from "react-router-dom";
import { default as InvitationsIndex } from "../../pages/invitations";
import { CompanyIndex } from "../../pages/invitations/entrepreneur/company";
import { WelcomeIndex } from "../../pages/invitations/entrepreneur/welcome";
import {
  InformationIndex
} from "../../pages/invitations/entrepreneur/information";
import {
  UpdateInformationIndex
} from "../../pages/invitations/entrepreneur/information/update-information";

export const InvitationsRoutes = <Switch>
  <Route
    exact
    path="/v2/invitations/entrepreneur/:id/information"
    component={InformationIndex} />
  <Route
    exact
    path="/v2/invitations/entrepreneur/:id/update-information"
    component={UpdateInformationIndex} />
  <Route
    exact
    path="/v2/invitations/entrepreneur/:id/company"
    component={CompanyIndex} />
  <Route
    exact
    path="/v2/invitations/entrepreneur/:id/welcome"
    component={WelcomeIndex} />
  <Route
    path="/v2/invitations/:id"
    component={InvitationsIndex} />
</Switch>;
