import React, { useContext } from "react";
import { List } from "antd";
import {
  cardGridSizes,
  ONGOING_STATUSES
} from "../../../../../../../../../../helpers";
import {
  ServicesLayerContext
} from "../../../../../../../../../../contexts/shared";
import { useService } from "../../../../../../../../../../hooks/shared";
import { AssignedMentoringListItem } from "./assigned-mentoring-list-item";

export function AssignedMentorings({ batch = null, company = null }) {
  const { CompanyService } = useContext(ServicesLayerContext);
  const shouldFetchServices = !!batch && !!company;

  const {
    data: assignedServices,
    loading: loadingAssigned,
    refetch: refetchAssigned
  } = useService(
    CompanyService.getAssignedServicesByBatch,
    {
      types: ["MENTORSHIP"],
      batchId: batch?.id,
      companyId: company?.id
    },
    { shouldFetch: shouldFetchServices }
  );

  const {
    data: scheduledServices,
    loading: loadingScheduled,
    refetch: refetchScheduled
  } = useService(
    CompanyService.getScheduledServicesByBatch,
    {
      types: ["MENTORSHIP"],
      batchId: batch?.id,
      companyId: company?.id
    },
    { shouldFetch: shouldFetchServices }
  );

  const refetchScheduledMentorings = () => {
    refetchScheduled();
    refetchAssigned();
  };

  const datasource = (assignedServices || [])
    .concat((scheduledServices || [])
      .map(ss => ({
        ...ss.assignedExpertService || {},
        scheduledExpertService: ss
      }))
    );

  const onGoingServices = scheduledServices?.reduce((acc = {}, curr) => {
    const isOnGoing = ONGOING_STATUSES.includes(curr.status);

    if (isOnGoing) {
      const { expertService } = curr.assignedExpertService || {};
      acc[expertService?.id] = true;
    }

    return acc;
  }, {});

  return (
    <List
      loading={loadingAssigned || loadingScheduled}
      grid={cardGridSizes}
      itemLayout="horizontal"
      dataSource={datasource}
      renderItem={assignedService => (
        <List.Item>
          <AssignedMentoringListItem
            company={company}
            onGoingServices={onGoingServices || {}}
            refetchScheduledMentorings={refetchScheduledMentorings}
            assignedService={assignedService} />
        </List.Item>
      )} />
  );
}
