import React  from "react";
import { Layout, Space, Typography } from "antd";
import { FlexContainer, Section } from "../../../../components/shared";
import { ReactComponent as Logo } from "../../../../images/logo.svg";
import {
  OrganizationEnvironmentMapIndex
} from "../../../../components/public/organization/organization-dashboard";
import Icon from "@ant-design/icons";

export function AsemEmbeddedPurposePageIndex() {

  return (
    <Layout className="asem Layout">
      <Space
        className="asem OrganizationIndex"
        direction="vertical">
        <Section
          className="OrganizationIndex__controls"
          spaced>
          <FlexContainer
            direction="horizontal"
            justifyContent="flex-end"
            style={{
              gap: ".8rem",
              alignItems: "center"
          }}>
            <Typography.Text
              strong
              style={{color: "#6348E5"}}>
              powered by
            </Typography.Text>
            <Icon
              component={Logo}
              style={{
                fontSize: "55px",
                marginBottom: "10px"
              }} />
          </FlexContainer>
          <OrganizationEnvironmentMapIndex />
        </Section>
      </Space>
    </Layout>
  );
}
