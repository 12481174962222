import React, { useState, useEffect, useContext } from "react";
import { List, Input, Button, Form, message } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { CommentItem } from "./item";
import { CurrentUserContext } from "../../../contexts/shared";
import { Avatar } from "../avatar";
import { genericFieldValidationMsgs } from "../../../utils";

/**
 * @param {object} props
 * @param {any[]} props.comments List of comment objects
 * @param {boolean} props.loading Sets the comments list to loading mode
 * @param {function} props.sendComment Callback to save the new comment
 * @param {function} props.editComment Callback to save the changes made to
 * the selected comment. Enables the editing functionallity if passed,
 * and disables it if don't.
 */
export const CommentsList = ({
  comments,
  loading,
  sendComment,
  editComment
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const [ form ] = Form.useForm();
  const { TextArea } = Input;

  const [sendCommentLoading, setSendCommentLoading] = useState(false);

  const onSendComment = async () => {
    await form.validateFields();
    setSendCommentLoading(true);
    try {
      const { comment } = form.getFieldsValue();
      await sendComment(comment);
      scrollToTheBottom();
      form.resetFields();
      setSendCommentLoading(false);
    } catch(e) {
      console.error(e);
      message.error("No se pudo enviar el mensaje");
      setSendCommentLoading(false);
    }
  };

  /**
   * @param {object} comment Comment object. Must have id and message fields
   */
  const onEdit = async comment => {
    await editComment(comment);
  };

  const scrollToTheBottom = () => {
    const commentList = document.getElementById("comments-content");
    commentList.scrollTop = commentList.scrollHeight - commentList.clientHeight;
  };

  useEffect(() => {
    scrollToTheBottom();
  }, []);

  const currentUserCanEdit = author => {
    return (author.id === currentUser.id && editComment !== undefined);
  };

  return (
    <>
      <List
        locale={{ emptyText: "Aún no hay comentarios" }}
        id="comments-content"
        style={{height: "300px", overflowY: "scroll", scrollBehavior: "smooth"}}
        loading={loading}
        dataSource={comments}
        renderItem={comment =>
          <CommentItem
            comment={comment}
            onEdit={currentUserCanEdit(comment.author) ?
              onEdit
              : undefined} />} />
      <List.Item.Meta
        style={{ marginTop: "30px" }}
        avatar={<Avatar>
          {currentUser?.profilePicUrl || currentUser.fullName}
        </Avatar>}
        description={
          <Form
            validateMessages={genericFieldValidationMsgs}
            form={form}>
            <Form.Item
              rules={[{ required: true }]}
              name="comment">
              <TextArea rows={4} placeholder="Escribe un comentario..." />
            </Form.Item>
          </Form>} />
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse"}}>
        <Button
          icon={<SendOutlined />}
          loading={sendCommentLoading}
          onClick={onSendComment}>
          {comments?.length ? "Responder" : "Enviar"}
        </Button>
      </div>
    </>
  );
};
