import gql from "graphql-tag";

export const getBatches = gql`
  query GetBatches($where: BatchWhereInput){
    batches(where: $where){
      id
      name
      program{
        id
        coverPicUrl
      }
    }
  }
`;