export const getCampusAttendancesChartConfig = ({
  attendances,
  nationalAttendances
}) => {
  const experiencesByCampus = attendances.map(el => el?.experience);
  const nationalExperiences = nationalAttendances.map(el => el?.experience);

  const getDataByInvolvementLevels = experiences => {
    return experiences.reduce((acc, experience) => {
      if (!experience.involvementLevel) return acc;

      acc[experience?.involvementLevel] =
        (acc[experience?.involvementLevel] || 0) + 1;

      return acc;
    }, {});
  };

  const campusLevelCount = getDataByInvolvementLevels(experiencesByCampus);
  const nationalLevelCount = getDataByInvolvementLevels(nationalExperiences);


  const experienceByCampusDataset = {
    label: "Campus",
    backgroundColor: "#FFD9A0",
    data: Object.keys(EXPERIENCE_LEVEL).map(el => campusLevelCount[el] || 0)
  };

  const NationalExperienceDataset = {
    label: "Nacional",
    backgroundColor: "#B3EEE8",
    data: Object.keys(EXPERIENCE_LEVEL).map(el => nationalLevelCount[el] || 0)
  };

  const data = {
    labels: Object.values(EXPERIENCE_LEVEL),
    datasets: [experienceByCampusDataset, NationalExperienceDataset]
  };

  return { data, options };
};

export const EXPERIENCE_LEVEL = {
  KNOW: "Conocer",
  LEARN: "Aprender",
  ACT: "Accionar"
};

const options = {
  barPercentage: .7,
  categoryPercentage: .4,
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 0
    }
  },
  plugins : {
    tooltip: {
      titleColor: "#000",
      bodyColor: "#000",
      backgroundColor: "rgba(255, 255, 255, 0.75)",
      titleFont: { weight: "bold" },
      titleFontSize: 16,
      bodyFontSize: 14,
      displayColors: false,
      callbacks: {
        title: tooltipItem => {
          return tooltipItem[0].label;
        },
        label: tooltipItem => {
          const { formattedValue } = tooltipItem;

          return `${formattedValue} experiencias`;
        }
      }
    }
  },
  scales: {
    x: {
      grid: {
        borderDash: [2, 2]
      },
      ticks: {
        beginAtZero: true,
        callback: number => {
          if (Math.floor(number) === number) {
            return number;
          }
        }
      }
    },
    y: {
      grid: {
        display: false
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false
};