import gql from "graphql-tag";

export const deleteMilestoneByOdeIdAndMilestoneId = gql`
  mutation DeleteMilestoneByOdeIdAndMilestoneId(
    $odeId: ID!,
    $milestoneId: ID!
  ) {
    updateODE(
      where: { id: $odeId }
      data: { milestones: { delete: { id: $milestoneId } } }
    ) {
      id
    }
  }
`;