import React, { useContext } from "react";
import { Table } from "antd";
import {
  expertServiceTypes,
  getReadableValue,
  maskedAssignedUnits,
  paginationConfig
} from "../../../../../helpers";
import { Format, ServiceCost, StatusTag } from "../../../../shared";
import {
  PaginationContext,
  ServiceDetailModalContext
} from "../../../../../contexts/shared";
import {
  ServiceApprovalModalContext,
  ServiceDeliveryModalContext
} from "../../../../../contexts/expert/services/requests";

export function ScheduledTable({
  loading = false,
  items: assignations = [],
  currentPage,
  onChangePage,
  totalRecordsCount,
  paginationRefetchName,
  serviceType
}) {
  const { onPaginationChange } = useContext(PaginationContext);
  const { openModal } = useContext(ServiceApprovalModalContext);
  const {
    openModal: openDeliveryModal
  } = useContext(ServiceDeliveryModalContext);
  const {
    openModal: openDetailsModal
  } = useContext(ServiceDetailModalContext);

  const handleOnRowClick = scheduledService => {
    if (scheduledService.status === "SCHEDULED") {
      openModal(scheduledService);
    } else if (["ON_GOING", "DELIVERY_REJECTED"].includes(
      scheduledService.status
    )) {
      openDeliveryModal(scheduledService);
    } else {
      openDetailsModal({
        scheduledExpertService: scheduledService
      });
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange(paginationRefetchName)(page, pageSize);
  };

  return (
    <Table
      loading={loading}
      scroll={{ x: true }}
      style={{ width: "100%" }}
      dataSource={assignations || []}
      rowKey={(row, index) => row?.id || index}
      onRow={scheduledService => ({
        onClick: () => handleOnRowClick(scheduledService),
        className: "clickable"
      })}
      pagination={{
        ...paginationConfig,
        current: currentPage,
        defaultPageSize: 12,
        onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
        total: totalRecordsCount
      }}>
      {serviceType !== "WORKSHOP" && (
        <Table.Column
          title="Compañía"
          render={({ ode }) => ode?.name } />
      )}
      <Table.Column
        title="Programa"
        render={({ batch }) => batch?.program?.name} />
      <Table.Column
        title="Edición"
        render={({ batch }) => batch?.name} />
      <Table.Column
        title="Producto"
        render={({ expertServiceData }) => expertServiceData?.name} />
      <Table.Column
        title="Tipo de producto"
        render={({ expertServiceData }) =>
          getReadableValue(expertServiceTypes, expertServiceData?.type) } />
      <Table.Column
        align="center"
        title="Cantidad"
        render={({ expertServiceData: { method }, duration }) =>
          maskedAssignedUnits({ method, duration })} />
      <Table.Column
        align="center"
        title="Costo total"
        render={({ duration, ...assignedExpertService }) =>
          <ServiceCost
            assignedExpertService={assignedExpertService}
            duration={duration} /> } />
      <Table.Column
        align="center"
        title="Fecha de asignación"
        render={({ assignmentDate }) =>
          <Format type="date">{assignmentDate}</Format>
        } />
      <Table.Column
        align="center"
        title="Fecha límite de consumo"
        render={({ limitDate }) =>
          <span className="bold">
            <Format type="date">{limitDate}</Format>
          </span>
        } />
      <Table.Column
        align="center"
        title="Estado"
        render={({ status }) => {
          return (<StatusTag status={status} />);
        }} />
    </Table>
  );
}
