import { cubeMeasureCounter } from "../cube-measure-counter";
import { buildCubeFilters } from "../build-cube-filters";

export function countEntrepreneursBy({ dimensions = [], filters = {} }) {
  return cubeMeasureCounter({
    by: dimensions,
    filters: buildCubeFilters(filters)
  })
    .entrepreneursCount();
}
