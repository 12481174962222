import gql from "graphql-tag";

export const acceptRequestedService = gql`
  mutation UpdateScheduled($id: ID!) {
    updateScheduledExpertService (
      where: { id: $id }
      data: { status: "ON_GOING" }
    ) {
      id
    }
  }
`;
