import React from "react";
import { Route, Switch } from "react-router-dom";
import { OrganizationIndex } from "../../pages/public/organization";
import {
  RegisterOrganizationIndex
} from "../../pages/public/register-organization";
import {
  RegisterOrganizationSuccessIndex
} from "../../pages/public/register-organization/success";
import { ReportingTool } from "../../pages/public/reporting-tool";
import {
  ImportBatchInstitutionsIndex
} from "../../pages/public/reporting-tool/import-batch-institutions";
import { OrganizationDashboardIndex } from "../../pages/public/dashboard";
import { AnalyticsPage } from "../../pages/public/analytics";
import {
  AsemEmbeddedPurposePageIndex
} from "../../pages/public/dashboard/asem-embedded-purpose-page";

export const PublicRoutes = (
  <Switch>
    <Route
      path="/v2/organizations"
      component={OrganizationIndex} />
    <Route
      path="/v2/organizations-dashboard"
      component={OrganizationDashboardIndex} />
    <Route
      path="/v2/asem-analytics"
      component={AsemEmbeddedPurposePageIndex} />
    <Route
      path="/v2/register-organization"
      exact
      component={RegisterOrganizationIndex} />
    <Route
      path="/v2/register-organization/success"
      component={RegisterOrganizationSuccessIndex} />
    <Route
      path="/v2/reporting-tool"
      component={ReportingTool} />
    <Route
      path="/v2/import-batches-institutions"
      component={ImportBatchInstitutionsIndex} />
    <Route
      path="/v2/analytics"
      component={AnalyticsPage} />
  </Switch>
);
