import React from "react";
import {
} from "../../../../entrepreneur/shared/modals/components";
import { BaseServiceDetailModal } from "../../base-service-detail-modal";
import {
  ScheduledProductHeader
} from "./header";
import { useService } from "../../../../../hooks/shared";
import {
  AssignedExpertServiceService,
  ScheduledExpertServiceService
} from "../../../../../services";
import { DetailContent } from "./detail-content";
import { Skeleton } from "antd";

export const OdesAssignedServicesDetail = ({ selectedItem, ...props }) => {
  const isScheduled = !!selectedItem?.scheduledExpertService;

  const query = isScheduled
    ? ScheduledExpertServiceService.getById
    : AssignedExpertServiceService.getById;

  const variables = isScheduled
    ? { id: selectedItem?.scheduledExpertService.id}
    : { id: selectedItem?.id };

  const {
    loading,
    data
  } = useService(
    query,
    variables,
    { shouldFetch: !!variables?.id && props.visible }
  );

  const assignedExpertService = isScheduled
    ? { ...(data || {})?.assignedExpertService, scheduledExpertService: data }
    : data;

  return (
    <BaseServiceDetailModal
      header={<ScheduledProductHeader
        assignedExpertService={assignedExpertService} />}
      footer={null}
      {...props}>
      {loading ?
        <Skeleton paragraph={{ rows: 3 }} />
        : <DetailContent assignedExpertService={assignedExpertService} /> }
    </BaseServiceDetailModal>
  );
};
