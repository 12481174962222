import React from "react";
import { Space, Select } from "antd";
import { remove as removeAccents } from "remove-accents";
import { debounce } from "lodash";
import {
  ProgramsSelect,
  SearchToolbar,
  ServiceCategoriesDropdown
} from "../../../../../shared";
import {
  entrepreneurScheduledServicesStatus,
  expertServiceTypes
} from "../../../../../../helpers/select-options";

export function FilterControls({ onChange }) {

  const handleOnStatusChange = status => {
    let statuses = undefined;

    if (status) {
      statuses = status === "ACCEPTED" ?
      ["ACCEPTED", "AUTHORIZED_FOR_PAYMENT", "PAID"]
      : [status];
    }

    onChange && onChange({ key: "status", value: statuses });
  };

  const onCategoryChange = category => {
    onChange && onChange({ value: category, key: "category" });
  };

  const handleSearchOnChange = debounce(text => {
    const stringValue = removeAccents(text).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ value, key: "searchString" });
  }, 500);

  return (
    <>
      <Space style={{ width: "100%", justifyContent: "flex-end" }}>
        <ServiceCategoriesDropdown
          onChange={val => onCategoryChange(val)} />
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          options={entrepreneurScheduledServicesStatus}
          style={{ width: "180px" }}
          onChange={val => handleOnStatusChange(val)}
          placeholder="Estado" />
        <SearchToolbar
          onSearchChange={e => handleSearchOnChange(e.target.value)} />
      </Space>
      <Space style={{
        marginTop: "10px",
        width: "100%",
        justifyContent: "flex-end"
      }}>
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          options={expertServiceTypes}
          style={{ width: "180px" }}
          onChange={value => onChange({ value, key: "type"})}
          placeholder="Tipo" />
        <ProgramsSelect
          onChange={value => onChange({ value, key: "programId" })}
          style={{ width: "180px" }} />
      </Space>
    </>
  );
}
