import React, { useState, useEffect } from "react";
import { Col, Form, Input, Row, Select } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Visibility } from "../../../../visibility";
import {
  TEC_RELATIONSHIP_TYPES,
  gradesStudentList,
  CAREERS
} from "../../../../../../helpers";
import { DatePicker } from "../../../../date-picker";
import { fieldValidationMessages } from "../../../../../../utils";
import { EDUCATION_INSTITUTIONS } from "../../../../../../helpers";

export const TecRelationshipItem = ({
  values = [],
  setStateForm,
  tecRelationship,
  idx,
  removeItem,
  onChange
}) => {
  const [form] = Form.useForm();

  const [state, setState] = useState({
    tecRelationship: [],
    filterType: []
  });

  useEffect(() => {
    const filter = values.map(el => el.type);
    const filterType =
      TEC_RELATIONSHIP_TYPES.filter(tec => !filter.includes(tec.value));

    setStateForm && setStateForm(form);
    form.setFieldsValue(tecRelationship);
    setState({ ...state, filterType });
  }, [values]);

  const onChangeRelationship = value => {
    setState({ ...state, tecRelationship: value });
    return value;
  };

  return (
    <Row gutter={2} style={{ width: "100%" }}>
      <Col span={1}>
        <CloseCircleOutlined onClick={removeItem(idx)} />
      </Col>
      <Col span={23}>
        <Form
          layout="vertical"
          validateMessages={fieldValidationMessages}
          name={`tecRelationship-input-form-${idx}`}
          onValuesChange={(_, allValues) => onChange(allValues)}
          form={form}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="type"
            label="Tipo de vínculo"
            getValueFromEvent={onChangeRelationship}>
            <Select options={state?.filterType} />
          </Form.Item>
          <Visibility
            visible={["STUDENT", "Alumno"]
              .includes(tecRelationship.type)}>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="enrollment"
                  label="Matrícula">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="careerName"
                  label="Carrera">
                  <Select options={CAREERS} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="campus"
                  label="Campus">
                  <Select options={EDUCATION_INSTITUTIONS} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="grade"
                  label="Nivel">
                  <Select options={gradesStudentList} />
                </Form.Item>
              </Col>
            </Row>
          </Visibility>
          <Visibility visible={["EXATEC"].includes(tecRelationship.type)}>
            <Form.Item
              rules={[{ required: true }]}
              name="enrollment"
              label="Matrícula">
              <Input />
            </Form.Item>
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="careerName"
                  label="Carrera">
                  <Select options={CAREERS} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[{ required: true }]}
                  name="graduation"
                  label="Año de graduación">
                  <DatePicker />
                </Form.Item>
              </Col>
            </Row>
          </Visibility>
          <Visibility visible={[
            "PROFESSOR",
            "COLABORATOR",
            "Profesor",
            "Colaborador"
          ].includes(tecRelationship.type)}>
            <Form.Item
              rules={[{ required: true }]}
              name="payroll"
              label="Nómina">
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              name="campus"
              label="Campus">
              <Select options={EDUCATION_INSTITUTIONS} />
            </Form.Item>
          </Visibility>
        </Form>
      </Col>
    </Row>
  );
};
