import { Form, Space, Avatar, Input } from "antd";
import React, { useContext, useEffect } from "react";
import { CountrySelect, RoleGuard, StateSelect, UploadButton, Visibility } from "../../../shared";
import { CurrentUserContext, LocationSelectorProvider } from "../../../../contexts/shared";
import { clearString } from "../../../../helpers";

export function EditableField({ onChange, user }) {
  const { currentUser } = useContext(CurrentUserContext);
  const [form] = Form.useForm();
  const getPhotoUrl = e => {
    const profilePicUrl = e?.file?.response?.imageUrl;
    return e.fileList;
  };

  const onChangeForm = () => {
    const values = form.getFieldsValue();

    const fullName = [
      values.firstName,
      values.middleName,
      values.lastName]
      .join(" ");

    values.fullName = fullName;

    if (values.password)
      values.password = clearString(values.password);

    if (values.email)
      values.email = clearString(values.email);

    onChange && onChange(values);
  };

  useEffect(() => {
    if(user) {
      form.setFieldsValue(user);
    }
  }, [user]);

  return (
    <Form
      form={form}
      layout="inline"
      onChange={onChangeForm}>
      <Space
        direction="horizontal"
        size={20}
        align="start">
        <Space direction="vertical" align="center">
          <Avatar
            size={64}>
          </Avatar>
          <Form.Item
            name="profilePicUrl"
            getValueFromEvent={getPhotoUrl}>
            <UploadButton.Crop
              type="primary"
              aspect={0.9}
              multiple={false}
              maxFiles={1}
              showUploadList={false}>
              Subir fotografía
            </UploadButton.Crop>
          </Form.Item>
        </Space>
        <Space direction="vertical" size={5}>
          <Space direction="horizontal">
            <Form.Item
              label="Nombre"
              name="firstName">
              <Input />
            </Form.Item>
            <Form.Item
              name="middleName">
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName">
              <Input />
            </Form.Item>
          </Space>
          <Space direction="horizontal">
            <Form.Item
              label="Correo electrónico"
              name="email">
              <Input
                type="email"
                disabled={currentUser?.systemRole !== "SUPER_ADMIN"} />
            </Form.Item>
            <RoleGuard roles={["SUPER_ADMIN"]}>
              <Form.Item
                label="Contraseña"
                name="password">
                <Input
                  type="password" />
              </Form.Item>
            </RoleGuard>
            <Form.Item
              label="Teléfono"
              name="phone">
              <Input type="tel" />
            </Form.Item>
          </Space>
        </Space>
      </Space>
    </Form>
  );
}
