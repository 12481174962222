import _ from "lodash";
import { getDeletedIdsFromTwoArrays } from "../../../helpers/get-deleted-ids-from-two-arrays";

export const formatValues = (values, currentUser, contentId, editing, isPublished) => {
  if (editing) {
    const materialsWithId = values.materials?.filter(material => material.id);
    const newMaterials = values.materials?.filter(material => !material.id);

    const deletedMaterials = getDeletedIdsFromTwoArrays(
      materialsWithId,
      values.oldMaterials
    );

    newMaterials.map(material => {
      delete material.uuid;
      delete material.__typename;
      return material;
    });

    if(values.dueDate) {
      values.dueDate = unifyDueDate(values.dueDate, values.dueDateTime);
      delete values.dueDateTime;
    }

    delete values.oldMaterials;

    let data = {
      ...values,
      materials: {
        create: newMaterials,
        deleteMany: { id_in: deletedMaterials }
      }
    };

    if(isPublished) {
      data = {
        draft: data,
        materials: data.materials
      };
      delete data.draft.materials;
    }

    return {
      contents: {
        update: {
          where: { id: contentId },
          data
        }
      }
    };
  } else {
    values.materials = {
      create: values.materials?.map(material => _.omit(material, ["uuid", "uid", "id", "__typename"])) || []
    };

    if(values.dueDate) {
      values.dueDate = unifyDueDate(values.dueDate, values.dueDateTime);
      delete values.dueDateTime;
    }

    return {
      contents: {
        create: {
          ...values,
          creationConfirmed: isPublished,
          createdBy: { connect: { id: currentUser.id } }
        }
      }
    };
  }
};

export const formatDuplicateValues = (content, user) => {
  const cloneContent = _.cloneDeep(content);
  delete cloneContent.id;
  delete cloneContent.__typename;
  return {
    contents: {
      create: {
        ...cloneContent,
        name: `Duplicado de ${cloneContent.name}`,
        createdBy: { connect: { id: user.id } },
        materials: {
          create: cloneContent.materials?.map(material => _.omit(material, ["id", "__typename"]))
        }
      }
    }
  };
};

const unifyDueDate = (dueDateString, dueDateTimeDate) => {
  const dueDateTime = dueDateTimeDate ?
    new Date(dueDateTimeDate.toISOString())
    : null;

  const dueDate = new Date(dueDateString);
  // if due date is not set, use the 11:59:59 of the due date (end of the day)
  dueDate.setHours(
    dueDateTime ? dueDateTime.getHours() : 11,
    dueDateTime ? dueDateTime.getMinutes() : 59,
    dueDateTime ? dueDateTime.getSeconds() : 59
  );

  return dueDate.toISOString();
};
