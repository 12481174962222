import React from "react";
import { Select } from "antd";
import { scheduledServicesStatus } from "../../../helpers/select-options";

/**
 * @deprecated Component not really reusable,
 * prefer using the Select component of Ant with scheduledServicesStatus options
 */
export function ServiceStatusDropdown({ onChange, options }) {

  return (
    <Select
      allowClear
      showSearch
      optionFilterProp="label"
      options={options || scheduledServicesStatus}
      style={{ width: "180px" }}
      onChange={onChange}
      placeholder="Estado" />
  );
}
