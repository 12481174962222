import React, { useContext } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { admin } from "../../graphql/admin";
import { client } from "../../../graphql";
import { withRouter } from "../../helpers";
import RegisterForm from "../../components/shared/register-form";
import { LoginLayout } from "../../components/shared";
import { AuthServiceContext } from "../../contexts/shared/auth";
import { shared } from "../../graphql/shared";
import { useParams } from "react-router";
import { Result, Spin } from "antd";
import {
  IdcardOutlined,
  FrownOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { UsageTrackingService } from "../../services";

function InvitationsIndex() {
  const { authService } = useContext(AuthServiceContext);
  const { id } = useParams();
  const [createInvitedUser] = useMutation(
    admin.mutations.createInvitedUser,
    { client }
  );

  const { data, error, loading } = useQuery(shared.queries.getInvitationById, {
    variables: {
      id: id
    },
    client
  });

  const trackCreatedUser = async (userData = {}) => {
    try {
      const user = {
        id: userData.id,
        email: userData.email,
        systemRole: userData.systemRole
      };
      await UsageTrackingService.trackAcceptedInvitationUser({ user });
      await UsageTrackingService.trackNewUser({ user });
    } catch (e) {
      console.error(e);
    }
  };

  const signUp = async values => {
    const systemRole = data.invitation?.systemRole;

    values.variables.systemRole = systemRole;
    values.variables.invitationId = id;

    await createInvitedUser({
      variables: {
        data: values.variables
      }
    });

    await authService.login(values);
    await trackCreatedUser(data.invitation);

    switch (systemRole) {
      case "ADVISER":
        return window
          .location.replace("/v2/expert/onboarding/general-information");
      // @todo remove this case because the new onboarding will replace it
      case "ENTREPRENEUR":
        return window
        .location
        .replace("/v2/entrepreneur/onboarding/personal-information");
      case "COORDINATOR":
        return window
          .location
          .replace("/v2/coordinator/onboarding/personal-information");
      default:
        break;
    }
  };

  if(loading) {
    return (
      <LoginLayout>
        <Result
          icon={<IdcardOutlined />}
          title="Verificando tu invitación..."
          extra={<Spin size="large" />} />
      </LoginLayout>
    );
  }

  if(error || !data) {
    return (
      <LoginLayout>
        <Result
          icon={<ExclamationCircleOutlined />}
          status="warning"
          title="Ha ocurrido un error"
          extra={
            <>
              No se ha podido verificar tu invitación,<br />
              por favor inténtalo de nuevo en unos segundos.
            </>
          } />
      </LoginLayout>
    );
  }

  if(data.invitation === null) {
    return (
      <LoginLayout>
        <Result
          status="error"
          icon={<FrownOutlined />}
          title="Esta invitación no es válida"
          extra={
            <>
              Contacta con tu coordinador para que te envíe
              <br />
              una nueva invitación.
            </>
          } />
      </LoginLayout>
    );
  }

  return (
    <LoginLayout systemRole={data.invitation?.systemRole}>
      <RegisterForm
        invitedEmail={data.invitation?.email}
        signingFunction={signUp}
        mode="signup" />
    </LoginLayout>
  );
}

export default withRouter(InvitationsIndex);
