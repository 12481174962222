import React, { useContext } from "react";
import { Space, Button } from "antd";
import { debounce } from "lodash";
import { remove as removeAccents } from "remove-accents";
import { SearchToolbar } from "../../../../../components/shared";
import {
  InvitationModalContext
} from "../../../../../contexts/shared/invitation-modal";

export function FilterControls({ onChange }) {
  const { openModal } = useContext(InvitationModalContext);

  const handleSearchOnChange = debounce((text = "") => {
    const stringValue = removeAccents(text).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ ifullName: value });
  }, 500);

  return (
    <Space>
      <Button
        type="primary"
        onClick={() => openModal(false, "ADVISER")}>
        Invitar expertos
      </Button>
      <SearchToolbar
        onSearchChange={e => handleSearchOnChange(e.target.value)} />
    </Space>
  );
}
