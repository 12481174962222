import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { Summary } from "../../../../../components/admin/batches/show/summary";
import { MainMenu } from "../../../../../components/admin/shared";
import { Section, SidebarLayout } from "../../../../../components/shared";
import { admin } from "../../../../../graphql/admin";
import { client } from "../../../../../../graphql";
import {
  default as Navbar
} from "../../../../../components/admin/batches/show/navbar";
import { BatchDetailsContextProvider } from "../../../../../contexts/admin/batches/batch-details";
import {
  default as PerksList
} from "../../../../../components/entrepreneur/batches/show/perks/list";
import { entrepreneur } from "../../../../../graphql/entrepreneur";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { CreatePerkModalProvider } from "../../../../../contexts/shared/create-perk-modal/provider";

/**
 * @deprecated  Stop using the Batch Detail page. The Batch Offer
 * replaces this page.
 * @returns {JSX.Element}
 * @constructor
 */
export function PerksIndex() {
  const { id } = useParams();

  const { loading, data, error } = useQuery(
    entrepreneur.queries.getPerksByBatchId,
    { client, variables: { id: id } }
  );

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const {
    loading: batchLoading,
    error: batchError,
    data: batchData
  } = useQuery(
    admin.queries.getBatchById,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      client
    }
  );

  return (
    <BatchDetailsContextProvider>
      <CreatePerkModalProvider>
        <SidebarLayout menu={<MainMenu />}>
          <Summary
            isLoading={batchLoading}
            error={batchError}
            data={batchData} />
          <Navbar selectedKeys={["perks"]} />
          <Section spaced title="Perks">
            <PerksList
              data={data}
              error={error}
              isLoading={loading} />
          </Section>
        </SidebarLayout>
      </CreatePerkModalProvider>
    </BatchDetailsContextProvider>
  );
}
