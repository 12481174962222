import React, { useState, useContext } from "react";
import { message, Modal, Form } from "antd";
import { remove as removeAccents } from "remove-accents";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ExpertServiceModalContext } from "./context";
import { client } from "../../../../graphql";
import { ExpertServiceModal } from "./modal";
import {
  ExpertServiceService
} from "../../../services/deprecated/expert-service-service";
import { shared } from "../../../graphql/shared";
import { UsageTrackingService } from "../../../services";
import { CurrentUserContext } from "../current-user";
import {
  expertServiceTypes,
  getReadableValue
} from "../../../helpers";

export function ExpertServiceModalProvider({ children, refetchVars }) {
  const [state, setState] = useState({
    isModalOpen: false,
    edit: false,
    newService: false,
    expertId: undefined,
    readOnly: false,
    expertMode: false
  });

  const { currentUser } = useContext(CurrentUserContext);

  const [form] = Form.useForm();

  const openModal = ({
    expertService,
    expertId,
    newService,
    readOnly = false,
    expertMode = false
  }) => {
    setState({
      isModalOpen: true,
      edit: expertService,
      newService: newService,
      readOnly,
      expertMode
    });
    setExpertId(expertId);
  };

  const disableExpertService = async ({ id, expertId }) => {
    await client.mutate({
      mutation: shared.mutations.disableExpertService,
      variables: { id },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: shared.queries.getServices,
        variables: refetchVars ?? {}
      },
      {
        query: shared.queries.getServicesByExpertId,
        variables: {
          expertId,
          ...(refetchVars ?? {})
        }
      }]
    });
  };

  const onDisableExpertService = ({ id, expertId }) => {
    Modal.confirm({
      okText: "Deshabilitar",
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: "¿Deseas deshabilitar este servicio?",
      onOk: async () => {
        try {
          await disableExpertService({ id, expertId });
          message.success("Servicio deshabilitado.");
          closeModal();
        } catch (error) {
          message.error("Ha ocurrido un error, inténtalo de nuevo\
            en unos segundos.");
        }
      }
    });
  };

  const closeModal = () => {
    form.resetFields();
    setState({
      isModalOpen: false,
      edit: false,
      expertId: undefined,
      readOnly: false,
      expertMode: false
    });
  };

  const setExpertId = expertId => {
    setState(prevState => ({
      ...prevState,
      expertId: expertId
    }));
  };

  const saveExpertService = async values => {
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      if (!state.expertId) {
        throw "Expert ID is required";
      }

      delete values.__typename;
      delete values.expert;
      delete values.index;
      delete values.assignedExpertServices;

      if (values.name !== undefined) {
        values.iname = removeAccents(values.name).toLowerCase();
      }

      // the input returns string or a number depending on the browser
      values.price = String(values.price);

      const expertServiceService = new ExpertServiceService();
      await expertServiceService.createOrUpdate(
        values,
        state.expertId,
        refetchVars
      );

      if (!values.id) {
        UsageTrackingService.trackNewProduct({
          product: {
            name: values.name,
            type: getReadableValue(expertServiceTypes, values.type)
          },
          addedBy: {
            id: currentUser.id,
            fullName: currentUser.fullName,
            email: currentUser.email,
            systemRole: currentUser.systemRole
          }
        });
      }

      message.success("Servicio guardado.");
      closeModal();
    } catch(e) {
      message.error("Ha ocurrido un error, inténtalo de nuevo\
        en unos segundos.");
      console.error(e);
    } finally {
      dismissLoader();
    }
  };

  return (
    <ExpertServiceModalContext.Provider value={{
        openModal,
        closeModal,
        onDisableExpertService
      }}>
      <ExpertServiceModal
        form={form}
        onDisableExpertService={onDisableExpertService}
        visible={state.isModalOpen}
        onCancel={closeModal}
        onSave={saveExpertService}
        newService={state.newService}
        edit={state.edit}
        readOnly={state.readOnly}
        expertMode={state.expertMode} />
      { children }
    </ExpertServiceModalContext.Provider>
  );
}
