export function getUpdatedData(entrepreneurships = []) {
  return {
    expertProfile: {
      update: {
        professionalExperience: {
          update: {
            entrepreneurships: { create: entrepreneurships }
          }
        }
      }
    }
  };
}
