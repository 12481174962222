
export const ENTREPRENEURS_OCCUPATION_OPTIONS = {
  plugins: {
    legend: {
      position: "bottom"
    },
    datalabels: {
      color: "#000"
    }
  },
  indexAxis: "y",
  scales: {
    x: {
      padding: 5,
      stacked: true
    },
    y: {
      padding: 5,
      stacked: true
    }
  },
  maintainAspectRatio: false
};
