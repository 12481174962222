import useTreatment from "../feature-flags/use-treatment";
import { useUserPermissions } from "../use-user-permissions";

/**
 * @todo Implement connector injection to allow to use any feature flags service
 * to avoid dependence of the current Split version and allow us to update it
 * or even change our Third Party Service Provider
 */
export const useFeatureFlags = () => {
  const newBatchAnalyticsTab = useTreatment("NewBatchAnalyticsTab");
  const disableTaskTab = useTreatment("DisableTaskTab");
  const disableExpertsTab = useTreatment("Disable_Experts_Tab");

  const permissions = useUserPermissions();

  return {
    batch: {
      analyticsTab: {
        isEnabled: newBatchAnalyticsTab === "ON"
      },
      tasksTab: {
        isEnabled: disableTaskTab === "OFF"
          && permissions.batch.tasksTab.isEnabled
      },
      expertsTab: {
        isEnabled: disableExpertsTab === "OFF"
      }
    }
  };
};
