import gql from "graphql-tag";

export const getCompanies = gql`
  query GetCompanies(
    $where: ODEWhereInput
  ){
    oDEs(
      where: $where
    ) { 
      id
      name
    }
  }
`;