import React, { useEffect, useContext } from "react";
import {
  SidebarLayout,
  ContentWrapper
} from "../../../../../components/shared";
import {
  default as ObjectivesList
} from "../../../../../components/admin/batches/show/objectives/list";
import { admin } from "../../../../../graphql/admin";
import { useParams } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import {
  Summary
} from "../../../../../components/admin/batches/show/summary";
import {
  default as Navbar
} from "../../../../../components/admin/batches/show/navbar";
import {
  BatchDetailsContextProvider
} from "../../../../../contexts/admin/batches/batch-details";
import { MenuContext } from "../../../../../contexts/shared";
import {
  BatchObjectiveModalProvider
} from "../../../../../contexts/shared/batch-objective-modal";
import { MainMenu } from "../../../../../components/coordinator/shared";
import {
  ContentModalContextProvider
} from "../../../../../contexts/shared/content-modal";
import { shared } from "../../../../../graphql/shared";

export function ObjectivesIndex() {
  const { id } = useParams();

  const { loading, error, data } = useQuery(
    shared.queries.getBatchObjectives,
    {
      fetchPolicy: "network-only",
      variables: { batchId: id },
      client
    }
  );

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  /* TODO: Create a batch/program detail context provider to set the layout and
   * load and manage the data */

  const {
    loading: batchLoading,
    error: batchError,
    data: batchData
  } = useQuery(
    admin.queries.getBatchById,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      client
    }
  );

  return (
    <BatchDetailsContextProvider>
      <ContentModalContextProvider batchId={id}>
        <BatchObjectiveModalProvider>
          <SidebarLayout menu={<MainMenu />}>
            <Summary
              isLoading={batchLoading}
              error={batchError}
              data={batchData} />
            <Navbar selectedKeys={["objectives"]} />
            <ContentWrapper>
              <ObjectivesList
                data={data}
                error={error}
                isLoading={loading} />
            </ContentWrapper>
          </SidebarLayout>
        </BatchObjectiveModalProvider>
      </ContentModalContextProvider>
    </BatchDetailsContextProvider>
  );
}
