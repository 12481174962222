import React from "react";
import { Input as ANTInput } from "antd";
import { ReadOnlyItem } from "./read-only-item";

export const Input = ({
  value,
  onChange, // required by form Item
  addonBefore,
  placeholder = "",
  editable = true,
  socialNetwork = ""
}) => {
  return editable ? (
    <ANTInput
      value={value || undefined}
      onChange={onChange}
      size="large"
      addonBefore={addonBefore}
      placeholder={placeholder} />
  )
  : (<ReadOnlyItem value={value || ""} socialNetwork={socialNetwork} />);
};

