import React, { useEffect } from "react";
import { Select } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { admin } from "../../../graphql/admin";
import { client } from "../../../../graphql";

/**
 * ODEs select which allows to filter the options by batch or program,
 * and exclude a set of ODEs.
 * @param {object} props AntDesign Select props
 * @param {{id: string }[] | undefined} props.excludedOdes Array of ODE objects
 * @param {string | undefined} props.batchId Allows to get the ODEs in a batch
 * only. Will ignore the programId.
 * @param {string | undefined} props.programId Allows to get the ODEs in a
 * program only. Will be ignored if the batchId is specified.
 */
export function FilterableOdesSelect({
  excludedOdes,
  batchId,
  programId,
  ...props
}) {

  const getQueryWhere = () => {
    const id_not_in = excludedOdes?.map(ode => ode?.id);
    let batches_some = undefined;
    if(batchId){
      batches_some = {
        id: batchId
      };
    }
    // if programId is equal to an empty String instead of undefined
    // the select will return cero ODEs
    if(programId || programId === ""){
      batches_some = {
        program: {
          id: programId
        }
      };
    }
    return {
      id_not_in,
      batches_some
    };
  };

  const { data, loading, refetch } = useQuery(admin.queries.getOdes, {
    client,
    variables: {
      where: getQueryWhere()
    },
    fetchPolicy: "network-only"
  });

  const options = data ?
    data.oDEs?.map(ode => ({
      label: ode?.name,
      value: ode?.id
    }))
    : [];

  useEffect(() => {
    refetch();
  }, [excludedOdes, programId]);

  return (
    <Select
      {...props}
      loading={loading}
      showSearch
      optionFilterProp="label"
      placeholder="Buscar compañías"
      options={options}
      data-cy="odes-select">
    </Select>
  );
}
