import gql from "graphql-tag";

export const findOdeByName = gql`
  query findOdeByName($iname: String!) {
    oDEs(where: { iname: $iname }) {
      id
      name
      iname
    }
  }
`;
