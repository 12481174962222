import { getDeletedIdsFromTwoArrays } from "../../../../../helpers/get-deleted-ids-from-two-arrays";

export const formatValues = (values = [], oldPapers = []) => {
  const upsertValues = values.map(({ id = "", ...paper }) => {
    delete paper.__typename;
    delete paper.uuid;
    delete paper.index;

    return ({
      where: { id: id },
      create: { ...paper },
      update: { ...paper }
    });
  });

  const createValues = upsertValues.map(v => v.create);

  const deletedPapersIds = getDeletedIdsFromTwoArrays(values, oldPapers);

  return ({
    expertProfile: {
      upsert: {
        create: {
          professionalExperience: {
            create: {
              papers: { create: createValues }
            }
          }
        },
        update: {
          professionalExperience: {
            upsert: {
              create: {
                papers: { create: createValues }
              },
              update: {
                papers: {
                  upsert: upsertValues,
                  deleteMany: { id_in: deletedPapersIds }
                }
              }
            }
          }
        }
      }
    }
  });
};
