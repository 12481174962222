import gql from "graphql-tag";

export const getAllQuery = gql`
  query GetAllBatchesQuery {
    batches {
      id
      name
    }
  }
`;
