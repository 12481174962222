import React from "react";
import { Typography, List, Space } from "antd";
import moment from "moment";
import { EditButton, DeleteButton } from "../../../../../";

export function PaperItem({
  paper,
  onEdit,
  onDelete,
  editable
}) {
  const {
    title = "Sin título",
    type = "N/A",
    institutionName = "N/A",
    publishedDate
  } = paper;

  const date = moment(publishedDate).format("DD [de] MMMM [de] YYYY");
  const types = {
    RESEARCH: "Investigación",
    PUBLISHING: "Publicación"
  };

  return (
    <List.Item style={{position: "relative"}}>
      { editable && (
        <div style={{top: "0", right: "0", position: "absolute"}}>
          <EditButton onClick={onEdit} />
          <DeleteButton onClick={onDelete} />
        </div>
      )}
      <Space size={5} direction="vertical">
        <Typography.Text style={{fontSize: "16px"}}>
          { types[type] } - { title }
        </Typography.Text>
        <Typography.Text type="secondary">{ institutionName }</Typography.Text>
        <Typography.Text type="secondary">{ date }</Typography.Text>
      </Space>
    </List.Item>
  );
}
