import gql from "graphql-tag";

export const getPerks = gql`
  query GetPerks($id: ID, $name: String) {
    perks(
      where: {
        createdBy: {id: $id}
        name_contains: $name
      }
    ) {
      id
      coverPicUrl
      name
      expiration
      program{
        id
        name
        coverPicUrl
      }
      category
      description
      contactInfo
      batches{
        id
        name
        program{
          id
          coverPicUrl
        }
      }
      odes{
        id
        name
        logoUrl
      }
    }
  }
`;