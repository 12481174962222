import React from "react";
import { Steps, Button } from "antd";
import { Visibility, TimelineEventLabel } from "../../../components/shared";
import { serviceStatusEnum as STATUS } from "../../../helpers/constants";
import {
  getMappedServiceStatusTimelineEvents
} from "../../../helpers/get-service-status-timeline-events";

function CancelServiceButton({ visible = false, onClick }) {
  return (
    <Visibility
      visible={visible}>
      <Button
        size="small"
        onClick={onClick}
        danger>
        Cancelar solicitud
      </Button>
    </Visibility>
  );
}

export function renderStatusSteps ({
  scheduledExpertService,
  onCancelRequest,
  systemRole
}) {
  const mappedEvents = getMappedServiceStatusTimelineEvents({
    ...scheduledExpertService,
    assignedAt: scheduledExpertService?.assignedExpertService?.createdAt,
    currentStatus: scheduledExpertService.status,
    serviceEvents: scheduledExpertService.events
  });

  const expertServiceData = scheduledExpertService
    .assignedExpertService
    ?.expertServiceData;

  if (["SUPER_ADMIN", "COORDINATOR", "ADVISER"].includes(systemRole)) {
    const allowToCancel = [STATUS.SCHEDULED, STATUS.ON_GOING]
      .includes(scheduledExpertService?.status);

    return (
      <>
        <Steps.Step
          title="Agendado"
          description={
            <>
              <TimelineEventLabel event={mappedEvents[STATUS.SCHEDULED]} />
              <div>
                {scheduledExpertService?.status === STATUS.SCHEDULED ?
                  <CancelServiceButton
                    visible={allowToCancel}
                    onClick={onCancelRequest} />
                : null}
              </div>
            </>
          } />
        <Steps.Step
          title="Solicitud aceptada"
          description={
            <>
              <TimelineEventLabel event={mappedEvents[STATUS.ON_GOING]} />
              <div>
                {scheduledExpertService?.status === STATUS.ON_GOING ?
                  <CancelServiceButton
                    visible={allowToCancel}
                    onClick={onCancelRequest} />
                  : null}
              </div>
            </>
          } />
        <Steps.Step
          title="Entregado"
          description={
            <TimelineEventLabel event={mappedEvents[STATUS.DELIVERED]} />
          } />
        <Steps.Step
          title="Aceptado por la Compañía"
          description={
            <TimelineEventLabel event={mappedEvents[STATUS.ACCEPTED]} />
          } />
        <Steps.Step
          title="Pago autorizado"
          description={
            <TimelineEventLabel
              event={mappedEvents[STATUS.AUTHORIZED_FOR_PAYMENT]} />
          } />
        <Steps.Step
          title="Pago en proceso"
          description={
            <TimelineEventLabel
              event={mappedEvents[STATUS.PAYMENT_IN_PROCESS]} />
          } />
        <Steps.Step
          title="Pagado"
          description={
            <TimelineEventLabel event={mappedEvents[STATUS.PAID]} />
          } />
      </>
    );
  }

  // Entrepreneur are able to cancel scheduled status services just if
  // the service is not a WORKSHOP
  if (systemRole === "ENTREPRENEUR") {
    const allowToCancel = [STATUS.SCHEDULED]
      .includes(scheduledExpertService?.status)
      && expertServiceData?.type !== "WORKSHOP";

    return (
      <>
        <Steps.Step
          title="Agendado"
          description={
            <>
              <TimelineEventLabel event={mappedEvents[STATUS.SCHEDULED]} />
              <CancelServiceButton
                visible={allowToCancel}
                onClick={onCancelRequest} />
            </>
          } />
        <Steps.Step
          title="Solicitud aceptada"
          description={
            <TimelineEventLabel event={mappedEvents[STATUS.ON_GOING]} />
          } />
        <Steps.Step
          title="Entregado"
          description={
            <TimelineEventLabel event={mappedEvents[STATUS.DELIVERED]} />
          } />
        <Steps.Step
          title="Aceptado por Compañía"
          description={
            <TimelineEventLabel event={mappedEvents[STATUS.ACCEPTED]} />
          } />
      </>
    );
  }
}
