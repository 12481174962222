import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Select, message } from "antd";
import { useParams } from "react-router-dom";
import { taskTypes } from "../../../../helpers/select-options/tasks/task-types";
import { FileLinkField } from "../../../../components/shared/file-link-field";
import { AchievementsSelect } from "../../../../components/shared/achievements-select";
import { EntrepreneursSelect } from "../../../../components/shared/entrepreneur-select";
import { CurrentUserContext } from "../../current-user";
import { formatValues } from "./format-values";
import { CreateTaskModalContext } from "../context";
import { FilterableOdesSelect } from "../../../../components/shared";
import moment from "moment";
import { cloneDeep } from "lodash";

/**
 * This modal is used for create and edit
 */
export function CreateTaskModal({ task, visible = false, closeModal, ...props }) {

  const {id} = useParams();
  const {currentUser} = useContext(CurrentUserContext);
  const {save} = useContext(CreateTaskModalContext);

  const [selectedOde, setSelectedOde] = useState(" ");

  const [form] = Form.useForm();
  const { TextArea } = Input;

  useEffect(() => {
    if(task) {
      setSelectedOde(task.ode.id);
      form.setFieldsValue({
        ...task,
        deliveryDate: moment(task.deliveryDate),
        achievement: task.achievement.id,
        ode: task.ode.id,
        responsable: task.responsable.id
      });
    }
  }, [task]);

  const handleOnChange = id => {
    setSelectedOde(id);
  };

  const onSave = async () => {
    await form.validateFields();
    const dismissLoading = message.loading("Guardando...");
    const values = form.getFieldsValue();

    const oldResources = task?.resources ? cloneDeep(task?.resources) : [];
    const updateData = formatValues(values, oldResources, currentUser, id);

    const newResources = values.resources?.map(resource => {
      const clonnedResource = cloneDeep(resource); // avoid side-effects

      delete clonnedResource.id;
      delete clonnedResource.uuid; // injected by composite-field component
      delete clonnedResource.__typename;
      return clonnedResource;
    });

    const createData = {
      ...updateData,
      resources:{
        create: newResources
      }
    };

    try {
      await save(createData, updateData);
      onCancel();
    } catch(error) {
      message.error("Ha ocurrido un error, inténtalo de nuevo\
        en unos segundos.");
    } finally {
      dismissLoading();
    }
  };

  const onCancel = () => {
    form.resetFields();
    closeModal();
  };

  return (
    <Modal
      visible={visible}
      okText="Guardar"
      cancelText="Cancelar"
      onOk={onSave}
      onCancel={onCancel}
      title="Crear nueva tarea"
      {...props}>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          label="Nombre de la actividad"
          name="name"
          rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Fecha de entrega"
          name="deliveryDate"
          rules={[{required: true}]}>
          <DatePicker className="wide-input" />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description"
          rules={[{required: true}]}>
          <TextArea rows={5} />
        </Form.Item>
        <Form.Item
          label="Tipo"
          name="type"
          rules={[{required: true}]}>
          <Select options={taskTypes} />
        </Form.Item>
        <Form.Item
          label="Cumple con el logro"
          name="achievement"
          rules={[{required: true}]}>
          <AchievementsSelect batchId={id} />
        </Form.Item>
        <Form.Item
          label="Compañía"
          name="ode"
          rules={[{required: true}]}>
          <FilterableOdesSelect onChange={handleOnChange} batchId={id} />
        </Form.Item>
        <Form.Item
          label="Responsable"
          name="responsable"
          rules={[{required: true}]}>
          <EntrepreneursSelect fromOdeId={selectedOde} />
        </Form.Item>
        <Form.Item
          label="Recursos"
          name="resources">
          <FileLinkField />
        </Form.Item>
      </Form>
    </Modal>
  );
}
