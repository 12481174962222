import gql from "graphql-tag";

export const updateProfessionalExperience = gql`
  mutation UpdateProfessionalExperience(
  	$userId: ID!

    $curriculumVitae: FileCreateInput
    $skills: [ProfessionalSkillCreateInput!]
    $industries: [ExpertIndustryCreateInput!]
    $areas: [ProfessionalAreaCreateInput!]
    $education: [EducationCreateInput!]
    $courses: [CourseCreateInput!]
    $papers: [ResearchPaperCreateInput!]
    $languages: [LanguageExperienceCreateInput!]
    $volunteerings: [VolunteeringCreateInput!]
    $tecRelationship: TecRelationshipCreateInput
    $entrepreneurships: [EntrepreneurshipCreateInput!]
  ) {
    updateUser(
      where: {
        id: $userId
      }
      data: {
        expertProfile: {
          update: {
            professionalExperience: {
              update: {
                curriculumVitae: {
                  create: $curriculumVitae
                }
                skills: {
                  create: $skills
                }
                industries: {
                  create: $industries
                }
                areas: {
                  create: $areas
                }
                education: {
                  create: $education
                }
                courses: {
                  create: $courses
                }
                papers: {
                  create: $papers
                }
                languages: {
                  create: $languages
                }
                volunteerings: {
                  create: $volunteerings
                }
                tecRelationship: {
                  create: $tecRelationship
                }
                entrepreneurships: {
                  create: $entrepreneurships
                }
              } # END CREATE PROFESSIONAL EXPERIENCE
            } # END PROFESSIONAL EXPERIENCE
          } # END CREATE EXPERT PROFILE
        } # END EXPERT PROFILE
      }
    ) {
      id
    }
  }
`;
