import React, { useContext } from "react";
import {
  MenuContext,
  EditProfileProvider,
  PageLeavePromptProvider,
  CurrentUserContext
} from "../../../contexts/shared";
import { SidebarLayout } from "../../../components/shared";
import { MainMenu } from "../../../components/entrepreneur/shared";
import {
  Title,
  GeneralProfileEntrepreneur
} from "../../../components/entrepreneur/profile";

export default function () {
  const { setMenuState } = useContext(MenuContext);
  const { currentUser: { id: userId} } = useContext(CurrentUserContext);

  setMenuState({
    openKeys: [""],
    selectedKeys: [""]
  });

  return (
    <PageLeavePromptProvider>
      <EditProfileProvider userId={userId}>
        <SidebarLayout menu={<MainMenu />}>
          <Title />
          <GeneralProfileEntrepreneur />
        </SidebarLayout>
      </EditProfileProvider>
    </PageLeavePromptProvider>
  );
}
