import React, { useState } from "react";

import { ArrayItem } from "../../../../../../shared";
import { LanguageModal } from "../modal";
import { LanguageItem } from "./language-item";
import {
  handleOnAdd,
  handleOnEditItem,
  handleOnRemoveItem,
  handleOnSave,
  handleOnCancel
} from "../../../../modal-helpers";

export const Languages = ({
  onSave,
  languages = [],
  editable = true
}) => {
  const [modal, setModal] = useState({ visible: false });

  const renderItem = item =>
    <LanguageItem
      editable={editable}
      item={item}
      onEditItem={language =>
        handleOnEditItem(setModal, language)}
      onRemoveItem={removed =>
        handleOnRemoveItem(onSave, languages, removed)} />;

  return (
    <>
      <LanguageModal
        visible={modal.visible}
        language={modal.item}
        onConfirm={newLanguage =>
          handleOnSave(modal, setModal, onSave, languages, newLanguage)}
        onCancel={() =>
          handleOnCancel(setModal)} />
      <ArrayItem
        editable={editable}
        value={languages}
        onClickAdd={() =>
          handleOnAdd(setModal)}
        buttonLabel="Agregar"
        renderItem={renderItem} />
    </>
  );
};
