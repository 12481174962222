import gql from 'graphql-tag';

export const addContentDeliverables = gql`
  mutation AddContentDeliverables(
    $odeContentId: ID, 
    $odeId: ID,
    $batchId: ID,
    $contentId: ID,
    $fileArray: [FileCreateInput!]
  ){
    upsertOdeContent(
      where: { id: $odeContentId }
      update: {
        deliverables: {
          create: $fileArray
        }
      },
      create: {
        ode: { connect: { id: $odeId } }
        batch: { connect: { id: $batchId } }
        content: { connect: { id: $contentId } }
        deliverables: {
          create: $fileArray
        }
      }
    ){
      id
    }
  }
`;