import { client3 } from "../../../graphql";
import {
  getDashboardInvolvementLevelsQuery
} from "./graphql";

export const getDashboardInvolvementLevels = async ({ startDate, endDate }) => {

  const filters = startDate && endDate ? { startDate, endDate } : undefined;

  const result = await client3.query({
    query: getDashboardInvolvementLevelsQuery,
    fetchPolicy: "network-only",
    variables: { filters }
  });

  return result?.data?.dashboardInvolvementLevels || null;
};