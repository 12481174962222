import React, { useContext } from "react";
import { List } from "antd";
import { withLoader, withRouter, cardGridSizes, paginationConfig } from "../../../../../../helpers";
import { PerkItem } from "../../../../../shared/perk-item";
import { CreatePerkModalContext } from "../../../../../../contexts/shared/create-perk-modal/context";

/**
 * This component is being shared by coords, admins and entrepreneurs.
 * @param data
 * @param loading
 * @returns {JSX.Element}
 * @constructor
 */
function PerksList({ data, loading }) {
  const { onViewPerk } = useContext(CreatePerkModalContext);

  return (
    <List
      style={{ margin: "24px" }}
      pagination={{
        ...paginationConfig,
        defaultPageSize: 12
      }}
      loading={loading}
      grid={cardGridSizes}
      dataSource={data?.perks}
      renderItem={perk =>
        <List.Item>
          <PerkItem
            perk={perk}
            onViewPerk={onViewPerk} />
        </List.Item>} />
  );
}

export default withLoader(withRouter(PerksList));
