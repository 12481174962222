import React, { useContext } from "react";
import { Descriptions, Button, Divider } from "antd";
import {
  Visibility,
  LinksDisplay,
  StatusSteps
} from "../../../../../components/shared";
import { ExpertServiceSummary } from "./expert-service-summary";
import { CurrentUserContext } from "../../../current-user";
import { useDecorator } from "../../../../../helpers/use-decorator";

export function ModalForm({
  scheduledExpertService,
  updatePaymentStatus,
  handleOnCancelRequest
}) {
  let assignedExpertService = scheduledExpertService
    ?.assignedExpertService;

  assignedExpertService = useDecorator(
    "assignedExpertService",
    assignedExpertService
  );

  const { currentUser } = useContext(CurrentUserContext);

  const isAssignedExpertService = ["ASSIGNED", "EXPIRED"]
    .includes(scheduledExpertService?.status);

  return (
    <>
      <ExpertServiceSummary
        scheduledExpertService={scheduledExpertService}
        assignedExpertService={assignedExpertService} />
      <Visibility
        visible={!["ASSIGNED", "SCHEDULED"]
          .includes(scheduledExpertService?.status)
          && !!scheduledExpertService.calendarEventUri}>
        <Divider orientation="left">
          Evento
        </Divider>
        <LinksDisplay
          links={[{
            fileName: "evento.ics",
            url: scheduledExpertService.calendarEventUri || ""
          }]} />
      </Visibility>
      <Visibility
        visible={!["ASSIGNED", "SCHEDULED"]
          .includes(scheduledExpertService?.status)}>
        <Divider orientation="left">
          Resultados
        </Divider>
        <LinksDisplay links={scheduledExpertService?.attachments} />
        <Divider orientation="left">
          Enlaces
        </Divider>
        <LinksDisplay links={scheduledExpertService?.links} />
      </Visibility>
      <Visibility visible={scheduledExpertService?.expertComments}>
        <Descriptions
          layout="vertical"
          style={{ margin: "20px 0px" }}>
          <Descriptions.Item
            label="Comentarios del experto"
            style={{whiteSpace: "pre-wrap"}}>
            {scheduledExpertService?.expertComments}
          </Descriptions.Item>
        </Descriptions>
      </Visibility>
      <Visibility visible={!isAssignedExpertService}>
        <Divider />
        <StatusSteps
          onCancelRequest={handleOnCancelRequest}
          scheduledExpertService={scheduledExpertService} />
      </Visibility>
      <Visibility
        visible={
          scheduledExpertService?.status === "ACCEPTED"
            && ["COORDINATOR", "SUPER_ADMIN"]
            .includes(currentUser?.systemRole)}>
        <Button
          type="primary"
          onClick={() => updatePaymentStatus("AUTHORIZED_FOR_PAYMENT")}>
          Autorizar para pago
        </Button>
      </Visibility>
      <Visibility
        visible={scheduledExpertService?.status === "AUTHORIZED_FOR_PAYMENT"
          && ["COORDINATOR", "SUPER_ADMIN"].includes(currentUser?.systemRole)}>
        <Button
          type="primary"
          onClick={() => updatePaymentStatus("PAYMENT_IN_PROCESS")}>
          Pago en proceso
        </Button>
      </Visibility>
      <Visibility
        visible={scheduledExpertService?.status === "PAYMENT_IN_PROCESS"
          && ["COORDINATOR", "SUPER_ADMIN"].includes(currentUser?.systemRole)}>
        <Button
          type="primary"
          onClick={() => updatePaymentStatus("PAID")}>
          Pagado
        </Button>
      </Visibility>
    </>
  );
}
