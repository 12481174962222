import React, { useContext } from "react";
import { Typography, List, message, Modal } from "antd";
import { useParams } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Section, AddButton } from "../../../../../../shared";
import {
  ObjectiveContentModalContext
} from "../../../../../../../contexts/shared/objective-content-modal";
import { ContentItem } from "./item";
import { paginationConfig, withLoader } from "../../../../../../../helpers";
import { admin } from "../../../../../../../graphql/admin";
import { shared } from "../../../../../../../graphql/shared";
import { client } from "../../../../../../../../graphql";
import { ActionButton } from "../../../../../../shared";

function ContentsList({ data, isLoading, ...props }) {
  const { openModal } = useContext(ObjectiveContentModalContext);
  const { objectiveId = ""} = useParams();

  const [deleteObjectiveContent] = useMutation(
    admin.mutations.deleteObjectiveContent,
    {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: shared.queries.getContentsByObjectiveId,
        variables: { id: objectiveId }
      }]
    });

  const deleteContent = async contentId => {
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      await deleteObjectiveContent({
        variables: {
          objectiveId,
          contentId
        }
      });
      message.success("Cambios guardados");
    } catch (error) {
      message.error("Ha ocurrido un error, inténtalo de nuevo\
      en unos segundos.");
    } finally {
      dismissLoader();
    }
  };

  const handleOnDelete = async contentId => {
    Modal.confirm({
      okText: "Eliminar",
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: "Estás a punto de eliminar contenido de este objetivo\
        ¿Deseas continuar?",
      onOk: () => deleteContent(contentId)
    });
  };

  return (
    <Section
      title="Contenido"
      spaced>
      <Typography.Text>
        Agrega contenido que el emprendedor pueda tomar como apoyo
        durante el transcurso del programa.
      </Typography.Text>
      <AddButton
        onClick={() => openModal(objectiveId)}
        style={{ width: "100%", marginTop: "20px" }}>
        Agregar contenido
      </AddButton>
      <List
        loading={isLoading}
        style={{ marginTop: "20px" }}
        itemLayout="horizontal"
        dataSource={data?.objective?.contents}
        pagination={{
          ...paginationConfig,
          pageSize: 10
        }}
        renderItem={i =>
          <ContentItem
            content={i}
            extra={
              <ActionButton onDelete={() => handleOnDelete(i?.id)} />
            } />
          }
        {...props} />
    </Section>
  );
}

export default withLoader(ContentsList);
