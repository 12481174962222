import React, { useContext } from "react";
import { Input, Select, Space } from "antd";
import { useParams } from "react-router-dom";
import { FilterableOdesSelect, Visibility } from "../../../../../shared";
import { CurrentUserContext } from "../../../../../../contexts/shared";
import { BatchesSelect } from "../../../../../shared/batches-select";

export const FilterControls = ({
  hideFilters = {
    odeSelect: false,
    batchesSelect: false,
    stateSelect: false,
    searchInput: false
  },
  handleOdeSelect,
  handleBatchSelect,
  handleSelectorChange,
  handleInputSearch,
  resetTableContent
}) => {
  const { id } = useParams();

  return (
    <Space>
      <Visibility
        visible={!hideFilters.batchesSelect}>
        <BatchesSelect
          odeId={id} 
          onChange={handleBatchSelect}
          style={{ width: "200px" }} />
      </Visibility>
      <Visibility
        visible={!hideFilters.odeSelect}>
        <FilterableOdesSelect
          batchId={id}
          onChange={handleOdeSelect}
          style={{ width: "200px" }} />
      </Visibility>
      <Visibility
        visible={!hideFilters.stateSelect}>
        <Select
          placeholder="Selecciona un estado" style={{ width: "180px" }}
          onChange={handleSelectorChange}>
          <Select.Option value="all">Todos los estados</Select.Option>
          <Select.Option value="completed">Completado</Select.Option>
          <Select.Option value="pending">Pendiente</Select.Option>
        </Select>
      </Visibility>
      <Visibility
        visible={!hideFilters.searchInput}>
        <Input.Search
          onSearch={handleInputSearch}
          onChange={e => resetTableContent(e)}
          placeholder="Buscar" />
      </Visibility>
    </Space>
  );
};
