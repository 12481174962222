import _ from "lodash";

export const formatDuplicatedValues = (contentGroup, user) => {
  const clonContentGroup = _.cloneDeep(contentGroup);
  delete clonContentGroup.id;
  delete clonContentGroup.__typename;
  return {
    ...clonContentGroup,
    title: `Duplicado de ${clonContentGroup.title}`,
    contents: {
      create: clonContentGroup.contents?.map(content => {
        delete content.id;
        delete content.__typename;
        content.name = `Duplicado de ${content.name}`;
        content.createdBy = { connect: { id: user.id } };
        content.materials = {
          create: content.materials?.map(material => _.omit(material, ["uuid", "uid", "id", "__typename"])) || []
        };
        return content;
      }) || []
    }
  };
};
