import React from "react";
import { BatchPublishExpert } from "./loader-wrapper";
import { useBatchMembers } from "../../../../../hooks/shared";

export const BatchPublishExpertIndex = ({ data, refetch }) => {
  const { batch } = data;

  const mentors = batch?.users?.filter(el => el.systemRole === "ADVISER");
  const { connectMember, disconnectMember } = useBatchMembers();

  const onConnectMember = async idUser => connectMember({
    idBatch: batch?.id,
    idUser,
    refetch,
    type: "multiple"
  });

  const onDisconnectMember = async idUser => disconnectMember({
    idBatch: batch?.id,
    idUser,
    refetch
  });

  return (
    <BatchPublishExpert
      batchMentors={mentors}
      onConnectMember={onConnectMember}
      onDisconnectMember={onDisconnectMember} />
  );
};