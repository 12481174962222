import React from "react";
import { Row, List, Typography } from "antd";
import { DollarCircleOutlined, FlagOutlined, CheckCircleOutlined  } from "@ant-design/icons";
import { ActionButton, Format, Visibility } from "../../../../..";

export const MilestoneItem = ({
  id,
  type,
  date,
  title,
  subTitle,
  social,
  onDelete,
  onEdit,
  allowEdit,
  children
}) => {

  const colorProps = { style: { color: "#5F39DE", fontSize: "16px" } };

  const milestonesTypesIcons = {
    "FUND": <DollarCircleOutlined {...colorProps} />,
    "COMPETENCE": <FlagOutlined {...colorProps} />,
    "ACHIEVEMENT": <CheckCircleOutlined {...colorProps} />
  };

  const Title = ({ children }) => (
    <Typography.Text
      style={{
        fontSize: "16px",
        fontWeight: "700",
        marginLeft: "6px"
      }}>
      {children}
    </Typography.Text>
  );

  return(
    <List.Item style={{ display: "block" }}>
      <Row justify="space-between">
        <Row align="middle">
          {milestonesTypesIcons[type]}
          <Title>{title}</Title>
          {social &&
            <Typography.Text
              style={{
                fontSize: "10px",
                fontWeight: "700",
                color: "#FFB61D",
                marginLeft: "6px" }}>
              Social
            </Typography.Text>}
        </Row>
        <Visibility visible={allowEdit}>
          <ActionButton
            size="small"
            onEdit={() => onEdit()}
            onDelete={() => onDelete(id)} />
        </Visibility>
      </Row>
      <Typography.Text className="milestones__item--description">
        {subTitle}
      </Typography.Text>
      {children}
      <Typography.Text
        className="milestones__item--description"
        style={{ textTransform: "capitalize" }}>
        <Format type="date" format="MMMM YYYY">{date}</Format>
      </Typography.Text>
    </List.Item>
  );
};
