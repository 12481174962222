import React, { useEffect, useState } from "react";
import { DatePicker as ADatePicker } from "antd";
import moment from "moment";

export const DatePicker = ({ onChange, value, defaultValue, ...props }) => {
  const [state, setState] = useState({
    date: null
  });

  useEffect(() => {
    setState({
      date: value ? moment(value) : null
    });
  }, [value]);

  const handleOnChange = value => {
    onChange && onChange(moment(value).toISOString());
  };

  return (
    <ADatePicker
      defaultValue={defaultValue}
      value={state.date}
      onChange={handleOnChange}
      format="DD [de] MMMM [de] YYYY"
      style={{ width: "100%" }}
      {...props} />
  );
};
