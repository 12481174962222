import gql from "graphql-tag";

export const getBatchById = gql`
  query GetBatchById($id: ID!) {
    batch(where: { id: $id }) {
      id
      name
      startDate
      finishDate
      description
      type
      leader
      odesSorted
      institution
      program {
        id
        coverPicUrl
        description
        type
        name
        email
        phone
        website
        facebook
      }
      users {
        id
        fullName
        email
        phone
        profilePicUrl
        systemRole
        careerName
      }
      coordinators: users(where: { systemRole_in: [COORDINATOR, SUPER_ADMIN] }) {
        id
        fullName
      }
      objectives{
        achievements{
          id
          contents{
            id
          }
        }
      }
    }
  }
`;
