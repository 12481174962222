import gql from "graphql-tag";

export const entrepreneurGlobalCalendarEventsQuery = gql`
  query EntrepreneurGlobalCalendarEvents(
    $entrepreneurId: ID!
    $month: Int!
    $year: Int!
    $batchId: ID
    $companyId: ID
  ) {
    entrepreneurGlobalCalendarEvents(
      entrepreneurId: $entrepreneurId
      month: $month
      year: $year
      batchId: $batchId
      companyId: $companyId
    ) {
      id
      name
      date
      type
      link
      location
      description
      meetingType
      expertServiceData
      assignedExpertService {
        expertServiceData
      }
      attachments {
        id
        fileName
        url
        type
      }
      materials {
        id
        fileName
        url
        type
        uploadDate
      }
    }
  }
`;
