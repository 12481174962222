import React from "react";
import { Avatar, Button, Divider, Form, List, Space, Typography } from "antd";
import { AddButton, Visibility } from "../../../../../components/shared";
import { BatchesSelect } from "../../../../../components/shared/batches-select";


export const BatchSection = ({ batches, selectedProgram = "", saveAssignments, removedAssigned, viewMode }) => {

  const [form] = Form.useForm();

  const saveBatches = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    saveAssignments(values.batches, "batches");
    form.resetFields();
  };

  return (
    <Form
      form={form}>
      <Divider orientation="left">Batch</Divider>
      <List
        dataSource={batches}
        renderItem={batch =>
          <List.Item key={batch.id}>
            <Space
              style={{ justifyContent: "space-between", width: "100%" }}>
              <div
                style={{ display: "flex" }}>
                <Avatar src={batch.program.coverPicUrl}>{batch.name}</Avatar>
                <Typography.Title
                  level={5}
                  style={{ marginLeft: "15px" }}>
                  {batch.name}
                </Typography.Title>
              </div>
              <Visibility visible={!viewMode}>
                <Button
                  onClick={() => removedAssigned(batch.id, "batches")}
                  danger>
                  Eliminar
                </Button>
              </Visibility>
            </Space>
          </List.Item>} />
      <Visibility visible={!viewMode}>
        <Form.Item
          style={{
            marginTop: "25px",
            marginBottom: "10px"
          }}
          rules={[{
            required: true,
            message: "Seleccione al menos un batch"
          }]}
          name="batches">
          <BatchesSelect
            mode="multiple"
            className="wide-input"
            programId={selectedProgram}
            excludedOdes={batches} />
        </Form.Item>
        <AddButton
          className="wide-input add-batches"
          type="dashed"
          onClick={saveBatches}>
          Agregar Batches
        </AddButton>
      </Visibility>
    </Form>
  );
};

