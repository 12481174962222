import gql from "graphql-tag";

export const getAnnouncementById = gql`
  query GetAnnouncemenById($id: ID!) {
    announcement(
      where: {
        id: $id
      }
    ) {
      id
      title
      body
      date
      image
      documents {
        fileName
        url
        type
      }
      createdBy {
        fullName
        profilePicUrl
      }
    }
  }
`;