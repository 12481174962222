import React, { useContext } from "react";
import { Menu } from "antd";
import { MenuTitle } from "../../../shared";
import {
  BookOutlined,
  GlobalOutlined,
  ForkOutlined,
  StarOutlined,
  TeamOutlined,
  IdcardOutlined,
  LineChartOutlined,
  SmileOutlined,
  SettingOutlined,
  DashboardOutlined
} from "@ant-design/icons";
import { withRouter } from "../../../../helpers";
import { MenuContext } from "../../../../contexts/shared";
import useTreatment from "../../../../hooks/feature-flags/use-treatment";

function MainMenu({ navigate }) {
  const { openKeys, selectedKeys } = useContext(MenuContext);

  const DynamicDashboardsModule = useTreatment(
    "DynamicDashboardsModule"
  );
  const showDynamicDashboardsMenuItem = DynamicDashboardsModule === "ON";

  return (
    <>
      <Menu
        mode="inline"
        openKeys={openKeys}
        selectedKeys={selectedKeys}>
        <Menu.SubMenu
          onTitleClick={() => navigate("/v2/admin/programs")}
          title={<MenuTitle text="Programas" icon={<GlobalOutlined />} />}
          key="programs">
          <Menu.Item
            key="programs/all"
            onClick={() => navigate("/v2/admin/programs")}>
            Todos
          </Menu.Item>
          <Menu.Item
            key="batches"
            onClick={() => navigate("/v2/admin/batches")}>
            Batches
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          onTitleClick={() =>
            window.location.href = `${v1}/admin/instituciones`
          }
          title={<MenuTitle text="Instituciones" icon={<GlobalOutlined />} />}
          key="institutions">
        </Menu.SubMenu>
        <Menu.SubMenu
          onTitleClick={() => window.location.href = `${v1}/admin/organism`}
          title={<MenuTitle text="Organizaciones" icon={<ForkOutlined />} />}
          key="organizations">
        </Menu.SubMenu>
        <Menu.Item
          key="odes"
          onClick={() => navigate("/v2/admin/odes")}>
          <MenuTitle text="Compañías" icon={<StarOutlined />} />
        </Menu.Item>
        <Menu.SubMenu
          onTitleClick={() => navigate("/v2/admin/entrepreneurs")}
          title={<MenuTitle text="Emprendedores" icon={<TeamOutlined />} />}
          key="entrepreneurs">
          <Menu.Item
            key="entrepreneurs/profiles"
            onClick={() => navigate("/v2/admin/entrepreneurs")}>
            Perfiles
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          onTitleClick={() => navigate("/v2/admin/experts")}
          title={<MenuTitle text="Expertos" icon={<BookOutlined />} />}
          key="experts">
          <Menu.Item
            key="experts/profiles"
            onClick={() => navigate("/v2/admin/experts")}>
            Perfiles
          </Menu.Item>
          <Menu.Item
            key="experts/services"
            onClick={() => navigate("/v2/admin/experts/services")}>
            Productos
          </Menu.Item>
          <Menu.Item
            key="experts/services/monitoring"
            onClick={() => navigate("/v2/admin/experts/services/monitoring")}>
            Monitoreo
          </Menu.Item>
          <Menu.Item
            key="experts/services/monitoring/assignations"
            onClick={() => navigate("/v2/admin/experts/services/monitoring/assignations")}>
            Monitoreo (Beta)
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu
          onTitleClick={() =>
            window.location.href = `${v1}/admin/administradores`
          }
          title={<MenuTitle text="Administradores" icon={<IdcardOutlined />} />}
          key="admins">
        </Menu.SubMenu>
        <Menu.SubMenu
          onTitleClick={() => window.location.href = `${v1}/admin/estadisticas`}
          title={<MenuTitle text="Estadísticas" icon={<LineChartOutlined />} />}
          key="statistics">
        </Menu.SubMenu>
        <Menu.SubMenu
          id="perks-sidebar-menu"
          onTitleClick={() => navigate("/v2/admin/perks")}
          title={<MenuTitle text="Perks" icon={<SmileOutlined />} />}
          key="perks">
        </Menu.SubMenu>
        <Menu.SubMenu
          onTitleClick={() => navigate("/v2/admin/configuration/workspaces")}
          title={<MenuTitle text="Configuración" icon={<SettingOutlined />} />}
          key="configuration">
          <Menu.Item
            key="configuration/workspaces"
            onClick={() => navigate("/v2/admin/configuration/workspaces")}>
            Workspaces
          </Menu.Item>
        </Menu.SubMenu>
        {showDynamicDashboardsMenuItem
          ? <Menu.Item
              onClick={() => navigate("/v2/admin/analytics")}
              key="analytics">
            <MenuTitle
              text="Reportes"
              icon={<DashboardOutlined />} />
          </Menu.Item>
        : null}
      </Menu>
    </>
  );
}

const v1 = "https://v1.eossolution.io";

export default withRouter(MainMenu);