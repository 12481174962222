import { message } from "antd";

const formatServiceDate = (serviceDate, serviceTime) => {
  const time = new Date(serviceTime.toString());
  const hours = time.getHours();
  const minutes = time.getMinutes();
  serviceDate.set({h: hours, m: minutes});
  return serviceDate;
};

export const useScheduleService = ({
  loggedUserId,
  ExpertServiceService
}) => {
  const scheduleService = async ({
    duration,
    serviceDate,
    serviceHour,
    assignedExpertServiceId,
    afterSuccess
  }) => {
    const dismissLoader = message.loading("Guardando...");
    try {
      await ExpertServiceService.scheduleExpertService({
        duration,
        assignedExpertServiceId,
        requestedById: loggedUserId,
        serviceDate: formatServiceDate(serviceDate, serviceHour)
      });

      message.success("Mentoría agendada");
      afterSuccess && afterSuccess();
    } catch (err) {
      message.error("Ocurrió un error");
      console.error(err);
    } finally {
      dismissLoader();
    }
  };

  return {
    scheduleService
  };
};
