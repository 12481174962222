import gql from "graphql-tag";

export const publishMasterContent = gql`
  mutation PublishMasterContent(
    $batchId: String,
    $masterContentId: String
  ) {
    publishContent(
      data: {
        batchId: $batchId,
        masterContentId: $masterContentId
      }
    ){
      count
    }
  }
`;