import React, { useState, useEffect } from "react";
import {
  Modal,
  Space,
  Divider
} from "antd";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";
import {
  Hero,
  Loader,
  SocialLinksSection,
  Presence,
  WebsiteAndContact
} from "../../../components/shared";

export function OrganizationModal({
  onCancel,
  organizationId,
  ...props
}) {
  const initialState = {
    organization: undefined,
    loading: true,
    error: null
  };

  const [state, setState] = useState(initialState);

  const fetchOrganization = async id => {
    setState(initialState);
    return await client.query({
      query: shared.queries.getOrganizationById,
      variables: { id: id },
      fetchPolicy: "network-only"
    });
  };

  useEffect(() => {
    const id = organizationId || state.organizationId;
    id && fetchOrganization(id)
      .then(({ data , loading, error }) => {
        setState({ organization: data?.organization, loading, error });
      });
  }, [organizationId]);

  return (
    <Modal
      className="OrganizationModal"
      width={916}
      destroyOnClose
      onCancel={onCancel}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      {...props}>
      <Loader
        data={state?.organization}
        loading={state?.loading}
        error={state?.error}>
        <div className="OrganizationModal__wrapper">
          <Hero
            organization={state?.organization} />
          <Divider />
          <Space direction="vertical">
            <Presence
              organization={state?.organization} />
          </Space>
          <Divider />
          <Space direction="vertical">
            <WebsiteAndContact
              organization={state?.organization} />
            <Space
              className="OrganizationModal__icons"
              size="large">
              <SocialLinksSection
                organization={state?.organization} />
            </Space>
          </Space>
        </div>
      </Loader>
    </Modal>
  );
}
