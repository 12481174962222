import React, { useContext } from 'react';
import { Radio } from 'antd';
import { TableOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { ContentWrapper } from '../../../../../../shared';
import { ContentListAlternativeViewContext } from "../../../../../../../contexts/entrepreneur/content-list-alternative-view/context";

export const ContentViewRadioGroup = () => {

  const { handleRadioClick } = useContext(ContentListAlternativeViewContext);

  return (
    <ContentWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "60px" }}>
        <Radio.Group
          onChange={e => handleRadioClick(e.target.value)}
          defaultValue="list">
          <Radio.Button value="list"><UnorderedListOutlined /></Radio.Button>
          <Radio.Button value="table"><TableOutlined /></Radio.Button>
        </Radio.Group>
      </div>
    </ContentWrapper>
  );
};