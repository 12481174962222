import React from "react";
import { CalendarOutlined } from "@ant-design/icons";
import { Alert } from "antd";

export const AlertError = ({
  title = "",
  description = ""
}) => {
  return (
    <Alert
      className="AlertError"
      message={title}
      description={description}
      type="error"
      icon={<CalendarOutlined />}
      showIcon />
  );
};
