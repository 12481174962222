import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  SidebarLayout,
  Section,
  IconLabel
} from "../../../../components/shared";
import { DataConnectionService } from "../../../../services";
import { expert } from "../../../../graphql/expert";
import { MenuContext } from "../../../../contexts/shared";
import {
  FilterControls
} from "../../../../components/expert/services/requests/filter-controls";
import {
  ScheduledServicesList
} from "../../../../components/expert/services/requests/list";
import { useQueryFilters } from "../../../../helpers";
import {
  ServiceApprovalModalProvider
} from "../../../../contexts/expert/services/requests";
import {
  ServiceDeliveryModalProvider
} from "../../../../contexts/expert/services/requests";
import { ServiceDetailModalProvider } from "../../../../contexts/shared";

export function ServiceRequestsIndex() {
  const [state, setState] = useState({ data: undefined });

  const { loading, error, data, refetch } = useQuery(
    expert.queries.getRequestedServicesByExpertId,
    {
      variables: { expertId: window.localStorage.getItem("id") },
      client: DataConnectionService.graphqlApolloClient,
      fetchPolicy: "network-only"
    });

  useEffect(() => {
    setState({ data });
  }, [data]);

  const { setMenuState } = useContext(MenuContext);
  const { setFilters } = useQueryFilters({ refetch });

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/services/monitoring"]
  });

  const onFilterChange = filter => {
    setFilters(filter);
  };

  if (error) {
    return (
      <IconLabel icon={<ExclamationCircleOutlined />}>
        Ocurrió un error
      </IconLabel>
    );
  }

  return (
    <ServiceDetailModalProvider refetch={refetch}>
      <ServiceApprovalModalProvider>
        <ServiceDeliveryModalProvider refetch={refetch}>
          <SidebarLayout>
            <Section
              extra={<FilterControls onChange={onFilterChange} />}
              spaced>
              <ScheduledServicesList
                loading={loading}
                data={state.data}
                refetch={refetch} />
            </Section>
          </SidebarLayout>
        </ServiceDeliveryModalProvider>
      </ServiceApprovalModalProvider>
    </ServiceDetailModalProvider>
  );
}
