import React, { useContext } from "react";
import { List } from "antd";
import {
  ServicesLayerContext
} from "../../../../../../../../../../contexts/shared";
import { cardGridSizes } from "../../../../../../../../../../helpers";
import { AvailableMentorListItem } from "./available-mentor-list-item";
import { useService } from "../../../../../../../../../../hooks/shared";

export function AvailableMentorings({ batch = null, company = null }) {
  const {
    BatchMentorService
  } = useContext(ServicesLayerContext);
  const {
    data: availableMentors,
    loading
  } = useService(
    BatchMentorService.getAvailableForOde,
    { batchId: batch?.id, odeId: company?.id },
    { shouldFetch: !!batch && !!company }
  );

  return (
    <List
      loading={loading}
      grid={cardGridSizes}
      itemLayout="horizontal"
      dataSource={availableMentors || []}
      renderItem={mentor => (
        <List.Item>
          <AvailableMentorListItem
            user={mentor}  />
        </List.Item>
      )} />
  );
}
