import { shared } from "../../../../graphql/shared";
import { eventTypeEnum } from "../../../../helpers";

export async function approve(scheduledService, expertId) {
  const assignedExpertService = scheduledService?.assignedExpertService;
  const where = { };

  const assignationIdentifier = assignedExpertService?.assignationIdentifier;

  // passing an undefined value in the where constraint
  // could update all the services
  if (!scheduledService.id) {
    throw new Error("id not specified");
  }

  if (assignedExpertService?.expertService?.type === "WORKSHOP") {
    if (!assignationIdentifier) {
      throw new Error("assignationIdentifier not specified");
    }

    where.assignedExpertService = { assignationIdentifier };
  } else {
    where.id = scheduledService.id;
  }

  return await this.client.mutate({
    mutation: shared.mutations.updateScheduledServices,
    variables: {
      where,
      data: {
        status: "ON_GOING",
        events: { // log event
          create: {
            event: eventTypeEnum.STATUS_CHANGED,
            description: "Status changed to ON_GOING",
            blame: { connect: { id: expertId } }
          }
        }
      }
    }
  });
}
