import gql from "graphql-tag";

export const getAvailablePrograms = gql`
  query GetAvailablePrograms(
    $coordinatorId: ID, 
    $iname: String, 
    $orderBy: ProgramOrderByInput,
    $first: Int,
    $skip: Int
  ) {
    programs(
      orderBy: $orderBy,
      where: {
        OR: [
          { coordinator: { id: $coordinatorId } }, 
          { batches_some: { users_some: { id: $coordinatorId } } }
        ]
        iname_contains: $iname
        AND: [
          { OR: [ { disabled: false}, { disabled: null} ]  }
        ]
      },
      skip: $skip,
      first: $first
    ) {
      id
      name
      type
      coverPicUrl
      disabled
      batches{
        name
      }
    }
    meta: programsConnection(
      where: {
        OR: [
          { coordinator: { id: $coordinatorId } },
          { batches_some: { users_some: { id: $coordinatorId } } }
        ]
        iname_contains: $iname
        AND: [
          { OR: [ { disabled: false}, { disabled: null} ]  }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
