import { client } from "../../../graphql";
import { shared } from "../../graphql/shared";

async function getById(id) {
  return await client.query({
    query: shared.queries.getTasks,
    fetchPolicy: "network-only",
    variables: {
      where: {
        id: id
      }
    }
  });
}

export const taskService = {
  getById
};
