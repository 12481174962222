import { useContext, useState } from "react";
import React from "react";
import { ObjectiveItemContext } from "./context";
import { admin } from "../../../graphql/admin";
import { shared } from "../../../graphql/shared";
import { useMutation } from "@apollo/react-hooks";
import { DataConnectionService } from "../../../services";
import { message } from "antd";
import { BatchDetailsContext } from "../../admin/batches/batch-details/context";

const { graphqlApolloClient: client } = DataConnectionService;

export function ObjectiveItemContextProvider({ children, objective }) {
  const [state, setState] = useState({ editable: false, objective: objective });
  const { data } = useContext(BatchDetailsContext);

  const [createObjectiveAchievement] = useMutation(
    admin.mutations.createObjectiveAchievement,
    { client }
  );

  const [deleteObjectiveAchievement] = useMutation(
    admin.mutations.deleteObjectiveAchievement,
    { client }
  );

  const [deleteObjectiveMutation] = useMutation(
    admin.mutations.deleteObjective,
    { client }
  );

  const [deleteContentMutation] = useMutation(
    admin.mutations.deleteContent,
    { client }
  );

  const setEditable = editable => {
    setState(state => ({ ...state, editable }));
  };

  const deleteAchievement = async achievementId => {
    const dismissLoader = message.loading("Eliminando...");
    try {
      await deleteObjectiveAchievement({
        variables: {
          objectiveId: objective.id,
          achievementId: achievementId
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: admin.queries.getObjectivesByBatchId,
            variables: { id: data?.batch?.id }
          },
          {
            query: shared.queries.getBatchObjectives,
            variables: { batchId: data?.batch?.id }
          }
        ]
      });
      message.success("Logro eliminado.");
    } catch (e) {
      console.error(e);
      message.error(`Ha ocurrido un error.
        /Por favor inténtalo de nuevo en unos segundos.`);
    }
    dismissLoader();
  };

  const createEmptyAchievement = async () => {
    try {
      await createObjectiveAchievement({
        variables: {
          data: {
            name: "",
            description: "",
            objective: {
              connect: { id: objective.id }
            }
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: admin.queries.getObjectivesByBatchId,
          variables: { id: data?.batch?.id }
        }]
      });
    }
    catch(e) {
      console.error(e);
      message.error(`Ha ocurrido un error.
        /Por favor inténtalo de nuevo en unos segundos.`);
    }
  };

  const deleteObjective = async () => {
    try {
      await deleteObjectiveMutation({
        variables: { id: objective.id },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
          query: admin.queries.getObjectivesByBatchId,
          variables: { id: data?.batch?.id }
          },
          {
            query: shared.queries.getBatchObjectives,
            variables: { batchId: data?.batch?.id }
          }
        ]
      });
    } catch (e) {
      console.error(e);
      message.error(`Ha ocurrido un error.
        /Por favor inténtalo de nuevo en unos segundos.`);
    }
  };

  const deleteContent = async contentId => {
    try {
      await deleteContentMutation({
        variables: { id: contentId },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: admin.queries.getObjectivesByBatchId,
            variables: { id: data?.batch?.id }
          },
          {
            query: shared.queries.getBatchObjectives,
            variables: { batchId: data?.batch?.id }
          }
        ]
      });
    } catch (e) {
      console.error(e);
      message.error(`Ha ocurrido un error.
        /Por favor inténtalo de nuevo en unos segundos.`);
    }
  };

  return (
    <ObjectiveItemContext.Provider
      value={{ ...state, setEditable, createEmptyAchievement, deleteObjective, deleteAchievement, deleteContent }}>
      {children}
    </ObjectiveItemContext.Provider>
  );
}
