import { Row, Col } from "antd";
import React from "react";
import { ExamplesItem } from "./item";

/**
 * @param {{ url: string, key: string, id: string }[]} examples
 * @returns {JSX.Element|null}
 * @constructor
 */
export function ExamplesDisplay({examples}) {
  if(!examples) {
    return null;
  }

  return (
    <Row gutter={10} style={{marginBottom: "20px", width: "100%"}}>
      { examples.map(i =>
        <Col key={i.key || i.id} span={4}>
          <ExamplesItem example={i} />
        </Col>
      ) }
    </Row>
  );
}
