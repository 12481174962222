import React from "react";
import { SidebarLayout } from "../sidebar-layout";

export function OnboardingLayout({ children }) {
  return (
    <SidebarLayout
      className="onboarding-layout"
      hideSidebar
      hideOptions
      showLogo>
      { children }
    </SidebarLayout>
  );
}
