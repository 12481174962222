import { client } from "../../../graphql";
import {
  getAssignedServices as getAssignedServicesQuery,
  getScheduledServices as getScheduledServicesQuery,
  getScheduledMentorshipServicesEntrepreneur
    as getScheduledMentorshipServicesEntrepreneurQuery,
  getAvailableAssignedServices as getAvailableAssignedServicesQuery
} from "./graphql";

async function getAssignedServices({
  entrepreneurId,
  first,
  skip,
  ...filters
}) {
  const result = await client.query({
    query: getAssignedServicesQuery,
    fetchPolicy: "network-only",
    variables: {
      entrepreneurId,
      first,
      skip,
      ...filters
    }
  });

  return {
    assignedExpertServices: result.data?.assignedExpertServices || [],
    count: result.data?.meta?.aggregate?.count
  };
}

async function getScheduledServices({
  entrepreneurId,
  first,
  skip,
  ...filters
}) {
  const result = await client.query({
    query: getScheduledServicesQuery,
    fetchPolicy: "network-only",
    variables: {
      entrepreneurId,
      first,
      skip,
      ...filters
    }
  });

  const scheduledExpertServices = result.data?.scheduledExpertServices.map(
    scheduled => ({
      ...scheduled.assignedExpertService,
      status: scheduled?.status,
      duration: scheduled?.duration,
      id: scheduled?.id
    })
  );

  return {
    scheduledExpertServices: scheduledExpertServices || [],
    count: result.data?.meta?.aggregate?.count
  };
}

async function getScheduledMentorshipServicesEntrepreneur({
  entrepreneurId,
  serviceId,
  companyId
}) {
  const result = await client.query({
    query: getScheduledMentorshipServicesEntrepreneurQuery,
    fetchPolicy: "network-only",
    variables: {
      entrepreneurId,
      serviceId,
      companyId
    }
  });

  return result?.data?.scheduledExpertServices;
}

async function getAvailableAssignedServices({
  entrepreneurId,
  serviceId,
  companyId
}) {
  const result = await client.query({
    query: getAvailableAssignedServicesQuery,
    fetchPolicy: "network-only",
    variables: {
      entrepreneurId,
      serviceId,
      companyId,
      now: new Date().toISOString()
    }
  });

  return result?.data?.assignedExpertServices;
}

export const EntrepreneurSessionsServicesService = {
  getAssignedServices,
  getScheduledServices,
  getScheduledMentorshipServicesEntrepreneur,
  getAvailableAssignedServices
};
