import gql from "graphql-tag";

export const getProgramById = gql`
  query GetProgramById($id: ID!) {
    program(
      where: { id: $id }
    ) {
      id
      name
      description
      type
      origin
      coverPicUrl
      email
      phone
      website
      facebook
      createdAt
      disabled
      coordinator {
        id
        fullName
      }
      batches {
        id
      }
    }
  }
`;
