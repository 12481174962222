import React from "react";
import { Column } from "@ant-design/charts";
import {
  odeTrlOptions,
  getReadableValue
} from "../../../../../../helpers/select-options";

export function TRLChart({ odes = [] }) {
  odes = odes.map(ode => ({
    ...ode,
    trl: parseInt(getReadableValue(odeTrlOptions, ode.trl))
  }));

  const config = {
    data: odes,
    xField: "name",
    yField: "trl",
    xAxis: {
      label: {
        autoRotate: true,
        formatter: text => text.length > 20 ?
          `${text?.slice(0, 20)}...`
          : text
      }
    },
    label: {
      position: "middle",
      style: {
        fill: "#FFFFFF",
        opacity: 0.6
      }
    },
    meta: {
      trl: {
        alias: "Nivel de TRL",
        min: 0,
        max: 9
      }
    }
  };

  return <Column {...config} />;
}
