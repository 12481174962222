import React, { useContext } from "react";
import { Button, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { ContentControllerContext } from "../../content-controller-context/";

export const ContentController = () => {
  const { onNext, onPrev, disablePrev, disableNext } = useContext(ContentControllerContext);
  const { Title } = Typography;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}>
      <div
        style={{
          display: "flex"
        }}>
        <Button
          disabled={disablePrev}
          onClick={onPrev}
          type="primary"
          icon={<LeftOutlined />}
          size="small" />
        <div style={{ marginLeft: "10px" }}><Title level={5}>Anterior</Title></div>
      </div>
      <div
        style={{
          display: "flex"
        }}>
        <div style={{ marginRight: "10px" }}><Title level={5}>Siguiente</Title></div>
        <Button
          disabled={disableNext}
          onClick={onNext}
          type="primary"
          icon={<RightOutlined />}
          size="small" />
      </div>
    </div>
  )
}
