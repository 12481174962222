import React, { useContext } from "react";
import {
  SidebarLayout,
  Section
} from "../../../../components/shared";
import {
  AdminExpertsContext
} from "../../../../contexts/admin/experts";
import {
  ExpertServiceModalProvider
} from "../../../../contexts/shared/expert-service-modal";
import {
  ExpertServicesList
} from "../../../../components/admin/experts/services/list";
import { shared } from "../../../../graphql/shared";
import { client } from "../../../../../graphql";
import { useQuery } from "@apollo/react-hooks";
import { MenuContext } from "../../../../contexts/shared/menu";
import {
  AssignedExpertServiceModalProvider
} from "../../../../contexts/shared/assigned-expert-service-modal";
import {
  FilterControls
} from "../../../../components/admin/experts/services/filter-controls";
import { MainMenu } from "../../../../components/coordinator/shared";
import { useQueryFilters, useControlledPagination } from "../../../../helpers";
import { ProfileVerifierProvider } from "../../../../contexts/shared/profile-verifier";
import { CurrentUserContext, PaginationContext } from "../../../../contexts/shared";

export function ServicesIndex() {
  const { setMenuState } = useContext(MenuContext);
  const { registerRefetch } = useContext(PaginationContext);
  const { currentUser } = useContext(CurrentUserContext);

  const userBatches = currentUser?.batches || [];

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/services"]
  });


  const { loading, error, data, refetch, variables } = useQuery(
    shared.queries.getServices,
    {
      client,
      variables: {
        expert: {
          OR: [
            {
              NOT: {
                expertProfile: {
                  private: true
                }
              }
            },
            {
              expertProfile: {
                private: true,
                privateBatches_some: { id_in: userBatches?.map(batch => (batch.id)) }
              }
            }
          ]
        },
        first: 12
      }
    }
  );

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 12
  });

  const { applyFilters, filters } = useQueryFilters({
    refetch,
    defaultFilters: {
      first: 12
    }
  });

  const onFilter = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);

    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  registerRefetch("services", refetch, variables);

  return (
    <ExpertServiceModalProvider refetchVars={filters}>
      <AssignedExpertServiceModalProvider>
        <AdminExpertsContext.Provider value={{ data }}>
          <SidebarLayout menu={<MainMenu />}>
            <ProfileVerifierProvider>
              <Section
                title="Productos y servicios"
                extra={<FilterControls onChange={onFilter} />}
                style={{ margin: "15px 20px" }}>
                <ExpertServicesList
                  data={data}
                  isLoading={loading}
                  error={error}
                  onChangePage={onPaginationChange}
                  currentPage={currentPage} />
              </Section>
            </ProfileVerifierProvider>
          </SidebarLayout>
        </AdminExpertsContext.Provider>
      </AssignedExpertServiceModalProvider>
    </ExpertServiceModalProvider>
  );
}
