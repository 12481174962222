import gql from "graphql-tag";

export const upsertProgram = gql`
  mutation UpsertProgram(
    $id: ID
    $create: ProgramCreateInput!,
    $update: ProgramUpdateInput!
  ) {
  upsertProgram(
    where: { id: $id }
    update: $update
    create: $create
  ) {
      id
    }
  }
`;
