import React, { useState } from "react";
import { Card, Alert } from "antd";
import { withLoader } from "../../../../../helpers";
import {
  userDecorator
} from "../../../../../decorators/admin/experts/show/user-decorator";
import { Details } from "./details";
import { EditPersonalInfoForm } from "./form";

function Summary({
  allowEdit = false,
  data
}) {
  const [state, setState] = useState({
    editing: false
  });

  const setEditing = (editing = true) =>
    setState({ editing });

  if(!data.user) {
    return (
      <Alert
        type="error"
        message="Ha ocurrido un error al cargar la información,
        por favor intenta de nuevo en unos segundos."
        showIcon />
    );
  }

  const decoratedUser = Object.assign(userDecorator, data.user);

  return (
    <Card
      bordered={false}
      style={{borderBottom: "solid 1px #f4f4f4"}}>
      {state.editing ?
        <EditPersonalInfoForm
          data={data}
          setEditing={setEditing}
          editing={state.editing} />
        : <Details
          allowEdit={allowEdit}
          decoratedUser={decoratedUser}
          setEditing={setEditing} />}
    </Card>
  );
}

export default withLoader(Summary);
