import gql from "graphql-tag";

export const upsertOdeUpdate = gql`
  mutation UpsertOdeUpdate(
    $id: ID!
    $create: OdeUpdateCreateInput!,
    $update: OdeUpdateUpdateInput!
  ) {
  upsertOdeUpdate(
    where: { id: $id }
    update: $update
    create: $create
  ) {
      id
    }
  }
`;
