import React, { useContext, useEffect } from "react";
import { Form, Input } from "antd";
import { EditOdeContext } from "../../../../../contexts/shared/edit-ode";
import { FormContainer } from "../../..";
import { Details } from "./details";
import { fieldValidationMessages } from "../../../../../utils";

export function PitchForm({ editable = false, form }) {
  const { data } = useContext(EditOdeContext);

  useEffect(() => {
    form.setFieldsValue(data?.oDE?.pitch);
  }, []);

  if (!editable) {
    return <Details data={data?.oDE?.pitch} />;
  }

  return (
    <FormContainer>
      <Form
        validateMessages={fieldValidationMessages}
        layout="vertical"
        form={form}>
        <Form.Item
          name="problem"
          label="Problema">
          <Input.TextArea
            rows={4}
            placeholder="Describe el problema que resuelves" />
        </Form.Item>
        <Form.Item
          name="solution"
          label="Solución">
          <Input
            placeholder="Describe la solución al problema" />
        </Form.Item>
        <Form.Item
          name="demoUrl"
          label="Demo">
          <Input
            placeholder="Link de tu demostración" />
        </Form.Item>
        <Form.Item
          name="market"
          label="Mercado">
          <Input.TextArea
            rows={4}
            placeholder="¿Qué mercado estás atacando?" />
        </Form.Item>
        <Form.Item
          name="businessModel"
          label="Modelo de negocio">
          <Input
            placeholder="Describe tu modelo de negocio" />
        </Form.Item>
      </Form>
    </FormContainer>
  );
}