import gql from "graphql-tag";

export const getSearchUser = gql`
  query SearchExperts (
    $skip: Int
    $first: Int!
    $batches: [String]
    $ifullName: String
    $systemRole: SystemRole!
  ) {
    searchUsers (
      skip: $skip
      first: $first
      where: { query: $ifullName systemRole: $systemRole, batches: $batches }
    ) {
      count
      page
      nbPages
      hitsPerPage
      users {
        id
        fullName
        email
        phone
        profilePicUrl
        expertProfile {
          id
          resume
        }
      }
    }
  }
`;