import gql from "graphql-tag";

export const sendCommentMutation = gql`
  mutation SendCommentMutation(
    $scheduleExpertServiceId: ID!,
    $comments: CommentUpdateManyInput
  ) {
    updateScheduledExpertService(
      where: { id: $scheduleExpertServiceId }
      data: {
        comments: $comments
      }
    ){
      id
    }
  }
`;
