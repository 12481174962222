import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { ServiceDetailModalContext } from "./context";
import { CurrentUserContext } from "../current-user";
import { ServiceDetailModal } from "./modal";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";
import { admin } from "../../../graphql/admin";
import { eventTypeEnum, serviceStatusEnum as STATUS } from "../../../helpers/constants";
import { message } from "antd";
import { Modal }from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useLocation, useHistory } from "react-router-dom";

export function ServiceDetailModalProvider({ children, refetch = null }) {
  const [state, setState] = useState({
    isModalOpen: false,
    scheduledExpertService: undefined,
    expertService: undefined,
    loading: false
  });

  const { pathname } = useLocation();
  const history = useHistory();

  const { currentUser } = useContext(CurrentUserContext);

  const [updateAssignedExpertService] = useMutation(
    admin.mutations.updateAssignedExpertService, {
    client,
    awaitRefetchQueries: true
  });

  const updateState = (values = {}) => {
    setState(prevState => ({
      ...prevState,
      ...values
    }));
  };

  const getScheduledExpertService = async id => {
    return await client.query({
      query: shared.queries.getFullScheduledExpertServiceById,
      variables: { id },
      fetchPolicy: "network-only"
    });
  };

  const getAssignedExpertService = async id => {
    return await client.query({
      query: shared.queries.getFullAssignedExpertServiceById,
      variables: { id },
      fetchPolicy: "network-only"
    });
  };

  const onCancelRequestListeners = [];

  const addOnCancelRequestListener = func => {
    onCancelRequestListeners.push(func);
  };

  const onCancelRequest = data => {
    onCancelRequestListeners.forEach(i => i(data));
  };

  const cancelAssignedService = async assignedExpertServiceId => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      await updateAssignedExpertService({
        variables: {
          id: assignedExpertServiceId,
          data: {
            status: STATUS.CANCELLED,
            events: { // log event
              create: {
                event: eventTypeEnum.STATUS_CHANGED,
                description: `Status changed to ${STATUS.CANCELLED}`,
                blame: { connect: { id: currentUser?.id } }
              }
            }
          }
        }
      });
      refetch && await refetch();
      message.success("La asignación ha sido cancelada");
    } catch (error) {
      message.error("Ocurrió un error.\
        Por favor intenta de nuevo en un momento.");
      console.error(error);
    } finally {
      dismissLoader();
    }
  };

  const onCancelAssignedService = async assignedExpertServiceId => {
    Modal.confirm({
      okText: "Cancelar asignación",
      cancelText: "Cerrar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: "Estás a punto de cancelar la asignación de un servicio\
        ¿Deseas continuar?",
      onOk: async () => {
        await cancelAssignedService(assignedExpertServiceId);
        closeModal();
      }
    });
  };

  const openModal = async ({
    scheduledExpertService,
    assignedExpertService,
    expertService
  }) => {
    updateState({ isModalOpen: true, loading: true });

    let service = {};

    if (scheduledExpertService?.id) {
      const result = await getScheduledExpertService(scheduledExpertService.id);
      service = result?.data.scheduledExpertService;
    } else if (assignedExpertService?.id) {
      const result = await getAssignedExpertService(assignedExpertService.id);
      const assignedExpertServiceData = result.data.assignedExpertService;
      service = { // simulate a scheduled expert service
        id: assignedExpertService.id,
        status: assignedExpertServiceData.status,
        duration: assignedExpertServiceData.assignedUnits,
        assignedExpertService: assignedExpertServiceData,
        isProbono: assignedExpertServiceData.isProbono
      };
    }

    updateState({
      scheduledExpertService: service,
      expertService,
      loading: false
    });
  };

  const closeModal = () => {
    updateState({
      isModalOpen: false,
      scheduledExpertService: undefined,
      expertService: undefined
    });

    history.replace(pathname);
  };

  const onCancel = () => {
    closeModal();
  };

  const injectActions = {
    openModal,
    onCancel,
    addOnCancelRequestListener,
    onCancelRequest,
    closeModal,
    onCancelAssignedService
  };

  return (
    <ServiceDetailModalContext.Provider value={injectActions}>
      <ServiceDetailModal
        loading={state.loading}
        onCancelRequest={onCancelRequest}
        visible={state.isModalOpen}
        onCancel={onCancel}
        scheduledExpertService={state.scheduledExpertService}
        refetch={refetch} />
      { children }
    </ServiceDetailModalContext.Provider>
  );
}
