import { entrepreneur } from "../../../../graphql/entrepreneur";
import { cancelledByEnum, eventTypeEnum } from "../../../../helpers";

export async function cancel(scheduledServiceId, entrepreneurId) {
  return await this.client.mutate({
    mutation: entrepreneur.mutations.cancelScheduledExpertService,
    variables: {
      id: scheduledServiceId,
      cancelledBy: cancelledByEnum.ODE,
        events: { // log event
          create: {
            event: eventTypeEnum.STATUS_CHANGED,
            description: "Request status changed to CANCELLED",
            blame: { connect: { id: entrepreneurId } }
          }
        }
    }
  });
}
