import React, { useState, useEffect } from "react";
import { message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql";
import { TaskDetailsModalContext } from "./context";
import { TaskDetailsModal } from "./modal";
import { entrepreneur } from "../../../graphql/entrepreneur";
import { taskService } from "../../../services/task-service";

export function TaskDetailsModalContextProvider({ children }) {

  const { id } = useParams();

  const [state, setState] = useState({
    isModalOpen: false,
    taskId: undefined,
    task: undefined
  });

  const getTask = async taskId => {
    try {
      const { data } = await taskService.getById(taskId);
      return data?.tasks?.[0];
    } catch(e) {
      console.error(e);
    }
  };

  const setTask = (tasks = []) => {
    const currentTask = tasks.filter(task => task.id === state.taskId);
    setState(state => ({
      ...state,
      task: currentTask?.[0]
    }));
  };

  const [updateTask] = useMutation(
    shared.mutations.updateTask,
    { client }
  );

  const [deleteTask] = useMutation(
    shared.mutations.deleteTask,
    { client }
  );

  const [addCommentTask] = useMutation(
    shared.mutations.addCommentTask,
    { client }
  );

  const [deleteDeliverableByFileId] = useMutation(
    entrepreneur.mutations.deleteDeliverableByFileId,
    { client }
  );

  const refetchQueries = [{
    query: shared.queries.getTasks,
    variables: {
      where: {
        batch: {id: id},
        ode: {id: undefined}
      }
    }
  }];

  const addComment = async (userId, comment) => {
    const dismissLoading = message.loading("Cargando...");
    try {
      await addCommentTask({
        variables: {
          id: state.task.id,
          comment: {
            author: { connect: { id: userId } },
            comment: comment
          }
        },
        awaitRefetchQueries: true,
        refetchQueries
      });
    } catch(error) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
    } finally {
      dismissLoading();
    }
  };

  const deleteCustomTask = async taskId => {
    const dismissLoading = message.loading("Cargando...");
    try {
      await deleteTask({
        variables: {
          id: taskId
        },
        awaitRefetchQueries: true,
        refetchQueries
      });
    } catch(error){
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
    } finally {
      dismissLoading();
    }
  };

  const sendDeliverables = async (deliverables = []) => {
    const dismissLoading = message.loading("Cargando...");
    try {
      await updateTask({
        variables: {
          data: {
            deliverables: { create: deliverables }
          },
          id: state.task.id
        },
        awaitRefetchQueries: true,
        refetchQueries
      });
    } catch (error) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
    } finally {
      dismissLoading();
    }
  };

  const deleteDeliverable = async fileId => {
    try {
      await deleteDeliverableByFileId({
        variables: {
          id: fileId
        },
        awaitRefetchQueries: true,
        refetchQueries
      });
    } catch (error) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
    }
  };

  const markTaskAsCompleted = async () => {
    const dismissLoading = message.loading("Cargando...");
    try {
      await updateTask({
        variables: {
          data: {
            isCompleted: true
          },
          id: state.task.id
        },
        awaitRefetchQueries: true,
        refetchQueries
      });
    } catch (error) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
    } finally {
      dismissLoading();
    }
  };

  const markTaskAsUncumpleted = async () => {
    const dismissLoading = message.loading("Cargando...");
    const deliveredDate = new Date();
    try {
      await updateTask({
        variables: {
          data: {
            deliveredDate,
            isCompleted: false
          },
          id: state.task.id
        },
        awaitRefetchQueries: true,
        refetchQueries
      });
    } catch (error) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
    } finally {
      dismissLoading();
    }
  };

  const openModal = async taskId => {
    setState(state => ({
      ...state,
      loading: true,
      isModalOpen: true
    }));

    if(taskId) {
      const task = await getTask(taskId);

      setState(state => ({
        ...state,
        taskId: taskId,
        task
      }));
    }

    setState(state => ({ ...state, loading: false }));
  };

  const closeModal = () => {
    setState({
      isModalOpen: false,
      taskId: undefined,
      task: undefined
    });
  };

  const injectActions = {
    openModal,
    closeModal,
    deleteCustomTask,
    setTask
  };

  return(
    <TaskDetailsModalContext.Provider value={injectActions}>
      <TaskDetailsModal
        task={state.task}
        visible={state.isModalOpen}
        sendDeliverables={sendDeliverables}
        deleteDeliverable={deleteDeliverable}
        markTaskAsCompleted={markTaskAsCompleted}
        markTaskAsUncompleted={markTaskAsUncumpleted}
        addComment={addComment}
        closeModal={closeModal} />
      {children}
    </TaskDetailsModalContext.Provider>
  );
}