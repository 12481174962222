import moment from "moment";

const formatAttendances = attendances => {
  return attendances.reduce((previous, current) => {
    const monthYear = moment(
      current?.experience?.startDate
    ).format("MMM YYYY");
    if (!previous[monthYear]) {
      previous[monthYear] = [current];
      return previous;
    }
    previous[monthYear] = [...previous[monthYear], current];
    return previous;
  }, {});
};

export const timeLinePersonForEe = attendances => {
  const parseAttendance = formatAttendances(attendances);
  const sortedMonths = Object.keys(parseAttendance).sort((a, b) => {
    const dateA = a.split(" ");
    const dateB = b.split(" ");

    const yearA = dateA[1];
    const yearB = dateB[1];
    const indexA = months.findIndex(value => value === dateA[0]);
    const indexB = months.findIndex(value => value === dateB[0]);

    if (yearA === yearB) return indexB - indexA;
    else return yearB.localeCompare(yearA);
  });

  const keys = sortedMonths.map(key => ({
    monthYear: key,
    experiences: parseAttendance[key]
  }));

  return keys.map(el => ({
    ...el,
    experiences: el?.experiences.sort((a, b) =>
      b.experience?.startDate.localeCompare(a.experience?.startDate))
  }));
};

export const months = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre"
];
