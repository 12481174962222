import React, { useContext } from "react";
import { Form } from "antd";
import { EditButton, Section, Visibility } from "../../../";
import { AccountsForm } from "../form";
import {
  EditProfileContext
} from "../../../../../contexts/shared";
import { formatValues } from "./format-values";
import { CurrentUserContext } from "../../../../../contexts/shared";
import { ActionButtonForm } from "../../action-button-form";

export function AccountsEditSection({ editable = false }) {
  const [form] = Form.useForm();
  const { refetchCurrentUser } = useContext(CurrentUserContext);
  const {
    saveChanges,
    editing,
    setEditing
  } = useContext(EditProfileContext);

  const onSave = async () => {
    const values = form.getFieldsValue();
    const data = formatValues(values);

    await saveChanges(data, "accounts");
    await refetchCurrentUser();
  };

  return (
    <Section
      spaced
      title="Redes sociales"
      extra={
        <Visibility visible={editable && !editing?.accounts}>
          <EditButton
            onClick={() => setEditing(true, "accounts")}>
            Editar
          </EditButton>
        </Visibility>
      }
      bottom={
        <Visibility visible={editable && editing?.accounts}>
          <ActionButtonForm
            setEditing={setEditing}
            formName="accounts"
            onSave={onSave} />
        </Visibility>}>
      <AccountsForm
        form={form}
        editable={editing?.accounts && editable} />
    </Section>
  );
}
