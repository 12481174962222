import gql from "graphql-tag";

export const getProgramsByEntrepreneurId = gql`
  query GetProgramsByEntrepreneurId(
    $id: ID,
    $orderBy: ProgramOrderByInput
  ) {
    programs(
      orderBy: $orderBy,
      where: {
        batches_some: {
          odes_some: {
            team_some: {
              systemRole: ENTREPRENEUR
              id: $id
            }
          }
        }
        AND: [
          { OR: [ { disabled: false}, { disabled: null} ]  }
        ]
      }
    ) {
      id
      name
      type
      coverPicUrl
      disabled
      batches{
        name
      }
    }
  }
`;
