import React, { useContext } from "react";
import { Form } from "antd";
import { Section, Visibility } from "../../..";
import { EditOdeContext } from "../../../../../contexts/shared/edit-ode";
import { ActionButton } from "../../action-button";
import { GeneralInfoForm } from "../form";

export function GeneralInfoEditSection({ editable = false }) {
  const {
    saveChanges,
    editing,
    setEditing
  } = useContext(EditOdeContext);

  const onSave = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    saveChanges({
      ...values,
      keywords: { set: values?.keywords }
    },
    "general");
  };

  const [form] = Form.useForm();

  return (
    <Section
      spaced
      title="Tipo de negocio"
      extra={
        <Visibility visible={editable}>
          <ActionButton
            editing={editing.general}
            setEditing={setEditing}
            formName="general"
            visible
            onSave={onSave}>
          </ActionButton>
        </Visibility>
      }>
      <GeneralInfoForm
        form={form}
        editable={editing.general && editable } />
    </Section>
  );
}
