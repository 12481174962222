import gql from "graphql-tag";

export const getOrganizationsByStatus = gql`
  query GetOrganizationsByStatus(
    $first: Int,
    $skip: Int
    $status: OrganizationStatus!
  ) {
    organizations(
      where: { status: $status }
      orderBy: createdAt_DESC
      first: $first,
      skip: $skip
    ) {
      id
      name
      website
      createdAt
      services
      stages
      reviewedBy {
        id
        fullName
      }
    }
    meta: organizationsConnection(
      where: {
        status: $status
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;