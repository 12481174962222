import gql from "graphql-tag";

export const getOrganizationById = gql`
  query GetOrganizationById($id: ID!) {
    organization(
      where: { id: $id }
    ) {
      logo
      name
      private
      createdAt
      startedAt
      services
      __typename
      stages
      description
      nationalPresence
      email
      website
      facebook
      instagram
      twitter
      linkedin
      status
      rejectionReason
      ranByWomen
      states {
        name
        allCities
        cities
      }
    }
  }
`;
