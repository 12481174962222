import { entrepreneur } from "../../../../graphql/entrepreneur";
import {
  eventTypeEnum,
  cancelledByEnum as CANCELLED_BY
} from "../../../../helpers";

export async function rejectDelivery(scheduledServiceId, entrepreneurId) {
  return await this.client.mutate({
    mutation: entrepreneur.mutations.rejectScheduledExpertServiceDelivery,
    variables: {
      id: scheduledServiceId,
      cancelledBy: CANCELLED_BY.ODE,
       // log event
      events: {
        create:
        {
          event: eventTypeEnum.STATUS_CHANGED,
          description: "Delivery changed to DELIVERY_REJECTED",
          blame: { connect: { id: entrepreneurId } }
        }
      }
    }
  });
}
