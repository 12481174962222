import React from "react";
import {
  Card,
  Button
} from "antd";
import { Image, Format } from "..";

export function ExpertCard({expert}) {
  return (
    <Card
      style={{minHeight: "330px"}}
      cover={
        <Image
          text={expert?.fullName?.trim() || "N A"}
          src={expert.profilePicUrl} />
      }>
      <Card.Meta
        title={expert.fullName}
        description={
          <Format type="prune" size="100">
            {expert.expertProfile?.resume}
          </Format>
        } />
    </Card>
  );
}
