import { client } from "../../../graphql";
import {
  getAssignedServices as getAssignedServicesQuery,
  getCoordinatorAssignedServices as getCoordinatorAssignedServicesQuery,
  getScheduledServices as getScheduledServicesQuery,
  getCoordinatorAssignedWorkshopServices as getCoordinatorAssignedWorkshopServicesQuery,
  getAssignedWorkshopServices as getAssignedWorkshopServicesQuery,
  getCoordinatorScheduledServices as getCoordinatorScheduledServicesQuery,
  getCoordinatorScheduledWorkshopServices as getCoordinatorScheduledWorkshopServicesQuery,
  getScheduledWorkshopServices as getScheduledWorkshopServicesQuery,
  generateReportScheduledServices as generateReportScheduledServicesQuery
} from "./graphql";
import {
  formatMonitoringReportFilters
} from "../../components/admin/experts/services/monitoring";

async function getAssignedServices({
  serviceType = "",
  coordinatorId,
  first,
  skip,
  ...filters
}) {
  const query = coordinatorId
    ? serviceType !== "WORKSHOP"
      ? getCoordinatorAssignedServicesQuery
      : getCoordinatorAssignedWorkshopServicesQuery
    : serviceType !== "WORKSHOP"
      ? getAssignedServicesQuery
      : getAssignedWorkshopServicesQuery;

  const result = await client.query({
    query,
    fetchPolicy: "network-only",
    variables: {
      serviceType,
      coordinatorId,
      // pagination variables
      first,
      skip,
      ...filters
    }
  });

  return {
    assignedExpertServices: result.data?.assignedExpertServices || [],
    count: result.data?.meta?.aggregate?.count
  };
}

async function getScheduledServices({
  serviceType = "",
  coordinatorId,
  first,
  skip,
  ...filters
}) {
  const query = coordinatorId
    ? serviceType !== "WORKSHOP"
      ? getCoordinatorScheduledServicesQuery
      : getCoordinatorScheduledWorkshopServicesQuery
    : serviceType !== "WORKSHOP"
      ? getScheduledServicesQuery
      : getScheduledWorkshopServicesQuery;

  const result = await client.query({
    query,
    fetchPolicy: "network-only",
    variables: {
      serviceType,
      coordinatorId,
      // pagination variables
      first,
      skip,
      ...filters
    }
  });

  const scheduledExpertServices = result.data?.scheduledExpertServices.map(
    scheduled => ({
      ...scheduled.assignedExpertService,
      duration: scheduled.duration,
      status: scheduled?.status,
      id: scheduled?.id
    })
  );

  return {
    scheduledExpertServices: scheduledExpertServices || [],
    count: result.data?.meta?.aggregate?.count
  };
}

const generateReportScheduledServices = async ({ filters, markAsPaid }) => {
  const where = formatMonitoringReportFilters(filters);
  const result = await client.query({
    query: generateReportScheduledServicesQuery,
    fetchPolicy: "network-only",
    variables: { where, markAsPaid }
  });
  return result?.data?.reportScheduledExpertServices || null;
};

export const AdminMonitoringServicesService = {
  getAssignedServices,
  getScheduledServices,
  generateReportScheduledServices
};
