import React, { useState } from "react";
import { Alert, Button, Form, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { ContainerOutlined } from "@ant-design/icons";
import { Visibility } from "../../../../components/shared";
import {
  getJsonFromExcel,
  filterEmptyRowsFromSheets,
  formatBatchInstitutionsForImportation
} from "./helpers";
import ReactJsonView from "react-json-view";
import { BatchesService } from "../../../../services";

export function ImportBatchInstitutionsIndex() {
  const [form] = Form.useForm();
  const [state, setState] = useState({});
  const [process, setProcess] = useState("");
  const [result, setResult] = useState({
    ok: false,
    error: false
  });

  const applyFormat = async () => {
    const { attachment = undefined } = form.getFieldsValue();
    setProcess("Reading data from excel file...");
    setResult({ ok: false, error: false });

    const sheets = await getJsonFromExcel(attachment.file)
      .catch(() => setResult({ error: "Error reading excel file" }));

    const [batchesSheet] = sheets;

    setProcess("Cleaning up data...");
    const { batchesRows } = await filterEmptyRowsFromSheets({ batchesSheet });

    setProcess("Formatting institutions...");
    const batchesToUpdate = formatBatchInstitutionsForImportation(batchesRows);

    setProcess("");
    setResult({ ok: "Data loaded successfully" });

    setState({ batches: batchesToUpdate });
  };

  const onChange = async file => {
    try {
      message.success(`Se cargó el archivo: ${file?.name}.`);
      form.setFieldsValue({ "attachment": file });
    } catch (error) {
      message.error(`Error al cargar el archivo: ${file?.name}.`);
    }

    return false;
  };

  const onRemove = () => form.resetFields();

  const importData = async (batches = []) => {
    const dismissLoader = message.loading("Importando datos...");
    try {
      const result = await BatchesService.importBatchInstitutions({
        data: batches
      });
      setState({ result });
      message.success("Datos importados correctamente.");
    } catch (e) {
      message.error(`Error al importar los datos: ${e.message}`);
    } finally {
      dismissLoader();
    }
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <div
        style={{ width: "500px", marginTop: "5rem", margin: "auto" }}>
        <Visibility visible={process}>
          <Alert message={process} type="info" />
        </Visibility>
        <Visibility visible={result.error}>
          <Alert message={result.error} type="error" />
        </Visibility>
        <Visibility visible={result.ok}>
          <Alert message={result.ok} type="success" />
        </Visibility>
        <Form
          form={form}
          layout="vertical">
          <Form.Item
            name="attachment"
            label="Cargar Todo">
            <Dragger
              maxCount={1}
              multiple={false}
              beforeUpload={onChange}
              onRemove={onRemove}>
              <p className="ant-upload-drag-icon">
                <ContainerOutlined />
              </p>
              <p className="ant-upload-text">
                Haz una creación masivas, sólo arrastra tu archivo excel o da click aquí para cargarlo.
              </p>
              <p className="ant-upload-hint">
                El archivo debe tener la extensión “.xlsx”
              </p>
            </Dragger>
          </Form.Item>
        </Form>
        <Button onClick={applyFormat}>
          Aplicar formato
        </Button>
        <Button onClick={() => importData(state.batches)}>
          Importar
        </Button>
        <br />
      </div>
      <ReactJsonView src={state} theme="monokai" />
    </div>
  );
}
