import React, { useContext } from "react";
import { List } from "antd";
import { withLoader, paginationConfig } from "../../../../../../helpers";
import { AddButton, Visibility } from "../../../../../shared";
import { ExpertItem } from "./item";
import {
  CurrentUserContext,
  ConnectUserToBatchContext
} from "../../../../../../contexts/shared";

function ExpertsList({ loading, data, ...props }) {
  const { openModal } = useContext(ConnectUserToBatchContext);
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <>
      <Visibility
        visible={!["ENTREPRENEUR"]
          .includes(currentUser.systemRole)}>
        <AddButton style={{ width: "100%" }} onClick={() => openModal()}>
          Añadir experto
        </AddButton>
      </Visibility>
      <List
        style={{ marginTop: "20px" }}
        loading={loading}
        itemLayout="vertical"
        dataSource={data.users}
        pagination={paginationConfig}
        renderItem={i =>
          <ExpertItem
            expert={i} />
        }
        {...props} />
    </>
  );
}

export default withLoader(ExpertsList);
