import gql from "graphql-tag";

export const addCompanyMultimedia = gql`
  mutation AddCompanyMultimedia(
    $id: ID,
    $data: FileUpdateManyInput
  ){
    updateODE(
      where: { id: $id }
      data: { files: $data }
    ){
      id
    }
  }
`;
