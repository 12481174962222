import numeral from "numeral";

numeral.register("locale", "es", {
  delimiters: {
    thousands: ",",
    decimal: ","
  },
  abbreviations: {
    thousand: "K",
    million: "M",
    billion: "B",
    trillion: "T"
  },
  currency: {
    symbol: "$"
  }
});

numeral.locale("es");

export default numeral;
