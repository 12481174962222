import { toNumber } from "lodash";
import { expertServiceTypes, getReadableValue, scheduledServicesStatus } from "../../../../../helpers/select-options";
import { toLocaleTime } from "../../../../../helpers/to-locale-time";

const todayDate = new Date().toLocaleDateString("es-US", { month: "long", day: "2-digit", year: "numeric" });

export const formatReportValues = scheduledExpertService => {
  return {
    pages: [
      pageOne(scheduledExpertService),
      pageTwo(scheduledExpertService),
      pageThree(scheduledExpertService)
    ]
  };
};

const pageOne = scheduledExpertService => {
  const expertService = scheduledExpertService.assignedExpertService.expertServiceData;
  const assignedExpertService = scheduledExpertService.assignedExpertService;
  const generalDate = new Date(scheduledExpertService.updatedAt);

  const method = expertService.method === "HOURS" ? "Por hora" : "Costo fijo";

  return {
    header: {
      date: `Fecha del reporte: ${todayDate}`
    },
    general: {
      name: expertService?.name,
      expert: expertService.expert?.fullName,
      ode: assignedExpertService.ode?.name,
      status: getReadableValue(scheduledServicesStatus, scheduledExpertService?.status),
      date: `${generalDate.toLocaleDateString()} - ${toLocaleTime(generalDate)}`
    },
    details: {
      type: getReadableValue(expertServiceTypes, expertService.type),
      assignationNumber: assignedExpertService.assignmentNumber,
      cost: `$${expertService.price} / ${method} `,
      isProbono: assignedExpertService.isProbono,
      assignedHours: assignedExpertService.assignedUnits,
      consumedHours: assignedExpertService.assignedUnits - assignedExpertService.availableUnits,
      leftHours: assignedExpertService.availableUnits,
      limitDate: new Date(assignedExpertService.limitDate).toLocaleDateString()
    },
    paymentInfo: {
      amount: assignedExpertService.assignedUnits,
      total: assignedExpertService.isProbono
        ? 0
        : assignedExpertService.assignedUnits * toNumber(expertService?.price)
    }
  };
};

const pageTwo = scheduledExpertService => {
  const expertService = scheduledExpertService.assignedExpertService.expertServiceData;
  const assignedExpertService = scheduledExpertService.assignedExpertService;
  return {
    header: {
      date: `Fecha del reporte: ${todayDate}`
    },
    involvedPeople: {
      expert: {
        name: expertService.expert?.fullName,
        cel: expertService.expert?.phone,
        email: expertService.expert?.email
      },
      entrepreneurs: assignedExpertService.ode.team.map(user => ({
        name: user?.fullName,
        cel: user?.phone,
        email: user?.email
      })),
      coordinator: {
        name: assignedExpertService.batch.users[0]?.fullName,
        cel: assignedExpertService.batch.users[0]?.phone,
        email: assignedExpertService.batch.users[0]?.email
      }
    }
  };
};

const pageThree = scheduledExpertService => {
  const expertService = scheduledExpertService.assignedExpertService.expertServiceData;

  const deliveryDate = new Date(scheduledExpertService.deliveredAt);

  return {
    header: {
      date: `Fecha del reporte: ${todayDate}`
    },
    record: {
      name: `Mentor / ${expertService.expert?.fullName}`,
      comment: scheduledExpertService?.expertComments,
      date: deliveryDate.toLocaleDateString(),
      time: toLocaleTime(deliveryDate)
    },
    evidence: scheduledExpertService?.attachments?.map(doc => ({
        name: `Mentor / ${expertService.expert?.fullName}`,
        url:  doc?.url,
        fileName: doc?.fileName,
        date: deliveryDate.toLocaleDateString(),
        time: toLocaleTime(deliveryDate)
    }))
  };
};