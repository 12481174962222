import gql from "graphql-tag";

export const getRequestedServicesByExpertId = gql`
  query GetRequestedServicesByExpertId(
    $expertId: ID!
    $searchString: String
    $status: String
    $programId: ID
    $category: String
    $type: String
  ) {
    scheduledExpertServices(
      orderBy: updatedAt_DESC
      where: {
        status: $status
        assignedExpertService: {
          expertService: {
            type: $type
            expert: { id: $expertId }
          }
          batch: {
            program: {
              id: $programId
            }
          }
          OR: [
            {
              expertService: {
                category: $category
                OR: [
                  { iname_contains: $searchString },
                  { idescription_contains: $searchString },
                  {
                    expert: {
                      ifullName_contains: $searchString
                    }
                  }
                ]
                expert: {
                  id: $expertId
                }
              }
            },
            {
              batch: { program: { iname_contains: $searchString } }
            },
            {
              batch: { iname_contains: $searchString }
            },
            {
              ode: { iname_contains: $searchString }
            }
          ]
        }
      }
    ) {
      id
      serviceDate
      status
      duration
      acceptedAt
      deliveredAt
      cancelledBy
      calendarEventUri
      requestedBy {
        id
        fullName
        email
        phone
      }
      assignedExpertService {
        id
        assignmentNumber
        assignationIdentifier
        expertServicePrice
        assignedUnits
        availableUnits
        expertServiceData
        expertService {
          id
          name
          type
          price
          method
          category
          expert {
            id
            fullName
            firstName
            middleName
            lastName
          }
        }
        batch {
          id
          name
          coordinators: users(where: { systemRole_in: [COORDINATOR, SUPER_ADMIN] }) {
            id
            fullName
            phone
            email
          }
          program {
            id
            name
            coordinator {
              id
              fullName
              phone
              email
            }
          }
        }
        ode {
          id
          name
        }
      }
    }
  }
`;
