import gql from "graphql-tag";

export const createObjectiveContent = gql`
  mutation CreateObjectiveContent(
    $objectiveId: ID!,
    $data: FileCreateInput!
  ) {
    updateObjective(
      where: { id: $objectiveId }
      data: {
        contents: {
          create: [$data]
        }
      }
    ) {
      id
    }
  }
`;
