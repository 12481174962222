import React, { useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { client } from "../../../../graphql";
import { EmptyView } from "../empty-view";
import { Typography } from "antd";
import { shared } from "../../../graphql/shared";
import { Section, Visibility } from "..";
import { BatchContentsContext } from "../../../contexts/admin/batches";
import {
  CurrentUserContext,
  ContentViewSwitchContext,
  ContentGroupModalContext
} from "../../../contexts/shared";
import BatchContentGroupList from "./list";
import { useQueryParams } from "../../../helpers";
import { BatchContentGroupTable } from "./table";
import { BatchContentHeader } from "./header";
import { sortContentGroups } from "../../admin/batches/show/odes/contents/progress-indicators/helpers";

const description = (
  <div>
    <Typography.Title level={2}>
      ¡Oops, aún no tienes contenidos!
    </Typography.Title>
    <Typography.Paragraph>
      Para comenzar crea las secciones en las que se divide el contenido de tu
      Batch.
    </Typography.Paragraph>
  </div>
);

export function BatchContent() {
  const query = useQueryParams();
  const { currentUser } = useContext(CurrentUserContext);
  const { openModal } = useContext(ContentGroupModalContext);
  const { isListView } = useContext(ContentViewSwitchContext);
  const { batchId, data, loading, refetch, isHeaderVisible = true } = useContext(BatchContentsContext);
  const { systemRole } = currentUser;

  const [createMasterContent] = useMutation(
    shared.mutations.createMasterContent, { client }
  );

  const onCreateMasterContent = async () => {
    await createMasterContent({
      variables: {
        data: { batch: { connect: { id: batchId } } }
      }
    });
    await refetch();
    openModal();
  };

  const showContentsDraftData = (contentGroups = []) => {

    const filteredDeletedSections = contentGroups
      .filter(section => !section.shouldBeDeleted);

    return filteredDeletedSections.map(contentGroup => {

      const filteredDeletedContents = contentGroup.contents
        .filter(content => !content.shouldBeDeleted);

      return {
        ...contentGroup,
        ...contentGroup?.draft || {},
        contents: filteredDeletedContents.map(content => {
          return {
            ...content,
            ...content?.draft || {}
          };
        })
      };
    });
  };

  useEffect(() => {
    if (query.get("create_content") && !data?.masterContents?.[0]) {
      onCreateMasterContent().then();
    }
  }, []);
  return (
    <Section spaced style={{ minHeight: "calc(100% - 370px)" }}>
      <EmptyView
        isEmpty={!data?.masterContents?.[0]}
        isLoading={loading}
        description={description}
        onClick={
          systemRole !== "ENTREPRENEUR" ? onCreateMasterContent : undefined
        }>
        <BatchContentHeader
          batchId={batchId}
          data={data}
          isListView={isListView}
          isHeaderVisible={isHeaderVisible}
          afterPublishContentChanges={refetch} />
        <Visibility visible={isListView}>
          <BatchContentGroupList
            data={data?.masterContents?.[0]?.contentGroupsSort.length
              ? sortContentGroups(
                showContentsDraftData(data?.masterContents?.[0]?.contentGroups),
                data?.masterContents?.[0]?.contentGroupsSort)
              : showContentsDraftData(data?.masterContents?.[0]?.contentGroups)
            }
            isLoading={loading}
            refetch={refetch} />
        </Visibility>
        <Visibility visible={!isListView}>
          <BatchContentGroupTable
            batchMode
            sort={data?.masterContents?.[0]?.contentGroupsSort}
            data={data?.masterContents?.[0]?.contentGroups}
            loading={loading} />
        </Visibility>
      </EmptyView>
    </Section>
  );
}
