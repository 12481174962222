import gql from "graphql-tag";

export const getOdeContentByContentsIdsAndOdeId = gql`
  query GetOdeContentByContentIdAndOdesId($contentsIds: [ID!], $odeId: ID!){
    odeContents(
      where: {
        AND: [
          { ode: { id: $odeId } }
          { content: { id_in: $contentsIds}}
        ]
      }
    ){
      id
      isComplete,
      finishDate,
      content{
        id
      }
      ode{
        name
      }
    }
  }
`;