import React from "react";
import { Section } from "../../section";
import { dayToDay } from "../../../../helpers";
import { Typography, Skeleton } from "antd";
import { getReadableValue } from "../../../../helpers/select-options";
import { institutions } from "../../../../utils/maps";
import { CoverImage } from "../../cover-image";
import { IconLabel } from "../../icon-label";
import {
  FacebookOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined
} from "@ant-design/icons";

export const HeroPublishBatch = ({ batch, loading, ...props }) => {
  const ready = batch && !loading;
  const { startDate, finishDate, institution } = !!batch && batch;

  const withSkeleton = (value, size = 300) => {
    return !ready
      ? <Skeleton.Input
          active
          style={{ width: size }} />
      : value;
  };

  return (
    <Section {...props}>
      <CoverImage
        url={batch?.program?.coverPicUrl}
        text={batch?.program?.name} />
      <Typography.Title style={{ minHeight: "63.9px" }}>
        {withSkeleton(batch?.program?.name)}
      </Typography.Title>
      <Typography.Title
        level={2}
        style={{ minHeight: "40.5px", margin: 0 }}>
        {withSkeleton(batch?.name, 350)}
      </Typography.Title>
      <Typography.Paragraph
        type="secondary"
        style={{ minHeight: "31.42px", marginBottom: 0 }}>
        {withSkeleton((batch?.program?.description  || "Sin descripción"), 400)}
      </Typography.Paragraph>
      <Typography.Paragraph
        type="success"
        style={{ minHeight: "31.42px", marginBottom: 0 }}>
        {`
          ${ready && dayToDay(startDate, finishDate)}
          ${institution ? `, 
            ${getReadableValue(institutions, institution)}` : ""}
        `}
      </Typography.Paragraph>
      <Typography.Paragraph
        type="secondary"
        style={{ margin: "0", fontSize: "16px" }}>
        {batch?.program?.email && (
          <IconLabel
            key="ent-mail"
            icon={<MailOutlined />}>
            {batch.program?.email}
          </IconLabel>
        )}
        {batch?.program?.phone && (
          <IconLabel
            styles={{ marginLeft: "2rem" }}
            key="ent-phone"
            icon={<PhoneOutlined />}>
            {batch.program?.phone}
          </IconLabel>
        )}
      </Typography.Paragraph>
      <Typography.Paragraph
        type="secondary"
        style={{ margin: "0", fontSize: "16px" }}>
        {batch?.program?.website && (
          <IconLabel
            key="ent-mail"
            icon={<GlobalOutlined />}>
            {batch.program?.website}
          </IconLabel>
        )}
        {batch?.program?.facebook && (
          <IconLabel
            styles={{ marginLeft: "2rem" }}
            key="ent-phone"
            icon={<FacebookOutlined />}>
            {batch.program?.facebook}
          </IconLabel>
        )}
      </Typography.Paragraph>
    </Section>
  );
};
