/**
 * Transform the Notion MD styles spec into css styles
 */
export const getRichTextStyle = ({
  bold,
  color,
  italic,
  underline,
  strikethrough
}) => {
  return {
    color,
    whiteSpace: "pre-line",
    display: "inline",
    fontWeight: bold ? "bold" : "normal",
    fontStyle: italic ? "italic" : "normal",
    textDecoration: strikethrough
      ? "line-through" : underline ? "underline"
      : "normal"
  };
};
