import React, { useState } from "react";
import { WorkspaceModalContext } from "./context";
import { WorkspaceModal } from "./modal";

export function WorkspaceModalProvider({ children }) {
  const [state, setState] = useState({
    isModalOpen: false,
    workspace: undefined
  });

  const openModal = workspace => {
    setState({ isModalOpen: true, workspace });
  };

  const closeModal = () => {
    setState({ isModalOpen: false, workspace: undefined });
  };

  const onSave = () => {
    closeModal();
  };

  return (
    <WorkspaceModalContext.Provider value={{ openModal, closeModal }}>
      <WorkspaceModal
        visible={state.isModalOpen}
        onCancel={closeModal}
        onSave={onSave}
        edit={state.workspace} />
      {children}
    </WorkspaceModalContext.Provider>
  );
}
