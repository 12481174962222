import React from "react";
import { Space, List, Input } from "antd";
import {
  Avatar,
  DeleteButton,
  Visibility
} from "../../../../../../components/shared";

const spaceStyle = {
  justifyContent: "space-between",
  width: "100%"
};

export function OdeItem({
  onChange,
  ode = {},
  onDelete,
  expertService
}) {
  const odeName = ode.name || "N/A";

  const showHoursInput = expertService?.type !== "WORKSHOP"
    && expertService.method === "HOURS";

  return (
    <List.Item>
      <Space style={spaceStyle}>
        <div>
          <Space>
            <Avatar>{odeName}</Avatar>
            {odeName}
          </Space>
        </div>
        <div>
          <Space>
            <Visibility visible={showHoursInput}>
              <Input
                addonAfter="horas"
                onChange={onChange}
                name="assignedUnits"
                style={{ width: "120px" }}
                type="number"
                min={1}
                defaultValue={0} />
            </Visibility>
            <DeleteButton onClick={onDelete} />
          </Space>
        </div>
      </Space>
    </List.Item>
  );
}
