import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { coordinator } from "../../../graphql/coordinator";
import { client } from "../../../../graphql";
import { SidebarLayout, Section } from "../../../components/shared";
import {
  BatchModalProvider, CloneBatchModalProvider, MenuContext,
  CurrentUserContext, PaginationContext
} from "../../../contexts/shared";
import { MainMenu } from "../../../components/coordinator/shared";
import { useQueryFilters, useControlledPagination } from "../../../helpers";
import {
  default as BatchesList
} from "../../../components/admin/batches/list";
import {
  FilterControls
} from "../../../components/admin/batches/filter-controls";
import {
  ProfileVerifierProvider
} from "../../../contexts/shared/profile-verifier";

export function BatchesIndex() {
  const { setMenuState } = useContext(MenuContext);
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const {
    loading,
    data,
    error,
    refetch,
    variables
  } = useQuery(
    coordinator.queries.getBatchesByCoordinatorId,
    {
      client,
      fetchPolicy: "network-only",
      variables: {
        id: currentUser.id,
        first: 12
      }
    }
  );

  const { registerRefetch } = useContext(PaginationContext);
  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 12
  });

  registerRefetch("batches", refetch, variables);

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: {
      first: 12
    }
  });

  const onFilter = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  return (
    <CloneBatchModalProvider>
      <BatchModalProvider>
        <SidebarLayout menu={<MainMenu />}>
          <ProfileVerifierProvider>
            <Section
              title="Batches"
              spaced
              extra={<FilterControls onChange={onFilter} />}>
              <BatchesList
                error={error}
                data={data}
                isLoading={loading}
                onChangePage={onPaginationChange}
                currentPage={currentPage} />
            </Section>
          </ProfileVerifierProvider>
        </SidebarLayout>
      </BatchModalProvider>
    </CloneBatchModalProvider>
  );
}
