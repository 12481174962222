import React, { useContext, useState, useEffect } from "react";
import { Form, Radio } from "antd";
import {
  EditButton,
  MissingProfileDataAlert,
  Section,
  Visibility
} from "../../../";
import {
  EditProfileContext,
  CurrentUserContext
} from "../../../../../contexts/shared";
import { TecRelationshipForm } from "../form";
import { formatValues } from "./format-values";
import { ActionButtonForm } from "../../action-button-form";
import { getUncompletedEntrepreneurProfileData } from "../../../../../helpers";
import { UsageTrackingService } from "../../../../../services";

export function TecRelationshipEditSection({ editable = false }) {
  const { currentUser, refetchCurrentUser } = useContext(CurrentUserContext);
  const [state, setState] = useState({ hasRelationship: null });
  const [stateForm, setStateForm] = useState(null);
  const [form] = Form.useForm();

  const {
    saveChanges,
    editing,
    setEditing,
    data
  } = useContext(EditProfileContext);

  useEffect(() => {
    if(data?.user) {
      setState({ hasRelationship: data.user?.hasTecRelationship });
    }
  }, [data]);

  const onSave = async () => {
    await stateForm?.validateFields();
    const values = form.getFieldsValue();
    const oldtecRelationships = data?.user?.tecRelationships || [];

    const formatedData = formatValues(
      values.tecRelationships,
      oldtecRelationships,
      state.hasRelationship
    );

    formatedData.hasTecRelationship = state.hasRelationship;

    const newFields = values?.tecRelationships
      ?.reduce((acc = {}, relationship) => {
        if (!acc.enrollment)
          acc.enrollment = relationship.enrollment;
        if (!acc.payroll)
          acc.payroll = relationship.payroll;

        acc.types = [relationship.type, ...(acc.types || [])]
          .filter(i => !!i);
        acc.campuses = [relationship.campus, ...(acc.campuses || [])]
          .filter(i => !!i);
        acc.careers = [relationship.careerName, ...(acc.careers || [])]
          .filter(i => !!i);
        return acc;
    }, {});

    UsageTrackingService.updateRegisteredUser({
      id: currentUser.id,
      ...newFields
    }).catch(console.error);

    await saveChanges(formatedData, "tecRelationship");
    await refetchCurrentUser();
  };

  const onSwitchChange = ({ target: { value } }) => {
    setState({ hasRelationship: value  });
  };

  const missingData = getUncompletedEntrepreneurProfileData(data?.user);


  return (
    <Section
      spaced
      title={<MissingProfileDataAlert
        missingInfo={missingData.tecRelationship}>
        Vínculo con el Tec de Monterrey
      </MissingProfileDataAlert>}
      extra={
        <Visibility visible={editable && !editing?.tecRelationship}>
          <EditButton
            onClick={() => setEditing(true, "tecRelationship")}>
            Editar
          </EditButton>
        </Visibility>
      }
      bottom={
        <Visibility visible={editable && editing?.tecRelationship}>
          <ActionButtonForm
            setEditing={setEditing}
            formName="tecRelationship"
            onSave={onSave} />
        </Visibility>}>
      <Section
        title={currentUser.systemRole === "ENTREPRENEUR" ?
          "¿Eres Familia Tec? Proporciónanos tu matrícula y/o nómina"
        : "¿Tiene vínculo con el Tec de Monterrey?"}
        key="toggable-section"
        headStyle={{ borderBottom: "none" }}
        extra={
          <Radio.Group
            value={state.hasRelationship}
            disabled={!editing.tecRelationship || !editable}
            onChange={onSwitchChange}>
            <Radio value={1}>Si</Radio>
            <Radio value={0}>No</Radio>
          </Radio.Group>
        }>
        <Visibility visible={state.hasRelationship === 1}>
          <TecRelationshipForm
            form={form}
            stateForm={stateForm}
            setStateForm={setStateForm} />
        </Visibility>
      </Section>
    </Section>
  );
}
