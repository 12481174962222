import React, { useContext } from "react";
import { List } from "antd";
import {
  OdeUpdateCommentsModalContext,
  OdeUpdateModalContext
} from "../../../contexts/shared";
import { OdeUpdateListItem } from "./item";

/**
 * @param {object} props
 * @param {any[]} props.odeUpdates
 * @param {boolean} props.loading
 */
export function OdeUpdatesList({
  odeUpdates,
  loading = false
}) {
  const {
    openModal: openDetailsModal
  } = useContext(OdeUpdateModalContext);
  const {
    openModal: openCommentsModal
  } = useContext(OdeUpdateCommentsModalContext);

  return (
    <List
      loading={loading}
      itemLayout="horizontal"
      dataSource={odeUpdates}
      rowKey={odeUpdate => odeUpdate.id}
      renderItem={odeUpdate =>
        <OdeUpdateListItem
          openCommentsModal={openCommentsModal}
          openDetailsModal={openDetailsModal}
          odeUpdate={odeUpdate} />} />
  );
}
