import React, { useContext, useState } from "react";
import { Button, Card, message, Space, Typography, Form } from "antd";
import moment from "moment";
import { Format, CoverImage, EditableLabel, Visibility } from "../../../../shared";
import { useDecorator } from "../../../../../helpers/use-decorator";
import { programTypes } from "../../../../../helpers/select-options/program";
import {
  BatchDetailsContext
} from "../../../../../contexts/admin/batches/batch-details/context";
import { useMutation } from "@apollo/react-hooks";
import { admin } from "../../../../../graphql/admin";
import { client } from "../../../../../../graphql";
import { CurrentUserContext } from "../../../../../contexts/shared";
import { EditableField } from "./editable-field";
import Breadcrumbs from "../breadcrumbs";

const containerStyle = {
  display: "flex",
  justifyContent: "space-between"
};

/**
*@todo: Move component to shared components module
*/

export function Summary() {
  const { data, loading } = useContext(BatchDetailsContext);
  const batch = useDecorator("batch", data?.batch);
  const [updateBatch] = useMutation(admin.mutations.updateBatch, { client });
  const { currentUser } = useContext(CurrentUserContext);
  const [state, setState] = useState({ editing: false });
  const [form] = Form.useForm();

  const onChange = newData => {
    setState(state => ({ ...state, formData: newData }));
  };

  const onSave = async () => {
    const formData = form.getFieldsValue();

    if (formData.name?.length > 0  && formData.description?.length > 0) {

      if (formData.coordinators) {
        formData.users = { set: formData.coordinators.map(id => ({ id })) };
        delete formData.coordinators;
      }

      if (formData.program) {
        formData.program = { connect: { id: formData.program } };
      }

      if (formData.period) {
        formData.startDate = formData.period[0].format();
        formData.finishDate = formData.period[1].format();
        delete formData.period;
      }

      try {
        await updateBatch({
          variables: {
            where: { id: batch.id },
            data: formData
          },
          refetchQueries: [
            {
              query: admin.queries.getBatchById,
              variables: {
                id: batch.id
              }
            }
          ],
          awaitRefetchQueries: true
        });

        setState({ ...state, editing: false, formData: undefined });
      } catch (error) {
        if (error.message.match(/NAME_ALREADY_TAKEN/)) {
          return message.warning(`Ya existe un batch con el nombre\
           "${formData.name}". Por favor, ingresa un nombre diferente.`);
        }

        message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      }
    } else {
      message.warning("Los campos de nombre y/o descripción están vacíos");
    }
  };

  const onEdit = () => {
    if (state.editing) {
      onSave();
    }
    else {
      form.setFieldsValue({
        name: batch?.object.name,
        program: batch?.object?.program?.id,
        description: batch?.object?.description,
        period: [moment(batch?.object?.startDate), moment(batch?.object?.finishDate)]
      });
      setState({ editing: !state.editing });
    }
  };

  const canEdit = ["SUPER_ADMIN", "COORDINATOR"].includes(currentUser.systemRole);

  return (
    <>
      <Breadcrumbs extra={
        <Visibility
          visible={!["ENTREPRENEUR"]
            .includes(currentUser.systemRole)}>
          <Button
            onClick={onEdit}
            type="primary">
            {state.editing ? "Guardar" : "Editar"}
          </Button>
        </Visibility>
      } />
      <Card
        loading={loading}
        className="batch-summary"
        bordered={false}
        style={{ borderBottom: "solid 1px #f4f4f4" }}>
        <EditableLabel
          editing={state.editing}
          hideEditButton
          onChange={onChange}
          editableField={onChangeHandler =>
            <EditableField form={form} />
          }
          editable={canEdit}>
          <Space
            direction="horizontal"
            size={20}
            align="start"
            style={containerStyle}>
            <Space direction="vertical" size={10}>
              <Typography.Title
                level={4}
                style={{ marginBottom: "0px" }}>
                {batch.name}
              </Typography.Title>
              <Typography.Text
                level={4}
                style={{ marginBottom: "0px" }}>
                Programa:&nbsp;
                {batch?.object?.program?.name}
              </Typography.Text>
              <Typography.Text>
                {batch.description}
              </Typography.Text>
              <Format type="tag" options={programTypes}>
                {batch?.object?.program?.type}
              </Format>
              <Typography.Text>
                Del&nbsp;{batch.period}
              </Typography.Text>
              <Typography.Text>
                Coordinadores:&nbsp;{batch?.coordinators}
              </Typography.Text>
            </Space>
            <CoverImage url={batch.coverPicUrl} text={batch.name} />
          </Space>
        </EditableLabel>
      </Card>
    </>
  );
}
