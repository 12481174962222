import React from "react";

/**
 * Allows to align its content
 * @param {object} props
 * @param {"horizontal" | "vertical"} props.direction "horizontal" | "vertical"
 * @param {"start" | "center" | "end"} props.yAlign "start" | "center" | "end"
 * @param {"start" | "center" | "end"} props.xAlign "start" | "center" | "end"
 * @param {string} props.justifyContent Optional. Will override calculated
 * @param {string} props.padding Optional. Will override default
 * @param {object} props.style Optional. Will override default
 * @param {string} props.className Optional styles
 *
 * justify-content if present
 */
export function FlexContainer({
  children,
  direction = "horizontal",
  yAlign = "start",
  xAlign = "start",
  justifyContent,
  padding,
  style = {},
  className
}) {
  const flexAlignValues = {
    start: "flex-start",
    center: "center",
    end: "flex-end"
  };

  const showInColumn = direction === "vertical";

  const flexStyle = {
    width: "100%",
    display: "flex",
    flexDirection: showInColumn ? "column" : "row",
    justifyContent: justifyContent
      || (flexAlignValues[showInColumn ? yAlign : xAlign]),
    alignItems: flexAlignValues[showInColumn ? xAlign : yAlign],
    padding
  };

  return (
    <div
      style={{ ...flexStyle, ...style }}
      className={className}>
      {children}
    </div>
  );
}
