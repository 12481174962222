import { entrepreneur } from "../../../../graphql/entrepreneur";

export async function create(data, expertServiceId, odeId, userId) {
  data.expertService = { connect: { id: expertServiceId } };
  data.ode = { connect: { id: odeId } };
  data.requestedBy = { connect: { id: userId } };

  return await this.client.mutate({
    mutation: entrepreneur.mutations.createScheduledExpertService,
    variables: { data }
  });
}
