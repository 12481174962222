import { client } from "../../../graphql";
import { GET_ORGANIZATIONS_BY_FILTERS } from "./graphql/get-organizations";

async function getOrganizationsByFilters(params = {}) {
  const result = await client.query({
    query: GET_ORGANIZATIONS_BY_FILTERS,
    variables: {
      searchText: params.searchText,
      origin: params.origin,
      states: params.states,
      services: params.services,
      stages: params.stages,
      ranByWomen: params.ranByWomen,
      skip: params.skip,
      first: params.first
    },
    fetchPolicy: "network-only"
  });
  return result?.data;
}

export const organizationsService = {
  getOrganizationsByFilters
}