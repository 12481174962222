/**
 * @todo Move this to a shared patH because it is used in multiple places
 */
export const productStageOptions = [
  { label: "Idea", value: "IDEA" },
  { label: "Prueba de concepto", value: "CONCEPT" },
  { label: "Producto mínimo viable (MVP)", value: "MVP" },
  { label: "Prototipo funcional", value: "PROTOTYPE" },
  { label: "Versión de prueba", value: "TEST" },
  { label: "Producto terminado", value: "FINISH" }
];
