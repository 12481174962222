import React, { useContext } from "react";
import { Loader, PublishBatchLearn, PublishBatchTeam } from "../../../../../../shared";
import { getBatchTeam } from "../../../../../../../helpers";
import { BatchOdesContext } from "../../../../../../../contexts/admin/batches";

export const BatchPublicInformation = ({ batch }) => {
  const {
    data: customContents,
    loading,
    error,
    published: publishContents
  } = useContext(BatchOdesContext);

  const teams = getBatchTeam(batch)?.filter(el => el.systemRole !== "ADVISER");
  const [publishContent] = publishContents?.publishedContents || [];
  const [customContent] = customContents?.customOdeContents || [];
  const {
    contentGroups: publishContentGroups = [],
    contentGroupsSort: publishContentGroupsSorts = []
  } = publishContent?.masterContent || {};
  const {
    contentGroups: customContentGroups = [],
    contentGroupsSort: customContentGroupsSorts = []
  } = customContent || {};
  const contentGroups = [...publishContentGroups, ...customContentGroups];
  const sort = [...publishContentGroupsSorts, ...customContentGroupsSorts];

  return (
    <Loader
      data={contentGroups}
      loading={loading}
      error={error}>
      <PublishBatchLearn
        style={{ paddingTop: 0 }}
        className="NewBatch__Publish-Learn"
        contentGroups={contentGroups}
        sort={sort}
        hideAlertContent={true} />
      <PublishBatchTeam
        className="NewBatch__Publish-Team"
        teams={teams}
        leaderId={batch?.leader}
        showAddTeamButton={false} />
    </Loader>
  );
};
