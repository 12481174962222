import React, { useContext } from "react";
import { Table } from "antd";
import { PaginationContext } from "../../../../contexts/shared";
import { Format, TagList } from "../../../shared";
import { paginationConfig } from "../../../../helpers";
import { COLORS_TAGS } from "../../../../helpers/select-options";
import { ModalButton } from "../../../shared/buttons";
import { OrganizationDetailModal } from "../../shared";
import { EyeOutlined } from "@ant-design/icons";

export function OrganizationsTable({
  loading = false,
  data = [],
  currentPage,
  onChangePage,
  totalRecordsCount,
  paginationRefetchName,
  tableType = null,
  allRefetch
}) {
  const { onPaginationChange } = useContext(PaginationContext);

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange(paginationRefetchName)(page, pageSize);
  };

  return (
    <Table
      loading={loading}
      scroll={{ x: true }}
      style={{ width: "100%" }}
      dataSource={data?.organizations}
      rowKey={(row, index) => row?.id || index}
      pagination={{
        ...paginationConfig,
        current: currentPage,
        defaultPageSize: 12,
        onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
        total: totalRecordsCount
      }}>
      <Table.Column
        title="Nombre de la organización"
        width={350}
        render={({ name }) => name } />
      <Table.Column
        title="Sitio web"
        width={400}
        render={({ website }) => website } />
      {tableType && (
        <Table.Column
          title="Aprobado por"
          width={400}
          render={({ reviewedBy }) => reviewedBy?.fullName } />
      )}
      <Table.Column
        width={200}
        title="Fecha de la solicitud"
        render={({ createdAt }) =>
          <Format type="date">{createdAt}</Format> } />
      <Table.Column
        title="Tipo"
        width={300}
        render={({ stages }) =>
          <TagList
            className="tags"
            maxCount={2}
            colors={COLORS_TAGS}
            mapColors={true}
            tags={stages} /> } />
      <Table.Column
        title="Servicios"
        width={400}
        render={({ services }) => services?.join(", ") } />
      <Table.Column
        align="center"
        width={200}
        render={({ id }) =>
          <ModalButton
            type="link"
            key="btn-details"
            icon={<EyeOutlined />}
            title="Ver solicitud"
            item={id}
            modalProps={{
              allRefetch
            }}
            modal={OrganizationDetailModal} />
        } />
    </Table>
  );
}


