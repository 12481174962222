import gql from "graphql-tag";

export const updateProfile = gql`
  mutation UpdateProfile(
    $id: ID!
    $data: UserUpdateInput!
  ) {
    updateUser(
      where: {
        id: $id
      },
      data: $data
    ) {
      id
    }
  }
`;
