import React from "react";
import { Button } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";

export const ControllerButtons = ({
  loading,
  onNext,
  onPrev,
  publish = false
}) => {
  const color = "#5F39DE";
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        margin: "31px"
      }}>
      {
        onPrev
          ?
            <Button
              loading={loading}
              onClick={ onPrev }
              style={{ width: "135px", color, borderColor: color }}>
              <ArrowLeftOutlined />
              Anterior
            </Button>
          : <></>
      }
      {
        onNext
          ?
            <Button
              type="primary"
              loading={loading}
              onClick={ onNext }
              style={{ width: "135px" }}>
              {publish ? "Publicar" : "Siguiente"}
              <ArrowRightOutlined />
            </Button>
          : <></>
      }
    </div>
  );
};
