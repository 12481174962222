import gql from "graphql-tag";

export const getAchievements = gql`
  query GetAchievements($where: AchievementWhereInput) {
    achievements(where: $where){
      id
      name
    }
  }
`;
