import gql from "graphql-tag";

export const getExpertsWithCoordSegmentation = gql`
  query GetExpertsWithCoordSegmentation (
    $ifullName: String,
    $workspace: WorkspaceMembershipWhereInput,
    $first: Int,
    $skip: Int,
    $batchesIds: [ID!]
  ) {
    users(
      where: {
        OR: [
          {
            ifullName_contains: $ifullName
            systemRole: ADVISER,
            memberships_some: $workspace
            NOT: {
              expertProfile: {
                private: true
              }
            }
          },
          {
            ifullName_contains: $ifullName
            systemRole: ADVISER,
            memberships_some: $workspace
            expertProfile: {
              private: true,
              privateBatches_some: { id_in: $batchesIds }
            }
          }
        ]
      },
      skip: $skip,
      first: $first
    ) {
      id
      fullName
      email
      phone
      profilePicUrl
      expertProfile {
        id
        resume
      }
    }
    meta: usersConnection(
      where: {
        ifullName_contains: $ifullName
        systemRole: ADVISER,
        memberships_some: $workspace
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;