import React, { useState } from "react";
import { AssignedExpertServiceModalContext } from "./context";
import { AssignedExpertServiceModal } from "./modal";

export function AssignedExpertServiceModalProvider({ children }) {
  const [state, setState] = useState({
    isModalOpen: false,
    expertService: undefined
  });

  const onSaveListeners = [];

  const openModal = (expertService = {}) => {
    setState({
      isModalOpen: true,
      expertService
    });
  };

  const closeModal = () => {
    setState({
      isModalOpen: false,
      expertService: undefined
    });
  };

  const onSave = (data = {}) => {
    for (const key in onSaveListeners) {
      const listener = onSaveListeners[key];
      listener({
        expertService:  { connect: { id: state.expertService?.id } },
        ...data
      });
    }
    closeModal();
  };

  const addOnSaveListener = (listenerFunc, key) => {
    if (!key) {
      onSaveListeners.push(listenerFunc);
    } else {
      onSaveListeners[key] = listenerFunc;
    }
  };

  const updateAssignExpertService = (values = {}) => {
    setState(prevState => ({
      ...prevState,
      expertService: {
        ...prevState.expertService || {},
        ...values
      }
    }));
  };

  const injectActions = {
    openModal,
    closeModal,
    addOnSaveListener,
    updateAssignExpertService
  };

  return (
    <AssignedExpertServiceModalContext.Provider value={injectActions}>
      <AssignedExpertServiceModal
        visible={state.isModalOpen}
        expertService={state.expertService}
        onCancel={closeModal}
        onSave={onSave} />
      { children }
    </AssignedExpertServiceModalContext.Provider>
  );
}

