import React from "react";
import { Section } from "../../../../shared";
import { withLoader } from "../../../../../helpers";

function PendingServices() {
  return (
    <Section
      title="Pendientes">
      <div>No hay pendientes por el momento</div>
    </Section>
  );
}

export default withLoader(PendingServices);
