import React from "react";
import { Menu } from "antd";
import { withRouter } from "../../../../../../helpers";

function Navbar({ navigate, selectedKeys, ...props }) {
  return (
    <Menu
      defaultOpenKeys="achievements"
      selectedKeys={selectedKeys}
      style={{ borderBottom: "none" }}
      mode="horizontal"
      {...props}>
      <Menu.Item
        onClick={() => navigate("./achievements")}
        key="achievements">
        Logros
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate("./contents")}
        key="contents">
        Contenido
      </Menu.Item>
    </Menu>
  );
}

export default withRouter(Navbar);
