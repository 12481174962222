import { Card, Form, Button, Checkbox } from "antd";
import React from "react";

export function ExportFilters({ onOk }) {
  const [form] = Form.useForm();

  const handleOnOk = () => {
    const values = form.getFieldsValue();

    onOk && onOk(values);
  };

  return (
    <Card>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          name="markAsPaid"
          getValueFromEvent={e => e.target.checked}>
          <Checkbox>
            Marcar como pago en proceso (solo pago autorizado)
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            onClick={handleOnOk}
            type="primary">
            Aplicar
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
