import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { MenuContext, EditProfileProvider, PageLeavePromptProvider } from "../../../../contexts/shared";
import { MainMenu } from "../../../../components/coordinator/shared";
import { SidebarLayout } from "../../../../components/shared";
import { GeneralProfileEntrepreneur } from "../../../../components/shared";

export default function ProfileOcupationIndex() {
  const { id } = useParams();
  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: ["entrepreneurs"],
    selectedKeys: ["entrepreneurs/profiles"]
  });

  return (
    <PageLeavePromptProvider>
      <EditProfileProvider userId={id}>
        <SidebarLayout menu={<MainMenu />}>
          <GeneralProfileEntrepreneur />
        </SidebarLayout>
      </EditProfileProvider>
    </PageLeavePromptProvider>
  );
}
