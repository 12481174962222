import gql from "graphql-tag";

export const getCompanyBatchesByCoordinator = gql`
  query GetBatchesByOdeId(
      $companyId: ID!
      $coordId: ID!
    ){
      batches(
        where:{
          users_some: {
            systemRole: COORDINATOR,
            id: $coordId
          }
          odes_some: { 
            id: $companyId
          }
        }
      ){
        id    
        name
        iname
        batchID
        startDate
        finishDate
        description
        program {
          id
          name
          type
          coverPicUrl
        }
      }
    }
  `;
