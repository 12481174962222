import { CAREERS } from "../careers";
import { INDUSTRIES } from "../industries";
import { ODE_SECTORS } from "../ode-sectors";
import { EDUCATION_INSTITUTIONS } from "../education-institutions";
import { TEC_RELATIONSHIP_TYPES } from "../tec-relationship-types";

/**
 * @type {{[key in string]: EnumerationItem[]}}
 */
const availableEnums = {
  CAREERS,
  INDUSTRIES,
  ODE_SECTORS,
  EDUCATION_INSTITUTIONS,
  TEC_RELATIONSHIP_TYPES
};

/**
 * @param {EnumerationItem[] | EnumerationName} enumeration
 * @param {string} value
 * @param {string} defaultValue
 * @return {string}
 */
export const getReadableValue = (
  enumeration = [],
  value,
  defaultValue = "N/A"
) => {
  // some data have dirty spaces or comes null
  const trimmedValue = typeof value === "string" ? value.trim() : value;

  const options = typeof enumeration === "string"
    ? availableEnums[enumeration]
    : enumeration;

  return options.find(item => (
    item.value === trimmedValue || item.legacyValues?.includes(trimmedValue)
  ))?.label || defaultValue;
};

/**
 * @typedef EnumerationItem
 * @property {string} value
 * @property {string} label
 * @property {string[] | undefined} legacyValues
 */

/**
 * @typedef {
    "CAREERS"
    | "EDUCATION_INSTITUTIONS"
    | "TEC_RELATIONSHIP_TYPES"
    | "INDUSTRIES"
    | "ODE_SECTORS"
 * } EnumerationName
 */
