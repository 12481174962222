import React, { useContext, useState, useEffect } from "react";
import { Form, Input, Descriptions, Select } from "antd";
import { EditProfileContext } from "../../../../../contexts/shared";
import { FormContainer } from "../../../";
import { fieldValidationMessages } from "../../../../../utils";
import {
  getReadableValue,
  OCCUPATIONS
} from "../../../../../helpers";

export const OccupationForm = ({ editable = false, form }) => {
  const { data } = useContext(EditProfileContext);

  const [state, setState] = useState({
    specialtyArea: "",
    occupation: "",
    companies: ""
  });

  useEffect(() => {
    const occupation =
      OCCUPATIONS.some(el => el.value === data?.user?.occupation);

    const occupationsFields = {
      specialtyArea: data?.user?.specialtyArea || "",
      occupation: occupation ? data?.user?.occupation : "",
      companies: data?.user?.companies || []
    };

    setState(occupationsFields);

    form.setFieldsValue({ ...occupationsFields });
  }, [data]);

  if (!editable) {
    return (
      <FormContainer>
        <Descriptions
          column={3}
          layout="vertical"
          title={getReadableValue(OCCUPATIONS, state.occupation)}>
          <Descriptions.Item
            label="Institución / Empresa"
            labelStyle={{ fontWeight: "bold" }}>
            {state.companies.toString()}
          </Descriptions.Item>
          <Descriptions.Item
            label="Carrera o aréa de especialidad"
            labelStyle={{ fontWeight: "bold" }}>
            {state.specialtyArea}
          </Descriptions.Item>
        </Descriptions>
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <Form
        validateMessages={fieldValidationMessages}
        layout="vertical"
        form={form}>
        <Form.Item
          rules={[{ required: true }]}
          name="occupation"
          label="Ocupación actual">
          <Select options={OCCUPATIONS} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="companies"
          label="Institución / Empresa">
          <Select
            placeholder="Mi empresa"
            mode="tags"
            tokenSeparators={[","]}
            notFoundContent={null}
            dropdownStyle={{ display: "none" }} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="specialtyArea"
          label="Carrera o aréa de especialidad">
          <Input />
        </Form.Item>
      </Form>
    </FormContainer>
  );
};
