import gql from "graphql-tag";

export const createAssignedExpertService = gql`
  mutation CreateAssignedExpertService(
    $data: AssignedExpertServiceCreateInput!
  ) {
    createAssignedExpertService(data: $data) {
      id
      limitDate
      assignmentDate
      assignedUnits
    }
  }
`;
