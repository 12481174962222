import { client } from "../../../../graphql";
import {
  approve,
  reject,
  deliver,
  create,
  acceptDelivery,
  rejectDelivery,
  getById,
  createMany,
  updatePaymentStatus,
  cancel,
  refundAssignedUnits
} from "./methods";

/**
 * @deprecated Use the new pure functions approach
 */
export class ScheduledExpertServiceService {
  constructor() {
    this.client = client;
    this.approve = approve;
    this.reject = reject;
    this.deliver = deliver;
    this.create = create;
    this.acceptDelivery = acceptDelivery;
    this.rejectDelivery = rejectDelivery;
    this.createMany = createMany;
    this.updatePaymentStatus = updatePaymentStatus;
    this.cancel = cancel;
    this.refundAssignedUnits = refundAssignedUnits;
    this.getById = getById;
  }
}
