import gql from "graphql-tag";

export const deleteObjectiveAchievement = gql`
  mutation DeleteObjectiveAchievement($objectiveId: ID!, $achievementId: ID!) {
    updateObjective(
      where: { id: $objectiveId }
      data: {
        achievements: {
          delete: {
            id: $achievementId
          }
        }
      }
    ) {
      id
    }
  }
`;
