
export function fileTypeToColor(type) {
  switch (type) {
    case "docx":
      return "blue";
    case "pdf":
      return "red";
    case "pptx":
      return "lightred";
    case "xlsx":
      return "green";
    case "png":
      return "orange";
    case "jpg":
      return  "orange";
    case "jpeg":
      return "orange";
    case "link":
      return "blue";
    default:
      return "lightgrey";
  }
}