import React from "react";
import { Button } from "antd";
import { FlexContainer } from "../../../../../shared";

export function Footer({
  onCancelScheduledService
}) {
  return (
    <FlexContainer
      direction="horizontal"
      xAlign="end">
      <Button
        type="danger"
        onClick={onCancelScheduledService}>
        Cancelar solicitud
      </Button>
    </FlexContainer>
  );
}
