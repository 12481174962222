import React, { useContext } from "react";
import { Select, Typography } from "antd";
import { useQuery} from "@apollo/react-hooks";
import { client } from "../../../../../graphql";
import { entrepreneur } from "../../../../graphql/entrepreneur";
import { CurrentUserContext } from "../../../../contexts/shared";

export function BatchesSelect({ onChange, ...props }) {
  const { currentUser } = useContext(CurrentUserContext);

  const { loading, data, error } = useQuery(
    entrepreneur.queries.getBatchesByEntrepreneurId,
    { client, variables: { id: currentUser.id } }
  );

  return (
    <>
      <Select
        data-cy="batch-select"
        placeholder="Seleccionar batch"
        optionFilterProp="label"
        allowClear
        showSearch
        disabled={loading || error}
        loading={loading}
        onChange={onChange}
        options={data?.batches.map(el => ({ label: el.name, value: el.id }))}
        {...props} />
      <Typography.Text type="danger">
        {error && "No se pudieron cargar los programs"}
      </Typography.Text>
    </>
  );
}
