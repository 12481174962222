import React, { useContext } from "react";
import { Form } from "antd";
import { Section, Visibility } from "../../../";
import { ActionButton } from "../../action-button";
import { CoursesForm } from "../form";
import {
  EditProfileContext
} from "../../../../../contexts/shared/edit-profile";
import { formatValues } from "./format-values";

export function CoursesEditSection({ editable = false }) {
  const {
    saveChanges,
    editing,
    setEditing,
    data
  } = useContext(EditProfileContext);

  const oldCourses = data?.user?.expertProfile?.professionalExperience?.courses || [];

  const onSave = () => {
    const { courses } = form.getFieldsValue();
    const data = formatValues(courses, oldCourses);

    saveChanges(data, "courses");
  };

  const [form] = Form.useForm();

  return (
    <Section
      spaced
      title="Cursos y certificaciones"
      bottom={
        <Visibility visible={editable}>
          <ActionButton
            editing={editing.courses}
            setEditing={setEditing}
            formName="courses"
            visible
            onSave={onSave} />
        </Visibility>}>
      <CoursesForm
        form={form}
        editable={editing.courses && editable} />
    </Section>
  );
}
