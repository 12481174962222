import { buildTableRows } from "./build-table-rows";
import { buildContentColumns } from "./build-content-columns";

export const buildColumnsAndRows = ({
  companies,
  batchProgress,
  customOdeContents,
  batchContentSections,
  type
}) => {
  const customCompaniesContentsMap = customOdeContents
    .reduce((acc, customOdeContent) => {
      const companyId = customOdeContent.ode.id;
      acc[companyId] = customOdeContent.contentGroups;
      return acc;
    }, {});

  const [
    batchContentColumns,
    collectedBatchContents
  ] = buildContentColumns(batchContentSections, type);

  const customContentColumns = [];
  const collectedCustomContents = [];

  Object.entries(customCompaniesContentsMap)
    .forEach(([companyId, sections]) => {
      const [contentColumns, collectedContents] = buildContentColumns(
        sections,
        type,
        companyId
      );

      customContentColumns.push(...contentColumns || []);
      collectedCustomContents.push(...collectedContents || []);
  });

  const columns = (batchContentColumns || [])
    .concat(customContentColumns || []);

  const collectedContents = (collectedBatchContents || [])
    .concat(collectedCustomContents || []);

  const allContentsMap = collectedContents
    .reduce((acc, content) => {
      const isCustomContent = !!content.belongsToCompany;
      acc[content.id] = {
        ...content,
        status: isCustomContent
          ? "CUSTOM_CONTENT_DOESNT_BELONG_TO_COMPANY"
          : "PENDING",
        belongsToCompany: content.belongsToCompany
      };
      return acc;
    }, {});

  const rows = buildTableRows(companies, batchProgress, allContentsMap);

  return { columns, rows };
};
