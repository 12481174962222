import React from "react";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Format } from "../../../../../../shared/format";
import { contentTypes, entrepreneurContentTypes } from '../../../../../../../helpers/select-options/content';

export const columns = [
  {
    title: "Contenido",
    dataIndex: "name",
    key: "name",
    render: text => <p>{text}</p>,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend']
  },
  {
    title: "Tipo",
    dataIndex: "type",
    key: "type",
    render: type => <Format
      type="tag"
      options={contentTypes}>
      {type}
    </Format>
  },
  {
    title: "Objetivo",
    dataIndex: "objective",
    key: "objetive",
    render: text => <p>{text}</p>
  },
  {
    title: "Fecha límite",
    dataIndex: "deliveryDate",
    key: "deliveryDate",
    render: date => <Format type="date">{date}</Format>,
    sorter: (a, b) => a.deliveryDate < b.deliveryDate,
    sortDirections: ['descend']
  },
  {
    title: "Fecha cumplimiento",
    dataIndex: "finishDate",
    key: "finishDate",
    render: date => <Format type="date">{date}</Format>,
    sorter: (a, b) => a.finishDate < b.finishDate,
    sortDirections: ['descend']
  },
  {
    title: "Estado",
    dataIndex: "isComplete",
    key: "isComplete",
    render: completed => <CheckCircleTwoTone
      twoToneColor={completed ? "#52c41a" : "#D7D7D7"} />
  }
];

export const entrepreneurColumns = [
  {
    title: "Contenido",
    dataIndex: "name",
    key: "name",
    render: text => <p>{text}</p>,
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ['descend']
  },
  {
    title: "Tipo",
    dataIndex: "type",
    key: "type",
    render: type => <Format
      type="tag"
      options={entrepreneurContentTypes}>
      {type}
    </Format>
  },
  {
    title: "Objetivo",
    dataIndex: "objective",
    key: "objetive",
    render: text => <p>{text}</p>
  },
  {
    title: "Fecha límite",
    dataIndex: "deliveryDate",
    key: "deliveryDate",
    render: date => <Format type="date">{date}</Format>,
    sorter: (a, b) => a.deliveryDate < b.deliveryDate,
    sortDirections: ['descend']
  },
  {
    title: "Fecha cumplimiento",
    dataIndex: "finishDate",
    key: "finishDate",
    render: date => <Format type="date">{date}</Format>,
    sorter: (a, b) => a.finishDate < b.finishDate,
    sortDirections: ['descend']
  },
  {
    title: "Estado",
    dataIndex: "isComplete",
    key: "isComplete",
    render: completed => <CheckCircleTwoTone
      twoToneColor={completed ? "#52c41a" : "#D7D7D7"} />
  }
];