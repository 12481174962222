import React from "react";
import { Typography } from "antd";

export const SubTitle = ({ children }) => (
  <Typography.Title
    level={5}
    style={{
      color: "#586472",
      fontWeight: 400
    }}>
    {children}</Typography.Title>
);