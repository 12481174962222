import React, { useState, useMemo, useContext } from "react";
import { Form, message } from "antd";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import { admin } from "../../../../../graphql/admin";
import { shared } from "../../../../../graphql/shared";
import { Section } from "../../../../../components/shared";
import { StepsWrapper } from "../../../../../components/expert/shared";
import { NewExpertContext } from "../../../../../contexts/experts/new/context";
import {
  AccountsForm
} from "../../../../../components/expert/new/id/accounts";
import {
  ExpertServiceContext
} from "../../../../../contexts/experts/new/service/context";

const Accounts = ({ history, match }) => {
  const { ExpertsService } = useContext(ExpertServiceContext);
  const [form] = Form.useForm();
  const userId = match?.params?.userId || "";

  const [state, setState] = useState({
    busy: false,
    accounts: {}
  });

  const { loading, error, data } = useQuery(
    shared.queries.getExpertById,
    {
      client,
      variables: { id: userId }
    });

  const [updateExpertProfile] = useMutation(
    admin.mutations.updateExpertProfile,
    {
      client,
      refetchQueries: [{
        query: shared.queries.getExpertById,
        variables: { id: userId }
      }]
    }
  );

  const updateAccounts = accounts => {
    ExpertsService.setUpdateAccounts(accounts, state, setState);
  };

  const save = async () => {
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      const dataToSave = { expertProfile: { update: { ...state.accounts } } };
      await ExpertsService.setSave(
        dataToSave, updateExpertProfile, userId);

      message.success("Datos guardados");
      history.push(`/v2/expert/new/${userId}/entrepreneurships`);
    } catch (error) {
      console.error(error);
      message.error("Ocurrió un error");
    } finally {
      dismissLoader();
    }
  };

  const injectActions = useMemo(() => ({
    updateAccounts,
    save,
    loading,
    error,
    data,
    form
  }), [state, loading]);

  const handleNext = async event => {
    event.preventDefault();
    try {
      await form.validateFields();
    } catch (error) {
      console.error(error);
      return message.warning("Campos requeridos faltantes.");
    }
    await save();
  };

  return (
    <NewExpertContext.Provider value={injectActions}>
      <StepsWrapper
        onNext={handleNext}
        prevUrl={`/v2/expert/new/${userId}/professional-information`}
        currentStep={2}>
        <Section
          title="Redes sociales">
          <AccountsForm />
        </Section>
      </StepsWrapper>
    </NewExpertContext.Provider>
  );
};

export default withRouter(Accounts);
