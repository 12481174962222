export const CONTENT_TYPES = {
  ACTIVITY: { color: "#13C2C2", label: "Actividad" },
  RESOURCE: { color: "#EB2F96", label: "Recurso" },
  WORKSHOP: { color: "#FA8C16", label: "Taller" }
};

export const STATUS_VALUES = {
  PENDING: { color: "#FF7875", label: "Pendiente" },
  COMPLETED: { color: "#5F39DE", label: "Completado" },
  VERIFIED: { color: "#52C41A", label: "Verificado" }
};
