import React from "react";
import { useParams } from "react-router";
import { ObjectiveDetailContext } from "./context";
import { withRouter } from "../../../../../helpers";
import { useQuery } from "@apollo/react-hooks";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql";

function ObjectiveDetailContextProvider({ children }) {
  const { objectiveId = "" } = useParams();

  const { data, loading, error } = useQuery(shared.queries.getObjectiveById, {
    client,
    variables: {
      id: objectiveId
    }
  });

  const injectActions = {
    data,
    loading,
    error
  };

  return (
    <ObjectiveDetailContext.Provider value={injectActions}>
      { children }
    </ObjectiveDetailContext.Provider>
  );
}

export default withRouter(ObjectiveDetailContextProvider);
