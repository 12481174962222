import React, { useContext, useState } from "react";
import { Modal, Form, Button, message } from "antd";
import { CreateOdeModalContext } from "../index";
import { CreateCompanySteps } from "./steps";
import { useCompany } from "../../../../hooks";
import { CompanyGeneralForm } from "../../../../components/shared/company-forms/general";
import { CompanyAboutForm } from "../../../../components/shared/company-forms/about";
import { CompanyContactForm } from "../../../../components/shared/company-forms/contact";
import { CompanyMilestonesForm } from "../../../../components/shared/company-forms/milestone";
import { Visibility } from "../../../../components/shared";
import { formatValues } from "./format-values";
import { useHistory } from "react-router-dom";

const initialState = {
  currentStep: 0,
  data: {
    presenceStates: [],
    socialMedia: [],
    milestones: [],
    techBased: true,
    social: true
  }
};

const nameValidationsInitialState = {
  status: undefined,
  msg: undefined
};

export function CreateOdeModal({ visible = false }) {
  const { onCancel, save } = useContext(CreateOdeModalContext);
  const { validateOriginalName } = useCompany();
  const [state, setState] = useState(initialState);
  const [nameValidations, setNameValidations] = useState(nameValidationsInitialState);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const [form] = Form.useForm();

  const setLogoUrl = url => {
    setState(prev => ({
      ...prev,
      data: {
        ...prev.data,
        logoUrl: url
      }
    }));
  };

  const setSelectedStates = states => {
    setState(prev => ({
      ...prev,
      data: {
        ...prev.data,
        presenceStates: states
      }
    }));
  };

  const setLinks = links => {
    setState(prev => ({
      ...prev,
      data: {
        ...prev.data,
        socialMedia: links
      }
    }));
  };

  const setNewMilestone = milestone => {
    setState(prev => ({
      ...prev,
      data: {
        ...prev.data,
        milestones: prev.data.milestones.concat(milestone)
      }
    }));
  };

  const deleteMilestone = id => {
    setState(prev => ({
      ...prev,
      data: {
        ...prev.data,
        milestones: prev.data.milestones.filter(milestone => milestone.id !== id)
      }
    }));
  };

  const onAboutSwitchChange = (value, switchName) => {
    setState(prev => ({
      ...prev,
      data: {
        ...prev.data,
        [switchName]: value
      }
    }));
  };

  const handleOnCancel = () => {
    setState(initialState);
    form.resetFields();
    onCancel();
  };

  const onReturn = () => {
    setState(prev => ({
      currentStep: prev.currentStep - 1,
      data: {
        ...prev.data
      }
    }));
  };

  const onNext = async () => {
    if (state.currentStep === 2 && !state.data.presenceStates?.length) {
      message.error("Debe de seleccionar al menos una presencia");
      return;
    }
    if (state.currentStep < 3) {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (state.currentStep === 0) {
        setLoading(true);
        const isOriginal = await validateOriginalName({ name: values.name });
        setLoading(false);
        if(!isOriginal) {
          setNameValidations({
            status: "error",
            msg: "El nombre para su compañía ya se encuentra en uso."
          });
          setTimeout(
            () => setNameValidations(nameValidationsInitialState),
            3000
          );
          return;
        }
      }
      setState(prev => {
        const newState = ({
          currentStep: prev.currentStep + 1,
          data: {
            ...prev.data,
            ...values,
            logoUrl: prev.data.logoUrl
          }
        });
        form.setFieldsValue({
          techBased: newState.data.techBased,
          social: newState.data.social
        });
        return newState;
      });
    } else {
      try {
        setLoading(true);
        const formatedData = formatValues(state.data);
        const res = await save(formatedData);
        history.push(`./odes/${res.data.createODE.id}/general-information`);
        onCancel();
      } catch (e) {
        message.error("Sucedio un error, intentelo mas tarde");
      } finally {
        setLoading(false);
      }
    }
  };

  const steps = [
    <CompanyGeneralForm
      key="GENERAL"
      form={form}
      logoUrl={state.data.logoUrl}
      nameValidations={nameValidations}
      setLogoUrl={setLogoUrl} />,
    <CompanyAboutForm
      key="ABOUT"
      form={form}
      companyChecks={state.data}
      onAboutSwitchChange={onAboutSwitchChange} />,
    <CompanyContactForm
      key="CONTACT"
      form={form}
      selectedStates={state.data.presenceStates}
      setSelectedStates={setSelectedStates}
      links={state.data.socialMedia}
      setLinks={setLinks} />,
    <CompanyMilestonesForm
      key="MILESTONE"
      milestones={state.data.milestones}
      setNewMilestone={setNewMilestone}
      deleteMilestone={deleteMilestone} />
  ];

  return (
    <Modal
      visible={visible}
      width="812px"
      onCancel={handleOnCancel}
      footer={[
        <Visibility
          key="cancel"
          visible={state.currentStep > 0}>
          <Button
            className="secundary-btn"
            onClick={onReturn}>
            Regresar</Button>
        </Visibility>,
        <Button
          key="next"
          type="primary"
          loading={loading}
          onClick={onNext}>
          {state.currentStep >= 3 ? "Finalizar" : "Continuar"}</Button>
      ]}
      title={<CreateCompanySteps current={state.currentStep} />}>
      {steps[state.currentStep]}
    </Modal>
  );
}
