import React from "react";
import { Card, Typography } from "antd";
import { FlexContainer, ActionButton } from "../../../../";

export function CardContainer({
   label,
   onClick
 }) {

  return (
    <Card
      bordered={false}
      onClick = {onClick}>
      <div style={{
        width: "100%",
        height: "125px",
        display: "flex",
        borderRadius: "8px",
        flexDirection: "row",
        alignContent: "center",
        border: "1px solid #f0f0f0",
        boxShadow: "2px 2px 2px 1px rgba(0,0,0,0.2)"
      }}>
        <FlexContainer
          direction={"vertical"}
          xAlign="center"
          yAlign="center">
          <ActionButton.New onAdd={() => {} } />
          <Typography.Title
            level={5}>
            {label}
          </Typography.Title>
        </FlexContainer>
      </div>
    </Card>
  );
}
