import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export function AddButton({
  children,
  fullWidth = false,
  ...props
}) {
  if(fullWidth) {
    props.style = { width: "100%", ...props.style };
  }

  return (
    <Button
      type="primary"
      icon={<PlusOutlined />}
      {...props}>{children}</Button>
  );
}
