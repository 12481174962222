import React, { forwardRef } from "react";
import { Button as AButton } from "antd";
import {
  GenericModalContext,
  GenericModalProvider
} from "../../../../contexts/shared/modals";

function ModalButtonComponent({
  button: Button = AButton,
  modalProps,
  modal,
  title,
  item,
  onCustomClick = null,
  ...props
}, ref) {
  return (
    <GenericModalProvider
      ref={ref}
      modal={modal}
      modalProps={modalProps}>
      <GenericModalContext.Consumer>
        {({ openModal }) =>
          <Button
            onClick={() => {
              openModal(item);
              onCustomClick && onCustomClick();
            }}
            {...props}>
            {title}
          </Button>
        }
      </GenericModalContext.Consumer>
    </GenericModalProvider>
  );
}

export const ModalButton = forwardRef(ModalButtonComponent);
