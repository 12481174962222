import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Upload, Space, message } from "antd";
import { Format } from "../format";
import ImgCrop from "antd-img-crop";

function UploadArea({
  children,
  onChange,
  icon = <PlusOutlined style={{fontSize: "24px"}} />,
  style,
  maxFiles,
  value,
  previewImage = true,
  ...props
}) {
  const [state, setState] = useState({
    backgroundImage: undefined,
    fileList: undefined
  });

  const getBGStyle = (imgUrl = "") =>
    setState(prevState => ({
      ...prevState,
      backgroundImage: `url(${imgUrl})`
    }));

  useEffect(() => {
    if (value) {
      getBGStyle(value);
    }
  }, [value]);

  const onUploadChange = info => {
    if(maxFiles && maxFiles > 0) {
      info.fileList = info.fileList.slice(-maxFiles);
    }

    setState(prevState => ({
      ...prevState,
      fileList: [...info.fileList]
    }));

    if(info.file.status === "done") {
      if(previewImage) {
        getBGStyle(info.file.response.imageUrl);
      }
      onChange && onChange(info);
    } else if(info.file.status === "error") {
      message.error("Ha ocurrido un error al subir el archivo, inténtalo de\
      nuevo en unos segundos.");
    }
  };

  return (
    <Upload.Dragger
      className="upload-dragger"
      onChange={onUploadChange}
      action={process.env.REACT_APP_ASSETS_ENDPOINT}
      multiple={false}
      fileList={state.fileList}
      style={{ ...style, backgroundImage: state.backgroundImage }}
      {...props}>
      <Space direction="vertical">
        { icon }
        <Format type="secondary">{children}</Format>
      </Space>
    </Upload.Dragger>
  );
}

UploadArea.Crop = function({aspect, children, ...props}) {
  return (
    <ImgCrop
      modalTitle="Editar imagen"
      aspect={aspect}
      {...props}>
      <UploadArea {...props}>{ children }</UploadArea>
    </ImgCrop>
  );
};

export default UploadArea;
