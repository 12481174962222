import React, { useContext, useEffect } from "react";
import { Form } from "antd";

import { EducationRecords } from "./education-records";
import { FormContainer } from "../../../../../../shared";
import { fieldValidationMessages } from "../../../../../../../utils";
import {
  NewExpertContext
} from "../../../../../../../contexts/experts/new/context";

const { Item } = Form;

export const EducationForm = ({ editable = true }) => {
  const {
    form,
    data = {},
    onFormFieldsChange
  } = useContext(NewExpertContext);

  const { user = {} } = data;
  const {
    education: educationRecords = [],
  } = user?.expertProfile?.professionalExperience || {};

  useEffect(() => {
    form.setFieldsValue({
      education: educationRecords
    });
  }, [data])

  return (
    <FormContainer>
      <Form
        validateMessages={fieldValidationMessages}
        onFieldsChange={onFormFieldsChange}
        form={form}
        layout="vertical">
        <Item
          // education is an array of education objects
          name="education"
          initialValue={educationRecords}
          trigger="onSave"
          valuePropName="education"
          label={editable && "Educación"}
          rules={[{ required: true }]}>
          <EducationRecords editable={editable} />
        </Item>
      </Form>
    </FormContainer>
  );
};
