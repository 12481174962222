import React, { useContext } from "react";
import { Menu } from "antd";
import { withRouter } from "../../../../../helpers";
import { CurrentUserContext } from "../../../../../contexts/shared";

function Navbar({ navigate, selectedKeys, routes = {}, ...props }) {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <Menu
      defaultOpenKeys={["general-information"]}
      selectedKeys={selectedKeys}
      style={{ borderBottom: "none" }}
      mode="horizontal"
      {...props}>
      <Menu.Item
        onClick={() =>
          navigate(routes.generalInformation || "./general-information")
        }
        key="general-information">
        Información general
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.team || "./team")}
        key="team">
        Equipo
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.pitch || "./pitch")}
        key="pitch">
        Mi pitch
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.myDocuments || "./my-documents")}
        key="my-documents">
        Mis documentos
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.batches || "./batches")}
        key="batches">
        Batches
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.metrics || "./metrics")}
        key="metrics">
        Métricas
      </Menu.Item>
      {["ENTREPRENEUR"].includes(currentUser.systemRole) ?
        <Menu.Item
          onClick={() => navigate(routes.odeUpdates || "./ode-updates")}
          key="ode-updates">
          Actualización
        </Menu.Item> : null}
    </Menu>
  );
}

export default withRouter(Navbar);
