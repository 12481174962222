import { cloneDeep } from "lodash";
import { remove as removeAccents } from "remove-accents";

export const formatValues = data => {
  const rawData = cloneDeep(data);

  const address = {
    street: rawData.streetAddress,
    city: rawData.city,
    icity: removeAccents(rawData.city),
    neighborhood: rawData.colonyAddress,
    country: rawData.country
  };

  return {
    ...rawData,
    hideDashboardAlert: true, //new companies shouldn't see the migration alert
    //because there's nothing to migrate
    address: {
      create: address
    },
    fiscalEntryDate: rawData.fiscalEntryDate ? rawData.fiscalEntryDate.toISOString() : undefined,
    startDate: rawData.startDate.toISOString(),
    socialMedia: { set: rawData.socialMedia || [] },
    keywords: { set: rawData.keywords || [] },
    mainTechnologies: { set: rawData.mainTechnologies || [] },
    files: { create: rawData.files?.map(file => {
      delete file.uuid;
      return file;
    }) || [] },
    milestones: { create: rawData.milestones?.map(milestone => {
      delete milestone.id;
      milestone.date = new Date(milestone.date).toISOString();
      return milestone;
    }) || [] },
    presenceStates: {
      create: rawData.presenceStates?.map(state => {
        state.cities = { set: state.cities };
        return state;
      }) || []
    }
  };
};
