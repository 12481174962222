import React from "react";
import { Typography } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { Visibility } from "../visibility";
import { Format } from "../format";


export const CompletedCheck = ({
  completedText = "Completado",
  isComplete,
  date,
  style,
  onRestore
}) => {
  const { Text } = Typography;
  return (
    <Visibility visible={isComplete}>
      <Text style={style}>
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          onClick={onRestore} />
        {" "}
        {completedText}: <Format type="date">{date}</Format>
        {" "}
      </Text>
    </Visibility>
  );
};
