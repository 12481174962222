import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Select } from "antd";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql";

export function EntrepreneursSelect({ fromOdeId, excludedEntrepreneurs, ...props }) {
  const { data , loading, refetch } = useQuery(
    shared.queries.getEntrepreneurs, {
    client,
    variables: {
      where: {
        id_not_in: excludedEntrepreneurs?.map(entrepreneur => entrepreneur.id),
        systemRole: "ENTREPRENEUR",
        teamOdes_some: fromOdeId ? { id: fromOdeId } : undefined
      }
    }
  });

  const options = data ?
    data.users?.map(user => ({
      label: user?.fullName,
      value: user?.id
      }))
    : [];

  useEffect(() => {
    refetch();
  },[excludedEntrepreneurs, fromOdeId]);

  return(
    <Select
      {...props}
      loading={loading}
      showSearch
      optionFilterProp="label"
      placeholder="Buscar emprendedores"
      options={options}>
    </Select>
  );
}