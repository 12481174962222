export function useNumeraliaByPerson (attendances = []) {
  let know = 0;
  let learn = 0;
  let act = 0;

  attendances.forEach(att => {
    if (att.experience.involvementLevel === "KNOW") {
      know += 1;
    } else if (att.experience.involvementLevel === "LEARN") {
      learn += 1;
    } else if (att.experience.involvementLevel === "ACT") {
      act += 1;
    }
  });

  return {
    know,
    learn,
    act
  };
}
