export const patentStateOptions = [
  {
    label: "No patentable",
    value: "NOT_PATENTABLE"
  },
  {
    label: "En investigación",
    value: "RESEARCH"
  },
  {
    label: "Solicitada",
    value: "REQUESTED"
  },
  {
    label: "Otorgada",
    value: "AWARDED"
  }
];
