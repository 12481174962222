import React from "react";
import { Form, Select, InputNumber, Row, Col } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import {
  genericFieldValidationMsgs,
  numberFormatter
} from "../../../../../utils";
import { currencies } from "../../../../../helpers/select-options";
import { Visibility } from "../../../../../components/shared";

export function AmountListItem({
  idx,
  removeItem,
  onChange,
  value,
  viewMode
}) {
  const [form] = Form.useForm();

  const handleOnChange = async (_, allValues) => {
    allValues.amount = parseFloat(allValues.amount || 0);
    onChange(allValues);
  };

  return (
    <Form
      style={{ marginBottom: "10px" }}
      className="wide-input"
      name={`amount-field-form-${idx}`}
      form={form}
      validateMessages={genericFieldValidationMsgs}
      onValuesChange={handleOnChange}>
      <Row
        gutter={[10, 10]}
        align="middle"
        style={{ width: "100%" }}>
        <Visibility
          visible={!viewMode}>
          <Col span={2} style={{ textAlign: "center" }}>
            <CloseCircleOutlined onClick={removeItem(idx)} />
          </Col>
        </Visibility>
        <Col span={14}>
          <Form.Item
            style={{ margin: "0px" }}
            initialValue={value?.amount || 0}
            name="amount"
            rules={[{ required: true }]}>
            <InputNumber
              disabled={viewMode}
              className="wide-input"
              min={0}
              formatter={numberFormatter}
              placeholder="Monto obtenido" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            style={{ margin: "0px" }}
            initialValue={value?.currency}
            name="currency"
            rules={[{ required: true }]}>
            <Select
              disabled={viewMode}
              placeholder="Moneda"
              options={currencies} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
