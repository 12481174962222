import React, { useContext, useEffect, useState } from "react";
import {
  Tag,
  Spin,
  List,
  Form,
  Modal,
  Alert,
  Divider,
  message,
  Typography,
  Descriptions
} from "antd";
import { PictureOutlined } from "@ant-design/icons";
import {
  OdeTag,
  CoverImage,
  Visibility,
  ContactInfo
} from "../../../../components/shared";
import { ScheduleExpertServiceForm } from "./form";
import { useDecorator } from "../../../../helpers/use-decorator";
import { useMutation } from "@apollo/react-hooks";
import { entrepreneur } from "../../../../graphql/entrepreneur";
import { client } from "../../../../../graphql";
import { CurrentUserContext } from "../../current-user";
import { LoadingOutlined, LinkOutlined } from "@ant-design/icons";
import {
  ExamplesDisplay
} from "../../../../components/shared/examples-display";
import { eventTypeEnum } from "../../../../helpers";
import { UsageTrackingService } from "../../../../services";
import {
  expertServiceTypes,
  getReadableValue
} from "../../../../helpers";

export function ScheduleExpertServiceModal({
  onSave,
  onCancel,
  assignedService,
  userId,
  ...props
}) {
  const [form] = Form.useForm();
  const [state, setState] = useState({ canSchedule: false, loading: false });
  const [busy, setBusy] = useState(false);

  const { currentUser } = useContext(CurrentUserContext);

  const checkAvailability = async () => {
    const { data } = await client.query({
      query: entrepreneur
        .queries.getActiveScheduledMentorshipServicesByEntrepreneurId,
      variables: {
        id: currentUser.id,
        expertId: assignedService?.expertService?.expert?.id,
        serviceId: assignedService?.expertService?.id,
        odeId: assignedService?.ode?.id
      },
      fetchPolicy: "network-only"
    });

    return !data?.scheduledExpertServices?.length;
  };

  useEffect(() => {
    setState({
      loading: true,
      canSchedule: false // until check is completed
    });

    if(assignedService?.expertServiceData?.type === "MENTORSHIP") {
      currentUser?.id &&
        checkAvailability()
          .then(availability => setState({
            loading: false,
            canSchedule: availability
          }))
          .catch(console.error);
    } else {
      setState({
        loading: false,
        canSchedule: true
      });
    }
  }, [assignedService, currentUser]);

  assignedService = useDecorator("assignedExpertService", assignedService);

  const [createScheduledExpertService] = useMutation(
    entrepreneur.mutations.createScheduledExpertService, {
      client: client,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: entrepreneur.queries
            .getAssignedExpertServicesByEntrepreneurId,
          variables: {
            id: currentUser?.id
          }
        },
        {
          query: entrepreneur.queries
            .getActiveScheduledMentorshipServicesByEntrepreneurId,
          variables: {
            id: currentUser?.id,
            expertId: assignedService.object.expertService?.expert?.id,
            serviceId: assignedService?.expertService?.id,
            odeId: assignedService?.ode?.id
          }
        }
      ]
    }
  );

  const formatServiceDate = (serviceDate, serviceTime) => {
    const time = new Date(serviceTime.toString());
    const hours = time.getHours();
    const minutes = time.getMinutes();
    serviceDate.set({h: hours, m: minutes});
    return serviceDate;
  };

  /**
   * @todo please refactor this function. The Bussiness logic must be placed
   * in the service layer, and even better, in the server side.
   * @returns {Promise<void>}
   */
  const handleOnOk = async () => {
    if (assignedService?.expertServiceData?.type === "MENTORSHIP") {
      const availability = await checkAvailability().catch(console.error);

      if (!availability) {
        setState({
          loading: false,
          canSchedule: false
        });
        return;
      }
    }

    await form.validateFields();
    const serviceType = assignedService?.expertServiceData?.type;
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      setBusy(true);
      const values = form.getFieldsValue();

      values.serviceDate = formatServiceDate(
        values.serviceDate,
        values.serviceHour
      );

      delete values.serviceHour;

      if (serviceType === "PRODUCT") {
        values.duration = String(assignedService?.assignedUnits);
      }

      if (serviceType === "MENTORSHIP") {
        values.duration = String(values.duration);
      }

      values.requestedBy = { connect: { id: userId } };
      values.status = "SCHEDULED";
      values.assignedExpertService = {
        connect: { id: assignedService?.id }
      };

      values.events = { // log event
        create: {
          event: eventTypeEnum.STATUS_CHANGED,
          description: `Status changed to ${values.status}`,
          blame: { connect: { id: userId } }
        }
      };

      values.duration = values.duration
        ? String(values.duration)
        : values.duration;

      await createScheduledExpertService({
        variables: { data: { ...values } }
      });

      message.success("Producto/servicio agendado");

      UsageTrackingService.trackScheduledProduct({
        product: {
          id: assignedService?.expertServiceData?.id,
          name: assignedService?.expertServiceData?.name,
          serviceType: getReadableValue(
            expertServiceTypes,
            assignedService?.expertServiceData?.type
          )
        },
        user: {
          id: currentUser?.id,
          email: currentUser?.email,
          fullName: currentUser?.fullName
        }
      });

      onSave && onSave(values);

      form.resetFields();
    } catch (error) {
      if (error.message.match(/CANT_SCHEDULE_CANCELLED_ASSIGNED_SERVICE/))
        message.error("Ocurrió un error. La asignación ha sido cancelada \
          por un administrador.");
      else
        message.error("Ocurrió un error por favor intentalo \
          de nuevo en unos segundos");
      console.error(error);
    } finally {
      dismissLoader();
      setBusy(false);
    }
  };

  const handleOnCancel = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  return (
    <Modal
      className="schedule-expert-service-modal"
      okText="Agendar"
      okButtonProps={{
        disabled: !state.canSchedule,
        loading: state.loading || busy
      }}
      cancelText="Cancelar"
      title={`Agendar ${assignedService?.modalTitle?.toLowerCase()}`}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      style={{ minWidth: "700px" }}
      {...props}>
      <CoverImage
        url={assignedService?.expertServiceData?.coverPicUrl}
        icon={PictureOutlined} />
      <Typography.Title level={3} style={{ marginTop: "20px" }}>
        {assignedService.serviceTitle}
      </Typography.Title>
      <Typography.Text>
        {assignedService?.expertServiceData?.description}
      </Typography.Text>
      <div style={{ marginTop: "20px" }}>
        <ContactInfo
          title={`Experto: ${assignedService?.expertService?.expert?.fullName}`}
          user={assignedService?.expertService?.expert} />
      </div>
      <Divider />
      <Descriptions
        layout="vertical"
        column={2}>
        <Descriptions.Item label="Compañía">
          <OdeTag odeName={assignedService?.ode?.name} />
        </Descriptions.Item>
        <Descriptions.Item
          label="Categoría"
          span={2}>
          <Tag color="purple">
            {assignedService.category}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item
          label={`${assignedService.unit}`}>
          {assignedService.availableUnits} disponibles
          de {assignedService.assignedUnits} asignadas
        </Descriptions.Item>
        <Descriptions.Item label="Fecha límite de consumo">
          {assignedService.limitDate}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha de asignación">
          {assignedService.assignmentDate}
        </Descriptions.Item>
        {!!assignedService.limitPerDay && (
          <Descriptions.Item label="Límite de horas por día">
            {assignedService.limitPerDay}
          </Descriptions.Item>
        )}
      </Descriptions>
      <Descriptions layout="vertical">
        <Descriptions.Item label="Galería de ejemplos" className="examples">
          <ExamplesDisplay
            examples={assignedService?.expertServiceData?.examples} />
        </Descriptions.Item>
      </Descriptions>
      <Descriptions>
        <Descriptions.Item label="Enlaces" className="examples">
          <List
            size="small"
            dataSource={assignedService?.expertServiceData?.links}
            renderItem={l => <List.Item key={l}>
              <Typography.Link
                href={l}
                target="_blank">
                <LinkOutlined /> {l}
              </Typography.Link>
            </List.Item>}>
          </List>
        </Descriptions.Item>
      </Descriptions>
      <Visibility visible={state.loading && assignedService.isMentorship}>
        <Alert
          type="info"
          message={
            <>
              Comprobando disponibilidad
              &nbsp;
              <Spin indicator={<LoadingOutlined spin />} />
            </>
          } />
      </Visibility>
      <Visibility visible={!state.loading && !state.canSchedule}>
        <Alert
          type="warning"
          message={`No puedes agendar la misma mentoría del mismo experto
          hasta que finalices o canceles la última activa para la Compañía
          ${assignedService?.ode?.name}.`} />
      </Visibility>
      <Visibility visible={state.canSchedule}>
        <ScheduleExpertServiceForm
          form={form}
          hoursPerDay={assignedService.limitPerDay}
          assignedService={assignedService} />
      </Visibility>
      <Visibility visible={!state.loading && state.canSchedule}>
        <Alert
          type="warning"
          message="Después de agendar, contacta al experto
          y espera a que la solicitud sea aceptada." />
      </Visibility>
    </Modal>
  );
}

