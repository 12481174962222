import React from "react";
import { Space } from "antd";
import { remove as removeAccents } from "remove-accents";
import { debounce } from "lodash";
import { SearchToolbar } from "../../../../../../../components/shared";

export function FilterControls({ onChange, objectiveId }) {
  const handleOnSearchChange = debounce((name = "") => {
    const stringValue = removeAccents(name).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({
      key: "where",
      value: {
        objective: {
          id: objectiveId
        },
        iname_contains: value
      }
    });
  }, 500);

  return (
    <Space>
      <SearchToolbar
        onSearchChange={e => handleOnSearchChange(e.target.value)} />
    </Space>
  );
}
