import gql from "graphql-tag";

export const getBatchesByProgramId = gql`
  query getBatchesByProgramId($id: ID!, $coordinatorId: ID!, $orderBy: BatchOrderByInput ) {
    batches(
      orderBy: $orderBy,
      where: {
        program: { id: $id }
        users_some: {
          systemRole: COORDINATOR
          id: $coordinatorId
        }
      }) {
      id    
      name
      iname
      batchID
      startDate
      finishDate
      description
      program {
        id
        name
        type
        coverPicUrl
      }
    }
  }
`;
