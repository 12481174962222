import { client } from "../../../graphql";
import {
  registerCompanyEnrolledWithBatchEventQuery
} from "./graphql/register-company-enrolled-with-batch-event-query";

export async function registerCompanyEnrolledWithBatchEvent({ odeId, batchId }){
  const result = await client.mutate({
    mutation: registerCompanyEnrolledWithBatchEventQuery,
    variables: {
      odeId,
      batchId,
      data: {
        odeId,
        batchId,
        enrollmentDate: new Date().toISOString()
      }
    }
  });

  return result.data?.updateODE || null;
}
