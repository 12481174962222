import { entrepreneur } from "../../../../graphql/entrepreneur";
import { eventTypeEnum } from "../../../../helpers";

export async function acceptDelivery(scheduledServiceId, entrepreneurId) {
  return await this.client.mutate({
    mutation: entrepreneur.mutations.acceptScheduledExpertService,
    variables: {
      id: scheduledServiceId,
      // log event
      events: {
        create: {
          event: eventTypeEnum.STATUS_CHANGED,
          description: "Delivery status changed to ACCEPTED",
          blame: { connect: { id: entrepreneurId } }
        }
      }
    }
  });
}
