import gql from "graphql-tag";

export const getByIdQuery = gql`
  query GetExpertMentoringsQuery($expertId: ID!) {
    user(where: {
    id: $expertId
    }) {
      id
      expertProfile {
        id
        private
        privateBatches {
          id
          name
        }
      }
    }
  }
`;
