import { useState, useEffect, useCallback } from "react";
import { flatReduce } from "../../../../../helpers";

export function useMetricsCalculation({ reports }) {
  const [calculating, setCalculating] = useState(false);
  const [state, setState] = useState({
    accumulatedPresalesMX: 0,
    accumulatedPresalesUS: 0,
    averageMonthlySalesMX: 0,
    averageMonthlySalesUS: 0,
    fundsRaisedMX: 0,
    fundsRaisedUS: 0,
    overallContests: 0,
    newJobs: 0
  });

  useEffect(() => {
    calculateMetrics(reports);
  }, [reports]);

 const calculateMetrics = useCallback(async reports => {
   setCalculating(true);
    try {
      const presales = flatReduce(reports, "presales");
      const monthlySales = flatReduce(reports, "monthlySales");
      const fundsRaised = flatReduce(reports, "fundings.receivedAmount");
      const contests = flatReduce(reports, "contests");
      const hiredEmployees = flatReduce(reports.map(r => r.hiredEmployees));

      setAccumulatedPresales(presales);
      setAverageMonthlySales(monthlySales);
      setFundsRaised(fundsRaised);

      setOverallContests(contests);
      setNewJobs(hiredEmployees);
    } catch (error) {
      console.error(error);
    } finally {
      setCalculating(false);
    }
  });

  const sumAmounts = (sales = []) => {
    return sales.reduce((acc , sale) => {
      const { currency, amount } = sale;

      acc.mxn += currency === "MXN" ? amount : 0;
      acc.usd += currency === "USD" ? amount : 0;
      return acc;
    }, { mxn: 0, usd: 0 });
  };

  const setAccumulatedPresales = (presales = []) => {
    const { mxn, usd } = sumAmounts(presales);

    setState(prevState => ({
      ...prevState,
      accumulatedPresalesMX: mxn,
      accumulatedPresalesUS: usd
    }));
  };

  const setAverageMonthlySales = (monthlySales = []) => {
    const { mxn, usd } = sumAmounts(monthlySales);

    setState(prevState => ({
      ...prevState,
      averageMonthlySalesMX: monthlySales.length > 0 ?
        mxn / monthlySales.length : 0,
      accumulatedPresalesUS: monthlySales.length > 0 ?
        usd / monthlySales.length : 0
    }));
  };

  const setFundsRaised = (funds = []) => {
    const { mxn, usd } = sumAmounts(funds);

    setState(prevState => ({
      ...prevState,
     fundsRaisedMX: mxn,
     fundsRaisedUS: usd
    }));
  };

  const setOverallContests = (contests = []) => {
    setState(prevState => ({
      ...prevState,
      overallContests: contests.length
    }));
  };

  const setNewJobs = (newJobs = []) => {
    setState(prevState => ({
      ...prevState,
      newJobs: newJobs.reduce((acc, curr) => acc += curr, 0)
    }));
  };

  return {
    ...state,
    calculating
  };
}
