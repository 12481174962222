import React from "react";
import { useHistory } from "react-router-dom";
import { List, Button, Space } from "antd";
import { Avatar, ActionButton, Format, Visibility } from "../../../shared";
import {
  activeTypes,
  programTypes
} from "../../../../helpers/select-options/program";

export function ProgramItem({
  program,
  onEdit,
  onCreateBatch,
  onDisable,
  onDelete
}) {
  const {
    name = "Sin nombre",
    coverPicUrl
  } = program;

  const actionButtonProps = {
    onEdit: onEdit
  };

  const history = useHistory();

  const handleProgramClick = () => {
    history.push(`./programs/${program.id}/batches`);
  };

  return (
    <List.Item
      actions={[
        <Visibility key="edit" visible={!program.disabled}>
          <Button
            onClick={() => onCreateBatch({ programId: program?.id })}
            type="link">
            Crear batch
          </Button>
        </Visibility>,
        <ActionButton
          customOnDelete={onDelete}
          onDisable={onDisable}
          {...actionButtonProps}
          key="more" />
      ]}>
      <List.Item.Meta
        className="clickable"
        onClick={handleProgramClick}
        avatar={<Avatar>{coverPicUrl || name}</Avatar>}
        title={name}
        description={<Space>
          <Format
            type="tag"
            options={activeTypes}>
            {!program?.disabled}
          </Format>
          <Format
            type="tag"
            options={programTypes}>
            {program.type}
          </Format>
        </Space>} />
    </List.Item>
  );
}
