import React from "react";
import { Progress, Typography } from "antd";
import { AssignedServices } from "./assigned-services";
import { AvailableServices } from "./available-services";
import { FlexContainer } from "../../../../../../../../shared";

export function EntrepreneurServicesSection({
  batch = null,
  company = null
}) {
  return (
    <div>
      <FlexContainer
        yAlign="center"
        xAlign="center"
        direction="vertical"
        style={{ gap: "2rem" }}>
        <Typography.Title level={4} style={{ color: "#6348E5" }}>
          Consumo de servicios
        </Typography.Title>
        <Progress
          percent={0}
          style={{ width: "400px" }} />
      </FlexContainer>
      <Typography.Title level={4}>
        Servicios asignados
      </Typography.Title>
      <AssignedServices
        batch={batch}
        company={company} />
      <Typography.Title level={4}>
        Servicios disponibles
      </Typography.Title>
      <AvailableServices batch={batch} />
    </div>
  );
}
