import React, { useContext } from "react";
import { ReactTinyLink } from "react-tiny-link";
import { Alert, Button, Card, List, Row, Typography } from "antd";
import { Format } from "../../../../../";
import {
  CompanyMilestonesModalContext
} from "../../../../../../../contexts/shared/company-milestones-modal";
import { SubTitle } from "../subtitle";
import { ActionButton } from "../../../../..";
import { checkUrlIsValid } from "../../../../../../../helpers";
import { Visibility } from "../../../../../visibility";
import { FlexContainer } from "../../../../../flex-container";

export const NewsMilestones = ({ data, allowEdit }) => {
  const {
    onCreateOpen,
    onEdit,
    onDelete
  } = useContext(CompanyMilestonesModalContext);

  return (
    <div>
      <Row justify="space-between">
        <SubTitle>Noticias</SubTitle>
        <Visibility visible={allowEdit}>
          <Button
            ghost
            onClick={() => onCreateOpen("NEWS")}>Agregar</Button>
        </Visibility>
      </Row>
      {data.length > 0 ?
        <List
          style={{ padding: "16px" }}
          dataSource={data}
          renderItem={news => (
            <List.Item>
              <Row justify="space-between" style={{ width: "100%" }}>
                <div>
                  <Card
                    bordered={false}
                    bodyStyle={{ padding: "0 24px 0 24px" }}
                    style={{
                      borderRadius: "8px",
                      width: "380px"
                    }}>
                    {checkUrlIsValid(news.link) ?
                      <div style={{
                        width: "100%",
                        display: "flex",
                        borderRadius: "8px",
                        flexDirection: "row",
                        alignContent: "center",
                        border: "1px solid #f0f0f0",
                        boxShadow: "2px 2px 2px 1px rgba(0,0,0,0.2)"
                      }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            height: "125px",
                            textAlign: "center",
                            backgroundColor: "rgba(95,57,222,0.05)"
                          }}>
                          <Typography.Title
                            level={5}
                            style={{
                              padding: "0 30px",
                              textTransform: "uppercase",
                              color: "#5F39DE"
                            }}>
                            https
                          </Typography.Title>
                        </div>
                        <FlexContainer
                          style={{ flex: 2, overflow: "hidden", padding: "15px 20px" }}
                          xAlign={"center"}
                          yAlign={"center"}>
                          <a
                            target="_blank"
                            href={news.link}
                            style={{ overflow: "hidden" }}>
                            <Typography.Title
                              style={{ textOverflow: "elipsis" }}
                              level={5}>
                              {news.name}
                            </Typography.Title>
                          </a>
                        </FlexContainer>
                      </div> :
                      <Alert
                        type="warning"
                        message="El enlace no es una URL válida"
                        showIcon />}
                  </Card>
                  <Typography.Text
                    className="milestones__item--description"
                    style={{
                      textTransform: "capitalize",
                      paddingLeft: "24px"
                    }}>
                    <Format type="date" format="MMMM YYYY">{news.date}</Format>
                  </Typography.Text>
                </div>
                <Visibility visible={allowEdit}>
                  <ActionButton
                    size="small"
                    onDelete={() => onDelete(news.id)}
                    onEdit={() => onEdit(news.milestoneType, news.id, news)} />
                </Visibility>
              </Row>
            </List.Item>
          )} /> : null
      }
    </div>
  );
};
