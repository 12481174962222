import React, { useContext } from "react";
import { Section } from "../../../shared";
import {
  ActionButton
} from "../../../shared/";
import Navbar from "./navbar";
import { client } from "../../../../../graphql";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { shared } from "../../../../graphql/shared";
import Breadcrumbs from "./breadcrumbs";
import { Typography } from "antd";
import { ProgramModalContext } from "../../../../contexts/shared/program-modal";

export function ProgramSummary({
  selectedKeys
}) {
  const { id } = useParams();

  const {
    openModal,
    onDisableProgram,
    onDeleteProgram,
    onReenableProgram
  } = useContext(ProgramModalContext);

  const { data, loading } = useQuery(
    shared.queries.getProgramById,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      client
    }
  );

  const isDisabled = data?.program?.disabled;

  return (
    <>
      <Breadcrumbs
        extra={<ActionButton
          onEdit={() => openModal({ programId: id })}
          onDisable={!isDisabled && (() => onDisableProgram({ id }))}
          onEnable={isDisabled && (() => onReenableProgram({ id }))}
          customOnDelete={() => onDeleteProgram({ id })}  />}
        program={data?.program} />
      <Section loading={loading}>
        <Typography.Title level={4}>{data?.program?.name}</Typography.Title>
        {data?.program?.description}
      </Section>
      <Navbar selectedKeys={selectedKeys} />
    </>
  );
}
