import gql from "graphql-tag";

/**
 * @deprecated Stop using GraphQL directly in the components.
 * We are moving this logic to the Services Layer. Instead of this query,
 * use the BatchesService.getPerks function that encapsulates
 * the GQL implementation
 * @type {DocumentNode}
 */
export const getPerksByBatchId = gql`
  query GetPerksByBatchId ( $id: ID! ) {
    perks(
      where: {
        batches_some: {id:$id}
      }
    ) {
      id
      coverPicUrl
      name
      expiration
      program{
        id
        name
        coverPicUrl
      }
      category
      description
      contactInfo
      batches{
        id
        name
        program{
          id
          coverPicUrl
        }
      }
      odes{
        id
        name
        logoUrl
      }
    }
  } 
`;
