import React, { useContext } from "react";
import { withLoader } from "../../../../helpers";
import {
  OccupationEditSection,
  TecRelationshipEditSection,
  EducationEditSection,
  AccountsEditSection
} from "../../../shared/profile-forms";
import { SummaryEditSection } from "../../../shared/profile-forms/summary";
import { CurrentUserContext } from "../../../../contexts/shared";
import { MissingProfileDataAlertProvider } from "../../../../contexts/entrepreneur/missing-profile-data-alert";

function EducationAndJob({ editable = false }) {
  const { currentUser } = useContext(CurrentUserContext);

  return(
    <MissingProfileDataAlertProvider user={currentUser}>
      <SummaryEditSection editable={editable} />
      <TecRelationshipEditSection editable={editable} />
      <AccountsEditSection editable={editable} />
      <OccupationEditSection editable={editable} />
      <EducationEditSection editable={editable} />
    </MissingProfileDataAlertProvider>
  );
}

export default withLoader(EducationAndJob);
