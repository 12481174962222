import React from "react";
import { DatePicker, Form, Input } from "antd";

export const CompanyAchievementForm = ({ form }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      style={{ padding: "0 16px" }}>
      <Form.Item
        label="Logro"
        name="name"
        rules={[{ required: true }]}>
        <Input
          autoComplete="off"
          placeholder="Por ejemplo: Reconocimiento por el presidente de la república." />
      </Form.Item>
      <Form.Item
        label="Describe tu logro"
        name="description"
        rules={[{ required: true }]}>
        <Input
          autoComplete="off"
          placeholder="Por ejemplo: Mención honorífica." />
      </Form.Item>
      <Form.Item
        label="Fecha del logro"
        name="date"
        rules={[{ required: true }]}>
        <DatePicker
          format="MMMM YYYY"
          picker="month"
          style={{ width: "357px" }} />
      </Form.Item>
    </Form>
  );
};
