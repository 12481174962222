import React from "react";
import { Space, Select } from "antd";
import _ from "lodash";
import { useQuery } from "react-apollo-hooks";
import { remove as removeAccents } from "remove-accents";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql";
import {
  SearchToolbar,
  ServiceCategoriesDropdown,
  ServiceStatusDropdown
} from "../../../../../components/shared";
import { expertServiceTypes } from "../../../../../helpers/select-options";

const selectStyle = { width: "180px" };

export function FilterControls({ onChange }) {
  const { data, loading, errors } = useQuery(
    shared.queries.getPrograms,
    { client });

  const programsOptions = (data?.programs || [])
    .map(p => ({ value: p.id, label: p.name }));

  const handleOnChange = (value, key) =>
    onChange && onChange({ value, key });

  const handleOnSearchChange = _.debounce((text = "") => {
    const stringValue = removeAccents(text).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ value, key: "searchString" });
  }, 500);

  return (
    <>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end"
        }}>
        <ServiceCategoriesDropdown
          onChange={val => handleOnChange(val, "category")} />
        <ServiceStatusDropdown
          onChange={val => handleOnChange(val, "status")} />
        <SearchToolbar
          onSearchChange={e => handleOnSearchChange(e.target.value)} />
      </Space>
      <Space style={{
        marginTop: "10px",
        width: "100%",
        justifyContent: "flex-end"
      }}>
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          options={expertServiceTypes}
          style={{ width: "180px" }}
          onChange={val => handleOnChange(val, "type")}
          placeholder="Tipo" />
        <Select
          optionFilterProp="label"
          disabled={loading || errors}
          loading={loading}
          options={programsOptions}
          allowClear
          showSearch
          style={selectStyle}
          onChange={val => handleOnChange(val, "programId")}
          placeholder="Programa" />
      </Space>
    </>
  );
}
