import React, { useState, useEffect, useContext } from "react";
import { Table } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { withLoader } from "../../../../../../../helpers";
import { Section } from "../../../../../../shared";
import { ContentDetailsModalContext } from "../../../../../../../contexts/shared/content-details-modal/context";
import { client } from "../../../../../../../../graphql";
import { columns, entrepreneurColumns, getColums } from "./table-columns-definition";
import { TableFilters } from "./table-filters";
import { shared } from "../../../../../../../graphql/shared";
import { CurrentUserContext } from "../../../../../../../contexts/shared";
import { getContentsFromObjectives } from "./get-contents-from-objectives";

function ContentTable({ data, odeId }) {

  const [loading, setLoading] = useState(true);
  const [tableContent, setTableContent] = useState({
    rawContent: [],
    content: []
  });

  const { currentUser } = useContext(CurrentUserContext);
  const { onOpen: openContentModal } = useContext(ContentDetailsModalContext);

  const { data: odeName } = useQuery(shared.queries.getOdeNameById, {
    client,
    variables: {
      id: odeId
    }
  });

  useEffect(() => {
      const contents = getContentsFromObjectives(data.objectives);
      setTableContent({
        rawContent: contents,
        content: contents
      });
      setLoading(false);
  }, [data]);

  const handleRowClick = id => {
    openContentModal(id);
  };

  const handleSelectorChange = value => {
    switch (value) {
      case "completed":
        setTableContent(prev => ({
          ...prev,
          content: prev
            .rawContent
            .filter(content => content.isComplete === true)
        }));
        break;

      case "pending":
        setTableContent(prev => ({
          ...prev,
          content: prev
            .rawContent
            .filter(content => content.isComplete === false)
        }));
        break;

      default:
        setTableContent(prev => ({
          ...prev,
          content: prev.rawContent
        }));
        break;
    }
  };

  const handleInputSearch = value => {
    const searchedContent = [];

    tableContent.rawContent.map(content => {
      if(content.name.includes(value)){
        searchedContent.push(content);
      }
    });

    setTableContent(prev => ({
      ...prev,
      content: searchedContent
    }));
  };

  const resetTableContent = e => {
    if(e.target.value === ""){
      setTableContent(prev => ({
        ...prev,
        content: prev.rawContent
      }));
    }
  };

  return (
    <Section
      title={`Entregables: ${odeName?.oDE.name || ""}`}
      spaced>
      <TableFilters
        handleSelectorChange={handleSelectorChange}
        handleInputSearch={handleInputSearch}
        resetTableContent={resetTableContent} />
      <Table
        onRow={record => {
          return {
            onClick: () => handleRowClick(record.id)
          };
        }}
        loading={loading}
        columns={
          currentUser.systemRole === "ENTREPRENEUR"
          ? entrepreneurColumns
          : columns}
        dataSource={tableContent?.content} />
    </Section>
  );
}

export default withLoader(ContentTable);