import { getPercentage } from "../../../../helpers";

export function entrepreneursPerGenderToTable (items = [], total = 0) {
  /**
   * @type {{[key in string]: { count: number, percentage: number }}}
   */
  const rows = {};

  items.forEach(item => {
    const count = parseInt(item["Company.entrepreneursCount"]);
    const gender = item["Company.teamMemberGender"];

    rows[gender] = {
      count,
      percentage: getPercentage(total, parseInt(count, 10)).toFixed(2)
    };
  });

  rows.usersWithNoData = {
    count: (total || 0)
      - (
        (rows.MALE?.count || 0)
        + (rows.FEMALE?.count  || 0)
        + (rows.OTHER?.count || 0)
        + (rows.NOT_SAY?.count || 0)
      )
  };

  rows.usersWithNoData.percentage = getPercentage(
    total,
    rows.usersWithNoData.count)
  .toFixed(2);

  return rows;
}
