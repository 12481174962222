import { client } from "../../../graphql";
import { getOdeById } from "./graphql/get-ode-by-id";
import { createUserByInvitation } from "./graphql/create-user-by-invitation";
import { getAvailableUsersToAddToCompany } from "./graphql/available-users-to-add";
import { addCompanyTeamMembers } from "./graphql/add-company-team-members";
import { getCompanyTeamMembers } from "./graphql/get-team-members";
import { removeCompanyTeamMember } from "./graphql/remove-company-team-member";

async function getById({ id = "" }) {
  const result = await client.query({
    query: getOdeById,
    variables: {
      id
    },
    fetchPolicy: "network-only"
  });

  return result.data.oDE;
}

async function inviteTeamMembers({ companyId, emails = [] }) {
  return await Promise.all(emails.map(email => (
    client.mutate({
      mutation: createUserByInvitation,
      variables: {
        email,
        systemRole: "ENTREPRENEUR",
        odes: {
          connect: [{ id: companyId }]
        }
      }
    })
  )));
}

async function getAvailableUsersToAdd({ companyId, systemRole }) {
  const result = await client.query({
    query: getAvailableUsersToAddToCompany,
    variables: {
      odeId: companyId,
      systemRole
    },
    fetchPolicy: "network-only"
  });

  return result.data.users;
}

async function addTeamMembers({
  ids = [],
  companyId,
  systemRole,
  requestedBy
}) {
  const result = await client.mutate({
    mutation: addCompanyTeamMembers,
    variables: {
      systemRole,
      requestedBy,
      teamIdList: ids.map(id => ({ id })),
      odeId: companyId
    }
  });

  return result.data?.addOdeTeamMembers;
}

async function getTeamMembers({ companyId, systemRole }) {
  const result = await client.query({
    query: getCompanyTeamMembers,
    variables: {
      odeId: companyId,
      systemRole
    },
    fetchPolicy: "network-only"
  });

  return result.data.users;
}

async function removeTeamMember({ companyId, userId }) {
  const result = await client.mutate({
    mutation: removeCompanyTeamMember,
    variables: {
      odeId: companyId,
      userId
    }
  });

  return result.data?.updateODE;
}

export const OdesService = {
  getById,
  addTeamMembers,
  getTeamMembers,
  removeTeamMember,
  inviteTeamMembers,
  getAvailableUsersToAdd
};
