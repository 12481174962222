import gql from "graphql-tag";

export const getPrograms = gql`
  query GetPrograms($orderBy: ProgramOrderByInput) {
    programs(
      orderBy: $orderBy,
      where: {OR: [ { disabled: false}, { disabled: null} ]}
    ) {
      id
      name
      type
      coverPicUrl
      disabled
      batches{
        name
      }
    }
  }
`;
