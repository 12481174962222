import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { MenuContext } from "../../../../../../contexts/shared";
import { MainMenu } from "../../../../../../components/admin/shared";
import {
  SidebarLayout,
  AnnouncementsSectionDetail
} from "../../../../../../components/shared";
import {
  BatchDetailsContextProvider
} from "../../../../../../contexts/admin/batches";
import {
  Navbar,
  Summary
} from "../../../../../../components/admin/batches/show";
import {
  CreateAnnouncementModalProvider
} from "../../../../../../contexts/shared/create-annoucement-modal";

export const AnnouncementsIndexDetail = () => {
  const { id: batchId } = useParams();
  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: ["programs"],
    selectedKeys: ["batches"]
  });

  const routes = {
    experts: "../experts",
    odes: "../odes",
    entrepreneurs: "../entrepreneurs",
    coordinators: "../coordinators",
    board: "../board",
    perks: "../perks",
    trainings: "../trainings",
    calendar: "../calendar",
    tasks: "../tasks",
    odesUpdates: "../odes-updates",
    content: "../content",
    announcements: "../announcements"
  };

  return (
    <BatchDetailsContextProvider>
      <CreateAnnouncementModalProvider batchId={batchId}>
        <SidebarLayout menu={<MainMenu />}>
          <Summary />
          <Navbar routes={routes} selectedKeys={["announcements"]} />
          <AnnouncementsSectionDetail />
        </SidebarLayout>
      </CreateAnnouncementModalProvider>
    </BatchDetailsContextProvider>
  );
};
