import React, { useEffect } from "react";
import { Modal, Form, Input, Radio, Switch } from "antd";
import { merge } from "lodash";
import { FormContainer } from "../../../../";

export const LanguageModal = ({
  onSave,
  onCancel,
  edit,
  visible,
  ...props
}) => {
  const [form] = Form.useForm();
  const validationMessages = {
    required: "El campo '${label}' es requerido."
  };

  useEffect(() => {
    if (edit) {
      form.setFieldsValue(edit);
    }
  }, [edit, visible]);

  const onOk = () => {
    try {
      let values = form.getFieldsValue();

      if (typeof edit?.index !== "undefined") {
        values.index = edit.index;
        values = merge(edit, values);
      }

      onSave(values);
      form.resetFields();
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnCancel = e => {
    if (onCancel) {
      onCancel(e);
    }
    form.resetFields();
  };

  return (
    <Modal
      className="modal-languages"
      okText="Guardar"
      cancelText="Cancelar"
      title="Idiomas"
      style={{ width: "600px" }}
      onOk={onOk}
      onCancel={handleOnCancel}
      visible={visible}
      {...props}>
      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          validateMessages={validationMessages}>
          <Form.Item
            name="name"
            label="Idioma"
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="level"
            label="Nivel"
            rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value="BASIC">Básico</Radio>
              <Radio value="INTERMEDIATE">Intermedio</Radio>
              <Radio value="EXPERT">Experto</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="canDeliverServices"
            label="Prefiero entregar productos en este idioma"
            valuePropName="checked">
            <Switch checkedChildren="si" unCheckedChildren="no" />
          </Form.Item>
        </Form>
      </FormContainer>
    </Modal>
  );
};
