import { client } from "../../../graphql";
import {
  getOrganizationsByStatus,
  approveOrganizationRequest,
  rejectOrganizationRequest
} from "./graphql";

async function organizationsByStatus (variables = {}) {
  const result = await client.query({
    query: getOrganizationsByStatus,
    fetchPolicy: "network-only",
    variables
  });

  return result?.data || [];
}

async function organizationRequestApprove ({ organizationId, reviewerId }) {
  return await client.mutate({
    mutation: approveOrganizationRequest,
    variables: {
      organizationId,
      reviewerId
    }
  });
}

async function organizationRequestReject ({
  organizationId,
  reviewerId,
  rejectionReason
}) {
  return await client.mutate({
    mutation: rejectOrganizationRequest,
    variables: {
      organizationId,
      reviewerId,
      rejectionReason
    }
  });
}

export const AsemOrganizationService = {
  organizationsByStatus,
  organizationRequestApprove,
  organizationRequestReject
};
