import React from "react";
import { Typography, Row } from "antd";

export function CardListLayout({title = "", children}) {
  return (
    <div style={{
      width: "100%",
      padding: "3rem"}}>
      <Row style={{marginBottom: "1.5rem"}}>
        <Typography.Title level={4}>{title}</Typography.Title>
      </Row>
      <Row gutter={16}>
        {children}
      </Row>
    </div>
  );
}
