const organizationServices = [
  { value: "Concursos y reconocimientos" },
  { value: "Eventos de pitches y demos" },
  { value: "Hackathones" },
  { value: "Desarrollo de ideas y desafíos de innovación" },
  { value: "Charlas y conferencias (sin fines educativos)" },
  { value: "Talleres, programas de formación y entrenamiento" },
  { value: "Pre-incubación" },
  { value: "Incubación" },
  { value: "Aceleración" },
  { value: "Consultoría" },
  { value: "Mentoreo y asesoría" },
  { value: "Servicios backoffice" },
  { value: "Financiamiento" },
  { value: "Inversión" },
  { value: "Medios de comunicación y difusión" },
  { value: "Catálogos, listas y noticias" },
  { value: "Espacios de trabajo flexible y de oficina" },
  { value: "Makerspaces" },
  { value: "Conexión con talento" },
  { value: "Vinculación con industria" },
  { value: "Laboratorios para el desarrollo tecnológico" }
];

export const organizationServicesOptions = organizationServices.sort((a, b) => {
  return a.value.localeCompare(b.value);
});