import React, { useContext, useState } from "react";
import { CurrentUserContext } from "../../../../contexts/shared";
import { AdminTabPanes } from "../../batch-offer/batch-offer-smart-tabs/admin";
import { useHistory, useLocation } from "react-router";
import useTreatment from "../../../../hooks/feature-flags/use-treatment";

export function BatchDetailTabs({
  batch = {},
  refetchBatch,
  defaultActiveKey = "information"
}) {
  const history = useHistory();
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const section = params.get("section");

  const [tab, setTab] = useState({ activeKey: "information" });
  const { currentUser } = useContext(CurrentUserContext);

  const setActiveTab = (path, keyName) => {
    keyName = keyName || "information"; // could be null
    setTab(prevState => ({ ...prevState, activeKey: keyName }));
    history.replace(path);
  };

  const onClickActiveTab = keyName => {
    setActiveTab(`./offer?section=${keyName}`, keyName);

    setTimeout(() => { // Wait render view
      const focus = document.getElementById("focus");
      focus.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const disableExpertsTabTreatment = useTreatment("Disable_Experts_Tab");

  const renderTabPanes = (systemRole = "", tabsProps = {}) => {
    switch (systemRole) {
      case "SUPER_ADMIN":
        return (
          <AdminTabPanes
            tabsProps={tabsProps}
            batch={batch}
            showExpert={
              disableExpertsTabTreatment === "OFF"
            }
            refetchBatch={refetchBatch}
            setActiveTab={setActiveTab}
            defaultActiveTab={section || "information"} />
        );
      case "COORDINATOR":
        return (
          <AdminTabPanes
            tabsProps={tabsProps}
            batch={batch}
            showExpert={
              disableExpertsTabTreatment === "OFF"
            }
            refetchBatch={refetchBatch}
            setActiveTab={setActiveTab}
            defaultActiveTab={section || "information"} />
        );
      default:
        return null;
    }
  };

  const tabsProps = {
    onTabClick: tabKeyName => onClickActiveTab(tabKeyName),
    activeKey: tab.activeKey,
    className: "NewBatch__Tabs",
    defaultActiveKey: defaultActiveKey
  };

  return (renderTabPanes(currentUser.systemRole, tabsProps));
}
