import React, { useContext, useEffect } from "react";
import { Form, message } from "antd";
import { client } from "../../../../../graphql/";
import { admin } from "../../../../graphql/admin";
import { shared } from "../../../../graphql/shared";
import { Loader } from "../../../../components/shared";
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { OnboardingLayout } from "../../../../components/shared";
import { CurrentUserContext } from "../../../../contexts/shared";
import { AuthServiceContext } from "../../../../contexts/shared/auth";
import {
  StepsNavigation
} from "../../../../components/entrepreneur/invitation/steps-navigation";
import {
  InformationForm
} from "../../../../components/entrepreneur/invitation/information/form";
import {
  formatValues
} from "../../../../components/entrepreneur/invitation/information/format-values";
import { UsageTrackingService } from "../../../../services";

export const InformationIndex = () => {
  const { id: invitationId } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const { authService } = useContext(AuthServiceContext);
  const { refetchCurrentUser } = useContext(CurrentUserContext);

  const { data, loading, error } = useQuery(
    shared.queries.getInvitationById,
    {
      client,
      variables: {
        id: invitationId
      }
    }
  );

  const [
    createInvitedUser,
    { loading: loadingNext }
  ] = useMutation(
    admin.mutations.createInvitedUser,
    { client, awaitRefetchQueries: true }
  );

  useEffect(() => {
    if(!loading){
      if(error || data?.invitation?.redeemed) {
        history.replace("/");
      }
      form.setFieldsValue({ email: data.invitation.email });
    }
  }, [data]);

  const redirectInvitedUser = invitedUserId => {
    history.push(`/v2/invitations/entrepreneur/${invitedUserId}/company`);
  };

  const onNext = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const formattedValues = formatValues(values, invitationId);

    const loginArgs = {
      variables: {
        email: formattedValues.email,
        password: formattedValues.password
      }
    };

    if(values.acceptedTermsAndConditions === true) {
      try {
        const result = await createInvitedUser({
          variables: { data: formattedValues }
        });
        const newUserId = result.data.createInvitedUser?.id;
        const newUser = {
          id: newUserId,
          systemRole: "ENTREPRENEUR",
          ...values
        };
        await UsageTrackingService
          .trackAcceptedInvitationUser({ user: newUser });
        await UsageTrackingService.trackNewUser({ user: newUser });

        await authService.login(loginArgs);
        await refetchCurrentUser();
        redirectInvitedUser(newUserId);
      } catch(e) {
        console.error(e);
        message.error("Ha ocurrido un error, \
      inténtalo de nuevo en unos segundos.");
      }
    } else {
      message.error("Debe aceptar términos y condiciones");
    }
  };

  return (
    <OnboardingLayout>
      <StepsNavigation current={0} />
      <div style={{maxWidth: "719px", margin: "0 auto"}}>
        <Loader
          data={data}
          error={error}
          loading={loading}>
          <InformationForm
            form={form}
            onNext={onNext}
            loadingNext={loadingNext} />
        </Loader>
      </div>
    </OnboardingLayout>
  );
};

