import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../../pages/login";
import { RequestIndex } from "../../pages/password-reset/request";
import { PasswordResetIndex } from "../../pages/password-reset";

export const AuthRoutes = <Switch>
  <Route
    path="/v2/login"
    component={Login} />
  <Route
    path="/v2/password-reset/request"
    component={RequestIndex} />
  <Route
    path="/v2/password-reset/:token"
    component={PasswordResetIndex} />
</Switch>;
