import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
import {
  Select,
  DatePicker,
  message,
  Button,
  Modal,
  Space,
  Typography
} from "antd";
import { remove as removeAccents } from "remove-accents";
import {
  InputTooltip,
  SearchToolbar,
  ProgramsSelect,
  ServiceCategoriesDropdown,
  GridContainer
} from "../../../../../../shared";
import {
  getReadableValue,
  scheduledServicesStatus,
  scheduledSupportedStatusInBatch,
  whiteListSupportesStatusInBatch
} from "../../../../../../../helpers";
import { ExportButton } from "../../filter-controls/export-button";
import {
  AdminMonitoringServicesService
} from "../../../../../../../services/admin-monitoring-services-service";

export function FilterControlsScheduled({
  onChange = null,
  refetch = null,
  filters = {},
  performAction = null,
  selectedRows = [],
  setSelectedRows = null
}) {
  const [canChangeStatus, setCanChangeStatus] = useState({
    status: null,
    afterStatus: null,
    noChanged: true
  });

  useEffect(() => {
    const statuses = selectedRows.map(el => el.status);
    const onlyStatus = [...new Set(statuses)];

    if (onlyStatus.length === 1) {
      const [status] = onlyStatus;

      if (whiteListSupportesStatusInBatch.includes(status)) {
        setCanChangeStatus({
          afterStatus: status,
          status: scheduledSupportedStatusInBatch[status]?.status,
          noChanged: false
        });
      }
    } else {
      setCanChangeStatus({ afterStatus: null, status: null, noChanged: true });
    }
  }, [selectedRows]);

  const handleOnChange = (filter = {}) =>
    onChange && onChange(filter);

  const handleOnSearchChange = debounce(text => {
    const stringValue = removeAccents(text).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ searchString: value });
  }, 500);

  const onDatePickerChange = (date, key) => {
    if(key === "fromAcceptedDate") {
      date && date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    if(key === "toAcceptedDate") {
      date && date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    }

    onChange && onChange({ [key]: date?.format() });
  };

  const handleOnClickExport = async ({
    turnExportLoaderOn,
    dismissExportLoader,
    variables
  }) => {
    try {
      turnExportLoaderOn();
      const reportScheduledExpertServices =
        await AdminMonitoringServicesService.generateReportScheduledServices(
          { filters, markAsPaid: variables?.markAsPaid }
        );
      refetch && await refetch();
      window.open(reportScheduledExpertServices?.url, "_blank");
    } catch(e) {
      console.error(e);
      message.error("Ocurrio un error al generar el reporte,\
        inténtalo de nuevo en unos segundos.");
    } finally {
      dismissExportLoader();
    }
  };

  const handleOnActionChangeStatus = status => {
    status && performAction && performAction(status, refetch);
  };

  const handleOnActionChangeConfirm = status => {
    const beforeStatus =
      getReadableValue(scheduledServicesStatus, canChangeStatus?.afterStatus);
    const afterStatus =
      getReadableValue(scheduledServicesStatus, status);
    const countSessionText = selectedRows.length === 1
      ? "1 sesión pasará" : `${selectedRows.length} sesiones pasarán`;

    return Modal.confirm({
      className: "ConfirmModal",
      width: 600,
      centered: true,
      closable: true,
      okText: "Sí, cambiar estado",
      icon: null,
      cancelText: "No, regresar",
      title: "Aplicar cambio de estado en lote",
      content: (
        <Space direction="vertical">
          <Typography.Paragraph>
            {countSessionText} de
            <strong> {beforeStatus?.toLowerCase()}</strong> a
            <strong> {afterStatus?.toLowerCase()}</strong>
          </Typography.Paragraph>
          <Typography.Paragraph strong>
            ¿Estás seguro de continuar?
          </Typography.Paragraph>
        </Space>
      ),
      onOk: () => handleOnActionChangeStatus(status)
    });
  };

  return (
    <GridContainer gutter={[16, 16]} style={{ marginBottom: "1rem" }}>
      <InputTooltip
        title="Filtrar desde fecha de aceptación por la Compañía"
        placement="topLeft">
        <DatePicker
          style={{ width: "100%" }}
          allowClear
          placeholder="Desde fecha de aceptación:"
          onChange={date => onDatePickerChange(date, "fromAcceptedDate")} />
      </InputTooltip>
      <InputTooltip
        title="Filtrar hasta fecha de aceptación por la Compañía"
        placement="topLeft">
        <DatePicker
          style={{ width: "100%" }}
          allowClear
          placeholder="hasta:"
          onChange={date => onDatePickerChange(date, "toAcceptedDate")} />
      </InputTooltip>
      <ServiceCategoriesDropdown
        style={{ width: "100%" }}
        onChange={val => handleOnChange({ category: val })} />
      <Select
        allowClear
        showSearch
        optionFilterProp="label"
        options={scheduledServicesStatus}
        style={{ width: "100%" }}
        onChange={val => {
          handleOnChange({ status: val });
          setSelectedRows && setSelectedRows([]);
        }}
        placeholder="Estado" />
      <ProgramsSelect
        onChange={val => handleOnChange({ programId: val })}
        placeholder="Programa"
        style={{ width: "100%" }} />
      <ExportButton
        className="MonitoringExpert__ExportButton"
        onClickExport={handleOnClickExport} />
      <SearchToolbar
        containerStyle={{ display: "block" }}
        style={{ minWidth: "100%", width: "100%", maxWidth: "100%" }}
        onSearchChange={e => handleOnSearchChange(e.target.value)} />
      <Button
        type="primary"
        disabled={canChangeStatus.noChanged}
        onClick={() => handleOnActionChangeConfirm(canChangeStatus.status)}
        style={{ width: "auto" }}>
        {canChangeStatus.noChanged
          ? "Cambiar estatus"
          : scheduledSupportedStatusInBatch[canChangeStatus.afterStatus]?.label
        }
      </Button>
    </GridContainer>
  );
}
