import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { client } from "../../../../graphql";
import { admin } from "../../../graphql/admin";
import { coordinator } from "../../../graphql/coordinator";
import { shared } from "../../../graphql/shared";
import { ProgramModalContext } from "./context";
import { ProgramModal } from "./modal";
import { CurrentUserContext } from "../current-user";
import { UsageTrackingService } from "../../../services";

export function ProgramModalProvider({children, refetch }) {
  const [state, setState] = useState({
    isModalOpen: false,
    program: undefined,
    loading: false
  });

  const { currentUser } = useContext(CurrentUserContext);

  const refetchVariables = {};

  if (currentUser.systemRole === "COORDINATOR") {
    refetchVariables.coordinatorId = currentUser.id;
  }

  const [upsertProgram] = useMutation(
    admin.mutations.upsertProgram,
    {
      awaitRefetchQueries: true,
      client,
      refetchQueries: [
        { query: admin.queries.getPrograms },
        {
          query: coordinator.queries.getAvailablePrograms,
          variables: refetchVariables
        }
      ]
    });

  const [deleteProgram] = useMutation(
    admin.mutations.deleteProgram,
    {
      awaitRefetchQueries: true,
      client,
      refetchQueries: [
        { query: admin.queries.getPrograms },
        {
          query: coordinator.queries.getAvailablePrograms,
          variables: refetchVariables
        }
      ]
    });

  const getProgram = async id => {
    return await client.query({
      query: shared.queries.getProgramById,
      variables: { id },
      fetchPolicy: "network-only"
    });
  };

  const openModal = async ({ programId } = {}) => {
    setState({ isModalOpen: true, loading: true });

    let result = {};

    if (programId)
      result = await getProgram(programId);

    setState(prevState => ({
      ...prevState,
      program: result?.data?.program,
      loading: false
    }));
  };

  const closeModal = () => {
    setState({ isModalOpen: false });
  };

  const onSave = async values => {
    const programAlreadyExists = values.variables?.id?.length > 0;
    const result = await upsertProgram(values);

    if (!programAlreadyExists) {
      await trackNewProgram({
        id: result.data?.upsertProgram.id,
        ...values.variables.create
      });
    }

    refetch && refetch();
    closeModal();
  };

  const trackNewProgram = async (program = {}) => {
    try {
      const addedBy = {
        id: currentUser.id,
        fullName: currentUser.fullName,
        email: currentUser.email,
        systemRole: currentUser.systemRole
      };

      await UsageTrackingService.trackNewProgram({ program, addedBy });
    } catch (e) {
      console.error(e);
    }
  };

  const updateProgramStatus = ({ id, disabled = false }) => {
    Modal.confirm({
      okText: disabled ? "Deshabilitar" : "Habilitar",
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: `¿Deseas ${disabled ? "deshabilitar" : " habilitar"}
        este programa?`,
      onOk: async () => {
        try {
          if (!id)
            throw "id is requiered but was not received";

          await upsertProgram({
            variables: {
              id,
              create: {},
              update: { disabled }
            },
            refetchQueries: [
              { query: shared.queries.getProgramById, variables: { id } }
            ],
            awaitRefetchQueries: true
          });

          if(refetch) {
            await refetch();
          }

          message.success("Programa actualizado.");
          closeModal();
        } catch (error) {
          message.error("Ha ocurrido un error, inténtalo de nuevo\
            en unos segundos.");
        }
      }
    });
  };

  const onDisableProgram = ({ id }) => {
    updateProgramStatus({ id, disabled: true });
  };

  const onReenableProgram = ({ id }) => {
    updateProgramStatus({ id, disabled: false });
  };

  const onDeleteProgram = async ({ id } = {}) => {
    const dismissLoader = message.loading("Verificando programa...");
    try {
      const result = await getProgram(id);
      let hasBatches = false;

      if (result?.data?.program?.batches?.length > 0)
        hasBatches = true;

      dismissLoader();

      Modal.confirm({
        okText: hasBatches ? "Aceptar" : "Eliminar",
        cancelText: "Cancelar",
        title: "Advertencia",
        icon: <ExclamationCircleOutlined />,
        content: hasBatches ?
        "No se puede eliminar el programa seleccionado debido a que\
          tiene batches relacionados."
        : "Estás a punto de eliminar un programa\
          ¿Deseas continuar?",
        onOk: hasBatches ?
          undefined
          : async () => {
            try {
              await deleteProgram({ variables: { id } });
              message.success("Programa eliminado.");
              refetch && refetch();
            } catch (e) {
              message.error("Ha ocurrido un error, inténtalo de nuevo\
              en unos segundos.");
              console.error(e);
            }
          }
      });
    } catch (e) {
      message.error("Ha ocurrido un error, inténtalo de nuevo\
            en unos segundos.");
      console.error(e);
    } finally {
      dismissLoader();
    }
  };

  const injectActions = {
    openModal,
    closeModal,
    onDisableProgram,
    onDeleteProgram,
    onReenableProgram
  };

  return (
    <ProgramModalContext.Provider value={injectActions}>
      <ProgramModal
        program={state.program}
        loading={state.loading}
        visible={state.isModalOpen}
        onCancel={closeModal}
        onSave={onSave} />
      {children}
    </ProgramModalContext.Provider>
  );
}
