import React from "react";
import { useParams } from "react-router-dom";
import "../../../../../../App.css";
import { useService } from "../../../../../hooks/shared"
import { OdesService } from "../../../../../services/odes-service";
import { OnePager } from "../../../../../components/shared";

export const OnePagerIndex = () => {

  const { id = "" } = useParams();

  const { data } = useService(
    OdesService.getById,
    { id: id });

  return (
    <OnePager ode={data} />
  );
};
