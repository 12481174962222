import gql from "graphql-tag";

export const getOdes = gql`
  query GetOdes($where: ODEWhereInput) {
    oDEs(where: $where) {
      id
      name
      logoUrl
      sector
      team {
        id
        profilePicUrl
        fullName
        email
        phone
      }
    }
  }
`;
