import React from "react";
import { EmptyImage } from "../empty-image";

const defaultStyles = {
  width: "100%",
  height: "200px",
  objectFit: "cover"
};

export function CoverImageFit ({ url, icon, text, style = {} }) {
  return (
    url ?
      <img
        style={{
          ...defaultStyles,
          ...style
        }}
        src={url} />
      : <EmptyImage icon={icon} text={text} />
  );
}
