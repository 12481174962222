import React from "react";
import { Space, Form, Input } from "antd";
import { genericFieldValidationMsgs } from "../../../utils";

export function EditableField({onChange, objective, form}) {
  const onChangeForm = () => {
    const values = form.getFieldsValue();

    onChange && onChange(values);
  };

  return (
    <Space
      direction="vertical"
      size={5}
      style={{width:"35vw"}}>
      <Form
        validateMessages={genericFieldValidationMsgs}
        onChange={onChangeForm}
        form={form}
        className="panel-heading-form">
        <Form.Item
          rules={[{ required: true }]}
          name="name"
          initialValue={objective.name}>
          <Input
            type="text"
            placeholder="Nombre del objetivo" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="description"
          initialValue={objective.description}>
          <Input.TextArea
            rows={2}
            placeholder="Descripción del objetivo" />
        </Form.Item>
      </Form>
    </Space>
  );
}
