import React, { useContext } from "react";
import { Typography } from "antd";
import { SidebarLayout, Section } from "../../../../../shared";
import { MainMenu } from "../../../../../coordinator/shared";
import Breadcrumbs from "../breadcrumbs";
import Navbar from "../navbar";
import {
  ActionButton
} from "../../../../../shared/expert-service-card/action-button";
import {
  ProgramDetailContext
} from "../../../../../../contexts/admin/programs/program-detail";
import {
  ObjectiveDetailContext
} from "../../../../../../contexts/admin/programs/objectives/objective-detail";

export function ShowLayout({ children, selectedKeys }) {
  const { data, loading } = useContext(ObjectiveDetailContext);
  const { data: programData } = useContext(ProgramDetailContext);

  const objective = data?.objective;
  const program = programData?.program;

  return (
    <SidebarLayout menu={<MainMenu />}>
      <Breadcrumbs
        program={program}
        objective={objective}
        extra={<ActionButton />} />
      <Section loading={loading}>
        <Typography.Title level={4}>
          {objective?.name || "N/A"}
        </Typography.Title>
        <Typography.Text>
          {objective?.description || "N/A"}
        </Typography.Text>
      </Section>
      <Navbar selectedKeys={selectedKeys} />
      {children}
    </SidebarLayout>
  );
}
