import React from "react";
import { Button } from "antd";
import { Visibility } from "../../../../shared";

export const ControllerButtons = ({
  onPrev,
  onNext,
  onAgree,
  onDisagree,
  disableNext,
  loading,
  isInvitedFromOde
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "83px" }}>
      <Button
        loading={loading.prev}
        onClick={onPrev}>Anterior</Button>
      <Visibility visible={isInvitedFromOde}>
        <div>
          <Button
            style={{
              color: "#5F39DE",
              border: "1px solid #5F39DE",
              marginRight: "25px"
            }}
            loading={loading.disagree}
            onClick={onDisagree}>
            No, esta no es mi compañía
          </Button>
          <Button
            type="primary"
            onClick={onAgree}>
            Sí, la información es correcta
          </Button>
        </div>
      </Visibility>
      <Visibility visible={!isInvitedFromOde}>
        <Button
          type="primary"
          disabled={disableNext}
          loading={loading.next}
          onClick={onNext}>Siguiente</Button>
      </Visibility>
    </div>
  );
};
