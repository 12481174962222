export function industriesToChart (industries = []) {
  const labels = [];
  // const data = [];
  const datasets = [];

  const secureIndustries = industries
    .filter(i => i["Company.companyIndustry"] !== null);

  secureIndustries.forEach((industry, idx) => {
    labels.push(industry["Company.companyIndustry"]);
    // data.push(industry["Company.companiesCount"]);

    const data = new Array(secureIndustries.length);
    data.fill(0, 0, secureIndustries.length - 1);
    data[idx] = parseInt(industry["Company.companiesCount"]);

    datasets.push({
      label: industry["Company.companyIndustry"],
      data,
      backgroundColor: '#751919',
      barThickness: 10
    });
  });

  return {
    labels,
    datasets
  };
}
