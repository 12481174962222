import {
  Card, Form, message, Select, Tooltip, DatePicker, Input, Row, Col
} from "antd";
import React, { useState } from "react";
import { useService } from "../../../../hooks/shared";
import { BatchesService } from "../../../../services";
import { ProgramService } from "../../../../services/program-service";
import { WarningTwoTone } from "@ant-design/icons";

export const BatchFilters = ({
  onBatchChange,
  onProgramChange,
  onDateChange
}) => {
  const [selectedProgram, setSelectedProgram] = useState(null);

  const {
    data: programs,
    loading: loadingPrograms
  } = useService(
    ProgramService.getAll
  );

  const {
    data: batches,
    refetch: refetchBatches
  } = useService(
    BatchesService.getByProgram,
    { programId: selectedProgram },
    { shouldFetch: !!selectedProgram }
  );

  const [form]  = Form.useForm();

  const onSelectProgram = async programId => {
    const dismissLoader = message.loading("Cargando batches...");
    setSelectedProgram(programId);
    refetchBatches({ programId: programId })
      .finally(() => dismissLoader());

    onProgramChange && onProgramChange(programId);
    form.setFieldsValue({ batch: undefined });
  };

  const onSelectBatch = batchId => {
    onBatchChange && onBatchChange(batchId);
  };

  const handleOnDateChange = dates => {
    if (!dates) {
      onDateChange && onDateChange(); // clear dates
      return;
    }

    const [since, until] = dates;

    onDateChange && onDateChange(
      since.format("YYYY-MM-DD"),
      until && until.format("YYYY-MM-DD")
    );
  };

  return (
    <Card>
      <Form form={form}>
        <Row align="middle" gutter={[5, 10]}>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={6}>
            <Form.Item name="program">
              <Select
                {...sharedProps}
                placeholder="Programa"
                loading={loadingPrograms}
                onChange={onSelectProgram}
                disabled={programs?.length < 1}
                options={programs?.map(p => ({
              value: p.id,
              label: p.name
            }) || [])} />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={6}>
            <Form.Item name="batch">
              <Select
                {...sharedProps}
                placeholder="Batch"
                onChange={onSelectBatch}
                disabled={!batches?.length}
                options={batches?.map(b => ({
              value: b.id,
              label: b.name
            })) || []} />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={6}>
            <Tooltip
              position="top"
              title="Se aplicará sobre las fecha de Inicio del batch">
              <Form.Item name="range">
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  onChange={handleOnDateChange} />
              </Form.Item>
            </Tooltip>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={6}>
            <Form.Item name="career">
              <Input
                {...sharedProps}
                status="warning"
                prefix={<WarningTwoTone twoToneColor="#F90" />}
                placeholder="Carrera del emprendedor"
                options={[]} />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={12}>
            <Form.Item name="campus">
              <Input
                {...sharedProps}
                status="warning"
                prefix={<WarningTwoTone twoToneColor="#F90" />}
                placeholder="Campus del emprendedor"
                options={[]} />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={12}>
            <Form.Item name="entrepreneurType">
              <Input
                {...sharedProps}
                status="warning"
                prefix={<WarningTwoTone twoToneColor="#F90" />}
                placeholder="Tipo de emprendedor"
                options={[]} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

const sharedProps = {
  optionFilterProp: "label",
  allowClear: true,
  showSearch: true,
  style: { width: "100%" }
};