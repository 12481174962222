import React, { useContext } from "react";
import { Form } from "antd";
import { Section, Visibility } from "../../../";
import { ActionButton } from "../../action-button";
import { LanguagesForm } from "../form";
import {
  EditProfileContext
} from "../../../../../contexts/shared/edit-profile";
import { formatValues } from "./formatValues";

export function LanguagesEditSection({ editable = false }) {

  const {
    saveChanges,
    editing,
    setEditing,
    data
  } = useContext(EditProfileContext);

  const oldLanguages = data?.user?.expertProfile?.professionalExperience?.languages || [];

  const onSave = () => {
    const { languages } = form.getFieldsValue();
    const data = formatValues(languages, oldLanguages);

    saveChanges(data, "languages");
  };

  const [form] = Form.useForm();

  return (
    <Section
      spaced
      title="Idiomas"
      bottom={
        <Visibility visible={editable}>
          <ActionButton
            editing={editing.languages}
            setEditing={setEditing}
            formName="languages"
            visible
            onSave={onSave} />
        </Visibility>}>
      <LanguagesForm
        form={form}
        editable={editing.languages && editable} />
    </Section>
  );
}
