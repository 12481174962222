import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  default as GeneralInformationIndex
} from "../../../pages/admin/odes/show/general-information";
import {
  default as MyDocumentsIndex
} from "../../../pages/admin/odes/show/my-documents";
import {
  default as PitchIndex
} from "../../../pages/admin/odes/show/pitch";
import { OdesIndex } from "../../../pages/admin/odes";
import {
  default as OdeTeamIndex
} from "../../../pages/admin/odes/show/team";
import {
  default as BatchesIndex
} from "../../../pages/admin/odes/show/batches";
import { OnePagerIndex } from "../../../pages/admin/odes/show/one-pager";
import MetricsIndex from "../../../pages/admin/odes/show/metrics";

export const AdminODEsRoutes = <Switch>
  <Route
    exact
    path="/v2/admin/odes"
    component={OdesIndex} />
  <Route
    path="/v2/admin/odes/:id/one-pager"
    component={OnePagerIndex} />
  <Route
    path="/v2/admin/odes/:id/general-information"
    component={GeneralInformationIndex} />
  <Route
    path="/v2/admin/odes/:id/my-documents"
    component={MyDocumentsIndex} />
  <Route
    path="/v2/admin/odes/:id/pitch"
    component={PitchIndex} />
  <Route
    path="/v2/admin/odes/:id/team"
    component={OdeTeamIndex} />
  <Route
    path="/v2/admin/odes/:id/batches"
    component={BatchesIndex} />
  <Route
    path="/v2/admin/odes/:id/metrics"
    component={MetricsIndex} />
</Switch>;
