import { shared } from "../../../../graphql/shared";
import { eventTypeEnum } from "../../../../helpers/constants/event-type-enum";

/**
 * @param {*} scheduledService Must contain id and assignationIdentifier
 * @param {*} status Should be AUTHORIZED_FOR_PAYMENT or PAID
 */
export async function updatePaymentStatus(scheduledService, status, blameId) {
  if (!status) {
    throw new Error("status param is required");
  }

  const isWorkshop = scheduledService
    ?.assignedExpertService
    ?.expertService
    ?.type === "WORKSHOP";

  const assignedExpertService = scheduledService?.assignedExpertService;
  const assignationIdentifier = assignedExpertService?.assignationIdentifier;

  // passing an undefined value in the where constraint
  // could update all the services
  if (!scheduledService?.id) {
    throw new Error("id is required");
  }

  if (isWorkshop && !assignationIdentifier)
    throw new Error("assignationIdentifier is required for workshops");

  const where = { };

  if (isWorkshop) {
    where.assignedExpertService = {
      assignationIdentifier: assignationIdentifier
    };
  } else {
    where.id = scheduledService.id;
  }

  return await this.client.mutate({
    mutation: shared.mutations.updateScheduledServices,
    variables: {
      where,
      data: {
        status: status,
        events: {
          create: {
            event: eventTypeEnum.STATUS_CHANGED,
            description: `Status changed to ${status}`,
            blame: { connect: { id: blameId } }
          }
        }
      }
    }
  });
}
