export function businessModelToChart (businessModels = []) {
  const labels = [];
  const datasets = [];

  businessModels.forEach((businessModel, idx) => {
    labels.push(businessModel["Company.companyBussinessModel"]);

    const data = new Array(businessModels.length);
    data.fill(0, 0, businessModels.length - 1);
    data[idx] = parseInt(businessModel["Company.companiesCount"]);

    datasets.push({
      label: businessModel["Company.companyBussinessModel"],
      data,
      backgroundColor: COLORS[idx],
      barThickness: 30
    });
  });

  return {
    labels,
    datasets
  };
}

const COLORS = [
  "#FEB7D6",
  "#DABEF6",
  "#C6D6FB",
  "#9FD9FE"
];
