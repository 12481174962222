import React from "react";
import { Modal, Form, Input, message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { client } from "../../../../graphql";
import { admin } from "../../../graphql/admin";
import { genericFieldValidationMsgs } from "../../../utils";

export function AchievementModal({ onSave, onCancel, objectiveId, ...props }) {
  const [form] = Form.useForm();

  const [createObjectiveAchievement] = useMutation(
    admin.mutations.createObjectiveAchievement,
    {
      refetchQueries: [{
        query: admin.queries.getAchievements,
        variables: {
          where: {
            objective: { id: objectiveId }
          }
        }
      }],
      client
    }
  );

  const onOk = async() => {
    const dismissLoading = message.loading("Guardando...");

    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      values.objective = { connect: { id: objectiveId } };

      const newObjective = await createObjectiveAchievement({
        variables: { data: values }
      });

      message.success("Logro creado.");

      onSave && onSave(newObjective);
      form.resetFields();
    }
    catch(e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    }
    finally {
      dismissLoading();
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  return (
    <Modal
      destroyOnClose
      okText="Guardar"
      cancelText="Cancelar"
      title="Nuevo logro"
      onOk={onOk}
      onCancel={onCancelModal}
      {...props}>
      <Form
        validateMessages={genericFieldValidationMsgs}
        form={form}
        layout="vertical">
        <Form.Item
          rules={[{ required: true }]}
          name="name"
          label="Nombre">
          <Input placeholder="Nombre del logro" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descripción">
          <Input.TextArea
            rows={5}
            placeholder="Descripción del logro" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
