import React, { useEffect } from "react";
import moment from "moment";
import { LinkOutlined } from "@ant-design/icons";
import { FlexContainer } from "../../../../../shared";
import { Divider, Form, List, Typography } from "antd";
import { ExamplesDisplay } from "../../../../../shared/examples-display";
import {
  ScheduleExpertServiceForm
} from "../../components/forms/schedule-expert-service-form";

export function DetailContent({
  scheduledExpertService = null
}) {
  const { expertServiceData = {} } =
  scheduledExpertService?.assignedExpertService || {};

  const { examples = [], links = [] } = expertServiceData;
  const [form] = Form.useForm();

  useEffect(() => {
    if (scheduledExpertService) {
      form.setFieldsValue({
        serviceDate: moment(scheduledExpertService?.serviceDate),
        serviceHour: moment(scheduledExpertService?.serviceDate),
        duration: scheduledExpertService?.duration,
        needs: scheduledExpertService?.needs
      });
    }
  }, [scheduledExpertService]);

  return (
    <FlexContainer direction="vertical">
      <Typography.Title strong level={5}>
        Información de la sesión
      </Typography.Title>
      <ScheduleExpertServiceForm form={form} readOnly />
      <Divider />
      <Typography.Title strong level={5}>
        Información del servicio
      </Typography.Title>
      <br />
      <Typography.Text type="secondary">
        Descripción:
      </Typography.Text>
      <Typography.Text>
        {expertServiceData?.description}
      </Typography.Text>
      <br />
      <Typography.Text type="secondary">
        Galería de ejemplos:
      </Typography.Text>
      <ExamplesDisplay examples={examples} />
      <br />
      <Typography.Text type="secondary">
        Enlaces y archivos:
      </Typography.Text>
      <List
        size="small"
        dataSource={links}
        renderItem={link => (
          <List.Item key={link}>
            <Typography.Link
              href={link}
              target="_blank">
              <LinkOutlined /> {link}
            </Typography.Link>
          </List.Item>)
        } />
    </FlexContainer>
  );
}
