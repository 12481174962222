import gql from "graphql-tag";

export const getAvailableServices = gql`
  query GetAvailableServices($id: ID!) {
    batch(where: { id: $id }){
      id
      services{
        id
        name
        category
        coverPicUrl
        expert{
          id
          fullName
        }
      }
    }
  }
`;
