import { client } from "../../../graphql";
import { getMasterContentByBatchId } from "./graphql";

export const getMasterContentByBatchIdService = async ({ batchId }) => {
  const result = await client.query({
    query: getMasterContentByBatchId,
    variables: {
      id: batchId
    },
    fetchPolicy: "network-only"
  });

  return result.data || {};
};
