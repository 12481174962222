export const formatValues = ({ curriculumVitae,  ...values }) => {

  let cv = undefined;

  // The upload component returns its value as an array
  if (curriculumVitae?.length === 1) {
    delete curriculumVitae[0].status;

    cv = {
      fileName: curriculumVitae[0].name,
      type: curriculumVitae[0].type,
      url: curriculumVitae[0].url || curriculumVitae[0].response?.imageUrl
    };
  }

  values = {
    skills: { set: values?.skills },
    areas: { set: values?.areas },
    industries: { set: values?.industries }
  };

  const createCV = {
    curriculumVitae: (cv !== undefined) ? { create: cv } : undefined
  };

  return {
    expertProfile: {
      upsert: {
        create: {
          professionalExperience: {
            create: {
              ...values,
              ...createCV
            }
          }
        },
        update: {
          professionalExperience: {
            upsert: {
              create: {
                ...values,
                ...createCV
              },
              update: {
                ...values,
                ...createCV
              }
            }
          }
        }
      }
    }
  };
};
