import gql from "graphql-tag";

export const getBatchEventById = gql`
  query GetBatchEventById($id: ID!) {
    batchEvent(where: { id: $id }) {
      id
      name
      link
      location
      description
      materials {
        id
        url
        type
        fileName
        uploadDate
      }
    }
  }
`;
