import React from "react";
import { Space } from "antd";
import {
  TwitterOutlined,
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  LinkOutlined
} from "@ant-design/icons";
import { LargeIcon } from "./large-icon";

export const ReadOnlyItem = ({ value: url = "", socialNetwork = ""}) => {

  const getIcon = () => {
    const iconsMap = {
      "twitter": TwitterOutlined,
      "facebook": FacebookFilled,
      "instagram": InstagramOutlined,
      "linkedin": LinkedinFilled,
      "other": LinkOutlined
    };

    return iconsMap[socialNetwork];
  };

  const getClickableLink = link => {
    return link.startsWith("http://") || link.startsWith("https://")
      ? link
      : `//${link}`;
  };

  return (
    <Space>
      <LargeIcon icon={getIcon()} />
      <a
        href={getClickableLink(url)}
        target="_blank"
        rel="noopener noreferrer"
        style={{color: "#6348E5"}}>
        {url}
      </a>
    </Space>
  );
};
