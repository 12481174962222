import gql from "graphql-tag";

export const acceptScheduledExpertService = gql`
  mutation UpdateScheduled(
    $id: ID!,
    $events: ServiceEventUpdateManyInput
  ) {
    updateScheduledExpertService (
      where: { id: $id }
      data: {
        status: "ACCEPTED",
        events: $events      
      }
    ) {
      id
    }
  }
`;
