import { GET_LIGHT_BATCH_COMPANIES } from "./graphql/get-light-batch-companies";
import {
  graphqlApolloClient
} from "../data-connection-service/graphql-apollo-client";

export const getLightBatchCompanies = async ({ batchId }) => {
  const response = await graphqlApolloClient.query({
    query: GET_LIGHT_BATCH_COMPANIES,
    fetchPolicy: "network-only",
    variables: { batchId }
  });

  return response?.data?.batch?.odes || [];
};
