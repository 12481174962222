import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { HeaderTexts } from "../../header-texts";

export const ComposedHeader = () => {

  const history = useHistory();

  const onSkip = () => {
    history.push(`./publish`);
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between", margin: "30px 13px"  }}>
      <HeaderTexts
        title="Agrega el contenido de tu batch"
        description="Agrega los contenidos de tu batch dividido por secciones" />
      <Button
        type="link"
        onClick={onSkip}
        style={{ marginRight: "24px" }}>Saltar este paso</Button>
    </div>
  )
}
