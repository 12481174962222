import React, { useState, useContext, useEffect } from "react";
import { DatePicker, Form, Select } from "antd";
import { Visibility } from "../../../../../components/shared";
import { companyStatusOptions } from "../../../../../helpers/select-options";
import { patentStateOptions } from "../../../../../helpers/select-options/shared/patent-state";
import { developmentPhaseOptions } from "../../../../../helpers/select-options/shared/development-phase";
import { TrlInput } from "./trl-input";
import { ReportFormModalContext } from "../../context";
import { AmountInput } from "../fundings-form/amount-input";
import { clone, cloneDeep } from "lodash";
import moment from "moment";

export function GeneralForm() {

  const {
    updateChanges,
    report,
    viewMode
  } = useContext(ReportFormModalContext);

  const [state, setState] = useState({
    inDevelopment: undefined
  });

  const [form] = Form.useForm();

  useEffect(() => {
    const data = clone(report);

    if (data.startDate)
      data.startDate = moment(data.startDate);

    if (data.productInDevelopment !== undefined)
      onDevelopmentChange(data.productInDevelopment);

    form.setFieldsValue(data);
  }, []);

  const onDevelopmentChange = value => {
    setState(prevState => ({
      ...prevState,
      inDevelopment: value
    }));
  };

  const onFormChange = (_, values) => {
    const data = cloneDeep(values);
    data.startDate = values.startDate?.toISOString();
    updateChanges(data);
  };

  return (
    <Form
      style={{ marginTop: "15px" }}
      onValuesChange={onFormChange}
      layout="vertical"
      title="General"
      contentEditable="false"
      form={form}>
      <Form.Item
        label="Periodo"
        name="startDate">
        <DatePicker disabled={viewMode} picker="month" className="wide-input" />
      </Form.Item>
      <Form.Item
        label="¿La empresa está en operación? ¿Genera ingresos regularmente?"
        name="companyStatus">
        <Select
          disabled={viewMode}
          placeholder="Selecciona una opción"
          options={companyStatusOptions} />
      </Form.Item>
      <Form.Item
        label="Monto de preventas"
        name="presales">
        <AmountInput viewMode={viewMode} />
      </Form.Item>
      <Form.Item
        label="Monto de ventas mensuales"
        name="monthlySales">
        <AmountInput viewMode={viewMode} />
      </Form.Item>
      <Form.Item
        label="Producto tecnológico en desarrollo"
        name="productInDevelopment">
        <Select
          disabled={viewMode}
          placeholder="Selecciona una opción"
          onChange={onDevelopmentChange}
          options={[
            { label: "Si", value: true },
            { label: "No", value: false }
          ]} />
      </Form.Item>
      <Visibility
        visible={state.inDevelopment}>
        <Form.Item
          name="patentStatus">
          <Select
            disabled={viewMode}
            placeholder="Estado de la patente"
            options={patentStateOptions} />
        </Form.Item>
      </Visibility>
      <Visibility
        visible={state.inDevelopment === false}>
        <Form.Item
          name="productPhase">
          <Select
            disabled={viewMode}
            placeholder="Fase de desarrollo del producto"
            options={developmentPhaseOptions} />
        </Form.Item>
      </Visibility>
      <Form.Item
        label="Nivel TRL"
        name="trlLevels">
        <TrlInput viewMode={viewMode} />
      </Form.Item>
    </Form>
  );
}
