import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useQueryFilters, useControlledPagination } from "../../../helpers";
import { client } from "../../../../graphql";
import { expert } from "../../../graphql/expert";
import { SidebarLayout, Section } from "../../../components/shared";
import {
  MenuContext,
  CurrentUserContext,
  PaginationContext,
  CreateOdeModalProvider,
  ImportOdeModalProvider
} from "../../../contexts/shared";
import {
  default as OdesList
} from "../../../components/shared/ode-list";
import { FilterControlsOdes } from "../../../components/shared";

export const OdesIndex = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/odes"]
  });

  const defaultVariables = {
    id: currentUser.id,
    first: 10
  };

  const { loading, data, error, refetch, variables } = useQuery(
    expert.queries.getOdesByExpertId,
    {
      client,
      fetchPolicy: "network-only",
      variables: defaultVariables }
  );

  const { applyFilters } = useQueryFilters({
    refetch
  });
  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 10
  });

  const onFiltersChange = filter => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters((prevFilters = {}) => {
      if (!filter.batchId && !filter.programId) {
        // Omit for new ODEs without connected batch
        filter.batches_some = undefined;
      } else {
        filter.batches_some = {
          id: filter.batchId,
          program: { id: filter.programId }
        };
      }

      return ({
        ...prevFilters,
        ...filter,
        skip,
        first
      });
    });
  };

  const { registerRefetch } = useContext(PaginationContext);
  registerRefetch("odes", refetch, variables);

  return (
    <CreateOdeModalProvider>
      <ImportOdeModalProvider>
        <SidebarLayout>
          <Section
            title="Compañías"
            spaced
            extra={<FilterControlsOdes onChange={onFiltersChange} />}>
            <OdesList
              data={data}
              error={error}
              isLoading={loading}
              onChangePage={onPaginationChange}
              currentPage={currentPage}  />
          </Section>
        </SidebarLayout>
      </ImportOdeModalProvider>
    </CreateOdeModalProvider>
  );
};
