import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Row, Statistic } from "antd";
import { AnalyticsService } from "../../../../services/analytics-service";
import { getPercentage } from "../../../../helpers";

export const BatchStatisticsCountResume = ({
  selectedBatch = null,
  selectedProgram = null,
  batchRangeDates
}) => {
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    const loadStatistics = async () => {
      const statistics = await  AnalyticsService
        .getCompaniesStatistics({
          batchId: selectedBatch,
          programId: selectedProgram,
          since: batchRangeDates?.since,
          until: batchRangeDates?.until
        });
      setStatistics(statistics);
    };

    setLoading(true);
    loadStatistics()
      .then()
      .finally(() => setLoading(false));
  }, [selectedBatch, selectedProgram, batchRangeDates]);

  const batchIsSelected = ![null, undefined].includes(selectedBatch);

  const techBasedText =
    `(${statistics.techBasedCompaniesCount || 0}) ${getPercentage(statistics.companiesCount, statistics.techBasedCompaniesCount).toFixed(2)}%`;

  const socialText =
    `(${statistics.socialCompaniesCount || 0}) ${getPercentage(statistics.companiesCount, statistics.socialCompaniesCount).toFixed(2)}%`;

  return (
    <Card
      title="Compañías"
      loading={loading}
      style={{ marginTop: "2rem" }}>
      <Row gutter={16}>
        <Col span={8}>
          <Statistic
            title={"Total"}
            value={statistics.companiesCount || 0} />
        </Col>
        {batchIsSelected
          ? <Col span={8}>
            <Statistic
              title={"Baja"}
              value={statistics.unEnrolledCompaniesCount || 0} />
          </Col>
        : null}
        {batchIsSelected
          ? <Col span={8}>
            <Statistic
              title={"Batch terminado"}
              value={statistics.completedBatchCompaniesCount || 0} />
          </Col>
          : null}
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={8}>
          <Statistic
            title={"Tecnología"}
            value={techBasedText} />
        </Col>
        <Col span={8}>
          <Statistic
            title={"Social"}
            value={socialText} />
        </Col>
      </Row>
    </Card>
  );
};
