import { client } from "../../../graphql";
import {
  getEntrepreneurCompaniesInBatchQuery
} from "./graphql/get-entrepreneur-companies-in-batch";

export async function getEntrepreneurCompaniesInBatch({
  entrepreneurId,
  batchId
}) {
  const result = await client.query({
    query: getEntrepreneurCompaniesInBatchQuery,
    fetchPolicy: "network-only",
    variables: {
      batchId,
      entrepreneurId
    }});

  return result.data?.oDEs || [];
}
