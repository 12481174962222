import React, { useContext } from "react";
import { List } from "antd";
import { BatchItem } from "../../admin/batches/list/item";
import {
  withLoader,
  withRouter,
  cardGridSizes,
  paginationConfig
} from "../../../helpers";
import { CurrentUserContext } from "../../../contexts/shared";
import useTreatment from "../../../hooks/feature-flags/use-treatment";

/**
 * @hasFeatureFlags
 * @param data
 * @param loading
 * @param navigate
 * @param odeId
 * @returns {JSX.Element}
 * @constructor
 */

function BatchesList({ data, loading, navigate, odeId }) {
  const { currentUser } = useContext(CurrentUserContext);

  const disableBatchOfferTreatmentValue =
    useTreatment("DisableBatchOffer");

  const getRouteByUserType = (batch, odeId) => {

    let roleRoutes = {
      ADVISER: `../../odes/${odeId}/batches/${batch?.id}`,
      ENTREPRENEUR: `../../odes/${odeId}/batches/${batch?.id}`,
      SUPER_ADMIN: `../../batches/${batch?.id}/experts`,
      COORDINATOR: `../../batches/${batch?.id}/experts`
    };

    /* We are using an if-else block to allow new treatments implementation */
    if (disableBatchOfferTreatmentValue === "ON") {
      roleRoutes = {
        SUPER_ADMIN : `../../batches/${batch?.id}/experts`,
        COORDINATOR : `../../batches/${batch?.id}/experts`,
        ENTREPRENEUR : `../../odes/${odeId}/batches/${batch?.id}`,
        ADVISER : `../../odes/${odeId}/batches/${batch?.id}`
      };
      return roleRoutes[currentUser.systemRole];
    } else if (disableBatchOfferTreatmentValue === "OFF") {
      roleRoutes = {
        SUPER_ADMIN : `../../batches/${batch?.id}/offer?section=content`,
        COORDINATOR : `../../batches/${batch?.id}/offer?section=content`,
        ENTREPRENEUR : `../../batches/${batch?.id}/offer?section=content`,
        ADVISER : `../../batches/${batch?.id}/offer?section=content`
      };
      return roleRoutes[currentUser.systemRole];
    }
  };

  return (
    <List
      style={{ marginTop: "24px" }}
      pagination={{
        ...paginationConfig,
        defaultPageSize: 12
      }}
      loading={loading}
      grid={cardGridSizes}
      dataSource={data?.batches}
      renderItem={batch =>
        <List.Item>
          <BatchItem
            batch={batch}
            onClick={() => navigate(getRouteByUserType(batch, odeId))} />
        </List.Item>
      } />
  );
}


export default withLoader(withRouter(BatchesList));
