import { client } from "../../../graphql";
import { getPublishedContents } from "./graphql";

export const getPublishedContentsService = async ({ batchId, companyId }) => {
  const result = await client.query({
    query: getPublishedContents,
    variables: {
      where: {
        ode: { id: companyId },
        masterContent: { batch: { id: batchId } }
      },
      odeId: companyId
    },
    fetchPolicy: "network-only"
  });

  return result.data || [];
};