import React from "react";
import { Document, Font } from "@react-pdf/renderer";
import { PageOne } from "./pages/page-one";
import { PageTwo } from "./pages/page-two";
import { PageThree } from "./pages/page-three";
import RobotoRegular from "./Fonts/Roboto-Regular.ttf";
import RobotoBold from "./Fonts/Roboto-Bold.ttf";

export const ReportDocument = ({ pages }) => {
  return (
    <Document title="Reporte de Pago">
      <PageOne data={pages[0]} />
      <PageTwo data={pages[1]} />
      <PageThree data={pages[2]} />
    </Document>
  );
};

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular },
    { src: RobotoBold, fontWeight: 700 }
  ]
});
