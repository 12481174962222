import React, { useEffect, useState, useRef } from "react";
import { List, Space, Input, Form, message } from "antd";
import { LinkOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Visibility, CompositeField } from "../index";
import { urlPatterns } from "../../../utils";
import UploadArea from "../upload-area";
import { getClickableLink, validateUrl } from "../../../helpers";
import { InputTooltip } from "../../shared";

export function LinksField({ onChange, value, showUploadArea = true, ...props }) {
  const [form] = Form.useForm();
  const [state, setState] = useState({ values: [] });

  const linkInputRef = useRef(Input);

  if (value && !Array.isArray(value)) {
    throw new Error("LinksField: value prop should be an array of strings");
  }

  useEffect(() => {
    if (value) {
      setState({ values: value.map(l => ({ link: l })) });
    }
  }, [value]);

  const handleOnChange = values => {
    onChange(values.map(l => l.link));
  };

  const onAddLink = (addNew, replaceItemAtIndex) =>
    async () => {
      const link = form.getFieldValue("link");
      if (!link) {
        return;
      }

      const validatedLink = validateUrl(link);
      if(validatedLink === null) {
        message.error("El enlace introducido no es válido");
        return;
      }

      const values = { link };

      await form.validateFields();
      if (typeof (values.index) === "undefined") {
        addNew(values);
      } else {
        const index = values.index;
        delete values.index;
        replaceItemAtIndex(index, values);
      }
      form.resetFields();
      linkInputRef.current.focus();
    };

  const onUploadFile = addNew => e => {
    const fileUrl = e?.file?.response?.imageUrl;
    addNew({ link: fileUrl });
  };

  return (
    <CompositeField
      isAddDisabled
      onChange={handleOnChange}
      value={state.values}
      style={{ marginBottom: "20px" }}>
      {({ items, addNew, removeItem, replaceItemAtIndex }) =>
        <>
          <Form form={form}>
            <InputTooltip title="Usa el botón + o Enter para agregar el link">
              <Form.Item
                name="link"
                required
                rules={[{
                  pattern: urlPatterns.url,
                  message: "El formato de la URL no es válido"
                }]}>
                <Input
                  ref={linkInputRef}
                  placeholder="wikipedia.com"
                  onPressEnter={onAddLink(addNew, replaceItemAtIndex)}
                  addonAfter={
                    <PlusOutlined
                      className="clickable"
                      onClick={onAddLink(addNew, replaceItemAtIndex)} />
                  } />
              </Form.Item>
            </InputTooltip>
          </Form>
          <Visibility visible={showUploadArea}>
            <UploadArea
              onChange={onUploadFile(addNew)}
              previewImage={false}
              showUploadList={false}>
              Adjuntar archivo
            </UploadArea>
          </Visibility>
          <List>
            {items.map((item, index) =>
              <List.Item key={item.uuid || index.toString()}>
                <Space
                  align="baseline"
                  style={{ justifyContent: "space-between", width: "100%" }}>
                  <Space
                    style={{
                      width: "450px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                    <LinkOutlined />
                    <a
                      target="_blank"
                      href={getClickableLink(item.link)}>
                      {item.link}
                    </a>
                  </Space>
                  <DeleteOutlined onClick={removeItem(index)} />
                </Space>
              </List.Item>
            )}
          </List>
        </>
      }
    </CompositeField>
  );
}
