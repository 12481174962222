import React from "react";
import { PaginationContext } from "./context";

export function PaginationContextProvider({ children }) {
  const refetchList = {};

  const registerRefetch = (name, refetch, variables) => {
    refetchList[name] = { refetch, variables };
  };

  const onPaginationChange = name => (page, pageSize) => {
    const { refetch, variables } = refetchList[name];

    page--;

    const skip = pageSize * page;

    refetch({ ...variables, skip, first: pageSize });
  };

  return (
    <PaginationContext.Provider value={{ refetchList, registerRefetch, onPaginationChange }}>
      { children }
    </PaginationContext.Provider>
  );
}
