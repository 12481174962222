import React from "react";
import { List, Space } from "antd";
import { MinusCircleOutlined, EditOutlined } from "@ant-design/icons";
import moment from "moment";

const { Item } = List;

export const VolunteeringItem = ({ item, onEditItem, onRemoveItem, editable = true }) => {
  const date = moment(item.date).format("DD [de] MMMM [de] YYYY");
  const renderExtra = () => (
    <Space>
      <EditOutlined onClick={() => onEditItem(item)} />
      <MinusCircleOutlined onClick={() => onRemoveItem(item)} />
    </Space>
  );

  return (
    <Item
      extra={editable && renderExtra()}>
      <div>
        <div>{item.description}</div>
        <div>{date}</div>
      </div>
    </Item>
  );
};
