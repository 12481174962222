import React, { useContext } from "react";
import { Space, Tabs } from "antd";
import { Section, useDocsContext } from "../../../components/shared";
import { HeroAsem } from "../../../components/shared";
import OrganizationLayout from "../../../components/shared/organization/layout";
import { AuthContext } from "../../../../AuthContext";
import {
  OrganizationModalProvider
} from "../../../contexts/shared";
import {
  OrganizationListIndex
} from "../../../components/public/organization/organization-list";
import {
  OrganizationEnvironmentMapIndex
} from "../../../components/public/organization/organization-dashboard";
import {
  NOTION_PAGES_ENUM
} from "../../../components/shared/docs-wapper/notion-pages-enum";

export function OrganizationDashboardIndex() {
  useDocsContext(NOTION_PAGES_ENUM.ORGANIZATIONS_DASHBOARD.pageId);

  const { isAuth } = useContext(AuthContext);

  const tabsProps = {
    className: "asem__Tabs",
    defaultActiveKey: "list"
  };

  return (
    <OrganizationModalProvider>
      <OrganizationLayout userIsLogged={isAuth}>
        <Space
          className="asem OrganizationIndex"
          direction="vertical">
          <HeroAsem />
          <Section
            className="OrganizationIndex__controls"
            spaced>
            <Tabs
              {...tabsProps}>
              <Tabs.TabPane tab="Organizaciones registradas" key="list">
                <OrganizationListIndex />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Estadísticas del ecosistema" key="dashboard">
                <OrganizationEnvironmentMapIndex />
              </Tabs.TabPane>
            </Tabs>
          </Section>
        </Space>
      </OrganizationLayout>
    </OrganizationModalProvider>
  );
}
