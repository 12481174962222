import { getReadableValue } from "../../../../../helpers/select-options";

export function entrepreneursAgeRangeToChart (items = []) {
  const labels = [];
  const datasets = [];

  READABLE_VALUES.forEach(({ label }, idx) => {
    labels.push(label);

    datasets.push({
      label,
      data: [],
      backgroundColor: COLORS[idx]
    });
  });

  items.forEach((item, idx) => {
    if (item["Company.ageRange"].includes("Less than 10")) return;
    const ageRange = getReadableValue(
      READABLE_VALUES,
      item["Company.ageRange"],
      item["Company.ageRange"]
    );

    const position = AGES_SORT[ageRange];

    const data = new Array(items.length);
    data.fill(0, 0, items.length - 1);
    data[position] = parseInt(item["Company.entrepreneursCount"]);

    labels[position] = ageRange;
    datasets[position] = {
      label: ageRange,
      data,
      backgroundColor: COLORS[idx],
      barThickness: 40
    };
  });

  return {
    labels,
    datasets
  };
}

const COLORS = [
  "#FDC0C0",
  "#E8D2FE",
  "#D9E4FE",
  "#D0ECFE",
  "#BCEFEA",
  "#9FD9FE"
];

const READABLE_VALUES = [
  { value: "10 to 20", label: "10 a 20" },
  { value: "21 to 25", label: "21 a 25" },
  { value: "26 to 30", label: "26 a 30" },
  { value: "31 to 35", label: "31 a 35" },
  { value: "More than 35", label: "Más de 35" }
];

const AGES_SORT = {
  "10 a 20": 0,
  "21 a 25": 1,
  "26 a 30": 2,
  "31 a 35": 3,
  "Más de 35": 4
};
