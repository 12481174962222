import { Popover, Statistic } from "antd";
import { FlexContainer, Visibility } from "../../shared";
import { InfoCircleOutlined } from "@ant-design/icons";
import React from "react";

export const StatisticCard = ({
  title,
  percentage = 0,
  value = 0,
  loading = false,
  infoText
}) => {
  return (
    <Statistic
      loading={loading}
      style={{
        height: "100%",
        padding: "1rem",
        border: "1px solid #6348E5"
      }}
      title={(
        <FlexContainer justifyContent="space-between">
          <FlexContainer>{title}</FlexContainer>
          <Visibility visible={!!infoText}>
            <FlexContainer xAlign="end">
              <Popover
                trigger="hover"
                placement="top"
                content={infoText}>
                <InfoCircleOutlined />
              </Popover>
            </FlexContainer>
          </Visibility>
        </FlexContainer>
      )}
      suffix={percentage
        ? <div style={{ fontSize: "14px" }}>{percentage}%</div>
        : null}
      value={value} />
  );
};
