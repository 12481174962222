export const mainTechnologyOptions = [
  {
    title: "Inteligencia Artificial",
    value: "AI",
    children: [
      { title: "Chatbots", value: "CHATBOT" },
      { title: "Visión computarizada", value: "COMPUTER_VISION" },
      { title: "Deep Learning", value: "DEEP_LEARNING" },
      { title: "Reconocimiento de imágenes", value: "IMAGE_RECOGNITION" },
      { title: "Machine Learning", value: "MACHINE_LEARNING" },
      { title: "Procesamiento de lenguaje natural (NLP)", value: "NLP" },
      { title: "Reconocimiento de voz y habla", value: "VOICE_RECOGNITION" }
    ]
  },
  {
    title: "Biotecnología",
    value: "BIOTECHNOLOGY",
    children: [
      { title: "Células", value: "CELLS" },
      { title: "Genes", value: "GENES" },
      { title: "Vegetal", value: "VEGETABLE" },
      { title: "Microorganismos", value: "MICROORGANISMS" },
      { title: "Moléculas", value: "MOLECULES" }
    ]
  },
  {
    title: "Comunicaciones",
    value: "TELECOM",
    children: [
      { title: "5G", value: "5G" },
      { title: "Bluetooth", value: "BLUETOOTH" },
      { title: "Fibra óptica", value: "OPTICAL_FIBER" },
      { title: "Internet de las cosas", value: "IOT" },
      { title: "Evolución a largo plazo (LTE)", value: "LTE" },
      { title: "Identificación por radiofrecuencia (RFID)", value: "RFID" },
      { title: "Radiofrecuencia", value: "RADIO_FREQUENCY" },
      { title: "Satélite", value: "SATELITE" },
      { title: "Voz sobre IP (VOIP)", value: "VOIP" },
      { title: "Inalámbrica", value: "WIRELESS" }
    ]
  },
  {
    title: "Seguridad/Protección de datos",
    value: "SECURITY_DATA_PROTECTION",
    children: [
      { title: "Encriptación", value: "ENCRYPTION" },
      { title: "Tokens", value: "TOKENS" }
    ]
  },
  {
    title: "Almacenamiento de datos",
    value: "DATA_STORAGE",
    children: [
      { title: "Red de distribución de contenido (CDN)", value: "CDN" },
      { title: "Nube", value: "CLOUD" },
      { title: "Blockchain y Tecnologías de registro distribuido (DLT)", value: "DLT" },
      { title: "Edge Computing", value: "EDGE_COMPUTING" },
      { title: "Flash", value: "FLASH" },
      { title: "Peer-to-peer", value: "P2P" }
    ]
  },
  {
    title: "Maquinaria y robótica",
    value: "MACHINERY_AND_ROBOTICS",
    children: [
      { title: "Impresión 3D y fabricación aditiva", value: "3D_PRINTING" },
      { title: "Vehículos autónomos (AGVs)", value: "AGVS" },
      { title: "Anti-Dron", value: "ANTI_DRONES" },
      { title: "Robots asistivos", value: "ASSISTIVE_ROBOTS" },
      { title: "Robots colaborativos", value: "COLLABORATIVE_ROBOTS" },
      { title: "Drones", value: "DRONES" },
      { title: "Exoesqueletos", value: "EXOSKELETON" },
      { title: "Robótica industrial", value: "INDUSTRIAL_ROBOTICS" },
      { title: "Maquinaria para procesamiento", value: "PROCESSING_MACHINERY" }
    ]
  },
  {
    title: "Materiales y sustancias",
    value: "MATERIALS_AND_SUBSTANCES",
    children: [
      { title: "Adhesivos", value: "ADHESIVOS" },
      { title: "Aleaciones", value: "ALEACIONES" },
      { title: "Compuestos", value: "COMPUESTOS" },
      { title: "Implantes", value: "IMPLANTES" },
      { title: "Aislamiento", value: "AISLAMIENTO" },
      { title: "Nanomateriales", value: "NANOMATERIALES" },
      { title: "Tela no tejida", value: "TELA_NO_TEJIDA" },
      { title: "Polimeros", value: "POLIMEROS" },
      { title: "Materiales crudos", value: "MATERIALES_CRUDOS" },
      { title: "Silicon", value: "SILICON" }
    ]
  },
  {
    title: "Plataformas e interfaces",
    value: "PLATFORM_AND_INTERFACES",
    children: [
      { title: "Aplicaciones (API)", value: "API" },
      { title: "Móvil", value: "MOBILE" },
      { title: "Plug-in", value: "PLUG_IN" },
      { title: "Motor de búsqueda", value: "SEARCH_ENGINE" },
      { title: "Software", value: "SOFTWARE" },
      { title: "Web", value: "WEB" }
    ]
  },
  {
    title: "Semiconductores y electrónicos",
    value: "SEMICONDUTORS_AND_ELECTRONICS",
    children: [
      { title: "Circuito integrado auxiliar (Chipsets)", value: "CHIPSETS" },
      { title: "Procesador de señal digital (DSP)", value: "DSP" },
      { title: "Circuito impreso (PCB)", value: "PCB" },
      { title: "Sistema en chip (SoC)", value: "SOC" },
      { title: "Vestibles", value: "WEARABLES" }
    ]
  },
  {
    title: "Sensores",
    value: "SENSORS",
    children: [
      { title: "Acústicos", value: "ACUSTICOS" },
      { title: "Biométricos", value: "BIOMETRICOS" },
      { title: "Cromáticos y de luz", value: "CROMATICOS_DE_LUZ" },
      { title: "Electromagnéticos", value: "ELECTROMAGNETIC" },
      { title: "Geolocalización", value: "GEOLOCATION" },
      { title: "Infrarrojo", value: "INFRARED" },
      { title: "Detección de luz y rango (LIDAR)", value: "LIDAR" },
      { title: "Laser", value: "LASER" },
      { title: "Movimiento", value: "MOVEMENT" },
      { title: "Comunicaciones de campo cercano", value: "NFC" },
      { title: "Ópticos", value: "OPTICOS" },
      { title: "Radar", value: "RADAR" },
      { title: "Infrarrojo de onda corta (SWIR)", value: "SWIR" },
      { title: "Aroma", value: "AROMA" },
      { title: "Vibración", value: "VIBRACION" }
    ]
  },
  {
    title: "Simulación y escaneo",
    value: "SIMULATION_AND_SCANNER",
    children: [
      { title: "Realidad aumentada", value: "AR" },
      { title: "Gemelo digital", value: "DIGITAL_TWIN" },
      { title: "Datos simulados", value: "SIMULATED_DATA" },
      { title: "Ultrasonido", value: "ULTRASOUND" },
      { title: "Realidad virtual", value: "VR" }
    ]
  }
];

export const mainTechnologyOptionAllChildren = [
  { label: "Chatbots", value: "CHATBOT" },
  { label: "Visión computarizada", value: "COMPUTER_VISION" },
  { label: "Deep Learning", value: "DEEP_LEARNING" },
  { label: "Reconocimiento de imágenes", value: "IMAGE_RECOGNITION" },
  { label: "Machine Learning", value: "MACHINE_LEARNING" },
  { label: "Procesamiento de lenguaje natural (NLP)", value: "NLP" },
  { label: "Reconocimiento de voz y habla", value: "VOICE_RECOGNITION" },
  { label: "Células", value: "CELLS" },
  { label: "Genes", value: "GENES" },
  { label: "Vegetal", value: "VEGETABLE" },
  { label: "Microorganismos", value: "MICROORGANISMS" },
  { label: "Moléculas", value: "MOLECULES" },
  { label: "5G", value: "5G" },
  { label: "Bluetooth", value: "BLUETOOTH" },
  { label: "Fibra óptica", value: "OPTICAL_FIBER" },
  { label: "Internet de las cosas", value: "IOT" },
  { label: "Evolución a largo plazo (LTE)", value: "LTE" },
  { label: "Identificación por radiofrecuencia (RFID)", value: "RFID" },
  { label: "Radiofrecuencia", value: "RADIO_FREQUENCY" },
  { label: "Satélite", value: "SATELITE" },
  { label: "Voz sobre IP (VOIP)", value: "VOIP" },
  { label: "Inalámbrica", value: "WIRELESS" },
  { label: "Encriptación", value: "ENCRYPTION" },
  { label: "Tokens", value: "TOKENS" },
  { label: "Red de distribución de contenido (CDN)", value: "CDN" },
  { label: "Nube", value: "CLOUD" },
  { label: "Blockchain y Tecnologías de registro distribuido (DLT)", value: "DLT" },
  { label: "Edge Computing", value: "EDGE_COMPUTING" },
  { label: "Flash", value: "FLASH" },
  { label: "Peer-to-peer", value: "P2P" },
  { label: "Impresión 3D y fabricación aditiva", value: "3D_PRINTING" },
  { label: "Vehículos autónomos (AGVs)", value: "AGVS" },
  { label: "Anti-Dron", value: "ANTI_DRONES" },
  { label: "Robots asistivos", value: "ASSISTIVE_ROBOTS" },
  { label: "Robots colaborativos", value: "COLLABORATIVE_ROBOTS" },
  { label: "Drones", value: "DRONES" },
  { label: "Exoesqueletos", value: "EXOSKELETON" },
  { label: "Robótica industrial", value: "INDUSTRIAL_ROBOTICS" },
  { label: "Maquinaria para procesamiento", value: "PROCESSING_MACHINERY" },
  { label: "Adhesivos", value: "ADHESIVOS" },
  { label: "Aleaciones", value: "ALEACIONES" },
  { label: "Compuestos", value: "COMPUESTOS" },
  { label: "Implantes", value: "IMPLANTES" },
  { label: "Aislamiento", value: "AISLAMIENTO" },
  { label: "Nanomateriales", value: "NANOMATERIALES" },
  { label: "Tela no tejida", value: "TELA_NO_TEJIDA" },
  { label: "Polimeros", value: "POLIMEROS" },
  { label: "Materiales crudos", value: "MATERIALES_CRUDOS" },
  { label: "Silicon", value: "SILICON" },
  { label: "Aplicaciones (API)", value: "API" },
  { label: "Móvil", value: "MOBILE" },
  { label: "Plug-in", value: "PLUG_IN" },
  { label: "Motor de búsqueda", value: "SEARCH_ENGINE" },
  { label: "Software", value: "SOFTWARE" },
  { label: "Web", value: "WEB" },
  { label: "Circuito integrado auxiliar (Chipsets)", value: "CHIPSETS" },
  { label: "Procesador de señal digital (DSP)", value: "DSP" },
  { label: "Circuito impreso (PCB)", value: "PCB" },
  { label: "Sistema en chip (SoC)", value: "SOC" },
  { label: "Vestibles", value: "WEARABLES" },
  { label: "Acústicos", value: "ACUSTICOS" },
  { label: "Biométricos", value: "BIOMETRICOS" },
  { label: "Cromáticos y de luz", value: "CROMATICOS_DE_LUZ" },
  { label: "Electromagnéticos", value: "ELECTROMAGNETIC" },
  { label: "Geolocalización", value: "GEOLOCATION" },
  { label: "Infrarrojo", value: "INFRARED" },
  { label: "Detección de luz y rango (LIDAR)", value: "LIDAR" },
  { label: "Laser", value: "LASER" },
  { label: "Movimiento", value: "MOVEMENT" },
  { label: "Comunicaciones de campo cercano", value: "NFC" },
  { label: "Ópticos", value: "OPTICOS" },
  { label: "Radar", value: "RADAR" },
  { label: "Infrarrojo de onda corta (SWIR)", value: "SWIR" },
  { label: "Aroma", value: "AROMA" },
  { label: "Vibración", value: "VIBRACION" },
  { label: "Realidad aumentada", value: "AR" },
  { label: "Gemelo digital", value: "DIGITAL_TWIN" },
  { label: "Datos simulados", value: "SIMULATED_DATA" },
  { label: "Ultrasonido", value: "ULTRASOUND" },
  { label: "Realidad virtual", value: "VR" }
];