import React, { useContext } from "react";
import {
  ContentWrapper,
  Loader
} from "../../../../../../shared";
import {
  ContentDetailsModalContextProvider,
  ContentGroupModalContextProvider,
  ContentModalContextProvider, ContentViewSwitchProvider, DraggableListProvider
} from "../../../../../../../contexts/shared";
import { BatchOdesContents } from "../../../../../../admin/batches/show/odes";
import { BatchOdesContext } from "../../../../../../../contexts/admin/batches";

export const BatchContent = () => {
  const {
    batchId,
    companyId,
    data,
    loading,
    error,
    refetch
  } = useContext(BatchOdesContext);

  return (
    <DraggableListProvider>
      <ContentWrapper>
        <ContentGroupModalContextProvider>
          <ContentModalContextProvider batchId={batchId}>
            <ContentDetailsModalContextProvider
              batchId={batchId}
              odeId={companyId}
              refetch={refetch}>
              <ContentViewSwitchProvider>
                <Loader
                  data={data}
                  loading={loading}
                  error={error}>
                  <BatchOdesContents />
                </Loader>
              </ContentViewSwitchProvider>
            </ContentDetailsModalContextProvider>
          </ContentModalContextProvider>
        </ContentGroupModalContextProvider>
      </ContentWrapper>
    </DraggableListProvider>
  );
};