import React from "react";
import { Breadcrumb } from "antd";
import { Breadcrumbs as BreadcrumbsContainer } from "../../../../shared";

export function Breadcrumbs({expertName}) {
  return (
    <BreadcrumbsContainer>
      <Breadcrumb.Item>Perfiles</Breadcrumb.Item>
      <Breadcrumb.Item>{expertName}</Breadcrumb.Item>
    </BreadcrumbsContainer>
  );
}
