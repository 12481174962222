import React, { useContext, useEffect, useState } from "react";
import Moment from "moment";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";
import { useMutation } from "@apollo/react-hooks";
import { CurrentUserContext } from "../current-user";
import { Format, VideoCarousel, Visibility } from "../../../components/shared";
import {
  Button, Col, Divider, Descriptions,
  Modal, Row, Typography, Popconfirm, Space, message
} from "antd";
import { getClickableLink } from "../../../helpers";

export function BatchEventDetailsModal({ event, onCancel, refetch, ...props }) {
  const [state, setState] = useState({});
  const { currentUser } = useContext(CurrentUserContext);

  const [deleteEvent] = useMutation(
    shared.mutations.deleteBatchEventById,
    { client }
  );

  const getData = async () => {
    switch (event.type) {
      case "SERVICE": {
        const {
          attachments: materials,
          assignedExpertService: {
            expertServiceData: { name, description, links }
          }
        } = event;

        return { name, description, materials, link: links[0] };
      }
      case "TRAINING": {
        const {
          link,
          location,
          meetingType,
          expertServiceData: { name, description, examples: materials }
        } = event;
        return { name, description, materials, link, location, meetingType };
      }
      default: {
        return event;
      }
    }
  };

  useEffect(() => {
    if (event) {
      getData(event.type)
        .then(setState);
    }
  }, [event]);

  const onDelete = async () => {
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      await deleteEvent({ variables: { id: event?.id } });
      onCancel();
      await refetch();
    } catch (e) {
      console.error(e);
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
    } finally {
      dismissLoader();
    }
  };

  return (
    <Modal
      destroyOnClose
      okText="Aceptar"
      onOk={onCancel}
      onCancel={onCancel}
      cancelButtonProps={{ hidden: true }}
      title="Evento"
      width={750}
      footer={
        <Space>
          <Visibility visible={["SUPER_ADMIN", "COORDINATOR"]
            .includes(currentUser.systemRole) && event.type === "EVENT"}>
            <Popconfirm
              onConfirm={onDelete}
              okText="Sí"
              cancelText="No"
              okButtonProps={{ style: { width: "46%", fontSize: "15px" } }}
              cancelButtonProps={{ style: { width: "46%", fontSize: "15px" } }}
              title="¿Estás seguro de eliminar el evento?">
              <Button type="primary" ghost>
                Eliminar evento
              </Button>
            </Popconfirm>
          </Visibility>
          <Button type="primary" onClick={() => onCancel()}>
            Aceptar
          </Button>
        </Space>
      }
      {...props}>
      <Row>
        <Col span={24}>
          <Typography.Title level={4}>
            {state?.name}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Text type="secondary">
            {state?.description}
          </Typography.Text>
        </Col>
        <Divider />
        <Col span={24}>
          <Descriptions
            layout={!event.type.includes("TRAINING") ? "vertical" : undefined}>
            <Descriptions.Item label="Fecha">
              {Moment(event?.date).format("DD/MM/YYYY")}
            </Descriptions.Item>
            {!event.type.includes("TRAINING")
              ?
                <Descriptions.Item label="Hora">
                  <Format type={"date"} format={"HH:mm"}>
                    {event?.date}
                  </Format>
                </Descriptions.Item>
              : null
            }
          </Descriptions>
        </Col>
        <Col span={24}>
          <Space>
            <Typography.Text strong>Lugar:</Typography.Text>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                state?.link
                  ? getClickableLink(state?.link)
                  : undefined
              }>
              {state?.link || state?.location || "No especificado"}
            </a>
          </Space>
        </Col>
        <Divider />
        <Col span={24}>
          <Typography.Title level={5}>Invitados</Typography.Title>
          <Typography.Text>Todas las Compañías</Typography.Text>
        </Col>
        {state?.materials?.length ? (
          <>
            <Divider />
            <Col span={24}>
              <Typography.Title level={5}>Recursos</Typography.Title>
              <VideoCarousel files={state?.materials} />
            </Col>
          </>
        ) : null}
      </Row>
    </Modal>
  );
}
