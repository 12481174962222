import React from "react";
import { Page, View } from "@react-pdf/renderer";
import { Header } from "../../components/header";
import { Record } from "./record";
import { Evidence } from "./evidence";

export const PageThree = ({ data }) => {
  return (
    <Page
      size="A4"
      style={{
        padding: 25,
        fontFamily: 'Roboto'
      }}>
      <Header data={data.header} />
      <Record data={data.record}/>
      <Evidence data={data.evidence} />
    </Page>
  )
}
