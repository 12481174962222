import React, { useState } from "react";

import { ArrayItem } from "../../../../../../shared";
import { CoursesAndCertificationsModal } from "../modal";
import { CourseItem } from "./course-item";
import {
  handleOnAdd,
  handleOnEditItem,
  handleOnRemoveItem,
  handleOnSave,
  handleOnCancel
} from "../../../../modal-helpers";

export const Courses = ({
  onSave,
  courses = [],
  editable = true
}) => {
  const [modal, setModal] = useState({ visible: false });

  const renderItem = item =>
    <CourseItem
      editable={editable}
      item={item}
      onEditItem={course =>
        handleOnEditItem(setModal, course)}
      onRemoveItem={removed =>
        handleOnRemoveItem(onSave, courses, removed)} />;

  return (
    <>
      <CoursesAndCertificationsModal
        visible={modal.visible}
        course={modal.item}
        onConfirm={newCourse =>
          handleOnSave(modal, setModal, onSave, courses, newCourse)}
        onCancel={() =>
          handleOnCancel(setModal)} />
      <ArrayItem
        editable={editable}
        value={courses}
        onClickAdd={() =>
          handleOnAdd(setModal)}
        buttonLabel="Agregar"
        renderItem={renderItem} />
    </>
  );
};
