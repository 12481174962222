import React, { useState } from "react";
import { Modal, Button, Form, DatePicker, Input } from "antd";
import moment from "moment";
import uuid from "uuid/v4";

import { FormContainer } from "../../../../../../../../shared";
import { EducationDegree } from "./education-degree";
// import { InstitutionSelector } from "./InstitutionSelector";
// import { SpecialityAreaSelector } from "./SpecialityAreaSelector";

const { Item, useForm } = Form;

export const EducationModal = ({ visible = false, onCancel, onConfirm, educationRecord = {} }) => {
  const [state, setState] = useState({
    busy: false
  });

  const [form] = Form.useForm();
  const validationMessages = {
    required: "El campo '${label}' es requerido."
  };

  const toggleBusy = () => setState(prevState =>
    ({ ...prevState, busy: !prevState.busy }));

  const handleConfirm = async () => {
    try {
      toggleBusy();
      await form.validateFields();
      const values = form.getFieldsValue();
      const { graduationDate } = values;

      onConfirm({
        ...values,
        uuid: uuid(),
        institution: values?.institution,
        graduationDate: moment(graduationDate).toISOString()
      });
      form.resetFields();
    } catch (error) {
      console.error(error);
    } finally {
      toggleBusy();
    }
  };

  const handleOnCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      style={{ minWidth: "600px" }}
      closable
      visible={visible}
      onCancel={handleOnCancel}
      footer={[
        <Button
          loading={state.busy}
          disabled={state.busy}
          type="primary"
          onClick={handleConfirm}>
          Guardar
        </Button>,
        <Button
          disabled={state.busy}
          onClick={handleOnCancel}>
          Cancelar
        </Button>
      ]}
      title="Educación">
      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          validateMessages={validationMessages}>
          <Item
            initialValue={educationRecord.institution}
            name="institution"
            label="Institución educativa"
            rules={[{ required: true }]}>
            {/* TODO: Replace with InstitutionSelector */}
            <Input />
          </Item>
          <Item
            name="degree"
            label="Grado"
            rules={[{ required: true }]}
            initialValue={educationRecord.degree}>
            <EducationDegree />
          </Item>
          <Item
            name="specialityArea"
            label="Área de especialidad"
            rules={[{ required: true }]}>
            {/* TODO: Replace with SpecialityAreaSelector */}
            <Input />
          </Item>
          <Item
            name="graduationDate"
            label="Fecha de graduación"
            rules={[{ required: true }]}>
            <DatePicker
              format="DD [de] MMMM [de] YYYY"
              style={{ width: "100%" }} />
          </Item>
        </Form>
      </FormContainer>
    </Modal>
  );
};
