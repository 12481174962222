import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useQueryFilters, useControlledPagination } from "../../../helpers";
import { client } from "../../../../graphql";
import { coordinator } from "../../../graphql/coordinator";
import { MainMenu } from "../../../components/coordinator/shared";
import { default as OdesList } from "../../../components/shared/ode-list";
import { FilterControlsOdes, SidebarLayout, Section } from "../../../components/shared";
import {
  CreateOdeModalProvider,
  CurrentUserContext,
  PaginationContext,
  MenuContext,
  ImportOdeModalProvider
} from "../../../contexts/shared";

export const OdesIndex = () => {
  const { currentUser } = useContext(CurrentUserContext);
  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: ["odes"],
    selectedKeys: ["odes"]
  });

  const {
    loading,
    data,
    error,
    refetch,
    variables
  } = useQuery(
    coordinator.queries.getOdesByCoordinatorId,
    {
      fetchPolicy: "network-only",
      client,
      variables: {
        id: currentUser.id,
        first: 12
      }
    }
  );

  const { registerRefetch } = useContext(PaginationContext);
  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 12
  });

  registerRefetch("odes", refetch, variables);

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: {
      first: 12
    }
  });

  const onFilterChange = filter => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters((prevFilters = {}) => {
      if (!filter.batchId && !filter.programId) {
        // Omit for new ODEs without connected batch
        filter.batches_some = undefined;
      } else {
        filter.batches_some = {
          id: filter.batchId,
          program: { id: filter.programId }
        };
      }

      return ({
        ...prevFilters,
        ...filter,
        skip,
        first
      });
    });
  };

  return (
    <CreateOdeModalProvider>
      <ImportOdeModalProvider>
        <SidebarLayout menu={<MainMenu />}>
          <Section
            title="Compañías"
            spaced
            extra={<FilterControlsOdes onChange={onFilterChange} />}>
            <OdesList
              data={data}
              error={error}
              isLoading={loading}
              onChangePage={onPaginationChange}
              currentPage={currentPage} />
          </Section>
        </SidebarLayout>
      </ImportOdeModalProvider>
    </CreateOdeModalProvider>
  );
};
