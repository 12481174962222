/**
 * @legacy from v2
 * @type {{SUPER_ADMIN: string, COORDINATOR: string, ADVISER: string, ENTREPRENEUR: string}}
 */
export const USER_ROLE = {
  SUPER_ADMIN: "Administrador",
  COORDINATOR: "Coordinador",
  ENTREPRENEUR: "Emprendedor",
  ADVISER: "Asesor"
};
