import React from "react";
import { Bar } from "@ant-design/charts";
import { genders, getReadableValue } from "../../../../../../helpers/select-options";
import moment from "moment";

export function GenderAgeChart({
  odes = [],
  ranges = [
    [0, 17],
    [18, 20],
    [21, 35],
    [26, 30],
    [31, 35]
  ]
}) {
  const rangesLabels = ranges.reduce((acc, range) => {
    const min = range[0];
    const max = range[1];

    acc[min] = {};

    if(!min) {
      acc[min][max] = `Menor de ${max} años`;
      return acc;
    }

    if(!max) {
      acc[min][max] = `Mayor de ${min} años`;
      return acc;
    }

    acc[min][max] = `${min} a ${max} años`;

    return acc;
  }, {});

  const getRange = age => {
    return ranges.find(range => {
      const min = range[0];
      const max = range[1];

      if(!min) {
        return age <= max;
      }

      if(!max) {
        return age >= min;
      }

      return age <= max && age >= min;
    });
  };

  const values = odes.reduce((acc, ode) => {
    ode.team.forEach(user => {
      const age = moment().diff(moment(user.birthday), "years");
      const gender = getReadableValue(genders, user.gender, "Sin especificar");
      const range = getRange(age);
      const rangeLabel = range ? rangesLabels[range[0]][range[1]] : "Sin especificar";

      if(Object.prototype.hasOwnProperty.call(acc, rangeLabel)) {
        if(Object.prototype.hasOwnProperty.call(acc[rangeLabel], gender)) {
          acc[rangeLabel][gender]++;
        }
        else {
          acc[rangeLabel][gender] = 1;
        }
      }
      else {
        acc[rangeLabel] = {};
        acc[rangeLabel][gender] = 1;
      }
    });

    return acc;
  }, []);

  const data = Object.keys(values).reduce((acc, rangeLabel) => {
    Object.keys(values[rangeLabel]).forEach(gender => {
      acc.push({
        label: rangeLabel,
        type: gender,
        value: values[rangeLabel][gender]
      });
    });

    return acc;
  }, []);

  return <Bar
    isGroup={true}
    xField="value"
    yField="label"
    seriesField="type"
    marginRatio={0}
    label={{
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" }
      ]
    }}
    data={data} />;
}
