import React from "react";
import { Button, List, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import * as uuid from "uuid";
import { CompositeField, Visibility } from "../../../../../components/shared";
import { AchievementListItem } from "./achievement-list-item";

export function AchievementsInput({ onChange, value, viewMode = false }) {
  const onItemChange = ({ achievement, values, idx, replaceItemAtIndex }) => {
    values.uid = achievement.uid;
    replaceItemAtIndex(idx, values);
  };

  return (
    <CompositeField
      onChange={onChange}
      isAddDisabled={true}
      value={value}>
      {({items, addNew, removeItem, replaceItemAtIndex}) => {
        return (
          <>
            <List>
              { (items.length === 0) ?
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Typography.Text
                    type="secondary">
                    No hay ningún logro
                  </Typography.Text>
                </div>
              : items.map((achievement, idx) => (
                <AchievementListItem
                  key={`achievement-item-${achievement.uid}`}
                  value={achievement}
                  idx={idx}
                  removeItem={removeItem}
                  viewMode={viewMode}
                  onChange={values =>
                    onItemChange({
                      achievement, values, idx, replaceItemAtIndex})} />
                )
              )}
            </List>
            <Visibility
              visible={!viewMode}>
              <Button
                block
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => addNew({ uid: uuid.v4(), value: "" })}>
                Agregar logro
              </Button>
            </Visibility>
          </>);
        }
      }
    </CompositeField>
  );
}
