import React, { useContext, useEffect } from "react";
import { useQuery } from "react-apollo-hooks";
import { SidebarLayout, Section } from "../../../components/shared";
import {
  EntrepreneurExpertsContext
} from "../../../contexts/entrepreneur/experts";
import { ExpertsList } from "../../../components/entrepreneur/experts";
import { FilterControls } from "../../../components/shared";
import { entrepreneur } from "../../../graphql/entrepreneur";
import { MainMenu } from "../../../components/entrepreneur/shared";
import { MenuContext, PaginationContext } from "../../../contexts/shared";
import { useQueryFilters, useControlledPagination } from "../../../helpers";
import { client } from "../../../../graphql";

export function ExpertsIndex() {
  const { data, loading, error, refetch } = useQuery(
    entrepreneur.queries.getExperts, { client }
  );

  const { applyFilters } = useQueryFilters({ refetch });

  const { setMenuState } = useContext(MenuContext);

  /* set menu's current state */
  useEffect(() => {
    setMenuState({
      openKeys: ["experts"],
      selectedKeys: ["experts/profiles"]
    });
  }, []);


  const { currentPage, onPaginationChange } = useControlledPagination();

  const { registerRefetch } = useContext(PaginationContext);

  registerRefetch("experts", refetch);

  const onFilter = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  return (
    <EntrepreneurExpertsContext.Provider value={{ data, loading, error }}>
      <SidebarLayout menu={<MainMenu />}>
        <Section
          title="Perfiles"
          extra={
            // TODO: verify it works. We resolve this
            // after a merge conflict resolution that was using
            // the FilterControls component from
            // comp/entre/experts/filter-controls
            <FilterControls
              onChange={onFilter}
              batches={false}
              programs={false} />
          }
          spaced>
          <ExpertsList
            onChangePage={onPaginationChange}
            currentPage={currentPage} />
        </Section>
      </SidebarLayout>
    </EntrepreneurExpertsContext.Provider>
  );
}
