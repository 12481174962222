import gql from "graphql-tag";

export const getExperts = gql`
  query GetExperts(
    $searchString: String
  ) {
    users(
      where: {
        systemRole: ADVISER
        ifullName_contains: $searchString
      }
    ) {
      id
      fullName
      email
      phone
      profilePicUrl
      expertProfile {
        id
        resume
      }
    }

    meta: usersConnection(
      where: {
        systemRole: ADVISER
        ifullName_contains: $searchString
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
