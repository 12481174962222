import React from "react";
import { Skeleton, Alert, Card } from "antd";

export const Loader = ({
  children,
  data = null,
  loading = true,
  error = null,
  paragraphs = 4
}) => {

  if(loading) {
    return (
      <Card bordered={false}>
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: paragraphs }} />
      </Card>
    );
  }

  if(!data || error) {
    return (
      <Card>
        <Alert
          message="Error"
          description="Ha ocurrido un error al cargar los datos de esta sección,
            por favor actualiza la página."
          type="error"
          showIcon />
      </Card>
    );
  }

  return children;
};
