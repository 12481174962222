import React from "react";
import { debounce } from "lodash";
import { ExportButton } from "./export-button";
import { Space, DatePicker, Select } from "antd";
import { remove as removeAccents } from "remove-accents";
import { expertServiceTypes } from "../../../../../../helpers/select-options";
import {
  InputTooltip,
  SearchToolbar,
  ProgramsSelect,
  ServiceCategoriesDropdown
} from "../../../../../shared";
import {
  scheduledServicesStatus
} from "../../../../../../helpers/select-options";

const statusesWithAssigned = Array.from(scheduledServicesStatus);
statusesWithAssigned.unshift({
  label: "Asignado",
  value: "ASSIGNED"
});

export function FilterControls({ onChange, onClickExport }) {
  const handleOnChange = (filter = {}) =>
    onChange && onChange(filter);

  const handleOnSearchChange = debounce(text => {
    const stringValue = removeAccents(text).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ searchString: value });
  }, 500);

  const onDatePickerChange = (date, key) => {
    if(key === "fromAcceptedDate") {
      date && date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    }

    if(key === "toAcceptedDate") {
      date && date.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
    }

    onChange && onChange({ [key]: date?.format() });
  };

  return (
    <>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end"
        }}>
        <InputTooltip
          title="Filtrar desde fecha de aceptación por la Compañía"
          placement="topLeft">
          <DatePicker
            style={{ width: "190px" }}
            allowClear
            placeholder="Desde fecha de aceptación:"
            onChange={date => onDatePickerChange(date, "fromAcceptedDate")} />
        </InputTooltip>
        <InputTooltip
          title="Filtrar hasta fecha de aceptación por la Compañía"
          placement="topLeft">
          <DatePicker
            style={{ width: "190px" }}
            allowClear
            placeholder="hasta:"
            onChange={date => onDatePickerChange(date, "toAcceptedDate")} />
        </InputTooltip>
        <ServiceCategoriesDropdown
          onChange={val => handleOnChange({ category: val })} />
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          options={statusesWithAssigned}
          style={{ width: "180px" }}
          onChange={val => handleOnChange({ status: val })}
          placeholder="Estado" />
        <SearchToolbar
          style={{ minWidth: "150px" }}
          onSearchChange={e => handleOnSearchChange(e.target.value)} />
        <ExportButton onClickExport={onClickExport} />
      </Space>
      <Space style={{
        marginTop: "10px",
        width: "100%",
        justifyContent: "flex-end"
      }}>
        <Select
          allowClear
          showSearch
          optionFilterProp="label"
          options={expertServiceTypes}
          style={{ width: "180px" }}
          onChange={val => handleOnChange({ type: val })}
          placeholder="Tipo" />
        <ProgramsSelect
          onChange={val => handleOnChange({ programId: val })}
          placeholder="Programa"
          style={{ width: "350px" }} />
      </Space>
    </>
  );
}
