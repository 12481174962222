import React  from "react";
import {
  Card,
  Col,
  Row,
  Space,
  Typography
} from "antd";
import {
  useOrganizationsNumeralia
} from "../../../../../hooks/organizations/use-organizations-numeralia";

export function NumeraliaIndex({ organizations }) {

  const {
    privateOrganizations,
    organizationsServices,
    publicOrganizations
  } = useOrganizationsNumeralia(organizations || []);

  const cardStyles = {
    width: "300",
    height: 120
  };

  return (
    <Space
      direction="vertical">
      <Row
        gutter={[16]}
        justify="space-around"
        align="middle">
        <Col span={6}>
          <Card
            bordered
            style={cardStyles}>
            <Typography.Text
              type="secondary">
              Organizaciones registradas
            </Typography.Text>
            <Typography.Title
              style={{marginTop: 0}}
              level={3}>
              {organizations?.length}
            </Typography.Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            style={cardStyles}>
            <Typography.Text
              type="secondary">
              Organizaciones de origen publico
            </Typography.Text>
            <Typography.Title
              style={{marginTop: 0}}
              level={3}>
              {publicOrganizations}
            </Typography.Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            style={cardStyles}>
            <Typography.Text
              type="secondary">
              Organizaciones de origen privado
            </Typography.Text>
            <Typography.Title
              style={{marginTop: 0}}
              level={3}>
              {privateOrganizations}
            </Typography.Title>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            style={cardStyles}>
            <Typography.Text
              type="secondary">
              Servicios que se ofrecen
            </Typography.Text>
            <Typography.Title
              style={{marginTop: 0}}
              level={3}>
              {organizationsServices}
            </Typography.Title>
          </Card>
        </Col>
      </Row>
    </Space>
  );
}
