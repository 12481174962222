import gql from "graphql-tag";

export const getEntrepreneurs = gql`
  query getEntrepreneur(
    $search: String,
    $excludedEntrepreneurs: [ID!],
    $first: Int,
    $skip: Int
  ){
    users(
      where: {
        systemRole: ENTREPRENEUR
        id_not_in: $excludedEntrepreneurs
        OR: [
          { email_contains: $search },
          { ifullName_contains: $search }
        ]
      },
      skip: $skip,
      first: $first
    ) {
      id
      fullName
      email
      phone
      profilePicUrl
      teamOdes {
        id
        name
        logoUrl
      }
      # Stop using the "odes" field in future updates. Use teamOdes
      # instead for entrepreneurs or networkOdes for users part of the network.
      odes {
        ode{
          id
          name
        }
      }
      expertProfile {
        id
        location {
          id
          state
          country
          city
        }
      }
    }
    meta: usersConnection(
      where: {
        systemRole: ENTREPRENEUR
        id_not_in: $excludedEntrepreneurs
        OR: [
          { email_contains: $search },
          { ifullName_contains: $search }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
