import { getDeletedIdsFromTwoArrays } from "../../../../../helpers/get-deleted-ids-from-two-arrays";

export const formatValues = (values = [], oldCourses = []) => {
  const upsertValues = values.map(({ id = "", ...course }) => {
    delete course.__typename;
    delete course.uuid;
    delete course.index;

    return ({
      where: { id: id },
      create: { ...course },
      update: { ...course }
    });
  });

  const createValues = upsertValues.map(v => v.create);

  const deletedCoursesIds = getDeletedIdsFromTwoArrays(values,oldCourses);

  return ({
    expertProfile: {
      upsert: {
        create: {
          professionalExperience: {
            create: {
              courses: { create: createValues }
            }
          }
        },
        update: {
          professionalExperience: {
            upsert: {
              create: {
                courses: { create: createValues }
              },
              update: {
                courses: {
                  upsert: upsertValues,
                  deleteMany: { id_in: deletedCoursesIds }
                }
              }
            }
          }
        }
      }
    }
  });
};
