/**
 * @param {rawData[]} subjectRows
 * @return {subjectInput[]}
 */

export const formatSubjectsForImportation = (subjectRows = []) => {
  return subjectRows.map(raw => ({
    enrollmentSchoolControlNumber: raw.Matricula,
    studyFieldCode: raw["Campo Estudio"],
    crnGroupDataId: parseInt(raw["Datos Grupo.Crn"]),
    subjectCode: raw["Datos Materia.Clave Materia"],
    subjectName: raw["Nombre Materia Largo"],
    enrollmentStatusGroupCode:raw["Clave Estatus Insc Grupo"],
    subjectTec21: String(raw["Ind Materia Tec21"]).toLowerCase() === "si",
    departmentCode: raw["Clave Depto Homologado"],
    departmentName: raw["Desc Depto Homologado"],
    formationUnityType: raw["Tipo de Unidad de Formación"],
    teacherPayroll: raw["Nomina Profesor"],
    teacherFirstName: raw["Nombre Profesor"],
    teacherMiddleName: raw["Apellido Paterno Profesor"],
    teacherLastName: raw["Apellido Materno Profesor"]
  }));
};

/**
 * @typedef subjectInput
 * @property enrollmentSchoolControlNumber: string
 * @property studyFieldCode: string
 * @property crnGroupDataId: number
 * @property subjectCode: string
 * @property subjectName: string
 * @property enrollmentStatusGroupCode:string
 * @property subjectTec21: boolean
 * @property departmentCode: string
 * @property departmentName: string
 * @property formationUnityType: string
 * @property teacherPayroll: string
 * @property teacherFirstName: string
 * @property teacherMiddleName: string
 * @property teacherLastName: string
 */

/**
 * @typedef rawData
 * @property {string} Matricula
 * @property {string} Campo Estudio
 * @property {string} Datos Grupo.Crn
 * @property {string} Datos Materia.Clave Materia
 * @property {string} Nombre Materia Largo
 * @property {string} Clave Estatus Insc Grupo
 * @property {string} Ind Materia Tec21
 * @property {string} Clave Depto Homologado
 * @property {string} Desc Depto Homologado
 * @property {string} Tipo de Unidad de Formación
 * @property {string} Nomina Profesor
 * @property {string} Nombre Profesor
 * @property {string} Apellido Paterno Profesor
 * @property {string} Apellido Materno Profesor
 */
