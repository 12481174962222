import React, { useCallback, useContext, useMemo, useState } from "react";
import { message } from "antd";
import { useService } from "../../../../../../../hooks/shared";
import {
  AdminMonitoringServicesService
} from "../../../../../../../services/admin-monitoring-services-service";
import {
  FilterControlsProvider,
  CurrentUserContext,
  PaginationContext,
  ServiceDetailModalContext,
  ServiceDetailModalProvider
} from "../../../../../../../contexts/shared";
import { useControlledPagination } from "../../../../../../../helpers";
import { ScheduledExpertServiceService } from "../../../../../../../services";

/**
 * @param {Object} props
 * @param {React.ReactElement} props.children
 * @param {"ASSIGNATIONS" | "TRACKING"}  props.tableType
 * @param {"PRODUCT" | "MENTORSHIP" | "WORKSHOP"} props.serviceType
 * @param {React.ReactElement} props.filterControls
 * @returns {JSX.Element}
 * @constructor
 */
export function MonitoringTableWrapper({
  children,
  tableType = "ASSIGNATIONS",
  serviceType = "PRODUCT",
  filterControls = null,
  selectedRows = null,
  setSelectedRows = null
}) {
  const { currentUser } = useContext(CurrentUserContext);
  const { systemRole } = currentUser || {};

  const [filters, setFilters] = useState({});

  const dataServicesMap = {
    ASSIGNATIONS: AdminMonitoringServicesService.getAssignedServices,
    TRACKING: AdminMonitoringServicesService.getScheduledServices
  };

  const { currentPage, onPaginationChange, pageSize, skip } = useControlledPagination({
   initialPageSize: 12
  });

  const variables = useMemo(() => ({
    skip,
    serviceType,
    first: pageSize,
    coordinatorId: systemRole === "COORDINATOR" ? currentUser?.id : undefined,
    ...filters
  }), [filters, serviceType, pageSize, systemRole, currentUser, skip]);

  const {
    data,
    loading,
    refetch
  } = useService(dataServicesMap[tableType], variables);

  const paginationRefetchName = `monitoring${tableType}_${serviceType}`;

  const { registerRefetch } = useContext(PaginationContext);
  registerRefetch(paginationRefetchName, refetch, variables);

  const items =
    (data?.assignedExpertServices || data?.scheduledExpertServices) || [];

  const performAction = useCallback(async (status, refetch) => {
    const dismissLoader = message.loading("Guardando...");

    try {
      if (!selectedRows.length) {
        return message.warning("Debes de seleccionar al menos un servicio.");
      }
      if (status !== "EXPORT") {
        const ids = selectedRows.map(el => el.id);
        await ScheduledExpertServiceService
          .updateManyScheduledExpertServicesCheckBoxPerStatus(
            { ids, status, userId: currentUser?.id }
          );

        message.success("Cambios guardados");
        refetch && await refetch(variables);
      }
    } catch (error) {
      console.error(error);
      message.error("Hubo un error intenta mas tarde.");
    } finally {
      dismissLoader();
      setSelectedRows([]);
    }
  }, [selectedRows]);

  return (
    <ServiceDetailModalProvider refetch={refetch}>
      <ServiceDetailModalContext.Consumer>
        {({ openModal }) => (
          <>
            <FilterControlsProvider
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              performAction={performAction}
              refetch={refetch}
              onPaginationChange={onPaginationChange}
              defaultFilters={variables}
              afterApplyFilters={setFilters}>
              {filterControls}
            </FilterControlsProvider>
            {React.cloneElement(children, {
              items,
              loading,
              currentPage,
              onChangePage: onPaginationChange,
              totalRecordsCount: data?.count,
              paginationRefetchName,
              openModal
            })}
          </>
        )}
      </ServiceDetailModalContext.Consumer>
    </ServiceDetailModalProvider>
  );
}
