export function sectorsToChart (industries = []) {
  const labels = [];
  // const data = [];
  const datasets = [];

  const secureSectors = industries
    .filter(i => i["Company.companySector"] !== null);

  secureSectors.forEach((industry, idx) => {
    labels.push(industry["Company.companySector"]);
    // data.push(industry["Company.companiesCount"]);

    const data = [];
    data.push(parseInt(industry["Company.companiesCount"]));

    datasets.push({
      label: industry["Company.companySector"],
      data,
      backgroundColor: PALE_COLORS_SERIES[idx],
      barThickness: 50
    });
  });

  return {
    labels: ["Sectores"],
    datasets
  };
}

const PALE_COLORS_SERIES = [
  "#5b8ff9",
  "#5ad8a6",
  "#5e7092",
  "#f6bd18",
  "#6f5efa",
  "#6ec8ec",
  "#945fb9",
  "#ff9845",
  "#299796",
  "#fe99c3",
  "#5b8ff9",
  "#5ad8a6",
  "#5e7092",
  "#f6bd18",
  "#6f5efa",
  "#6ec8ec",
  "#945fb9",
  "#ff9845",
  "#299796"
];