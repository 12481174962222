import { client } from "../../../graphql";
import {
  getAvailableMentorsForOde
} from "./graphql/get-available-mentors-for-ode";
import {
  getAssignedExpertServices,
  scheduledExpertServices
} from "../ode-service/graphql";

const getAvailableForOde = async ({ odeId = "", batchId = "" }) => {
  const result = await client.query({
    query: getAvailableMentorsForOde,
    variables: {
      batchId,
      odeId
    }
  });

  return result?.data?.users;
};

export const getAssignedForOde = async ({
  companyId,
  batchId,
  type
}) => {
  const result = await client.query({
    query: getAssignedExpertServices,
    variables: {
      companyId,
      batchId,
      type
    },
    fetchPolicy: "network-only"
  });

  return result?.data?.assignedExpertServices || [];
};

export const getScheduledForOde = async ({
  companyId,
  batchId,
  type
}) => {
  const result = await client.query({
    query: scheduledExpertServices,
    variables: {
      companyId,
      batchId,
      type
    },
    fetchPolicy: "network-only"
  });

  return result?.data?.scheduledExpertServices || [];
};

export const BatchMentorService = {
  getAvailableForOde,
  getAssignedForOde,
  getScheduledForOde
};
