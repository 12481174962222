import React, { useContext } from "react";
import { Form } from "antd";
import { Section, Visibility } from "../../..";
import { EditOdeContext } from "../../../../../contexts/shared/edit-ode";
import { ActionButton } from "../../action-button";
import { AddressForm } from "../form";

export function AddressEditSection({ editable = false }) {
  const {
    saveChanges,
    editing,
    setEditing
  } = useContext(EditOdeContext);

  const sectionName = "address";

  const onSave = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();

    saveChanges({
      address: {
        upsert: {
          update: values,
          create: values
        }
      }
    },
    sectionName);
  };

  const [form] = Form.useForm();

  return (
    <Section
      spaced
      title="Ubicación"
      extra={
        <Visibility visible={editable}>
          <ActionButton
            editing={editing[sectionName]}
            setEditing={setEditing}
            formName={sectionName}
            visible
            onSave={onSave}>
          </ActionButton>
        </Visibility>
      }>
      <AddressForm
        addressType={sectionName}
        form={form}
        editable={editing[sectionName] && editable} />
    </Section>
  );
}
