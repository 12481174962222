const unsortedGradesStudentList = [
  {
    label: "Preparatoria",
    value: "HIGHSCHOOL"
  },
  {
    label: "Profesional",
    value: "BACHELORS"
  },
  {
    label: "Postgrado",
    value: "MASTERS"
  }
];


export const gradesStudentList =
  unsortedGradesStudentList.sort((aGrade, bGrade) => {
    return aGrade.label.localeCompare(bGrade.label);
});
