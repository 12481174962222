import gql from "graphql-tag";

export const getServicesLightQuery = gql`
  query GetServicesLightQuery(
    $id_not_in: [ID!],
    $type_in: [String!],
    $expert_in: [ID!]
  ) {
    expertServices(
      where: {
        disabled: false
        id_not_in: $id_not_in
        type_in: $type_in
        expert: {
          id_in: $expert_in
        }
      }
    ) {
      id
      name
    }
  }
`;
