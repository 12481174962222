import React from "react";
import { Upload as ANTUpload } from "antd";

// TODO: Get from env var
const actionUrl = "";

export const Upload = ({ onDone, onError, children, ...uploadProps }) => {
  const handleOnChange = info => {
    if (info.file.status === "done") {
      onDone && onDone(info);
    } else if (info.file.status === "error") {
      onError && onError(info);
    }
  };

  return (
    <ANTUpload
      onChange={handleOnChange}
      action={actionUrl}
      {...uploadProps}>
      {children}
    </ANTUpload>
  );
};
