import React from "react";
import { LOGIN } from "./v2/graphql/shared/queries/login";
import { client } from "./graphql/";

const AuthContext = React.createContext();

class AuthProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      isAuth: false,
      session: { email: "", token: null },
      loginFailed: false
    };
  }

  componentWillMount() {
    //localStorage.clear(); //enable it if you need to test login again, this will be handled by logout function
    const email = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    const isAuth = email && token ? true : false;
    this.setState({ isAuth, session: { email, token, id } });
  }

  login = async ({ email, password }) => {
    const {
      data: { login }
    } = await client.query({
      query: LOGIN,
      variables: { email, password }
    });

    if (login.session.code === 202) {
      localStorage.setItem("email", login.email);
      localStorage.setItem("id", login.session.id);
      localStorage.setItem("role", login.session.systemRole);
      localStorage.setItem("token", login.session.token);
      this.setState({
        isAuth: true,
        session: {
          email: login.email,
          token: login.session.token
        },
        loginFailed: false
      });
    } else {
      this.setState({
        isAuth: false,
        session: {
          email: "",
          token: null
        },
        loginFailed: true
      });
    }
  };

  logout = () => {
    localStorage.clear();
    this.setState({
      isAuth: false,
      session: {
        email: "",
        token: null
      },
      loginFailed: false
    });
  };

  handleLoginFailedState = () => {
    this.setState({ loginFailed: false });
  };

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuth: this.state.isAuth,
          login: this.login,
          loginFailed: this.state.loginFailed,
          handleLoginFailedState: this.handleLoginFailedState,
          logout: this.logout
        }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
const AuthConsumer = AuthContext.Consumer;
export { AuthProvider, AuthConsumer, AuthContext };
