import gql from "graphql-tag";

export const getUsersByBatchId = gql`
  query GetUsersByBatchId($id: ID!) {
    users(where: {
      systemRole:ENTREPRENEUR
      OR: [
        {
          odes_some: {
            ode_some: {
              batches_some: {
                id: $id
              }
            }
          }
        },
        {
          teamOdes_some: {
            batches_some: {
              id: $id
            }
          }
        },
        {
          batches_some: {
            id: $id
          }
        }
      ]
    }) {
      id
      fullName
      firstName
      middleName
      lastName
      gender
      email
      profilePicUrl
      phone
      odes{
        ode{
          id
          name
          batches{
            id
          }
        }
      }
    }
  } 
`;
