import React, {useEffect, useState} from "react";
import moment from "moment";
import { genericFieldValidationMsgs } from "../../../../utils";
import {
  Row,
  Col,
  Form,
  Input,
  Switch,
  Select,
  Checkbox,
  DatePicker,
  Typography, InputNumber
} from "antd";
import {
  Summay
} from "../../../../contexts/shared/assigned-expert-service-modal/modal/form/summary";
import { OdesPicker } from "../../../../contexts/shared/assigned-expert-service-modal/modal/odes-picker";
import {Visibility} from "../../visibility";

export function AssignMentoringForm({
  form,
  expert,
  expertServices = null
}) {

  const [state, setState] = useState({
    totalUnits: 0,
    isProbono: false,
    isLimited: false,
    hoursForAll: false
  });

  const [selectedMentoring, setSelectedMentoring] = useState(null);

  const onSwitchChange = value => {
    form.setFieldsValue({ isProbono: value });
    setState(prevState => ({ ...prevState, isProbono: value }));
  };

  const onLimitedChange = value => {
    setState(prevState => ({ ...prevState, isLimited: value }));
  };

  const onHoursForAllChange = value => {
    setState(prevState = ({ ...prevState, hoursForAll: value }));
  };

  const updateTotalUnits = (totalUnits = 0) =>
    setState(prevState => ({ ...prevState, totalUnits }));

  useEffect(() => {
    form.setFieldsValue({ assignmentDate: moment() });
  }, []);

  // const hasLimitHoursPerDay =
  //   ["MENTORSHIP"].includes(expertService?.type) &&
  //   expertService?.method === "HOURS";

  return (
    <Form
      form={form}
      validateMessages={genericFieldValidationMsgs}
      layout="vertical"
      className="assigned-expert-service-form">
      <Typography.Title level={5}>Detalles de la asignación</Typography.Title>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="assignmentNumber"
            rules={[{ required: true }]}
            label="Número de asignación">
            <Input
              placeholder="Ej. 1239840" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="limitDate"
            rules={[{ required: true }]}
            label="Fecha límite de consumo">
            <DatePicker
              className="wide-input"
              placeholder="DD-MM-YYYY"
              format="LL" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="leadership"
            label="Rol del mentor">
            <Checkbox>Líder</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Typography.Text
        strong
        level={3}>
        Selecciona el tema para la asignación
      </Typography.Text>
      <Row>
        <Form.Item
          getValueFromEvent={
            id => {
              const selectedMentoring =
                expertServices.find(s => s.id === id);
              setSelectedMentoring(selectedMentoring);
              return id;
            }
          }
          className="wide-input"
          rules={[{ required: true }]}
          name="product"
          label="Tema">
          <Select
            options={expertServices?.map(e => ({
              label : e.name,
              value : e.id
            }))}
            placeholder="Selecciona un tema" />
        </Form.Item>
      </Row>
      <Row>
        <Typography.Text
          level={3}>
          Descripción
        </Typography.Text>
      </Row>
      <Row style={{marginBottom: "1rem"}}>
        <Typography.Text
          level={3}
          style={{color: "rgba(0, 0, 0, 0.65)"}}>
          Consejos para crear mejores negocios
        </Typography.Text>
      </Row>
      <Typography.Text
        strong
        level={3}>
        Selecciona las compañías para la asignación
      </Typography.Text>
      <Form.Item
        name="oDEs"
        rules={[{
          required: true,
          message: "La cantidad de horas a asignar no puede ser 0"
        }]}>
        <OdesPicker
          expert={expert}
          expertService={selectedMentoring}
          updateTotalUnits={updateTotalUnits} />
      </Form.Item>
      <Form.Item
        name="isProbono"
        valuePropName="checked">
        <Switch
          checkedChildren="Si"
          unCheckedChildren="No" />
        <Typography.Text
          type="secondary"
          style={{ marginLeft: "1rem", verticalAlign: "bottom" }}>
          Probono
        </Typography.Text>
      </Form.Item>
      <Form.Item>
        <Switch
          defaultChecked={state.hoursForAll}
          onChange={onHoursForAllChange}
          checkedChildren="Si"
          unCheckedChildren="No" />
        <Typography.Text
          type="secondary"
          style={{ marginLeft: "1rem", verticalAlign: "bottom" }}>
          Asignar la misma cantidad de horas a todas las compañías
        </Typography.Text>
      </Form.Item>
      <Visibility visible={state.isLimited}>
        <Form.Item
          name="hoursForAll"
          rules={[{ required: state.isLimited }]}
          style={{ marginRight: "130px" }}>
          <InputNumber
            min={1}
            type="number"
            style={{ width: "153px" }} />
        </Form.Item>
      </Visibility>
      <Form.Item>
        <Switch
          onChange={onLimitedChange}
          defaultChecked={state.isLimited}
          checkedChildren="Si"
          unCheckedChildren="No" />
        <Typography.Text
          type="secondary"
          style={{ marginLeft: "1rem", verticalAlign: "bottom" }}>
          Limitar la cantidad de consumo por sesión
        </Typography.Text>
      </Form.Item>
      <Visibility visible={state.isLimited}>
        <Form.Item
          name="limitPerDay"
          rules={[{ required: state.isLimited }]}
          style={{ marginRight: "130px" }}>
          <InputNumber
            min={1}
            type="number"
            style={{ width: "153px" }} />
        </Form.Item>
      </Visibility>
      <Summay
        isProbono={state.isProbono}
        totalUnits={state.totalUnits}
        serviceType={selectedMentoring?.type}
        serviceMethod={selectedMentoring?.method}
        price={selectedMentoring?.price || undefined} />
    </Form>
  );
}
