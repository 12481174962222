import React from "react";
import { Tooltip } from "antd";

const circleStyle = {
  width: "20px",
  height: "20px",
  borderRadius: "50%"
};

export const STATUS_ICONS = {
  PENDING: <Tooltip title="Pendiente">
    <div
      style={{
        ...circleStyle,
        backgroundColor: "#ffffff",
        border: "solid 1px #6d6d6d"
      }} />
  </Tooltip>,
  COMPLETED: <Tooltip title="Completado">
    <div style={circleStyle}>
      <div
        style={{
          width: "10px",
          height: "20px",
          borderRadius: "10px 0 0 10px",
          backgroundColor: "#5F39DE"
        }} />
    </div>
  </Tooltip>,
  VERIFIED: <Tooltip title="Verificado">
    <div
      style={{
        ...circleStyle,
        backgroundColor: "#52C41A"
      }} />
  </Tooltip>,
  CUSTOM_CONTENT_DOESNT_BELONG_TO_COMPANY: null
};
