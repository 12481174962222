import React, { useEffect } from "react";
import { cloneDeep } from "lodash";
import { Modal, Typography, Form } from "antd";
import { AddCompanyTeamMembersForm } from "./form";

export function AddCompanyTeamMembersModal({
  onOk, // should return true if you want to skip the modalClose
  onCancel,
  afterCancel,
  selectedItem,
  title = "Agregar nuevo miembro",
  description,
  inputLabel = "Usuarios",
  inputPlaceholder,
  availableUsers = [],
  loadingAvailableUsers = false,
  refetchAvailableUsers,
  ...props
}) {
  const [form] = props.form ? [props.form] : Form.useForm();

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue(cloneDeep(selectedItem));
    }
  }, [props.isVisible, selectedItem]);

  const handleOnCancel = async () => {
    form.resetFields();
    afterCancel && await afterCancel();
    onCancel();
  };

  const handleOnOk = async () => {
    await form.validateFields();

    const shouldInterrupt = await onOk(form.getFieldsValue());
    refetchAvailableUsers && await refetchAvailableUsers();
    if (shouldInterrupt) return;

    await handleOnCancel();
  };

  return (
    <Modal
      title={title}
      okText="Agregar"
      cancelText="Cancelar"
      onOk={handleOnOk}
      onCancel={() => handleOnCancel()}
      {...props}>
      <Typography.Text>{description}</Typography.Text>
      <AddCompanyTeamMembersForm
        form={form}
        inputLabel={inputLabel}
        inputPlaceholder={inputPlaceholder}
        loadingAvailableUsers={loadingAvailableUsers}
        availableUsers={availableUsers
          ?.map(({ id, fullName }) => ({ value: id, label: fullName }))} />
    </Modal>
  );
}
