import gql from "graphql-tag";

export const removeCompanyTeamMember = gql`
  mutation RemoveCompanyTeamMember(
    $userId: ID!
    $odeId: ID!
  ) {
    updateODE(
      where: { id: $odeId }
      data: {
        team: {
          disconnect: { id: $userId }
        }
      }
    ) {
      id
    }
  }
`;
