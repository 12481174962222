import React from "react";
import { Bar } from "react-chartjs-2";
import { ChartCard } from "../../chart-cards";
import {
  Title,
  Legend,
  Tooltip as ChartJsTooltip,
  BarElement,
  LinearScale,
  LineElement,
  CategoryScale,
  registerables,
  Chart as ChartJS
} from "chart.js";
import { getCampusInvolvementsLevelChartConfig } from "./helpers";

ChartJS.register(
  ...registerables,
  Title,
  Legend,
  ChartJsTooltip,
  BarElement,
  LinearScale,
  LineElement,
  CategoryScale
);

export const CampusInvolvementLevelChart = ({ involvementLevelsImpact }) => {
  const {
    data,
    options
  } = getCampusInvolvementsLevelChartConfig({ involvementLevelsImpact });

  return (
    <ChartCard
      style={{ width: "50%" }}
      title={`Nivel de involucramiento
      (porcentaje de personas impactadas vs promedio nacional)`}
      chartInfo={`% de personas inscritas en el campus seleccionado, impactados
       (que asistieron al menos a una experiencia), en el periodo elegido,
       del total de la comunidad filtrada comparados con el % de personas
       impactados a nivel nacional`}>
      <Bar
        type="bar"
        data={data}
        height={400}
        options={options} />
    </ChartCard>
  );
};