import gql from "graphql-tag";

export const getOdeUpdatesByCoordinatorId = gql`
  query GetOdeUpdatesByCoordinatorId(
    $id: ID!
    $batchId: ID
    $odeId: ID
  ) {
      odeUpdates(where: {
        batch: {
          id: $batchId
          users_some: {
            id: $id
            systemRole: COORDINATOR
          }
        }
        ode: {
          id: $odeId
        }
      }) {
        id
        period
        comments {
          id
        }
      }
  }
`;
