import React from "react";
import { Row, Col, Button } from "antd";
import { Visibility } from "../visibility";

export function FormNavigation({onNext = () => {}, onPrev, onSkip, nextLoading = false}) {
  return (
    <Row gutter={[10, 30]}>
      <Col span={12}>
        <Visibility visible={onPrev}>
          <Button type="default" onClick={onPrev}>
            Anterior
          </Button>
        </Visibility>
      </Col>
      <Col
        span={12}
        style={{textAlign: "right"}}>
        <Visibility visible={onNext}>
          <Button
            type="primary"
            onClick={onNext}
            loading={nextLoading}>
            Siguiente
          </Button>
        </Visibility>
      </Col>
      <Col
        span={24}
        style={{textAlign: "right"}}>
        <Visibility visible={onSkip}>
          <Button type="link" onClick={onSkip}>
            Lo haré después
          </Button>
        </Visibility>
      </Col>
    </Row>
  );
}
