import React from "react";
import { Route, Switch } from "react-router";
import ProfilesIndex from "../../../pages/admin/entrepreneurs/";
import PersonalInformationIndex from "../../../pages/admin/entrepreneurs/show";

export const AdminEntrepreneursRoutes = (
  <Switch>
    <Route
      exact
      path="/v2/admin/entrepreneurs"
      component={ProfilesIndex} />
    <Route
      path="/v2/admin/entrepreneurs/:id/personal-information"
      component={PersonalInformationIndex} />
  </Switch>
);