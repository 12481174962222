/**
 * Returns an object of all the passed contents with the company status if the
 * company have an interaction on each content.
 * @param allContentsMap all contents with a PENDING
 * or CUSTOM_CONTENT_DOESNT_BELONG_TO_COMPANY status by default.
 * @param companyBreakdownSectionsMap contains the company interactions with
 * the content
 * @return {any}
 */
export const getCompanyContentsInfo = (
  allContentsMap = {},
  companyBreakdownSectionsMap = {}
) => {
  const companyContentsInfo = JSON.parse(JSON.stringify(allContentsMap));

  Object.entries(allContentsMap)
    .forEach(([contentId, content]) => {
      const isCustomContent = content.belongsToCompany !== undefined;
      const belongsToThisCompany = (
        companyBreakdownSectionsMap.companyId === content.belongsToCompany
      );

      const originalStatus = companyBreakdownSectionsMap
        ?.sections
        ?.[content.section.id]
        ?.contents
        ?.[content?.id]
        ?.odeContent
        ?.status;

      const customContentStatus = belongsToThisCompany
        ? (originalStatus === "CUSTOM_CONTENT_DOESNT_BELONG_TO_COMPANY"
          || originalStatus === undefined
            ? "PENDING"
            : originalStatus || "PENDING"
        )
        : "CUSTOM_CONTENT_DOESNT_BELONG_TO_COMPANY";

      // Status list:
      // - PENDING
      // - COMPLETED
      // - VERIFIED
      // - CUSTOM_CONTENT_DOESNT_BELONG_TO_COMPANY
      companyContentsInfo[contentId].status = isCustomContent
        ? customContentStatus
        : originalStatus || "PENDING";
    });

  return companyContentsInfo;
};
