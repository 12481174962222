import gql from "graphql-tag";

/** @deprecated Use the content-service functions instead
  * @type {DocumentNode}
 */
export const upsertOdeContent = gql`
  mutation UpsertOdeContent(
    $id: ID!,
    $create: OdeContentCreateInput!,
    $update: OdeContentUpdateInput!
  ) {
    upsertOdeContent(
      where: { id: $id }
      update: $update
      create: $create
    ) {
      id
    }
  }
`;
