import gql from "graphql-tag";

export const deleteMasterContent = gql`
  mutation deleteMasterContent($id: ID!) {
    deleteMasterContent(
      where: {
        id: $id
      }
    ) {
      id
    }
  }
`;