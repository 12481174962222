import React, { useContext, createContext } from "react";

const ChartsDataContext = createContext({});

export const ChartsDataProvider = ({ dataProps, children }) => {
  return (
    <ChartsDataContext.Provider value={dataProps}>
      {children}
    </ChartsDataContext.Provider>
  );
};

export const useChartsDataContext = () => {
  return useContext(ChartsDataContext);
};
