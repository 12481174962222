import gql from 'graphql-tag';

export const deleteContent = gql`
  mutation DeleteContent( $id: ID! ){
    deleteContent(
      where: {
        id: $id
      }
    ){
      id
    }
  }
`;