export const roles = {
  SUPER_ADMIN: {
    label: "Administrador",
    labelSingular: "administrador",
    labelPlural: "administradores",
    labelPluralCaps: "Administradores"
  },
  ENTREPRENEUR: {
    label: "Emprendedor",
    labelSingular: "emprendedor",
    labelPlural: "emprendedores",
    labelPluralCaps: "Emprendedores"
  },
  COORDINATOR: {
    label: "Coordinador",
    labelSingular: "coordinador",
    labelPlural: "coordinadores",
    labelPluralCaps: "Coordinadores"
  },
  ADVISER: {
    label: "Experto",
    labelSingular: "experto",
    labelPlural: "expertos",
    labelPluralCaps: "Expertos"
  }
};
