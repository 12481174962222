import React, { useContext, useEffect } from "react";
import { Form, Select, Input } from "antd";
import { cloneDeep } from "lodash";
import { ReportFormModalContext } from "../../context";
import moment from "moment";

export function EmployeesForm() {

  const { updateChanges, report, viewMode } = useContext(ReportFormModalContext);

  const [form] = Form.useForm();

  useEffect(() => {
    const data = cloneDeep(report);
    form.setFieldsValue(data);
  }, []);

  const onFormChange = (_, values) => {
    updateChanges(values);
  };

  const formatedStartDate = moment(report.startDate).format("DD/MM/YYYY");

  return (
    <Form
      style={{marginTop: "15px"}}
      onValuesChange={onFormChange}
      form={form}
      layout="vertical"
      name="employees-form"
      title="Empleados">
      <Form.Item
        name="hasEmployees"
        label="¿Tienes empleados?">
        <Select
          disabled={viewMode}
          options={[
          {label: "Si", value: true},
          {label: "No", value: false}
        ]} />
      </Form.Item>
      <Form.Item
        name="currentEmployeesNumber"
        label="Número de empleados">
        <Input disabled={viewMode} type="number" />
      </Form.Item>
      <Form.Item
        name="terminatedEmployees"
        label={`Número de empleados dados de baja en el\
          periodo [${formatedStartDate}]`}>
        <Input disabled={viewMode} type="number" />
      </Form.Item>
      <Form.Item
        name="hiredEmployees"
        label={`Número de empleados contratados en el\
          periodo [${formatedStartDate}]`}>
        <Input disabled={viewMode} type="number" />
      </Form.Item>
    </Form>
  );
}
