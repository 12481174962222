import { getReadableValue } from "../../../../../helpers";

export function sectorsToChart (industries = []) {
  let datasets = [];

  const secureSectors = industries
    .filter(i => i["Company.companySector"] !== null);

  secureSectors.forEach((industry, idx) => {
    const data = [];
    data.push(parseInt(industry["Company.companiesCount"]));

    const rawLabel = industry["Company.companySector"];
    const readableLabel = getReadableValue(
      "ODE_SECTORS",
      rawLabel,
      rawLabel
    );

    datasets.push({
      label: readableLabel,
      data,
      backgroundColor: COLORS[idx]
    });
  });

  if (datasets.length > 16) {
    const othersReducedTotal = datasets
      .slice(16, datasets.length)
      .reduce((acc, curr) => {
        return acc + curr.data[0];
      }, 0);

    const others = {
      label: "Otros",
      data: [othersReducedTotal]
    };

    datasets = datasets.slice(0, 16);
    datasets.push(others);
  }

  return {
    labels: ["Sectores"],
    datasets
  };
}

const COLORS = [
  "#EEE3F9",
  "#E8D2FE",
  "#D7B7F6",
  "#C995FB",
  "#EBF1FE",
  "#D9E4FE",
  "#B7CCFE",
  "#95B4FE",
  "#FDDCDC",
  "#FDC0C0",
  "#FEB7D6",
  "#FE99C6",
  "#E5F4F2",
  "#BCEFEA",
  "#97E9E1",
  "#7AD3CA"
];
