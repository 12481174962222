import React, { useContext } from "react";
import { Form } from "antd";
import { Section, Visibility } from "../../../";
import { EditProfileContext } from "../../../../../contexts/shared/edit-profile";
import { ActionButton } from "../../action-button";
import { ContactForm } from "../form";
import { CurrentUserContext } from "../../../../../contexts/shared";

/**
 * @deprecated This component is unnecessary now because the Summary
 * has the same fields than this one
 * @param {*} props
 */
export function ContactEditSection({ editable = false }) {
  const {
    saveChanges,
    editing,
    setEditing
  } = useContext(EditProfileContext);

  const { refetchCurrentUser } = useContext(CurrentUserContext);

  const onSave = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    await saveChanges(values, "contact");
    await refetchCurrentUser();
  };

  const [form] = Form.useForm();

  return (
    <Section
      spaced
      title="Datos de contacto"
      bottom={
        <Visibility visible={editable}>
          <ActionButton
            editing={editing.contact}
            setEditing={setEditing}
            formName="contact"
            visible
            onSave={onSave}>
          </ActionButton>
        </Visibility>
      }>
      <ContactForm
        form={form}
        editable={editing.contact && editable } />
    </Section>
  );
}
