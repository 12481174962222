import { CompanyService } from "../../../services/company-service";

import {message} from "antd";

export const useCompanyMultimedia = ({companyId}) => {

  const addCompanyMultimedia = async ({items = [], afterSuccess}) => {
    try {
      if(!items?.length)
        return;

      await CompanyService.createMultimedia({
        odeId: companyId,
        multimedia: items
      });
      afterSuccess && await afterSuccess();
      message.success("Agregado con éxito");
    }
    catch (error){
      message.error("Ocurrió un error");
      console.error(error);
    }
  };

  const deleteCompanyMultimedia = async ({
   fileId = "",
   afterSuccess
  }) => {
    try {
      await CompanyService.deleteMultimedia({
        odeId: companyId,
        fileId: fileId
      });
      afterSuccess && await afterSuccess();
      message.success("Eliminado con éxito");
    }
    catch (error){
      message.error("Ocurrió un error");
      console.error(error);
    }
  };

  return{
    addCompanyMultimedia,
    deleteCompanyMultimedia
  };
};
