import React, { useEffect } from "react";
import { Form, Input, InputNumber, Select } from "antd";
import { UploadArea, UploadButton, SearchableDropdown } from "../../../../shared";
import { expertServiceTypes, expertServiceCategories, expertPaymentMethods } from "../../../../../helpers/select-options/expert";
import { numberFormatter } from "../../../../../utils";

export function AddServiceForm({ onChange, data }) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data?.user?.expertServices);
    }
  }, [data]);

  const onFormChange = () => {
    try {
      const values = form.getFieldsValue();
      values.price = String(values.price || "");

      if (values.coverPicUrl?.length) {
        values.coverPicUrl = values.coverPicUrl[0].url
          || values.coverPicUrl[0].response?.imageUrl;
      }

      if (values?.examples?.length) {
        values.examples = {
          create: values.examples.map(i => ({
            fileName: i.name,
            url: i.response?.imageUrl,
            type: i.type
          })).filter(i => i.url)
        };
      }

      onChange && onChange({
        expertServices: {
          create: [values]
        }
      });
    }
    catch (e) {
      console.error(e);
    }
  };

  const getPhotoUrl = e => {
    return e.fileList;
  };

  const getExamplesValue = e => {
    return e.fileList;
  };

  return (
    <Form
      form={form}
      onChange={onFormChange}
      layout="vertical">
      <Form.Item
        name="coverPicUrl"
        getValueFromEvent={getPhotoUrl}>
        <UploadArea.Crop
          maxFiles={1}
          aspect={360 / 160}>
          foto de portada
        </UploadArea.Crop>
      </Form.Item>
      <Form.Item
        label="Nombre del producto o servicio"
        name="name">
        <Input type="text" />
      </Form.Item>
      <Form.Item
        label="Tipo de artículo"
        name="type">
        <SearchableDropdown
          options={expertServiceTypes}
          openOption="OTHER" />
      </Form.Item>
      <Form.Item
        label="Categoría"
        name="category">
        <SearchableDropdown
          options={expertServiceCategories}
          openOption="OTHER" />
      </Form.Item>
      <Form.Item
        label="Método de pago"
        name="method">
        <Select options={expertPaymentMethods} />
      </Form.Item>
      <Form.Item
        name="price"
        label="Precio">
        <InputNumber
          className="wide-input"
          min={0}
          formatter={numberFormatter} />
      </Form.Item>
      <Form.Item
        label="Descripción del artículo"
        name="description">
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        label="Galería de ejemplos"
        help="Adjunta ejemplos para que las empresas puedan tener una idea
        más clara de tu servicio"
        name="examples"
        getValueFromEvent={getExamplesValue}>
        <UploadButton listType="picture">Subir</UploadButton>
      </Form.Item>
    </Form>
  );
}
