import React from "react";
import { Space } from "antd";
import { EmptyImage } from "../empty-image";

export function CoverImage({ url, icon, text, onClick, styles = {} }) {

  if (!url) {
    return <EmptyImage
      onClick={onClick}
      icon={icon}
      text={text} />;
  }

  return (
    <Space
      className="empty-image"
      align="center"
      direction="vertical"
      onClick={onClick}
      style={{
        backgroundImage: `url(${url})`,
        cursor: "pointer",
        ...styles
      }}>
      <div></div>
    </Space>
  );
}
