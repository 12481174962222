import { Card } from "antd";
import { Bar } from "react-chartjs-2";
import React, { useMemo } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { ChartDataFeedback } from "../../chart-data-feedback";
import { useCompaniesByDimensions } from "../use-companies-by-dimensions";
import { financingStageToChart} from "./financing-stage-to-chart";
import { FINANCING_STAGE_CHART_OPTIONS } from "./financing-stage-chart-options";

ChartJS.register(
  ...registerables
);

export const FinancingStageChart = ({ filters  = {} }) => {
  const { loading, results } = useCompaniesByDimensions({
    dimensions: ["companyFinancingStage"],
    mustBeSet: ["companyFinancingStage"],
    ...filters
  });

  const chartData = useMemo(() => {
    if (!results) return ({ labels: [], datasets: [] });

    const [result] = results;
    return financingStageToChart(result.data);
  }, [results]);

  return (
    <Card
      spaced
      bordered
      style={sectionStyle}
      bodyStyle={{ height: "max-content" }}
      title="Compañías por etapa de financiación">
      <ChartDataFeedback
        loading={loading}
        isEmpty={!chartData?.datasets?.length}>
        <Bar
          height={300}
          type={"bar"}
          data={chartData}
          options={FINANCING_STAGE_CHART_OPTIONS} />
      </ChartDataFeedback>
    </Card>
  );
};

const sectionStyle = {
  marginTop: 16,
  boxShadow: "none",
  borderRadius: "0"
};
