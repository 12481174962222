import React from "react";
import { Button, List, Tag, Typography } from "antd";
import Icon, {
  ApiOutlined,
  DownloadOutlined,
  TeamOutlined
} from "@ant-design/icons";
import { Avatar } from "../avatar";
import { ReactComponent as Logo } from "../../../images/logo.svg";
import "../../../../App.css";
import { getReadableValue } from "../../../helpers";
import { FlexContainer } from "../flex-container";
import {
  salesStageOptions
} from "../company-forms/general/select-options/sales-stage";
import {
  productStageOptions
} from "../company-forms/general/select-options/product-stage";
import {
  financingStageOptions
} from "../company-forms/general/select-options/financing-stage";
import {
  mainTechnologyOptionAllChildren
} from "../../../helpers/select-options/company/latest/main-technology";
import {
  socialComponentOptions
} from "../../../helpers/select-options/company/latest/social-component";
import {TagList} from "../tag-list";
import numeral from "../../../helpers/numeral";
import {
  annualSalesOptions
} from "../company-forms/general/select-options/annual-sales";

export const OnePager = ({ ode = {} }) => {

  const { Title, Text } = Typography;
  const fontSize = "17px";

  const filterMembers = (members = []) => {
    return members.filter(member => member.fullName !== null).slice(0, 5);
  };

  const renderTech = () => (<span><ApiOutlined /><Text strong> Tecnología</Text></span>);
  const renderSocial = () => (<span style={{color: "#FFB61D"}}> <TeamOutlined /> Social</span>);

  const getProfileTags = () => {
    const sector = getReadableValue("ODE_SECTORS", ode?.economicSector, null);

    const industry = getReadableValue(
      "INDUSTRIES",
      ode?.economicIndustry || ode?.industry,
      null
    );

    return [industry, sector];
  };

  const profileTags = getProfileTags();

  const { annualSales = 0, milestones = []  } = ode || {};

  const amounts = milestones.reduce((prev, current) => {
    if (
      (current.milestoneType === "FUND" && current.fundType !== "AWARD")
      || current.milestoneType === "COMPETENCE"
    ) {
      prev[`founds${current.currency}`] += parseInt(current.amount);
    } else {
      prev[`awards${current.currency}`] += parseInt(current.amount);
    }

    return prev;
  }, { foundsMXN: 0, foundsUSD: 0, awardsMXN: 0, awardsUSD: 0 });

  const getTitle = (mxn = 0, usd = 0) => {
    mxn = numeral(mxn).format("0a");
    usd = numeral(usd).format("0a");
    return <p>${mxn}<span> MXN</span> | ${usd}<span> USD</span></p>;
  };

  const getSales = (mxn = 0) => {
    mxn = numeral(mxn).format("0a");
    return <p>${mxn}<span> MXN</span></p>;
  };

  return (
    <div style={{ display: "block" }}>
      <div style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        width: "100%"
      }}>
        <Button
          className="no-print"
          type="primary"
          shape="round"
          icon={<DownloadOutlined />}
          size={"large"}
          style={{
            position: "fixed",
            right: "10px",
            bottom: "10px"
          }}
          onClick={() => window.print()}>
          Descargar PDF
        </Button>
        <Icon
          component={Logo}
          style={{
            position: "fixed",
            right: "15px",
            top: "5px",
            fontSize: "40px"
          }} />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "50% 50%",
            height: "100%",
            fontSize: fontSize,
            backgroundColor: "white",
            width: "95%"
          }}>
          <FlexContainer
            direction={"vertical"}
            style={{
              backgroundColor: "#eee9ff",
              gridColumn: "1",
              borderRadius: "15px",
              padding: "20px",
              display: "flex",
              gap: "20px",
              flexDirection: "column"
            }}>
            {ode?.logoUrl &&
              <div
                style={{
                  alignSelf: "center",
                  backgroundImage: `url(${ode?.logoUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: "10px",
                  width: "100%",
                  height: "200px"
                }} />
            }
            <Text strong>Perfil</Text>
            <FlexContainer
              style={{justifyContent: "space-between"}}
              direction="horizontal">
              <Text style={{ fontSize: "14px" }}>Compañía</Text>
              <Text
                strong
                style={{ fontSize: "15px", width: "40%" }}>
                {ode?.name}
              </Text>
            </FlexContainer>
            <FlexContainer
              style={{justifyContent: "space-between"}}
              direction="horizontal">
              {ode?.techBased && renderTech()}
              <div style={{width: "40%"}}>
                {ode?.social && renderSocial()}
              </div>
            </FlexContainer>
            <TagList maxCount={2} tags={profileTags} />
            <Text strong>Métricas</Text>
            <FlexContainer
              style={{justifyContent: "space-between"}}
              direction="horizontal">
              <Text style={{ fontSize: "14px" }}>Ventas</Text>
              <Text
                strong
                style={{ fontSize: "14px", width: "40%"}}>
                {getReadableValue(annualSalesOptions, annualSales, getSales(0))}
              </Text>
            </FlexContainer>
            <FlexContainer
              style={{justifyContent: "space-between"}}
              direction="horizontal">
              <Text style={{ fontSize: "15px" }}>Fondos</Text>
              <Text
                strong
                style={{ fontSize: "15px", width: "40%"}}>
                {getTitle(amounts?.foundsMXN, amounts?.foundsUSD)}
              </Text>
            </FlexContainer>
            <FlexContainer
              style={{justifyContent: "space-between"}}
              direction="horizontal">
              <Text style={{ fontSize: "15px" }}>Empleados</Text>
              <Text
                strong
                style={{ fontSize: "15px", width: "40%"}}>
                {ode?.employeesAmount}
              </Text>
            </FlexContainer>
            <Text strong>Contacto</Text>
            <Text>{ode?.email}</Text>
            <Text>{ode?.phone}</Text>
            {ode?.socialMedia && <Text>{ode?.socialMedia[0]}</Text>}
            {(ode?.team || []).length < 5
              ?
              <div>
                <Title style={{margin: "0"}} level={4}>Equipo</Title>
                <List
                  itemLayout="horizontal"
                  renderItem={member => (
                    <List.Item>
                      <div>
                        <Avatar size="large">{member.profilePicUrl || member.fullName}</Avatar>
                        <Text style={{ marginLeft: "15px", fontSize: fontSize }}>
                          {member?.fullName}</Text>
                      </div>
                    </List.Item>
                  )}
                  dataSource={ode?.team} />
              </div>
              : null
            }
          </FlexContainer>
          <div
            style={{
              gridColumn: "2",
              paddingLeft: "40px",
              paddingTop: "20px"
            }}>
            <FlexContainer direction="vertical" padding={"1rem"}>
              <Text style={{ fontSize: "15px" }}>Descripción corta</Text>
              <Text
                strong
                style={{ fontSize: "18px" }}>
                {ode?.shortDescription}
              </Text>
            </FlexContainer>
            <FlexContainer direction="vertical" padding={"1rem"}>
              <Text style={{ fontSize: "15px" }}>Etapa de ventas</Text>
              <Text
                strong
                style={{ fontSize: "18px" }}>
                {getReadableValue(salesStageOptions, ode?.salesStage, "")}
              </Text>
            </FlexContainer>
            <FlexContainer direction="vertical" padding={"1rem"}>
              <Text style={{ fontSize: "15px" }}>Etapa del producto</Text>
              <Text
                strong
                style={{ fontSize: "18px" }}>
                {getReadableValue(productStageOptions, ode?.productStage, "")}
              </Text>
            </FlexContainer>
            <FlexContainer direction="vertical" padding={"1rem"}>
              <Text style={{ fontSize: "15px" }}>Etapa de financiación</Text>
              <Text
                strong
                style={{ fontSize: "18px" }}>
                {getReadableValue(financingStageOptions, ode?.financingStage, "")}
              </Text>
            </FlexContainer>
            <FlexContainer direction="vertical" padding={"1rem"}>
              <Text style={{ fontSize: "15px" }}>Modelo de negocio</Text>
              <Text
                strong
                style={{ fontSize: "18px" }}>
                {ode?.businessModel}
              </Text>
            </FlexContainer>
            {ode?.businessName.replace("N/A", "") && (
              <FlexContainer direction="vertical" padding={"1rem"}>
                <Text style={{ fontSize: "15px" }}>Razón social</Text>
                <Text
                  strong
                  style={{ fontSize: "18px" }}>
                  {ode?.businessName}
                </Text>
              </FlexContainer>
            )}
            {ode?.techBased &&
              <FlexContainer direction="vertical" padding={"1rem"} style={{gap: "10px"}}>
                <Text style={{ fontSize: "15px" }}>Principal tecnología</Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "80%"
                  }}>
                  {ode?.mainTechnologies?.map(technology => (
                    <Tag
                      style={{margin: "5px"}}
                      key={technology}
                      color={"default"}>
                      {
                        getReadableValue(
                          mainTechnologyOptionAllChildren,
                          technology,
                          ""
                        )}
                    </Tag>
                  ))}
                </div>
              </FlexContainer>
            }
            <FlexContainer direction="vertical" padding={"1rem"}>
              <Text style={{ fontSize: "15px" }}>Madurez de la tecnología</Text>
              <Text
                strong
                style={{ fontSize: "18px" }}>
                {ode?.technologyLevel}
              </Text>
            </FlexContainer>
            {ode?.social && (
              <FlexContainer direction="vertical" padding={"1rem"}>
                <Text style={{ fontSize: "15px" }}>Madurez de la tecnología</Text>
                <Tag
                  style={{marginTop: "10px"}}
                  color={"default"}>
                  {
                    getReadableValue(
                      socialComponentOptions,
                      ode?.socialComponent,
                      ""
                    )}
                </Tag>
              </FlexContainer>
            )}
            <FlexContainer direction="vertical" padding={"1rem"}>
              <Text style={{ fontSize: "15px" }}>Producto</Text>
              <Text
                strong
                style={{ fontSize: "18px" }}>
                {ode?.product}
              </Text>
            </FlexContainer>
            <FlexContainer direction="vertical" padding={"1rem"}>
              <Text style={{ fontSize: "15px" }}>Mercado</Text>
              <Text
                strong
                style={{ fontSize: "18px" }}>
                {ode?.market}
              </Text>
            </FlexContainer>
          </div>

        </div>
      </div>
      {ode?.team.length > 5 &&
        <>
          <div style={{
            breakAfter: "page",
            pageBreakAfter: "always",
          }} />
          <div style={{
            width: "55%",
            margin: "4rem 4rem",
            backgroundColor: "#eee9ff",
            padding: "20px",
            borderRadius: "15px"
          }}>
            <Title style={{margin: "0"}} level={4}>Equipo</Title>
            <List
              itemLayout="horizontal"
              renderItem={member => (
                <List.Item>
                  <div>
                    <Avatar size="large">{member.profilePicUrl || member.fullName}</Avatar>
                    <Text style={{ marginLeft: "15px", fontSize: fontSize }}>
                      {member?.fullName}</Text>
                  </div>
                </List.Item>
              )}
              dataSource={ode?.team} />
          </div></>
      }
    </div>
  );
};
