import React, { useContext, useState } from "react";
import { message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { ContentGroupModalContext } from "./context";
import { ContentGroupModal } from "./modal";
import { formatDuplicatedValues } from "./format-values";
import { CurrentUserContext } from "../current-user";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql";

export function ContentGroupModalContextProvider({
  onSave,
  children,
  onUpdate,
  refetch,
  isPublished
}) {
  const [state, setState] = useState({
    isModalOpen: false,
    contentGroup: undefined
  });

  const [deleteContentGroup] = useMutation(
    shared.mutations.deleteContentGroup, { client }
  );

  const { currentUser } = useContext(CurrentUserContext);

  const duplicateSection = async contentGroup => {
    const dismissLoading = message.loading("Duplicando...");
    try {
      const values = formatDuplicatedValues(contentGroup, currentUser);
      await onSave(values);
      message.success("Datos duplicados.");
    } catch (e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    } finally {
      dismissLoading();
    }
  };

  const removeContentGroup = async id => {
    const dismissLoading = message.loading("Eliminando...");
    try {
      if(isPublished){
        await onUpdate(id, { shouldBeDeleted: true });
      } else {
        await deleteContentGroup({ variables: { id } });
        await refetch();
      }
      message.success("Datos eliminados.");
    } catch (e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    } finally {
      dismissLoading();
    }
  };

  const openModal = contentGroup => {
    setState({ contentGroup, isModalOpen: true });
  };

  const closeModal = () => {
    setState({ contentGroup: undefined, isModalOpen: false });
  };

  const injectActions = {
    openModal,
    closeModal,
    duplicateSection,
    removeContentGroup
  };

  return (
    <ContentGroupModalContext.Provider value={injectActions}>
      <ContentGroupModal
        contentGroup={state.contentGroup}
        visible={state.isModalOpen}
        isPublished={isPublished}
        onSave={onSave}
        onUpdate={onUpdate}
        onCancel={closeModal} />
      { children }
    </ContentGroupModalContext.Provider>
  );
}
