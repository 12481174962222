import gql from "graphql-tag";

export const getScheduledExpertServiceById = gql`
  query GetScheduledExpertServiceById($id: ID!) {
    scheduledExpertService(
      where: {
        id: $id
      }
    ) {
      id
      links
      status
      duration
      assignedExpertService {
        expertServiceData
      }
      attachments {
        id
        fileName
        url
        type
      }
    }
  }
`;
