import React, { useContext, useEffect, useState } from "react";
import { Form, Radio, message } from "antd";
import { SidebarLayout, Section, Visibility } from "../../../../../components/shared";
import { StepsWrapper } from "../../../../../components/expert/shared";
import { CurrentUserProvider } from "../../../../../contexts/shared/current-user";
import { MenuContext } from "../../../../../contexts/shared/menu";
import {
  WorkExperienceForm,
  EducationForm,
  CoursesForm,
  PapersForm,
  LanguagesForm,
  TecRelationshipForm
} from "../../../../../components/shared/profile-forms";
import { EditProfileProvider, EditProfileContext } from "../../../../../contexts/shared/edit-profile";
import { withRouter } from "../../../../../helpers/with-router";
import { formatValues } from "./format-values";
import { PageLeavePromptProvider } from "../../../../../contexts/shared";

function ProfessionaInformationIndex({ match, navigate }) {
  const [state, setState] = useState({ hasRelationship: 1 });

  const { setMenuState } = useContext(MenuContext);
  const expertId = match?.params?.id;

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/profiles"]
  });

  const onSwitchChange = ({ target: { value } }) => {
    setState({ hasRelationship: value  });
  };

  const forms = {
    workExperienceForm: Form.useForm()[0],
    educationForm: Form.useForm()[0],
    coursesForm: Form.useForm()[0],
    papersForm: Form.useForm()[0],
    languagesForm: Form.useForm()[0],
    tecRelationshipForm: Form.useForm()[0]
  };

  const validateForms = async () => {
    try {
      await Promise.all(
        Object.values(forms).map(form => form.validateFields())
      );
    } catch (error) {
      message.warning("Campos requeridos faltantes");
      throw error;
    }
  };

  const onNext = (saveChanges, data) => async function(event) {
    event.preventDefault();
    await validateForms();

    const oldtecRelationships = data?.user?.tecRelationships || [];
    const tecRelationship = { ...forms.tecRelationshipForm.getFieldsValue(), oldtecRelationships, hasRelationship: state.hasRelationship };

    try {
      const values = formatValues({
        workExperience: forms.workExperienceForm.getFieldsValue(),
        education: forms.educationForm.getFieldsValue(),
        courses: forms.coursesForm.getFieldsValue(),
        papers: forms.papersForm.getFieldsValue(),
        languages: forms.languagesForm.getFieldsValue(),
        tecRelationship
      });

      await saveChanges(values, expertId);
      navigate(`/v2/admin/experts/new/${expertId}/accounts`);
    } catch(error) {
      console.error(error);
    }
  };

  return (
    <PageLeavePromptProvider>
      <EditProfileProvider userId={expertId}>
        <EditProfileContext.Consumer>
          {({ saveChanges, loading, data }) => (
            <CurrentUserProvider>
              <SidebarLayout>
                <StepsWrapper
                  currentStep={1}
                  prevUrl={`/v2/admin/experts/new/${expertId}/personal-information`}
                  onNext={onNext(saveChanges, data)}>
                  <Section
                    spaced
                    loading={loading}
                    title="Experiencia laboral">
                    <WorkExperienceForm
                      editable
                      form={forms.workExperienceForm} />
                  </Section>
                  <Section
                    spaced
                    loading={loading}
                    title="Educación">
                    <EducationForm
                      editable
                      form={forms.educationForm} />
                  </Section>
                  <Section
                    spaced
                    loading={loading}
                    title="Cursos y certificaciones">
                    <CoursesForm
                      editable
                      form={forms.coursesForm} />
                  </Section>
                  <Section
                    spaced
                    loading={loading}
                    title="Investigaciones y publicaciones">
                    <PapersForm
                      editable
                      form={forms.papersForm} />
                  </Section>
                  <Section
                    spaced
                    loading={loading}
                    title="Idiomas">
                    <LanguagesForm
                      editable
                      form={forms.languagesForm} />
                  </Section>
                  <Section
                    spaced
                    loading={loading}
                    title="¿Eres Familia Tec? Proporciónanos tu matrícula y/o nómina"
                    extra={
                      <Radio.Group
                        value={state.hasRelationship}
                        onChange={onSwitchChange}>
                        <Radio value={1}>Si</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    }>
                    <Visibility visible={state.hasRelationship}>
                      <TecRelationshipForm
                        editable
                        form={forms.tecRelationshipForm} />
                    </Visibility>
                  </Section>
                </StepsWrapper>
              </SidebarLayout>
            </CurrentUserProvider>
          )}
        </EditProfileContext.Consumer>
      </EditProfileProvider>
    </PageLeavePromptProvider>
  );
}

export default withRouter(ProfessionaInformationIndex);
