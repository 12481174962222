const { default: gql } = require("graphql-tag");

export const getServicesByExpertId = gql`
  query GetServicesByExpertId(
    $expertId: ID!,
    $searchString: String,
    $category: String,
    $first: Int,
    $skip: Int
  ) {
    expertServices(
      where: {
        AND: [
          {
            OR: [ { disabled: false}, { disabled: null} ]   
          }
        ],
        expert: { id: $expertId }
        category: $category
        OR: [
            { iname_contains: $searchString },
            { idescription_contains: $searchString },
            {
              expert: { ifullName_contains: $searchString }
            }
          ]
      },
      first: $first,
      skip: $skip
    ) {
      id
      expert {
        id
        fullName
        profilePicUrl
        role
        systemRole
        expertProfile {
          id
          private
          privateBatches {
            id
            name
            program {
              id
            }
          }
        }
      }
      name
      description
      type
      method
      category
      duration
      price
      coverPicUrl
      examples {
        id
        url
        type
        fileName
      }
      links
      assignedExpertServices {
        id
      }
    }
    meta: expertServicesConnection(
      where: {
        AND: [
          {
            OR: [ { disabled: false}, { disabled: null} ]   
          }
        ],
        expert: { id: $expertId }
        category: $category
        OR: [
            { iname_contains: $searchString },
            { idescription_contains: $searchString },
            {
              expert: { ifullName_contains: $searchString }
            }
          ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
