import React, { useContext } from "react";
import Moment from "moment";
import { Table, Typography } from "antd";
import { CONTENT_TYPES, STATUS_VALUES } from "./helpers";
import { ContentDetailsModalContext } from "../../../../contexts/shared";

export function BatchContentGroupTable({
  sort = [],
  data: contentGroups = [],
  loading,
  batchMode = false // weather to show the deliveryDate
  // field exclusive for companies or not (in batch mode)
}) {
  const { onOpen } = useContext(ContentDetailsModalContext);

  /**
   * It takes an array of objects and an array of strings, and returns an array of
   * objects sorted by the order of the strings
   * @param items - The array of objects that you want to sort.
   * @param sort - an array of uuids that represent the order of the items
   * @returns - The function is returning an array of objects.
   *   - The array is sorted based on the sort array.
   *   - If the sort array is not the same length as the items array, the items
   * array is returned.
   */
  const sortItems = (items, sort) => {
    const response = [];
    for (const uuid of sort) {
      const findElement = items.find(item => item.id === uuid);
      if (findElement) {
        response.push(findElement);
      }
    }
    return items.length === response.length ? response : items;
  };

  /* Sorting the contents of each contentGroup by the contentsSort array. */
  const sortedContent = contentGroups.map(contentGroup => {
    const sortedContent = sortItems(contentGroup.contents, contentGroup.contentsSort);
    return {
      ...contentGroup,
      contents: sortedContent
    }
  });

  /* Sorting the contentGroups by the order of the sort array. */
  const sortedContentGroups = sortItems(sortedContent, sort);

  /**
   * odeContent could be undefined if the entrepreneur have no interaction with
   * the main content yet.
   * @type {{section: any, sectionId: string, odeContent: any | undefined}[]}
   */

  const dataSource = sortedContentGroups?.reduce((prev, contentGroup) => {
    const res = [];
    contentGroup.contents.forEach(content => {
      res.push({
        ...content,
        section: contentGroup.title,
        sectionId: contentGroup.id,
        odeContent: content.odeContents?.[0]
      });
    });
    return [...prev, ...res];
  }, []);

  const ColoredText = ({ color, text }) => (
    <Typography.Text style={{ color }}>
      {text}
    </Typography.Text>
  );

  return (
    <Table
      scroll={{ x: true }}
      dataSource={dataSource}
      loading={loading}
      onRow={(content, index) => ({
        onClick: () => {
          const group = contentGroups.find(el => el.id === content.sectionId);
          onOpen(
            content.id, index, group.contents, group, content.odeContents
          );
        }
      })}
      rowKey={({ id }) => id}>
      <Table.Column
        dataIndex="section"
        title="Sección" />
      <Table.Column
        dataIndex="name"
        title="Nombre del contenido" />
      <Table.Column
        dataIndex="type"
        title="Tipo de contenido"
        render={value =>
          <ColoredText
            color={CONTENT_TYPES[value].color}
            text={CONTENT_TYPES[value].label} />
        } />
      <Table.Column
        dataIndex="dueDate"
        title="Fecha límite"
        sorter={(a, b) => a.dueDate?.localeCompare(b.dueDate)}
        showSorterTooltip={false}
        render={dueDate => dueDate
          ? Moment(dueDate).format("DD/MM/YYYY")
          : null} />
      {!batchMode && (
        <Table.Column
          title="Fecha de entrega"
          sorter={(a, b) => {
            return a.odeContent?.deliveryDate
              ?.localeCompare(b.odeContent?.deliveryDate);
          }}
          showSorterTooltip={false}
          render={({ odeContent }) => {
            const deliveryDate = odeContent?.deliveryDate;
            return deliveryDate ?
              Moment(deliveryDate).format("DD/MM/YYYY")
              : null;
          }} />
      )}
      {!batchMode && (
        <Table.Column
          dataIndex="odeContent"
          title="Status"
          render={odeContent => {
            const status = STATUS_VALUES[odeContent?.status || "PENDING"];
            return (
              <ColoredText
                color={status.color}
                text={status.label} />
            );
          }} />
      )}
    </Table>
  );
}

