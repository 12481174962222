import { client } from "../../../graphql";
import {
  registerCompanyDroppedOutFromBatchEventQuery
} from "./graphql/register-company-dropped-out-from-batch-event-query";

export async function registerCompanyDroppedOutFromBatchEvent({
  odeId,
  batchId
}){
  const result = await client.mutate({
    mutation: registerCompanyDroppedOutFromBatchEventQuery,
    variables: {
      odeId,
      batchId,
      data: {
        odeId,
        batchId,
        droppedOutDate: new Date().toISOString()
      }
    }
  });

  return result.data?.updateODE || null;
}
