export const onDateChangeHandler = (dates, filters, filtersUpdater) => {
  let finalFilters = { ...filters };

  if (!dates) {
    filtersUpdater(prevFilters => {
      finalFilters = {
        ...prevFilters,
        from: undefined,
        until: undefined
      };
      return finalFilters;
    });
    return finalFilters;
  }

  const [since, until] = dates;

  filtersUpdater(prevFilters => {
    finalFilters = ({
      ...prevFilters,
      from: since.format("YYYY-MM-DD"),
      until: until ? until.format("YYYY-MM-DD") : undefined
    });
    return finalFilters;
  });

  return finalFilters;
};