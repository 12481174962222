import React from "react";
import moment from "moment";
import { Space, Tag, Typography, Avatar } from "antd";
import { MailOutlined, PhoneOutlined, CalendarOutlined } from "@ant-design/icons";
import { FlexContainer, IconLabel, Visibility } from "../../../../../../shared";
import {
  getReadableValue,
  expertServiceCategories
} from "../../../../../../../helpers/select-options";

export function ScheduledMentoringHeader({
  scheduledExpertService
}) {
  const { assignedExpertService = {} } = scheduledExpertService || {};
  const { expertServiceData = {} } = assignedExpertService;
  const { expert = {} } = expertServiceData || {};

  const textType = assignedExpertService.availableUnits  < 1
    ? "danger" : "success";

  const assignmentDate = assignedExpertService.assignmentDate
    ? moment(assignedExpertService.assignmentDate).format("DD/MMMM/YYYY")
    : "";

  const limitDate = assignedExpertService.limitDate
    ? moment(assignedExpertService.limitDate).format("DD/MMMM/YYYY")
    : "";

  return (
    <div style={{ fontSize: "14px" }}>
      <Space
        size={20}
        align="start"
        direction="horizontal">
        <Avatar
          size={60}
          icon={<CalendarOutlined style={{ color: "#c4c4c4" }} />}
          style={{
            backgroundColor: "rgba(196,196,196,0.2)",
            border: "solid 2px #c4c4c4"
          }} />
        <Space direction="vertical" size={5}>
          <FlexContainer
            style={{ gap: "24px" }}
            direction="horizontal"
            yAlign="center">
            <Typography.Title
              level={4}
              style={{marginBottom: "0px"}}>
              {expertServiceData?.name}
            </Typography.Title>
            <Typography.Text type={textType} style={{ fontSize: "12px" }}>
              Disponible {assignedExpertService.availableUnits}h
              de {assignedExpertService.assignedUnits}h
            </Typography.Text>
          </FlexContainer>
          <Typography.Text
            strong
            style={{marginBottom: "0px"}}>
            {`Experto: ${expert?.fullName}`}
          </Typography.Text>
          <Space
            style={{color: "#8C8C8C"}}
            direction="horizontal">
            <Visibility visible={expert?.phone}>
              <IconLabel icon={<PhoneOutlined />}>
                {expert?.phone}
              </IconLabel>
            </Visibility>
            <Visibility visible={expert?.email}>
              <IconLabel icon={<MailOutlined />}>
                {expert?.email}
              </IconLabel>
            </Visibility>
          </Space>
          <span>
            <Tag color="cyan">
              Mentoría
            </Tag>
            <Tag color="blue">
              {getReadableValue(
                expertServiceCategories,
                expertServiceData.category
              )}
            </Tag>
          </span>
          <Typography.Text type="secondary">
            Asignado el {assignmentDate}
          </Typography.Text>
          <FlexContainer
            style={{ gap: "16px" }}
            direction="horizontal"
            yAlign="center">
            <Typography.Title level={5} type="warning">
              Fecha límite de consumo {limitDate}
            </Typography.Title>
            <Visibility visible={assignedExpertService.limitPerDay}>
              <Typography.Text type="secondary" style={{ fontSize: "12px" }}>
                Límite por sesión {assignedExpertService.limitPerDay}h
              </Typography.Text>
            </Visibility>
          </FlexContainer>
        </Space>
      </Space>
    </div>
  );
}
