import { client3 } from "../../../graphql";
import {
  getImpactedCommunityTableQuery
} from "./graphql/get-impacted-community-table-query";

export async function getImpactedCommunityTable ({
  campus,
  startDate,
  endDate,
  audienceTypes
}) {
  const result = await client3.query({
    query: getImpactedCommunityTableQuery,
    fetchPolicy: "network-only",
    variables: {
      campus,
      startDate,
      endDate,
      audienceTypes
    }
  });

  return result?.data?.impactedCommunityTable || {};
}
