import { client } from "../../../graphql";
import {
  acceptTermsAndConditionsMutation
} from "./graphql/accept-terms-and-conditions";

export const acceptTermsAndConditions = userId => {
   return client.mutate({
    mutation: acceptTermsAndConditionsMutation,
    variables: {
      id: userId
    }});
};

export const AppService = { acceptTermsAndConditions };
