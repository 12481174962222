import React, { useContext } from 'react';
import { ContentViewRadioGroup } from '../../../../batches/show/objectives/objectives-content/content-view-radio-group';
import { ContentListAlternativeViewContext } from '../../../../../../contexts/entrepreneur/content-list-alternative-view/context';
import ObjectivesList from '../../../../../admin/batches/show/objectives/list/';
import ContentTable from './table';
import { ContentWrapper, Visibility } from '../../../../../shared';
import { ContentDetailsModalContextProvider } from '../../../../../../contexts/shared/content-details-modal/provider';
import { CurrentUserContext } from '../../../../../../contexts/shared';


export const ObjectivesContent = ({ data, loading, error, odeId }) => {

  const { listViewEnabled, tableViewEnabled } = useContext(ContentListAlternativeViewContext);
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <div>
      <ContentViewRadioGroup />
      <ContentWrapper>
        <Visibility visible={listViewEnabled}>
          <ObjectivesList
            data={data}
            error={error}
            isLoading={loading} />
        </Visibility>
        <Visibility visible={tableViewEnabled}>
          <ContentDetailsModalContextProvider>
            <ContentTable
              data={data}
              error={error}
              isLoading={loading}
              odeId={odeId || currentUser.odes?.[0]?.ode?.[0]?.id} />
          </ContentDetailsModalContextProvider>
        </Visibility>
      </ContentWrapper>
    </div>
  );
};
