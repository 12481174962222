import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Select } from "antd";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql";

/**
 * @params { object } props
 * @param { string } fromOdeId
 * @param { array } excluded
 * @param { string } placeholder
 * @param { string } systemRole
 * @param { string } mode
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function UsersTeamMemberSelect({
  fromOdeId,
  excluded,
  placeholder,
  systemRole,
  emailLabel = false,
  mode = "multiple",
  batchesIds = [],
  showWarning,
  ...props
}) {
  const { data , loading } = useQuery(
    shared.queries.getUsersTeamMember, {
      client,
      variables: {
        where: {
          OR: [
            {
              id_not_in: excluded?.map(user => user.id),
              systemRole,
              teamOdes_some: fromOdeId ? { id: fromOdeId } : undefined,
              NOT: {
                expertProfile: {
                  private: true
                }
              }
            },
            {
              id_not_in: excluded?.map(user => user.id),
              systemRole,
              teamOdes_some: fromOdeId ? { id: fromOdeId } : undefined,
              expertProfile: {
                private: true,
                privateBatches_some: { id_in: batchesIds.map(batch => (batch.id)) }
              }
            }
          ]
        }
      }
    });

  const options = data ?
    data?.users?.map(user => ({
      label: emailLabel ? user.email : user.fullName,
      value: user.id
    })) : [];

  const onSelect = (value, object) => {
    if(mode === "tags" && emailLabel) {
      if(!object?.value){
        const re = /\S+@\S+\.\S+/;
        if(!re.test(value)) { //Validates if is a valid email
          showWarning();
        }
      }
    }
  };

  return(
    <Select
      {...props}
      mode={mode}
      loading={loading}
      disabled={loading}
      options={options}
      onSelect={onSelect}
      showSearch
      optionFilterProp="label"
      placeholder={placeholder}>
    </Select>
  );
}

