import React from "react";
import { withLoader, paginationConfig } from "../../../../../../helpers";
import { Typography, List } from "antd";
import { EntrepreneurItem } from "./item";

function EntrepreneursList({ loading, data, ...props }) {
  return (
    <>
      <Typography.Text>
        Emprendedores que están involucrados en el programa se toman de
        las Compañías.
      </Typography.Text>
      <List
        style={{marginTop: "20px"}}
        loading={loading}
        itemLayout="vertical"
        dataSource={data.users}
        pagination={paginationConfig}
        renderItem={i =>
          <EntrepreneurItem
            entrepreneur={i} />
        }
        {...props} />
    </>
  );
}

export default withLoader(EntrepreneursList);
