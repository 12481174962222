import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Form, message, Modal } from "antd";
import moment from "moment";
import { client } from "../../../../../graphql";
import { InformationForm } from "../../../admin/batches/new/information/information-form";
import { HeaderTexts } from "../../../admin/batches/new/header-texts";
import { shared } from "../../../../graphql/shared";
import { admin } from "../../../../graphql/admin";
import { UsageTrackingService } from "../../../../services";

export const EditBatch = ({ batch, refetchBatch, onCancel, ...props }) => {
  const [state, setState] = useState({
    programId: undefined,
    program: undefined
  });
  const [form] = Form.useForm();
  const [updateBatch, { loading }] = useMutation(
    admin.mutations.updateBatch, { client }
  );

  const { data } = useQuery(
    admin.queries.getBatchById, {
      client,
      fetchPolicy: "network-only",
      variables: { id: batch?.id }
    }
  );

  const onSave = async () => {
    const dismissLoading = message.loading("Guardando...");
    await form.validateFields();
    const values = form.getFieldsValue();

    try {
      values.program = {
        connect: {
          id: state.programId
        }
      };

      values.startDate = moment(values.dates[0]).toISOString();
      values.finishDate = moment(values.dates[1]).toISOString();

      delete values.dates;
      delete values.programId;

      await updateBatch({
        variables: { data: values, where: { id: batch?.id } }
      });

      UsageTrackingService.trackUpdatedBatch({
        batch: { ...(values || {}), id: batch.id }
      }).catch(console.error);

      refetchBatch && await refetchBatch();
      message.success("Cambios guardados");
    } catch(e) {
      if (e.message.match(/NAME_ALREADY_TAKEN/)) {
        return message.warning(`Ya existe un batch con el nombre\
           "${values.name}". Por favor, ingresa un nombre diferente.`);
      }
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    } finally {
      onCancel && onCancel();
      dismissLoading();
    }
  };

  const onSelectProgram = value => {
    setState({ ...state, programId: value });
  };

  useEffect(() => {
    const fetchProgram = async () => {
      const { data } = await client.query({
        query: shared.queries.getProgramById,
        variables: { id: state.programId }
      });

      setState({ ...state, program: data.program });
    };

    if(state.programId) {
      fetchProgram().then();
    }
  }, [state.programId]);

  useEffect(() => {
    if(data) {
      const { startDate, finishDate } = !!data?.batch && data?.batch;
      const dates = (startDate && finishDate)
        ? [moment(startDate), moment(finishDate)]
        : [];
      form.setFieldsValue({ ...data.batch, programId: data.batch?.program?.id, dates });
      setState({ program: data.batch?.program, programId: data.batch?.program?.id });
    }
  }, [data]);

  return (
    <Modal
      width={1000}
      footer={[
        <Button
          key="cancel"
          onClick={onCancel}>
          Cancelar
        </Button>,
        <Button
          type="primary"
          key="send"
          loading={loading}
          onClick={onSave}>
          Guardar
        </Button>
      ]}
      onCancel={onCancel}
      {...props}>
      <HeaderTexts
        title="Agrega la información básica de tu batch"
        description="Explica brevemente tu batch" />
      <InformationForm
        form={form}
        program={state.program}
        onSelectProgram={onSelectProgram} />
    </Modal>
  );
};
