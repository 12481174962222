import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { EditOdeContext } from "../../../../../contexts/shared/edit-ode";
import { FormContainer } from "../../..";
import {
  fieldValidationMessages,
  countries,
  states
} from "../../../../../utils";
import { Details } from "./details";

export function AddressForm({
  editable = false,
  form,
  addressType // address or fiscalAddress
}) {
  const [state, setState] = useState({
    countryId: undefined,
    stateName: undefined,
    countryOptions: [],
    countryStates: []
  });

  const { data } = useContext(EditOdeContext);

  const inputRules = editable && [{ required: true }];

  const updateState = (values = {}) => {
    setState(prevState => ({
      ...prevState,
      ...values
    }));
  };

  useEffect(() => {
    updateState({
      countryOptions: countries.map(c => ({
        value: c.id?.toString(),
        label: c.name
      })),
      countryId: data?.oDE?.[addressType]?.country || ""
    });

    form.setFieldsValue(data?.oDE?.[addressType]);
  }, [data]);

  useEffect(() => {
    const countryStates = states
      .filter(s => s.id_country == state.countryId)
      .map(s => ({ value: s.name, label: s.name }));

    updateState({ countryStates });
  }, [state.countryId]);

  const onSelectChange = (value = {}) => {
    updateState({ ...value });
  };

  if (!editable) {
    return <Details address={data?.oDE?.[addressType] || undefined} />;
  }

  return (
    <FormContainer>
      <Form
        validateMessages={fieldValidationMessages}
        layout="vertical"
        form={form}>
        <Form.Item
          rules={inputRules}
          name="country"
          label="País">
          <Select
            options={state.countryOptions}
            showSearch
            onChange={countryId => onSelectChange({ countryId })}
            optionFilterProp="label"
            placeholder="Seleccionar país" />
        </Form.Item>
        <Form.Item
          rules={inputRules}
          label="Estado"
          name="state">
          <Select
            options={state.countryStates}
            onChange={stateName => onSelectChange({ stateName })}
            showSearch
            optionFilterProp="label"
            disabled={state.countryId === undefined}
            placeholder="Seleccionar estado" />
        </Form.Item>
        <Form.Item
          rules={inputRules}
          label="Ciudad"
          name="city">
          <Input disabled={!editable} placeholder="Ciudad" />
        </Form.Item>
        <Form.Item
          rules={inputRules}
          label="Calle y número"
          name="street">
          <Input disabled={!editable} placeholder="Calle y número" />
        </Form.Item>
        <Form.Item
          rules={inputRules}
          label="Colonia"
          name="neighborhood">
          <Input disabled={!editable} placeholder="Colonia" />
        </Form.Item>
        <Form.Item
          rules={inputRules}
          label="Código postal"
          name="zipCode">
          <Input disabled={!editable} placeholder="Código postal" />
        </Form.Item>
      </Form>
    </FormContainer>
  );
}
