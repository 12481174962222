import { Card } from "antd";
import { Bar } from "react-chartjs-2";
import React, { useMemo } from "react";
import { sectorsToChart } from "./sectors-to-chart";
import { ChartDataFeedback } from "../../chart-data-feedback";
import { useCompaniesByDimensions } from "../use-companies-by-dimensions";
import { SECTORS_CHART_OPTIONS } from "./sectors-chart-options";

export const SectorsChart = ({ filters = {} }) => {
  const { loading, results } = useCompaniesByDimensions({
    dimensions: ["companySector"],
    mustBeSet: ["companySector"],
    ...filters
  });

  const chartData = useMemo(() => {
    if (!results) return ({ labels: [], datasets: [] });

    const [result] = results;
    return sectorsToChart(result.data);
  }, [results]);

  return (
    <Card
      spaced
      bordered
      style={sectionStyle}
      title="Compañías por sectores">
      <ChartDataFeedback
        loading={loading}
        isEmpty={!chartData?.datasets?.length}>
        <Bar
          type={"bar"}
          height={400}
          data={chartData}
          options={SECTORS_CHART_OPTIONS} />
      </ChartDataFeedback>
    </Card>
  );
};

const sectionStyle = {
  marginTop: 16,
  boxShadow: "none",
  borderRadius: "0"
};
