import gql from "graphql-tag";

export const updateObjective = gql`
  mutation UpdateObjective(
    $data: ObjectiveUpdateInput!,
    $where: ObjectiveWhereUniqueInput!
  ) {
    updateObjective(data: $data, where: $where) {
      id
    }
  }
`;
