import { message } from "antd";
import { useEffect, useState } from "react";
import { AnalyticsService } from "../../../../services/analytics-service";

export function useEntrepreneursByDimensions({
  batchId,
  programId,
  from,
  until,
  mustBeSet = [],
  dimensions = [],
  teamMemberCareer,
  teamMemberCampus,
  teamMemberTecRelationshipType
}) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);

  useEffect(() => {
    setLoading(true);

    const filters = {
      batchId,
      programId,
      from,
      until,
      mustBeSet,
      teamMemberCareer,
      teamMemberCampus,
      teamMemberTecRelationshipType
    };

    AnalyticsService.countEntrepreneursBy({
        dimensions,
        filters
      })
      .then(result => {
        setResults(result);
      })
      .catch(e => {
        console.error(e);
        message.error("Ha ocurrido un error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [batchId, programId, from, until, teamMemberCareer, teamMemberCampus,
    teamMemberTecRelationshipType]);

  return {
    loading,
    results
  };
}