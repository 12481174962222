import React, { useContext, useEffect } from "react";
import { cloneDeep } from "lodash";
import { withRouter, useParams } from "react-router-dom";
import { useService } from "../../../../../hooks/shared";
import { MenuContext } from "../../../../../contexts/shared";
import { OdesService } from "../../../../../services/odes-service";
import { OdeDetail } from "../../../../../components/shared/odes/show";
import { Breadcrumbs } from "../../../../../components/shared/odes/show";
import {
  SidebarLayout,
  ContentWrapper
} from "../../../../../components/shared";
import {
  EditCompanyProvider
} from "../../../../../contexts/shared/edit-company";

function GeneralInformationIndex() {
  const { id: odeId } = useParams();

  const { loading, data, refetch = {} } = useService(
    OdesService.getById,
    { id: odeId });

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["odes"],
      selectedKeys: ["odes"]
    });
  }, []);

  return (
    <EditCompanyProvider data={data ? cloneDeep(data) : null} refetch={refetch}>
      <SidebarLayout>
        <Breadcrumbs
          odeName={data?.name} />
        <ContentWrapper>
          <OdeDetail
            companyId={odeId}
            allowEdit={false}
            data={data}
            loading={loading}
            refetch={refetch} />
        </ContentWrapper>
      </SidebarLayout>
    </EditCompanyProvider>
  );
}

export default withRouter(GeneralInformationIndex);
