import React from "react";
import { Space } from "antd";
import { debounce } from "lodash";
import { remove as removeAccents } from "remove-accents";
import { SearchToolbar } from "../../../../components/shared";

export function FilterControls({ onChange }) {
  const handleSearchOnChange = debounce((text = "") => {
    const stringValue = removeAccents(text).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ iname: value });
  }, 500);

  return (
    <Space>
      <SearchToolbar
        onSearchChange={e => handleSearchOnChange(e.target.value)} />
    </Space>
  );
}
