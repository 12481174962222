import React from "react";
import { Button, Menu, Dropdown, Modal } from "antd";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

export function ActionButton({
  onRemove,
  rotate,
  ...props
}) {
  const handleOnRemove = () => {
    Modal.confirm({
      okText: "Remover",
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: "¿Deseas remover al experto?",
      onOk: onRemove
    });
  };

  const menu = <Menu>
    <Menu.Item onClick={handleOnRemove}>
      Remover
    </Menu.Item>
    <Menu.Item onClick={null}>
      Asignar
    </Menu.Item>
  </Menu>;

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button
        data-cy="action-button"
        shape="circle"
        icon={<EllipsisOutlined rotate={rotate && 90} />}
        {...props} />
    </Dropdown>
  );
}
