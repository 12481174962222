import React from "react";
import { Tag } from "antd";

export function tag(value, props) {
  const { options } = props;

  const data = options.find(i => i.value === value);

  if(!data)
    return null;

  const { color, label } = data;

  return(
    <Tag color={color}>{label}</Tag>
  );
}
