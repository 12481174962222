import React, { useContext } from "react";
import { Modal, Form, message } from "antd";
import { ImportOdeForm } from "./form";
import { ImportOdeModalContext } from "../index";

export function ImportOdeModal({ visible = false }) {
  const {
    onCancelImport,
    loading,
    setLoading,
    onSave
  } = useContext(ImportOdeModalContext);

  const [form] = Form.useForm();

  const handleOnCancel = () => {
    form.resetFields();
    onCancelImport();
  };

  const handleSave = async () => {
    const { attachment = undefined } = form.getFieldsValue();

    if (attachment) {
      await onSave(attachment, handleOnCancel);
    } else {
      return message.error("Debes de subir un archivo para importar.");
    }

    setLoading(false);
  };

  return (
    <Modal
      destroyOnClose={true}
      confirmLoading={loading}
      onOk={handleSave}
      onCancel={handleOnCancel}
      visible={visible}
      okText="Importar compañías"
      cancelText="Cancelar"
      title={"Importar compañías"}>
      <ImportOdeForm
        form={form}
        onCancel={handleOnCancel} />
    </Modal>
  );
}
