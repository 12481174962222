import React from "react";
import { Bar } from "@ant-design/charts";
import { getReadableValue } from "../../../../../../helpers";

export function IndustrySectorChart({ odes = [] }) {

  const industriesSectors = odes.map(ode => {
    const { economicIndustry, economicSector, sector, industry } = ode;

    const i = (economicIndustry === null && industry === null) ?
      null
      : economicIndustry || getReadableValue("INDUSTRIES", industry);

    const s = (economicSector === null && sector === null) ?
      null
      : economicSector || getReadableValue("ODE_SECTORS", sector, sector);

    return ({
      industry: i,
      sector: s
    });
  })
  .filter(ode => (ode.industry !== null && ode.sector !== null));

  const data = industriesSectors.reduce((acc = [], industrySector) => {
    const idx = acc // each industry-sector combination should appears once only
      .findIndex(item =>
        item.industry === industrySector.industry
        && item.sector === industrySector.sector
      );

    if (idx < 0) { // the combination isn't included yet
      industrySector.odesCount = 1;
      acc.push(industrySector);
    } else { // the combination already exists
      const item = acc[idx];
      acc[idx] = { ...item, odesCount: item.odesCount + 1 };
    }

    return acc;
  }, []);


  const config = {
    data: data.reverse(),
    isStack: true,
    xField: "odesCount",
    yField: "industry",
    yAxis: {
      label: {
        formatter: text => text.length > 24 ?
          `${text?.slice(0, 25)}...`
          : text
      }
    },
    seriesField: "sector",
    label: {
      content: function content(item) {
        return item.sector.length > 24 ?
          `${item.sector.slice(0, 25)}...`
          : item.sector;
      },
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" }
      ]
    }
  };

  return <Bar {...config} />;
}
