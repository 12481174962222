import React, { useContext, useEffect, useRef } from "react";
import {
  SidebarLayout,
  Section
} from "../../../../components/shared";
import {
  EntrepreneurExpertsContext
} from "../../../../contexts/entrepreneur/experts";
import {
  ExpertServicesList
} from "../../../../components/entrepreneur/experts/services/list";
import { MainMenu } from "../../../../components/entrepreneur/shared";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../../../../graphql";
import { MenuContext } from "../../../../contexts/shared/menu";
import {
  FilterControls
} from "../../../../components/entrepreneur/experts/services/filter-controls";
import { entrepreneur } from "../../../../graphql/entrepreneur";
import { CurrentUserContext } from "../../../../contexts/shared/current-user";
import { ScheduleExpertServiceModalProvider } from "../../../../contexts/shared/schedule-expert-service-modal/provider";
import { useQueryFilters } from "../../../../helpers";
import { PaginationContext } from "../../../../contexts/shared";

export function ServicesIndex() {
  const { setMenuState } = useContext(MenuContext);
  const { currentUser } = useContext(CurrentUserContext);
  const {
    registerRefetch: registerPaginationRefetch
  } = useContext(PaginationContext);

  const yesterdayDate = new Date().setDate(new Date().getDate() - 1);
  const currentTime = useRef(new Date(yesterdayDate).toISOString());

  /* set menu's current state */
  useEffect(() => {
    setMenuState({
      openKeys: ["experts"],
      selectedKeys: ["experts/services"]
    });
  }, []);

  const {
    loading,
    error,
    data,
    refetch,
    variables
  } = useQuery(
    entrepreneur.queries.getAssignedExpertServicesByEntrepreneurId, {
      client,
      variables: {
        id: currentUser.id,
        currentDate: currentTime.current
      },
      fetchPolicy: "network-only"
    }
  );

  const { applyFilters } = useQueryFilters({
    refetch
  });

  const onFilterChange = filter => {
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
  };

  registerPaginationRefetch("services", refetch, variables);

  return (
    <EntrepreneurExpertsContext.Provider value={{ data }}>
      <ScheduleExpertServiceModalProvider>
        <SidebarLayout menu={<MainMenu />}>
          <Section
            title="Asignados"
            extra={<FilterControls onChange={onFilterChange} />}
            spaced>
            <ExpertServicesList
              data={data}
              isLoading={loading}
              error={error} />
          </Section>
        </SidebarLayout>
      </ScheduleExpertServiceModalProvider>
    </EntrepreneurExpertsContext.Provider>
  );
}
