import React from 'react';
import { Input, Select } from 'antd';

export const TableFilters = ({ handleSelectorChange, handleInputSearch, resetTableContent}) => {

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        position: "absolute",
        right: "12px",
        top: "12px"
      }}>
      <Select
        placeholder="Selecciona un estado" style={{ width: "180px", marginRight: "15px" }}
        onChange={handleSelectorChange}>
        <Select.Option value="all">Todos los estados</Select.Option>
        <Select.Option value="completed">Completado</Select.Option>
        <Select.Option value="pending">Pendiente</Select.Option>
      </Select>
      <Input.Search
        onSearch={handleInputSearch}
        onChange={ e => resetTableContent(e)}
        placeholder="Buscar" />
    </div>
  );
};
