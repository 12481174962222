import React, { useEffect } from "react";
import { cloneDeep } from "lodash";
import { Modal, Alert, List, Typography, Form } from "antd";
import { InviteForm } from "./form";
import { Visibility } from "../../visibility";

export function InvitationModal({
  onOk, // should return true if you want to skip the modalClose
  onCancel,
  afterCancel,
  selectedItem,
  takenEmails = [],
  onFieldsChange,
  ...props
}) {
  const [form] = props.form ? [props.form] : Form.useForm();

  useEffect(() => {
    if (selectedItem) {
      form.setFieldsValue(cloneDeep(selectedItem));
    }
  }, [props.isVisible, selectedItem, takenEmails]);

  const handleOnCancel = async () => {
    form.resetFields();
    afterCancel && await afterCancel();
    onCancel();
  };

  const handleOnOk = async () => {
    await form.validateFields();

    const shouldInterrupt = await onOk(form.getFieldsValue());
    if (shouldInterrupt) return;

    await handleOnCancel();
  };

  return (
    <Modal
      cancelText="Cancelar"
      title="Invitación a EOS"
      okText={takenEmails?.length < 1
        ? "Enviar invitaciones"
        : "Enviar de todas formas"}
      onOk={handleOnOk}
      onCancel={() => handleOnCancel()}
      {...props}>
      <InviteForm
        onFieldsChange={onFieldsChange}
        form={form} />
      <Visibility
        visible={takenEmails?.length > 0}>
        <Alert
          description="Los siguientes correos ya están registrados.
            ¿Deseas enviarles la invitación de todas formas?"
          type="warning" />
        <List
          dataSource={takenEmails || []}
          renderItem={email => (
            <List.Item>
              <Typography.Text mark>{email}</Typography.Text>
            </List.Item>
          )} />
      </Visibility>
    </Modal>
  );
}
