import { Typography } from "antd";
import React from "react";
import { Format } from "../format";

/**
 * @param {object} event
 */
export function TimelineEventLabel({ event }) {
  const date = event.date ?
    <span style={{ marginRight: "5px" }}>
      <Format type="date">{event.date}</Format>
    </span>
    : null;

  const blame = event.blame ?
    <Typography.Text type="secondary">{event.blame}</Typography.Text>
    : null;

  return (
    <span>
      {date}
      {blame}
    </span>
  );
}