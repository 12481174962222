import gql from "graphql-tag";

export const markContentAsCompleted = gql`
  mutation MarkContentAsCompleted(
    $odeContentId: ID, 
    $odeId: ID,
    $batchId: ID,
    $contentId: ID,
    $date: DateTime!
  ){
    upsertOdeContent(
      where: { id: $odeContentId }
      update: {
        finishDate: $date,
        isComplete: true
      },
      create: {
        ode: { connect: { id: $odeId } }
        batch: { connect: { id: $batchId } }
        content: { connect: { id: $contentId } }
        finishDate: $date,
        isComplete: true
      }
    ){
      id
    }
  }
`;