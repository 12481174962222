import { Button, Form, message, Modal } from "antd";
import React, { useState } from "react";
import { TeamFormContent } from "../../team-form-content";
import { useBatchMembers } from "../../../../hooks/shared";

export const TeamMemberBatchAssigned = ({
  batch,
  onCancel,
  refetchBatch,
  ...props
}) => {
  const INITIAL_STATE = { leader: "", team: [] };
  const [state, setState] = useState(INITIAL_STATE);
  const [form] = Form.useForm();

  const { connectMember, disconnectMember } = useBatchMembers();

  /**
   * @param { string | string[] } idUser
   * @param { "team" | "leader" } type
   * @returns {Promise<void>}
   */
  const onSubmit = async (idUser, type) => connectMember({
    idBatch: batch?.id,
    idUser,
    type,
    refetch: refetchBatch
  });

  const onDelete = async (idUser, type) => disconnectMember({
    idBatch: batch?.id,
    idUser,
    type,
    refetch: refetchBatch
  });

  const handleSubmit = async type => {
    await form.validateFields([type]);
    const isTeam = (type === "team");
    const whatType = isTeam ? "multiple" : "leader";
    const value = isTeam
      ? state[type]?.map(id => ({ id }))
      : state[type];

    onSubmit && await onSubmit(value, whatType);
    form.resetFields();
    setState(INITIAL_STATE);
  };

  const handleChange = (value, type) => {
    setState({ ...state, [type]: value });
  };

  const onCancelModal = () => {
    const selectedLeader = batch?.leader;

    if (!selectedLeader) {
      return message.warning("Por favor selecciona un líder.");
    }

    form.resetFields();
    setState(INITIAL_STATE);
    onCancel && onCancel();
  };

  const batchContextValue = {
    data: { batch },
    loading: false,
    handleChange,
    handleSubmit,
    onDelete,
    multipleTeams: true
  };
  return (
    <Modal
      width={900}
      footer={[
        <Button
          key="close"
          type="secondary"
          onClick={onCancelModal}>
          Cerrar
        </Button>
      ]}
      onCancel={onCancelModal}
      {...props}>
      <Form
        form={form}
        layout="vertical"
        className="NewBatch__MembersForm">
        <TeamFormContent
          batchContextValue={batchContextValue} />
      </Form>
    </Modal>
  );
};