import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { MainMenu } from "../../../components/coordinator/shared";
import { Section, SidebarLayout } from "../../../components/shared";
import { MenuContext } from "../../../contexts/shared/menu";
import { CreatePerkModalProvider } from "../../../contexts/shared/create-perk-modal/provider";
import { FilterControls } from "../../../components/admin/perks/list/filter-controls";
import { admin } from "../../../graphql/admin";
import { client } from "../../../../graphql";
import {
  default as PerksList
} from "../../../components/entrepreneur/batches/show/perks/list";
import { CurrentUserContext } from "../../../contexts/shared";
import { useQueryFilters, useControlledPagination } from "../../../helpers";

export const PerksIndex = () => {

  const { setMenuState } = useContext(MenuContext);
  const { currentUser } = useContext(CurrentUserContext);

  const { loading, data, error, refetch } = useQuery(
    admin.queries.getPerks, {
      client,
      variables: {id: currentUser.id}
    }
  );

  const { applyFilters } = useQueryFilters({ refetch });
  const { currentPage, onPaginationChange } = useControlledPagination();

  useEffect(() => {
    setMenuState({
      openKeys: ["perks"]
    });
  }, []);

  const onFilter = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  return (
    <CreatePerkModalProvider>
      <SidebarLayout menu={<MainMenu />}>
        <Section
          title="Perks"
          spaced
          extra={<FilterControls onChange={onFilter} />}>
          <PerksList
            data={data}
            error={error}
            isLoading={loading}
            onChangePage={onPaginationChange}
            currentPage={currentPage} />
        </Section>
      </SidebarLayout>
    </CreatePerkModalProvider>
  );
};
