import React, { useState, useEffect } from "react";
import { ContentControllerContext } from "./context";

export function ContentControllerContextProvider({ children, controllerState, onControllerNext, onControllerPrev }) {

  const [controllerAvailability, setControllerAvailability] = useState({
    prev: true,
    next: true,
  })

  const { currentIndex, contentList } = controllerState;

  useEffect(() => {
    setControllerAvailability({
      prev: !(currentIndex === 0),
      next: !(currentIndex === contentList.length - 1) 
    });
  }, [controllerState])

  const injectActions = {
    onNext: onControllerNext,
    onPrev: onControllerPrev,
    disablePrev: !controllerAvailability.prev,
    disableNext: !controllerAvailability.next
  }

  return (
    <ContentControllerContext.Provider value={injectActions}>
      { children }
    </ContentControllerContext.Provider>
  );
}