import gql from "graphql-tag";

export const updateExpertService = gql`
  mutation UpdateExpertService(
    $data: ExpertServiceUpdateInput!,
    $id: ID!
  ) {
    updateExpertService(
      data: $data,
      where: { id: $id }
    ) {
      id
      expert {
        id
        fullName
        profilePicUrl
      }
      name
      description
      type
      duration
      price
      links
    }
  }
`;
