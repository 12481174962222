import React from "react";
import { Descriptions } from "antd";
import { FormContainer } from "../../..";
import { countries } from "../../../../../utils";

export function Details({
  address = {}
}) {
  return (
    <FormContainer>
      <Descriptions column={1}>
        <Descriptions.Item label="País">
          {address.country ?
            countries.find(c => c.id == address.country)?.name
            : "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Estado">
          {address.state || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Ciudad">
          {address.city || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Calle y número">
          {address.street || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Colonia">
          {address.neighborhood || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label="Código postal">
          {address.zipCode || "N/A"}
        </Descriptions.Item>
      </Descriptions>
    </FormContainer>
  );
}
