import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { EditOdeProvider } from "../../../../../contexts/shared/edit-ode";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { withRouter } from "../../../../../helpers";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql";
import { SidebarLayout } from "../../../../../components/shared";
import { Breadcrumbs, Navbar } from "../../../../../components/shared/odes/show";
import { MainMenu } from "../../../../../components/coordinator/shared";
import { SummaryEditSection } from "../../../../../components/shared/ode-forms";
import { ReportsList, Resume } from "../../../../../components/shared/odes/show/metrics";
import { ReportFormModalProvider } from "../../../../../contexts/shared";

function MetricsIndex({ match }) {
  const { params = {} } = match;
  const odeId = params?.id;

  const { loading, error, data = {} } = useQuery(
    shared.queries.getOdeById,
    {
      client,
      variables: { id: odeId },
      fetchPolicy: "network-only"
    }
  );

  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: ["odes"],
    selectedKeys: ["profiles"]
  });

  return(
    <EditOdeProvider
      odeId={odeId}
      data={data}
      loading={loading}
      error={error}>
      <SidebarLayout menu={<MainMenu />}>
        <Breadcrumbs
          odeName={data?.oDE?.name} />
        <SummaryEditSection
          editable
          data={data}
          isLoading={loading}
          error={error} />
        <Navbar selectedKeys={["metrics"]} />
        <Resume
          loading={loading}
          reports={data?.oDE?.reports} />
        <ReportFormModalProvider>
          <ReportsList
            odeId={odeId}
            reports={data?.oDE?.reports}
            loading={loading} />
        </ReportFormModalProvider>
      </SidebarLayout>
    </EditOdeProvider>
  );
}

export default withRouter(MetricsIndex);
