export const socialComponentOptions = [
  { label: "Fin de la pobreza", value: "END_POVERTY" },
  { label: "Hambre cero", value: "CERO_HUNGER" },
  { label: "Salud y bienestar", value: "HEALTH_AND_WELLNESS" },
  { label: "Educación de calidad", value: "QUALITY_EDUCATION" },
  { label: "Igualdad de género", value: "GENDER_EQUALITY" },
  { label: "Agua limpia y saneamiento", value: "CLEAN_WATER" },
  { label: "Energía asequible y no contaminante", value: "CLEAN_ENERGY" },
  { label: "Trabajo decente y crecimiento económico", value: "DECENT_WORK" },
  { label: "Agua industria innovación e infraestructura", value: "WATER_INDUSTRY" },
  { label: "Reducción de las desigualdades", value: "REDUCTION_INEQUALITIES" },
  { label: "Ciudades y comunidades sostenibles", value: "SUSTAINABLE_CITIES" },
  { label: "Producción y consumo responsables", value: "RESPONSIBLE_CONSUMPTION" },
  { label: "Acción por el clima", value: "CLIMATE_ACTION" },
  { label: "Vida submarina", value: "SUBMARINE_LIFE" },
  { label: "Vida de ecosistemas terrestres", value: "TERRESTRIAL_LIFE" },
  { label: "Paz", value: "PEACE" },
  { label: "Justicia e instituciones sólidas", value: "JUSTICE" }
];