import { Dropdown } from "antd";
import React, { useState } from "react";
import { ExportFilters } from "./export-filters";
import { SettingOutlined, DownloadOutlined } from "@ant-design/icons";

export function ExportButton({ onClickExport, className }) {
  const [state, setState] = useState({
    loading: false,
    filtersVisible: false,
    markAsPaid: undefined
  });

  const dismissExportLoader = () => {
    setState(state => ({ ...state, loading: false }));
  };

  const turnExportLoaderOn = () => {
    setState(state => ({ ...state, loading: true }));
  };

  const handleExport = () => {
    if(state.loading) {
      return false;
    }

    const { markAsPaid } = state;

    onClickExport &&
      onClickExport({
        turnExportLoaderOn,
        dismissExportLoader,
        variables: { markAsPaid }
      });
  };

  const toggleFilters = () => {
    setState(state => ({ ...state, filtersVisible: !state.filtersVisible }));
  };

  const onOk = values => {
    setState(state => ({ ...state, ...values, filtersVisible: false }));
  };

  return (
    <Dropdown.Button
      className={className}
      data-cy="export-monitoring-services-button"
      type="primary"
      overlay={<ExportFilters onOk={onOk} />}
      icon={<SettingOutlined />}
      onVisibleChange={toggleFilters}
      visible={state.filtersVisible}
      trigger="click"
      onClick={handleExport}
      buttonsRender={([lb, rb]) => [
        lb,
        React.cloneElement(
          rb,
          {
            loading: state.loading
          }
        )
      ]}>
      <><DownloadOutlined /> Exportar</>
    </Dropdown.Button>
  );
}
