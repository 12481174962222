import React from "react";
import { useParams } from "react-router";
import { BatchDetailsContext } from "./context";
import { withRouter } from "../../../../helpers";
import { useQuery } from "@apollo/react-hooks";
import { admin } from "../../../../graphql/admin/index";
import { client } from "../../../../../graphql";

function BatchDetailsContextProvider({ children }) {
  const { id = "" } = useParams();

  const { data, loading, error, refetch } = useQuery(admin.queries.getBatchById, {
    client,
    fetchPolicy: "network-only",
    variables: {
      id: id
    }
  });

  const injectActions = {
    data,
    loading,
    error,
    refetch,
    id
  };

  return (
    <BatchDetailsContext.Provider value={injectActions}>
      { children }
    </BatchDetailsContext.Provider>
  );
}

export default withRouter(BatchDetailsContextProvider);
