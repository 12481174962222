import React, { useState, useMemo } from "react";
import { MenuContext } from "./context";
import { isEqual } from "lodash";

export function MenuContextProvider({ children }) {
  const [state, setState] = useState({ openKeys: [], selectedKeys: [] });

  const setMenuState = newState => {
    if(!isEqual(newState, state)) {
      setState({...state, ...newState});
    }
  };

  const injectActions = useMemo(() => ({
    setMenuState,
    openKeys: state.openKeys,
    selectedKeys: state.selectedKeys
  }), [state]);

  return (
    <MenuContext.Provider value={injectActions}>
      { children }
    </MenuContext.Provider>
  );
}
