import React, { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { AdminTabPanes } from "./admin";
import { ExpertTabPanes } from "./expert";
import { EntrepreneurTabPanes } from "../../../entrepreneur/batches/show/offer/entrepreneur-tab-panes";
import { CurrentUserContext } from "../../../../contexts/shared";

export const BatchOfferSmartTabs = ({
  batch = {},
  defaultActiveKey = "information"
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const section = params.get("section");

  const [tab, setTab] = useState({ activeKey: "information" });

  const setActiveTab = (path, keyName) => {
    keyName = keyName || "information"; // could be null
    setTab(prevState => ({ ...prevState, activeKey: keyName }));
    history.replace(path);
  };

  const onClickActiveTab = keyName => {
    setActiveTab(`./offer?section=${keyName}`, keyName);

    setTimeout(() => { // Workaround: Wait render view
      const focus = document.getElementById("focus");
      focus.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  const tabPanesMap = {
    SUPER_ADMIN: AdminTabPanes,
    EXPERT: ExpertTabPanes,
    ENTREPRENEUR: EntrepreneurTabPanes
  };

  const TabPanes = tabPanesMap[currentUser.systemRole];

  const tabsProps = {
    onTabClick: tabKeyName => onClickActiveTab(tabKeyName),
    activeKey: tab.activeKey,
    className: "NewBatch__Tabs",
    defaultActiveKey: defaultActiveKey
  };

  const props = {
    batch,
    setActiveTab,
    defaultActiveTab: section || "information"
  };

  return <TabPanes {...props} tabsProps={tabsProps} />;
};
