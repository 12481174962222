import React from "react";
import { useService } from "../../../../hooks/shared";
import { BatchesService } from "../../../../services";
import { Filters } from "./filters";
import { Section } from "../../../shared";
import { SectorsChart } from "./sectors-chart";
import { IndustryChart } from "./industry-chart";
import { GridContainer } from "../../../shared";
import { StatisticsCards } from "./statistics-cards";
import { TechnologyChart } from "./technology-chart";
import { SalesStageChart } from "./sales-stage-chart";
import { ProductStageChart } from "./product-stage-chart";
import { BusinessModelChart } from "./business-model-chart";
import { FinancingStageChart } from "./financing-stage-chart";
import { useChartsDataContext } from "../charts-data-provider";

export const CompaniesCharts = () => {
  const [filters, setFilters] = React.useState({});

  const { programs, loadingPrograms } = useChartsDataContext();

  const { data: batches, refetch: refetchBatches } = useService(
    BatchesService.getByProgram,
    { programId: filters.programId },
    { shouldFetch: !!filters.filters }
  );

  return (
    <Section spaced>
      <Filters
        batches={batches}
        programs={programs}
        onChange={setFilters}
        refetchBatches={refetchBatches}
        loadingPrograms={loadingPrograms} />
      <StatisticsCards filters={filters} />
      <GridContainer responsiveValues={COL_RESPONSIVE_SIZES}>
        <FinancingStageChart filters={filters} />
        <SalesStageChart filters={filters} />
        <BusinessModelChart filters={filters} />
        <ProductStageChart filters={filters} />
      </GridContainer>
      <GridContainer responsiveValues={{ lg: 24, md: 24, sm: 24 }}>
        <SectorsChart filters={filters} />
        <IndustryChart filters={filters} />
        <TechnologyChart filters={filters} />
      </GridContainer>
    </Section>
  );
};

const COL_RESPONSIVE_SIZES = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 12,
  xxl: 12
};
