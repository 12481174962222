import {
  GET_BATCH_CONTENTS_PROGRESS_BREAKDOWN
} from "./graphql/get-batch-contents-progress-breakdown";
import {
  graphqlApolloClient
} from "../data-connection-service/graphql-apollo-client";

export const getBatchContentsProgressBreakdown = async ({ batchId }) => {
  const response = await graphqlApolloClient.query({
    query: GET_BATCH_CONTENTS_PROGRESS_BREAKDOWN,
    fetchPolicy: "network-only",
    variables: { batchId }
  });

  return response.data?.batchCompaniesContentProgressBreakdown;
};
