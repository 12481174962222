import React from "react";
import { AnchorWrapper } from "./anchor-wrapper";
import { getRichTextStyle } from "./get-rich-text-style";

export const richTextToHtml = (richTextElements = [], key) => {
  return (
    <span key={key} style={{ display: "inline" }}>
      {richTextElements.map((richElement, idx) => {
        const elementKey = `${key}-rich-element-${idx}`;

        const { code } = richElement?.annotations || {};

        const style = getRichTextStyle(richElement?.annotations || {});

        if (code) {
          return (
            <AnchorWrapper
              key={elementKey}
              href={richElement?.href}>
              <code
                style={style}>
                {richElement?.plain_text}
              </code>
            </AnchorWrapper>
          );
        }

        return (
          <AnchorWrapper
            key={elementKey}
            href={richElement?.href}>
            <div
              style={style}>
              {richElement?.plain_text}
            </div>
          </AnchorWrapper>
        );
      })}
    </span>
  );
};

