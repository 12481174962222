import React from "react";
import { debounce } from "lodash";
import { Space, Select } from "antd";
import { remove as removeAccents } from "remove-accents";
import {
  SearchToolbar,
  ServiceCategoriesDropdown
} from "../../../../shared";
import {
  assignedServicesStatus
} from "../../../../../helpers";

export function FilterControlsAssigned({ onChange = null }) {
  const handleOnChange = (filter = {}) =>
    onChange && onChange(filter);

  const handleOnSearchChange = debounce(text => {
    const stringValue = removeAccents(text).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ searchString: value });
  }, 500);

  return (
    <Space
      style={{
        marginBottom: "1rem",
        display: "flex",
        justifyContent: "flex-end"
      }}>
      <ServiceCategoriesDropdown
        onChange={val => handleOnChange({ category: val })} />
      <Select
        allowClear
        showSearch
        optionFilterProp="label"
        options={assignedServicesStatus}
        style={{ width: "180px" }}
        onChange={val => handleOnChange({ status: val })}
        placeholder="Estado" />
      <SearchToolbar
        style={{ minWidth: "150px" }}
        onSearchChange={e => handleOnSearchChange(e.target.value)} />
    </Space>
  );
}
