import React from "react";
import { Space } from "antd";
import { remove as removeAccents } from "remove-accents";
import { debounce } from "lodash";
import { SearchToolbar } from "../../../../../../components/shared";

export function FilterControls({ onChange }) {
  const handleOnSearchChange = debounce((odeName = "") => {
    const stringValue = removeAccents(odeName).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ odeName: value });
  }, 500);

  return (
    <Space>
      <SearchToolbar
        onSearchChange={e => handleOnSearchChange(e.target.value)} />
    </Space>
  );
}
