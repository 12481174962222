import React, { useContext, useEffect } from "react";
import { Form } from "antd";
import { genericFieldValidationMsgs } from "../../../../../utils";
import { ReportFormModalContext } from "../../../../../contexts/shared";
import { AchievementsInput } from "./achievements-input";

export function AchievementsForm() {
  const {
    updateChanges,
    report,
    viewMode
  } = useContext(ReportFormModalContext);

  const [form] = Form.useForm();

  const onFormChange = (_, values) => {
    updateChanges(values);
  };

  useEffect(() => {
    form.setFieldsValue({ achievements: report?.achievements });
  }, []);

  return (
    <Form
      onValuesChange={onFormChange}
      name="achievements-form"
      layout="vertical"
      form={form}
      validateMessages={genericFieldValidationMsgs}>
      <Form.Item
        label="¿Cuáles son los logros obtenidos en el periodo?"
        name="achievements">
        <AchievementsInput viewMode={viewMode} />
      </Form.Item>
    </Form>
  );
}
