import React from "react";
import { Card } from "antd";

export function Section({
  title,
  children,
  spaced,
  bottom,
  transparent,
  ...props
}) {
  if(spaced) {
    props.style = { ...props.style, margin: "15px" };
  }

  if(transparent) {
    props.style = { ...props.style, backgroundColor: "transparent" };
  }

  return (
    <Card
      bordered={false}
      title={title}
      {...props}>
      { children }
      <div style={{display: "flex", flexDirection: "row-reverse"}}>
        { bottom }
      </div>
    </Card>
  );
}
