import React, { useContext, useState, useEffect } from "react";
import { ProfileVerifierContext } from ".";
import { CurrentUserContext } from "..";
import { Fallback } from "./fallback";

export function ProfileVerifierProvider({ children }) {
  const { currentUser } = useContext(CurrentUserContext);
  const [state, setState] = useState({
    hasODEs: false,
    hasCompletedProfile: false
  });

  useEffect(() => {
    let hasODEs = currentUser?.odes?.length > 0;
    const hasCompletedProfile = currentUser?.completedProfile;

    if (currentUser.systemRole === "COORDINATOR") {
      hasODEs = true;
    }

    setState({
      hasODEs,
      hasCompletedProfile
    });
  }, [currentUser]);

  return (
    <ProfileVerifierContext.Provider>
      {state.hasODEs && state.hasCompletedProfile ?
        children
        : <Fallback
            hasODEs={state.hasODEs}
            hasCompletedProfile={state.hasCompletedProfile}
            user={currentUser} />}
    </ProfileVerifierContext.Provider>
  );
}
