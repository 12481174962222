import React, { useState } from "react";
import { OrganizationModalContext } from "./context";
import { OrganizationModal } from "./modal";

export function OrganizationModalProvider({ children }) {
  const [state, setState] = useState({
    isModalOpen: false,
    organizationId: undefined
  });

  const openModal = organizationId => {
    setState({
      isModalOpen: true,
      organizationId
    });
  };

  const closeModal = () => {
    setState({
      isModalOpen: false,
      organizationId: undefined
    });
  };

  return (
    <OrganizationModalContext.Provider value={{ openModal, closeModal }}>
      <OrganizationModal
        organizationId={state.organizationId}
        visible={state.isModalOpen}
        onCancel={closeModal} />
      {children}
    </OrganizationModalContext.Provider>
  );
}
