import React, { useContext, useEffect } from "react";
import { Form } from "antd";
import {
  TwitterOutlined,
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  LinkOutlined
} from "@ant-design/icons";
import { LargeIcon } from "./large-icon";
import { FormContainer } from "../../../../../shared";
import { NewExpertContext } from "../../../../../../contexts/experts/new/context";
import { genericFieldValidationMsgs, urlPatterns } from "../../../../../../utils";
import { Input } from "./input";

const AccountsForm = ({
  editable = true,
  patternMsg = "El formato de la URL no es válido"
}) => {
  const {
    updateAccounts,
    data = {},
    form,
    loading,
    error
  } = useContext(NewExpertContext);

  const { user = {} } = data;
  const expertProfile = user?.expertProfile || {};

  useEffect(() => {
    form.setFieldsValue({ ...expertProfile });
  }, [data, loading, error, editable]);

  const handleOnChange = (changedFields = []) => {
    const { name = [], value } = changedFields[0] || {};
    const fieldName = name[0];

    updateAccounts({
      [fieldName]: value
    });
  };

  return (
    <Form
      onFieldsChange={handleOnChange}
      form={form}
      validateMessages={genericFieldValidationMsgs}>
      <FormContainer>
        <Form.Item
          name="twitter"
          rules={[
            {required: true }, {
              pattern: urlPatterns.twitter,
              message: patternMsg
            }]}>
          <Input
            editable={editable}
            socialNetwork="twitter"
            addonBefore={<LargeIcon icon={TwitterOutlined} />}
            placeholder="Twitter" />
        </Form.Item>
        <Form.Item
          name="facebook"
          rules={[
            {required: true }, {
              pattern: urlPatterns.facebook,
              message: patternMsg
            }]}>
          <Input
            editable={editable}
            socialNetwork="facebook"
            addonBefore={<LargeIcon icon={FacebookFilled} />}
            placeholder="Facebook" />
        </Form.Item>
        <Form.Item
          name="instagram"
          rules={[
            { required: true }, {
              pattern: urlPatterns.facebook,
              message: patternMsg
            }]}>
          <Input
            editable={editable}
            socialNetwork="instagram"
            addonBefore={<LargeIcon icon={InstagramOutlined} />}
            placeholder="Instagram" />
        </Form.Item>
        <Form.Item
          editable={editable}
          socialNetwork="linkedin"
          name="linkedin"
          rules={[
            { required: true }, {
              pattern: urlPatterns.linkedin,
              message: patternMsg
            }]}>
          <Input
            editable={editable}
            socialNetwork="linkedin"
            addonBefore={<LargeIcon icon={LinkedinFilled} />}
            placeholder="Linkedin" />
        </Form.Item>
        <Form.Item
          name="otherUrl"
          rules={[
            { required: true }, {
              pattern: urlPatterns.url,
              message: patternMsg
            }]}>
          <Input
            editable={editable}
            socialNetwork="other"
            addonBefore={<LargeIcon icon={LinkOutlined} />}
            placeholder="Otro" />
        </Form.Item>
      </FormContainer>
    </Form>
  );
};

export default AccountsForm;
