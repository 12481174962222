/* eslint-disable max-lines */
import { gql } from "graphql-tag";

export const getScheduledServices = gql`
  query GetScheduledServices(
    $serviceType: String!
    $expertId: ID!
    $skip: Int
    $first: Int
    $category: String
    $status: String
    $programId: ID
    $searchString: String
    $fromAcceptedDate: DateTime
    $toAcceptedDate: DateTime
  ) {
    scheduledExpertServices(
      orderBy: createdAt_DESC
      where: { 
        status: $status
        acceptedAt_gte: $fromAcceptedDate
        acceptedAt_lte: $toAcceptedDate
        assignedExpertService: { 
          expertService: {
            expert: { id: $expertId }
            category: $category
            type: $serviceType
          }
          batch: {
            program: {
              id: $programId
            }
          }
          OR: [
            {
              expertService: {
                category: $category
                OR: [
                  { iname_contains: $searchString },
                  { idescription_contains: $searchString },
                  { expert: { ifullName_contains: $searchString } }
                ]
                expert: {
                  id: $expertId
                }
              }
            },
            {
              batch: { program: { iname_contains: $searchString } }
            },
            {
              batch: { iname_contains: $searchString }
            },
            {
              ode: { iname_contains: $searchString }
            }
          ]
        }
      }
      skip: $skip
      first: $first
    ) {
      id
      serviceDate
      status
      duration
      acceptedAt
      deliveredAt
      cancelledBy
      calendarEventUri
      requestedBy {
        id
        fullName
        email
        phone
      }
      assignedExpertService {
        id
        assignmentNumber
        assignationIdentifier
        expertServicePrice
        assignedUnits
        availableUnits
        expertServiceData
        expertService {
          id
          name
          type
          price
          method
          category
          expert {
            id
            fullName
            firstName
            middleName
            lastName
          }
        }
        batch {
          id
          name
          coordinators: users(
            where: { systemRole_in: [COORDINATOR, SUPER_ADMIN] }
          ) {
            id
            fullName
            phone
            email
          }
          program {
            id
            name
            coordinator {
              id
              fullName
              phone
              email
            }
          }
        }
        ode {
          id
          name
        }
      }
    }
    meta: scheduledExpertServicesConnection(
      where: { 
        status: $status
        acceptedAt_gte: $fromAcceptedDate
        acceptedAt_lte: $toAcceptedDate
        assignedExpertService: { 
          expertService: {
            expert: { id: $expertId }
            category: $category
            type: $serviceType
          }
          batch: {
            program: {
              id: $programId
            }
          }
          OR: [
            {
              expertService: {
                category: $category
                OR: [
                  { iname_contains: $searchString },
                  { idescription_contains: $searchString },
                  { expert: { ifullName_contains: $searchString } }
                ]
                expert: {
                  id: $expertId
                }
              }
            },
            {
              batch: { program: { iname_contains: $searchString } }
            },
            {
              batch: { iname_contains: $searchString }
            },
            {
              ode: { iname_contains: $searchString }
            }
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
