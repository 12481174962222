import React from "react";
import { List, Typography } from "antd";
import { getReadableValue } from "../../../../helpers/select-options";
import { programTypes } from "../../../../helpers/select-options/program";
import { EmptyImage, Image } from "../../../../components/shared";

const { Text } = Typography;

const imageStyle = {
  width: "150px",
  height: "100px"
};

export function SelectedProgram({ program = {} }) {
  return (
    <List.Item
      className="batch-program"
      extra={
        program.coverPicUrl ?
          <Image
            src={program.coverPicUrl}
            style={imageStyle} />
          : <EmptyImage text={program?.name} />
      }>
      <List.Item.Meta
        title={<Text strong>{program?.name}</Text>}
        description={getReadableValue(programTypes, program?.type)} />
    </List.Item>
  );
}
