import { client } from "../../../graphql";
import { scheduledExpertServices } from "./graphql";

export const getScheduledExpertServices = async ({
  companyId,
  batchId,
  type
}) => {
  const result = await client.query({
    query: scheduledExpertServices,
    variables: { companyId, batchId, type },
    fetchPolicy: "network-only"
  });

  return result?.data?.scheduledExpertServices || [];
};
