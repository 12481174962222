import React from "react";
import img from "../../../../assets/maintenance-fallback-image.svg";
import { ReactComponent as Logo } from "../../../images/logo.svg";

import { Typography } from "antd";

const containerStyle = {
  padding: "1rem",
  height: "100vh",
  width: "100vw",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center"
};

const subContainerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center"
};

const barStyle = {
  top: 0,
  zIndex: 2,
  padding: 20,
  width: "100%",
  textAlign: "left",
  position: "fixed"
};

export const MaintenanceFallbackPage = () => {
  return (
    <div>
      <div style={barStyle}>
        <Logo />
      </div>
      <div style={containerStyle}>
        <div style={subContainerStyle}>
          <img
            src={img}
            style={{ width: "400px", marginBottom: "2rem" }}
            alt="Error fallback" />
          <Typography.Title level={2} style={{ color: "#6648c4" }}>
            Hola, estamos fuera de servicio por mantenimiento.
          </Typography.Title>
          <Typography.Text style={{ marginBottom: "6rem", fontSize: 16 }}>
            Lamentamos los inconvenientes y estaremos de regreso en unos
            minutos.
          </Typography.Text>
          <span>
            Si necesitas ayuda contáctanos en <a
              href="mailto:support@eossolution.io">support@eossolution.io</a>
          </span>
        </div>
      </div>
    </div>
  );
};
