import { client } from "../../../graphql";
import { createPublishedContent } from "./graphql";

export const createPublishedContentService = async ({
  companyId,
  masterContentId
}) => {
  const result = await client.mutate({
    mutation: createPublishedContent,
    variables: {
      odeId: companyId,
      masterContentId
    }
  });

  return result?.data?.createPublishedContent || {};
};
