import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useQueryFilters, useControlledPagination } from "../../../helpers";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";
import { MainMenu } from "../../../components/admin/shared";
import { default as OdesList } from "../../../components/shared/ode-list";
import {
  FilterControlsOdes,
  SidebarLayout,
  Section
} from "../../../components/shared";
import {
  CreateOdeModalProvider,
  MenuContext,
  PaginationContext,
  ImportOdeModalProvider
} from "../../../contexts/shared";

export const OdesIndex = () => {
  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: ["odes"],
    selectedKeys: ["odes"]
  });

  const {
    loading,
    data,
    error,
    refetch,
    variables
  } = useQuery(
    shared.queries.getOdes,
    {
      client,
      fetchPolicy: "network-only",
      variables: {
        first: 12
      }
    }
  );

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: {
      first: 12
    }
  });

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 12
  });

  const onFilterChange = filter => {
    const { skip, pageSize: first } = onPaginationChange(1);

    applyFilters((prevFilters = {}) => {
      if (!filter.batchId && !filter.programId) {
        // Omit for new ODEs without connected batch
        filter.batches_some = undefined;
      } else {
        filter.batches_some = {
          id: filter.batchId,
          program: { id: filter.programId }
        };
      }

      return ({
        ...prevFilters,
        ...filter,
        skip,
        first
      });
    });
  };

  const { registerRefetch } = useContext(PaginationContext);

  registerRefetch("odes", refetch, variables);

  return (
    <CreateOdeModalProvider>
      <ImportOdeModalProvider refetch={refetch}>
        <SidebarLayout menu={<MainMenu />}>
          <Section
            title="Compañías"
            spaced
            extra={<FilterControlsOdes onChange={onFilterChange} />}>
            <OdesList
              data={data}
              error={error}
              isLoading={loading}
              onChangePage={onPaginationChange}
              currentPage={currentPage} />
          </Section>
        </SidebarLayout>
      </ImportOdeModalProvider>
    </CreateOdeModalProvider>
  );
};
