import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  entrepreneursOccupationToChart
} from "./entrepreneurs-occupation-to-chart";
import {
  entrepreneursOccupationOptions
} from "./entrepreneurs-occupation-options";
import { Visibility } from "../../../../components/shared";
import { Card } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const EntrepreneursOccupationChart = ({ cubeClient, filters }) => {
  const [occupationData, setOccupationData] = useState();

  useEffect(() => {
    const loadCubeQuery = async () => {
      const cubeFilters = [
        {
          "member": "Company.teamMemberOccupation",
          "operator": "set"
        }
      ];

      if (filters.batchId) {
        cubeFilters.push({
          "member": "Company.batchId",
          "operator": "equals",
          "values": [filters.batchId]
        });
      }

      if (filters.programId) {
        cubeFilters.push({
          "member": "Company.programId",
          "operator": "equals",
          "values": [filters.programId]
        });
      }

      if (filters.batchRangeDates.since && filters.batchRangeDates.until) {
        const { since, until } = filters.batchRangeDates;
        const filter = {
          "member": "Company.batchStartDate",
          "operator": "inDateRange",
          "values": [since, until]
        };
        cubeFilters.push(filter);
      }

      const resultSet = await cubeClient.load({
        "measures": [
          "Company.entrepreneursCount"
        ],
        "order": {
          "Company.entrepreneursCount": "desc"
        },
        "dimensions": [
          "Company.teamMemberOccupation"
        ],
        filters: cubeFilters,
        "limit": 5000
      });

      const queryData = resultSet.loadResponse.results[0].data;

      const chartData = entrepreneursOccupationToChart(queryData);
      setOccupationData(chartData);
    };


    loadCubeQuery()
      .then()
      .catch(console.error);
  }, [filters]);

  return (
    <Visibility visible={!!occupationData}>
      <Card
        style={{ marginTop: "2rem" }}
        title="Emprendedores por ocupación">
        <Bar
          data={occupationData}
          type={"bar"}
          height={400}
          options={entrepreneursOccupationOptions} />
      </Card>
    </Visibility>
  );
};
