import gql from "graphql-tag";

// accepted by expert, and then an user cancelled it
export const cancelScheduledExpertService = gql`
  mutation CancelScheduledExpertService(
    $id: ID!,
    $cancelledBy: String,
    $events: ServiceEventUpdateManyInput
  ) {
    updateScheduledExpertService (
      where: { id: $id }
      data: {
        status: "CANCELLED",
        cancelledBy: $cancelledBy,
        events: $events
      }
    ) {
      id
    }
  }
`;
