import gql from "graphql-tag";

export const updateBatchServicesConnection = gql`
  mutation UpdateBatchServicesConnection(
    $batchId: ID!,
    $services: ExpertServiceUpdateManyWithoutBatchesInput!
  ) {
    updateBatch(
      where: { id: $batchId }
      data: {
        services: $services
      }
    ) {
      id
    }
  }
`;