import React, { useContext } from "react";
import { Form } from "antd";
import {
  EditButton,
  MissingProfileDataAlert,
  Section,
  Visibility
} from "../../../";
import { EditProfileContext, CurrentUserContext } from "../../../../../contexts/shared";
import { OccupationForm } from "../form";
import { ActionButtonForm } from "../../action-button-form";
import { getUncompletedEntrepreneurProfileData } from "../../../../../helpers";

export const OccupationEditSection = ({ editable = false }) => {
  const [form] = Form.useForm();
  const { refetchCurrentUser } = useContext(CurrentUserContext);
  const { data } = useContext(EditProfileContext);

  const {
    saveChanges,
    editing,
    setEditing
  } = useContext(EditProfileContext);

  const onSave = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    values.companies = {set: values.companies};
    await saveChanges(values, "occupation");
    await refetchCurrentUser();
  };

  const missingData = getUncompletedEntrepreneurProfileData(data?.user);

  return (
    <Section
      spaced
      title={<MissingProfileDataAlert
        missingInfo={missingData.occupation
        }>
        Ocupación
      </MissingProfileDataAlert>}
      extra={
        <Visibility visible={editable && !editing?.occupation}>
          <EditButton
            onClick={() => setEditing(true, "occupation")}>
            Editar
          </EditButton>
        </Visibility>
      }
      bottom={
        <Visibility visible={editable && editing?.occupation}>
          <ActionButtonForm
            setEditing={setEditing}
            formName="occupation"
            onSave={onSave} />
        </Visibility>}>
      <OccupationForm
        form={form}
        editable={editing.occupation && editable} />
    </Section>
  );
};
