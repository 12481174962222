import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  PaymentReportIndex
} from "../../../pages/admin/experts/services/payment-report";
import { ServicesIndex } from "../../../pages/expert/services";
import { ServiceRequestsIndex } from "../../../pages/expert/services/requests";

export const ExpertServicesRoutes = <Switch>
  <Route
    exact
    path="/v2/expert/services"
    component={ServicesIndex} />
  <Route
    path="/v2/expert/services/monitoring"
    component={ServiceRequestsIndex} />
  <Route
    path="/v2/expert/services/:id/payment-report"
    component={PaymentReportIndex} />
</Switch>;
