import React from "react";
import { Descriptions } from "antd";
import { FormContainer } from "../../..";

export function Details({ ode = {} }) {

  return (
    <FormContainer>
      <Descriptions column={1}>
        <Descriptions.Item label="Tipo de tecnología">
          {ode.technologyLevel}
        </Descriptions.Item>
        <Descriptions.Item label="Giro">
          {ode.commercialBussines}
        </Descriptions.Item>
        <Descriptions.Item label="Industria">
          {ode.economicIndustry}
        </Descriptions.Item>
        <Descriptions.Item label="Sector">
          {ode.economicSector}
        </Descriptions.Item>
        <Descriptions.Item label="Actividad">
          {ode.economicActivity}
        </Descriptions.Item>
        <Descriptions.Item label="Palabras clave">
          {ode.keywords}
        </Descriptions.Item>
        <Descriptions.Item label="Finalidad del negocio">
          {ode.profitMotives}
        </Descriptions.Item>
      </Descriptions>
    </FormContainer>
  );
}
