import { client } from "../../../graphql";
import { getByProgramQuery } from "./graphql";

export async function getByProgram({ programId }) {
  const result = await client.query({
    query: getByProgramQuery,
    fetchPolicy: "network-only",
    variables: { programId }
  });

  return result.data?.batches || [];
}
