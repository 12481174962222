import React from "react";
import { List, Space, Button, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import * as uuid from "uuid";
import { CompositeField, Visibility } from "../../../../../components/shared";
import { TrlField } from "./trl-field";

export function TrlInput({ onChange, value, viewMode = false }) {
  const onItemChange = ({ trl, values, idx, replaceItemAtIndex }) => {
    values.uid = trl.uid;
    replaceItemAtIndex(idx, values);
  };

  return (
    <CompositeField
      onChange={onChange}
      isAddDisabled={true}
      value={value}>
      {({ items, addNew, removeItem, replaceItemAtIndex }) =>
        <>
          <List>
            {(items.length === 0) ?
              <div style={{ textAlign: "center", width: "100%" }}>
                <Typography.Text
                  type="secondary">
                  No hay ningún nivel
                </Typography.Text>
              </div>
              : items.map((trl, idx) => (
                <TrlField
                  key={`trl-item-${trl.uid}`}
                  value={trl}
                  idx={idx}
                  viewMode={viewMode}
                  removeItem={removeItem}
                  onChange={values =>
                    onItemChange({ trl, values, idx, replaceItemAtIndex })} />)
              )}
          </List>
          <Visibility
            visible={!viewMode}>
            <Space direction="horizontal">
              <Button
                type="link"
                icon={<PlusOutlined />}
                onClick={() => addNew({ uid: uuid.v4() })}>
                Agregar nivel
              </Button>
            </Space>
          </Visibility>
        </>
      }
    </CompositeField>
  );
}