import React, { useEffect, useContext } from "react";
import { SidebarLayout, Section } from "../../../components/shared";
import { ProgramModalProvider } from "../../../contexts/shared/program-modal";
import { CurrentUserContext } from "../../../contexts/shared/current-user";
import { BatchModalProvider } from "../../../contexts/shared/batch-modal";
import {
  default as ProgramsList
} from "../../../components/admin/programs/list";
import {
  FilterControls
} from "../../../components/admin/programs/filter-controls";
import { coordinator } from "../../../graphql/coordinator";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../../../graphql";
import { MenuContext } from "../../../contexts/shared/menu";
import { MainMenu } from "../../../components/coordinator/shared";
import { ProfileVerifierProvider } from "../../../contexts/shared/profile-verifier";
import { useQueryFilters, useControlledPagination } from "../../../helpers";
import { PaginationContext } from "../../../contexts/shared";

export function ProgramsIndex() {
  const { currentUser } = useContext(CurrentUserContext);

  const {
    loading,
    error,
    data,
    refetch,
    variables
  } = useQuery(
    coordinator.queries.getAvailablePrograms, {
    variables: {
      coordinatorId: currentUser?.id,
      first: 10
    },
    fetchPolicy: "network-only",
    client
  });

  const { setMenuState } = useContext(MenuContext);

  const { registerRefetch } = useContext(PaginationContext);

  registerRefetch("programs", refetch, variables);

  useEffect(() => {
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["programs/all"]
    });
  }, []);

  const { applyFilters } = useQueryFilters({ refetch });
  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 10
  });

  const onFilterChange = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  return (
    <BatchModalProvider>
      <ProgramModalProvider>
        <SidebarLayout menu={<MainMenu />}>
          <ProfileVerifierProvider>
            <Section
              title="Programas"
              spaced
              extra={<FilterControls onChange={onFilterChange} />}>
              <ProgramsList
                data={data}
                isLoading={loading}
                error={error}
                onChangePage={onPaginationChange}
                currentPage={currentPage} />
            </Section>
          </ProfileVerifierProvider>
        </SidebarLayout>
      </ProgramModalProvider>
    </BatchModalProvider>
  );
}
