export const NOTION_PAGES_ENUM = {
  ORGANIZATIONS_DASHBOARD: {
    pageId: "ec2b812bd1ee48fbb5d15cf9b7c125b9",
    pages: {}
  },
  BATCHES: {
    pageId: "e3685e122e6944eeb0002dd1ea5b393f",
    pages: {
      COMPANIES_TAB: {
        pageId: "f9c04579cd5f43cc97cc9ab4c674b002"
      }
    }
  }
};
