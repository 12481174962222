import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { ProgramDetailContext } from "./context";
import { withRouter } from "../../../../helpers";
import { shared } from "../../../../graphql/shared";
import { client } from "../../../../../graphql";

function ProgramDetailContextProvider({ children }) {
  const { programId = "" } = useParams();

  const { data, loading, error } = useQuery(shared.queries.getProgramById, {
    client,
    variables: {
      id: programId
    }
  });

  const injectActions = {
    data,
    loading,
    error
  };

  return (
    <ProgramDetailContext.Provider value={injectActions}>
      { children }
    </ProgramDetailContext.Provider>
  );
}

export default withRouter(ProgramDetailContextProvider);
