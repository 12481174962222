import moment from "moment";

/**
 * Formats a start and end date to a string in this way:
 * 12 enero del 2021 al 15 febrero del 2022
 * @param start
 * @param end
 * @returns {string}
 */
export function dayToDay (start, end) {
  const yearStart = moment(start).format("YYYY");
  const yearFinish = moment(end).format("YYYY");

  return yearStart === yearFinish
    // eslint-disable-next-line max-len
    ? `${moment(start).format("DD MMMM")} al ${moment(end).format("DD MMMM YYYY")}`
    // eslint-disable-next-line max-len
    : `${moment(start).format("DD MMMM YYYY")} al ${moment(end).format("DD MMMM YYYY")}`;
}
