import React, { useContext } from "react";
import { Button, Skeleton } from "antd";
import { DetailContent } from "./detail-content";
import { ScheduledMentoringHeader } from "../components/headers";
import { useService } from "../../../../../hooks/shared";
import { BaseServiceDetailModal } from "../../../../shared/modals";
import {
  CurrentUserContext,
  ServicesLayerContext
} from "../../../../../contexts/shared";
import { AlertError } from "../../../../shared";
import { Link } from "react-router-dom";
import { limitDateReached } from "../../../../../helpers";

export function EntrepreneurAssignedMentoringDetailModal({
  // Could be injected by the modal provider
  selectedItem, // selected scheduled service
  company,
  ...props
}) {
  const {
    AssignedExpertServiceService,
    EntrepreneurSessionsServicesService
  } = useContext(ServicesLayerContext);

  const { currentUser } = useContext(CurrentUserContext);

  const {
    loading,
    data: assignedExpertService
  } = useService(
    AssignedExpertServiceService.getById,
    { id: selectedItem?.id },
    { shouldFetch: !!selectedItem?.id && props.visible }
  );

  const variablesQuery = {
    companyId: company?.id,
    serviceId: selectedItem?.expertService?.id,
    entrepreneurId: currentUser.id
  };

  const {
    loading: onGoingScheduledServicesLoading,
    data: onGoingScheduledServices
  } = useService(
    EntrepreneurSessionsServicesService
      .getScheduledMentorshipServicesEntrepreneur,
    variablesQuery,
    { shouldFetch: !!selectedItem?.expertService?.id && !!company?.id && props.visible }
  );

  const {
    loading: availableAssignedServicesLoading,
    data: availableAssignedServices
  } = useService(
    EntrepreneurSessionsServicesService
      .getAvailableAssignedServices,
    variablesQuery,
    { shouldFetch: !!selectedItem?.expertService?.id && !!company?.id && props.visible }
  );

  const { availableUnits, limitDate } = assignedExpertService || {};
  const hasScheduled =
    !!onGoingScheduledServices && !!onGoingScheduledServices.length;
  const hasAvailableAssigned =
    !!availableAssignedServices && !!availableAssignedServices.length;

  const renderAlertError = () => {
    if (availableUnits === 0 || limitDateReached(limitDate))
      return null;

    return (hasScheduled && availableUnits > 0) ? (
      <AlertError
        title="No es posible agendar. Actualmente tienes sesiones sin concluir."
        description={
          <p>
            Para ver lo que tienes pendiente da
            <Link to="../../sessions/assignations"> click aquí</Link>.
          </p>
      } />
    ) : null;
  };

  const renderFooterButtons = () => {
    if (hasAvailableAssigned)
      return null;

    if (availableUnits === 0 || limitDateReached(limitDate))
      return (
        <>
          <Button type="default">Ver sesiones</Button>
          <Button type="primary">Solicitar más</Button>
        </>
      );

    return null;
  };

  const fullLoading =
    loading &&
    onGoingScheduledServicesLoading &&
    availableAssignedServicesLoading;

  return (
    <BaseServiceDetailModal
      header={<ScheduledMentoringHeader
        scheduledExpertService={{
          assignedExpertService: assignedExpertService || undefined
        }} />}
      footer={renderFooterButtons()}
      alert={!fullLoading && renderAlertError()}
      {...props}>
      {fullLoading ?
        <Skeleton paragraph={{ rows: 3 }} />
        : <DetailContent assignedExpertService={assignedExpertService} /> }
    </BaseServiceDetailModal>
  );
}
