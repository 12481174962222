import React, { useContext } from "react";
import { Form } from "antd";
import { SidebarLayout, Section } from "../../../../../components/shared";
import { StepsWrapper } from "../../../../../components/expert/shared";
import { CurrentUserProvider } from "../../../../../contexts/shared/current-user";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { EntrepreneurshipsForm } from "../../../../../components/shared/profile-forms";
import { formatValues } from "../../../../../components/shared/profile-forms/entrepreneurships/section/format-values";
import { EditProfileProvider, EditProfileContext } from "../../../../../contexts/shared/edit-profile";
import { withRouter } from "../../../../../helpers/with-router";
import { PageLeavePromptProvider } from "../../../../../contexts/shared";

function EntrepreneurshipsIndex({ match, navigate }) {
  const { setMenuState } = useContext(MenuContext);

  const expertId = match?.params?.id;
  const [entrepreneurshipsForm] = Form.useForm();

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/profiles"]
  });

  const onNext = saveChanges => async function(event) {
    event.preventDefault();
    await entrepreneurshipsForm.validateFields();

    try {
      const { entrepreneurships } = entrepreneurshipsForm.getFieldsValue();
      const values = formatValues(entrepreneurships);
      await saveChanges(values);
      navigate("/v2/coordinator/experts");
    } catch(error) {
      console.error(error);
    }
  };

  return (
    <PageLeavePromptProvider>
      <EditProfileProvider userId={expertId}>
        <EditProfileContext.Consumer>
          {({ saveChanges, editing, loading }) => (
            <CurrentUserProvider>
              <SidebarLayout>
                <StepsWrapper
                  currentStep={3}
                  prevUrl={`/v2/coordinator/experts/new/${expertId}/accounts`}
                  onNext={onNext(saveChanges, editing)}>
                  <Section
                    spaced
                    loading={loading}
                    title="Emprendimientos">
                    <EntrepreneurshipsForm
                      editable
                      form={entrepreneurshipsForm} />
                  </Section>
                </StepsWrapper>
              </SidebarLayout>
            </CurrentUserProvider>
          )}
        </EditProfileContext.Consumer>
      </EditProfileProvider>
    </PageLeavePromptProvider>
  );
}

export default withRouter(EntrepreneurshipsIndex);
