export const ODE_SECTORS = [
  {
    value: "Extracción de materias primas",
    label: "Extracción de materias primas",
    legacyValues: [
      "AGROTECH",
      "MATERIAL_EXTRACTION",
      "Agrotech"
    ]
  },
  {
    value: "Construcción",
    label: "Construcción",
    legacyValues: [
      "CONSTRUCTION_TECH",
      "Construction Tech"
    ]
  },
  {
    value: "Manufactura",
    label: "Manufactura",
    legacyValues: [
      "MANUFACTURING_TECH",
      "MANUFACTURE",
      "FASHION",
      "NANO_TECH",
      "CHEMICAL_TECH",
      "Chemical Tech",
      "MANUFACTORING_TECH"
    ]
  },
  {
    value: "Comercio",
    label: "Comercio",
    legacyValues: [
      "RETAIL_TECH",
      "COMMERCE",
      "E_COMMERCE_TECH",
      "REAL_STATE_TECH",
      "E-Commerce Tech"
    ]
  },
  {
    value: "Transporte",
    label: "Transporte",
    legacyValues: [
      "AUTOMOTIVE_TECH",
      "TRANSPORT",
      "Transporte",
      "TRANSPOTATION_TECH"
    ]
  },
  {
    value: "Servicios",
    label: "Servicios",
    legacyValues: [
      "SOFTWARE",
      "FINTECH",
      "TECH",
      "AI_AND_ROBOTICS",
      "HEALTH_TECH",
      "SERVICES",
      "SYNTHETIC_BIOLOGY",
      "TOURISM_TECH",
      "LEGAL_TECH",
      "HR_TECH",
      "ENERGY_AND_ENVIROMENTAL",
      "IOT",
      "INSURANCE_TECH",
      "ENTERTAINING_TECH",
      "GOVERNMENT",
      "SYSTEMS",
      "ED_TECH",
      "MARKETING_TECH",
      "CORPORATE_TECH",
      "KNOWLEDGE_AND_RESEARCH",
      "SECURITY_TECH",
      "SOCIAL",
      "NETWORKS_AND_COMPUTING",
      "FOOD", // investigating what activity is related to. It could change
      "Software",
      "Marketing Tech",
      "Tech",
      "Health",
      "ED Tech"
    ]
  }
];
