import React, { useContext } from "react";
import { Button, Tabs } from "antd";
import {
  CurrentUserContext,
  ServicesLayerContext
} from "../../../../../../../contexts/shared";
import { Avatar } from "../../../../../../shared";
import {
  EntrepreneurMentoringSection,
  EntrepreneurServicesSection
} from "./sections";
import { UsergroupAddOutlined } from "@ant-design/icons";
import {
  SectionHeading
} from "../../../../../../shared/batch-offer/section-heading";
import { useService } from "../../../../../../../hooks/shared";
import { useHistory } from "react-router-dom";

export function ExpertsSection({ batch = null }) {
  const { CompanyService } = useContext(ServicesLayerContext);
  const { currentUser } = useContext(CurrentUserContext);
  const history = useHistory();

  const { data: companies, loading: loadingCompanies } = useService(
    CompanyService.getEntrepreneurCompaniesInBatch,
    { batchId: batch?.id, entrepreneurId: currentUser?.id },
    { shouldFetch: !!batch && !!currentUser?.id }
  );

  const [firstMatchedCompany] = companies || [];

  const goToCompanyProfile = () => {
    history
      // eslint-disable-next-line max-len
      .push(`/v2/entrepreneur/odes/${firstMatchedCompany?.id}/general-information`);
  };

  return (
    <div
      className="batch-section-container">
      <SectionHeading
        loading={loadingCompanies}
        title={firstMatchedCompany?.name}
        subtitle={firstMatchedCompany?.description}
        rightComponent={<Button
          ghost
          type="primary"
          onClick={goToCompanyProfile}>
          Ver perfil
        </Button>}
        leftComponent={<Avatar size={45} src={firstMatchedCompany?.logoUrl}>
          {firstMatchedCompany?.name}
        </Avatar>} />
      <Tabs defaultActiveKey="mentors">
        <Tabs.TabPane
          key="mentors"
          tab={<span><UsergroupAddOutlined /> Mentores</span>}>
          <EntrepreneurMentoringSection
            batch={batch}
            company={firstMatchedCompany} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="services"
          tab={<span><UsergroupAddOutlined /> Servicios</span>}>
          <EntrepreneurServicesSection
            batch={batch}
            company={firstMatchedCompany} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="sessions"
          tab={<span><UsergroupAddOutlined /> Sesiones</span>}>
          <div>sesiones</div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
