import { client3 } from "../../../graphql";
import {
  getExperiencesByCampusQuery
} from "./graphql";

export async function getCampusExperiences ({
  campus,
  startDate,
  endDate,
  audienceTypes
}) {
  const result = await client3.query({
    query: getExperiencesByCampusQuery,
    fetchPolicy: "network-only",
    variables: {
      campus,
      startDate,
      endDate,
      audienceTypes
    }
  });

  return result?.data?.personExperienceAttendances || [];
}
