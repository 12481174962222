import React from "react";
import { List, Space } from "antd";
import { MinusCircleOutlined, EditOutlined } from "@ant-design/icons";
import styled from "styled-components";

const { Item } = List;

const Title = styled.div`
  font-size: 18px;
`;

export const LanguageItem = ({ item, onEditItem, onRemoveItem, editable }) => {
  const levels = {
    BASIC: "Básico",
    INTERMEDIATE: "Intermedio",
    EXPERT: "Experto"
  };

  const renderExtra = () => (
    <Space>
      <EditOutlined onClick={() => onEditItem(item)} />
      <MinusCircleOutlined onClick={() => onRemoveItem(item)} />
    </Space>
  );

  return (
    <Item
      extra={editable && renderExtra()}>
      <Title>{levels[item.level]} · {item.name}</Title>
    </Item>
  );
};
