/* eslint-disable max-len */
/* eslint-disable max-lines */
export const INDUSTRIES = [
  {
    label: "Agricultura",
    value: "Agricultura",
    legacyValues: [
      "FARMING",
      "Agrotecnología"
    ]
  },
  {
    label: "Agua",
    value: "Agua",
    legacyValues: [
      "WATER"
    ]
  },
  {
    label: "Alimentos",
    value: "Alimentos",
    legacyValues: [
      "FOOD"
    ]
  },
  {
    label: "Automotriz",
    value: "Automotriz",
    legacyValues: [
      "AUTOMOTIVE"
    ]
  },
  {
    label: "Construcción",
    value: "Construcción",
    legacyValues: [
      "CONSTRUCTION"
    ]
  },
  {
    label: "Diseño",
    value: "Diseño",
    legacyValues: [
      "DESIGN"
    ]
  },
  {
    label: "Educación",
    value: "Educación",
    legacyValues: [
      "EDUCATION",
      "EDUCATONAL_SERVICES"
    ]
  },
  {
    label: "Energías limpias",
    value: "Energías limpias",
    legacyValues: [
      "CLEAN_ENERGY",
      "Energía"
    ]
  },
  {
    label: "Entretenimiento",
    value: "Entretenimiento",
    legacyValues: [
      "ENTERTAINMENT"
    ]
  },
  {
    label: "Equipos médicos",
    value: "Equipos médicos",
    legacyValues: [
      "MEDICAL_EQUIPMENTS"
    ]
  },
  {
    label: "Farmacéutica",
    value: "Farmacéutica",
    legacyValues: [
      "PHARMACEUTICAL"
    ]
  },
  {
    label: "Finanzas",
    value: "Finanzas",
    legacyValues: [
      "FINANCE",
      "FINANCIAL_SERVICES"
    ]
  },
  {
    label: "Forestal",
    value: "Forestal",
    legacyValues: [
      "FOREST"
    ]
  },
  {
    label: "Ganadería",
    value: "Ganadería",
    legacyValues: [
      "CATTLE_RAISING"
    ]
  },
  {
    label: "Gas y Petróleo",
    value: "Gas y Petróleo",
    legacyValues: [
      "GAS_AND_OIL",
      "ENERGY_SUPPLY"
    ]
  },
  {
    label: "Gobierno",
    value: "Gobierno",
    legacyValues: [
      "GOVERNMENT",
      "GOVERNMENT_ACTIVITIES"
    ]
  },
  {
    label: "Hardware",
    value: "Hardware",
    legacyValues: [
      "HARDWARE"
    ]
  },
  {
    label: "Inmobiliaria",
    value: "Inmobiliaria",
    legacyValues: [
      "REAL_ESTATE",
      "Inmobiliario"
    ]
  },
  {
    label: "Legal",
    value: "Legal",
    legacyValues: [
      "LEGAL"
    ]
  },
  {
    label: "Logística",
    value: "Logística",
    legacyValues: [
      "LOGISTICS"
    ]
  },
  {
    label: "Mercadotecnia",
    value: "Mercadotecnia",
    legacyValues: [
      "MARKETING",
      "MASS_MEDIA"
    ]
  },
  {
    label: "Minería",
    value: "Minería",
    legacyValues: [
      "MINING"
    ]
  },
  {
    label: "Moda",
    value: "Moda",
    legacyValues: [
      "FASHION",
      "Textil"
    ]
  },
  {
    label: "Pesca y caza",
    value: "Pesca y caza",
    legacyValues: [
      "FISHING_AND_HUNTING"
    ]
  },
  {
    label: "Química",
    value: "Química",
    legacyValues: [
      "CHEMISTRY"
    ]
  },
  {
    label: "Reciclaje",
    value: "Reciclaje",
    legacyValues: [
      "RECYCLING"
    ]
  },
  {
    label: "Residuos",
    value: "Residuos",
    legacyValues: [
      "WASTE"
    ]
  },
  {
    label: "Software",
    value: "Software",
    legacyValues: [
      "SOFTWARE"
    ]
  },
  {
    label: "Telecomunicaciones",
    value: "Telecomunicaciones",
    legacyValues: [
      "TELECOM",
      "Internet",
      "Redes"
    ]
  },
  {
    label: "Transporte",
    value: "Transporte",
    legacyValues: [
      "TRANSPORT",
      "TRANSPORTATION"
    ]
  },
  {
    label: "Turismo",
    value: "Turismo",
    legacyValues: [
      "TOURISM"
    ]
  },
  {
    label: "Otro",
    value: "Otro",
    legacyValues: [
      "OTHER",
      "Recursos",
      "Otra",
      "OTHERS"
    ]
  }
];
