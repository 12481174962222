import React from "react";
import { Button, Tag, Typography } from "antd";
import { CardListItem } from "../list-item/card-list-item";

export function PerkItem({ perk, onViewPerk }) {
  const today = new Date();
  const perkIsExpired = perk.expiration ?
    perk.expiration <= today
    : null;

  const options = perkIsExpired ?
    <Typography.Text
      type="danger"
      style={{ fontWeight: 400, fontSize: 14 }}>
      Vencido
    </Typography.Text>
    : null;

  return (
    <CardListItem
      coverUrl={perk?.coverPicUrl}
      title={perk?.name}
      extra={<Tag>{perk?.category}</Tag>}
      customOptionsComponent={options}
      footerItems={[<Button
        key="btnViewPerk"
        type="link"
        onClick={() => onViewPerk && onViewPerk(perk)}>
        Ver detalle
      </Button>]} />
  );
}
