import React, { useRef, useState } from "react";
import {
  ResumeByPersonFilterControls
} from "./filters";
import { Col, message, Row } from "antd";
import {
  EntrepreneurSpiritService
} from "../../../../services/entrepreneur-spirit";
import { debounce } from "lodash";
import { ResumeByPersonNumeralia } from "./numeralia";
import { ExperienceDetailModal } from "./participation-timeline/modal";
import { GenericModalProvider } from "../../../../contexts/shared/modals";
import { ResumeByPersonEngagementLevelChart } from "./engagement-level-chart";
import { ResumeByPersonShareComparisonChart } from "./share-comparison-chart";
import { ResumeByPersonParticipationTimeline } from "./participation-timeline";

export const ResumeByPerson = ({ windowSize }) => {
  const [attendances, setAttendances] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState(undefined);
  const [
    involvementNationalData,
    setInvolvementNationalData
  ] = useState(null);

  const loadAttendances = debounce(async ({
    endDate,
    startDate,
    person
  }) => {
    if (!person) {
      setAttendances([]);
      setSelectedPerson(undefined);
      return;
    }
    const dismissLoader = message.loading("Cargando experiencias...");
    try {
      const { personId, fullName } = person;
      const attendances = await EntrepreneurSpiritService
        .getPersonExperienceAttendances({ personId, startDate, endDate });
      const newInvolvementNationalData = await EntrepreneurSpiritService
        .getDashboardInvolvementLevels({ startDate, endDate });
      setAttendances(attendances);
      setInvolvementNationalData(newInvolvementNationalData);
      setSelectedPerson(fullName);
      message.success("Experiencias cargadas");
    } catch (error) {
      console.error(error);
      message.error("Error al cargar experiencias");
    } finally {
      dismissLoader();
    }
  }, 400);

  const modalRef = useRef(null);

  const onAttendanceClick = ({ selectedExperience }) => {
    modalRef.current.openModal({ selectedExperience });
  };

  return(
    <>
      <GenericModalProvider
        ref={modalRef}
        modal={ExperienceDetailModal} />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <ResumeByPersonFilterControls
            windowSize={windowSize}
            onFilterAttendances={loadAttendances} />
        </Col>
        <ResumeByPersonNumeralia
          windowSize={windowSize}
          attendances={attendances}
          selectedPerson={selectedPerson} />
        <Col xs={24} xl={18}>
          <ResumeByPersonEngagementLevelChart attendances={attendances} />
        </Col>
        <Col xs={24} xl={6}>
          <ResumeByPersonParticipationTimeline
            windowSize={windowSize}
            attendances={attendances}
            onClick={onAttendanceClick} />
        </Col>
        <Col xs={24} xl={12}>
          <ResumeByPersonShareComparisonChart
            attendances={attendances}
            involvementNationalData={involvementNationalData} />
        </Col>
      </Row>
    </>
  );
};