import React from "react";
import { Descriptions } from "antd";

export function WebsiteAndContact({ organization }) {
  return (
    <Descriptions
      title=" Sitio web y contacto"
      layout="vertical">
      <Descriptions.Item label="Email">
        {organization?.email}
      </Descriptions.Item>
      <Descriptions.Item label="Sitio web">
        <a
          href={`https://${organization?.website}`}
          target="_blank">
          {organization?.website}
        </a>
      </Descriptions.Item>
    </Descriptions>
  );
}