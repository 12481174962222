/**
 * @deprecated This function was used by the old achievements features
 * which has been replaced by the contents feature
 * @param objetivos
 * @param odeAchievements
 * @param batchId
 * @returns {string|number}
 */
export const calculateOdeProgressPerBatch = (
  objetivos = [],
  odeAchievements = [], batchId) => {
  let amountOfAchievements = 0;
  let completedAchievements = 0;

  const achievementsFromBatch = odeAchievements.filter(
    odeAchievement =>
      odeAchievement?.achievement?.objective?.batch?.id === batchId
  );

  for (const i in achievementsFromBatch) {
    if (achievementsFromBatch[i].isComplete === true) {
      completedAchievements++;
    }
  }

  objetivos.forEach(objetivo => {
    objetivo.achievements.forEach(() => {
      amountOfAchievements++;
    });
  });

  const percentage =
    amountOfAchievements !== 0
      ? (completedAchievements / amountOfAchievements) * 100
      : 0;

  if (percentage > 100) return 100;

  return percentage.toFixed(0);
};

/**
 * @param{{ status: string }[]} odeContents
 * @param{{ id: string, contentGroups: any[] }} masterContent
 * @param{{
 * id: string,
 * contentGroups: any[],
 * ode: { id: string }
 * }} customOdeContent
 */
export const getOdeContentPercentage = ({
   odeContents = [],
   masterContent = {},
   customOdeContent = {}
 }) => {

  let totalContents = 0;
  let completedContents = 0;

  masterContent?.contentGroups?.forEach(contentGroup =>
    contentGroup.contents.forEach(() => {
      totalContents ++;
    })
  );

  customOdeContent?.contentGroups?.forEach(contentGroup =>
    contentGroup.contents.forEach(() => {
      totalContents ++;
    })
  );

  odeContents?.forEach(odeContent => {
    if (odeContent.status === "VERIFIED") {
      completedContents ++;
    }
  });

  return parseInt((completedContents / totalContents) * 100);
};
