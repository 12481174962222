import gql from "graphql-tag";

export const getPublishedBatchContentGroupsForODEQuery = gql`
  query GetPublishedBatchContentGroupsQueryForODE(
    $odeId: ID,
    $batchId: ID
  ) {
    publishedContents(
      where: {
        ode: { id: $odeId }
        masterContent: { batch: { id: $batchId } }
      }
    ){
      id
      masterContent {
        id
        contentGroupsSort
        batch {
          id
          name
        }
        contentGroups {
          id
          title
          description
          contentsSort
          creationConfirmed
          contents {
            id
            type
            name
            dueDate
            creationConfirmed
            odeContents(where: { ode: { id: $odeId } }){
              id
              status
              deliveryDate
              deliverables {
                id
                fileName
                url
                type
                uploadDate
              }
              events {
                id
                event
                description
                createdAt
                blame {
                  id
                  fullName
                  profilePicUrl
                }
              }
            }
          }
        }
      }
      customOdeContent {
        id
        contentGroupsSort
        contentGroups {
          id
          title
          description
          contentsSort
          creationConfirmed
          contents {
            id
            type
            name
            dueDate
            creationConfirmed
            odeContents(where: { ode: { id: $odeId } }){
              id
              status
              finishDate
              events {
                id
                event
                description
                createdAt
                blame {
                  id
                  fullName
                }
              }
            }
          }
        }
      }
      excludedContentGroups {
        id
      }
      excludedContent {
        id
      }
    }
  }
`;
