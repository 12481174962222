import React, { useContext } from "react";
import { Form } from "antd";
import { Section, Visibility } from "../../../";
import { ActionButton } from "../../action-button";
import { FiscalForm } from "../form";
import {
  EditProfileContext
} from "../../../../../contexts/shared/edit-profile";

export function FiscalEditSection({ editable = false }) {

  const {
    saveChanges,
    editing,
    setEditing
  } = useContext(EditProfileContext);

  const onSave = () => {
    const values = form.getFieldsValue();
    const data = formatValues(values);

    saveChanges(data, "fiscal");
  };

  const formatValues = (values = {}) => {
    return {
      expertProfile: {
        upsert: {
          create: {
            ...values
          },
          update: {
            ...values
          }
        }
      }
    };
  };

  const [form] = Form.useForm();

  return (
    <Section
      spaced
      title="Fiscal"
      bottom={
        <Visibility visible={editable}>
          <ActionButton
            editing={editing.fiscal}
            setEditing={setEditing}
            formName="fiscal"
            visible
            onSave={onSave} />
        </Visibility>}>
      <FiscalForm
        form={form}
        editable={editing.fiscal && editable} />
    </Section>
  );
}
