import React, { useContext } from "react";
import { Layout, Menu, Image, Button } from "antd";
import AsemEos from "../../../../assets/eos-asem-logo.png";
import { withRouter } from "../../../helpers";
import { Link } from "react-router-dom";
import { ExportOutlined } from "@ant-design/icons";
import { CurrentUserContext } from "../../../contexts/shared";

function OrganizationLayout({
  children,
  userIsLogged = false,
  isAsemAdmin = false
}) {
  const { signout } = useContext(CurrentUserContext) || {};

  const renderMenuAsemAdmin = () => (
    <Menu
      theme="dark"
      mode="horizontal">
      <Menu.Item
        key="signout"
        onClick={signout}
        icon={<ExportOutlined />}>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  const renderMenuAsem = () => (
    <Menu
      // openKeys={openKeys}
      // selectedKeys={selectedKeys}
      theme="dark"
      mode="horizontal">
      {/*
        // Todo: Unhide when pages are completed , no use Visibily component
        <Menu.Item
          key="about">
          Sobre EOS
        </Menu.Item>
        <Menu.Item
        key="organizations"
        onClick={() => navigate("/v2/organizations")}>
        Organizaciones
        </Menu.Item>
        <Menu.Item
        key="startups">
        Startups
        </Menu.Item>
        <Menu.Item
        key="founders">
        Founders
        </Menu.Item>
        <Menu.Item
        key="funds">
        Funds
        </Menu.Item>
        <Menu.Item
        key="programs">
        Programas
        </Menu.Item>
      */}
      <Link to="/v2/login">
        <Button
          onClick={null}
          type="secondary">
          {userIsLogged
            ? "Ir a EOS"
            : "Iniciar sesión en EOS"}
        </Button>
      </Link>
    </Menu>
  );

  return (
    <Layout className="asem Layout">
      <Layout.Header>
        <Image
          preview={false}
          width={250}
          src={AsemEos} />
        {isAsemAdmin
          ? renderMenuAsemAdmin()
          : renderMenuAsem()
        }
      </Layout.Header>
      <Layout.Content>
        { children }
      </Layout.Content>
    </Layout>
  );
}

export default withRouter(OrganizationLayout);
