import gql from "graphql-tag";

export const refundScheduledUnits = gql`
  mutation RefundAssignedUnits( $id: ID!, $units: Int!) {
    refundAssignedUnits(
      where: { id: $id }
      data: { units: $units }
    ) {
      id
      assignedUnits
      availableUnits
    }
  }
`;

