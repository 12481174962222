import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "antd";
import bannerUrl from "../../../../../assets/eos-asm-banner.png";
import { SvgImage } from "../../../../components/shared";
import SVG from "../../../../../assets/rocket.svg";

export const RegisterOrganizationSuccessIndex = () => {

  const history = useHistory();
  const { Text, Title } = Typography;

  const onSeeOrganizations = () => {
    history.push("../organizations");
  }

  return (
    <div style={{ height: "100%" }}>
        <div
          style={{ 
            height: "400px", 
            backgroundImage: `url(${bannerUrl})`, 
            backgroundPosition: "center" }}></div>
        <div
          style={{ 
            display: "flex", 
            flexDirection: "column", 
            justifyContent: "center", 
            alignItems: "center", 
            marginTop: "20px" }}>
          <Title style={{ color: "#5F39DE", marginTop: "10px" }} level={2}>Tu solicitud ha sido enviada</Title>
          <Text type="secondary" style={{ width: "690px", textAlign: "center", marginBottom: "40px", fontSize: "14px" }}>Hemos enviado la solicitud para registrar a tu organización en nuestro sistema, en cuanto ésta quede aprobada por el comité organizador recibirás una notificación por correo electrónico.</Text>
          <SvgImage width="400px" svg={SVG} />
          <div style={{ display: "flex", marginTop: "15px", marginBottom: "0px"}}>
            <Button onClick={onSeeOrganizations} type="primary">Ver organizaciones</Button>
          </div>
        </div>
    </div>
  )
}