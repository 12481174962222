import React, { useContext, useState } from "react";
import { Form } from "antd";
import { EditButton, Section, Visibility } from "../../../";
import { EducationForm } from "../form";
import {
  CurrentUserContext,
  EditProfileContext
} from "../../../../../contexts/shared";
import { formatValues } from "./format-values";
import { ActionButtonForm } from "../../action-button-form";

export function EducationEditSection({ editable = false }) {
  const { refetchCurrentUser } = useContext(CurrentUserContext);
  const [stateForm, setStateForm] = useState(null);
  const [form] = Form.useForm();
  const {
    saveChanges,
    editing,
    setEditing,
    data
  } = useContext(EditProfileContext);

  const onSave = async () => {
    await stateForm?.validateFields();
    const { education } = form.getFieldsValue();
    const oldEducation =
      data?.user?.expertProfile?.professionalExperience?.education || [];
    const formatedData = formatValues(education, oldEducation);
    await saveChanges(formatedData, "education");
    await refetchCurrentUser();
  };

  return (
    <Section
      spaced
      title="Educación"
      extra={
        <Visibility visible={editable && !editing?.education}>
          <EditButton
            onClick={() => setEditing(true, "education")}>
            Editar
          </EditButton>
        </Visibility>
      }
      bottom={
        <Visibility visible={editable && editing?.education}>
          <ActionButtonForm
            setEditing={setEditing}
            formName="education"
            onSave={onSave} />
        </Visibility>}>
      <EducationForm
        form={form}
        setStateForm={setStateForm} />
    </Section>
  );
}
