import React from "react";
import {Typography} from "antd";

export function RightLabel({
  status,
  availableUnits,
  isScheduledService
}) {
  const isOnGoing = [
    "ACCEPTED",
    "SCHEDULED",
    "DELIVERY_REJECTED",
    "DELIVERED",
    "ON_GOING"
  ].includes(status);

  if (!isScheduledService && availableUnits < 1) {
    return (
      <Typography.Text
        type="danger"
        style={{ fontWeight: 400, fontSize: 14 }}>
        Consumido
      </Typography.Text>
    );
  }

  if (!isScheduledService && status === "EXPIRED") {
    return (
      <Typography.Text
        type="danger"
        style={{ fontWeight: 400, fontSize: 14 }}>
        Expirado
      </Typography.Text>
    );
  }

  if (!isScheduledService) return null;

  if (isOnGoing) {
    return (
      <Typography.Text
        type="success"
        style={{ fontWeight: 400, fontSize: 14 }}>
        En curso
      </Typography.Text>
    );
  }

  return (
    <Typography.Text
      type="danger"
      style={{ fontWeight: 400, fontSize: 14 }}>
      {availableUnits < 1
        ? "Consumido"
        : "Inconsistencia"}
    </Typography.Text>
  );
}
