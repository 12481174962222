import React, { useState } from "react";
import { BatchEventDetailsModalContext } from "./context";
import { BatchEventDetailsModal } from "./modal";

export function BatchEventDetailsModalProvider({ children, refetch }) {
  const [state, setState] = useState({
    isModalOpen: false,
    event: null
  });

  const openModal = event => {
    setState({ isModalOpen: true, event });
  };

  const closeModal = () => {
    setState({ isModalOpen: false, event: null });
  };

  return (
    <BatchEventDetailsModalContext.Provider value={{ openModal, closeModal }}>
      {state.isModalOpen && (
        <BatchEventDetailsModal
          event={state.event}
          visible={state.isModalOpen}
          onCancel={closeModal}
          refetch={refetch} />
      )}
      {children}
    </BatchEventDetailsModalContext.Provider>
  );
}
