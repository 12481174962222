import React, { useContext, useEffect } from "react";
import { Form } from "antd";
import {
  TwitterOutlined,
  FacebookFilled,
  InstagramOutlined,
  LinkedinFilled,
  LinkOutlined
} from "@ant-design/icons";
import { LargeIcon } from "./large-icon";
import { FormContainer } from "../../../";
import {
  EditProfileContext
} from "../../../../../contexts/shared";
import { genericFieldValidationMsgs, urlPatterns } from "../../../../../utils";
import { Input } from "./input";

export function AccountsForm({ editable = false, form }) {
  const patternMsg = "El formato de la URL no es válido";
  const { data } = useContext(EditProfileContext);

  useEffect(() => {
    const expertProfile = data?.user?.expertProfile || {};
    form.setFieldsValue({ ...expertProfile });
  }, [data]);

  return (
    <FormContainer>
      <Form
        form={form}
        validateMessages={genericFieldValidationMsgs}>
        <Form.Item
          name="twitter"
          rules={[{
              pattern: urlPatterns.twitter,
              message: patternMsg
            }]}>
          <Input
            editable={editable}
            socialNetwork="twitter"
            addonBefore={<LargeIcon icon={TwitterOutlined} />}
            placeholder="Twitter" />
        </Form.Item>
        <Form.Item
          name="facebook"
          rules={[{
              pattern: urlPatterns.facebook,
              message: patternMsg
            }]}>
          <Input
            editable={editable}
            socialNetwork="facebook"
            addonBefore={<LargeIcon icon={FacebookFilled} />}
            placeholder="Facebook" />
        </Form.Item>
        <Form.Item
          name="instagram"
          rules={[{
              pattern: urlPatterns.facebook,
              message: patternMsg
            }]}>
          <Input
            editable={editable}
            socialNetwork="instagram"
            addonBefore={<LargeIcon icon={InstagramOutlined} />}
            placeholder="Instagram" />
        </Form.Item>
        <Form.Item
          editable={editable}
          name="linkedin"
          rules={[{
              pattern: urlPatterns.linkedin,
              message: patternMsg
            }]}>
          <Input
            editable={editable}
            socialNetwork="linkedin"
            addonBefore={<LargeIcon icon={LinkedinFilled} />}
            placeholder="Linkedin" />
        </Form.Item>
        <Form.Item
          name="otherUrl"
          rules={[{
              pattern: urlPatterns.url,
              message: patternMsg
            }]}>
          <Input
            editable={editable}
            socialNetwork="other"
            addonBefore={<LargeIcon icon={LinkOutlined} />}
            placeholder="Otro" />
        </Form.Item>
      </Form>
    </FormContainer>
  );
}
