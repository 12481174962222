import gql from "graphql-tag";

export const getPersonExperienceAttendancesQuery = gql`
  query GetPersonExperienceAttendances(
    $personId: ID!
    $startDate: DateTime
    $endDate: DateTime
  ) {
    personExperienceAttendances(
      criteria: {
        personId: $personId,
        startDate: $startDate,
        endDate: $endDate 
      }
    ) {
      personExperienceAttendanceId
      experience {
        name
        startDate
        experienceId
        involvementLevel
      }
    }
  }
`;
