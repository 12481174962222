import React from "react";
import { Button, Modal, Popover } from "antd";
import {
  MinusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  CopyOutlined,
  InfoCircleFilled,
  ExclamationCircleOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import { MultiOptionsButton } from "./multi-options-button";

const icons = {
  onRemove: MinusCircleOutlined,
  onDelete: DeleteOutlined,
  onEdit: EditOutlined,
  onDuplicate: CopyOutlined,
  onAdd: PlusCircleOutlined
};

/**
 * @param {object} props
 * @param {function} props.onRemove
 * @param {function} props.onDelete
 * @param {function} props.onEdit
 * @param {function} props.onDuplicate
 * @param {function} props.onAdd
 * @param {{[string]: {label: string, action: function}}} props.extraActions
 * Will be merged with the default actions. You can overwrite a default action
 * by using the same name. Example: { onDelete: {} } will overwrite the default
 * onDelete action.
 * @param {{[string]: {label: string, action: function}}} props.customActions
 * Will replace all the defaultActions and extraActions.
 * @returns {JSX.Element}
 */
export function NewActionButton({
  onRemove,
  onDelete,
  onEdit,
  onDuplicate,
  onAdd,
  extraActions,
  customActions
}) {
  const withConfirm = ({ label, action }) => ({
    label,
    action: action && (() => {
      Modal.confirm({
        title: "Advertencia",
        cancelText: "Cancelar",
        content: `¿Deseas ${label.toLowerCase()} este elemento?`,
        onOk: action,
        okText: label,
        icon: <ExclamationCircleOutlined />
      });
    })
  });

  let actions = {
    onRemove: withConfirm({ label: "Remover", action: onRemove }),
    onDelete: withConfirm({ label: "Eliminar", action: onDelete }),
    onEdit: { label: "Editar", action: onEdit },
    onDuplicate: { label: "Duplicar", action: onDuplicate },
    onAdd: { label: "Agregar", action: onAdd }
  };

  if (extraActions) {
    Object.assign(actions, extraActions);
  }

  if (customActions) {
    actions = customActions;
  }

  // remove empty actions and count filled actions
  const optionsWithAction = Object.keys(actions || {})
    .reduce((acc = {}, key) => {
      if (actions[key].action) acc[key] = actions[key];

      return acc;
    }, {});

  const actionsCount = Object.keys(optionsWithAction || {})
    .filter(actionKey => {
      const hasAction = !!optionsWithAction[actionKey].action;
      return hasAction;
    })
    .length;

  if (actionsCount === 1 && !customActions) {
    const [key] = Object.keys(optionsWithAction || {});
    const action = optionsWithAction[key];
    const Icon = icons[key];

    return (
      <Popover
        trigger="hover"
        content={<>
          <InfoCircleFilled
            style={{ color: "#FAAD14", marginRight: "1rem" }} />
          <span>{action.label}</span>
        </>}>
        <Button
          type="text"
          shape="circle"
          icon={<Icon />}
          onClick={() => action.action()} />
      </Popover>
    );
  }

  return <MultiOptionsButton options={optionsWithAction} />;
}
