import React from "react";
import {
  ListItem, TagList
} from "../../../../../../../../../shared";
import { RightLabel } from "./right-label";
import { LeaderIcon } from "./leader-icon";
import { FooterButton } from "./footer-button";
import {
  getReadableValue, expertServiceTypes, expertServiceCategories
} from "../../../../../../../../../../helpers/select-options";
import { truncateText } from "../../../../../../../../../../helpers";

export function AssignedMentoringListItem({
  leaderId,
  onGoingServices = {},
  assignedService = null,
  refetchScheduledMentorings,
  company
}) {
  // the expertServiceData is a snapshot of the service at assignment moment
  const expertService = assignedService?.expertServiceData || {};
  const { expert = {} } = assignedService?.expertService || {};
  const scheduledService = assignedService?.scheduledExpertService;

  const type = expertService.type
    && truncateText(
      getReadableValue(expertServiceTypes, expertService.type),
      15
    );
  const category = expertService.category
    && truncateText(
      getReadableValue(expertServiceCategories, expertService.category),
      15
    );

  return (
    <ListItem.CardListItem
      customOptionsComponent={<RightLabel
        assignedService={assignedService} />}
      coverUrl={expertService.coverPicUrl}
      title={expertService.name}
      titleMaxSize={10}
      footerItems={[<FooterButton
        key="footer-btn"
        company={company}
        assignedService={assignedService}
        serviceIsOnGoing={onGoingServices[assignedService?.expertService?.id]}
        refetchScheduledMentorings={refetchScheduledMentorings}
        scheduledService={scheduledService} />]}
      extra={
        <TagList
          colors={["blue", "magenta"]}
          tags={[type, category].filter(tag => !!tag)} />
      }>
      <span>
        <LeaderIcon leaderId={leaderId} expert={expert} /> {expert.fullName}
      </span>
    </ListItem.CardListItem>
  );
}
