import gql from "graphql-tag";

export const updateObjectiveAchievement = gql`
  mutation UpdateObjectiveAchievement(
    $data: AchievementUpdateInput!,
    $where: AchievementWhereUniqueInput!
  ) {
    updateAchievement(
      data: $data
      where: $where
    ) {
      id
    }
  }
`;
