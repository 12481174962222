import { client } from "../../../graphql";
import {
  getPublishedBatchContentGroupsForODEQuery
} from "./graphql/get-published-batch-content-groups-for-ode-query";
import {
  getCustomOdeContents
} from "./graphql/get-custom-ode-constant";

/**
 * @param batchId
 * @param companyId
 * @return {Promise<{customOdeContent: any, masterContent: any}>}
 */
export const getPublishedContent = async ({
  batchId,
  companyId
}) => {
  const publishedContentResult = await client.query({
    fetchPolicy: "network-only",
    query: getPublishedBatchContentGroupsForODEQuery,
    variables: {
      batchId,
      odeId: companyId
    }
  });

  const publishedContent = publishedContentResult.data
    ?.publishedContents?.[0] || {};

  const customODEContentResult = await client.query({
   query: getCustomOdeContents,
    fetchPolicy: "network-only",
    variables: { where: { ode: { id: companyId }, batch: { id: batchId } } }
  });

  // @todo: prepare this data in the backend
  publishedContent.customOdeContent = customODEContentResult
    .data?.customOdeContents?.[0];

  // this is a workaround for a bug in the graphql-server or in the schema
  // design. We should keep a single published content object but there are
  // multiple published content records for a batch-ode combination.
  return publishedContent;
};

