import gql from "graphql-tag";

export const approveOrganizationRequest = gql`
  mutation ApproveOrganizationRequest(
    $organizationId: ID!
    $reviewerId: ID!
  ){
    approveOrganizationRequest(
      organizationId: $organizationId
      reviewerId: $reviewerId
    )
  }
`;
