import React from "react";
import {Button} from "antd";
import {
  ListItem, TagList
} from "../../../../../../../../../shared";
import {
  expertServiceCategories,
  expertServiceTypes,
  getReadableValue
} from "../../../../../../../../../../helpers/select-options";
import { truncateText } from "../../../../../../../../../../helpers";

export function AvailableServiceListItem({ expertService = null }) {
  expertService = expertService || {};
  const { expert = {} } = expertService;

  const type = expertService.type
    && truncateText(
      getReadableValue(expertServiceTypes, expertService.type),
      15
    );
  const category = expertService.category
    && truncateText(
      getReadableValue(expertServiceCategories, expertService.category),
      15
    );

  return (
    <ListItem.CardListItem
      coverUrl={expertService.coverPicUrl}
      title={expertService.name}
      footerItems={[
        <Button
          ghost
          type={"primary"}
          key={"btn-request"}>
          Solicitar
        </Button>
      ]}
      extra={
        <TagList
          colors={["blue", "magenta"]}
          tags={[type, category].filter(tag => !!tag)} />
      }>
      {expert.fullName}
    </ListItem.CardListItem>
  );
}
