import gql from "graphql-tag";

export const getByIdQuery = gql`
  query GetAssignedServiceById($id: ID!) {
    assignedExpertService(
      where: { id: $id }
    ) {
      id
      status
      limitDate
      limitPerDay
      assignedUnits
      availableUnits
      assignmentDate
      expertServiceData
    }
  }
`;
