import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Descriptions } from "antd";
import { FormContainer } from "../../../";
import {
  EditProfileContext
} from "../../../../../contexts/shared/edit-profile";
import {
  fieldValidationMessages
} from "../../../../../utils/fieldValidationMessages";

export function FiscalForm({ editable = false, form }) {
  const { data } = useContext(EditProfileContext);

  const [state, setState] = useState({
    rfc: "N/A",
    businessName: "N/A"
  });

  useEffect(() => {
    const expertProfile = data?.user?.expertProfile || {};
    setState({
      rfc: expertProfile?.rfc || "N/A",
      businessName: expertProfile?.businessName || "N/A"
    });

    form.setFieldsValue({ ...expertProfile });
  }, [data]);

  if (editable) {
    return (
      <FormContainer>
        <Form
          validateMessages={fieldValidationMessages}
          layout="vertical"
          form={form}>
          <Form.Item
            name="businessName"
            label="Razón social"
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="rfc"
            label="RFC"
            rules={[{ required: true }]}>
            <Input />
          </Form.Item>
        </Form>
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <Descriptions column={1}>
        <Descriptions.Item label="Razón social">
          {state.businessName}
        </Descriptions.Item>
        <Descriptions.Item label="RFC">
          {state.rfc}
        </Descriptions.Item>
      </Descriptions>
    </FormContainer>
  );
}
