import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Modal, Typography, Form, Space, message } from "antd";
import { admin } from "../../../graphql/admin";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql";
import { UsersTeamMemberSelect } from "../../shared";
import { roles } from "../../../utils/maps";
import { CurrentUserContext } from "../../../contexts/shared";

/**
 * @params { object } props
 * @param { boolean } visible
 * @param { function } closeModal
 * @param { string } systemRole
 * @returns {JSX.Element}
 * @constructor
 */
export const AddTeamMemberModal = ({
  visible = false,
  closeModal,
  systemRole = "ADVISER"
}) => {
  const { id = "" } = useParams();
  const [form] = Form.useForm();
  const { currentUser } = useContext(CurrentUserContext);

  const { data = {} } = useQuery(
    shared.queries.getOdeTeamUsers,
    {
      client,
      variables: { id: id }
    });

  const [connectTeamMemberToOde] = useMutation(
    admin.mutations.connectTeamMemberToOde, {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: shared.queries.getOdeTeamUsers,
        variables: { id: id }
      }]
    }
  );

  const handleOnOk = async () => {
    const dismissLoader = message.loading("Guardando...", 0);
    await form.validateFields();
    const values = form.getFieldsValue();

    const data = {
      requestedBy: currentUser.fullName,
      teamIdList: values.team.map(id => ({ id }))
    };

    try {
      await connectTeamMemberToOde({
        variables: {
          data,
          odeId: id
        }
      });

    form.resetFields();
    } catch (error) {
      message.error("Ocurrió un error");
    } finally {
      dismissLoader();
      closeModal();
    }
  };

  const onCancel = () => {
    form.resetFields();
    closeModal && closeModal();
  };

  return (
    <Modal
      okText="Guardar"
      onCancel={onCancel}
      onOk={handleOnOk}
      title={`Añadir ${roles[systemRole].labelPlural} a la compañía`}
      visible={visible}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Typography.Text>
          Selecciona a los {roles[systemRole].labelPlural} que quieras añadir a la compañía
        </Typography.Text>
        <Form form={form} layout="vertical">
          <Form.Item name="team" label={roles[systemRole].labelPluralCaps}>
            <UsersTeamMemberSelect
              systemRole={systemRole}
              excluded={data?.oDE?.team}
              batchesIds={data?.oDE?.batches}
              mode="multiple"
              placeholder={`Buscar ${roles[systemRole].labelPlural}`} />
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};
