import gql from "graphql-tag";

export const getAssignedExpertServicesByEntrepreneurId = gql`
  query GetAssignedExpertServicesByEntrepreneurId(
    $id: ID!,
    $currentDate: DateTime,
    $method: String,
    $category: String,
    $searchString: String,
    $first: Int,
    $skip: Int
  ) {
    assignedExpertServices(
      where: {
        status_not: "CANCELLED"
        limitDate_gte: $currentDate
        availableUnits_gt: 0
        ode: {
          team_some: {
            id: $id
          }
        }
        expertService: {
          AND: [
            {
              OR: [ { disabled: false}, { disabled: null} ]   
            }
          ],
          method: $method,
          category: $category,
          OR: [
            { iname_contains: $searchString },
            { idescription_contains: $searchString },
            {
              expert: { ifullName_contains: $searchString }
            }
          ]
        }
      }
      first: $first,
      skip: $skip
    ) {
      id
      limitPerDay
      assignmentNumber
      assignationIdentifier
      assignmentDate
      limitDate
      status
      finished
      ode {
        id
        name
      }
      availableUnits
      assignedUnits
      expertServicePrice
      expertServiceData
      expertService {
        id
        expert {
          id
          fullName
          email
          phone
        }
        name
        iname
        description
        type
        duration
        price
        method
        category
        coverPicUrl
        examples {
          id
          url
        }
        links
      }
    }
    meta: assignedExpertServicesConnection(
      where: {
        status_not: "CANCELLED"
        limitDate_gte: $currentDate
        availableUnits_gt: 0
        ode: {
          team_some: {
            id: $id
          }
        }
        expertService: {
          AND: [
            {
              OR: [ { disabled: false}, { disabled: null} ]   
            }
          ],
          method: $method,
          category: $category,
          OR: [
            { iname_contains: $searchString },
            { idescription_contains: $searchString },
            {
              expert: { ifullName_contains: $searchString }
            }
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
