import React from "react";
import { Section } from "../section";
import { Button, Form, Row, Typography } from "antd";
import { UsersSelect } from "../users-select";
import MemberList from "../member-list";

export const TeamFormContent = ({ batchContextValue }) => {
  const {
    data,
    loading,
    error,
    handleChange,
    handleSubmit,
    onDelete,
    multipleTeams = false
  } = batchContextValue;
  const leader = data?.batch?.leader;
  const teams = data?.batch?.users.map(user => user.id);

  const getLabel = text =>
    <Typography.Text
      style={{ fontSize: "14px"}}
      strong>
      {text}
    </Typography.Text>;

  return (
    <>
      <Section style={{ marginBottom: "11px" }}>
        <Row justify="space-between">
          <Form.Item
            name="leader"
            label={getLabel("Líder del batch")}
            rules={[{
              required: true,
              message: "Por favor seleccionar un líder"
            }]}>
            <UsersSelect
              disabled={leader}
              onChange={value => handleChange(value, "leader")}
              mode="tag"
              systemRoles={["COORDINATOR", "SUPER_ADMIN"]}
              placeholder="Selecciona al líder del batch" />
          </Form.Item>
          <Button
            disabled={leader}
            onClick={() => handleSubmit("leader")}>
            Agregar
          </Button>
        </Row>
        <MemberList
          type="leader"
          data={data}
          error={error}
          isLoading={loading}
          onDelete={onDelete} />
      </Section>
      <Section style={{ marginBottom: "11px" }}>
        <Row justify="space-between">
          <Form.Item
            name="team"
            label={getLabel("Equipo de apoyo")}
            style={{ width: "100%" }}
            rules={[{
              required: true,
              message: "Por favor seleccionar al equipo"
            }]}>
            <UsersSelect
              filterUsers={teams}
              onChange={value => handleChange(value, "team")}
              mode={multipleTeams ? "multiple" : "tag" }
              systemRoles={["COORDINATOR", "SUPER_ADMIN"]}
              placeholder="Selecciona al equipo desde la lista" />
          </Form.Item>
          <Button onClick={() => handleSubmit("team")}>Agregar</Button>
        </Row>
        <MemberList
          type="teams"
          data={data}
          error={error}
          isLoading={loading}
          onDelete={onDelete} />
      </Section>
    </>
  );
};
