import React from "react";
import {
  HeroPublishBatch,
  PublishBatchLearn,
  PublishBatchTeam,
  Section
} from "../../../../shared";
import { getBatchTeam, withLoader } from "../../../../../helpers";

const LandingBatch = ({ data, content }) => {
  const { batch } = data;
  const { masterContents } = content;
  const teams = getBatchTeam(batch)?.filter(el => el.systemRole !== "ADVISER");
  const contentGroups = masterContents[0]?.contentGroups || [];

  return (
    <Section className="NewBatch__Publish">
      <HeroPublishBatch
        className="NewBatch__Publish-Hero"
        batch={batch} />
      <PublishBatchLearn
        className="NewBatch__Publish-Learn"
        contentGroups={contentGroups} />
      <PublishBatchTeam
        className="NewBatch__Publish-Team"
        teams={teams}
        leaderId={batch?.leader} />
    </Section>
  );
};

export default withLoader(LandingBatch);
