import React, { useContext } from "react";
import { List } from "antd";
import { cardGridSizes } from "../../../../../../../../../../helpers";
import {
  AvailableServiceListItem
} from "./available-service-list-item";
import {
  ServicesLayerContext
} from "../../../../../../../../../../contexts/shared";
import { useService } from "../../../../../../../../../../hooks/shared";

export function AvailableServices({ batch = null }) {
  const { BatchesService } = useContext(ServicesLayerContext);
  const {
    data: availableServices,
    error, // @todo show an error message
    loading
  } = useService(
    BatchesService.getServices,
    { batchId: batch?.id, types: ["PRODUCT"] },
    { shouldFetch: !!batch }
  );

  return (
    <List
      loading={loading}
      grid={cardGridSizes}
      itemLayout="horizontal"
      dataSource={availableServices || []}
      renderItem={expertService => (
        <List.Item>
          <AvailableServiceListItem
            expertService={expertService}  />
        </List.Item>
      )} />
  );
}