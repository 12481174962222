import React from "react";
import { List, Space } from "antd";
import { MinusCircleOutlined, EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";

const { Item } = List;

const Title = styled.div`
  font-size: 18px;
`;

export const ResearchItem = ({ item, onEditItem, onRemoveItem, editable }) => {
  const date = moment(item.publishedDate).format("DD [de] MMMM [de] YYYY");
  const types = {
    RESEARCH: "Investigación",
    PUBLISHING: "Publicación"
  };

  const renderExtra = () => (
    <Space>
      <EditOutlined onClick={() => onEditItem(item)} />
      <MinusCircleOutlined onClick={() => onRemoveItem(item)} />
    </Space>
  );

  return (
    <Item
      extra={editable && renderExtra()}>
      <div>
        <Title>{types[item.type]} · {item.title}</Title>
        <div>{item.institutionName}</div>
        <div>{date}</div>
      </div>
    </Item>
  );
};
