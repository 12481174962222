import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql";
import { useDecorator } from "../../../../../helpers/use-decorator";
import "../../../../../../App.css";
import {
  OnePager
} from "../../../../../components/shared/one-pager";

export const OnePagerIndex = () => {

  const { id = "" } = useParams();

  const { data } = useQuery(
    shared.queries.getOdeById,
    {
      client,
      variables: { id: id },
      fetchPolicy: "network-only"
    }
  );

  const ode = useDecorator("ode", data?.oDE);

  return (
    <OnePager ode={ode} />);
};