import React, { useContext, useEffect, useState } from "react";
import { Form, Select } from "antd";
import { EditOdeContext } from "../../../../../contexts/shared/edit-ode";
import { InputTooltip, SelectWithCustomOption } from "../../../../../components/shared";
import { FormContainer } from "../../..";
import { fieldValidationMessages } from "../../../../../utils";
import {
  odeProfitMotives,
  commercialBussines,
  odeTecnologyLevels,
  odeEconomicActivities,
  INDUSTRIES,
  ODE_SECTORS
} from "../../../../../helpers";
import { Details } from "./details";
import { useDecorator } from "../../../../../helpers/use-decorator";

/**
 * @deprecated This form is not used after the Company Profile feature
 * @param editable
 * @param form
 * @returns {JSX.Element}
 * @constructor
 */
export function GeneralInfoForm({ editable = false, form }) {
  const [economicActivities, setEconomicActivities] = useState([]);

  const { data } = useContext(EditOdeContext);
  const ode = useDecorator("ode",  data?.oDE);

  const inputRules = editable && [{ required: true }];

  const onSectorChange = value => {
    filterActivitiesBySector(value);
    form.setFieldsValue({ economicActivity: undefined });
    return value;
  };

  const filterActivitiesBySector = sector => {
    const activitiesBySector = odeEconomicActivities.filter(activity => {
      return activity.sector === sector;
    });

    setEconomicActivities(activitiesBySector);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...ode.object,
      economicIndustry: ode.object.economicIndustry,
      economicSector: ode.object.economicSector
    });

    filterActivitiesBySector(ode.object.economicSector);
  }, []);

  if (!editable) {
    return <Details ode={ode} />;
  }

  return (
    <FormContainer>
      <Form
        validateMessages={fieldValidationMessages}
        layout="vertical"
        form={form}>
        <Form.Item
          rules={inputRules}
          label="Nivel de tecnología"
          name="technologyLevel">
          <Select
            disabled={!editable}
            placeholder="Nivel de tecnología"
            className="wide-input"
            showSearch
            optionFilterProp="label">
            {odeTecnologyLevels.map(level => (
              <Select.Option title={level.label} key={level.value}>
                {level.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="commercialBussines"
          label="Giro">
          <Select
            className="wide-input"
            showSearch
            optionFilterProp="label"
            placeholder="Giro"
            options={commercialBussines}
            disabled={!editable} />
        </Form.Item>
        <Form.Item
          name="economicIndustry"
          label="Industria">
          <Select
            className="wide-input"
            showSearch
            optionFilterProp="label"
            placeholder="Industria"
            options={INDUSTRIES}
            disabled={!editable} />
        </Form.Item>
        <Form.Item
          getValueFromEvent={onSectorChange}
          name="economicSector"
          label="Sector">
          <Select
            className="wide-input"
            showSearch
            optionFilterProp="label"
            placeholder="Sector"
            options={ODE_SECTORS}
            disabled={!editable} />
        </Form.Item>
        <Form.Item
          help={economicActivities.length < 1 &&
            "Selecciona un sector para ver las opciones correspondientes"}
          name="economicActivity"
          label="Actividad">
          <SelectWithCustomOption
            className="wide-input"
            showSearch
            optionFilterProp="label"
            placeholder="Actividad"
            options={Array.from(economicActivities)}
            disabled={!editable} />
        </Form.Item>
        <InputTooltip
          title="Ingresa las palabras clave separadas por una coma ( , )">
          <Form.Item
            name="keywords"
            label="Palabras clave">
            <Select
              disabled={!editable}
              tokenSeparators={[","]}
              notFoundContent={null}
              dropdownStyle={{display: "none"}}
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Palabras clave" />
          </Form.Item>
        </InputTooltip>
        <Form.Item
          label="Finalidad del negocio"
          name="profitMotives">
          <Select
            className="wide-input"
            showSearch
            optionFilterProp="label"
            placeholder="Finalidad del negocio"
            options={odeProfitMotives}
            disabled={!editable} />
        </Form.Item>
      </Form>
    </FormContainer>
  );
}
