/* eslint-disable max-len */
import gql from "graphql-tag";

export const registerCompanyDroppedOutFromBatchEventQuery = gql`
  mutation RegisterCompanyDroppedOutFromBatchEventQuery(
    $odeId: ID!,
    $batchId: ID!,
    $data: Json
  ) {
    updateODE(
      where: { id: $odeId }
      data: {
        events: {
          create: {
            batches: { connect: { id: $batchId } },
            name: COMPANY_DROPPED_OUT_FROM_BATCH,
            description: "The Company has been dropped out from the Batch. Check the data field to get more info.",
            data: $data,
          }
        }
      }
    ) {
      id
      events {
        id
        name
        data
        description
      }
    }
  }
`;
