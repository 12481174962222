import React, { useEffect, useState, useContext, useMemo } from "react";
import { Modal, Form, Select, message, Alert, List, Typography } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { Visibility } from "../../../components/shared";
import { shared } from "../../../graphql/shared";
import { DataConnectionService } from "../../../services";
import { clearString, removeNonAscii, validateEmail } from "../../../helpers";
import { CurrentUserContext } from "..";
import { formatEntrepreneursInvitationData } from "../../../helpers/format-entrepreneurs-invitation-data";
import { tagRender } from "./tag-render";

const { graphqlApolloClient: client } = DataConnectionService;

export function InvitationModal({
  onSave,
  onCancel,
  edit,
  systemRole,
  ode,
  ...props
}) {
  const [state, setState] = useState({
    takenEmails: [],
    validationMade: false,
    loading: false
  });
  const { currentUser } = useContext(CurrentUserContext);

  const [form] = Form.useForm();

  const [createUserByInvitation] = useMutation(
    shared.mutations.createUserByInvitation,
    { client }
  );

  const [inviteEntrepreneurs] = useMutation(
    shared.mutations.inviteEntrepreneurs,
    { client }
  );

  const updateState = (value = {}) => {
    setState(prevState => ({ ...prevState, ...value }));
  };

  useEffect(() => {
    if (edit) {
      form.setFieldsValue(edit);
    }
  }, [edit]);

  const getUsersbyEmail = async (emails = []) => {
    return await client.query({
      query: shared.queries.getUsersByEmail,
      variables: { emails }
    });
  };

  const verifyEmailList = async (emails = []) => {
    const dismissLoader = message.loading("Verificando emails...", 0);

    const result = await getUsersbyEmail(emails);
    const takenEmails = result.data.users?.map(u => u.email);

    updateState({
      takenEmails,
      validationMade: true
    });

    dismissLoader();
    return takenEmails;
  };

  const onOk = async () => {
    updateState({ loading: true });
    try {
      const values = await form.validateFields(["emails"]);
      values.emails = values.emails.map(email => clearString(removeNonAscii(email)));

      // skip validation if the user wants to continue anyway
      const takenEmails = !state.validationMade &&
        await verifyEmailList(values?.emails);

      if (takenEmails?.length > 0)
        return;

      if(systemRole === "ENTREPRENEUR") {
        const data = formatEntrepreneursInvitationData(values.emails, ode, currentUser);
        await inviteEntrepreneurs({
          variables: { data }
        });
      } else {
        await Promise.all(values.emails.map(email => {
          return createUserByInvitation({
            variables: {
              email,
              systemRole,
              odes: {
                connect: edit?.odes?.map(o => ({ id: o.id }))
              },
              batches: {
                connect: edit?.batches?.map(b => ({ id: b.id }))
              }
            }
          });
        }));
      }

      onSave && onSave(values);
      form.resetFields();

      updateState({
        takenEmails: [],
        validationMade: false,
        loading: false
      });

      message.success("Invitaciones enviadas.");
    }
    catch(e) {
      console.error(e);
      message.error("Tienes que ingresar al menos un correo para enviar la\
      invitación");
      console.error(e);
    } finally {
      updateState({ loading: false });
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    setEmailList([]);
    onCancel && onCancel();
  };

  const [emailList, setEmailList] = useState([]);

  const onEmailChanges = emails => {
    setEmailList(() => (emails || []).map(email => {
      return {
        email,
        isValid: validateEmail(email)
      }
    }))
    if (state.validationMade) { // dismiss validation flag
      updateState({ validationMade: false });
    }

    return emails;
  };

  const allowSend = useMemo(() => {
    return !emailList.some(entry => entry.isValid === false)
  }, [emailList]);

  const onSelectChange = async (e = []) => {
    if (e[e.length - 1]) {
      const emailList = e[e.length - 1].split(/(\r\n|\n|\r)/gm);
      const filteredEmailList = emailList.filter(email => email.length > 2);
      let emailFields;
      if (e.length === 1) {
        emailFields = {
          emails: filteredEmailList
        };
      } else {
        const value = form.getFieldsValue();
        value.emails.pop();
        emailFields = {
          emails: value.emails.concat(filteredEmailList)
        };
      }
      form.setFieldsValue({ ...emailFields });
    }
  };

  return (
    <Modal
      okButtonProps={{
        loading: state.loading,
        disabled: !allowSend
    }}
      cancelButtonProps={{ disabled: state.loading }}
      okText={state.validationMade && state.takenEmails?.length ?
        "Enviar de todas formas" : "Enviar invitaciones"}
      cancelText="Cancelar"
      title="Invitación a EOS"
      onOk={onOk}
      onCancel={!state.loading && onCancelModal}
      {...props}>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          getValueFromEvent={onEmailChanges}
          label="Escribe los correos a los que desees enviar una invitación"
          help={
            allowSend
              ? "Da enter o escribe una coma para agregar un nuevo correo"
              : "Correos inválidos, por favor elimínalos."
          }
          validateStatus={allowSend ? "default" : "error"}
          name="emails"
          rules={[{
            required: true,
            message: "Tienes que ingresar al menos un\
            correo para enviar la invitación."
          }]}>
          <Select
            tagRender={tagRender}
            onChange={onSelectChange}
            placeholder="usuario@eossolution.io"
            mode="tags"
            tokenSeparators={[","]}
            notFoundContent={null}
            dropdownStyle={{ display: "none" }} />
        </Form.Item>
      </Form>
      <Visibility visible={state.takenEmails?.length > 0}>
        <Alert
          description="Los siguientes correos ya están registrados.
            ¿Deseas enviarles la invitación de todas formas?"
          type="warning" />
        <List
          dataSource={state.takenEmails}
          renderItem={email => (
            <List.Item>
              <Typography.Text mark>{email}</Typography.Text>
            </List.Item>
          )} />
      </Visibility>
    </Modal>
  );
}
