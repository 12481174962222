export const formatValues = (batches, isPublic) => {
  const formatedBatches = batches.map(batch => ({ id: batch }));
  return {
    expertProfile: {
      upsert: {
        create: {
          private: !isPublic,
          privateBatches: {
            connect: formatedBatches
          }
        },
        update: {
          private: !isPublic,
          privateBatches: {
            connect: formatedBatches
          }
        }
      }
    }
  }
}