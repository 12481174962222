import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router";
import { client } from "../../../../../../../../graphql";
import { shared } from "../../../../../../../graphql/shared";
import {
  ShowLayout
} from "../../../../../../../components/admin/programs/objectives/show/show-layout";
import {
  ObjectiveContentModalProvider
} from "../../../../../../../contexts/shared/objective-content-modal";
import { ContentsList } from "../../../../../../../components/admin/programs/objectives/show/contents";
import {
  ProgramDetailContextProvider
} from "../../../../../../../contexts/admin/programs/program-detail";
import {
  ObjectiveDetailContextProvider
} from "../../../../../../../contexts/admin/programs/objectives/objective-detail";

export function ContentsIndex() {
  const { objectiveId: id } = useParams();
  const { loading, error, data } = useQuery(
    shared.queries.getContentsByObjectiveId,
    {
      fetchPolicy: "network-only",
      variables: { id },
      client
    }
  );

  return (
    <ProgramDetailContextProvider>
      <ObjectiveDetailContextProvider>
        <ObjectiveContentModalProvider>
          <ShowLayout selectedKeys={["contents"]}>
            <ContentsList
              data={data}
              isLoading={loading}
              error={error} />
          </ShowLayout>
        </ObjectiveContentModalProvider>
      </ObjectiveDetailContextProvider>
    </ProgramDetailContextProvider>
  );
}
