import React, { useContext, useState } from "react";
import { useMutation } from "react-apollo-hooks";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";
import { CurrentUserContext } from "../current-user";
import { OdeUpdateCommentsModalContext } from "./context";
import { OdeUpdateCommentsModal } from "./modal";

export function OdeUpdateCommentsModalProvider({ children }) {
  const { currentUser } = useContext(CurrentUserContext);

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    isModalOpen: false,
    odeUpdate: undefined
  });

  const [updateOdeUpdate] = useMutation(shared.mutations.updateOdeUpdate);

  const fetchOdeUpdate = (id = "") => {
    return client.query({
      query: shared.queries.getOdeUpdateWithComments,
      variables: { id },
      fetchPolicy: "network-only"
    });
  };

  const loadOdeUpdate = async id => {
    setLoading(true);

    const { data } = await fetchOdeUpdate(id);

    setState(prevState => ({
      ...prevState,
      odeUpdate: data.odeUpdate
    }));

    setLoading(false);
  };

  const updateLocalComments = (comments = []) => {
    // Update the comments list approaching the mutation payload
    setState(prevState => ({
      ...prevState,
      odeUpdate: {
        ...state.odeUpdate,
        comments: comments
      }
    }));
  };

 /**
  * @param {string} odeUpdateId Needed to load the OdeUpdate comments
  */
  const openModal = odeUpdateId => {
    setState({ isModalOpen: true });
    loadOdeUpdate(odeUpdateId);
  };

  const closeModal = () => {
    setState({ isModalOpen: false, odeUpdate: undefined });
  };

  const sendComment = async comment => {
    const { data } = await updateOdeUpdate({
      variables: {
        id: state.odeUpdate.id,
        data: {
          comments: {
            create: {
              comment,
              author: { connect: { id: currentUser.id } }
            }
          }
        }
      }
    });

    updateLocalComments(data.updateOdeUpdate.comments);
  };

  const editComment = async comment => {
    const { data } = await updateOdeUpdate({
      variables: {
        id: state.odeUpdate.id,
        data: {
          comments: {
            update: {
              where: { id: comment.id },
              data: {
                comment: comment.comment,
                edited: true
              }
            }
          }
        }
      }
    });

    updateLocalComments(data.updateOdeUpdate.comments);
  };

  const providerValue = {
    ...state,
    loading,
    openModal,
    closeModal,
    sendComment,
    editComment
  };

  return (
    <OdeUpdateCommentsModalContext.Provider value={providerValue}>
      <OdeUpdateCommentsModal visible={state.isModalOpen} />
      {children}
    </OdeUpdateCommentsModalContext.Provider>
  );
}
