import { STAGES, SERVICES, COLORS_TAGS } from "./constants";

export function useOrganizationsCharts (organizations = []) {

  const dataByService = organizations.reduce((acc, curr) => {
    curr.stages.forEach(stage => {
      curr.services.forEach(service => {
        if (acc[stage]) {
          if (acc[stage][service] !== undefined) {
            acc[stage][service] += 1;
          } else {
            acc[stage].Otro += 1;
          }
        } else if (acc.Otro[service] !== undefined) {
          acc.Otro[service] += 1;
        } else {
          acc.Otro.Otro += 1;
        }
      });
    });
    return acc;
  }, JSON.parse(JSON.stringify(STAGES)));

  const datasets = Object.entries(dataByService).map(([key, value]) => ({
    label: key, data: Object.values(value), backgroundColor: COLORS_TAGS[key]
  }));

  return {
    datasets,
    labels: SERVICES
  };
}
