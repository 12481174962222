import React, { useContext } from "react";
import { Typography, Card, Space, Progress, message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AddServiceForm } from "./form";
import { FormNavigation } from "../../../shared";
import { withRouter } from "../../../../helpers";
import { OnboardingContext } from "../../../../contexts/expert/onboarding";

function AddService({ navigate }) {
  const {
    updateExpert,
    save,
    data,
    error,
    loading
  } = useContext(OnboardingContext);

  const onNext = route => async () => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      await save();
      navigate(route);
    }
    catch(e) {
      console.error(e);
      message.error("Ha ocurrido un error, \
      inténtalo de nuevo en unos segundos.");
    }
    finally {
      dismissLoader();
    }
  };

  const onChange = values => {
    updateExpert(values);
  };

  const handleOnPrev = () => {
    Modal.confirm({
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: `Si vuelves al paso anterior los datos
        del producto o servcio se perderán`,
      okText: "Continuar de todos modos",
      cancelText: "Cancelar",
      onOk: () => navigate("./experience")
    });
  };

  return (
    <Card bordered={false} style={{maxWidth: "600px", margin: "0 auto"}}>
      <Space direction="vertical" size="large">
        <Progress percent={75} showInfo={false} />
        <>
          <Typography.Title>Nuevo artículo 🛒</Typography.Title>
          <Typography.Text>
            Agrega tu primer artículo que se pondrá en venta dentro de EOS.
            Cualquier emprendedor podrá adquirirlo. Podrás agregar más
            artículos posteriormente.
          </Typography.Text>
        </>
        <AddServiceForm
          data={data}
          error={error}
          isLoading={loading}
          onChange={onChange} />
        <FormNavigation
          onPrev={handleOnPrev}
          onNext={onNext("./success")}
          onSkip={() => navigate("./success")} />
      </Space>
    </Card>
  );
}

export default withRouter(AddService);
