import React, { useCallback, useContext, useEffect, useState } from "react";
import { List, Modal, Space } from "antd";
import { ContentItem } from "./item";
import {
  ContentDetailsModalContext, ContentModalContext, DraggableListContext
} from "../../../../../contexts/shared";
import { RoleGuard } from "../../../role-guard";
import { ActionButton, Visibility } from "../../..";
import "./styles.sass";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export const ContentList = ({
  contents,
  refetch,
  isDragable,
  contentGroup
}) => {
  const [items, setItems] = useState(contents);
  const {
    moveItemPosition,
    isMovingContents
  } = useContext(DraggableListContext);
  const { onOpen } = useContext(ContentDetailsModalContext);
  const {
    openEditModal,
    duplicateContent,
    removeContent
  } = useContext(ContentModalContext);

  const moveItem = useCallback(({ destination, source })  => {
    if(!destination || !source || destination.index === source.index) return;
    moveItemPosition
    && moveItemPosition(
      items, setItems, source.index, destination.index, contentGroup.id
    );
  }, [items]);

  useEffect(() => {
    if (isMovingContents) return;
    setItems(contents);
  }, [contents]);

  const openDeleteModal = contentId => {
    return Modal.confirm({
      className: "ConfirmModal",
      width: 600,
      centered: true,
      closable: true,
      okText: "Aceptar",
      title: "Eliminar elemento",
      icon: null,
      content: (
        <Space>
          Estás a punto de eliminar un contenido, ¿seguro que quieres continuar?
        </Space>
      ),
      onOk: () => removeContent(contentId, contentGroup.id)
    });
  };

  const onDuplicateContent = content => {
    return Modal.confirm({
      className: "ConfirmModal",
      width: 600,
      centered: true,
      closable: true,
      okText: "Duplicar",
      icon: null,
      cancelText: "Cancelar",
      title: "Duplicar contenido",
      content: "¿Seguro que desea duplicar este contenido?",
      onOk: () => duplicateContent(content, contentGroup.id)
    });
  };

  return (
    <DragDropContext onDragEnd={moveItem}>
      <Droppable droppableId={contentGroup.id}>
        {droppableProvided =>
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}>
            <List
              size="small"
              dataSource={items}
              renderItem={(content, index) =>
                <Draggable
                  index={index}
                  key={content.id}
                  isDragDisabled={!isDragable}
                  draggableId={content.id}>
                  {draggableProvided =>
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}>
                      <List.Item
                        key={content.id}
                        style={{ paddingLeft: "0", paddingRight: "0" }}>
                        <div className="content-list-item">
                          <ContentItem
                            isDragable={isDragable}
                            content={content}
                            onClick={() =>
                              onOpen(
                                content.id,
                                index,
                                contents,
                                contentGroup,
                                content.odeContents
                              )
                            }
                            dragHandleProps={
                              draggableProvided.dragHandleProps
                            } />
                          <RoleGuard
                            roles={["SUPER_ADMIN", "COORDINATOR", "ADVISER"]}>
                            <Visibility visible={!!refetch}>
                              <ActionButton
                                rotate
                                type="text"
                                extra={[{
                                  label: "Duplicar",
                                  action: () => onDuplicateContent(content)
                                }]}
                                customOnDelete={() =>
                                  openDeleteModal(content.id, content.name)
                                }
                                onEdit={() =>
                                  openEditModal(contentGroup, content)
                                } />
                            </Visibility>
                          </RoleGuard>
                        </div>
                      </List.Item>
                    </div>
                  }
                </Draggable>
              } />
            {droppableProvided.placeholder}
          </div>
        }
      </Droppable>
    </DragDropContext>
  );
};