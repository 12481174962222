import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Input, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";
import { formStyle, loginErrorLabel } from "../register-form/styles";
import { Visibility } from "../visibility";
import logoImg from "../../../../assets/EOSPURPLE@3x.png";

export function PasswordResetRequestForm() {
  const [state, setState] = useState({
    sending: false,
    error: false,
    success: false
  });

  const [form] = Form.useForm();
  const [createPasswordResetRequest] = useMutation(
    shared.mutations.createPasswordResetRequest, { client }
  );

  const submitForm = async () => {
    const data = form.getFieldsValue();

    try {
      setState(state => ({
        ...state,
        sending: true,
        error: false,
        success: false
      }));

      await createPasswordResetRequest({ variables: data });

      setState(state => ({
        ...state,
        success: true
      }));
    }
    catch (e) {
      console.error(e);

      if(e.message.indexOf("No Node for the model User") > -1) {
        setState(state => ({ ...state, error: "notfound" }));
      }
      else {
        setState(state => ({ ...state, error: "other" }));
      }
    }
    finally {
      setState(state => ({ ...state, sending: false }));
    }
  };

  return (
    <Form
      form={form}
      style={formStyle}>
      <div style={{ marginBottom: "14px" }}>
        <img src={logoImg} width="87px" />
      </div>
      <Typography.Paragraph>
        Ingresa tu correo y te enviaremos un link para cambiar tu contraseña.
      </Typography.Paragraph>
      <Form.Item
        rules={[{ required: true, type: "email" }]}
        name="email">
        <Input placeholder="Correo electrónico" />
      </Form.Item>
      <Visibility visible={state.error}>
        <p style={loginErrorLabel}>
          {
            state.error === "notfound" ?
            "El correo no está registrado." :
            "Ocurrió un error, inténtalo de nuevo más tarde."
          }
        </p>
      </Visibility>
      <Button
        style={{ width: "100%" }}
        onClick={submitForm}
        loading={state.sending}
        disabled={state.success}
        type="primary">
        { !state.success ? "Enviar" : "Link enviado" }
      </Button>
      <Link to="/v2/login">
        <Button
          style={{ width: "100%" }}
          type="link">
          Regresar al login
        </Button>
      </Link>
    </Form>
  );
}
