import gql from "graphql-tag";

export const getOdeContentsQuery = gql`
  query getOdeContents($odeId: ID!, $batchId: ID!) {
    odeContents(
      where:
      {
        ode: {
          id: $odeId
        }
        batch: {
          id: $batchId
        }
      }
    ) {
      id
      status
      content {
        id
        type
      }
    }
  }
`;
