import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Title } from "../../../components/title";
import { Line } from "../../../components/line";

export const PeopleInvolved = ({ data }) => {
  return (
    <View style={{ fontSize: 12 }}>
      <Title>PERSONAS INVOLUCRADAS</Title>
      <Line style={{ marginTop: 5, marginBottom: 10 }} />
      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <View style={styles.width}>
          <Text style={styles.titles}>Mentor</Text>
          <Text>{data?.expert?.name}</Text>
        </View>
        <View>
          <Text style={styles.titles}>Teléfono</Text>
          {data?.expert?.cel
              ? <Text style={styles.cel}>{data?.expert?.cel}</Text>
              : <Text style={{ ...styles.cel, color: "lightgrey" }}>Sin número</Text>}
        </View>
        <View style={styles.width}>
          <Text style={styles.titles}>Correo</Text>
          {data?.expert?.email
              ? <Text>{data?.expert?.email}</Text>
              : <Text style={{ color: "lightgrey" }}>Sin información</Text>}
        </View>
      </View>
      <Line style={{ marginTop: 15, marginBottom: 10 }} />
      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <View style={styles.width}>
          <Text style={styles.titles}>Emprendedores</Text>
          {data?.entrepreneurs?.map(user => (
            <Text style={styles.entrepreners}>{user?.name}</Text>
          ))}
        </View>
        <View>
          <Text style={styles.titles}>Teléfono</Text>
          {data?.entrepreneurs?.map(user => (
            user?.cel
              ? <Text style={{ ...styles.cel, ...styles.entrepreners }}>{user?.cel}</Text>
              : <Text style={{ ...styles.cel, ...styles.entrepreners, color: "lightgrey" }}>Sin número</Text>
          ))}
        </View>
        <View style={styles.width}>
          <Text style={styles.titles}>Correo</Text>
          {data?.entrepreneurs?.map(user => (
            user?.email
              ? <Text style={styles.entrepreners}>{user?.email}</Text>
              : <Text style={{ ...styles.entrepreners, color: "lightgrey" }}>Sin información</Text>
          ))}
        </View>
      </View>
      <Line style={{ marginTop: 15, marginBottom: 10 }} />
      <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <View style={styles.width}>
          <Text style={styles.titles}>Coordinador</Text>
          <Text>{data?.coordinator?.name}</Text>
        </View>
        <View>
          <Text style={styles.titles}>Teléfono</Text>
          {data?.coordinator?.cel
              ? <Text style={styles.cel}>{data?.coordinator?.cel}</Text>
              : <Text style={{ ...styles.cel, color: "lightgrey" }}>Sin número</Text>}
        </View>
        <View style={styles.width}>
          <Text style={styles.titles}>Correo</Text>
          {data?.coordinator?.email
              ? <Text>{data?.coordinator?.email}</Text>
              : <Text style={{ color: "lightgrey" }}>Sin información</Text>}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titles: {
    marginBottom: 10,
    fontWeight: 700
  },
  width: {
    width: 160
  },
  cel: {
    width: 80
  },
  entrepreners: {
    marginBottom: 8
  }
});