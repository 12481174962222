import React from "react";
import { Alert, Card, Typography } from "antd";
import { checkUrlIsValid } from "../../../../../../helpers";
import { CardContainer } from "./card-container";
import { ActionButton, FlexContainer } from "../../../../index";
import { Visibility } from "../../../../visibility";

export function renderCard({
  id,
  url,
  fileName,
  type,
  openModal,
  onDelete,
  allowDelete = true,
  isAdviser
}) {

  const getTypeName = (type = "") => {
      return type.split("/").pop();
  };

  if(type === "addBtn"){
    return(
      <Visibility visible={!isAdviser}>
        <CardContainer
          onClick={openModal}
          label="Agrega un documento, video, liga o imagen." />
      </Visibility>
    );
  }

  if(type === "link")
    return (
      <Card
        bordered={false}
        style={{borderRadius: "8px", width: "100%"}}>
        <div>
          {checkUrlIsValid(url)
            ? <div style={{
              width: "100%",
              display: "flex",
              borderRadius: "8px",
              flexDirection: "row",
              alignContent: "center",
              border: "1px solid #f0f0f0",
              boxShadow: "2px 2px 2px 1px rgba(0,0,0,0.2)"
            }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "125px",
                  textAlign: "center",
                  backgroundColor: "rgba(95,57,222,0.05)"
                }}>
                <Typography.Title
                  level={5}
                  style={{
                    padding: "0 30px",
                    textTransform: "uppercase",
                    color: "#5F39DE"
                  }}>
                  https
                </Typography.Title>
              </div>
              <FlexContainer
                style={{ flex: 2, overflow: "hidden", padding: "15px 20px" }}
                xAlign={"center"}
                yAlign={"center"}>
                <a style={{ overflow: "hidden" }} href={url}>
                  <Typography.Title
                    style={{ textOverflow: "elipsis", height: "60px" }}
                    level={5}>
                    {url}
                  </Typography.Title>
                </a>
              </FlexContainer>
            </div>
            : <Alert
                type="warning"
                message="El enlace no es una URL válida"
                showIcon />}
          <Visibility visible={!isAdviser}>
            <div
              style={{
                top: "25px",
                right: "-10px",
                position: "absolute"
              }}>
              <ActionButton.New
                onDelete={() => onDelete(id)} />
            </div>
          </Visibility>
        </div>
      </Card>
    );

    return (
      <Card bordered={false}>
        <div style={{
          width: "100%",
          display: "flex",
          borderRadius: "8px",
          flexDirection: "row",
          alignContent: "center",
          border: "1px solid #f0f0f0",
          boxShadow: "2px 2px 2px 1px rgba(0,0,0,0.2)"
        }}>
          <div
            style={{
              flex: 1,
              height: "125px",
              textAlign: "center",
              backgroundColor: "rgba(95,57,222,0.05)"
            }}>
            <Typography.Title
              level={5}
              style={{
                textTransform: "uppercase",
                paddingTop: "40%",
                color: "#5F39DE"
              }}>
              {getTypeName(type || "")}
            </Typography.Title>
          </div>
          <FlexContainer
            style={{flex: 2, overflow: "hidden", padding: "15px 20px"}}
            xAlign={"center"}
            yAlign={"center"}>
            <a style={{ overflow: "hidden" }} href={url}>
              <Typography.Title
                style={{ textOverflow: "elipsis" }}
                level={5}>
                {fileName}
              </Typography.Title>
            </a>
          </FlexContainer>
          <div
            style={{
              top: "25px",
              right: "-10px",
              position: "absolute"
            }}>
            <Visibility visible={allowDelete}>
              <ActionButton.New
                onDelete={() => onDelete(id)} />
            </Visibility>
          </div>
        </div>
      </Card>
  );
}
