import React, {useContext} from "react";
import { List } from "antd";
import { ParagraphSkeleton } from "../../../../";
import {renderCard} from "./render-card";
import {
  GenericModalContext,
  GenericModalProvider
} from "../../../../../../contexts/shared/modals";
import { AddCompanyMultimediaModal } from "../../../../modals";
import { useCompanyMultimedia } from "../../../../../../hooks/company";
import { CurrentUserContext } from "../../../../../../contexts/shared";

export function CompanyMultimedia(
  {
    loading = false,
    refetch,
    data = {
      id: "",
      files: []
    },
    allowEdit
  })
{
  data = data || {};

  const { currentUser } = useContext(CurrentUserContext);
  const isAdviser = currentUser.systemRole === "ADVISER";

  const {
    addCompanyMultimedia,
    deleteCompanyMultimedia
  } = useCompanyMultimedia(
    {
      companyId: data.id
    });


  if (loading) {
    return <ParagraphSkeleton paragraphs={1} />;
  }

  const addMultimedia = ({ attachments = [], links = []}) => {
    let items = attachments.map(item => ({
      fileName: item.fileName,
      url: item.url,
      type: item.type,
      uploadDate: item.uploadDate
    }));

    items = items.concat(
      links.map(link => ({
        fileName: link.fileName,
        url: link,
        type: "link",
        uploadDate: new Date().toISOString()
      }))
    );

    addCompanyMultimedia({items, afterSuccess: refetch});
  };
  const onDelete = (fileId = "") => {
    deleteCompanyMultimedia({fileId, afterSuccess: refetch});
  };

  const refetchMultimedia = () => {

  };

  return (
    <GenericModalProvider
      modalProps={{
        onOk: addMultimedia,
        afterOk: refetchMultimedia
      }}
      modal={AddCompanyMultimediaModal}>
      <GenericModalContext.Consumer>
        {
          ({openModal}) => (
            <div style={{marginLeft: "-24px"}}>
              <List
                dataSource={
                  data?.files
                  ? [...data.files, {type: "addBtn"}]
                  : []
                }
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 2,
                  xxl: 3
                }}
                renderItem={item => (
                  <List.Item>
                    {renderCard(
                      {
                        ...item,
                        openModal,
                        onDelete,
                        isAdviser,
                        allowDelete: allowEdit
                      })}
                  </List.Item>
                )}>
              </List>
            </div>
          )
        }
      </GenericModalContext.Consumer>
    </GenericModalProvider>
  );
}
