import { client } from "../../../graphql";
import { activeStatusOdesFromBatch } from "./graphql";

export const activeStatusOdesFromBatchService = async ({
  batchId,
  odes
}) => {
  const result = await client.mutate({
    mutation: activeStatusOdesFromBatch,
    variables: {
      data: {
        odes,
        batchId
      }
    }
  });

  return result?.data?.statusOdesFromBatch || false;
};