import React from "react";
import { EventsCalendar } from "../../../shared";
import {
  BatchEventDetailsModalProvider
} from "../../../../contexts/shared";

export function EntrepreneurCalendar({
  events = [],
  loading,
  error,
  onChange
}) {
  return (
    <BatchEventDetailsModalProvider>
      <EventsCalendar
        onChange={onChange}
        events={events}
        loading={loading}
        error={error} />
    </BatchEventDetailsModalProvider>
  );
}
