import gql from "graphql-tag";

export const updateMasterContent = gql`
  mutation UpdateMasterContent(
    $data: MasterContentUpdateInput!,
    $where: MasterContentWhereUniqueInput!
  ) {
    updateMasterContent(data: $data, where: $where) {
      id
    }
  }
`;
