import gql from "graphql-tag";

export const applyContentDraft = gql`
  mutation ApplyContentDraft(
    $where: [ContentGroupWhereUniqueInput!]
  ) {
    applyContentDraft(
      where: $where
    ) {
      count
    }
  }
`;
