import { OdesService } from "../../services";
import { message } from "antd";

export const useOdeServices = () => {
  const errorMsg = "Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.";

  const disconnectOdeFromBatch = async ({
    batchId,
    companyId,
    odesSorted,
    refetch = null
  }) => {
    const dismissLoading = message.loading("Eliminando...");
    try {
      await OdesService.disconnectOdeFromBatchService({
        batchId,
        companyId,
        odesSorted
      });
      message.success("Eliminado.");
      refetch && await refetch();
    } catch(e) {
      message.error(errorMsg);
      console.error(e);
    } finally {
      dismissLoading();
    }
  };

  const connectOdesFromBatch = async ({
    batchId,
    odesConnect,
    odes = [],
    refetch = null
  }) => {
    const dismissLoading = message.loading("Guardando...");
    try {
      // Todo: Generate Single Custom Resolver With Transaction
      await OdesService.connectOdesFromBatchService({
        batchId,
        odesConnect
      });
      await OdesService.activeStatusOdesFromBatchService({
        batchId,
        odes
      });
      message.success("Guardado.");
      await refetch();
    } catch(e) {
      message.error(errorMsg);
      console.error(e);
    } finally {
      dismissLoading();
    }
  };

  const createPublishedContent = async ({ companyId, masterContentId }) => {
    try {
      await OdesService.createPublishedContentService({
        companyId,
        masterContentId
      });
    } catch(e) {
      message.error(errorMsg);
      console.error(e);
    }
  };

  const removeAssignedExpertService = async ({
    assignedExpertServiceId,
    refetch
  }) => {
    try {
      await OdesService.deleteAssignedExpertServicesService({
        assignedExpertServiceId
      });
      message.success("Eliminado.");
      refetch && await refetch();
    } catch(e) {
      message.error(errorMsg);
      console.error(e);
    }
  };

  return {
    disconnectOdeFromBatch,
    connectOdesFromBatch,
    createPublishedContent,
    removeAssignedExpertService
  };
};
