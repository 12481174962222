import React, { useContext } from "react";
import dayjs from "dayjs";
import { ParagraphSkeleton } from "../../../skeletons";
import { Row, Typography, Space, Tag, Divider, Button, Alert } from "antd";
import { Avatar, EditButton, Section, TagList, Visibility } from "../../../";
import {
  ApiOutlined,
  FilePdfOutlined,
  TeamOutlined,
  EnvironmentFilled,
  MailOutlined,
  PhoneOutlined,
  HomeOutlined
} from "@ant-design/icons";
import {
  getReadableValue,
  nationalities
} from "../../../../../helpers";
import { getLinkIcon } from "../../../../../helpers";
import {
  EditCompanyContext
} from "../../../../../contexts/shared/edit-company";
import {
  readableLegacyCountriesSupport
} from "../../../../../helpers/readable-legacy-countries-support";
import { countries } from "../../../../../utils";

export function CompanyDetailIndex({
  data,
  loading,
  allowEdit,
  detailAlert,
  contactAlert
}) {
  const { onEdit } = useContext(EditCompanyContext);

  const isNew = data?.createdAt
    ? (dayjs().diff(data?.createdAt, "month") === 0) : false;

  const handleOnePagerClick = () => {
    const win = window.open("./one-pager", "_blank");
    win.focus();
  };

  if (loading) {
    return <ParagraphSkeleton paragraphs={2} />;
  }

  const addHttp = link => {
    if (link.includes("http")) {
      return link;
    } else {
      return `https://${link}`;
    }
  };

  const renderTech = () => (<><ApiOutlined /> Tecnología</>);
  const renderSocial = () => (<span> <TeamOutlined /> Social</span>);
  const renderSocialLinks = links => links?.map(link => (
    <Typography.Link
      key={link}
      rel="noopener noreferrer"
      href={addHttp(link)}
      target="_blank">
      {getLinkIcon(link)}
    </Typography.Link>
  ));

  const renderPresenceStates = presenceStates => {
    const presence = [];

    for (const element of presenceStates) {
      const tpl = (
        <Row style={{ marginBottom: "0"}}>
          <Typography.Text
            style={{
              fontWeight: "bold",
              color: "rgb(88, 100, 114)"}}>
            <EnvironmentFilled />
            {`  ${element?.name}: `}
          </Typography.Text>
          <Typography.Text
            style={{
              marginLeft: "5px",
              color: "rgb(138, 147, 156)" }}>
            {
              element?.allCities
                ? "Todo el estado"
                : element?.cities?.join(", ")
            }
          </Typography.Text>
        </Row>
      );
      presence.push(tpl);
    }
    return presence;
  };

  const getProfileTags = () => {
    const sector = getReadableValue("ODE_SECTORS", data?.economicSector, null);

    const industry = getReadableValue(
        "INDUSTRIES",
        data?.economicIndustry || data?.industry,
        null
      );

    return [industry, sector];
  };

  const addressPart = [
    data?.address?.street || data?.streetAddress,
    data?.address?.neighborhood || data?.colonyAddress,
    data?.address?.zipCode || data?.zipCode,
    data?.address?.city || data?.city,
    data?.address?.country || data?.country
      ? readableLegacyCountriesSupport(nationalities, countries, data?.address?.country || data?.country)
      : null
  ].filter(p => !!p);

  const address = addressPart.join(", ");
  const profileTags = getProfileTags();
  return (
    <Section className="CompanyDetail">
      <Visibility visible={detailAlert}>
        <Row>
          <Alert
            type="warning" showIcon
            style={{width: "-webkit-fill-available"}}
            message="Tienes campos obligatorios sin llenar." />
        </Row>
      </Visibility>
      <Row>
        <div style={{ flex: "1", alignSelf: "center" }}>
          <Avatar size={70}>
            {data?.logoUrl || data?.name}
          </Avatar>
        </div>
        <div style={{ flex: "3" }}>
          <Space direction="vertical" className="CompanyDetail__Wrapper-S">
            <Space
              className="CompanyDetail__S1"
              style={!isNew && { justifyContent: "flex-end" }}>
              {isNew && <Tag color="green">Nueva</Tag>}
              <EditButton visible={allowEdit} onClick={() => onEdit("GENERAL")}>
                Editar
              </EditButton>
            </Space>
            <Typography.Text
                strong
                style={{marginBottom: "0px"}}>
              { data?.odeID ? `Folio: ${data?.odeID}` : null }
            </Typography.Text>
            <Typography.Title level={3}>
              {data?.name}
            </Typography.Title>
            <Space className="CompanyDetail__S2">
              <Typography.Text>
                {data?.techBased && renderTech()}
                {data?.social && renderSocial()}
              </Typography.Text>
              <EditButton visible={allowEdit} onClick={() => onEdit("ABOUT")}>
                Editar
              </EditButton>
            </Space>
            <Space className="CompanyDetail__S3">
              <TagList maxCount={2} tags={profileTags} />
            </Space>
          </Space>
        </div>
      </Row>
      <Row>
        <Typography.Paragraph ellipsis={{ rows: 3 }} type="secondary">
          {data?.shortDescription}
        </Typography.Paragraph>
      </Row>
      <Row>
        <Button
          onClick={handleOnePagerClick}
          icon={<FilePdfOutlined />}>
          One Pager
        </Button>
      </Row>
      <Divider />
      <Space direction="vertical">
        <Visibility visible={contactAlert}>
          <Row>
            <Alert
              type="warning" showIcon
              style={{width: "-webkit-fill-available"}}
              message="Tienes campos obligatorios sin llenar." />
          </Row>
        </Visibility>
        <Space className="CompanyDetail__C1">
          <Typography.Text>
            Contacto
          </Typography.Text>
          <EditButton visible={allowEdit} onClick={() => onEdit("CONTACT")}>
            Editar
          </EditButton>
        </Space>
        <Typography.Text>
          Lugares en donde tiene presencia
        </Typography.Text>
        <Space direction="vertical" className="CompanyDetail__C2">
          {data?.presenceStates && renderPresenceStates(data?.presenceStates)}
        </Space>
      </Space>
      <Divider />
      <Space direction="vertical" className="CompanyDetail__C3">
        <Typography.Text type="secondary">
          <MailOutlined /> {data?.email}
        </Typography.Text>
        <Typography.Text type="secondary">
          <PhoneOutlined /> {data?.phone}
        </Typography.Text>
        <Typography.Text type="secondary">
          <HomeOutlined /> {address}
        </Typography.Text>
        <Space className="CompanyDetail__C4">
          {renderSocialLinks(data?.socialMedia)}
        </Space>
      </Space>
    </Section>
  );
}
