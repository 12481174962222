import React from "react";
import { Button, Space, Typography } from "antd";
import { CrownOutlined } from "@ant-design/icons";
import { TagList } from "../../index";
import { CardListItem } from "../../list-item/card-list-item";
import {
  expertServiceCategories,
  getReadableValue
} from "../../../../helpers/select-options";

export function AssignedMentoringCard({
  leader,
  openModal,
  assignedService
}) {
  const {
    assignedUnits,
    availableUnits,
    expertService
  } = !!assignedService && assignedService;
  const {
    name,
    category,
    expert,
    coverPicUrl
  } = !!expertService && expertService;
  const { id, fullName } = !!expert && expert;
  const mentoringType = [
    getReadableValue(expertServiceCategories, category),
    "Mentoría"
  ];
  const consumedHours = +assignedUnits - +availableUnits;
  const isLeader = leader === id;

  const statusConsumed = [
    "REJECTED",
    "AUTHORIZED_FOR_PAYMENT",
    "PAID",
    "ACCEPTED"
  ];

  const scheduled = assignedService?.scheduledExpertService;
  const consumed = statusConsumed.includes(scheduled?.status) || scheduled
    ? false : assignedService?.availableUnits === 0;

  const expired = scheduled
    ? scheduled?.status === "EXPIRED"
    : assignedService.status === "EXPIRED";

  const cancelled = scheduled
    ? scheduled?.status === "CANCELLED"
    : assignedService.status === "CANCELLED";

  const started = (consumedHours > 0 && consumedHours < assignedUnits);

  const labelStatus = consumed
    ? "Consumido" : cancelled
      ? "Cancelado" : expired
        ? "Asignación vencida" : `${consumedHours}/${assignedUnits}h`;

  const color = cancelled
    ? "secondary" : (consumed || expired) ? "danger" : "success";

  const props = (scheduled || consumed || cancelled || expired || started) ? {
    customOptionsComponent:
    <Typography.Text type={color}>
      {labelStatus}
    </Typography.Text>
  } : { onRemove: () => null };

  return (
    <CardListItem
      {...props}
      style={{height: "23rem"}}
      footerItems={[
        <Button
          onClick={openModal}
          ghost
          type={"link"}
          key={"btn-assigned"}>Ver detalle</Button>
      ]}
      extra={
        <TagList
          colors={["blue", "magenta"]}
          maxCount={2}
          tags={mentoringType} />}
      coverUrl={coverPicUrl}
      title={name}>
      {
        isLeader ?
          <Space direction="horizontal">
            <CrownOutlined style={{color: "#FFB61D"}} />
            <Typography.Text style={{color: "#979797"}}>
              {fullName}
            </Typography.Text>
          </Space>
        :
          <Space direction="horizontal">
            <Typography.Text style={{color: "#979797"}}>
              {fullName}
            </Typography.Text>
          </Space>
      }
    </CardListItem>
  );
}
