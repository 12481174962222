/**
 * Fix a link string that could have not the http prefix
 * @param {*} link
 * @returns A valid link string
 */
export function getClickableLink(link) {
  return link.startsWith("http://") || link.startsWith("https://") ?
    link
    : `//${link}`;
}
