import { useMutation } from "@apollo/react-hooks";
import { Button, List, message, Popconfirm, Space } from "antd";
import React, { useContext } from "react";
import { client } from "../../../../../../graphql";
import { WorkspaceModalContext } from "../../../../../contexts/admin/configuration/workspaces/workspace-modal";
import { admin } from "../../../../../graphql/admin";
import { cardGridSizes, withRouter } from "../../../../../helpers";
import { ActionButton, AddButton, DeleteButton } from "../../../../shared";
import { WorkspaceItem } from "./item";

function WorkspacesList({ data, loading, navigate }) {
  const { openModal } = useContext(WorkspaceModalContext);

  const [deleteWorkspace] = useMutation(admin.mutations.deleteWorkspace, {
    client
  });

  const onClickDelete = id => async () => {
    try {
      await deleteWorkspace({
        variables: {
          id: id
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: admin.queries.getWorkspaces
          }
        ]
      });
      message.success("Workspace eliminado");
    }
    catch (e) {
      console.error(e);
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
    }
  };

  return (
    <>
      <AddButton
        onClick={() => openModal()}
        fullWidth>
        Agregar workspace
      </AddButton>
      <List
        style={{ marginTop: "24px" }}
        pagination
        loading={loading}
        grid={cardGridSizes}
        dataSource={data?.workspaces}
        renderItem={workspace =>
          <List.Item>
            <WorkspaceItem
              workspace={workspace}
              extra={
                <Space>
                  <Button
                    type="primary"
                    onClick={() => openModal(workspace)}>
                    Editar
                  </Button>
                  <Popconfirm
                    title="¿Deseas eliminar este workspace? Esta acción no se puede deshacer."
                    okText="Eliminar"
                    cancelText="Cancelar"
                    onConfirm={onClickDelete(workspace.id)}>
                    <Button
                      type="secondary"
                      danger>
                      Eliminar
                    </Button>
                  </Popconfirm>
                </Space>
              } />
          </List.Item>
        } />
    </>
  );
}

export default withRouter(withRouter(WorkspacesList));
