import { cubeMeasureCounter } from "../cube-measure-counter";
import { buildCubeFilters } from "../build-cube-filters";

export function countCompaniesBy({ dimensions = [], filters = {} }) {
  return cubeMeasureCounter({
    by: dimensions,
    filters: buildCubeFilters(filters)
  })
    .countCompanies();
}
