/* eslint-disable max-len */
/* eslint-disable max-lines */

export const OCCUPATIONS = [
  {
    label: "Empleado(a)",
    value: "Empleado(a)",
    legacyValues: [
      "GERENTE DE MKT.",
      "Ingeniero de producción",
      "Administración CulturArte",
      "Director municipal de Catemaco",
      "CVO @ Alcancía",
      "Desarrollo Orgnizacional",
      "Recursos Humanos",
      "Cto",
      "Mantenimiento",
      "CTO",
      "Ingeniero Químico",
      "ventas y atencion al clientes",
      "empleada",
      "JEFE DE EMPRENDIMIENTO",
      "Emprendedor / empleado",
      "Director General",
      "Coordinadora proyectos",
      "Director de Tecnología",
      "TI",
      "Jefa de Investigación UNIVA Campus Guadalajara",
      "área administrativa de interiorismo kace",
      "Diseñador de sistemas emectronicos",
      "asesor financiero",
      "Becario software embebido",
      "Biólogo molecular",
      "Director de la oficina de proyectos",
      "Account Manager",
      "Auxiliar de comercialización",
      "Campus Intern",
      "Gerente",
      "Subdirectora",
      "Head of Finance",
      "Ingeniero de Inteligencia Artificial",
      "DIRECTOR GENERAL",
      "Director jurídico",
      "Ingeniero de Proyectos",
      "Sr. IT Project Manager",
      "Co-Director",
      "Abogada",
      "Ventas",
      "CMO",
      "Director de Departamento",
      "Ingeniero Ambiental",
      "Coordinadora",
      "Sales Strategist",
      "Responsable de Laboratorio de Calidad y Desarrollo",
      "Servicios turísticos",
      "Líder de Operaciones",
      "Director de Operaciones",
      "Coordinador de Seguridad Industrial de sitios de no manufactura a nivel México",
      "Director de Ceropobreza",
      "Chief of Staff",
      "Gerente de Ventas",
      "Project Manager",
      "Coordinador de Transferencia Tecnológica y del Conocimiento",
      "National Junior Branch Representative",
      "CEO",
      "FP&A Fintech Lead",
      "Directora de Operaciones",
      "Servidor público",
      "Coordinator de logística",
      "Administradora",
      "CEO Bright Space",
      "Ingeniero",
      "Agente de Seguros / COO Deep Technologies",
      "Gerente general",
      "LOGISTICA",
      "Ejecutivo de finanzas para marketing y empredendora",
      "Intern",
      "Presidente",
      "Colaborador",
      "Directora de Finanzas",
      "Coordinador de Emprendimiento",
      "Coordinadora Escuela",
      "Dir Comercial",
      "director general",
      "Supervisión calidad",
      "Director de Proyectos",
      "EMPLEADO",
      "Jefe de Departamento",
      "FUNCIONARIO PUBLICO",
      "Analista",
      "Gerente de operaciones",
      "Becaria",
      "EMPLEADA (JEFE OFICINA SERVICIOS ESCOLARES)",
      "Lead Project Management",
      "Coordinador de Espíritu Emprendedor",
      "Ing. de Desarrollo",
      "Director de Aceleradora de Empresas ALFA Orion",
      "Colaboradora Tec",
      "Ing. de Proyectos",
      "Director ingenieria",
      "Coordinadora de Biblioteca",
      "Ingeniero en Sistemas - COT",
      "Electrical Validation Intern",
      "EMPLOYEE",
      "Director",
      "Jefe de laboratorio",
      "Ing Biomedico",
      "Coordinador de Atracción de Fondos",
      "Director de Supply Chain",
      "CEO",
      "Directora Administrativa",
      "CIET",
      "empleado",
      "Dirección",
      "Encargada de logística",
      "Gerente de administración",
      "CGO",
      "Ingeniero de Diseño Mecanico",
      "Ingeniero de Software",
      "Data Scientist Sr Manager",
      "Asesor de calidad",
      "INGENIERA INDUSTRIAL",
      "Asesora",
      "CTO",
      "directora de Naikare suculentas y Biomateria",
      "Subdirección Administrativa",
      "Gerente Económico Riesgos",
      "Trabajo de tiempo completo",
      "Ingeniero de Producto - Innovación y Desarrollo",
      "EMPLEADA",
      "Arquitecto, Empresario, Administrador De Desarrollos Inmobiliarios, Emprendedor",
      "Product Manager",
      "Creador Contenido EducaresEc / Diseñador Multimedia en Esval Producciones",
      "Data Hub Coordinator",
      "WORKER",
      "CFO InteVolution",
      "PROCURADORA DE FONDOS",
      "Abogada de Propiedad Intelectual y Derecho Corporativo",
      "Dueño de negocio",
      "CCO",
      "Director General",
      "Directora administrativa",
      "Country Manager",
      "Colaboradora en proyectos de emprendimiento",
      "Directora del programa bicultural",
      "CFO",
      "Empleada Federal",
      "Manager de emprendimiento",
      "Coordinador de Programas",
      "Gerente de I + D",
      "Inside Sales",
      "Empleado",
      "Ingeniero Electrónico",
      "Coordinador",
      "Ejecutivo de Ventas",
      "Gerente de manufactura",
      "Encargada del área de planeación, estrategias y negocios de LWTS",
      "DIRECTOR GENERAL",
      "Ingeniero de obra",
      "PUNTO FOCAL",
      "Sales Operations Manager Latin America",
      "Director de operaciones",
      "Prefectura.",
      "Fundadora y abogada",
      "Director de Fomento Agropecuario",
      "Coordinadora de Soluciones Médicas",
      "Abogado",
      "CEO Innmortal",
      "Director de innovacion y desarrollo de nuevas unidades de negocio",
      "Empleada",
      "Jefe de desarrollo organizacional",
      "Subdirectora de Proyectos",
      "Revuenue Management",
      "Jefe de Investigación y Desarrollo",
      "Comercialización",
      "Director General Grupo 2Rios",
      "Co editora Chic Magazine Tamaulipas",
      "Coordinador de Inclusión y Vinculación con familias",
      "Manager",
      "Director de Innovación",
      "Coordinadora académica",
      "Project manager",
      "Coordinadora Fomento Agropecuario",
      "Empleado.",
      "Gerete de producción",
      "Administrativo",
      "Ingeniero practicante",
      "Ingeniero Agrícola",
      "Abogado"
    ]
  },
  {
    label: "Estudiante",
    value: "Estudiante",
    legacyValues: [
      "Estudiante de maestría",
      "Alumno / Fundador de OneStep",
      "Estudiante de preparatoria",
      "Estudiante, diseñadora",
      "Estudiante doctoral",
      "estudiante",
      "Estudiante de Doctorado",
      "Estudiante y Project Manager",
      "Estudiante/Asesor",
      "Estudiante / Emprendedora",
      "Auxiliar de Zona Ei / Estudiante",
      "Estudiante y emprendedora",
      "Preparatoria",
      "Estudiante de Profesional",
      "Estudiante y Emprendedor",
      "Estudiante Y Trabajo de medio tiempo",
      "Estudiante.",
      "Estudiante de Preparatoria",
      "Estudiante doctoral/Emprendedor",
      "Estudiante/Emprendedor",
      "Estudiante/emprendedora",
      "Estudiante y emprendedor",
      "Alumno",
      "Estudiante de Doctorado en Biotecnología",
      "Estudiante",
      "Estudiante",
      "Estudiante/ Trabajo medio tiempo",
      "Estudiante de Ingeniería Química",
      "Estudiante emprendedor",
      "Estudiante de ingeniería",
      "Alumna",
      "ESTUDIANTE",
      "Cantante, actríz, diseñador gráfico.",
      "Estudiante de Ingeniería en Tecnológico de Monterrey CSN",
      "estudiante/emprendedor",
      "Estudiante // Emprendedor social",
      "Estudiannte",
      "STUDENT",
      "Estudiante y emprendedora",
      "Estudiante/Emprendedora",
      "Estudiante de último semestre",
      "Estudiante Preparatoria",
      "Doctorante",
      "Alumno/Estudiante",
      "Alumnos",
      "Emprendedor, estudiante",
      "Estudiante/ CIO",
      "Estudiante e ingeniero de producto",
      "Estudiante - Emprendedor",
      "Estudiante, Empleado",
      "Estudiante de mecatrónica",
      "Alumna, cantante, actriz y bailarina",
      "estudiante emprededor",
      "Empresario y Estudiante",
      "Estudiante de posgrado",
      "Emprendedor y Estudiante",
      "estudiante",
      "Estudiane",
      "student"
    ]
  },
  {
    label: "Negocio propio",
    value: "Negocio propio",
    legacyValues: [
      "Co-fundadora",
      "ACADEMICO, EMPRESARIO",
      "FOUNDER",
      "Emprendedor",
      "Pequeña empresaria",
      "Co-fundador",
      "Emprendedor y colaborador",
      "Autoempleado",
      "Coach, emprendedor",
      "Fundador",
      "E-Commerce",
      "empresaria",
      "Negocio Propio",
      "Co-Founder",
      "Cofundador, encargada de operaciones",
      "Co founder",
      "CEO & Founder at Blau Corp | Data Scientist | Eco-Entrepreneur",
      "EMPRENDEDORA",
      "OWN_BUSINESS",
      "Ama de casa/Empresaria",
      "Instructora del taller de cocina misión Cuautla/Negocio Propio",
      "Cofundadora. CEO.",
      "Empresaria",
      "Co Fundador de EZ-TEK",
      "Emprendedor/Inversionista.",
      "cofundador",
      "PROPIETARIO",
      "Ganadería",
      "Socio Arquitecto",
      "Practicante",
      "Founder Doczent",
      "Fundador y CEO",
      "Empresario",
      "CEO & Founder",
      "CEO & Co-founder",
      "Co-fundador, Presidente",
      "Ventas humus orgánico",
      "CCO y Fundador",
      "Co-founder @ Project Clair I Project Manager Jr. @ Orion Startups",
      "Co-founder",
      "SOCIO",
      "Empresario Social",
      "Founder"
    ]
  },
  {
    label: "Trabajador(a) independiente",
    value: "Trabajador(a) independiente",
    legacyValues: [
      "Data Science and Software Engineer / Investigador independiente",
      "Realizador Audiovisual - Gestor Cultural",
      "Consultora de Desarrollo Organizacional",
      "Desarrolllador de Software",
      "Creativo independiente",
      "Comerciante",
      "Fisioterapeuta a domicilio y fisioterapeuta eventual en Médica Campestre",
      "Desarrolladora",
      "emprendedor",
      "Contador Público",
      "Diseñador",
      "Psicóloga clínica",
      "DevOps & Full Stack",
      "Emprendiendo",
      "Sondeadorespara tecnologías 5G",
      "Psicóloga y Psicoterapeuta",
      "Marketing",
      "medico",
      "Trabajador independiente",
      "Medico Veterinario Zootecnista",
      "Independiente",
      "Consultor",
      "Científico de datos",
      "Emprendedora",
      "Medico",
      "NUTRIÓLOGA CLÍNICA PEDIÁTRICA",
      "Emprendedor / Energy Expert",
      "Consultor en areas de Transformacion Cultural, Desarrollo de Procesos y Desarrollo Organizacional",
      "Programador",
      "Consultor de Emprendimiento Regenerativo",
      "Manejo de redes",
      "Arquitecta",
      "Consultora Estratégica Empresarial",
      "EMPRENDEDOR",
      "Software Engineer",
      "Desarrollador Web",
      "emprendedora y voluntaria",
      "Prestador de servicios",
      "Full Stack Developer IoT",
      "Especialista de Desarrollo de Producto",
      "Desarrolladora de Software",
      "cOACH & mENTOR DE eMPRENDEDORES",
      "Analista de Riesgos",
      "Piloto Aviador",
      "Emprendedor",
      "Arquitecto independiente",
      "Diseño",
      "iOS Developer",
      "Contador",
      "Emprendedora",
      "Diseño Gráfico",
      "Consultor independiente",
      "Procuradora de Fondos y Desarrollo de Proyectos.",
      "INDEPENDIENTE",
      "Consultora y coach",
      "Producto Designer",
      "Especialista en Transformación",
      "Pastor",
      "Mercadotecnia"
    ]
  },
  {
    label: "Docente",
    value: "Docente",
    legacyValues: [
      "Prefectura y  maestra",
      "Profesora Tutora",
      "Docente de química industrial",
      "Docente",
      "Prefectura y maestra",
      "Diseñadora de Modas en Encanto Infantil y docente de universidad",
      "Catedrático",
      "Profesor Liderazgo Emprendedor y Liderazgo Social",
      "Ama de casa/Docente/Empresaria",
      "Maestro",
      "Profesor Tutoe",
      "Educadora",
      "Maestra de càtedra",
      "Profesora Prepa Tec. Fundadora La Fiesta de los Libros",
      "Docente y consultor",
      "Profesor",
      "emprendedor y profesor",
      "docente",
      "Profesora Investigadora",
      "Profesora Tutora",
      "profesor",
      "Medico internista / Profesor universitario",
      "Profesor tiempo completo en Ciencias Básicas",
      "Profesora",
      "Profesora-Investigadora",
      "DOCENTE",
      "DOCENTE UNIVERSITARIO",
      "Profesor investigador",
      "Profesor de Asignatura",
      "Profe",
      "Maestro en Universidad Privada",
      "Maestro",
      "Profesor - Investigador"
    ]
  },
  {
    label: "Investigador(a)",
    value: "Investigador(a)",
    legacyValues: [
      "Investigación y Desarrollo",
      "Investigador Titular",
      "Investigadora",
      "Investigador / Empresario",
      "Profesor Investigador",
      "Investigador Postdoc",
      "Investigación en Alimentos",
      "Investigador",
      "Comerciante / Investigación",
      "Lider de Investigación y Desarrollo Illucens Mexico",
      "Diseño, educación, investigación",
      "Auxiliar de investigación",
      "INVESIGADORA"
    ]
  },
  {
    label: "Otro",
    value: "Otro",
    legacyValues: [
      "Proyecto La Colonia Deshidratados",
      "Ama de casa",
      "NA",
      "Staff Auditoría y Assurance",
      "..",
      "OTHER",
      "Carrera Técnica",
      "Artesana",
      "ARQUITECTO",
      "DERECHOS HUMANOS",
      "N/A",
      "4676808552106545 eaweadsdasdasdasdasddsa",
      "Profesionista",
      "N/A",
      "Bachiller",
      "Revvo Lab",
      "Creative Specialist",
      "Business Developemt",
      "Productor",
      "Egresada en busca de oportunidades laborales",
      "No aplica",
      "Contenidos",
      "Analista de Finanzas",
      "Tecnología",
      "Salamanca, Guanajuato 36740",
      "Carrera profesional",
      "Tecnico de campo",
      "Porcicultor",
      "Product, Platform, Sales",
      "hola",
      "NLP Developer",
      "Consejera",
      "Product, Platform, Sales",
      "ARQUITECTA",
      "egresada",
      "Profesional",
      "N/A",
      "4542356527645017"
    ]
  }
];
