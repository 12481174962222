import React from "react";
import { Avatar } from "antd";
import { MissingProfileDataAlertContext } from "./context";
import { AlertSection, Visibility } from "../../../components/shared";
import { CalendarOutlined } from "@ant-design/icons";

export function MissingProfileDataAlertProvider({
  children,
  user
}) {
  const { completedProfile = true } = user || {};

  return (
    <MissingProfileDataAlertContext.Provider value={user}>
      <Visibility
        visible={
          !completedProfile && ["ENTREPRENEUR"].includes(user.systemRole)
        }>
        <AlertSection
          message="Termina tu registro"
          description={
            <p>Para seguir navegando por favor
              <strong> completa la información de tu perfil.</strong>
            </p>}
          icon={
            <Avatar
              icon={<CalendarOutlined />}
              style={{ color: "white", backgroundColor: "red" }}>
            </Avatar>
          }
          showIcon />
      </Visibility>
      {children}
    </MissingProfileDataAlertContext.Provider>
  );
}
