import React from "react";
import { ActionButton, Avatar, IconLabel } from "../../../../../shared";
import { List, message } from "antd";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { client } from "../../../../../../../graphql";
import { admin } from "../../../../../../graphql/admin";
import { shared } from "../../../../../../graphql/shared";
import { useParams } from "react-router";

export function CoordinatorItem({ user = {} }) {
  const {
    fullName = "Sin nombre",
    email = "N/A",
    phone = "N/A",
    profilePicUrl
  } = user;

  const { id } = useParams();

  const [disconnectCoordinatorFromBatch] = useMutation(
    admin.mutations.disconnectCoordinatorFromBatch,
    {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: shared.queries.getBatchCoordinators,
        variables: { id: id }
      }]
    }
  );

  const disconnectCoordinator = async userId => {
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      await disconnectCoordinatorFromBatch({
        variables: {
          batchId: id,
          userId
        }
      });
      message.success("Cambios guardados");
    } catch(error) {
      message.error("Ha ocurrido un error, inténtalo de nuevo\
        en unos segundos.");
    } finally {
      dismissLoader();
    }
  };

  return (
    <List.Item
      actions={[
        <IconLabel
          key="ent-mail"
          icon={<MailOutlined />}>
          {email}
        </IconLabel>,
        <IconLabel
          key="ent-phone"
          icon={<PhoneOutlined />}>
          {phone}
        </IconLabel>
      ]}>
      <div style={{display: "flex"}}>
        <List.Item.Meta
          avatar={<Avatar>{profilePicUrl || fullName}</Avatar>}
          title={fullName} />
        <div style={{display: "flex", flexDirection: "row-reverse"}}>
          <ActionButton
            onDelete={() => disconnectCoordinator(user?.id)}
            confirmDeleteText="Estás a punto de quitar a un Coordinador de este batch
              ¿Deseas continuar?"
            onDeleteText="Quitar" />
        </div>
      </div>
    </List.Item>
  );
}
