import React from "react";
import { Space, DatePicker } from "antd";

export function FilterControls({ onChange }) {

  const onDatesChange = dates => {
    dates = dates ?? []; // prevent null values

    let firstDate = dates[0] ?? undefined;
    let lastDate = dates[1] ?? undefined;

    firstDate = firstDate
      ?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    lastDate = lastDate
      ?.set({ hour: 23, minute: 59, second: 59, millisecond: 999 });

    onChange({
      firstDate: firstDate?.toISOString(),
      lastDate: lastDate?.toISOString()
    });
  };

  return (
    <Space>
      <DatePicker.RangePicker
        picker="month"
        onChange={onDatesChange} />
    </Space>
  );
}
