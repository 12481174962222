import gql from "graphql-tag";

export const addCommentTask = gql`
  mutation AddCommentTask(
    $id: ID,
    $comment: CommentCreateInput!
  ){
    updateTask(
      where: { id: $id }
      data: {
        comments: {
          create: [$comment]
        }
      }
    ) {
      id
    }
  }
`;