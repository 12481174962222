import { client } from "../../../graphql";
import {
  getScheduledServicesByBatchQuery
} from "./graphql/get-scheduled-services-by-batch-query";

export async function getScheduledServicesByBatch({
 types,
 batchId,
 companyId
}) {
  const result = await client.query({
    query: getScheduledServicesByBatchQuery,
    fetchPolicy: "network-only",
    variables: {
      types,
      batchId,
      companyId
    }});

  return result.data?.scheduledExpertServices || [];
}