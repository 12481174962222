import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { capitalize } from "lodash";

const cascadeDeleteAlert = (target, nested) => {
  Modal.warning({
    okText: "Ok",
    title: `${capitalize(target)} con ${nested}s`,
    icon: <ExclamationCircleOutlined />,
    content: `Antes de eliminar un ${target}\
      primero se deben de eliminar sus ${nested}s.`
  });
};

export default cascadeDeleteAlert;