export const onCareerChangeHandler = (
  teamMemberCareer,
  filters,
  filtersUpdater
) => {
  let updatedFilters = {};

  filtersUpdater(prevFilters => {
    updatedFilters = {
      ...prevFilters,
      teamMemberCareer
    };
    return updatedFilters;
  });

  return updatedFilters;
};