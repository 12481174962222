import React from "react";
import { Modal } from "antd";
import {
  CompanyGeneralForm
} from "../../../../components/shared/company-forms/general";
import {
  CompanyAboutForm
} from "../../../../components/shared/company-forms/about";
import {
  CompanyContactForm
} from "../../../../components/shared/company-forms/contact";

export const EditCompanyModal = ({
  visible,
  form,
  state,
  nameValidations,
  setState,
  type,
  loading,
  onCancel,
  onOk
}) => {

  const setLogoUrl = url => {
    setState(prev => ({
      ...prev,
      company: {
        ...prev.company,
        logoUrl: url
      }
    }));
  };

  const onAboutSwitchChange = (value, switchName) => {
    setState(prev => ({
      ...prev,
      company: {
        ...prev.company,
        [switchName]: value
      }
    }));
  };

  const setSelectedStates = states => {
    setState(prev => ({
      ...prev,
      company: {
        ...prev.company,
        presenceStates: states
      }
    }));
  };

  const setLinks = links => {
    setState(prev => ({
      ...prev,
      company: {
        ...prev.company,
        socialMedia: links
      }
    }));
  };

  const { company } = state || {};

  const companyForms = {
    "GENERAL": <CompanyGeneralForm
      form={form}
      company={company}
      setLogoUrl={setLogoUrl}
      logoUrl={state?.company?.logoUrl}
      nameValidations={nameValidations}
      initialSalesStage={state?.company?.salesStage}
      initialFiscalState={state?.company?.fiscalState} />,
    "ABOUT": <CompanyAboutForm
      form={form}
      company={company}
      companyChecks={state?.company}
      onAboutSwitchChange={onAboutSwitchChange} />,
    "CONTACT": <CompanyContactForm
      form={form}
      company={company}
      setLinks={setLinks}
      links={state?.company?.socialMedia}
      setSelectedStates={setSelectedStates}
      selectedStates={state?.company?.presenceStates} />
  };

  return (
    <Modal
      visible={visible}
      width="812px"
      onCancel={onCancel}
      onOk={onOk}
      okText="Guardar"
      cancelButtonProps={{ className: "secundary-btn" }}
      okButtonProps={{ loading }}>
      { companyForms[type] }
    </Modal>
  );
};
