/**
 * @deprecated use the content-service functions instead
 * @param userId
 * @param event
 * @param description
 */
export const formatEvent = (userId, event, description) => {
  if(event === "STATUS_CHANGE") {
    return {
      create: {
        event,
        description: `marcó como ${status[description]}`,
        blame: { connect: { id: userId } }
      }
    };
  }
  if(event === "COMMENT") {
    return {
      create: {
        event,
        description,
        blame: { connect: { id: userId } }
      }
    };
  }
};

export const status = {
  PENDING: "pendiente",
  COMPLETED: "completado",
  VERIFIED: "verificado"
};
