
export const businessModelChartOptions = {
  plugins: {
    legend: {
      position: "bottom"
    },
    datalabels: {
      color: "#000"
    }
  },
  scales: {
  },
  maintainAspectRatio: false
};
