import gql from "graphql-tag";

export const getBatchesByOdeId = gql`
  query GetBatchesByOdeId(
    $id: ID!
  ){
    batches(
      where:{ 
        odes_some: { 
          id: $id
        }
      }
    ){
      id    
      name
      iname
      batchID
      startDate
      finishDate
      description
      program {
        id
        name
        type
        coverPicUrl
      }
    }
  }
`;