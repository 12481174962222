import { registerContentDeliveryDateQuery } from "./graphql";
import { client } from "../../../graphql";
import { changeCompanyContentStatus } from "./change-company-content-status";

/**
 * @param {object} props
 * @param {string} props.userId Needed to register the event responsible
 * @param {string | undefined} props.odeContentId
 * @param {string} props.contentId
 * @param {string} props.previousDeliveryDate If present,
 * the deliveryDate wont be updated to keep the first deliveryDate
 * @param {string} props.odeId
 * @param {string} props.batchId
 * @returns {Promise<OdeContent>}
 */
export async function markCompanyContentAsDelivered({
  odeId,
  userId,
  batchId,
  contentId,
  odeContentId,
  previousDeliveryDate
}) {
  const updatedOdeContent = await changeCompanyContentStatus({
    odeId,
    userId,
    batchId,
    contentId,
    odeContentId,
    status: "COMPLETED"
  });

  if (!previousDeliveryDate)
    await registerContentDeliveryDate({ odeContentId: updatedOdeContent.id });
  return updatedOdeContent;
}

const registerContentDeliveryDate = ({ odeContentId }) => {
  return client.mutate({
    mutation: registerContentDeliveryDateQuery,
    variables: {
      odeContentId,
      deliveryDate: (new Date()).toISOString()
    }
  });
};
