import { useMutation } from "@apollo/react-hooks";
import { Form, Input, message, Modal } from "antd";
import React, { useEffect } from "react";
import { client } from "../../../../../../graphql";
import { UploadArea, UsersSelect } from "../../../../../components/shared";
import { admin } from "../../../../../graphql/admin";

export function WorkspaceModal({ onSave, onCancel, edit, ...props }) {
  const [form] = Form.useForm();

  const [createWorkspace] = useMutation(
    admin.mutations.createWorkspace,
    {
      refetchQueries: [{
        query: admin.queries.getWorkspaces
      }],
      client
    }
  );

  const [updateWorkspace] = useMutation(
    admin.mutations.updateWorkspace,
    {
      refetchQueries: [{
        query: admin.queries.getWorkspaces
      }],
      client
    }
  );

  useEffect(() => {
    if(edit) {
      if(edit.admins) {
        edit.admins = edit.admins.map(admin => admin?.user?.id || admin);
      }
      form.setFieldsValue(edit);
    }
  }, [edit]);

  const onOk = async() => {
    const dismissLoading = message.loading("Guardando...");

    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      if(values.admins.length) {
        values.memberships = {
          create: values.admins.map(
            adminId => ({
              user: { connect: { id: adminId } },
              role: "SUPER_ADMIN"
            })
          )
        };

        delete values.admins;
      }

      let newWorkspace;

      if(edit) {
        await updateWorkspace({
          variables: {
            data: {
              memberships: {
                deleteMany: [{ role: "SUPER_ADMIN" }]
              }
            },
            id: edit.id
          }
        });

        await updateWorkspace({
          variables: { data: values, id: edit.id }
        });

        message.success("Workspace guardado.");
      }
      else {
        await createWorkspace({
          variables: { data: values }
        });

        message.success("Workspace creado.");
      }

      onSave && onSave(newWorkspace);
      form.resetFields();
    }
    catch(e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    }
    finally {
      dismissLoading();
    }
  };

  const getPhotoUrl = e => {
    const coverPicUrl = e?.file?.response?.imageUrl;
    form.setFieldsValue({ coverPicUrl });
    return coverPicUrl;
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  return (
    <Modal
      destroyOnClose
      okText="Guardar"
      cancelText="Cancelar"
      title="Nuevo workpace"
      onOk={onOk}
      onCancel={onCancelModal}
      {...props}>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          name="coverPicUrl"
          getValueFromEvent={getPhotoUrl}>
          <UploadArea.Crop
            multiple={false}
            maxFiles={1}
            aspect={360 / 160}>
            Foto de portada
          </UploadArea.Crop>
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="name"
          label="Nombre">
          <Input />
        </Form.Item>
        <Form.Item
          label="Administradores"
          name="admins"
          help="Asigna los usuarios que administrarán este workspace">
          <UsersSelect systemRoles={["SUPER_ADMIN"]} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
