import { inventionRegisterOptions } from "./invention-register";
import { inventionTypeOptions } from "./invention-type";
import {
  mainTechnologyOptionAllChildren,
  mainTechnologyOptions
} from "./main-technology";
import { patentStatusOptions } from "./patent-status";
import { socialComponentOptions } from "./social-component";
import { technologyLevelStatusOptions } from "./technology-level-status";


export const newCompanyEnums = {
  inventionRegisterOptions,
  inventionTypeOptions,
  mainTechnologyOptionAllChildren,
  mainTechnologyOptions,
  patentStatusOptions,
  socialComponentOptions,
  technologyLevelStatusOptions
};
