import React from "react";
import { useQueryFilters } from "../../../helpers";

export function FilterControlsProvider({
  children,
  refetch,
  defaultFilters = {},
  onPaginationChange = null,
  afterApplyFilters = null,
  performAction = null,
  selectedRows = null,
  setSelectedRows = null
}) {
  const Filter = children;

  const { applyFilters, filters } = useQueryFilters({
    refetch,
    defaultFilters: {
      ...defaultFilters,
      first: 12
    }
  });

  const onFilterChange = filter => {
    const {
      skip = undefined,
      pageSize: first = undefined
    } = (onPaginationChange && onPaginationChange(1)) || {};

    applyFilters((prevFilters = {}) => {
      const newFilters = {
        ...prevFilters,
        ...filter,
        skip,
        first
      };
      afterApplyFilters && afterApplyFilters(newFilters);
      return newFilters;
    });
  };

  return <Filter
    selectedRows={selectedRows}
    setSelectedRows={setSelectedRows}
    performAction={performAction}
    filters={filters}
    refetch={refetch}
    onChange={onFilterChange} />;
}
