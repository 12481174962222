import React from "react";
import { Modal, Typography, Descriptions, Tag, Divider, Form, Button, Skeleton } from "antd";
import moment from "moment";
import {
  getReadableValue,
  expertServiceTypes,
  expertServiceCategories
} from "../../../../../helpers/select-options";
import {
  OdeTag,
  ContactInfo,
  Visibility,
  StatusSteps,
  LinksDisplay
} from "../../../../../components/shared";
import { DeliveryForm } from "./form";
import {
  serviceStatusEnum as STATUS
} from "../../../../../helpers/constants";

export function ServiceDeliveryModal({
  onCancel,
  onSave,
  scheduledService = {},
  onCancelRequest,
  loading = false,
  ...props
}) {
  const { assignedExpertService = {} } = scheduledService;
  const { expertServiceData = {} } = assignedExpertService;
  const { program } = assignedExpertService;
  const isWorkshop = expertServiceData?.type === "WORKSHOP";

  const [form] = Form.useForm();

  const handleOnSend = async () => {
    await form.validateFields();
    const data = form.getFieldsValue();

    await onSave({
      id: scheduledService.id,
      assignedExpertService: scheduledService?.assignedExpertService,
      ...data
    });

    handleOnCancel();
  };

  const handleOnCancel = () => {
    form.resetFields();
    onCancel();
  };

  const readableServiceType = getReadableValue(
    expertServiceTypes,
    expertServiceData.type
  );

  const showButton = scheduledService.status === STATUS.ON_GOING ||
    scheduledService.status === STATUS.DELIVERY_REJECTED;

  return (
    <Modal
      className="service-delivery-modal"
      footer={[
        <Visibility
          key="send-button-wrapper"
          visible={showButton}>
          <Button
            type="primary"
            onClick={handleOnSend}>
            {scheduledService?.status === STATUS.DELIVERY_REJECTED ?
              "Re-enviar"
              : "Enviar"}
          </Button>
        </Visibility>
        ]}
      destroyOnClose
      title={`Entrega de ${readableServiceType?.toLowerCase()}`}
      onCancel={handleOnCancel}
      {...props}>
      <Visibility visible={loading}>
        <Skeleton />
        <Skeleton />
      </Visibility>
      <Visibility visible={!loading}>
        <DeliveryForm
          scheduledService={scheduledService}
          form={form} />
        <Divider orientation="left" />
        <Typography.Title level={4}>
          {expertServiceData?.name}
        </Typography.Title>
        <Typography.Text style={{ whiteSpace: "pre-wrap" }}>
          {expertServiceData?.description}
        </Typography.Text>
        <Descriptions
          layout="vertical"
          column={1}
          style={{ marginTop: "20px" }}>
          <Descriptions.Item label="Categoría">
            <Tag color="purple">
              {getReadableValue(expertServiceCategories, expertServiceData?.category)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Número de asignación">
            {assignedExpertService?.assignmentNumber || "N/A"}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          layout="vertical"
          column={2}>
          <Descriptions.Item
            label="Fecha seleccionada">
            {scheduledService?.serviceDate ?
              moment(scheduledService?.serviceDate)
                .format("DD/MM/YYYY")
              : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Cantidad">
            {scheduledService
              .assignedExpertService
              ?.expertServiceData?.method === "HOURS" ?
              `${scheduledService.duration} horas`
              : "1 servicio"}
          </Descriptions.Item>
          {!isWorkshop &&
            <Descriptions.Item label="Compañía">
              <OdeTag odeName={assignedExpertService?.ode?.name} />
            </Descriptions.Item>}
        </Descriptions>
        <ContactInfo
          title="Coordinador del programa"
          user={program?.coordinator ||
            assignedExpertService?.batch?.coordinators?.find(Boolean)} />
        <Visibility visible={!isWorkshop}>
          <ContactInfo
            title="Emprendedor"
            user={scheduledService?.requestedBy} />
        </Visibility>
        <Visibility visible={!!scheduledService.calendarEventUri}>
          <Divider>Evento</Divider>
          <LinksDisplay
            links={[{
              fileName: "evento.ics",
              url: scheduledService.calendarEventUri || ""
            }]} />
        </Visibility>
        <Divider />
        <StatusSteps
          onCancelRequest={onCancelRequest}
          scheduledExpertService={scheduledService} />
      </Visibility>
    </Modal>
  );
}
