import gql from "graphql-tag";

export const updateOdeUpdate = gql`
  mutation updateOdeUpdate($id: ID!, $data: OdeUpdateUpdateInput!) {
    updateOdeUpdate(
      where: { id: $id }
      data: $data
    ) {
      id
      comments {
        id
        comment
        edited
        author {
          id
          fullName
          firstName
          middleName
          lastName
          profilePicUrl
        }
        createdAt
        updatedAt
      }
    }
  }
`;
