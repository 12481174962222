import gql from "graphql-tag";

export const getScheduledServicesByBatchQuery = gql`
  query getScheduledServicesByBatchQuery(
    $batchId: ID!
    $companyId: ID!
    $types: [String!]
  ) {
    scheduledExpertServices(
      where: {
        assignedExpertService: {
          status_not: "CANCELLED"
          batch: { id: $batchId }
          ode: { id: $companyId }
          expertService: {
            type_in: $types
            OR: [{ disabled: false }, { disabled: null }],
          }
        }
      }
    ) {
      id
      status
      assignedExpertService {
        id
        status
        limitDate
        assignedUnits
        availableUnits
        expertServiceData
        expertService {
          id
          expert {
            id
            fullName
          }
        }
      }
    }
  }
`;
