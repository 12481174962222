import gql from "graphql-tag";

export const GET_CUSTOM_ODE_CONTENTS_BY_BATCH = gql`
  query GET_CUSTOM_ODE_CONTENTS_BY_BATCH(
    $batchId: ID!,
    $batchOdesIds: [ID!]!
  ) {
    customOdeContents(
      where: {
        ode: {
          id_in: $batchOdesIds
        },
        batch: {
          id: $batchId
        }
      }
    ) {
      id
      ode {
        id
        name
      }
      contentGroups: contentGroups(
        where: {
          OR: [{ shouldBeDeleted: false }, { shouldBeDeleted: null }]
        }
      ) {
        id
        title
        shouldBeDeleted
        contents: contents(
          where: {
            OR: [{ shouldBeDeleted: false }, { shouldBeDeleted: null }]
          }
        ) {
          id
          name
          shouldBeDeleted
          type  
        }
      }
    }
  }
`;
