/* eslint-disable max-lines */
import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import cloneDeep from "lodash.clonedeep";
import { ContentDetailsModalContext } from "./context";
import { ContentModal } from "./components/modal";
import { contentService } from "../../../services";
import {
  ContentControllerContextProvider
} from "../content-controller-context";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql/";
import { CurrentUserContext } from "../current-user";
import { formatEvent, status } from "./components/format-event";

// @todo Simplify this component by using the GenericModalProvider instead
export function ContentDetailsModalContextProvider({
  children, refetch, odeId, batchId, showDraft = false
}) {
  const { currentUser } = useContext(CurrentUserContext);

  const [state, setState] = useState({
    isModalOpen: false,
    contentId: undefined,
    content:  {},
    currentIndex: 0,
    contentList: [],
    contentGroup: {},
    loading: false
  });

  const [upsertOdeContent] = useMutation(
    shared.mutations.upsertOdeContent,
    { client }
  );

  const getContent = async id => {
    try {
      const { data } = await contentService.getById(id);
      return data?.content;
    } catch(e) {
      console.error(e);
      onClose();
    }
  };

  const getContentWithOdeContent = async (id, odeId) => {
    try {
      const { data } = await contentService.getByIdWithOdeContent(id, odeId);
      return data?.content;
    } catch(e) {
      console.error(e);
      onClose();
    }
  };

  const onOpen = async (id, index, contentList, contentGroup, odeContents) => {
    let odeContent;
    if(odeContents) {
      if(odeContents.length === 0) {
        odeContent = undefined;
      } else {
        odeContent = odeContents[0];
      }
    } else {
      odeContent = null;
    }
    setState(state => ({
      ...state,
      isModalOpen: true,
      loading: true,
      currentIndex: index,
      contentList: contentList.map(content => ({ id: content.id })),
      odeContent,
      contentGroup
    }));


    if (id) {
      let content = await getContent(id);
      if(showDraft) {
        content = {
          ...content,
          ...content?.draft || {}
        };
      }
      setState(state => ({
        ...state,
        contentId: id,
        content,
        loading: false
      }));
    }
  };

  const onClose = () => {
    setState({
      isModalOpen: false,
      contentId: undefined,
      content:  {},
      currentIndex: 0,
      contentGroup: {},
      contentList: [],
      loading: false
    });
  };

  const changeContentStatus = async selectedStatus => {
    const dismissLoading = message.loading("Guardando...");
    try {
      const data = {
        odeId,
        userId: currentUser.id,
        batchId,
        contentId: state.contentId,
        odeContentId: state.odeContent?.id,
        previousDeliveryDate: state.odeContent?.deliveryDate
      };

      let updatedOdeContent = undefined;
      if (selectedStatus === "COMPLETED") {
        updatedOdeContent =
          await contentService.markCompanyContentAsDelivered(data);
      } else {
        updatedOdeContent = await contentService.changeCompanyContentStatus({
          ...data,
          status: selectedStatus
        });
      }

      await refetch();
      setState(p => {
        return({
          ...p,
          odeContent: {
            id: updatedOdeContent.id,
            ...p.odeContent,
            events: [
              ...p.odeContent?.events || [],
              {
                id: "NEW_STATUS_CHANGE",
                event: "STATUS_CHANGE",
                description: `marcó como ${status[selectedStatus]}`,
                createdAt: new Date().toISOString(),
                blame: {
                  id: currentUser.id,
                  fullName: currentUser.fullName,
                  profilePicUrl: currentUser.profilePicUrl
                }
              }
            ]
          }
        });
      });
    } catch (e) {
      console.error(e);
      message.error("Se produjo un error. Inténtelo de nuevo.");
      setState({
        isModalOpen: true,
        contentId: undefined,
        content:  {},
        currentIndex: 0,
        contentGroup: {},
        contentList: [],
        loading: false
      });
    } finally {
      message.success("Guardado.");
      dismissLoading();
    }
  };

  const sendComment = async value => {
    const dismissLoading = message.loading("Guardando...");
    try {
      const updatedOdeContent = await upsertOdeContent({
        variables: {
          id: state.odeContent?.id || "",
          create: {
            ode: { connect: { id: odeId } },
            content: { connect: { id: state.contentId } },
            batch: { connect: { id: batchId } },
            events: formatEvent(currentUser.id, "COMMENT", value)
          },
          update: {
            events: formatEvent(currentUser.id, "COMMENT", value)
          }
        }
      });
      const odeContentId = updatedOdeContent.data.upsertOdeContent.id;
      await refetch();
      setState(state => ({
        ...state,
        odeContent: {
          id: odeContentId,
          ...state.odeContent,
          events: [
            ...state.odeContent?.events || [],
            {
              id: "NEW_COMMENT",
              event: "COMMENT",
              description: value,
              createdAt: new Date().toISOString(),
              blame: {
                id: currentUser.id,
                fullName: currentUser.fullName,
                profilePicUrl: currentUser.profilePicUrl
              }
            }
          ]
        }
      }));
    } catch (e) {
      console.error(e);
      message.error("Se produjo un error. Inténtelo de nuevo.");
      setState({
        isModalOpen: false,
        contentId: undefined,
        content:  {},
        currentIndex: 0,
        contentGroup: {},
        contentList: [],
        loading: false
      });
    } finally {
      message.success("Guardado.");
      dismissLoading();
    }
  };

  const sendDeliverables = async (deliverables, event) => {
    const dismissLoading = message.loading("Guardando...");
    try {
      const updatedOdeContent = await upsertOdeContent({
        variables: {
          id: state.odeContent?.id || "",
          create: {
            ode: { connect: { id: odeId } },
            content: { connect: { id: state.contentId } },
            batch: { connect: { id: batchId } },
            deliverables: { create: deliverables },
            events: formatEvent(currentUser.id, "COMMENT", event)
          },
          update: {
            deliverables: { create: deliverables },
            events: formatEvent(currentUser.id, "COMMENT", event)
          }
        }
      });
      const odeContentId = updatedOdeContent.data.upsertOdeContent.id;
      await refetch();
      const newComment = {
        id: "NEW_COMMENT",
        event: "COMMENT",
        description: event,
        createdAt: new Date().toISOString(),
        blame: {
          id: currentUser.id,
          fullName: currentUser.fullName,
          profilePicUrl: currentUser.profilePicUrl
        }
      };
      setState(state => ({
        ...state,
        odeContent: state?.odeContent ? {
          id: odeContentId,
          ...state?.odeContent,
          deliverables: [
            ...deliverables,
            ...(state?.odeContent?.deliverables || [])
          ],
          events: [
            ...state.odeContent?.events || [],
            newComment
          ]
        } : {
          id: odeContentId,
          deliverables,
          events: [newComment]
        }
      }));
    } catch (e) {
      console.error(e);
      message.error("Se produjo un error. Inténtelo de nuevo.");
      setState({
        isModalOpen: false,
        contentId: undefined,
        content:  {},
        currentIndex: 0,
        contentGroup: {},
        contentList: [],
        loading: false
      });
    } finally {
      message.success("Guardado.");
      dismissLoading();
    }
  };

  const onControllerNext = async () => {
    const currentIndex = cloneDeep(state.currentIndex);
    setState(state => ({
      ...state,
      currentIndex: currentIndex + 1,
      loading: true
    }));
    const newContentId = state.contentList[currentIndex + 1].id;
    try {
      let content;
      let odeContent = state.odeContent;

      if([null, undefined].includes(odeContent)) {
        content = await getContent(newContentId);
        if(showDraft) {
          content = {
            ...content,
            ...content?.draft || {}
          };
        }
      } else {
        content = await getContentWithOdeContent(newContentId, odeId);
      }

      if(content?.odeContents?.length) {
        odeContent = content?.odeContents?.[0];
      } else {
        odeContent = undefined;
      }

      setState(state => ({
        ...state,
        contentId: newContentId,
        content,
        odeContent,
        loading: false
      }));
    } catch (e){
      console.error(e);
      message.error("Se produjo un error. Inténtelo de nuevo.");
      setState({
        isModalOpen: false,
        contentId: undefined,
        content:  {},
        currentIndex: 0,
        contentGroup: {},
        contentList: [],
        loading: false
      });
    }
  };

  const onControllerPrev = async () => {
    const currentIndex = cloneDeep(state.currentIndex);
    setState(state => ({
      ...state,
      currentIndex: currentIndex - 1,
      loading: true
    }));
    const newContentId = state.contentList[currentIndex - 1].id;
    try {
      let content;
      let odeContent = state.odeContent;
      if([null, undefined].includes(odeContent)) {
        content = await getContent(newContentId);
        if(showDraft) {
          content = {
            ...content,
            ...content?.draft || {}
          };
        }
      } else {
        content = await getContentWithOdeContent(newContentId, odeId);
      }
      if(content?.odeContents?.length) {
        odeContent = content?.odeContents?.[0];
      } else {
        odeContent = undefined;
      }
      setState(state => ({
        ...state,
        contentId: newContentId,
        content,
        odeContent,
        loading: false
      }));
    } catch (e){
      console.error(e);
      message.error("Se produjo un error. Inténtelo de nuevo.");
      setState({
        isModalOpen: false,
        contentId: undefined,
        content:  {},
        currentIndex: 0,
        contentGroup: {},
        contentList: [],
        loading: false
      });
    }
  };

  return(
    <ContentDetailsModalContext.Provider value={{ onOpen, onControllerNext }}>
      <ContentControllerContextProvider
        onControllerNext={onControllerNext}
        onControllerPrev={onControllerPrev}
        controllerState={{
          currentIndex: state.currentIndex,
          contentList: state.contentList }}>
        <ContentModal
          contentGroup={state.contentGroup}
          content={state.content}
          odeContent={state.odeContent}
          loading={state.loading}
          onClose={onClose}
          visible={state.isModalOpen}
          changeContentStatus={changeContentStatus}
          sendDeliverables={sendDeliverables}
          sendComment={sendComment} />
        { children }
      </ContentControllerContextProvider>
    </ContentDetailsModalContext.Provider>
  );

}
