import React, { useContext } from "react";
import { List, Typography } from "antd";
import { withLoader, paginationConfig } from "../../../../../helpers";
import ObjectiveItem from "./item";
import {
  ObjectiveModalContext
} from "../../../../../contexts/shared/objective-modal";
import { useParams } from "react-router";
import { Section, AddButton } from "../../../../shared";

function ObjectivesList({ loading, data, ...props }) {
  const { openModal } = useContext(ObjectiveModalContext);
  const { id } = useParams();

  return (
    <Section
      title="Objetivos"
      spaced>
      <Typography.Text>
        Agrega los objetivos a cumplir a través del programa, considera los
        objetivos como el resultado más grande de la Compañía durante el transcurso
        de este tiempo dentro de EOS
      </Typography.Text>
      <AddButton
        onClick={() => openModal(id)}
        style={{width: "100%", marginTop: "20px"}}>
        Agregar objetivo
      </AddButton>
      <List
        style={{marginTop: "20px"}}
        loading={loading}
        itemLayout="horizontal"
        dataSource={data.objectives}
        pagination={paginationConfig}
        renderItem={i =>
          <ObjectiveItem
            objective={i} />
        }
        {...props} />
    </Section>
  );
}

export default withLoader(ObjectivesList);
