import React from "react";
import {
  ServiceDetailModalContext,
  ServiceDetailModalProvider
} from "../../../contexts/shared";

export const TableWrapperAssignationServiceProvider = ({ children }) => (
  <ServiceDetailModalProvider refetch={() => null}>
    <ServiceDetailModalContext.Consumer>
      {({ openModal }) => (
        React.cloneElement(children, { openModal })
      )}
    </ServiceDetailModalContext.Consumer>
  </ServiceDetailModalProvider>
);