import React, { useContext } from "react";
import { Menu } from "antd";
import { MenuTitle } from "../../../shared";
import { BookOutlined, StarOutlined } from "@ant-design/icons";
import { withRouter } from "../../../../helpers";
import { MenuContext } from "../../../../contexts/shared";

function MainMenu({ navigate }) {
  const { openKeys, selectedKeys } = useContext(MenuContext);

  return (
    <Menu
      mode="inline"
      defaultOpenKeys={["experts/profile"]}
      openKeys={openKeys}
      selectedKeys={selectedKeys}>
      <Menu.SubMenu
        title={<MenuTitle text="Expertos" icon={<BookOutlined />} />}
        key="experts">
        <Menu.Item
          key="experts/profile"
          onClick={() => navigate("/v2/expert/me/profile")}>
          Perfil
        </Menu.Item>
        <Menu.Item
          key="experts/services/monitoring"
          onClick={() => navigate("/v2/expert/services/monitoring")}>
          Monitoreo
        </Menu.Item>
        <Menu.Item
          key="experts/monitoring"
          onClick={() => navigate("/v2/expert/monitoring/assignations")}>
          Monitoreo (Beta)
        </Menu.Item>
        <Menu.Item
          key="experts/services"
          onClick={() => navigate("/v2/expert/services")}>
          Productos
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item
        key="experts/odes"
        onClick={() => navigate("/v2/expert/odes")}>
        <MenuTitle text="Mis compañías" icon={<StarOutlined />} />
      </Menu.Item>
    </Menu>
  );
}

export default withRouter(MainMenu);
