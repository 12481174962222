/**
 * @todo Move this to a shared patH because it is used in multiple places
 */
export const financingStageOptions = [
  { label: "Bootstrapping", value: "BOOTSTRAPPING" },
  { label: "Pre-Seed", value: "PRE-SEED" },
  { label: "Seed", value: "SEED" },
  { label: "A", value: "A" },
  { label: "B", value: "B" },
  { label: "C+", value: "C+" },
  { label: "Pública", value: "PUBLIC" },
  { label: "Establecida", value: "ESTABLISHED" }
];
