import React from "react";
import { PageHeader, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const HeaderBreadcrumbs = ({ title, name, onPrev = null, ...props }) => {
  return (
    <PageHeader
      {...props}
      backIcon={<ArrowLeftOutlined />}
      onBack={onPrev}
      subTitle={<Typography.Text>
        { title } / <strong>{ name }</strong>
      </Typography.Text>} />
  );
};
