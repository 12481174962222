import React from "react";
import { Card, Space, Progress, Result, Button } from "antd";
import { withRouter } from "../../../../helpers";

function Success({ navigate }) {

  const onNavigate = () => {
    navigate("/v2/coordinator/batches");
  };

  return (
    <Card bordered={false} style={{maxWidth: "600px", margin: "0 auto"}}>
      <Space direction="vertical" size="large">
        <Progress percent={100} showInfo={false} />
        <>
          <Result
            status="success"
            title="¡Yay! ¡Has creado tu cuenta con éxito!"
            extra={
              <Button
                onClick={onNavigate}
                type="primary">
                Explorar EOS
              </Button>
            } />
        </>
      </Space>
    </Card>
  );
}

export default withRouter(Success);
