import React, { useContext } from "react";
import { InvitationModalContext } from "../../../../../contexts/shared";
import { default as BatchPublishInformation } from "./loader-wrapper";

export const BatchPublishInformationIndex = ({
  data,
  refetchBatch,
  masterContents,
  errorMasterContent,
  loadingMasterContent
}) => {
  const { openModal: openModalInvitation } = useContext(InvitationModalContext);

  return (
    <BatchPublishInformation
      batch={data?.batch}
      data={masterContents}
      error={errorMasterContent}
      isLoading={loadingMasterContent}
      openModalInvitation={openModalInvitation}
      hideAlertContent={true}
      showAddTeamButton={true}
      refetchBatch={refetchBatch} />
  );
};
