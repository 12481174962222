import gql from "graphql-tag";

export const deleteObjectiveManyAchievements = gql`
  mutation DeleteObjectiveManyAchievements($objectiveId: ID!, $achievementsIds: [ID!]!) {
    updateObjective(
      where: { id: $objectiveId }
      data: {
        achievements: {
          deleteMany: {
            id_in: $achievementsIds
          }
        }
      }
    ) {
      id
    }
  }
`;
