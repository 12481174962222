import { client } from "../../../graphql";
import { getByIdQuery } from "./graphql";

async function getById ({ id }) {
  const result = await client.query({
    query: getByIdQuery,
    fetchPolicy: "network-only",
    variables: {
      id
    }
  });

  return result?.data?.assignedExpertService || null;
}

export const AssignedExpertServiceService = {
  getById
};
