import React from "react";
import { Pie } from "@ant-design/charts";
import { getReadableValue, odeTecnologyLevels } from "../../../../../../helpers/select-options";

export function TechnologyTypeChart({ odes = [] }) {
  const data = odes.reduce((acc = [], ode) => {
    const idx = acc // the tech-type should be included only once
      .findIndex(item => item.technologyLevel === ode.technologyLevel);

    if (idx < 0) { // it's the first tech-type
      ode.odesCount = 1;
      acc.push(ode);
    } else { // increase counter if it's the same tech-type again
      const item = acc[idx];
      acc[idx] = { ...item, odesCount: item.odesCount + 1 };
    }

    return acc;
  }, [])
  .map(item => ({
    technologyLevel: getReadableValue(odeTecnologyLevels, item.technologyLevel),
    percentage: ((item.odesCount * 100) / odes.length)
  }));

  const config = {
    appendPadding: 10,
    data: data,
    angleField: "percentage",
    colorField: "technologyLevel",
    radius: 1,
    innerRadius: 0.64,
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    meta: {
      percentage: {
        formatter: function formatter(percentage) {
          return `${percentage?.toFixed(2)} %`;
        }
      }
    },
    label: {
      type: "inner",
      offset: "-50%",
      style: { textAlign: "center" },
      autoRotate: false,
      content: "{percentage}"
    },
    statistic: {
      title: false,
      content: {
        formatter: () => `Compañías \n totales: \n ${odes.length}`,
        style: {
          fontSize: 20,
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }
      }
    }
  };

  return <Pie {...config} />;
}
