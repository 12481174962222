import React, { useContext } from "react";
import moment from "moment";
import Text from "antd/es/typography/Text";
import { Column } from "@ant-design/charts";
import { Avatar, Card, Image, Table, Typography } from "antd";
import { COLORS_TAGS } from "../../../../../helpers";
import { FlexContainer, TagList } from "../../../../shared";
import { OrganizationTableLabels } from "./popover-handler";
import { OrganizationTableServiceLabels } from "./service-label-handler";
import { OrganizationModalContext } from "../../../../../contexts/shared";
import {InfoCircleOutlined} from "@ant-design/icons";

export const OrganizationsEnvironmentMapTable = ({
   organizations = [],
   loading
}) => {

  const { openModal } = useContext(OrganizationModalContext);

  const onRowClick = organization => ({
    onClick: () => {openModal(organization.id)},
    className: "clickable"
  })

  return (
    <Card
      className="asem__OrganizationsEnvironmentMap-Table"
      style={{marginTop: "2rem"}}
      title={
        <FlexContainer
          direction="vertical">
          <Typography.Title
              level={5}>
            Organizaciones registradas
          </Typography.Title>
          <Typography.Text
              type={"secondary"}
              style={{ fontSize: "12px" }}>
            <InfoCircleOutlined /> Haz click en una organización para ver más detalles
          </Typography.Text>
        </FlexContainer>
      }>
      <Table
        style={{
          width: "100%",
          height: 700}}
        scroll={{ y: 620, x: true}}
        pagination={false}
        loading={loading}
        onRow={onRowClick}
        dataSource={organizations}>
        <Column
          title="Nombre"
          width={300}
          sorter={(a, b) => {
            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }
            return 0;
          }}
          render={({ name, logo }) =>
            <FlexContainer
              yAlign="center"
              direction="horizontal"
              style={{ gap: "1rem" }}>
              <Avatar
                shape={"square"}
                src={
                  <Image
                    src={logo}
                    preview={false}
                    style={{ objectFit: "cover" }
                } />} />
              <Text>{name}</Text>
            </FlexContainer>
        } />
        <Column
          width={130}
          sorter={(a, b) => new Date(a.startedAt) - new Date(b.startedAt)}
          title="Fecha de fundación"
          render={({ startedAt }) => moment(startedAt).format("YYYY")} />
        {/*//The data for this column has not been given.*/}
        {/*<Column*/}
        {/*  width={100}*/}
        {/*  title="Tipo de actor"*/}
        {/*  render={({ private: isPrivate }) =>*/}
        {/*    isPrivate*/}
        {/*      ? "-"*/}
        {/*      : "-"} />*/}
        <Column
          title="Origen"
          sorter={(a, b) => Number(a.private) - Number(b.private)}
          render={({ private: isPrivate }) =>
            isPrivate
              ? "Privado"
              : "Público"} />
        <Column
          width={250}
          title="Etapas en las que apoya"
          render={({ stages }) =>
            <TagList
              labelMaxLength={10}
              className="tags"
              maxCount={2}
              colors={COLORS_TAGS}
              mapColors={true}
              tags={stages} /> } />
        <Column
          width={200}
          title="Presencia"
          render={({ states, nationalPresence }) =>
            nationalPresence
              ? "Nacional"
              : <OrganizationTableLabels states={states} />
              } />
        <Column
          title="Servicio"
          render={({ services }) =>
            <OrganizationTableServiceLabels services={services} /> } />
      </Table>
    </Card>
  );
};
