import React from "react";
import { List, Typography } from "antd";
import { BookOutlined } from "@ant-design/icons";
import { Avatar } from "../../../../../../shared";

export function ContentItem({ extra, content }) {
  const { fileName = "Sin nombre", url = "Sin URL" } = content;

  return (
    <List.Item extra={extra}>
      <List.Item.Meta
        avatar={
          <Avatar color="#FA541C">
            <BookOutlined />
          </Avatar>
        }
        title={fileName}
        description={
          <Typography.Link href={url} target="_blank">
            { url }
          </Typography.Link>
        } />
    </List.Item>
  );
}
