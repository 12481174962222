import React from "react";
import { Tooltip } from "antd";

export function InputTooltip({ title, children, ...props }) {
  return (
    <Tooltip
      trigger={["focus"]}
      title={title}
      placement="bottomLeft"
      overlayClassName="input-tooltip"
      {...props}>
      { children }
    </Tooltip>
  );
}
