import React from "react";

export const TextTag = ({ options, children }) => {

  const data = options.find(i => i.value === children);

  if(!data)
    return null;

  const { color, label } = data;

  return (
    <span
      style={{ 
        color,
        fontSize: "10px"}}>   
      {label}
    </span>
  )
}
