import gql from "graphql-tag";

export const getAvailableUsersToAddToCompany = gql`
  query GetAvailableUsersToAdd(
    $systemRole: SystemRole!
    $odeId: ID!
  ) {
    users(
      where: {
        systemRole: $systemRole
        teamOdes_none: {
          id: $odeId
        }
      }
    ) {
      id
      fullName
      teamOdes {
        id
      }
    }
  }
`;
