import gql from "graphql-tag";

/**
 * Fetches the Batches in which the ODE specified by ID is involved with
 */
export const getOdeInvolvedBatches = gql`
  query GetOdeInvolvedBatches($id: ID!) {
    batches (
      where: {
        odes_some: { id: $id  }
      }
    ) {
      id
      name
    }
  }
`;
