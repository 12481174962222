import gql from "graphql-tag";

export const addCompanyTeamMembers = gql`
  mutation ConnectTeamMemberToOde(
    $odeId: ID!
    $teamIdList: [UserWhereUniqueInput!]
    $requestedBy: String
   ) {
    addOdeTeamMembers(
      where: { id: $odeId }
      data: {
        teamIdList: $teamIdList
        requestedBy: $requestedBy
      }
    ) {
      count
    }
  }
`;
