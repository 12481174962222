import React, { useContext } from "react";
import { Modal, Typography, Descriptions, Tag, Divider, Skeleton } from "antd";
import moment from "moment";
import {
  getReadableValue,
  expertServiceTypes,
  expertServiceCategories
} from "../../../../../../helpers/select-options";
import {
  CurrentUserContext
} from "../../../../../../contexts/shared/current-user";
import { ContactInfo, LinksDisplay, OdeTag, Format, Visibility } from "../../../../../shared";
import { ModalButtons } from "./modal-buttons";
import { useDecorator } from "../../../../../../helpers/use-decorator/index";

export function ServiceAcceptanceModal({
  onCancel,
  onSave,
  onReject,
  scheduledService = {},
  loading = false,
  ...props
}) {

  const { currentUser } = useContext(CurrentUserContext);
  let { assignedExpertService = {} } = scheduledService;
  const { expertServiceData = {} } = assignedExpertService;

  assignedExpertService = useDecorator(
    "assignedExpertService", assignedExpertService);

  const handleOnOk = async () => {
    try {
      await onSave({
        scheduledServiceId: scheduledService.id,
        entrepreneurId: currentUser.id
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnCancel = () => {
    onCancel();
  };

  const readableServiceType = getReadableValue(
      expertServiceTypes,
      expertServiceData.type
    );

  const handleOnReject = async () => {
    onReject && await onReject({
      scheduledServiceId: scheduledService.id,
      entrepreneurId: currentUser.id
    });
  };

  return (
    <Modal
      className="service-acceptance-modal"
      onCancel={handleOnCancel}
      destroyOnClose
      title={`Entrega de ${readableServiceType.toLowerCase()}`}
      footer={
        <ModalButtons
          loadingData={loading}
          onReject={!assignedExpertService.isWorkshop && handleOnReject}
          onOk={!assignedExpertService.isWorkshop && handleOnOk} />}
      {...props}>
      <Visibility visible={loading}>
        <Skeleton />
        <Skeleton />
      </Visibility>
      <Visibility visible={!loading}>
        <Typography.Title level={4}>
          {assignedExpertService?.serviceTitle}
        </Typography.Title>
        <p style={{ whiteSpace: "pre-wrap" }}>
          {assignedExpertService?.expertServiceData?.description}
        </p>
        <Descriptions
          layout="vertical"
          column={1}
          style={{ marginTop: "20px" }}>
          <Descriptions.Item label="Categoría">
            <Tag color="purple">
              {getReadableValue(expertServiceCategories, expertServiceData?.category)}
            </Tag>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          layout="vertical"
          column={2}>
          <Descriptions.Item
            label={`Fecha de ${readableServiceType.toLowerCase()}`}>
            {scheduledService?.serviceDate ?
              moment(scheduledService?.serviceDate)
                .format("DD/MM/YYYY")
              : "N/A"}
          </Descriptions.Item>
          <Descriptions.Item label="Fecha de realización">
            <Format type="date">{scheduledService.deliveredAt}</Format>
          </Descriptions.Item>
          <Descriptions.Item label="Cantidad">
            {scheduledService.method === "HOURS" ?
              `${scheduledService.duration} horas`
              : "1 servicio"}
          </Descriptions.Item>
          { assignedExpertService?.expertServiceData?.type !== "WORKSHOP" &&
            <Descriptions.Item label="Compañía">
              <OdeTag odeName={assignedExpertService?.ode?.name} />
            </Descriptions.Item>}
        </Descriptions>
        <ContactInfo
          title="Coordinador del programa"
          user={
            assignedExpertService?.program?.coordinator ||
            assignedExpertService?.batch?.coordinators?.find(Boolean)} />
        <ContactInfo
          title={`Experto: ${assignedExpertService
            ?.expertService?.expert?.fullName}`}
          user={assignedExpertService?.expertService?.expert} />
        <Visibility visible={scheduledService.calendarEventUri}>
          <Divider orientation="left">
            Evento
          </Divider>
          <LinksDisplay
            links={[{
              fileName: "evento.ics",
              url: scheduledService.calendarEventUri || ""
            }]} />
        </Visibility>
        <Divider orientation="left">
          Resultados
        </Divider>
        <LinksDisplay links={scheduledService?.attachments} />
        <Divider orientation="left">
          Enlaces
        </Divider>
        <LinksDisplay links={scheduledService?.links} />
        { scheduledService?.expertComments &&
          <Descriptions
            layout="vertical"
            style={{ marginTop: "20px" }}>
            <Descriptions.Item label="Comentarios del experto">
              {scheduledService?.expertComments}
            </Descriptions.Item>
          </Descriptions> }
      </Visibility>
    </Modal>
  );
}
