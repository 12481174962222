import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Alert, Button, message, Modal } from "antd";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql";
import { Visibility } from "../../..";

/**
 * @param {object} props
 * @param {string} props.batchId
 * @param {object} props.masterContent
 * @param {function} props.afterPublishContentChanges Use instead of this
 * callback refetchQueries because using the useQuery hook hides the
 * collaborators it needs to work fine, which makes maintenance work a bit
 * harder
 */
export const PublishButton = ({
  batchId,
  masterContent,
  afterPublishContentChanges
}) => {

  const [publishMasterContent] = useMutation(
    shared.mutations.publishMasterContent, {
    client
  }
  );

  const [applyContentDraft] = useMutation(
    shared.mutations.applyContentDraft, {
    client
  }
  );

  const canPublish = masterContent => {
    const isMasterContentPublished =
      masterContent.publishedContents?.length > 0;
    const contentGroups = masterContent.contentGroups || [];

    for (const section of contentGroups) {
      if (section.creationConfirmed) return true;
      if (section.shouldBeDeleted) return true;
      if (section.draft) return true;
      const { contents = [] } = section;
      for (const content of contents) {
        if (content.creationConfirmed) return true;
        if (content.shouldBeDeleted) return true;
        if (content.draft) return true;
      }
    }

    if (!isMasterContentPublished) return true;
    return false;
  };

  const publishContent = async () => {
    const dismissLoading = message.loading("Cargando");
    try {
      if (masterContent.publishedContents?.length > 0) {
        const contentGroups = masterContent.contentGroups || [];
        const where = contentGroups.map(section => ({ id: section.id }));
        // @todo Remove the useMutation and useQuery hooks implementation after.
        // Use useService hook instead.
        await applyContentDraft({
          variables: { where }
        });
      } else {
        await publishMasterContent({
          variables: {
            batchId,
            masterContentId: masterContent.id
          }
        });
      }

      afterPublishContentChanges && await afterPublishContentChanges();
    } catch (error) {
      message.error("Ha ocurrido un error, inténtalo de nuevo\
        en unos segundos.");
    } finally {
      dismissLoading();
    }
  };

  const onPublishContent = () => {
    Modal.confirm({
      okText: "Publicar",
      cancelText: "Cancelar",
      title: "Publicar",
      icon: null,
      content: `Al publicar tu contenido será visible para todas las compañías. \n
                Si quieres hacer cambios al contenido de una compañía especifica, ve a la pestaña compañías`,
      onOk: () => publishContent()
    });
  };

  return (
    <>
      <Visibility visible={canPublish(masterContent)}>
        <Alert
          message="Has modificado el contenido, estos cambios serán visibles al publicar."
          type="warning"
          showIcon
          style={{ marginBottom: "5px" }} />
      </Visibility>
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          margin: "1rem 0"
        }}>
        <Button
          type="primary"
          disabled={!canPublish(masterContent)}
          onClick={onPublishContent}>
          {canPublish(masterContent) ? "Publicar Contenido" : "Publicado"}
        </Button>
      </div>
    </>
  );
};
