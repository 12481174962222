import React, { useState, useEffect } from "react";
import { Table } from "antd";

export function DoubleScrollbarTable({ width, height, style = {}, ...props }) {
  const [tableWidth, setTableWith] = useState(0);

  const topScrollerContainer = document.querySelector(".scrollbar-container");
  const table = document.querySelector(".ant-table-content table");
  const tableContainer = document.querySelector(".ant-table-content");

  useEffect(() => {
    if (table) {
      setTableWith(table.offsetWidth);
      tableContainer.addEventListener("scroll", () => {
        topScrollerContainer.scrollTo(tableContainer.scrollLeft, 0);
      });
    }

    if (topScrollerContainer) {
      topScrollerContainer.addEventListener("scroll", () => {
        tableContainer.scrollTo(topScrollerContainer.scrollLeft, 0);
      });
    }

    return () => {
      if (table && topScrollerContainer) {
        tableContainer.removeEventListener("scroll", () => false);
        topScrollerContainer.removeEventListener("scroll", () => false);
      }
    };
  }, [props.dataSource, props.loading]);

  return (
    <div style={{ width, height, ...style }}>
      <div
        className="scrollbar-container"
        style={{
          width: "100%",
          overflowX: "auto",
          overflowY: "hidden",
          height: "20px"
        }}>
        <div
          className="top-scrollbar"
          style={{ minHeight: "20px", width: tableWidth }} />
      </div>
      <Table
        scroll={{ x: true }}
        style={{ height: "100%" }}
        {...props} />
    </div>
  );
}
