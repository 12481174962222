export const formatValues = (values, states) => {
  const iname = values.name.toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const iservices = values.services.join(",").toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const istages = values.stages.join(",").toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
  const startedAt =
    values.startedAt && new Date(values.startedAt).toISOString();

  return {
    ...values,
    status: "REVIEW_REQUESTED",
    logo: values.logo.url,
    iname,
    services: { set: values.services },
    iservices,
    stages: { set: values.stages },
    istages,
    startedAt,
    states: {
      create: states.map(state => ({
        ...state,
        cities: { set: state.cities }
      }))
    }
  };
};