import React, { useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Card, Typography } from "antd";
import {
  useFoundationYearChart
} from "../../../../../hooks/organizations/charts/use-foundation-year-chart";
import { FlexContainer } from "../../../../shared";
import { InfoCircleOutlined } from "@ant-design/icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const FoundationYearChart = ({ organizations, onClick }) => {
  const { chartData, labels } = useFoundationYearChart(organizations);

  const chartRef = useRef();

  const handleOnClick = event => {
    const [chartElement] = getElementAtEvent(chartRef.current, event);
    const selectedYear = labels[chartElement.index];

    const organizationsBySelectedYear = organizations?.filter(organization => {
      const foundationYear = new Date(organization.startedAt).getFullYear();
      return foundationYear === selectedYear;
    });

    onClick({
      selectedYear,
      organizations: organizationsBySelectedYear
    });
  };

  const options = {
    plugins: {
      legend: {
        position: "bottom"
      },
      datalabels: {
        color: "#000",
        labels: {
          title: {
            text: "Servicios por etapa"
          }
        }
      }
    },
    maintainAspectRatio: false,
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Año de fundación",
        data: chartData,
        backgroundColor: "#722ED1",
        barThickness: 50
      }
    ]
  };

  return (
    <Card
      style={{
        marginTop: "2rem",
        height: "500px"
      }}
      title={
        <FlexContainer
            direction="vertical">
            <Typography.Title
                level={5}>
                Organizaciones por año de fundación (últimos 10 años)
            </Typography.Title>
            <Typography.Text
                type={"secondary"}
                style={{ fontSize: "12px" }}>
                <InfoCircleOutlined />  Haz click en cualquier barra para ver las organizaciones fundadas en cada año
            </Typography.Text>
        </FlexContainer>
      }>
      <Bar
        style={{
          cursor: "pointer"
        }}
        data={data}
        type={"bar"}
        height={400}
        ref={chartRef}
        onClick={handleOnClick}
        options={options} />
    </Card>
  );
};
