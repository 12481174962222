import React, { useMemo } from "react";
import { Select } from "antd";
import { useChartsDataContext } from "../../charts-data-provider";
import {
  getReadableValue
} from "../../../../../helpers";

export const CareersSelector = props => {
  const { careers, loadingCareers } = useChartsDataContext();

  const readableCareers = useMemo(() => {
    return careers?.map(rawCareerValue => ({
      value: rawCareerValue,
      label: getReadableValue("CAREERS", rawCareerValue, null)
    })) || [];
  }, [careers]);

  return (
    <Select
      {...props}
      placeholder="Carrera"
      loading={loadingCareers}
      disabled={careers?.length < 1}
      options={readableCareers} />
  );
};
