import gql from "graphql-tag";

export const markNotificationsAsReadByUserId = gql`
  mutation MarkNotificationsAsReadByUserById($id: ID!) {
    updateManyNotifications(
      where: { user: { id: $id } },
      data: { read: true }
    ) {
      count
    }
  }
`;
