export const scheduledServicesStatus = [
  { label: "Aceptado por la Compañía", value: "ACCEPTED" },
  { label: "Agendado", value: "SCHEDULED" },
  { label: "Cancelado", value: "CANCELLED" },// accepted by expert, and then an user cancelled it
  { label: "Entrega rechazada", value: "DELIVERY_REJECTED" }, // entrepreneur rejects the delivery
  { label: "Entregado", value: "DELIVERED" },
  { label: "Pago autorizado", value: "AUTHORIZED_FOR_PAYMENT" },
  { label: "Pago en proceso", value: "PAYMENT_IN_PROCESS" },
  { label: "Pagado", value: "PAID" },
  { label: "Rechazado", value: "REJECTED" }, // expert rejects the service request
  { label: "Solicitud aceptada", value: "ON_GOING" }
];

export const assignedServicesStatus = [
  { label: "Asignado", value: "ASSIGNED" },
  // { label: "Asignación vencida", value: "EXPIRED" },
  { label: "Asignación cancelada", value: "CANCELLED" }
];

export const whiteListSupportesStatusInBatch = [
  "ACCEPTED", "AUTHORIZED_FOR_PAYMENT", "PAYMENT_IN_PROCESS"
];

export const scheduledSupportedStatusInBatch = {
  "ACCEPTED": {
    label: "Cambiar estatus a pago autorizado",
    status: "AUTHORIZED_FOR_PAYMENT"
  },
  "AUTHORIZED_FOR_PAYMENT": {
    label: "Cambiar estatus a pago en proceso",
    status: "PAYMENT_IN_PROCESS"
  },
  "PAYMENT_IN_PROCESS": {
    label: "Cambiar estatus a pagado",
    status: "PAID"
  }
};
