import React, { useContext } from "react";
import { Modal, message, Skeleton, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDecorator } from "../../../../helpers/use-decorator";
import { ModalForm } from "./form";
import {
  ScheduledExpertServiceService
} from "../../../../services/deprecated/scheduled-expert-service-service";
import { CurrentUserContext } from "../../current-user";
import { Visibility } from "../../../../components/shared";
import {
  serviceStatusEnum as STATUS
} from "../../../../helpers/constants/service-status-enum";
import { ServiceDetailModalContext } from "../context";
import { UsageTrackingService } from "../../../../services";
import { useHistory } from "react-router";

export function ServiceDetailModal({
  onCancel,
  scheduledExpertService,
  onCancelRequest,
  loading = false,
  refetch = null,
  ...props
}) {
  const { currentUser } = useContext(CurrentUserContext);
  const { onCancelAssignedService } = useContext(ServiceDetailModalContext);
  const { location } = useHistory();

  const isBetaMonitoring = location?.pathname.includes("/experts/services/monitoring/tracking");

  let assignedExpertService = scheduledExpertService
    ?.assignedExpertService;

  assignedExpertService = useDecorator(
    "assignedExpertService",
    assignedExpertService
  );

  const updatePaymentStatus = async status => {
    const dismissLoader = message.loading("Guardando...", 0);
    const scheduledExpertServiceService = new ScheduledExpertServiceService();

    try {
      await scheduledExpertServiceService
        .updatePaymentStatus(scheduledExpertService, status, currentUser?.id);

      refetch && await refetch();
      message.success("Servicio actualizado.");

      if (status === "PAID") trackPaidStatusChange(scheduledExpertService);
      onCancel();
    } catch (error) {
      console.error(error?.message);
      message.error("Ha ocurrido un error, inténtalo de nuevo\
        en unos segundos.");
    } finally {
      dismissLoader();
    }
  };

  const trackPaidStatusChange = (scheduledExpertService = {}) => {
    try {
      UsageTrackingService.trackPaidSession({
        session: {
          scheduledExpertService,
          id: scheduledExpertService?.assignedExpertService?.expertService?.id,
          name:
            scheduledExpertService?.assignedExpertService?.expertService?.name
        },
        updatedBy: {
          id: currentUser?.id,
          fullName: currentUser?.fullName,
          email: currentUser?.email,
          systemRole: currentUser?.systemRole
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnCancelRequest = scheduledExpertService => {
    onCancelRequest &&
      onCancelRequest({
        scheduledExpertService,
        currentUserId: currentUser.id
      });
  };

  const servStatus = scheduledExpertService?.status || false;

  const footerRender = () => {
    if (currentUser.systemRole === "ADVISER") {
      return <Button
        type="primary"
        onClick={onCancel}>
        Cerrar
      </Button>;
    }

    return [
      <Visibility
        key="cancel-assignation"
        visible={
          scheduledExpertService?.status === STATUS.ASSIGNED
          && currentUser.systemRole !== "ENTREPRENEUR"
        }>
        <Button
          onClick={() => onCancelAssignedService(assignedExpertService.id)}>
          Cancelar asignación
        </Button>
      </Visibility>,
      <Visibility
        key="print-payment"
        visible={([
          STATUS.AUTHORIZED_FOR_PAYMENT,
          STATUS.PAYMENT_IN_PROCESS,
          STATUS.PAID
        ].includes(servStatus) && currentUser.systemRole !== "ENTREPRENEUR")}>
        <Link
          target="_blank"
          to={
            isBetaMonitoring
              ? {pathname: `../${scheduledExpertService?.id}/payment-report`}
              : {pathname:
                  `../services/${scheduledExpertService?.id}/payment-report`}
          }>
          <Button
            icon={<PrinterOutlined />}>
            Imprimir reporte
          </Button>
        </Link>
      </Visibility>
    ];
  };

  return (
    <Modal
      className="service-details-modal"
      footer={footerRender()}
      destroyOnClose
      title={`Detalles de ${assignedExpertService.modalTitle?.toLowerCase()}`}
      onCancel={onCancel}
      {...props}>
      <Visibility visible={loading}>
        <Skeleton loading />
        <Skeleton loading />
      </Visibility>
      <Visibility visible={!loading}>
        <ModalForm
          scheduledExpertService={scheduledExpertService}
          updatePaymentStatus={updatePaymentStatus}
          handleOnCancelRequest={handleOnCancelRequest} />
      </Visibility>
    </Modal>
  );
}
