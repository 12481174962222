import React from "react";
import { Button, Space } from "antd";
import { EditButton } from "../../index";

export const ActionButton = ({
  editing = false,
  formName,
  onSave,
  setEditing,
  visible = true
}) => {
  if (!visible) {
    return null;
  }

  if (!editing) {
    return (
      <EditButton
        onClick={() => setEditing(true, formName)}>
        Editar
      </EditButton>);
  } else {
    return (
      <Space>
        <Button
          type="default"
          onClick={() => setEditing(false, formName)}>
          Cancelar
        </Button>
        <Button
          type="primary"
          onClick={() => onSave(false, formName)}>
          Guardar
        </Button>
      </Space>
    );
  }
};
