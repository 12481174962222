import gql from "graphql-tag";

export const getServicesByCoordinatorId = gql`
  query GetServicesByCoordinatorId(
    $method: String,
    $category: String,
    $searchString: String,
    $id: ID!
  ) {
    expertServices(
      where: {
        method: $method
        category: $category
        AND: [
          {
            OR: [
              { iname_contains: $searchString },
              { idescription_contains: $searchString },
              {
                expert: { ifullName_contains: $searchString }
              }
            ]
          },
          {
            OR: [
              {
                assignedPrograms_some: {
                  coordinator: {
                    id: $id
                  }
                }
              },
              {
                assignedPrograms_some: {
                  organism: {
                    coordinator: {
                      id: $id
                    }
                  }
                }
              },
              {
                assignedPrograms_some: {
                  batches_some: {
                    users_some: {
                      id: $id,
                      systemRole: COORDINATOR
                    }
                  }
                }
              },
              {
                OR: [
                  {
                    expert: {
                      odes_some: {
                        ode_some: {
                          batches_some: {
                            users_some: {
                              id: $id,
                              systemRole: COORDINATOR
                            }
                          }
                        } 
                      }
                    }
                  },
                  {
                    expert: {
                      odes_some: {
                        ode_some: {
                          batches_some: {
                            program: {
                              coordinator: {
                                id: $id
                              }
                            }
                          }
                        } 
                      }
                    }
                  },
                  {
                    expert: {
                      odes_some: {
                        ode_some: {
                          organisms_some: {
                            coordinator: {
                              id: $id
                            }
                          }
                        }
                      } 
                    }
                  },
                  {
                    expert: {
                      odes_some: {
                        ode_some: {
                          OR: [
                            {
                              team_some: {
                                id: $id,
                                systemRole: COORDINATOR
                              },
                              network_some: {
                                id: $id,
                                systemRole: COORDINATOR
                              }
                            }
                          ]
                        }
                      } 
                    }
                  }
                ]
              }
            ]
          }
        ]
    }) {
      id
      expert {
        id
        fullName
        profilePicUrl
      }
      name
      description
      type
      method
      category
      duration
      price
      coverPicUrl
      examples {
        id
        url
        type
        fileName
      }
    }
  }
`;
