import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tooltip, List, message, Space, Avatar } from "antd";
import { DeleteOutlined, TrophyOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import {
  ContentModalContext
} from "../../../contexts/shared/content-modal/context";
import {
  CurrentUserContext
} from "../../../contexts/shared";
import {
  ObjectiveItemContext
} from "../../../contexts/shared/objective-item";
import { useDecorator } from "../../../helpers/use-decorator";
import { ContentList } from "../content-list";
import { Visibility } from "../visibility";
import cascadeDeleteAlert from "../../../contexts/shared/cascade-delete-modal";
import { EditableLabel } from "../editable-label";
import { EditableField } from "./editable-field";
import { BatchDetailsContext } from "../../../contexts/admin/batches/batch-details";
import { useMutation } from "@apollo/react-hooks";
import { admin } from "../../../graphql/admin";
import { client } from "../../../../graphql";
import { DeleteButton } from "../delete-button";
import { CompletedCheck } from "../completed-check";
import { shared } from "../../../graphql/shared";
import { AchievementStatus } from "./achievement-status";
import { Section } from "../section";

export function AchievementItem({ achievement }) {
  const { id, odeId = false } = useParams();
  const [
    odeAchievement,
    setOdeAchievement
  ] = useState(achievement.odeAchievements?.[0] || {
    isComplete: false
  });

  const {
    deleteAchievement,
    objective
  } = useContext(ObjectiveItemContext);
  const { data: batchData } = useContext(BatchDetailsContext);

  const { openModal: openContentModal } = useContext(ContentModalContext);

  const decoratedAchievement = useDecorator("achievement", achievement);
  const { currentUser } = useContext(CurrentUserContext);

  const onAddContent = () => {
    openContentModal(achievement.id);
  };

  const handleOnDelete = () => {
    if (achievement.contents?.length > 0) {
      return cascadeDeleteAlert("logro", "contenido");
    }

    deleteAchievement(achievement.id);
  };

  const onEditContent = id => {
    openContentModal(achievement.id, id);
  };

  const [markAchievementAsCompleted, { data }] = useMutation(
    shared.mutations.markAchievementAsCompleted,
    { client, ignoreResults: false }
  );

  const onMarkAachievementAsCompleted = async () => {
    try {
      await markAchievementAsCompleted({
        variables: {
          date: new Date(),
          achievementId: achievement.id,
          odeId: odeId
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: admin.queries.getOdesByBatchId,
          variables: {
            id: id
          }
        }]
      });
    } catch (e) {
      message.error("Ha ocurrido un error, inténtalo de nuevo\
      en unos segundos.");
    }
  };

  const [restoreCompletedAchievement] = useMutation(
    shared.mutations.restoreCompletedAchievement,
    { client }
  );

  const onRestoreCompletedAchievement = async () => {
    try {
      await restoreCompletedAchievement({
        variables: {
          id: odeAchievement.id
        }
      });
      setOdeAchievement(null);
    } catch (e) {
      message.error("Ha ocurrido un error, inténtalo de nuevo\
      en unos segundos.");
    }
  };

  const [createAchievement] = useMutation(
    admin.mutations.createObjectiveAchievement,
    {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: admin.queries.getObjectivesByBatchId,
          variables: {
            id: batchData?.batch?.id
          }
        },
        {
          query: shared.queries.getBatchObjectives,
          variables: {
            batchId: batchData?.batch?.id
          }
        }
      ]
    }
  );

  const [updateAchievement] = useMutation(
    admin.mutations.updateObjectiveAchievement,
    {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: admin.queries.getObjectivesByBatchId,
          variables: {
            id: batchData?.batch?.id
          }
        },
        {
          query: shared.queries.getBatchObjectives,
          variables: {
            batchId: batchData?.batch?.id
          }
        }
      ]
    }
  );

  const onSaveEditable = async data => {
    const dismissLoader = message.loading("Guardando...");

    try {
      if (achievement.new) {
        data.objective = {
          connect: { id: objective?.id }
        };

        await createAchievement({
          variables: { data }
        });

        message.success("Nuevo logro agregado.");
      }
      else {
        await updateAchievement({
          variables: {
            where: { id: achievement.id },
            data
          }
        });
        message.success("Cambios guardados.");
      }
    } catch (error) {
      console.error(error);
      message.error(`Ha ocurrido un error.
        /Por favor inténtalo de nuevo en unos segundos.`);
    }

    dismissLoader();
  };

  useEffect(() => {
    if (data) {
      setOdeAchievement(data.createOdeAchievement);
    }
  }, [data]);

  return (
    <Section style={{border: "1px solid #d9d9d9"}}>
      <Space className="achievement-item">
        <div className="left-container">
          <Avatar
            icon={<TrophyOutlined twoToneColor="#000000" />}
            style={{
              marginRight: "15px",
              backgroundColor: odeAchievement?.isComplete ? "#FFE666" : ""}} />
          <EditableLabel
            onSave={onSaveEditable}
            editing={achievement.new}
            editable={
              ["SUPER_ADMIN", "COORDINATOR"].includes(currentUser.systemRole)
            }
            editableField={onChange =>
              <EditableField onChange={onChange} achievement={achievement} />
            }>
            <List.Item.Meta
              title={decoratedAchievement.name || "Nuevo logro"}
              description={decoratedAchievement.description || "Sin descripción"} />
          </EditableLabel>
          <Visibility
            visible={["SUPER_ADMIN", "COORDINATOR"].includes(currentUser.systemRole)}>
            <DeleteButton
              onClick={handleOnDelete}
              icon={<DeleteOutlined />}
              confirm
              confirmText="¿Quieres eliminar este logro? Esta acción no se puede deshacer." />
          </Visibility>
          <Visibility
            visible={
              !["SUPER_ADMIN", "COORDINATOR"].includes(currentUser.systemRole)}>
            <AchievementStatus
              style={{marginLeft: "15px"}}
              status={odeAchievement?.isComplete} />
          </Visibility>
        </div>
        <div className="rigth-container">
          <Visibility visible={odeId && !odeAchievement?.isComplete}>
            <Tooltip
              placement="left"
              title="Marcar como logrado">
              <CheckCircleTwoTone
                style={{fontSize: "15px"}}
                className="clickable"
                twoToneColor="#52c41a"
                onClick={onMarkAachievementAsCompleted} />
            </Tooltip>
          </Visibility>
          <CompletedCheck
            completedText="Logrado"
            isComplete={odeAchievement?.isComplete}
            date={odeAchievement?.finishDate}
            onRestore={onRestoreCompletedAchievement} />
        </div>
      </Space>
      <ContentList
        onEdit={onEditContent}
        onAdd={onAddContent}
        contents={decoratedAchievement.contents ?? []} />
    </Section>
  );
}
