import React, { useContext } from "react";
import { Form } from "antd";
import { DetailContent } from "./detail-content";
import { ScheduledMentoringHeader } from "../components/headers";
import { BaseServiceDetailModal } from "../../../../shared/modals";
import {
  useScheduleService
} from "../../../../../hooks/shared/product/use-schedule-service";
import {
  CurrentUserContext,
  ServicesLayerContext 
} from "../../../../../contexts/shared";


export function EntrepreneurScheduledServiceModal({
  selectedItem: assignedService,
  onCancel,
  refetchScheduledServices,
  ...props
}) {
  const { currentUser } = useContext(CurrentUserContext);
  const { ExpertServiceService } = useContext(ServicesLayerContext);

  const { scheduleService } = useScheduleService({
    loggedUserId: currentUser?.id,
    ExpertServiceService
  });

  const formatData = { assignedExpertService: assignedService };
  const [form] = Form.useForm();

  const onSchedule = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();

    await scheduleService({
      duration: String(values.duration),
      serviceDate: values.serviceDate,
      serviceHour: values.serviceHour,
      assignedExpertServiceId: assignedService?.id,
      afterSuccess: () => {
        refetchScheduledServices && refetchScheduledServices();
        handleOnClose && handleOnClose();
      }
    });
  };

  const handleOnClose = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  return ( 
    <BaseServiceDetailModal
      {...props}
      footer={null}
      onCancel={handleOnClose}
      header={<ScheduledMentoringHeader
        scheduledExpertService={formatData}/>}>
      <DetailContent
        form={form}
        onSchedule={onSchedule}
        assignedExpertService={assignedService} />
    </BaseServiceDetailModal>
  );
}