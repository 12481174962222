import gql from "graphql-tag";

export const rejectScheduledService = gql`
  mutation RejectScheduledService(
    $data: RejectScheduledExpertServiceInput!
  ) {
    rejectScheduledExpertService(
      data: $data
    ) {
      id
      status
      assignedExpertService {
        status
      }
    }
  }
`;
