import React, { useContext } from "react";
import {
  ContentWrapper,
  FormContainer
} from "../../../shared";
import { EducationAndJob } from "../../../../components/entrepreneur/profile";
import { EditProfileContext } from "../../../../contexts/shared";

export function GeneralProfileEntrepreneur () {
  const {
    data,
    error,
    loading
  } = useContext(EditProfileContext);

  return (
    <ContentWrapper>
      <FormContainer>
        <EducationAndJob
          editable
          data={data}
          error={error}
          isLoading={loading} />
      </FormContainer>
    </ContentWrapper>
  );
}
