import React from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";

export const ContentCompaniesProgressTable = ({
  loading = true,
  companiesRows = [],
  contentColumns = [],
  generateGoToCompanyUrl
}) => {
  const BASE_TABLE_COLUMNS = [
    {
      title: <strong>Compañía</strong>,
      key: "companyName",
      width: "200px",
      fixed: "left",
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      defaultSortOrder: "ascend",
      render: ({ companyId, companyName }) => {
        return (
          <Link
            style={{ color: "rgb(95, 57, 222)" }}
            to={generateGoToCompanyUrl
            ? generateGoToCompanyUrl(companyId)
            : undefined}>
            {companyName}
          </Link>
        );
      }
    },
    {
      title: <strong>Avance gral.</strong>,
      dataIndex: "companyProgress",
      key: "companyProgress",
      width: "120px",
      align: "center",
      fixed: "left",
      sorter: (a, b) =>
        parseFloat(b.companyProgress) - parseFloat(a.companyProgress),
      render: companyProgress => `${companyProgress}%`
    }
  ];

  const columns = BASE_TABLE_COLUMNS.concat(contentColumns);

  const [firstSectionColumn] = contentColumns;
  const shouldFillEmptySpace = contentColumns.length < 2
    && (firstSectionColumn?.children?.length || 0) < 3;

  if (shouldFillEmptySpace) {
    columns.push({ width: "1000px" });
  }

  return (
    <Table
      bordered
      size="middle"
      loading={loading}
      pagination={false}
      columns={columns}
      dataSource={companiesRows}
      scroll={{ x: 2000, y: 600 }} />
  );
};

