import gql from "graphql-tag";

export const getBatchOdesForCharts = gql`
  query GetBatchOdesForCharts($id: ID!) {
    oDEs(
      where: { batches_some: { id: $id } }
    ) {
      id
      name
      industry
      sector
      economicIndustry
      economicSector
      technologyLevel
      trl
      address {
        country
      }
      team {
        gender
        birthday
        career
        careerName
        tecRelationship
        tecRelationships {
          careerName
          type
        }
      }
    }
  }
`;
