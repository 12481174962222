import gql from "graphql-tag";

export const getBatchObjectives = gql`
  query getBatchObjectives($batchId: ID!, $odeId: ID) {
    objectives(
      orderBy: id_DESC
      where: { batch: { id: $batchId } }
    ) {
      id
      name
      description
      batch {
        id
      }
      achievements(orderBy: id_DESC) {
        id
        name
        description
      	odeAchievements(where: { ode: { id: $odeId } }) {
          id
          achievement{
            id
          }
          isComplete
          finishDate
        }
        contents(orderBy: id_DESC) {
          id
          name
          description
        	type
          deliveryDate
        	odeContents(where: { ode: { id: $odeId } }) {
            id
            isComplete
            finishDate
            content{
              id
              type
              deliveryDate
            }
          }
        }
      }
    }
  }
`;
