import gql from "graphql-tag";

export const getCoordinatorById = gql`
  query GetCoordinatorById($id: ID!) {
    user(
      where: { id: $id }
    ) {
      id
      profilePicUrl
      fullName
      firstName
      middleName
      lastName
      email
      systemRole
      phone
    }
  }
`;