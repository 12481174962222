import { client3 } from "../../../graphql";
import {
  getPersonExperienceAttendanceByAttendanceIdQuery
} from "./graphql";

export const getPersonExperienceAttendanceByAttendanceId =
  async personExperienceAttendanceId => {
  const result = await client3.query({
    query: getPersonExperienceAttendanceByAttendanceIdQuery,
    fetchPolicy: "network-only",
    variables: { personExperienceAttendanceId }
  });

  return result?.data?.personExperienceAttendance || {};
};
