import { cubeJSClient } from "../../api-clients";

export const getSocialTechCompanies = async ({
  batchId,
  programId,
  from,
  until,
  teamMemberCampus
}) => {
  const filters = [];

  if (batchId) {
    filters.push({
      "member": "Company.batchId",
      "operator": "equals",
      "values": [batchId]
    });
  }

  if (teamMemberCampus) {
    filters.push({
      "member": "Company.teamMemberCampus",
      "operator": "equals",
      "values": [teamMemberCampus]
    });
  }

  if (programId) {
    filters.push({
      "member": "Company.programId",
      "operator": "equals",
      "values": [programId]
    });
  }

  if (from && until) {
    filters.push({
      "member": "Company.batchStartDate",
      "operator": "inDateRange",
      "values": [from, until]
    });
  }

  const resultSet = await cubeJSClient.load({
    "measures": [
      "Company.techBasedCompaniesCount",
      "Company.socialCompaniesCount"
    ],
    "timeDimensions": [],
    "order": {
      "Company.batchCreatedAt": "asc"
    },
    "dimensions": [],
    filters,
    "limit": 5000
  });

  const [queryData] = resultSet.loadResponse.results[0].data;
  return {
    techBased: parseInt(queryData["Company.techBasedCompaniesCount"] || "0"),
    social: parseInt(queryData["Company.socialCompaniesCount"] || "0")
  };
};
