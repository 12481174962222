import { client } from "../../../../graphql";
import { getSearchUser as getSearchUserQuery } from "./graphql";

export async function getExperts({
  ifullName,
  systemRole,
  first,
  skip,
  batches
}) {
  return await client.query({
    query: getSearchUserQuery,
    fetchPolicy: "network-only",
    variables: { ifullName, systemRole, first, skip, batches }
  });
}
