export const setUpdateCredentials = (credentials, state, setState) => {
  const updatedCredentials = {
    ...state.credentials,
    ...credentials
  };

  setState({
    ...state,
    credentials: updatedCredentials
  });
};
