import React from "react";
import { DatePicker, Form, Input } from "antd";
import { urlPatterns } from "../../../../../utils";

export const CompanyNewsForm = ({ form }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      style={{ padding: "0 16px" }}>
      <Form.Item
        label="Título de la noticia"
        name="name"
        rules={[{ required: true }]}>
        <Input
          autoComplete="off"
          placeholder="Por ejemplo: Declaración como la startup más innovadora del año." />
      </Form.Item>
      <Form.Item
        label="Liga del artículo"
        name="link"
        rules={[{
          required: true,
          pattern: urlPatterns.urlRequiredHttp,
          message: "El formato de la URL no es válido. Escriba la URL con el prefijo http:// o https://"
        }]}>
        <Input
          autoComplete="off"
          placeholder="Por ejemplo: https://www.noticia.com.mx" />
      </Form.Item>
      <Form.Item
        label="Fecha de la noticia"
        name="date"
        rules={[{ required: true }]}>
        <DatePicker
          format="MMMM YYYY"
          picker="month"
          style={{ width: "347px" }} />
      </Form.Item>
    </Form>
  );
};
