import React from "react";
import { Select, Form, message, DatePicker } from "antd";
import { GridContainer } from "../../../../shared";
import { useChartFiltering } from "../../use-chart-filtering";
import { onProgramChange } from "../../on-program-change-handler";
import { onBatchChangeHandler } from "../../on-batch-change-handler";
import { onDateChangeHandler } from "../../on-date-change-handler";
import { onCampusChangeHandler } from "../../on-campus-change-handler";
import {
  CampusSelector
} from "../../entrepreneurs-charts/filters/campus-selector";

export const Filters = ({
  onChange, programs, batches, loadingPrograms, refetchBatches
}) => {
  const [form] = Form.useForm();
  const { filters, handleOnChange } = useChartFiltering({ onChange });

  const afterSelectProgram = ({ programId } = {}) => {
    if (programId) {
      const dismissLoader = message.loading("Cargando batches...");
      refetchBatches({ programId: programId })
        .finally(() => dismissLoader());
    }
    form.setFieldsValue({ batch: undefined });
  };

  return (
    <Form
      form={form}
      layout="vertical">
      <GridContainer responsiveValues={COL_RESPONSIVE_SIZES}>
        <Form.Item
          name="program"
          label="Programa">
          <Select
            {...sharedProps}
            placeholder="Programa"
            loading={loadingPrograms}
            onChange={programId =>
              handleOnChange(onProgramChange)(programId, afterSelectProgram)}
            disabled={programs?.length < 1}
            options={programs?.map(p => ({
              value: p.id,
              label: p.name
            }) || [])} />
        </Form.Item>
        <Form.Item
          name="batch"
          label="Batch">
          <Select
            {...sharedProps}
            placeholder="Batch"
            onChange={batchId => handleOnChange(onBatchChangeHandler)(batchId)}
            disabled={!filters.programId}
            options={batches?.map(b => ({
              value: b.id,
              label: b.name
            })) || []} />
        </Form.Item>
        <Form.Item
          name="teamMemberCampus"
          label="Campus">
          <CampusSelector
            {...sharedProps}
            onChange={campus =>
              handleOnChange(onCampusChangeHandler)(campus)} />
        </Form.Item>
        <Form.Item
          name="dateRange"
          label="Rango de fecha">
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            format="DD/MM/YYYY"
            onChange={dates => handleOnChange(onDateChangeHandler)(dates)} />
        </Form.Item>
      </GridContainer>
    </Form>
  );
};

const COL_RESPONSIVE_SIZES = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 6,
  xl: 6,
  xxl: 6
};

const sharedProps = {
  optionFilterProp: "label",
  allowClear: true,
  showSearch: true,
  style: { width: "100%" }
};

