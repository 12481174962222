export function useOrganizationsNumeralia (organizations = []) {
  let privateOrganizations = 0;
  let organizationsServices = 0;

  organizations.forEach(organization => {
    if(organization.private === true){
      privateOrganizations += 1;
    }
    organizationsServices += organization.services.length || 0;
  });

  return {
    privateOrganizations,
    publicOrganizations: organizations.length - privateOrganizations,
    organizationsServices
  };
}
