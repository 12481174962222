import React, { useContext, useMemo, useState } from "react";
import { useService } from "../../../../../hooks/shared";
import {
  ExpertSessionsServicesService
} from "../../../../../services/expert-sessions-services-service";
import {
  FilterControlsProvider,
  CurrentUserContext,
  PaginationContext
} from "../../../../../contexts/shared";
import { useControlledPagination } from "../../../../../helpers";
import {
  TableWrapperAssignationServiceProvider,
  TableWrapperScheduledServiceProvider
} from "../../../../shared/table-wrapper-services-for-experts";

/**
 * @param {Object} props
 * @param {React.ReactElement} props.children
 * @param {"ASSIGNATIONS" | "TRACKING"}  props.tableType
 * @param {"PRODUCT" | "MENTORSHIP" | "WORKSHOP"} props.serviceType
 * @param {React.ReactElement} props.filterControls
 * @returns {JSX.Element}
 * @constructor
*/
export function MonitoringTableWrapper({
  children,
  tableType = "ASSIGNATIONS",
  serviceType = "PRODUCT",
  filterControls = null
}) {
  const { currentUser } = useContext(CurrentUserContext);
  const { systemRole } = currentUser || {};
  const [filters, setFilters] = useState({});

  const dataServicesMap = {
    ASSIGNATIONS: ExpertSessionsServicesService.getAssignedServices,
    TRACKING: ExpertSessionsServicesService.getScheduledServices
  };

  const TableProvider = tableType === "ASSIGNATIONS"
    ? TableWrapperAssignationServiceProvider
    : TableWrapperScheduledServiceProvider;

  const {
    currentPage,
    onPaginationChange,
    pageSize,
    skip
  } = useControlledPagination({
    initialPageSize: 12
  });

  const variables = useMemo(() => ({
    skip,
    serviceType,
    first: pageSize,
    expertId: systemRole === "ADVISER" ? currentUser?.id : undefined,
    ...filters
  }), [filters, serviceType, pageSize, systemRole, currentUser, skip]);

  const {
    data,
    loading,
    refetch
  } = useService(dataServicesMap[tableType], variables);

  const paginationRefetchName = `monitoring${tableType}_${serviceType}`;

  const { registerRefetch } = useContext(PaginationContext);
  registerRefetch(paginationRefetchName, refetch, variables);

  const items =
    (data?.assignedExpertServices || data?.scheduledExpertServices) || [];

  return (
    <>
      <FilterControlsProvider
        refetch={refetch}
        onPaginationChange={onPaginationChange}
        defaultFilters={variables}
        afterApplyFilters={setFilters}>
        {filterControls}
      </FilterControlsProvider>
      <TableProvider refetch={refetch}>
        {React.cloneElement(children, {
          items,
          loading,
          currentPage,
          onChangePage: onPaginationChange,
          totalRecordsCount: data?.count,
          paginationRefetchName,
          refetch
        })}
      </TableProvider>
    </>
  );
}