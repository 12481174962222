import React from "react";
import { Popover, Tag } from "antd";

export function OrganizationsTablePopover({
    children,
    labels = []
  }) {
  const content = (
    <div>
      {labels.map(label => (
        <Tag
          style={{ padding: "1px 8px" }}
          key={label.name}>
          {label.name}
        </Tag>
      ))}
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="hover">
      {children}
    </Popover>
  );
}
