import { gql } from "graphql-tag";

export const getCampusInvolvementLevelsImpactQuery = gql`
  query GetCampusInvolvementLevelsImpact (
      $filters: CampusInvolvementLevelsImpactInput
  ) {
    campusInvolvementLevelsImpact(
      filters: $filters
    )
  }
`;