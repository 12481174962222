import React, { useContext } from "react";
import { List } from "antd";
import {
  cardGridSizes,
  paginationConfig,
  withLoader
} from "../../../../../helpers";
import { OrganizationItem } from "./item";
import { PaginationContext } from "../../../../../contexts/shared";

function OrganizationList({
  data,
  isLoading,
  currentPage = 1,
  onChangePage
}) {
  const { onPaginationChange } = useContext(PaginationContext);

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange("organization")(page, pageSize);
  };

  return (
    <List
      locale={{ emptyText: "No hay organizaciones" }}
      className="OrganizationIndex__list"
      pagination={{
        ...paginationConfig,
        defaultPageSize: 12,
        current: currentPage,
        onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
        total: data?.organizationsPaginationCount?.count || 0
      }}
      loading={isLoading}
      grid={cardGridSizes}
      dataSource={data?.organizationsByFilters || []}
      renderItem={organization =>
        <List.Item>
          <OrganizationItem
            organization={organization} />
        </List.Item>
      } />
  );
}

export default withLoader(OrganizationList);
