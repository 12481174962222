export const formatCreateValues = (formsValues = {}) => {
  const {
    resume,
    ...contact
  } = formsValues.contact;

  return ({
    ...contact,
    expertProfile: {
      create: {
        ...formsValues.fiscal,
        resume: resume,
        location: {
          create: formsValues.location
        }
      }
    }
  });
};

export function formatUpdateValues(formsValues = {}) {
  const {
    resume,
    ...contact
  } = formsValues.contact;

  return ({
    ...contact,
    expertProfile: {
      update: {
        ...formsValues.fiscal,
        resume: resume,
        location: {
          update: formsValues.location
        }
      }
    }
  });
}

