import React from "react";
import { useCompanyTeamMembers } from "../../../../../../hooks/company";
import { useService } from "../../../../../../hooks/shared";
import { OdesService } from "../../../../../../services/odes-service";
import { Modal, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { FlexContainer } from "../../../../flex-container";
import { Visibility } from "../../../../visibility";
import { ModalButton } from "../../../../buttons";
import { InvitationModal } from "../../../../modals";
import { TeamMembersList } from "./list";
import { AddTeamMemberButton } from "./add-team-member-button";

export function TeamMembers({ companyId, allowEdit }) {
  const {
    inviteTeamMembers,
    takenEmails,
    resetState,
    addTeamMembers,
    removeTeamMemberFromCompany
  } = useCompanyTeamMembers({ companyId: companyId });

  const onInviteCompanyTeamMembers = async ({ emails }) => {
    const taken = await inviteTeamMembers({
      emails: emails || [],
      continueAnyway: takenEmails.length > 0 // retry it without changing emails
    });

    return taken?.length > 0; // modal will skip "close" if we return true
  };

  const dismissTakenEmailsValidation = () => {
    if (takenEmails.length > 0) resetState();
  };

  const {
    data: teamMember,
    loading: loadingTeamMember,
    refetch: refetchTeamMember
  } = useService(
    OdesService.getTeamMembers,
    { companyId, systemRole: ["ENTREPRENEUR", "ADVISER"] }
  );

  const {
    data: availableUsersEntrepreneur,
    loading: loadingAvailableUsersEntrepreneur,
    refetch: refetchAvailableUsersEntrepreneur
  } = useService(
    OdesService.getAvailableUsersToAdd,
    { companyId, systemRole: "ENTREPRENEUR" }
  );

  const {
    data: availableUsersAdviser,
    loading: loadingAvailableUsersAdviser,
    refetch: refetchAvailableUsersAdviser
  } = useService(
    OdesService.getAvailableUsersToAdd,
    { companyId, systemRole: "ADVISER" }
  );

  const onAddCompanyTeamMembers = async ({ users = [], type }) => {
    await addTeamMembers({
      ids: users,
      systemRole: type,
      afterSuccess: () => {
        return refetchTeamMember();
      }
    });
  };

  const onRemove = (user = {}) => {
    const mapping = {
      "ENTREPRENEUR": "emprendedor",
      "ADVISER": "experto"
    };

    Modal.confirm({
      width: 550,
      maskClosable: true,
      okText: "Sí, remover",
      cancelText: "No, regresar",
      title: "Remover elemento",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <div>
            <Typography.Text>
              {`Al remover al ${mapping[user?.systemRole]}, no podrás verlo en tu compañía.`}
            </Typography.Text>
          </div>
          <Typography.Text strong>
            ¿Deseas continuar?
          </Typography.Text>
        </>
      ),
      onOk: () => removeTeamMemberFromCompany({
        userId: user.id,
        afterSuccess: () => {
          refetchTeamMember();
          refetchAvailableUsersEntrepreneur();
          refetchAvailableUsersAdviser();
        }
      })
    });
  };

  return (
    <div>
      <FlexContainer justifyContent="space-between">
        <Typography.Title level={5}>
          Equipo
        </Typography.Title>
        <div>
          <Visibility visible={allowEdit}>
            <FlexContainer style={{ gap: "24px" }}>
              <ModalButton
                type="primary"
                title="Invitar miembros"
                modal={InvitationModal}
                modalProps={{
                  onFieldsChange: dismissTakenEmailsValidation,
                  takenEmails: takenEmails,
                  onOk: onInviteCompanyTeamMembers,
                  afterCancel: () => resetState()
                }} />
              <AddTeamMemberButton
                type="ENTREPRENEUR"
                availableUsers={availableUsersEntrepreneur}
                loadingAvailableUsers={loadingAvailableUsersEntrepreneur}
                refetchAvailableUsers={refetchAvailableUsersEntrepreneur}
                onAddCompanyTeamMembers={onAddCompanyTeamMembers} />
              <AddTeamMemberButton
                type="ADVISER"
                title="Agregar expertos"
                availableUsers={availableUsersAdviser}
                loadingAvailableUsers={loadingAvailableUsersAdviser}
                refetchAvailableUsers={refetchAvailableUsersAdviser}
                onAddCompanyTeamMembers={onAddCompanyTeamMembers} />
            </FlexContainer>
          </Visibility>
        </div>
      </FlexContainer>
      <TeamMembersList
        customOnRemove={onRemove}
        loading={loadingTeamMember}
        teamMembers={teamMember || []}
        allowEdit={allowEdit} />
    </div>
  );
}
