import React, { useEffect, useState } from "react";
import { ObjectiveAchivementItemContext } from "./context";
import { useMutation } from "@apollo/react-hooks";
import { admin } from "../../../graphql/admin";
import { DataConnectionService } from "../../../services";

export function ObjectiveAchivementItemContextProvider({
  achievement,
  children
}) {
  const [state, setState] = useState({ achievement: undefined });

  useEffect(() => {
    if(achievement) {
      setState({ ...state, achievement });
    }
  }, [achievement]);

  const [updateObjectiveAchievement] = useMutation(
    admin.mutations.updateObjectiveAchievement,
    { client: DataConnectionService.graphqlApolloClient }
  );

  const onUpdateObjectiveAchivement = ({ values, id }) => {
    updateObjectiveAchievement({
      variables: {
        data: values,
        where: { id }
      }
    });
  };

  return (
    <ObjectiveAchivementItemContext.Provider
      value={{
        ...state,
        updateObjectiveAchievement: onUpdateObjectiveAchivement
      }}>
      {children}
    </ObjectiveAchivementItemContext.Provider>
  );
}
