import React from "react";
import { Empty, Spin } from "antd";
import { FlexContainer } from "../../shared";

export const ChartDataFeedback = ({ loading, error, isEmpty, children }) => {
  if (loading) {
    return (
      <CenteredContainer>
        {loading && <Spin size="large" />}
      </CenteredContainer>
    );
  }

  if (isEmpty) {
    return (
      <CenteredContainer>
        <Empty description="Sin datos" />
      </CenteredContainer>
    );
  }

  if (error) {
    return (
      <CenteredContainer>
        <h3>Ocurrió un error</h3>
      </CenteredContainer>
    );
  }

  return children;
};

const CenteredContainer = ({ children }) => (
  <FlexContainer
    style={{
      height: "100%",
      width: "100%"
    }}
    direction="vertical"
    xAlign="center"
    yAlign="center">
    {children}
  </FlexContainer>
);
