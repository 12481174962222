import React from "react";
import { Button, Menu, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

/**
 * @deprecated Should use the shared ActionButton available at
 * components/shared/ActionButton
 * @param {*} param0
 */
export function ActionButton({onDisable, onEdit, ...props}) {
  const menu = <Menu>
    <Menu.Item onClick={onEdit}>
      Editar
    </Menu.Item>
    <Menu.Item onClick={onDisable}>
      Deshabilitar
    </Menu.Item>
  </Menu>;

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button
        shape="circle"
        icon={<EllipsisOutlined />}
        {...props} />
    </Dropdown>
  );
}
