import React from "react";
import { Switch, Route } from "react-router-dom";
import { OdesIndex } from "../../../pages/expert/odes";
import { default as GeneralInformationIndex } from "../../../pages/expert/odes/show/general-information";
import { default as TeamIndex } from "../../../pages/expert/odes/show/team";
import { default as PitchIndex } from "../../../pages/expert/odes/show/pitch";
import { default as MyDocumentsIndex } from "../../../pages/expert/odes/show/my-documents";
import { default as BatchesIndex } from "../../../pages/expert/odes/show/batches";
import { default as MetricsIndex } from "../../../pages/expert/odes/show/metrics";
import { OnePagerIndex } from "../../../pages/expert/odes/show/one-pager";
import { default as BatchContentsIndex } from "../../../pages/expert/odes/show/batches/contents";

export const ExpertODEsRoutes = <Switch>
  <Route
    exact
    path="/v2/expert/odes"
    component={OdesIndex} />
  <Route
    path="/v2/expert/odes/:id/general-information"
    component={GeneralInformationIndex} />
  <Route
    path="/v2/expert/odes/:id/team"
    component={TeamIndex} />
  <Route
    path="/v2/expert/odes/:id/pitch"
    component={PitchIndex} />
  <Route
    path="/v2/expert/odes/:id/my-documents"
    component={MyDocumentsIndex} />
  <Route
    exact
    path="/v2/expert/odes/:id/batches"
    component={BatchesIndex} />
  <Route
    path="/v2/expert/odes/:id/metrics"
    component={MetricsIndex} />
  <Route
    path="/v2/expert/odes/:id/one-pager"
    component={OnePagerIndex} />
  <Route
    path="/v2/expert/odes/:id/batches/:batchId"
    component={BatchContentsIndex} />
</Switch>;
