import React from "react";
import { Typography } from "antd";
import { FlexContainer } from "../../flex-container";

export const EntrepreneurSpiritHeader = () => {

  return(
    <FlexContainer
      style={{marginBottom: "1rem"}}
      justifyContent="space-between"
      direction="horizontal">
      <Typography.Title level={4} style={{color: "#5F39DE"}}>
        Dashboard Espíritu Emprendedor
      </Typography.Title>
    </FlexContainer>
  );
};
