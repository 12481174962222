import React, { useContext } from "react";
import OrganizationList from "./list";
import { Space, Typography } from "antd";
import { FilterControls } from "./filter-controls";
import { PaginationContext } from "../../../../contexts/shared";
import { useControlledPagination, useQueryFilters } from "../../../../helpers";
import { useService } from "../../../../hooks/shared";
import { organizationsService } from "../../../../services/organizations";

export function OrganizationListIndex() {

  const defaultFilters = { first: 12 };

  const {
    loading,
    data,
    error,
    refetch,
    variables
  } = useService(
    organizationsService.getOrganizationsByFilters,
    defaultFilters
  )

  const { registerRefetch } = useContext(PaginationContext);
  registerRefetch("organization", refetch, variables);

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters
  });

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: defaultFilters.first
  });

  const onFiltersChange = newFilters => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => {
      const arrayData = Object.entries({ ...prevFilters, ...newFilters });
      const filters = arrayData.reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
          if (value.length > 0) acc[key] = value;
        } else {
          acc[key] = value; //soporte para filtros que no son arrays
        }
        return acc;
      }, {});
      return ({
        ...filters,
        skip,
        first
      })
    })
  }

  return (
    <Space
      style={{marginTop: "2rem"}}
      className="asem OrganizationIndex"
      direction="vertical">
      <FilterControls onChange={onFiltersChange} />
      <Typography.Title
        className="OrganizationIndex__title"
        level={2}>
        Organizaciones
      </Typography.Title>
      <OrganizationList
        error={error}
        data={data}
        isLoading={loading}
        onChangePage={onPaginationChange}
        currentPage={currentPage} />
    </Space>
  );
}
