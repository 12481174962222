import React, { useContext, useEffect } from "react";
import { Select } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql";
import { CurrentUserContext } from "../../../contexts/shared";

export const BatchesSelect = ({ excludedBatches, programId, odeId, ...props }) => {
  const { currentUser } = useContext(CurrentUserContext);

  // Todo: Recommend separate for queries by role
  const getQueryWhere = () => {
    const id_not_in = excludedBatches?.map(batch => batch?.id);
    let odes_some = undefined;
    let program = undefined;
    let OR = undefined;

    // if programId is equal to an empty String instead of undefined
    // the select will return cero Batches
    if(programId || programId === ""){
      program = { id: programId };
    }

    if(odeId) {
      odes_some = { id: odeId };
    }

    if (currentUser.systemRole === "COORDINATOR") {
      OR = [
        {
          users_some: {
            systemRole: "COORDINATOR",
            id: currentUser.id
          }
        }, {
          program: {
            coordinator: {
              id: currentUser.id
            }
          }
        }
      ];
    }

    if (currentUser.systemRole === "ENTREPRENEUR") {
      odes_some = {
        ...(odes_some || {}),
        team_some: {
          systemRole: "ENTREPRENEUR",
          id: currentUser.id
        }
      };
    }

    return {
      id_not_in,
      program,
      odes_some,
      OR
    };
  };

  const { data, loading, refetch } = useQuery(shared.queries.getBatches, {
    client,
    variables: { where: getQueryWhere() }
  });

  const options = data ?
    data.batches?.map(batch => ({
      label: batch?.name,
      value: batch?.id
    }))
    : [];

  useEffect(() => {
    refetch();
  }, [excludedBatches, programId]);

  return (
    <Select
      {...props}
      loading={loading}
      showSearch
      allowClear
      optionFilterProp="label"
      placeholder="Buscar Batches"
      options={options}
      data-cy="batches-select">
    </Select>
  );
};

