import React, { useState } from "react";
import { DraggableListContext } from "./context";
import { moveArrayItemPosition } from "../../../helpers";


/**
 * Provides the drag and drop basic functionalities to implement a reorderable
 * list
 * @param {JSX.Element} children
 * @param {function} onUpdate
 * @returns {JSX.Element}
 */
export function DraggableListProvider({
  children,
  onUpdate = null
}) {
  const [isMovingContents, setIsMovingContents] = useState(false);
  const [isMovingGroups, setIsMovingGroups] = useState(false);

  const moveItemPosition = async (
    items,
    setState,
    dragIndex,
    hoverIndex,
    groupId = false
  ) => {

    groupId ? setIsMovingContents(true) : setIsMovingGroups(true);
    const dragItem = items[dragIndex];
    if (dragItem) {
      let uuids = [];
      setState(prevState => {
        let copiedStateArray = [...prevState];

        copiedStateArray = moveArrayItemPosition(
          copiedStateArray,
          dragIndex,
          hoverIndex
        );

        uuids = copiedStateArray.map(item => item.id);
        return copiedStateArray;
      });
      try {
        onUpdate && await onUpdate(uuids, groupId);
      } finally {
        groupId ? setIsMovingContents(false) : setIsMovingGroups(false);
      }
    }
  };

  const providerValue = {
    isMovingGroups,
    isMovingContents,
    moveItemPosition
  };

  return (
    <DraggableListContext.Provider value={providerValue}>
      {children}
    </DraggableListContext.Provider>
  );
}
