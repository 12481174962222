import React, { useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Form, message } from "antd";
import { StepsNavigation } from "../../../../components/entrepreneur/invitation/steps-navigation";
import { OnboardingLayout } from "../../../../components/shared";
import { InformationForm } from "../../../../components/entrepreneur/invitation/information/form";
import { client } from "../../../../../graphql/";
import { shared } from "../../../../graphql/shared";
import { Loader } from "../../../../components/shared/loader";
import { formatValues } from "../../../../components/entrepreneur/invitation/information/format-values";
import { AuthServiceContext } from "../../../../contexts/shared/auth";
import { CurrentUserContext } from "../../../../contexts/shared";

export const UpdateInformationIndex = () => {

  const { id } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const { authService } = useContext(AuthServiceContext);
  const { refetchCurrentUser } = useContext(CurrentUserContext);

  const { data, loading, error } = useQuery(
    shared.queries.getUserById,
    {
      client,
      variables: {
        id
      },
      fetchPolicy: "network-only"
    }
  );

  const [updateUser] = useMutation(
    shared.mutations.updateEntrepreneurById, { client }
  );

  useEffect(() => {
    if(!loading){
      form.setFieldsValue({
        firstName: data?.user?.firstName,
        middleName: data?.user?.middleName,
        lastName: data?.user?.lastName,
        email: data?.user?.email
      });
    }
  }, [data]);

  const onNext = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const formattedValues = formatValues(values);

    const loginArgs = {
      variables: {
        email: formattedValues.email,
        password: formattedValues.password
      }
    };

    try {
      await updateUser({ variables: { data: formattedValues, id } });
      await authService.login(loginArgs);
      await refetchCurrentUser();

      history.push("./company");
    } catch(e) {
      console.error(e);
      message.error("Ha ocurrido un error, \
      inténtalo de nuevo en unos segundos.");
    }
  };

  return (
    <OnboardingLayout>
      <StepsNavigation current={0} />
      <div style={{maxWidth: "719px", margin: "0 auto"}}>
        <Loader
          data={data}
          error={error}
          loading={loading}>
          <InformationForm
            form={form}
            profilePic={data?.user?.profilePicUrl}
            onNext={onNext}
            loadingNext={false} />
        </Loader>
      </div>
    </OnboardingLayout>
  );
};

