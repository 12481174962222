import React from "react";
import { Radio } from "antd";
import { educationalDegrees } from "../../../../../../../../../utils/maps";

export const EducationDegree = ({ value, onChange }) => {
  return (
    <Radio.Group
      onChange={onChange}
      value={value}>
      {Object.keys(educationalDegrees).map(degree =>
        <Radio
          key={degree}
          value={degree}>
          {educationalDegrees[degree]}
        </Radio>
      )}
    </Radio.Group>
  );
};
