import { message } from "antd";
import { useEffect, useState } from "react";
import { AnalyticsService } from "../../../../services/analytics-service";

export function useCompaniesByDimensions({
  batchId,
  programId,
  from,
  until,
  teamMemberCampus,
  mustBeSet = [],
  dimensions = []
}) {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);

  useEffect(() => {
    setLoading(true);

    AnalyticsService.countCompaniesBy({
        dimensions,
        filters: {
          from,
          until,
          batchId,
          programId,
          mustBeSet,
          teamMemberCampus
        }
      })
      .then(result => {
        setResults(result);
      })
      .catch(e => {
        console.error(e);
        message.error("Ha ocurrido un error");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [batchId, programId, from, until, teamMemberCampus]);

  return {
    loading,
    results
  };
}