import React, { useContext } from "react";
import { Form } from "antd";
import { Section, Visibility } from "../../../";
import { ActionButton } from "../../action-button";
import { EntrepreneurshipsForm } from "../form";
import {
  EditProfileContext
} from "../../../../../contexts/shared/edit-profile";
import { formatValues } from "./format-values";

export function EntrepreneurshipsEditSection({ editable = false }) {
  const {
    saveChanges,
    editing,
    setEditing,
    data
  } = useContext(EditProfileContext);

  const oldEntrepreneurships = data?.user?.expertProfile?.professionalExperience?.entrepreneurships || [];

  const onSave = () => {
    const values = form.getFieldsValue();
    const entrepreneurships = formatValues(values?.entrepreneurships, oldEntrepreneurships);

    saveChanges(entrepreneurships, "entrepreneurships");
  };

  const [form] = Form.useForm();

  return (
    <Section
      spaced
      title="Emprendimientos"
      bottom={
        <Visibility visible={editable}>
          <ActionButton
            editing={editing.entrepreneurships}
            setEditing={setEditing}
            formName="entrepreneurships"
            visible
            onSave={onSave} />
        </Visibility>}>
      <EntrepreneurshipsForm
        form={form}
        editable={editing.entrepreneurships && editable} />
    </Section>
  );
}
