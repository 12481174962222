import React, { useContext } from "react";
import { message } from "antd";
import { MonitoringServicesTable } from "./table";
import { ServiceAcceptanceModalContext } from "../../../../../../contexts/entrepreneur/experts/services/monitoring/service-acceptance-modal";
import { ScheduledExpertServiceService } from "../../../../../../services/deprecated/scheduled-expert-service-service";
import { ServiceDetailModalContext } from "../../../../../../contexts/shared";

export function MonitoringServicesList({
  data,
  loading,
  refetch = null
}) {

  const {
    addOnSaveListener,
    addOnRejectListener,
    closeModal
  } = useContext(ServiceAcceptanceModalContext);

  const {
    addOnCancelRequestListener,
    closeModal: closeDetailModal
  } = useContext(ServiceDetailModalContext);

  const scheduledExpertServiceService = new ScheduledExpertServiceService();

  const onAccept = async ({
    scheduledServiceId,
    entrepreneurId
  }) => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      await scheduledExpertServiceService
        .acceptDelivery(scheduledServiceId, entrepreneurId);

      refetch && await refetch();
      message.success("Entrega aceptada.");
      closeModal();
    } catch (error) {
      console.error(error);
      message.error("Ocurrió un error.");
    } finally {
      dismissLoader();
    }
  };

  const onReject = async ({
    scheduledServiceId,
    entrepreneurId
  }) => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      await scheduledExpertServiceService
        .rejectDelivery(scheduledServiceId, entrepreneurId);

      refetch && await refetch();
      message.warning("Entrega rechazada.");
      closeModal();
    }
    catch (e) {
      console.error(e);
      message.error("Ocurrio un error.\
        Por favor intenta de nuevo en unos momentos.");
    }
    finally {
      dismissLoader();
    }
  };

  const onCancelRequest = async ({
    currentUserId,
    scheduledExpertService
  }) => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      const assignedServiceId = scheduledExpertService.assignedExpertService.id;
      const units = Number(scheduledExpertService.duration);

      await scheduledExpertServiceService
        .cancel(scheduledExpertService.id, currentUserId);

      await scheduledExpertServiceService.refundAssignedUnits({
        assignedServiceId,
        units
      });

      refetch && await refetch();
      message.success("Solicitud cancelada.");
      closeDetailModal();
    }
    catch (e) {
      console.error(e);
      message.error("Ocurrio un error.\
        Por favor intenta de nuevo en unos momentos.");
    }
    finally {
      dismissLoader();
    }
  };

  addOnSaveListener(onAccept);
  addOnRejectListener(onReject);
  addOnCancelRequestListener(onCancelRequest);

  return (
    <MonitoringServicesTable
      loading={loading}
      scheduledExpertServices={data?.scheduledExpertServices} />
  );
}
