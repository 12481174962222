import { client } from "../../../graphql";
import { getCompanyBatchesByCoordinator } from "./graphql";
import {
  getBatchServices,
  getServicesLightQuery,
  disconnectService,
  updateBatchServicesConnection
} from "./graphql";
import { getSingle } from "./get-single";
import { getPerks } from "./get-perks";
import { getCompanyPerks } from "./get-company-perks";
import { importBatchInstitutions } from "./import-batch-institutions";
import { removeCompany } from "./remove-company";
import { toggleStatusCompany } from "./toggle-status-company";
import { getCalendarEvents } from "./get-calendar-events";
import { getAll } from "./get-all";
import { getByProgram} from "./get-by-program";
import { getLightBatchCompanies } from "./get-light-batch-companies";

/**
 * @param {object} args
 * @param {string} args.batchId Required. This field allows to find the ID
 * @param {string[]} args.types Optional. Allows to filter by a set of service
 * types
 */
async function getServices({
  batchId = "",
  types
}) {
  const result = await client.query({
    query: getBatchServices,
    variables: {
      id: batchId,
      type_in: types // graphql will ignore it if it's undefined
    },
    fetchPolicy: "network-only"
  });

  return result.data?.expertServices || [];
}

/**
 * @param {object} args
 * @param {string} args.batchId Required. This field allows to find the ID
 * @param {string[]} args.types Optional. Allows to filter by a set of service
 * types
 */
async function getAvailableServicesToConnect({ batchId = "", types }) {
  const batchServicesResult = await client.query({
    query: getBatchServices,
    variables: {
      id: batchId
    },
    fetchPolicy: "network-only"
  });

  const alreadyConnectedServices = batchServicesResult.data?.expertServices;

  const result = await client.query({
    query: getServicesLightQuery,
    variables: {
      id_not_in: alreadyConnectedServices.map(({ id }) => id),
      type_in: types // graphql will ignore it if it's undefined
    }
  });

  return result.data?.expertServices;
}

async function diconnectService({
  batchId,
  serviceId
}) {
  const result = await client.mutate({
    mutation: disconnectService,
    variables: {
      batchId,
      serviceId
    }
  });

  return result;
}

async function connectServices({
  batchId,
  serviceIds = []
}) {
  const result = await client.mutate({
    mutation: updateBatchServicesConnection,
    variables: {
      batchId,
      services: {
        connect: serviceIds.map(id => ({ id }))
      }
    }
  });

  return result;
}

async function getCompanyBatchesByCoord({companyId, coordId}) {
  const result = await client.query({
    query: getCompanyBatchesByCoordinator,
    fetchPolicy: "network-only",
    variables: {
      companyId,
      coordId
    }
  });
  return result.data?.batches || [];
}

export const BatchesService = {
  getServices,
  getAvailableServicesToConnect,
  diconnectService,
  connectServices,
  getCompanyBatchesByCoord,
  getSingle,
  getPerks,
  getCompanyPerks,
  importBatchInstitutions,
  removeCompany,
  toggleStatusCompany,
  getCalendarEvents,
  getAll,
  getByProgram,
  getLightBatchCompanies
};
