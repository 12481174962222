import React from "react";
import { useHistory } from "react-router-dom";
import { MainMenu } from "../../../../../components/admin/shared";
import { SidebarLayout } from "../../../../../components/shared";
import { StepsNavigation } from "../../../../../components/admin/batches/new/steps-navigation";
import { useQuery } from "@apollo/react-hooks";
import { admin } from "../../../../../graphql/admin";
import { client } from "../../../../../../graphql";
import { useParams } from "react-router";
import { ControllerButtons } from "../../../../../components/admin/batches/new/controller-buttons";
import { default as LadingBatch } from "../../../../../components/admin/batches/new/publish";
import { shared } from "../../../../../graphql/shared";

export const NewBatchPublishIndex = () => {
  const history = useHistory();
  const { id: idBatch } = useParams();

  const { data, error, loading } = useQuery(admin.queries.getBatchById, {
    client,
    fetchPolicy: "network-only",
    variables: {
      id: idBatch
    }
  });

  const {
    data: dataContent, error: errorContent, loading: loadingContent
  } = useQuery(shared.queries.getMasterContentByBatchId, {
    client,
    variables: {
      id: idBatch
    }
  });

  const onNext = () => history.push("/v2/coordinator/batches");
  const onPrev = () => history.push("./content");

  return (
    <SidebarLayout menu={<MainMenu />}>
      <StepsNavigation current={4} />
      <ControllerButtons
        onPrev={onPrev}
        onNext={onNext}
        publish />
      <LadingBatch
        content={dataContent}
        data={data}
        error={error || errorContent}
        isLoading={loading || loadingContent} />
    </SidebarLayout>
  );
};
