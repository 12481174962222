import React from "react";
import { List, Button } from "antd";
import { EditOutlined, DeleteOutlined, SolutionOutlined } from "@ant-design/icons";
import { Avatar } from "../../../avatar";

export function ReportListItem({ report, onDelete, onEdit, onView }) {
  const date = new Date(report.startDate);
  const spanishMonth = date
    .toLocaleDateString("es-ES", { month: "long" })
    .toUpperCase();

  return (
    <List.Item
      actions={[
        <Button
          key="edit"
          onClick={onEdit}
          shape="circle"
          icon={<EditOutlined />} />,
        <Button
          key="delete"
          shape="circle"
          onClick={() => onDelete(report.id)}
          icon={<DeleteOutlined />} />,
        <Button
          key="view"
          onClick={onView}>Ver</Button>]}>
      <List.Item.Meta
        avatar={
          <Avatar
            size="large"
            color="#EB2F96">
            <SolutionOutlined
              style={{
                fontSize: "25px",
                marginTop: "7px",
                color: "white"
              }} />
          </Avatar>
        }
        title={spanishMonth}
        description={`Fecha de creación: \
          ${date.toLocaleDateString()}`} />
    </List.Item>
  );
}
