/**
 * @param {*} state Auth variables
 * @param {*} signingFunction The sign-up or sign-in function to perform
 */
export const setAuthAccount = async (state, signingFunction) => {
  return await signingFunction({
    variables: {
      ...state
    }
  });
};
