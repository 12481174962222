import { getOdeByIdService } from "./get-ode-by-id-service";
import { getPublishedContentsService } from "./get-published-contents-service";
import { getCustomOdeContentsService } from "./get-custom-ode-contents-service";
import { getOdesByBatchIdService } from "./get-odes-by-batch-id-service";
import {
  getMasterContentByBatchIdService
} from "./get-master-content-by-batch-id-service";
import {
  disconnectOdeFromBatchService
} from "./disconnect-ode-from-batch-service";
import {
  connectOdesFromBatchService
} from "./connect-odes-from-batches-service";
import {
  getAssignedExpertServicesService
} from "./get-assigned-expert-services-service";
import {
  createPublishedContentService
} from "./create-published-content-service";
import {
  deleteAssignedExpertServicesService
} from "./delete-assigned-expert-services-service";
import { getAvailableServicesService } from "./get-available-services-service";
import { getScheduledExpertServices } from "./get-scheduled-expert-services";
import {
  activeStatusOdesFromBatchService
} from "./active-status-odes-from-batch-service";

export const OdesService = {
  getOdeByIdService,
  getPublishedContentsService,
  getCustomOdeContentsService,
  getOdesByBatchIdService,
  getMasterContentByBatchIdService,
  getAssignedExpertServicesService,
  disconnectOdeFromBatchService,
  connectOdesFromBatchService,
  createPublishedContentService,
  deleteAssignedExpertServicesService,
  getAvailableServicesService,
  getScheduledExpertServices,
  activeStatusOdesFromBatchService
};
