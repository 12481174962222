import React from "react";
import { Switch, Route } from "react-router-dom";
import PersonalInformationIndex from "../../../pages/entrepreneur/profile";
import UncompletedProfileWarning
  from "../../../pages/entrepreneur/uncompleted-profile-warning";

export const EntrepreneurProfileRoutes = (
  <Switch>
    <Route
      path="/v2/entrepreneur/profile/uncompleted-profile-warning"
      component={UncompletedProfileWarning} />
    <Route
      path="/v2/entrepreneur/profile/personal-information"
      component={PersonalInformationIndex} />
  </Switch>
);
