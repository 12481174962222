import gql from "graphql-tag";

export const deleteCompanyMultimedia = gql`
  mutation DeleteCompanyMultimedia(
    $id: ID!,
    $fileId: ID!
  ){
    updateODE(
      where: { id: $id }
      data: {
        files: {
          delete: { id: $fileId }
        } 
      }
    ){
      id
    }
  }
`;
