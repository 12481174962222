import React from "react";
import { Modal, Form, Input, message, Typography } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { client } from "../../../../graphql";
import { admin } from "../../../graphql/admin";
import { shared } from "../../../graphql/shared";
import { genericFieldValidationMsgs } from "../../../utils";

export function ObjectiveContentModal({
  onSave,
  onCancel,
  objectiveId,
  ...props
}) {
  const [form] = Form.useForm();

  const [createObjectiveContent] = useMutation(
    admin.mutations.createObjectiveContent,
    {
      refetchQueries: [{
        query: shared.queries.getContentsByObjectiveId,
        variables: { id: objectiveId }
      }],
      client
    }
  );

  const onOk = async() => {
    const dismissLoading = message.loading("Guardando...");

    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      values.type = "link";

      const newContent = await createObjectiveContent({
        variables: {
          objectiveId,
          data: values
        }
      });

      message.success("Contenido creado.");

      onSave && onSave(newContent);
      form.resetFields();
    }
    catch(e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    }
    finally {
      dismissLoading();
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  return (
    <Modal
      destroyOnClose
      okText="Guardar"
      cancelText="Cancelar"
      title="Agregar contenido"
      onOk={onOk}
      onCancel={onCancelModal}
      {...props}>
      <Form
        validateMessages={genericFieldValidationMsgs}
        form={form}
        layout="vertical">
        <Typography.Paragraph>
          Agrega enlaces a cursos o videos que el emprendedor debe analizar o
          aprender para apoyarse durante el programa.
        </Typography.Paragraph>
        <Form.Item
          rules={[{ required: true }]}
          name="fileName"
          label="Nombre">
          <Input placeholder="Nombre del contenido" />
        </Form.Item>
        <Form.Item
          name="url">
          <Input placeholder="Enlace al contenido" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
