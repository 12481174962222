import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  default as PersonalInformation
} from "../../../pages/expert/new/personal-information";
import {
  default as ProfessionalInformation
} from "../../../pages/expert/new/id/professional-information";
import { default as Accounts } from "../../../pages/expert/new/id/accounts";
import {
  default as Entrepreneurships
} from "../../../pages/expert/new/id/entrepreneurships";

export const ExpertNewRoutes = <Switch>
  <Route
    path="/v2/expert/new/personal-information"
    component={PersonalInformation} />
  <Route
    path="/v2/expert/new/:userId/personal-information"
    component={PersonalInformation} />
  <Route
    path="/v2/expert/new/:userId/professional-information"
    component={ProfessionalInformation} />
  <Route
    path="/v2/expert/new/:userId/accounts"
    component={Accounts} />
  <Route
    path="/v2/expert/new/:userId/entrepreneurships"
    component={Entrepreneurships} />
</Switch>;
