import React from "react";
import { Route, Switch } from "react-router";
import { BatchesIndex } from "../../../pages/entrepreneur/batches";
import { EntrepreneursIndex } from "../../../pages/entrepreneur/batches/show/entrepreneurs";
import { ExpertsIndex } from "../../../pages/entrepreneur/batches/show/experts";
import { ObjectivesIndex } from "../../../pages/entrepreneur/batches/show/objectives";
import { BatchODEsIndex } from "../../../pages/entrepreneur/batches/show/odes";
import { CoordinatorsIndex } from "../../../pages/entrepreneur/batches/show/coordinators";
import { ObjectiveOdeIndex } from "../../../pages/entrepreneur/batches/show/odes/objectives";
import { PerksIndex } from "../../../pages/entrepreneur/batches/show/perks";
import { TasksIndex } from "../../../pages/entrepreneur/batches/show/tasks";
import { TrainingsIndex } from "../../../pages/entrepreneur/batches/show/trainings";
import { CalendarIndex } from "../../../pages/entrepreneur/batches/show/calendar";
import { AnnouncementsIndex } from "../../../pages/entrepreneur/batches/show/announcements";
import { AnnouncementsIndexDetail } from "../../../pages/coordinator/batches/show/announcements/detail";
import BatchOfferIndex from "../../../pages/entrepreneur/batches/show/offer";

export const EntrepreneurBatchesRoutes = (
  <Switch>
    <Route
      exact
      path="/v2/entrepreneur/batches"
      component={BatchesIndex} />
    <Route
      exact
      path="/v2/entrepreneur/batches/:id/offer"
      component={BatchOfferIndex} />
    <Route
      path="/v2/entrepreneur/batches/:id/entrepreneurs"
      component={EntrepreneursIndex} />
    <Route
      exact
      path="/v2/entrepreneur/batches/:id/odes"
      component={BatchODEsIndex} />
    <Route
      path="/v2/entrepreneur/batches/:id/odes/:odeId/objectives"
      component={ObjectiveOdeIndex} />
    <Route
      path="/v2/entrepreneur/batches/:id/objectives"
      component={ObjectivesIndex} />
    <Route
      path="/v2/entrepreneur/batches/:id/experts"
      component={ExpertsIndex} />
    <Route
      path="/v2/entrepreneur/batches/:id/coordinators"
      component={CoordinatorsIndex} />
    <Route
      path="/v2/entrepreneur/batches/:id/perks"
      component={PerksIndex} />
    <Route
      path="/v2/entrepreneur/batches/:id/tasks"
      component={TasksIndex} />
    <Route
      path="/v2/entrepreneur/batches/:id/trainings"
      component={TrainingsIndex} />
    <Route
      path="/v2/entrepreneur/batches/:id/calendar"
      component={CalendarIndex} />
    <Route
      path="/v2/entrepreneur/batches/:id/announcements"
      exact={true}
      component={AnnouncementsIndex} />
    <Route
      path="/v2/entrepreneur/batches/:id/announcements/:announcementsId"
      component={AnnouncementsIndexDetail} />
  </Switch>
);
