import gql from "graphql-tag";

export const disableExpertService = gql`
  mutation DisableExpertService($id: ID!) {
    updateExpertService(
      where: { id: $id }
      data: { disabled: true }
    ) {
      id
    }
  }
`;
