const unorderedCommercialBussines = [
  {
    label: "Economía colaborativa",
    value: "SHARE_ECONOMY"
  },
  {
    label: "Industrial",
    value: "INDUSTRIAL"
  },
  {
    label: "Comercial",
    value: "COMMERCIAL"
  },
  {
    label: "Servicios",
    value: "SERVICES"
  },
  {
    label: "Financiero",
    value: "FINANCIAL"
  }
];

export const commercialBussines = unorderedCommercialBussines.sort((a, b) => {
  return a.label.localeCompare(b.label);
});