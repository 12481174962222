export function useFoundationYearChart (organizations = []) {

  function rangeYear () {
    const max = new Date().getFullYear();
    const min = max - 115;
    const years = [];

    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years.sort((a, b) => a - b);
  }
  const years = rangeYear();

  const formattedYears = JSON.parse(JSON.stringify(
    years.reduce((acc, curr) => {
      acc[curr] = 0;
      return acc;
    }, {})
  ));

  const organizationsByFoundationYear = organizations.reduce((acc, curr) => {
    const foundationYear = curr.startedAt
      ? new Date(curr.startedAt).getFullYear()
      : new Date(curr.createdAt).getFullYear();
    formattedYears[foundationYear] += 1;
    return acc;
  }, formattedYears);

  const chartData = Object.values(organizationsByFoundationYear).slice(-10);

  return {
    chartData,
    labels: years.slice(-10)
  };
}
