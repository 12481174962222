import React from "react";
import { Button, Modal, Typography, Space } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

export function DataExportModal({
  exportLoading,
  exportUrl,
  onCancel,
  ...props
}) {

  return (
    <Modal
      title="Exportar"
      onCancel={onCancel}
      footer={[
        <Button
          loading={exportLoading}
          key="cancel"
          type="secondary"
          onClick={onCancel}>
          Cerrar
        </Button>
      ]}
      {...props}>
      {exportLoading
        ? <Typography.Title
            style={{ textAlign: "center" }}
            level={5}>
          Espera un momento, tu archivo se está generando
        </Typography.Title>
        : (
          <Space
            direction="vertical" align="center"
            style={{ width: "100%" }}>
            <Typography.Title
              style={{ textAlign: "center" }}
              level={5}>
              Tu archivo se generó correctamente
            </Typography.Title>
            <a
              target="_blank"
              href={exportUrl}>
              <DownloadOutlined /> Descárgalo aquí
            </a>
          </Space>
        )
      }
    </Modal>
  );
}
