import gql from "graphql-tag";

export const getOdeUpdate = gql`
  query GetOdeUpdate(
    $id: ID!
  ){
    odeUpdate(
      where: { id: $id }
    ) {
      id
      ode {
        id
      }
      batch {
        id
      }
      period
      currentGoal
      productProgress
      businessProgress
      nextSteps
      issues
      satisfactionRate
    }
  }
`;
