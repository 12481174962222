import React from "react";
import { Row, Col, Card } from "antd";
import * as Charts from "./charts";

const cardGridSizes = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12, xxl: 8 };

export function BatchesBoardCharts({ loading = false, odes = [] }) {
  return (
    <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
      <Col {...cardGridSizes}>
        <Card
          loading={loading}>
          <h4>Tipo de tecnología</h4>
          <Charts.TechnologyTypeChart odes={odes} />
        </Card>
      </Col>
      <Col {...cardGridSizes}>
        <Card
          loading={loading}>
          <h4>Industria y Sector</h4>
          <Charts.IndustrySectorChart odes={odes} />
        </Card>
      </Col>
      <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 }}>
        <Card
          loading={loading}>
          <h4>Nivel de TRL de productos tecnológicos</h4>
          <Charts.TRLChart odes={odes} />
        </Card>
      </Col>
      <Col {...{ xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 }}>
        <Card
          loading={loading}>
          <h4>Ubicación de la Compañía</h4>
          <Charts.OdeLocationChart odes={odes} />
        </Card>
      </Col>
      <Col {...cardGridSizes}>
        <Card
          loading={loading}>
          <h4>Carreras y areas</h4>
          <Charts.CareerAreaChart odes={odes} />
        </Card>
      </Col>
      <Col {...cardGridSizes}>
        <Card
          loading={loading}>
          <h4>Género y edad</h4>
          <Charts.GenderAgeChart odes={odes} />
        </Card>
      </Col>
      <Col {...cardGridSizes}>
        <Card
          loading={loading}>
          <h4>Relación con el Tec</h4>
          <Charts.TecRelationshipChart odes={odes} />
        </Card>
      </Col>
    </Row>
  );
}
