import React from "react";
import { List, Button, Typography } from "antd";
import { SolutionOutlined, CommentOutlined } from "@ant-design/icons";
import { getSpanishMonth } from "../../../helpers";

export function OdeUpdateListItem({
  odeUpdate,
  openDetailsModal,
  openCommentsModal
}) {
  const date = new Date(odeUpdate.period);

  const numberOfComments = odeUpdate?.comments?.length;

  return (
    <List.Item
      actions={[
        <Button
          onClick={() => openDetailsModal(odeUpdate.id)}
          icon={<SolutionOutlined />}
          key="report">
          Reporte
        </Button>,
        <Button
          onClick={() => openCommentsModal(odeUpdate.id)}
          icon={<CommentOutlined />}
          key="comments">
          Comentarios {numberOfComments ?
            `(${odeUpdate?.comments?.length})`
            : ""}
        </Button>
        ]}>
      <Typography.Title level={4} style={{ fontSize: "16px" }}>
        Actualización {getSpanishMonth(date)} de {date.getFullYear()}
      </Typography.Title>
    </List.Item>
  );
}
