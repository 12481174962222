import React from "react";
import { Image } from "antd";
import DRAG_ICON from "../../../../assets/drag-icon.svg";

/**
 * @todo Allow to customize vector icon, if necessary via props
 * @returns {*|JSX.Element}
 * @constructor
 */

export const DragIcon = ({ style }) => (
  <div style={{display: "flex", alignItems: "center", ...style }}>
    <Image
      width="1rem"
      src={DRAG_ICON}
      preview={false} />
  </div>
);

export const SvgImage = ({ style, svg, width }) => (
  <div style={{display: "flex", alignItems: "center", ...style }}>
    <Image
      width={width || "1rem"}
      src={svg}
      preview={false} />
  </div>
);
