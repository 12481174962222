import React, { useEffect, useState } from "react";
import { Modal, Form, Input, DatePicker, Checkbox } from "antd";
import { merge } from "lodash";
import moment from "moment";
import { fieldValidationMessages } from "../../../../../../../utils";

export function CourseModal({ onSave, onCancel, edit, ...props }) {
  const [state, setState] = useState({
    onGoing: false
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        ...edit,
        startDate: edit?.startDate ? moment(edit?.startDate) : null,
        endDate: edit?.endDate ? moment(edit?.endDate) : null
      });
    }
  }, [edit]);

  const onOk = async() => {
    try {
      await form.validateFields();
      let values = form.getFieldsValue();

      if (typeof edit?.index !== "undefined") {
        values.index = edit.index;
        values = merge(edit, values);
      }

      onSave(values);
      form.resetFields();
    }
    catch(e) {
      console.error(e);
    }
  };

  const handleOnCancel = e => {
    if (onCancel) {
      onCancel(e);
    }
    form.resetFields();
  };

  const toggleOngoing = () => {
    setState(prevState => ({
      ...prevState,
      onGoing: !prevState?.onGoing
    }));

    form.setFieldsValue({ dates: undefined });
  };

  return (
    <Modal
      className="modal-courses"
      okText="Guardar"
      cancelText="Cancelar"
      title="Cursos y certificaciones"
      onOk={onOk}
      onCancel={handleOnCancel}
      {...props}>
      <Form
        validateMessages={fieldValidationMessages}
        form={form}
        layout="vertical">
        <Form.Item
          name="name"
          label="Nombre"
          rules={[{ required: true }]}>
          <Input placeholder="Nombre del curso o certificación" />
        </Form.Item>
        <Form.Item
          name="endorsingInstitution"
          label="Institución que avaló el curso"
          rules={[{ required: true }]}>
          <Input placeholder="Institución que avaló el curso" />
        </Form.Item>
        <Form.Item label="En curso">
          <Checkbox
            checked={state.onGoing}
            onChange={toggleOngoing} />
        </Form.Item>
        <Form.Item
          name="startDate"
          label="Fecha de inicio"
          rules={[{ required: true }]}>
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        { !state.onGoing &&
          <Form.Item
            name="endDate"
            label="Fecha de término">
            <DatePicker style={{ width: "100%" }} />
          </Form.Item> }
      </Form>
    </Modal>
  );
}
