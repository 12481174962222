import React from "react";
import {
  ServicesIndex
} from "../../../pages/entrepreneur/experts/services";
import {
  default as ProfileIndex
} from "../../../pages/entrepreneur/experts/show/profile";
import {
  ExpertsIndex
} from "../../../pages/entrepreneur/experts";
import {
  MonitoringServicesIndex
} from "../../../pages/entrepreneur/experts/services/monitoring";
import { Route, Switch } from "react-router-dom";

export const EntrepreneurExpertsRoutes = <Switch>
  <Route
    exact
    path="/v2/entrepreneur/experts/services/monitoring"
    component={MonitoringServicesIndex} />
  <Route
    exact
    path="/v2/entrepreneur/experts/services"
    component={ServicesIndex} />
  <Route
    exact
    path="/v2/entrepreneur/experts/:expertId/profile"
    component={ProfileIndex} />
  <Route
    exact
    path="/v2/entrepreneur/experts"
    component={ExpertsIndex} />
</Switch>;
