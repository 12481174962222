import gql from "graphql-tag";

export const getNotificationsByUserId = gql`
  query GetNotificationsByUserById($id: ID!) {
    notifications(where: { user: { id: $id }, read: false }) {
      id
      tag
      data
      createdAt
    }
  }
`;
