import React, { useContext, useEffect } from "react";
import { Form } from "antd";
import { FormContainer } from "../../../";
import {
  EditProfileContext
} from "../../../../../contexts/shared/edit-profile";
import { PapersInput } from "./papers-input";

export function PapersForm({ editable = true, form }) {
  const { data } = useContext(EditProfileContext);

  useEffect(() => {
    const { papers = [] } = data?.user?.expertProfile?.professionalExperience ?? {};
    if (papers.length) {
      form.setFieldsValue({ papers });
    }
  }, [data]);

  return (
    <FormContainer>
      <Form form={form}>
        <Form.Item
          name="papers">
          <PapersInput
            editable={editable} />
        </Form.Item>
      </Form>
    </FormContainer>
  );
}
