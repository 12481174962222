import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  SidebarLayout,
  Section,
  IconLabel
} from "../../../../../components/shared";
import { MainMenu } from "../../../../../components/admin/shared";
import { DataConnectionService } from "../../../../../services";
import { admin } from "../../../../../graphql/admin";
import { MenuContext } from "../../../../../contexts/shared/menu";
import {
  formatMonitoringReportFilters,
  MonitoringServicesList
} from "../../../../../components/admin/experts/services/monitoring";
import {
  useControlledPagination,
  useQueryFilters
} from "../../../../../helpers";
import {
  CurrentUserContext,
  PaginationContext,
  ServiceDetailModalProvider
} from "../../../../../contexts/shared";
import { FilterControls } from "../../../../../components/admin/experts/services/monitoring/filter-controls";
import { message } from "antd";

export function MonitoringServicesIndex() {
  const [state, setState] = useState({ data: undefined });

  const { currentWorkspace } = useContext(CurrentUserContext);

  const variables = {};

  if(currentWorkspace.id) {
    variables.workspace = { id: currentWorkspace.id };
  }

  const { loading, error, data, refetch } = useQuery(
    admin.queries.getMonitoringServices,
    {
      fetchPolicy: "network-only",
      client: DataConnectionService.graphqlApolloClient,
      variables: {
        ...variables,
        first: 100
      }
    }
  );

  useEffect(() => {
    setState({ data });
  }, [data]);

  const { setMenuState } = useContext(MenuContext);

  const defaultFilters = { where: {} };
  if (currentWorkspace.id) {
    variables.workspace = { id: currentWorkspace.id };
    defaultFilters.where = {
      workspace: { id: currentWorkspace.id }
    };
  }

  const { applyFilters, filters } = useQueryFilters({
    refetch,
    defaultFilters: {
      ...defaultFilters,
      first: 100
    }
  });

  const onExport = async ({
    turnExportLoaderOn,
    dismissExportLoader,
    variables
  }) => {
    const where = formatMonitoringReportFilters(filters);

    try {
      turnExportLoaderOn();
      const response = await DataConnectionService.graphqlApolloClient.query({
        query: admin.queries.getScheduledExpertServicesReport,
        fetchPolicy: "network-only",
        variables: {
          where: where,
          markAsPaid: variables.markAsPaid
        }
      });

      await refetch();

      window.open(response.data.reportScheduledExpertServices.url, "_blank");
    }
    catch(e) {
      console.error(e);

      message.error("Ocurrio un error al generar el reporte,\
        inténtalo de nuevo en unos segundos.");
    }
    finally {
      dismissExportLoader();
    }
  };

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 100
  });

  const onFilterChange = filter => {
    const { skip, pageSize: first } = onPaginationChange(1);

    applyFilters((prevFilters = {}) => {
      const updatedFilters = {
        where: {
          ...(prevFilters.where ?? {}),
          ...filter
        },
        skip,
        first
      };

      if (currentWorkspace.id) {
        updatedFilters.where.workspace = { id: currentWorkspace.id };
      }

      return updatedFilters;
    });
  };

  /* set menu's current state */
  useEffect(() => {
    setMenuState({
      openKeys: ["experts"],
      selectedKeys: ["experts/services/monitoring"]
    });
  }, []);

  if (error) {
    return (
      <IconLabel icon={<ExclamationCircleOutlined />}>
        Ocurrió un error
      </IconLabel>
    );
  }

  const { registerRefetch } = useContext(PaginationContext);

  registerRefetch("monitoring", refetch, variables);

  return (
    <ServiceDetailModalProvider refetch={refetch}>
      <SidebarLayout menu={<MainMenu />}>
        <Section
          spaced
          extra={
            <FilterControls
              onChange={onFilterChange}
              onClickExport={onExport} />
          }>
          <MonitoringServicesList
            refetch={refetch}
            currentPage={currentPage}
            onChangePage={onPaginationChange}
            loading={loading}
            data={state.data} />
        </Section>
      </SidebarLayout>
    </ServiceDetailModalProvider>
  );
}
