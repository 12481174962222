import { useContext } from "react";
import { CurrentUserContext } from "../../contexts/shared";

export const useLoggedUser = () => {
  const { currentUser: loggedUser } = useContext(CurrentUserContext);
  const { systemRole } = loggedUser || {};

  return {
    loggedUser,
    systemRole
  };
};
