import React from "react";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Visibility } from "..";

export function EditButton({onClick, visible = true, ...props}) {
  return (
    <Visibility visible={visible}>
      <Button
        type="link"
        icon={<EditOutlined />}
        onClick={onClick}
        {...props} />
    </Visibility>
  );
}
