import { client } from "../../../graphql";
import { getPerksQuery } from "./graphql";

/**
 * Returns a list of the Batch perks
 * @param {string} batchId
 * @returns {Promise<any[]>} A list of the Batch perks
 */
export async function getPerks({ batchId }) {
  const result = await client.query({
    query: getPerksQuery,
    fetchPolicy: "network-only",
    variables: { id: batchId }
  });

  return result.data?.perks || [];
}
