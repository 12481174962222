import React, { useMemo } from "react";
import { Typography, Progress } from "antd";
import { getOdeContentPercentage } from "../../../../../../../helpers";

export const GeneralProgress = ({
  masterContent,
  customOdeContent,
  odeContents
}) => {

  const generalProgress = useMemo(() => getOdeContentPercentage(
    {
      odeContents: odeContents || [],
      masterContent,
      customOdeContent
    }
  ), [customOdeContent, masterContent, odeContents]);

  return (
    <div style={{ display: "flex", marginBottom: "15px", marginTop: "13px" }}>
      <Typography.Title
        level={4}
        style={{ color: "#704AED" }}>
        Avance general
      </Typography.Title>
      <Progress
        percent={generalProgress}
        style={{
          width: "354px",
          marginLeft: "25px"
        }} />
    </div>
  );
};

export const SectionProgress = ({ contentGroup, odeContents }) => {

  if(!contentGroup) return null;

  const getSectionProgress = ({ contents = [], odeContents }) => {
    const contentsLength = contents?.length || 0;
    let completedContents = 0;

    odeContents?.forEach(odeContent => {
        if (odeContent.status === "VERIFIED" && odeContent.content) {
          completedContents ++;
        }
    });

    if (contentsLength < 1) return 0;

    return parseInt((completedContents / contentsLength) * 100);
  };

  return <Progress
    style={{ width: "354px" }}
    percent={getSectionProgress({ odeContents, contents: contentGroup?.contents})} />;
};
