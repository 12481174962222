import React, { useContext } from "react";
import { Avatar, Breadcrumb } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import {
  ContentWrapper,
  FormContainer,
  AlertSection, Breadcrumbs
} from "../../shared";
import { EducationAndJob } from "../../../components/entrepreneur/profile";
import { EditProfileContext } from "../../../contexts/shared";
import { Link } from "react-router-dom";

export function GeneralProfileEntrepreneur () {
  const {
    data,
    error,
    loading
  } = useContext(EditProfileContext);

  return (
    <>
      <Breadcrumbs>
        <Breadcrumb.Item>
          <Link to="../../entrepreneurs">
            Perfiles
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{data?.user?.fullName}</Breadcrumb.Item>
      </Breadcrumbs>
      <ContentWrapper>
        <FormContainer>
          <EducationAndJob
            editable
            data={data}
            error={error}
            isLoading={loading} />
        </FormContainer>
      </ContentWrapper>
    </>
  );
}
