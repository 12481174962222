import React from "react";

export function MenuTitle({ icon, text }) {
  return (
    <span>
      {icon}
      <span>{text}</span>
    </span>
  );
}
