import gql from "graphql-tag";

export const updateContentGroup = gql`
  mutation UpdateContentGroup(
    $data: ContentGroupUpdateInput!,
    $where: ContentGroupWhereUniqueInput!
  ) {
    updateContentGroup(data: $data, where: $where) {
      id
    }
  }
`;
