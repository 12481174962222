import React from "react";
import { Typography, Card } from "antd";

export const CardService = ({ title = "", description = "" }) => {
  return (
    <Card
      className="Card__Service"
      title={title}
      bordered={true}>
      <Typography.Paragraph type="secondary">
        {description}
      </Typography.Paragraph>
    </Card>
  );
};
