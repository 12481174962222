import gql from "graphql-tag";

export const connectOdeByBatchId = gql`
  mutation ConnectOdeByBatchId(
    $batchId: ID!, 
    $data: BatchUpdateInput!
  ) {
    updateBatch(
      where: { id: $batchId }
      data: $data
    ) {
      id
    }
  }
`;
