import React, { useContext } from "react";
import { List, Button, Space, Typography } from "antd";
import {
  Avatar, Format, ActionButton, Visibility
} from "../../../../../shared";
import { withRouter } from "../../../../../../helpers";
import { OdeProgress } from "./ode-progress";
import {
  getReadableValue
} from "../../../../../../helpers";
import { CurrentUserContext } from "../../../../../../contexts/shared";
import useTreatment from "../../../../../../hooks/feature-flags/use-treatment";
import { useBatchCompanyActions } from "../../../../../../hooks";
import { BatchOdesContext } from "../../../../../../contexts/admin/batches";

function OdeItem({ ode, onDelete, navigate, onClick = null, batchId, odesSorted }) {
  const odeStatusFromBatchIds = ode?.odeStatusBatch?.map(el => el?.id) || [];
  // Todo: Its possible no contains ode status batch, fallback
  const isActive =
    ((ode?.odeStatusBatch?.[0]?.status === "ACTIVE")
    || !ode?.odeStatusBatch?.length);
  const { currentUser } = useContext(CurrentUserContext);
  const { refetch } = useContext(BatchOdesContext);
  const {
    onRemoveCompany,
    onToggleStatusCompany
  } = useBatchCompanyActions({ batchId, odesSorted });

  const disableBatchOfferTreatmentValue =
    useTreatment("DisableBatchOffer");

  const navigateToBatchDetail = id => {
    if (disableBatchOfferTreatmentValue === "ON") {
      navigate(`./odes/${id}/content`);
    } else if(disableBatchOfferTreatmentValue === "OFF") {
      navigate(`./publish/companies/${id}/content`);
    }
  };

  const sector = ode?.economicSector || ode?.sector;
  const readableSector = getReadableValue("ODE_SECTORS", sector);

  return (
    <List.Item
      className="batch-odes-list-item"
      style={{
        backgroundColor: isActive ? "transparent" : "rgba(196, 196, 196, 0.2)"
      }}>
      <div>
        <List.Item.Meta
          title={
            <Space
              direction={"horizontal"}
              style={{width: "300px"}}>
              <Typography.Text>
                {ode?.name}
              </Typography.Text>
            </Space>
            }
          avatar={
            <Avatar size="large">
              {ode?.logoUrl || ode?.name}
            </Avatar>}
          description={
            <Space
              direction="vertical"
              style={{width: "300px"}}>
              <Typography.Text>
                {readableSector}
              </Typography.Text>
              <Typography.Text type="secondary">
                <Format type="prune" size={250}>
                  {ode?.description}
                </Format>
              </Typography.Text>
            </Space>
          } />
        <Space
          size={100}
          direction="horizontal"
          style={{ justifyContent: "flex-end" }}>
          <OdeProgress ode={ode} />
          <Visibility
            visible={!["ENTREPRENEUR"]
              .includes(currentUser.systemRole)}>
            <Button
              style={{ marginRight: "15px"}}
              disabled={!isActive}
              onClick={() =>
                onClick
                  ? onClick(ode?.id)
                  : navigateToBatchDetail(ode?.id)}>
              Ver
            </Button>
            {/* Todo: Remove onClick release batch offer */}
            {onClick
              ? <ActionButton.New
                  extraActions={[
                    {
                      label: "Remover",
                      action: () => onRemoveCompany(
                        ode?.name,
                        ode?.id,
                        odeStatusFromBatchIds,
                        refetch
                      )
                    },
                    {
                      label: isActive ? "Desactivar" : "Activar",
                      action: () => onToggleStatusCompany(
                        ode?.name,
                        ode?.id,
                        ode?.odeStatusBatch,
                        isActive,
                        refetch
                      )
                    }
                  ]} />
              : (
                <ActionButton
                  onDelete={() => onDelete(ode?.id)}
                  confirmDeleteText=
                  "Estás a punto de remover una Compañía de este batch
                  ¿Deseas continuar?"
                  onDeleteText="Quitar" />
                )
            }
          </Visibility>
        </Space>
      </div>
    </List.Item>
  );
}

export default withRouter(OdeItem);
