import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  AssignationsMonitoringServicesIndex
} from "../../../pages/expert/monitoring/assignations";
import {
  TrackingMonitoringServicesIndex
} from "../../../pages/expert/monitoring/tracking";

export const ExpertMonitoringRoutes = <Switch>
  <Route
    path="/v2/expert/monitoring/assignations"
    component={AssignationsMonitoringServicesIndex} />
  <Route
    path="/v2/expert/monitoring/tracking"
    component={TrackingMonitoringServicesIndex} />
</Switch>;
