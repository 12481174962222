import { BatchMemberService } from "../../services/batch-members-service";
import { message } from "antd";

export const useBatchMembers = () => {
  const errorMsg = "Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.";

  const disconnectMember = async ({
    idBatch,
    idUser,
    type,
    refetch = null
  }) => {
    const dismissLoading = message.loading("Eliminando...");
    try {
      await BatchMemberService
        .disconnect({ idBatch, idUser, type });
      message.success("Eliminado.");
      refetch && await refetch();
    } catch(e) {
      message.error(errorMsg);
      console.error(e);
    } finally {
      dismissLoading();
    }
  };

  const connectMember = async ({
    idBatch,
    idUser,
    type,
    refetch = null
  }) => {
    const dismissLoading = message.loading("Guardando...");
    try {
      await BatchMemberService.connect({ idBatch, idUser, type });
      message.success("Guardado.");
      await refetch();
    } catch(e) {
      message.error(errorMsg);
      console.error(e);
    } finally {
      dismissLoading();
    }
  };

  return {
    disconnectMember,
    connectMember
  };
};