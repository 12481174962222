import gql from "graphql-tag";

export const getFullAssignedExpertServiceById = gql`
  fragment assignedExpertServiceFragment on AssignedExpertService {
    id
    status
    finished
    availableUnits
    assignedUnits
    assignmentNumber
    assignationIdentifier
    limitDate
    assignmentDate
    expertServicePrice
    expertServiceData
    medium
    mediumDescription
    isProbono
    expertService {
      id
      name
      type
      price
      method
      category
      expert {
        id
        fullName
        firstName
        middleName
        lastName
        email
        phone
      }
    }
    batch {
      id
      name
      users {
        id
        fullName
        phone
        email
      }
      program {
        id
        name
        coordinator {
          id
          fullName
          phone
          email
        }
      }
    }
    ode {
      id
      name
      email
      phone
      team {
        id
        fullName
        email
        phone
        systemRole
      }
    }
  }

  query GetFullAssignedExpertServiceById($id: ID!) {
    assignedExpertService(where: { id: $id }) {
      ...assignedExpertServiceFragment
    }
  }
`;
