import React from "react";
import { List, Typography } from "antd";
import { Avatar } from "../../avatar";
import { ActionButton } from "../../index";
import { FlexContainer } from "../../flex-container";

/**
 * List Item. Ideal vertical lists.
 * @param {object} props
 * @param {function} props.onClick
 * @param {React.ReactNode[]} props.extras
 * @param {function} props.onRemove
 * @param {function} props.onDelete
 * @param {function} props.onEdit
 * @param {function} props.onDuplicate
 * @param {{[string]: {label: string, action: function}}} props.extraActions
 * @param {{[string]: {label: string, action: function}}} props.customActions
 * @param {string} props.title
 * @param {React.ReactNode} props.customTitle
 * @param {React.ReactNode} props.children
 * @param {React.ReactNode} props.contentExtra
 * @param {string} props.avatarUrl
 * @param {boolean} props.blockActions Allows to hide the ActionButton
 * @returns {JSX.Element}
 * @constructor
 */
export function RowListItem({
  onClick,
  extras = [],
  onRemove,
  onDelete,
  onEdit,
  onDuplicate,
  extraActions,
  customActions,
  title, // needed to show the avatar letters
  customTitle, // use whatever as title
  children, // content
  contentExtra,
  user,
  blockActions = false
}) {
  const { profilePicUrl, fullName } = !!user && user;

  const shouldShowActionButton =
    ([onRemove, onDelete, onEdit, onDuplicate].filter(action => !!action))
      ?.length > 0
    || Object.keys(extraActions || {})
      .filter(actionKey => !!extraActions[actionKey])
      ?.length > 0
    || Object.keys(customActions || {})
      .filter(actionKey => !!customActions[actionKey])
      ?.length > 0;

  let actions = [];
  if (extras?.length > 0) actions = extras;

  if (shouldShowActionButton && !blockActions) {
    actions.push(
      <ActionButton.New
        onRemove={onRemove}
        onDelete={onDelete}
        onEdit={onEdit}
        onDuplicate={onDuplicate}
        extraActions={extraActions}
        customActions={customActions} />
    );
  }

  return (
    <List.Item
      actions={actions}>
      <List.Item.Meta
        onClick={onClick}
        className={onClick ? "clickable" : undefined}
        title={customTitle
          ? customTitle
          : <Typography.Text>{title}</Typography.Text>}
        avatar={
          <Avatar
            size="large">
            {profilePicUrl || fullName}
          </Avatar>}
        description={
          <FlexContainer
            direction="vertical"
            style={{width: "300px"}}>
            {children}
            {contentExtra}
          </FlexContainer>
        } />
    </List.Item>
  );
}
