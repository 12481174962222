import React, { useState } from "react";
import { InvitationModalContext } from "./context";
import { InvitationModal } from "./modal";

export function InvitationModalProvider({ children, ode = undefined }) {
  const [state, setState] = useState({
    isModalOpen: false,
    edit: undefined,
    systemRole: undefined
  });

  const onSaveListeners = [];

  const openModal = (edit = false, systemRole) => {
    setState({
      isModalOpen: true,
      edit: edit,
      systemRole
    });
  };

  const closeModal = () => {
    setState({ isModalOpen: false, edit: false, systemRole: undefined });
  };

  const onSave = values => {
    onSaveListeners.forEach(i => i(values));
    closeModal();
  };

  const onCancel = () => {
    closeModal();
  };

  const addOnSaveListener = func => {
    onSaveListeners.push(func);
  };

  return (
    <InvitationModalContext.Provider
      value={{ openModal, closeModal, addOnSaveListener }}>
      <InvitationModal
        edit={state.edit}
        systemRole={state.systemRole}
        onSave={onSave}
        onCancel={onCancel}
        ode={ode}
        visible={state.isModalOpen} />
      { children }
    </InvitationModalContext.Provider>
  );
}
