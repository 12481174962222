import React, { useContext } from "react";
import { Typography, Descriptions, Tag, Space } from "antd";
import {
  OdeTag,
  ContactInfo,
  Visibility,
  Format
} from "../../../../../components/shared";
import { CurrentUserContext } from "../../../../../contexts/shared";
import {
  getReadableValue,
  expertPaymentMethods,
  mediumTypes
} from "../../../../../helpers/select-options";

export function ExpertServiceSummary({
  assignedExpertService, // decorated object
  scheduledExpertService
}) {
  const { currentUser } = useContext(CurrentUserContext);
  const userIsEntrepreneur = currentUser?.systemRole === "ENTREPRENEUR";

  return (
    <>
      <Typography.Title level={4}>
        {assignedExpertService.serviceTitle}
      </Typography.Title>
      <p style={{ whiteSpace: "pre-wrap" }}>
        {assignedExpertService.expertServiceData?.description}
      </p>
      <Descriptions
        layout="vertical"
        column={1}
        style={{ marginTop: "20px" }}>
        <Descriptions.Item label="Categoría">
          <Tag color="purple">
            {assignedExpertService.category}
          </Tag>
        </Descriptions.Item>
        {!userIsEntrepreneur ?
          <Descriptions.Item label="Costo">
            <Format type="money">
              {assignedExpertService.expertServicePrice || undefined}
            </Format> /
            &nbsp;
            {getReadableValue(
              expertPaymentMethods,
              assignedExpertService?.expertServiceData?.method)}
            <Typography.Text style={{ marginLeft: "1rem" }} type="danger">
              {assignedExpertService.isProbono && "Probono"}
            </Typography.Text>
          </Descriptions.Item>
          : null}
        {!userIsEntrepreneur ?
          <Descriptions.Item label="Número de asignación">
            {assignedExpertService.assignmentNumber || "N/A"}
          </Descriptions.Item>
        : null}
      </Descriptions>
      <Descriptions
        layout="vertical"
        column={2}>
        <Descriptions.Item
          label={`${assignedExpertService.paymentMethodLabel} asignadas`}>
          <Typography.Text strong>
            {assignedExpertService.assignedUnits || "N/A"}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label={`${assignedExpertService.paymentMethodLabel} consumidas`}>
          <Typography.Text strong>
            {assignedExpertService.consumedUnits || "N/A"}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item
          label={`${assignedExpertService.paymentMethodLabel} restantes`}>
          <Typography.Text
            strong
            style={{ color: Number(assignedExpertService.availableUnits) > 0 ? "#52C41A" : "#E94244"}}>
            {assignedExpertService.availableUnits || "N/A"}
          </Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Fecha límite de consumo">
          <span className="bold">
            <Format type="date">{assignedExpertService.object?.limitDate}</Format>
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Fecha de asignación">
          <Format type="date">{assignedExpertService.object?.assignmentDate}</Format>
        </Descriptions.Item>
        <Descriptions.Item label="Fecha seleccionada por la Compañía">
          {scheduledExpertService.serviceDate ?
            <Format type="date">
              {scheduledExpertService.serviceDate}
            </Format>
            : "Sin fecha"}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha de realización">
          <Format type="date">{scheduledExpertService.deliveredAt}</Format>
        </Descriptions.Item>
        {scheduledExpertService.status === "ACCEPTED" ?
          <Descriptions.Item label="Fecha de aceptación">
            <Format type="date">{assignedExpertService.object?.acceptedAt}</Format>
          </Descriptions.Item>
          : null}
        <Descriptions.Item label="Cantidad">
          {assignedExpertService.expertServiceData.method === "HOURS" ?
            `${scheduledExpertService.duration || "N/A"} horas` : "1 servicio"}
        </Descriptions.Item>
      </Descriptions>
      {!assignedExpertService.isWorkshop &&
        <>
          <Typography.Title level={5}>
            Compañía:
          </Typography.Title>
          <Space size="middle" direction="vertical">
            <OdeTag odeName={assignedExpertService.ode?.name} />
            <Descriptions layout="vertical" column={2}>
              <Descriptions.Item label="Teléfono">
                {assignedExpertService.ode?.phone || "N/A"}
              </Descriptions.Item>
              <Descriptions.Item label="Correo">
                {assignedExpertService.ode?.email || "N/A"}
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </>
      }
      { assignedExpertService.isWorkshop ?
        <>
          <Typography.Title level={5}>
            Capacitación
          </Typography.Title>
          <Descriptions
            layout="vertical"
            column={2}>
            <Descriptions.Item
              label="Modalidad">
              { getReadableValue(mediumTypes, assignedExpertService.medium) }
            </Descriptions.Item>
            <Descriptions.Item
              label={assignedExpertService.medium === "ONLINE"
                ? "Link"
                : "Ubicación"}>
              { assignedExpertService.medium === "ONLINE"
              ? <a href={assignedExpertService.mediumDescription}>
                {assignedExpertService.mediumDescription}
              </a>
              : assignedExpertService.mediumDescription }
            </Descriptions.Item>
          </Descriptions>
        </>
      : null }
      <ContactInfo
        title={`Experto: ${assignedExpertService
          .expertService?.expert?.fullName}`}
        user={assignedExpertService.expertService?.expert} />
      <ContactInfo
        title="Coordinador de programa"
        user={assignedExpertService.batchLeadCoordinator} />
      <Visibility
        visible={
          scheduledExpertService.status !== "ASSIGNED"
          && !assignedExpertService.isWorkshop}>
        <ContactInfo
          title="Emprendedor"
          user={scheduledExpertService.requestedBy} />
      </Visibility>
    </>
  );
}
