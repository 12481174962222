export const developmentPhaseOptions = [
  {
    label: "Prueba de concepto",
    value: "PROOF_CONCEPT"
  },
  {
    label: "Prototipo",
    value: "PROTOTYPE"
  },
  {
    label: "Versión de prueba",
    value: "TRIAL_VERSION"
  },
  {
    label: "Producto terminado",
    value: "FINISHED_PRODUCT"
  }
];
