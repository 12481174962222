import gql from "graphql-tag";

export const getODEsByProgramId = gql`
  query getODEsByProgramId($id: ID!) {
    oDEs (
      where: {
        batches_some: {
          program: { id: $id }
        }
      }
    ) {
      id
      name
    }
  }
`;
