export const contentTypes = [
  { label: "Actividad - Tarea para ejecutar", value: "ACTIVITY", color: "magenta" },
  { label: "Taller - Contenido educativo", value: "WORKSHOP", color: "cyan" },
  { label: "Recurso - Material de apoyo", value: "RESOURCE", color: "gold" }
];

export const entrepreneurContentTypes = [
  { label: "Actividad", value: "ACTIVITY", color: "magenta" },
  { label: "Taller", value: "WORKSHOP", color: "cyan" },
  { label: "Recurso", value: "RESOURCE", color: "gold" }
];

export const textContentTypes = [
  { label: "Actividad", value: "ACTIVITY", color: "#13C2C2" },
  { label: "Taller", value: "WORKSHOP", color: "#EB2F96" },
  { label: "Recurso", value: "RESOURCE", color: "#FA8C16" }
]