import gql from "graphql-tag";

export const getOdesByBatchId = gql`
  query GetOdesByBatchId(
    $id: ID!
    $orderBy: ODEOrderByInput
    $odeName: String
  ) {
    oDEs (
      orderBy: $orderBy,
      where:{
        batches_some: {
          id: $id
        }
        iname_contains: $odeName
      }) {
      id
      name
      sector
      logoUrl
      odeStatusBatch(where: { batch: { id: $id } }){
        id
        status  
      }
      team {
        id
        fullName
        systemRole
        email
        phone
      }
      activities {
        id
        isComplete
        startDate
        finishDate
        batch {
          id
          name
        }
      }
      contents(
        where: {
          batch: {
            id: $id
          }
        }
      ){
        batch{
          id
        }
        content{
          id
        }
        id
        status
        isComplete
        finishDate
      }
      achievements{
        id
        achievement{
          objective{
            id
            batch{
              id
            }
          }
          id
        }
        isComplete
        finishDate
      }
    }
    masterContents(
      where: {
        batch: {
          id: $id
        }
      }
    ) {
      id
      contentGroups {
        id
        contents {
          id
        }
      }
    }
    customOdeContents(
      where: {
        batch: {
          id: $id
        }
      }
    ){
      id
      ode {
        id
      }
      contentGroups {
        id
        contents {
          id
        }
      }
    }
  }
`;
