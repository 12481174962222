import React from "react";
import { Card } from "antd";

export function ExamplesItem({example}) {
  return (
    <Card className="examples-item">
      <a
        href={example.url}
        target="_blank"
        style={{
          display: "block",
          backgroundImage: `url(${example.url})`,
          width: "100%",
          paddingBottom: "100%",
          backgroundSize: "cover"
        }}>
      </a>
    </Card>
  );
}
