import React from "react";
import { List, Alert, Space, Divider } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { shared } from "../../../../../../graphql/shared";
import { client } from "../../../../../../../graphql";
import { UserItem } from "./item";
import { RoleGuard, Section, AddTeamMemberButton } from "../../../../../../components/shared";
import { useQueryFilters, paginationConfig, useControlledPagination } from "../../../../../../helpers";
import { FilterControls } from "./filter-controls";

export function TeamList({ onClickItem, odeId }) {
  const { data, loading, error, refetch } = useQuery(
    shared.queries.getOdeTeamUsers, {
      variables: {
        id: odeId
      },
      client
    });

    const { currentPage, onPaginationChange } = useControlledPagination({
      initialPageSize: 10
    });

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: {
      id: odeId
    }
  });

  if(error) {
    return (
      <Alert
        type="error"
        message="Ha ocurrido un error al cargar los usuarios,
        por favor intentalo de nuevo en unos segundos."
        showIcon />
    );
  }

  const onFilter = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  return (
    <Section
      spaced
      title="Equipo"
      extra={<FilterControls odes={[{ id: odeId } ]} onChange={onFilter} />}>
      <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR"]}>
        <Space style={{ display: "flex", justifyContent: "flex-end" }}>
          <AddTeamMemberButton
            systemRole="ENTREPRENEUR"
            style={{ minWidth: "250px" }} />
          <AddTeamMemberButton
            systemRole="ADVISER"
            style={{ minWidth: "250px" }} />
        </Space>
      </RoleGuard>
      <Divider style={{ marginBottom: "4px", marginTop: "24px" }} />
      <List
        renderItem={i =>
          <UserItem
            user={i}
            onClickItem={() => onClickItem && onClickItem(i)} />}
        itemLayout="vertical"
        pagination={{
          ...paginationConfig,
          current: currentPage,
          onChange: page => onPaginationChange(page)
        }}
        dataSource={data?.oDE?.team}
        loading={loading} />
    </Section>
  );
}
