import React, { useContext } from "react";
import { default as GeneralInformationForm } from "./form";
import { Typography, Card, Space, Progress, message, Form } from "antd";
import { FormNavigation } from "../../../shared";
import { withRouter } from "../../../../helpers";
import { OnboardingContext } from "../../../../contexts/expert/onboarding";

function GeneralInformation({ navigate }) {
  const {
    updateExpert,
    save,
    data,
    error,
    loading
  } = useContext(OnboardingContext);

  const [form] = Form.useForm();

  const onNext = route => async () => {
    await form.validateFields();
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      await save();
      navigate(route);
    }
    catch(e) {
      console.error(e);
      message.error("Ha ocurrido un error, \
      inténtalo de nuevo en unos segundos.");
    }
    finally {
      dismissLoader();
    }
  };

  const onChange = values => {
    updateExpert(values);
  };

  return (
    <Card bordered={false} style={{maxWidth: "600px", margin: "0 auto"}}>
      <Space direction="vertical" size="large">
        <Progress percent={25} showInfo={false} />
        <>
          <Typography.Title>Bienvenido a EOS 👋</Typography.Title>
          <Typography.Text>
            En sencillos pasos podemos dar de alta la información
            para que puedas comenzar a ofrecer tus servicios en
            EOS y ayudes a compañías a llevar sus productos
            al más alto nivel.
          </Typography.Text>
        </>
        <GeneralInformationForm
          data={data}
          error={error}
          isLoading={loading}
          onChange={onChange}
          form={form} />
        <FormNavigation
          onNext={onNext("./experience")}
          onSkip={() =>
            navigate("./success")
          } />
      </Space>
    </Card>
  );
}

export default withRouter(GeneralInformation);
