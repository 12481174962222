import React from "react";
import { Form, Select } from "antd";

export function AddCompanyTeamMembersForm({
  inputLabel,
  inputPlaceholder,
  loadingAvailableUsers,
  availableUsers = [],
  form
}) {
  return (
    <Form
      form={form}
      layout="vertical">
      <Form.Item
        name="users"
        label={inputLabel}
        rules={[{ required: true }]}>
        <Select
          showSearch
          mode="multiple"
          optionFilterProp="label"
          loading={loadingAvailableUsers}
          placeholder={inputPlaceholder || inputLabel}
          options={availableUsers} />
      </Form.Item>
    </Form>
  );
}
