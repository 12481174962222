import React, { useState, useEffect } from "react";
import { Select, Typography } from "antd";
import { useQuery } from "react-apollo-hooks";
import { client } from "../../../../graphql";
import { getUsersBySystemRoleWithExpertSegmentation } from "../../../graphql/shared/queries";
import { Visibility } from "../visibility";

export function UsersSelect({
  onChange,
  systemRoles = [],
  mode = "multiple",
  filterUsers = [],
  batchId = "",
  ...props
}) {
  const [state, setState] = useState({
    options: []
  });

  const { data, loading, error } = useQuery(
    getUsersBySystemRoleWithExpertSegmentation,
    {
      variables: {
        systemRoles,
        filterUsers,
        batchId
      },
      fetchPolicy: "network-only",
      client
    }
  );

  useEffect(() => {
    if (!loading) {
      const users = Array.from(data?.users || []);

      const options = users.map(e => ({
        label: e.fullName,
        value: e.id
      }));

      setState({ options });
    }
  }, [data]);

  const handleOnChange = values => onChange && onChange(values);

  return (
    <>
      <Visibility visible={loading}>
        <Select
          placeholder="Seleccionar usuario"
          className="wide-input"
          disabled={loading}
          loading={loading} />
      </Visibility>
      <Visibility visible={!loading}>
        <Select
          mode={mode}
          placeholder="Seleccionar usuario"
          optionFilterProp="label"
          allowClear
          showSearch
          disabled={loading || error}
          loading={loading}
          onChange={handleOnChange}
          options={state.options}
          {...props} />
      </Visibility>
      <Typography.Text type="danger">
        {error && "No se pudieron cargar los usuarios"}
      </Typography.Text>
    </>
  );
}
