import gql from "graphql-tag";

export const updateExpertProfile = gql`
  mutation UpdateExpertProfile(
  	$expertId: ID!
    $data: UserUpdateInput!
  ) {
    updateUser(
      where: { id: $expertId }
      data: $data
    ) {
      id
    }
  }
`;
