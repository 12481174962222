import { gql } from "graphql-tag";

export const getAssignedServices = gql`
  query GetAssignedServices(
    $entrepreneurId: ID!
    $skip: Int
    $first: Int
    $category: String
    $status: String
    $programId: ID
    $searchString: String
    $type: String
  ) {
    assignedExpertServices(
      orderBy: createdAt_DESC
      where: {
        expertService: {
          category: $category
          type: $type
        }
        status: $status
        batch: {
          program: {
            id: $programId
          }
        }
        ode: {
          team_some: {
            id: $entrepreneurId
          }
        }
        OR: [
          {
            expertService: {
              OR: [
                { iname_contains: $searchString },
                { idescription_contains: $searchString },
                { expert: { ifullName_contains: $searchString } }
              ]
            }
          },
          {
            batch: { program: { iname_contains: $searchString } }
          },
          {
            batch: { iname_contains: $searchString }
          },
          {
            ode: { iname_contains: $searchString }
          }
        ]
      }
      skip: $skip
      first: $first
    ) {
      id
      expertServiceData
      assignmentDate
      limitDate
      status
      assignedUnits
      batch {
        id
        name
        program {
          id
          name
        }
      }
      expertService {
        id
        name
        type
        category
        duration
      }
      ode {
        id
        name
      }
    }
    meta: assignedExpertServicesConnection(
      where: {
        expertService: {
          category: $category
          type: $type
        }
        status: $status
        batch: {
          program: {
            id: $programId
          }
        }
        ode: {
          team_some: {
            id: $entrepreneurId
          }
        }
        OR: [
          {
            expertService: {
              OR: [
                { iname_contains: $searchString },
                { idescription_contains: $searchString },
                { expert: { ifullName_contains: $searchString } }
              ]
            }
          },
          {
            batch: { program: { iname_contains: $searchString } }
          },
          {
            batch: { iname_contains: $searchString }
          },
          {
            ode: { iname_contains: $searchString }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
