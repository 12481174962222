import React from "react";
import {
  LoginLayout,
  PasswordResetForm
} from "../../components/shared";

export function PasswordResetIndex() {
  return (
    <LoginLayout>
      <PasswordResetForm />
    </LoginLayout>
  );
}
