import { gql } from "graphql-tag";

export const getAssignedWorkshopServices = gql`
  query GetAssignedWorkshopServices(
    $serviceType: String  
    $expertId: ID!
    $first: Int
    $skip: Int
    $category: String
    $status: String
    $programId: ID
    $searchString: String
  ) {
    assignedExpertServices: monitoringAssignedWorkshops(
      orderBy: createdAt_DESC
      where: {
        expertService: {
          expert: { id: $expertId }
          category: $category
          type: $serviceType
        }
        status: $status
        batch: {
          program: {
            id: $programId
          }
        }
        OR: [
          {
            expertService: {
              OR: [
                { iname_contains: $searchString },
                { idescription_contains: $searchString },
                { expert: { ifullName_contains: $searchString } }
              ]
            }
          },
          {
            batch: { program: { iname_contains: $searchString } }
          },
          {
            batch: { iname_contains: $searchString }
          },
          {
            ode: { iname_contains: $searchString }
          }
        ]
      }
      skip: $skip
      first: $first
    ) {
      id
      assignationIdentifier
      isProbono
      expertServicePrice
      assignedUnits
      availableUnits
      expertServiceData
      assignmentDate
      limitDate
      status
      batch {
        id
        name
        program {
          id
          name
        }
      }
      expertService {
        id
        name
        type
        category
        duration
      }
      ode {
        id
        name
      }
    }
    meta: monitoringAssignedWorkshopsConnection(
      where: {
        expertService: {
          expert: { id: $expertId }
          category: $category
          type: $serviceType
        }
        status: $status
        batch: {
          program: {
            id: $programId
          }
        }
        OR: [
          {
            expertService: {
              OR: [
                { iname_contains: $searchString },
                { idescription_contains: $searchString },
                { expert: { ifullName_contains: $searchString } }
              ]
            }
          },
          {
            batch: { program: { iname_contains: $searchString } }
          },
          {
            batch: { iname_contains: $searchString }
          },
          {
            ode: { iname_contains: $searchString }
          }
        ]
      }
    ) {
      aggregate
    }
  }
`;
