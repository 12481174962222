export const serviceStatusEnum = {
  ASSIGNED: "ASSIGNED",
  SCHEDULED: "SCHEDULED",
  ON_GOING: "ON_GOING",
  DELIVERED: "DELIVERED",
  ACCEPTED: "ACCEPTED",
  AUTHORIZED_FOR_PAYMENT: "AUTHORIZED_FOR_PAYMENT",
  PAID: "PAID",
  PAYMENT_IN_PROCESS: "PAYMENT_IN_PROCESS",
  CANCELLED: "CANCELLED",// accepted by expert, and then an user cancelled it
  DELIVERY_REJECTED: "DELIVERY_REJECTED", // entrepreneur rejects the delivery
  REJECTED: "REJECTED", // expert rejects the service request,
  EXPIRED: "EXPIRED" // the limit date has been reached and the assignation hast available units yet
};
