import React, { useState, useEffect } from "react";
import {
  Divider, Modal, Skeleton, Space, Typography
} from "antd";
import { getClickableLink, USER_ROLE } from "../../../../helpers";
import {
  ContentStatusRadio, Format, TextTag, VideoCarousel, Visibility
} from "../../../../components/shared";
import { textContentTypes } from "../../../../helpers/select-options/content";
import { ContentController } from "./content-controller";
import { CommentField } from "./comment-field";
import { CommentList } from "./list";

export function ContentModal({
  contentGroup,
  content = {},
  odeContent,
  changeContentStatus,
  sendDeliverables,
  sendComment,
  loading,
  visible,
  onClose
}) {
  const [statusRadio, setStatusRadio] = useState("PENDING");

  const { Text, Title } = Typography;

  const onStatusRadioChange = e => {
    changeContentStatus(e.target.value);
    setStatusRadio(e.target.value);
  };

  const onCloseModal = () => {
    setStatusRadio("PENDING");
    onClose();
  };

  useEffect(() => {
    if(odeContent){
      setStatusRadio(odeContent?.status);
    } else {
      setStatusRadio("PENDING");
    }
  }, [content]);

  const ModalHeader = (
    <div style={{
      backgroundColor: "#F5F3FD",
      padding: "20px"
    }}>
      <Title level={5}>{contentGroup?.title}</Title>
      <div
        style={{
          fontWeight: "400",
          display: "flex",
          flexDirection: "column"
        }}>
        <Text>{contentGroup?.description}</Text>
        <Text
          style={{
            fontSize: "14px",
            marginTop: "7px",
            marginBottom: "11px"
          }}>
          Asignado por&nbsp;
          <span style={{ color: "#704AED" }}>
            {content?.createdBy?.fullName}&nbsp;
            ({USER_ROLE[content?.createdBy?.systemRole]})
          </span>
        </Text>
        <Visibility visible={odeContent !== null}>
          <ContentStatusRadio
            onChange={onStatusRadioChange}
            value={statusRadio}
            loading={loading} />
        </Visibility>
      </div>
    </div>
  );

  return (
    <Modal
      className="content-modal"
      visible={visible}
      onCancel={onCloseModal}
      onOk={onClose}
      cancelButtonProps={{ hidden: true }}
      okButtonProps={{ hidden: true }}
      width={841}
      title={ModalHeader}>
      <ContentController />
      {loading
        ? <Skeleton active paragraph={{ rows: 8 }} />
        :
        <div style={{ padding: "20px" }}>
          <Title style={{ fontSize: "22px" }}>{content?.name}</Title>
          <div
            style={{
                marginTop: "16px",
                marginBottom: "16px"
              }}>
            <TextTag options={textContentTypes}>{content?.type}</TextTag>
          </div>
          <Visibility
            visible={["WORKSHOP", "ACTIVITY"].includes(content?.type)}>
            <Visibility visible={!!content?.dueDate}>
              <Title style={{ fontSize: "16px" }}>
                {content?.type === "ACTIVITY"
                  ? "Fecha límite de entrega: "
                  : "Fecha del taller: "}
                <Format type="date">
                  {content.dueDate}
                </Format>
              </Title>
            </Visibility>
          </Visibility>
          <Visibility visible={!!odeContent?.deliveryDate}>
            <Title style={{ fontSize: "16px" }}>
              Fecha de entrega: <Format type="date">
                {odeContent?.deliveryDate}
              </Format>
            </Title>
          </Visibility>
          <Space direction="vertical" size="middle">
            <Visibility visible={content?.type?.localeCompare("RESOURCE")}>
              <Space direction="horizontal">
                <Typography>Lugar:</Typography>
                <Typography.Link
                  href={
                    content?.placeDirection && content?.placeType === "DIGITAL"
                      ? getClickableLink(content?.placeDirection)
                      : undefined
                  }
                  target="_blank">
                  {content?.placeDirection || "No especificado"}
                </Typography.Link>
              </Space>
            </Visibility>
            <Text
              type="secondary"
              style={{whiteSpace: "pre-wrap"}}>
              {content?.description}
            </Text>
          </Space>
          <Divider />
          <Title style={{ fontSize: "16px" }}>Instrucciones</Title>
          <Text
            type="secondary"
            style={{whiteSpace: "pre-wrap"}}>
            {content?.instructions}
          </Text>
          <Title style={{ fontSize: "16px" }}>Recursos</Title>
          <VideoCarousel files={content?.materials} />
          <Visibility visible={odeContent !== null}>
            <Divider />
            <Title style={{ fontSize: "16px" }}>Historial de entrega</Title>
            <CommentList odeContent={odeContent} />
            <Title style={{ fontSize: "16px", marginBottom: "15px" }}>
              Comentarios
            </Title>
            <CommentField
              sendComment={sendComment}
              sendDeliverables={sendDeliverables} />
          </Visibility>
        </div>
      }
    </Modal>
  );
}
