export function entrepreneursAgeRangeToChart (items = []) {
  const labels = [];
  const datasets = [];

  items.forEach((item, idx) => {
    labels.push(item["Company.ageRange"]);

    const data = new Array(items.length);
    data.fill(0, 0, items.length - 1);
    data[idx] = parseInt(item["Company.entrepreneursCount"]);

    datasets.push({
      label: item["Company.ageRange"],
      data,
      backgroundColor: "#1045b2",
      barThickness: 20
    });
  });

  return {
    labels,
    datasets
  };
}
