const unsortedEducationDegreesList = [
  {
    label: "Primaria",
    value: "PRIMARY"
  },
  {
    label: "Secundaria",
    value: "SECUNDARIA"
  },
  {
    label: "Preparatoria",
    value: "HIGHSCHOOL"
  },
  {
    label: "Profesional",
    value: "BACHELORS"
  },
  {
    label: "Postgrado",
    value: "MASTERS"
  },
  {
    label: "Doctorado",
    value: "DOCTOR"
  }
];


export const educationDegreesList =
  unsortedEducationDegreesList.sort((aEducation, bEducation) => {
    return aEducation.label.localeCompare(bEducation.label);
  });