import React, { useState } from "react";
import { ServiceAcceptanceModalContext } from "./context";
import {
  ServiceAcceptanceModal
} from "../../../../../../components/entrepreneur/experts/services/monitoring";
import { client } from "../../../../../../../graphql";
import { shared } from "../../../../../../graphql/shared";

export function ServiceAcceptanceModalProvider({ children }) {
  const [state, setState] = useState({
    isModalOpen: false,
    scheduledService: undefined,
    loading: false
  });

  const onSaveListeners = [];
  const onRejectListeners = [];

  const getScheduledExpertService = async id => {
    return await client.query({
      query: shared.queries.getFullScheduledExpertServiceById,
      variables: { id },
      fetchPolicy: "network-only"
    });
  };

  const openModal = async (scheduledService = undefined)  => {
    setState(prevState => ({
      ...prevState,
      isModalOpen: true,
      loading: true
    }));

    const result = await getScheduledExpertService(scheduledService.id);

    setState(prevState => ({
      ...prevState,
      loading: false,
      scheduledService: result.data.scheduledExpertService
    }));
  };

  const closeModal = () =>
    setState({
      isModalOpen: false,
      scheduledService: undefined
    });

  const onCancel = () => {
    closeModal();
  };

  const onSave = async values => {
    await Promise.all(onSaveListeners.map(async listener => {
      await listener(values);
    }));
  };

  const onReject = async values => {
    await Promise.all(onRejectListeners.map(async listener => {
      await listener(values);
    }));
  };

  const addOnSaveListener = func => {
    onSaveListeners.push(func);
  };

  const addOnRejectListener = func => {
    onRejectListeners.push(func);
  };

  const injectActions = {
    openModal,
    closeModal,
    addOnSaveListener,
    addOnRejectListener
  };

  return (
    <ServiceAcceptanceModalContext.Provider value={injectActions}>
      <ServiceAcceptanceModal
        onSave={onSave}
        onReject={onReject}
        visible={state.isModalOpen}
        onCancel={onCancel}
        scheduledService={state.scheduledService}
        loading={state.loading} />
      {children}
    </ServiceAcceptanceModalContext.Provider>
  );
}
