import React from "react";
import { Avatar, Card, Space, Modal, List, Typography } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { fileTypeToColor } from "../../../../../../helpers/file-type-to-color";
import { getClickableLink } from "../../../../../../helpers";
import { Format } from "../../../../../shared";

export function FileItem({ item, onDelete }) {

  const fileExtension = item.type.split('/').pop();
  const avatarColor = fileTypeToColor(fileExtension);

  const handleDelete = () => {
    Modal.confirm({
      okText: "Eliminar",
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: `Estás a punto de eliminar un documento de esta Compañía 
        ¿Deseas continuar?`,
      onOk: () => onDelete(item.id)
    });
  };

  return (
    <List.Item key={item.id}>
      <Card
        style={{ width: "100%" }}
        bordered={false}>
        <Space
          direction="horizontal"
          style={{ justifyContent: "space-between", width: "100%" }}>
          <List.Item.Meta
            style={{ width: "800px" }}
            avatar={
              <Avatar
                style={{
                  backgroundColor: avatarColor,
                  verticalAlign: "middle"
                }}
                gap={1}>
                {fileExtension}
              </Avatar>}
            title={
              <a
                style={{
                  fontSize: "17px",
                  color: "black",
                  fontWeight: "bold"
                }}
                href={getClickableLink(item.url)}>
                {item.fileName}
              </a>}
            description={
              <Typography.Text>
                Subido: <Format type="date">{item?.uploadDate}</Format>
              </Typography.Text>} />
          <DeleteOutlined
            style={{ fontSize: "17px" }}
            className="clickleable"
            onClick={handleDelete} />
        </Space>
      </Card>
    </List.Item>
  );

}