import React from "react";
import { List } from "antd";
import { TrophyOutlined } from "@ant-design/icons";
import { Avatar } from "../../../../../../shared";

export function AchievementItem({ extra, achievement }) {
  const { name = "Sin nombre", description = "Sin descripción" } = achievement;
  return (
    <List.Item
      extra={extra}>
      <List.Item.Meta
        avatar={
          <Avatar color="#FFC53D">
            <TrophyOutlined />
          </Avatar>
        }
        title={name}
        description={description} />
    </List.Item>
  );
}
