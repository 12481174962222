import React, { useContext } from "react";
import { withLoader } from "../../../../helpers";
import { HeroPublishBatch, Section, Visibility } from "../../../shared";
import { CurrentUserContext, InvitationModalProvider } from "../../../../contexts/shared";
import { BatchDetailTabs } from "../../../shared/batch-detail";
import { ModalButton } from "../../../shared/buttons";
import { EditOutlined } from "@ant-design/icons";
import { EditBatch } from "../../../shared/modals";

const BatchPublish = ({ batch = {}, refetchBatch }) => {
  const { currentUser } = useContext(CurrentUserContext);
  return (
    <InvitationModalProvider>
      <Section className="NewBatch__Publish">
        <Visibility
          visible={["SUPER_ADMIN", "COORDINATOR"].includes(currentUser.systemRole)}>
          <ModalButton
            className="NewBatch__Publish-EditBtn"
            title="Editar información"
            type="primary"
            key="assign-btn"
            icon={<EditOutlined />}
            modalProps={{
              title: "Editar información del batch",
              batch,
              refetchBatch
            }}
            modal={EditBatch} />
        </Visibility>
        <HeroPublishBatch
          className="NewBatch__Publish-Hero"
          batch={batch} />
        <div id="focus" />
        <BatchDetailTabs
          refetchBatch={refetchBatch}
          batch={batch} />
      </Section>
    </InvitationModalProvider>
  );
};

export default withLoader(BatchPublish);
