import React from "react";
import { List, Space, Typography, Modal, message } from "antd";
import { MailOutlined, PhoneOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import { IconLabel, Avatar, Format } from "../../../shared";
import { admin } from "../../../../graphql/admin";
import { client } from "../../../../../graphql";
import { ActionButton } from "../../../shared";
import { getServicesByExpertId } from "../../../../services/expert";

export default function ExpertItem({ expert, onClickExpert }) {

  const [deleteExpert] = useMutation(
    admin.mutations.deleteExpert,
    {
      client,
      variables: { id: expert?.id },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: admin.queries.getExperts
      }]
    });

  const deleteExpertUser = async () => {
    try {
      await deleteExpert();
      message.success("El experto ha sido eliminado.");
    } catch (e) {
      console.error(e);
      message.error("Ocurrió un error.");
    }
  };

  const checkExpertHasServices = async () => {
    const { data } = await getServicesByExpertId(expert?.id);
    return data.expertServices?.length > 0;
  };

  const handleOnDelete = async () => {
    const dismissLoading = message.loading("Verificando...");
    try {
      const allowDelete = !(await checkExpertHasServices());
      dismissLoading();

      if (allowDelete) {
        Modal.confirm({
          okText: "Eliminar",
          cancelText: "Cancelar",
          title: "Advertencia",
          icon: <ExclamationCircleOutlined />,
          content: "Estás a punto de eliminar un experto ¿Deseas continuar?",
          onOk: deleteExpertUser
        });
      } else {
        Modal.confirm({
          okText: "Entendido",
          title: "Advertencia",
          icon: <ExclamationCircleOutlined />,
          content: "No es posible eliminar este usuario debido a que tiene\
           Productos vinculados."
        });
      }
    } catch (e) {
      console.error(e);
      message.error("Ha ocurrido un error, inténtalo de nuevo\
        en unos segundos.");
    } finally {
      dismissLoading();
    }
  };

  return (
    <List.Item className="admin-experts-list">
      <div>
        <div
          className="clickable"
          onClick={onClickExpert}>
          <List.Item.Meta
            avatar={<Avatar>{expert.profilePicUrl || expert.fullName }</Avatar>}
            title={expert.fullName}
            description={
              <div>
                <div>
                  <Typography.Text type="secondary">
                    <Format type="prune" size={250}>{expert?.expertProfile?.resume}</Format>
                  </Typography.Text>
                </div>
                <Space>
                  <IconLabel icon={<MailOutlined />}>{expert.email}</IconLabel>
                  <IconLabel icon={<PhoneOutlined />}>
                    <Format type="phone">{expert.phone}</Format>
                  </IconLabel>
                </Space>
              </div>
            } />
        </div>
        <ActionButton
          onEdit={onClickExpert}
          customOnDelete={handleOnDelete} />
      </div>
    </List.Item>
  );
}
