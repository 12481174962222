import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ProgramBatchesIndex } from "../../../pages/admin/programs/show/batches";
import {
  ProgramsIndex
} from "../../../pages/coordinator/programs";
import {
  ProgramObjectivesIndex
} from "../../../pages/coordinator/programs/show/objectives";
import {
  AchievementsIndex
} from "../../../pages/coordinator/programs/show/objectives/show/achievements";
import {
  ContentsIndex
} from "../../../pages/coordinator/programs/show/objectives/show/contents";

export const CoordinatorProgramsRoutes = <Switch>
  <Route
    exact
    path="/v2/coordinator/programs"
    component={ProgramsIndex} />
  <Route
    path="/v2/coordinator/programs/:id/"
    render={({ match: { params } }) =>
      <Redirect
        from={`/v2/coordinator/programs/${params?.id}/`}
        to={`/v2/coordinator/programs/${params?.id}/batches`} />
    } />
  <Route
    exact
    path="/v2/coordinator/programs/:id/objectives"
    component={ProgramObjectivesIndex} />
  <Route
    exact
    path="/v2/coordinator/programs/:id/batches"
    component={ProgramBatchesIndex} />
  <Route
    path="/v2/coordinator/programs/:programId/objectives/:objectiveId/"
    render={({ match: { params } }) =>
      <Redirect
        from={`/v2/coordinator/programs/${params?.programId}/objectives/${params?.objectiveId}/`}
        to={`/v2/coordinator/programs/${params?.programId}/objectives/${params?.objectiveId}/achievements`} />
    } />
  <Route
    path="/v2/coordinator/programs/:programId/objectives/:objectiveId/achievements"
    component={AchievementsIndex} />
  <Route
    path="/v2/coordinator/programs/:programId/objectives/:objectiveId/contents"
    component={ContentsIndex} />
</Switch>;
