import gql from "graphql-tag";

export const updateBatch = gql`
  mutation UpdateBatch(
    $data: BatchUpdateInput!,
    $where: BatchWhereUniqueInput!
  ) {
    updateBatch(data: $data, where: $where) {
      id
    }
  }
`;
