import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Space,
  Divider,
  Button,
  message,
  Form
} from "antd";
import { client } from "../../../../../graphql";
import { shared } from "../../../../graphql/shared";
import {
  Hero,
  Loader,
  SocialLinksSection,
  WebsiteAndContact,
  Presence
} from "../../../shared";
import { RejectedSection } from "../../../shared/organization/rejected-section";
import {
  CurrentUserContext,
  ServicesLayerContext
} from "../../../../contexts/shared";

export function OrganizationDetailModal({
  selectedItem: organizationId,
  onCancel,
  allRefetch,
  ...props
}) {
  const initialState = {
    organization: undefined,
    loading: true,
    error: null
  };

  const [form] = Form.useForm();
  const { currentUser } = useContext(CurrentUserContext);
  const { AsemOrganizationService } = useContext(ServicesLayerContext);
  const [state, setState] = useState(initialState);
  const [toggleBtnReject, setToggleBtnReject] = useState(false);
  const showBtnActions = state?.organization?.status === "REVIEW_REQUESTED";

  const fetchOrganization = async id => {
    setState(initialState);
    return await client.query({
      query: shared.queries.getOrganizationById,
      variables: { id: id },
      fetchPolicy: "network-only"
    });
  };

  useEffect(() => {
    const id = organizationId || state.organizationId;
    id && fetchOrganization(id)
      .then(({ data , loading, error }) => {
        setState({ organization: data?.organization, loading, error });

        form.setFieldsValue({
          rejectionReason: data?.organization?.rejectionReason
        });
      });
  }, [organizationId]);

  const actions = toggleBtnReject
    ? ({
      okText: "Enviar rechazo",
      cancelText: "Cancelar",
      handleOnOk: () => handleOnOk("REJECTED"),
      handleOnCancel: () => handleSetToggleBtnReject(false)
    }) : ({
      okText: "Aprobar Solicitud",
      cancelText: "Rechazar solicitud",
      handleOnOk: () => handleOnOk("APPROVED"),
      handleOnCancel: () => handleSetToggleBtnReject(true)
    });

  const handleSetToggleBtnReject = async bool => {
    await form.resetFields();
    setToggleBtnReject(bool);
  };

  const handleOnOk = async status => {
    const dismissLoader = message.loading("Guardando...");
    const isApproved = status === "APPROVED";
    try {
      if (isApproved) {
        await AsemOrganizationService.organizationRequestApprove({
          organizationId,
          reviewerId: currentUser?.id
        });
      } else {
        await form.validateFields();
        const { rejectionReason = "" } = form.getFieldsValue();
        await AsemOrganizationService.organizationRequestReject({
          organizationId,
          reviewerId: currentUser?.id,
          rejectionReason
        });
      }
      onCancel && onCancel();
      allRefetch && allRefetch();
      message.success(`Solicitud ${isApproved ? "aprobada" : "rechazada"}`, 2);
    } catch (e) {
      if (e?.hasOwnProperty("errorFields")) return;

      message.error(
        "Ocurrió un error, intente de nuevo en unos segundos"
      );
      console.error(e);
    } finally {
      dismissLoader();
    }
  };

  const handleOnCancel = async () => {
    await handleSetToggleBtnReject(false);
    onCancel && onCancel();
  };

  return (
    <Modal
      className="OrganizationModal"
      width={800}
      destroyOnClose
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      onCancel={handleOnCancel}
      title="Solicitud de organización"
      footer={showBtnActions
        ? [
          <>
            <Button
              onClick={actions?.handleOnCancel}>
              {actions?.cancelText}
            </Button>
            <Button
              type="primary"
              onClick={actions?.handleOnOk}>
              {actions?.okText}
            </Button>,
          </>
        ] : null}
      {...props}>
      <Loader
        data={state?.organization}
        loading={state?.loading}
        error={state?.error}>
        <div className="OrganizationModal__wrapper">
          <Hero
            isAsemAdmin
            organization={state?.organization} />
          <Divider />
          <Space direction="vertical">
            <Presence
              organization={state?.organization} />
          </Space>
          <Divider />
          <Space direction="vertical">
            <WebsiteAndContact
              organization={state?.organization} />
            <Space
              className="OrganizationModal__icons"
              size="large">
              <SocialLinksSection
                organization={state?.organization} />
            </Space>
          </Space>
          {(state?.organization?.status === "REJECTED" || toggleBtnReject) && (
            <>
              <Divider />
              <RejectedSection
                form={form}
                organization={state?.organization} />
            </>
          )}
        </div>
      </Loader>
    </Modal>
  );
}
