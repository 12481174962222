import React, { useContext } from "react";
import { Redirect } from "react-router";
import { CurrentUserContext } from "../../../contexts/shared";

export function RouteGuard({ children, check, redirect = "/v2/login" }) {
  const { currentUser, loading, error } = useContext(CurrentUserContext);

  if(loading) {
    return null;
  }

  let allowed = check({ currentUser });

  if(error) {
    allowed = false;
  }

  children = React.Children.map(children, child => {
    return React.Children.map(child.props.children, route => {
      return React.cloneElement(
        route,
        {},
        (allowed ? route.props.children : <Redirect to={redirect} />)
      );
    });
  });

  return children;
}
