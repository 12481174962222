import React from "react";
import { Popover, Typography } from "antd";
import { CrownTwoTone, InfoCircleFilled } from "@ant-design/icons";

export function LeaderIcon({
  leaderId,
  expert = {}
}) {
  return leaderId === expert.id
    ? (
      <Popover
        placement="top"
        content={<div>
          <InfoCircleFilled
            style={{ color: "#FFB61D" }} /> <Typography.Text>
              Mentor líder
            </Typography.Text>
        </div>}
        trigger="hover">
        <CrownTwoTone twoToneColor="#FFB61D" />
      </Popover>
    )
    : null;
}
