import React, { useEffect, useState } from "react";
import { Form, Input, Row, Select } from "antd";
import { PresenceSelector } from "../../presence-selector";
import { SocialMediaInput } from "../../social-media-input";
import {
  getCompanyMissingRequiredFields,
  nationalities
} from "../../../../helpers";

export const CompanyContactForm = ({
  form,
  links,
  setLinks,
  company,
  selectedStates,
  setSelectedStates
}) => {

  const oneThirdInput = { width: "230px" };

  const [validation, setValidation] = useState({});

  useEffect(() => {
    if (company) {
      const { requiredFieldsValidation } =
        getCompanyMissingRequiredFields(company);
      setValidation(requiredFieldsValidation);
    }
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ padding: "0 16px" }}>
      <Form.Item
        label="Email"
        name="email"
        help={validation?.email?.msg}
        validateStatus={validation?.email?.status}
        rules={[
          {
            type: "email",
            message: "Por favor ingrese un email valido"
          },
          {
            required: true,
            message: "Por favor ingrese el email"
          }
        ]}>
        <Input
          type="email"
          placeholder="contacto@gmail.com"
          onChange={() => setValidation(prev => ({
            ...prev,
            email: {
              msg: undefined,
              status: undefined
            }
          }))} />
      </Form.Item>
      <Row justify="space-between">
        <Form.Item
          label="Teléfono"
          name="phone"
          help={validation?.phone?.msg}
          validateStatus={validation?.phone?.status}
          rules={[{
            required: true,
            pattern: RegExp(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/),
            message: "Número de teléfono inválido"}]}>
          <Input
            type="tel"
            placeholder="(154) 215-4468"
            style={oneThirdInput}
            onChange={() => setValidation(prev => ({
              ...prev,
              phone: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
        <Form.Item
          label="Ciudad"
          name="city"
          help={validation?.city?.msg}
          validateStatus={validation?.city?.status}
          rules={[{ required: true }]}>
          <Input
            style={oneThirdInput}
            onChange={() => setValidation(prev => ({
              ...prev,
              city: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
        <Form.Item
          label="País"
          name="country"
          help={validation?.country?.msg}
          validateStatus={validation?.country?.status}
          rules={[{ required: true }]}>
          <Select
            options={nationalities}
            style={oneThirdInput}
            onChange={() => setValidation(prev => ({
              ...prev,
              country: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
      </Row>
      <Row justify="space-between">
        <Form.Item
          label="Calle y número"
          name="streetAddress">
          <Input style={oneThirdInput} />
        </Form.Item>
        <Form.Item
          label="Colonia"
          name="colonyAddress">
          <Input style={oneThirdInput} />
        </Form.Item>
        <Form.Item
          label="Código postal"
          name="zipCode">
          <Input type="number" style={oneThirdInput} />
        </Form.Item>
      </Row>
      <Form.Item
        label="Lugares donde tiene presencia"
        required
        help={validation?.presenceStates?.msg}
        validateStatus={validation?.presenceStates?.status}
        style={{ width: "100%" }}>
        <PresenceSelector
          setValidation={setValidation}
          selectedStates={selectedStates}
          setSelectedStates={setSelectedStates} />
      </Form.Item>
      <Form.Item
        label="Página o redes sociales">
        <SocialMediaInput links={links} setLinks={setLinks} />
      </Form.Item>
    </Form>
  );
};
