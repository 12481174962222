import gql from "graphql-tag";

export const getOdeContentByOdeIdAndContentId = gql`
  query GetOdeContentByOdeIdAndContentId($odeId: ID!, $contentId: ID!) {
    odeContents(
      where:{
        ode:{ id: $odeId }
        content:{ id: $contentId }
      }
    ) {
      id
      isComplete
      finishDate
      deliverables{
        id
        fileName
        url
        type
      }
      comments{
        id
        author{
          profilePicUrl
          firstName
          middleName
        }
        comment
        createdAt
     }
    }
  }
`;
