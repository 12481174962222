import { useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import React, { useContext, useState } from "react";
import { client } from "../../../../graphql";
import { admin } from "../../../graphql/admin";
import { shared } from "../../../graphql/shared";
import { BatchDetailsContext } from "../../admin/batches";
import { ConnectUserToBatchContext } from "./context";
import { ConnectUserModal } from "./modal";

export function ConnectUserToBatchProvider({
  children,
  systemRole,
  refetch = null,
  filterUsers = []
}) {
  const { id: batchId } = useContext(BatchDetailsContext);
  const [state, setState] = useState({ isModalOpen: false });
  const [updateBatch] = useMutation(admin.mutations.updateBatch, { client });

  const openModal = () => {
    setState({ isModalOpen: true });
  };

  const closeModal = () => {
    setState({ isModalOpen: false });
  };

  const onCancel = () => {
    closeModal();
  };

  const onSave = async values => {
    let { users } = values;

    users = users.map(e => ({ id: e }));

    const dismissLoader = message.loading("Guardando...");

    try {
      await updateBatch({
        variables: {
          where: { id: batchId },
          data: {
            users: { connect: users }
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: admin.queries.getExpertsByBatchId,
          fetchPolicy: "network-only",
          variables: { id: batchId }
        },
        {
          query: shared.queries.getBatchCoordinators,
          fetchPolicy: "network-only",
          variables: { id: batchId }
        }]
      });

      message.success("Usuarios asignados");

      closeModal();
      refetch && await refetch();
    }
    catch(e) {
      console.error(e);
      message.error("Ha ocurrido un error. Por favor, inténtalo de nuevo.");
    }
    finally {
      dismissLoader();
    }
  };

  return (
    <ConnectUserToBatchContext.Provider value={{ openModal, closeModal }}>
      <ConnectUserModal
        systemRole={systemRole}
        onCancel={onCancel}
        onSave={onSave}
        batchId={batchId}
        filterUsers={filterUsers}
        visible={state.isModalOpen} />
      { children }
    </ConnectUserToBatchContext.Provider>
  );
}
