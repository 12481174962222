import gql from "graphql-tag";

export const createWorkspace = gql`
  mutation CreateWorkspace($data: WorkspaceCreateInput!) {
    createWorkspace(data: $data) {
      id
      name
    }
  }
`;
