import { getDeletedIdsFromTwoArrays } from "../../../../../helpers/get-deleted-ids-from-two-arrays";

export const formatValues = (entrepreneurships = [], oldEntrepreneurships = []) => {
  const upsertValues = entrepreneurships.map(({ id, period, ...e }) => {
    delete e.__typename;
    delete e.uuid;
    delete e.index;

    e.startDate = period[0]?.toISOString();
    e.endDate = period[1] ? period[1].toISOString() : null;

    return ({
      where: { id: id || "" },
      create: { ...e },
      update: { ...e }
    });
  });

  const createValues = upsertValues.map(v => v.create);

  const deletedEntrepreneurshipsIds = getDeletedIdsFromTwoArrays(entrepreneurships, oldEntrepreneurships);

  return ({
    expertProfile: {
      upsert: {
        create: {
          professionalExperience: {
            create: {
              entrepreneurships: { create: createValues }
            }
          }
        },
        update: {
          professionalExperience: {
            upsert: {
              create: {
                entrepreneurships: { create: createValues }
              },
              update: {
                entrepreneurships: {
                  upsert: upsertValues,
                  deleteMany: { id_in: deletedEntrepreneurshipsIds }
                }
              }
            }
          }
        }
      }
    }
  });
};
