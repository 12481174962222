import React from "react";
import { List, Space } from "antd";
import { MinusCircleOutlined, EditOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";

const { Item } = List;

const Title = styled.div`
  font-size: 18px;
`;

export const CourseItem = ({ item, onEditItem, onRemoveItem, editable }) => {
  const startDate = moment(item.startDate).format("YYYY");
  const endDate = item.endDate === null
    ? "Actual"
    : moment(item.endDate).format("YYYY");

  const renderExtra = () => (
    <Space>
      <EditOutlined onClick={() => onEditItem(item)} />
      <MinusCircleOutlined onClick={() => onRemoveItem(item)} />
    </Space>
  );

  return (
    <Item
      extra={editable && renderExtra()}>
      <div>
        <Title>{item.name}</Title>
        <div>{item.endorsingInstitution}</div>
        <div>{startDate} · {endDate}</div>
      </div>
    </Item>
  );
};
