import React from "react";
import moment from "moment";
import { capitalize } from "lodash";
import { timeLinePersonForEe } from "../../../../../helpers";
import { Card, Empty, Tag, Timeline, Typography } from "antd";
import { EXPERIENCE_LEVEL_LABELS } from "../share-comparison-chart/helpers";

export const ResumeByPersonParticipationTimeline = ({
  onClick,
  windowSize,
  attendances = []
}) => {
  const formatAttendances = timeLinePersonForEe(attendances);
  const handleOnClick = async attendanceId => {
      onClick({
        selectedExperience: attendanceId
      });
  };

  const renderTimeLineItems = ({ monthYear, experiences }) => (
    <Timeline.Item
      key={monthYear}
      label={
        <Typography.Title
          level={5}
          style={{ margin: 0, fontSize: contentFontSizes[windowSize] }}>
          {capitalize(monthYear)}
        </Typography.Title>
      }>
      <br />
      {experiences.map(({
        personExperienceAttendanceId,
        experience
      }) => (
        <div
          className="clickable"
          key={experience?.experienceId}
          onClick={() => handleOnClick(personExperienceAttendanceId)}>
          <Typography.Text
            strong
            style={{ marginBottom: 0, fontSize: contentFontSizes[windowSize] }}>
            {moment(experience?.startDate).format("DD/MM/YYYY")}
          </Typography.Text>
          <Typography.Paragraph
            style={{ marginBottom: 0, fontSize: contentFontSizes[windowSize] }}>
            {experience?.name}
          </Typography.Paragraph>
          <Tag>
            {EXPERIENCE_LEVEL_LABELS[experience?.involvementLevel]}
          </Tag>
        </div>
      ))}
    </Timeline.Item>
  );

  return(
    <Card
      className="TimeLineEE"
      bordered
      title={
        <Typography.Title level={5}>
          Timeline de participaciones
        </Typography.Title>
     }>
      <Timeline mode="left" className={classNameBySize[windowSize]}>
        {formatAttendances.length
          ? formatAttendances.map(renderTimeLineItems)
          : <Empty />
        }
      </Timeline>
    </Card>
  );
};

const classNameBySize = {
  "MEDIUM": "TimeLineEE-on-row",
  "LARGE": "TimeLineEE-on-row",
  "EXTRA_LARGE": "TimeLineEE-on-line",
  "EXTRA_EXTRA_LARGE": "TimeLineEE-on-row"
};

const contentFontSizes = {
  "MEDIUM": "15px",
  "LARGE": "15px",
  "EXTRA_LARGE": "13px",
  "EXTRA_EXTRA_LARGE": "16px"
};