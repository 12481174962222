import React from "react";
import { Tabs, Space } from "antd";
import { FileTextOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { BatchHeroContent } from "../../../../../shared/batch-content/contents";
import { useService } from "../../../../../../hooks/shared";
import { OdesService } from "../../../../../../services";
import { Loader, OdeContents } from "../../../../../shared";
import { HeaderBreadcrumbs } from "../../../../../shared/batch-offer/header-breadcrumbs";
import { useHistory } from "react-router-dom";
import { CompanyServices } from "./services";
import { AssignedMentoring } from "../../../../../shared/assignation/assigned-mentoring";
import { AvailableMentors } from "../../../../../shared/assignation/available-mentors";

export function OdeContentTab({ batchId, companyId }) {
  const history = useHistory();

  const { data: ode, loading, error } = useService(
    OdesService.getOdeByIdService,
    { companyId }
  );

  return (
    <Space direction="vertical" className="OdeContentTab">
      <Loader
        loading={loading}
        data={ode}
        error={error}>
        <HeaderBreadcrumbs
          title="Compañías"
          name={ode?.name}
          onPrev={() =>
            history.push(`../${batchId}/offer?section=companies`)} />
        <BatchHeroContent data={ode} />
        <Tabs defaultActiveKey="content">
          <Tabs.TabPane
            key="content"
            tab={<span><FileTextOutlined /> Contenido</span>}>
            <OdeContents batchId={batchId} companyId={companyId} />
          </Tabs.TabPane>
          <Tabs.TabPane
            key="mentors"
            tab={<span><UsergroupAddOutlined /> Mentores</span>}>
            <AssignedMentoring
              batchId={batchId}
              companyId={companyId} />
            <AvailableMentors
              odeId={companyId}
              batchId={batchId} />
          </Tabs.TabPane>
          <Tabs.TabPane
            key="services"
            tab={<span><UsergroupAddOutlined /> Servicios</span>}>
            <CompanyServices
              batchId={batchId}
              companyId={companyId} />
          </Tabs.TabPane>
        </Tabs>
      </Loader>
    </Space>
  );
}
