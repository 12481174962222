import React, { useContext } from "react";
import { Table, Tag } from "antd";
import moment from "moment";
import {
  getReadableValue,
  expertServiceTypes,
  scheduledServicesStatus
} from "../../../../../../helpers/select-options";
import { Format, ServiceCost } from "../../../../../shared";
import { ServiceApprovalModalContext } from "../../../../../../contexts/expert/services/requests/service-approval-modal";
import { ServiceDeliveryModalContext } from "../../../../../../contexts/expert/services/requests/service-delivery-modal";
import { ServiceDetailModalContext } from "../../../../../../contexts/shared";
import { statusTagColors } from "../status-tag-colors";
import { ignoreDuplicatedWorkshops } from "./ignore-duplicated-workshops";

export function ScheduledServicesTable({
  loading,
  scheduledExpertServices
}) {
  const { openModal } = useContext(ServiceApprovalModalContext);
  const {
    openModal: openDeliveryModal
  } = useContext(ServiceDeliveryModalContext);
  const {
    openModal: openDetailsModal
  } = useContext(ServiceDetailModalContext);

  const handleOnRowClick = scheduledService => {
    if (scheduledService.status === "SCHEDULED") {
      openModal(scheduledService);
    } else if (["ON_GOING", "DELIVERY_REJECTED"].includes(scheduledService.status)) {
      openDeliveryModal(scheduledService);
    } else {
      openDetailsModal({
        scheduledExpertService: scheduledService
      });
    }
  };

  // Multiple workshop service assignments and its "schedule" record
  // with the same assignmentNumber must be represented as a single row
  const dataSource = ignoreDuplicatedWorkshops(scheduledExpertServices);

  return (
    <Table
      title={() => `Resultados: ${dataSource.length}`}
      style={{ overflowX: "auto" }}
      onRow={scheduledService => ({
        onClick: () => handleOnRowClick(scheduledService),
        className: "clickable"
      })}
      loading={loading}
      rowKey={(row, index) => row?.id || index}
      dataSource={dataSource}>
      <Table.Column
        dataIndex="assignedExpertService"
        title="Compañía"
        render={({ ode, expertServiceData }) =>
          expertServiceData.type === "WORKSHOP" ? null : ode?.name } />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Programa"
        render={({ batch }) => batch?.program?.name} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Batch"
        render={({ batch }) => batch?.name} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Producto"
        render={({ expertServiceData }) => expertServiceData?.name} />
      <Table.Column
        dataIndex="assignedExpertService"
        title="Tipo de producto"
        render={({ expertServiceData }) =>
          getReadableValue(expertServiceTypes, expertServiceData?.type) } />
      <Table.Column
        align="center"
        dataIndex="duration"
        title="Cantidad"
        render={(duration, { assignedExpertService: { expertServiceData } }) =>
        expertServiceData?.method === "HOURS" ?
            `${duration} horas` : "1 servicio"} />
      <Table.Column
        align="center"
        dataIndex="duration"
        title="Costo total"
        render={(duration, { assignedExpertService }) =>
          <ServiceCost
            assignedExpertService={assignedExpertService}
            duration={duration} />} />
      <Table.Column
        align="center"
        dataIndex="assignedExpertService"
        title="Fecha de asignación"
        render={({ assignmentDate }) =>
          <Format type="date">{assignmentDate}</Format>
        } />
      <Table.Column
        dataIndex="serviceDate"
        title="Fecha de ejecución"
        render={serviceDate =>
          serviceDate && moment(serviceDate).format("DD/MM/YYYY")} />
      <Table.Column
        dataIndex="acceptedAt"
        title="Fecha de aceptación"
        render={acceptedAt =>
          <Format type="date">{ acceptedAt }</Format>
        } />
      <Table.Column
        align="center"
        dataIndex="status"
        title="Estado"
        render={status => (
          <Tag
            style={{ margin: 0 }}
            color={statusTagColors[status]}>
            {getReadableValue(scheduledServicesStatus, status)}
          </Tag>
        )} />
    </Table>
  );
}
