import gql from "graphql-tag";

/**
 * @deprecated Use the batch-service.getCalendarEvents function instead.
 */
export const getEventsByBatchId = gql`
  query GetEventsByBatchId($id: ID!, $month: Int, $year: Int) {
    events(where: {
      batch: { id: $id },
      month: $month,
      year: $year
    }) {
      id
      date
      name
      type
    }
  }
`;
