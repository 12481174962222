import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";

import {Button, Card, Space, Typography} from "antd";

export const WelcomeSection = () => (
  <Card
    bordered={false}
    style={{ maxWidth: "719px", margin: "0 auto" }}
    align={"center"}>
    <Space
      direction="vertical"
      className="wide-input">
      <CheckCircleFilled
        style={{
          color: "#52C41A",
          fontSize: "74px",
          display: "block"
        }} />
      <Typography.Title style={{ fontSize: "24px", fontWeight: "300" }}>
        !Haz creado tu cuenta con éxito!
      </Typography.Title>
      <Typography.Text style={{ fontSize: "16px" }}>
        Comienza a administrar tu avance
      </Typography.Text>
    </Space>
    <Button
      href={"/v2/entrepreneur/batches"}
      type="primary"
      style={{marginTop: "2rem"}}>
      Explorar EOS
    </Button>
  </Card>
);
