export const initialState = {
  editing: {
    entrepreneurships: false,
    accounts: false,
    workExperience: false,
    fiscal: false,
    education: false,
    courses: false,
    papers: false,
    languages: false,
    privacy: false,
    tecRelationship: false
  },
  entrepreneurships: undefined,
  education: undefined,
  courses: undefined,
  papers: undefined,

  skills: undefined,
  areas: undefined,
  industries: undefined,
  curriculumVitae: undefined
};
