export const formatEntrepreneursInvitationData = (emails, ode, invitedBy) => {
  let emailTemplate = "invitation-to-platform";
  let dataForOde = {};
  if(ode) {
    emailTemplate = "invitation-to-company";
    dataForOde = {
      ode: ode.id,
      templateArgs: {
        coordName: invitedBy?.fullName || "Coordinador",
        companyName: ode?.name || "Compañia"
      }
    };
  }
  return {
    ...dataForOde,
    emailList: emails.map(email => email.trim()),
    invitedBy: invitedBy.id,
    template: emailTemplate
  };
};
