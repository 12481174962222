import { client } from "../../../graphql";
import { getSingleBatchQuery } from "./graphql";

export async function getSingle({ batchId }) {
  const result = await client.query({
    query: getSingleBatchQuery,
    fetchPolicy: "network-only",
    variables: { batchId }
  });

  return result.data?.batch || null;
}