import { client3 } from "../../../graphql";
import { getCampusInvolvementLevelsImpactQuery } from "./graphql";

export const getCampusInvolvementLevelsImpact = async ({
  campus,
  audienceTypes,
  experience
}) => {

  const result = await client3.query({
    query: getCampusInvolvementLevelsImpactQuery,
    fetchPolicy: "network-only",
    variables: {
      filters: {
        campus,
        audienceTypes,
        experience
      }
    }
  });

  return result?.data?.campusInvolvementLevelsImpact;
};