import moment from "moment";
import { remove as removeAccents } from "remove-accents";

export const formatValues = ({ values, hasExpertProfile = false }) => {
  const { resume, city, country, state } = values;

  delete values.resume;

  values.country = String(values.country);

  values.fullName = [values?.firstName, values?.middleName, values?.lastName]
    .filter(str => str !== undefined)
    .join(" ");

  values.ifullName = removeAccents(values.fullName).toLowerCase();
  values.birthday = values.birthday && moment(values.birthday).toISOString();

  if (values.profilePicUrl?.length === 1) {
    values.profilePicUrl = values.profilePicUrl[0].url
      || values.profilePicUrl[0].response?.imageUrl;
  }

  const location = {
    city,
    state,
    country: country?.toString()
  };

  const upsertLocation = {
    update: location,
    create: location
  };

  let data = {};

  if (!hasExpertProfile) {
    // use the create mutation directly because prisma has problems creating
    // entities through nested upsert operations
    data = {
      ...values,
      expertProfile: {
        create: {
          resume: resume,
          location: {
            create: location
          }
        }
      }
    };

    return data;
  }

  data = {
    ...values,
    expertProfile: {
      upsert: {
        create: {
          resume: resume,
          location: {
            create: location
          }
        },
        update: {
          resume: resume,
          location: {
            upsert: upsertLocation
          }
        }
      }
    }
  };

  return data;
};
