import gql from "graphql-tag";

export const getScheduledExpertServicesReportByCoordinatorId = gql`
  query GetScheduledExpertServicesReportByCoordinatorId(
    $where: ScheduledExpertServiceWhereInput
    $markAsPaid: Boolean = false
  ) {
    reportScheduledExpertServices(
      orderBy: updatedAt_DESC
      where: $where,
      markAsPaid: $markAsPaid
    ) {
      url
    }
  }
`;
