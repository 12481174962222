import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import { withRouter } from "../../../../../helpers";
import { BatchOdesNewList, OdeContentTab } from "../../show/odes";
import { useService } from "../../../../../hooks/shared";
import { OdesService } from "../../../../../services";
import BatchDetailsContextProvider
  from "../../../../../contexts/admin/batches/batch-details/provider";
import {
  BatchOdesContext
} from "../../../../../contexts/admin/batches";

export function BatchODEsIndex({ companyId }) {
  const [showEmpty, setShowEmpty] = useState(false);
  const { id = "" } = useParams();
  const history = useHistory();

  const { data, loading, error, refetch } = useService(
    OdesService.getOdesByBatchIdService,
    { batchId: id }
  );

  const handleOnClick = id =>
    history.replace(`./offer?section=companies&companyId=${id}`);

  const injectActions = {
    data,
    loading,
    error,
    batchId: id,
    refetch
  };

  if (companyId) {
    return (
      <OdeContentTab
        batchId={id}
        companyId={companyId} />
    );
  }

  return (
    <BatchDetailsContextProvider>
      <BatchOdesContext.Provider value={injectActions}>
        <BatchOdesNewList
          showEmpty={showEmpty}
          setShowEmpty={setShowEmpty}
          onClick={handleOnClick} />
      </BatchOdesContext.Provider>
    </BatchDetailsContextProvider>
  );
}

export default withRouter(BatchODEsIndex);
