import React from "react";
import { List, Typography, Tag } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export const LocationsList = ({ states, onCloseCity }) => {

  if (states && states.length === 0) return null;

  return (
    <List
      dataSource={states}
      renderItem={(state, index) => (
        <div style={{ marginBottom: "8px" }}>
          <Typography.Text>
            {`${state.name}: `}
            {
              state.cities.map(city =>
                <Tag style={{ marginLeft: "3px" }}>
                  {city}
                  <CloseOutlined
                    style={{ marginLeft: "5px" }}
                    onClick={() => onCloseCity(state, city, index)} />
                </Tag>)
            }
          </Typography.Text>
        </div>
      )} />
  )
}
