import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { MainMenu } from "../../../components/coordinator/shared";
import { Section, SidebarLayout } from "../../../components/shared";
import {
  MenuContext,
  InvitationModalProvider
} from "../../../contexts/shared";
import {
  EntrepreneurProfilesContext
} from "../../../contexts/entrepreneur/entrepreneurs/profiles";
import EntrepreneurList from "../../../components/admin/entrepreneurs/list";
import { coordinator } from "../../../graphql/coordinator";
import { client } from "../../../../graphql";
import { useQueryFilters, useControlledPagination } from "../../../helpers";
import {
  FilterControls
} from "../../../components/admin/entrepreneurs/list/filter-controls";
import {
  CurrentUserContext, PaginationContext
} from "../../../contexts/shared";

function ProfilesIndex() {
  const { setMenuState } = useContext(MenuContext);
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["entrepreneurs"],
      selectedKeys: ["entrepreneurs/profiles"]
    });
  }, []);

  const {
    data,
    loading,
    error,
    refetch
  } = useQuery(
    coordinator.queries.getEntrepreneursByCoordinatorId,
    {
      variables: {
        coordinatorId: currentUser.id,
        first: 10
      },
      fetchPolicy: "network-only",
      client
    }
  );

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 10
  });

  const { registerRefetch } = useContext(PaginationContext);
  registerRefetch("entrepreneurs", refetch);

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilter: { first: 10 }
  });

  const onFilter = (field = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);

    applyFilters(prevFilters => ({
      ...prevFilters,
      ...field,
      skip,
      first
    }));
  };

  return (
    <InvitationModalProvider>
      <EntrepreneurProfilesContext.Provider value={{ data, loading, error}}>
        <SidebarLayout menu={<MainMenu />}>
          <Section
            title="Perfiles"
            extra={
              <FilterControls onChange={onFilter} />
            }
            style={{ margin: "15px 20px" }}>
            <EntrepreneurList
              onChangePage={onPaginationChange}
              currentPage={currentPage} />
          </Section>
        </SidebarLayout>
      </EntrepreneurProfilesContext.Provider>
    </InvitationModalProvider>
  );
}

export default withRouter(ProfilesIndex);
