import { client } from "../../../graphql";
import { getOdeById } from "./graphql";

export const getOdeByIdService = async ({ companyId }) => {
  const result = await client.query({
    query: getOdeById,
    variables: {
      id: companyId
    },
    fetchPolicy: "network-only"
  });

  return result.data?.oDE || {};
};