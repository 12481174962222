import React  from "react";
import { SidebarLayout } from "../../../components/shared";
import { MainMenu } from "../../../components/entrepreneur/shared";
import {
  ProfileVerifierProvider
} from "../../../contexts/shared/profile-verifier";

export default function () {

  return (
    <SidebarLayout menu={<MainMenu />}>
      <ProfileVerifierProvider>
      </ProfileVerifierProvider>
    </SidebarLayout>
  );
}
