import React, { useContext } from "react";
import PerksList from "../../../perks/list";
import { useService } from "../../../../../../../hooks/shared";
import { BatchesService, CompanyService } from "../../../../../../../services";
import {
  CreatePerkModalProvider
} from "../../../../../../../contexts/shared/create-perk-modal";
import { CurrentUserContext } from "../../../../../../../contexts/shared";

export function Perks({ batchId }) {
  const { currentUser } = useContext(CurrentUserContext);

  // @todo This is the second batch offer tab that uses the entrepreneur
  // company. If this occurs again, please, query the company from the beginning
  // and share it with the tabs to avoid excessive data fetching
  const { data: companies, loading: loadingCompanies } = useService(
    CompanyService.getEntrepreneurCompaniesInBatch,
    { batchId: batchId, entrepreneurId: currentUser?.id },
    { shouldFetch: !!batchId && !!currentUser?.id }
  );

  const [firstMatchedCompany] = companies || [];
  const companyId = firstMatchedCompany?.id;

  const { data: perks, loading, error } = useService(
    BatchesService.getCompanyPerks,
    { batchId, companyId },
    { shouldFetch: !!batchId && !!companyId }
  );

  const isLoading = loading || loadingCompanies || !perks;

  return (
    <CreatePerkModalProvider>
      <PerksList
        error={error}
        loading={isLoading}
        isLoading={isLoading}
        data={perks ? { perks } : undefined} />
    </CreatePerkModalProvider>
  );
}
