import React, { useContext } from "react";
import {
  SidebarLayout,
  Section
} from "../../../components/shared";
import { AdminExpertsContext } from "../../../contexts/admin/experts";
import { ExpertServicesList } from "../../../components/expert/services/list";
import { ExpertServiceModalProvider } from "../../../contexts/shared/expert-service-modal";
import { useQuery } from "@apollo/react-hooks";
import { DataConnectionService } from "../../../services";
import { shared } from "../../../graphql/shared";
import { MenuContext, PaginationContext } from "../../../contexts/shared";
import { FilterControls } from "../../../components/expert/services/filter-controls";
import { useQueryFilters, useControlledPagination } from "../../../helpers";

export function ServicesIndex() {
  const { setMenuState } = useContext(MenuContext);
  const { registerRefetch } = useContext(PaginationContext);
  const expertId = window.localStorage.getItem("id");

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/services"]
  });

  const {
    loading,
    error,
    data,
    refetch,
    variables
  } = useQuery(
    shared.queries.getServicesByExpertId,
    {
      variables: {
        expertId: expertId,
        first: 12
      },
      client: DataConnectionService.graphqlApolloClient
    }
  );

  const { applyFilters, filters } = useQueryFilters({
    refetch,
    defaultFilters: {
      expertId: expertId,
      first: 12
    }
  });

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 10
  });

  const onFilterChange = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  registerRefetch("services", refetch, variables);

  return (
    <AdminExpertsContext.Provider value={{ data }}>
      <ExpertServiceModalProvider refetchVars={filters}>
        <SidebarLayout>
          <Section
            title="Productos y servicios"
            extra={<FilterControls onChange={onFilterChange} />}
            spaced>
            <ExpertServicesList
              refetchQueriesVars={filters}
              data={data}
              isLoading={loading}
              error={error}
              onChangePage={onPaginationChange}
              currentPage={currentPage} />
          </Section>
        </SidebarLayout>
      </ExpertServiceModalProvider>
    </AdminExpertsContext.Provider>
  );
}
