import { client } from "../../../graphql";
import { getAllQuery } from "./graphql";

export const getAll = async () => {
  const result = await client.query(
    {
      query: getAllQuery,
      fetchPolicy: "network-only"
    }
  );

  return result.data.programs || [];
};
