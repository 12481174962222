export const filterEmptyRowsFromSheets = async sheetRows => {
  const [
    importationRows
  ] = await Promise.all([
    new Promise(resolve => resolve(
      //ToDo prevent empty rows
      sheetRows
    ))
  ]);

  return {
    importationRows
  };
};
