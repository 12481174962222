import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Skeleton } from "antd";
import { ReportFormModalContext } from "../context";
import { FormSteps } from "./form-steps";
import { FundingsForm } from "./fundings-form";
import { GeneralForm } from "./general-form";
import { AchievementsForm } from "./achievements-form";
import { ContestsForm } from "./contests-form";
import { EmployeesForm } from "./employees-form";
import { Visibility } from "../../../../components/shared";

export function OdeReportModal({
  isOpen,
  loading = false
}) {
  const [state, setState] = useState({
    currentStep: 0,
    loading: false
  });

  useEffect(() => {
    setState(prevState => ({ ...prevState, loading }));
  }, [loading]);

  const {
    closeModal,
    saveChanges
  } = useContext(ReportFormModalContext);

  const onCloseModal = () => {
    setState(prevState => ({ ...prevState, currentStep: 0 }));
    closeModal();
  };

  const onNext = async () => {
    setState(prevState => ({
      ...prevState,
      loading: true
    }));

    saveChanges && await saveChanges();

    setState(prevState => ({
      ...prevState,
      currentStep:
        parseInt(prevState.currentStep) + (prevState.currentStep === 4 ? 0 : 1),
      loading: false
    }));

    if (state.currentStep === 4) {
      onCloseModal();
    }
  };

  const onPrevious = () => {
    if(state.currentStep > 0) {
      setState(prevState => ({
        ...prevState,
        currentStep: prevState.currentStep - 1
      }));
    }
  };

  const forms = {
    0: GeneralForm,
    1: FundingsForm,
    2: ContestsForm,
    3: EmployeesForm,
    4: AchievementsForm
  };

  const ReportForm = forms[state.currentStep];

  return (
    <Modal
      okButtonProps={{ loading: state.loading }}
      width={900}
      title="Reporte"
      visible={isOpen}
      onCancel={onCloseModal}
      footer={[
        <Button
          key="prevButton"
          onClick={onPrevious}>
          Atrás
        </Button>,
        <Button
          key="nextButton"
          type="primary"
          onClick={onNext}>
          Siguiente
        </Button>
      ]}>
      <FormSteps current={state.currentStep} />
      <Visibility visible={!loading}>
        <ReportForm />
      </Visibility>
      <Visibility visible={loading}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Visibility>
    </Modal>
  );
}
