import React, { useContext } from "react";
import { Typography, Card, Space, Progress, message, Form } from "antd";
import { FormNavigation } from "../../../shared";
import { withRouter } from "../../../../helpers";
import { OnboardingContext } from "../../../../contexts/coordinator/onboarding";
import PersonalInformationForm from "./form";

function PersonalInformation({ navigate }) {
  const {
    updateCoordinator,
    save,
    data,
    error,
    loading
  } = useContext(OnboardingContext);

  const [form] = Form.useForm();

  const onNext = route => async () => {
    await form.validateFields();
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      await save();
      navigate(route);
      message.success("Cambios guardados.");
    } catch (e) {
      console.error(e);
      message.error("Ha ocurrido un error, \
      inténtalo de nuevo en unos segundos.");
    }
    finally {
      dismissLoader();
    }
  };

  const onChange = values => {
    updateCoordinator(values);
  };

  return (
    <Card bordered={false} style={{ maxWidth: "600px", margin: "0 auto" }}>
      <Space direction="vertical" size="large">
        <Progress percent={50} showInfo={false} />
        <>
          <Typography.Title>Bienvenido a EOS 👋🏼</Typography.Title>
          <Typography.Text>
            En sencillos pasos podemos dar de alta la información necesaria
            para que puedas comenzar a ofrecer tus servicios en EOS y ayudes
            a compañías a llevar sus proyectos al mas alto nivel.
          </Typography.Text>
        </>
        <PersonalInformationForm
          data={data}
          error={error}
          isLoading={loading}
          onChange={onChange}
          form={form} />
        <FormNavigation
          onNext={onNext("./success")} />
      </Space>
    </Card>
  );
}

export default withRouter(PersonalInformation);