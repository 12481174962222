import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Title } from "../../../components/title";
import { Line } from "../../../components/line";

export const PaymentInfo = ({ data }) => {
  return (
    <View>
      <Title>INFORMACIÓN DE PAGO</Title>
      <View
        style={styles.body}>
        <View style={styles.row}>
          <Text>Cantidad</Text>
          <Text>{data.amount}</Text>
        </View>
        <Line style={{ marginTop: 13, marginBottom: 13 }} />
        <View style={styles.row}>
          <Text>Costo total</Text>
          <Text>$ {data.total} MXN</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    fontSize: 12,
    border: "1px solid lightgrey",
    borderRadius: 5,
    padding: 13
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
});