import { client } from "../../../graphql";
import { updateBatchInstitutions } from "./graphql/update-batch-institutions";

/**
 * @param {Data[]} data
 * @returns {Promise<{successfullyUpdated: *[], failedToUpdate: *[]}>}
 */
export const importBatchInstitutions = async ({ data }) => {
  const successfullyUpdated = [];
  const failedToUpdate = [];

  for (const item of data) {
    const result = await updateBatchInstitutionsField(
      item.batchId,
      item.institutionNames
    );

    if (result)
      successfullyUpdated.push(item);
    else
      failedToUpdate.push(item);
  }

  return {
    successfullyUpdated,
    failedToUpdate
  };
};

/**
 * @param {string} batchId
 * @param {string[]} institutionNamesEnumValue
 * @returns {Promise<boolean>}
 */
const updateBatchInstitutionsField = async (
  batchId,
  institutionNamesEnumValue
) => {
  try {
    await client.mutate({
      mutation: updateBatchInstitutions,
      variables: {
        batchId,
        institutions: institutionNamesEnumValue
      }
    });
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

/**
 * @typedef Data
 * @property {string} batchId
 * @property {string[]} institutionNames
 */
