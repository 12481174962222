import React from "react";
import { Button } from "antd";
import { assignationHasExpired } from "../../../../../../../../../../helpers";
import {
  EntrepreneurAssignedMentoringDetailModal,
  EntrepreneurScheduledMentoringDetailModal
} from "../../../../../../../../shared/modals";
import { ModalButton } from "../../../../../../../../../shared/buttons";
import { EntrepreneurScheduledMentoringModal } from "../../../../../../../../shared/modals/scheduled-mentoring-modal";

export function FooterButton({
  assignedService, // contains the scheduled service too
  scheduledService, // scheduled service inside the assigned service
  serviceIsOnGoing = false,
  refetchScheduledMentorings,
  company = null
}) {

  if (scheduledService) {
    return (
      <ModalButton
        type="link"
        key="btn-details"
        title="Ver detalle"
        item={scheduledService}
        modal={EntrepreneurScheduledMentoringDetailModal} />
    );
  }

  if (assignationHasExpired(assignedService.limitDate) || serviceIsOnGoing) {
    return (
      <ModalButton
        type="link"
        key="btn-details"
        title="Ver detalle"
        item={assignedService}
        modalProps={
          { company }
        }
        modal={EntrepreneurAssignedMentoringDetailModal} />
    );
  }

  return (
    <ModalButton
      type="link"
      key="btn-schedule"
      title="Agendar"
      item={assignedService}
      modalProps={{ refetchScheduledMentorings }}
      modal={EntrepreneurScheduledMentoringModal} />
  );
}
