import React, {useRef} from "react";
import { Space, Typography } from "antd";
import { NumeraliaIndex } from "./numeralia";
import { useQueryFilters } from "../../../../helpers";
import { DashboardFilterControls } from "./filter-controls";
import { ServiceByStageChart } from "./service-by-stage-chart";
import { OrganizationsEnvironmentMapTable } from "./organizations-table";
import { OrganizationsMapChart } from "./map-chart";
import { FoundationYearChart } from "./chart-by-fundation-year";
import { FoundationYearChartModal } from "./chart-by-fundation-year/modal";
import { GenericModalProvider } from "../../../../contexts/shared/modals";
import { useService } from "../../../../hooks/shared";
import { organizationsService } from "../../../../services/organizations";

export function OrganizationEnvironmentMapIndex() {

  const {
    loading,
    data,
    error,
    refetch
  } = useService(
    organizationsService.getOrganizationsByFilters
  )

  const { applyFilters } = useQueryFilters({
    refetch
  });

  const modalRef = useRef(null);

  const onChartClick = ({selectedYear, organizations}) => {
    modalRef.current.openModal({selectedYear, organizations});
  };
  const onFiltersChange = newFilters => {
    applyFilters(prevFilters => {
      const arrayData = Object.entries({ ...prevFilters, ...newFilters });
      const filters = arrayData.reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
          if (value.length > 0) acc[key] = value;
        } else {
          acc[key] = value; //soporte para filtros que no son arrays
        }
        return acc;
      }, {});
      return ({...filters})
    })
  }

  return (
    <>
      <GenericModalProvider
        ref={modalRef}
        modal={FoundationYearChartModal} />
      <Space
        style={{marginTop: "2rem"}}
        className="asem OrganizationIndex"
        direction="vertical">
        <Typography.Title
          className="OrganizationIndex__map_environment_title"
          level={4}>
          Organizaciones
        </Typography.Title>
        <DashboardFilterControls
          onChange={onFiltersChange}/>
        <NumeraliaIndex organizations={data?.organizationsByFilters}/>
        <ServiceByStageChart organizations={data?.organizationsByFilters}/>
        <OrganizationsEnvironmentMapTable
          loading={loading}
          organizations={data?.organizationsByFilters}/>
        <FoundationYearChart
          onClick={onChartClick}
          organizations={data?.organizationsByFilters}/>
        <OrganizationsMapChart organizations={data?.organizationsByFilters}/>
      </Space>
    </>
  );
}
