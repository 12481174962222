export function useNumeraliaByCampus (
  experiences = [],
  audienceTypes = [],
  persons = []
) {
  let act = 0;
  let know = 0;
  let learn = 0;


  experiences.forEach(att => {
    if (att.experience.involvementLevel === "KNOW") {
      know += 1;
    } else if (att.experience.involvementLevel === "LEARN") {
      learn += 1;
    } else if (att.experience.involvementLevel === "ACT") {
      act += 1;
    }
  });

  const result = experiences.reduce((acc, curr) => {
    if(!acc.includes(curr.person.personId)) {
      acc.push(curr.person.personId);
    }
    return acc;
  }, []);

  const impactedCommunity = result.length || 0;

  const getTotalCommunityByAudienceType = (persons, audienceTypes = []) => {
    if (!audienceTypes.length) {
      return persons.length;
    }
    const filteredPersons = persons
      .filter(person => audienceTypes.includes(person.audienceType));
    return filteredPersons.length;
  };

  const totalCommunity = getTotalCommunityByAudienceType(
    persons,
    audienceTypes
  );
  return {
    act,
    know,
    learn,
    totalCommunity,
    impactedCommunity
  };
}
