import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql/";
import { PDFViewer } from "@react-pdf/renderer";
import { ReportDocument } from "../../../../../components/admin/experts/services/payment-report/report-document";
import { formatReportValues } from "../../../../../components/admin/experts/services/payment-report/format-report-values";

export const PaymentReportIndex = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(
    shared.queries.getFullScheduledExpertServiceById,
    {
      fetchPolicy: "network-only",
      variables: { id },
      client
    }
  );
  const [reportData, setReportData] = useState(undefined);

  useEffect(() => {
    if (!loading) {
      const { pages } = formatReportValues(data.scheduledExpertService);
      setReportData(pages);
    }
  }, [loading]);

  if (!reportData) {
    return <p>Cargando...</p>;
  }

  return (
    <PDFViewer
      style={{ border: "0" }}
      width="100%"
      height="100%">
      <ReportDocument pages={reportData} />
    </PDFViewer>
  );
};