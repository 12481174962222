import gql from "graphql-tag";

export const getAssignedServices = gql`
  query GetAssignedServices {
    assignedExpertServices {
      id
      expertService {
        name
      }
      expertServiceData
      expert {
        id
        fullName
      }
      startDate
      endDate
      status
      finished
      duration
      odes {
        name
      }
    }
  }
`;
