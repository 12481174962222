import React from "react";
import { List, Space, Typography } from "antd";
import { Avatar, Format, IconLabel } from "../../shared";
import { MailOutlined, PhoneOutlined, DeleteOutlined } from "@ant-design/icons";
import { withLoader } from "../../../helpers";
import { ActionButton } from "./action-button";

const MemberList = ({
  type = "",
  data,
  isLoading,
  onDelete,
  only = "ALL",
  actionsButton = false
}) => {
  let newData = null;
  const users = data?.batch?.users || data || [];

  if (only === "ADVISER") {
    newData = users?.filter(el => el.systemRole === "ADVISER");
  } else {
    const leader = users?.filter(el => el.id === data?.batch?.leader);
    const teams =
      users
        ?.filter(el => el.id !== data?.batch?.leader)
        ?.filter(el => el.systemRole !== "ADVISER");
    newData = type === "leader" ? leader : teams;
  }

  return (
    <List
      loading={isLoading}
      itemLayout="horizontal"
      dataSource={newData}
      renderItem={el => (
        <List.Item actions={[
          actionsButton
            ? <ActionButton key={el.id} onRemove={() => onDelete(el.id, type)} />
            : <DeleteOutlined
                key={el.id}
                className="clickleable"
                onClick={() => onDelete(el.id, type)} />
        ]}>
          <List.Item.Meta
            avatar={<Avatar size={50}>{el.profilePicUrl || el.fullName}</Avatar>}
            title={el.fullName}
            description={
              <Space direction="vertical">
                {el?.careerName && (
                  <Typography.Text type="secondary">
                    {el.careerName || ""}
                  </Typography.Text>
                )}
                <Space>
                  <IconLabel icon={<MailOutlined />}>{el.email}</IconLabel>
                  <IconLabel icon={<PhoneOutlined />}>
                    <Format type="phone">{el.phone}</Format>
                  </IconLabel>
                </Space>
              </Space>
            }>
          </List.Item.Meta>
        </List.Item>
      )} />
  );
};

export default withLoader(MemberList);
