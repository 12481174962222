import {
  getReadableValue,
  scheduledServicesStatus
} from "../../helpers/select-options";
import { cloneObject } from "../clone-object";
import { eventTypeEnum } from "../constants";

/**
 * @typedef Blame
 * @type {object}
 * @property {string} id
 * @property {string} fullName
 */

/**
 * @typedef ServiceEvent
 * @type {object}
 * @property {string} id
 * @property {string} event The event name
 * @property {string} description Brief description about the event
 * @property {Blame} blame The User who made the change
 * @property {string} createdAt An ISO date string
 * @property {string} updatedAt An ISO date string
 */

/**
 * @typedef TimelineEvent
 * @type {object}
 * @property {string} status
 * @property {string} label
 * @property {string} blame
 * @property {string} date
 * @property {boolean} isCurrent
 */

/**
 * @typedef ExpertService
 * @type {object}
 * @property {string} id
 * @property {"WORKSHOP" | "PRODUCT" | "MENTORSHIP"} type
 */

/**
 * @typedef AssignedExpertService
 * @type {object}
 * @property {ExpertService} expertService
 */

/**
 * @typedef Args
 * @type {object}
 * @property {string} currentStatus
 * @property {ServiceEvent[]} serviceEvents
 * @property {Blame} requestedBy
 * @property {"ODE" | "ADMIN" | "EXPERT"} cancelledBy
 * @property {string} authorizedForPaymentAt
 * @property {string} deliveredAt
 * @property {string} acceptedAt
 * @property {string} createdAt
 * @property {string} assignedAt
 * @property {AssignedExpertService} assignedExpertService
 */

/**
 * @param {Args} args
 * @returns {TimelineEvent[]}
 */
export function getServiceStatusTimelineEvents(args) {
  const {
    currentStatus,
    serviceEvents = [],
    requestedBy = {},
    cancelledBy,
    authorizedForPaymentAt,
    deliveredAt,
    acceptedAt,
    createdAt,
    assignedAt
  } = args;

  const statuses = [
    "ASSIGNED",
    "SCHEDULED",
    "ON_GOING",
    "DELIVERED",
    "ACCEPTED",
    "AUTHORIZED_FOR_PAYMENT",
    "PAYMENT_IN_PROCESS",
    "PAID",
    "CANCELLED",
    "DELIVERY_REJECTED",
    "REJECTED",
    "EXPIRED"
  ];

  const fallbackEvents = {
    ASSIGNED: { date: assignedAt },
    SCHEDULED: { blame: requestedBy?.fullName, date: createdAt },
    CANCELLED: { blame: cancelledBy },
    AUTHORIZED_FOR_PAYMENT: { date: authorizedForPaymentAt },
    DELIVERED: { date: deliveredAt },
    ACCEPTED: { date: acceptedAt}
  };

  const formattedEvents = statuses.map(status => {
    let eventMatched = serviceEvents.reverse().find(serviceEvent => {
      return (serviceEvent.event === eventTypeEnum.STATUS_CHANGED)
        && !!(serviceEvent.description.match(`changed to ${status}`));
    });

    if (!eventMatched) {
      eventMatched = fallbackEvents[status];
    }

    eventMatched = eventMatched && cloneObject(eventMatched);

    return {
      status,
      label: getReadableValue(scheduledServicesStatus, status),
      blame: eventMatched?.blame?.fullName,
      date: eventMatched?.createdAt,
      isCurrent: status === currentStatus
    };
  });

  const deliveredEvent = formattedEvents.find(e => e.status === "DELIVERED");

  /**
   * This second formatting is a workaround to avoid showing the blame of the
   * the WORKSHOPS in the status ACCEPTED and use the same date than the
   * DELIVERED event because they are automatically accepted when the expert
   * delivers it
  */
  return formattedEvents.map(formattedEvent => {
    const isWorkshop =
      args.assignedExpertService?.expertService?.type === "WORKSHOP";

    let shouldPolyfillWorkshopEvents = false;
    if (
      isWorkshop && ["ACCEPTED", "DELIVERED"].includes(formattedEvent.status)
    ) {
      shouldPolyfillWorkshopEvents = true;
    }

    if (
      shouldPolyfillWorkshopEvents
      && ["DELIVERED", "ACCEPTED"].includes(formattedEvent.status)
    ) {
      if (formattedEvent.status === "ACCEPTED") {
        delete formattedEvent.blame;
      }

      formattedEvent.date = deliveredEvent.date;
    }

    return formattedEvent;
  });
}

/**
 * @typedef MappedEvent
 * @type {{[key in string]: TimelineEvent}}
 */

/**
 * @param {Args} args
 * @returns {MappedEvent}
 */
export function getMappedServiceStatusTimelineEvents(args) {
  const events = getServiceStatusTimelineEvents(args);

  const mappedEvents = {};
  events.forEach(e => {
    if (e.status === "PAYMENT_IN_PROCESS" && !e?.blame && !e?.date) {
      const findPaymentPaid = events.find(el => el.status === "PAID");
      mappedEvents[e.status] = {
        ...e,
        blame: findPaymentPaid.blame,
        date: findPaymentPaid.date
      };
    } else {
      mappedEvents[e.status] = e;
    }
  });

  return mappedEvents;
}
