import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { productStageToChart } from "./product-stage-to-chart";
import { productStageChartOptions } from "./product-stage-chart-options";
import { Visibility } from "../../../../components/shared";
import { Card } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const ProductStageChart = ({ cubeClient, filters }) => {
  const [productStageData, setProductStageData] = useState();

  useEffect(() => {
    const loadCubeQuery = async () => {
      const cubeFilters = [
        {
          "member": "Company.companyProductStage",
          "operator": "set"
        }
      ];

      if (filters.batchId) {
        cubeFilters.push({
          "member": "Company.batchId",
          "operator": "equals",
          "values": [filters.batchId]
        });
      }

      if (filters.programId) {
        cubeFilters.push({
          "member": "Company.programId",
          "operator": "equals",
          "values": [filters.programId]
        });
      }

      if (filters.batchRangeDates.since && filters.batchRangeDates.until) {
        const { since, until } = filters.batchRangeDates;
        const filter = {
          "member": "Company.batchStartDate",
          "operator": "inDateRange",
          "values": [since, until]
        };
        cubeFilters.push(filter);
      }

      const resultSet = await cubeClient.load({
        "measures": [
          "Company.companiesCount"
        ],
        "order": {
          "Company.companiesCount": "desc"
        },
        "dimensions": [
          "Company.companyProductStage"
        ],
        "filters": cubeFilters
      });

      const queryData = resultSet.loadResponse.results[0].data;
      const chartData = productStageToChart(queryData);
      setProductStageData(chartData);
    };


    loadCubeQuery()
      .then()
      .catch(console.error);
  }, [filters]);

  return (
    <Visibility visible={!!productStageData}>
      <Card
        style={{ marginTop: "2rem" }}
        title="Por etapa de producto">
        <Bar
          data={productStageData}
          type={"bar"}
          height={400}
          options={productStageChartOptions} />
      </Card>
    </Visibility>
  );
};
