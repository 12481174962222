/**
 * @param {rawData[]} enrollmentRows
 * @return {enrollmentInput[]}
 */

export const formatEnrollmentsForImportation = (enrollmentRows = []) => {
  return enrollmentRows.map(raw => ({
    academicExerciseCode: String(raw["Clave Ejercicio Academico"]),
    enrollmentSchoolControlNumber: raw.Matricula,
    firstName: raw.Nombres,
    middleName: raw["Apellido Paterno"],
    lastName: raw["Apellido Materno"],
    academicLevel: raw["Desc Nivel Acad Alumno"],
    audienceType: raw["Desc Nivel Acad Alumno"],
    academicProgramCode: raw["Clave Major Pgma Acad"],
    planTec21: raw["Ind Plan Tec21"] === "SI",
    academicProgram: raw["Desc Programa Academico"],
    academicPeriod: parseInt(raw["Clave Periodo Academico"]),
    academicPeriodDescription: raw["Desc Periodo Academico"],
    majorSchoolClassification: raw["v_Clasificación Escuela por Major"],
    email: raw["Cuenta Correo Alumno"],
    score: raw["Calificacion de competencia"],
    campus: raw.Campus
  }));
};

/**
 * @typedef enrollmentInput
 * @property academicExerciseCode: string
 * @property enrollmentSchoolControlNumber: string
 * @property firstName: string
 * @property middleName: string
 * @property lastName: string
 * @property academicLevel: string
 * @property audienceType: string
 * @property academicProgramCode: string
 * @property planTec21: boolean
 * @property academicProgram: string
 * @property academicPeriod: number
 * @property academicPeriodDescription: string
 * @property majorSchoolClassification: string
 * @property email: string
 * @property score: string
 * @property campus: string
 */

/**
 * @typedef rawData
 * @property {string} Clave Ejercicio Academico
 * @property {string} Matricula
 * @property {string} Nombres
 * @property {string} Apellido Paterno
 * @property {string} Apellido Materno
 * @property {string} Desc Nivel Acad Alumno
 * @property {string} Clave Major Pgma Acad
 * @property {string} Ind Plan Tec21
 * @property {string} Campus
 * @property {string} Desc Programa Academico
 * @property {string} Clave Periodo Academico
 * @property {string} Desc Periodo Academico
 * @property {string} v_Clasificación Escuela por Major
 * @property {string} Cuenta Correo Alumno
 * @property {string} Calificacion de competencia
 */
