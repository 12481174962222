import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Form, message } from "antd";
import moment from "moment";
import { EditCompanyContext } from ".";
import { EditCompanyModal } from "./modal";
import { useCompany } from "../../../hooks";
import { cloneObject } from "../../../helpers";
import {
  getDeletedIdsFromTwoArrays
} from "../../../helpers/get-deleted-ids-from-two-arrays";

const initialState = {
  isVisible: false,
  type: "",
  company: {},
  oldPresenceStates: []
};

const nameValidationsInitialState = {
  status: undefined,
  msg: undefined
};

export function EditCompanyProvider({ data, refetch, children }) {
  const { validateOriginalName } = useCompany();
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [
    nameValidations,
    setNameValidations
  ] = useState(nameValidationsInitialState);
  const [form] = Form.useForm();
  const { id } = useParams();

  const { updateCompany } = useCompany();

  const onEdit = type => {
    setState(prev => ({
      ...prev,
      isVisible: true,
      type,
      company: data,
      oldPresenceStates: data?.presenceStates || []
    }));

    form.setFieldsValue({
      ...data,
      annualSales: data?.salesStage === "NO_SALES" ? "0" : data?.annualSales,
      country: data?.address?.country,
      businessName: data?.businessName?.replace("N/A", ""),
      logoUrl: data?.logoUrl ? [data.logoUrl] : [],
      inventionRegisterDate: data?.inventionRegisterDate ? moment(data.inventionRegisterDate || undefined) : null,
      startDate: data?.startDate ? moment(data.startDate) : null,
      fiscalEntryDate: data?.fiscalEntryDate ? moment(data.fiscalEntryDate) : null
    });
  };

  const formatValues = (values, oldValues) => {
    if (state.type === "GENERAL") {
      const cleanData = {};
      if (values.fiscalState === "NOT_REGISTERED") {
        cleanData.businessName = "";
        cleanData.fiscalId = "";
        cleanData.fiscalRepresentative = "";
      } if (values.fiscalState === "PHYSICAL_PERSON") {
        cleanData.fiscalRepresentative = "";
      } else {
        cleanData.fiscalEntryDate = values.fiscalEntryDate
          ? values.fiscalEntryDate.toISOString()
          : null;
      }

      return {
        ...values,
        ...cleanData,
        logoUrl: state?.company?.logoUrl,
        startDate: values.startDate.toISOString()
      };
    } else if (state.type === "ABOUT") {
      const filesWithId = values.files?.filter(file => file.id);
      const newFiles = values.files?.filter(file => !file.id);
      const deletedFiles = getDeletedIdsFromTwoArrays(
        filesWithId,
        oldValues.files
      );
      newFiles.map(file => {
        delete file.uuid;
        delete file.__typename;
        return file;
      });
      return {
        ...values,
        techBased: state.company.techBased || false,
        social: state.company.social || false,
        keywords: { set: values.keywords || [] },
        mainTechnologies: { set: values.mainTechnologies || [] },
        files: {
          create: newFiles || [],
          deleteMany: { id_in: deletedFiles }
        }
      };
    } else if (state.type === "CONTACT") {
      const statesWithId = oldValues.presenceStates?.filter(state => state.id);
      const newStates = oldValues.presenceStates?.filter(state => !state.id);
      const deletedStates = getDeletedIdsFromTwoArrays(
        statesWithId,
        state.oldPresenceStates
      );
      const createStates = newStates.map(state => {
        const clonedState = cloneObject(state);
        clonedState.cities = { set: clonedState.cities };
        delete clonedState.id;
        delete clonedState.__typename;
        return clonedState;
      }) || [];
      const updateStates = statesWithId.map(state => {
        const clonedState = cloneObject(state);
        clonedState.cities = { set: state.cities };
        delete clonedState.id;
        delete clonedState.__typename;
        return {
          where: { id: state.id },
          data: clonedState
        };
      });
      const address = {
        country: values?.country,
        street: values?.street,
        city: values?.city,
        neighborhood: values?.colonyAddress,
        zipCode: values?.zipCode
      };
      return {
        ...values,
        address: {
          upsert: {
            create: address,
            update: address
          }
        },
        socialMedia: state.company.socialMedia
          ? { set: state.company.socialMedia.concat([]) || [] }
          : undefined,
        presenceStates: {
          create: createStates,
          updateMany: updateStates,
          deleteMany: { id_in: deletedStates || [] }
        }
      };
    }
  };

  const onOk = async () => {
    await form.validateFields();
    if (
      state?.type === "CONTACT" &&
      !state.company?.presenceStates?.length
    ) {
      message.error("Debe de seleccionar al menos una presencia");
      return;
    }
    setLoading(true);
    const values = form.getFieldsValue();
    if (state?.type === "GENERAL") {
      const isOriginal =
        (values?.name?.toUpperCase() === state?.company?.name?.toUpperCase())
        ? true : await validateOriginalName({ name: values.name });
      setLoading(false);
      if (!isOriginal) {
        setNameValidations({
          status: "error",
          msg: "El nombre para su compañía ya se encuentra en uso."
        });
        setTimeout(
          () => setNameValidations(nameValidationsInitialState),
          3000
        );
        return;
      }
    }
    const formatedValues = formatValues(values, state.company);

    await updateCompany({
      data: formatedValues,
      odeId: id,
      afterSuccess: async () => {
        await refetch();
        onCancel();
      }
    });
    setLoading(false);
  };

  const onCancel = () => {
    setState(initialState);
    form.resetFields();
  };

  const injectActions = {
    onEdit,
    onCancel
  };

  return (
    <EditCompanyContext.Provider value={injectActions}>
      <EditCompanyModal
        visible={state.isVisible}
        form={form}
        state={state}
        nameValidations={nameValidations}
        setState={setState}
        type={state.type}
        loading={loading}
        onCancel={onCancel}
        onOk={onOk} />
      {children}
    </EditCompanyContext.Provider>
  );
}
