import React, {
  useState,
  useCallback,
  useMemo,
  useContext
} from "react";
import { OnboardingContext } from "./context";
import { shared } from "../../../graphql/shared";
import { CurrentUserContext } from "../../shared/current-user";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { client } from "../../../../graphql";

export function OnBoardingContextProvider({ children }) {
  const [ state, setState ] = useState({
    expert: {},
    dirty: false
  });

  const { currentUser } = useContext(CurrentUserContext);

  const { data, loading, error } = useQuery(
    shared.queries.getExpertById,
    {
      variables: { id: currentUser.id },
      fetchPolicy: "network-only",
      client
    }
  );

  const [ updateExpertById ] = useMutation(
    shared.mutations.updateExpertById,
    { client }
  );


  const updateExpert = useCallback(expert => {
    const newExpert = {
      ...state.expert,
      ...expert
    };

    setState(prevState => ({
      ...prevState,
      dirty: true,
      expert: newExpert
    }));
  });

  const save = useCallback(() => {
    return updateExpertById({
      variables: { data: state.expert, id: currentUser.id }
    });
  });

  const injectActions = useMemo(() => ({
    updateExpert,
    save,
    data,
    loading,
    error
  }), [state, data]);

  return (
    <OnboardingContext.Provider value={injectActions}>
      { children }
    </OnboardingContext.Provider>
  );
}
