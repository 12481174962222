import gql from "graphql-tag";

export const getEntrepreneurCompaniesInBatchQuery = gql`
  query GetEntrepreneurCompaniesInBatchQuery(
    $entrepreneurId: ID!,
    $batchId: ID!
  ) {
    oDEs(
      where: {
        team_some: { id: $entrepreneurId }
        batches_some: { id: $batchId }
      }
    ) {
      id
      name
      logoUrl
      description
    }
  }
`;