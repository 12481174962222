import { Button } from "antd";
import React, { useState, useEffect } from "react";
import { Visibility } from "../visibility";
import { EditOutlined, CheckOutlined } from "@ant-design/icons";

/**
 *
 * @callback EditableField
 *
 * @param {function} onChangeHandler
 */

/**
 * @param {object} props
 * @param {EditableField} props.editableField component visible when editing
 * @param {function} props.onChange on change callback
 * @param {*} props.children
 * @param {string} props.name field name
 * @param {function} props.onSave on save callback
 * @param {boolean} props.editing toggle editing mode
 * @param {boolean} props.editable allow or disallow editing
 */
export function EditableLabel({
  children,
  onChange,
  editableField,
  name,
  onSave,
  editing,
  editable = true,
  hideEditButton = false
}) {
  const [state, setState] = useState({ editing: false, value: undefined });

  useEffect(() => {
    setState({ ...state, editing: editing });
  }, [editing]);

  const onChangeHandler = data => {
    if(data.currentTarget !== null && data.currentTarget) {
      data = data.currentTarget.value;
    }

    setState({ ...state, value: data });
    onChange && onChange(data, name);
  };

  const toggleEdit = async () => {
    if(state.editing && state.value) {
      onSave && await onSave(state.value, name);
    }

    setState({...state, editing: !state.editing});
  };

  return (
    <>
      <Visibility visible={!state.editing}>
        <div className="editable-label">
          {children}
          <Visibility visible={editable}>
            <Visibility visible={!hideEditButton}>
              <Button onClick={toggleEdit} type="link">
                <EditOutlined />
              </Button>
            </Visibility>
          </Visibility>
        </div>
      </Visibility>
      <Visibility visible={state.editing}>
        <div className="editable-label editing">
          {editableField(onChangeHandler)}
          <Visibility visible={!hideEditButton}>
            <Button onClick={toggleEdit} type="link">
              <CheckOutlined />
            </Button>
          </Visibility>
        </div>
      </Visibility>
    </>
  );
}
