import React from "react";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export function SearchField({onChange, ...props}) {
  return (
    <Input
      prefix={<SearchOutlined />}
      onChange={onChange}
      placeholder="Buscar"
      {...props} />
  );
}
