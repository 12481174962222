export const handleFormOnChange = async (values, updatePersonalInformation) => {
  values.fullName = [values.firstName, values.middleName, values.lastName]
    .filter(str => str)
    .join(" ");
  values.ifullName = values.fullName?.toLowerCase();
  values.location = {
    upsert: {
      create: {
        city: values.city,
        state: values.state,
        country: values.country
      },
      update: {
        city: values.city,
        state: values.state,
        country: values.country
      }
    }
  };

  if (updatePersonalInformation) {
    updatePersonalInformation(values);
  }
};
