import React, { useState } from "react";
import { message } from "antd";
import { client } from "../../../../graphql/";
import { shared } from "../../../graphql/shared";
import { useMutation } from "@apollo/react-hooks";
import { CreateAnnouncementModal } from "./modal";
import { CreateAnnouncementModalContext } from "./context";

export const CreateAnnouncementModalProvider =
  ({ batchId, children, refetch }) => {

  const [state, setState] = useState(false);

  const [createAnnouncement, { loading }] = useMutation(
    shared.mutations.createAnnouncement,
    { client }
  );

  const sendAnnouncement = async (data, onFinishSend) => {
    try {
      data.batch = { connect: { id: batchId } };
      await createAnnouncement({
        variables: { data }
      });
      onFinishSend();
    } catch (e) {
      message.error("Ha ocurrido un error, inténtalo de nuevo\
        en unos segundos.");
    }
    await refetch();
  };

  const openModal = () => {
    setState(true);
  };

  const closeModal = () => {
    setState(false);
  };

  const injectActions = {
    openModal,
    closeModal
  };

  return (
    <CreateAnnouncementModalContext.Provider value={injectActions}>
      <CreateAnnouncementModal
        visible={state}
        sendAnnouncement={sendAnnouncement}
        loading={loading}
        closeModal={closeModal} />
      {children}
    </CreateAnnouncementModalContext.Provider>
  );
};
