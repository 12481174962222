import { shared } from "../../../../graphql/shared";

export async function getById(id) {
  return await this.client.query({
    query: shared.queries.getScheduledExpertServiceById,
    variables: {
      id: id
    }
  });
}
