import { client } from "../../../graphql";
import { deleteAssignedExpertService } from "./graphql";

export const deleteAssignedExpertServicesService = async ({
  assignedExpertServiceId
}) => {
  const result = await client.mutate({
    mutation: deleteAssignedExpertService,
    variables: {
      id: assignedExpertServiceId
    }
  });

  return result?.data?.deleteAssignedExpertService || {};
};
