/* eslint-disable max-lines */
import React, { useEffect, useState } from "react";
import "./styles.sass";
import { Visibility } from "../../visibility";
import { FileLinkField } from "../../file-link-field";
import {
  Row,
  Form,
  Input,
  Select,
  Switch,
  Tooltip,
  DatePicker,
  TreeSelect
} from "antd";
import {
  inventionTypeOptions
} from "../../../../helpers/select-options/company/latest/invention-type";
import {
  mainTechnologyOptions
} from "../../../../helpers/select-options/company/latest/main-technology";
import {
  socialComponentOptions
} from "../../../../helpers/select-options/company/latest/social-component";
import {
  inventionRegisterOptions
} from "../../../../helpers/select-options/company/latest/invention-register";
import {
  technologyLevelStatusOptions
} from "../../../../helpers/select-options/company/latest/technology-level-status";
import {
  getCompanyMissingRequiredFields,
  INDUSTRIES, ODE_SECTORS
} from "../../../../helpers";


export const CompanyAboutForm = ({
  form,
  company,
  companyChecks,
  onAboutSwitchChange
}) => {

  const { TextArea } = Input;

  const [inputsVisibility, setInputsVisibility] = useState({
    inventionRegister: companyChecks.inventionType !== "DOESNT_APPLY",
    inventionRegisterDate: companyChecks.inventionRegister === "GRANTED"
  });

  const onFieldChange = (value, field) => {
    if (field === "inventionType") {
      setInputsVisibility(prev => ({
        ...prev,
        inventionRegister: value !== "DOESNT_APPLY"
      }));
    } else if (field === "inventionRegister") {
      setInputsVisibility(prev => ({
        ...prev,
        inventionRegisterDate: value === "GRANTED"
      }));
    }
  };

  const [validation, setValidation] = useState({});

  useEffect(() => {
    if (company) {
      const { requiredFieldsValidation } =
        getCompanyMissingRequiredFields(company);
      setValidation(requiredFieldsValidation);
    }
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ padding: "0 16px" }}>
      <Form.Item
        name="shortDescription"
        rules={[{ required: true }]}
        help={validation?.shortDescription?.msg}
        validateStatus={validation?.shortDescription?.status}
        label="Describe tu compañía en una línea (máximo 150 caracteres).">
        <TextArea
          rows={1}
          showCount
          maxLength={150}
          placeholder="Escribe una descripción corta de tu compañía."
          onChange={() => setValidation(prev => ({
            ...prev,
            shortDescription: {
              msg: undefined,
              status: undefined
            }
          }))} />
      </Form.Item>
      <Form.Item
        label="Acerca de tu compañía (máximo 1300 caracteres)."
        name="description">
        <TextArea
          rows={3}
          showCount
          maxLength={1300}
          placeholder=
            "Agrega una descripción larga y detallada de tu compañía." />
      </Form.Item>
      <Form.Item
        label="Producto"
        name="product">
        <Input placeholder="Escribe el nombre de tu producto o servicio." />
      </Form.Item>
      <Form.Item
        label="Agregar multimedia"
        name="files">
        <FileLinkField />
      </Form.Item>
      <Form.Item
        label="Mercado"
        name="market">
        <Input
          placeholder=
            "Describe el mercado meta en el que se enfoca tu compañía." />
      </Form.Item>
      <Row justify="space-between">
        <Form.Item
          label="Base tecnológica">
          <Tooltip title="Nuevos productos o servicios que parten de la de la investigación científica y el desarrollo tecnológico.">
            <Switch
              checked={companyChecks.techBased}
              onChange={e => onAboutSwitchChange(e, "techBased")} />
          </Tooltip>
        </Form.Item>
        <Visibility visible={companyChecks.techBased}>
          <Form.Item
            label="Principal tecnología"
            name="mainTechnologies"
            rules={[{ required: companyChecks?.techBased }]}>
            <TreeSelect
              treeData={mainTechnologyOptions}
              treeCheckable
              style={{ width: "357px " }} />
          </Form.Item>
        </Visibility>
      </Row>
      <Visibility visible={companyChecks.techBased}>
        <Row justify="space-between">
          <Form.Item
            label="Tipo de invención"
            name="inventionType"
            rules={[{ required: companyChecks?.techBased }]}>
            <Select
              placeholder="Seleccionar"
              onChange={e => onFieldChange(e, "inventionType")}
              options={inventionTypeOptions}
              style={{ width: "357px " }} />
          </Form.Item>
          <Visibility visible={inputsVisibility.inventionRegister}>
            <Form.Item
              label="Registro de invención"
              name="inventionRegister"
              rules={[{ required: inputsVisibility.inventionRegister }]}>
              <Select
                placeholder="Seleccionar"
                onChange={e => onFieldChange(e, "inventionRegister")}
                options={inventionRegisterOptions}
                style={{ width: "357px " }} />
            </Form.Item>
          </Visibility>
        </Row>
        <Row justify="space-between">
          <Visibility
            visible={
            inputsVisibility.inventionRegister
              && inputsVisibility.inventionRegisterDate}>
            <Form.Item
              label="Fecha de registro de la invención"
              name="inventionRegisterDate"
              rules={[{
                required:
                  inputsVisibility.inventionRegister
                  && inputsVisibility.inventionRegisterDate }]}>
              <DatePicker
                style={{ width: "357px " }}
                picker="month"
                format="MMMM-YYYY" />
            </Form.Item>
          </Visibility>
          <Tooltip title="TRL, Technology readiness level o escala de madurez tecnológica.">
            <Form.Item
              label="Madurez tecnológica"
              name="technologyLevel">
              <Select
                style={{ width: "357px " }}
                options={technologyLevelStatusOptions} />
            </Form.Item>
          </Tooltip>
        </Row>
      </Visibility>
      <Row justify="space-between">
        <Form.Item
          label="Social">
          <Tooltip title="Empresa rentable cuyo modelo de negocio impacta positivamente en un problema social.">
            <Switch
              checked={companyChecks.social}
              onChange={e => onAboutSwitchChange(e, "social")} />
          </Tooltip>
        </Form.Item>
        <Visibility visible={companyChecks.social}>
          <Form.Item
            label="Componente social"
            name="socialComponent"
            rules={[{ required: companyChecks?.social }]}>
            <Select
              style={{ width: "357px " }}
              options={socialComponentOptions} />
          </Form.Item>
        </Visibility>
      </Row>
      <Row justify="space-between">
        <Form.Item
          label="Industria"
          name="economicIndustry"
          rules={[{ required: true }]}
          help={validation?.economicIndustry?.msg}
          validateStatus={validation?.economicIndustry?.status}>
          <Select
            style={{ width: "357px " }}
            options={INDUSTRIES}
            onChange={() => setValidation(prev => ({
              ...prev,
              economicIndustry: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
        <Form.Item
          label="Sector"
          name="economicSector"
          rules={[{ required: true }]}
          help={validation?.economicSector?.msg}
          validateStatus={validation?.economicSector?.status}>
          <Select
            options={ODE_SECTORS}
            style={{ width: "357px " }}
            onChange={() => setValidation(prev => ({
              ...prev,
              economicSector: {
                msg: undefined,
                status: undefined
              }
            }))} />
        </Form.Item>
      </Row>
      <Form.Item
        label="Palabras clave"
        name="keywords">
        <Select
          mode="tags"
          dropdownClassName="keywords-dropdown"
          placeholder="Escribe las palabras clave que describan tu compañía." />
      </Form.Item>
    </Form>
  );
};
