import moment from "moment";
import { cloneDeep } from "lodash";
import {
  getReadableValue
} from "../../select-options/get-readable-value";
import { programTypes } from "../../select-options/program";
import { checkUnicodeString } from "../../check-unicode-string";
import { CLEAN_TEXT } from "../../../helpers";

export function batch(data = {}) {
  const decorated = cloneDeep(data);

  decorated.object = data;

  decorated.programType = getReadableValue(programTypes, data?.program?.type);

  const startDate = moment(data?.startDate).format("DD/MM/YYYY");
  const finishDate = moment(data?.finishDate).format("DD/MM/YYYY");
  decorated.period = [startDate, finishDate].join(" - ");

  decorated.coverPicUrl = data?.program?.coverPicUrl;
  decorated.coordinators = data
    ?.coordinators?.map(user => user?.fullName)?.join(", ");

  decorated.description = checkUnicodeString(data.description || "") ?
    CLEAN_TEXT.UNICODE_TO_STRING(data.description || "")
    : data.description;

  return decorated;
}
