import React, { useState } from "react";
import { ScheduleExpertServiceModalContext } from "./context";
import { ScheduleExpertServiceModal } from "./modal";

export function ScheduleExpertServiceModalProvider({ children }) {
  const [state, setState] = useState({
    isModalOpen: false,
    assignedService: undefined,
    userId: undefined
  });

  const onSaveListeners = [];

  const openModal = (assignedService = false, userId = false) => {
    setState({
      isModalOpen: true,
      assignedService,
      userId
    });
  };

  const closeModal = () => {
    setState({
      isModalOpen: false,
      assignedService: undefined,
      userId: undefined
    });
  };

  const onSave = values => {
    onSaveListeners.forEach(i => i(values));
    closeModal();
  };

  const addOnSaveListener = listenerFunc => {
    onSaveListeners.push(listenerFunc);
  };

  return (
    <ScheduleExpertServiceModalContext.Provider value={{
      openModal,
      closeModal,
      addOnSaveListener}}>
      <ScheduleExpertServiceModal
        visible={state.isModalOpen}
        assignedService={state.assignedService}
        userId={state.userId}
        onCancel={closeModal}
        onSave={onSave} />
      { children }
    </ScheduleExpertServiceModalContext.Provider>
  );
}
