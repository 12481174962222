import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AddButton, RoleGuard } from "../../../shared";
import { List, Button, Space, message } from "antd";
import { BatchItem } from "./item";
import {
  withRouter,
  cardGridSizes,
  paginationConfig
} from "../../../../helpers";
import {
  BatchModalContext,
  CloneBatchModalContext,
  PaginationContext
} from "../../../../contexts/shared";
import { useMutation } from "@apollo/react-hooks";
import { client } from "../../../../../graphql";
import { shared } from "../../../../graphql/shared";
import { admin } from "../../../../graphql/admin";
import "./styles.sass";
import useTreatment from "../../../../hooks/feature-flags/use-treatment";

function BatchesList({
  data,
  isLoading,
  navigate,
  currentPage = 1,
  onChangePage
}) {
  const [deleteBatchById] = useMutation(shared.mutations.deleteBatchById, {
    client,
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: admin.queries.getBatches, fetchPolicy: "network-only" }
    ]
  });

  const {
    openModal: openCloneModal
  } = useContext(CloneBatchModalContext);

  const {
    openModal
  } = useContext(BatchModalContext);

  const { onPaginationChange } = useContext(PaginationContext);

  const history = useHistory();

  const onDelete = id => async () => {
    const closeLoadingMessage = message.loading("Eliminando...");
    try {
      await deleteBatchById({ variables: { id } });
      message.success("Edición eliminada.");
    }
    catch(e) {
      if (e?.message?.match(/required relation 'BatchToMasterContent'/)) {
        message
          .warning("La edición tiene contenidos. No es posible eliminar.", 6);
      } else {
        message.error("Ocurrió un error");
        console.error(e);
      }
    } finally {
      closeLoadingMessage();
    }
  };

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange("batches")(page, pageSize);
  };

  const disableBatchOfferTreatmentValue =
    useTreatment("DisableBatchOffer");

  const disableExpertsTabValue =
    useTreatment("Disable_Experts_Tab");

    const onCreateBatch = () => {
      if (disableBatchOfferTreatmentValue === "ON") {
        // polyfill the args because the modal was destructuring
        // the first arg to check if the programId has been passed
        openModal({});
      } else if (disableBatchOfferTreatmentValue === "OFF") {
        history.push("./batches/new");
      }
    };

  const navigateToBatchDetail = id => {
    if (disableBatchOfferTreatmentValue === "ON") {
      if(disableExpertsTabValue === "ON") {
        navigate(`./batches/${id}/odes`);
      } else {
        navigate(`./batches/${id}/experts`);
      }
    }
    else if(disableBatchOfferTreatmentValue === "OFF") {
      navigate(`./batches/${id}/offer?section=information`);
    }
  };

  const navigateToBatchCompanies = id => {
    if (disableBatchOfferTreatmentValue === "ON") {
      navigate(`./batches/${id}/odes`);
    } else if (disableBatchOfferTreatmentValue === "OFF") {
      navigate(`./batches/${id}/offer?section=companies`);
    }
  };

  return (
    <>
      <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR"]}>
        <AddButton
          onClick={onCreateBatch}
          style={{ width: "100%" }}>
          Añadir batch
        </AddButton>
      </RoleGuard>
      <List
        className="batches-list"
        style={{ marginTop: "24px" }}
        pagination={{
          ...paginationConfig,
          defaultPageSize: 12,
          current: currentPage,
          onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
          total: data?.meta?.aggregate?.count || 0
        }}
        loading={isLoading}
        grid={cardGridSizes}
        dataSource={data?.batches}
        renderItem={batch =>
          <List.Item>
            <BatchItem
              clickable
              batch={batch}
              onClick={() =>
                navigateToBatchDetail(batch.id)}
              onAddOde={() =>
                navigateToBatchCompanies(batch.id)}
              actions={{
                extra: [{
                  action: () => openCloneModal(batch), label: "Copiar"
                }],
                onDelete: onDelete(batch.id)
              }}
              extra={
                <Space>
                  <Button
                    onClick={() => openCloneModal(batch)}
                    type="secondary">
                    Copiar
                  </Button>
                </Space>
              } />
          </List.Item>
        } />
    </>
  );
}

export default withRouter(BatchesList);
