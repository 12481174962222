import React, { useState, useMemo, useContext } from "react";
import { Form, message } from "antd";
import { withRouter } from "react-router";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { client } from "../../../../../graphql";
import { admin } from "../../../../graphql/admin";
import { shared } from "../../../../graphql/shared";
import { Section } from "../../../../components/shared";
import { StepsWrapper } from "../../../../components/expert/shared";
import { NewExpertContext } from "../../../../contexts/experts/new/context";
import {
  ExpertServiceContext
} from "../../../../contexts/experts/new/service/context";
import {
  FiscalForm,
  ContactDataForm,
  LocationForm
} from "../../../../components/expert/new/personal-information";
import { getUpdatedData } from "./get-updated-data";

const PersonalInformation = ({ history, match }) => {
  const [form] = Form.useForm();
  const userId = match?.params?.userId || "";

  const { ExpertsService } = useContext(ExpertServiceContext);

  const [state, setState] = useState({
    busy: false,
    personalInformation: {}
  });

  const [createExpertUser] = useMutation(
    admin.mutations.createUser,
    { client }
  );

  const [updateExpertProfile] = useMutation(
    admin.mutations.updateExpertProfile,
    {
      client,
      refetchQueries: [{
        query: shared.queries.getExpertById,
        variables: { id: userId }
      }]
    }
  );

  const { loading, error, data } = useQuery(
    shared.queries.getExpertById,
    {
      client,
      variables: { id: userId }
    });

  const updatePersonalInformation = personalInformation => {
    ExpertsService
      .setUpdatePersonalInformation(personalInformation, state, setState);
  };

  const save = async () => {
    try {
      const updatedData = getUpdatedData({
        personalInformation: state.personalInformation,
        userId
      });

      const { data = {} } = await ExpertsService.setSave(
        updatedData,
        userId ? updateExpertProfile : createExpertUser,
        userId
      );

      const { createUser = {}, updateUser = {} } = data;
      const updatedUserId = createUser.id || updateUser.id;
      message.success("Datos guardados");
      history.push(`/v2/expert/new/${updatedUserId}/professional-information`);
    } catch (err) {
      console.error(err);
      message.error("Ocurrió un error");
    }
  };

  const injectActions = useMemo(() => ({
    updatePersonalInformation,
    save,
    loading,
    error,
    data,
    form
  }), [state, loading]);

  const handleNext = async event => {
    event.preventDefault();
    try {
      await form.validateFields();
    } catch (error) {
      return message.warning("Campos requeridos faltantes.");
    }
    await save();
  };

  return (
    <NewExpertContext.Provider value={injectActions}>
      <StepsWrapper
        loading={state.busy}
        onNext={handleNext}
        currentStep={0}>
        <Section title="Datos de contacto">
          <ContactDataForm />
        </Section>
        <Section title="Ubicación">
          <LocationForm />
        </Section>
        <Section title="Fiscal">
          <FiscalForm />
        </Section>
      </StepsWrapper>
    </NewExpertContext.Provider>
  );
};

export default withRouter(PersonalInformation);
