import React, { useState } from "react";
import { List, Empty } from "antd";
import { CompositeField } from "../../../composite-field";
import { EntrepreneurshipModal } from "./modal";
import { EntrepreneurshipItem } from "./item";

export function EntrepreneurshipsInput({
  onChange,
  value,
  editable
}) {
  const [state, setState] = useState({
    isModalOpen: false,
    edit: false
  });

  const setModalVisibility = (visible = true) => {
    setState(prevState => ({
      ...prevState,
      isModalOpen: visible
    }));
  };

  const onEdit = (item, index) => () => {
    item.index = index;
    setState({ isModalOpen: true, edit: item });
  };

  const onSave = (addNew, replaceItemAtIndex) => values => {
    if (typeof values.index === "undefined") {
      addNew(values);
    } else {
      const index = values.index;
      delete values.index;

      replaceItemAtIndex(index, values);
    }
    setModalVisibility(false);
  };

  return (
    <CompositeField
      onChange={onChange}
      isAddDisabled={!editable}
      value={value}
      onClickAdd={() => setModalVisibility(true)}
      addLabel="Agregar emprendimiento">
      {({items, addNew, removeItem, replaceItemAtIndex}) =>
        <>
          <EntrepreneurshipModal
            onSave={onSave(addNew, replaceItemAtIndex)}
            onCancel={() => setModalVisibility(false)}
            visible={state.isModalOpen}
            edit={state.edit} />
          <List>
            { items.map((item, index) =>
              <EntrepreneurshipItem
                editable={editable}
                key={`entrepreneurship-item-${item.uuid}`}
                entrepreneurship={item}
                onDelete={removeItem(index)}
                onEdit={onEdit(item, index)} />
            ) }
            { (items.length === 0) ?
              <Empty />
            : null }
          </List>
        </>
      }
    </CompositeField>
  );
}
