import React, { useEffect, useState } from "react";
import {
  entrepreneursPerGenderToTable
} from "./entrepreneurs-per-gender-to-table";
import { Card, Col, Row, Statistic } from "antd";
import { Visibility } from "../../../../components/shared";

export const EntrepreneursPerGenderChart = ({ cubeClient, filters }) => {
  const [rows, setRows] = useState({});
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadCubeQuery = async () => {
      setLoading(true);
      const cubeFilters = [
        {
          "member": "Company.teamMemberGender",
          "operator": "set"
        }
      ];

      const totalCubeFilters = [];

      if (filters.batchId) {
        const filter = {
          "member": "Company.batchId",
          "operator": "equals",
          "values": [filters.batchId]
        };
        cubeFilters.push(filter);
        totalCubeFilters.push(filter);
      }

      if (filters.programId) {
        const filter = {
          "member": "Company.programId",
          "operator": "equals",
          "values": [filters.programId]
        };
        cubeFilters.push(filter);
        totalCubeFilters.push(filter);
      }

      if (filters.batchRangeDates.since && filters.batchRangeDates.until) {
        const { since, until } = filters.batchRangeDates;
        const filter = {
          "member": "Company.batchStartDate",
          "operator": "inDateRange",
          "values": [since, until]
        };
        cubeFilters.push(filter);
        totalCubeFilters.push(filter);
      }

      const resultSet = await cubeClient.load({
        "measures": [
          "Company.entrepreneursCount"
        ],
        "order": {
          "Company.entrepreneursCount": "desc"
        },
        "dimensions": [
          "Company.teamMemberGender"
        ],
        filters: cubeFilters
      });

      const totalResultSet = await cubeClient.load({
        "measures": [
          "Company.entrepreneursCount"
        ],
        filters: totalCubeFilters
      });

      const queryData = resultSet.loadResponse.results[0].data;
      const queryDataTotal = totalResultSet.loadResponse.results[0].data;

      const total = parseInt(queryDataTotal[0]["Company.entrepreneursCount"]);
      setTotal(total);

      const rows = entrepreneursPerGenderToTable(queryData, total);
      setRows(rows);
    };

    loadCubeQuery()
      .then()
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [filters]);

  return (
    <Card
      loading={loading}
      style={{ marginTop: "2rem" }}
      title={`Emprendedores por género de un total de ${total}`}>
      <Visibility visible={!loading}>
        <Row gutter={16}>
          <Col span={4}>
            <Statistic
              suffix="%"
              title={`Hombre (${rows.MALE?.count || 0})`}
              value={rows.MALE?.percentage} />
          </Col>
          <Col span={4}>
            <Statistic
              suffix="%"
              title={`Mujer (${rows.FEMALE?.count || 0})`}
              value={rows.FEMALE?.percentage} />
          </Col>
          <Col span={4}>
            <Statistic
              suffix="%"
              title={`Otro (${rows.OTHER?.count || 0})`}
              value={rows.OTHER?.percentage}  />
          </Col>
          <Col span={4}>
            <Statistic
              suffix="%"
              title={`Prefiero no decirlo (${rows.NOT_SAY?.count || 0})`}
              value={rows.NOT_SAY?.percentage} />
          </Col>
          <Col span={4}>
            <Statistic
              suffix="%"
              precision={2}
              title={`Sin información (${rows?.usersWithNoData?.count || 0})`}
              value={rows?.usersWithNoData?.percentage} />
          </Col>
        </Row>
      </Visibility>
    </Card>
  );
};
