const services = {
  "Concursos y reconocimientos": 0,
  "Eventos de pitches y demos": 0,
  "Hackathones": 0,
  "Desarrollo de ideas y desafíos de innovación": 0,
  "Charlas y conferencias (sin fines educativos)": 0,
  "Talleres, programas de formación y entrenamiento": 0,
  "Pre-incubación": 0,
  "Incubación": 0,
  "Aceleración": 0,
  "Consultoría": 0,
  "Mentoreo y asesoría": 0,
  "Servicios backoffice": 0,
  "Financiamiento": 0,
  "Inversión": 0,
  "Medios de comunicación y difusión": 0,
  "Catálogos, listas y noticias": 0,
  "Espacios de trabajo flexible y de oficina": 0,
  "Makerspaces": 0,
  "Conexión con talento": 0,
  "Vinculación con industria": 0,
  "Laboratorios para el desarrollo tecnológico": 0,
  "Otro": 0
};

export const SERVICES = Object.keys(services);

export const STAGES = {
  "Sin idea": services,
  "Idea": services,
  "Prototipo": services,
  "En operación": services,
  "En crecimiento": services,
  "En consolidación": services,
  "Otro": services
};

export const COLORS_TAGS = {
  "Sin idea": "#F3B1D0",
  "Idea": "#9CE2DB",
  "Prototipo": "#C2E999",
  "En operación": "#B2C6FA",
  "En crecimiento": "#F7D599",
  "En consolidación": "#D3ADF7",
  "Otro": "#B1B1B1"
};

export const STATES =
  [
    { name: "Aguascalientes" , location: [-102.28259, 21.88234], r: 0},
    { name: "Baja California", location: [-114.833337 ,29.885307], r: 0},
    { name: "Baja California Sur", location: [-111.919850, 26.162110], r: 0},
    { name: "Campeche", location: [-90.286090, 18.694681], r: 0},
    { name: "Chiapas", location: [-92.643948, 16.480010], r: 0},
    { name: "Chihuahua", location: [-106.472398, 28.623247], r: 0},
    { name: "Ciudad de México", location: [-99.143669, 19.369792], r: 0},
    { name: "Coahuila", location: [-102.070022, 27.261808], r: 0},
    { name: "Colima", location: [-103.915655, 19.149817], r: 0},
    { name: "Durango", location: [-104.834182, 24.738095], r: 0},
    { name: "Guanajuato", location: [-101.099333, 20.862165], r: 0},
    { name: "Guerrero", location: [-99.981412, 17.598738], r: 0},
    { name: "Hidalgo", location: [-98.994581, 20.494541], r: 0},
    { name: "Jalisco", location: [-104.375931, 20.113252], r: 0},
    { name: "México", location: [-99.792690, 19.286689], r: 0},
    { name: "Michoacán", location: [-101.868566, 19.312117], r: 0},
    { name: "Morelos", location: [-99.083137, 18.741608], r: 0},
    { name: "Nayarit", location: [-104.991003, 21.819676], r: 0},
    { name: "Nuevo León", location: [-99.708018, 25.731354], r: 0},
    { name: "Oaxaca", location: [-96.499760, 16.930450], r: 0},
    { name: "Puebla", location: [-97.923534, 18.848741], r: 0},
    { name: "Querétaro", location: [-99.886658, 20.792652], r: 0},
    { name: "Quintana Roo", location: [-88.040400, 19.508100], r: 0},
    { name: "San Luis Potosí", location: [-100.446672, 22.434902], r: 0},
    { name: "Sinaloa", location: [-107.695688, 25.080202], r: 0},
    { name: "Sonora", location: [-110.830535, 29.835934], r: 0},
    { name: "Tabasco", location: [-92.812460, 18.287102], r: 0},
    { name: "Tamaulipas", location: [-98.593392, 24.016303], r: 0},
    { name: "Tlaxcala", location: [-98.142192, 19.416381], r: 0},
    { name: "Veracruz", location: [-96.335466, 19.127386], r: 0},
    { name: "Yucatán", location: [-89.054722, 20.780401], r: 0},
    { name: "Zacatecas", location: [-102.859367, 23.441166], r: 0}
  ];

export const STATES_RATIO = {
  "Aguascalientes": 0,
  "Baja California": 0,
  "Baja California Sur": 0,
  "Campeche": 0,
  "Chiapas": 0,
  "Chihuahua": 0,
  "Ciudad de México": 0,
  "Coahuila": 0,
  "Colima": 0,
  "Durango": 0,
  "Guanajuato": 0,
  "Guerrero": 0,
  "Hidalgo": 0,
  "Jalisco": 0,
  "México": 0,
  "Michoacán": 0,
  "Morelos": 0,
  "Nayarit": 0,
  "Nuevo León": 0,
  "Oaxaca": 0,
  "Puebla": 0,
  "Querétaro": 0,
  "Quintana Roo": 0,
  "San Luis Potosí": 0,
  "Sinaloa": 0,
  "Sonora": 0,
  "Tabasco": 0,
  "Tamaulipas": 0,
  "Tlaxcala": 0,
  "Veracruz": 0,
  "Yucatán": 0,
  "Zacatecas": 0
};


