import {
  financingStageOptions
} from "../../../../shared/company-forms/general/select-options/financing-stage";
import { getReadableValue } from "../../../../../helpers/select-options";

export function financingStageToChart (financingStages = []) {
  const labels = [];
  const datasets = [];

  financingStages.forEach((financingStage, idx) => {
    const rawLabel = financingStage["Company.companyFinancingStage"];
    const readableLabel = getReadableValue(
      financingStageOptions,
      rawLabel,
      rawLabel
    );

    labels.push(readableLabel);

    const data = new Array(financingStages.length);
    data.fill(0, 0, financingStages.length - 1);
    data[idx] = parseInt(financingStage["Company.companiesCount"]);

    datasets.push({
      label: readableLabel,
      data,
      backgroundColor: COLORS[idx]
    });
  });

  return {
    labels,
    datasets
  };
}

const COLORS = [
  "#EEE3F9",
  "#E8D2FE",
  "#D7B7F6",
  "#C995FB",
  "#EBF1FE",
  "#D9E4FE",
  "#B7CCFE",
  "#95B4FE"
];
