import moment from "moment";
import { genders } from "../../../../utils/maps";
import { countries } from "../../../../utils";

export const userDecorator = {
  get resume() {
    return this.expertProfile?.resume;
  },
  get displayEmail() {
    return this.email || "";
  },
  get displayPhone() {
    return this.phone || "";
  },
  get displayGender() {
    return genders[this.gender] || "";
  },
  get fullNameInCaps() {
    return this?.fullName?.toUpperCase();
  },
  get firstNameInCaps() {
    const { name = ""} = this.expertProfile || {};
    return name.toUpperCase();
  },
  get readableBirthday() {
    const { birthday } = this;
    return  birthday ? moment(birthday).format("DD-MM-YYYY") : "";
  },
  get address() {
    const {
      country: countryId,
      state,
      city
    } = this.expertProfile?.location || {};

    const country = countries.find(country => country.id == countryId);

    return [country?.name, state, city]
        .filter(e => !!e)
        .join(" ") || "";
  }
};
