import React from "react";
import { DatePicker as ANTDatePicker } from "antd";
import moment from "moment";
import { RangePicker } from "../../../../../../shared";

export const DatePicker = ({ ongoing, dates = [], ...props }) => {
  const pickerDates = (dates === null) ? [] : dates;

  const commonProps = {
    format: "DD [de] MMMM [de] YYYY",
    style: { width: "100%" }
  };

  if (ongoing) {
    const value = pickerDates[0] && moment(pickerDates[0]);
    return (
      <ANTDatePicker
        {...commonProps}
        {...props}
        defaultValue={value} />
    );
  } else {
    const value = pickerDates.map(date => date && moment(date));
    return (
      <RangePicker
        {...commonProps}
        {...props}
        defaultValue={value} />
    );
  }
};
