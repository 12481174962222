import React, { useContext, useEffect } from "react";
import { Tabs } from "antd";
import { MenuContext } from "../../../contexts/shared";
import { MainMenu } from "../../../components/coordinator/shared";
import {
  ResumeByPerson,
  ResumeByCampus,
  Section,
  SidebarLayout
} from "../../../components/shared";
import {
  EntrepreneurSpiritHeader
} from "../../../components/shared";
import { useWindowSize } from "../../../hooks";

export const EntrepreneurSpiritIndex = () => {

  const { windowSize } = useWindowSize();
  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["entrepreneur-spirit"],
      selectedKeys: ["entrepreneur-spirit"]
    });
  }, []);

  const tabsProps = {
    className: "asem__Tabs",
    defaultActiveKey: "list"
  };

  return (
    <SidebarLayout menu={<MainMenu />}>
      <Section
        spaced
        className="asem OrganizationIndex">
        <EntrepreneurSpiritHeader />
        <Tabs {...tabsProps}>
          <Tabs.TabPane tab="Resumen por campus" key="campus">
            <ResumeByCampus />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Resumen por persona" key="single">
            <ResumeByPerson windowSize={windowSize} />
          </Tabs.TabPane>
        </Tabs>
      </Section>
    </SidebarLayout>
  );
};
