import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { MenuContext } from "../../../contexts/shared/menu";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";
import { EditProfileProvider } from "../../../contexts/shared/edit-profile";
import { ContentWrapper, FormContainer, SidebarLayout } from "../../../components/shared";
import { MainMenu } from "../../../components/coordinator/shared";
import { Title } from "../../../components/entrepreneur/profile";
import { SummaryEditSection } from "../../../components/shared/profile-forms/summary";
import PersonalInformation from "../../../components/coordinator/profile/personal-information";
import { PageLeavePromptProvider } from "../../../contexts/shared";

export const ProfileIndex = () => {
  const userId = window.localStorage.getItem("id");
  const { error, loading, data = {} } = useQuery(
    shared.queries.getProfileDataByUserId,
    {
      client,
      variables: { id: userId }
    }
  );

  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: [""],
    selectedKeys: [""]
  });

  return(
    <PageLeavePromptProvider>
      <EditProfileProvider
        userId={userId} data={data}>
        <SidebarLayout menu={<MainMenu />}>
          <Title />
          <SummaryEditSection
            editable
            data={data}
            error={error}
            loading={loading} />
          <ContentWrapper>
            <FormContainer>
              <PersonalInformation
                editable
                data={data}
                error={error}
                isLoading={loading} />
            </FormContainer>
          </ContentWrapper>
        </SidebarLayout>
      </EditProfileProvider>
    </PageLeavePromptProvider>
  );
};