import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { Title } from "../../../components/title";
import { Line } from "../../../components/line";

export const Record = ({ data }) => {
  return (
    <View style={styles.body}>
      <View style={styles.row}>
        <Title>HISTORIAL</Title>
        <Title style={{ marginRight: 20 }}>FECHA</Title>
      </View>
      <Line style={{ marginTop: 5, marginBottom: 16 }} />
      <View style={{ ...styles.row, marginBottom: 16 }}>
        <View>
          <Text style={styles.title}>{data?.name}</Text>
          <Text style={{ width: 450 }}>{data?.comment}</Text>
        </View>
        <View>
          <Text>{data?.date}</Text>
          <Text>{data?.time}</Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    fontSize: 12,
    fontWeight: 400
  },
  title: {
    fontWeight: 700
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
});