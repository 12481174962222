import gql from "graphql-tag";

export const getScheduledMentorshipServicesEntrepreneur = gql`
  query GetScheduledMentorshipServicesEntrepreneur(
    $entrepreneurId: ID!
    $serviceId: ID!
    $companyId: ID!
  ) {
    scheduledExpertServices(
      where: {
        status_in: [
          "ON_GOING",
          "DELIVERED",
          "SCHEDULED"
        ],
        assignedExpertService: {
          ode: {
            id: $companyId
            team_some: {
              id: $entrepreneurId
              systemRole: ENTREPRENEUR
            }
          }
          expertService: {
            id: $serviceId
            type: "MENTORSHIP"
          }
        }
      }
    ) {
      id
    }
  }
`;
