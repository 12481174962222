import gql from "graphql-tag";

export const getObjetivesByProgramId = gql`
  query GetObjetivesByProgramId($id: ID!) {
    objectives(where: { program: { id: $id } }) {
      id
      name
      description
    }
  }
`;
