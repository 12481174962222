import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import {
  ProgramSummary
} from "../../../../../components/admin/programs/program-summary";
import { admin } from "../../../../../graphql/admin";
import { client } from "../../../../../../graphql";
import {
  default as BatchesList
} from "../../../../../components/admin/programs/batches/list";
import {
  Section,
  SidebarLayout
} from "../../../../../components/shared";
import {
  ProgramModalProvider
} from "../../../../../contexts/shared/program-modal";
import { CloneBatchModalProvider } from "../../../../../contexts/shared";
import {
  MainMenu as CoordMainMenu
} from "../../../../../components/coordinator/shared";
import {
  MainMenu as AdminMainMenu
} from "../../../../../components/admin/shared";
import { CurrentUserContext } from "../../../../../contexts/shared";
import { coordinator } from "../../../../../graphql/coordinator";

export function ProgramBatchesIndex() {
  const { currentUser } = useContext(CurrentUserContext);
  const { id } = useParams();

  const query = currentUser.systemRole === "COORDINATOR"
    ? {
        query: coordinator.queries.getBatchesByCoordinatorIdAndProgramId,
        options: {
          client,
          fetchPolicy: "network-only",
          variables: {
            id: currentUser.id,
            programId: id
          }
        }
      }
    : {
        query: admin.queries.getBatchesByProgramId,
        options: {
          fetchPolicy: "network-only",
          variables: { id: id },
          client
        }
      };

  const { loading, error, data, refetch } = useQuery(query.query, query.options)

  const MainMenu = currentUser.systemRole === "SUPER_ADMIN" ?
    AdminMainMenu
    : currentUser.systemRole === "COORDINATOR" ? CoordMainMenu : null;
  return(
    <CloneBatchModalProvider refetch={refetch}>
      <SidebarLayout menu={<MainMenu />}>
        <ProgramModalProvider refetch={refetch}>
          <ProgramSummary selectedKeys={["batches"]} />
        </ProgramModalProvider>
        <Section spaced>
          <BatchesList
            error={error}
            data={data}
            isLoading={loading}
            refetch={refetch} />
        </Section>
      </SidebarLayout>
    </CloneBatchModalProvider>
  );
}
