import {
  FacebookFilled,
  GlobalOutlined,
  InstagramFilled,
  LinkedinFilled,
  TwitterCircleFilled
} from "@ant-design/icons";
import React from "react";

export const getLinkIcon = (link = "") => {
  const styles = { fontSize: "28px" };
  if(link.includes("facebook")) return <FacebookFilled style={styles} />;
  if(link.includes("linkedin")) return <LinkedinFilled style={styles} />;
  if(link.includes("twitter")) return <TwitterCircleFilled style={styles} />;
  if(link.includes("instagram")) return <InstagramFilled style={styles} />;
  return <GlobalOutlined style={styles} />;
};
