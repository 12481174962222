import React, { useContext } from "react";
import { Button, Row, List, Typography } from "antd";
import { SubTitle } from "../subtitle";
import {
  CompanyMilestonesModalContext
} from "../../../../../../../contexts/shared/company-milestones-modal";
import { getReadableValue } from "../../../../../../../helpers/select-options";
import {
  fundTypeOptions
} from "../../../../../company-forms/milestone/select-options/fund-type";
import {
  sourceOptions
} from "../../../../../company-forms/milestone/select-options/source";
import { MilestoneItem } from "../milestone-list-item";
import { Visibility } from "../../../../..";

export const FundsMilestones = ({ data, allowEdit }) => {
  const {
    onCreateOpen,
    onEdit,
    onDelete
  } = useContext(CompanyMilestonesModalContext);

  return (
    <div>
      <Row justify="space-between">
        <SubTitle>Fondos</SubTitle>
        <Visibility visible={allowEdit}>
          <Button
            ghost
            onClick={() => onCreateOpen("FUND")}>Agregar</Button>
        </Visibility>
      </Row>
      {data.length > 0 ?
        <List
          style={{ padding: "16px" }}
          dataSource={data}
          renderItem={fund => (
            <MilestoneItem
              id={fund.id}
              title={fund.investor}
              subTitle={getReadableValue(fundTypeOptions, fund.fundType)}
              type={fund.milestoneType}
              social={fund.socialCompetence}
              date={fund.date}
              allowEdit={allowEdit}
              onEdit={() => onEdit(fund.milestoneType, fund.id, fund)}
              onDelete={onDelete}>
              <Row>
                <Typography.Text
                  className="milestones__item--description">
                  {`$${Number(fund.amount).toLocaleString()} ${fund.currency} de fondos.`}
                </Typography.Text>
                <Typography.Text
                  className="milestones__item--description"
                  style={{ marginLeft: "10px" }}>
                  {getReadableValue(sourceOptions, fund.source)}
                </Typography.Text>
              </Row>
            </MilestoneItem>
          )} /> : null
      }
    </div>
  );
};
