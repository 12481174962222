import React, { useState, useEffect } from "react";
import { Select, Divider, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";

export function SelectWithCustomOption({
  options = [],
  value,
  onChange,
  ...props
}) {
  const [selectOptions, setSelectOptions] = useState([]);
  const [newOption, setNewOption] = useState(undefined);

  useEffect(() => {
    setSelectOptions(Array.from(options));
  }, [options]);

  const addNewOption = () => {
    setSelectOptions(prevState => {
      const updatedOptions = [
        ...prevState,
        { value: newOption, label: newOption }
      ];

      onChange && onChange(newOption);
      setNewOption(undefined);
      return updatedOptions;
    });
  };

  const handleOnChange = value => {
    onChange(value);
  };

  return (
    <Select
      {...props}
      defaultValue={value}
      options={selectOptions}
      onChange={handleOnChange}
      dropdownRender={menu => (
        <div>
          {menu}
          <Divider style={{ margin: "4px 0" }} />
          <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
            <Input
              size="small"
              value={newOption}
              placeholder="Agregar otra opción"
              onPressEnter={() => addNewOption()}
              onChange={e => setNewOption(e.target.value)} />
            <a
              onClick={() => addNewOption()}
              style={{
                flex: "none",
                padding: "8px",
                display: "block",
                cursor: "pointer"
              }}>
              <PlusOutlined /> Agregar
            </a>
          </div>
        </div>
      )} />
  );
}
