import React, { useState, useEffect } from "react";
import { DatePicker, Space } from "antd";

export const RangePicker = ({
  defaultValue = [],
  style = {},
  size = "large",
  onChange
}) => {
  const [state, setState] = useState({
    firstDate: null,
    lastDate: null
  });

  let firstDate = null;
  let lastDate = null;

  if (Array.isArray(defaultValue)) {
    firstDate = defaultValue[0] || null;
    lastDate = defaultValue[1] || null;
  }

  useEffect(() => {
    if (Array.isArray(defaultValue)) {
      setState({
        firstDate: defaultValue[0],
        lastDate: defaultValue[1]
      });
    }
  }, [defaultValue]);

  const format = "DD [de] MMMM [de] YYYY";

  const handleOnChange = (selector, momentDate) => {
    setState({ ...state, [selector]: momentDate });
    if (onChange) {
      if (selector === "firstDate") {
        onChange([
          momentDate,
          momentDate ? state.lastDate : null
        ]);
      } else if (selector === "lastDate") {
        onChange([state.firstDate, momentDate]);
      }
    }
  };

  const resetDates = () => {
    setState({ firstDate: null, lastDate: null });
  };

  return (
    <Space
      align="center"
      size="small"
      style={{ ...style }}>
      <DatePicker
        placeholder="Fecha de inicio"
        onChange={momentDate => {
          if (!momentDate) {
            resetDates();
          }
          handleOnChange("firstDate", momentDate);
        }}
        size={size}
        style={{ width: "100%" }}
        defaultValue={firstDate}
        value={state.firstDate}
        format={format} />
      <DatePicker
        placeholder="Fecha fin"
        onChange={momentDate => handleOnChange("lastDate", momentDate)}
        size={size}
        style={{ width: "100%" }}
        defaultValue={lastDate}
        value={state.lastDate}
        format={format} />
    </Space>
  );
};
