import React, { useContext } from "react";
import { Section } from "../section";
import { List, Space } from "antd";
import { TeamAvatar } from "../team-avatar";
import { AddButton } from "../add-button";
import { CurrentUserContext } from "../../../contexts/shared";
import { Visibility } from "../visibility";
import { BatchSectionHeading } from "../batch-section-heading";
import { TeamMemberBatchAssigned } from "../modals";
import { ModalButton } from "../buttons";
import { EditOutlined } from "@ant-design/icons";

export const PublishBatchTeam = ({
  batch,
  teams,
  showAddTeamButton = false,
  openModalInvitation = null,
  refetchBatch,
  ...props
}) => {
  const { id: batchId, leader } = !!batch && batch;
  const { currentUser } = useContext(CurrentUserContext);

  return (
    <Section
      extra={
        <Visibility
          visible={
            showAddTeamButton &&
            ["SUPER_ADMIN", "COORDINATOR"].includes(currentUser.systemRole)
          }>
          <Space>
            <Visibility
              visible={["SUPER_ADMIN"].includes(currentUser.systemRole)}>
              <AddButton
                icon={null}
                type="default"
                onClick={() =>
                  openModalInvitation(
                    { batches: [{ id: batchId }] }, "COORDINATOR"
                  )}>
                Invitar equipo
              </AddButton>
            </Visibility>
            <ModalButton
              title="Editar equipo"
              type="primary"
              key="assign-btn"
              icon={<EditOutlined />}
              modalProps={{
                title: "Editar equipo del batch",
                batch: batch,
                refetchBatch
              }}
              modal={TeamMemberBatchAssigned} />
          </Space>
        </Visibility>
      }
      {...props}>
      <BatchSectionHeading
        title="El mejor equipo apoyándote"
        subTitle="Conoce al equipo que te acompañará en todo tu proceso" />
      <div className="NewBatch__Publish-Team__Wrapper">
        <List
          className="TeamBatch"
          dataSource={teams}
          renderItem={item =>
            <TeamAvatar
              key={item?.id}
              team={item}
              isLeader={leader === item?.id} />
          } />
      </div>
    </Section>
  );
};
