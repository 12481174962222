import React, { useContext } from "react";
import { Button, List, Row, Col, Typography } from "antd";
import {
  CompanyMilestonesModalContext
} from "../../../../../../../contexts/shared/company-milestones-modal";
import { SubTitle } from "../subtitle";
import { MilestoneItem } from "../milestone-list-item";
import { Format, Visibility } from "../../../../..";
import { getReadableValue } from "../../../../../../../helpers/select-options";
import {
  placeOptionsDetails
} from "../../../../../company-forms/milestone/select-options/place";
import {
  prizeOptions
} from "../../../../../company-forms/milestone/select-options/prize";

export const CompetencesMilestones = ({ data, allowEdit }) => {
  const {
    onCreateOpen,
    onEdit,
    onDelete
  } = useContext(CompanyMilestonesModalContext);

  const styleDescriptionText = {
    style: {
      marginLeft: "10px",
      wordBreak: "break-all",
      overflowWrap: "break-word"
    }
  };

  return (
    <div>
      <Row justify="space-between">
        <SubTitle>Competencias y premios</SubTitle>
        <Visibility visible={allowEdit}>
          <Button
            ghost
            onClick={() => onCreateOpen("COMPETENCE")}>Agregar</Button>
        </Visibility>
      </Row>
      {data.length > 0 ?
        <List
          style={{ padding: "16px" }}
          dataSource={data}
          renderItem={competence => (
            <MilestoneItem
              id={competence.id}
              type={competence.milestoneType}
              title={competence.name}
              social={competence.socialCompetence}
              date={competence.date}
              allowEdit={allowEdit}
              onEdit={() =>
                onEdit(competence.milestoneType, competence.id, competence)}
              onDelete={onDelete}>
              <Col style={{ padding: "0" }}>
                <Row>
                  <Typography.Text
                    className="milestones__item--description">
                    {getReadableValue(placeOptionsDetails, competence?.place)}.
                  </Typography.Text>
                  <Visibility visible={competence?.place !== "NONE"}>
                    <Typography.Text
                      className="milestones__item--description"
                      {...styleDescriptionText}>
                      <Typography.Text
                        className="milestones__item--description">
                        <Format type="money">{competence?.amount}</Format>
                        {` ${competence?.currency}`}
                      </Typography.Text>
                    </Typography.Text>
                    <Typography.Text
                      className="milestones__item--description"
                      {...styleDescriptionText}>
                      {getReadableValue(prizeOptions, competence?.prize)}.
                    </Typography.Text>
                    <Typography.Text
                      className="milestones__item--description"
                      {...styleDescriptionText}>
                      {competence?.prizeDescription}.
                    </Typography.Text>
                  </Visibility>
                </Row>
                <br />
                <Typography.Text
                  className="milestones__item--description">
                  {competence.description}
                </Typography.Text>
              </Col>
            </MilestoneItem>
          )} /> : null
      }
    </div>
  );
};
