import gql from "graphql-tag";

export const getUserById = gql`
  query GetUserById($id: ID!) {
    user(where: { id: $id }) {
      id
      fullName
      firstName
      middleName
      lastName
      email
      systemRole
      profilePicUrl
      phone
      gender
      birthday
      country
      state
      city
      acceptedTermsAndConditions
      specialtyArea
      occupation
      company
      teamOdes {
        id
        name
      }
      batches {
        id
      }
      expertProfile {
        id
        location {
          id
          city
          state
          country
        }
      }
    }
    userProfileCompletion(where: { id: $id }) {
      completed
    }
    odes: oDEs(
      where: {
        OR:[
          { team_some: { id: $id } }
          { network_some: {id: $id } }
          { batches_some: { users_some: { id: $id } } }
        ],
      }
    ){
      id
      name
    }
    workspaces(where: {
      memberships_some: { user: { id: $id } }
    }) {
      id
      name
      coverPicUrl
      memberships(where: { user: { id: $id } }) {
        role
      }
    }
  }
`;
