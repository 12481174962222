import gql from "graphql-tag";

export const getBatchesByEntrepreneurId = gql`
  query GetBatchesByEntrepreneurId(
    $id: ID!, $iname: String, $first: Int, $skip: Int, $programId: ID
  ){
    batches(
      where:{ 
        iname_contains: $iname
        program: { id: $programId }
        odes_some: { 
          team_some: { 
            id: $id
          }
        }
      },
      first: $first,
      skip: $skip
    ){
      id    
      name
      iname
      batchID
      startDate
      finishDate
      description
      program {
        id
        name
        type
        coverPicUrl
      }
      odeStatusBatch(
        where: { status: INACTIVE }
      ){
        id
        status
      }
    }

    meta: batchesConnection(
      where: { 
        odes_some: { 
          team_some: { 
            id: $id
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
