import React, { useState } from  "react";
import { Form } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { ProgramsSelect } from "../../../../../components/shared";
import { BatchesSelect } from "./batches-select";
import { OdesSelect } from "./odes-select";
import { getOdesByBatchId } from "../../../../../graphql/admin/queries";
import { client } from "../../../../../../graphql";

export function OdesPicker({
  expert,
  onChange,
  updateTotalUnits,
  expertService = {}
}) {
  const [state, setState] = useState({
    programId: "",
    batchId: ""
  });

  // Should filter by program if it's not workshop
  const { data, loading, error, refetch } = useQuery(getOdesByBatchId, {
      client,
      variables: {
        id: state.batchId,
        orderBy: "name_ASC"
      },
      fetchPolicy: "network-only"
    }
  );

  const getTotalUnits = data => {
    return data?.map(item => parseInt(item.assignedUnits))
      .reduce((acc, curr) => acc + curr, 0);
  };

  const onOdesChange = (odes = []) => {
    onChange && onChange(odes);
  };

  const onProgramChange = async (programId = "") => {
    setState(prevState => ({
      ...prevState,
      programId
    }));

    await refetch({ id: "" });
  };

  const onBatchChange = (batchId = "") => {
    setState(prevState => ({
      ...prevState,
      batchId
    }));
    refetch({ id: batchId });
  };

  return (
    <>
      <Form.Item
        className="programa"
        label="Programa">
        <ProgramsSelect
          onChange={onProgramChange} />
      </Form.Item>
      <Form.Item
        className="batch"
        label="Batch"
        help={
          !state.programId &&
          "Para seleccionar un batch debes seleccionar un programa primero"
        }>
        <BatchesSelect
          onChange={onBatchChange}
          expertProfile={expert?.expertProfile}
          programId={state.programId} />
      </Form.Item>
      <Form.Item
        help={
          !state.batchId &&
          "Para seleccionar las compañías debes seleccionar un batch primero"
        }
        label="Compañías">
        <OdesSelect
          getTotalUnits={getTotalUnits}
          batchId={state.batchId}
          expertService={expertService}
          updateTotalUnits={updateTotalUnits}
          error={error}
          loading={loading}
          data={data}
          mode="multiple"
          onChange={onOdesChange} />
      </Form.Item>
    </>
  );
}
