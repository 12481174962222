import gql from "graphql-tag";

export const createPublishedContent = gql`
  mutation CreatePublishedContent(
    $odeId: ID!, 
    $masterContentId: ID!
  ) {
    createPublishedContent(
      data: {
        ode: { connect: { id: $odeId } }
        masterContent: { connect: { id: $masterContentId } }
      }
    ){
      id
    }
  }
`;
