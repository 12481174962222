import gql from "graphql-tag";

export const updateEntrepreneurById = gql`
  mutation UpdateEntrepreneurById($data: UserUpdateInput!, $id: ID!) {
    updateUser(data: $data, where: { id: $id }) {
      id
      fullName
      teamOdes {
        id
        name
      }
    }
  }
`;
