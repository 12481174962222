import {
  OCCUPATIONS,
  getReadableValue
} from "../../../../../helpers";

export function entrepreneursOccupationToChart (items = []) {
  let datasets = [];

  items.forEach((item, idx) => {
    const data = [];
    data.push(parseInt(item["Company.entrepreneursCount"]));

    const rawOccupation = item["Company.teamMemberOccupation"];
    const readableOccupation = getReadableValue(
      OCCUPATIONS,
      rawOccupation,
      rawOccupation
    );

    datasets.push({
      label: readableOccupation,
      data,
      backgroundColor: COLORS[idx]
    });
  });

  if (datasets.length > 16) {
    const othersReducedTotal = datasets
      .slice(16, datasets.length)
      .reduce((acc, curr) => {
        return acc + curr.data[0];
      }, 0);

    const others = {
      label: "Otros",
      data: [othersReducedTotal]
    };

    datasets = datasets.slice(0, 16);
    datasets.push(others);
  }

  return {
    labels: ["Ocupación"],
    datasets
  };
}

const COLORS = [
  "#EEE3F9",
  "#E8D2FE",
  "#D7B7F6",
  "#C995FB",
  "#EBF1FE",
  "#D9E4FE",
  "#B7CCFE",
  "#95B4FE",
  "#FDDCDC",
  "#FDC0C0",
  "#FEB7D6",
  "#FE99C6",
  "#E5F4F2",
  "#BCEFEA",
  "#97E9E1",
  "#7AD3CA"
];
