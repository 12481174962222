import React, { useEffect, useContext, useState } from "react";
import {
  Section,
  SidebarLayout
} from "../../../../../components/shared";
import { admin } from "../../../../../graphql/admin";
import { useParams, useHistory } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import {
  Summary
} from "../../../../../components/admin/batches/show/summary";
import {
  default as Navbar
} from "../../../../../components/entrepreneur/batches/show/navbar";
import {
  BatchDetailsContextProvider
} from "../../../../../contexts/admin/batches/batch-details";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { MainMenu } from "../../../../../components/entrepreneur/shared";
import { SvgImage } from "../../../../../components/shared";
import SVG from "../../../../../../assets/redireccionamiento.svg";
import { Button, Select } from "antd";

export function ObjectivesIndex() {
  const { id } = useParams();
  const [selectedOde, setSelectedOde] = useState(undefined);

  const {
    data,
    loading
  } = useQuery(admin.queries.getOdesByBatchId, {
    client,
    variables: {
      id: id
    }
  });

  const odes = data?.oDEs ?? [];
  const selectOptions = odes.map(ode => ({ label: ode.name, value: ode.id }));

  const { setMenuState } = useContext(MenuContext);
  const history = useHistory();

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  /* TODO: Create a batch/program detail context provider to set the layout and
   * load and manage the data */

  const {
    loading: batchLoading,
    error: batchError,
    data: batchData
  } = useQuery(
    admin.queries.getBatchById,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      client
    }
  );

  const onOdeSelectChange = (odeId = "") => {
    setSelectedOde(odeId);
  };

  const navigateToShortcut = () => {
    history.push(`/v2/entrepreneur/odes/${selectedOde}/batches/${id}`);
  };

  return (
    <BatchDetailsContextProvider>
      <SidebarLayout menu={<MainMenu />}>
        <Summary
          isLoading={batchLoading}
          error={batchError}
          data={batchData} />
        <Navbar selectedKeys={["objectives"]} />
        <Section
          spaced
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "50px",
            paddingBottom: "50px"
          }}>
          <div style={{ maxwidth: "400px"}}>
            <SvgImage width="100%" svg={SVG} />
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="Selecciónala de la lista"
              style={{ width: "100%" }}
              onChange={onOdeSelectChange}
              loading={loading}
              options={selectOptions} />
            <Button
              style={{ display: "block", marginTop: "10px" }}
              className="clickable"
              type="primary"
              size="large"
              disabled={loading || !selectedOde}
              value="Ver mis contenidos"
              onClick={navigateToShortcut}>
              Ver mis contenidos
            </Button>
          </div>

        </Section>
      </SidebarLayout>
    </BatchDetailsContextProvider>
  );
}
