import { Button, Form, Input, message, Modal } from "antd";
import React, { useEffect, useState } from "react";

export function ContentGroupModal({
  onSave,
  onCancel,
  onUpdate,
  isPublished,
  contentGroup,
  ...props
}) {
  const [form] = Form.useForm();
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    if (contentGroup) {
      form.setFieldsValue(contentGroup);
    }
  }, [contentGroup]);

  const onOk = async () => {
    await form.validateFields();
    const dismissLoading = message.loading("Guardando...");
    setSaveLoading(true);

    try {
      let values = form.getFieldsValue();

      if (contentGroup) {
        if(isPublished) {
          values = {
            draft: values
          };
        }
        await onUpdate(contentGroup.id, values);
      } else {
        if(isPublished) {
          values.creationConfirmed = true;
        }
        await onSave([values]);
      }
      form.resetFields();
      onCancel();

      message.success("Cambios guardados.");
    } catch (e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    } finally {
      setSaveLoading(false);
      dismissLoading();
    }
  };

  const onCancelModal = () => {
    setSaveLoading(false);
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      destroyOnClose
      title="Sección"
      onCancel={onCancelModal}
      footer={[
        <Button
          key="cancel"
          onClick={onCancelModal}>Cancelar</Button>,
        <Button
          key="guardar"
          type="primary"
          onClick={onOk}
          loading={saveLoading}>Guardar</Button>
      ]}
      width={700}
      {...props}>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          label="Titulo de la sección"
          name="title">
          <Input type="text" placeholder="Escribe un título" />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="description">
          <Input.TextArea
            rows={4}
            placeholder="Describe cómo esta sección ayudará
            a tus Compañías a progresar." />
        </Form.Item>
      </Form>
    </Modal>
  );
}
