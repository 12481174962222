import React, { useContext, useEffect } from "react";
import { Form, Input, DatePicker } from "antd";
import moment from "moment";
import { EditOdeContext } from "../../../../../contexts/shared/edit-ode";
import { FormContainer } from "../../..";
import { fieldValidationMessages } from "../../../../../utils";
import { Details } from "./details";

export function FiscalInfoForm({ editable = false, form }) {
  const { data } = useContext(EditOdeContext);

  useEffect(() => {
    const oDE = data?.oDE || {};
    oDE.fiscalEntryDate = oDE.fiscalEntryDate ?
      moment(oDE.fiscalEntryDate) : undefined;

    form.setFieldsValue(oDE);
  }, [data]);

  if (!editable) {
    return <Details data={data?.oDE} />;
  }

  return (
    <FormContainer>
      <Form
        validateMessages={fieldValidationMessages}
        layout="vertical"
        form={form}>
        <Form.Item
          name="businessName"
          label="Razón social">
          <Input disabled={!editable} placeholder="Razón social" />
        </Form.Item>
        <Form.Item
          label="RFC/TAX ID"
          name="rfc">
          <Input disabled={!editable} placeholder="12345678" />
        </Form.Item>
        <Form.Item
          name="fiscalEntryDate"
          label="Fecha de alta fiscal">
          <DatePicker
            className="wide-input"
            format="DD [de] MMMM [de] YYYY" />
        </Form.Item>
        <Form.Item
          name="fiscalRepresentative"
          label="Nombre del representante fiscal">
          <Input
            placeholder="Nombre del representante fiscal" />
        </Form.Item>
      </Form>
    </FormContainer>
  );
}
