export const odeTrlOptions = [
  { label: "1", value: "ONE" },
  { label: "2", value: "TWO" },
  { label: "3", value: "THREE" },
  { label: "4", value: "FOUR" },
  { label: "5", value: "FIVE" },
  { label: "6", value: "SIX" },
  { label: "7", value: "SEVEN" },
  { label: "8", value: "EIGHT" },
  { label: "9", value: "NINE" }
];
