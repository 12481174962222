import { client } from "../../../graphql";
import { getOdesByBatchId } from "./graphql";

export const getOdesByBatchIdService = async ({ batchId }) => {
  const result = await client.query({
    query: getOdesByBatchId,
    variables: {
      id: batchId
    },
    fetchPolicy: "network-only"
  });

  return result.data || [];
};
