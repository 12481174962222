import gql from "graphql-tag";

export const getByProgramQuery = gql`
  query GetBatchesByProgramQuery($programId: ID!) {
    batches(
      where: {
        program: {
          id: $programId
        }
      }
    ) {
      id
      name
    }
  }
`;
