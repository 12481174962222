export const initialState = {
  editing: { // allows to know what section is being edited
    pitch: false,
    summary: false,
    general: false,
    address: false,
    socialNetworks: false,
    fiscal: false,
    fiscalAddress: false
  }
};
