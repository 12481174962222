import React from "react";
import { Button, Tooltip, Typography } from "antd";
import { ListItem } from "../../../../../../../../../shared";
import { truncateText } from "../../../../../../../../../../helpers";

export function AvailableMentorListItem({ user = null }) {
  const truncatedText = truncateText(user?.expertProfile?.resume || "", 30);

  return (
    <ListItem.CardListItem
      coverUrl={user?.profilePicUrl}
      title={user?.fullName || ""}
      titleMaxSize={15}
      footerItems={[
        <Button
          ghost
          type={"primary"}
          key={"btn-request"}>
          Solicitar
        </Button>
      ]}>
      <Typography.Text type="secondary">
        {!truncatedText
          ? <div style={{ minHeight: "22px" }} />
          : <Tooltip
              title={user?.expertProfile?.resume || ""}
              arrowPointAtCenter>
            {truncatedText}
          </Tooltip>}
      </Typography.Text>
    </ListItem.CardListItem>
  );
}
