import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  SidebarLayout,
  Section,
  IconLabel
} from "../../../../../components/shared";
import { MainMenu } from "../../../../../components/entrepreneur/shared";
import { DataConnectionService } from "../../../../../services";
import { entrepreneur } from "../../../../../graphql/entrepreneur";
import { MenuContext } from "../../../../../contexts/shared/menu";
import {
  MonitoringServicesList
} from "../../../../../components/entrepreneur/experts/services/monitoring";
import { useQueryFilters } from "../../../../../helpers";
import {
  ServiceDetailModalProvider,
  ServicesLayerProvider
} from "../../../../../contexts/shared";
import {
  ServiceAcceptanceModalProvider
// eslint-disable-next-line max-len
} from "../../../../../contexts/entrepreneur/experts/services/monitoring/service-acceptance-modal";
import {
  FilterControls
// eslint-disable-next-line max-len
} from "../../../../../components/entrepreneur/experts/services/monitoring/filter-controls";
import {
  CurrentUserContext
} from "../../../../../contexts/shared/current-user";
import {
  ProfileVerifierProvider
} from "../../../../../contexts/shared/profile-verifier";
import { ScheduledExpertServiceService } from "../../../../../services";

export function MonitoringServicesIndex() {
  const [state, setState] = useState({ scheduledExpertServices: {} });

  const { currentUser } = useContext(CurrentUserContext);

  const { loading, error, data, refetch } = useQuery(
    entrepreneur.queries.getScheduledServicesByEntrepreneurId,
    {
      client: DataConnectionService.graphqlApolloClient,
      variables: { entrepreneurId: currentUser?.id },
      fetchPolicy: "network-only"
    }
  );

  useEffect(() => {
    const scheduledExpertServices = filterWorkshops(
      data?.scheduledExpertServices || []
    );

    setState({ scheduledExpertServices });
  }, [data]);

  const { setMenuState } = useContext(MenuContext);
  const { setFilters } = useQueryFilters({ refetch });

  const onFilterChange = filter => {
    setFilters(filter);
  };

  /* set menu's current state */
  useEffect(() => {
    setMenuState({
      openKeys: ["experts"],
      selectedKeys: ["experts/services/monitoring"]
    });
  }, []);

  if (error) {
    return (
      <IconLabel icon={<ExclamationCircleOutlined />}>
        Ocurrió un error
      </IconLabel>
    );
  }

  const filterWorkshops = scheduledExpertServices => {
    return scheduledExpertServices.filter(scheduledService => {
        const type = scheduledService.assignedExpertService.expertService.type;
        const status = scheduledService.status;
        if (type !== "WORKSHOP") {
          return true;
        }
        return !["ASSIGNED", "SCHEDULED", "ON_GOING"].includes(status);
      }
    );
  };

  return (
    <ServiceDetailModalProvider refetch={refetch}>
      <ServiceAcceptanceModalProvider>
        <SidebarLayout menu={<MainMenu />}>
          <ProfileVerifierProvider>
            <Section
              spaced
              extra={
                <FilterControls onChange={onFilterChange} />
              }>
              <ServicesLayerProvider services={{
                ScheduledExpertServiceService
              }}>
                <MonitoringServicesList
                  loading={loading}
                  refetch={refetch}
                  data={{
                    scheduledExpertServices: state.scheduledExpertServices
                  }} />
              </ServicesLayerProvider>
            </Section>
          </ProfileVerifierProvider>
        </SidebarLayout>
      </ServiceAcceptanceModalProvider>
    </ServiceDetailModalProvider>
  );
}
