import React, { useEffect, useContext } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { SidebarLayout } from "../../../../../components/shared";
import { admin } from "../../../../../graphql/admin";
import { client } from "../../../../../../graphql";
import { MainMenu } from "../../../../../components/coordinator/shared";
import { Summary } from "../../../../../components/admin/batches/show/summary";
import { default as Navbar } from "../../../../../components/admin/batches/show/navbar";
import { BatchDetailsContextProvider } from "../../../../../contexts/admin/batches/batch-details";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { BatchObjectiveModalProvider } from "../../../../../contexts/shared/batch-objective-modal";
import { ContentModalContextProvider } from "../../../../../contexts/shared/content-modal/provider";
import { Section } from "../../../../../components/shared";
import { BatchesBoardCharts } from "../../../../../components/admin/batches/show/board";

export function BatchBoardIndex() {
  const { id } = useParams();

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const {
    loading: batchLoading,
    error: batchError,
    data: batchData
  } = useQuery(
    admin.queries.getBatchById,
    {
      variables: { id: id },
      client
    }
  );

  const {
    loading: odesLoading,
    error: odesError,
    data: odesData
  } = useQuery(admin.queries.getBatchOdesForCharts,
    {
      fetchPolicy: "cache-first",
      variables: { id: id },
      client
    });

  return (
    <BatchDetailsContextProvider>
      <ContentModalContextProvider batchId={id}>
        <BatchObjectiveModalProvider>
          <SidebarLayout menu={<MainMenu />}>
            <Summary
              isLoading={batchLoading}
              error={batchError}
              data={batchData} />
            <Navbar selectedKeys={["board"]} />
            <Section
              title="Tablero"
              spaced>
              <BatchesBoardCharts
                loading={odesLoading}
                error={odesError}
                odes={odesData?.oDEs} />
            </Section>
          </SidebarLayout>
        </BatchObjectiveModalProvider>
      </ContentModalContextProvider>
    </BatchDetailsContextProvider>
  );
}
