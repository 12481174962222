import { getCompanyContentsInfo } from "./get-company-contents-info";

export const buildTableRows = (
  companies = [],
  batchProgress,
  allContentsMap
) => {
  const tableRows = [];

  companies
    .forEach(company => {
      const companyBreakdown = batchProgress
        .breakdown
        .companies[company.id];

      if (companyBreakdown) companyBreakdown.companyId = company.id;
      const row = {
        key: company.id,
        companyId: company.id,
        companyName: company.name,
        companyProgress: companyBreakdown?.overallPercentage || 0,
        ...getCompanyContentsInfo(allContentsMap, companyBreakdown, company)
      };
      tableRows.push(row);
    });

  return tableRows;
};
