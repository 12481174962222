import gql from "graphql-tag";

export const getTask = gql`
  query GetTask($id: ID!) {
    task(where: {
      id: $id
    }){
      id
      name
      description
      deliveryDate
      type
      achievement{
        id
        name
        objective{
          id
          name
        }
      }
      ode{
        id
        name
      }
      responsable{
        id
        fullName
      }
      resources{
        id
        fileName
        url
        type
      }
      createdBy{
        id
        fullName
      }
      deliverables {
        id
        fileName
        url
        type
      }
      isCompleted
    }
  }
`;
