import React, { useState } from "react";

import { ArrayItem } from "../../../../../../shared";
import { ResearchesModal } from "../modal";
import { ResearchItem } from "./research-item";
import {
  handleOnAdd,
  handleOnEditItem,
  handleOnRemoveItem,
  handleOnSave,
  handleOnCancel
} from "../../../../modal-helpers";

export const Researches = ({
  onSave,
  papers: researches = [],
  editable = true
}) => {
  const [modal, setModal] = useState({ visible: false });

  const renderItem = item =>
    <ResearchItem
      key={item.id || item.uuid}
      editable={editable}
      item={item}
      onEditItem={researches =>
        handleOnEditItem(setModal, researches)}
      onRemoveItem={removed =>
        handleOnRemoveItem(onSave, researches, removed)} />;

  return (
    <>
      <ResearchesModal
        visible={modal.visible}
        research={modal.item}
        onConfirm={newResearch =>
          handleOnSave(modal, setModal, onSave, researches, newResearch)}
        onCancel={() =>
          handleOnCancel(setModal)} />
      <ArrayItem
        editable={editable}
        value={researches}
        onClickAdd={() =>
          handleOnAdd(setModal)}
        buttonLabel="Agregar"
        renderItem={renderItem} />
    </>
  );
};
