import React, { useMemo, useState, useContext } from "react";
import { Form, message } from "antd";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import { admin } from "../../../../../graphql/admin";
import { shared } from "../../../../../graphql/shared";
import { StepsWrapper } from "../../../../../components/expert/shared";
import { Section } from "../../../../../components/shared";
import { NewExpertContext } from "../../../../../contexts/experts/new/context";
import {
  ExpertServiceContext
} from "../../../../../contexts/experts/new/service/context";
import {
  TecRelationship
} from "../../../../../components/expert/new/id/professional-information";
import {
  WorkExperienceForm,
  EducationForm,
  CoursesAndCertificationsForm,
  ResearchesForm,
  LanguagesForm,
  VolunteeringForm
} from "../../../../../components/expert/new/id/professional-information";
import { getUpdatedData } from "./get-updated-data";

function ProfessionalInformation({ history, match }) {
  const { ExpertsService } = useContext(ExpertServiceContext);
  const [form] = Form.useForm();
  const userId = match?.params?.userId || "";

  const [state, setState] = useState({
    busy: false,
    professionalInformation: {}
  });

  const { loading, error, data } = useQuery(
    shared.queries.getExpertById,
    {
      client,
      variables: { id: userId }
    });

  const [updateProfessionalExperience] = useMutation(
    admin.mutations.updateExpertProfile,
    {
      client,
      refetchQueries: [{
        query: shared.queries.getExpertById,
        variables: { id: userId }
      }]
    }
  );

  const updateProfessionalInformation = proInfo => {
    ExpertsService.setUpdateProfessionalInformation(proInfo, state, setState);
  };

  const onFormFieldsChange = (field = []) => {
    const { name = [], value } = field[0] || {};
    const fieldName = name[0];
    let changedValue = {};

    changedValue = { [fieldName]: value };

    updateProfessionalInformation(changedValue);
  };

  const handleNext = async event => {
    event.preventDefault();
    try {
      await form.validateFields();
    } catch (error) {
      return message.warning("Campos requeridos faltantes.");
    }
    await save();
  };

  const save = async () => {
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      const updatedData = getUpdatedData({
        professionalInformation: state.professionalInformation,
        expertProfile: data?.expertProfile
      });

      await ExpertsService.setSave(
        updatedData, updateProfessionalExperience, userId);

      message.success("Datos guardados");
      history.push(`/v2/expert/new/${userId}/accounts`);
    } catch (error) {
      console.error(error);
      message.error("Ocurrió un error");
    } finally {
      dismissLoader();
    }
  };

  const injectActions = useMemo(() => ({
    updateProfessionalInformation,
    onFormFieldsChange,
    save,
    loading,
    error,
    data,
    form
  }), [state, loading]);

  return (
    <NewExpertContext.Provider value={injectActions}>
      <StepsWrapper
        onNext={handleNext}
        prevUrl={`/v2/expert/new/${userId}/personal-information`}
        currentStep={1}>
        <Section title="Experiencia laboral">
          <WorkExperienceForm />
        </Section>
        <Section title="Educación">
          <EducationForm />
        </Section>
        <Section title="Cursos y certificaciones">
          <CoursesAndCertificationsForm />
        </Section>
        <Section title="Investigaciones y publicaciones">
          <ResearchesForm />
        </Section>
        <Section title="Idiomas">
          <LanguagesForm />
        </Section>
        <Section title="Voluntariados">
          <VolunteeringForm />
        </Section>
        <TecRelationship />
      </StepsWrapper>
    </NewExpertContext.Provider>
  );
}

export default withRouter(ProfessionalInformation);
