import gql from "graphql-tag";

export const disableOdeInBatch = gql`
  mutation DisableOdeInBatch(
    $batchId: ID!,  
    $companyId: ID!,
    $statusId: ID,
  ){
    disableOdeInBatch(
      data: {
        batchId: $batchId,
        companyId: $companyId,
        statusId: $statusId,
      }
    )
  }
`;

export const activeOdeInBatch = gql`
  mutation ActiveOdeInBatch(
    $statusId: ID!,
    $companyId: ID!
  ){
    updateODE(
      where: { id: $companyId }
      data: {
        odeStatusBatch: {
          update: {
            where: { id: $statusId }
            data: {
              status: ACTIVE
            }
          }
        }
      }
    ){
      id
    }
  }
`;