import { client } from "../../../graphql";
import {
  updateProfile,
  createExpert
} from "./methods";

export class UserService {
  constructor() {
    this.client = client;
    this.updateProfile = updateProfile;
    this.createExpert = createExpert;
  }
}
