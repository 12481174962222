import React, { useEffect, useState } from "react";
import moment from "moment";
import { capitalize } from "lodash";
import { List, Timeline, Typography } from "antd";
import { getReadableValue, cloneObject } from "../../../../../helpers";
import { DollarOutlined, CheckCircleOutlined, FlagOutlined } from "@ant-design/icons";
import { sourceOptions } from "../../../company-forms/milestone/select-options/source";

export function CompanyTimelineIndex({ data, loading }) {
  const [milestones, setMilestones] = useState([]);

  useEffect(() => {
    if (data) {
      const cloneMilestone = cloneObject(data?.milestones);
      const sortedMilestones = cloneMilestone.sort((a, b) => new Date(b.date)- new Date(a.date))

      if (data?.startDate) {
        sortedMilestones.push({
          name: "Fundación",
          date: data?.startDate,
          milestoneType: "Foundation"
        });
      }

      setMilestones(sortedMilestones);
    }
  }, [data]);

  const renderTitle = item => {
    if (item?.milestoneType === "FUND") {
      const dollarUSLocale = Intl.NumberFormat('es-MX', {
        style: "currency",
        currency: "MXN"
      });

      return (
        <>
          <DollarOutlined /> {getReadableValue(sourceOptions, item?.source)}
          &nbsp;{`${dollarUSLocale.format(item?.amount)} ${item?.currency}`}
        </>
      );
    }

    if (item?.milestoneType === "ACHIEVEMENT") {
      return <><CheckCircleOutlined /> {item?.name}</>;
    }

    return <><FlagOutlined /> {item?.name}</>;
  };

  const renderTimeline = item => {
    const createdAt = capitalize(moment(item?.date).format("MMMM, YYYY"));

    return (
      <Timeline.Item>
        <Typography.Title level={5}>
          {createdAt}
        </Typography.Title>
        <Typography.Text>
          {renderTitle(item)}
        </Typography.Text>
      </Timeline.Item>
    );
  };

  return (
    <Timeline className="TimeLine">
      <List
        loading={loading}
        dataSource={milestones}
        renderItem={renderTimeline} />
    </Timeline>
  );
}
