import React, { useContext, useEffect } from "react";
import { Form } from "antd";
import { FormContainer } from "../../../../shared";
import { RelationshipInfo } from "./relationship-info";
import { EditProfileContext } from "../../../../../contexts/shared";
import { TecRelationshipInput } from "./tec-relationship-input";

export function TecRelationshipForm({
  form,
  stateForm,
  editable = false,
  setStateForm
}) {
  const {
    editing,
    data
  } = useContext(EditProfileContext);

  const tecRelationships = data?.user?.tecRelationships || [];

  useEffect(() => {
    if (tecRelationships.length) {
      form.setFieldsValue({ tecRelationships });
    }
  }, [data]);

  if (!editing.tecRelationship && !editable) {
    return <RelationshipInfo user={data?.user} />;
  }

  return (
    <FormContainer>
      <Form form={form}>
        <Form.Item
          name="tecRelationships">
          <TecRelationshipInput
            value={tecRelationships}
            stateForm={stateForm}
            setStateForm={setStateForm} />
        </Form.Item>
      </Form>
    </FormContainer>
  );
}
