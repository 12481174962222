import React from "react";
import { Form, message } from "antd";
import { ContainerOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import
  Template
from "../../../../../assets/companies-importation-template.xlsx";

export function ImportOdeForm({ form }) {
  const onChange = async file => {
    try {
      message.success(`Se cargo el archivo: ${file?.name}.`);
      form.setFieldsValue({ "attachment": file });
    } catch (error) {
      message.error(`Error al cargar el archivo: ${file?.name}.`);
    }

    return false;
  };

  const onRemove = () => form.resetFields();

  return (
    <Form
      form={form}
      layout="vertical">
      <Form.Item
        name="attachment">
        <Dragger
          maxCount={1}
          multiple={false}
          beforeUpload={onChange}
          onRemove={onRemove}
          accept=".xlsx">
          <p className="ant-upload-drag-icon">
            <ContainerOutlined />
          </p>
          <p className="ant-upload-text">
            Haz una creación masivas, sólo arrastra tu archivo excel o da click aquí para cargarlo.
          </p>
          <p className="ant-upload-hint">
            El archivo debe tener la extensión “.xlsx”
          </p>
        </Dragger>
        <a
          style={{
            display: "block",
            textAlign: "center",
            marginTop: "1rem",
            color: "#6348E5",
            textDecoration: "underline"
          }}
          download="template.xlsx"
          href={Template}
          target="_blank">
          Descarga el template
        </a>
      </Form.Item>
    </Form>
  );
}
