import { remove as removeAccents } from "remove-accents";
import { client } from "../../../graphql";
import { shared } from "../../../v2/graphql/shared";

export async function verifyOdeNameAvailability(odeName = "") {
  odeName = removeAccents(odeName).toLowerCase();

  const result = await client.query({
    query: shared.queries.findOdeByName,
    fetchPolicy: "network-only",
    variables: { iname: odeName }
  });

  const nameAlreadyTaken = !!(result.data?.oDEs?.length > 0);
  return nameAlreadyTaken;
}
