import { client } from "../../../graphql";
import { entrepreneurGlobalCalendarEventsQuery } from "./graphql";

async function getEntrepreneurEvents({
  entrepreneurId,
  batchId,
  companyId,
  year,
  month
}) {
  const result = await client.query({
    query: entrepreneurGlobalCalendarEventsQuery,
    fetchPolicy: "network-only",
    variables: {
      entrepreneurId,
      batchId,
      companyId,
      year,
      month
    }
  });

  return result.data.entrepreneurGlobalCalendarEvents ?? [];
}

export const GlobalCalendarService = {
  getEntrepreneurEvents
};
