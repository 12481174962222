import React, { useState, useRef } from "react";
import { Button, Row, Select } from "antd";
import { statesWithCitysOptions, allStateOption } from "../../../helpers/select-options/organizations/states-citys";
import { LocationsList } from "./locations-list";

const initialState = {
  currentState: undefined,
  currentSelectedCities: [],
  selectedCitiesValues: [],
  currentCitiesList: []
};

export const PresenceSelector = ({
  setSelectedStates,
  selectedStates,
  setValidation
}) => {
  const [
    citiesSelectorHandler,
    setCitiesSelectorHanlder
  ] = useState(initialState);
  const citiesSelectRef = useRef();

  const {
    currentState,
    currentSelectedCities,
    currentCitiesList,
    selectedCitiesValues
  } = citiesSelectorHandler;

  const onStateChange = stateValue => {
    const [selectedState] =
      statesWithCitysOptions.filter(state => state.value === stateValue);
    setCitiesSelectorHanlder({
      currentState: selectedState,
      currentCitiesList: allStateOption.concat(selectedState.children),
      currentSelectedCities: [],
      selectedCitiesValues: []
    });
    citiesSelectRef.current.focus();
    setValidation(prev => ({
      ...prev,
      presenceStates: {
        msg: undefined,
        status: undefined
      }
    }));
  };

  const onCitiesChange = (e, citiesValues) => {
    if (e[0] === "ALL") {
      citiesSelectRef.current.blur();
    }
    setCitiesSelectorHanlder(state => ({
      ...state,
      selectedCitiesValues: e,
      currentSelectedCities: citiesValues
    }));
  };

  const onAddState = () => {
    if (currentState) {
      const prevStateIndex =
        selectedStates.findIndex(state => state.value === currentState.value);

      if (prevStateIndex >= 0) {
        const selectedStatesCopy = selectedStates;
        const originalState = selectedStates[prevStateIndex];

        const originalCities = originalState.cities || [];
        const newCities = currentSelectedCities?.map(city => city.label);

        originalState.cities = [...new Set([...originalCities, ...newCities])];
        selectedStatesCopy[prevStateIndex] = originalState;
        setSelectedStates(selectedStatesCopy);
        setCitiesSelectorHanlder(initialState);
      } else {
        let newState = {
          name: currentState.label,
          iname: currentState.label
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, ""),
          value: currentState.value,
          allCities: true,
          cities: ["Todo el estado"]
        };
        const allCities = selectedCitiesValues?.filter(city => city === "ALL");
        const cities = currentSelectedCities?.map(city => city.label);
        if (allCities.length === 0) {
          newState = {
            ...newState,
            allCities: allCities.length > 0 || cities.length === 0,
            cities: cities.length > 0 ? cities : ["Todo el estado"]
          };
        }
        setSelectedStates(selectedStates.concat(newState));
        setCitiesSelectorHanlder(initialState);
      }
    }
  };

  const onDeleteStateCity = (state, city, index) => {
    const newCitiesList = state.cities.filter(oldCity => oldCity !== city);
    let newSelectedStates = selectedStates.map(selectedState => {
      if (selectedState.value !== state.value) return selectedState;
      return {
        ...state,
        cities: newCitiesList
      };
    });
    if (newCitiesList.length === 0) {
      const filteredStates = selectedStates
        .filter(({ value }) => value !== state.value);
      newSelectedStates = filteredStates;
    }
    setValidation(prev => ({
      ...prev,
      presenceStates: {
        status: "error",
        msg:"Por favor ingrese lugares donde tiene presencia"
      }
    }));
    setSelectedStates(newSelectedStates);
  };

  return (
    <div>
      <Row
        justify="space-between"
        align="top"
        style={{ marginBottom: "15px" }}>
        <Select
          showSearch
          autoComplete="none"
          placeholder="Estado"
          options={statesWithCitysOptions}
          value={currentState?.value}
          onChange={onStateChange}
          style={{ width: "315px" }} />
        <Select
          showSearch
          mode="multiple"
          aria-autocomplete="none"
          placeholder="Ciudades"
          ref={citiesSelectRef}
          options={currentCitiesList}
          value={selectedCitiesValues}
          onChange={onCitiesChange}
          style={{ width: "298px" }} />
        <Button
          onClick={onAddState}
          style={{
            color: "#5F39DE",
            border: "1px solid #5F39DE"
          }}>
          Agregar</Button>
      </Row>
      <LocationsList states={selectedStates} onCloseCity={onDeleteStateCity} />
    </div>
  );
};
