import React, { useState } from "react";
import { Tabs } from "antd";
import { OrganizationsTable } from "../table";
import { OrganizationsTableWrapper } from "../table/wrapper";

export function AsemOrganizationsTabPanes() {
  const [selectedType, setSelectedType] = useState("REVIEW_REQUESTED");

  return (
    <Tabs
      className="OrganizationAsem__TabsPanes"
      defaultActiveKey="REVIEW_REQUESTED"
      activeKey={selectedType}
      onChange={setSelectedType}>
      <Tabs.TabPane tab="Por revisar" key="REVIEW_REQUESTED">
        <OrganizationsTableWrapper tableType="REVIEW_REQUESTED">
          <OrganizationsTable />
        </OrganizationsTableWrapper>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Rechazadas" key="REJECTED">
        <OrganizationsTableWrapper tableType="REJECTED">
          <OrganizationsTable />
        </OrganizationsTableWrapper>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Aprobadas" key="APPROVED">
        <OrganizationsTableWrapper tableType="APPROVED">
          <OrganizationsTable tableType="APPROVED" />
        </OrganizationsTableWrapper>
      </Tabs.TabPane>
    </Tabs>
  );
}
