import gql from "graphql-tag";

export const getBatchCoordinators = gql`
  query GetBatchCoordinators($id: ID!, $searchString: String) {
    users(
      where: {
        ifullName_contains: $searchString,
        batches_some: { id: $id },
        OR: [
          { systemRole: COORDINATOR },
          { systemRole: SUPER_ADMIN }
        ]
      }) {
      id
      fullName
      email
      phone
      profilePicUrl
      expertProfile {
        id
        resume
      }
    }
  }
`;
