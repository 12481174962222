import gql from "graphql-tag";

export const updateCompanyWithId = gql`
  mutation UpdateCompanyWithId(
    $id: ID!,
    $data: ODEUpdateInput!
  ){
    updateODE(
      data: $data
      where: { id: $id }
    ){
      id
      name
    }
  }
`;
