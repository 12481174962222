import React, { useContext } from "react";
import { Button, Space, Spin } from "antd";
import { Section, Visibility } from "..";
import { EventsCalendar } from "../events-calendar";
import {
  BatchEventModalContext,
  BatchEventDetailsModalProvider,
  CurrentUserContext
} from "../../../contexts/shared";

export function BatchCalendar({
  events = [],
  onDatesChange,
  refetch,
  loading,
  error
}) {
  const { openModal } = useContext(BatchEventModalContext);
  const { currentUser } = useContext(CurrentUserContext);

  /**
   * @param {Date} date
   * @return {Promise<void>}
   */
  const handleOnDatesChange = async date => {
    onDatesChange && onDatesChange({
      month: date.month(),
      year: date.year()
    });
  };

  return (
    <Section
      title={
        <Space size="large">
          Calendario
          {loading ? <Spin /> : undefined}
        </Space>
      }
      extra={
        <Visibility visible={["SUPER_ADMIN", "COORDINATOR"]
          .includes(currentUser.systemRole)}>
          <Button onClick={() => openModal(refetch)} type="primary">
            Añadir evento
          </Button>
        </Visibility>
      }
      spaced>
      <BatchEventDetailsModalProvider refetch={refetch}>
        <EventsCalendar
          onDatesChange={handleOnDatesChange}
          events={events ?? []}
          loading={loading}
          error={error} />
      </BatchEventDetailsModalProvider>
    </Section>
  );
}
