import React, { useState } from "react";

import { EducationModal } from "./education-modal";
import { EducationList } from "./education-list";

export const EducationRecords = ({
  onSave,
  education: educationRecords = [],
  editable = true
}) => {
  const [visible, setVisible] = useState(false);

  const handleOnClickAdd = () =>
    setVisible(true);

  const handleOnRemoveItem = removed => {
    onSave([
      ...educationRecords
    ].filter(record => record.id !== removed.id));
  };

  const handleOnSave = er => {
    try {
      onSave([...educationRecords, er]);
      setVisible(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <EducationModal
        visible={visible}
        onConfirm={handleOnSave}
        onCancel={handleOnCancel} />
      <EducationList
        editable={editable}
        value={educationRecords}
        onRemoveItem={handleOnRemoveItem}
        onClickAdd={handleOnClickAdd}
        buttonLabel="Agregar" />
    </>
  );
};
