import gql from "graphql-tag";

export const deleteAssignedExpertService = gql`
  mutation DeleteAssignedExpertService(
    $id: ID!, 
  ) {
    deleteAssignedExpertService(
      where: { id: $id }
    ) {
      id
    }
  }
`;
