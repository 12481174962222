import gql from "graphql-tag";

export const upsertOdeReport = gql`
  mutation UpsertOdeReport(
    $update: OdeReportUpdateInput!
    $create: OdeReportCreateInput!
    $id: ID!
  ){
    upsertOdeReport(
      update: $update
      create: $create
      where: { id: $id }
    ) {
      id
    }
  }
`;
