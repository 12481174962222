import React, { useEffect, useContext } from "react";
import { Section, SidebarLayout } from "../../../../components/shared";
import { MenuContext } from "../../../../contexts/shared";
import {
  ServicesSessionsTables
} from "../../../../components/entrepreneur/services/sessions";

export function ScheduledSessionServicesIndex() {
  const { setMenuState } = useContext(MenuContext);

  /* set menu's current state */
  useEffect(() => {
    setMenuState({
      openKeys: ["entrepreneur/monitoring"],
      selectedKeys: ["entrepreneur/monitoring"]
    });
  }, []);

  return (
    <SidebarLayout>
      <Section spaced>
        <ServicesSessionsTables
          tableType="TRACKING" />
      </Section>
    </SidebarLayout>
  );
}
