import React from "react";
import { Typography, List, Space } from "antd";
import moment from "moment";
import { EditButton, DeleteButton } from "../../../../../";

export function CourseItem({
  course,
  onEdit,
  onDelete,
  editable
}) {
  const {
    name = "Sin título",
    endorsingInstitution = "N/A"
  } = course;

  const startDate = moment(course?.startDate).format("DD [de] MMMM [de] YYYY");
  const endDate = course?.endDate ?
    moment(course?.endDate).format("DD [de] MMMM [de] YYYY")
    : "Actualidad";

  const dates = [startDate, endDate].join(" - ");

  return (
    <List.Item style={{position: "relative"}}>
      { editable && (
        <div style={{top: "0", right: "0", position: "absolute"}}>
          <EditButton onClick={onEdit} />
          <DeleteButton onClick={onDelete} />
        </div>
      )}
      <Space size={5} direction="vertical">
        <Typography.Text
          style={{fontSize: "16px"}}>
          { name }
        </Typography.Text>
        <Typography.Text
          type="secondary">
          { endorsingInstitution }
        </Typography.Text>
        <Typography.Text
          type="secondary">
          { dates }
        </Typography.Text>
      </Space>
    </List.Item>
  );
}
