import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ProgramsIndex } from "../../../pages/admin/programs";
import { ProgramBatchesIndex } from "../../../pages/admin/programs/show/batches";
import { ProgramObjectivesIndex } from "../../../pages/admin/programs/show/objectives";
import { AchievementsIndex } from "../../../pages/admin/programs/show/objectives/show/achievements";
import { ContentsIndex } from "../../../pages/admin/programs/show/objectives/show/contents";

export const AdminProgramsRoutes = <Switch>
  <Route
    exact
    path="/v2/admin/programs"
    component={ProgramsIndex} />
  <Route
    path="/v2/admin/programs/:id/"
    render={({ match: { params } }) =>
      <Redirect
        from={`/v2/admin/programs/${params?.id}/`}
        to={`/v2/admin/programs/${params?.id}/batches`} />
    } />
  <Route
    exact
    path="/v2/admin/programs/:id/objectives"
    component={ProgramObjectivesIndex} />
  <Route
    exact
    path="/v2/admin/programs/:id/batches"
    component={ProgramBatchesIndex} />
  <Route
    path="/v2/admin/programs/:programId/objectives/:objectiveId/"
    render={({ match: { params } }) =>
      <Redirect
        from={`/v2/admin/programs/${params?.programId}/objectives/${params?.objectiveId}/`}
        to={`/v2/admin/programs/${params?.programId}/objectives/${params?.objectiveId}/achievements`} />
    } />
  <Route
    path="/v2/admin/programs/:programId/objectives/:objectiveId/achievements"
    component={AchievementsIndex} />
  <Route
    path="/v2/admin/programs/:programId/objectives/:objectiveId/contents"
    component={ContentsIndex} />
</Switch>;
