import { client } from "../../../graphql";
import { connectOdeByBatchId } from "./graphql";

export const connectOdesFromBatchService = async ({
  batchId,
  odesConnect
}) => {
  const result = await client.mutate({
    mutation: connectOdeByBatchId,
    variables: {
      data: {
        ...odesConnect
      },
      batchId
    }
  });

  return result?.data?.updateBatch || {};
};
