import React from "react";
import { withLoader } from "../../../../helpers";
import { ContactEditSection } from "../../../shared/profile-forms/contact/section";

function PersonalInformation({ editable = false }) {
  return(
    <>
      <ContactEditSection editable={editable} />
    </>
  );
}

export default withLoader(PersonalInformation);