import gql from "graphql-tag";

export const createScheduledExpertService = gql`
  mutation CreateScheduledExpertService(
    $serviceDate: DateTime!
    $duration: String!
    $requestedById: ID!
    $assignedExpertServiceId: ID!
    $events: ServiceEventCreateManyInput
  ) {
    createScheduledExpertService(
      data: {
        status: "SCHEDULED"
        serviceDate: $serviceDate
        duration: $duration
        events: $events
        requestedBy : {
          connect: { id: $requestedById }
        }
        assignedExpertService: {
          connect: { id: $assignedExpertServiceId }
        }
      }
    ) {
      id
    }
  }
`;
