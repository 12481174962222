import gql from "graphql-tag";

export const getExpertsByBatchId = gql`
  query GetExpertsByBatchId($id: ID!) {
    users(
      where: {
        batches_some: { id: $id },
        systemRole: ADVISER
      }) {
      id
      fullName
      email
      phone
      profilePicUrl
      expertProfile {
        id
        resume
      }
    }
  }
`;
