import gql from "graphql-tag";

export const getExperts = gql`
  query GetExperts (
    $ifullName: String,
    $workspace: WorkspaceMembershipWhereInput,
    $first: Int,
    $skip: Int
  ) {
    users(
      where: {
        ifullName_contains: $ifullName
        systemRole: ADVISER,
        memberships_some: $workspace
      },
      skip: $skip,
      first: $first
    ) {
      id
      fullName
      email
      phone
      profilePicUrl
      expertProfile {
        id
        resume
      }
    }
    meta: usersConnection(
      where: {
        ifullName_contains: $ifullName
        systemRole: ADVISER,
        memberships_some: $workspace
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
