import gql from "graphql-tag";

export const getWorkspaces = gql`
  query GetWorkspaces {
    workspaces {
      id
      name
      coverPicUrl
      createdAt
      admins: memberships(where: { role: "SUPER_ADMIN" }) {
        user {
          id
        }
      }
      updatedAt
    }
  }
`;
