import React, { useEffect, useContext } from "react";
import { useParams } from "react-router";
import { BatchCalendar } from "../../../../../components/shared";
import { ContentWrapper } from "../../../../../components/shared";
import {
  MenuContext,
  BatchEventModalProvider
} from "../../../../../contexts/shared";
import {
  BatchDetailsContextProvider
} from "../../../../../contexts/admin/batches/batch-details";

export function CalendarIndex() {
  const { id } = useParams();

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  return (
    <BatchDetailsContextProvider>
      <BatchEventModalProvider batchId={id}>
        <ContentWrapper>
          <BatchCalendar batchId={id} />
        </ContentWrapper>
      </BatchEventModalProvider>
    </BatchDetailsContextProvider>
  );
}
