import { client } from "../../../graphql";
import { getByIdQuery, sendCommentMutation } from "./graphql";
import {
  ScheduledExpertServiceService as LegacyScheduledExpertServiceService
} from "../deprecated/scheduled-expert-service-service";
import {
  updateManyScheduledExpertServicesCheckBoxPerStatus
} from "./update-many-scheduled-expert-services-check-box-per-status";

const getById = async ({ id }) => {
  const result = await client.query({
    query: getByIdQuery,
    fetchPolicy: "network-only",
    variables: {
      id
    }
  });

  return result?.data?.scheduledExpertService || null;
};

/**
 * Needs refactor to stop using deprecated logic
 * @param expertId
 * @param serviceType
 * @param assignationIdentifier
 * @param {string[]} links
 * @param deliveredAt
 * @param {{fileName: string, type: string, uploadDate: string, url: string}[]}
 * attachments
 * @param {string} expertComments
 * @param scheduledServiceId
 * @returns {Promise<*|null>}
 */
async function deliver({
  expertId,
  serviceType,
  assignationIdentifier,
  links,
  deliveredAt,
  attachments,
  expertComments,
  scheduledServiceId
}) {
  const scheduledServiceService = new LegacyScheduledExpertServiceService();
  const result = await scheduledServiceService.deliver(
    { assignationIdentifier, expertServiceData: { type: serviceType } },
    expertId,
    {
      links,
      deliveredAt,
      expertComments,
      id: scheduledServiceId,
      attachments: attachments?.map(a => ({ create: a }))
    }
  );

  return result?.data?.deliverManyScheduledServices || null;
}

/**
 * Needs refactor to stop using deprecated logic
 * @param expertId
 * @param expertServiceType
 * @param scheduledServiceId
 * @param assignationIdentifier
 * @returns {Promise<void>}
 */
async function acceptRequest({
  expertId,
  expertServiceType,
  scheduledServiceId,
  assignationIdentifier
}) {
  const scheduledService = {
    id: scheduledServiceId,
    assignedExpertService:  {
      assignationIdentifier,
      expertService: { type: expertServiceType }
    }
  };

  const scheduledServiceService = new LegacyScheduledExpertServiceService();
  await scheduledServiceService.approve(scheduledService, expertId);
}

/**
 * Needs refactor to stop using deprecated logic
 * @param {string} entrepreneurId
 * @param {string} scheduledServiceId
 * @returns {Promise<void>}
 */
async function acceptDelivery({
  entrepreneurId,
  scheduledServiceId
}) {
  const scheduledServiceService = new LegacyScheduledExpertServiceService();
  await scheduledServiceService.acceptDelivery(
    scheduledServiceId,
    entrepreneurId
  );
}

async function sendComment({ comment, files, userId, type, scheduledId }) {
  const newComment = {
    create: {
      author: {
        connect: { id: userId }
      },
      comment,
      type,
      attachments: {
        create: files.map(file => ({
          fileName: file.name,
          type: "SCHEDULED",
          url: file?.response?.imageUrl,
          uploadDate: new Date().toISOString()
        }))
      }
    }
  };

  const result = await client.mutate({
    mutation: sendCommentMutation,
    variables: {
      scheduleExpertServiceId: scheduledId,
      comments: newComment
    }
  });

  return result?.data?.updateScheduledExpertService || null;
}

export const ScheduledExpertServiceService = {
  getById,
  deliver,
  acceptRequest,
  acceptDelivery,
  sendComment,
  updateManyScheduledExpertServicesCheckBoxPerStatus
};
