import { cubeJSClient } from "../../../api-clients";

export const counter = async (
  measures = [],
  {
    filters = [],
    dimensions = []
  } = {}
) => {
  const resultSet = await cubeJSClient.load({
    measures,
    dimensions,
    filters
  });

  return resultSet.loadResponse.results || [];
};
