import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale,
  LineController,
  Chart as ChartJS,
  registerables
} from "chart.js";
import React from "react";
import { Chart } from "react-chartjs-2";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Card, Popover, Space, Typography } from "antd";
import { getEngagementLevenChartConfig } from "./helpers";
ChartJS.register(
  ...registerables,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
  Title,
  LineElement,
  CategoryScale,
  BarElement,
  LineController
);

export const ResumeByPersonEngagementLevelChart = ({ attendances }) => {

  const { data, options } = getEngagementLevenChartConfig({ attendances });

  return (
    <Card
      style={{ width: "100%" }}
      title={
        <Space size="medium">
          <Typography.Title level={5} style={{ margin: 0 }}>
            Nivel de involucramiento por mes
          </Typography.Title>
          <Popover
            overlayStyle={{ width: "300px"}}
            content={
              `Experiencias a las que asistió la persona en el periodo filtrado,
               divididas por mes y agrupadas por nivel de involucramiento`}>
            <Button
              primary
              type="text"
              size="small"
              shape="circle"
              icon={<ExclamationCircleOutlined />} />
          </Popover>
        </Space>
      }
      bodyStyle={{ padding: "0 1rem" }}
      className="dashboard-graphs">
      <Chart
        height={500}
        type="bar"
        data={data}
        options={options} />
    </Card>
  );
};
