import React from "react";
import { Button, Popconfirm } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

/**
 * Renders a delete button
 *
 * @param {*} props
 * @param {function} props.onClick Callback on click
 * @param {string} props.confirmText Text that will appear in the confirm popover
 * @param {*} props.icon Icon component
 * @param {boolean} confirm Confirm enabled
 *
 */
export function DeleteButton({
  onClick,
  confirmText = "Confirma",
  icon = <MinusCircleOutlined />,
  confirm = false,
  ...props
}) {
  return (
    <Popconfirm
      title={confirmText}
      cancelText="Cancelar"
      okText="Eliminar"
      disabled={!confirm}
      onConfirm={onClick}>
      <Button
        type="link"
        icon={icon}
        onClick={e => !confirm && onClick(e)}
        {...props} />
    </Popconfirm>
  );
}
