import gql from "graphql-tag";

export const updateMilestoneWithMilestoneIdAndOdeId = gql`
  mutation UpdateMilestoneWithMilestoneId(
    $odeId: ID!
    $milestoneId: ID!
    $data: MilestoneUpdateDataInput!
  ) {
    updateODE(
      where: { id: $odeId }
      data: { 
        milestones: {
          update: {
            where: { id: $milestoneId }
            data: $data
          }
        }
      }
    ){ id }
  }
`;