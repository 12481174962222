import React, { useRef } from "react";
import { useOdeServices, useService } from "../../../../../../../hooks/shared";
import { OdesService } from "../../../../../../../services";
import {
  CompanyAssignedServicesList,
  CompanyAvailableServicesList,
  Loader
} from "../../../../../../shared";
import {
  GenericModalProvider
} from "../../../../../../../contexts/shared/modals";
import {
  OdesAssignedServicesDetail
} from "../../../../../../shared/modals/shared/odes-assigned-services-detail";

export function CompanyServices({ companyId, batchId }) {
  const modalRef = useRef(null);
  const { removeAssignedExpertService } = useOdeServices();

  const {
    data: assignedServices,
    loading: assignedServicesLoading,
    error: assignedServicesError,
    refetch: assignedServicesRefetch
  } = useService(
    OdesService.getAssignedExpertServicesService,
    { companyId, batchId, type: "PRODUCT" }
  );

  const {
    data: scheduledExpertServices,
    loading: scheduledExpertServicesLoading,
    error: scheduledExpertServicesError,
    refetch: scheduledExpertServicesRefetch
  } = useService(
    OdesService.getScheduledExpertServices,
    { companyId, batchId, type: "PRODUCT" }
  );

  const {
    data: availableServices,
    loading: availableServicesLoading,
    error: availableServicesError
  } = useService(
    OdesService.getAvailableServicesService,
    { batchId }
  );

  const refetch = async () => {
    await assignedServicesRefetch();
    await scheduledExpertServicesRefetch();
  };

  const onRemoveAssignedExpert = async assignedExpertServiceId =>
    removeAssignedExpertService({
      assignedExpertServiceId,
      refetch
    });

  const newScheduledExpertServices = scheduledExpertServices?.map(scheduled => {
    return {
      ...scheduled.assignedExpertService,
      scheduledExpertService: scheduled
    };
  });

  const virtualAssignedExpertServices = [
    ...assignedServices || [],
    ...newScheduledExpertServices || []
  ];

  return (
    <>
      <GenericModalProvider
        ref={modalRef}
        modal={OdesAssignedServicesDetail} />
      <Loader
        loading={assignedServicesLoading || scheduledExpertServicesLoading}
        data={virtualAssignedExpertServices}
        error={assignedServicesError || scheduledExpertServicesError}>
        <CompanyAssignedServicesList
          modalRef={modalRef}
          assignedServices={virtualAssignedExpertServices}
          onRemoveAssignedExpert={onRemoveAssignedExpert} />
      </Loader>
      <Loader
        loading={availableServicesLoading}
        data={availableServices}
        error={availableServicesError}>
        <CompanyAvailableServicesList
          availableServices={availableServices} />
      </Loader>
    </>
  );
}
