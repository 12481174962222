import React, { useState, useEffect, useContext } from "react";
import { Modal, DatePicker, Form, Input, Typography, message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { client } from "../../../../graphql";
import { admin } from "../../../graphql/admin";
import { SelectablePrograms } from "./selectable-program";
import {
  genericFieldValidationMsgs
} from "../../../utils/fieldValidationMessages";
import { UsersSelect } from "../../../components/shared";
import { shared } from "../../../graphql/shared";
import { CurrentUserContext } from "../current-user";
import { UsageTrackingService } from "../../../services";

export function BatchModal({
  onSave,
  onCancel,
  programId,
  ...props
}) {
  const [form] = Form.useForm();
  const [state, setState] = useState({
    programId: undefined,
    project: undefined
  });

  const {currentUser} = useContext(CurrentUserContext);

  useEffect(() => {
    form.setFieldsValue({
      users: [currentUser.id]
    });
  });

  useEffect(() => {
    const id = programId || state.programId;

    const fetchProgram = async () => {
      const { data } = await client.query({
        query: shared.queries.getProgramById,
        variables: { id: id }
      });

      setState({ ...state, program: data.program, programId: id });
    };

    if(id) {
      fetchProgram();
    }
  }, [programId, state.programId]);

  const [createBatch] = useMutation(
    admin.mutations.createBatch, {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: admin.queries.getBatches }]
    }
  );

  const onOk = async() => {
    await form.validateFields();
    const dismissLoading = message.loading("Guardando...");

    const values = form.getFieldsValue();

    try {
      values.users = {
        connect: values.users?.map(id => ({ id }))
      };

      values.program = {
        connect: {
          id: programId || state.programId
        }
      };

      values.startDate = moment(values.dates[0]).toISOString();
      values.finishDate = moment(values.dates[1]).toISOString();

      delete values.dates;
      delete values.programId;

      const newBatch = await createBatch({ variables: { data: values } });

      message.success("Batch creado.");
      trackCreatedBatch(values);

      onSave && onSave(newBatch);
      form.resetFields();
    } catch(e) {
      if (e.message.match(/NAME_ALREADY_TAKEN/)) {
        return message.warning(`Ya existe un batch con el nombre\
           "${values.name}". Por favor, ingresa un nombre diferente.`);
      }
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    } finally {
      dismissLoading();
    }
  };

  const trackCreatedBatch = (batch = {}) => {
    try {
      UsageTrackingService.trackNewBatch({
        batch: {
          id: batch?.id,
          name: batch?.name,
          startDate: batch.startDate,
          finishDate: batch.finishDate,
          programId: state.programId
        },
        addedBy: {
          id: currentUser.id,
          SystemRole: currentUser.systemRole,
          fullName: currentUser.fullName,
          email: currentUser.email
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  const beforeProductCreation = current => {
    return current.isBefore(state.program.createdAt);
  };

  const onSelectProgram = value => {
    setState({ ...state, programId: value });
  };

  return (
    <Modal
      destroyOnClose
      okText="Guardar"
      cancelText="Cancelar"
      title="Nuevo batch"
      onOk={onOk}
      onCancel={onCancelModal}
      {...props}>
      <Form
        validateMessages={genericFieldValidationMsgs}
        form={form}
        layout="vertical">
        <Form.Item>
          <Typography.Text>
            Escribe el nombre y las fechas en las que se llevará a cabo
            el batch del programa.
          </Typography.Text>
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          label="Nombre del batch"
          name="name">
          <Input placeholder="Nombre del batch" />
        </Form.Item>
        <Form.Item
          label="Coordinadores"
          name="users">
          <UsersSelect
            systemRoles={["COORDINATOR", "SUPER_ADMIN"]}
            placeholder="Coordinadores" />
        </Form.Item>
        {!programId ?
          <Form.Item
            rules={[{ required: true }]}
            label="Programa"
            name="programId">
            <SelectablePrograms onChange={onSelectProgram} />
          </Form.Item>
        : null }
        <Form.Item
          rules={[{ required: true }]}
          label="Fecha de inicio y fin"
          name="dates">
          <DatePicker.RangePicker
            className="wide-input"
            disabled={!state.program}
            disabledDate={beforeProductCreation} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
