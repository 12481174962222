import React, { useState } from "react";
import { BatchObjectiveModalContext } from "./context";
import { BatchObjectiveModal } from "./modal";

export function BatchObjectiveModalProvider({children}) {
  const [state, setState] = useState({
    isModalOpen: false,
    batchId: undefined
  });

  const openModal = batchId => {
    setState({ isModalOpen: true, batchId: batchId });
  };

  const closeModal = () => {
    setState({ isModalOpen: false, batchId: undefined });
  };

  const onSave = () => {
    closeModal();
  };

  return (
    <BatchObjectiveModalContext.Provider value={{openModal, closeModal}}>
      <BatchObjectiveModal
        visible={state.isModalOpen}
        onCancel={closeModal}
        onSave={onSave}
        batchId={state.batchId} />
      {children}
    </BatchObjectiveModalContext.Provider>
  );
}
