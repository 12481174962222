import { admin } from "../../../graphql/admin";
import { client } from "../../../../graphql";

export const login = async (args = {}, customClient) => {
  const { data = {}, error } = await (customClient || client)
    .query({
      query: admin.queries.login,
      variables: args.variables,
      fetchPolicy: "network-only"
    });

  const sessionCode = data?.login?.session?.code;

  if (sessionCode === 202) {
    storeSessionData(data?.login);
    return {
      id: data?.login?.session?.id,
      systemRole: data?.login?.session?.systemRole
    };
  } else if (sessionCode === 400 || sessionCode === 404) {
    throw "INVALID_CREDENTIALS";
  }

  if (error)
    console.error(error);

  throw "Unexpected response";
};

const storeSessionData = (loginData = {}) => {
  localStorage.setItem("email", loginData.email);
  localStorage.setItem("id", loginData.session.id);
  localStorage.setItem("role", loginData.session.systemRole);
  localStorage.setItem("token", loginData.session.token);
};
