import React from "react";
import { IconLabel } from "../icon-label";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const style = {
  padding: 10,
  textAlign: "center",
  backgroundColor: "#fdfbe6"
};

export const PlatformAlert = ({ children }) => (
  <div style={style}>
    <IconLabel icon={<ExclamationCircleOutlined style={{ fontSize: 20 }} />}>
      {children}
    </IconLabel>
  </div>
);
