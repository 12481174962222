import React, { useContext, useEffect } from "react";
import { Form, Button, Select } from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import {
  Upload,
  FormContainer,
  InputTooltip
} from "../../../../../../../components/shared";
import { fieldValidationMessages } from "../../../../../../../utils";
import {
  NewExpertContext
} from "../../../../../../../contexts/experts/new/context";
const { Item } = Form;

export const WorkExperienceForm = ({ editable = true }) => {
  const {
    onFormFieldsChange,
    form,
    data = {}
  } = useContext(NewExpertContext);

  const inputRules = editable && [{ required: true }];
  const { user = {} } = data;

  const {
    skills = [],
    industries = [],
    areas = []
  } = user?.expertProfile?.professionalExperience || {};

  useEffect(() => {
    form.setFieldsValue({ skills, industries, areas });
  }, [data]);

  return (
    <FormContainer>
      <Form
        onFieldsChange={onFormFieldsChange}
        validateMessages={fieldValidationMessages}
        form={form}
        layout="vertical">
        <Item
          name="curriculumVitae"
          label="Curriculum Vitae"
          rules={inputRules}>
          { editable ? (
            <Upload>
              <Button icon={<UploadOutlined />}>Subir CV</Button>
            </Upload>
            )
            : (
              <Button
                type="primary"
                icon={<DownloadOutlined />}>
                Descargar
              </Button>) }
        </Item>
        <InputTooltip
          title="Ingresa las habilidades separadas por una coma ( , )">
          <Item
            name="skills"
            label="Habilidades"
            rules={inputRules}>
            <Select
              tokenSeparators={[","]}
              notFoundContent={null}
              dropdownStyle={{display: "none"}}
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Habilidades" />
          </Item>
        </InputTooltip>
        <InputTooltip
          title="Ingresa las industrias separadas por una coma ( , )">
          <Item
            name="industries"
            label="Industrias"
            rules={inputRules}>
            <Select
              tokenSeparators={[","]}
              notFoundContent={null}
              dropdownStyle={{display: "none"}}
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Industrias" />
          </Item>
        </InputTooltip>
        <InputTooltip
          title="Ingresa las áreas de experiencia separadas por una coma ( , )">
          <Item
            name="areas"
            label="Áreas de experiencia"
            rules={inputRules}>
            <Select
              tokenSeparators={[","]}
              notFoundContent={null}
              dropdownStyle={{display: "none"}}
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Áreas de experiencia" />
          </Item>
        </InputTooltip>
      </Form>
    </FormContainer>
  );
};
