import {
  removeUnnusedFields
} from "../../../../../services/expert/experts/new/helpers";

export function getUpdatedData({ professionalInformation, expertProfile }) {
  const {
    skills,
    areas,
    industries,
    courses,
    education,
    languages,
    papers,
    volunteerings,
    curriculumVitae,
    tecRelationship,
    ...professionalExperience
  } = professionalInformation;

  const operation = expertProfile?.professionalExperience ?
    "update" : "create";
  return {
    ...professionalExperience,
    tecRelationship: { set: tecRelationship },
    expertProfile: {
      update: {
        professionalExperience: {
          [operation]: {
            skills: { set: skills },
            areas: { set: areas },
            industries: { set: industries },
            courses: {
              create: courses?.map(item => removeUnnusedFields(item))
            },
            education: {
              create: education?.map(item => removeUnnusedFields(item))
            },
            languages: {
              create: languages?.map(item => removeUnnusedFields(item))
            },
            papers: {
              create: papers?.map(item => removeUnnusedFields(item))
            },
            volunteerings: {
              create: volunteerings?.map(item => removeUnnusedFields(item))
            }
          }
        }
      }
    }
  };
}
