import gql from "graphql-tag";

export const createMilestoneWithOdeId = gql`
  mutation CreateMilestoneWithOdeId(
    $id: ID,
    $data: MilestoneCreateInput!
  ){
    updateODE(
      where: { id: $id }
      data: { milestones: { create: [$data] } }
    ){
      id
    }
  }
`;