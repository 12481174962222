import React, { useContext, useEffect } from "react";
import { Form, Input, Row, Col, Select } from "antd";
import { fieldValidationMessages } from "../../../../../../../utils";
import { FormContainer } from "../../../../../../shared";
import { RelationshipInfo } from "./relationship-info";
import {
  NewExpertContext
} from "../../../../../../../contexts/experts/new/context";
import {
  CAREERS,
  TEC_RELATIONSHIP_TYPES
} from "../../../../../../../helpers";

export function TecRelationshipForm({ editable = true }) {
  const {
    onFormFieldsChange,
    form,
    data = {}
  } = useContext(NewExpertContext);

  useEffect(() => {
    form.setFieldsValue({ ...data?.user });
  }, [data]);

  if (!editable) {
    return <RelationshipInfo tecRelationship={{ ...data?.user }} />;
  }

  return (
    <FormContainer>
      <Form
        validateMessages={fieldValidationMessages}
        onFieldsChange={onFormFieldsChange}
        form={form}
        layout="vertical">
        <Form.Item
          rules={[{ required: true }]}
          name="tecRelationship"
          label="Tipo de vínculo">
          <Select options={TEC_RELATIONSHIP_TYPES} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="enrollment"
          label="Matrícula / Nomina">
          <Input />
        </Form.Item>
        <Row
          gutter={[10, 8]}
          justify="start">
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="campus"
              label="Campus">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="careerName"
              label="Carrera">
              <Select options={CAREERS} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
}
