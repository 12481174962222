import React, { useContext } from "react";
import { List, Button } from "antd";
import { withLoader, withRouter, cardGridSizes, paginationConfig } from "../../../../helpers";
import { PerkItem } from "../../../shared/perk-item";
import {
  CreatePerkModalContext
} from "../../../../contexts/shared/create-perk-modal/context";
import { PaginationContext } from "../../../../contexts/shared";


function PerksList({
  data,
  loading,
  onChangePage,
  currentPage
}) {

  const { onViewPerk } = useContext(CreatePerkModalContext);
  const { onPaginationChange } = useContext(PaginationContext);

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange("perks")(page, pageSize);
  };

  return (
    <List
      style={{ margin: "24px" }}
      pagination={{
        ...paginationConfig,
        current: currentPage,
        defaultPageSize: 12,
        onChange: page => handlePaginationChange(page),
        total: data?.meta?.aggregate?.count || 0
      }}
      loading={loading}
      grid={cardGridSizes}
      dataSource={data?.perks}
      renderItem={perk =>
        <List.Item>
          <PerkItem
            perk={perk}
            extra={
              <Button
                type="primary"
                onClick={() => onViewPerk(perk)}>
                Ver
              </Button>
            } />
        </List.Item>} />
  );
}

export default withLoader(withRouter(PerksList));