import React from "react";
import { Menu } from "antd";
import { withRouter } from "../../../../../helpers";
import useTreatment from "../../../../../hooks/feature-flags/use-treatment";

function Navbar({ navigate, selectedKeys, routes = {}, ...props }) {

  const disableTaskTabTreatmentValue =
    useTreatment("DisableTaskTab");

  const disableExpertsTabValue =
    useTreatment("Disable_Experts_Tab");

  return (
    <Menu
      defaultOpenKeys={["objectives"]}
      selectedKeys={selectedKeys}
      style={{ borderBottom: "none" }}
      mode="horizontal"
      {...props}>
      <Menu.Item
        onClick={() => navigate(routes.objectives || "./objectives")}
        key="objectives">
        Objetivos
      </Menu.Item>
      {
        disableExpertsTabValue === "OFF"
          ? <Menu.Item
              onClick={() => navigate(routes.experts || "./experts")}
              key="experts">
            Expertos
          </Menu.Item>
          : null
      }
      <Menu.Item
        onClick={() => navigate(routes.odes || "./odes")}
        key="odes">
        Compañías
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.entrepreneurs || "./entrepreneurs")}
        key="entrepreneurs">
        Emprendedores
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.coordinators || "./coordinators")}
        key="coordinators">
        Coordinadores
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.trainings || "./trainings")}
        key="trainings">
        Capacitación
      </Menu.Item>
      {
        disableTaskTabTreatmentValue === "OFF"
          ? <Menu.Item
              onClick={() => navigate(routes.tasks || "./tasks")}
              key="tasks">
            Tareas
          </Menu.Item>
          : null
      }
      <Menu.Item
        onClick={() => navigate(routes.calendar || "./calendar")}
        key="calendar">
        Calendario
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.perks || "./perks")}
        key="perks">
        Perks
      </Menu.Item>
      <Menu.Item
        onClick={() => navigate(routes.announcements || "./announcements")}
        key="announcements">
        Avisos
      </Menu.Item>
    </Menu>
  );
}

export default withRouter(Navbar);
