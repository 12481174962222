import React from "react";
import { Skeleton, Alert, Card } from "antd";

export function withLoader(func) {
  return function component({
    data,
    onChange,
    error,
    isLoading,
    ...props
  }) {
    if(isLoading) {
      return <Card bordered={false}><Skeleton loading active /></Card>;
    }

    if(!data || error) {
      return (
        <Card>
          <Alert
            message="Error"
            description="Ha ocurrido un error al cargar los datos de esta sección,
            por favor actualiza la página."
            type="error"
            showIcon />
        </Card>
      );
    }

    return func({data, onChange, error, isLoading, ...props});
  };
}
