import gql from "graphql-tag";

export const getAssignedServicesByBatchId = gql`
  query GetAssignedServicesByBatchId($id: ID!, $type: String!) {
    assignedExpertServices(
      where: {
        batch: { id: $id },
        expertService: { type: $type },
        status: "ASSIGNED"
      },
      orderBy: assignationIdentifier_ASC) {
      status
      id
      expertServiceData
      assignationIdentifier
    }
  }
`;
