/**
 * @param {*} files File items from our service [{ id, fileName, url }]
 * @returns {*} An array of items supported by the defaultFileList prop of Upload input
 */
export const toFileList = (files = []) => {
  return files.map((f, i) => (
    {
      uid: `-${i}`,
      id: f.id,
      name: f.fileName || f.url,
      url: f.url,
      status: "done",
      type: f?.type
    }
  ));
};
