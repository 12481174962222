import React, { useState } from "react";
import { IndustriesChart } from "./industries-chart";
import { SectorsChart } from "./sectors-chart";
import { SalesStageChart } from "./sales-stage-chart";
import { FinancingStageChart } from "./financing-stage-chart";
import { BusinessModelChart } from "./business-model-chart";
import { ProductStageChart } from "./product-stage-chart";
import { EntrepreneursPerGenderChart } from "./entrepreneurs-per-gender-chart";
import { EntrepreneursAgeRangeChart } from "./entrepreneurs-age-range-chart";
import { EntrepreneursOccupationChart } from "./entrepreneurs-occupation-chart";
import { BatchStatisticsCountResume } from "./batch-statistics-count-resume";
import { BatchFilters } from "./batch-filters";
import { cubeJSClient } from "../../../api-clients";

export const AnalyticsPage = () => {
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [batchRangeDates, setBatchRangeDates] = useState({
    since: undefined,
    until: undefined
  });

  const onBatchChange = batchId => {
    setSelectedBatch(batchId);
  };

  const onProgramChange = batchId => {
    setSelectedProgram(batchId);
  };

  const onDateChange = (since, until) => {
    setBatchRangeDates({ since, until });
  };

  /*
   Company
   - ✅ Industries
   - ✅ Sectors
   - ✅ Sales stage
   - ✅ Financing stage
   - ✅ Business model
   - ✅ Product stage
   - ✅ Tech Based percentage
   - ✅ With Social component percentage
   - ✅ Summary: total count, un-enrolled, finished batch

   Entrepreneur
   - ✅ Gender
   - ✅ Age Range
   - ✅ Occupation
   */

  const filters = {
    programId: selectedProgram,
    batchId: selectedBatch,
    batchRangeDates
  };

  return (
    <div style={{ padding: "50px", maxWidth: "1200px", margin: "auto" }}>
      {
        // put the filters here
      }
      <BatchFilters
        onDateChange={onDateChange}
        onBatchChange={onBatchChange}
        onProgramChange={onProgramChange} />
      <BatchStatisticsCountResume
        batchRangeDates={batchRangeDates}
        selectedProgram={selectedProgram}
        selectedBatch={selectedBatch} />

      <EntrepreneursPerGenderChart
        filters={filters}
        cubeClient={cubeJSClient} />
      <EntrepreneursAgeRangeChart
        filters={filters}
        cubeClient={cubeJSClient} />
      <EntrepreneursOccupationChart
        filters={filters}
        cubeClient={cubeJSClient} />

      <IndustriesChart
        filters={filters}
        cubeClient={cubeJSClient} />
      <SectorsChart
        filters={filters}
        cubeClient={cubeJSClient} />
      <SalesStageChart
        filters={filters}
        cubeClient={cubeJSClient} />
      <FinancingStageChart
        filters={filters}
        cubeClient={cubeJSClient} />
      <BusinessModelChart
        filters={filters}
        cubeClient={cubeJSClient} />
      <ProductStageChart
        filters={filters}
        cubeClient={cubeJSClient} />
    </div>
  );
};
