import { getReadableValue, expertServiceCategories } from "../../select-options";
import moment from "moment";
import { cloneDeep } from "lodash";

export function assignedExpertService(data = {}) {
  const decorated = cloneDeep(data);

  decorated.object = data;

  decorated.modalTitle =
    data?.expertServiceData?.type === "MENTORSHIP" ?
    "Mentoría" :
    "Servicio";

  decorated.serviceTitle =
    [
      data?.expertServiceData?.name
    ].filter(i => i).join(" - ");

  decorated.category = getReadableValue(
    expertServiceCategories,
    data?.expertServiceData?.category
  );

  decorated.unit =
    data?.expertServiceData?.type === "MENTORSHIP" ?
    "Horas" :
    "Servicios";

  decorated.limitDate = moment(data?.limitDate).format("DD/MM/YYYY");
  decorated.assignmentDate = moment(data?.assignmentDate).format("DD/MM/YYYY");
  decorated.acceptedAt = moment(data?.scheduledService?.acceptedAt)
    .format("DD/MM/YYYY");

  decorated.availableUnits = data.availableUnits ?? 0;
  decorated.assignedUnits = data.assignedUnits ?? 0;

  decorated.isMentorship = data?.expertServiceData?.type === "MENTORSHIP";
  decorated.isWorkshop = data?.expertServiceData?.type === "WORKSHOP";

  if (data?.batch?.coordinators?.length) {
    decorated.batchLeadCoordinator = data?.batch?.coordinators[0];
  } else if (data?.batch?.users?.length) {
    decorated.batchLeadCoordinator = data?.batch?.users[0];
  }

  decorated.consumedUnits =
    parseInt(decorated.assignedUnits) - parseInt(decorated.availableUnits);

  decorated.paymentMethodLabel = data?.expertServiceData
    ?.method === "HOURS" ? "Horas" : "Unidades";

  return decorated;
}
