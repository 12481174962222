import gql from "graphql-tag";

export const updateOdeContent = gql`
  mutation UpsertOdeContent(
    $id: ID!,
    $create: OdeContentCreateInput!,
    $update: OdeContentUpdateInput!
  ) {
    upsertOdeContent(
      where: { id: $id }
      update: $update
      create: $create
    ) {
      id
    }
  }
`;
