import React from "react";
import { Route, Switch } from "react-router";
import { WorkspacesIndex } from "../../../pages/admin/configuration/workspaces";


export const AdminConfigurationRoutes = (
  <Switch>
    <Route
      exact
      path="/v2/admin/configuration/workspaces"
      component={WorkspacesIndex} />
  </Switch>
);
