import { flatten } from "lodash";
/**
 * Reduce an array of objects to get the field indicated by the path as a flat
 * array. If the path param is not passed, the array will be flatted until
 * the first level.
 * @param {Object[]} source Array of objects
 * @param {string} path The fields path. Example: path.to.field.key
 */
export const flatReduce = (source = [], path = undefined) => {
  if (!path) {
    return flatten(source).filter(item => !!item);
  }

  let reducedSource = undefined;

  path.split(".").forEach(key => {
    const reducedValues = (reducedSource || source)
      .reduce((acc, currReport) => {
        acc = Array.from(acc)
          .concat(Array.from(currReport[key] ?? []));

        return acc;
      }, []);

    reducedSource = reducedValues;
  });

  return reducedSource;
};
