import React from "react";
import { Switch, Route } from "react-router-dom";
import { ProfileIndex } from "../../../pages/coordinator/profile";

export const CoordinatorProfileRoutes = (
  <Switch>
    <Route
      path="/v2/coordinator/me/profile"
      component={ProfileIndex} />
  </Switch>
);