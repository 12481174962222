import { Badge, Calendar, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  CurrentUserContext,
  BatchEventDetailsModalContext
} from "../../../contexts/shared";

export function EventsCalendar({
  onChange,
  events = [],
  defaultEventColor = "purple",
  onDatesChange,
  loading,
  error,
  ...props
}) {
  const [state, setState] = useState({
    events: [],
    selectedMonth: (new Date()).getMonth()
  });

  const { openModal } = useContext(BatchEventDetailsModalContext);
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    const groupedEvents = events.reduce((acc, event) => {
      const date = new Date(Date.parse(event.date));
      const day = date.getDate();

      if(Object.prototype.hasOwnProperty.call(acc, day)) {
        acc[day].push(event);
      }
      else {
        acc[day] = [event];
      }

      return acc;
    }, {});

    setState(state => ({ ...state, events: groupedEvents }));
  }, [events]);

  const dateCellRender = day => {
    const dayEvents = state.events[day.date()];

    if(!dayEvents) {
      return null;
    }

    return dayEvents.map(event => {
      const eventMonth = day.month();

      switch (currentUser.systemRole){
        case("ENTREPRENEUR"):
          if (
            eventMonth !== state.selectedMonth
            || !ENTREPRENEUR_ALLOWED_EVENTS.includes(event.type)
          )
            return null;
          break;
        default:
          if (eventMonth !== state.selectedMonth)
            return null;
      }

      return (
        <ul key={event.id} className="events">
          <li key={event.id}>
            <Badge
              onClick={() => openModal(event)}
              color={event.color || defaultEventColor} text={event.name} />
          </li>
        </ul>
      );
    }, []);
  };

  const handleOnChange = date => {
    onChange && onChange(date);
    setState(prevState => ({ ...prevState, selectedMonth: date.month() }));
    onDatesChange && onDatesChange(date);
  };

  if (loading || error) {
    if (error) {
      message.error("Ocurrió un error al cargar los eventos");
    }
    return (
      <Calendar mode="month" className="events-calendar" />
    );
  }

  return (
    <Calendar
      className="events-calendar"
      dateCellRender={dateCellRender}
      onChange={handleOnChange}
      mode="month"
      {...props} />
  );
}

const ENTREPRENEUR_ALLOWED_EVENTS = [
  "EVENT",
  "TRAINING",
  "SERVICE_PRODUCT",
  "WORKSHOP_CONTENT",
  "ACTIVITY_CONTENT",
  "SERVICE_MENTORSHIP"
];