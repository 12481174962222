import {
  getDeletedIdsFromTwoArrays
} from "../../../../../helpers/get-deleted-ids-from-two-arrays";

export const formatValues = (
  originalValues = [],
  oldRelationships,
  hasTecRelationship
) => {
  const values = JSON.parse(JSON.stringify(originalValues));

  const upsertValues = values.map(relationship => {
    const id = relationship.id || "";
    delete relationship.uuid;
    delete relationship.uid;
    delete relationship.__typename;
    delete relationship.id;

    return ({
      where: { id },
      create: { ...relationship },
      update: { ...relationship }
    });
  });

  const deletedTecRelationships = getDeletedIdsFromTwoArrays(
    originalValues,
    oldRelationships
  );

  return {
    hasTecRelationship: hasTecRelationship ? 1 : 0,
    tecRelationships:{
      upsert: upsertValues,
      deleteMany: { id_in: deletedTecRelationships || [""] }
    }
  };
};
