import React, { useContext } from "react";
import { Alert, List } from "antd";
import EntrepreneurItem from "./item";
import { EntrepreneurProfilesContext } from "../../../../contexts/entrepreneur/entrepreneurs/profiles";
import { paginationConfig, systemRoleEnum } from "../../../../helpers";
import { CurrentUserContext, PaginationContext } from "../../../../contexts/shared";

export default function EntrepreneurList({
  onChangePage,
  currentPage
}) {
  const { data, loading, error } = useContext(EntrepreneurProfilesContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { onPaginationChange } = useContext(PaginationContext);

  if(error) {
    return (
      <Alert
        type="error"
        message="Ha ocurrido un error al cargar los expertos,
        por favor intentalo de nuevo en unos segundos."
        showIcon />
    );
  }

  const discardEntrepreneursWithoutOdes = (users = []) => {
    if (currentUser.systemRole === systemRoleEnum.SUPER_ADMIN)
      return users;

    return users.filter(user => user.teamOdes.length > 0);
  };

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange("entrepreneurs")(page, pageSize);
  };

  return (
    <List
      renderItem={i => <EntrepreneurItem entrepreneur={i} />}
      itemLayout="vertical"
      pagination={{
        ...paginationConfig,
        current: currentPage,
        onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
        total: data?.meta?.aggregate?.count || 0
      }}
      dataSource={discardEntrepreneursWithoutOdes(data?.users)}
      loading={loading} />
  );
}
