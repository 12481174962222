import React from "react";
import { CardListItem } from "../list-item/card-list-item";
import { Button, List } from "antd";
import { TagList } from "../tag-list";
import { Section } from "../section";
import { cardGridSizes } from "../../../helpers";
import {
  expertServiceCategories,
  getReadableValue
} from "../../../helpers/select-options";

export function CompanyAvailableServicesList({
  availableServices = []
}) {
  return (
    <Section
      className="CompanyServiceList"
      spaced
      title="Servicios disponibles">
      <List
        grid={cardGridSizes}
        style={{ marginTop: "24px" }}
        dataSource={availableServices}
        renderItem={availableService =>
          <List.Item>
            <CardListItem
              footerItems={[
                <Button
                  type="default"
                  key="btn-assigned">
                  Asignar
                </Button>
              ]}
              extra={
                <TagList
                  colors={["blue", "red"]}
                  maxCount={2}
                  tags={[
                    getReadableValue(
                      expertServiceCategories,
                      availableService?.category
                    ),
                    "Servicio"
                  ]} />}
              coverUrl={availableService?.coverPicUrl}
              title={availableService?.name}>
              {availableService?.expert?.fullName}
            </CardListItem>
          </List.Item>
        } />
    </Section>
  );
}
