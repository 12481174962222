import React  from "react";
import { client } from "../../../../graphql";
import { useHistory } from "react-router-dom";
import { withLoader } from "../../../helpers";
import { Button, Popconfirm, Table } from "antd";
import { shared } from "../../../graphql/shared";
import { useMutation } from "@apollo/react-hooks";
import { DeleteOutlined } from "@ant-design/icons";
import { RoleGuard } from "../role-guard";
import { columns } from "./table-colums-definition";
import useTreatment from "../../../hooks/feature-flags/use-treatment";

function AnnouncementsTable({ data, refetch }) {
  const history = useHistory();

  const [deleteAnnouncementById] =
    useMutation(shared.mutations.deleteAnnouncementById, {
    client
  });

  const onDelete = async (event, announcements) => {
    event.stopPropagation();
    try {
      await deleteAnnouncementById({ variables: { id: announcements.id } });
    }
    catch(e) {
      console.error(e);
    }
    await refetch();
  };

  const dynamicColumns = [
    {
      key: "action",
      align: "center",
      render: announcements => (
        <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR"]}>
          <Popconfirm
            title={"Estás a punto de eliminar este aviso"}
            cancelText="Cancelar"
            okText="Eliminar"
            onClick={event => { event.stopPropagation(); }}
            onCancel={event => { event.stopPropagation(); }}
            onConfirm={event => onDelete(event , announcements)}>
            <Button
              type={"link"}
              icon={<DeleteOutlined />}
              onClick={event => { event.stopPropagation(); }} />
          </Popconfirm>
        </RoleGuard>
      )
    }
  ];

  const disableBatchOffer = useTreatment("DisableBatchOffer");

  const onRowClick = announcement => {
    return {
      onClick: () => {
        if (disableBatchOffer)
          history.push(`./announcements/${announcement.id}`);
        else {
          history
            .replace(
              `./offer?section=announcements&announcement=${announcement.id}`);
        }
      }
    };
  };

  return (
    <Table
      onRow={onRowClick}
      style={{ marginTop: "15px" }}
      columns={columns.concat(dynamicColumns)}
      dataSource={data?.announcements} />
  );
}

export default withLoader(AnnouncementsTable);
