import React, { useContext, useEffect } from "react";
import { Table } from "antd";
import {
  expertServiceTypes,
  getReadableValue,
  maskedAssignedUnits,
  maskedStatus,
  paginationConfig
} from "../../../../../helpers";
import { Format, ServiceCost, StatusTag } from "../../../../shared";
import {
  PaginationContext
} from "../../../../../contexts/shared";
import { useLocation } from "react-router-dom";

export function AssignationsTable({
  loading = false,
  items: assignations = [],
  currentPage,
  onChangePage,
  totalRecordsCount,
  paginationRefetchName,
  serviceType,
  openModal
}) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const idFromNotification = params.get("id");

  useEffect(() => {
    if (!idFromNotification) return;
    openModal({ assignedExpertService: { id: idFromNotification } });
  }, []);

  const { onPaginationChange } = useContext(PaginationContext);

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange(paginationRefetchName)(page, pageSize);
  };

  const handleOnRowClick = assignedExpertService => ({
    onClick: () =>  openModal({ assignedExpertService }),
    className: "clickable"
  });

  return (
    <Table
      loading={loading}
      scroll={{ x: true }}
      style={{ width: "100%" }}
      dataSource={assignations || []}
      rowKey={(row, index) => row?.id || index}
      onRow={handleOnRowClick}
      pagination={{
        ...paginationConfig,
        current: currentPage,
        defaultPageSize: 12,
        onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
        total: totalRecordsCount
      }}>
      {serviceType !== "WORKSHOP" && (
        <Table.Column
          title="Compañía"
          render={({ ode }) => ode?.name } />
      )}
      <Table.Column
        title="Programa"
        render={({ batch }) => batch?.program?.name} />
      <Table.Column
        title="Batch"
        render={({ batch }) => batch?.name} />
      <Table.Column
        title="Producto"
        render={({ expertServiceData }) => expertServiceData?.name} />
      <Table.Column
        title="Tipo de producto"
        render={({ expertServiceData }) =>
          getReadableValue(expertServiceTypes, expertServiceData?.type) } />
      <Table.Column
        align="center"
        title="Cantidad"
        render={({ expertServiceData: { method }, assignedUnits }) =>
          maskedAssignedUnits({ method, duration: assignedUnits })} />
      <Table.Column
        align="center"
        title="Costo total"
        render={({ assignedUnits, ...assignedExpertService }) =>
          <ServiceCost
            duration={assignedUnits}
            assignedExpertService={assignedExpertService} />} />
      <Table.Column
        align="center"
        title="Fecha de asignación"
        render={({ assignmentDate }) =>
          <Format type="date">{assignmentDate}</Format>
        } />
      <Table.Column
        align="center"
        title="Fecha límite de consumo"
        render={({ limitDate }) =>
          <span className="bold">
            <Format type="date">{limitDate}</Format>
          </span>
        } />
      <Table.Column
        align="center"
        title="Estado"
        render={({ status, limitDate, availableUnits, assignedUnits}) => {
          const newStatus = maskedStatus({
            status,
            limitDate,
            availableUnits,
            assignedUnits
          });
          return (<StatusTag status={newStatus} />);
        }} />
    </Table>
  );
}
