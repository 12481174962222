import { useLoggedUser } from "../use-logged-user";
import { batchTasksTabIsEnabled } from "./checkers/batch-checkers";

export const useUserPermissions = () => {
  const { loggedUser } = useLoggedUser();
  const { systemRole } = loggedUser || {};

  return {
    batch: {
      tasksTab: {
        isEnabled: batchTasksTabIsEnabled(systemRole)
      }
    }
  };
};
