import gql from "graphql-tag";

export const transformOdeToCompanyMutation = gql`
  mutation TransformOdeToCompany($odeId: ID) {
    transformOdeIntoCompany(
      where: { id: $odeId }
    ){
      count
    }
  }
`;