import gql from "graphql-tag";

export const getOrganizations = gql`
  query GetOrganizations(
    $iname: String
    $iservices: String
    $istages: String
    $private: Boolean
    $states: OrganizationStateWhereInput
    $first: Int
    $skip: Int
  ) {
    organizations(
      where: {
        status: APPROVED
        iname_contains: $iname
        private: $private
        iservices_contains: $iservices
        istages_contains: $istages
        states_some: $states
      }
      first: $first
      skip: $skip
    ) {
      id
      logo
      name
      iname
      startedAt
      private
      stages
      nationalPresence
      states {
          name
      }
      services
    }
    meta: organizationsConnection(
      where: {
        status: APPROVED
        iname_contains: $iname
        private: $private
        iservices_contains: $iservices
        istages_contains: $istages
        states_some: $states
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
