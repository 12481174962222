import React from "react";
import { Page, View } from "@react-pdf/renderer";
import { Header } from "../../components/header";
import { General } from "./general";
import { Details } from "./details";
import { PaymentInfo } from "./payment-info";

export const PageOne = ({ data }) => {
  return (
    <Page
      size="A4"
      style={{
        padding: "25px",
        display: "flex",
        justifyContent: "space-between",
        fontFamily: "Roboto" }}>
      <View>
        <Header data={data.header} />
        <General data={data.general} />
        <Details data={data.details} />
      </View>
      <PaymentInfo data={data.paymentInfo} />
    </Page>
  );
};