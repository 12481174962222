import React from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Row, Typography, Select } from "antd";
import { Section, EmptyView, BatchSectionHeading } from "../../../../shared";
import { BatchesService } from "../../../../../services";
import { BatchServicesList } from "./batch-services-list";
import { useService, useBatchServices } from "../../../../../hooks/shared";

export const ExpertServicesForm = ({ showAddServices, handleClickExpert }) => {
  const { id: batchId } = useParams();
  const [form] = Form.useForm();

  const { connectServices, removeService } = useBatchServices({ batchId });

  const {
    data: availableServices,
    loading: loadingAvailableServices,
    refetch: refetchAvailableServices
  } = useService(
    BatchesService.getAvailableServicesToConnect,
    { batchId, types: ["PRODUCT", "WORKSHOP"] }
  );

  const {
    data: batchServices,
    loading: loadingBatchServices,
    refetch: refetchBatchServices
  } = useService(
    BatchesService.getServices,
    { batchId, types: ["PRODUCT", "WORKSHOP"] }
  );

  const getLabel = text =>
    <Typography.Text style={{ fontSize: "14px"}}>
      {text}
    </Typography.Text>;

  const onConnectServices = async () => {
    await form.validateFields();
    const { services: serviceIds } = form.getFieldsValue();

    await connectServices({
      serviceIds,
      afterSuccess: () => reloadDataSources(true)
    });
  };

  const onRemoveService = async ({ serviceId = "" }) => {
    await removeService({
      serviceId,
      afterSuccess: reloadDataSources
    });
  };

  const reloadDataSources = async (shouldResetForm = false) => {
    await Promise.all([
        refetchBatchServices(),
        refetchAvailableServices()
      ]);

    shouldResetForm && form.resetFields();
  };

  if (!showAddServices && !batchServices?.length) {
    return (
      <EmptyView
        style={{ width: "auto" }}
        isEmpty={true}
        isLoading={false}
        buttonTitle="Agregar servicios"
        onClick={() => handleClickExpert("showAddServices")}
        description={
          <BatchSectionHeading
            title="¡Oops, aún no tienes Servicios!"
            subTitle="Agrégalos para tenerlos disponibles en tu batch." />
        } />
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      className="NewBatch__MembersForm">
      <Section style={{ marginBottom: "11px", minHeight: "600px"}}>
        <Row justify="space-between">
          <Form.Item
            name="services"
            label={getLabel("Aquí puedes ver, añadir servicios y asignarlos a las compañías")}>
            <Select
              allowClear
              showSearch
              optionFilterProp="label"
              mode="multiple"
              className="wide-input"
              loading={loadingAvailableServices}
              placeholder="Seleccionar tus servicios"
              options={availableServices?.map(service =>
                ({ label: service.name, value: service.id }))
              } />
          </Form.Item>
          <Button
            onClick={onConnectServices}>
            Agregar
          </Button>
        </Row>
        <BatchServicesList
          loading={loadingBatchServices}
          batchServices={batchServices}
          onRemove={onRemoveService} />
      </Section>
    </Form>
  );
};
