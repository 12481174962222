import React from "react";
import { Pie } from "@ant-design/charts";
import { getReadableValue } from "../../../../../../helpers";

export function CareerAreaChart({ odes = [] }) {
  const accumulate = (key, object) => {
    if(key) {
      if(Object.prototype.hasOwnProperty.call(object, key)) {
        object[key]++;
      }
      else {
        object[key] = 1;
      }
    }
    else {
      object["No especificado"]++;
    }

    return object;
  };

  const careers = odes.reduce((acc, ode) => {
    ode.team.forEach(user => {
      if(user.tecRelationships.length) {
        user.tecRelationships.forEach(tecRelationship => {
          const career = tecRelationship.careerName;
          accumulate(career, acc);
        });
      }
      else {
        accumulate(user.career, acc);
      }
    });

    return acc;
  }, {"No especificado": 0});

  const data = Object.keys(careers).map(career => {
    return { type: career, value: careers[career] };
  });

  return (
    <Pie
      angleField="value"
      colorField="type"
      meta={{
        type: {
          alias: "Carrera",
          formatter: v => {
            return getReadableValue("CAREERS", v, v);
          }
        }
      }}
      label={{
        type: "inner",
        offset: "-30%",
        content: function content(_ref) {
          const percent = _ref.percent;
          return "".concat((percent * 100).toFixed(2), "%");
        },
        style: {
          fontSize: 14,
          textAlign: "center"
        }
      }}
      data={data} />
  );
}
