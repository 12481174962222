export const formStyle = {
  maxWidth: "280pt",
  padding: "20pt",
  background: "#FFF",
  boxShadow: "1px 1px 4px 4px rgba(5,5,5,.1)",
  margin: "auto"
};

export const loginErrorLabel = {
  color: "red",
  width: "100%",
  textAlign: "center"
};
