import React, { useState, useMemo, useContext } from "react";
import { Form, message } from "antd";
import { withRouter } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import { admin } from "../../../../../graphql/admin";
import { shared } from "../../../../../graphql/shared";
import { NewExpertContext } from "../../../../../contexts/experts/new/context";
import { Section } from "../../../../../components/shared";
import { StepsWrapper } from "../../../../../components/expert/shared";
import {
  EntrepreneurshipsForm
} from "../../../../../components/expert/new/id/entrepreneurships";
import {
  ExpertServiceContext
} from "../../../../../contexts/experts/new/service/context";
import { getUpdatedData } from "./get-updated-data";

function Entrepreneurships({ history, match }) {
  const { ExpertsService } = useContext(ExpertServiceContext);
  const [form] = Form.useForm();
  const userId = match?.params?.userId || "";

  const [state, setState] = useState({
    busy: false,
    entrepreneurships: []
  });

  const [updateProfessionalExperience] = useMutation(
    admin.mutations.updateExpertProfile,
    {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: shared.queries.getExpertById,
        variables: { id: userId }
      }]
    }
  );

  const { loading, error, data } = useQuery(
    shared.queries.getExpertById,
    {
      client,
      variables: { id: userId }
    });

  const updateEntrepreneurships = entrepreneurships => {
    ExpertsService.setUpdateEntrepreneurships(
      entrepreneurships, state, setState);
  };

  const save = async () => {
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      const updatedData = getUpdatedData(state.entrepreneurships);

      await ExpertsService
        .setSave(updatedData, updateProfessionalExperience, userId);

      message.success("Datos guardados");
      history.push("/v2/admin/experts");
    } catch (err) {
      console.error(err);
      message.error("Ocurrió un error");
    } finally {
      dismissLoader();
    }
  };

  const injectActions = useMemo(() => ({
    updateEntrepreneurships,
    save,
    loading,
    error,
    data,
    form
  }), [state, loading]);

  const handleNext = async event => {
    event.preventDefault();
    try {
      await form.validateFields();
    } catch (error) {
      return message.warning("Campos requeridos faltantes.");
    }
    await save();
  };

  return (
    <NewExpertContext.Provider value={injectActions}>
      <StepsWrapper
        onNext={handleNext}
        prevUrl={`/v2/expert/new/${userId}/accounts`}
        currentStep={3}>
        <Section>
          <EntrepreneurshipsForm />
        </Section>
      </StepsWrapper>
    </NewExpertContext.Provider>
  );
}

export default withRouter(Entrepreneurships);
