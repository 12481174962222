/**
 * @param {Company} data
 * @return {Object}
 */
export function getCompanyMissingRequiredFields(data = {}) {

  const requiredFieldsValidation = {
    startDate: checkFields(data?.startDate, "fecha de fundación"),
    fiscalState: checkFields(data?.fiscalState, "situación fiscal"),
    businessName: checkFields(data?.businessName, "razón social"),
    fiscalId: checkFields(data?.fiscalId, "RFC/TAX ID"),
    fiscalEntryDate:
      checkFields(data?.fiscalEntryDate, "fecha de alta fiscal"),
    fiscalRepresentative:
      checkFields(
        data?.fiscalRepresentative,
        "nombre del representante legal"
      ),
    businessModel: checkFields(data?.businessModel, "modelo de negocio"),
    productStage: checkFields(data?.productStage, "etapa del producto"),
    salesStage: checkFields(data?.salesStage, "etapa de ventas"),
    annualSales: data?.salesStage === "NO_SALES"
      ? {status: undefined, msg: undefined}
      : checkFields(data?.annualSales, "ventas anuales MXN"),
    financingStage:
      checkFields(data?.financingStage, "etapa de financiación"),
    employeesAmount:
      checkFields(data?.employeesAmount, "número de empleados"),
    shortDescription: checkFields(data?.shortDescription, "descripción corta"),
    economicIndustry: checkFields(data?.economicIndustry, "industria"),
    economicSector: checkFields(data?.economicSector, "sector"),
    email: checkFields(data?.email, "email"),
    phone: checkFields(data?.phone, "teléfono"),
    city: checkFields(data?.city, "ciudad"),
    country: checkFields(data?.country, "pais"),
    presenceStates: data?.presenceStates?.length
      ? {status: undefined, msg: undefined}
      : {status: "error", msg:"Por favor ingrese lugares donde tiene presencia"}
  };

  return {
    requiredFieldsValidation
  };
}

/**
 * @param {string | undefined | boolean} field
 * @param {string} readableField
 * @returns {{msg: undefined, status: undefined}|{msg: string, status: string}}
 */

function checkFields (field, readableField) {
  if (!field || field === "N/A")
    return {status: "error", msg: `Por favor ingrese ${readableField}`};
  return {status: undefined, msg: undefined};
}


/**
 * @typedef Company
 * @property {string} name
 * @property {string} startDate
 * @property {string} legalName
 * @property {string} fiscalState
 * @property {string} businessModel
 * @property {string} productStage
 * @property {string} salesStage
 * @property {string} annualSales
 * @property {string} financingStage
 * @property {string} employeesAmount
 * @property {string} shortDescription
 * @property {string} economicIndustry
 * @property {string} economicSector
 * @property {string} email
 * @property {string} phone
 * @property {string} city
 * @property {string} country
 * @property {string} streetAddress
 * @property {string} colonyAddress
 * @property {string} zipCode
 * @property {string} presenceStates
 */
