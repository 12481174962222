import React from "react";
import { Select } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../../../graphql";

/**
 * Select component which shows a list of ODEs as options
 * @param {object} props
 * @param {string} props.gqlQuery Graphql template literal needed to fetch data
 * @param {JSON | undefined} props.queryVariables Optional variables to pass
 * to the GQL query
 * @param {string} props.dataKey The key of the requested entity
 */
export function GqlSelect({
  gqlQuery,
  queryVariables = {},
  dataKey,
  ...props
}) {
  const { data, loading, error } = useQuery(gqlQuery, {
    client,
    variables: queryVariables,
    fetchPolicy: "network-only"
  });

  /**
   * @param {JSON} data gql data result
   * @returns {{label: string, value: string  }[]} formated array of options
   */
  function optionsMapper(data = {}) {
    return data[dataKey]?.map(item => ({
      value: item.id,
      label: item.name
    }));
  }

  const options = optionsMapper(data ?? {});

  return (
    <Select
      allowClear
      disabled={error || loading}
      loading={loading}
      showSearch
      optionFilterProp="label"
      placeholder="Selecciona una opción"
      options={options}
      {...props}>
    </Select>
  );
}
