const unorderedProgramTypes = [
  { label: "Evento o experiencia", value: "EVENT_OR_EXPERIENCE", color: "blue" },
  { label: "Ideas o prototipos", value: "IDEAS_OR_PROTOTYPES", color: "pink" },
  { label: "Programa académico", value: "ACADEMIC_PROGRAM", color: "cyan" },
  { label: "Incubación", value: "INCUBATOR", color: "purple" },
  { label: "Pre-incubación", value: "PRE_INCUBATION", color: "volcano" },
  { label: "Aceleración", value: "ACCELERATOR", color: "green" },
  { label: "Pre-aceleración", value: "PRE_ACELERATION", color: "gold" },
  {
    label: "Híbrido incubación aceleración",
    value: "HYBRID_INCUBATION_ACCELERATION",
    color: "gold"
  },
  { label: "Desarrollo empresarial", value: "ENTERPRISE_DEVELOPMENT", color: "cyan" },
  { label: "Venture builder", value: "VENTURE_BUILDER", color: "red" }
];

export const programTypes = unorderedProgramTypes.sort((a, b) => {
  return a.label.localeCompare(b.label);
});
