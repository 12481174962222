import React, { useEffect } from "react";
import { GridContainer } from "../../../../shared";
import { StatisticCard } from "../../statistic-card";
import { useCompaniesStatistics } from "./use-companies-statistics";

export const StatisticsCards = ({ filters = {} }) => {
  const {
    loading,
    data,
    percentageData,
    applyFilters
  } = useCompaniesStatistics();

  useEffect(() => {
    applyFilters(filters);
  }, [filters]);

  return (
    <div style={{ marginTop: 32 }}>
      <GridContainer
        gutter={[16, 16]}
        responsiveValues={COL_RESPONSIVE_SIZES}>
        <StatisticCard
          loading={loading}
          value={data.totalCompanies}
          title="Totales" />
        <StatisticCard
          loading={loading}
          value={data.socialComponent}
          title="Con componente social"
          percentage={percentageData.socialComponent} />
        <StatisticCard
          loading={loading}
          value={data.techBased}
          title="Con base tecnológica"
          percentage={percentageData.techBased} />
      </GridContainer>
    </div>
  );
};

const COL_RESPONSIVE_SIZES = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 8,
  xl: 8,
  xxl: 8
};
