import React, { useContext } from "react";
import { Form } from "antd";
import { Section, Visibility } from "../../../";
import { ActionButton } from "../../action-button";
import { WorkExperienceForm } from "../form";
import {
  EditProfileContext
} from "../../../../../contexts/shared/edit-profile";
import { formatValues } from "./format-values";

export function WorkExperienceEditSection({ editable = false }) {

  const {
    saveChanges,
    editing,
    setEditing
  } = useContext(EditProfileContext);

  const onSave = () => {
    const values = form.getFieldsValue();
    const data = formatValues(values);

    saveChanges(data, "workExperience");
  };

  const [form] = Form.useForm();

  return (
    <Section
      spaced
      title="Experiencia laboral"
      bottom={
        <Visibility visible={editable}>
          <ActionButton
            editing={editing.workExperience}
            setEditing={setEditing}
            formName="workExperience"
            visible
            onSave={onSave} />
        </Visibility>}>
      <WorkExperienceForm
        form={form}
        editable={editing.workExperience && editable} />
    </Section>
  );
}
