import { join } from "lodash";
import { remove as removeAccents } from "remove-accents";

export function getUpdatedData({ personalInformation = {}, userId }) {
  const {
    resume,
    businessName,
    rfc,
    state: locationState,
    country,
    city,
    ...data
  } = personalInformation;
  const operation = userId ? "update" : "create";

  const fullName =
    join([data.firstName, data.middleName, data.lastName], " ");
  return  {
    ...data,
    systemRole: "ADVISER",
    password: "1234567890",
    fullName,
    ifullName: removeAccents(fullName.toLowerCase()),
    expertProfile: {
      [operation]: {
        resume,
        businessName,
        rfc,
        location: {
          [operation]: {
            country: country?.toString(),
            city,
            state: locationState
          }
        }
      }
    }
  };
}
