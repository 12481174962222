export const technologyLevelStatusOptions = [
  { label: "TRL 1", value: "TRL 1"},
  { label: "TRL 2", value: "TRL 2"},
  { label: "TRL 3", value: "TRL 3"},
  { label: "TRL 4", value: "TRL 4"},
  { label: "TRL 5", value: "TRL 5"},
  { label: "TRL 6", value: "TRL 6"},
  { label: "TRL 7", value: "TRL 7"},
  { label: "TRL 8", value: "TRL 8"},
  { label: "TRL 9", value: "TRL 9"},
  { label: "No aplica", value: "DOESNT_APPLY"}
];
