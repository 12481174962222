import React from "react";
import { Switch, Route } from "react-router-dom";
import { PerksIndex } from "../../../pages/admin/perks";

export const AdminPerksRoutes = <Switch>
  <Route
    exact
    path="/v2/admin/perks"
    component={PerksIndex} />
</Switch>;
