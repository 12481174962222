import React, { useContext } from "react";
import { Button, Modal, Skeleton } from "antd";
import { CommentsList } from "../../../../components/shared/comment-list";
import { OdeUpdateCommentsModalContext } from "../context";
import { Visibility } from "../../../../components/shared";

export function OdeUpdateCommentsModal({ visible = false }) {
  const {
    loading,
    odeUpdate,
    closeModal,
    sendComment,
    editComment
  } = useContext(OdeUpdateCommentsModalContext);

  return (
    <Modal
      onCancel={closeModal}
      visible={visible}
      destroyOnClose
      title="Comentarios"
      footer={[
        <Button
          key="closeButton"
          onClick={closeModal}>
          Cerrar
        </Button>
      ]}>
      <Visibility visible={!loading}>
        <CommentsList
          loading={loading}
          comments={odeUpdate?.comments}
          sendComment={sendComment}
          editComment={editComment} />
      </Visibility>
      <Visibility visible={loading}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Visibility>
    </Modal>
  );
}
