export const formatValues = (accounts = {}) => {
  return {
    expertProfile: {
      upsert: {
        create: {
          ...accounts
        },
        update: {
          ...accounts
        }
      }
    }
  };
};
