import React from "react";
import { Space } from "antd";

export function IconLabel({ icon, children, styles = {} }) {
  return (
    <Space style={{...styles}}>
      {icon}
      {children}
    </Space>
  );
}
