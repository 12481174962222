import React, { useContext } from "react";
import {
  SidebarLayout,
  ContentWrapper
} from "../../../../../components/shared";
import {
  Navbar,
  Summary,
  Services,
  Breadcrumbs
} from "../../../../../components/admin/experts/show";
import { shared } from "../../../../../graphql/shared";
import { AdminExpertsContext } from "../../../../../contexts/admin/experts";
import {
  ExpertServiceModalProvider
} from "../../../../../contexts/shared/expert-service-modal";
import { withRouter } from "../../../../../helpers";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import { MenuContext } from "../../../../../contexts/shared/menu";
import {
  AssignedExpertServiceModalProvider
} from "../../../../../contexts/shared/assigned-expert-service-modal";

function ServicesIndex({ match }) {
  const { params = {} } = match;

  const {
    loading: expertLoading,
    error: expertError,
    data: expertData
  } = useQuery(
    shared.queries.getExpertById,
    {
      variables: { id: params?.expertId },
      client,
      fetchPolicy: "network-only"
    }
  );

  const {
    loading: servicesLoading,
    error: servicesError,
    data: servicesData
  } = useQuery(
    shared.queries.getServicesByExpertId,
    {
      variables: { expertId: params?.expertId },
      client,
      fetchPolicy: "network-only"
    }
  );

  const data = {
    expert: expertData?.user,
    expertServices: servicesData?.expertServices
  };

  const { setMenuState } = useContext(MenuContext);

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/profiles"]
  });

  return (
    <AdminExpertsContext.Provider value={{ data }}>
      <ExpertServiceModalProvider>
        <AssignedExpertServiceModalProvider>
          <SidebarLayout>
            <Breadcrumbs expertName={expertData?.user?.fullName} />
            <Summary
              allowEdit
              data={expertData}
              isLoading={expertLoading}
              error={expertError} />
            <Navbar selectedKeys={["services"]} />
            <ContentWrapper>
              <Services
                borderless
                data={servicesData}
                isLoading={servicesLoading}
                error={servicesError} />
            </ContentWrapper>
          </SidebarLayout>
        </AssignedExpertServiceModalProvider>
      </ExpertServiceModalProvider>
    </AdminExpertsContext.Provider>
  );
}

export default withRouter(ServicesIndex);
