import React, { useState } from "react";
import { ContentViewSwitchContext } from "./context";

export function ContentViewSwitchProvider({ children }) {
  const [isListView, setListView] = useState(true);

  return (
    <ContentViewSwitchContext.Provider value={{ isListView, setListView }}>
      { children }
    </ContentViewSwitchContext.Provider>
  );
}
