import React from "react";
import { Col, Form, Input, Row, Rate, DatePicker } from "antd";
import { genericFieldValidationMsgs } from "../../../../utils";
import { GqlSelect, Visibility } from "../../../../components/shared";
import { getOdesByBatchId } from "../../../../graphql/admin/queries";
import { getOdeInvolvedBatches } from "../../../../graphql/shared/queries";

/**
 * @param {object} props
 * @param {import("antd/lib/form").FormInstance} props.form
 * @param {"ode" | "batch"} props.selectType "ode" | "batch"
 * @param {string | undefined} props.odeId Optional. Needed by the Batch select.
 * @param {string | undefined} props.batchId Optional. Needed by the ODE select.
 */
export function OdeUpdateForm({
  form,
  selectType = "ode",
  odeId,
  batchId
}) {

  return (
    <Form
      validateMessages={genericFieldValidationMsgs}
      form={form}
      layout="vertical">
      <Row gutter={8}>
        <Col span={12}>
          <Visibility visible={selectType === "ode"}>
            <Form.Item
              rules={[{ required: true }]}
              name="ode"
              label="Compañía">
              <GqlSelect
                dataKey="oDEs"
                placeholder="Selecciona una compañía"
                gqlQuery={getOdesByBatchId}
                queryVariables={{ id: batchId }} />
            </Form.Item>
          </Visibility>
          <Visibility visible={selectType === "batch"}>
            <Form.Item
              rules={[{ required: true }]}
              name="batch"
              label="Batch">
              <GqlSelect
                dataKey="batches"
                placeholder="Selecciona un batch"
                gqlQuery={getOdeInvolvedBatches}
                queryVariables={{ id: odeId }} />
            </Form.Item>
          </Visibility>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true }]}
            name="period"
            label="Periodo de la actualización">
            <DatePicker
              picker="month"
              format="MMMM [de] YYYY"
              className="wide-input" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        rules={[{ required: true }]}
        name="currentGoal"
        label="Objetivo actual">
        <Input.TextArea
          rows={5}
          placeholder="Describe el objetivo actual" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name="productProgress"
        label="Mis avances de producto">
        <Input.TextArea
          rows={5}
          placeholder="¿Cuáles son tus logros de producto?" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name="businessProgress"
        label="Mis avances de negocio">
        <Input.TextArea
          rows={5}
          placeholder="¿Cuáles son tus logros de negocio?" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name="nextSteps"
        label="Próximos planes">
        <Input.TextArea
          rows={5}
          placeholder="¿Qué te gustaría alcanzar en 1 mes?" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name="issues"
        label="Problemas">
        <Input placeholder="¿Cuáles son tus desafíos?" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name="satisfactionRate"
        label="Satisfacción del programa">
        <Rate />
      </Form.Item>
    </Form>
  );
}