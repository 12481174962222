export function ignoreDuplicatedWorkshops(scheduledExpertServices = []) {
  // WORKAROUND: an intermediate refetch step give us a null assigned service
  // but it's just one time while re-rendering.
  // @todo Investigate why is it happening and fix it to avoid drastic
  // empty table
  if (scheduledExpertServices.some(s => s.assignedExpertService === null)) {
    return [];
  }

  return scheduledExpertServices
    ?.reduce((accServices, currService) => {
      const {
        assignationIdentifier
      } = currService?.assignedExpertService ?? {};

      const {
        type
      } = currService?.assignedExpertService?.expertServiceData ?? {};

      if (type !== "WORKSHOP") {
        accServices.push(currService);
        return accServices;
      }

      const alreadyIncluded = accServices
        .some(({ assignedExpertService }) => (
          assignedExpertService?.assignationIdentifier === assignationIdentifier
        ));

      if (alreadyIncluded) {
        return accServices;
      }

      accServices.push(currService);
      return accServices;
    }, []);
}
