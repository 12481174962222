import React, { useMemo } from "react";
import { Select } from "antd";
import { useChartsDataContext } from "../../charts-data-provider";
import {
  getReadableValue
} from "../../../../../helpers";

export const CampusSelector = props => {
  const { campus, loadingCampus } = useChartsDataContext();

  const readableCampus = useMemo(() => {
    return campus?.map(rawCampusValue => ({
      value: rawCampusValue,
      label: getReadableValue(
        "EDUCATION_INSTITUTIONS",
        rawCampusValue, null
      )
    })) || [];
  }, [campus]);

  return (
    <Select
      {...props}
      placeholder="Campus"
      loading={loadingCampus}
      disabled={campus?.length < 1}
      options={readableCampus} />
  );
};
