import gql from "graphql-tag";

export const createOdesByImport = gql`
  mutation CreateOdesByImport($data: CreateOdesByImportInput!) {
    createOdesByImport(data: $data) {
      odesCreated {
        id
        name
      },
      odesFailed
    }
  }
`;