import React, { useContext } from "react";
import { Button, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ObjectiveItemContext } from "../../../contexts/shared/objective-item";
import cascadeDeleteAlert from "../../../contexts/shared/cascade-delete-modal";

export function Toolbar({ onSave, achievements }) {
  const {deleteObjective} = useContext(ObjectiveItemContext);

  const handleOnDelete = async () => {
    if(achievements.length > 0) return cascadeDeleteAlert('objetivo','logro');
    return Modal.confirm({
      okText:"Eliminar",
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: "Estas a punto de eliminar un objetivo\
        ¿Deseas continuar?",
      onOk: deleteObjective
    });
  };

  return (
    <div style={{textAlign: "right"}}>
      <Button
        type="link" onClick={ handleOnDelete }
        style={{ marginRight: "15px" }}>
        Eliminar
      </Button>
    </div>
  );
}
