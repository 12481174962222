import { getReadableValue } from "../../../../../helpers/select-options";
import {
  salesStageOptions
} from "../../../../shared/company-forms/general/select-options/sales-stage";

export function salesStageToChart (salesStage = []) {
  const labels = [];
  const datasets = [];

  salesStage.forEach((stage, idx) => {
    const rawLabel = stage["Company.companySalesStage"];
    const readableLabel = getReadableValue(
      salesStageOptions,
      rawLabel,
      rawLabel
    );

    labels.push(readableLabel);

    const data = new Array(salesStage.length);
    data.fill(0, 0, salesStage.length - 1);
    data[idx] = parseInt(stage["Company.companiesCount"]);

    datasets.push({
      label: readableLabel,
      data,
      backgroundColor: COLORS[idx],
      barThickness: 50
    });
  });

  return {
    labels,
    datasets
  };
}

const COLORS = [
  "#FEB7D6",
  "#D7B7F6",
  "#9FD9FE"
];
