import React, { useEffect, useState } from "react";
import { Space, Tabs } from "antd";
import { BatchSectionHeading } from "../../../../shared";
import { UsergroupAddOutlined, ShopOutlined } from "@ant-design/icons";
import { MembersFormExpert } from "./form";
import { ExpertServicesForm } from "./services-form";

export const BatchPublishExpert = ({
  batchMentors,
  onConnectMember,
  onDisconnectMember
}) => {
  const initialState = {
    showAddExpert: false,
    showAddServices: false
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(initialState);
  }, [batchMentors]);

  const handleClickExpert = (formName = "") => {
    setState(prevState => ({
      ...prevState,
      [formName]: true
    }));
  };

  return (
    <Space
      className="ExpertSection"
      direction="vertical">
      <BatchSectionHeading
        style={{ textAlign: "center" }}
        title="Expertos"
        subTitle="Aquí puedes ver a los mentores y sus servicios." />
      <Tabs defaultActiveKey="mentors">
        <Tabs.TabPane
          key="mentors"
          tab={<span><UsergroupAddOutlined /> Mentores</span>}>
          <MembersFormExpert
            showAddExpert={state.showAddExpert}
            handleClickExpert={handleClickExpert}
            batchMentors={batchMentors}
            onConnectMember={onConnectMember}
            onDisconnectMember={onDisconnectMember} />
        </Tabs.TabPane>
        <Tabs.TabPane
          key="services"
          tab={<span><ShopOutlined /> Servicios</span>}>
          <ExpertServicesForm
            showAddServices={state.showAddServices}
            handleClickExpert={handleClickExpert} />
        </Tabs.TabPane>
      </Tabs>
    </Space>
  );
};
