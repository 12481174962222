import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Input, Form, Select, DatePicker, Col, Row, Alert } from "antd";
import { Avatar, UploadButton } from "../../../../shared";
import { countries, states } from "../../../../../utils";
import { toFileList } from "../../../../../helpers";
import { EditProfileContext } from "../../../../../contexts/shared";
import { genders } from "../../../../../helpers/select-options";
import {
  userDecorator
} from "../../../../../decorators/admin/experts/show/user-decorator";
import { RoleGuard } from "../../../role-guard";

export function EditPersonalInfoForm({ form }) {
  const { data } = useContext(EditProfileContext);

  const [state, setState] = useState({
    profilePicUrl: undefined,
    countryId: undefined
  });

  useEffect(() => {
    const countryId = data?.user?.expertProfile?.location?.country || 0;

    form.setFieldsValue({
      ...data?.user,
      profilePicUrl: data?.user?.profilePicUrl ?
        toFileList([{ url: data?.user?.profilePicUrl }])
        : undefined,
      resume: data?.user?.expertProfile?.resume,
      birthday: data?.user?.birthday ? moment(data?.user?.birthday) : null,
      city: data?.user?.expertProfile?.location?.city,
      state: data?.user?.expertProfile?.location?.state,
      country: countryId ? parseInt(countryId) : undefined
    });

    setState({
      countryId: data?.user?.expertProfile?.location?.country,
      profilePicUrl: data?.user?.profilePicUrl
    });

  }, [data]);


  if(!data.user) {
    return (
      <Alert
        type="error"
        message="Ha ocurrido un error al cargar la información,
        por favor intenta de nuevo en unos segundos."
        showIcon />
    );
  }

  const decoratedUser = Object.assign(userDecorator, data.user);

  const getPhotoUrl = e => {
    const profilePicUrl = e?.file?.response?.imageUrl;

    setState(prevState => ({
      ...prevState,
      profilePicUrl
    }));

    return e.fileList;
  };

  const onCountryChange = countryId => {
    setState(prevState => ({
      ...prevState,
      countryId
    }));
  };

  const countryStates = states
    .filter(s => s.id_country == state.countryId)
    .map(s => ({ value: s.name, label: s.name }));

  const uploadButtonCustomRender = (_, file) => {
    if (file.status === "done" && file.percent == 100)
      return "Archivo cargado";

    if (file.status === "uploading")
      return `Subiendo archivo ${parseFloat(file.percent).toFixed(0) || 0}%`;

    return null;
  };

  return (
    <Form
      form={form}
      layout="vertical">
      <Row>
        <Col
          span={4}
          style={{ textAlign: "center" }}>
          <Form.Item>
            <Avatar
              size={120}
              src={state.profilePicUrl}>
              { decoratedUser.fullName }
            </Avatar>
          </Form.Item>
          <Form.Item
            name="profilePicUrl"
            getValueFromEvent={getPhotoUrl}>
            <UploadButton.Crop
              align="center"
              itemRender={uploadButtonCustomRender}
              type="primary"
              aspect={0.9}
              multiple={false}
              maxFiles={1}>
              Editar fotografía
            </UploadButton.Crop>
          </Form.Item>
        </Col>
        <Col
          offset={1}
          span={19}>
          <Row>
            <Col span={24}>
              <Form.Item
                rules={[{required: true}]}
                label="Nombre(s)"
                name="firstName">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                rules={[{required: true}]}
                label="Apellido paterno"
                name="middleName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[{required: true}]}
                label="Apellido materno"
                name="lastName">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                rules={[{required: true}]}
                label="Género"
                name="gender">
                <Select options={genders} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[{required: true}]}
                label="Fecha de nacimiento"
                name="birthday">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={12}>
              <Form.Item
                rules={[{required: true}]}
                label="Email"
                name="email">
                <Input
                  disabled
                  placeholder="Email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[{required: true}]}
                label="Teléfono"
                name="phone">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16]}>
            <Col span={8}>
              <Form.Item
                rules={[{required: true}]}
                label="País"
                name="country">
                <Select
                  options={countries.map(c => ({ value: c.id, label: c.name }))}
                  showSearch
                  onChange={onCountryChange}
                  optionFilterProp="label"
                  placeholder="Seleccionar país" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                rules={[{required: true}]}
                label="Estado"
                name="state">
                <Select
                  options={countryStates}
                  showSearch
                  optionFilterProp="label"
                  disabled={state.countryId === undefined}
                  placeholder="Seleccionar estado" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                rules={[{required: true}]}
                label="Ciudad"
                name="city">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <RoleGuard roles={["SUPER_ADMIN"]}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Contraseña"
                  name="password">
                  <Input type="password" />
                </Form.Item>
              </Col>
            </Row>
          </RoleGuard>
        </Col>
      </Row>
    </Form>
  );
}
