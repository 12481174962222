import gql from "graphql-tag";

export const getContentsByObjectiveId = gql`
  query GetContentsByObjectiveIdGetObjectiveContents($id: ID!) {
    objective(
      where: {
        id: $id
      }
    ) {
      id
      contents {
        id
        fileName
        url
        type
      }
    }
  }
`;
