import React, { useContext } from "react";
import { Steps, Alert } from "antd";
import { renderStatusSteps } from "./render-status-steps";
import { CurrentUserContext } from "../../../contexts/shared";
import {
  serviceStatusEnum as STATUS
} from "../../../helpers/constants/service-status-enum";
import { cancelledByEnum as CANCELLED_BY } from "../../../helpers";

export function StatusSteps({
  scheduledExpertService,
  onCancelRequest
}) {
  const { currentUser } = useContext(CurrentUserContext);

  const statusSteps = {
    SCHEDULED: 0, // by ODE
    ON_GOING: 1, // request accepted by expert
    DELIVERED: 2, // by expert
    ACCEPTED: 3, // ODE accepts the expert delivery
    AUTHORIZED_FOR_PAYMENT: 4,
    PAYMENT_IN_PROCESS: 5,
    PAID: 6
  };

  const getInfoMessage = (status, cancelledBy) => {
    if (status === STATUS.REJECTED && !cancelledBy)
      return "El servicio ha sido rechazado.";
    if (status === STATUS.CANCELLED && !cancelledBy)
      return "El servicio ha sido cancelado.";

    const reasons = {
      ODE: "la Compañía",
      EXPERT: "el experto",
      ADMIN: "un administrador"
    };

    if (status === STATUS.REJECTED && cancelledBy === CANCELLED_BY.EXPERT)
      return "La solicitud de servicio ha sido rechazada por el experto.";
    if (status === STATUS.DELIVERY_REJECTED && cancelledBy === CANCELLED_BY.ODE)
      return "La entrega del servicio ha sido rechazada por la Compañía.";
    if (status === STATUS.CANCELLED && cancelledBy)
      return `El servicio ha sido cancelado por ${reasons[cancelledBy]}.`;
  };

  if ([STATUS.CANCELLED, STATUS.REJECTED, STATUS.DELIVERY_REJECTED]
      .includes(scheduledExpertService?.status)) {

    const msg = getInfoMessage(
      scheduledExpertService?.status,
      scheduledExpertService?.cancelledBy);

    return (
      <Alert
        type="error"
        message={msg}
        showIcon />
    );
  }

  return (
    <Steps
      direction="vertical"
      size="small"
      current={statusSteps[scheduledExpertService?.status]}>
      {renderStatusSteps({
          scheduledExpertService,
          onCancelRequest: () => onCancelRequest(scheduledExpertService),
          systemRole: currentUser?.systemRole
        })}
    </Steps>
  );
}
