import gql from "graphql-tag";

export const getScheduledExpertServicesReport = gql`
  query GetScheduledExpertServicesReport(
    $where: ScheduledExpertServiceWhereInput
    $markAsPaid: Boolean = false
  ) {
    reportScheduledExpertServices(
      orderBy: updatedAt_DESC
      where: $where
      markAsPaid: $markAsPaid
    ) {
      url
    }
  }
`;
