import { client3 } from "../../../graphql";
import { getPersonExperienceAttendancesQuery } from "./graphql";

export async function getPersonExperienceAttendances ({
  personId,
  startDate,
  endDate
}) {
  const result = await client3.query({
    query: getPersonExperienceAttendancesQuery,
    fetchPolicy: "network-only",
    variables: {
      personId,
      startDate,
      endDate
    }
  });

  return result?.data?.personExperienceAttendances || [];
}