import React from "react";
import { richTextToHtml } from "./rich-text-to-html";
import { tableRowsToHtmlTable } from "./table-rows-to-html-table";

export const RENDERERS = {
  heading_1: (content, idx) => (
    <h1 key={`element-${idx}`}>
      {content?.heading_1?.rich_text?.[0]?.text?.content}
    </h1>
  ),
  heading_2: (content, idx) => (
    <h2 key={`element-${idx}`}>
      {content?.heading_2?.rich_text?.[0]?.text?.content}
    </h2>
  ),
  heading_3: (content, idx) => (
    <h3 key={`element-${idx}`}>
      {content?.heading_3?.rich_text?.[0]?.text?.content}
    </h3>
  ),
  heading_4: (content, idx) => (
    <h4 key={`element-${idx}`}>
      {content?.heading_4?.rich_text?.[0]?.text?.content}
    </h4>
  ),
  paragraph: (content, idx) => {
    if (content?.paragraph?.rich_text?.length < 1) return <br />;

    return (
      <div key={`element-${idx}`}>
        <br />
        {richTextToHtml(content?.paragraph?.rich_text, `element-${idx}`)}
      </div>
    );
  },
  table: content => {
    return tableRowsToHtmlTable({
      rows: content?.table_rows
    });
  },
  bulleted_list_item: (content, idx) => {
    return (
      <li>{richTextToHtml(content?.bulleted_list_item?.rich_text, idx)}</li>
    );
  },
  image: (content, idx) => {
    return (
      <div
        style={{
          display: "flex",
          alignContent: "center"
        }}
        key={`image-${idx}`}>
        <img width={"100%"} src={content?.image?.file?.url} />
      </div>
    );
  }
};
