import React, { useEffect, useContext } from "react";
import { SidebarLayout, Section } from "../../../../../components/shared";
import { Summary } from "../../../../../components/admin/batches/show/summary";
import {
  default as Navbar
} from "../../../../../components/admin/batches/show/navbar";
import {
  CoordinatorsList,
  FilterControls
} from "../../../../../components/admin/batches/show/coordinators";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import { admin } from "../../../../../graphql/admin";
import { shared } from "../../../../../graphql/shared";
import { useParams } from "react-router";
import { BatchDetailsContextProvider } from "../../../../../contexts/admin/batches/batch-details";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { InvitationModalProvider } from "../../../../../contexts/shared/invitation-modal";
import { ConnectUserToBatchProvider } from "../../../../../contexts/shared";
import { useQueryFilters } from "../../../../../helpers/use-query-filters";
import { useControlledPagination } from "../../../../../helpers";

export function CoordinatorsIndex() {
  const { id } = useParams();

  const { loading, error, data, refetch } = useQuery(
    shared.queries.getBatchCoordinators,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      client
    }
  );

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["experts"]
    });
  }, []);

  /* TODO: Create a batch/program detail context provider to set the layout and
   * load and manage the data */
  const {
    loading: batchLoading,
    error: batchError,
    data: batchData
  } = useQuery(
    admin.queries.getBatchById,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      client
    }
  );

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: {
      id: id
    }
  });

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 10
  });

  const onFilter = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  return (
    <BatchDetailsContextProvider>
      <InvitationModalProvider>
        <ConnectUserToBatchProvider systemRole={"COORDINATOR"}>
          <SidebarLayout>
            <Summary
              isLoading={batchLoading}
              error={batchError}
              data={batchData} />
            <Navbar selectedKeys={["coordinators"]} />
            <Section
              extra={<FilterControls onChange={onFilter} />}
              title="Coordinadores"
              spaced>
              <CoordinatorsList
                data={data}
                error={error}
                isLoading={loading}
                onChangePage={onPaginationChange}
                currentPage={currentPage} />
            </Section>
          </SidebarLayout>
        </ConnectUserToBatchProvider>
      </InvitationModalProvider>
    </BatchDetailsContextProvider>
  );
}
