import gql from "graphql-tag";

export const getUsersByEmail = gql`
  query GetUsersByEmail($emails: [String!]) {
    users(
      where: {
        email_in: $emails
      }
    ) {
      id
      email
    }
  }
`;
