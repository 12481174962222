import gql from "graphql-tag";

export const getExperiencesByCampusQuery = gql`
    query GetPersonExperienceAttendances(
        $campus: String
        $startDate: DateTime
        $endDate: DateTime
        $audienceTypes: [String]
    ) {
        personExperienceAttendances(
            criteria: {
                experienceCampus: $campus,
                startDate: $startDate,
                endDate: $endDate
                audienceTypes: $audienceTypes
            }
        ) {
            experience {
                name
                startDate
                experienceId
                involvementLevel
            }
            person {
                personId
            }
            personExperienceAudience
        }
    }
`;
