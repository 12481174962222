export function prune(value = "", props) {
  const { size = 100, end = "..." } = props;

  if(size >= value.length) {
    return value;
  }

  const words = value.split(" ");

  const prunedWords = [];

  for(
    let i = 0;
    (i < words.length && prunedWords.join(" ").length < size);
    i++
  ) {
    prunedWords.push(words[i]);
  }

  if(prunedWords.length === words.length) {
    return value;
  }

  return `${prunedWords.join(" ")} ${end}`;
}
