import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, InputNumber, Button, Alert } from "antd";
import { assign, cloneDeep } from "lodash";
import {
  UploadButton,
  UploadArea,
  Visibility,
  RoleGuard
} from "../../../components/shared";
import {
  expertServiceTypes,
  expertPaymentMethods,
  expertServiceCategories
} from "../../../helpers";
import { toFileList } from "../../../helpers";
import { LinksField } from "../../../components/shared/links-field";
import { numberFormatter } from "../../../utils";

export function ExpertServiceModal({
  onSave,
  onCancel,
  edit,
  newService,
  onDisableExpertService,
  form,
  expertMode,
  ...props
}) {
  const [state, setState] = useState({
    servicePaymentOptions: []
  });
  const [priceLabel, setPriceLabel] = useState("Precio");
  const [loading, setLoading] = useState(false);

  const mode = edit ? "EDIT" : "CREATE";

  useEffect(() => {
    if (edit) {
      form.setFieldsValue({
        ...edit,
        examples: toFileList(edit.examples)
      });

      loadPaymentOptions(edit.type);
    } else {
      loadPaymentOptions();
    }
  }, [edit]);

  const onOk = async () => {
    setLoading(true);
    try {
      await form.validateFields();
      let values = form.getFieldsValue();
      if (typeof edit?.index !== "undefined") {
        values.index = edit.index;
        // using cloneDeep to avoid mutating the edit object
        values = assign(cloneDeep(edit), values);
      }

      if (values?.examples?.length) {
        const examples = cloneDeep(values.examples);

        values.examples = {
          create: examples.map(i => ({
            fileName: i.name,
            url: i.response?.imageUrl,
            type: i.type
          })).filter(i => i.url)
        };

        if (typeof edit?.index !== "undefined") {
          values.examples.disconnect = getRemovedExamplesId(examples);
        }
      }

      if (values?.links?.length) {
        values.links = {
          set: values.links
        };
      }
      values.duration = String(values.duration);
      values.price = String(values.price);
      await onSave(values);
    }
    catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getRemovedExamplesId = (examples = []) => {
    return examples.filter(example => example.status === "removed")
      .map(example => ({
        id: example.id
      }));
  };

  const getExamplesValue = e => {
    return e.fileList;
  };

  const getPhotoUrl = e => {
    const coverPicUrl = e?.file?.response?.imageUrl;
    form.setFieldsValue({ coverPicUrl });
    return coverPicUrl;
  };

  const loadPaymentOptions = type => {
    const servicePaymentOptions = type === "MENTORSHIP" ?
      expertPaymentMethods.filter(m => m.value !== "FIXED_COST")
      : expertPaymentMethods;

    if (type === "MENTORSHIP") {
      form.setFieldsValue({ method: "HOURS" });
    }

    setState({ servicePaymentOptions });

    return type;
  };

  const onMethodSelectChange = value => {
    if (value === "HOURS") {
      setPriceLabel("Precio por hora");
    } else {
      setPriceLabel("Precio");
    }
  };

  const activeAssignedServices = (edit?.assignedExpertServices || [])
    .filter(service => service.availableUnits > 0);

  const showAlertExpertAssignation = !!activeAssignedServices.length;

  const modalAlertMessage = mode === "CREATE"
  ? "Una vez que hayas creado el producto ya no podrás editar su precio y tipo."
  : `El precio y tipo de producto no son editables, 
    si necesitas hacer cambios por favor consulta a tu coordinador.`;

  const showDisableServiceButton = (!expertMode && !newService)

  return (
    <Modal
      className="expert-service-modal"
      footer={[
        <Button
          disabled={loading}
          key="cancel"
          type="default"
          onClick={onCancel}>
          Cancelar
        </Button>,
        <Visibility
          key="reject"
          visible={showDisableServiceButton}>
          <Button
            data-cy="disable-service-button"
            disabled={loading}
            danger
            type="primary"
            onClick={() => onDisableExpertService({
              id: edit?.id,
              expertId: edit?.expert?.id
            })}>
            Deshabilitar
          </Button>
        </Visibility>,
        <Button
          disabled={loading}
          key="ok"
          type="primary"
          onClick={onOk}>
          Guardar
        </Button>
      ]}
      onCancel={onCancel}
      destroyOnClose
      title="Producto o servicio"
      {...props}>
      <Form
        form={form}
        layout="vertical">
        <RoleGuard roles={["ADVISER"]}>
          <Alert
            style={{ marginBottom: "1rem" }}
            message={modalAlertMessage}
            type="warning"
            showIcon />
        </RoleGuard>
        <Form.Item
          name="coverPicUrl"
          getValueFromEvent={getPhotoUrl}>
          <UploadArea.Crop
            multiple={false}
            maxFiles={1}
            aspect={360 / 160}>
            Foto de portada
          </UploadArea.Crop>
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="name"
          label="Nombre del producto o servicio">
          <Input />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="type"
          label="Tipo"
          getValueFromEvent={loadPaymentOptions}>
          <Select
            disabled={expertMode}
            data-cy="service-type-select"
            showSearch
            optionFilterProp="label"
            options={expertServiceTypes} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="category"
          label="Categoría">
          <Select
            data-cy="service-category-select"
            showSearch
            optionFilterProp="label"
            options={expertServiceCategories} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="method"
          label="Método de cobro">
          <Select
            disabled={expertMode}
            data-cy="service-payment-method-select"
            showSearch
            optionFilterProp="label"
            onChange={onMethodSelectChange}
            options={state.servicePaymentOptions} />
        </Form.Item>

        <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR"]}>
          {
            showAlertExpertAssignation && (
              <Alert
                message={`Este producto/servicio ya ha sido 
                asignado con un costo.`}
                type="warning"
                showIcon />
            )
          }
        </RoleGuard>

        <Form.Item
          rules={[{ required: true }]}
          name="price"
          label={priceLabel}>
          <InputNumber
            disabled={expertMode}
            className="wide-input"
            min={0}
            formatter={numberFormatter} />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descripción">
          <Input.TextArea />
        </Form.Item>
        <>
          <Form.Item
            name="examples"
            label="Galería de ejemplos"
            getValueFromEvent={getExamplesValue}>
            <UploadButton
              multiple
              listType="picture-card">
              Subir
            </UploadButton>
          </Form.Item>
          <Form.Item
            name="links"
            label="Enlaces">
            <LinksField />
          </Form.Item>
        </>
      </Form>
    </Modal>
  );
}
