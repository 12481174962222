import React from "react";
import { Avatar } from "antd";
import {
  CalendarOutlined,
  AlertOutlined,
  TeamOutlined
} from "@ant-design/icons";

export function NotificationIcon(type) {
  let icon;

  switch(type) {
    case "event":
      icon = <CalendarOutlined />;
      break;
      case "assign":
        icon = <TeamOutlined />;
        break;
    default:
      icon = <AlertOutlined />;
  }

  return (
    <Avatar icon={icon} />
  );
}
