import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  EntrepreneurSpiritIndex
} from "../../../pages/coordinator/entrepreneur-spirit";
import {
  EntrepreneurSpiritImportersIndex
} from "../../../pages/coordinator/entrepreneur-spirit-importers";

export const CoordinatorEntrepreneurSpiritRoutes = <Switch>
  <Route
    exact
    path="/v2/coordinator/entrepreneur-spirit"
    component={EntrepreneurSpiritIndex} />
  <Route
    exact
    path="/v2/coordinator/es-importer"
    component={EntrepreneurSpiritImportersIndex} />
</Switch>;
