import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  AssignationsSessionsIndex
} from "../../../pages/entrepreneur/sessions/assignations";
import {
  ScheduledSessionServicesIndex
} from "../../../pages/entrepreneur/sessions/scheduled";

export const EntrepreneurSessionsRoutes = <Switch>
  <Route
    path="/v2/entrepreneur/sessions/assignations"
    component={AssignationsSessionsIndex} />
  <Route
    path="/v2/entrepreneur/sessions/scheduled"
    component={ScheduledSessionServicesIndex} />
</Switch>;