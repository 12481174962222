import React, { useContext } from "react";
import { Space, Typography } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  CalendarOutlined,
  EnvironmentOutlined
} from "@ant-design/icons";
import {
  Avatar,
  IconLabel,
  Format
} from "../../../../shared";
import { EditProfileContext } from "../../../../../contexts/shared";
import {
  userDecorator
} from "../../../../../decorators/admin/experts/show/user-decorator";

/**
 * @deprecated Stop using this. Use SummaryEditSection instead. The
 * SummaryEditSection was introduced by the new Entrepreneur Onboarding feature.
 * @returns {JSX.Element}
 */
export const Details = () => {
  const { data } = useContext(EditProfileContext);
  const decoratedUser = Object.assign(userDecorator, data?.user);

  return (
    <Space direction="horizontal">
      <Avatar size={100} style={{ margin: "0 3rem" }}>
        {decoratedUser.profilePicUrl || decoratedUser.fullName}
      </Avatar>
      <Space direction="vertical">
        <Space direction="horizontal" size={20}>
          <Typography.Title
            level={4}
            style={{marginBottom: "0px"}}>
            {decoratedUser.fullNameInCaps}
          </Typography.Title>
          <Typography.Text
              strong
              style={{marginBottom: "0px"}}>
            {
              decoratedUser.userID
                ? `Folio: ${decoratedUser.userID}`
                : null
            }
          </Typography.Text>
        </Space>
        <Space direction="horizontal" size={20}>
          <IconLabel icon={<MailOutlined />}>
            <Format type="secondary">
              {decoratedUser.displayEmail}
            </Format>
          </IconLabel>
          <IconLabel icon={<PhoneOutlined />}>
            <Format type="secondary">
              {decoratedUser.displayPhone}
            </Format>
          </IconLabel>
          <IconLabel icon={<CalendarOutlined />}>
            <Format type="secondary">
              {decoratedUser.readableBirthday}
            </Format>
          </IconLabel>
          <IconLabel icon={<EnvironmentOutlined />}>
            <Format type="secondary">{decoratedUser.address}</Format>
          </IconLabel>
        </Space>
      </Space>
    </Space>
  );
};
