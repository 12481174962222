import React from "react";
import { View, Text, Image, StyleSheet } from "@react-pdf/renderer";

export const Header = ({ data }) => {
  return (
    <View style={styles.body}>
      <View>
        <Text style={styles.title}>Reporte de experto</Text>
        <Text style={styles.subTitle}>{ data?.date } </Text>
      </View>
      <Image
        style={styles.logo}
        src="/assets/EOSPURPLE@3x.png" />
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "23px"
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    marginBottom: 6
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#0000007A"
  },  
  logo: {
    width:"71px",
    height:"25px"
  }
});



