import React, { useContext, useState, useEffect } from "react";
import { Modal, Form, message, Tabs, Button } from "antd";
import { CreatePerkModalContext } from "../context";
import { DetailsForm } from "./details-form";
import { AssignmentForm } from "./assignment-form";
import { client } from "../../../../../graphql";
import { admin } from "../../../../graphql/admin";
import { shared } from "../../../../graphql/shared";
import { formatData } from "./format-data";
import { DetailsInfo } from "./details-info";
import { Visibility } from "../../../../components/shared";
import { CurrentUserContext } from "../../current-user";


export const CreatePerkModal = ({
  visible = false,
  viewMode = false,
  perk = {}
}) => {

  const initialState = {
    batches: [],
    odes: [],
    selectedProgram: ""
  };

  const initialTab = {
    activeKey: "details"
  };

  const [tab, setTab] = useState(initialTab);
  const [state, setState] = useState(initialState);

  const { onCancel, onCreate } = useContext(CreatePerkModalContext);
  const { currentUser } = useContext(CurrentUserContext);

  const [form] = Form.useForm();

  useEffect(() => {
    setState({
      batches: perk?.batches || [],
      odes: perk?.odes || []
    });
  }, [perk]);

  const setActiveTab = keyName => {
    setTab(prevState => ({
      ...prevState,
      activeKey: keyName
    }));
  };

  const onOk = async () => {
    await form.validateFields();
    if (state.batches.length > 0 && state.odes.length > 0) {
      const values = form.getFieldsValue();
      const data = { ...state, ...values };
      const formatedData = formatData(data, currentUser);
      onCreate(formatedData);
      onCancel();
      form.resetFields();
      setTab(initialTab);
      setState(initialState);
    } else {
      message.error("Los campos de asignación de la Compañía o Batch estan vacíos");
    }
  };

  const onClose = () => {
    onCancel();
    setTab(initialTab);
    form.resetFields();
    setState(initialState);
  };

  const saveAssignments = async (values, type) => {
    const query = await client.query({
      query: type === "odes"
        ? admin.queries.getOdes
        : shared.queries.getBatches,
      fetchPolicy: "network-only",
      variables: {
        where: { id_in: values }
      }
    });
    if (type === "odes") {
      setState(prev => ({
        ...prev,
        odes: prev.odes.concat(query?.data?.oDEs)
      }));
    } else {
      setState(prev => ({
        ...prev,
        batches: prev.batches.concat(query?.data?.batches)
      }));
    }
  };

  const removedAssigned = (id, type) => {
    if (type === "odes") {
      setState(prev => ({
        ...prev,
        odes: prev.odes.filter(ode => ode.id !== id)
      }));
    } else {
      setState(prev => ({
        ...prev,
        batches: prev.batches.filter(batch => batch.id !== id)
      }));
    }
  };

  const onProgramChange = id => {
    setState(prev => ({
      ...prev,
      selectedProgram: id
    }));
  };

  return (
    <Modal
      className="new-perk-modal"
      title="Nuevo Perk"
      footer={[
        <Button
          key="1"
          onClick={onClose}
          className="cancel">
          Cerrar
        </Button>,
        <Visibility key="2" visible={!viewMode}>
          <Button
            type="primary"
            className="submit"
            onClick={onOk}>
            Crear
          </Button>
        </Visibility>]}
      visible={visible}
      onCancel={onClose}
      onOk={onOk}
      okText="Crear"
      width={800}>
      <Tabs
        onTabClick={tabKeyName => setActiveTab(tabKeyName)}
        defaultActiveKey="details"
        activeKey={tab.activeKey}>
        <Tabs.TabPane tab="Detalles" key="details">
          <Form
            form={form}
            id="create-perk-form"
            layout="vertical">
            {viewMode
              ? <DetailsInfo perk={perk} />
              : <DetailsForm onChange={onProgramChange} />}
          </Form>
        </Tabs.TabPane>
        {
          !["ENTREPRENEUR"].includes(currentUser.systemRole)
            ? <Tabs.TabPane
              tab="Asignación"
              key="assignment"
              id="perk-assignment">
              <AssignmentForm
                odes={state.odes}
                batches={state.batches}
                selectedProgram={state.selectedProgram}
                viewMode={viewMode}
                saveAssignments={saveAssignments}
                removedAssigned={removedAssigned} />
            </Tabs.TabPane>
            : null
        }
      </Tabs>
    </Modal>
  );
};
