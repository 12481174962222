import React, {useContext} from "react";
import moment from "moment";
import { Alert, Descriptions, Typography } from "antd";
import {
  EditButton,
  FlexContainer,
  ParagraphSkeleton,
  Visibility
} from "../../../../";
import {
  EditCompanyContext
} from "../../../../../../contexts/shared/edit-company";
import { getReadableValue } from "../../../../../../helpers/select-options";
import {
  salesStageOptions
} from "../../../../company-forms/general/select-options/sales-stage";
import {
  productStageOptions
} from "../../../../company-forms/general/select-options/product-stage";
import {
  financingStageOptions
} from "../../../../company-forms/general/select-options/financing-stage";
import {
  fiscalStateOptions
} from "../../../../company-forms/general/select-options/fiscal-state";

export function GeneralInformation({
  visible,
  loading = false,
  data = {
    salesStage: "",
    productStage: "",
    financingStage: "",
    businessModel: "",
    fiscalState: "",
    fiscalEntryDate: "",
    businessName: "",
    rfc: "",
    fiscalRepresentative: ""
  },
  allowEdit
}) {
  const { onEdit } = useContext(EditCompanyContext);

  data = data || {};

  if (loading) {
    return <ParagraphSkeleton paragraphs={1} />;
  }

  return (
    <div className="CompanyGeneralInfo">
      <Visibility visible={visible}>
        <Alert
          showIcon
          type="warning"
          message="Tienes campos obligatorios sin llenar." />
      </Visibility>
      <FlexContainer
        style={{margin: "1rem 0"}}
        direction="horizontal"
        justifyContent="space-between">
        <Typography.Title level={4}>
          Información general
        </Typography.Title>
        <EditButton visible={allowEdit} onClick={() => onEdit("GENERAL")}>
          Editar
        </EditButton>
      </FlexContainer>
      <Descriptions
        colon={false}
        style={{gap: ".25rem"}}
        labelStyle={{color: "#8A939C"}}
        contentStyle={{
          color: "#586472",
          fontsize: "16px",
          fontWeight: "bold",
          paddingBottom: "16px"
        }}
        layout="vertical">
        <Descriptions.Item label="Etapa de ventas">
          {getReadableValue(salesStageOptions, data?.salesStage, "")}
        </Descriptions.Item>
        <Descriptions.Item label="Etapa del producto">
          {getReadableValue(productStageOptions, data?.productStage, "")}
        </Descriptions.Item>
        <Descriptions.Item label="Etapa de financiación">
          {getReadableValue(financingStageOptions, data?.financingStage, "")}
        </Descriptions.Item>
        <Descriptions.Item label="Modelo de negocio">
          {data?.businessModel}
        </Descriptions.Item>
        <Descriptions.Item label="Situación fiscal">
          {getReadableValue(fiscalStateOptions, data?.fiscalState, "")}
        </Descriptions.Item>
        {data?.fiscalEntryDate && (
          <Descriptions.Item
            contentStyle={{textTransform: "capitalize"}}
            label="Fecha de alta fiscal">
            {moment(data?.fiscalEntryDate).format("MMMM, YYYY")}
          </Descriptions.Item>
        )}
        {data?.businessName?.replace("N/A", "") && (
          <Descriptions.Item label="Razón social">
            {data?.businessName}
          </Descriptions.Item>
        )}
        {data?.fiscalId?.replace("N/A", "") && (
          <Descriptions.Item label="RFC/TAX ID">
            {data?.fiscalId}
          </Descriptions.Item>
        )}
        {
          (data?.fiscalState === "MORAL_PERSON" && data?.fiscalRepresentative)
          &&
          (
            <Descriptions.Item label="Representante legal">
              {data?.fiscalRepresentative}
            </Descriptions.Item>
          )
        }
      </Descriptions>
    </div>
  );
}
