import React from "react";
import {
  Modal,
  Tag,
  Typography,
  Descriptions,
  Alert,
  Form,
  message
} from "antd";
import { AssignExpertServiceForm } from "./form";
import {
  expertServiceCategories,
  expertServiceTypes,
  expertPaymentMethods
} from "../../../../helpers/select-options/expert";
import {
  getReadableValue
} from "../../../../helpers/select-options/get-readable-value";
import { numberFormatter } from "../../../../utils";
import { ContactInfo, Visibility } from "../../../../components/shared";

export function AssignedExpertServiceModal({
  onSave, onCancel, expertService, ...props
}) {
  const [form] = Form.useForm();

  const handleOnOk = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      values.serviceType = expertService.type;

      const invalidAssigment = values?.oDEs.some(el =>
        [0, "0"].includes(el.assignedUnits));

      if (invalidAssigment)
        return message.warn("La cantidad de horas a asignar no puede ser 0");
      values.metadata = {
        expertService
      };
      onSave && onSave(values);
      form.resetFields();
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnCancel = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  const getModalTitle = () => {
    return getReadableValue(expertServiceTypes, expertService?.type)
      .toLowerCase();
  };

  /* Use data of expert service when was assigned */
  if(expertService?.expertServiceData) {
    expertService = expertService.expertServiceData;
  }

  return (
    <Modal
      destroyOnClose
      className="assigned-expert-service-modal"
      okText={"Asignar"}
      cancelText="Cancelar"
      title={`Asignación de ${getModalTitle()}`}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      style={{ minWidth: "700px" }}
      {...props}>
      <Typography.Title level={3}>
        {expertService?.name}
      </Typography.Title>
      <Typography.Text>
        {expertService?.description}
      </Typography.Text>
      <ContactInfo
        style={{ marginTop: "20px" }}
        title={`Experto: ${expertService?.expert?.fullName}`}
        user={expertService?.expert} />
      <Visibility visible={expertService?.type === "WORKSHOP"}>
        <Alert
          type="info"
          showIcon
          message="El consumo y la asignación es grupal. Todas las compañías del
            programa tomarán la capacitación el mismo día." />
      </Visibility>
      <Descriptions
        layout="vertical"
        column={1}
        style={{ marginTop: "20px" }}>
        <Descriptions.Item label="Categoría">
          <Tag color="purple">
            {getReadableValue(expertServiceCategories, expertService?.category)}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Costo">
          {numberFormatter(expertService?.price || 0)} /
          &nbsp;
          {getReadableValue(expertPaymentMethods, expertService?.method)}
        </Descriptions.Item>
      </Descriptions>
      <AssignExpertServiceForm
        form={form}
        expertService={expertService} />
    </Modal>
  );
}
