import React, { useContext } from "react";
import { Form } from "antd";
import { SidebarLayout, Section } from "../../../../../components/shared";
import { StepsWrapper } from "../../../../../components/expert/shared";
import { CurrentUserProvider } from "../../../../../contexts/shared/current-user";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { AccountsForm } from "../../../../../components/shared/profile-forms";
import { formatValues } from "../../../../../components/shared/profile-forms/accounts/section/format-values";
import { EditProfileProvider, EditProfileContext } from "../../../../../contexts/shared/edit-profile";
import { withRouter } from "../../../../../helpers/with-router";
import { PageLeavePromptProvider } from "../../../../../contexts/shared";

function AccountsIndex({ match, navigate }) {
  const { setMenuState } = useContext(MenuContext);

  const expertId = match?.params?.id;
  const [accountsForm] = Form.useForm();

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/profiles"]
  });

  const onNext = saveChanges => async function(event) {
    event.preventDefault();
    await accountsForm.validateFields();

    try {
      const values = formatValues(accountsForm.getFieldsValue());
      await saveChanges(values);
      navigate(`/v2/coordinator/experts/new/${expertId}/entrepreneurships`);
    } catch(error) {
      console.error(error);
    }
  };

  return (
    <PageLeavePromptProvider>
      <EditProfileProvider userId={expertId}>
        <EditProfileContext.Consumer>
          {({ saveChanges, editing, loading }) => (
            <CurrentUserProvider>
              <SidebarLayout>
                <StepsWrapper
                  currentStep={2}
                  prevUrl={`/v2/coordinator/experts/new/${expertId}/professional-information`}
                  onNext={onNext(saveChanges, editing)}>
                  <Section
                    loading={loading}
                    spaced
                    title="Experiencia laboral">
                    <AccountsForm
                      editable
                      form={accountsForm} />
                  </Section>
                </StepsWrapper>
              </SidebarLayout>
            </CurrentUserProvider>
          )}
        </EditProfileContext.Consumer>
      </EditProfileProvider>
    </PageLeavePromptProvider>
  );
}

export default withRouter(AccountsIndex);
