import { client } from "../../../../graphql";
import { admin } from "../../../graphql/admin";

/**
 * @deprecated Use the current pure functions approach instead
 */
export class AssignedExpertServiceService {
  constructor() {
    this.client = client;
  }

  /**
   * @deprecated We should move this logic to the server side to avoid
   * errors and excesive server calls
   * @param {*} assignedServices
   */
  async createMany(assignedServices = []) {
    const hasMissedAssignationIdentifier = assignedServices
      .some(assignedService => !assignedService.assignationIdentifier);

    if (hasMissedAssignationIdentifier) {
      throw new Error("Missed assignationIdentifier field");
    }

    const promises = assignedServices.map(async data => (
      this.client.mutate({
        mutation: admin.mutations.createAssignedExpertService,
        variables: { data }
      })
    ));

    return await Promise.all(promises);
  }

  async update(data) {
    const id = data.id;
    delete data.id;

    return await client.mutate({
      mutation: admin.mutations.updateAssignedExpertService,
      awaitRefetchQueries: true,
      refetchQueries: [{ query: admin.queries.getAssignedServices }],
      variables: {
        data,
        id
      }
    });
  }

  async createOrUpdate(data) {
    try {
      return await this.create(data);
    } catch (e) {
      console.error(e);
    }
  }
}
