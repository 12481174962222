import React from "react";
import { Switch, Route } from "react-router-dom";
import { PersonalInformationIndex } from "../../../pages/coordinator/onboarding/personal-information";
import { SuccessIndex } from "../../../pages/coordinator/onboarding/success";

export const CoordinatorOnboardingRoutes = (
  <Switch>
    <Route
      path="/v2/coordinator/onboarding/personal-information"
      component={PersonalInformationIndex} />
    <Route
      path="/v2/coordinator/onboarding/success"
      component={SuccessIndex} />
  </Switch>
);