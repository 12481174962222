import { List, Typography } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import React from "react";
import { getClickableLink } from "../../../helpers";

export function LinksDisplay({ links }) {
  return (
    <List
      size="small"
      dataSource={links}
      renderItem={l => <List.Item key={l}>
        <Typography.Link
          href={getClickableLink(typeof l === "string" ? l : l.url)}
          target="_blank">
          <LinkOutlined /> {typeof l === "string" ? l : (l.fileName || l.url)}
        </Typography.Link>
      </List.Item>}>
    </List>
  );
}
