import React from "react";
import { Bar } from "react-chartjs-2";
import { ChartCard } from "../../chart-cards";
import { getCampusAttendancesChartConfig } from "./helpers";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  registerables,
  Title,
  Tooltip as ChartJsTooltip
} from "chart.js";

ChartJS.register(
  ...registerables,
  Title,
  Legend,
  ChartJsTooltip,
  BarElement,
  LinearScale,
  LineElement,
  CategoryScale
);

export const CampusAttendancesChart = ({
  experiences = [],
  nationalAttendances
}) => {
  const {
    data,
    options
  } = getCampusAttendancesChartConfig({
    nationalAttendances,
    attendances: experiences
  });

  return (
    <ChartCard
      style={{ width: "50%" }}
      title="Experiencias por nivel de involucramiento"
      chartInfo={`Total de las experiencias producidas por el campus
      seleccionado, por nivel de involucramiento, comparadas con la cantidad
      a nivel nacional.`}>
      <Bar
        type="bar"
        data={data}
        height={400}
        options={options} />
    </ChartCard>
  );
};