import React, { useContext } from "react";
import { Menu } from "antd";
import { MenuTitle } from "../../../shared";
import {
  BookOutlined, CalendarOutlined, StarOutlined, GlobalOutlined, CoffeeOutlined
} from "@ant-design/icons";
import { withRouter } from "../../../../helpers";
import { MenuContext } from "../../../../contexts/shared";

function MainMenu({ navigate }) {
  const { openKeys, selectedKeys } = useContext(MenuContext);

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      selectedKeys={selectedKeys}>
      <Menu.SubMenu
        onTitleClick={() => navigate("/v2/entrepreneur/batches")}
        title={<MenuTitle text="Programas" icon={<GlobalOutlined />} />}
        key="programs">
        <Menu.Item
          key="batches"
          onClick={() => navigate("/v2/entrepreneur/batches")}>
          Batches
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item
        key="odes"
        onClick={() => navigate("/v2/entrepreneur/odes")}>
        <MenuTitle text="Compañías" icon={<StarOutlined />} />
      </Menu.Item>
      <Menu.SubMenu
        onTitleClick={() => navigate("/v2/entrepreneur/experts")}
        title={<MenuTitle text="Expertos" icon={<BookOutlined />} />}
        key="experts">
        <Menu.Item
          key="experts/profiles"
          onClick={() => navigate("/v2/entrepreneur/experts")}>
          Perfiles
        </Menu.Item>
        <Menu.Item
          key="experts/services"
          onClick={() => navigate("/v2/entrepreneur/experts/services")}>
          Asignados
        </Menu.Item>
        <Menu.Item
          key="experts/services/monitoring"
          onClick={() =>
            navigate("/v2/entrepreneur/experts/services/monitoring")
          }>
          Monitoreo
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item
        key="entrepreneur/calendar"
        onClick={() => navigate("/v2/entrepreneur/calendar")}>
        <MenuTitle text="Calendario" icon={<CalendarOutlined />} />
      </Menu.Item>
      {/*
      @todo restore when sessions epic is ready to be launched
      <Menu.Item
        key="entrepreneur/sessions"
        onClick={() => navigate("/v2/entrepreneur/sessions/assignations")}>
        <MenuTitle text="Sesiones" icon={<CoffeeOutlined />} />
      </Menu.Item> */}
    </Menu>
  );
}

export default withRouter(MainMenu);

