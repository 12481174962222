import { useState } from "react";

export function useControlledPagination({ initialPageSize } = {}) {
  const [state, setState] = useState({
    currentPage: 1,
    pageSize: initialPageSize,
    skip: 0
  });

  /**
   * Handles the pagination state and values
   * @param {number} currentPage
   * @param {number | undefined} pageSize If not passed, the hook will use
   * the last pageSize in the state
   * @returns The most recent fields for pagination.
   * If pageSize is undefined, it will return the last updated pageSize
   */
  const onChange = (currentPage = 1, pageSize) => {
    const updatedState = {
      currentPage,
      pageSize: pageSize || state.pageSize,
      skip: (currentPage - 1) * pageSize
    };

    setState(updatedState);

    return updatedState;
  };

  return {
    currentPage: state.currentPage,
    pageSize: state.pageSize,
    skip: state.skip,
    onPaginationChange: onChange
  };
}
