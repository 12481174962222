/**
 * @typedef Dataset
 * @property {number[]} data
 * @property {string} label
 * @property {string} backgroundColor
 */

/**
 * @param {Dataset[]} datasets
 * @param {string[]}labels
 * @returns {*[]}
 */
export function generateSortingGuide(datasets, labels) {
  const iterations = datasets[0].data.length;
  const length = datasets.length;
  const sums = [];
  for(let i = 0; i < iterations; i++) {
    let sum = 0;
    for(let h = 0; h < length; h++) {
      sum += datasets[h].data[i] || 0;
    }
    sums.push({total: sum, sortedIndex: i, label: labels[i]});
  }

  return sums.sort((a, b) => b.total - a.total).filter(sum => {
    return sum.total !== 0;
  });
}

/**
 * @param {Dataset[]} datasets
 * @param {{ total: number, label: string, sortedIndex: number }[]}sortedGuide
 * @returns {*[]}
 */
export function sortDatasetsData(datasets, sortedGuide) {
  const newDatasets = [];

  sortedGuide.forEach(sum => {
    for(let i = 0; i < datasets.length; i++) {
      const dataset = datasets[i];
      const sortedDataset = newDatasets[i] || {};
      const sortedData = sortedDataset.data || [];
      sortedData.push(dataset.data[sum.sortedIndex]);
      newDatasets[i] = {
        ...dataset,
        data: sortedData
      };
    }
  });

  return newDatasets;
}

export const OPTIONS = {
  plugins: {
    legend: {
      position: "bottom"
    },
    datalabels: {
      color: "#000",
      labels: {
        title: {
          text: "Servicios por etapa"
        }
      }
    }
  },
  maintainAspectRatio: false,
  indexAxis: "y",
  x: {
    stacked: true
  },
  y: {
    stacked: true
  }
};
