import {
  SidebarLayout,
  Section,
  AddButton
} from "../../../components/shared";
import {
  withRouter,
  useQueryFilters,
  useControlledPagination
} from "../../../helpers";
import {
  InvitationModalProvider
} from "../../../contexts/shared";
import { useService } from "../../../hooks/shared";
import {
  CurrentUserContext, PaginationContext, MenuContext
} from "../../../contexts/shared";
import {
  FilterControls
} from "../../../components/admin/experts/list/filter-controls";
import { AdminExpertsContext } from "../../../contexts/admin/experts";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { default as ExpertsList } from "../../../components/admin/experts/list";
import {
  ExpertServiceContext
} from "../../../contexts/experts/new/service/context";

function ExpertsIndex({navigate}) {
  const { setMenuState } = useContext(MenuContext);
  const { currentWorkspace } = useContext(CurrentUserContext);
  const { ExpertsService } = useContext(ExpertServiceContext);

  /* set menu's current state */
  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/profiles"]
  });

  const [refetchVariables, setRefetchVariables] = useState({
    first: 10,
    systemRole: "ADVISER"
  });

  const { refetch, data , error, loading } = useService(
    ExpertsService.getExperts, { ...refetchVariables, first: 10 }
  );

  const { registerRefetch } = useContext(PaginationContext);
  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 10
  });

  registerRefetch("experts", refetch, refetchVariables);

  useEffect(() => {
    if(currentWorkspace.id) {
      setRefetchVariables({ ...refetchVariables, workspace: {
          workspace: {
            id: currentWorkspace.id
          }
        } });
    }
  }, [currentWorkspace]);

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: {
      first: 10,
      systemRole: "ADVISER"
    }
  });

  const onFilter = async (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);

    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));

    setRefetchVariables(prev => ({ ...prev, ...filter }));
  };

  const onClickExpert = expert => {
    navigate(`/v2/admin/experts/${expert.id}/profile`);
  };

  const injectActions = useMemo(() => ({
    error,
    loading,
    total: data?.data?.searchUsers?.count || 0,
    data: data?.data?.searchUsers?.users || []
  }), [data, error, loading]);

  return (
    <InvitationModalProvider>
      <AdminExpertsContext.Provider value={injectActions}>
        <SidebarLayout>
          <Section
            spaced
            title="Expertos"
            extra={
              <FilterControls onChange={onFilter} />
            }>
            <AddButton
              onClick={() =>
                navigate("/v2/admin/experts/new/personal-information")}
              style={{width: "100%"}}>
              Agregar experto
            </AddButton>
            <ExpertsList
              onClickExpert={onClickExpert}
              onChangePage={onPaginationChange}
              currentPage={currentPage} />
          </Section>
        </SidebarLayout>
      </AdminExpertsContext.Provider>
    </InvitationModalProvider>
  );
}

export default withRouter(ExpertsIndex);