import React from "react";
import { Modal, Form } from "antd";
import { UsersSelect } from "../../../components/shared";
import {
  getReadableValue,
  systemRoleTypes
} from "../../../helpers/select-options";

export function ConnectUserModal({
  onSave,
  onCancel,
  systemRole,
  filterUsers,
  batchId,
  ...props
}) {
  const [form] = Form.useForm();

  const onOk = async() => {
    const values = await form.validateFields(["users"]);

    onSave && onSave(values);
    form.resetFields();
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  const systemRoles = [systemRole];

  if (systemRole === "COORDINATOR") {
    systemRoles.push("SUPER_ADMIN"); // admins can be coordinators
  }

  return (
    <Modal
      okText="Guardar"
      cancelText="Cancelar"
      title={`Añadir \
        ${getReadableValue(systemRoleTypes, systemRole)?.toLowerCase()}`}
      onOk={onOk}
      onCancel={onCancelModal}
      {...props}>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          label="Selecciona los usuarios que quieras asignar"
          name="users">
          <UsersSelect
            filterUsers={filterUsers}
            systemRoles={systemRoles}
            batchId={batchId} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
