import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import {
  default as Navbar
} from "../../../../../components/entrepreneur/batches/show/navbar";
import { Summary } from "../../../../../components/admin/batches/show";
import { MainMenu } from "../../../../../components/entrepreneur/shared";
import { SidebarLayout } from "../../../../../components/shared";
import { BatchDetailsContextProvider } from "../../../../../contexts/admin/batches";
import { MenuContext } from "../../../../../contexts/shared";
import { CreateAnnouncementModalProvider } from "../../../../../contexts/shared/create-annoucement-modal/provider";
import { AnnouncementsSection } from "../../../../../components/shared/announcements-section";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql";

export const AnnouncementsIndex = () => {
  const { id } = useParams();
  const { setMenuState } = useContext(MenuContext);

  const { data, loading, error, refetch } = useQuery(
    shared.queries.getAnnouncements,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          batch: {
            id
          }
        }
      },
      client
    }
  );

  setMenuState({
    openKeys: ["programs"],
    selectedKeys: ["batches"]
  });

  return (
    <BatchDetailsContextProvider>
      <CreateAnnouncementModalProvider batchId={id}>
        <SidebarLayout menu={<MainMenu />}>
          <Summary />
          <Navbar selectedKeys={["announcements"]} />
          <AnnouncementsSection
            refetch={refetch}
            data={data}
            error={error}
            loading={loading} />
        </SidebarLayout>
      </CreateAnnouncementModalProvider>
    </BatchDetailsContextProvider>
  );
};
