import React from "react";
import { RENDERERS } from "./renderers";

/**
 * Transforms the Content in JSON Format to html.
 * Only the content block types specified in the RENDERERS collection will
 * be rendered.
 * Blocks that are not in the collection will appear empty. You can add more
 * renderers per block type as you need.
 */
export const renderDocs = docs => {
  if (!docs)
    return null;

  return (
    <div style={{ padding: "10px" }}>
      <a
        href={docs?.page?.url}
        target="_blank"
        rel="noopener noreferrer">
        <h1>
          {docs?.page?.properties?.Name?.title?.[0]?.plain_text}
        </h1>
      </a>
      {docs?.page?.children?.map((childContent, idx) => {
        const render = RENDERERS[childContent.type] || (() => <br />);
        return render(childContent, idx);
      })}
    </div>
  );
};
