import gql from "graphql-tag";

export const deleteDeliverableByFileId = gql`
  mutation DeleteDeliverableByFileId($id: ID!) {
    deleteFile(
      where:{ id: $id }
    ){
      id
    }
  }
`;
