import React, { useContext, useState } from "react";
import { Alert, Modal, Form, Input, Button } from "antd";
import ReactQuill from "react-quill";
import { DeleteOutlined } from "@ant-design/icons";
import { UploadButton, UploadSection } from "../../../../components/shared";
import { CurrentUserContext } from "../../current-user";
import "react-quill/dist/quill.snow.css";
import { formatValues } from "./format-values";

export const CreateAnnouncementModal = ({
  visible = false,
  loading,
  sendAnnouncement,
  closeModal
}) => {

  const initialState = {
    image: undefined,
    text: "",
    files: []
  };

  const { currentUser } = useContext(CurrentUserContext);

  const [form] = Form.useForm();
  const [data, setData] = useState(initialState);

  const onImageChange = e => {
    setData(state => ({
      ...state,
      image: e?.url || undefined
    }));
  };

  const onFileChange = e => {
    setData(state => ({
      ...state,
      files: e.fileList
    }));
  };

  const onRemoveFile = e => {
    setData(state => ({
      ...state,
      files: data.files.filter(file => file.uid !== e.uid)
    }));
  };

  const deleteImage = () => {
    setData(state => ({
      ...state,
      image: undefined
    }));
  };

  const setText = value => {
    setData(state => ({
      ...state,
      text: value
    }));
  };

  const onSend = () => {
    const { title } = form.getFieldsValue();
    const formatedValues = formatValues({ ...data, title }, currentUser);
    sendAnnouncement(formatedValues, onClose);
  };

  const onClose = () => {
    form.resetFields();
    setData(initialState);
    closeModal();
  };

  return (
    <Modal
      title="Nuevo aviso"
      width={841}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button
          key="cancel"
          onClick={onClose}>
          Cancelar
        </Button>,
        <Button
          type="primary"
          key="send"
          loading={loading}
          onClick={onSend}>
          Enviar
        </Button>
      ]}>
      <Form
        form={form}
        layout="vertical">
        <Alert
          style={{ backgroundColor: "#5F39DE0D", border: "1px solid #5F39DE" }}
          message="El aviso será enviado a cada Compañía de tu batch." />
        <br />
        <Form.Item
          rules={[{ required: true }]}
          label="Asunto"
          name="title">
          <Input
            placeholder="Escribe el asunto del aviso" />
        </Form.Item>
        <Form.Item
          label="Imagen de apoyo">
          {
            !data.image
            ? <UploadSection
              onChange={onImageChange}
              showUploadList={false}>
              Adjuntar archivos
              <br />
              <span>Puedes adjuntar：.pdf .jpg</span>
            </UploadSection>
          : <ImagePreview
            image={data.image}
            onDelete={deleteImage} />
          }
        </Form.Item>
        <Form.Item
          required={true}
          label="Mensaje">
          <ReactQuill
            style={{ height: "220px" }}
            theme="snow"
            value={data.text}
            onChange={setText} />
        </Form.Item>
        <Form.Item style={{ paddingTop: "40px" }}>
          <UploadButton
            listType="custom"
            value={data.files}
            onChange={onFileChange}
            onRemove={onRemoveFile}>
            <Button
              color="primary"
              style={{ width: "793px" }}>
              Agregar documento de apoyo</Button>
          </UploadButton>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ImagePreview = ({ image, onDelete }) => {
  return (
    <div
      style={{
        width: "100%",
        height:"400px",
        backgroundImage: `url(${image})`
      }}>
      <Button
        shape="circle"
        icon={<DeleteOutlined />}
        style={{
          position: "absolute",
          right: "15px",
          top: "15px"
        }}
        onClick={onDelete} />
    </div>
  );
};
