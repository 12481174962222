import React from "react";
import { Card, Divider, Tooltip } from "antd";
import { PictureOutlined } from "@ant-design/icons";
import { renderOptions } from "./render-options";
import {
  FlexContainer,
  Visibility,
  CoverImage,
  Format
} from "../../index";

/**
 * List Item in Card mode. Ideal for grid lists.
 * @param onRemove
 * @param onDelete
 * @param onEdit
 * @param onDuplicate
 * @param removeOptionLabel
 * @param deleteOptionLabel
 * @param editOptionLabel
 * @param duplicateOptionLabel
 * @param onClick
 * @param titleMaxSize
 * @param {object} props
 * @param {string} props.title
 * @param {React.ReactNode} props.customOptionsComponent
 * @param {React.ReactNode} props.extra
 * @param {React.ReactNode[]} props.footerItems
 * @param {string} props.coverUrl
 * @param {object} props.style
 * @param {object} props.extraActions { label: string, action: function }[]
 * @param {any} children
 * @returns
 */
export function CardListItem({
  title,
  customOptionsComponent,
  extra,
  footerItems = [],
  children,

  coverUrl,
  style = {},

  onRemove,
  onDelete,
  onEdit,
  onDuplicate,
  removeOptionLabel = "Remover",
  deleteOptionLabel = "Eliminar",
  editOptionLabel = "Editar",
  duplicateOptionLabel = "Duplicar",

  extraActions,
  onClick,
  titleMaxSize,
  ...props
}) {
  const labels = {
    onRemove: removeOptionLabel,
    onDelete: deleteOptionLabel,
    onEdit: editOptionLabel,
    onDuplicate: duplicateOptionLabel
  };

  const handleOnCardClick = () => {
    onClick && onClick();
  };

  return (
    <Card
      hoverable
      className="card-list-item"
      style={{ ...style }}
      bodyStyle={{ padding: "10px" }}
      cover={
        <a onClick={handleOnCardClick}>
          <CoverImage
            text={title}
            url={coverUrl}
            icon={PictureOutlined} />
        </a>
      }
      {...props}>
      <Card.Meta
        title={
          <FlexContainer
            yAlign="center"
            justifyContent="space-between"
            padding="1rem 1rem 0 1rem">
            <Tooltip title={title} arrowPointAtCenter>
              <a onClick={handleOnCardClick}>
                <Format size={titleMaxSize || 20} type="prune">
                  {title}
                </Format>
              </a>
            </Tooltip>
            {renderOptions({
              customOptionsComponent,
              onRemove,
              onDelete,
              onEdit,
              onDuplicate,
              extraActions,
              labels
            })}
          </FlexContainer>
        }
        description={
          <>
            <Visibility visible={!!children}>
              <div onClick={handleOnCardClick} className="clickable">
                <Divider style={{ margin: "0", border: "none" }} />
                <FlexContainer
                  direction="vertical"
                  padding="0.5rem 1rem">
                  {children}
                </FlexContainer>
              </div>
            </Visibility>
            <Visibility visible={!!extra}>
              <Divider style={{ margin: "0", border: "none" }} />
              <FlexContainer
                direction="vertical"
                padding="0 1rem 1rem 1rem">
                {extra}
              </FlexContainer>
            </Visibility>
            <Visibility visible={!!footerItems}>
              <Divider style={{ margin: "0" }} />
              {footerItems.length === 1
                ? <FlexContainer
                    style={{height: "100%"}}
                    xAlign="end"
                    padding="0.5rem 1rem">
                  {footerItems}
                </FlexContainer>
                : <FlexContainer
                    justifyContent="space-between"
                    padding="0.5rem">
                  {footerItems}
                </FlexContainer>}
            </Visibility>
          </>
        } />
    </Card>
  );
}
