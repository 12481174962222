import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";

/**
 * @param {object} props
 * @param {{[string]: {label: string, action: function}}} props.options
 * @param {boolean} props.rotate
 * @returns {JSX.Element}
 */
export function MultiOptionsButton({
  options = {},
  rotate = false,
  ...props
}) {
  const menu = (
    <Menu>
      {Object.keys(options || {})
        .map(key => {
          const option = options[key];
          return (
            <Menu.Item key={key} onClick={option.action}>
              {option.label}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button
        data-cy="action-button"
        shape="circle"
        icon={<EllipsisOutlined rotate={rotate && 90} />}
        {...props} />
    </Dropdown>
  );
}
