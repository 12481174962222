import React, { useContext, useEffect } from "react";
import { Form } from "antd";

import { Languages } from "./languages";
import { FormContainer } from "../../../../../../shared";
import { fieldValidationMessages } from "../../../../../../../utils";
import {
  NewExpertContext
} from "../../../../../../../contexts/experts/new/context";

const { Item } = Form;

export const LanguagesForm = ({ editable = true }) => {
  const {
    onFormFieldsChange,
    form,
    data = {}
  } = useContext(NewExpertContext);

  const { user = {} } = data;
  const {
    languages = []
  } = user?.expertProfile?.professionalExperience || {};

  useEffect(() => {
    form.setFieldsValue({
      languages
    });
  }, [data]);

  return (
    <FormContainer>
      <Form
        validateMessages={fieldValidationMessages}
        onFieldsChange={onFormFieldsChange}
        form={form}
        layout="vertical">
        <Item
          name="languages"
          trigger="onSave"
          valuePropName="languages"
          initialValue={languages}
          label={editable && "Idiomas"}
          rules={[{ required: true }]}>
          <Languages editable={editable} />
        </Item>
      </Form>
    </FormContainer>
  );
};
