import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { admin } from "../../../graphql/admin";
import { client } from "../../../../graphql";
import { CreateTaskModalContext } from "./context";
import { CreateTaskModal } from "./modal";
import { shared } from "../../../graphql/shared";
import { useParams } from "react-router-dom";

export function CreateTaskModalProvider({ children, selectedOde }) {
  const { id } = useParams();

  const [visible, setVisible] = useState(false);
  const [task, setTask] = useState();

  const [upsertTask] = useMutation(
    admin.mutations.upsertTask,
    { client }
  );

  const openModal = () => {
    setVisible(true);
  };

  const openEditModal = task => {
    setTask(task);
    setVisible(task);
  };

  const closeModal = () => {
    setTask(undefined);
    setVisible(false);
  };

  const save = async (createData, updateData) => {
    await upsertTask({
      variables: {
        id: task?.id || "",
        update: updateData,
        create: createData
      },
      awaitRefetchQueries: true,
      refetchQueries: [{
          query: shared.queries.getTasks,
          variables: {
            where: {
              batch: {id: id},
              ode: {id: selectedOde}
            }
          }
      }]});
  };

  const value = {
    openModal,
    openEditModal,
    closeModal,
    save
  };
  return (
    <CreateTaskModalContext.Provider value={value}>
      <CreateTaskModal
        task={task}
        closeModal={closeModal}
        visible={visible} />
      {children}
    </CreateTaskModalContext.Provider>
  );
}

