import { useState, useContext } from "react";
import { message } from "antd";
import { DataConnectionService } from "../../../services";
import { shared } from "../../../graphql/shared";
import { OdesService } from "../../../services/odes-service";
import { CurrentUserContext } from "../../../contexts/shared";

export function useCompanyTeamMembers({ companyId }) {
  const [state, setState] = useState({
    loading: false,
    takenEmails: []
  });

  const { currentUser } = useContext(CurrentUserContext);

  const resetState = () => {
    setState({ loading: false, takenEmails: [] });
  };

  // @todo Move to the domain models
  const getUsersbyEmail = async (emails = []) => {
    return await DataConnectionService.graphqlApolloClient.query({
      query: shared.queries.getUsersByEmail,
      variables: { emails }
    });
  };

  const verifyEmailList = async (emails = []) => {
    const dismissLoader = message.loading("Verificando emails...", 0);

    const result = await getUsersbyEmail(emails);
    const takenEmails = result.data.users?.map(u => u.email);

    setState(prevState => ({
      ...prevState,
      takenEmails
    }));

    dismissLoader();
    return takenEmails || [];
  };

  async function inviteTeamMembers({
    emails = [],
    continueAnyway = false
  }) {
    if (!continueAnyway) {
      const takenEmails = await verifyEmailList(emails);
      if (takenEmails?.length > 0) return takenEmails;
    }

    const dismissLoader = message.loading("Enviando...");
    try {
      await OdesService.inviteTeamMembers({ companyId, emails });
      message.success("Invitaciones enviadas");
    } catch (err) {
      console.error(err);
      message.error("Ocurrió un error");
      dismissLoader();
      // to avoid closing the modal
      throw new Error("Error while sending invitations");
    } finally {
      dismissLoader();
    }
  }

  async function addTeamMembers({
    ids = [],
    systemRole,
    afterSuccess
  }) {
    const dismissLoader = message.loading("Cargando...");
    try {
      await OdesService.addTeamMembers({
        ids,
        companyId,
        systemRole,
        requestedBy: currentUser?.fullName
      });
      afterSuccess && await afterSuccess();
      message.success("Guardado.");
    } catch (err) {
      console.error(err);
      message.error("Ocurrió un error");
      dismissLoader();
      // to avoid closing the modal
      throw new Error("Error while adding members");
    } finally {
      dismissLoader();
    }
  }

  async function removeTeamMemberFromCompany ({
    userId,
    afterSuccess
  }) {
    const dismissLoader = message.loading("Removiendo...");
    try {
      await OdesService.removeTeamMember({ companyId, userId });
      afterSuccess && await afterSuccess();
      message.success("Guardado.");
    } catch (err) {
      console.error(err);
      message.error("Ocurrió un error");
      dismissLoader();
      // to avoid closing the modal
      throw new Error("Error while adding members");
    } finally {
      dismissLoader();
    }
  }

  return ({
    removeTeamMemberFromCompany,
    inviteTeamMembers,
    addTeamMembers,
    resetState,
    ...state
  });
}
