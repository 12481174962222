import gql from "graphql-tag";

export const getCompaniesByIdsQuery = gql`
  query GetCompaniesByIdsQuery(
    $ids: [ID!]
    ) {
    oDEs(where: {id_in: $ids}) {
      id
      name
    }
  }
`;
