import gql from "graphql-tag";
export const getOdes = gql`
  query GetOdes(
    $searchString: String,
    $skip: Int,
    $first: Int,
    $batches_some: BatchWhereInput
  ){
    oDEs(
      where: {
        iname_contains: $searchString
        batches_some: $batches_some
      },
      skip: $skip,
      first: $first
    ){
      id
      iname
      name
      logoUrl
      sector
      team {
        fullName
        profilePicUrl
      }
      batches {
        program {
          type
        }
      }
    }
    meta: oDEsConnection(
      where: {
        iname_contains: $searchString
        batches_some: $batches_some
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
