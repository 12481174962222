import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

export const Details = ({ data }) => {
  return (
    <View style={{ fontWeight: 700, fontSize: 12, flexDirection: "row", justifyContent: "space-between" }}>
      <View>
        <View style={styles.row}>
          <Text style={styles.title}>Tipo</Text>
          <Text>{data.type}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.title}>Hora asignadas</Text>
          <Text>{data.assignedHours}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.title}>Fecha límite de consumo</Text>
          <Text>{data.limitDate}</Text>
        </View>
      </View>
      <View>
        <View style={styles.row}>
          <Text style={styles.title}>No. de asignación</Text>
          <Text>{data.assignationNumber}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.title}>Horas consumidas</Text>
          <Text>{data.consumedHours}</Text>
        </View>
      </View>
      <View>
        <View style={styles.row}>
          <Text style={styles.title}>Costo</Text>
          <Text style={{ fontWeight: 400 }}>
            {data.cost}
            <Text
              style={{ fontSize: "10px", color: "red" }}>
              {data.isProbono && " Probono"}
            </Text>
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.title}>Horas restantes</Text>
          <Text>{data.leftHours}</Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    marginTop: 22
  },
  title: {
    marginBottom: 7,
    fontWeight: 400
  }
});