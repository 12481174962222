import gql from "graphql-tag";

export const restoreCompletedContent = gql`
  mutation RestoreCompletedContent(
    $id: ID,
  ){
    updateOdeContent(
      where: { id: $id }
      data: {
        isComplete: false
        finishDate: null
      }
    ){
      id
    }
  }
`;
