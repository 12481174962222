import { client } from "../../../graphql";
import { removeCompanyFromBatchMutation } from "./graphql";
import { disconnectStatusOdeFromBatch } from "../ode-service/graphql";

export async function removeCompany(
  { batchId, odeStatusFromBatchIds, companyId, odesSorted }
) {
  const result = await client.mutate({
    mutation: removeCompanyFromBatchMutation,
    variables: { batchId, companyId, odesSorted }
  });

  await client.mutate({
    mutation: disconnectStatusOdeFromBatch,
    variables: { companyId, odeStatusFromBatchIds }
  });

  return result?.data?.removeOdeInBatch || false;
}