import React from "react";
import { Route, Switch } from "react-router";
import { CalendarIndex } from "../../../pages/entrepreneur/calendar";

export const EntrepreneurCalendarRoutes = (
  <Switch>
    <Route
      exact
      path="/v2/entrepreneur/calendar"
      component={CalendarIndex} />
  </Switch>
);
