import React, { useState } from "react";
import { List, Input } from "antd";

export function EditableField({ achievement, onChange }) {
  const [state, setState] = useState({});

  const handleOnChange = (data, name) => {
    const newValues = Object.assign({}, state);

    newValues[name] = data.currentTarget.value;

    setState(newValues);

    onChange && onChange(newValues);
  };

  return (
    <List.Item.Meta
      style={{ width: "100%" }}
      title={
        <Input
          autoFocus
          className="wide-input"
          name="name"
          type="text"
          placeholder="Título del logro"
          onChange={e => handleOnChange(e, "name")}
          defaultValue={achievement?.name} />
      }
      description={<Input.TextArea
        className="wide-input"
        name="description"
        type="text"
        placeholder="Descripción del logro"
        onChange={e => handleOnChange(e, "description")}
        defaultValue={achievement?.description} />} />
  );
}
