/* eslint-disable max-lines */
import React, { useContext, useEffect } from "react";
import { message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { entrepreneur } from "../../../../../graphql/entrepreneur";
import { client } from "../../../../../../graphql";
import {
  ServiceApprovalModalContext,
  ServiceDeliveryModalContext
} from "../../../../../contexts/expert/services/requests";
import {
  ScheduledExpertServiceService
} from "../../../../../services/deprecated/scheduled-expert-service-service";
import {
  ServiceDetailModalContext,
  CurrentUserContext
} from "../../../../../contexts/shared";
import {
  cancelledByEnum as CANCELLED_BY,
  eventTypeEnum
} from "../../../../../helpers";
import { UsageTrackingService } from "../../../../../services";
import {
  expertServiceTypes,
  getReadableValue
} from "../../../../../helpers";
import { ScheduledTable } from "./scheduled";

export function ScheduledServicesList({
  refetch,
  ...props
}) {
  const {
    addOnSaveListener,
    addOnRejectListener
  } = useContext(ServiceApprovalModalContext);
  const {
    addOnSaveListener: addDeliveryOnSaveListener
  } = useContext(ServiceDeliveryModalContext);
  const {
    addOnCancelRequestListener,
    closeModal: closeDetailModal
  } = useContext(ServiceDetailModalContext);

  const {
    currentUser
  } = useContext(CurrentUserContext);

  const [cancelScheduledExpertService] = useMutation(
    entrepreneur.mutations.cancelScheduledExpertService,
    { client }
  );

  useEffect(() => {
    addOnSaveListener(onApprove);
    addOnRejectListener(onReject);
    addDeliveryOnSaveListener(onDeliverService);
    addOnCancelRequestListener(onCancelRequest);
  });

  const scheduledExpertServiceService = new ScheduledExpertServiceService();
  const expertId = currentUser.id;

  const onApprove = async scheduledService => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      await scheduledExpertServiceService.approve(scheduledService, expertId);
      refetch && await refetch();
      message.success("Solicitud de servicio aprobada.");
    } catch (error) {
      console.error(error);
      message.error("Ocurrió un error.");
    } finally {
      dismissLoader();
    }
  };

  const onReject = async scheduledService => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      await scheduledExpertServiceService.reject(
        scheduledService,
        expertId,
        CANCELLED_BY.EXPERT
      );

      refetch && await refetch();
      message.success("Solicitud de servicio rechazada.");
    } catch (error) {
      console.error(error);
      message.error("Ocurrió un error.\
        Por favor intenta de nuevo en un momento.");
    } finally {
      dismissLoader();
    }
  };

  const onDeliverService = async ({ assignedExpertService, ...values }) => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      await scheduledExpertServiceService
        .deliver(assignedExpertService, expertId, values);
      const expertService = assignedExpertService.expertServiceData;
      UsageTrackingService.trackDeliveredSession({
        session: {
          ...assignedExpertService,
          id: expertService?.id,
          name: expertService?.name,
          type: getReadableValue(expertServiceTypes, expertService?.type)
        },
        deliveredBy: {
          id: expertId,
          fullName: currentUser.fullName,
          systemRole: currentUser.systemRole,
          email: currentUser.email
        }
      });

      refetch && await refetch();
      message.success("Entregado.");
    } catch (error) {
      console.error(error);
      message.error("Ocurrió un error.");
    } finally {
      dismissLoader();
    }
  };

  const onCancelRequest = async ({
    scheduledExpertService
  }) => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      const assignedServiceId = scheduledExpertService.assignedExpertService.id;
      const units = scheduledExpertService.duration;

      await cancelScheduledExpertService({
        variables: {
          id: scheduledExpertService.id,
          events: { // log event
            create: {
              event: eventTypeEnum.STATUS_CHANGED,
              description: "Request status changed to CANCELLED",
              blame: { connect: { id: expertId } }
            }
          }
        }
      });

      await scheduledExpertServiceService.refundAssignedUnits({
        assignedServiceId,
        units
      });

      refetch && await refetch();
      message.success("Solicitud cancelada.");
      closeDetailModal();
    } catch (e) {
      console.error(e);
      message.error("Ocurrio un error.\
        Por favor intenta de nuevo en unos momentos.");
    } finally {
      dismissLoader();
    }
  };

  return <ScheduledTable { ...props } />;
}
