import gql from "graphql-tag";

export const getMonitoringServices = gql`
  query GetMonitoringServices(
    $where: MonitoringServicesInput,
    $skip: Int,
    $first: Int,
  ){
    monitoringServices(
      where: $where
      skip: $skip,
      first: $first
    ){
      count
      scheduledExpertServices{
        id
        serviceDate
        status
        duration
        expertComments
        deliveredAt
        acceptedAt
        updatedAt
        events {
          id
          event
          description
          createdAt
          blame {
            id
            fullName
          }
        }
        assignedExpertService {
          id
          status
          finished
          availableUnits
          assignedUnits
          assignmentNumber
          assignationIdentifier
          limitDate
          assignmentDate
          expertServicePrice
          expertServiceData
          isProbono
          medium
          mediumDescription
          expertService {
            id
            name
            type
            price
            method
            category
            expert {
              id
              fullName
              firstName
              middleName
              lastName
            }
          }
          batch {
            id
            name
            program {
              id
              name
            }
          }
          ode {
            id
            name
            team {
              id
              fullName
              email
              phone
              systemRole
            }
          }
        }
      }
    }
  }
`;
