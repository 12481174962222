import gql from "graphql-tag";

export const getServices = gql`
  query GetServices(
    $method: String,
    $category: String,
    $searchString: String,
    $expert: UserWhereInput,
    $type: String,
    $first: Int,
    $skip: Int
  ) {
    expertServices(
      orderBy: updatedAt_DESC,
      first: $first,
      skip: $skip,
      where: {
        AND: [
          {
            OR: [ { disabled: false}, { disabled: null} ]   
          },
        ],
        expert: $expert,
        method: $method,
        category: $category,
        type: $type,
        OR: [
          { iname_contains: $searchString },
          { idescription_contains: $searchString },
          {
      	    expert: { ifullName_contains: $searchString }
          }
        ]
      }
    ) {
      id
      expert {
        id
        fullName
        email
        phone
        profilePicUrl
        expertProfile {
          id
          private
          privateBatches {
            id
            name
            program {
              id
            }
          }
        }
      }
      name
      description
      disabled
      type
      method
      category
      duration
      price
      coverPicUrl
      examples {
        id
        url
        type
        fileName
      }
      links
      assignedExpertServices {
        id
        availableUnits
      }
    }
    meta: expertServicesConnection(
      orderBy: updatedAt_DESC,
      where: {
        AND: [
          {
            OR: [ { disabled: false}, { disabled: null} ]   
          },
        ],
        expert: $expert,
        method: $method,
        category: $category,
        type: $type,
        OR: [
          { iname_contains: $searchString },
          { idescription_contains: $searchString },
          {
      	    expert: { ifullName_contains: $searchString }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
