import React from "react";
import { Button, List, Space, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import * as uuid from "uuid";
import { CompositeField, Visibility } from "../../../../../components/shared";
import { AmountListItem } from "./amount-list-item";

export function AmountInput({ onChange, value, viewMode = false }) {
  const onItemChange = ({ amount, values, idx, replaceItemAtIndex }) => {
    values.uid = amount.uid;
    replaceItemAtIndex(idx, values);
  };

  return (
    <CompositeField
      onChange={onChange}
      isAddDisabled={true}
      value={value}>
      {({items, addNew, removeItem, replaceItemAtIndex}) => {
        return (
          <>
            <List>
              { (items.length === 0) ?
                <div style={{ textAlign: "center", width: "100%" }}>
                  <Typography.Text
                    type="secondary">
                    No hay ningún monto
                  </Typography.Text>
                </div>
              : items.map((amount, idx) => (
                <AmountListItem
                  key={`amount-item-${amount.uid}`}
                  value={amount}
                  idx={idx}
                  removeItem={removeItem}
                  viewMode={viewMode}
                  onChange={values =>
                    onItemChange({amount, values, idx, replaceItemAtIndex})} />
                )
              )}
            </List>
            <Visibility
              visible={!viewMode}>
              <Space direction="horizontal">
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() => addNew({ uid: uuid.v4() })}>
                  Agregar monto
                </Button>
              </Space>
            </Visibility>
          </>);
        }
      }
    </CompositeField>
  );
}
