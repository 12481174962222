import { setSave } from "./new/set-save";

import {
  setUpdateAccounts
} from "./new/set-update-accounts";
import { getExperts } from "./get-experts";
import {
  setUpdateEntrepreneurships
} from "./new/set-update-entrepreneurships";
import {
  setUpdatePersonalInformation
} from "./new/set-update-personal-information";
import {
  setUpdateProfessionalInformation
} from "./new/set-update-professional-information";

export class ExpertsService {
  constructor () {
    this.setSave = setSave;
    this.getExperts = getExperts;
    this.setUpdateAccounts = setUpdateAccounts;
    this.setUpdateEntrepreneurships = setUpdateEntrepreneurships;
    this.setUpdatePersonalInformation = setUpdatePersonalInformation;
    this.setUpdateProfessionalInformation = setUpdateProfessionalInformation;
  }
}
