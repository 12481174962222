import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { message, PageHeader, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { withRouter } from "../../../../../../helpers";
import { client } from "../../../../../../../graphql";
import {
  BatchOdesContext,
  BatchDetailsContextProvider
} from "../../../../../../contexts/admin/batches";
import {
  MenuContext,
  ContentViewSwitchProvider,
  ContentModalContextProvider,
  ContentGroupModalContextProvider,
  ContentDetailsModalContextProvider, DraggableListProvider
} from "../../../../../../contexts/shared";
import {
  ContentWrapper,
  SidebarLayout
} from "../../../../../../components/shared";
import {
  Navbar,
  Summary
} from "../../../../../../components/admin/batches/show";
import {
  BatchOdesContents
} from "../../../../../../components/admin/batches/show/odes";
import { shared } from "../../../../../../graphql/shared";
import { MainMenu } from "../../../../../../components/coordinator/shared";
import { useService } from "../../../../../../hooks/shared";
import { CompanyService } from "../../../../../../services";

/**
 * @deprecated this component is deprecated, and it's used only while New batch
 * offer feature is released.
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
export function BatchODEContent({ history }) {
  const { id: batchId = "", odeId = "" } = useParams();

  const {
    data: { oDE } = {},
    refetch: odeRefetch
  } = useQuery(shared.queries.getOdeNameById, {
    client,
    variables: {
      id: odeId
    }
  });

  const {
    data: odeContents,
    refetch: refetchOdeContents
  } = useService(
    CompanyService.getOdeContents,
    { odeId, batchId }
  );

  const {
    data: publishedContent,
    refetch: refetchPublishedContent,
    loading: loadingPublishedContent,
    error: errorPublishedContent
  } = useService(
    CompanyService.getPublishedContent,
    { companyId: odeId, batchId },
    { shouldFetch: !!odeId && !!batchId }
  );

  const refetch = async () => {
    await refetchOdeContents();
    await odeRefetch();
    await refetchPublishedContent();
  };

  const [createCustomOdeContent] = useMutation(
    shared.mutations.createCustomOdeContent, { client }
  );

  const [updateCustomOdeContent] = useMutation(
    shared.mutations.updateCustomOdeContent, { client }
  );

  const [updateContentGroup] = useMutation(
    shared.mutations.updateContentGroup, { client }
  );

  const onUpdateCustomOdeContent = useCallback(async create => {
    if (publishedContent?.customOdeContent?.id) {
      await updateCustomOdeContent({
        variables: {
          where: { id: publishedContent?.customOdeContent?.id },
          data: { contentGroups: { create } }
        }
      });
    } else {
      await createCustomOdeContent({
        variables: {
          data: {
            contentGroups: { create },
            batch: { connect: { id: batchId } },
            ode: { connect: { id: odeId } }
          }
        }
      });
    }

    await refetch();
  }, [publishedContent]);

  const onUpdateContentGroup = useCallback(async (groupId, values) => {
    await updateContentGroup({
      variables: { where: { id: groupId }, data: values }
    });
    await refetch();
  }, [publishedContent]);

  const onUpdateContentSorted = useCallback(async (uuids, groupId) => {
    if (!groupId) {
      await updateCustomOdeContent({
        variables: {
          where: { id: publishedContent?.customOdeContent?.id },
          data: { contentGroupsSort: { set: uuids } }
        }
      });
    } else {
      await updateContentGroup({
        variables: {
          where: { id: groupId },
          data: { contentsSort: { set: uuids } }
        }
      });
    }
    await refetch();
    message.success("Ordenamiento guardado.");
  }, [publishedContent]);

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const routes = {
    objectives: "../../objectives",
    odes: "../../odes",
    entrepreneurs: "../../entrepreneurs",
    experts: "../../experts",
    coordinators: "../../coordinators",
    perks: "../../perks",
    tasks: "../../tasks",
    odesUpdates: "../../odes-updates",
    content: "../../content",
    announcements: "../../announcements",
    batchCompaniesProgress: "../../batch-companies-progress"
  };

  const injectActions = useMemo(() => ({
    data: publishedContent,
    error: errorPublishedContent,
    loading: loadingPublishedContent,
    refetch: refetchPublishedContent,
    isPublished: !!publishedContent,
    published: publishedContent,
    batchId,
    isDragable: false
  }), [loadingPublishedContent, publishedContent]);

  return (
    <BatchDetailsContextProvider>
      <BatchOdesContext.Provider value={injectActions}>
        <SidebarLayout menu={<MainMenu />}>
          <Summary />
          <Navbar routes={routes} selectedKeys={["odes"]} />
          <DraggableListProvider onUpdate={onUpdateContentSorted}>
            <ContentWrapper>
              <PageHeader
                style={{ padding: "0 24px" }}
                backIcon={<ArrowLeftOutlined />}
                subTitle={
                  <Typography.Text>
                    Compañías / <strong>{oDE?.name}</strong>
                  </Typography.Text>
                }
                onBack={() => history.goBack()} />
              <ContentGroupModalContextProvider
                onSave={onUpdateCustomOdeContent}
                onUpdate={onUpdateContentGroup}>
                <ContentModalContextProvider
                  batchId={batchId}
                  refetch={refetch}>
                  <ContentDetailsModalContextProvider
                    batchId={batchId}
                    odeId={odeId}
                    refetch={refetch}>
                    <ContentViewSwitchProvider>
                      <BatchOdesContents
                        ode={oDE}
                        odeContents={odeContents}
                        publishedContent={publishedContent} />
                    </ContentViewSwitchProvider>
                  </ContentDetailsModalContextProvider>
                </ContentModalContextProvider>
              </ContentGroupModalContextProvider>
            </ContentWrapper>
          </DraggableListProvider>
        </SidebarLayout>
      </BatchOdesContext.Provider>
    </BatchDetailsContextProvider>
  );
}

export default withRouter(BatchODEContent);
