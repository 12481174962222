import React from "react";
import { Space, Button, List } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

import { EducationListItem } from "./education-list-item";

const Item = ({ children, onRemove }) =>
  <List.Item
    extra={ onRemove && (
      <MinusCircleOutlined
        onClick={onRemove} />)}>
    {children}
  </List.Item>;

export const EducationList = ({
  value,
  onRemoveItem,
  onClickAdd,
  buttonLabel,
  editable = true
}) =>
  <Space
    direction="vertical"
    style={{ width: "100%" }}>
    <List
      size="small"
      bordered
      dataSource={value}
      renderItem={item =>
        <Item
          onRemove={ editable && (() => onRemoveItem(item))}>
          <EducationListItem educationRecord={item} />
        </Item>} />
    { editable && (
      <Button
        icon={<PlusOutlined />}
        onClick={onClickAdd}
        type="dashed"
        block>
        {buttonLabel}
      </Button>)}
  </Space>;
