import { useMutation } from "@apollo/react-hooks";
import { Button, Form, Input, Typography } from "antd";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";
import { withRouter } from "../../../helpers";
import { formStyle, loginErrorLabel } from "../register-form/styles";
import { Visibility } from "../visibility";
import logoImg from "../../../../assets/EOSPURPLE@3x.png";

function PasswordResetForm({ navigate }) {
  const [form] = Form.useForm();
  const { token } = useParams();
  const [state, setState] = useState({ sending: false, error: false });

  const [resetPassword] = useMutation(
    shared.mutations.resetPassword,
    { client }
  );

  const submitForm = async () => {
    const data = form.getFieldsValue();

    try {
      setState(state => ({ ...state, sending: true, error: false }));

      await resetPassword({
        variables: { ...data, token  }
      });

      navigate("/v2/login");
    }
    catch (e) {
      console.error(e);

      let error = "unknown";

      if(e.message.indexOf("Invalid password reset token") !== -1) {
        error = "invalid";
      }

      setState(state => ({ ...state, error }));
    }
    finally {
      setState(state => ({ ...state, sending: false }));
    }
  };

  return (
    <Form
      form={form}
      style={formStyle}>
      <div style={{ marginBottom: "14px" }}>
        <img src={logoImg} width="87px" />
      </div>
      <Typography.Paragraph>
        Ingresa tu nueva contraseña
      </Typography.Paragraph>
      <Form.Item
        rules={[{ required: true }]}
        name="password">
        <Input type="password" placeholder="Contraseña" />
      </Form.Item>
      <Visibility visible={state.error}>
        <p style={loginErrorLabel}>
          {
            state.error === "invalid" ?
            "Este link ha expirado. Solicita uno nuevo." :
            "Ocurrió un error, inténtalo de nuevo más tarde."
          }
        </p>
      </Visibility>
      <Button
        style={{ width: "100%" }}
        type="primary"
        loading={state.sending}
        onClick={submitForm}>
        Cambiar contraseña
      </Button>
      <Link to="/v2/login">
        <Button
          style={{ width: "100%" }}
          type="link">
          Regresar al login
        </Button>
      </Link>
    </Form>
  );
}

export default withRouter(PasswordResetForm);
