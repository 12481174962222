/**
 * Returns an integer percentage.
 * @param {number} total Denominator
 * @param {number} fraction Fraction
 */
export const getPercentage = (total, fraction) => {
  if (total > 0) {
    return (fraction * 100) / total;
  }
  return 0;
};