import React from "react";
import { Form, Typography, Input, Select, DatePicker } from "antd";
import moment from "moment";
import {
  programTypes,
  programOrigin
} from  "../../../helpers/select-options/program";
import { UsersSelect, UploadArea } from "../../../components/shared";
import {
  fieldValidationMessages
} from "../../../utils/fieldValidationMessages";

export function GeneralInfoForm({ form, editable = true }) {
  const getPhotoUrl = e => {
    const coverPicUrl = e?.file?.response?.imageUrl;
    form.setFieldsValue({ coverPicUrl });
    return coverPicUrl;
  };

  return (
    <Form
      validateMessages={fieldValidationMessages}
      form={form}
      layout="vertical">
      <Form.Item>
        <Typography.Text>
          Comencemos con el nombre y el tipo. Podrás editar más
          información al programa posteriormente.
        </Typography.Text>
      </Form.Item>
      <Form.Item
        name="coverPicUrl"
        getValueFromEvent={getPhotoUrl}>
        <UploadArea.Crop
          multiple={false}
          maxFiles={1}
          aspect={360 / 160}>
          Logotipo
        </UploadArea.Crop>
      </Form.Item>
      <Form.Item
        rules={[{ required: editable }]}
        name="name"
        label="Nombre del programa">
        <Input disabled={!editable} />
      </Form.Item>
      <Form.Item
        rules={[{ required: editable }]}
        name="description"
        label="Descripción del programa">
        <Input.TextArea disabled={!editable} />
      </Form.Item>
      <Form.Item
        rules={[{ required: editable }]}
        name="type"
        label="Tipo de programa">
        <Select
          showSearch
          optionFilterProp="label"
          options={programTypes}
          disabled={!editable} />
      </Form.Item>
      <Form.Item
        rules={[{ required: editable }]}
        name="origin"
        label="Origen">
        <Select
          showSearch
          optionFilterProp="label"
          options={programOrigin}
          disabled={!editable} />
      </Form.Item>
      <Form.Item
        rules={[{ required: editable }]}
        name="coordinator"
        label="Coordinador">
        <UsersSelect
          mode={null}
          systemRoles={["COORDINATOR", "SUPER_ADMIN"]}
          disabled={!editable} />
      </Form.Item>
      <Form.Item
        name="date"
        initialValue={moment()}
        label="Fecha de creación">
        <DatePicker
          format="DD [de] MMMM [de] YYYY"
          className="wide-input"
          disabled />
      </Form.Item>
    </Form>
  );
}
