import React from "react";
import { DatePicker, Form, Input } from "antd";
import { ProgramsSelect } from "../../../../shared/programs-select";

export function EditableField({ form }) {

  return (
    <Form
      form={form}
      style={{width: "100%"}}>
      <Form.Item
        name="name"
        label="Nombre">
        <Input />
      </Form.Item>
      <Form.Item
        label="Programa"
        name="program">
        <ProgramsSelect />
      </Form.Item>
      <Form.Item
        label="Descripción"
        name="description">
        <Input.TextArea  />
      </Form.Item>
      <Form.Item
        label="Del: "
        name="period">
        <DatePicker.RangePicker />
      </Form.Item>
    </Form>
  );
}
