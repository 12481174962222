import React, { useState } from "react";
import { BatchEventModalContext } from "./context";
import { BatchEventModal } from "./modal";

export function BatchEventModalProvider({ children, batchId }) {
  const [state, setState] = useState({
    isModalOpen: false,
    refetch: null
  });

  const openModal = refetch => {
    setState({ isModalOpen: true, refetch });
  };

  const closeModal = () => {
    setState({ isModalOpen: false, refetch: null });
  };

  const onSave = () => {
    state.refetch();
    closeModal();
  };

  return (
    <BatchEventModalContext.Provider value={{ openModal, closeModal }}>
      <BatchEventModal
        batchId={batchId}
        visible={state.isModalOpen}
        onCancel={closeModal}
        onSave={onSave} />
      {children}
    </BatchEventModalContext.Provider>
  );
}
