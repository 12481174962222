import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router";
import { client } from "../../../../../../../../graphql";
import { admin } from "../../../../../../../graphql/admin";
import {
  ShowLayout
} from "../../../../../../../components/admin/programs/objectives/show/show-layout";
import {
  AchievementModalProvider
} from "../../../../../../../contexts/shared/achievement-modal";
import AchievementsList from "../../../../../../../components/admin/programs/objectives/show/achievements/list";
import {
  ProgramDetailContextProvider
} from "../../../../../../../contexts/admin/programs/program-detail";
import {
  ObjectiveDetailContextProvider
} from "../../../../../../../contexts/admin/programs/objectives/objective-detail";
import {
  useQueryFilters
} from "../../../../../../../helpers/use-query-filters";

export function AchievementsIndex() {
  const { objectiveId } = useParams();
  const { loading, error, data, refetch } = useQuery(
    admin.queries.getAchievements,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          objective: {
            id: objectiveId
          }
        }
      },
      client
    }
  );

  const { setFilters } = useQueryFilters({ refetch });

  return (
    <ProgramDetailContextProvider>
      <ObjectiveDetailContextProvider>
        <AchievementModalProvider>
          <ShowLayout selectedKeys={["achievements"]}>
            <AchievementsList
              data={data}
              isLoading={loading}
              error={error}
              setFilters={setFilters} />
          </ShowLayout>
        </AchievementModalProvider>
      </ObjectiveDetailContextProvider>
    </ProgramDetailContextProvider>
  );
}
