import gql from "graphql-tag";

export const getMasterContentById = gql`
  query GetMasterContentById($id: ID!) {
    masterContent(
      where: {
        id: $id
      }
    ) {
      id
      contentGroups {
        id
        title
        draft
        description
      }
    }
  }
`;
