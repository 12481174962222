export const statusTagColors = {
  ASSIGNED: "default",
  SCHEDULED: "magenta",
  ON_GOING: "gold",
  DELIVERED: "purple",
  ACCEPTED: "cyan",
  AUTHORIZED_FOR_PAYMENT: "green",
  PAID: "blue",
  CONSUMED: "green",
  CONSUMING: "purple"
};

