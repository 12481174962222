import moment from "moment";
import {
  getReadableValue,
  programTypes,
  ODE_SECTORS
} from "../../../../helpers";

export const getAvatarsAndTags = ({ object, odes }) => {
  const sectorsList = new Set();
  const avatars = odes?.map(({ name, logoUrl: url, sector }) => {
    sectorsList.add(getReadableValue(ODE_SECTORS, sector));
    return { name, url };
  });

  const tags = object?.program?.type
    ? [getReadableValue(programTypes, object?.program?.type)]
    : [];
  tags.push(...Array.from(sectorsList));

  return { avatars, tags };
};

export const getStatus = ({ startDate, finishDate }, isActive) => {
  const status = { label: "", className: "" };

  if (moment().isBefore(startDate)) {
    Object.assign(status, { label: "Pendiente", className: "pending" });
  } else if (moment().isBefore(finishDate)) {
    Object.assign(status, { label: "En Curso", className: "ongoing" });
  } else {
    Object.assign(status, { label: "Finalizado", className: "finished" });
  }

  if (!isActive) {
    Object.assign(status, {
      label: "Compañía Inactiva",
      className: "cancelled"
    });
  }

  return status;
};
