import React, { useContext, useEffect, useState } from "react";
import { List, Button } from "antd";
import {
  EntrepreneurExpertsContext
} from "../../../../../contexts/entrepreneur/experts";
import {
  ExpertServiceCard
} from "../../../../shared/expert-service-card";
import {
  AssignedExpertServiceModalProvider
} from "../../../../../contexts/shared/assigned-expert-service-modal";
import { withLoader } from "../../../../../helpers";
import { ScheduleExpertServiceModalContext } from "../../../../../contexts/shared/schedule-expert-service-modal/context";
import { CurrentUserContext } from "../../../../../contexts/shared/current-user";

function ExpertServicesList({ data }) {
  const [state, setState] = useState({ expertServices: [] });

  useEffect(() => {
    setState({
      assignedExpertServices: data?.assignedExpertServices?.filter(a =>
        a.expertServiceData?.type !== "WORKSHOP"
      )
    });
  }, [data]);

  const { openModal } = useContext(ScheduleExpertServiceModalContext);
  const { currentUser } = useContext(CurrentUserContext);

  const gridConf = {
    gutter: 16,
    xs: 1,
    sm: 1,
    md: 2,
    lg: 3,
    xl: 4,
    xxl: 4
  };

  return (
    <List
      grid={gridConf}
      dataSource={state.assignedExpertServices}
      renderItem={s =>
        <List.Item>
          <ExpertServiceCard
            showActions={false}
            service={s.expertServiceData}
            extras={
              <Button
                type="primary"
                onClick={() => openModal(s, currentUser.id)}>
                Ver
              </Button>
            } />
        </List.Item>
      } />
  );
}

export default withLoader(ExpertServicesList);
