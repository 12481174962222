import React from "react";
import { DatePicker, Form, Input, Select, Switch, Row} from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { placeOptions } from "../select-options/place";
import { prizeOptions } from "../select-options/prize";
import { currencies } from "../../../../../helpers/select-options";

export const CompanyCompetenceForm = ({
  form,
  isSocial,
  nonePlace,
  onPlaceChange,
  onIsSocialChange
}) => {
  const { TextArea } = Input;

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ padding: "0 16px" }}>
      <Form.Item
        label="Nombre de la competencia"
        name="name"
        rules={[{ required: true }]}>
        <Input placeholder="Por ejemplo: Emprende por méxico" />
      </Form.Item>
      <Row>
        <Form.Item
          label="Fecha de la competencia"
          name="date"
          rules={[{ required: true }]}>
          <DatePicker
            picker="month"
            format="MMMM-YYYY"
            style={{ width: "357px " }} />
        </Form.Item>
        <Form.Item
          label="Competencia enfocada en problemas sociales"
          name="socialCompetence"
          valuePropName="checked"
          style={{ marginLeft: "18px" }}>
          <Switch
            checked={isSocial}
            onChange={onIsSocialChange}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />} />
        </Form.Item>
      </Row>
      <Form.Item
        label="Descripción de la competencia"
        name="description"
        rules={[{ required: true }]}>
        <Input placeholder="Por ejemplo: Competencia nacional" />
      </Form.Item>
      <Row justify="space-between">
        <Form.Item
          label="Lugar obtenido"
          name="place"
          rules={[{ required: true }]}>
          <Select
            options={placeOptions}
            onChange={onPlaceChange}
            style={{ width: "357px " }} />
        </Form.Item>
        <Form.Item
          label="Premio"
          name="prize">
          <Select
            disabled={nonePlace}
            options={prizeOptions}
            style={{ width: "357px " }} />
        </Form.Item>
      </Row>
      <Form.Item
        label="Monto"
        name="amount">
        <Input
          addonAfter={
            <Form.Item
              noStyle={true}
              initialValue="MXN"
              name="currency">
              <Select options={currencies} />
            </Form.Item>
          }
          prefix="$"
          type="number"
          disabled={nonePlace}
          style={{ width: "357px " }} />
      </Form.Item>
      <Form.Item
        label="Describe el premio"
        name="prizeDescription">
        <TextArea
          rows={3}
          disabled={nonePlace}
          placeholder="Agrega una descripción del premio obtenido." />
      </Form.Item>
    </Form>
  );
};
