import React, { useContext } from "react";
import { Card, Typography, Space, Modal } from "antd";
import { ActionButton, Visibility, DragIcon } from "../..";
import { AddContentButton } from "../contents/add-button";
import { ContentList } from "../contents";
import {
  ContentGroupModalContext
} from "../../../../contexts/shared";
import { RoleGuard } from "../../role-guard";
import "./styles.sass";
import { SectionProgress } from "../../../admin/batches/show/odes/contents/progress-indicators";
import { sortContents } from "../../../admin/batches/show/odes/contents/progress-indicators/helpers";

export function BatchContentGroupItem({
  refetch = null,
  contentGroup,
  odeContents,
  isDragable = false,
  dragHandleProps = {}
}) {
  const { id, contents, description, title } = contentGroup || {};
  const {
    openModal,
    duplicateSection,
    removeContentGroup
  } = useContext(ContentGroupModalContext);

  const openDeleteModal = () => {
    if (contents?.length) {
      return Modal.confirm({
        className: "ConfirmModal",
        width: 600,
        centered: true,
        closable: true,
        okText: "Aceptar",
        title: "Eliminar sección",
        icon: null,
        content: `No puedes eliminar una sección si ya tienes contenido
        agregado. Prueba eliminando primero el contenido y después intenta de
        nuevo eliminar la sección.`,
        cancelButtonProps: { hidden: true }
      });
    } else {
      return removeContentGroup(id);
    }
  };

  const openDuplicateModal = () => {
    return Modal.confirm({
      className: "ConfirmModal",
      width: 600,
      centered: true,
      closable: true,
      okText: "Duplicar",
      cancelText: "Cancelar",
      title: "Duplicar sección",
      icon: null,
      content: "¿Seguro que desea duplicar esta sección?",
      onOk: () => duplicateSection(contentGroup)
    });
  };

  const contentGroupContentsMap = {};
  contentGroup?.contents?.forEach(
    content => (contentGroupContentsMap[content.id] = true)
  );

  const odeContentsInContentGroup = odeContents?.filter(
      odeContent => contentGroupContentsMap[odeContent?.content?.id]
    ) || [];

  return (
    <Card
      title={
        <div style={{ display: "flex", alignItems: "center" }} {...dragHandleProps}>
          {isDragable &&
            <DragIcon style={{ marginRight: "10px" }} />
          }
          <Typography.Text strong>
            {title}
          </Typography.Text>
        </div>
      }
      extra={
        <>
          <Visibility visible={odeContents}>
            <SectionProgress
              odeContents={odeContentsInContentGroup}
              contentGroup={contentGroup} />
          </Visibility>
          <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR", "ADVISER"]}>
            <Visibility visible={!!refetch}>
              <ActionButton
                rotate
                type="text"
                extra={[{
                  label: "Duplicar", action: () => openDuplicateModal()
                }]}
                onEdit={() => openModal(contentGroup)}
                customOnDelete={openDeleteModal} />
            </Visibility>
          </RoleGuard>
        </>
      }>
      <Space direction="vertical" className="wide-input">
        <Typography.Text
          type="secondary"
          style={{whiteSpace: "pre-wrap"}}>
          {description}
        </Typography.Text>
        <Typography.Text strong>Contenidos</Typography.Text>
        <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR", "ADVISER"]}>
          <Visibility visible={!!refetch}>
            <AddContentButton
              id={id}
              title={title}
              description={description} />
          </Visibility>
        </RoleGuard>
        {!contents?.length > 0 ?
          <Typography.Text type="secondary">
            Aún no has agregado contenidos, los contenidos son una unidad
            básica de acción o conocimiento que tus compañías deben realizar
            durante el curso de su batch.
          </Typography.Text>
        :
          <ContentList
            isDragable={isDragable}
            contents={contentGroup?.contentsSort.length
              ? sortContents(contents, contentGroup)
              : contents
            }
            contentGroup={contentGroup}
            refetch={refetch} />
        }
      </Space>
    </Card>
  );
}
