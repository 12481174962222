import React, { useState } from "react";
import { Button, List, Typography, Input, Form, message } from "antd";
import { EditOutlined, CheckOutlined } from "@ant-design/icons";
import { Avatar } from "../index";
import { Visibility } from "../visibility";
import { genericFieldValidationMsgs } from "../../../utils";
import { useEffect } from "react";

export const CommentItem = ({ comment, onEdit }) => {
  const [editingComment, setEditingComment] = useState(false);
  const [savingChanges, setSavingChanges] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(comment);
  }, [comment]);

  const { Text } = Typography;

  const date = new Date(comment.createdAt);

  const authorFullName = comment.author?.fullName || [
      comment.author?.firstName,
      comment.author?.middleName,
      comment.author?.lastName
    ].filter(n => !!n)
    .join(" ");

  const handleOnEdit = async () => {
    if (!editingComment) {
      setEditingComment(true);
    } else {
      await form.validateFields();
      setSavingChanges(true);
      const dismissLoading = message.loading("Guardando...");

      try {
        const { comment: updatedComment } = form.getFieldsValue();
        comment.comment = updatedComment;

        onEdit && await onEdit(comment);
        form.resetFields();
        setEditingComment(false);
      } catch (e) {
        console.error(e);
        message.error("Ha ocurrido un error. Por favor, intentalo de\
          nuevo e unos minutos");
      } finally {
        setSavingChanges(false);
        dismissLoading();
      }
    }
  };

  const onCancelEdit = () => {
    setEditingComment(false);
    form.resetFields();
  };

  return (
    <List.Item>
      <List.Item.Meta
        avatar={<Avatar>
          {comment.author?.profilePicUrl || authorFullName}
        </Avatar>}
        title={
          <div>
            <Text type="secondary">
              {`${authorFullName}`}
            </Text>
            <Text style={{ fontSize: "12px", marginLeft: "10px" }} disabled>
              {date.toLocaleDateString()}
            </Text>
            <Text style={{ fontSize: "12px", marginLeft: "10px" }} disabled>
              {comment.edited ? "Editado" : ""}
            </Text>
            <Visibility visible={onEdit !== undefined}>
              <Button
                type="link"
                disabled={savingChanges}
                onClick={handleOnEdit}
                icon={!editingComment ?
                  <EditOutlined />
                  : <CheckOutlined />} />
              <Visibility visible={editingComment}>
                <Button
                  disabled={savingChanges}
                  onClick={onCancelEdit}
                  type="link">Cancelar</Button>
              </Visibility>
            </Visibility>
          </div>}
        description={
          <>
            <Visibility visible={!editingComment}>
              <Text style={{ whiteSpace: "pre-wrap" }}>{comment.comment}</Text>
            </Visibility>
            <Visibility visible={editingComment}>
              <Form form={form} validateMessages={genericFieldValidationMsgs}>
                <Form.Item
                  name="comment"
                  rules={[{ required: true }]}>
                  <Input.TextArea
                    disabled={savingChanges}
                    autoSize autoFocus />
                </Form.Item>
              </Form>
            </Visibility>
          </>
        } />
    </List.Item>
  );
};
