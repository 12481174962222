import { Card } from "antd";
import { Bar } from "react-chartjs-2";
import React, { useMemo } from "react";
import { ChartDataFeedback } from "../../chart-data-feedback";
import {
  useEntrepreneursByDimensions
} from "../use-entrepreneurs-by-dimensions";
import {
  ENTREPRENEURS_AGE_RANGE_CHART_OPTIONS
} from "./entrepreneurs-age-range-chart-options";
import {
  entrepreneursAgeRangeToChart
} from "./entrepreneurs-age-range-to-chart";

export const AgeRangeChart = ({ filters = {} }) => {
  const { loading, results } = useEntrepreneursByDimensions({
    dimensions: ["ageRange"],
    mustBeSet: ["ageRange"],
    ...filters
  });

  const chartData = useMemo(() => {
    if (!results) return ({ labels: [], datasets: [] });

    const [result] = results;
    return entrepreneursAgeRangeToChart(result.data);
  }, [results]);

  return (
    <Card
      spaced
      bordered
      style={sectionStyle}
      bodyStyle={{ height: "max-content" }}
      title="Emprendedores por rango de edad">
      <ChartDataFeedback
        loading={loading}
        isEmpty={!chartData?.datasets?.length}>
        <Bar
          data={chartData}
          type={"bar"}
          height={500}
          options={ENTREPRENEURS_AGE_RANGE_CHART_OPTIONS} />
      </ChartDataFeedback>
    </Card>
  );
};

const sectionStyle = {
  marginTop: 16,
  boxShadow: "none",
  borderRadius: "0",
  padding: "1rem"
};
