import gql from "graphql-tag";

export const getEntrepreneursByCoordinatorId = gql`
  query GetEntrepreneursByCoordinatorId(
    $search: String,
    $coordinatorId: ID,
    $first: Int,
    $skip: Int
  ){
    users(
      where: {
        systemRole: ENTREPRENEUR
        ifullName_contains: $search,
        OR: [
          { organisms_some: { coordinator : { id: $coordinatorId } } },
          {
            teamOdes_some: {
              OR: [
                    { team_some: { id: $coordinatorId } },
                    { network_some: { id: $coordinatorId } }
                    {
                      batches_some: {
                        OR: [
                              { users_some: { id: $coordinatorId } },
                              { program: {
                                  OR: [
                                      { coordinator: { id: $coordinatorId }},
                                      {
                                        organism: {
                                            OR: [
                                                { coordinator:
                                                  { id: $coordinatorId }
                                                },
                                                { team_some:
                                                  { id: $coordinatorId }
                                                },
                                                { institution: {
                                                    coordinator:
                                                        { id: $coordinatorId }
                                                  }
                                                }
                                            ]
                                        } # END OR
                                      }
                                  ] # END OR
                                } #  END program
                              }
                          ] # END OR
                        } # END batches_some
                    }
                ]
            }
          } # END teamOdes_some
        ] # END OR
    },
      skip: $skip,
      first: $first
    ){ 
      id
      fullName
      email
      phone
      profilePicUrl
      teamOdes {
        id
        name
        logoUrl
      }
      # Stop using the "odes" field in future updates. Use teamOdes
      # instead for entrepreneurs or networkOdes for users part of the network.
      odes{
        ode{
          id
          name
        }
      }
    }
    meta: usersConnection(
      where: {
        systemRole: ENTREPRENEUR
        ifullName_contains: $search,
        OR: [
          { organisms_some: { coordinator : { id: $coordinatorId } } },
            {
              teamOdes_some: {
                OR: [
                    { team_some: { id: $coordinatorId } },
                    { network_some: { id: $coordinatorId } }
                    {
                      batches_some: {
                        OR: [
                            { users_some: { id: $coordinatorId } },
                            {
                              program: {
                                OR: [
                                    { coordinator: { id: $coordinatorId } },
                                    {
                                      organism: {
                                        OR: [
                                            { coordinator: {
                                                id: $coordinatorId
                                            }},
                                            { team_some: {
                                                id: $coordinatorId
                                            }}
                                            { institution: {
                                                coordinator: {
                                                    id: $coordinatorId
                                                }
                                              }
                                            }
                                        ]
                                      } # END OR
                                    }
                                ] # END OR
                              } #  END program
                            }
                        ] # END OR
                      } # END batches_some
                    }
                ]
              }
            } # END teamOdes_some
        ] # END OR
      }
    ){
      aggregate {
        count
      }
    }
  }
`;
