import { getReadableValue } from "../../../../../helpers";

export function industriesToChart (industries = []) {
  let labels = [];
  let datasets = [];

  const secureIndustries = industries
    .filter(i => i["Company.companyIndustry"] !== null);

  secureIndustries.forEach((industry, idx) => {
    const industryName = industry["Company.companyIndustry"];
    const readableLabel = getReadableValue(
      industries,
      industryName,
      industryName
    );

    labels.push(readableLabel.length > 19
      ? `${readableLabel.slice(0, 20)}...`
      : readableLabel
    );

    const data = new Array(secureIndustries.length);
    data.fill(0, 0, secureIndustries.length - 1);
    data[idx] = parseInt(industry["Company.companiesCount"]);

    datasets.push({
      label: readableLabel,
      data,
      backgroundColor: COLORS[idx]
    });
  });

  if (datasets.length > 16) {
    const othersReducedTotal = datasets
      .slice(16, datasets.length)
      .reduce((acc, curr) => {
        return acc + curr.data.reduce((a, c) => a + c, 0);
      }, 0);

    const othersData = new Array(16);
    othersData.fill(0, 0, 16);
    othersData[16] = othersReducedTotal;
    const others = {
      label: "Otros",
      data: othersData
    };

    datasets = datasets.slice(0, 16);
    datasets.push(others);

    labels = labels.slice(0, 16);
    labels.push("Otros");
  }

  return {
    labels,
    datasets
  };
}

const COLORS = [
  "#EEE3F9",
  "#E8D2FE",
  "#D7B7F6",
  "#C995FB",
  "#EBF1FE",
  "#D9E4FE",
  "#B7CCFE",
  "#95B4FE",
  "#FDDCDC",
  "#FDC0C0",
  "#FEB7D6",
  "#FE99C6",
  "#E5F4FE",
  "#D0ECFE",
  "#9FD9FE",
  "#71C7FE",
  "#E5F4F2",
  "#BCEFEA",
  "#97E9E1",
  "#7AD3CA"
];
