import React, { useContext, useEffect, useState } from "react";
import { NotificationsContext } from "./context";
import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";
import { CurrentUserContext } from "../current-user";

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function getBaseUrl() {
  const re = new RegExp(/^.*\/\/[^\/]+/);
  return re.exec(window.location.href);
}

export function NotificationsContextProvider({ children }) {
  const { currentUser } = useContext(CurrentUserContext);
  const [hasEnabledNotifications, setHasEnabledNotifications] = useState(false);

  const registerWorker = (timeout = 10000) => {
    return new Promise((resolve, reject) => (async () => {
      const registration = await window.navigator
        .serviceWorker
        .register(`${getBaseUrl()}/notifications-worker.js`);

      let worker;

      if(registration.installing) {
        worker = registration.installing;
      }
      else if(registration.waiting) {
        worker = registration.waiting;
      }
      else if(registration.active) {
        worker = registration.active;
      }

      const rejectTimeout = setTimeout(() => {
        reject(false);
      }, timeout);

      if(worker) {
        if(worker.state === "activated") {
          resolve(registration);
        }

        worker.addEventListener("statechange", e => {
          if(e.target.state === "activated") {
            clearTimeout(rejectTimeout);
            resolve(registration);
          }
        });
      }
    })());
  };

  const subscribe = async () => {
    try {
      const registration = await registerWorker();

      const permission = await window.Notification.requestPermission();

      if(permission === "denied" || !registration) {
        localStorage.removeItem("subscriptionEndpoint");
        return false;
      }

      const subscription = await registration
        .pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(
            process.env.REACT_APP_WEB_NOTIFICATIONS_PUBLIC_KEY
          )
        });

      /* extract auth keys */
      const subscriptionObject = JSON.parse(JSON.stringify(subscription));

      if(localStorage.getItem("subscriptionEndpoint") != subscription.endpoint) {
        await client.mutate({
          mutation: shared.mutations.createNotificationSubscription,
          variables: {
            data: {
              user: {
                connect: { id: currentUser.id }
              },
              endpoint: subscription.endpoint,
              keys: subscriptionObject.keys
            }
          }
        });

        localStorage.setItem("subscriptionEndpoint", subscription.endpoint);
      }
      setHasEnabledNotifications(true);
      return true;
    }
    catch(e) {
      console.error(e);

      return false;
    }
  };

  useEffect(() => {
    const subscriptionEndpoint = localStorage.getItem("subscriptionEndpoint") !== null;
    if(subscriptionEndpoint) {
      setHasEnabledNotifications(subscriptionEndpoint);
    }
  }, []);

  return (
    <NotificationsContext.Provider value={{subscribe, hasEnabledNotifications}}>
      { children }
    </NotificationsContext.Provider>
  );
}
