import {getReadableValue} from "../select-options";

/**
 * @param {{label: string, value: string}[]} countryOptions
 * @param {{id: number, name: string}[]} legacyCountryOptions
 * @param {string | number} country
 */

export function readableLegacyCountriesSupport (
  countryOptions,
  legacyCountryOptions,
  country
) {
  const readableCountry = getReadableValue(countryOptions, country, null);

  if(readableCountry)
    return readableCountry;

  const legacyCountries = legacyCountryOptions.map((country) => ({
    label: country.name,
    value: country.id
  }));

  return getReadableValue(legacyCountries, Number(country), null)
    || country;
}
