import React, { useState } from "react";
import { message } from "antd";
import { useParams } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import { ReportFormModalContext } from "./context";
import { OdeReportModal } from "./modal";
import { entrepreneur } from "../../../graphql/entrepreneur";
import { client } from "../../../../graphql";
import { cloneDeep } from "lodash";
import { shared } from "../../../graphql/shared";
import * as uid from "uuid";

export function ReportFormModalProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    isOpen: false,
    viewMode: false,
    report: {}
  });

  const { id: odeId } = useParams();

  const [upsertOdeReport] = useMutation(
    entrepreneur.mutations.upsertOdeReport, {
    client,
    awaitRefetchQueries: true
  });

  const getOdeReportById = async id => {
    const result = await client.query({
      query: entrepreneur.queries.getOdeReportById,
      fetchPolicy: "network-only",
      variables: { id }
    });

    const report = result.data?.odeReport || {};
    return report;
  };

  const saveChanges = async () => {
    if (!state.viewMode) {
      const dismissLoader = message.loading("Guardando...");
      try {
        const data = cloneDeep(state.report);
        const id = data.id || "";
        delete data.id;

        data.ode = { connect: { id: odeId } };
        data.achievements = {
          set: data.achievements?.map(achievement => achievement?.value)
        };

        const result = await upsertOdeReport({
          variables: {
            id,
            create: data,
            update: data
          },
          refetchQueries: [{
            query: shared.queries.getOdeReports,
            variables: {
              odeId: odeId
            }
          }]
        });

        setState(prevState => ({
          ...prevState,
          report: {
            ...prevState.report,
            id: result.data?.upsertOdeReport?.id
          }
        }));

        message.success("Reporte de la Compañía guardado.");
      } catch (error) {
        console.error(error);
        message.error("Ocurrió un error.");
      } finally {
        dismissLoader();
      }
    }
  };

  const updateChanges = _.debounce((value = {}) => {
    setState(prevState => {
      return ({
        ...prevState,
        report: {
          ...prevState.report,
          ...value
        }
      });
    });
  }, 100);

  const openModal = async (reportId, viewMode = false) => {
    setLoading(true);
    let report = {};

    try {
      setState(prevState => ({
        ...prevState,
        isOpen: true,
        viewMode: viewMode
      }));

      if (reportId) {
        report = await getOdeReportById(reportId);
        delete report.__typename;
        delete report.ode?.__typename;
        // achievements are stored as an string array
        report.achievements = report
          .achievements
          ?.map(achievement => ({
            uid: uid.v4(),
            value: achievement
          }));
      }

      setState(prevState => ({
        ...prevState,
        report
      }));
    } catch (error) {
      console.error(error);
      message.error("Ha ocurrido un error, inténtalo de nuevo\
        en unos segundos.");
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setState(prevState => ({
      ...prevState,
      report: {},
      onlyView: false,
      isOpen: false
    }));
  };

  const injectActions = {
    openModal,
    closeModal,
    updateChanges,
    saveChanges,
    viewMode: state.viewMode,
    report: state.report
  };

  return (
    <ReportFormModalContext.Provider value={injectActions}>
      <OdeReportModal loading={loading} isOpen={state.isOpen} />
      {children}
    </ReportFormModalContext.Provider>
  );
}