import gql from "graphql-tag";

export const getPersonsQuery = gql`
    query getPersonsQuery ($criteria: PersonsCriteriaInput) {
        persons (criteria: $criteria) {
            personId
            fullName
            audienceType
        }
    }
`;
