import React from "react";
import { Avatar, Space, List, Modal } from "antd";
import { LinkOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { fileTypeToColor } from "../../../helpers/file-type-to-color";
import { getClickableLink } from "../../../helpers";
import { Visibility } from "../visibility";

export function FileLinkItem({ item, onDelete, canDelete }) {

  const fileExtension = item.type.split("/").pop();
  const avatarColor = fileTypeToColor(fileExtension);

  const handleDelete = () => {
    Modal.confirm({
      okText: "Eliminar",
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: `Estás a punto de eliminar un elemento de este contenido 
        ¿Deseas continuar?`,
      onOk: () => onDelete(item.id)
    });
  };

  return (
    <List.Item
      key={item.id}
      style={{
        display: "flex",
        flexDirection: "column" }}>
      <Space
        align="baseline"
        style={{ justifyContent: "space-between", width: "100%" }}>
        <Space
          style={{
            width: "450px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "elipsis"
          }}>
          <Avatar
            style={{
              backgroundColor: avatarColor,
              verticalAlign: "middle",
              marginRight: "10px"
            }}
            size="default"
            gap={1}>
            {fileExtension}
          </Avatar>
          <LinkOutlined />
          <a
            href={getClickableLink(item.url)}>
            {item.fileName}
          </a>
        </Space>
        <Visibility visible={!canDelete}>
          <DeleteOutlined onClick={handleDelete} />
        </Visibility>
      </Space>
    </List.Item>
  );
}