import React, { useContext } from "react";
import { Button, List, message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ReportFormModalContext } from "../../../../../contexts/shared";
import { Section } from "../../../section";
import { ReportListItem } from "./report-list-item";
import { useMutation } from "@apollo/react-hooks";
import { entrepreneur } from "../../../../../graphql/entrepreneur";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql";

export const ReportsList = ({ reports, loading, odeId }) => {
  const { openModal } = useContext(ReportFormModalContext);

  const [deleteOdeReport] = useMutation(entrepreneur.mutations.deleteOdeReport,
    {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: shared.queries.getOdeReports,
          variables: { odeId: odeId }
        }
      ]
    });

  const onDeleteOdeReport = id => {
    Modal.confirm({
      okText: "Eliminar",
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: "Estás a punto eliminar el reporte seleccionado\
        ¿Deseas continuar?",
      onOk: async () => {
        const dismissLoader = message.loading("Eliminando...");

        try {
          await deleteOdeReport({ variables: { id } });
          message.success("Reporte eliminado");
        } catch (error) {
          console.error(error);
          message.error("Ha ocurrido un error, inténtalo de nuevo\
            en unos segundos.");
        } finally {
          dismissLoader();
        }
      }
    });
  };

  return (
    <Section
      spaced
      title="Reportes"
      extra={
        <Button onClick={() => openModal()} type="primary">Iniciar</Button>
      }>
      <List
        itemLayout="horizontal"
        loading={loading}
        dataSource={reports}
        rowKey={report => report.id}
        renderItem={(report =>
          <ReportListItem
            report={report}
            onEdit={() => openModal(report.id)}
            onDelete={onDeleteOdeReport}
            onView={() => openModal(report.id, true)} />)} />
    </Section>
  );
};
