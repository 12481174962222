import { client } from "../../../graphql";
import {
  getAssignedServices as getAssignedServicesQuery,
  getAssignedWorkshopServices as getAssignedWorkshopServicesQuery,
  getScheduledServices as getScheduledServicesQuery,
  getScheduledWorkshopServices as getScheduledWorkshopServicesQuery
} from "./graphql";

async function getAssignedServices({
  serviceType = "",
  expertId,
  first,
  skip,
  ...filters
}) {

  const query = serviceType !== "WORKSHOP"
      ? getAssignedServicesQuery
      : getAssignedWorkshopServicesQuery;

  const result = await client.query({
    query,
    fetchPolicy: "network-only",
    variables: {
      serviceType,
      expertId,
      first,
      skip,
      ...filters
    }
  });

  return {
    assignedExpertServices: result.data?.assignedExpertServices || [],
    count: result.data?.meta?.aggregate?.count
  };
}

async function getScheduledServices({
  serviceType = "",
  expertId,
  first,
  skip,
  ...filters
}) {

  const query = serviceType !== "WORKSHOP"
    ? getScheduledServicesQuery
    : getScheduledWorkshopServicesQuery;

  const result = await client.query({
    query,
    fetchPolicy: "network-only",
    variables: {
      serviceType,
      expertId,
      first,
      skip,
      ...filters
    }
  });

  const scheduledExpertServices = result.data?.scheduledExpertServices.map(
    scheduled => ({
      ...scheduled.assignedExpertService,
      duration: scheduled.duration,
      status: scheduled?.status,
      id: scheduled?.id
    })
  );

  return {
    scheduledExpertServices: scheduledExpertServices || [],
    count: result.data?.meta?.aggregate?.count
  };
}

export const ExpertSessionsServicesService = {
  getAssignedServices,
  getScheduledServices
};
