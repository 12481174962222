import React, { useEffect, useContext } from "react";
import { Section, SidebarLayout } from "../../../components/shared";
import { ProgramModalProvider } from "../../../contexts/shared/program-modal";
import {
  default as ProgramsList
} from "../../../components/admin/programs/list";
import {
  FilterControls
} from "../../../components/admin/programs/filter-controls";
import { admin } from "../../../graphql/admin";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../../../graphql";
import { MenuContext } from "../../../contexts/shared/menu";
import { BatchModalProvider, PaginationContext } from "../../../contexts/shared";
import { useQueryFilters, useControlledPagination } from "../../../helpers";

export function ProgramsIndex() {
  const { loading, error, data, refetch, variables } = useQuery(
    admin.queries.getPrograms, {
    fetchPolicy: "network-only",
    client,
    variables: {
      first: 10
    }
  });

  const { setMenuState } = useContext(MenuContext);

  const { registerRefetch } = useContext(PaginationContext);

  registerRefetch("programs", refetch, variables);

  useEffect(() => {
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["programs/all"]
    });
  }, []);

  const { currentPage, onPaginationChange } = useControlledPagination({
    initialPageSize: 10
  });

  const { applyFilters } = useQueryFilters({
    refetch,
    defaultFilters: {
      first: 10
    }
  });

  const onFilter = (filter = {}) => {
    const { skip, pageSize: first } = onPaginationChange(1);
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter,
      skip,
      first
    }));
  };

  return (
    <BatchModalProvider>
      <ProgramModalProvider refetch={refetch}>
        <SidebarLayout>
          <Section
            data-testid="programs-section"
            title="Programas"
            spaced
            extra={<FilterControls onChange={onFilter} />}>
            <ProgramsList
              onChangePage={onPaginationChange}
              currentPage={currentPage}
              data-test-id="programs-list"
              data={data}
              isLoading={loading}
              error={error} />
          </Section>
        </SidebarLayout>
      </ProgramModalProvider>
    </BatchModalProvider>
  );
}
