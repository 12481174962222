import React from "react";
import { Layout } from "../../../../components/shared";
import Steps from "./steps";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Space, Button } from "antd";

const StepperContainer = styled.div`
  background-color: #ffffff;
  border-bottom: solid 1px #f4f4f4;
`;

const ContentContainer = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  & .ant-card {
    margin-top: 15px;
  }
`;

const FooterContainer = styled.div`
  margin: 20px 0;
`;

export function StepsWrapper({
  loading = false,
  onNext,
  currentStep = 0,
  children,
  nextUrl = "/",
  prevUrl = ""
}) {
  const handleNext = event => {
    onNext && onNext(event);
  };

  return (
    <Layout>
      <StepperContainer>
        <Steps current={currentStep} />
      </StepperContainer>
      <ContentContainer direction="vertical">
        { children }
        <FooterContainer>
          <Space>
            { prevUrl &&
              <Link to={prevUrl}>
                <Button>
                  Anterior
                </Button>
              </Link> }
            <Link to={nextUrl} onClick={handleNext}>
              <Button
                loading={loading}
                type="primary">
                Siguiente
              </Button>
            </Link>
          </Space>
        </FooterContainer>
      </ContentContainer>
    </Layout>
  );
}
