import React, { useContext } from "react";
import { AuthServiceContext } from "../../contexts/shared/auth";
import { LoginLayout } from "../../components/shared";
import { withRouter } from "../../helpers";
import RegisterForm from "../../components/shared/register-form";
import { CurrentUserContext, CurrentUserProvider } from "../../contexts/shared";
import { Result, Spin } from "antd";
import { useHistory, useLocation } from "react-router-dom";

function Login() {
  const { authService } = useContext(AuthServiceContext);
  const { refetchCurrentUser } = useContext(CurrentUserContext);
  // TODO: Implement session context

  const history = useHistory();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  // From is the route to redirect after the login,
  // this route replaced "&" to "__"
  const from = params.get("from");

  const login = async (args = {}) => {
    const { systemRole } = await authService.login(args);

    await refetchCurrentUser();

    redirect(systemRole);
  };

  const redirect = systemRole => {
    const roleRoutes = {
      SUPER_ADMIN: "admin",
      ADVISER: "expert",
      COORDINATOR: "coordinator",
      ENTREPRENEUR: "entrepreneur",
      ASEM_ADMIN: "asem"
    };

    if (from) return history.push(from.replace("__", "&"));

    switch (systemRole) {
      case "ADVISER":
        history.push("/v2/expert/services/monitoring");
        break;
      case "ENTREPRENEUR":
        history.push("/v2/entrepreneur/batches");
        break;
      case "COORDINATOR":
        history.push("/v2/coordinator/batches");
        break;
      case "SUPER_ADMIN":
        history.push("/v2/admin/programs");
        break;
      case "ASEM_ADMIN":
        history.push("/v2/asem");
        break;
      default:
        history.push(`/v2/${roleRoutes[systemRole]}/services`);
        break;
    }
  };

  return (
    <CurrentUserProvider>
      <CurrentUserContext.Consumer>
        {({ currentUser, loading, error }) => {
          if (currentUser.id && currentUser.systemRole && !loading && !error) {
            return redirect(currentUser.systemRole);
          }

          if (loading) {
            return (
              <LoginLayout>
                <Result
                  icon={<Spin size="large" />} />
              </LoginLayout>
            );
          }

          return (
            <LoginLayout>
              <RegisterForm
                badCredentials
                signingFunction={login}
                mode="signin" />
            </LoginLayout>
          );
        }}
      </CurrentUserContext.Consumer>
    </CurrentUserProvider>
  );
}

export default withRouter(Login);
