import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  default as GeneralInformationIndex
} from "../../../pages/entrepreneur/odes/show/general-information";
import {
  default as TeamIndex
} from "../../../pages/entrepreneur/odes/show/team";
import {
  default as MyDocumentsIndex
} from "../../../pages/entrepreneur/odes/show/my-documents";
import {
  default as PitchIndex
} from "../../../pages/entrepreneur/odes/show/pitch";
import {
  default as BatchesIndex
} from "../../../pages/entrepreneur/odes/show/batches";
import {
  default as BatchContentsIndex
} from "../../../pages/entrepreneur/odes/show/batch-contents";
import {
  default as MetricsIndex
} from "../../../pages/entrepreneur/odes/show/metrics";
import { OdesIndex } from "../../../pages/entrepreneur/odes";
import { OnePagerIndex } from "../../../pages/entrepreneur/odes/show/one-pager";
import { default as OdeUpdates } from "../../../pages/entrepreneur/odes/show/ode-updates";

export const EntrepreneurODEsRoutes = <Switch>
  <Route
    exact
    path="/v2/entrepreneur/odes"
    component={OdesIndex} />
  <Route
    path="/v2/entrepreneur/odes/:id/one-pager"
    component={OnePagerIndex} />
  <Route
    path="/v2/entrepreneur/odes/:id/general-information"
    component={GeneralInformationIndex} />
  <Route
    path="/v2/entrepreneur/odes/:id/my-documents"
    component={MyDocumentsIndex} />
  <Route
    path="/v2/entrepreneur/odes/:id/team"
    component={TeamIndex} />
  <Route
    path="/v2/entrepreneur/odes/:id/pitch"
    component={PitchIndex} />
  <Route
    exact
    path="/v2/entrepreneur/odes/:id/batches"
    component={BatchesIndex} />
  <Route
    path="/v2/entrepreneur/odes/:id/batches/:batchId"
    component={BatchContentsIndex} />
  <Route
    path="/v2/entrepreneur/odes/:id/metrics"
    component={MetricsIndex} />
  <Route
    path="/v2/entrepreneur/odes/:id/ode-updates"
    component={OdeUpdates} />
</Switch>;
