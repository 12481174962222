import React from "react";
import { ServiceDetailModalProvider } from "../../../contexts/shared";
import {
  ServiceApprovalModalProvider,
  ServiceDeliveryModalProvider
} from "../../../contexts/expert/services/requests";

export const TableWrapperScheduledServiceProvider = ({ refetch, children }) => (
  <ServiceDetailModalProvider refetch={refetch}>
    <ServiceApprovalModalProvider>
      <ServiceDeliveryModalProvider refetch={refetch}>
        {React.cloneElement(children)}
      </ServiceDeliveryModalProvider>
    </ServiceApprovalModalProvider>
  </ServiceDetailModalProvider>
);