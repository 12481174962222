import { client } from "../../../graphql";
import { getCompaniesByIdsQuery } from "./graphql/get-companies-by-ids";

export async function getCompaniesByIds({ ids = []}){
  const result = await client.query({
    query: getCompaniesByIdsQuery,
    variables: {
      ids
    }});

  return result.data?.oDEs || [];
}