import React, { useState } from "react";
import { Filters } from "./filters";
import { Section } from "../../../shared";
import { AgeRangeChart } from "./age-range-chart";
import { OccupationChart } from "./occupation-chart";
import { StatisticsCards } from "./statistics-cards";
import { useService } from "../../../../hooks/shared";
import { BatchesService } from "../../../../services";
import { useChartsDataContext } from "../charts-data-provider";

export const EntrepreneursCharts = () => {
  const [filters, setFilters] = useState({});

  const { programs, loadingPrograms } = useChartsDataContext();

  const { data: batches, refetch: refetchBatches } = useService(
    BatchesService.getByProgram,
    { programId: filters.programId },
    { shouldFetch: !!filters.filters }
  );

  return (
    <Section spaced>
      <Filters
        onChange={setFilters}
        programs={programs}
        loadingPrograms={loadingPrograms}
        batches={batches}
        refetchBatches={refetchBatches} />
      <StatisticsCards filters={filters} />
      <AgeRangeChart filters={filters} />
      <OccupationChart filters={filters} />
    </Section>
  );
};
