import { List } from "antd";
import React, { useContext } from "react";
import { ObjectiveAchivementItemContext } from "../../../contexts/shared";
import { ContentDetailsModalContextProvider } from "../../../contexts/shared/content-details-modal/provider";
import { AddButton } from "../add-button";
import { ContentItem } from "../content-item";
import { RoleGuard } from "../role-guard";
import { Visibility } from "../visibility";

export function ContentList({ contents = [], onAdd, onEdit }) {
  const {
    achievement
  } = useContext(ObjectiveAchivementItemContext);

  contents = contents.map((c, index) => {
    c.number = index + 1;
    return c;
  });

  return (
    <>
      <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR"]}>
        <Visibility visible={!achievement?.new}>
          <AddButton
            onClick={onAdd}
            style={{ width: "100%" }}>
            Agregar contenido
          </AddButton>
        </Visibility>
      </RoleGuard>
      <List
        dataSource={contents}
        renderItem={i =>
          <List.Item>
            <ContentDetailsModalContextProvider>
              <ContentItem
                content={i}
                onEdit={onEdit} />
            </ContentDetailsModalContextProvider>
          </List.Item>
        } />
    </>
  );
}
