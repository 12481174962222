import React, { useContext } from "react";
import { Button, Space } from "antd";
import { OdeUpdateModalContext } from "../../../contexts/shared";
import { GqlSelect } from "../gql-select";
import { admin } from "../../../graphql/admin";
import { Visibility } from "../visibility";
import { shared } from "../../../graphql/shared";

/**
 * @param {object} props
 * @param {string | undefined} props.batchId
 * @param {string | undefined} props.odeId
 * @param {function} props.onFilter
 */
export function FilterControls({
  batchId,
  odeId,
  onFilter
}) {
  const { openModal } = useContext(OdeUpdateModalContext);

  return (
    <Space>
      <Visibility visible={odeId}>
        <GqlSelect
          onChange={batchId => onFilter({ batchId })}
          style={{ width: "250px" }}
          placeholder="Batch"
          dataKey="batches"
          queryVariables={{ id: odeId }}
          gqlQuery={shared.queries.getBatchesByOdeId} />
      </Visibility>
      <Visibility visible={batchId}>
        <GqlSelect
          onChange={odeId => onFilter({ odeId })}
          style={{ width: "250px" }}
          placeholder="Compañía"
          dataKey="oDEs"
          queryVariables={{ id: batchId }}
          gqlQuery={admin.queries.getOdesByBatchId} />
      </Visibility>
      <Button
        onClick={() => openModal()}
        type="primary">
        Nueva actualización
      </Button>
    </Space>
  );
}
