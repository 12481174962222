import React, { useContext } from "react";
import { Space, Button } from "antd";
import { CreatePerkModalContext } from "../../../../../contexts/shared/create-perk-modal/context";
import { SearchToolbar } from "../../../../shared";
import { debounce } from "lodash";

export const FilterControls = ({ onChange }) => {

  const { openModal } = useContext(CreatePerkModalContext);

  const handleSearchOnChange = debounce((text = "") => {
    const value = text || undefined;
    onChange && onChange({ name: value });
  }, 500);

  return (
    <Space>
      <SearchToolbar
        onSearchChange={e => handleSearchOnChange(e.target.value)} />
      <Button
        type="primary"
        id="add-perk-button"
        onClick={openModal}>
        Nuevo perk
      </Button>
    </Space>
  );
};

