/* eslint-disable max-lines */
import React, { useState, useEffect, useContext } from "react";
import { Form, message } from "antd";
import { client } from "../../../../../graphql/";
import { admin } from "../../../../graphql/admin";
import { useMutation } from "@apollo/react-hooks";
import { shared } from "../../../../graphql/shared";
import { useParams, useHistory } from "react-router-dom";
import { remove as removeAccents } from "remove-accents";
import { OnboardingLayout } from "../../../../components/shared";
import { CurrentUserContext } from "../../../../contexts/shared";
import {
  CompanyForm
} from "../../../../components/entrepreneur/invitation/company/form";
import {
  formatEntrepreneursInvitationData
} from "../../../../helpers/format-entrepreneurs-invitation-data";
import {
  StepsNavigation
} from "../../../../components/entrepreneur/invitation/steps-navigation";

export const CompanyIndex = () => {
  const [odes, setOdes] = useState([]);
  const [selectedOde, setSelectedOde] = useState(null);
  const { currentUser, refetchCurrentUser } = useContext(CurrentUserContext);
  const [userData, setUserData] = useState(null);
  const [loadingData, setLoadingData] = useState(true);

  const [ode, setOde] = useState({
    isInvitedFromOde: false,
    id: null
  });

  const [loading, setLoading] = useState({
    next: false,
    prev: false,
    disagree: false
  });

  const { id: userId } = useParams();
  const history = useHistory();
  const [form] = Form.useForm();

  const loadUserData = async id => {
    try {
      const { data } = await client.query({
        query: shared.queries.getUserById,
        fetchPolicy: "network-only",
        variables: { id }
      });
      return data;
    } catch (e) {
      message.error("Ocurrió un error al cargar los datos del usuario");
      console.error(e);
    }
  };

  const loadOdes = async () => {
    try {
      const { data } = await client.query({
        query: admin.queries.getOdes,
        fetchPolicy: "network-only"
      });
      return data.oDEs || [];
    } catch (e) {
      message.error("Ocurrió un error al cargar los Compañías");
      console.error(e);
    }
  };

  const [connectTeamMemberToOde] = useMutation(
    admin.mutations.connectTeamMemberToOde, { client }
  );

  const [inviteEntrepreneurs] = useMutation(
    shared.mutations.inviteEntrepreneurs, { client }
  );

  const [updateUser] = useMutation(
    shared.mutations.updateEntrepreneurById, { client }
  );

  const [createOde] = useMutation(
    shared.mutations.createOde, { client }
  );

  /**
   * @param {object} ode
   * @param {string} userId
   * @returns {Promise<ODE>} The connected ODE
   */
  const handleCompany = async (ode, userId) => {
    try {
      if (ode.isNew) {
        const response = await createCompany(ode.name, userId);
        return response.data.updateUser?.teamOdes
          ?.find(teamOde => teamOde.name === ode.name);
      } else {
        await addExistingCompany(ode.id, userId);
        return ode;
      }
    } catch (e) {
      message.error("Ocurrió un error");
    }
  };

  const addExistingCompany = (odeId, userId) => {
    return updateUser({
      variables: {
        id: userId,
        data: { teamOdes: { connect: { id: odeId } } }
      }
    });
  };

  const createCompany = (odeName, userId) => {
    return createOde({
      variables: {
        data: {
          name: odeName,
          iname: removeAccents(odeName || "").toLowerCase(),
          hideDashboardAlert: true,
          team: {
            connect: {
              id: userId
            }
          }
        }
      }
    })
  };

  const addUsersToCompany = (odeId, requestedBy, team) => {
    return connectTeamMemberToOde({
      variables: {
        data: {
          requestedBy,
          teamIdList: team.map(id => ({ id }))
        },
        odeId
      }
    });
  };

  const inviteUsersToCompany = (ode, invitedBy, emails) => {
    return inviteEntrepreneurs({
      variables: {
        data: formatEntrepreneursInvitationData(emails, ode, invitedBy)
      }
    });
  };

  const onNext = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    try {
      setLoading(prev => ({ ...prev, next: true }));
      const currentUser = userData?.user
        || { id: userId, fullName: "Emprendedor" };

      const newUsers = values.team?.filter(member => member.includes("@"));
      const oldUsers = values.team?.filter(member => !member.includes("@"));

      const connectedOde = await handleCompany(selectedOde, userId);

      if (oldUsers?.length > 0) {
        await addUsersToCompany(
          connectedOde.id,
          currentUser.fullName,
          oldUsers
        );
      }
      if (newUsers?.length > 0) {
        await inviteUsersToCompany(connectedOde, currentUser, newUsers);
      }
      history.push("./welcome");
    } catch (e) {
      message.error("Sucedió un error, inténtalo más tarde");
      console.error(e);
    } finally {
      setLoading(prev => ({ ...prev, next: false }));
    }
  };

  const onPrev = () => {
    history.push(`../${userId}/update-information`);
  };

  const onAgree = () => {
    history.push("./welcome");
  };

  const onDisagree = async () => {
    setLoading(prev => ({ ...prev, disagree: true }));
    try {
      await updateUser({
        variables: {
          data: {
            teamOdes: { disconnect: { id: ode.id } },
            networkOdes: { disconnect: { id: ode.id } }
          },
          id: userId
        }
      });
      await refetchCurrentUser();
      form.resetFields();

      setOde({ isInvitedFromOde: false, id: null });
      setSelectedOde(null);
    } catch (e) {
      message.error("Ocurrió un error actualizando relación con la compañía");
    } finally {
      setLoading(prev => ({ ...prev, disagree: false }));
    }
  };

  useEffect(() => {
    const { completedProfile } = currentUser || {};

    // check if user is loaded
    if (completedProfile === undefined || completedProfile === null) {
      setLoadingData(false);
      return;
    }

    const loadData = async () => {
      setLoadingData(true);
      try {
        const userData = await loadUserData(userId);
        const odes = await loadOdes();
        setOdes(odes);
        setUserData(userData);

        if (userData?.user?.teamOdes?.length > 0 && odes?.length) {
          const odeId = userData.user.teamOdes[0].id;

          setOde({
            isInvitedFromOde: true,
            id: odeId
          });

          const ode = odes.find(ode => ode.id === odeId);
          setSelectedOde(ode);
        }
      } catch (e) {
        console.error(`loadData: ${e}`);
      } finally {
        setLoadingData(false);
      }
    };

    loadData();
  }, [currentUser]);

  /**
   * @param {string} id uuid
   * @param {string} name new ode name
   */
  const onAddOde = ({ id, name }) => {
    const newOde = { isNew: true, id, name };
    setOdes(prevOdes => ([
      ...prevOdes,
      newOde
    ]));
    setSelectedOde(newOde);
  };

  const onSelectOde = value => {
    const ode = odes.find(ode => ode.id === value);
    setSelectedOde(ode);
  };

  return (
    <OnboardingLayout>
      <StepsNavigation current={1} />
      <CompanyForm
        form={form}
        onNext={onNext}
        odeId={ode?.id}
        onPrev={onPrev}
        onAgree={onAgree}
        odes={odes || []}
        onAddOde={onAddOde}
        onDisagree={onDisagree}
        loadingButtons={loading}
        odesLoading={loadingData}
        onSelectOde={onSelectOde}
        isInvitedFromOde={ode.isInvitedFromOde} />
    </OnboardingLayout>
  );
};
