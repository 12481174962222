/* eslint-disable max-lines */
export const institutions = [
  {
    label: "Madata Accelerator",
    value: "MADATA",
    region: "N/A",
    key: "MADATA"
  },
  {
    label: "Tecnológico de Monterrey campus Aguascalientes",
    value: "AGU",
    region: "Región Occidente",
    key: "AGU"
  },
  {
    label: "Tecnológico de Monterrey campus Central de Veracruz",
    value: "VER",
    region: "Desarrollo Regional",
    key: "VER"
  },
  {
    label: "Tecnológico de Monterrey campus Chiapas",
    value: "CHP",
    region: "Desarrollo Regional",
    key: "CHP"
  },
  {
    label: "Tecnológico de Monterrey campus Chihuahua",
    value: "CHH",
    region: "Región Occidente",
    key: "CHH"
  },
  {
    label: "Tecnológico de Monterrey campus Ciudad de México",
    value: "CDMX",
    region: "Región Ciudad de México",
    key: "CDMX"
  },
  {
    label: "Tecnológico de Monterrey campus Ciudad Juárez",
    value: "JUA",
    region: "Desarrollo Regional",
    key: "JUA"
  },
  {
    label: "Tecnológico de Monterrey campus Ciudad Obregón",
    value: "SON",
    region: "Desarrollo Regional",
    key: "SON"
  },
  {
    label: "Tecnológico de Monterrey campus Cuernavaca",
    value: "MOR",
    region: "Región Centro/Sur",
    key: "MOR"
  },
  {
    label: "Tecnológico de Monterrey campus Estado de México",
    value: "MEX",
    region: "Región Ciudad de México",
    key: "MEX"
  },
  {
    label: "Tecnológico de Monterrey campus Guadalajara",
    value: "JAL",
    region: "Región Occidente",
    key: "JAL"
  },
  {
    label: "Tecnológico de Monterrey campus Hidalgo",
    value: "HID",
    region: "Región Centro/Sur",
    key: "HID"
  },
  {
    label: "Tecnológico de Monterrey campus Irapuato",
    value: "IRA",
    region: "Desarrollo Regional",
    key: "IRA"
  },
  {
    label: "Tecnológico de Monterrey campus Laguna",
    value: "LAG",
    region: "Región Monterrey",
    key: "LAG"
  },
  {
    label: "Tecnológico de Monterrey campus León",
    value: "GUA",
    region: "Región Occidente",
    key: "GUA"
  },
  {
    label: "Tecnológico de Monterrey campus Monterrey",
    value: "NLE",
    region: "Región Monterrey",
    key: "NLE"
  },
  {
    label: "Tecnológico de Monterrey campus Morelia",
    value: "MIC",
    region: "Región Occidente",
    key: "MIC"
  },
  {
    label: "Tecnológico de Monterrey campus Puebla",
    value: "PUE",
    region: "Región Centro/Sur",
    key: "PUE"
  },
  {
    label: "Tecnológico de Monterrey campus Querétaro",
    value: "QUE",
    region: "Región Centro/Sur",
    key: "QUE"
  },
  {
    label: "Tecnológico de Monterrey campus Saltillo",
    value: "COAH",
    region: "Región Monterrey",
    key: "COAH"
  },
  {
    label: "Tecnológico de Monterrey campus San Luis Potosí",
    value: "SLP",
    region: "Región Centro/Sur",
    key: "SLP"
  },
  {
    label: "Tecnológico de Monterrey campus Santa fe",
    value: "STF",
    region: "Región Ciudad de México",
    key: "STF"
  },
  {
    label: "Tecnológico de Monterrey campus Sinaloa",
    value: "SIN",
    region: "Región Occidente",
    key: "SIN"
  },
  {
    label: "Tecnológico de Monterrey campus Sonora norte",
    value: "SNT",
    region: "Región Occidente",
    key: "SNT"
  },
  {
    label: "Tecnológico de Monterrey campus Tampico",
    value: "TAM",
    region: "Región Centro/Sur",
    key: "TAM"
  },
  {
    label: "Tecnológico de Monterrey campus Toluca",
    value: "TOL",
    region: "Región Centro/Sur",
    key: "TOL"
  },
  {
    label: "Tecnológico de Monterrey campus Zacatecas",
    value: "ZAC",
    region: "Desarrollo Regional",
    key: "ZAC"
  },
  {
    label: "Universidad Técnica Particular de Loja",
    value: "UTPDL",
    region: "N/A",
    key: "UTPDL"
  },
  {
    label: "Tecnológico de Monterrey Institute for the Future of Education",
    value: "TM_IFE",
    region: "N/A",
    key: "TM_IFE"
  },
  {
    label: "Tecnológico de Monterrey Oficina de Transferencia de Tecnología",
    value: "TM_OTT",
    region: "N/A",
    key: "TM_OTT"
  }
];
