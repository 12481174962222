import { client } from "../../../graphql";
import { getAssignedExpertServices } from "./graphql";

export const getAssignedExpertServicesService = async ({
  companyId,
  batchId,
  type
}) => {
  const result = await client.query({
    query: getAssignedExpertServices,
    variables: { companyId, batchId, type },
    fetchPolicy: "network-only"
  });

  return result?.data?.assignedExpertServices || [];
};
