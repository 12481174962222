import React from "react";
import PerksList from "../../../perks/list";
import {
  CreatePerkModalProvider
} from "../../../../../contexts/shared/create-perk-modal";
import { useService } from "../../../../../hooks/shared";
import { BatchesService } from "../../../../../services";

export function Perks({ batchId }) {
  const { data: perks, loading, error } = useService(
    BatchesService.getPerks,
    { batchId },
    { shouldFetch: !!batchId }
  );

  return (
    <CreatePerkModalProvider>
      <PerksList
        error={error}
        loading={loading}
        isLoading={loading}
        data={perks ? { perks } : undefined} />
    </CreatePerkModalProvider>
  );
}
