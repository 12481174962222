import React, { useState } from "react";
import { ObjectiveContentModalContext } from "./context";
import { ObjectiveContentModal } from "./modal";

export function ObjectiveContentModalProvider({children}) {
  const [state, setState] = useState({
    isModalOpen: false,
    objectiveId: undefined
  });

  const openModal = objectiveId => {
    setState({ isModalOpen: true, objectiveId: objectiveId });
  };

  const closeModal = () => {
    setState({ isModalOpen: false, objectiveId: undefined });
  };

  const onSave = () => {
    closeModal();
  };

  return (
    <ObjectiveContentModalContext.Provider value={{ openModal, closeModal }}>
      <ObjectiveContentModal
        visible={state.isModalOpen}
        onCancel={closeModal}
        onSave={onSave}
        objectiveId={state.objectiveId} />
      {children}
    </ObjectiveContentModalContext.Provider>
  );
}
