import React, { useEffect } from "react";
import { Tabs } from "antd";
import { useLocation } from "react-router";
import { CalendarIndex } from "../../../../admin/batches/publish/calendar";
import { BatchODEsIndex } from "../../../../admin/batches/publish/companies";
import { BatchContentIndex } from "../../../../admin/batches/publish/content";
import { Announcements } from "../../../../admin/batches/publish/announcements";
import {
  BatchPublishExpertIndex
} from "../../../../admin/batches/publish/expert";
import {
  BatchAnalyticsIndex
} from "../../../../admin/batches/publish/analytics";
import {
  BatchPublishInformationIndex
} from "../../../../admin/batches/publish/information";
import {
  TabContentWrapper
} from "../../../../admin/batches/publish/tab-content-wrapper";
import { Perks } from "../../../../admin/batches/publish/perks";
import { useService } from "../../../../../hooks/shared";
import {
  BatchContentService
} from "../../../../../services/batch-content-service";
import useTreatment from "../../../../../hooks/feature-flags/use-treatment";

export function AdminTabPanes ({
  tabsProps,
  batch,
  refetchBatch,
  showExpert,
  setActiveTab,
  defaultActiveTab
}) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const announcementId = params.get("announcement");
  const companyId = params.get("companyId");

  const {
    data: masterContents,
    error: errorMasterContent,
    loading: loadingMasterContent,
    refetch: refetchMasterContents
  } = useService(
    BatchContentService.getMasterContent,
    { id: batch?.id },
    { shouldFetch: !!batch?.id }
  );

  useEffect(() => {
    const keyName = defaultActiveTab || "information";
    if (announcementId) {
      setActiveTab(
        `./offer?section=announcements&announcement=${announcementId}`,
        "announcements"
      );
    } else if (companyId) {
      setActiveTab(
        `./offer?section=companies&companyId=${companyId}`,
        "companies"
      );
    } else {
      setActiveTab(`./offer?section=${keyName}`, keyName);
    }
  }, []);

  const announcementsTabTreatmentValue =
    useTreatment("AnnouncementsTab");

  return (
    <Tabs {...tabsProps}>
      <Tabs.TabPane tab="Información General" key="information">
        <TabContentWrapper>
          <BatchPublishInformationIndex
            data={{ batch }}
            masterContents={masterContents}
            loadingMasterContent={loadingMasterContent}
            errorMasterContent={errorMasterContent}
            refetchBatch={refetchBatch} />
        </TabContentWrapper>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Contenido" key="content">
        <TabContentWrapper>
          <BatchContentIndex
            masterContents={masterContents}
            loadingMasterContent={loadingMasterContent}
            errorMasterContent={errorMasterContent}
            refetchMasterContents={refetchMasterContents} />
        </TabContentWrapper>
      </Tabs.TabPane>
      { showExpert
        ? <Tabs.TabPane tab="Expertos" key="expert">
          <TabContentWrapper>
            <BatchPublishExpertIndex
              data={{ batch }}
              refetch={refetchBatch} />
          </TabContentWrapper>
        </Tabs.TabPane>
        : null
      }
      <Tabs.TabPane tab="Compañías" key="companies">
        <TabContentWrapper>
          <BatchODEsIndex companyId={companyId} />
        </TabContentWrapper>
      </Tabs.TabPane>
      {
        announcementsTabTreatmentValue === "ON"
          ? <Tabs.TabPane tab="Avisos" key="announcements">
            <TabContentWrapper>
              <Announcements
                announcementId={announcementId}
                batchId={batch?.id} />
            </TabContentWrapper>
          </Tabs.TabPane>
          : null
      }
      <Tabs.TabPane tab="Calendario" key="calendar">
        <TabContentWrapper>
          <CalendarIndex />
        </TabContentWrapper>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Analytics" key="analytics">
        <TabContentWrapper>
          <BatchAnalyticsIndex />
        </TabContentWrapper>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Perks" key="perks">
        <TabContentWrapper>
          <Perks batchId={batch?.id} />
        </TabContentWrapper>
      </Tabs.TabPane>
    </Tabs>
  );
}
