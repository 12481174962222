import React, { useState } from "react";
import { Modal } from "antd";
import { CompanyFundForm } from "../../../components/shared/company-forms/milestone/fund";
import { CompanyCompetenceForm } from "../../../components/shared/company-forms/milestone/competence";
import { CompanyAchievementForm } from "../../../components/shared/company-forms/milestone/achivement";
import { CompanyNewsForm } from "../../../components/shared/company-forms/milestone/news";

export const CompanyMilestonesModal = ({
  form,
  type = "FUND",
  open,
  loading,
  onOk,
  onCancel
}) => {

  const [competenceNonePlace, setCompetenceNonePlace] = useState(false);

  const onCompetenceNonePlace = value => {
    setCompetenceNonePlace(value === "NONE");
  };

  const milestonesNames = {
    "FUND": "Fondo",
    "COMPETENCE": "Competencia",
    "ACHIEVEMENT": "Logro",
    "NEWS": "Noticia"
  };

  const milestonesForms = {
    "FUND": <CompanyFundForm form={form} />,
    "COMPETENCE": <CompanyCompetenceForm
      form={form}
      nonePlace={competenceNonePlace}
      onPlaceChange={onCompetenceNonePlace} />,
    "ACHIEVEMENT": <CompanyAchievementForm form={form} />,
    "NEWS": <CompanyNewsForm form={form} />
  };

  return (
    <Modal
      title={`Agregar ${milestonesNames[type]}`}
      cancelButtonProps={{ className: "secundary-btn" }}
      okButtonProps={{ loading }}
      okText="Guardar"
      width="812px"
      onOk={onOk}
      onCancel={onCancel}
      visible={open}>
      {milestonesForms[type]}
    </Modal>
  );
};
