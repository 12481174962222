import gql from "graphql-tag";

export const getOdeReports = gql`
  query GetOdeReports(
    $odeId: ID!
    $firstDate: DateTime
    $lastDate: DateTime
  ) {
    odeReports(
      orderBy: startDate_ASC
      where: {
        ode: { id: $odeId }
        startDate_gte: $firstDate
        startDate_lte: $lastDate
      }
    ) {
      id
      startDate
      endDate
      companyStatus
      presales
      monthlySales
      productInDevelopment
      patentStatus
      productPhase
      trlLevels
      fundings
      contests
      hasEmployees
      currentEmployeesNumber
      terminatedEmployees
      hiredEmployees
      achievements
    }
  }
`;
