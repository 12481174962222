/**
 * Removes break lines and whitespaces at the beggining and end of the string
 * @param {string} text
 * @returns an string without break lines and whitespaces
 */
export function clearString(text = "") {
  return text
    .replace(/(\r\n|\n|\r)/gm, "")
    .trim();
}
