import gql from "graphql-tag";

export const deleteProgram = gql`
  mutation DeleteProgram( $id: ID! ){
    deleteProgram(
      where: {
        id: $id
      }
    ) {
      id
    }
  }
`;