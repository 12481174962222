import React, { useState, useEffect } from "react";
import { Modal, message, Tabs, Skeleton, Form, Alert } from "antd";
import moment from "moment";
import { GeneralInfoForm } from "./general-info-form";
import { ContactForm } from "./contact-form";
import { Visibility } from "../../../components/shared";

export function ProgramModal({
  onSave,
  onCancel,
  program,
  loading,
  ...props}) {
  const [form] = Form.useForm();
  const [state, setState] = useState({
    activeKey: "general-information"
  });

  useEffect(() => {
    if (program) {
      form.setFieldsValue({
        ...program,
        coordinator: program.coordinator?.id,
        date: program.createdAt ? moment(program.createdAt) : null
      });
    }
  }, [program]);

  const setActiveTab = keyName => {
    setState(prevState => ({
      ...prevState,
      activeKey: keyName
    }));
  };

  const verifyContactForm = async values => {
    if (!values.name || !values.description || !values.type || !values.origin || !values.coordinator) {
      setActiveTab("general-information");
      message.warning("Campos faltantes. Verifica los datos de información\
      general.");
      await form.validateFields();
      throw("Missing general information fields");
    }

    if (!values.email || !values.phone) {
      setActiveTab("contact");
      message.warning("Campos faltantes. Verifica los datos de contacto.");
      await form.validateFields();
      throw("Missing contact information fields");
    }
  };

  const onOk = async() => {
    const dismissLoading = message.loading("Guardando...");
    const values = form.getFieldsValue();

    /* if program has batches allow edit only coverPicUrl */
    if(program?.batches?.length) {
      const data = {
        variables: {
          id: program?.id || "",
          update: { coverPicUrl: values.coverPicUrl },
          create: {}
        }
      };

      try {
        onSave && await onSave(data);
        form.resetFields();
        setActiveTab("general-information");
      }
      catch(e) {
        console.error(e);
        message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
          en unos segundos.");
      }
      finally {
        dismissLoading();
      }

      return;
    }

    try {
      values.email = values.email !== undefined ? values.email : program?.email;
      values.phone = values.phone !== undefined ? values.phone : program?.phone;

      await verifyContactForm(values);


      delete values.date;
      values.coordinator = { connect: { id: values.coordinator } };

      const data = {
        variables: {
          id: program?.id || "",
          update: values,
          create: values
        }
      };

      onSave && await onSave(data);
      message.success("Programa guardado.");

      form.resetFields();
      setActiveTab("general-information");
    }
    catch(e) {
      if (e.message?.match(/NAME_ALREADY_TAKEN/)) {
        return message.warning(`Ya existe un programa con el nombre\
           "${values.name}". Por favor, ingresa un nombre diferente.`);
      }

      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    }
    finally {
      dismissLoading();
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    setActiveTab("general-information");
    onCancel && onCancel();
  };

  return (
    <Modal
      destroyOnClose
      okText="Guardar"
      cancelText="Cancelar"
      title={program ? "Editar programa" : "Nuevo programa"}
      onOk={onOk}
      onCancel={onCancelModal}
      {...props}>
      <Visibility visible={loading}>
        <Skeleton loading />
        <Skeleton loading />
      </Visibility>
      <Visibility visible={!loading}>
        <Visibility visible={program?.batches?.length}>
          <Alert
            type="warning"
            message="Este programa ya tiene batches activos.
              Solo puedes cambiar su logotipo."
            showIcon />
        </Visibility>
        <Tabs
          onTabClick={tabKeyName => setActiveTab(tabKeyName)}
          defaultActiveKey="general-information"
          activeKey={state.activeKey}>
          <Tabs.TabPane tab="Información" key="general-information">
            <GeneralInfoForm form={form} editable={!program?.batches?.length} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Contacto" key="contact">
            <ContactForm form={form} editable={!program?.batches?.length} />
          </Tabs.TabPane>
        </Tabs>
      </Visibility>
    </Modal>
  );
}
