import { Card, Row, Space, Col } from "antd";
import React from "react";
import { CoverImage } from "../../../../shared";
import { PictureOutlined } from "@ant-design/icons";

export function WorkspaceItem({ workspace, extra }) {
  return (
    <Card cover={
      <CoverImage
        text={workspace?.name}
        url={workspace?.coverPicUrl}
        icon={PictureOutlined} />}>
      <Card.Meta
        title={workspace?.name}
        description={
          <Space
            direction="vertical"
            style={{ width: "100%" }}>
            <Row
              style={{paddingTop: "20px"}}
              justify="space-between"
              align="bottom">
              <Col span={12}>
                {extra}
              </Col>
              <Col span={12}></Col>
            </Row>
          </Space>
        } />
    </Card>
  );
}
