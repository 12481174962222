import gql from "graphql-tag";
export const getOdesByExpertId = gql`
  query GetOdesByExpertId(
    $id: ID!
    $searchString: String
    $batches_some: BatchWhereInput
  ){
    oDEs(
      where: {
        iname_contains: $searchString
        team_some: { id: $id }
        batches_some: $batches_some
      }
    ){
      id
      iname
      name
      logoUrl
      sector
      team {
        fullName
        profilePicUrl
      }
      batches {
        program {
          type
        }
      }
    }

    meta: oDEsConnection(
        where: {
            iname_contains: $searchString
            team_some: { id: $id }
            batches_some: $batches_some
        }
    ) {
      aggregate {
        count
      }
    }
  }
`;
