import React from "react"
import { View, Text, StyleSheet } from "@react-pdf/renderer"
import { Line } from "../../../components/line";
import { Title } from "../../../components/title";

export const General = ({ data }) => {
  return (
    <View>
      <Title>GENERAL</Title>
      <View style={styles.body}>
        <View style={{ fontSize: 12, fontWeight: 400 }}>
          <Text style={{ fontSize: 16, fontWeight: 400, marginBottom: 5, fontWeight: 700 }}>{data.name}</Text>
          <Text>Experto: {data.expert}</Text>
          <Text>Compañía: {data.ode}</Text>
        </View>
        <View style={styles.status}>
          <Text style={{ marginBottom: 5 }}>Estatus {data.status}</Text>
          <Text>{data.date}</Text>
        </View>
      </View>
      <Line />
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row"
  },
  status: {
    fontSize: 14,
    fontWeight: 700,
    display: "flex",
    alignItems: "flex-end"
  },
});
