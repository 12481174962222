import React from "react";
import { Space, Typography } from "antd";
import {
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
  CalendarOutlined,
  EnvironmentOutlined
} from "@ant-design/icons";
import { Avatar, EditButton, IconLabel, Format, Visibility } from "../../../../shared";

export const Details = ({
  decoratedUser = {},
  setEditing,
  allowEdit = false
}) => {
  return (
    <Space
      direction="horizontal"
      size={20}
      align="start">
      <Avatar size={60}>
        {decoratedUser.profilePicUrl || decoratedUser.fullName}
      </Avatar>
      <Space direction="vertical" size={5}>
        <Space direction="horizontal">
          <Typography.Title
            level={4}
            style={{marginBottom: "0px"}}>
            {decoratedUser.fullNameInCaps}
          </Typography.Title>
          <Visibility visible={allowEdit}>
            <EditButton onClick={setEditing} />
          </Visibility>
        </Space>
        <Format type="secondary">
          <div style={{maxWidth: "700px"}}>
            {decoratedUser.resume}
          </div>
        </Format>
        <Space direction="horizontal">
          <IconLabel icon={<MailOutlined />}>
            <Format type="secondary">
              {decoratedUser.displayEmail}
            </Format>
          </IconLabel>
          <IconLabel icon={<PhoneOutlined />}>
            <Format type="secondary">
              {decoratedUser.displayPhone}
            </Format>
          </IconLabel>
          <IconLabel icon={<UserOutlined />}>
            <Format type="secondary">
              {decoratedUser.displayGender}
            </Format>
          </IconLabel>
          <IconLabel icon={<CalendarOutlined />}>
            <Format type="secondary">
              {decoratedUser.readableBirthday}
            </Format>
          </IconLabel>
          <IconLabel icon={<EnvironmentOutlined />}>
            <Format type="secondary">{decoratedUser.address}</Format>
          </IconLabel>
        </Space>
      </Space>
    </Space>
  );
};
