import gql from "graphql-tag";

export const GET_BATCH_PUBLISHED_MASTER_CONTENTS = gql`
  query GET_BATCH_PUBLISHED_MASTER_CONTENTS($batchId: ID!) {
    masterContents (
      where: {
        batch: { id: $batchId }
      }
    ) {
      contentGroupsSort
      contentGroups: contentGroups(
        where: {
          OR: [{ shouldBeDeleted: false }, { shouldBeDeleted: null }]
        }
      ) {
        id
        title
        contentsSort
        shouldBeDeleted
        contents: contents(
          where: {
            OR: [{ shouldBeDeleted: false }, { shouldBeDeleted: null }]
          }
        ) {
          id
          shouldBeDeleted
          name
          type  
        }
      }
      publishedContents(where: { published: true }) {
        id
        published
        ode {
          id
          name
          odeContents: contents(
            where: {
              batch: { id: $batchId }
              content: {
                OR: [{ shouldBeDeleted: false }, { shouldBeDeleted: null }]
              }
            }
          ) {
            id
            content {
              shouldBeDeleted
              id
              name
              type  
            }
            status
          }
        }
        customOdeContent {
          id
        }
        excludedContentGroups {
          id
        }
      }
    }
  }
`;
