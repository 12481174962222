import React from "react";
import { Descriptions } from "antd";
import { FormContainer } from "../../..";

export function Details({ data = {} }) {
  return(
    <FormContainer>
      <Descriptions column={1}>
        <Descriptions.Item label="Problema">
          {data?.problem}
        </Descriptions.Item>
        <Descriptions.Item label="Solución">
          {data?.solution}
        </Descriptions.Item>
        <Descriptions.Item label="Demo">
          {data?.demoUrl}
        </Descriptions.Item>
        <Descriptions.Item label="Mercado">
          {data?.market}
        </Descriptions.Item>
        <Descriptions.Item label="Modelo de negocio">
          {data?.businessModel}
        </Descriptions.Item>
      </Descriptions>
    </FormContainer>
  );
}