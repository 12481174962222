import { withRouter as reactWithRouter } from "react-router-dom";

export function withRouter(func) {
  return reactWithRouter(({
    history,
    match,
    ...props
  }) => {
    const navigate = path => {
      history.push(path);
    };

    return func({navigate, match, history, ...props});
  });
}
