import gql from "graphql-tag";

export const getAssignedServiceById = gql`
  query GetAssignedServiceById($id: ID!) {
    assignedExpertService(where: { id: $id }) {
      id
      limitDate
      expertServiceData
    }
  }
`;
