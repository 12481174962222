import { cloneDeep } from "lodash";
import moment from "moment";
import {
  getReadableValue,
  odeTecnologyLevels,
  odeProfitMotives,
  commercialBussines,
  odeEconomicActivities
} from "../../select-options";
import { countries } from "../../../utils";

function addPlaceholder(text, placeholder = "N/A") {
  return (text || placeholder);
}

export function ode(data = {}) {
  const decorated = cloneDeep(data);
  decorated.object = cloneDeep(data);

  decorated.description = decorated.description || "Sin descripción";
  decorated.email = addPlaceholder(decorated.email);
  decorated.phone = addPlaceholder(decorated.phone);
  decorated.technologyLevel = getReadableValue(
    odeTecnologyLevels,
    decorated.technologyLevel
  ) || "N/A";

  // nombre comercial
  decorated.tradeName = addPlaceholder(decorated.tradeName);
  // razón social
  decorated.businessName = addPlaceholder(decorated.businessName);
  decorated.description = addPlaceholder(decorated.description);
  decorated.technology = addPlaceholder(decorated.technology);
  decorated.commercialBussines = getReadableValue(
    commercialBussines,
    decorated.commercialBussines
  ) || "N/A";

  // use the legacy sector if there is not economicSector value yet
  decorated.object.economicSector = decorated.economicSector ??
    getReadableValue("ODE_SECTORS", decorated.sector);

  decorated.economicSector = getReadableValue(
    "ODE_SECTORS",
    decorated.economicSector || decorated.sector
  );

  // use the legacy industry if there is not economicIndustry value yet
  decorated.object.economicIndustry = decorated.economicIndustry ??
    getReadableValue("INDUSTRIES", decorated.industry);

  decorated.economicIndustry = getReadableValue(
    "INDUSTRIES",
    decorated.economicIndustry || decorated.industry
  );

  decorated.economicActivity =
    odeEconomicActivities
      .find(activity => activity.value === decorated.economicActivity)
      ?.label ?? decorated.economicActivity;

  decorated.keywords = Array.isArray(decorated?.keywords) ?
  decorated?.keywords?.join(", ")
    : "N/A";
  decorated.profitMotives = getReadableValue(
    odeProfitMotives,
    decorated.profitMotives
  ) || "N/A";

  decorated.country = countries
    .find(c => c.id == decorated.address?.country)
    ?.name;
  decorated.state = addPlaceholder(decorated.address?.state);
  decorated.city = addPlaceholder(decorated.address?.city);
  decorated.street = addPlaceholder(decorated.address?.street);
  decorated.neighborhood = addPlaceholder(decorated.address?.neighborhood);
  decorated.zipCode = addPlaceholder(decorated.address?.zipCode);

  //Pitch
  decorated.businessModel = addPlaceholder(decorated.pitch?.businessModel);
  decorated.problem = addPlaceholder(decorated.pitch?.problem);
  decorated.solution = addPlaceholder(decorated.pitch?.solution);
  decorated.market = addPlaceholder(decorated.pitch?.market);

  decorated.rfc = addPlaceholder(decorated.rfc);
  decorated.fiscalEntryDate = decorated?.fiscalEntryDate ?
    moment(decorated.fiscalEntryDate).format("DD [de] MMMM [de] YYYY") : "N/A";
  decorated.fiscalRepresentative = addPlaceholder(
    decorated.fiscalRepresentative);

  return decorated;
}
