import gql from "graphql-tag";

export const getPersonExperienceAttendanceByAttendanceIdQuery = gql`
    query getPersonExperienceAttendanceByAttendanceIdQuery(
        $personExperienceAttendanceId: ID!
    ) {
        personExperienceAttendance(
            personExperienceAttendanceId: $personExperienceAttendanceId
        ) {
            personExperienceAudience
            npsScore
            npsScoreReason
            experience {
                experienceId
                name
                description
                startDate
                endDate
                durationInHours
                involvementLevel
                type
                category
                audience
                minimumAudienceSemester
                maximumAudienceSemester
                input
                output
                callToAction
                curriculumType
                ufCode
                uforganizedByMTYTecCode
                campus
                institution
                client
                projectProfiles
            }
        }
    }

`;
