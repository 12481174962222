import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { PageLeavePromptContext } from "./context";

export function PageLeavePromptProvider({ children }) {
  const [shouldPrompt, setShouldPrompt] = useState(false);

  useEffect(() => {
    window.onbeforeunload = () => {
      if (shouldPrompt) {
        return true;
      } else {
        return undefined;
      }
    };

    // remove the callback when the context provider is destroyed
    return () => {
      window.onbeforeunload = null;
    };
  }, [shouldPrompt]);

  const onSetShouldPrompt = (shouldPrompt = true) => {
    setShouldPrompt(shouldPrompt);
  };

  return (
    <PageLeavePromptContext.Provider
      value={{ setShouldPrompt: onSetShouldPrompt }}>
      <Prompt
        when={shouldPrompt}
        message="Tienes cambios sin guardar. Si abandonas la página
          tus cambios se perderán. ¿Deseas continuar?" />
      {children}
    </PageLeavePromptContext.Provider>
  );
}
