import React, { useEffect } from "react";
import { Row, Form, Input, DatePicker, Col, Select } from "antd";
import moment from "moment";
import { CloseCircleOutlined } from "@ant-design/icons";
import { fieldValidationMessages } from "../../../../../../../utils";
import { educationDegreesList } from "../../../../../../../helpers/select-options";

export function EducationItem({
  education,
  setStateForm,
  removeItem,
  onChange,
  idx
}) {
  const [form] = Form.useForm();
  setStateForm(form);

  useEffect(() => {
    if (education) {
      const degree =
        educationDegreesList.some(el => el.value === education?.degree);
      form.setFieldsValue({
        ...education,
        degree: degree ? education?.degree : "",
        graduationDate: education?.graduationDate ?
          moment(education?.graduationDate)
          : undefined
      });
    }
  }, [education]);

  return (
    <Row gutter={2} style={{ width: "100%" }}>
      <Col span={1}>
        <CloseCircleOutlined onClick={removeItem(idx)} />
      </Col>
      <Col span={23}>
        <Form
          layout="vertical"
          validateMessages={fieldValidationMessages}
          name={`education-input-form-${idx}`}
          form={form}
          onValuesChange={(_, allValues) => onChange(allValues)}>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item
            name="institution"
            label="Institución educativa">
            <Input placeholder="Institución educativa" />
          </Form.Item>
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="degree"
                label="Grado">
                <Select options={educationDegreesList} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="specialityArea"
                label="Área de especialidad">
                <Input placeholder="Área de especialidad" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="graduationDate"
            label="Fecha de graduación">
            <DatePicker
              picker="year"
              format="[Año] YYYY"
              style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
