import React, { useContext } from "react";
import { Card } from "antd";
import {
  EditOdeContext
} from "../../../../../contexts/shared/edit-ode";
import { EditSummaryForm } from "../form";
import { Details } from "../summary-details";
import { withLoader } from "../../../../../helpers/withLoader/index";

function SummaryEditSection({ editable = false }) {
  const { editing } = useContext(EditOdeContext);

  return (
    <Card
      bordered={false}
      style={{borderBottom: "solid 1px #f4f4f4"}}>
      { (editing?.summary) ?
        <EditSummaryForm />
        : <Details allowEdit={editable} /> }
    </Card>
  );
}

export default withLoader(SummaryEditSection);
