import React, { useContext, useEffect } from "react";
import { Form } from "antd";
import moment from "moment";
import { FormContainer } from "../../../";
import {
  EditProfileContext
} from "../../../../../contexts/shared/edit-profile";
import { EntrepreneurshipsInput } from "./entrepreneurships-input";

export function EntrepreneurshipsForm({ editable = true, form }) {
  const { data } = useContext(EditProfileContext);

  const {
    entrepreneurships = []
  } = data?.user?.expertProfile?.professionalExperience || {};

  useEffect(() => {
    const value = entrepreneurships
      .map(({ startDate, endDate, ...e }) => ({
        ...e,
        period: [ // period is saved as "start" and "end" dates in the DB
          startDate ? moment(startDate) : null,
          endDate ? moment(endDate) : null
        ]
      }));

    form.setFieldsValue({ entrepreneurships: value });
  }, [data]);

  return (
    <FormContainer>
      <Form form={form}>
        <Form.Item
          name="entrepreneurships">
          <EntrepreneurshipsInput
            editable={editable} />
        </Form.Item>
      </Form>
    </FormContainer>
  );
}
