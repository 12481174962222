import React from "react";
import { HeroAsem, Section } from "../../../components/shared";
import OrganizationLayout from "../../../components/shared/organization/layout";
import { Typography } from "antd";
import { ServicesLayerProvider } from "../../../contexts/shared";
import {
  AsemOrganizationsTabPanes
} from "../../../components/asem/organizations/tab-panes";
import { AsemOrganizationService } from "../../../services/asem";

export function AsemOrganizationIndex() {
  return (
    <ServicesLayerProvider services={{
      AsemOrganizationService
    }}>
      <OrganizationLayout isAsemAdmin>
        <HeroAsem />
        <Section
          className="OrganizationAsem">
          <Typography.Title
            className="OrganizationAsem__title"
            level={2}>
            Solicitudes de organizaciones
          </Typography.Title>
          <AsemOrganizationsTabPanes />
        </Section>
      </OrganizationLayout>
    </ServicesLayerProvider>
  );
}
