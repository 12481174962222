import { getDeletedIdsFromTwoArrays } from "../../../../../helpers/get-deleted-ids-from-two-arrays";

export const formatValues = (values = [], oldLanguages) => {
  const upsertValues = values.map(({ id = "", ...language }) => {
    delete language.__typename;
    delete language.uuid;
    delete language.index;

    return ({
      where: { id: id },
      create: { ...language },
      update: { ...language }
    });
  });

  const createValues = upsertValues.map(v => v.create);

  const deletedLanguagesIds = getDeletedIdsFromTwoArrays(values, oldLanguages);

  return ({
    expertProfile: {
      upsert: {
        create: {
          professionalExperience: {
            create: {
              languages: { create: createValues }
            }
          }
        },
        update: {
          professionalExperience: {
            upsert: {
              create: {
                languages: { create: createValues }
              },
              update: {
                languages: {
                  upsert: upsertValues,
                  deleteMany: { id_in: deletedLanguagesIds }
                }
              }
            }
          }
        }
      }
    }
  });
};
