import { client } from "../../../graphql";
import { getCalendarEventsQuery } from "./graphql";

/**
 * @param {string} batchId
 * @param {number} year
 * @param {number} month
 * @return {Promise<*|*[]>}
 */
export async function getCalendarEvents({
  batchId,
  year,
  month
}) {
  const result = await client.query({
    query: getCalendarEventsQuery,
    fetchPolicy: "network-only",
    variables: {
      batchId,
      year,
      month
    }
  });

  return result.data?.batchCalendarEvents || [];
}
