import React, { useState, useEffect,useContext } from "react";
import { Select, Typography, Spin } from "antd";
import { useQuery } from "react-apollo-hooks";
import { admin } from "../../../../../graphql/admin";
import { coordinator } from "../../../../../graphql/coordinator";
import { client } from "../../../../../../graphql";
import { LoadingOutlined } from "@ant-design/icons";
import { CurrentUserContext } from "../../../../../contexts/shared";

export function BatchesSelect({ expertProfile, onChange, programId }) {
  const [state, setState] = useState({
    options: []
  });

  const { currentUser } = useContext(CurrentUserContext);

  const query = currentUser.systemRole === "COORDINATOR" ?
    coordinator.queries.getBatchesByProgramId
    : admin.queries.getBatchesByProgramId;

  const { data, loading, error } = useQuery(query, {
    client,
    variables: {
      id: programId,
      coordinatorId: currentUser.id || "",
      orderBy: "name_ASC"
    },
    fetchPolicy: "network-only"
  });

  const getSelectOptions = () => {
    if(expertProfile && expertProfile.private) {
      const batches = expertProfile.privateBatches;
      const filteredBatches = batches.filter(batch => batch.program.id === programId);
      return filteredBatches.map(batch => ({
        label: batch.name,
        value: batch.id
      }));
    } else {
      const batches = Array.from(data?.batches || []);
      return batches.map(batch => ({
        label: batch.name,
        value: batch.id
      }));
    }
  }

  useEffect(() => {
    const options = getSelectOptions();

    setState({ options });
  }, [programId, data]);

  const handleOnChange = batchId =>
    onChange && onChange(batchId);

  if(loading) {
    return (
      <Spin indicator={<LoadingOutlined />} />
    );
  }

  return (
    <>
      <Select
        data-cy="batches-select"
        placeholder="Seleccionar batch"
        optionFilterProp="label"
        allowClear
        showSearch
        disabled={loading || error || !programId}
        loading={loading}
        onChange={handleOnChange}
        options={state.options} />
      <Typography.Text type="danger">
        {error && "No se pudieron cargar los batches"}
      </Typography.Text>
    </>
  );
}
