import React from "react";
import { Button, Row, Divider, List, Typography } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { FlexContainer } from "../../../../../shared";
import { ExamplesDisplay } from "../../../../../shared/examples-display";
import { ScheduleExpertServiceForm } from "../../components/forms/schedule-expert-service-form";

export function DetailContent ({
  form,
  onSchedule,
  assignedExpertService = null
}) {
  const expertServiceData = assignedExpertService?.expertServiceData || {};
  const { examples = [], links = [] } = expertServiceData;

  return (
    <FlexContainer direction="vertical">
      <Typography.Title strong level={5}>
        Agendar sesión
      </Typography.Title>
      <ScheduleExpertServiceForm form={form} isService />
      <Divider />
      <Typography.Title strong level={5}>
        Información de la mentoría
      </Typography.Title>
      <br />
      <Typography.Text type="secondary">
        Descripción:
      </Typography.Text>
      <Typography.Text>
        {expertServiceData?.description}
      </Typography.Text>
      <br />
      <Typography.Text type="secondary">
        Galería de ejemplos:
      </Typography.Text>
      <ExamplesDisplay examples={examples} />
      <br />
      <Typography.Text type="secondary">
        Enlaces y archivos:
      </Typography.Text>
      <List
        size="small"
        dataSource={links}
        renderItem={link => (
          <List.Item key={link}>
            <Typography.Link
              href={link}
              target="_blank">
              <LinkOutlined /> {link}
            </Typography.Link>
          </List.Item>)
        } />
      <Row
        style={{ width: "100%" }}
        justify="end">
        <Button onClick={onSchedule}>Agendar</Button>
      </Row>
    </FlexContainer>
  );
}