import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  default as ExpertServicesIndex
} from "../../../pages/admin/experts/show/services";
import {
  ServicesIndex
} from "../../../pages/admin/experts/services";
import {
  default as ProfileIndex
} from "../../../pages/admin/experts/show/profile";
import {
  default as ExpertsIndex
} from "../../../pages/admin/experts";
import {
  MonitoringServicesIndex
} from "../../../pages/admin/experts/services/monitoring";
import { default as NewExpertIndex } from "../../../pages/admin/experts/new";
import { default as ProfessionaInformationIndex } from "../../../pages/admin/experts/new/professional-information";
import { default as AccountsIndex } from "../../../pages/admin/experts/new/accounts";
import { default as EnterpreneurshipsIndex } from "../../../pages/admin/experts/new/enterpreneurships";
import { PaymentReportIndex } from "../../../pages/admin/experts/services/payment-report";
import {
  AssignationsMonitoringIndex
} from "../../../pages/admin/experts/services/monitoring/assignations";
import {
  TrackingMonitoringServicesIndex
} from "../../../pages/admin/experts/services/monitoring/tracking";

export const AdminExpertsRoutes = <Switch>
  <Route
    exact
    path="/v2/admin/experts/services/monitoring"
    component={MonitoringServicesIndex} />
  <Route
    path="/v2/admin/experts/services/monitoring/assignations"
    component={AssignationsMonitoringIndex} />
  <Route
    path="/v2/admin/experts/services/monitoring/tracking"
    component={TrackingMonitoringServicesIndex} />
  <Route
    path="/v2/admin/experts/services/:id/payment-report"
    component={PaymentReportIndex} />
  <Route
    exact
    path="/v2/admin/experts/services"
    component={ServicesIndex} />
  <Route
    path="/v2/admin/experts/:expertId/services"
    component={ExpertServicesIndex} />
  <Route
    path="/v2/admin/experts/:expertId/profile"
    component={ProfileIndex} />
  <Route
    exact
    path="/v2/admin/experts"
    component={ExpertsIndex} />
  <Route
    exact
    path="/v2/admin/experts/new/personal-information"
    component={NewExpertIndex} />
  <Route
    exact
    path="/v2/admin/experts/new/:id/personal-information"
    component={NewExpertIndex} />
  <Route
    exact
    path="/v2/admin/experts/new/:id/professional-information"
    component={ProfessionaInformationIndex} />
  <Route
    exact
    path="/v2/admin/experts/new/:id/accounts"
    component={AccountsIndex} />
  <Route
    exact
    path="/v2/admin/experts/new/:id/entrepreneurships"
    component={EnterpreneurshipsIndex} />
</Switch>;
