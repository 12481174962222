import React from "react";
import { Typography, List, Space } from "antd";
import { readablePeriod } from "./readablePeriod";
import { EditButton, DeleteButton } from "../../../../";

export function EntrepreneurshipItem({
  entrepreneurship,
  onEdit,
  onDelete,
  editable
}) {
  const {
    name = "Sin título",
    position = "N/A",
    achievements = "N/A",
    period = []
  } = entrepreneurship;

  return (
    <List.Item style={{position: "relative"}}>
      { editable && (
        <div style={{top: "0", right: "0", position: "absolute"}}>
          <EditButton onClick={onEdit} />
          <DeleteButton
            onClick={onDelete}
            confirmText="¿Quieres eliminar este emprendimiento?"
            confirm />
        </div>
      )}
      <Space size={5} direction="vertical">
        <Typography.Text style={{fontSize: "16px"}}>{name}</Typography.Text>
        <Typography.Text type="secondary">{ position }</Typography.Text>
        <Typography.Text type="secondary">{ achievements }</Typography.Text>
        <Typography.Text>{ readablePeriod(period) }</Typography.Text>
      </Space>
    </List.Item>
  );
}
