import React from "react";
import { Alert, Modal } from "antd";

/**
 * WIP
 * @todo: Implement functionality to show the right stuff in the
 * modal header using the mode prop and the data props
 * @param children
 * @param {React.ReactNode} header
 * @param {React.ReactNode} footer
 * @param {React.ReactNode} alert
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function BaseServiceDetailModal({
  header,
  children,
  footer = undefined,
  alert = null,
  ...props
}) {
  return (
    <Modal
      {...props}
      width={840}
      bodyStyle={{ backgroundColor: "#E5E5E5", padding: "20px" }}
      footer={footer}
      title={<div style={{ fontSize: "14px" }}>{header}</div>}>
      {alert}
      <div style={{ backgroundColor: "#fff", padding: "20px" }}>
        {children}
      </div>
    </Modal>
  );
}
