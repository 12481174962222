import React, { useContext } from "react";
import { Form } from "antd";
import { Section, Visibility } from "../../..";
import { EditOdeContext } from "../../../../../contexts/shared/edit-ode";
import { ActionButton } from "../../action-button";
import { FiscalInfoForm } from "../form";

export function FiscalInfoEditSection({ editable = false }) {
  const {
    saveChanges,
    editing,
    setEditing
  } = useContext(EditOdeContext);

  const sectionName = "fiscal";

  const onSave = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    saveChanges({
      ...values,
      keywords: { set: values?.keywords }
    },
    sectionName);
  };

  const [form] = Form.useForm();

  return (
    <Section
      spaced
      title="Información fiscal"
      extra={
        <Visibility visible={editable}>
          <ActionButton
            editing={editing[sectionName]}
            setEditing={setEditing}
            formName={sectionName}
            visible
            onSave={onSave}>
          </ActionButton>
        </Visibility>
      }>
      <FiscalInfoForm
        form={form}
        editable={editing[sectionName] && editable } />
    </Section>
  );
}
