/* eslint-disable max-lines */
import moment from "moment";

export const getEngagementLevenChartConfig = ({
  attendances: personData = []
}) => {
  const experiences = personData.map(el => el?.experience);
  let maxYear = 0;
  let minYear = 10000;

  const labels = experiences.reduce((acc, experience) => {
    const { involvementLevel, startDate } = experience;
    if (!involvementLevel || !startDate) return acc;

    const monthNumber = parseInt(moment(startDate).format("MM"));
    const monthIndex = monthNumber - 1;

    const year = parseInt(moment(startDate).format("YYYY"));

    const month = `${labelsX[monthIndex]} ${year}`;
    const monthCount = (acc?.[involvementLevel]?.[month] || 0) + 1;

    if (year > maxYear) maxYear = year;
    if (year < minYear) minYear = year;

    acc[involvementLevel] = {
      ...(acc?.[involvementLevel] || {}),
      [month]: monthCount
    };

    return acc;
  }, {});

  let yearsList = [];
  for (let i = minYear; i <= maxYear; i++) {
    yearsList = [...yearsList, i];
  }

  const monthLabels = yearsList.flatMap(year => {
    return labelsX.map(label => `${label} ${year}`);
  });

  const bubblesDataSets = Object.entries(EXPERIENCE_LEVEL_INFO)
    .map(el => {
      const experienceJson = labels?.[el[0]];
      const data = monthLabels.map((month, index) => {
        const experienceByMonth =  experienceJson?.[month] || 0;
        const r = experienceByMonth > 0
          ? 5 + experienceByMonth * 1.5
          : 0;
        return { x: index, y: experienceByMonth, r};
      });
      return {
        data,
        type: "bubble",
        label: el[1]?.label,
        borderColor: el[1]?.borderColor,
        backgroundColor: el[1]?.backgroundColor
      };
    });

  const linearDatasets = Object.entries(EXPERIENCE_LEVEL_INFO)
    .map(el => {
      const experienceJson = labels?.[el[0]] || {};

      const toSortData = Object.entries(experienceJson);
      const data = toSortData.sort((a, b) => {
        const indexA = monthLabels.findIndex(value => value === a[0]);
        const indexB = monthLabels.findIndex(value => value === b[0]);

        return indexA - indexB;
      });

      return {
        data,
        fill: false,
        type: "line",
        borderWidth: 2,
        label: el[1]?.label,
        borderColor: el[1]?.borderColor
      };
    });

  return {
    data: {
      labels: monthLabels,
      datasets: [...bubblesDataSets, ...linearDatasets]
    },
    options
  };
};

const labelsX = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic"
];

export const EXPERIENCE_LEVEL_INFO = {
  KNOW: {
    label: "Conocer",
    borderColor: "#FBC1A2",
    backgroundColor: "rgba(251,193,162,0.8)"
  },
  LEARN: {
    label: "Aprender",
    borderColor: "#9DD7FB",
    backgroundColor: "rgba(157,215,251,0.8)"
  },
  ACT: {
    label: "Accionar",
    borderColor: "#FB93C5",
    backgroundColor: "rgba(251, 147, 197, 0.8)"
  }
};

const options = {
  elements: {
    point: {
      radius: 0
    }
  },
  plugins : {
    tooltip: {
      callbacks: {
        title: tooltipItem => {
          if (tooltipItem?.[0]?.dataset?.type.includes("line")) return;
          return tooltipItem[0].formattedValue
            .replaceAll("(", "")?.split(",")?.[0];
        },
        label: tooltipItem => {
          if (tooltipItem?.dataset?.type.includes("line")) return;
          const { dataIndex } = tooltipItem;

          const getActualData = tooltipItem?.dataset?.data?.[dataIndex]?.y;
          const getActualLabel = tooltipItem?.dataset?.label;

          return `${getActualData} experiencias para ${getActualLabel}`;
        }
      },
      titleColor: "#000",
      bodyColor: "#000",
      backgroundColor: "rgba(255, 255, 255, 0.75)",
      titleFont: { weight: "bold" },
      titleFontSize: 16,
      bodyFontSize: 14,
      displayColors: false
    }
  },
  scales: {
    y: {
      grid: {
        borderDash: [2, 2]
      },
      ticks: {
        beginAtZero: true,
        callback: number => {
          if (Math.floor(number) === number) {
            return number;
          }
        }
      }
    },
    x: {
      grid: {
        borderDash: [2, 2]
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false
};
