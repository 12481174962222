import React, { useContext, useEffect, useMemo } from "react";
import { cloneDeep } from "lodash";
import { withRouter } from "../../../../../helpers";
import { useParams, useHistory } from "react-router";
import { useService } from "../../../../../hooks/shared";
import { OdesService } from "../../../../../services/odes-service";
import { BatchOdesContext } from "../../../../../contexts/admin/batches";
import {
  ContentWrapper,
  SidebarLayout
} from "../../../../../components/shared";
import {
  EditCompanyProvider
} from "../../../../../contexts/shared/edit-company";
import {
  BatchContents
} from "../../../../../components/shared/odes/show/batch-contents";
import {
  Breadcrumbs
} from "../../../../../components/shared/odes/show/batch-contents/breadcrumbs";
import {
  MenuContext,
  DraggableListProvider,
  ContentViewSwitchProvider,
  ContentModalContextProvider,
  ContentGroupModalContextProvider,
  ContentDetailsModalContextProvider
} from "../../../../../contexts/shared";
import { CompanyService } from "../../../../../services";

const BatchContentsIndex = () => {
  const { id: odeId, batchId } = useParams();
  const history = useHistory();

  const { setMenuState } = useContext(MenuContext);

  const { loading, data, refetch: odeRefetch } = useService(
    OdesService.getById,
    { id: odeId }
  );

  const {
    data: odeContents,
    refetch: refetchOdeContents
  } = useService(
    CompanyService.getOdeContents,
    { odeId, batchId }
  );

  const {
    data: publishedContent,
    refetch: refetchPublishedContent,
    loading: loadingPublishedContent,
    error: errorPublishedContent
  } = useService(
    CompanyService.getPublishedContent,
    { companyId: odeId, batchId },
    { shouldFetch: !!odeId && !!batchId }
  );

  const refetch = async () => {
    await odeRefetch();
    await refetchOdeContents();
    await refetchPublishedContent();
  };

  const getBatchNameFromOdeData = data => {
    const batches = data?.batches || [];
    const batch = batches.filter(batch => batch.id === batchId);
    return batch[0]?.name || "";
  };

  const injectActions = useMemo(() => ({
    data: publishedContent,
    error: errorPublishedContent,
    loading: loadingPublishedContent,
    batchId,
    refetch,
    isDragable: false
  }), [
    publishedContent,
    loadingPublishedContent,
    errorPublishedContent
  ]);

  useEffect(() => {
    setMenuState({
      openKeys: ["odes"],
      selectedKeys: ["odes"]
    });
  }, []);

  return (
    <EditCompanyProvider
      data={data ? cloneDeep(data) : null} refetch={odeRefetch}>
      <BatchOdesContext.Provider value={injectActions}>
        <SidebarLayout>
          <Breadcrumbs
            odeName={data?.name}
            batchName={getBatchNameFromOdeData(data)}
            history={history} />
          <DraggableListProvider>
            <ContentWrapper>
              <ContentGroupModalContextProvider>
                <ContentModalContextProvider batchId={batchId}>
                  <ContentDetailsModalContextProvider
                    batchId={batchId}
                    odeId={odeId}
                    refetch={refetch}>
                    <ContentViewSwitchProvider>
                      <BatchContents
                        odeContents={odeContents}
                        data={data}
                        loading={loading}
                        allowEdit={true}
                        publishedContent={publishedContent} />
                    </ContentViewSwitchProvider>
                  </ContentDetailsModalContextProvider>
                </ContentModalContextProvider>
              </ContentGroupModalContextProvider>
            </ContentWrapper>
          </DraggableListProvider>
        </SidebarLayout>
      </BatchOdesContext.Provider>
    </EditCompanyProvider>
  );
};

export default withRouter(BatchContentsIndex);
