import React from "react";
import { Form, Button, Input, Row } from "antd";
import { LinksList } from "./list";

export const SocialMediaInput = ({ links, setLinks }) => {
  const [form] = Form.useForm();

  const onAddLink = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    setLinks(links.concat(values?.link));
    form.resetFields();
  };

  const onRemoveLink = link => {
    const newLinks = links.filter(stateLink => stateLink !== link);
    setLinks(newLinks);
  };

  return (
    <div>
      <Row justify="space-between">
        <Form form={form}>
          <Form.Item
            name="link"
            rules={[{
              required: true,
              message: "Por favor ingrese una URL" }]}>
            <Input
              style={{ width: "632px" }}
              placeholder="Agrega la URL de la página web o redes sociales." />
          </Form.Item>
        </Form>
        <Button
          onClick={onAddLink}
          style={{
            color: "#5F39DE",
            border: "1px solid #5F39DE"
          }}>
          Agregar</Button>
      </Row>
      <LinksList links={links} onRemoveLink={onRemoveLink} />
    </div>
  );
};
