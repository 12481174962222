import React from "react";
import { Tag, Space, Tooltip } from "antd";
import Text from "antd/es/typography/Text";
import { OrganizationsTablePopover } from "./hidden-popover-list";

export function OrganizationTableLabels({
  states = []
  }) {

  const labelsList = states.slice(0, 2);

  return (
    <Space>
      {
        labelsList?.length === 2
          ?
            <Text>
              {`${labelsList[0].name}, ${labelsList[1].name}`}
            </Text>
          :
            <Text>
              {labelsList[0].name}
            </Text>
      }
      {states.length > 2 && (
        <OrganizationsTablePopover
          labels={states?.slice(2)}>
          <Tag
            style={{ padding: "1px 8px" }}>
            + {states.length - 2}
          </Tag>
        </OrganizationsTablePopover>
      )}
    </Space>
  );
}
