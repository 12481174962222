export const onCampusChangeHandler = (
  teamMemberCampus,
  filters,
  filtersUpdater
) => {
  let updatedFilters = {};

  filtersUpdater(prevFilters => {
    updatedFilters = {
      ...prevFilters,
      teamMemberCampus
    };
    return updatedFilters;
  });

  return updatedFilters;
};