import React, { useContext } from "react";
import { Breadcrumb as ABreadcrumb, Space, Card } from "antd";
import {
  ArrowLeftOutlined
} from "@ant-design/icons";
import { withRouter } from "../../../../../helpers";
import {
  BatchDetailsContext
} from "../../../../../contexts/admin/batches/batch-details";

const spaceStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%"
};

function Breadcrumbs({ navigate, history, extra }) {
  const { data } = useContext(BatchDetailsContext);
  const batch = data?.batch;

  return (
    <Card bordered={false} className="batch-actions-bar">
      <Space style={spaceStyle} size="small">
        <Space>
          <ArrowLeftOutlined onClick={() => history?.goBack()} />
          <ABreadcrumb>
            <ABreadcrumb.Item
              className="clickable"
              onClick={() => navigate("../../batches")}>
              Batches
            </ABreadcrumb.Item>
            <ABreadcrumb.Item>{batch?.name}</ABreadcrumb.Item>
          </ABreadcrumb>
        </Space>
        { extra }
      </Space>
    </Card>
  );
}

export default withRouter(Breadcrumbs);
