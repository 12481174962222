import React from "react";
import { Upload } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import uuid from "uuid/v4";
import { toFileList } from "../../../../../../helpers/to-file-list/index";
import { withLoader } from "../../../../../../helpers";

/** @deprecated Not reusable component, expects query, reeplaced with AttachmentsDisplay */
function Attachments({ data }) {
  const attachments = data?.scheduledExpertService?.attachments || [];
  const links = data?.scheduledExpertService?.links || [];

  const getDefaultFileList = () => {
    const linksAsFiles = links.map(link => ({
      id: uuid(),
      url: link
    }));

    return toFileList(attachments.concat(linksAsFiles));
  };

  return (
    <Upload
      defaultFileList={getDefaultFileList()}
      showUploadList={{
        showRemoveIcon: false,
        showDownloadIcon: true,
        downloadIcon: <DownloadOutlined />
      }} />
  );
}

export default withLoader(Attachments);
