import React from "react";
import bannerUrl from "../../../../assets/eos-asm-banner.png";
import { Image } from "antd";

export const HeroAsem = () => {
  return (
    <Image
      preview={false}
      width="100%"
      src={bannerUrl} />
  );
};