import React, { useContext, useEffect, useState } from "react";
import { Space, Typography } from "antd";
import {
  BatchOdesContext
} from "../../../../../../contexts/admin/batches";
import {
  EmptyView, ListViewButton, RoleGuard, Section, Visibility
} from "../../../../../shared";
import { withRouter } from "../../../../../../helpers";
import {
  AddContentGroupButton
} from "../../../../../shared/batch-content/header/add-button";
import BatchContentGroupList from "../../../../../shared/batch-content/list";
import {
  CurrentUserContext,
  ContentViewSwitchContext
} from "../../../../../../contexts/shared";
import {
  BatchContentGroupTable
} from "../../../../../shared/batch-content/table";
import { GeneralProgress } from "./progress-indicators";
import { UsageTrackingService } from "../../../../../../services";
import { sortContentGroups } from "./progress-indicators/helpers";

const emptyDescription = (
  <div>
    <Typography.Title level={2}>
      ¡Oops, aún no tienes contenidos!
    </Typography.Title>
    <Typography.Paragraph>
      Para comenzar, crea tu contenido maestro, o si ya lo creaste, publícalo.
    </Typography.Paragraph>
  </div>
);

function BatchOdesContents({ ode, publishedContent, odeContents }) {
  const { currentUser } = useContext(CurrentUserContext);
  const {
    refetch, isDragable, loading, batchId
  } = useContext(BatchOdesContext);

  const [showEmptyView, setShowEmptyView] = useState(true);

  useEffect(() => {
    if (
      publishedContent?.masterContent || publishedContent?.customOdeContent
    ) {
      setShowEmptyView(false);
    }
  }, [publishedContent]);

  const { isListView } = useContext(ContentViewSwitchContext);

  const filterUnpublishedData = (contentGroups = []) => {
    const filteredContentsFromSections = contentGroups.map(section => {
      section.contentGroups = section
        .contentGroups
        ?.filter(contentGroup => !contentGroup.creationConfirmed);
      return section;
    });
    return filteredContentsFromSections
      .filter(contentGroup => !contentGroup.creationConfirmed);
  };

  const customODEContent = {
    contentGroups: publishedContent?.customOdeContent?.contentGroups || [],
    contentGroupsSort: publishedContent?.customOdeContent?.contentGroupsSort
      || []
  };

  const batchContent = {
    contentGroups: filterUnpublishedData(
      publishedContent?.masterContent?.contentGroups
    ),
    contentGroupsSort: publishedContent?.masterContent?.contentGroupsSort
  };

  useEffect(() => {
    if (!publishedContent) return;

    UsageTrackingService.trackVisitedContent({
      company: {
        id: ode?.id,
        name: ode?.name
      },
      content: {
        batchId: batchId,
        titles: ([
          ...batchContent.contentGroups,
          ...customODEContent.contentGroups])
          ?.map(({ title }) => ({ title }))
      },
      user: {
        id: currentUser?.id,
        email: currentUser?.email,
        fullName: currentUser?.fullName,
        role: currentUser?.systemRole
      }
    });
  }, [publishedContent]);

  return (
    <Section>
      <EmptyView
        isEmpty={showEmptyView}
        isLoading={loading}
        description={emptyDescription}
        onClick={() => setShowEmptyView(prevState => !prevState)}>
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <Typography.Title level={5}>
            {isListView ? "Bloques de contenido" : "Tabla de contenidos"}
          </Typography.Title>
          <ListViewButton />
        </Space>
        <Typography.Text>
          {isListView
            ? `Aquí verás los contenidos de tu batch divididos en secciones.
              Dentro de ellas están los recursos y actividades que te ayudarán a
              lograr cada una de las secciones.`
            : `Aquí verás los contenidos de tu batch en forma de lista, da
              click en cada uno para ver el detalle.`
          }
        </Typography.Text>
        <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR", "ADVISER"]}>
          <AddContentGroupButton />
        </RoleGuard>
        <GeneralProgress
          odeContents={odeContents}
          masterContent={publishedContent?.masterContent}
          customOdeContent={customODEContent} />
        <Visibility visible={isListView}>
          <BatchContentGroupList
            emptyText="Sin contenido del Batch"
            isLoading={loading}
            isDragable={isDragable}
            odeContents={odeContents}
            data={batchContent.contentGroupsSort?.length
              ? sortContentGroups(
                batchContent.contentGroups,
                batchContent.contentGroupsSort
              )
              : batchContent.contentGroups} />
          <Visibility visible={!!customODEContent.contentGroups?.length}>
            <BatchContentGroupList
              emptyText=""
              refetch={refetch}
              odeContents={odeContents}
              isLoading={loading}
              data={customODEContent.contentGroupsSort?.length
                ? sortContentGroups(
                    customODEContent.contentGroups,
                    customODEContent.contentGroupsSort
                  )
                : customODEContent.contentGroups} />
          </Visibility>
        </Visibility>
        <Visibility visible={!isListView}>
          <BatchContentGroupTable
            sort={batchContent.contentGroupsSort}
            data={batchContent
              .contentGroups
              .concat(customODEContent.contentGroups)}
            loading={loading} />
        </Visibility>
      </EmptyView>
    </Section>
  );
}

export default withRouter(BatchOdesContents);
