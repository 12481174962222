import React, { useState } from "react";

import { ArrayItem } from "../../../../../../../shared";
import { VolunteeringModal } from "./volunteering-modal";
import { VolunteeringItem } from "./volunteering-item";
import {
  handleOnAdd,
  handleOnEditItem,
  handleOnRemoveItem,
  handleOnSave,
  handleOnCancel
} from "../../../../../modal-helpers";

export const VolunteeringRecords = ({
  onSave,
  volunteerings = [],
  editable = true
}) => {
  const [modal, setModal] = useState({ visible: false });

  const renderItem = item =>
    <VolunteeringItem
      editable={editable}
      item={item}
      onEditItem={volunteering =>
        handleOnEditItem(setModal, volunteering)}
      onRemoveItem={removed =>
        handleOnRemoveItem(onSave, volunteerings, removed)} />;

  return (
    <>
      <VolunteeringModal
        visible={modal.visible}
        volunteering={modal.item}
        onConfirm={newRecord =>
          handleOnSave(modal, setModal, onSave, volunteerings, newRecord)}
        onCancel={() =>
          handleOnCancel(setModal)} />
      <ArrayItem
        editable={editable}
        value={volunteerings}
        onClickAdd={() =>
          handleOnAdd(setModal)}
        buttonLabel="Agregar"
        renderItem={renderItem} />
    </>
  );
};
