import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { client } from "../../../../../../graphql";
import { shared } from "../../../../../graphql/shared";
import { SidebarLayout } from "../../../../../components/shared";
import {
  Navbar,
  TeamList,
  Breadcrumbs
} from "../../../../../components/shared/odes/show";
import {
  SummaryEditSection
} from "../../../../../components/shared/ode-forms/summary";
import { EditOdeProvider } from "../../../../../contexts/shared/edit-ode";
import { MenuContext } from "../../../../../contexts/shared";
import {
  InvitationModalProvider } from "../../../../../contexts/shared";
import { MainMenu } from "../../../../../components/admin/shared";

function OdeTeamIndex({ match }) {
  const { params = {} } = match;
  const odeId = params?.id;

  const { loading, error, data = {} } = useQuery(
    shared.queries.getOdeById,
    {
      client,
      variables: { id: odeId },
      fetchPolicy: "network-only"
    });

  const { setMenuState } = useContext(MenuContext);

  /* set menu's current state */
  useEffect(() => {
    setMenuState({
      openKeys: ["odes"],
      selectedKeys: ["odes"]
    });
  }, []);

  return (
    <EditOdeProvider
      odeId={odeId}
      data={data}
      loading={loading}
      error={error}>
      <SidebarLayout menu={<MainMenu />}>
        <Breadcrumbs
          odeName={data?.oDE?.name} />
        <SummaryEditSection
          editable
          data={data}
          isLoading={loading}
          error={error} />
        <Navbar selectedKeys={["team"]} />
        <InvitationModalProvider ode={data?.oDE}>
          <TeamList odeId={odeId} />
        </InvitationModalProvider>
      </SidebarLayout>
    </EditOdeProvider>
  );
}

export default withRouter(OdeTeamIndex);
