import {
  GET_BATCH_PUBLISHED_MASTER_CONTENTS
} from "./graphql/get-batch-published-master-contents";
import {
  graphqlApolloClient
} from "../data-connection-service/graphql-apollo-client";

export const getBatchPublishedMasterContents = async ({ batchId }) => {
  const response = await graphqlApolloClient.query({
    query: GET_BATCH_PUBLISHED_MASTER_CONTENTS,
    fetchPolicy: "network-only",
    variables: { batchId }
  });

  // There are many garbage relationships.
  // The first result is the latest one.
  return response?.data?.masterContents?.[0];
};
