import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { Avatar, UploadButton } from "../../../../shared";
import { withLoader, toFileList } from "../../../../../helpers";

function PersonalInformationForm({ onChange, data, form }) {
  const [state, setState] = useState({ profilePicUrl: undefined });

  useEffect(() => {
    if(data) {
      form.setFieldsValue({
        ...data.user,
        profilePicUrl: data.user?.profilePicUrl ?
          toFileList([{ url: data.user?.profilePicUrl }])
          : undefined
      });

      setState({
        profilePicUrl: data?.user?.profilePicUrl
      });
    }
  }, [data]);

  const onFormChange = ({profilePicUrl = ""}) => {
    try {
      const values = form.getFieldsValue();

      const fullName = [values.firstName, values.middleName, values.lastName]
        .filter(e => e !== undefined)
        .join(" ");

      values.fullName = fullName;

      values.profilePicUrl = profilePicUrl;

      onChange && onChange(values);
    }
    catch(e) {
      console.error(e);
    }
  };

  const getPhotoUrl = e => {
    const profilePicUrl = e?.file?.response?.imageUrl;

    setState(prevState => ({
      ...prevState,
      profilePicUrl
    }));

    onFormChange(profilePicUrl);

    return e.fileList;
  };

  return(
    <Form
      form={form}
      onChange={onFormChange}
      layout="vertical">
      <Form.Item>
        <Avatar
          size={64}
          src={state.profilePicUrl} />
      </Form.Item>
      <Form.Item
        name="profilePicUrl"
        getValueFromEvent={getPhotoUrl}>
        <UploadButton.Crop
          aspect={0.9}
          multiple={false}
          maxFiles={1}>
          Subir fotografía
        </UploadButton.Crop>
      </Form.Item>
      <Form.Item
        label="Nombre(s)"
        name="firstName"
        rules={[{required: true}]}>
        <Input type="text" placeholder="Nombre(s)" />
      </Form.Item>
      <Form.Item
        label="Apellido paterno"
        name="middleName"
        rules={[{required: true}]}>
        <Input type="text" placeholder="Apellido paterno" />
      </Form.Item>
      <Form.Item
        label="Apellido materno"
        name="lastName"
        rules={[{required: true}]}>
        <Input type="text" placeholder="Apellido materno" />
      </Form.Item>
      <Form.Item
        label="Teléfono"
        name="phone"
        rules={[{required: true}]}>
        <Input type="phone" placeholder="(999) 3 38 03 34" />
      </Form.Item>
    </Form>
  );
}

export default withLoader(PersonalInformationForm);