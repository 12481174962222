import React from "react";
import { Text, StyleSheet } from "@react-pdf/renderer";

export const Title = ({ children, style }) => {
  
  const styles = StyleSheet.create({
    general: {
      fontSize: 10,
      fontWeight: 700,
      marginBottom: 5,
      color: "#0000007A",
      letterSpacing: 1.5,
      ...style
    }
  })

  return (
    <Text style={styles.general}>{children}</Text>
  )
}