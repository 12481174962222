import React from "react";
import { List, Row } from "antd";
import { getLinkIcon } from "../../../helpers";
import { DeleteOutlined } from "@ant-design/icons";

export const LinksList = ({ links, onRemoveLink }) => {
  if (links && links.length === 0) return null;

  return (
    <List
      dataSource={links || []}
      renderItem={link => (
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "8px" }}>
          <div style={{ display: "flex" }}>
            {getLinkIcon(link)}
            <p style={{ fontSize: "14px", margin: "4px 0 0 10px" }}>{link}</p>
          </div>
          <DeleteOutlined
            className="clickable"
            style={{ fontSize: "15px" }}
            onClick={() => onRemoveLink(link)} />
        </Row>
      )} />
  );
};
