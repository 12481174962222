import React from "react";
import { List, Typography } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { FlexContainer } from "../../../../../shared";
import { ExamplesDisplay } from "../../../../../shared/examples-display";

export function DetailContent({
  assignedExpertService = null
}) {
  const { expertServiceData = {} } = assignedExpertService || {};
  const { examples = [], links = [] } = expertServiceData;

  return (
    <FlexContainer direction="vertical">
      <Typography.Title strong level={5}>
        Información de la mentoría
      </Typography.Title>
      <br />
      <Typography.Text type="secondary">
        Descripción:
      </Typography.Text>
      <Typography.Text>
        {expertServiceData?.description}
      </Typography.Text>
      <br />
      <Typography.Text type="secondary">
        Galería de ejemplos:
      </Typography.Text>
      <ExamplesDisplay examples={examples} />
      <br />
      <Typography.Text type="secondary">
        Enlaces y archivos:
      </Typography.Text>
      <List
        size="small"
        dataSource={links}
        renderItem={link => (
          <List.Item key={link}>
            <Typography.Link
              href={link}
              target="_blank">
              <LinkOutlined /> {link}
            </Typography.Link>
          </List.Item>)
        } />
    </FlexContainer>
  );
}
