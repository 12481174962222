import React from "react";
import { Switch, Route } from "react-router-dom";
import { PerksIndex } from "../../../pages/coordinator/perks";

export const CoordinatorPerksRoutes = <Switch>
  <Route
    exact
    path="/v2/coordinator/perks"
    component={PerksIndex} />
</Switch>;
