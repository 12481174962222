import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql";
import { withRouter } from "../../../../../helpers";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { EditOdeProvider } from "../../../../../contexts/shared/edit-ode";
import { ContentWrapper, SidebarLayout } from "../../../../../components/shared";
import { Breadcrumbs, Navbar } from "../../../../../components/shared/odes/show";
import { SummaryEditSection } from "../../../../../components/shared/ode-forms";
import {
  default as BatchesList
} from "../../../../../components/shared/batches-list";

function BatchesIndex({ match }) {
  const { params = {} } = match;
  const odeId = params?.id;

  const { loading, error, data = {} } = useQuery(
    shared.queries.getOdeById,
    {
      client,
      variables: { id: odeId },
      fetchPolicy: "network-only"
    }
  );

  const { loading: batchesLoading, error: batchesError, data: batchesData } = useQuery(
    shared.queries.getBatchesByOdeId,
    {
      client,
      variables: { id: odeId }
    }
  );

  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/odes"]
  });

  return (
    <EditOdeProvider
      odeId={odeId}
      data={data}
      loading={loading}
      error={error}>
      <SidebarLayout>
        <Breadcrumbs
          odeName={data?.oDE?.name} />
        <SummaryEditSection
          editable
          data={data}
          isLoading={loading}
          error={error} />
        <Navbar selectedKeys={["batches"]} />
        <ContentWrapper>
          <BatchesList
            data={batchesData}
            error={batchesError}
            isLoading={batchesLoading}
            odeId={odeId} />
        </ContentWrapper>
      </SidebarLayout>
    </EditOdeProvider>
  );
}

export default withRouter(BatchesIndex);
