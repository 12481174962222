import React, { useContext, useEffect } from "react";
import {
  Form,
  Input,
  Radio
} from "antd";
import moment from "moment";
import { AvatarUpload } from "./avatar-upload";
import { FormContainer, DatePicker } from "../../../../../shared";
import {
  NewExpertContext
} from "../../../../../../contexts/experts/new/context";

const validationMessages = {
  required: "El campo '${label}' es requerido."
};

const { Item } = Form;

const ContactDataForm = () => {
  const {
    updatePersonalInformation,
    loading,
    error,
    data = {},
    form
  } = useContext(NewExpertContext);

  useEffect(() => {
    const { user = {} } = data;
    const personalInfo = {
      resume: user?.expertProfile?.resume,
      firstName: user?.firstName,
      middleName: user?.middleName,
      lastName: user?.lastName,
      email: user?.email,
      phone: user?.phone,
      gender: user?.gender,
      birthday: user?.birthday ? moment(user?.birthday) : undefined
    };
    form.setFieldsValue(personalInfo);
  }, [data, loading, error]);

  const handleOnChange = (changedFields = []) => {
    const { name = [], value } = changedFields[0] || {};
    const fieldName = name[0];

    updatePersonalInformation({
      [fieldName]: value
    });
  };

  return (
    <FormContainer>
      <Form
        onFieldsChange={handleOnChange}
        layout="vertical"
        validateMessages={validationMessages}
        form={form}>
        <Item>
          <AvatarUpload />
        </Item>
        <Item
          name="resume"
          label="Semblanza"
          rules={[{ required: true }]}>
          <Input.TextArea
            rows={3}
            placeholder="Describe brevemente" />
        </Item>
        <Item
          name="firstName"
          label="Nombre(s)"
          rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item
          name="middleName"
          label="Apellido paterno"
          rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item
          name="lastName"
          label="Apellido materno"
          rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item
          name="email"
          label="Correo electrónico"
          rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item
          name="password"
          label="Contraseña"
          rules={[{ required: true }]}
          help="Contraseña que utilizará en EOS">
          <Input.Password />
        </Item>
        <Item
          name="phone"
          label="Teléfono"
          rules={[{ required: true }]}>
          <Input />
        </Item>
        <Item
          name="gender"
          label="Género"
          rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value="FEMALE">Femenino</Radio>
            <Radio value="MALE">Masculino</Radio>
            <Radio value="NOT_SAY">Prefiero no decirlo</Radio>
          </Radio.Group>
        </Item>
        <Item
          name="birthday"
          label="Fecha de nacimiento"
          rules={[{ required: true }]}>
          <DatePicker />
        </Item>
      </Form>
    </FormContainer>
  );
};

export default ContactDataForm;
