import gql from "graphql-tag";

export const deleteOdeReport = gql`
  mutation deleteManyOdeReports($id: ID!) {
    deleteManyOdeReports(
      where: { id: $id }
    ) {
      count
    }
  }
`;
