import React from "react";
import { Form, Select } from "antd";

export function InviteForm({ form, onFieldsChange }) {
  const onSelectChange = async (e = []) => {
    if (e[e.length - 1]) {
      const emailList = e[e.length - 1].split(/(\r\n|\n|\r)/gm);
      const filteredEmailList = emailList.filter(email => email.length > 2);
      let emailFields;
      if (e.length === 1) {
        emailFields = {
          emails: filteredEmailList
        };
      } else {
        const value = form.getFieldsValue();
        value.emails.pop();
        emailFields = {
          emails: value.emails.concat(filteredEmailList)
        };
      }
      form.setFieldsValue({ ...emailFields });
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFieldsChange={onFieldsChange}>
      <Form.Item
        label="Escribe los correos a los que desees enviar una invitación"
        help="Da enter o escribe una coma para agregar un nuevo correo"
        name="emails"
        rules={[{
          required: true,
          message: "Tienes que ingresar al menos un\
              correo para enviar la invitación."
        }]}>
        <Select
          mode="tags"
          notFoundContent={null}
          tokenSeparators={[","]}
          onChange={onSelectChange}
          dropdownStyle={{ display: "none" }}
          placeholder="usuario@eossolution.io" />
      </Form.Item>
    </Form>
  );
}
