import React from "react";
import {Typography, Popover} from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { assignationHasExpired } from "../../../../../../../../../../helpers";

export function RightLabel({
  assignedService
}) {
  const {
    scheduledExpertService,
    expertServiceData
  } = assignedService;

  const availableUnits = assignedService.availableUnits;
  const assignedUnits = assignedService.assignedUnits;
  const paymentMethod = expertServiceData.method;

  const paymentMethodsMap = {
    HOURS: "h",
    FIXED_COST: ""
  };

  const textType = availableUnits  < 1 ? "danger" : "success";

  if (!scheduledExpertService && assignationHasExpired(assignedService.limitDate)) {
    return (
      <Typography.Text
        strong
        type="danger"
        style={{ fontWeight: 400, fontSize: 16 }}>
        Vencido
      </Typography.Text>
    );
  }

  return (
    <Popover
      trigger="hover"
      placement="top"
      content={<div>
        <InfoCircleFilled
          style={{ color: "#FFB61D" }} />{" "}
        <Typography.Text>
          {availableUnits}{paymentMethodsMap[paymentMethod] || ""} disponibles
        </Typography.Text>
        <br />
        <Typography.Text style={{ marginLeft: "1.2rem" }}>
          {assignedUnits}{paymentMethodsMap[paymentMethod] || ""} asignadas
        </Typography.Text>
      </div>}>
      <Typography.Text
        strong
        type={textType}
        style={{ fontWeight: 400, fontSize: 16 }}>
        {availableUnits}/{assignedUnits}{paymentMethodsMap[paymentMethod] || ""}
      </Typography.Text>
    </Popover>
  );
}
