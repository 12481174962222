import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql";
import { useParams } from "react-router";
import { default as AnnouncementsDetail  } from "../announcements-section-detail/detail";

export const AnnouncementsSectionDetail = ({ announcementId, onGoBack }) => {
  const { announcementsId } = useParams();

  const { data, loading, error } = useQuery(
    shared.queries.getAnnouncementById,
    {
      fetchPolicy: "network-only",
      variables: {
        id: announcementId || announcementsId // for legacy purposes
      },
      client
    }
  );

  return (
    <div style={{ minHeight: "900px"}}>
      <AnnouncementsDetail
        onGoBack={onGoBack}
        data={data}
        error={error}
        isLoading={loading} />
    </div>
  );
};
