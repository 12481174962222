import React, { useContext } from "react";
import { List, Spin, Alert } from "antd";
import { Avatar } from "../avatar";
import { LoadingOutlined } from "@ant-design/icons";
import { CurrentUserContext } from "../../../contexts/shared/current-user/context";

export function WorkspacesList({onSelectWorkspace = () => {}}) {
  const {
    currentUser,
    loading,
    error
  } = useContext(CurrentUserContext);


  if(loading) {
    return <Spin indicator={<LoadingOutlined />} />;
  }

  if(error) {
    return <Alert
      message="Ha ocurrido un error al cargar los workspaces"
      type="error"
      showIcon />;
  }

  return (
    <List
      dataSource={currentUser.workspaces}
      renderItem={workspace => (
        <List.Item
          onClick={() => onSelectWorkspace(workspace.id)}
          style={{cursor: "pointer"}}>
          <List.Item.Meta
            title={workspace.name}
            avatar={<Avatar>{workspace.coverPicUrl || workspace.name}</Avatar>} />
        </List.Item>
      )} />
  );
}
