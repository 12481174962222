import gql from "graphql-tag";

export const getExpertMentoringsQuery = gql`
  query GetExpertMentoringsQuery($expertId: ID!) {
    expertServices(where: { 
      expert: {
        id: $expertId
      }
     }) {
      id
      name
      type
      price
      method
      expert {
        id
        fullName
        systemRole
      }
    }
  }
`;
