import gql from "graphql-tag";

export const getObjectivesByBatchId = gql`
  query GetObjectivesByBatchId($id: ID!) {
    objectives(where: { batch: { id: $id } }, orderBy: createdAt_DESC) {
      id
      name
      description
      batch {
        id
      }
      achievements(orderBy: createdAt_DESC) {
        id
        name
        description
        contents(orderBy: createdAt_DESC) {
          id
          name
          description
          deliveryDate
          type
        }
      }
    }
  }
`;
