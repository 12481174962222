import React, {useState} from "react";
import { Alert, Button, Form, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { ContainerOutlined } from "@ant-design/icons";
import ReactJsonView from "react-json-view";
import {
  getJsonFromExcel
} from "../../../../../pages/public/reporting-tool/import-batch-institutions/helpers";
import { Visibility } from "../../../visibility";
import {
  formatEnrollmentsForImportation
} from "../../../../../helpers";
import {
  filterEmptyRowsFromSheets
} from "../../../../../helpers";
import {
  EntrepreneurSpiritService
} from "../../../../../services/entrepreneur-spirit";

export const EnrollmentAcademicsImporter = () => {
  const [form] = Form.useForm();
  const [state, setState] = useState({});
  const [process, setProcess] = useState("");
  const [result, setResult] = useState({
    ok: false,
    error: false
  });

  const applyFormat = async () => {
    const { attachment = undefined } = form.getFieldsValue();
    setProcess("Reading data from excel file...");
    setResult({ ok: false, error: false });

    const sheets = await getJsonFromExcel(attachment.file)
      .catch(() => setResult({ error: "Error reading excel file" }));

    const [schoolEnrollment] = sheets;

    setProcess("Cleaning up data...");
    const { importationRows } =
      await filterEmptyRowsFromSheets(schoolEnrollment);

    setProcess("Formatting institutions...");
    const enrollmentsToUpdate =
      formatEnrollmentsForImportation(importationRows);
    setProcess("");
    setResult({ ok: "Data loaded successfully" });

    setState({ enrollments: enrollmentsToUpdate });
  };

  const onChange = async file => {
    try {
      message.success(`Se cargó el archivo: ${file?.name}.`);
      form.setFieldsValue({ "attachment": file });
    } catch (error) {
      message.error(`Error al cargar el archivo: ${file?.name}.`);
    }

    return false;
  };

  const onRemove = () => form.resetFields();

  const importData = async (enrollments = []) => {
    const dismissLoader = message.loading("Importando datos...");
    try {
      const result = await
        EntrepreneurSpiritService.importEnrollments(enrollments);
      setState({ result });
      message.success("Datos importados correctamente.");
    } catch (e) {
      message.error(`Error al importar los datos: ${e.message}`);
    } finally {
      dismissLoader();
    }
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <div
        style={{ width: "500px", marginTop: "5rem", margin: "auto" }}>
        <Visibility visible={process}>
          <Alert message={process} type="info" />
        </Visibility>
        <Visibility visible={result.error}>
          <Alert message={result.error} type="error" />
        </Visibility>
        <Visibility visible={result.ok}>
          <Alert message={result.ok} type="success" />
        </Visibility>
        <Form
          form={form}
          layout="vertical">
          <Form.Item
            name="attachment"
            label="Cargar Todo">
            <Dragger
              maxCount={1}
              multiple={false}
              beforeUpload={onChange}
              onRemove={onRemove}>
              <p className="ant-upload-drag-icon">
                <ContainerOutlined />
              </p>
              <p className="ant-upload-text">
                Importador de Inscritos/Académica
              </p>
              <p className="ant-upload-hint">
                El archivo debe tener la extensión “.xlsx”
              </p>
            </Dragger>
          </Form.Item>
        </Form>
        <Button onClick={applyFormat}>
          Aplicar formato
        </Button>
        <Button onClick={() => importData(state.enrollments)}>
          Importar
        </Button>
        <br />
      </div>
      <ReactJsonView src={state} theme="monokai" />
    </div>
  );
};
