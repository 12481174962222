import gql from "graphql-tag";

export const scheduledExpertServices = gql`
  query ScheduledExpertServices(
    $companyId: ID!
    $batchId: ID!
    $type: String!
  ) {
    scheduledExpertServices(where: { 
      assignedExpertService: { 
        ode: { id: $companyId }
        batch: { id: $batchId }  
        expertService: {
          type: $type
        }
      }
    }){
      id
      status
      assignedExpertService{
        id
        assignedUnits
        availableUnits
        expertService{
          id
          name
          coverPicUrl
          category
          expert{
            id
            fullName
            profilePicUrl
          }
        }
      }
    }
  }
`;
