import {
  removeUnnusedFields,
  mapName,
  purgeString
} from "./helpers";

export const setSave = async (data, updateExpert, expertId) => {

  return await updateExpert({
    variables: { data, expertId }
  });
};
