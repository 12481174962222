import React, { useContext } from "react";
import { AddButton } from "../../../shared";
import { ProgramModalContext } from "../../../../contexts/shared/program-modal";
import { BatchModalContext, PaginationContext } from "../../../../contexts/shared";
import { withLoader, withRouter, paginationConfig } from "../../../../helpers";
import { ProgramItem } from "./item";
import { List } from "antd";

function ProgramsList({
  data,
  loading,
  onChangePage,
  currentPage,
  ...props
}) {
  const {
    openModal,
    onDisableProgram,
    onDeleteProgram
  } = useContext(ProgramModalContext);
  const { openModal: openBatchModal } = useContext(BatchModalContext);

  const { onPaginationChange } = useContext(PaginationContext);

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange("programs")(page, pageSize);
  };

  return (
    <>
      <AddButton
        onClick={() => openModal()}
        style={{width: "100%"}}>
        Agregar nuevo programa
      </AddButton>
      <List
        style={{marginTop: "20px"}}
        loading={loading}
        itemLayout="horizontal"
        dataSource={data.programs}
        pagination={{
          ...paginationConfig,
          current: currentPage,
          onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
          total: data?.meta?.aggregate?.count || 0
        }}
        renderItem={program =>
          <ProgramItem
            onCreateBatch={openBatchModal}
            program={program}
            onEdit={() => openModal({ programId: program.id })}
            onDisable={() => onDisableProgram({ id: program.id })}
            onDelete={() => onDeleteProgram({ id: program.id })} />
        }
        {...props} />
    </>
  );
}

export default withLoader(withRouter(ProgramsList));
