import gql from "graphql-tag";

export const updateExpertProfile = gql`
  mutation UpdateExpertProfile(
    $userId: ID!

    $resume: String
    $phone: String
    $gender: Gender
    $birthday: DateTime
    $firstName: String
    $middleName: String
    $lastName: String
    $fullName: String
    $ifullName: String

    $businessName: String
    $rfc: String

    $website: String
    $facebook: String
    $twitter: String
    $instagram: String
    $otherUrl: String
    $linkedin: String

    $location: LocationUpdateOneInput

    $curriculumVitae: FileUpdateOneInput
    $skills: ProfessionalSkillUpdateManyInput
    $industries: ExpertIndustryUpdateManyInput
    $areas: ProfessionalAreaUpdateManyInput
    $education: EducationUpdateManyInput
    $courses: CourseUpdateManyInput
    $papers: ResearchPaperUpdateManyInput
    $languages: LanguageExperienceUpdateManyInput
    $volunteerings: VolunteeringUpdateManyInput
    $entrepreneurships: EntrepreneurshipUpdateManyInput

    # tecRelationship
		$enrollmentId: String
    $campus: String
    $career: String
    $type: String
    $deleteTecRelatioship: Boolean
  ) {
    updateUser(
      where: {
        id: $userId
      }
      data: {
        fullName: $fullName
        ifullName: $ifullName
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        phone: $phone
        gender: $gender
        birthday: $birthday
        expertProfile: {
          update: {
            resume: $resume
            location: $location
            businessName: $businessName
            rfc: $rfc
            website: $website
            facebook: $facebook
            twitter: $twitter
            instagram: $instagram
            otherUrl: $otherUrl
            linkedin: $linkedin
            professionalExperience: {
              update: {
                curriculumVitae: $curriculumVitae
                skills: $skills
                industries: $industries
                areas: $areas
                education: $education
                courses: $courses
                papers: $papers
                languages: $languages
                volunteerings: $volunteerings
                entrepreneurships: $entrepreneurships
                tecRelationship: {
                  delete: $deleteTecRelatioship
                  upsert: {
                    create: {
                      enrollmentId: $enrollmentId
                      campus: $campus
                      career: $career
                      type: $type
                    }
                    update: {
                      enrollmentId: $enrollmentId
                      campus: $campus
                      career: $career
                      type: $type
                    }
                  }
                }
              } # END UPDATE PROFESSIONAL EXPERIENCE
            } # END PROFESSIONAL EXPERIENCE
          } # END UPDATE EXPERT PROFILE
        } # END EXPERT PROFILE
      }
    ) {
      id
    }
  }
`;
