import gql from "graphql-tag";

export const createExpertService = gql`
  mutation CreateExpertService($data: ExpertServiceCreateInput!) {
    createExpertService(data: $data) {
      id
      expert {
        fullName
        profilePicUrl
      }
      name
      description
      type
      duration
      price
    }
  }
`;
