import React, { useState } from "react";
import { Select, Input } from "antd";

/**
 * @deprecated Control not needed TODO: refactor into custom select
 * 
 * Ant Select wich allows to filter the passed options or show a text input
 * for manual input.
 * @param {*} options must be an array of objects
 * with label and value keys: { label: string, value: any }
 * @param {*} openOption
 * the key which will allow to show the text input when the user selects it
 */
export function SearchableDropdown({ options = [], openOption, onChange, ...props }) {
  const [state, setState] = useState({
    selectMode: true
  });

  const handleOnChange = val => {    
    if (state.selectMode) {
      const showTextInput = openOption ?
        val === openOption : false;

      showTextInput && setState({ selectMode: false });
      onChange && onChange(!showTextInput ? val : undefined);
    } else {
      !val && setState({ selectMode: true });
      onChange && onChange(val ? val: undefined);
    }
  };

  if (state.selectMode) {
    return (
      <Select
        allowClear
        showSearch
        {...props}
        onChange={handleOnChange}>
        {options.map((option, i) => (
          <Select.Option key={i} value={option?.value}>
            { option?.label }
          </Select.Option>
        ))}
      </Select>
    );
  }

  return (
    <Input
      autoFocus
      placeholder="Ingresa un tipo de servicio personalizado"
      type="text"
      allowClear
      onChange={e => handleOnChange(e?.target?.value)} /> 
  );
}
