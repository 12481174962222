import React, { useContext } from "react";
import moment from "moment";
import { Visibility } from "../../../../visibility";
import { EditButton } from "../../../../edit-button";
import { ParagraphSkeleton } from "../../../../skeletons";
import { FlexContainer } from "../../../../flex-container";
import { Alert, Descriptions, Tag, Typography } from "antd";
import { getReadableValue } from "../../../../../../helpers/select-options";
import {
  EditCompanyContext
} from "../../../../../../contexts/shared/edit-company";
import {
  inventionTypeOptions
} from "../../../../../../helpers/select-options/company/latest/invention-type";
import {
  mainTechnologyOptionAllChildren
} from "../../../../../../helpers/select-options/company/latest/main-technology";
import {
  socialComponentOptions
} from "../../../../../../helpers/select-options/company/latest/social-component";
import {
  inventionRegisterOptions
} from "../../../../../../helpers/select-options/company/latest/invention-register";

export function AboutYourCompany({
  aboutYourCompanyAlert,
  loading = false,
  data = {
    description: "",
    mainTechnologies: [],
    technologyLevel: "",
    patentStatus: "",
    socialComponent: "",
    product: "",
    market: "",
    inventionType: null
  },
  allowEdit
}) {
  const { onEdit } = useContext(EditCompanyContext);

  data = data || {};

  if (loading) {
    return <ParagraphSkeleton paragraphs={2} />;
  }
  return (
    <FlexContainer
      className="CompanyAboutInfo"
      direction={"vertical"}>
      <Visibility visible={aboutYourCompanyAlert}>
        <Alert
          showIcon
          type="warning"
          style={{width: "inherit"}}
          message="Tienes campos obligatorios sin llenar." />
      </Visibility>
      <FlexContainer
        style={{margin: "1rem 0"}}
        direction="horizontal"
        justifyContent="space-between">
        <Typography.Title level={4}>
          Sobre la compañía
        </Typography.Title>
        <EditButton visible={allowEdit} onClick={() => onEdit("ABOUT")}>
          Editar
        </EditButton>
      </FlexContainer>
      <Descriptions
        column={2}
        colon={false}
        layout="vertical"
        style={{ gap: ".25rem" }}
        labelStyle={{ color: "#8A939C" }}
        contentStyle={{
          color: "#586472",
          fontsize: "16px",
          fontWeight: "bold",
          paddingBottom: "16px"
        }}>
        <Descriptions.Item
          span={2}
          style={{ whiteSpace: "pre-wrap" }}>
          {data?.description}
        </Descriptions.Item>
        {data?.techBased && (<>
          <Descriptions.Item
            className="CompanyAboutInfo__Tags"
            span={2}
            contentStyle={{}}
            label="Principal Tecnologia">
            {data?.mainTechnologies?.map(technology => (
              <Tag
                key={technology}
                color={"default"}>
                {
                  getReadableValue(
                    mainTechnologyOptionAllChildren,
                    technology,
                    ""
                  )}
              </Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="Madurez de la tecnología">
            {data?.technologyLevel}
          </Descriptions.Item>
          <Descriptions.Item label="Tipo de invención">
            {getReadableValue(inventionTypeOptions, data?.inventionType)}
          </Descriptions.Item>
          {data?.inventionType === "DOESNT_APPLY"
            ? null
            : <Descriptions.Item label="Registro de invención">
              {
                getReadableValue(
                  inventionRegisterOptions,
                  data?.inventionRegister
                )}
            </Descriptions.Item>
          }
          {data?.inventionRegisterDate && (
            <Descriptions.Item label="Fecha de registro de la invención">
              {moment(data?.inventionRegisterDate).format("MMMM, YYYY")}
            </Descriptions.Item>
          )}
        </>
        )}
        {data?.social && (
          <Descriptions.Item
            span={2}
            label="Componente Social">
            <Tag
              color={"default"}>
              {
                getReadableValue(
                  socialComponentOptions,
                  data?.socialComponent,
                  ""
                )}
            </Tag>
          </Descriptions.Item>
        )}
        {data?.product && (
          <Descriptions.Item label="Producto">
            {data?.product}
          </Descriptions.Item>
        )}
        {data?.market && (
          <Descriptions.Item label="Mercado">
            {data?.market}
          </Descriptions.Item>
        )}
      </Descriptions>
    </FlexContainer>
  );
}
