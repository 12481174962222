import {
  getReadableValue
} from "../../../../../helpers";
import {
  mainTechnologyOptions
} from "../../../../../helpers/select-options/company/latest/main-technology";

const TECHNOLOGIES = mainTechnologyOptions
  .reduce((acc, curr) => {
    acc.push(
      ...curr.children.map(({ title, value }) => ({ value, label: title }))
    );
    return acc;
  }, []);

export function technologiesToChart (technologies = []) {
  const labels = [];
  const datasets = [];

  /** @type {{ count: number, value: string }[]} */
  const validTechnologies = technologies
    .reduce((acc, t) => {
      if (t["Company.companyTechnology"] !== null) {
        acc.push({
          count: parseInt(t["Company.companiesCount"]),
          value: t["Company.companyTechnology"]
        });
      }
      return acc;
    }, []);

  /** @type {{[key in string]: { count: number, value: string }[]}} */
  const mainTechnologiesMap = {};
  validTechnologies.forEach(subTechnology => {
    const mainTechnology = mainTechnologyOptions.find(mainTech => {
      const foundTechChildren = mainTech
        .children.find(t => t.value === subTechnology.value);

      return foundTechChildren !== undefined;
    });

    let mainTechnologyGroup = mainTechnologiesMap[mainTechnology.title];
    mainTechnologyGroup = [
      ...mainTechnologyGroup || [],
      {
        ...subTechnology,
        value: getReadableValue(TECHNOLOGIES, subTechnology.value) // in spanish
      }
    ];

    mainTechnologiesMap[mainTechnology.title] = mainTechnologyGroup;
  });

  const mainTechnologiesEntries = Object.entries(mainTechnologiesMap);

  // this array will store the sum of each data item of each dataset
  // to show the bars sorted in a descending way
  const sumData = new Array(mainTechnologiesEntries.length);
  sumData.fill(0, 0, mainTechnologiesEntries.length - 1);

  mainTechnologiesEntries
    .forEach(([mainTechnologyName, technologies], idx) => {
        technologies.forEach((t, subTechIdx) => {
          const data = new Array(mainTechnologiesEntries.length);
          data.fill(0, 0, mainTechnologiesEntries.length - 1);
          data[idx] = t.count;

          datasets.push({
            data,
            label: t.value,
            backgroundColor: context => {
              return getColor(
                context.dataIndex, // the bar position
                subTechIdx, // the position in the stack
                technologies.length || 1 // the stacks quantity in this bar
              );
            }
          });
          sumData[idx] = (sumData[idx] || 0) + t.count;
        });

      labels.push(mainTechnologyName);
  });

  const sortedData = sumData
    .map((sum, originalIdx) => ({ originalIdx, sum }))
    .sort((a, b) => b.sum - a.sum);

  const sortedDatasets = datasets.map(dataset => {
    dataset.data = sortedData.reduce((acc, sortingPointer) => {
      acc.push(dataset.data[sortingPointer.originalIdx]);
      return acc;
    }, []);

    return dataset;
  });

  return {
    labels: sortedData
      .map(sortingPointer => labels[sortingPointer.originalIdx]),
    datasets: sortedDatasets
  };
}

function getColor(barPosition, positionInStack, splits = 1) {
  const hslColor = HSL_COLORS[barPosition];

  const MIN_LIGHTNESS = hslColor.lightness;
  const MAX_SATURATION = hslColor.saturation;

  const calculatedTotalIncrement = splits <= 10
    ? (splits <= 4 ? 20 : 25)
    : 45;

  const finalLightnessPreview = calculatedTotalIncrement + MIN_LIGHTNESS;

  // if the final lightness is white (+100%), use 95% manually
  const TOTAL_LIGHTNESS_INCREMENT = finalLightnessPreview > 95
      ? 95 - MIN_LIGHTNESS
      : calculatedTotalIncrement;

  const LIGHTNESS_INCREMENT = Math.floor(TOTAL_LIGHTNESS_INCREMENT / splits);

  const HUE =  hslColor.hue;
  const SATURATION = MAX_SATURATION - positionInStack;
  const LIGHTNESS = MIN_LIGHTNESS + positionInStack * LIGHTNESS_INCREMENT;

  return `hsl(${HUE}, ${SATURATION}%, ${LIGHTNESS}%)`;
}

const HSL_COLORS = [
  { hue: 21, saturation: 88, lightness: 69 },
  { hue: 42, saturation: 82, lightness: 68 },
  { hue: 0, saturation: 88, lightness: 69 },
  { hue: 173, saturation: 40, lightness: 55 },
  { hue: 132, saturation: 51, lightness: 68 },
  { hue: 198, saturation: 57, lightness: 61 },
  { hue: 202, saturation: 90, lightness: 57 },
  { hue: 221, saturation: 90, lightness: 66 },
  { hue: 259, saturation: 41, lightness: 50 },
  { hue: 270, saturation: 86, lightness: 65 },
  { hue: 332, saturation: 90, lightness: 67 }
];
