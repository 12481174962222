import React, { useEffect, useContext, useMemo, useCallback } from "react";
import {
  SidebarLayout, BatchContent
} from "../../../../../components/shared";
import {
  Navbar,
  Summary
} from "../../../../../components/admin/batches/show";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import { shared } from "../../../../../graphql/shared";
import { useParams } from "react-router";
import {
  BatchDetailsContextProvider
} from "../../../../../contexts/admin/batches/batch-details";
import {
  BatchContentsContext
} from "../../../../../contexts/admin/batches";
import {
  MenuContext,
  ContentViewSwitchProvider,
  ContentModalContextProvider,
  ContentGroupModalContextProvider,
  ContentDetailsModalContextProvider,
  DraggableListProvider
} from "../../../../../contexts/shared";
import { message } from "antd";

export function ContentIndex() {
  const { id } = useParams();
  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const {
    data, error, loading, refetch
  } = useQuery(shared.queries.getMasterContentByBatchId, {
    client,
    variables: {
      id: id
    }
  });

  const [updateMasterContent] = useMutation(
    shared.mutations.updateMasterContent, { client }
  );

  const [updateContentGroup] = useMutation(
    shared.mutations.updateContentGroup, { client }
  );

  const onUpdateMasterContent = useCallback(async create => {
    await updateMasterContent({
      variables: {
        where: { id: data?.masterContents?.[0]?.id },
        data: { contentGroups: { create } }
      }
    });
    await refetch();
  }, [data]);

  const onUpdateContentSorted = useCallback(async (uuids, groupId) => {
    if (!groupId) {
      await updateMasterContent({
        variables: {
          where: { id: data?.masterContents?.[0]?.id },
          data: { contentGroupsSort: { set: uuids } }
        }
      });
    } else {
      await updateContentGroup({
        variables: {
          where: { id: groupId },
          data: { contentsSort: { set: uuids } }
        }
      });
    }
    await refetch();
    message.success("Ordenamiento guardado.");
  }, [data]);

  const onUpdateContentGroup = useCallback(async (groupId, values) => {
    await updateContentGroup({
      variables: { where: { id: groupId }, data: values }
    });
    await refetch();
  }, [data]);

  const injectActions = useMemo(() => ({
    data,
    error,
    loading,
    refetch,
    batchId: id
  }), [data, loading]);

  const isPublished = data?.masterContents?.[0]?.publishedContents?.length > 0;

  return (
    <BatchDetailsContextProvider>
      <BatchContentsContext.Provider value={injectActions}>
        <SidebarLayout>
          <Summary />
          <Navbar selectedKeys={["content"]} />
          <DraggableListProvider onUpdate={onUpdateContentSorted}>
            <ContentGroupModalContextProvider
              isPublished={isPublished}
              refetch={refetch}
              onSave={onUpdateMasterContent}
              onUpdate={onUpdateContentGroup}>
              <ContentModalContextProvider
                isPublished={isPublished}
                batchId={id}
                refetch={refetch}>
                <ContentDetailsModalContextProvider
                  batchId={id}
                  showDraft={true}
                  refetch={refetch}>
                  <ContentViewSwitchProvider>
                    <BatchContent />
                  </ContentViewSwitchProvider>
                </ContentDetailsModalContextProvider>
              </ContentModalContextProvider>
            </ContentGroupModalContextProvider>
          </DraggableListProvider>
        </SidebarLayout>
      </BatchContentsContext.Provider>
    </BatchDetailsContextProvider>
  );
}
