import React from "react";
import { Button } from "antd";
import { FlexContainer } from "../../../../../shared";

export function Footer({
  onCancelScheduledService = null,
  onNavigateToSessions = null,
  onRequestMore = null,
  scheduledServiceId
}) {

  return onCancelScheduledService
    ? (
      <FlexContainer
        direction="horizontal"
        xAlign="end">
        <Button
          type="danger"
          onClick={onCancelScheduledService}>
          Cancelar solicitud
        </Button>
      </FlexContainer>
    ) : (
      <FlexContainer
        direction="horizontal"
        xAlign="end">
        <Button
          type="default"
          onClick={onNavigateToSessions}>
          Ver sesiones
        </Button>
        <Button
          type="primary"
          onClick={onRequestMore}>
          Solicitar mas
        </Button>
      </FlexContainer>
    );
}
