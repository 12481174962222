import React, { useContext, useEffect, useState } from "react";
import { List, message, Button } from "antd";
import { cloneDeep } from "lodash";
import * as uid from "uuid";
import { AddButton, Visibility, ExpertServiceCard } from "../../../../shared";
import {
  ExpertServiceModalContext
} from "../../../../../contexts/shared/expert-service-modal";
import {
  AssignedExpertServiceModalContext
} from "../../../../../contexts/shared/assigned-expert-service-modal";
import {
  eventTypeEnum,
  paginationConfig,
  withRouter,
  withLoader,
  cardGridSizes
} from "../../../../../helpers";
import {
  AssignedExpertServiceService
} from "../../../../../services/deprecated/assigned-expert-service-service";
import {
  ScheduledExpertServiceService
} from "../../../../../services/deprecated/scheduled-expert-service-service/index";
import {
  CurrentUserContext,
  PaginationContext
} from "../../../../../contexts/shared";
import { UsageTrackingService } from "../../../../../services";
import {
  expertServiceTypes,
  getReadableValue
} from "../../../../../helpers";

function ExpertServicesList({
  isAddEnabled,
  data,
  match,
  onChangePage,
  currentPage
}) {
  const [state, setState] = useState({ expertServices: [] });
  const { params = {} } = match;
  const { onPaginationChange } = useContext(PaginationContext);

  const {
    openModal,
    onDisableExpertService
  } = useContext(ExpertServiceModalContext);

  const { currentUser } = useContext(CurrentUserContext);

  const {
    openModal: openAssignModal,
    addOnSaveListener: addOnSaveListenerAssignment
  } = useContext(AssignedExpertServiceModalContext);

  const assignedExpertServiceService = new AssignedExpertServiceService();
  const scheduledExpertServiceService = new ScheduledExpertServiceService();

  useEffect(() => {
    setState({ expertServices: data?.expertServices });
  }, [data]);

  /**
   * @deprecated This function is prune to errors and represents a performance
   * issue. Move it to the server side
   * @param oDEs
   * @param serviceType
   * @param data
   * @returns {Promise<void>}
   */
  const assignService = async ({ oDEs = [], serviceType, metadata, ...data }) => {
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      if (oDEs.some(o => !(o.ode))) {
        throw "ode field is required to generate scheduled services";
      }

      // This field will be used to identify the assignedExpertServices
      // in the same assignation
      const assignationIdentifier = uid.v4();

      data.limitDate = data.limitDate?.toISOString();

      if (serviceType === "WORKSHOP") {
        data.availableUnits = 0;
      }

      const assignedServicesToCreate = Array.from(oDEs).map(ode => {
        delete ode.name;

        const assignedServiceData = cloneDeep(data);
        assignedServiceData.assignationIdentifier = assignationIdentifier;

        return Object.assign(assignedServiceData, cloneDeep(ode));
      });

      const results = await assignedExpertServiceService
        .createMany(assignedServicesToCreate);

      if (serviceType === "WORKSHOP") {
        await scheduleAssignedServices(results);
      }

      UsageTrackingService.trackAssignedService({
        service: cloneDeep({
          type: getReadableValue(expertServiceTypes,serviceType),
          assignationIdentifier,
          id: data?.connect?.id,
          name: metadata?.expertService?.name
        }),
        user: {
          id: currentUser?.id,
          email: currentUser?.email,
          fullName: currentUser?.fullName,
          systemRole: currentUser?.systemRole
        }
      });
      message.success("Servicio asignado.");
    } catch (e) {
      console.error(e);
      message.error("Ocurrió un error.");
    } finally {
      dismissLoader();
    }
  };

  const scheduleAssignedServices = async (assignedServicesResults = []) => {
    const serviceToSchedule = assignedServicesResults
      .map(({ data }) => {
        const assignedService = data.createAssignedExpertService;

        return {
          serviceDate: assignedService?.limitDate,
          status: "SCHEDULED",
          duration: String(assignedService?.assignedUnits),
          assignedExpertService: { connect: { id: assignedService?.id } },
          events: {
            create: { // log event
              event: eventTypeEnum.STATUS_CHANGED,
              description: "WORKSHOP auto-scheduled after assignment",
              blame: { connect: { id: currentUser?.id  } }
            }
          }
        };
    });

    return scheduledExpertServiceService.createMany(serviceToSchedule);
  };

  addOnSaveListenerAssignment(assignService, "assignService");

  const onEditService = (expertService, index) => () => {
    expertService.index = index;

    openModal({
      expertService,
      expertId: expertService.expert?.id
    });
  };

  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
    onPaginationChange("services")(page, pageSize);
  };

  return (
    <>
      <Visibility visible={isAddEnabled}>
        <AddButton
          onClick={() => openModal({ expertId: params.expertId, newService: true })}
          style={{ width: "100%", marginBottom: "24px" }}>
          Añadir producto
        </AddButton>
      </Visibility>
      <List
        grid={cardGridSizes}
        dataSource={state.expertServices}
        pagination={{
          ...paginationConfig,
          current: currentPage,
          defaultPageSize: 12,
          onChange: (page, pageSize) => handlePaginationChange(page, pageSize),
          total: data?.meta?.aggregate?.count || 0
        }}
        renderItem={(s, i) =>
          <List.Item>
            <ExpertServiceCard
              service={s}
              onEdit={onEditService(s, i)}
              onDisableExpertService={() => onDisableExpertService({
                id: s.id,
                expertId: s.expert?.id
              })}
              extras={
                <Button
                  type="primary"
                  onClick={() => openAssignModal(s)}>
                  Asignar
                </Button>
              } />
          </List.Item>
        } />
    </>
  );
}

export default withLoader(withRouter(ExpertServicesList));
