export const sortColumns = (
  contentColumns = [],
  sectionsSortingArray = [],
  sectionContentsSortingMap = {}
) => {
  const sectionsAlreadySorted = {};

  const sortedContentColumns = sectionsSortingArray.reduce((acc, sortedId) => {
    contentColumns.forEach(column => {
      const { sectionId, children } = column;

      // sort the column children
      const subcolumnsSorting = (sectionContentsSortingMap[sectionId] || []);
      const contentsAlreadySorted = {};

      const sortedChildren = subcolumnsSorting.length < 1
      ? children
      : subcolumnsSorting.reduce((sortedChildren, sortingContentId, idx) => {
        const isTheLastIteration = idx === subcolumnsSorting.length - 1;

        const child = children
          .find(child => child.contentId === sortingContentId);

        if (child !== undefined) { // put the sorted contents at the begining
          sortedChildren.push(child);
          contentsAlreadySorted[child.contentId] = true;
        }

        if (isTheLastIteration) { // put the unsorted contents at the end
          const unsortedChildren = children
            .filter(child => !contentsAlreadySorted[child.contentId]);
          sortedChildren.push(...unsortedChildren);
        }

        return sortedChildren;
      }, []);

      // replace the original children with the sorted ones
      if (sortedId === sectionId) {
        sectionsAlreadySorted[sectionId] = true;
        acc.push({
          ...column,
          children: sortedChildren
        });
      }
    });

    return acc;
  }, []);

  let unsortedColumns = [];
  if (sortedContentColumns.length !== contentColumns.length) {
    unsortedColumns = contentColumns.filter(column => {
      const columnAlreadySorted = sectionsAlreadySorted[column.sectionId];
      return !columnAlreadySorted;
    });
  }

  return sortedContentColumns.concat(unsortedColumns);
};
