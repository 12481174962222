import { client } from "../../../graphql";
import { reportBatchInfo } from "./graphql/report-batch-info";

const exportBatchInfo = async ({ batchId }) => {
  const result = await client.query({
    query: reportBatchInfo,
    fetchPolicy: "network-only",
    variables: {
      batchId
    }
  });

  return result?.data?.reportBatchInfo || null;
};

export const reportService = {
  exportBatchInfo
};
