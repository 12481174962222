import { Col, Row } from "antd";
import React from "react";
import { CompanyDetailIndex, CompanyTimelineIndex } from "..";
import { Section } from "../../..";
import { BatchOdesContents } from "../../../../admin/batches/show/odes";

export const BatchContents = ({
  data,
  loading,
  allowEdit = true,
  publishedContent,
  ode,
  odeContents
}) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Section title="Summary">
              <CompanyDetailIndex
                data={data}
                loading={loading}
                allowEdit={allowEdit} />
            </Section>
          </Col>
          <Col span={24}>
            <Section title="Timeline">
              <CompanyTimelineIndex
                loading={loading}
                data={data} />
            </Section>
          </Col>
        </Row>
      </Col>
      <Col span={18}>
        <BatchOdesContents
          odeContents={odeContents}
          ode={ode}
          publishedContent={publishedContent} />
      </Col>
    </Row>
  );
};
