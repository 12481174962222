import { entrepreneur } from "../../../../graphql/entrepreneur";

export async function createMany(scheduledServices = []) {
  const promises = scheduledServices.map(async data => (
    this.client.mutate({
      mutation: entrepreneur.mutations.createScheduledExpertService,
      variables: { data }
    })
  ));

  return await Promise.all(promises);
}
