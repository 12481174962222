import React from "react";
import { Empty, List, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import * as uuid from "uuid";
import { CompositeField, Visibility } from "../../../../../components/shared";
import { FundingListItem } from "./funding-list-item";

export function FundingInput({ onChange, value, viewMode = false }) {
  const onItemChange = ({ funding, values, idx, replaceItemAtIndex }) => {
    values.uid = funding.uid;
    replaceItemAtIndex(idx, values);
  };

  return (
    <CompositeField
      onChange={onChange}
      isAddDisabled={true}
      value={value}>
      {({items, addNew, removeItem, replaceItemAtIndex}) => {
        return (
          <>
            <List>
              { (items.length === 0) ?
                <Empty />
              : items.map((funding, idx) => (
                <List.Item key={`funding-item-${funding.uid}`}>
                  <FundingListItem
                    funding={funding}
                    idx={idx}
                    viewMode={viewMode}
                    onChange={values =>
                      onItemChange({funding, values, idx, replaceItemAtIndex})}
                    removeItem={removeItem} />
                </List.Item>
                )
              )}
            </List>
            <Visibility
              visible={!viewMode}>
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => addNew({ uid: uuid.v4() })}
                block>
                Agregar fondo
              </Button>
            </Visibility>
          </>);
        }
      }
    </CompositeField>
  );
}
