import React, { useState } from "react";
import { Button, Empty, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CompositeField } from "../../../../composite-field";
import * as uuid from "uuid";
import { TecRelationshipItem } from "../tec-relationship-item";

export const TecRelationshipInput = ({
  setStateForm,
  stateForm,
  onChange,
  value = []
}) => {
  const { enrollment = "", payroll = ""} =
    value.find(el => (el?.enrollment || el?.payroll)) || {}

  const [state, setState] = useState({ enrollment, payroll })

  const onItemChange = ({ tecRelationship, values, idx, replaceItemAtIndex }) => {
    if (values?.enrollment) {
      setState(prevState => ({ ...prevState, enrollment: values?.enrollment }))
    }

    if (values?.payroll) {
      setState(prevState => ({ ...prevState, payroll: values?.payroll }))
    }
    values.uid = tecRelationship.uid;
    replaceItemAtIndex(idx, values);
  };

  const addNewRelationValidation = async (addNew) => {
    await stateForm?.validateFields();
    addNew({ uid: uuid.v4() })
  }

  return (
    <CompositeField
      onChange={onChange}
      isAddDisabled={true}
      value={value}>
      {({ items, addNew, removeItem, replaceItemAtIndex }) => {
        return (
          <>
            <List>
              {(items.length === 0) ?
                <Empty />
                : items.map((tecRelationship, idx) => {
                  tecRelationship.enrollment = state.enrollment
                  tecRelationship.payroll = state.payroll
                  return (
                    <List.Item key={`tecRelationship-item-${tecRelationship.uid}`}>
                      <TecRelationshipItem
                        values={value}
                        setStateForm={setStateForm}
                        tecRelationship={tecRelationship}
                        idx={idx}
                        onChange={values =>
                          onItemChange({
                            tecRelationship,
                            values,
                            idx,
                            replaceItemAtIndex
                          })}
                        removeItem={removeItem} />
                    </List.Item>
                  );
                })
              }
            </List>
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={() => addNewRelationValidation(addNew)}
              block>
              Agregar relación
            </Button>
          </>
        );
      }}
    </CompositeField>
  );
};
