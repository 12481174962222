import { useService } from "../../../../../hooks/shared";
import { AnalyticsService } from "../../../../../services/analytics-service";
import { getPercentage } from "../../../../../helpers";

export function useCompaniesStatistics({
  ...filters
} = {}) {
  const { loading, error, data, refetch } = useService(
    AnalyticsService.getCompaniesStatistics,
    { ...filters }
  );

  const applyFilters = filters => refetch(filters);

  const totalCompanies = data?.companiesCount || 0;
  const techBased = data?.techBasedCompaniesCount || 0;
  const socialComponent = data?.socialCompaniesCount || 0;
  const unEnrolled = data?.unEnrolledCompaniesCount || 0;
  const completedBatchCompanies = data?.completedBatchCompaniesCount || 0;

  const percentageData = Object.entries({
    techBased,
    unEnrolled,
    socialComponent,
    completedBatchCompanies
  })
    .reduce((acc, [key, value]) => {
      acc[key] = getPercentage(totalCompanies, value).toFixed(2);
      return acc;
    }, {});

  return {
    loading,
    error,
    applyFilters,
    data: {
      totalCompanies,
      techBased,
      socialComponent,
      unEnrolled,
      completedBatchCompanies
    },
    percentageData
  };
}