import React, { useState, useEffect } from "react";
import { List, Spin, Form, Alert, Input } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { CompositeField, Visibility } from "../../../../../../components/shared";
import { Select } from "./select";
import { OdeItem } from "./ode-item";

export function OdesSelect({
  onChange,
  data,
  loading = true,
  error,
  expertService = {},
  updateTotalUnits,
  batchId,
  getTotalUnits
}) {
  const [state, setState] = useState({ globalHours: 0 });
  const odes = Array.from(data?.oDEs || []);
  const isWorkshop = expertService?.type === "WORKSHOP";
  const isMentorship = expertService?.type === "MENTORSHIP";
  const isProduct = expertService?.type === "PRODUCT";

  const shouldAddGlobalHours = isWorkshop && expertService?.method === "HOURS";

  useEffect(() => {
    setState({ globalHours: 0 });

    if (isWorkshop) {
      onSaveAllODEsInProgram(odes, undefined);
    }
  }, [data, batchId]);

  const getDefaultUnits = () => {
    let defaultUnit = 1;
    if (isWorkshop && expertService?.method === "HOURS"
      || isProduct && expertService?.method === "HOURS"
      || isMentorship) {
      defaultUnit = 0;
    }

    return defaultUnit;
  };

  const handleOnChange = (values, globalHours) => {
    if (shouldAddGlobalHours && !globalHours) {
      onChange && onChange();
      return;
    }

    const data = values?.map(({ ode, assignedUnits: units }) => {
      const assignedUnits = globalHours ?
        globalHours
        : parseInt(units) || getDefaultUnits();

      return ({
        ode,
        assignedUnits,
        availableUnits: assignedUnits,
        batch: { connect: { id: batchId } }
      });
    });

    const totalUnits = isWorkshop ?
      globalHours
      : getTotalUnits(data);

    updateTotalUnits && updateTotalUnits(totalUnits);
    onChange && onChange(data);
  };

  const onSaveAllODEsInProgram = (odes, hours) => {
    const allOdes = odes?.map(ode => ({
      name: ode.name,
      ode: { connect: { id: ode.id } }
    }));

    if (batchId) {
      handleOnChange(allOdes, hours);
    }
  };

  const onSave = (addNew, replaceItemAtIndex) => values => {
    if (typeof values.index === "undefined") {
      addNew(values);
    } else {
      const index = values.index;
      delete values.index;

      replaceItemAtIndex(index, values);
    }
  };

  if(loading) {
    return (
      <Spin size="small" indicator={<LoadingOutlined />} />
    );
  }

  const onGloabalHoursInputChange = debounce((items = [], hours) => {
    if (isWorkshop) {
      onSaveAllODEsInProgram(odes, hours);
    } else {
      handleOnChange(items, hours);
    }

    setState({ globalHours: hours });
  }, 100);

  return (
    <CompositeField
      isAddDisabled
      onChange={items => handleOnChange(items, state.globalHours)}>
      {({ items, addNew, removeItem, replaceItemAtIndex, updateItem }) =>
        <>
          <Visibility visible={!isWorkshop}>
            <Select
              items={items}
              batchId={batchId}
              loading={loading}
              error={error}
              odes={odes}
              isWorkshop={isWorkshop}
              onSave={onSave(addNew, replaceItemAtIndex)} />
          </Visibility>
          <Visibility  visible={isWorkshop && batchId}>
            <Alert
              type="warning"
              message={odes?.length ? `Se han cargado ${odes?.length}
                Compañías del batch seleccionado`
                : "El batch seleccionado no cuenta con compañías"} />
          </Visibility>
          <Form>
            { shouldAddGlobalHours &&
            <Form.Item
              className="hours"
              label="Horas asignadas"
              rules={[{ required: true }]}>
              <Input
                disabled={loading || error || !batchId}
                className="wide-input"
                onChange={({ target }) => onGloabalHoursInputChange(items, +target.value)}
                type="number"
                min={1}
                defaultValue={0}
                placeholder="Ingresa las horas asignadas para las compañías" />
            </Form.Item> }
          </Form>
          <Visibility visible={!isWorkshop}>
            <List>
              { items.map((item, index) =>
                <OdeItem
                  expertService={expertService}
                  onChange={updateItem(index)}
                  key={`ode_item_${item.ode?.connect?.id}`}
                  ode={item}
                  onDelete={removeItem(index)} />
              ) }
            </List>
          </Visibility>
        </>
      }
    </CompositeField>
  );
}
