import gql from "graphql-tag";

export const getBatchEventsByEntrepreneurId = gql`
  query GetBatchEventsByEntrepreneurId($id: ID!, $batchId: ID!, $month: Int, $year: Int) {
    events(where: {
      batch: { id: $batchId, users_some: { id: $id } },
      month: $month,
      year: $year
    }) {
      id
      date
      name
      type
    }
  }
`;
