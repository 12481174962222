import gql from "graphql-tag";

export const createNotificationSubscription = gql`
  mutation CreateNotificationSubscription(
    $data: NotificationSubscriptionCreateInput!
  ) {
    createNotificationSubscription(data: $data) {
      id
    }
  }
`;
