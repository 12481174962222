import { client } from "../../../graphql";
import {
  updateManyScheduledStatusPaymentMutation
} from "./graphql/update-many-scheduled-status-payment-mutation";

export async function updateManyScheduledExpertServicesCheckBoxPerStatus({
  ids,
  status,
  userId
}) {
  const result = await client.mutate({
    mutation: updateManyScheduledStatusPaymentMutation,
    variables: {
      status,
      userId,
      ids
    }
  });

  return result?.data
    ?.updateManyScheduledExpertServicesCheckBoxPerStatus.count || null;
}