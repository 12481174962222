import { admin } from "../../graphql/admin";
import { client } from "../../../graphql";
import { getBatchExpertsIds } from "./graphql";

const format = ({
  type,
  operation,
  idUser,
  idBatch,
  leader = undefined
}) => {
  if (type === "multiple") {
    return {
      data: {
        users: { [operation]: idUser }
      },
      where: {
        id: idBatch
      }
    };
  }

  return {
    data: {
      leader,
      users: { [operation]: { id: idUser }}
    },
    where: {
      id: idBatch
    }
  };
};

const updateBatch = async variables => {
  const result = await client.mutate({
    mutation: admin.mutations.updateBatch,
    variables
  });

  return result?.data?.updateBatch;
};

const disconnect = async ({ idBatch = "", idUser = "", type = "" }) => {
  let variables = null;

  if (type === "leader") {
    variables = format({
      operation: "disconnect",
      idUser,
      idBatch,
      leader: null
    });
  } else {
    variables = format({
      operation: "disconnect",
      idUser,
      idBatch
    });
  }

  return await updateBatch(variables);
};

const connect = async ({ idBatch = "", idUser = "", type = "" }) => {
  let variables = null;

  if (type === "multiple") {
    variables = format({
      operation: "connect",
      idUser,
      idBatch,
      leader: idUser,
      type
    });
  } else if (type === "leader") {
    variables = format({
      operation: "connect",
      idUser,
      idBatch,
      leader: idUser
    });
  } else {
    variables = format({
      operation: "connect",
      idUser,
      idBatch
    });
  }

  return await updateBatch(variables);
};

/**
 * @param {object} args
 * @param {string} args.batchId
 * @returns {{ id: string }[]} Array of experts
 */
async function getAll({ batchId }) {
  const result = await client.query({
    query: getBatchExpertsIds,
    variables: { batchId }
  });

  return result.data?.batch?.experts;
}


export const BatchMemberService = {
  disconnect,
  connect,
  getAll
};