import gql from "graphql-tag";

export const getBatchesByCoordinatorId = gql`
  query GetBatches(
    $id: ID!,
    $iname: String,
    $programId: ID,
    $skip: Int,
    $first: Int
  ) {
    batches(
      where: {
        users_some: {
          systemRole: COORDINATOR, 
          id: $id 
        }
        iname_contains: $iname
        program: { id: $programId }
      }
      skip: $skip,
      first: $first
    ) {
      id    
      name
      iname
      batchID
      startDate
      finishDate
      description
      odes {
        name
        sector
        logoUrl
      }
      program {
        id
        name
        type
        coverPicUrl
      }
    }
    meta: batchesConnection(
      where: {
        users_some: { 
          systemRole: COORDINATOR, 
          id: $id 
        }
        iname_contains: $iname
        program: { id: $programId }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
