import React from "react";
import {Typography, Button, Progress, Space} from "antd";
import { useHistory } from "react-router-dom";
import { VerificationSteps } from "./steps";
import { Section } from "../../../components/shared";

export function Fallback({
    hasCompletedProfile = false,
    user = {}
  }) {
  const history = useHistory();

  const labels = [];

  if (!hasCompletedProfile) {
    labels.push("Perfil");
  }

  // if (!hasODEs) {
  //   labels.push("Compañía");
  // }

  const onClick = () => {
    let profileRoute;
    let odeRoute;
    switch (user.systemRole) {
      case "COORDINATOR":
        profileRoute = "/v2/coordinator/me/profile";
        break;
      case "ENTREPRENEUR":
        profileRoute = "/v2/entrepreneur/profile/personal-information";
        break;
      default:
        break;
    }

    switch (user.systemRole) {
      case "COORDINATOR":
        odeRoute = "/v2/coordinator/odes";
        break;
      case "ENTREPRENEUR":
        odeRoute = "/v2/entrepreneur/odes";
        break;
      default:
        odeRoute = "/v2/admin/odes";
        break;
    }

    history.push(!hasCompletedProfile ? profileRoute : odeRoute);
  };
  const percentProgress = hasCompletedProfile ? 60 : 30;

  return (
    <Section
      style={{height: "100vh"}}>
      <div className="entrepreneur-warning-container">
        <Typography.Title
          style={{
            marginBottom: ".5rem"
          }}>
          ¡Bienvenido!
        </Typography.Title>
        <Typography.Text
          style={{
            fontSize: "20px",
            textAlign: "center",
            marginBottom: "1rem"
          }}>
          Hagamos que EOS funcione
        </Typography.Text>
        <Space
          align={"center"}
          direction={"horizontal"}
          style={{width: "700px"}}>
          <Typography.Text
            strong>
            Configuración de la cuenta
          </Typography.Text>
          <Progress
            status={"active"}
            trailColor={"#c6c6c6"}
            style={{width: "400px"}}
            percent={percentProgress} />
        </Space>
        <Typography.Text
          style={{
            fontSize: "15px",
            margin: "1.5rem 0",
            textAlign: "center"
          }}>
          Parece que todavía hay algunos pasos sin completar.
        </Typography.Text>
        <VerificationSteps current={hasCompletedProfile ? 2 : 1} />
        <Button
          type="primary"
          onClick={onClick}>
          Terminar la configuración
        </Button>
      </div>
    </Section>
  );
}
