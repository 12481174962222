import React, { useState } from "react";
import { Form, Button, Row, Typography } from "antd";
import { Section } from "../section";
import { FilterableOdesSelect } from "../filterable-odes-select";

export const OdeFormAssign = ({ excludedOdes, connectOdeFromBatch }) => {
  const [state, setState] = useState([]);
  const [form] = Form.useForm();

  const getLabel = text =>
    <Typography.Text style={{ fontSize: "14px"}}>{text}</Typography.Text>;

  const onChangeMemberList = value => {
    setState(value);
    return value;
  };

  const handleSubmit = async () => {
    await form.validateFields();
    const data = form.getFieldsValue();
    connectOdeFromBatch && connectOdeFromBatch(data);
    form.resetFields();
    setState([]);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="OdeFormList">
      <Section style={{ marginBottom: "11px", minHeight: "600px"}}>
        <Row justify="space-between">
          <Form.Item
            getValueFromEvent={onChangeMemberList}
            name="odes"
            label={getLabel("Aquí podrás ver y añadir compañías.")}>
            <FilterableOdesSelect
              excludedOdes={excludedOdes}
              mode="multiple"
              placeholder="Selecciona las compañías que quieres agregar a tu batch" />
          </Form.Item>
          <Button
            disabled={!state.length}
            onClick={handleSubmit}>
            Agregar
          </Button>
        </Row>
      </Section>
    </Form>
  );
};
