import React from "react";
import { OnboardingLayout } from "../../../../components/shared";
import { Experience } from "../../../../components/expert/onboarding";
import { OnBoardingContextProvider } from "../../../../contexts/expert/onboarding";

export function ExperienceIndex() {
  return (
    <OnBoardingContextProvider>
      <OnboardingLayout>
        <Experience />
      </OnboardingLayout>
    </OnBoardingContextProvider>
  );
}
