import React from "react";
import { withLoader } from "../../../../../helpers";
import {
  EntrepreneurshipsEditSection,
  AccountsEditSection,
  FiscalEditSection,
  WorkExperienceEditSection,
  LanguagesEditSection,
  EducationEditSection,
  PapersEditSection,
  CoursesEditSection,
  TecRelationshipEditSection
} from "../../../../shared/profile-forms";
import { PrivacyEditSection } from "../../../../shared/profile-forms/privacy";

function Profile({ editable = false }) {
  return (
    <>
      <PrivacyEditSection editable={editable} />
      <EntrepreneurshipsEditSection editable={editable} />
      <AccountsEditSection editable={editable} />
      <FiscalEditSection editable={editable} />
      <WorkExperienceEditSection editable={editable} />
      <EducationEditSection editable={editable} />
      <CoursesEditSection editable={editable} />
      <PapersEditSection editable={editable} />
      <LanguagesEditSection editable={editable} />
      <TecRelationshipEditSection editable={editable} />
    </>
  );
}

export default withLoader(Profile);
