import React, { useContext, useEffect, useState } from "react";
import { BellOutlined } from "@ant-design/icons";
import { Badge, Popover } from "antd";
import { NotificationsList } from "./notifications-list";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql";
import { CurrentUserContext } from "../../../contexts/shared";
import { useQuery } from "@apollo/react-hooks";

export function NotificationCenter() {
  const { currentUser } = useContext(CurrentUserContext);
  const [state, setState] = useState({ open: false, count: 0 });

  useEffect(() => {
    const getNotificationsCount = async() => {
      const { data } = await client.query({
        query: shared.queries.getNotificationCountByUserId,
        variables: {
          id: currentUser.id
        }
      });

      setState(state => ({
        ...state,
        count: data?.notificationsConnection?.aggregate?.count || 0
      }));
    };

    getNotificationsCount();
  }, []);

  const onVisibleChange = visible => {
    if(visible == true && state.count) {
      client.mutate({
        mutation: shared.mutations.markNotificationsAsReadByUserId,
        variables: {
          id: currentUser.id
        }
      });
      setState(state => ({ ...state, count: 0 }));
    }
  };

  return (
    <Popover
      placement="bottomRight"
      content={<NotificationsList />}
      trigger="click"
      onVisibleChange={onVisibleChange}>
      <div
        style={{padding: "0px 20px 0px 10px"}}>
        <Badge offset={[7, 5]} count={state.count}>
          <BellOutlined
            className="clickable"
            style={{fontSize: "22px"}} />
        </Badge>
      </div>
    </Popover>
  );
}
