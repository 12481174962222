import React from "react";
import { Descriptions } from "antd";
import { FormContainer } from "../../../../../../shared";

export const RelationshipInfo = ({ user = {} }) => {
  return (
    <FormContainer>
      <Descriptions column={2}>
        <Descriptions.Item label="Tipo de vínculo">
          {user.category}
        </Descriptions.Item>
        <Descriptions.Item label="Matrícula / Nómina">
          {user.tecRelationship}
        </Descriptions.Item>
        <Descriptions.Item label="Campus">
          {user.campus}
        </Descriptions.Item>
        <Descriptions.Item label="Carrera">
          {user.career}
        </Descriptions.Item>
      </Descriptions>
    </FormContainer>
  );
};
