import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "../../../../../helpers";
import { client } from "../../../../../../graphql";
import { shared } from "../../../../../graphql/shared";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { EditOdeProvider } from "../../../../../contexts/shared/edit-ode";
import { Section, SidebarLayout } from "../../../../../components/shared";
import { Breadcrumbs, MyDocuments, Navbar } from "../../../../../components/shared/odes/show";
import { SummaryEditSection } from "../../../../../components/shared/ode-forms";

function MyDocumentsIndex({ match }) {
  const { params = {} } = match;
  const odeId = params?.id;

  const { loading, error, data = {} } = useQuery(
    shared.queries.getOdeById,
    {
      client,
      variables: { id: odeId },
      fetchPolicy: "network-only"
    }
  );

  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: ["odes"],
    selectedKeys: ["odes"]
  });

  return(
    <EditOdeProvider
      odeId={odeId}
      data={data}
      loading={loading}
      error={error}>
      <SidebarLayout>
        <Breadcrumbs
          odeName={data?.oDE?.name} />
        <SummaryEditSection
          editable
          data={data}
          isLoading={loading}
          error={error} />
        <Navbar selectedKeys={["my-documents"]} />
        <Section title="Mis documents" spaced>
          <MyDocuments
            editable
            data={data}
            error={error}
            isLoading={loading} />
        </Section>
      </SidebarLayout>
    </EditOdeProvider>
  );
}

export default withRouter(MyDocumentsIndex);