import { Button, List, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { ServiceDetailModalContext } from "../../../contexts/shared";
import { admin } from "../../../graphql/admin";
import { coordinator } from "../../../graphql/coordinator";
import {
  cancelScheduledExpertService
} from "../../../graphql/entrepreneur/mutations";
import {
  cardGridSizes,
  withLoader,
  withRouter,
  cancelledByEnum,
  eventTypeEnum
} from "../../../helpers";
import {
  ScheduledExpertServiceService
} from "../../../services/deprecated/scheduled-expert-service-service";
import { ExpertServiceCard } from "../expert-service-card";
import _ from "lodash";

function AssignedExpertServicesList({ data }) {
  const [state, setState] = useState({ assignedExpertServices: [] });
  const {
    addOnCancelRequestListener,
    closeModal: closeDetailModal,
    openModal
  } = useContext(ServiceDetailModalContext);

  const onCancelRequest = async ({
    scheduledExpertService,
    currentUserId
  }) => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      const scheduledExpertServiceService = new ScheduledExpertServiceService();

      const assignedServiceId = scheduledExpertService.assignedExpertService.id;
      const units = scheduledExpertService.duration;

      await cancelScheduledExpertService({
        variables: {
          id: scheduledExpertService.id,
          cancelledBy: cancelledByEnum.ADMIN,
          events: { // log event
            create: {
              event: eventTypeEnum.STATUS_CHANGED,
              description: "Status changed to CANCELLED",
              blame: { connect: { id: currentUserId } }
            }
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{
          query: admin.queries.getMonitoringServices
        },
        {
          query: coordinator.queries.getMonitoringServicesByCoordinatorId,
          variables: {
            where: {
              coordinatorId: currentUserId
            }
          }
        }]
      });

      await scheduledExpertServiceService.refundAssignedUnits({
        assignedServiceId,
        units
      });

      closeDetailModal();
      message.success("Solicitud cancelada.");
    }
    catch (e) {
      console.error(e);
      message.error("Ocurrio un error.\
        Por favor intenta de nuevo en unos momentos.");
    }
    finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    addOnCancelRequestListener(onCancelRequest);

    if(data?.assignedExpertServices) {
      data.assignedExpertServices = _.uniqBy(
        data.assignedExpertServices,
        "assignationIdentifier"
      );
    }

    setState({ assignedExpertServices: data?.assignedExpertServices });
  }, [data]);

  return (
    <List
      grid={cardGridSizes}
      dataSource={state.assignedExpertServices}
      renderItem={s =>
        <List.Item>
          <ExpertServiceCard
            service={s.expertServiceData}
            extras={
              <Button
                type="primary"
                onClick={() => openModal({ assignedExpertService: s })}>
                Ver
              </Button>
            } />
        </List.Item>
      } />
  );
}

export default withLoader(withRouter(AssignedExpertServicesList));
