import React, { useState } from "react";
import { ProgramsSelect, Visibility } from "../../../../components/shared";
import { SelectedProgram } from "./selected-program";

export function SelectablePrograms({ program = undefined, onChange, ...props }) {
  const [state, setState] = useState({
    program
  });

  const handleOnChange = (programId, program) => {
    setState({ program });
    onChange && onChange(programId);
  };

  return (
    <>
      <ProgramsSelect
        onChange={handleOnChange}
        className="wide-input"
        {...props} />
      <Visibility visible={state.program}>
        <SelectedProgram program={state.program} />
      </Visibility>
    </>
  );
}
