import React, { useState, useEffect, useContext } from "react";
import { Visibility } from "../visibility";
import {
  MaintenanceFallbackPage
} from "../../../pages/public/maintenance-fallback";
import { PlatformAlert } from "../platform-alert";
import { SplitContext } from "@splitsoftware/splitio-react";

export const MaintenanceFallbackWrapper = ({ children }) => {
  const [stage, setStage] = useState();

  let { client } = useContext(SplitContext);

  useEffect(() => {
    client.on(client.Event.SDK_READY, () => {
      const maintenanceStage = client.getTreatment("MaintenanceStage");
      setStage(maintenanceStage);
    });

    client.on(client.Event.SDK_UPDATE, () => {
      const maintenanceStage = client.getTreatment("MaintenanceStage");
      setStage(maintenanceStage);
    });

    return () => {
      client.destroy();
      client = null;
    };
  }, []);

  const systemRole = window.localStorage.getItem("role");

  return (
    <>
      <Visibility visible={stage === "ON_GOING"}>
        <MaintenanceFallbackPage />
      </Visibility>
      <Visibility
        visible={stage === "ANNOUNCE" && systemRole === "ENTREPRENEUR"}>
        <PlatformAlert>
          <div>
            A partir del 27/Julio/2022, si tu perfil de usuario no pertenece a una compañía no podrás navegar por la plataforma hasta que te des de alta en una.
            Por favor asegúrate de formar parte del equipo de por lo menos una compañía registrada en EOS para seguir navegando con normalidad.
          </div>
        </PlatformAlert>
      </Visibility>
      <Visibility visible={stage !== "ON_GOING"}>
        {children}
      </Visibility>
    </>
  );
};
