import React, {useContext, useEffect, useState} from "react";
import { Modal, Typography, Form, Space, message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import {
  BatchOdesContext,
  BatchDetailsContext
} from "../../../../../../../contexts/admin/batches";
import { admin } from "../../../../../../../graphql/admin";
import { client } from "../../../../../../../../graphql";
import { FilterableOdesSelect } from "../../../../../../shared";
import { shared } from "../../../../../../../graphql/shared";
import { UsageTrackingService } from "../../../../../../../services";
import { CurrentUserContext } from "../../../../../../../contexts/shared";
import { CompanyService } from "../../../../../../../services/company-service";

export function AddOdeModal({ visible = false, closeModal }) {
  const { data: { batch } = {} } = useContext(BatchDetailsContext);
  const { data: { oDEs } = {} } = useContext(BatchOdesContext);
  const { currentUser } = useContext(CurrentUserContext);

  const [form] = Form.useForm();

  const [connectOdesToBatch] = useMutation(
    admin.mutations.connectOdesToBatch, {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: admin.queries.getOdesByBatchId,
        variables: { id: batch?.id }
      }]
    }
  );

  const [createPublishedContent] = useMutation(
    admin.mutations.createPublishedContent, { client }
  );

  const [masterContentsData, setMasterContentsData] = useState(null);

  useEffect(() => {
    if (!batch?.id)
      return;

    client.query({
      query: shared.queries.getMasterContentByBatchId,
      variables: {
        id: batch.id
      },
      fetchPolicy: "network-only"
    }).then(r => {
      setMasterContentsData(r.data);
    });
  }, [batch?.id]);

  const handleOnClose = () => {
    closeModal && closeModal();
  };

  const handleOnOk = async () => {
    const dismissLoader = message.loading("Guardando...", 0);
    await form.validateFields();
    const values = form.getFieldsValue();

    const odesIds = values.odes.map(id => id);

    values.odes = {
      connect: values.odes?.map(id => ({ id }))
    };
    const masterContentId = masterContentsData?.masterContents?.[0]?.id || undefined;
    const hasPublished = masterContentsData?.masterContents?.[0]?.publishedContents || [];

    try {
      if(hasPublished.length > 0) {
        Promise.all(
          values.odes.connect.map(ode => {
            return createPublishedContent({
              variables: {
                odeId: ode.id,
                masterContentId
              }
            });
          })
        );
      }

      const batchId = batch?.id;

      await connectOdesToBatch({
        variables: {
          data: { ...values },
          batchId
        }
      });

      await Promise.all(odesIds.map(odeId =>
        CompanyService.registerCompanyEnrolledWithBatchEvent({ odeId, batchId })
      ));

      message.success("Cambios guardados.");
      form.resetFields();
      closeModal && closeModal();

      try {
        const companies = await CompanyService.getCompaniesByIds({
          ids: values.odes.connect.map(ode => ode.id)});
        UsageTrackingService.trackCompaniesLinkedToBatch({
          companies: {
            names: companies.map(company => company.name).join(", "),
            count: companies.length
          },
          batch: {
            id: batch?.id,
            name: batch?.name
          },
          createdBy: {
            id: currentUser?.id,
            fullName: currentUser?.fullName,
            email: currentUser?.email,
            systemRole: currentUser?.systemRole
          }
        });
      } catch (e) {
        console.error(e);
      }
    } catch (error) {
      console.error(error);
      message.error("Ocurrió un error");
    } finally {
      dismissLoader();
    }
  };

  return (
    <Modal
      okText="Agregar"
      onCancel={handleOnClose}
      onOk={handleOnOk}
      title="Agregar Compañía al batch"
      visible={visible}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Typography.Text>
          Selecciona las compañías que quieras agregar al programa
        </Typography.Text>
        <Form form={form} layout="vertical">
          <Form.Item name="odes" label="Compañías">
            <FilterableOdesSelect excludedOdes={oDEs} mode="multiple" />
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
}
