/**
 * @param {rawData[]} experiencesRows
 * @return {experiencesInput[]}
 */

export const formatExperiencesForImportation = (experiencesRows = []) => {
  return experiencesRows.map(raw => ({
    name: raw.Nombre,
    description: raw["Descripción"],
    // Crear helper para sacar esta operación del formateador
    /* Excel stores dates as a number of days since 1/1/1900. This is a way to
    convert that to milliseconds date. */
    startDate:
      new Date((raw["Fecha de inicio"] - 25568) * 86400 * 1000).toISOString(),
    endDate:
      new Date((raw["Fecha de fin"] - 25568) * 86400 * 1000).toISOString(),
    durationInHours: raw["Duración (horas"],
    involvementLevel: raw["Nivel de involucramiento numérico"],
    type: raw["Tipo de experiencia"],
    category: raw["Categoría"],
    audience: raw.Audiencia,
    minimumAudienceSemester: parseInt(raw["Semestre de audiencia mínimo"]),
    maximumAudienceSemester: parseInt(raw["Semestre de audiencia máximo"]),
    projectProfiles:
      raw["Perfil de proyecto"] ? [raw["Perfil de proyecto"]] : undefined,
    input: raw.Input,
    output: raw.Output,
    callToAction: raw["Call to action"],
    curriculumType: raw.Curricularidad,
    ufCode: raw["Clave UF"] ? raw["Clave UF"] : undefined,
    organizedByMTYTec:
      raw["Experiencia organizada por el Tec de Monterrey"] === "Si",
    campus: raw.Campus,
    institution: raw["Institución que organiza"],
    sponsor: raw["Aliado o patrocinador"]
      ? raw["Aliado o patrocinador"]
      : undefined,
    client: raw.Cliente ? raw.Cliente : undefined,
    profileTypes: [raw["Perfil del gestor"]],
    managerIsEntrepreneurshipClubMember:
      raw["¿El gestor es parte de un Club de emprendimiento?"] === "Si",
    firstName: raw["Nombre de gestor"],
    email: raw["Correo de gestor"],
    enrollmentSchoolControlNumber:
      raw["Matrícula de gestor"] ? raw["Matrícula de gestor"] : undefined,
    payroll: raw["Nómina de gestor"]
      ? String(raw["Nómina de gestor"])
      : undefined,
    teacherCampus: [raw["Campus del gestor"]],
    country: raw["País"],
    city: raw.Ciudad
  }));
};

/**
 * @typedef experiencesInput
 * @property name: string,
 * @property description: string,
 * @property startDate: string,
 * @property endDate: string,
 * @property durationInHours: number,
 * @property involvementLevel: string,
 * @property type: string,
 * @property category: string,
 * @property audience: string,
 * @property minimumAudienceSemester: number,
 * @property maximumAudienceSemester: number,
 * @property projectProfiles: [string],
 * @property input: string,
 * @property output: string,
 * @property callToAction: string,
 * @property curriculumType: string,
 * @property ufCode: string,
 * @property organizedByMTYTec: boolean,
 * @property campus: string,
 * @property institution: string,
 * @property sponsor: string,
 * @property client: string,
 * @property profileTypes: [string],
 * @property managerIsEntrepreneurshipClubMember: boolean,
 * @property firstName: string,
 * @property email: string,
 * @property enrollmentSchoolControlNumber: string,
 * @property payroll: string,
 * @property teacherCampus: [string],
 * @property country: string,
 * @property city: string
 */

/**
 * @typedef rawData
 * @property {string} Nombre
 * @property {string} Descripción
 * @property {string} Fecha de inicio
 * @property {string} Fecha de fin
 * @property {string} Duración (horas
 * @property {string} Nivel de involucramiento numérico
 * @property {string} Tipo de experiencia
 * @property {string} Categoría
 * @property {string} Audiencia
 * @property {string} Semestre de audiencia mínimo
 * @property {string} Semestre de audiencia máximo
 * @property {string} Perfil de proyecto
 * @property {string} Input
 * @property {string} Output
 * @property {string} Call to action
 * @property {string} Curricularidad
 * @property {string} Clave UF
 * @property {string} Experiencia organizada por el Tec de Monterrey
 * @property {string} Campus
 * @property {string} Institución que organiza
 * @property {string} Nombre de gestor
 * @property {string} Perfil del gestor
 * @property {string} Correo de gestor
 * @property {string} Matrícula de gestor
 * @property {string} Nómina de gestor
 * @property {string} Campus del gestor
 * @property {string} ¿El gestor es parte de un Club de emprendimiento?
 * @property {string} Ciudad
 * @property {string} País
 * @property {string} Aliado o patrocinador
 * @property {string} Cliente
 */
