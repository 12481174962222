import React from "react";
import { AnnouncementsList } from "./announcements-list";
import { AnnouncementsSectionDetail } from "../../../../shared";
import { useHistory } from "react-router";

/**
 * @param {string} batchId
 * @param {string} announcementId Will show the announcement detail if
 * announcementId is passed
 * @constructor
 */
export function Announcements({ batchId, announcementId }) {
  const history = useHistory();

  if (announcementId) {
    return (
      <AnnouncementsSectionDetail
        announcementId={announcementId}
        onGoBack={() => history.replace("./offer?section=announcements")} />
    );
  }

  return (
    <AnnouncementsList
      batchId={batchId} />
  );
}
