import gql from "graphql-tag";

export const disconnectOdeFromBatch = gql`
  mutation DisconnectOdeFromBatch($batchId: ID!, $data: BatchUpdateInput!) {
    updateBatch(
      where: { id: $batchId }
      data: $data
    ) {
      id
    }
  }
`;
