import React from "react";
import { Menu } from "antd";
import { ExportOutlined, UserOutlined, ReadOutlined } from "@ant-design/icons";

export function Options({ onClickSignout, onClickProfile, manualUrl }) {
  return (
    <Menu>
      <Menu.Item
        key="profile"
        onClick={onClickProfile}
        icon={<UserOutlined />}>
        Perfil
      </Menu.Item>
      <Menu.Item
        key="userManual"
        icon={<ReadOutlined />}>
        <a
          target="_blank"
          href={manualUrl}
          rel="noopener noreferrer">
          Manual de usuario
        </a>
      </Menu.Item>
      <Menu.Item
        key="signout"
        onClick={onClickSignout}
        icon={<ExportOutlined />}>
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );
}