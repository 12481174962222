import React from "react";
import { Steps } from "antd";
import { Section } from "../../../../shared";

export const StepsNavigation = ({ current }) => {
  const { Step } = Steps;
  return (
    <Section
      style={{ margin: "5px 13px" }}>
      <Steps
        current={current}
        style={{ padding: "0 113px", fontWeight: "500" }}>
        <Step title={"Información"} />
        <Step title={"Integrantes"} />
        <Step title={"Contenido"} />
        <Step title={"Publicar"} />
      </Steps>
    </Section>
  );
};
