import gql from "graphql-tag";

export const updateCustomOdeContent = gql`
  mutation UpdateCustomOdeContent(
    $data: CustomOdeContentUpdateInput!,
    $where: CustomOdeContentWhereUniqueInput!
  ) {
    updateCustomOdeContent(data: $data, where: $where) {
      id
    }
  }
`;
