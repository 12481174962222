import React, { useState } from "react";
import { Button } from "antd";
import { Visibility } from "../../../../../shared";

export function ModalButtons({
  onReject,
  onOk,
  onCancel,
  loadingData = false
}) {
  const [loading, setLoading] = useState(false);

  const handleButtonClick = callback => async () => {
    try {
      setLoading(true);
      if (callback) await callback();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Visibility visible={onCancel && !loadingData}>
        <Button
          key="cancel"
          onClick={onCancel}>
          Cancelar
        </Button>
      </Visibility>
      <Visibility visible={onReject && !loadingData}>
        <Button
          danger
          key="reject"
          type="primary"
          onClick={handleButtonClick(onReject)}
          loading={loading}>
          Rechazar entrega
        </Button>
      </Visibility>
      <Visibility visible={onOk && !loadingData}>
        <Button
          key="ok"
          type="primary"
          onClick={handleButtonClick(onOk)}
          loading={loading}>
          Aceptar entrega
        </Button>
      </Visibility>
    </>
  );
}