import gql from "graphql-tag";

export const updateBatchInstitutions = gql`
  mutation UpdateBatchInstitutions($batchId: ID!, $institutions: [String!]!) {
    updateBatch(
      where: { id: $batchId }
      data: {
        institutions: { set: $institutions }
      }
    ) {
      id
      institutions
    }
  }
`;
