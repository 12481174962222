import React from "react";
import { Space } from "antd";
import ColorHash from "color-hash";

export function EmptyImage({ style = {}, text = "" , onClick }) {
  text = text ?? "N A";

  const colorHash = new ColorHash();
  const color = colorHash.hex(text);
  text = text
    .split(" ")
    .slice(0, 2)
    .map(s => s[0])
    .join("")
    .toUpperCase();

  return (
    <Space
      onClick={onClick}
      className="empty-image"
      align="center"
      direction="vertical"
      style={{
        cursor: "pointer",
        backgroundColor: color,
        ...style
      }}>
      <div
        style={{ color: color }}
        className="circle">
        {text}
      </div>
    </Space>
  );
}
