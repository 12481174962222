import {
  GET_CUSTOM_ODE_CONTENTS_BY_BATCH
} from "./graphql/get-custom-ode-contents";
import {
  graphqlApolloClient
} from "../data-connection-service/graphql-apollo-client";

export const getCustomOdesContents = async ({ batchId, batchOdesIds = [] }) => {
  const response = await graphqlApolloClient.query({
    query: GET_CUSTOM_ODE_CONTENTS_BY_BATCH,
    fetchPolicy: "network-only",
    variables: { batchId, batchOdesIds }
  });

  return response?.data?.customOdeContents || [];
};
