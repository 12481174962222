import React from "react";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";

setTimeout(() => {}).__proto__.unref = () => {};

const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG || "xxxx",
  releaseStage: process.env.REACT_APP_ENVIRONMENT === "production"
    ? "production"
    : "development"
});
bugsnagClient.use(bugsnagReact, React);

// wrap your entire app tree in the ErrorBoundary provided
const ErrorBoundary = bugsnagClient.getPlugin("react");

export { ErrorBoundary as ErrorBoundary, bugsnagClient as Bugsnag };
