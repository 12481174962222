import gql from "graphql-tag";

export const getFullScheduledExpertServiceById = gql`
  fragment assignedExpertServiceFragment on AssignedExpertService {
    id
    status
    finished
    availableUnits
    assignedUnits
    assignmentNumber
    assignationIdentifier
    limitDate
    assignmentDate
    createdAt
    expertServicePrice
    expertServiceData
    isProbono
    medium
    mediumDescription
    expertService {
      id
      name
      type
      price
      method
      category
      expert {
        id
        fullName
        firstName
        middleName
        lastName
        email
        phone
      }
    }
    batch {
      id
      name
      users {
        id
        fullName
        phone
        email
      }
      program {
        id
        name
        coordinator {
          id
          fullName
          phone
          email
        }
      }
    }
    ode {
      id
      name
      email
      phone
      team {
        id
        fullName
        email
        phone
        systemRole
      }
    }
  }

  query GetFullScheduledExpertServiceById($id: ID!) {
    scheduledExpertService(where: { id: $id }) {
      id
      serviceDate
      status
      duration
      expertComments
      deliveredAt
      acceptedAt
      cancelledBy
      calendarEventUri
      requestedBy {
        id
        fullName
        email
        phone
      }
      updatedAt
      links
      attachments {
        url
        fileName
        type
      }
      assignedExpertService {
        ...assignedExpertServiceFragment
      }
      events {
        id
        event
        description
        createdAt
        blame {
          id
          email
          systemRole
          fullName
        }
      }
    }
  }
`;
