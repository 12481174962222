import React from "react";
import { List } from "antd";
import { BatchItem } from "../../../admin/batches/list/item";
import { withLoader, withRouter, cardGridSizes, paginationConfig } from "../../../../helpers";
import useTreatment from "../../../../hooks/feature-flags/use-treatment";

function BatchesList({
  data,
  loading,
  navigate,
  onChangePage,
  currentPage
}) {
  const handlePaginationChange = (page, pageSize) => {
    onChangePage(page, pageSize);
  };

  const disableBatchOfferTreatmentValue =
    useTreatment("DisableBatchOffer");

  const navigateToBatchDetail = id => {
    if (disableBatchOfferTreatmentValue === "ON") {
      navigate(`./batches/${id}/objectives`);
    } else if(disableBatchOfferTreatmentValue === "OFF") {
      navigate(`./batches/${id}/offer`);
    }
  };

  return (
    <List
      style={{ marginTop: "24px" }}
      pagination={{
        ...paginationConfig,
        showSizeChanger: true,
        current: currentPage,
        defaultPageSize: 12,
        onChange: page => handlePaginationChange(page),
        total: data?.meta?.aggregate?.count || 0
      }}
      loading={loading}
      grid={cardGridSizes}
      dataSource={data?.batches}
      renderItem={batch =>
        <List.Item>
          <BatchItem
            clickable
            batch={batch}
            onClick={() =>
              navigateToBatchDetail(batch?.id)} />
        </List.Item>
      } />
  );
}


export default withLoader(withRouter(BatchesList));
