import { useState, useEffect } from "react";
import { sortColumns } from "./sort-content-columns";
import { buildColumnsAndRows } from "./build-columns-and-rows";

export const useBatchContentsProgressTable = ({
  companies,
  batchProgress,
  sectionsSorting = [],
  contentsSorting = {}, // {[sectionId]: Array<contentId>}
  customOdeContents,
  batchContentSections
}) => {
  const [tableData, setTableData] = useState({
    companiesRows: [],
    contentColumns: [],
    tableBuildingFinished: false
  });

  useEffect(() => {
    const someDataIsNotReady = !companies
      || !batchProgress
      || !customOdeContents
      || !batchContentSections;

    if (tableData.tableBuildingFinished) return;

    if (someDataIsNotReady) return;
    calculateData && calculateData();
  }, [companies, batchProgress, customOdeContents, batchContentSections]);

  const calculateData = type => {
    const { rows, columns } = buildColumnsAndRows({
      companies: companies || [],
      batchProgress: batchProgress || {},
      customOdeContents: customOdeContents || [],
      batchContentSections: batchContentSections || [],
      type
    });

    setTableData({
      companiesRows: rows,
      tableBuildingFinished: true,
      contentColumns: sortColumns(
        columns,
        sectionsSorting,
        contentsSorting
      )
    });
  };

  const filterContent = type => {
    calculateData && calculateData(type);
  };

  return {
    ...tableData,
    filterContent
  };
};
