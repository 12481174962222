import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { Summary } from "../../../../../components/admin/batches/show/summary";
import { MainMenu } from "../../../../../components/admin/shared";
import { AssignedExpertServicesList, Section, SidebarLayout } from "../../../../../components/shared";
import { BatchDetailsContextProvider } from "../../../../../contexts/admin/batches/batch-details";
import {
  default as Navbar
} from "../../../../../components/admin/batches/show/navbar";
import { client } from "../../../../../../graphql";
import { admin } from "../../../../../graphql/admin";
import { shared } from "../../../../../graphql/shared";
import { ExpertServiceModalProvider } from "../../../../../contexts/shared/expert-service-modal";
import { AssignedExpertServiceModalProvider } from "../../../../../contexts/shared/assigned-expert-service-modal";
import { ServiceDetailModalProvider } from "../../../../../contexts/shared";

export function TrainingsIndex() {
  const { id } = useParams();

  const {
    loading: batchLoading,
    error: batchError,
    data: batchData
  } = useQuery(
    admin.queries.getBatchById,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      client
    }
  );

  const {
    loading,
    error,
    data
  } = useQuery(
    shared.queries.getAssignedServicesByBatchId,
    {
      variables: {
        type: "WORKSHOP",
        id
      },
      fetchPolicy: "network-only",
      client
    }
  );

  return (
    <BatchDetailsContextProvider>
      <AssignedExpertServiceModalProvider>
        <ServiceDetailModalProvider>
          <ExpertServiceModalProvider>
            <SidebarLayout menu={<MainMenu />}>
              <Summary
                isLoading={batchLoading}
                error={batchError}
                data={batchData} />
              <Navbar selectedKeys={["trainings"]} />
              <Section spaced title="Capacitación">
                <AssignedExpertServicesList
                  data={data}
                  isLoading={loading}
                  error={error} />
              </Section>
            </SidebarLayout>
          </ExpertServiceModalProvider>
        </ServiceDetailModalProvider>
      </AssignedExpertServiceModalProvider>
    </BatchDetailsContextProvider>
  );
}
