import React from "react";
import { useState } from "react";
import { debounce } from "lodash";
import { Select, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export const SearchBar = ({
  found,
  search,
  onSelect,
  selected,
  placeholder = ""
}) => {
  const [loading, setLoading] = useState(false);

  const handleSearch = searchText => {
    setLoading(true);
    search(searchText).finally(() => setLoading(false));
  };

  const handleChange = personId => {
    onSelect(personId);
  };

  return (
    <Select
      allowClear
      showSearch
      value={selected}
      onChange={handleChange}
      defaultValue={null}
      filterOption={false}
      placeholder={placeholder}
      notFoundContent={
        loading
          ? <Spin size="small" />
          : "Sin resultados"
      }
      suffixIcon={<SearchOutlined />}
      onSearch={debounce(handleSearch, 500)}
      getPopupContainer={triggerFocus => triggerFocus.parentNode}>
      {found?.map(el => (
        <Select.Option
          name={el.fullName}
          value={el.personId}
          key={el.personId}>
          {el.fullName}
        </Select.Option>
      ))}
    </Select>
  );
};