import React, { useContext, useState } from "react";
import { message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { CreatePerkModalContext } from "./context";
import { CreatePerkModal } from "./modal";
import { admin } from "../../../graphql/admin";
import { client } from "../../../../graphql";
import { CurrentUserContext } from "../current-user";

export const CreatePerkModalProvider = ({ children }) => {

  const initialState = {
    viewMode: false,
    perk: {}
  };

  const { currentUser } = useContext(CurrentUserContext);

  const [visible, setVisible] = useState(false);
  const [state, setState] = useState(initialState);

  const {viewMode, perk} = state;

  const [createPerk] = useMutation(
    admin.mutations.createPerk,
    {client}
  );

  const onCreate = async data => {
    const dismissLoading = message.loading("Guardando...");
    try {
      await createPerk({
        variables: { data: data },
        awaitRefetchQueries: true,
        refetchQueries:[{
          query: admin.queries.getPerks,
          variables: {where:{createdBy: {id: currentUser.id}}}
        }]
      });
    } catch(e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
    } finally {
      dismissLoading();
    }
  }
;
  const openModal = () => {
    setVisible(true);
  };

  const onCancel = () => {
    setState(initialState);
    setVisible(false);
  };

  const onViewPerk = perk => {
    setState({
      viewMode: true,
      perk: perk
    });
    openModal();
  };

  const value = {
    openModal,
    onCancel,
    onCreate,
    onViewPerk
  };

  return(
    <CreatePerkModalContext.Provider value={value}>
      <CreatePerkModal
        visible={visible}
        viewMode={viewMode}
        perk={perk} />
      {children}
    </CreatePerkModalContext.Provider>
  );
};
