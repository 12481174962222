import React, { useContext, useState, useMemo, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { CurrentUserContext } from "../../shared";
import { OnboardingContext } from "./context";
import { client } from "../../../../graphql";
import { coordinator } from "../../../graphql/coordinator";

export function OnboardingContextProvider({ children }) {

  const [state, setState] = useState({
    coordinator: {},
    dirty: false
  });

  const { currentUser } = useContext(CurrentUserContext);

  const { data, loading, error } = useQuery(
    coordinator.queries.getCoordinatorById,
    {
      variables: { id: currentUser.id },
      fetchPolicy: "network-only",
      client
    }
  );

  const [ updateCoordinatorById ] = useMutation(
    coordinator.mutations.updateCoordinatorById,
    { client }
  );

  const updateCoordinator = useCallback(coordinator => {
    const newCoordinator = {
      ...state.coordinator,
      ...coordinator
    };

    setState(prevstate => ({
      ...prevstate,
      dirty: true,
      coordinator: newCoordinator
    }));
  });

  const save = useCallback(() => {
    return updateCoordinatorById({
      variables: { data: state.coordinator, id: currentUser.id }
    });
  });

  const injectActions = useMemo(() => ({
    updateCoordinator,
    save,
    data,
    loading,
    error
  }), [state, data]);

  return(
    <OnboardingContext.Provider value={injectActions}>
      { children }
    </OnboardingContext.Provider>
  );
}