import React, { useContext, useState, useEffect } from "react";
import { Section, AddButton, ObjectiveItem, Visibility } from "../../../../../shared";
import { List, Typography } from "antd";
import { withLoader, paginationConfig } from "../../../../../../helpers";
import { CurrentUserContext } from "../../../../../../contexts/shared";

function ObjectivesList({ loading, data, ...props }) {
  const [state, setState] = useState({ objectives: [] });
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    if(data?.objectives) {
      setState({
        objectives: data.objectives
      });
    }
  }, [data]);

  const addEmptyObjective = () => {
    const objectives = Array.from(state.objectives);

    objectives.unshift({
      description: undefined,
      name: undefined,
      new: true
    });

    setState({ objectives: objectives });
  };

  return (
    <Section
      title="Objetivos"
      spaced>
      <Visibility
        visible={!["ENTREPRENEUR"]
          .includes(currentUser.systemRole)}>
        <Typography.Text>
          Agrega los objetivos a cumplir a través del programa, considera los
          objetivos como el resultado más grande de la Compañía durante el transcurso
          de este tiempo dentro de EOS
        </Typography.Text>
      </Visibility>
      <Visibility
        visible={["ENTREPRENEUR"]
          .includes(currentUser.systemRole)}>
        <Typography.Text>
          Tu progreso se mide con base en los objetivos cumplidos dentro de un programa.
          Un objetivo está conformado por una serie de logros que debes ir marcando como completados.
          De esta manera lograrás ver progreso en tu avance.
        </Typography.Text>
      </Visibility>
      <Visibility
        visible={!["ENTREPRENEUR"]
          .includes(currentUser.systemRole)}>
        <AddButton
          onClick={() => addEmptyObjective()}
          style={{ width: "100%", marginTop: "20px" }}>
          Agregar objetivo
        </AddButton>
      </Visibility>
      <List
        style={{ marginTop: "20px" }}
        loading={loading}
        itemLayout="horizontal"
        dataSource={state.objectives}
        pagination={paginationConfig}
        renderItem={i =>
          <ObjectiveItem key={i.id} objective={i} />
        }
        {...props} />
    </Section>
  );
}

export default withLoader(ObjectivesList);
