import React, { useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Input
} from "antd";
import moment from "moment";

import {
  FormContainer,
  DatePicker
} from "../../../../../../../shared";

const { Item, useForm } = Form;

export const VolunteeringModal = ({
  visible = false,
  onCancel,
  onConfirm,
  volunteering = {}
}) => {
  const [form] = Form.useForm();
  const validationMessages = {
    required: "El campo '${label}' es requerido."
  };

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const handleConfirm = async () => {
    try {
      const values = await form.validateFields();
      onConfirm({
        ...volunteering,
        ...values
      });
    } catch (error) {
      // TODO: Report to bugsnag
    }
  };

  return (
    <Modal
      forceRender
      style={{ width: "600px" }}
      closable
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button
          type="primary"
          onClick={handleConfirm}>
          Guardar
        </Button>,
        <Button
          onClick={onCancel}>
          Cancelar
        </Button>
      ]}
      title="Voluntariados">
      <FormContainer>
        <Form
          form={form}
          layout="vertical"
          validateMessages={validationMessages}>
          <Item
            initialValue={volunteering.description}
            name="description"
            label="Descripción"
            rules={[{ required: true }]}>
            <Input />
          </Item>
          <Item
            initialValue={volunteering.date ?
              moment(volunteering.date) : null}
            name="date"
            label="Fecha"
            rules={[{ required: true }]}>
            <DatePicker />
          </Item>
        </Form>
      </FormContainer>
    </Modal>
  );
};
