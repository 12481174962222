import React, { useState, useEffect, useContext } from "react";
import { Table } from "antd";
import { withLoader } from "../../../helpers";
import { TaskDetailsModalContext } from "../../../contexts/shared/task-details-modal/context";
import { ActionButton } from "../index";
import { columns } from "./table-colums-definition";
import { CreateTaskModalContext } from "../../../contexts/shared/create-task-modal/context";
import { CurrentUserContext } from "../../../contexts/shared";

function TaskTable({ data, selectedState, inputSearch }) {
  const [loading, setLoading] = useState(true);
  const [tableContent, setTableContent] = useState({
    rawContent: [],
    content: []
  });

  const {
    openModal,
    setTask,
    deleteCustomTask
  } = useContext(TaskDetailsModalContext);

  const { openEditModal } = useContext(CreateTaskModalContext);
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    setTableContent({
      content: data?.tasks,
      rawContent: data?.tasks
    });
    setLoading(false);
    setTask(data?.tasks);
  }, [data]);

  useEffect(() => {
    handleSelectorChange(selectedState);
  }, [selectedState]);

  useEffect(() => {
    if (inputSearch !== "") {
      handleInputSearch(inputSearch);
    } else {
      setTableContent(prev => ({
        ...prev,
        content: prev.rawContent
      }));
    }
  }, [inputSearch]);

  const handleInputSearch = value => {
    const searchedContent = [];

    tableContent.rawContent.map(content => {
      if (content?.name?.includes(value)) {
        searchedContent.push(content);
      }
    });

    setTableContent(prev => ({
      ...prev,
      content: searchedContent
    }));
  };

  const handleSelectorChange = value => {
    switch (value) {
      case "completed":
        setTableContent(prev => ({
          ...prev,
          content: prev
            .rawContent
            .filter(content => content.isCompleted === true)
        }));
        break;

      case "pending":
        setTableContent(prev => ({
          ...prev,
          content: prev
            .rawContent
            .filter(content => content.isCompleted === false)
        }));
        break;

      default:
        setTableContent(prev => ({
          ...prev,
          content: prev.rawContent
        }));
        break;
    }
  };

  const onRowClick = task => {
    return {
      onClick: () => openModal(task.id)
    };
  };

  const dynamicColums = [
    {
      title: "Acciones",
      key: "action",
      render: task => (
        <ActionButton
          onClick={e => { e.stopPropagation(); }}
          onEdit={() => openEditModal(task)}
          onDelete={() => deleteCustomTask(task.id)} />
      )
    }
  ];

  let tableColumns = columns;
  if (currentUser?.systemRole !== "ENTREPRENEUR") {
    tableColumns = columns.concat(dynamicColums);
  }

  return (
    <Table
      onRow={onRowClick}
      style={{ marginTop: "15px" }}
      loading={loading}
      columns={tableColumns}
      dataSource={tableContent.content} />
  );
}

export default withLoader(TaskTable);
