import React, { useContext } from "react";
import { Space, Button } from "antd";
import { remove as removeAccents } from "remove-accents";
import { debounce } from "lodash";
import { useParams } from "react-router";
import {
  SearchToolbar,
  Visibility
} from "../../../../../../components/shared";
import {
  InvitationModalContext,
  CurrentUserContext
} from "../../../../../../contexts/shared";

export function FilterControls({ onChange }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { openModal } = useContext(InvitationModalContext);
  const { id: batchId } = useParams();

  const handleOnSearchChange = debounce(text => {
    const stringValue = removeAccents(text).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ searchString: value });
  }, 500);

  return (
    <Space>
      <Visibility
        visible={!["ENTREPRENEUR"]
          .includes(currentUser.systemRole)}>
        <Button
          type="primary"
          onClick={() => openModal({ batches: [{ id: batchId }] },
            "COORDINATOR")}>
          Invitar coordinadores
        </Button>
      </Visibility>
      <SearchToolbar
        onSearchChange={e => handleOnSearchChange(e.target.value)} />
    </Space>
  );
}
