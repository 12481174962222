/* eslint-disable max-lines */
import React, { useContext, useEffect, useState } from "react";
import { List } from "antd";
import { default as OdeItem } from "../list/item";
import {
  BatchOdesContext,
  BatchDetailsContext
} from "../../../../../../contexts/admin/batches";
import { paginationConfig } from "../../../../../../helpers";
import {
  BatchSectionHeading,
  EmptyView,
  OdeFormAssign,
  Section,
  Loader
} from "../../../../../shared";
import { useService } from "../../../../../../hooks/shared";
import { OdesService } from "../../../../../../services";
import {
  useOdeServices
} from "../../../../../../hooks/shared";

export function BatchOdesNewList({ onClick, showEmpty, setShowEmpty }) {
  const [sortOdes, setSortOdes] = useState([]);
  const { data: { batch } = {}, refetch: refetchBatch } = useContext(
    BatchDetailsContext
  );
  const { data, loading, error, refetch: refetchOdes } = useContext(
    BatchOdesContext
  );

  const refetch = async () => {
    await refetchBatch();
    await refetchOdes();
  };

  /**
   * Sorts the Companies list regard the sortedOdes IDs array.
   * If companies are not in the array, will be shown at the bottom.
   * @param {Company[]} companies
   * @param {string[]} sortedOdes
   * @returns {Company[]}
   */
  const sortCompanies = (companies = [], sortedOdes = []) => {
    const sortedCompaniesIds = {};

    const companiesWithSortingConf = sortedOdes
      .reduce((sortedCompanies = [], companyId) => {
        const company = companies.find(c => c.id === companyId);
        // this list will help us to check if we already
        // included the company in the sorting array
        sortedCompaniesIds[companyId] = true;

        if (company) sortedCompanies.push(company);

        return sortedCompanies;
      }, []);

    const unsortedCompanies = companies
      .reduce((finalSortedCompanies = [], company) => {
        if (sortedCompaniesIds[company.id]) return finalSortedCompanies;

        finalSortedCompanies.push(company);
        return finalSortedCompanies;
      }, []);

    return companiesWithSortingConf.concat(unsortedCompanies);
  };

  useEffect(() => {
    if (data && batch) {
      const sortedCompanies = sortCompanies(data.oDEs, batch.odesSorted);
      setSortOdes(sortedCompanies);
    }
  }, [data, batch]);

  const {
    disconnectOdeFromBatch,
    connectOdesFromBatch,
    createPublishedContent
  } = useOdeServices();

  const { data: masterContentsData } = useService(
    OdesService.getMasterContentByBatchIdService,
    { batchId: batch?.id },
    { shouldFetch: batch?.id }
  );

  const onDisconnectOdeFromBatch = async companyId => {
    const odesSorted = [...batch?.odesSorted || []];
    const foundIndex = odesSorted.indexOf(companyId);

    if (foundIndex !== -1) {
      odesSorted.splice(foundIndex, 1);
    }

    return disconnectOdeFromBatch({
      batchId: batch?.id,
      companyId,
      odesSorted,
      refetch
    });
  };

  const onConnectOdeFromBatch = async values => {
    const odesConnected =
      batch?.odesSorted ? batch?.odesSorted : data?.oDEs?.map(el => el.id);
    const odesData = values?.odes?.reduce((prev, current) => {
      prev.odesSorted.push(current);
      prev.odesConnect.push({ id: current });
      return prev;
    }, { odesSorted: [], odesConnect: [] });

    values = {
      odes: { connect: odesData?.odesConnect },
      odesSorted: {
        set: odesData?.odesSorted?.concat(odesConnected || [])
      }
    };

    const masterContentId =
      masterContentsData.masterContents?.[0]?.id || undefined;
    const hasPublished =
      masterContentsData.masterContents?.[0]?.publishedContents || [];

    if(hasPublished.length > 0) {
      await Promise.all(
        values.odes.connect.map(ode => createPublishedContent({
          companyId: ode.id,
          masterContentId
        }))
      );
    }

    return connectOdesFromBatch({
      batchId: batch?.id,
      odesConnect: values,
      odes: odesData?.odesConnect,
      refetch
    });
  };

  const renderEmptyView = () => (
    <EmptyView
      style={{ width: "auto" }}
      isEmpty={true}
      isLoading={false}
      buttonTitle="Comenzar"
      onClick={() => setShowEmpty(true)}
      description={
        <BatchSectionHeading
          title="¡Oops, aún no tienes compañías!"
          subTitle="Agrégalas para asignarles expertos y contenidos." />
      } />
  );

  const renderListCompanies = () => {
    return (
      <>
        <OdeFormAssign
          excludedOdes={sortOdes}
          connectOdeFromBatch={onConnectOdeFromBatch} />
        <List
          renderItem={ode =>
            <OdeItem
              odesSorted={batch?.odesSorted}
              onClick={onClick}
              onDelete={onDisconnectOdeFromBatch}
              ode={ode}
              batchId={batch?.id} /> }
          itemLayout="vertical"
          pagination={paginationConfig}
          dataSource={sortOdes}
          loading={loading} />
      </>
    );
  };

  return (
    <Section spaced className="OdeNewList">
      <Loader
        data={data}
        loading={loading}
        error={error}>
        <BatchSectionHeading
          style={{ textAlign: "center" }}
          title="Compañías"
          subTitle="Gestiona las compañías y ve sus avances" />
        {!showEmpty && !sortOdes.length
          ? renderEmptyView()
          : renderListCompanies()
        }
      </Loader>
    </Section>
  );
}
