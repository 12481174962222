import React, { useContext, useEffect, useMemo } from "react";
import { Tabs } from "antd";
import { useLocation } from "react-router";
import {
  TabContentWrapper
} from "../../../../../admin/batches/publish/tab-content-wrapper";
import {
  BatchPublicInformation,
  ExpertsSection,
  BatchContent
} from "../sections";
import { Perks } from "../sections/perks";
import useTreatment from "../../../../../../hooks/feature-flags/use-treatment";
import { useService } from "../../../../../../hooks/shared";
import { BatchOdesContext } from "../../../../../../contexts/admin/batches";
import {
  CurrentUserContext,
  ServicesLayerContext
} from "../../../../../../contexts/shared";

export function EntrepreneurTabPanes ({
  batch,
  setActiveTab,
  defaultActiveTab,
  tabsProps
}) {
  const { currentUser } = useContext(CurrentUserContext);
  const { CompanyService, OdesService } = useContext(ServicesLayerContext);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const announcementId = params.get("announcement");
  const companyId = params.get("companyId");

  const {
    data: companies,
    loading: loadingCompanies,
    error: errorCompanies
  } = useService(
    CompanyService.getEntrepreneurCompaniesInBatch,
    { batchId: batch?.id, entrepreneurId: currentUser?.id },
    { shouldFetch: !!batch && !!currentUser?.id }
  );

  const {
    data: publishContents,
    loading: loadingPublishContents,
    error: errorPublishContents,
    refetch: refetchPublishContents
  } = useService(
    OdesService.getPublishedContentsService,
    { batchId: batch?.id, companyId: companies?.[0]?.id },
    { shouldFetch: !!batch && !!companies }
  );

  const {
    data: customContents,
    loading: loadingCustomContents,
    error: errorCustomContents,
    refetch: refetchCustomContents
  } = useService(
    OdesService.getCustomOdeContentsService,
    { batchId: batch?.id, companyId: companies?.[0]?.id },
    { shouldFetch: !!batch && !!companies }
  );

  const fullLoading =
    loadingCompanies || loadingPublishContents || loadingCustomContents;

  const fullError =
    errorCompanies || errorPublishContents || errorCustomContents;

  useEffect(() => {
    const keyName = defaultActiveTab || "information";
    if (announcementId) {
      setActiveTab(
        `./offer?section=announcements&announcement=${announcementId}`,
        "announcements"
      );
    } else if (companyId) {
      setActiveTab(
        `./offer?section=companies&companyId=${companyId}`,
        "companies"
      );
    } else {
      setActiveTab(`./offer?section=${keyName}`, keyName);
    }
  }, []);

  const disableExpertsTabTreatment =
    useTreatment("Disable_Experts_Tab");

  const announcementsTabTreatmentValue =
    useTreatment("AnnouncementsTab");

  const refetchContents = async () => {
    await refetchPublishContents();
    await refetchCustomContents();
  };

  const injectActions = useMemo(() => ({
    data: customContents,
    published: publishContents,
    error: fullError,
    loading: fullLoading,
    batchId: batch?.id,
    companyId: companies?.[0]?.id,
    refetch: refetchContents,
    isDragable: false
  }), [fullLoading]);

  return (
    <BatchOdesContext.Provider value={injectActions}>
      <Tabs {...tabsProps}>
        <Tabs.TabPane tab="Información General" key="information">
          <TabContentWrapper>
            <BatchPublicInformation batch={batch} />
          </TabContentWrapper>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Contenido" key="content">
          <TabContentWrapper>
            <BatchContent />
          </TabContentWrapper>
        </Tabs.TabPane>
        {
          disableExpertsTabTreatment === "OFF"
            ? <Tabs.TabPane tab="Expertos" key="expert">
              <TabContentWrapper>
                <ExpertsSection batch={batch} />
              </TabContentWrapper>
            </Tabs.TabPane>
            : null
        }
        <Tabs.TabPane tab="Compañías" key="companies">
          <TabContentWrapper>
          </TabContentWrapper>
        </Tabs.TabPane>
        {
          announcementsTabTreatmentValue === "ON"
            ? <Tabs.TabPane tab="Avisos" key="announcements">
              <TabContentWrapper>
              </TabContentWrapper>
            </Tabs.TabPane>
            : null
        }
        <Tabs.TabPane tab="Calendario" key="calendar">
          <TabContentWrapper>
          </TabContentWrapper>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Perks" key="perks">
          <TabContentWrapper>
            <Perks batchId={batch?.id} />
          </TabContentWrapper>
        </Tabs.TabPane>
      </Tabs>
    </BatchOdesContext.Provider>
  );
}
