import React from "react";
import { Avatar, List, Modal } from "antd";

export const FoundationYearChartModal = ({
  visible,
  selectedItem,
  ...props
}) => {

  const { selectedYear, organizations } = selectedItem || {};

  return (
    <>
      <Modal
        {...props}
        title={`Organizaciones creadas en ${selectedYear}`}
        closable
        footer={null}
        visible={visible}>
        <List
          itemLayout="horizontal"
          dataSource={organizations || []}
          renderItem={organization => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={organization.logo}
                    alt={`Logo de ${organization.name}`} />
                }
                title={organization.name} />
            </List.Item>
          )} />
      </Modal>
    </>
  );
};
