import React from "react";
import { Col, Row } from "antd";

const COL_RESPONSIVE_SIZES = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 6,
  xl: 6,
  xxl: 4
};

export const GridContainer = React.memo(({
  children,
  responsiveValues = null,
  gutter = null,
  customColWidth = null,
  style = {},
  keyPrefix = ""
}) => {
  const colProps = customColWidth
    ? { style: { width: customColWidth }}
    : (responsiveValues || COL_RESPONSIVE_SIZES);

  return (
    <Row gutter={gutter || [16]} style={style}>
      {React.Children.map(children, (child, idx) => (
        <Col
          key={`${keyPrefix}-column-${idx.toString()}`}
          {...colProps}>
          {child}
        </Col>
      ))}
    </Row>
  );
});
