import React from "react";
import { Alert } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export function LoadingWrapper({ loading, children }) {
  if (loading) {
    return (
      <Alert
        showIcon
        type="success"
        icon={<LoadingOutlined spin />}
        message="Cargando compañías..." />
    );
  }

  return children;
}
