import React, { useContext, useState } from "react";
import { Row, Col, Typography, Space, Dropdown } from "antd";
import { Avatar } from "../avatar";
import { UserSwitchOutlined } from "@ant-design/icons"
import { WorkspacesList } from "./workspaces-list";
import { CurrentUserContext } from "../../../contexts/shared/current-user/context";
import { getReadableValue, systemRoleTypes } from "../../../helpers/select-options";

export function WorkspacesMenu() {
  const {
    currentWorkspace,
    currentUser,
    setWorkspace
  } = useContext(CurrentUserContext);

  const [state, setState] = useState({
    isPopoverVisible: false
  });

  const textStyle = {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "100%",
    display: "inline-block",
    overflow: "hidden"
  };

  if(!currentWorkspace?.id) {
    return null;
  }

  const onSelectWorkspace = id => {
    setWorkspace(id);
    setState({
      ...state,
      isPopoverVisible: false
    });
  };

  const handleVisibleChange = visible => {
    setState({
      ...state,
      isPopoverVisible: visible
    });
  };

  return (
    <Dropdown
      trigger="click"
      overlay={<WorkspacesList onSelectWorkspace={onSelectWorkspace} />}
      overlayClassName="ant-popover-inner"
      overlayStyle={{padding: "10px"}}
      visible={state.isPopoverVisible}
      onVisibleChange={handleVisibleChange}>
      <Row
        wrap={false}
        align="middle"
        gutter={[10, 0]}
        style={{margin: "5px 10px", cursor: "pointer"}}>
        <Col flex="20px">
          <Avatar>{ currentWorkspace.coverPicUrl || currentWorkspace?.name }</Avatar>
        </Col>
        <Col flex="auto">
          <Typography.Text
            style={textStyle}>
            { currentWorkspace.name }
          </Typography.Text>
          <br />
          <Typography.Text
            style={textStyle}
            type="secondary">
            { getReadableValue(systemRoleTypes, currentUser?.systemRole, "N/A") }
          </Typography.Text>
        </Col>
        <Col flex="20px">
          <UserSwitchOutlined />
        </Col>
      </Row>

    </Dropdown>
  );
}
