import { Space, Typography, message, Form } from "antd";
import React, { useContext } from "react";
import { Avatar } from "../avatar";
import { FlagOutlined } from "@ant-design/icons";
import { EditableLabel } from "../editable-label";
import { EditableField } from "./editable-field";
import { useMutation } from "@apollo/react-hooks";
import { admin } from "../../../graphql/admin";
import { client } from "../../../../graphql";
import { BatchDetailsContext } from "../../../contexts/admin/batches/batch-details";
import { CurrentUserContext } from "../../../contexts/shared";
import { shared } from "../../../graphql/shared";

export function PanelHeading({ objective }) {
  const { data: batchData } = useContext(BatchDetailsContext);
  const { currentUser } = useContext(CurrentUserContext);

  const [form] = Form.useForm();

  const [createObjective] = useMutation(
    admin.mutations.createBatchObjective,
    {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: shared.queries.getBatchObjectives,
          variables: {
            batchId: batchData?.batch?.id
          }
        }
      ]
    }
  );

  const [updateObjective] = useMutation(
    admin.mutations.updateObjective,
    {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: shared.queries.getBatchObjectives,
          variables: {
            batchId: batchData?.batch?.id
          }
        }
      ]
    }
  );

  const stopPropagation = e => {
    e.stopPropagation();
  };

  const onSaveEditable = async data => {
    await form.validateFields();
    const dismissLoader = message.loading("Guardando...");

    if(objective.new) {
      data.batch = {
        connect: { id: batchData?.batch?.id }
      };

      try {
        await createObjective({
          variables: { data }
        });
        message.success("Nuevo objetivo agregado.");
      } catch (error) {
        console.error(error);
        message.error("Ha ocurrido un error, inténtalo de nuevo\
          en unos segundos.");
      }
    }
    else {
      try {
        await updateObjective({
          variables: {
            where: { id: objective.id },
            data
          }
        });
        message.success("Cambios guardados.");
      } catch (error) {
        console.error(error);
        message.error("Ha ocurrido un error, inténtalo de nuevo\
          en unos segundos.");
      }
    }

    dismissLoader();
  };

  return (
    <Space
      className="objective-item-header"
      direction="horizontal"
      onClick={stopPropagation}>
      <Avatar color="#13C2C2">
        <FlagOutlined
          style={{color: "#FFFFFF", fontSize: "20px", marginTop: "6px"}} />
      </Avatar>
      <EditableLabel
        onSave={onSaveEditable}
        editing={objective.new}
        editable={["SUPER_ADMIN", "COORDINATOR"].includes(currentUser.systemRole)}
        editableField={onChange =>
          <EditableField
            form={form}
            objective={objective}
            onChange={onChange} />
        }>
        <Space direction="vertical" size={0}>
          <Typography.Text strong>{objective.name}</Typography.Text>
          <Typography.Text>{objective.description}</Typography.Text>
        </Space>
      </EditableLabel>
    </Space>
  );
}
