import React from "react";
import {
  CompanyMilestonesModalProvider
} from "../../../../../../contexts/shared/company-milestones-modal";
import { AchievementsMilestones } from "./achievements";
import { CompetencesMilestones } from "./competences";
import { FundsMilestones } from "./funds";
import { NewsMilestones } from "./news";
import { FlexContainer } from "../../../../flex-container";
import { Typography } from "antd";

export const Milestones = ({ data, refetch, allowEdit }) => {

  const getFilteredMilestones = type => {
    return data.filter(milestone => milestone.milestoneType === type);
  };

  const fundMilestonesArray = getFilteredMilestones("FUND");
  const competencesMilestonesArray = getFilteredMilestones("COMPETENCE");
  const achievementsMilestonesArray = getFilteredMilestones("ACHIEVEMENT");
  const newsMilestonesArray = getFilteredMilestones("NEWS");

  return (
    <CompanyMilestonesModalProvider refetch={refetch}>
      <div style={{ marginTop: "20px" }}>
        <FlexContainer
          style={{
            gap: "10px"
          }}
          direction="vertical">
          <Typography.Title level={5}>
            Hitos
          </Typography.Title>
          <div style={{width: "100%"}}>
            <FundsMilestones allowEdit={allowEdit} data={fundMilestonesArray} />
          </div>
          <div style={{width: "100%"}}>
            <CompetencesMilestones allowEdit={allowEdit} data={competencesMilestonesArray} />
          </div>
          <div style={{width: "100%"}}>
            <AchievementsMilestones allowEdit={allowEdit} data={achievementsMilestonesArray} />
          </div>
          <div style={{width: "100%"}}>
            <NewsMilestones allowEdit={allowEdit} data={newsMilestonesArray} />
          </div>
        </FlexContainer>
      </div>
    </CompanyMilestonesModalProvider>
  );
};
