import React, { useContext } from "react";
import { Alert, List, message, Empty } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { shared } from "../../../graphql/shared";
import { CurrentUserContext } from "../../../contexts/shared";
import { client } from "../../../../graphql";
import { NotificationIcon } from "./notification-icon";
import { NotificationsContext } from "../../../contexts/shared/notifications/context";
import * as moment from "moment";

export function NotificationsList() {
  const { currentUser } = useContext(CurrentUserContext);
  const {
    subscribe,
    hasEnabledNotifications
  } = useContext(NotificationsContext);

  const { data, error, loading } = useQuery(shared.queries.getNotificationsByUserId, {
    variables: {
      id: currentUser.id
    },
    client
  });

  if(error) {
    return (
      <Alert
        style={{width: "250px"}}
        message="Ha ocurrido un error al cargar tus
        notificationes, por favor inténtalo más tarde."
        type="error" />
    );
  }

  if(!data?.notifications?.length) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="No tienes notificaciones" />
    );
  }

  return (
    <List
      className="notifications-list"
      itemLayout="horizontal"
      loading={loading}
      dataSource={data?.notifications}
      renderItem={({data: notification, id}) => (
        <List.Item
          key={id}
          style={{cursor: "pointer"}}
          onClick={() => typeof notification.onClick === "function" && notification.onClick()}>
          <List.Item.Meta
            avatar={<NotificationIcon type={notification?.type} />}
            title={notification?.body}
            description={notification?.createdAt} />
        </List.Item>
      )} />
  );
}
