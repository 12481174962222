import React, { useState } from "react";
import { Button, Form, Input, Tooltip } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { UploadButton } from "../../../../components/shared";
import "./styles.sass";
import {
  convertFromFileList
} from "../../../../helpers/convert-from-file-list";

export function CommentField ({ sendComment, sendDeliverables }) {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [form] = Form.useForm();

  const { TextArea } = Input;

  const onSendComment = async () => {
    await form.validateFields();
    setLoading(true);
    const values = form.getFieldsValue();
    await sendComment(values.comment);
    form.resetFields();
    setLoading(false);
  };

  const onSendDeliverables = async () => {
    await form.validateFields();
    setLoading(true);
    const values = form.getFieldsValue();
    const formatedFiles = convertFromFileList(files);
    await sendDeliverables(formatedFiles, values.comment);
    form.resetFields();
    setFiles([]);
    setLoading(false);
  };

  const onFileChange = e => {
    setFiles(e.fileList);
  };

  const onRemoveFile = e => {
    setFiles(files.filter(file => file.uid !== e.uid));
  };

  const handleButtonClick = async () => {
    if(files.length > 0) {
      await onSendDeliverables();
    } else {
      await onSendComment();
    }
  };

  return (
    <div>
      <Form form={form}>
        <Form.Item
          name="comment"
          rules={[{
            required: true, message: "Favor de ingresar su comentario"
          }]}
          style={{ marginBottom: "15px" }}>
          <TextArea
            row={4}
            placeholder="Escribe los comentarios necesarios aquí para llevar
            un mejor seguimiento de la entrega" />
        </Form.Item>
      </Form>
      <div
        style={{
          display: "flex"
        }}>
        <UploadButton
          className="deliverables-upload-button"
          listType="custom"
          value={files}
          maxFiles={1}
          onChange={onFileChange}
          onRemove={onRemoveFile}>
          <Tooltip
            arrowPointAtCenter
            title="Solo se puede subir un archivo a la vez">
            <Button
              type="text"
              shape="circle"
              icon={<LinkOutlined />}
              size="middle" />
          </Tooltip>
        </UploadButton>
        <Button
          style={{ marginLeft: "10px" }}
          onClick={handleButtonClick}
          loading={loading}>
          {files.length > 0 ? "Enviar entregables" : "Enviar comentarios"}
        </Button>
      </div>
    </div>
  );
}
