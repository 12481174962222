import React from "react";
import moment from "moment";
import { Space, Tag, Typography, Avatar } from "antd";
import { IconLabel, Visibility } from "../../../../../../shared";
import { MailOutlined, PhoneOutlined, CalendarOutlined } from "@ant-design/icons";
import {
  getReadableValue,
  expertServiceCategories
} from "../../../../../../../helpers/select-options";

export function ScheduledProductHeader({
  scheduledExpertService
}) {
  const { assignedExpertService = {} } = scheduledExpertService || {};
  const { expertServiceData = {}, limitDate } = assignedExpertService;
  const { expert = {} } = expertServiceData || {};

  const formatDate = date => date
    ? moment(date).format("DD/MMMM/YYYY")
    : "";

  return (
    <div style={{ fontSize: "14px" }}>
      <Space
        size={20}
        align="start"
        direction="horizontal">
        <Avatar
          size={60}
          icon={<CalendarOutlined style={{ color: "#c4c4c4" }} />}
          style={{
            backgroundColor: "rgba(196,196,196,0.2)",
            border: "solid 2px #c4c4c4"
          }} />
        <Space direction="vertical" size={5}>
          <Typography.Title
            level={4}
            style={{marginBottom: "0px"}}>
            {expertServiceData?.name}
          </Typography.Title>
          <Typography.Text
            strong
            style={{marginBottom: "0px"}}>
            {`Experto: ${expert?.fullName}`}
          </Typography.Text>
          <Space
            style={{color: "#8C8C8C"}}
            direction="horizontal">
            <Visibility visible={expert?.phone}>
              <IconLabel icon={<PhoneOutlined />}>
                {expert?.phone}
              </IconLabel>
            </Visibility>
            <Visibility visible={expert?.email}>
              <IconLabel icon={<MailOutlined />}>
                {expert?.email}
              </IconLabel>
            </Visibility>
          </Space>
          <span>
            <Tag color="purple">
              Servicio
            </Tag>
            <Tag color="blue">
              {getReadableValue(
                expertServiceCategories,
                expertServiceData.category
              )}
            </Tag>
          </span>
          <Typography.Text type="secondary">
            Asignado el {formatDate(assignedExpertService.assignmentDate)}
          </Typography.Text>
          <Typography.Title level={5} type="warning">
            Fecha límite de consumo {formatDate(limitDate)}
          </Typography.Title>
        </Space>
      </Space>
    </div>
  );
}
