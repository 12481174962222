import { client } from "../../../../graphql";
import { shared } from "../../../graphql/shared";

/**
 * @deprecated
 */
export class ExpertServiceService {
  constructor() {
    this.client = client;
  }

  async create(data, expertId) {
    if (expertId) {
      data.expert = { connect: { id: expertId } };
    }

    return await client.mutate({
      mutation: shared.mutations.createExpertService,
      variables: { data },
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: shared.queries.getServicesByExpertId,
        variables: { expertId }
      }]
    });
  }

  async update({ id, ...data}) {
    return await client.mutate({
      mutation: shared.mutations.updateExpertService,
      variables: { data, id },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: shared.queries.getServices }]
    });
  }

  async createOrUpdate(data, expertId) {
    try {
      if (data.id) {
        return await this.update(data);
      }
      else {
        return await this.create(data, expertId);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
