import React from "react";
import { Section } from "../../../../shared";
import { withLoader } from "../../../../../helpers";
import { ExpertServicesList } from "../../services/list";

function Services({ data }) {
  return (
    <Section spaced title="Todos los artículos">
      <ExpertServicesList
        data={data}
        isSingleExpert
        isAddEnabled />
    </Section>
  );
}

export default withLoader(Services);
