export const organizationStagesOptions = [
  {
    label: "Sin idea",
    value: "Sin idea"
  },
  {
    label: "Idea. Estoy desarrollando una idea de negocio",
    value: "Idea"
  },
  {
    label: "Prototipo. Tengo un prototipo de producto, servicio.",
    value: "Prototipo"
  },
  {
    label: "En operación, ya vendo productos/servicios.",
    value: "En operación"
  },
  {
    label: "En crecimiento, a través de un incremento de ventas o a través del portafolio de producto/servicio",
    value: "En crecimiento"
  },
  {
    label: "En consolidación, profesionalización y formalización de la empresa",
    value: "En consolidación"
  }
]
