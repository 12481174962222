import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { message, PageHeader, Tabs, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { withRouter } from "../../../../../../helpers";
import { client } from "../../../../../../../graphql";
import {
  BatchOdesContext,
  BatchDetailsContext,
  BatchDetailsContextProvider
} from "../../../../../../contexts/admin/batches";
import {
  MenuContext,
  ContentViewSwitchProvider,
  ContentModalContextProvider,
  ContentGroupModalContextProvider,
  ContentDetailsModalContextProvider, DraggableListProvider, CurrentUserContext
} from "../../../../../../contexts/shared";
import {
  ContentWrapper, HeroPublishBatch, Section,
  SidebarLayout
} from "../../../../../../components/shared";
import {
  BatchOdesContents
} from "../../../../../../components/admin/batches/show/odes";
import { shared } from "../../../../../../graphql/shared";
import { MainMenu } from "../../../../../../components/admin/shared";
import { HeaderBreadcrumbs } from "../../../../../../components/shared/batch-offer/header-breadcrumbs";

export function BatchODEContent({ history }) {
  const { id = "", odeId = "" } = useParams();
  const { currentUser } = useContext(CurrentUserContext);

  const { data: published, refetch: refetchPublished } = useQuery(
    shared.queries.getPublishedContents,
    {
      fetchPolicy: "network-only",
      variables: {
        where: { ode: { id: odeId }, masterContent: { batch: { id } } },
        odeId
      },
      client
    }
  );

  const { data, loading, error, refetch: refetchCustom } = useQuery(
    shared.queries.getCustomOdeContents,
    {
      fetchPolicy: "network-only",
      variables: { where: { ode: { id: odeId }, batch: { id } }, odeId },
      client
    }
  );

  const { data: { oDE } = {} } = useQuery(shared.queries.getOdeNameById, {
    client,
    variables: {
      id: odeId
    }
  });

  const [createCustomOdeContent] = useMutation(
    shared.mutations.createCustomOdeContent, { client }
  );

  const [updateCustomOdeContent] = useMutation(
    shared.mutations.updateCustomOdeContent, { client }
  );

  const [updateContentGroup] = useMutation(
    shared.mutations.updateContentGroup, { client }
  );

  const refetch = async () => {
    await refetchCustom();
    await refetchPublished();
  };

  const onUpdateCustomOdeContent = useCallback(async create => {
    if (data?.customOdeContents?.[0]) {
      await updateCustomOdeContent({
        variables: {
          where: { id: data?.customOdeContents?.[0]?.id },
          data: { contentGroups: { create } }
        }
      });
    } else {
      await createCustomOdeContent({
        variables: {
          data: {
            contentGroups: { create },
            batch: { connect: { id } },
            ode: { connect: { id: odeId } }
          }
        }
      });
    }

    await refetch();
  }, [data]);

  const onUpdateContentGroup = useCallback(async (groupId, values) => {
    await updateContentGroup({
      variables: { where: { id: groupId }, data: values }
    });
    await refetch();
  }, [data]);

  const onUpdateContentSorted = useCallback(async (uuids, groupId) => {
    if (!groupId) {
      await updateCustomOdeContent({
        variables: {
          where: { id: data?.customOdeContents?.[0]?.id },
          data: { contentGroupsSort: { set: uuids } }
        }
      });
    } else {
      await updateContentGroup({
        variables: {
          where: { id: groupId },
          data: { contentsSort: { set: uuids } }
        }
      });
    }
    await refetch();
    message.success("Ordenamiento guardado.");
  }, [data]);

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });

    setTimeout(() => {
      const focus = document.getElementById("focus");
      focus.scrollIntoView();
    }, 0);
  }, []);

  const injectActions = useMemo(() => ({
    data,
    error,
    loading,
    refetch,
    published,
    batchId: id,
    isDragable: false
  }), [data, loading, published]);

  const ROUTES = {
    SUPER_ADMIN: "/v2/admin/batches",
    COORDINATOR: "/v2/coordinator/batches",
    ADVISER: "/v2/expert/batches",
    ENTREPRENEUR: "/v2/entrepreneur/batches"
  };

  return (
    <BatchDetailsContextProvider>
      <BatchDetailsContext.Consumer>
        {({ data }) => {
          return (
            <BatchOdesContext.Provider value={injectActions}>
              <SidebarLayout menu={<MainMenu />}>
                <HeaderBreadcrumbs
                  onPrev={() => history.push(`${ROUTES[currentUser.systemRole]}`)}
                  title="Batches"
                  name={data?.batch?.name} />
                <Section className="NewBatch__Publish">
                  <HeroPublishBatch
                    className="NewBatch__Publish-Hero"
                    batch={data?.batch} />
                  <div id="focus" />
                  <Tabs
                    onTabClick={tabKeyName =>
                      history.push(`../../${tabKeyName}`)}
                    className="NewBatch__Tabs"
                    defaultActiveKey="companies"
                    activeKey="companies">
                    <Tabs.TabPane tab="Información General" key="information" />
                    <Tabs.TabPane tab="Contenido" key="content" />
                    <Tabs.TabPane tab="Expertos" key="expert" />
                    <Tabs.TabPane tab="Compañías" key="companies" />
                    <Tabs.TabPane tab="Avisos" key="announcement" />
                    <Tabs.TabPane tab="Calendario" key="calendar" />
                    <Tabs.TabPane tab="Analytics" key="analytics" />
                  </Tabs>
                </Section>
                <DraggableListProvider onUpdate={onUpdateContentSorted}>
                  <ContentWrapper>
                    <PageHeader
                      style={{ padding: "0 24px" }}
                      backIcon={<ArrowLeftOutlined />}
                      subTitle={
                        <Typography.Text>
                          Compañías / <strong>{oDE?.name}</strong>
                        </Typography.Text>
                      }
                      onBack={() => history.goBack()} />
                    <ContentGroupModalContextProvider
                      onSave={onUpdateCustomOdeContent}
                      onUpdate={onUpdateContentGroup}>
                      <ContentModalContextProvider batchId={id} refetch={refetch}>
                        <ContentDetailsModalContextProvider
                          batchId={id}
                          odeId={odeId}
                          refetch={refetch}>
                          <ContentViewSwitchProvider>
                            <BatchOdesContents />
                          </ContentViewSwitchProvider>
                        </ContentDetailsModalContextProvider>
                      </ContentModalContextProvider>
                    </ContentGroupModalContextProvider>
                  </ContentWrapper>
                </DraggableListProvider>
              </SidebarLayout>
            </BatchOdesContext.Provider>
          );
        }}
      </BatchDetailsContext.Consumer>
    </BatchDetailsContextProvider>
  );
}

export default withRouter(BatchODEContent);
