import { Modal, Space, Typography, message } from "antd";
import React from "react";
import { BatchesService } from "../../../services";

export const useBatchCompanyActions = ({
  batchId,
  odesSorted = []
}) => {
  const removeCompany = async (
    { companyId, odeStatusFromBatchIds, afterSuccess }
  ) => {
    try {
      const newOdesSorted = odesSorted || [];
      const foundIndex = newOdesSorted.indexOf(companyId);

      if (foundIndex !== -1) {
        newOdesSorted.splice(foundIndex, 1);
      }

      const result = await BatchesService.removeCompany(
        { companyId, batchId, odeStatusFromBatchIds, odesSorted: newOdesSorted }
      );

      if (result) {
        message.success("Compañía removida.");
        return  afterSuccess && await afterSuccess();
      }

      return message.warning("No es posible remover compañía con contenidos.");
    } catch (err) {
      console.error(err);
      message.error("Hubo un error intenta mas tarde.");
    }
  };

  const toggleStatusCompany =  async ({
    companyId,
    odeStatusBatch,
    status,
    afterSuccess
  }) => {
    try {
      await BatchesService.toggleStatusCompany({
        companyId,
        odeStatusBatch,
        batchId,
        status
      });
      status === "ACTIVE"
        ? message.success("Compañía activada.")
        : message.success("Compañía desactivada.");

      afterSuccess && await afterSuccess();
    } catch (err) {
      console.error(err);
      message.error("Hubo un error intenta mas tarde.");
    }
  };

  const onRemoveCompany = (name, id, odeStatusFromBatchIds, afterSuccess) => {
    return Modal.confirm({
      className: "ConfirmModal",
      width: 600,
      centered: true,
      closable: true,
      okText: "Si, remover compañía",
      cancelText: "No, regresar",
      title: "Remover compañía del batch",
      icon: null,
      content: <Space direction="vertical">
        <Typography.Text>
          Vas a remover a la compañía
          <strong>{name}</strong> del batch, esta acción no se podrá deshacer.
        </Typography.Text>
        <Typography.Text strong>
          ¿Estás seguro de continuar?
        </Typography.Text>
      </Space>,
      cancelButtonProps: { type: "primary", ghost: true },
      onOk: () => removeCompany({
        companyId: id,
        odeStatusFromBatchIds,
        afterSuccess
      })
    });
  };

  const onToggleStatusCompany = (
    name,
    companyId,
    odeStatusBatch,
    isActive,
    afterSuccess
  ) => {
    const toggleStatus = isActive ? "INACTIVE" : "ACTIVE";
    const content = isActive
      ? (
        <Typography.Text>
          Vas a desactivar a la compañía <strong>{name}</strong> del batch,
          al hacerlo dejará de tener acceso a entrega de contenido,
          consumo de expertos y sus asignaciones serán canceladas.
        </Typography.Text>
        )
      : (
        <Typography.Text>
          Vas a activar a la compañía <strong>{name}</strong> del batch,
          al hacerlo volverás a tener acceso a su contenido.
        </Typography.Text>
      );

    return Modal.confirm({
      className: "ConfirmModal",
      width: 600,
      centered: true,
      closable: true,
      okText: isActive ? "Si, desactivar" : "Si, volver activar",
      cancelText: "No, regresar",
      title: isActive
        ? "Desactivar compañía del batch"
        : "Activar compañía del batch",
      icon: null,
      content: <Space direction="vertical">
        {content}
        <Typography.Text strong>
          ¿Estás seguro de continuar?
        </Typography.Text>
      </Space>,
      cancelButtonProps: { type: "primary", ghost: true },
      onOk: () => toggleStatusCompany({
        companyId,
        odeStatusBatch,
        status: toggleStatus,
        afterSuccess
      })
    });
  };

  return {
    onRemoveCompany,
    onToggleStatusCompany
  };
};