import React, { useEffect, useContext } from "react";
import {
  BatchCalendar, SidebarLayout
} from "../../../../../components/shared";
import { Summary } from "../../../../../components/admin/batches/show";
import {
  default as Navbar
} from "../../../../../components/entrepreneur/batches/show/navbar";
import { useParams } from "react-router";
import {
  BatchDetailsContextProvider
} from "../../../../../contexts/admin/batches/batch-details";
import {
  MenuContext,
  BatchEventModalProvider
} from "../../../../../contexts/shared";
import { MainMenu } from "../../../../../components/entrepreneur/shared";
import { useService } from "../../../../../hooks/shared";
import { BatchesService } from "../../../../../services";

export function CalendarIndex() {
  const { id: batchId } = useParams();

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const today = new Date();

  const { data: events, loading, error, refetch } = useService(
    BatchesService.getCalendarEvents,
    {
      batchId,
      month: today.getMonth(),
      year: today.getFullYear()
    },
    { shouldFetch: batchId }
  );

  const onDatesChange = ({ month, year }) => {
    refetch({ batchId, month, year });
  };

  return (
    <BatchDetailsContextProvider>
      <SidebarLayout menu={<MainMenu />}>
        <Summary />
        <Navbar selectedKeys={["calendar"]} />
        <BatchEventModalProvider batchId={batchId}>
          <BatchCalendar
            error={error}
            loading={loading}
            refetch={refetch}
            events={events ?? []}
            onDatesChange={onDatesChange} />
        </BatchEventModalProvider>
      </SidebarLayout>
    </BatchDetailsContextProvider>
  );
}
