import React, { useState, useCallback } from "react";
import { SearchBar } from "./search-bar";
import { CAMPUS_LIST } from "../../../campus-list";
import {
  EntrepreneurSpiritService
} from "../../../../../services/entrepreneur-spirit";
import { Col, Row, Select, Form, DatePicker } from "antd";

export const ResumeByPersonFilterControls = ({
  windowSize,
  onFilterAttendances
}) => {
  const [persons, setPersons] = useState([]); // available persons to select
  const [selectedPerson, setSelectedPerson] = useState(null); // selected person
  const [lockPeriod, setLockPeriod] = useState(true); // lock period
  const [disableCampusAndName, setDisableCampusAndName] = useState(false);

  const [form] = Form.useForm();

  const formSizes = {
    "EXTRA_EXTRA_LARGE": "large"
  };

  const loadPersons = async ({ campus }) => {
    try {
      const matchedPersons = await EntrepreneurSpiritService
        .getPersons({ campus });
      const sortedPersons = matchedPersons
        .sort((a, b) => a.fullName.localeCompare(b.fullName));
      setPersons(sortedPersons);
    } catch (e) {
      console.error(e);
    }
  };

  const onCampusChange = campus => {
    setPersons([]);
    form.setFieldsValue({ person: undefined });
    setSelectedPerson(null);
    loadPersons({ campus }).then(); // filter the persons
    return campus; // needed by the Form.Item
  };

  const getSelectedPerson = useCallback(personId => {
    return persons?.find(person => person?.personId === personId);
  },[persons]);


  const handleOnPersonChange = personId => {
    const newSelectedPerson = getSelectedPerson(personId);
    setSelectedPerson(newSelectedPerson);
    setLockPeriod(true);
    form.setFieldsValue({ year: undefined, period: undefined });
    onFilterAttendances && onFilterAttendances({
      person: newSelectedPerson
    });
    return personId;
  };

  const searchByPersonOrCampus = searchText => {
    return EntrepreneurSpiritService.getSearchPerson({ searchText });
  };

  const onSelectPersonBySearch = personId => {
    const newSelectedPerson =
      persons?.find(person => person?.personId === personId);
    !personId && setPersons([]);
    setSelectedPerson(newSelectedPerson);
    setDisableCampusAndName(!!personId);
    resetFiltersFields();
    setLockPeriod(true);
    handleOnFilterAttendances({ person: newSelectedPerson });
  };

  const handleSearchByText = async searchText => {
    if (!searchText) return;
    try {
      const matchedPersons = await searchByPersonOrCampus(searchText);
      setPersons(matchedPersons);
      resetFiltersFields();
      setLockPeriod(true);
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnFilterAttendances = ({
    startDate,
    endDate,
    person
  }) => {
    onFilterAttendances && onFilterAttendances({
      startDate,
      endDate,
      person
    });
  };

  const onYearChange = date => {
    form.setFieldsValue({ period: undefined });
    const selectedYear = new Date(date).getFullYear() || 0;

    if (!date) {
      handleOnFilterAttendances({ person: selectedPerson });
      setLockPeriod(true);
      return date;
    }

    handleOnFilterAttendances({
      person: selectedPerson,
      startDate: new Date(selectedYear, 0, 1, 0, 0, 0, 0).toISOString(),
      endDate: new Date(selectedYear, 11, 31, 23, 59, 59, 999).toISOString()
    });

    if (selectedYear) {
      setLockPeriod(false);
    } else {
      setLockPeriod(true);
    }

    return date;
  };

  const onPeriodChange = period => {
    const selectedDate = form.getFieldValue("year");
    const selectedYear = new Date(selectedDate).getFullYear() || 0;

    if (!period) {
      handleOnFilterAttendances({
        person: selectedPerson,
        startDate: new Date(selectedYear, 0, 1, 0, 0, 0, 0).toISOString(),
        endDate: new Date(selectedYear, 11, 31, 23, 59, 59, 999).toISOString()
      });
    }

    if (period === 1) {
      handleOnFilterAttendances({
        person: selectedPerson,
        startDate: new Date(selectedYear, 0, 1, 0, 0, 0, 0).toISOString(),
        endDate: new Date(selectedYear, 5, 31, 23, 59, 59, 999).toISOString()
      });
    }

    if (period === 2) {
      handleOnFilterAttendances({
        person: selectedPerson,
        startDate: new Date(selectedYear, 6, 1, 0, 0, 0, 0).toISOString(),
        endDate: new Date(selectedYear, 11, 31, 23, 59, 59, 999).toISOString()
      });
    }

    return period;
  };

  const resetFiltersFields = () => {
    form.setFieldsValue({
      campus: undefined,
      person: undefined,
      year: undefined,
      period: undefined
    });
  };
  return(
    <Form
      form={form}
      size={formSizes[windowSize]}
      layout="vertical"
      style={{ width: "100%" }}>
      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <Form.Item
            name="personSearch"
            label="Buscar">
            <SearchBar
              found={persons}
              search={handleSearchByText}
              onSelect={onSelectPersonBySearch}
              selected={selectedPerson?.personId}
              placeholder="Buscar estudiante por nombre o matrícula" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={12} lg={6}>
          <Form.Item
            name="campus"
            label="Campus"
            getValueFromEvent={onCampusChange}>
            <Select
              allowClear
              showSearch
              style={{ width: "100%" }}
              disabled={disableCampusAndName}
              placeholder="Campus"
              options={CAMPUS_LIST.map(c =>
                ({ value: c.slice(7), label: c })
              )}
              filterOption={(inputValue, option) => {
                const valueAndLabelArray = Object.values(option || {});
                const valueAndLabel = valueAndLabelArray.join("").toLowerCase();
                return valueAndLabel.includes(inputValue.toLowerCase());
              }} />
          </Form.Item>
        </Col>
        <Col xs={12} lg={6}>
          <Form.Item
            name="person"
            label="Nombre de la persona"
            getValueFromEvent={handleOnPersonChange}>
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              options={persons.map(p => ({
                label: p.fullName,
                value: p.personId
              })) || []}
              disabled={disableCampusAndName}
              placeholder="Nombre de la persona"
              filterOption={(inputValue, option) => {
                const valueAndLabelArray = Object.values(option || {});
                const valueAndLabel = valueAndLabelArray.join("").toLowerCase();
                return valueAndLabel.includes(inputValue.toLowerCase());
              }} />
          </Form.Item>
        </Col>
        <Col xs={12} lg={6}>
          <Form.Item
            name="year"
            label="Año"
            getValueFromEvent={onYearChange}>
            <DatePicker
              picker="year"
              style={{ width: "100%" }}
              disabled={!selectedPerson}
              placeholder="Seleccionar año" />
          </Form.Item>
        </Col>
        <Col xs={12} lg={6}>
          <Form.Item
            name="period"
            label="Periodo"
            getValueFromEvent={onPeriodChange}>
            <Select
              allowClear
              disabled={lockPeriod}
              style={{ width: "100%" }}
              placeholder="Periodo"
              options={[
                { value: 1, label: "1er semestre" },
                { value: 2, label: "2do semestre" }
              ]} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};