/**
 * @param {Company} data
 * @returns {Object}
 */
export function getUncompletedCompanyData(data = {}) {

  const generalInformation = !checkFields([
    data.name,
    data.startDate,
    data.fiscalState,
    data.businessModel,
    data.productStage,
    data.salesStage,
    data.financingStage,
    data.employeesAmount
  ]);

  const aboutYourCompany = !checkFields([
    data.shortDescription,
    data.economicIndustry,
    data.economicSector
  ]);

  const hasPresenceStates = !data?.presenceStates?.length > 0;

  const contactFields = !checkFields([
    data.email,
    data.phone,
    data.city,
    data.country
  ]);

  const contact = contactFields || hasPresenceStates;

  return {
    generalInformation,
    aboutYourCompany,
    contact
  };
}

/**
 * @param {*[]} fields
 * @returns {boolean}
 */
function checkFields(fields = []) {
  return fields.every(field => field);
}

/**
 * @typedef Company
 * @property {string} name
 * @property {string} startDate
 * @property {string} legalName
 * @property {string} fiscalState
 * @property {string} businessModel
 * @property {string} productStage
 * @property {string} salesStage
 * @property {string} annualSales
 * @property {string} financingStage
 * @property {string} employeesAmount
 * @property {string} shortDescription
 * @property {string} economicIndustry
 * @property {string} economicSector
 * @property {string} email
 * @property {string} phone
 * @property {string} city
 * @property {string} country
 * @property {string} streetAddress
 * @property {string} colonyAddress
 * @property {string} zipCode
 * @property {string} presenceStates
 */
