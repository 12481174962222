import React, { useState } from "react";
import { AddButton } from "../../shared";
import { AddTeamMemberModal } from "./modal";
import { roles } from "../../../utils/maps";

/**
 * @params { object } props
 * @param { object } style
 * @param { string } systemRole
 * @returns {JSX.Element}
 * @constructor
 */
export function AddTeamMemberButton({
  style = { width: "100%" },
  systemRole = "ADVISER"
}) {
  const [state, setState] = useState({
    isModalVisible: false
  });

  const handleModalClick = () => {
    setState({ isModalVisible: !state.isModalVisible });
  };

  return (
    <>
      <AddTeamMemberModal
        visible={state.isModalVisible}
        closeModal={handleModalClick}
        systemRole={systemRole} />
      <AddButton style={style} onClick={handleModalClick}>
        Añadir {roles[systemRole].labelSingular}
      </AddButton>
    </>
  );
}
