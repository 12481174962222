import gql from "graphql-tag";

export const getAssignedExpertServices = gql`
  query GetAssignedExpertServices(
    $companyId: ID!
    $batchId: ID!
    $type: String!
  ) {
    assignedExpertServices(
      where: { 
        ode: { id: $companyId }
        batch: { id: $batchId }
        expertService: { type: $type }
      }
    ) {
      id
      status
      assignedUnits
      availableUnits
      limitDate
      expertService{
        id
        name
        category
        coverPicUrl
        expert{
          id
          fullName
          profilePicUrl
        }
      }
    }
  }
`;
