export const companyStatusOptions = [
  {
    label: "Si",
    value: "YES"
  },
  {
    label: "No, cerró",
    value: "CLOSED"
  },
  {
    label: "No, no ha arrancado",
    value: "NOT_STARTED"
  }
];
