import React from "react";
import { Typography, List, Tag } from "antd";
import { EditButton, DeleteButton } from "../../../../";
import { Visibility } from "../../../../visibility";
import { languageLevels, getReadableValue } from "../../../../../../helpers/select-options";

export function LanguageItem({
  language,
  onEdit,
  onDelete,
  editable
}) {

  return (
    <List.Item style={{position: "relative"}}>
      <Visibility visible={editable}>
        <div style={{top: "0", right: "0", position: "absolute"}}>
          <EditButton onClick={onEdit} />
          <DeleteButton onClick={onDelete} />
        </div>
      </Visibility>
      <Typography.Text style={{ fontSize: "18px" }}>
        {language?.name} - {getReadableValue(languageLevels, language?.level)}
        &nbsp;
        <Visibility visible={language?.canDeliverServices}>
          <Tag color="purple">Entrega servicios en este idioma</Tag>
        </Visibility>
      </Typography.Text>
    </List.Item>
  );
}
