import React, { useState, useEffect, useContext } from "react";
import { Form, Switch, Typography, Row, Tag, List, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { EditProfileContext } from "../../../../contexts/shared/edit-profile";
import { Section } from "../../section";
import { Visibility } from "../../visibility";
import { FormContainer } from "../../form-container";
import { BatchesSelect } from "../../batches-select";
import { formatValues } from "./format-values";
import { CurrentUserContext } from "../../../../contexts/shared";

export const PrivacyEditSection = ({ editable = false }) => {
  const {
    saveChanges,
    loading,
    data
  } = useContext(EditProfileContext);
  const { currentUser } = useContext(CurrentUserContext);
  const [isPublic, setIsPublic] = useState(true);
  const [selectedBatches, setSelectedBatches] = useState({
    oldBatches: [],
    newBatches: []
  });

  const {Title} = Typography;

  const onSwitchChange = e => {
    setIsPublic(e);
    const data = formatValues(selectedBatches.newBatches, e);
    saveChanges(data, "privacy");
  }

  const onBatchSelectChange = (e, values) => {
    setSelectedBatches(state => ({
      ...state,
      newBatches: values.map(value => (value.value))
    }));
  }

  const onSave = () => {
    if(selectedBatches.newBatches.length !== 0) {
      const data = formatValues(selectedBatches.newBatches, isPublic);
      saveChanges(data, "privacy");
      setSelectedBatches(state => ({
        ...state,
        newBatches: []
      }))
    }
  };

  const onDeleteTag = batchId => {
    const data = { expertProfile: { update: { 
      privateBatches: { disconnect: { id: batchId } },
      private: !isPublic
    }}};
    saveChanges(data, "privacy", true);
  }

  useEffect(() => {
    let isExpertPrivate = data?.user?.expertProfile?.private || false;
    let oldBatches = data?.user?.expertProfile?.privateBatches || [];
    setIsPublic(!isExpertPrivate);
    setSelectedBatches({
      newBatches: [],
      oldBatches: oldBatches
    });
  }, [data])

  if( currentUser.systemRole !== "SUPER_ADMIN" ) { 
    return null 
  };

  return (
    <Section
      spaced
      title="Privacidad">
      <FormContainer>
        <Form
          layout="vertical">
          <Row
            justify="space-between">
            <Title level={5}>El perfil del experto será visible para todos los batches</Title>
            <Switch
              checked={isPublic}
              onChange={onSwitchChange}/>
          </Row>
          <Visibility
            visible={!isPublic}>  
            <Form.Item label="Selecciona los batches en donde el perfil será visible">
              <Row justify="space-between">
                <BatchesSelect
                  mode="multiple"
                  excludedBatches={selectedBatches.oldBatches}
                  style={{ width: "92%" }}
                  value={selectedBatches.newBatches}
                  onChange={onBatchSelectChange} />
                <Button
                  style={{
                    color: "#704AED",
                    border: "1px solid #704AED"
                  }}
                  onClick={onSave}>Agregar</Button>
              </Row>
              <Visibility visible={!isPublic}>
                {
                  selectedBatches.oldBatches.length > 0 ?
                    <List
                      loading={loading}
                      style={{ marginTop: "13px" }}
                      dataSource={selectedBatches.oldBatches}
                      renderItem={item => (
                        <Tag>
                          {`${item.name} `}
                          <CloseOutlined
                            className="clickable"
                            onClick={() => onDeleteTag(item.id)}/>
                        </Tag>
                      )}/> : null
                }
              </Visibility>
            </Form.Item>
          </Visibility>
        </Form>
      </FormContainer>
    </Section>
  )
}
