import React from "react";
import { richTextToHtml } from "./rich-text-to-html";

export const tableRowsToHtmlTable = ({ rows = [] }) => {
  return (
    <div>
      <table style={{
        width: "100%",
        borderCollaps: "collapse"
      }}>
        <tbody>
          {rows?.map((row, rowIdx) => {
            return (
              <tr key={`table-row-${rowIdx.toString()}`}>
                {row?.table_row?.cells?.map((cellElement, cellIdx) => {
                  return (
                    <td
                      rowIdx={rowIdx}
                      key={`table-row-cell-${cellIdx.toString()}`}
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        border: "1px solid #ddd"
                      }}>
                      {richTextToHtml(cellElement)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
    </div>
  );
};
