import gql from "graphql-tag";

export const getCompanyTeamMembers = gql`
  query GetCompanyTeamMembers(
    $systemRole: [SystemRole!]
    $odeId: ID!
  ) {
    users(
      where: {
        systemRole_in: $systemRole
        teamOdes_some: {
          id: $odeId
        }
      }
    ) {
      id
      fullName
      phone
      email
      profilePicUrl
      systemRole
    }
  }
`;
