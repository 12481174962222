import React from "react";
import { Button, Menu, Dropdown, Modal } from "antd";
import { EllipsisOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { NewActionButton } from "./new-action-button";

function ActionButton({
  extra,
  onDelete,
  customOnDelete,
  onDeleteText = "Eliminar",
  confirmDeleteText = "¿Deseas eliminar este elemento?",
  onDuplicateText = "Duplicar",
  onEdit,
  onDisable,
  onDuplicate,
  rotate,
  onEnable,
  needStopPropagation = true,
  ...props
}) {
  const handleOnDelete = () => {
    Modal.confirm({
      okText: onDeleteText,
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: confirmDeleteText,
      onOk: onDelete
    });
  };

  const handleOnDeleteClick = e => {
    e.domEvent.stopPropagation();
    if(customOnDelete) {
      customOnDelete();
    } else {
      handleOnDelete();
    }
  };

  const handleOnEditClick = e => {
    if (needStopPropagation) {
      e.domEvent.stopPropagation();
    }
    onEdit();
  };

  const handleOnDuplicateClick = e => {
    e.domEvent.stopPropagation();
    onDuplicate();
  };

  const menu = <Menu>
    {onEdit &&
      <Menu.Item onClick={e => handleOnEditClick(e)}>
        Editar
      </Menu.Item>}
    {extra &&
      <>
        {extra.map(({ action, label }) =>
          <Menu.Item key={label} onClick={e => action(e)}>
            {label}
          </Menu.Item>
        )}
      </>}
    {onDisable &&
      <Menu.Item onClick={onDisable}>
        Deshabilitar
      </Menu.Item>}
    {onEnable &&
      <Menu.Item onClick={onEnable}>
        Habilitar
      </Menu.Item>}
    {(onDelete || customOnDelete) &&
      <Menu.Item onClick={e => handleOnDeleteClick(e)}>
        {onDeleteText}
      </Menu.Item>}
    {onDuplicate &&
      <Menu.Item onClick={e => handleOnDuplicateClick(e)}>
        {onDuplicateText}
      </Menu.Item>}
  </Menu>;

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <Button
        data-cy="action-button"
        shape="circle"
        onClick={ e => e.stopPropagation() }
        icon={<EllipsisOutlined rotate={rotate && 90} />}
        {...props} />
    </Dropdown>
  );
}

ActionButton.New = NewActionButton;

export default ActionButton;
