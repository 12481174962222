export const formatData = (data, user) => {
  delete data.selectedProgram;
  return {
    ...data,
    createdBy: {connect: { id: user.id }},
    coverPicUrl: data?.coverPicUrl?.file?.response?.imageUrl,
    program: {connect:{id:data.program}},
    batches: {connect: data.batches.map(batch => ({id: batch.id}))},
    odes: {connect: data.odes.map(ode => ({id: ode.id}))}
  };
};