import gql from "graphql-tag";

export const updateAssignedExpertService = gql`
  mutation UpdateAssignedExpertService(
    $data: AssignedExpertServiceUpdateInput!,
    $id: ID!
  ) {
    updateAssignedExpertService(
      data: $data,
      where: { id: $id }
    ) {
      id
    }
  }
`;
