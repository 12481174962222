import gql from "graphql-tag";

export const getOdeUpdatesByBatchId = gql`
  query GetOdeUpatesByBatchId(
    $id: ID!,
    $odeId: ID
  ) {
      odeUpdates(
        where: {
          batch: {
            id: $id
          }
          ode: {
            id: $odeId
          }
        }
      ) {
        id
        period
        comments {
          id
        }
      }
  }
`;
