import gql from "graphql-tag";

export const getEntrepreneurEvents = gql`
  query GetEntrepreneurEvents(
    $month: Int, $year: Int, $batch: BatchWhereInput
  ) {
    events(where: {
      batch: $batch,
      month: $month,
      year: $year
    }) {
      id
      date
      name
      type
    }
  }
`;
