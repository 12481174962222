import { client } from "../../../graphql";
import { disconnectOdeByBatchId } from "./graphql";

export const disconnectOdeFromBatchService = async ({
  batchId,
  companyId,
  odesSorted
}) => {
  const result = await client.mutate({
    mutation: disconnectOdeByBatchId,
    variables: {
      batchId,
      data: {
        odes: { disconnect: { id: companyId } },
        odesSorted: { set: odesSorted }
      }
    }
  });

  return result?.data?.updateBatch || {};
};
