import gql from "graphql-tag";

export const getScheduledServicesByAssignmentNumber = gql`
  query GetScheduledServicesByAssignmentNumber(
    $assignmentNumber: String!
  ) {
    scheduledExpertServices(
      where: {
        assignedExpertService: {
          assignmentNumber: $assignmentNumber
        }
      }
    ) {
      id
      serviceDate
      status
      duration
      requestedBy {
        id
        fullName
        email
        phone
      }
      assignedExpertService {
        id
        assignmentNumber
        assignationIdentifier
        expertService {
          id
          name
          type
          price
          method
          category
          expert {
            id
            fullName
            firstName
            middleName
            lastName
          }
        }
        batch {
          id
          name
          program {
            id
            name
            coordinator {
              id
              fullName
              phone
              email
            }
          }
        }
        ode {
          id
          name
        }
      }
    }
  }
`;
