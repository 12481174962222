import React, { useState } from "react";
import { Switch } from "antd";
import { TecRelationshipForm } from "./form";
import { Section } from "../../../../../shared";
import { Visibility } from "../../../../../shared/visibility";

export function TecRelationship() {
  const [state, setState] = useState({ hasRelationship: true });

  const onSwitchChange = value => {
    setState({ hasRelationship: value });
  };

  return (
    <Section
      title="¿Eres Familia Tec? Proporciónanos tu matrícula y/o nómina"
      extra={
        <Switch
          defaultChecked={state.hasRelationship}
          onChange={onSwitchChange} />
      }>
      <Visibility visible={state.hasRelationship}>
        <TecRelationshipForm />
      </Visibility>
    </Section>
  );
}
