const unorderedExpertServiceCategories = [
  { label: "Diseño", value: "DESIGN" },
  { label: "Emprendimiento de negocios", value: "ENTREPRENEURSHIP" },
  { label: "Especialidad técnica", value: "TECHNICAL_SPECIALITY" },
  { label: "Finanzas", value: "FINANCIAL" },
  { label: "Fiscal", value: "FISCAL" },
  { label: "Legal", value: "LEGAL" },
  { label: "Lifestyle", value: "LYFESTYLE" },
  { label: "Marketing", value: "MARKETING" },
  { label: "Música y audio", value: "MUSIC_AUDIO" },
  { label: "Programación", value: "PROGRAMMING" },
  { label: "Psicología y coaching", value: "PSICOLOGY_AND_COACHING" },
  { label: "Ventas", value: "SALES" }
];

const categories = unorderedExpertServiceCategories
  .sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

categories.push({ label: "Otro", value: "OTHER" });

export const expertServiceCategories = categories;
