import React, { useState } from "react";
import { ServiceApprovalModalContext } from "./context";
import {
  ServiceApprovalModal
} from "../../../../../components/expert/services/requests/service-approval-modal";
import { client } from "../../../../../../graphql";
import { shared } from "../../../../../graphql/shared";

export function ServiceApprovalModalProvider({ children }) {
  const [state, setState] = useState({
    isModalOpen: false,
    scheduledService: false,
    loading: false
  });

  const onSaveListeners = [];
  const onRejectListeners = [];

  const getScheduledExpertService = async id => {
    return await client.query({
      query: shared.queries.getFullScheduledExpertServiceById,
      variables: { id },
      fetchPolicy: "network-only"
    });
  };

  const openModal = async (scheduledService = false) => {
    setState(prevState => ({
      ...prevState,
      isModalOpen: true,
      loading: true
    }));

    let service = {};

    if (scheduledService?.id) {
      const result = await getScheduledExpertService(scheduledService.id);
      service = result?.data.scheduledExpertService;
    }

    setState({
      isModalOpen: true,
      scheduledService: service,
      loading: false
    });
  };

  const closeModal = () => {
    setState({
      isModalOpen: false,
      scheduledService: false
    });
  };

  const onSave = values => {
    onSaveListeners.forEach(i => i(values));
    closeModal();
  };

  const onReject = values => {
    onRejectListeners.forEach(i => i(values));
    closeModal();
  };

  const onCancel = () => {
    closeModal();
  };

  const addOnSaveListener = func => {
    onSaveListeners.push(func);
  };

  const addOnRejectListener = func => {
    onRejectListeners.push(func);
  };

  const injectActions = {
    openModal,
    closeModal,
    addOnSaveListener,
    addOnRejectListener
  };

  return (
    <ServiceApprovalModalContext.Provider value={injectActions}>
      <ServiceApprovalModal
        loading={state.loading}
        visible={state.isModalOpen}
        onReject={onReject}
        onCancel={onCancel}
        onSave={onSave}
        scheduledService={state.scheduledService} />
      {children}
    </ServiceApprovalModalContext.Provider>
  );
}
