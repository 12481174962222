import { STATES_RATIO } from "./constants";

export function usePresenceMap (organizations = []) {

  const ratioByPresence = JSON.parse(JSON.stringify(STATES_RATIO));

  const ratioByNationalPresence = organizations.reduce((acc, curr) => {
    if (curr?.nationalPresence) {
      return acc + 1;
    } else {
      curr.states.forEach(state =>
        ratioByPresence[state.name] += 1
      );
    }
    return acc;
  }, 0);

  return {
    ratioByPresence,
    ratioByNationalPresence
  };
}

