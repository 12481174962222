import React, { useEffect, useContext } from "react";
import {
  ServicesMonitoringPanedTables
} from "../../../../../../components/admin/experts/services/monitoring";
import {
  MenuContext,
  ServiceDetailModalProvider
} from "../../../../../../contexts/shared";
import { Section, SidebarLayout } from "../../../../../../components/shared";

export function AssignationsMonitoringIndex() {
  const { setMenuState } = useContext(MenuContext);

  /* set menu's current state */
  useEffect(() => {
    setMenuState({
      openKeys: ["experts"],
      selectedKeys: ["experts/services/monitoring/assignations"]
    });
  }, []);
status;
  return (
    <ServiceDetailModalProvider refetch={() => undefined}>
      <SidebarLayout>
        <Section spaced>
          <ServicesMonitoringPanedTables
            tableType="ASSIGNATIONS" />
        </Section>
      </SidebarLayout>
    </ServiceDetailModalProvider>
  );
}
