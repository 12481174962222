import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { shared } from "../../../../../graphql/shared";
import { client } from "../../../../../../graphql";
import { useQueryFilters, withRouter } from "../../../../../helpers";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { EditOdeProvider } from "../../../../../contexts/shared/edit-ode";
import { Section, SidebarLayout } from "../../../../../components/shared";
import { Breadcrumbs, Navbar } from "../../../../../components/shared/odes/show";
import { MainMenu } from "../../../../../components/entrepreneur/shared";
import { SummaryEditSection } from "../../../../../components/shared/ode-forms";
import {
  CurrentUserContext,
  OdeUpdateModalProvider
} from "../../../../../contexts/shared";
import { FilterControls, OdeUpdatesList } from "../../../../../components/shared/ode-updates-list";
import { entrepreneur } from "../../../../../graphql/entrepreneur";
import { OdeUpdateCommentsModalProvider } from "../../../../../contexts/shared";

function OdeUpdatesIndex({ match }) {
  const { params = {} } = match;
  const odeId = params?.id;

  const { currentUser } = useContext(CurrentUserContext);

  const { loading, error, data = {} } = useQuery(
    shared.queries.getOdeById,
    {
      client,
      variables: { id: odeId },
      fetchPolicy: "network-only"
    }
  );

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    setMenuState({
      openKeys: ["odes"],
      selectedKeys: ["odes"]
    });
  }, []);

  const {
    loading: odeUpdatesLoading,
    data: odeUpdatesData,
    refetch: refetchOdeUpdates
  } = useQuery(entrepreneur.queries.getOdeUpdatesByEntrepreneurId, {
    variables: { id: currentUser.id, odeId: odeId },
    client
  });

  const { applyFilters } = useQueryFilters({ refetch: refetchOdeUpdates });

  const onFilter = (values = {}) => {
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...values
    }));
  };

  return (
    <EditOdeProvider
      odeId={odeId}
      data={data}
      loading={loading}
      error={error}>
      <SidebarLayout menu={<MainMenu />}>
        <Breadcrumbs
          odeName={data?.oDE?.name} />
        <SummaryEditSection
          editable
          data={data}
          isLoading={loading}
          error={error} />
        <Navbar selectedKeys={["ode-updates"]} />
        <OdeUpdateModalProvider odeId={odeId}>
          <OdeUpdateCommentsModalProvider>
            <Section
              spaced
              title="Historial"
              extra={<FilterControls
                onFilter={onFilter}
                odeId={odeId} />
              }>
              <OdeUpdatesList
                loading={odeUpdatesLoading}
                odeUpdates={odeUpdatesData?.odeUpdates} />
            </Section>
          </OdeUpdateCommentsModalProvider>
        </OdeUpdateModalProvider>
      </SidebarLayout>
    </EditOdeProvider>
  );
}

export default withRouter(OdeUpdatesIndex);
