import React, { useState } from "react";
import { Button, Tabs } from "antd";
import { AssignationsTable, ScheduledTable } from "../../";
import { useHistory } from "react-router-dom";
import { MonitoringTableWrapper } from "../monitoring-table-wrapper";
import {
  FilterControlsAssigned,
  FilterControlsScheduled
} from "../filter-controls";

/**
 * @param {object} props
 * @param {"ASSIGNATIONS" | "TRACKING"} props.tableType
 */
export function ServicesMonitoringPanedTables({
  tableType = "ASSIGNATIONS"
}) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState("MENTORSHIP");
  const history = useHistory();

  const btnType = type => type === tableType
    ? "MonitoringExpert__Secondary" : "MonitoringExpert__Default";

  const ServicesTableComponent = tableType === "ASSIGNATIONS"
    ? AssignationsTable
    : ScheduledTable;

  const navigate = (path = "") => history.push(path);

  const FilterControls = tableType === "ASSIGNATIONS"
    ? FilterControlsAssigned
    : FilterControlsScheduled;

  const handleChangedTab = tabName => {
    setSelectedServiceType && setSelectedServiceType(tabName);
    setSelectedRows && setSelectedRows([]);
  };

  return (
    <div className="MonitoringExpert">
      <Button
        onClick={() => navigate("./assignations")}
        className={btnType("ASSIGNATIONS")}>
        Asignadas
      </Button>
      <Button
        onClick={() => navigate("./tracking")}
        className={btnType("TRACKING")}>
        En curso
      </Button>
      <Tabs
        defaultActiveKey="MENTORSHIP"
        activeKey={selectedServiceType}
        onChange={handleChangedTab}>
        <Tabs.TabPane tab="Mentoria" key="MENTORSHIP">
          <MonitoringTableWrapper
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            filterControls={FilterControls}
            tableType={tableType}
            serviceType={selectedServiceType}>
            <ServicesTableComponent
              serviceType={selectedServiceType}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows} />
          </MonitoringTableWrapper>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Capacitaciones" key="WORKSHOP">
          <MonitoringTableWrapper
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            filterControls={FilterControls}
            tableType={tableType}
            serviceType={selectedServiceType}>
            <ServicesTableComponent
              serviceType={selectedServiceType}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows} />
          </MonitoringTableWrapper>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Servicios" key="PRODUCT">
          <MonitoringTableWrapper
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            filterControls={FilterControls}
            tableType={tableType}
            serviceType={selectedServiceType}>
            <ServicesTableComponent
              serviceType={selectedServiceType}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows} />
          </MonitoringTableWrapper>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
