import gql from "graphql-tag";

export const deleteObjective = gql` 
  mutation deleteObjective(
    $id: ID!
  ){
    deleteObjective( where: { id: $id }){
      id
    }
  }
`;