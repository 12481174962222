import React from "react";
import { List } from "antd";
import { Avatar } from "../../../../shared";
import { FlagOutlined } from "@ant-design/icons";
import { withRouter } from "../../../../../helpers";

function ObjectiveItem({ objective, navigate }) {
  const {
    name = "Sin título",
    description = "Sin descripción"
  } = objective;

  const gotToObjectiveDetails = () => {
    navigate(`./objectives/${objective?.id}`);
  };

  return (
    <List.Item
      className="clickable"
      onClick={gotToObjectiveDetails}>
      <List.Item.Meta
        avatar={<Avatar color="#1DBABA"><FlagOutlined /></Avatar>}
        title={name}
        description={description} />
    </List.Item>
  );
}

export default withRouter(ObjectiveItem);
