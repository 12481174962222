import gql from "graphql-tag";

export const createObjectiveAchievement = gql`
  mutation CreateObjectiveAchievement($data: AchievementCreateInput!) {
    createAchievement(
      data: $data
    ) {
      id
    }
  }
`;
