import React from "react";
import { ModalButton } from "../../../../../../../../../shared/buttons";
import {
  EntrepreneurAssignedProductDetailModal,
  EntrepreneurScheduledProductDetailModal,
  EntrepreneurScheduledServiceModal
} from "../../../../../../../../shared/modals";

export function FooterButton({
  assignedService,
  scheduledService,
  onSchedule,
  refetchScheduledServices
}) {
  const limitDate = new Date(assignedService.limitDate).getTime();
  const today = new Date();
  today.setHours(0,0,0,0);
  const limitDateReached = limitDate < today.getTime();

  if (limitDateReached && !scheduledService)
    return (
      <ModalButton
        type="link"
        key="btn-details"
        title="Ver detalle"
        item={assignedService}
        modal={EntrepreneurAssignedProductDetailModal} />
    );

  return !scheduledService
    ? <ModalButton
        type="link"
        title="Agendar"
        item={assignedService}
        modalProps={{ refetchScheduledServices }}
        modal={EntrepreneurScheduledServiceModal}
        key="btn-schedule" />
    : <ModalButton
        type="link"
        key="btn-details"
        title="Ver detalle"
        item={scheduledService}
        modal={EntrepreneurScheduledProductDetailModal} />;
}
