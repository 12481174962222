export const setUpdatePersonalInformation = (personalInformation = {}, state, setState) => {
  const updatedPersonalInformation = {
    ...state.personalInformation,
    ...personalInformation
  };

  setState({
    ...state,
    personalInformation: updatedPersonalInformation
  });
};
