import React from "react";
import { Steps } from "antd";
import { Section } from "../../../shared";

export const StepsNavigation = ({ current }) => {
  const { Step } = Steps;
  return (
    <Section
      style={{ margin: "5px 300px"}}>
      <Steps
        current={current}
        style={{ fontWeight: "500" }}>
        <Step title={"Tu información"} />
        <Step title={"Tu compañía"} />
        <Step title={"Bienvenido"} />
      </Steps>
    </Section>
  );
};
