import React from "react";
import { Space, Tag, Typography } from "antd";
import { Visibility } from "../visibility";
import { TagList } from "../tag-list";

export function Presence({ organization }) {
  return (
    <>
      <Typography.Title
        level={4}
        className="OrganizationModal__title">
        Esta organización tiene presencia en
      </Typography.Title>
      <Visibility visible={organization?.nationalPresence}>
        <Tag
          style={{ padding: "1px 8px" }}
          color="default">
          Presencia nacional
        </Tag>
      </Visibility>
      <Space direction="vertical">
        {organization?.states.map(e => {
          return (
            <Typography.Text
              type="secondary"
              key={e.name}>
              {`${e.name} : `}
              <TagList
                expandTags
                mapColors={true}
                tags={e.cities} />
            </Typography.Text>
          );
        })}
      </Space>
    </>
  );
}