import React from "react";
import { List } from "antd";
import { ListItem } from "../../../../../components/shared";
import { cardGridSizes } from "../../../../../helpers";

export const BatchServicesList = ({
  batchServices,
  loading = false,
  onRemove
}) => {
  batchServices = batchServices || [];

  return (
    <List
      loading={loading}
      grid={cardGridSizes}
      itemLayout="horizontal"
      dataSource={batchServices}
      renderItem={service => (
        <List.Item>
          <ListItem.BatchServiceListItem
            batchService={service}
            onRemove={() => onRemove({ serviceId: service.id })} />
        </List.Item>
      )} />
  );
};
