import { cloneDeep } from "lodash";

export function achievement(data = {}) {
  const decorated = cloneDeep(data);

  decorated.object = data;

  decorated.name = data.name === "" ? "Sin título" : data.name;
  decorated.description = data.description === "" ?
    "Sin descripción"
    : data.description;

  return decorated;
}
