import React from "react";
import { Tag, Space } from "antd";
import { HiddenTagsPopover } from "./hidden-tags-popover";
import { Visibility } from "../visibility";
import { Format } from "../format";
import { filterTags } from "./filter-tags";

const COLORS = ["cyan", "geekblue", "magenta", "green"];

/**
 * @params props
 * @param {number} labelMaxLength
 * @param {string} className
 * @param {array} tags
 * @param {array} colors
 * @param {number} maxCount
 * @param {boolean} mapColors
 * @param {boolean} expandTags
 * @returns {JSX.Element|null}
 * @constructor
 */
export function TagList({
  labelMaxLength,
  className = "",
  tags = [],
  colors = COLORS,
  maxCount = 3,
  mapColors = false,
  expandTags = false
}) {
  if(!tags.length)
    return null;

  const filteredTags = filterTags(tags);

  const listTags = expandTags ? filteredTags : filteredTags.slice(0, maxCount);

  return (
    <Space className={className}>
      <Visibility visible={true}>
        {listTags.map((label, index) => (
          <Tag
            key={label}
            style={{ padding: "1px 8px", marginRight: "4px" }}
            color={mapColors ? (colors[label] || "default") : colors[index]}>
            {labelMaxLength
              ? <Format type="prune" size={labelMaxLength}>{label}</Format>
              : label
            }
          </Tag>
        ))}
      </Visibility>
      <Visibility visible={!expandTags}>
        {filteredTags.length > maxCount && (
          <HiddenTagsPopover
            colors={colors}
            mapColors={mapColors}
            tags={filteredTags?.slice(maxCount)}>
            <Tag
              style={{ padding: "1px 8px" }}
              color={colors[maxCount]}>
              + {filteredTags.length - maxCount}
            </Tag>
          </HiddenTagsPopover>
        )}
      </Visibility>
    </Space>
  );
}
