import React, { useMemo, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { message } from "antd";
import { client } from "../../../../../../graphql";
import { shared } from "../../../../../graphql/shared";
import { BatchContentsContext } from "../../../../../contexts/admin/batches";
import {
  ContentDetailsModalContextProvider,
  ContentGroupModalContextProvider,
  ContentModalContextProvider,
  ContentViewSwitchProvider,
  DraggableListProvider
} from "../../../../../contexts/shared";
import { MainMenu } from "../../../../../components/admin/shared";
import { BatchContent, SidebarLayout } from "../../../../../components/shared";
import { HeaderBreadcrumbs } from "../../../../../components/shared/batch-offer/header-breadcrumbs";
import { StepsNavigation } from "../../../../../components/admin/batches/new/steps-navigation";
import { ComposedHeader } from "../../../../../components/admin/batches/new/content/composed-header";
import { ControllerButtons } from "../../../../../components/admin/batches/new/controller-buttons";

export const NewBatchContentIndex = () => {
  const { id } = useParams();
  const history = useHistory();

  const {
    data, error, loading, refetch
  } = useQuery(shared.queries.getMasterContentByBatchId, {
    client,
    variables: {
      id: id
    }
  });

  const [deleteMasterContent, { loading: loadingMasterContent }] = useMutation(
    shared.mutations.deleteMasterContent, { client }
  );

  const [updateMasterContent] = useMutation(
    shared.mutations.updateMasterContent, { client }
  );

  const [updateContentGroup] = useMutation(
    shared.mutations.updateContentGroup, { client }
  );

  const onUpdateMasterContent = useCallback(async create => {
    await updateMasterContent({
      variables: {
        where: { id: data?.masterContents?.[0]?.id },
        data: { contentGroups: { create } }
      }
    });
    await refetch();
  }, [data]);

  const onUpdateContentSorted = useCallback(async (uuids, groupId) => {
    if (!groupId) {
      await updateMasterContent({
        variables: {
          where: { id: data?.masterContents?.[0]?.id },
          data: { contentGroupsSort: { set: uuids } }
        }
      });
    } else {
      await updateContentGroup({
        variables: {
          where: { id: groupId },
          data: { contentsSort: { set: uuids } }
        }
      });
    }
    await refetch();
    message.success("Ordenamiento guardado.");
  }, [data]);

  const onUpdateContentGroup = useCallback(async (groupId, values) => {
    await updateContentGroup({
      variables: { where: { id: groupId }, data: values }
    });
    await refetch();
  }, [data]);

  const onPrev = () => history.push("./members");
  const onNext = async () => history.push("./publish");

  const injectActions = useMemo(() => ({
    data,
    error,
    loading,
    refetch,
    batchId: id,
    isHeaderVisible: false
  }), [data, loading]);

  return (
    <BatchContentsContext.Provider value={injectActions}>
      <DraggableListProvider onUpdate={onUpdateContentSorted}>
        <ContentGroupModalContextProvider
          onSave={onUpdateMasterContent}
          onUpdate={onUpdateContentGroup}>
          <ContentModalContextProvider batchId={id} refetch={refetch}>
            <ContentDetailsModalContextProvider batchId={id} refetch={refetch}>
              <SidebarLayout menu={<MainMenu />}>
                <HeaderBreadcrumbs
                  onPrev={onPrev}
                  title="Batches"
                  name="Contenido" />
                <StepsNavigation current={2} />
                <ComposedHeader />
                <ContentViewSwitchProvider>
                  <BatchContent />
                </ContentViewSwitchProvider>
                <ControllerButtons
                  onPrev={onPrev}
                  onNext={onNext}
                  loading={loadingMasterContent} />
              </SidebarLayout>
            </ContentDetailsModalContextProvider>
          </ContentModalContextProvider>
        </ContentGroupModalContextProvider>
      </DraggableListProvider>
    </BatchContentsContext.Provider>
  );
};
