import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Form, message } from "antd";
import moment from "moment";
import { client } from "../../../../../../../graphql";
import { MainMenu } from "../../../../../../components/coordinator/shared";
import { SidebarLayout } from "../../../../../../components/shared";
import { HeaderBreadcrumbs } from "../../../../../../components/shared/batch-offer/header-breadcrumbs";
import { StepsNavigation } from "../../../../../../components/admin/batches/new/steps-navigation";
import { InformationForm } from "../../../../../../components/admin/batches/new/information/information-form";
import { HeaderTexts } from "../../../../../../components/admin/batches/new/header-texts";
import { shared } from "../../../../../../graphql/shared";
import { admin } from "../../../../../../graphql/admin";
import { ControllerButtons } from "../../../../../../components/admin/batches/new/controller-buttons";

export const NewBatchInformationUpdateIndex = () => {
  const { id = "" } = useParams();
  const [state, setState] = useState({
    programId: undefined,
    program: undefined
  });
  const history = useHistory();
  const [form] = Form.useForm();
  const [updateBatch, { loading }] = useMutation(
    admin.mutations.updateBatch, { client }
  );

  const { data } = useQuery(
    admin.queries.getBatchById, {
      client,
      fetchPolicy: "network-only",
      variables: { id }
    }
  );

  const onNext = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();

    try {
      values.program = {
        connect: {
          id: state.programId
        }
      };

      values.startDate = moment(values.dates[0]).toISOString();
      values.finishDate = moment(values.dates[1]).toISOString();

      delete values.dates;
      delete values.programId;

      await updateBatch({ variables: { data: values, where: { id } } });
      history.push(`/v2/coordinator/batches/new/${id}/members`);
    } catch(e) {
      if (e.message.match(/NAME_ALREADY_TAKEN/)) {
        return message.warning(`Ya existe un batch con el nombre\
           "${values.name}". Por favor, ingresa un nombre diferente.`);
      }
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    }
  };

  const onSelectProgram = value => {
    setState({ ...state, programId: value });
  };

  useEffect(() => {
    const fetchProgram = async () => {
      const { data } = await client.query({
        query: shared.queries.getProgramById,
        variables: { id: state.programId }
      });

      setState({ ...state, program: data.program });
    };

    if(state.programId) {
      fetchProgram();
    }
  }, [state.programId]);

  useEffect(() => {
    if(data) {
      const { startDate, finishDate } = !!data?.batch && data?.batch;
      const dates = (startDate && finishDate)
        ? [moment(startDate), moment(finishDate)]
        : [];
      form.setFieldsValue({ ...data.batch, programId: data.batch?.program?.id, dates });
      setState({ program: data.batch?.program, programId: data.batch?.program?.id });
    }
  }, [data]);

  return (
    <SidebarLayout menu={<MainMenu />}>
      <HeaderBreadcrumbs
        title="Batches"
        name="Crear nuevo batch" />
      <StepsNavigation current={0} />
      <div style={{ margin: "30px 13px" }}>
        <HeaderTexts
          title="Agrega la información básica de tu batch"
          description="Explica brevemente tu batch" />
        <InformationForm
          form={form}
          program={state.program}
          onSelectProgram={onSelectProgram} />
      </div>
      <ControllerButtons loading={loading} onNext={onNext} />
    </SidebarLayout>
  );
};
