import gql from "graphql-tag";

export const getUsersTeamMember = gql`
  query GetUsersTeamMember($where: UserWhereInput) {
    users(where: $where){
      id
      fullName
      email
    }
  }
`;
