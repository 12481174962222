import gql from "graphql-tag";

export const addCommentContent = gql`
  mutation AddCommentContent(
    $odeContentId: ID,
    $odeId: ID,
    $batchId: ID,
    $contentId: ID,
    $userId: ID,
    $comment: String!,
  ){
    upsertOdeContent(
      where:{ id: $odeContentId }
      update:{
        comments:{
          create:{
            author:{ connect:{ id: $userId } }
            comment: $comment
          }
        }
      }
      create:{
        ode: { connect: { id: $odeId } }
        batch: { connect: { id: $batchId } }
        content: { connect: { id: $contentId } }
        comments:{
          create:{
            author:{ connect:{ id: $userId } }
            comment: $comment
          }
        }
      }
    ){
      id
    }
  }
`;