import React from "react";
import { Form, Select, Input, DatePicker, Row } from "antd";
import { fundTypeOptions } from "../select-options/fund-type";
import { sourceOptions } from "../select-options/source";
import { currencies } from "../../../../../helpers/select-options";

export const CompanyFundForm = ({ form }) => {
  return (
    <Form
      form={form}
      layout="vertical"
      style={{ padding: "0 16px" }}>
      <Row justify="space-between">
        <Form.Item
          label="Tipo de fondo"
          name="fundType"
          rules={[{ required: true }]}>
          <Select
            options={fundTypeOptions}
            placeholder="Seleccionar"
            style={{ width: "357px " }}  />
        </Form.Item>
        <Form.Item
          label="Fuente"
          name="source"
          rules={[{ required: true }]}>
          <Select
            options={sourceOptions}
            placeholder="Seleccionar"
            style={{ width: "357px " }}  />
        </Form.Item>
      </Row>
      <Row justify="space-between">
        <Form.Item
          label="Inversor"
          name="investor"
          rules={[{ required: true }]}>
          <Input
            placeholder="Por ejemplo: Secretaría del emprendedor"
            style={{ width: "357px " }}  />
        </Form.Item>
        <Form.Item
          label="Monto obtenido"
          name="amount"
          rules={[{ required: true }]}>
          <Input
            addonAfter={
              <Form.Item
                noStyle={true}
                initialValue="MXN"
                name="currency">
                <Select options={currencies} />
              </Form.Item>
            }
            type="number"
            prefix="$"
            placeholder="Por ejemplo: $500,000"
            style={{ width: "357px " }}  />
        </Form.Item>
      </Row>
      <Form.Item
        label="Fecha"
        name="date"
        rules={[{ required: true }]}>
        <DatePicker
          format="MMMM-YYYY"
          picker="month"
          style={{ width: "357px " }} />
      </Form.Item>
    </Form>
  );
};
