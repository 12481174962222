import gql from "graphql-tag";

export const getBatchExpertsIds = gql`
  query GetBatchExpertsIds($batchId: ID!) {
    batch(where: { id: $batchId }) {
      id
      experts: users(where: { systemRole: ADVISER }) {
        id
      }
    }
  }
`;
