import React from "react";
import { List } from "antd";
import { CommentListItem } from "./item";
import "./styles.sass";

export function CommentList ({ odeContent }) {
  const { deliverables = [], events = [] } = odeContent || {};

  const dataSource = events?.map(el => ({
    id: el.id,
    type: el.event,
    createdAt: el.createdAt,
    description: el.description,
    fullName: el.blame.fullName,
    profilePicUrl: el.blame.profilePicUrl
  }));

  deliverables.forEach(el => {
    dataSource.push({
      id: el.id,
      url: el.url,
      type: "file",
      fileName: el.fileName,
      createdAt: el.uploadDate
    });
  });

  return (
    <List
      className="comment-list"
      itemLayout="horizontal"
      dataSource={
        dataSource.sort((a, b) =>
          b.createdAt.localeCompare(a.createdAt)
        )
      }
      rowKey={el => el.id}
      renderItem={item =>
        <CommentListItem item={item} />
      } />
  );
}
