import React, { useState } from "react";
import { Form, Button, Row, Typography } from "antd";
import { default as MemberList } from "../../../../shared/member-list";
import {
  Section,
  UsersSelect,
  EmptyView,
  BatchSectionHeading
} from "../../../../shared";

export const MembersFormExpert = ({
  showAddExpert,
  batchMentors,
  onConnectMember,
  onDisconnectMember,
  handleClickExpert
}) => {
  const [state, setState] = useState([]);
  const [form] = Form.useForm();
  const filterUsers = batchMentors?.map(el => el.id);

  const getLabel = text =>
    <Typography.Text style={{ fontSize: "14px"}}>{text}</Typography.Text>;

  const onChangeMemberList = value => {
    setState(value);
    return value;
  };

  const handleSubmit = async () => {
    await form.validateFields();
    const data = form.getFieldsValue();
    const parseData = data?.mentors?.map(el => ({ id: el }));
    onConnectMember && onConnectMember(parseData);
    form.resetFields();
    setState([]);
  };

  if (!showAddExpert && !batchMentors?.length) {
    return (
      <EmptyView
        style={{ width: "auto" }}
        isEmpty={true}
        isLoading={false}
        buttonTitle="Agregar expertos"
        onClick={() => handleClickExpert("showAddExpert")}
        description={
          <BatchSectionHeading
            title="¡Oops, aún no tienes mentores!"
            subTitle="Agrégalos para tenerlos disponibles en tu batch." />
        } />
    );
  }

  return (
    <Form
      form={form}
      layout="vertical"
      className="NewBatch__MembersForm">
      <Section style={{ marginBottom: "11px", minHeight: "600px"}}>
        <Row justify="space-between">
          <Form.Item
            getValueFromEvent={onChangeMemberList}
            name="mentors"
            label={getLabel("Aquí puedes ver, añadir mentores y asignarlos a las compañías.")}>
            <UsersSelect
              filterUsers={filterUsers}
              systemRoles={["ADVISER"]}
              placeholder="Selecciona a tus mentores" />
          </Form.Item>
          <Button
            disabled={!state.length}
            onClick={handleSubmit}>
            Agregar
          </Button>
        </Row>
        <MemberList
          actionsButton
          only="ADVISER"
          data={batchMentors}
          onDelete={onDisconnectMember} />
      </Section>
    </Form>
  );
};
