import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import { shared } from "../../../../../graphql/shared";
import {
  SidebarLayout,
  ContentWrapper,
  Breadcrumbs
} from "../../../../../components/shared";
import { Profile } from "../../../../../components/admin/experts/show";
import {
  AdminExpertsContext
} from "../../../../../contexts/admin/experts";
import { MainMenu } from "../../../../../components/entrepreneur/shared";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { EditProfileProvider } from "../../../../../contexts/shared/edit-profile";
import {
  SummaryEditSection
} from "../../../../../components/shared/profile-forms/summary";
import { PageLeavePromptProvider } from "../../../../../contexts/shared";

function ProfileIndex({ match }) {
  const { params = {} } = match;

  const { loading, error, data = {} } = useQuery(
    shared.queries.getExpertById,
    {
      client,
      variables: { id: params?.expertId },
      fetchPolicy: "network-only"
    }
  );

  const { setMenuState } = useContext(MenuContext);

  /* set menu's current state */
  useEffect(() => {
    setMenuState({
      openKeys: ["experts"],
      selectedKeys: ["experts/profiles"]
    });
  }, []);

  return (
    <PageLeavePromptProvider>
      <AdminExpertsContext.Provider value={{ data }}>
        {/* some shared forms need this provider */}
        {/* I think ProfileProvider could be a better name */}
        <EditProfileProvider
          data={data}
          userId={params?.expertId}>
          <SidebarLayout menu={<MainMenu />}>
            <Breadcrumbs
              expertName={data?.user?.fullName} />
            <SummaryEditSection
              data={data}
              error={error}
              isLoading={loading} />
            <ContentWrapper>
              <Profile
                data={data}
                error={error}
                isLoading={loading} />
            </ContentWrapper>
          </SidebarLayout>
        </EditProfileProvider>
      </AdminExpertsContext.Provider>
    </PageLeavePromptProvider>
  );
}

export default withRouter(ProfileIndex);
