import React, { useState } from "react";
import { Button, Space } from "antd";

export function CompanyNavBar() {

  const [activeComponent, setActive] = useState("general-information");

  return (
    <Space
      style={{
        top: "0",
        zIndex: 1,
        gap: "1rem",
        width: "100%",
        height: "4rem",
        position: "sticky",
        backgroundColor: "white",
        justifyContent: "center"
      }}
      direction={"horizontal"}>
      <Button
        type={activeComponent === "general-information" ? "primary" : "default"}
        onClick={() => setActive("general-information")}
        href={"#general-information"}
        shape={"round"}>
        Información General
      </Button>
      <Button
        type={activeComponent === "about" ? "primary" : "default"}
        onClick={() => setActive("about")}
        href={"#about"}
        shape={"round"}>
        Sobre la compañía
      </Button>
      <Button
        type={activeComponent === "hitos" ? "primary" : "default"}
        onClick={() => setActive("hitos")}
        href={"#hitos"}
        shape={"round"}>
        Hitos
      </Button>
      <Button
        type={activeComponent === "team" ? "primary" : "default"}
        onClick={() => setActive("team")}
        href={"#team"}
        shape={"round"}>
        Equipo
      </Button>
      <Button
        type={activeComponent === "batches" ? "primary" : "default"}
        onClick={() => setActive("batches")}
        href={"#batches"}
        shape={"round"}>
        Batches
      </Button>
    </Space>
  );
}
