import { cubeJSClient } from "../../api-clients";

export const getBatchEnrollmentStatusCompaniesCount = async ({
  batchId,
  batchFinished,
  enrollmentStatus,
  from,
  until,
  teamMemberCampus
}) => {
  const filters = [
    {
      "member": "Company.batchId",
      "operator": "equals",
      "values": [batchId]
    },
    {
      "member": "Company.companyStatusInBatch",
      "operator": "equals",
      "values": [enrollmentStatus]
    }
  ];

  if (teamMemberCampus) {
    filters.push({
      "member": "Company.teamMemberCampus",
      "operator": "equals",
      "values": [teamMemberCampus]
    });
  }

  if (batchFinished) {
    filters.push({
      "member": "Company.batchIsFinished",
      "operator": "equals",
      "values": [batchFinished ? "true" : "false"]
    });
  }

  if (from && until) {
    filters.push({
      "member": "Company.batchStartDate",
      "operator": "inDateRange",
      "values": [from, until]
    });
  }

  const resultSet = await cubeJSClient.load({
    "measures": [
      "Company.companiesCount"
    ],
    "order": {
      "Company.batchCreatedAt": "asc"
    },
    filters,
    "limit": 5000
  });

  const [queryData] = resultSet.loadResponse.results[0].data;
  return parseInt(queryData["Company.companiesCount"] || "0");
};