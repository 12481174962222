import { List } from "antd";
import React, { useEffect, useState } from "react";
import {
  ObjectiveAchivementItemContextProvider
} from "../../../contexts/shared";
import { AchievementItem } from "../achievement-item";
import { AddButton } from "../add-button";
import { RoleGuard } from "../role-guard";

export function AchievementsList({ achievements }) {
  const [state, setState] = useState({ achievements: [] });

  useEffect(() => {
    if(achievements) {
      setState({ ...state, achievements });
    }
  }, [achievements]);

  const onAdd = () => {
    const achievements = Array.from(state.achievements);

    achievements.unshift({
      name: undefined,
      description: undefined,
      new: true
    });

    setState({ ...state, achievements });
  };

  return (
    <>
      <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR"]}>
        <AddButton
          onClick={onAdd}
          style={{width: "100%"}}>
          Agregar logro
        </AddButton>
      </RoleGuard>
      <List
        itemLayout="vertical"
        dataSource={state.achievements}
        renderItem={i =>
          <List.Item key={i.id}>
            <ObjectiveAchivementItemContextProvider achievement={i}>
              <AchievementItem achievement={i} />
            </ObjectiveAchivementItemContextProvider>
          </List.Item>
        } />
    </>
  );
}
