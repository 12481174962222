export const placeholders = {
  name: "Por ejemplo: Modelo de negocio",
  description: {
    ACTIVITY: "Por ejemplo: El entregable debe ser en formato PDF",
    WORKSHOP: "Por ejemplo: Aprende a crear tu modelo de negocio de la mano de un experto",
    RESOURCE: "Por ejemplo: Este libro te ayudará a crear tu modelo de negocio"
  },
  instructions: "Por ejemplo: Crea tu modelo de negocio de acuerdo a tus objetivos",
  dueDate: "Por ejemplo: 13/02/2021",
  releaseTime: "Por ejemplo: 10:00",
  placeType: "Selecciona el tipo",
  placeDirection: "Por ejemplo: zoom.web/1234567890"
};
