import React from "react";
import { useHistory } from "react-router-dom";
import { MainMenu } from "../../../../../components/coordinator/shared";
import { SidebarLayout } from "../../../../../components/shared";
import {
  HeaderBreadcrumbs
} from "../../../../../components/shared/batch-offer/header-breadcrumbs";
import {
  default as BatchPublish
} from "../../../../../components/admin/batches/publish";
import {
  BatchDetailsContextProvider,
  BatchDetailsContext
} from "../../../../../contexts/admin/batches/batch-details";

export const BatchPublishIndex = () => {
  const history = useHistory();

  return (
    <BatchDetailsContextProvider>
      <BatchDetailsContext.Consumer>
        {({ data, error, loading, refetch }) => {
          return (
            <SidebarLayout menu={<MainMenu />}>
              <HeaderBreadcrumbs
                onPrev={() => history.push("/v2/coordinator/batches")}
                title="Batches"
                name={data?.batch?.name} />
              <BatchPublish
                batch={data?.batch}
                data={data}
                error={error}
                isLoading={loading}
                refetchBatch={refetch} />
            </SidebarLayout>
          );
        }}
      </BatchDetailsContext.Consumer>
    </BatchDetailsContextProvider>
  );
};
