import React, { useEffect, useRef, useState } from "react";
import { Button, Carousel, List, Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player/youtube";
import { FileLinkItem } from "../file-link-item";

/**
 * @params { object } props
 * @param { Array } files
 * @returns {JSX.Element}
 */
export function VideoCarousel({ files }) {
  const [videos, setVideos] = useState([]);
  const carouselRef = useRef(null);

  useEffect(() => {
    setVideos(files?.filter(file => ReactPlayer.canPlay(file.url)));
  }, [files]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {videos?.length ? (
        <div
          style={{
            marginTop: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
          {videos.length > 1 && (
            <Button
              type="secondary"
              icon={<LeftOutlined />}
              size="middle"
              onClick={() => carouselRef.current.prev()} />
          )}
          <div
            style={{ width: "90%" }}>
            <Carousel ref={carouselRef}>
              {
                videos.map(video => (
                  <div className="video-player" key={video.url}>
                    <ReactPlayer url={video.url} />
                  </div>
                ))
              }
            </Carousel>
          </div>
          {videos.length > 1 && (
            <Button
              type="secondary"
              icon={<RightOutlined />}
              size="middle"
              onClick={() => carouselRef.current.next()} />
          )}
        </div>
      ) : null}
      {files?.length ? (
        <List
          dataSource={files}
          renderItem={file =>
            <FileLinkItem
              item={file}
              canDelete={true} />} />
      ) : null}
    </Space>
  );
}
