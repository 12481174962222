export const filterEmptyRowsFromSheets = async ({
  batchesSheet
}) => {
  const [
    batchesRows
  ] = await Promise.all([
    new Promise(resolve => resolve(
      batchesSheet.filter(b => !!b.batch_id && !!b.Institución)
    ))
  ]);

  return {
    batchesRows
  };
};
