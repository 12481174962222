import gql from "graphql-tag";

export const getAvailableAssignedServices = gql`
  query GetAvailableAssignedServices(
    $entrepreneurId: ID!
    $serviceId: ID!
    $companyId: ID!
    $now: DateTime!
  ) {
    assignedExpertServices(
      where: {
        availableUnits_gt: 0
        limitDate_gt: $now
        ode: {
          id: $companyId
          team_some: {
            id: $entrepreneurId
            systemRole: ENTREPRENEUR
          }
        }
        expertService: {
          id: $serviceId
          type: "MENTORSHIP"
        }
      }
    ){
      id
    }
  }
`;
