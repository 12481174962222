export const getContentsFromObjectives = objectives => {
  const contents = objectives.reduce((acc, objective) => {
    objective.achievements.forEach(achievement => {
      achievement.contents.forEach(content => {
        acc.push({
          ...content,
          isComplete: content.odeContents[0]?.isComplete,
          finishDate: content.odeContents[0]?.finishDate,
          objective: objective.name
        });
      });
    });
    return acc;
  }, []);

  return contents;
};