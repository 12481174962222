import React from "react";
import { Form, Typography } from "antd";
import { LinksField } from "../../links-field";
import {
  UploadSection
} from "../../upload-section";

export function AddMultimediaForm({ form }) {

  const getResultAttachments = async e => {
    let attachments = await form.getFieldValue("attachments") || [];

    if (!attachments) {
      attachments = [];
    }
    attachments.push(
      {
        fileName: e.file.name,
        url: e.url,
        type: e.file.type,
        uploadDate: new Date().toISOString()
      }
    );
    form.setFieldsValue({ attachments });
  };

  return (
    <Form
      layout="vertical"
      form={form}>
      <Form.Item
        label="Archivos"
        getValueFromEvent={getResultAttachments}
        name="attachments">
        <UploadSection>
          <Typography.Text style={{ display: "block" }}>
            Adjunta los archivos resultado del servicio
          </Typography.Text>
          <Typography.Text type="secondary">
            Puedes adjuntar: .rar, .zip, .doc, .docx, .pdf, .jpg, .png
          </Typography.Text>
        </UploadSection>
      </Form.Item>
      <Form.Item
        label="o Agrega un enlace aquí"
        name="links">
        <LinksField showUploadArea={false} />
      </Form.Item>
    </Form>
  );
}
