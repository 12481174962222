import React, { useContext } from "react";
import { Avatar, List, Space, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { contentTypes } from "../../../helpers/select-options/content";
import { ActionButton } from "../index";
import { Format } from "../format";
import {
  ObjectiveItemContext
} from "../../../contexts/shared/objective-item/context";
import { CurrentUserContext } from "../../../contexts/shared";
import { ContentDetailsModalContext } from "../../../contexts/shared/content-details-modal/context";
import { Visibility } from "../visibility";


export function ContentItem({ content, onEdit }) {

  const { currentUser } = useContext(CurrentUserContext);

  const { deleteContent } = useContext(ObjectiveItemContext);

  const { onOpen: openContentModal } = useContext(ContentDetailsModalContext);

  const { odeContents } = content;

  const handleOnDelete = content => {
    Modal.confirm({
      okText: "Eliminar",
      cancelText: "Cancelar",
      title: "Advertencia",
      icon: <ExclamationCircleOutlined />,
      content: `Estás a punto de eliminar contenido de este objetivo 
        ¿Deseas continuar?`,
      onOk: () => deleteContent(content)
    });
  };

  const formatedContentTypes = contentTypes.map(type => {
    const label = type.label.split(" - ");
    return {
      ...type,
      label: label[0]
    };
  });

  return (
    <>
      <div
        className="clickable"
        style={{display: "contents"}}
        onClick={() => openContentModal(content.id)}>
        <List.Item.Meta
          avatar={<Avatar style={{ backgroundColor: odeContents[0]?.isComplete ? "#87d068" : "" }}>{content.number}</Avatar>}
          title={content.name} />
        <Space align="start">
          <Format type="date">{content.deliveryDate}</Format>
          <Format type="tag" options={formatedContentTypes}>{content.type}</Format>
        </Space>
      </div>
      <Visibility
        visible={["SUPER_ADMIN", "COORDINATOR"]
          .includes(currentUser.systemRole)}>
        <ActionButton
          onEdit={() => onEdit(content.id)}
          customOnDelete={() => handleOnDelete(content.id)} />
      </Visibility>
    </>
  );
}
