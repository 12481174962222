import React, { useContext } from "react";
import { Typography } from "antd";
import { AddButton } from "../../../../../shared";
import { CreateTaskModalContext } from "../../../../../../contexts/shared/create-task-modal";

export const Head = () => {

  const {openModal} = useContext(CreateTaskModalContext);

  const { Text } = Typography;
  return (
    <>
      <Text>Revisa las tareas que tienes que realizar en el plan de trabajo de tu batch.</Text>
      <AddButton
        fullWidth={true}
        onClick={openModal}
        style={{marginTop: "10px"}}>Agregar tarea</AddButton>
    </>
  );
};

