import React, { useState } from "react";
import { AchievementModalContext } from "./context";
import { AchievementModal } from "./modal";

export function AchievementModalProvider({children}) {
  const [state, setState] = useState({
    isModalOpen: false,
    objectiveId: undefined
  });

  const openModal = objectiveId => {
    setState({ isModalOpen: true, objectiveId: objectiveId });
  };

  const closeModal = () => {
    setState({ isModalOpen: false, objectiveId: undefined });
  };

  const onSave = () => {
    closeModal();
  };

  return (
    <AchievementModalContext.Provider value={{ openModal, closeModal }}>
      <AchievementModal
        visible={state.isModalOpen}
        onCancel={closeModal}
        onSave={onSave}
        objectiveId={state.objectiveId} />
      {children}
    </AchievementModalContext.Provider>
  );
}
