import gql from "graphql-tag";

export const getMasterContentByBatchId = gql`
  query GetMasterContentByBatchId($id: ID!) {
    masterContents(
      where: {
        batch: { id: $id }
      }
    ) {
      id
      contentGroupsSort
      publishedContents {
        id
      }
      contentGroups {
        id
        title
        draft
        description
        contentsSort
        creationConfirmed
        shouldBeDeleted
        contents{
          id
          name
          type
          dueDate
          description
          instructions
          placeType
          placeDirection
          draft
          creationConfirmed
          shouldBeDeleted
          materials {
            id
            fileName
            url
            type
            uploadDate
          }
        }
      }
    }
  }
`;
