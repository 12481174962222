import { Space, Typography, Spin, Popover } from "antd";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { Avatar } from "../avatar";
import { CurrentUserContext } from "../../../contexts/shared";
import { IconLabel } from "../icon-label";
import {
  getReadableValue,
  systemRoleTypes
} from "../../../helpers/select-options";
import { Options } from "./options";

export function UserMenu() {

  const history = useHistory();

  try {
    const {
      currentUser,
      loading,
      error,
      signout
    } = useContext(CurrentUserContext);

    if(loading) {
      return (
        <Spin />
      );
    }

    if(error) {
      return (
        <IconLabel icon={<ExclamationCircleOutlined />}>
          Sesión inválida / Sin conexión
        </IconLabel>
      );
    }

    const onClickProfile = () => {
      switch (currentUser.systemRole) {
        case "ENTREPRENEUR":
          return history.push("/v2/entrepreneur/profile/personal-information");
        case "ADVISER":
          return history.push("/v2/expert/me/profile");
        case "COORDINATOR":
          return history.push("/v2/coordinator/me/profile");
        default:
          break;
      }
    };

    const getManualUrl = () => {
      if (!currentUser.systemRole)
        throw new Error("No system role");

      const coordLink = "https://parqueorion.notion.site/parqueorion/\
            Manual-EOS-ea4944b0960449a6a888dd38200371bb";

      const linksMap = {
        SUPER_ADMIN: coordLink,
        COORDINATOR: coordLink,
        ENTREPRENEUR: "https://parqueorion.notion.site/\
            Gu-a-para-emprendedores-52d8588839a44351afb840a5d545f20e",
        ADVISER: "https://parqueorion.notion.site/\
            Gu-a-para-expertos-84b80b77f7b84d05ab5dafd6e528412b"
      };

      return linksMap[currentUser.systemRole];
    };

    return (
      <Popover
        content={
          <Options
            manualUrl={getManualUrl()}
            onClickProfile={onClickProfile}
            onClickSignout={() => signout()} />
        }>
        <Space
          data-cy="user-session-button"
          direction="horizontal"
          align="center"
          size="small"
          style={{cursor: "pointer"}}>
          <Avatar>{currentUser.profilePicUrl || currentUser.fullName}</Avatar>
          <Space
            direction="vertical"
            size="small"
            align="center">
            <Typography.Text>{currentUser.fullName}</Typography.Text>
            <Typography.Text type="secondary">
              {getReadableValue(systemRoleTypes, currentUser.systemRole)}
            </Typography.Text>
          </Space>
        </Space>
      </Popover>
    );
  }
  catch(e) {
    throw new Error("Parent CurrentUserProvider not detected. " +
      "Wrap <UserMenu> in <CurrentUserProvider>.");
  }
}
