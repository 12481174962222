import gql from "graphql-tag";

/**
 * Entrepreneur rejects the service delivery.
 */
export const rejectScheduledExpertServiceDelivery = gql`
  mutation RejectScheduledExpertService(
    $id: ID!,
    $cancelledBy: String,
    $events: ServiceEventUpdateManyInput
  ) {
    updateScheduledExpertService (
      where: { id: $id }
      data: {
        status: "DELIVERY_REJECTED",
        cancelledBy: $cancelledBy,
        events: $events
      }
    ) {
      id
    }
  }
`;
