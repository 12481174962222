import React from "react";
import {
  Avatar,
  Button,
  Upload,
  Space
} from "antd";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";

export const AvatarUpload = () => {
  return (
    <Space
      align="center"
      direction="vertical"
      style={{ width: "100%" }}>
      <Avatar size={64} icon={<UserOutlined />} />
      <Upload>
        <Button icon={<UploadOutlined />}>Subir fotografía</Button>
      </Upload>
    </Space>
  );
};
