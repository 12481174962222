export const SUPPORTED_DIMENSIONS = {
  companyIndustry: "Company.companyIndustry",
  companyFinancingStage: "Company.companyFinancingStage",
  companySalesStage: "Company.companySalesStage",
  companyBussinessModel: "Company.companyBussinessModel",
  companyProductStage: "Company.companyProductStage",
  companySector: "Company.companySector",
  teamMemberGender: "Company.teamMemberGender",
  ageRange: "Company.ageRange",
  teamMemberOccupation: "Company.teamMemberOccupation",
  companyTechnology: "Company.companyTechnology"
};
