import React, { useEffect, useState } from "react";
import { message } from "antd";
import { debounce } from "lodash";
import { FlexContainer } from "../../flex-container";
import { ResumeByCampusNumeralia } from "./numeralia";
import { ResumeByCampusFilterControls } from "./filters";
import {
  EntrepreneurSpiritService
} from "../../../../services/entrepreneur-spirit";
import { ImpactedCommunityTable } from "./impacted-community-table";
import { CampusAttendancesChart } from "./campus-attendances-chart";
import { CampusInvolvementLevelChart } from "./campus-involvement-level-chart";

export const ResumeByCampus = () => {
  const [persons, setPersons] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [audienceTypes, setAudienceTypes] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState("");
  const [tableData, setTableData] = useState({});
  const [nationalAttendances, setNationalAttendances] = useState([]);
  const [involvementLevelsImpact, setInvolvementLevelsImpact] = useState({
    IMPACT: undefined,
    TOTAL: undefined
  });

  const loadAttendances = debounce(async ({
    endDate,
    audienceTypes,
    startDate,
    selectedCampus
  }) => {
    if (!selectedCampus) {
      setPersons([]);
      setTableData({});
      setExperiences([]);
      setAudienceTypes([]);
      setNationalAttendances([]);
      setInvolvementLevelsImpact({
        IMPACT: undefined,
        TOTAL: undefined
      });
      return;
    }
    setSelectedCampus(selectedCampus);
    setAudienceTypes(audienceTypes);

    const dismissLoader = message.loading("Cargando experiencias...");
    try {
      const experiences = await EntrepreneurSpiritService
        .getCampusExperiences({
          campus: selectedCampus,
          startDate,
          endDate,
          audienceTypes
        });
      setExperiences(experiences);

      const newInvolvementLevelsImpact = await EntrepreneurSpiritService
        .getCampusInvolvementLevelsImpact({
          campus: selectedCampus,
          audienceTypes,
          experience: endDate && startDate
            ? { startDate, endDate }
            : undefined
        });
      setInvolvementLevelsImpact(newInvolvementLevelsImpact);

      const newNationalAverage = await EntrepreneurSpiritService
        .getCampusExperiences({
          startDate,
          endDate,
          audienceTypes
        });
      setNationalAttendances(newNationalAverage);

      const tableData = await EntrepreneurSpiritService
        .getImpactedCommunityTable({
          campus: selectedCampus,
          startDate,
          endDate,
          audienceTypes
        });
      setTableData(tableData);

      message.success("Experiencias cargadas");
    } catch (error) {
      console.error(error);
      message.error("Error al cargar experiencias");
    } finally {
      dismissLoader();
    }
  }, 400);

  useEffect(() => {
    if(!selectedCampus)
      return;
    (async () => {
      try {
        const persons = await EntrepreneurSpiritService
          .getPersons({campus: selectedCampus});
        setPersons(persons);
      } catch (e) {
        console.error(e);
        message.error(`Error ${e}`);
      }
    }
    )();
  }, [selectedCampus]);

  return(
    <FlexContainer
      direction="vertical" style={{ gap: "1rem" }}>
      <ResumeByCampusFilterControls onFilterAttendances={loadAttendances} />
      <ResumeByCampusNumeralia
        persons={persons}
        experiences={experiences}
        audienceTypes={audienceTypes} />
      <FlexContainer style={{ gap: "1rem" }} direction="horizontal">
        <ImpactedCommunityTable tableData={tableData} />
        <CampusInvolvementLevelChart
          involvementLevelsImpact={involvementLevelsImpact} />
      </FlexContainer>
      <CampusAttendancesChart
        experiences={experiences}
        nationalAttendances={nationalAttendances} />
    </FlexContainer>
  );
};
