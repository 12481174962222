import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { withRouter } from "react-router-dom";
import { client } from "../../../../../../graphql";
import { shared } from "../../../../../graphql/shared";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { ContentWrapper, SidebarLayout } from "../../../../../components/shared";
import { Breadcrumbs, Navbar, Pitch } from "../../../../../components/shared/odes/show";
import { SummaryEditSection } from "../../../../../components/shared/ode-forms/summary";
import { EditOdeProvider } from "../../../../../contexts/shared/edit-ode";

function PitchIndex({ match }) {
  const { params = {} } = match;
  const odeId = params?.id;

  const { loading, error, data = {} } = useQuery(
    shared.queries.getOdeById,
    {
      client,
      variables: { id: odeId },
      fetchPolicy: "network-only"
    }
  );

  const { setMenuState } = useContext(MenuContext);

  setMenuState({
    openKeys: ["experts"],
    selectedKeys: ["experts/odes"]
  });

  return (
    <EditOdeProvider
      odeId={odeId}
      data={data}
      loading={loading}
      error={error}>
      <SidebarLayout>
        <Breadcrumbs
          odeName={data?.oDE?.name} />
        <SummaryEditSection
          editable
          data={data}
          isLoading={loading}
          error={error} />
        <Navbar selectedKeys={["pitch"]}  />
        <ContentWrapper>
          <Pitch
            editable
            data={data}
            error={error}
            isLoading={loading} />
        </ContentWrapper>
      </SidebarLayout>
    </EditOdeProvider>
  );
}

export default withRouter(PitchIndex);
