import React, { useContext } from "react";
import {useHistory, useParams} from "react-router-dom";
import { useService } from "../../../../../hooks/shared";
import { HeroPublishBatch, Section } from "../../../../shared";
import { ServicesLayerContext } from "../../../../../contexts/shared";
import {
  HeaderBreadcrumbs
} from "../../../../shared/batch-offer/header-breadcrumbs";
import {
  BatchOfferSmartTabs
} from "../../../../shared/batch-offer/batch-offer-smart-tabs";

export const BatchOfferContent = ({
  defaultActiveTab
}) => {
  const history = useHistory();
  const { id: batchId } = useParams();
  const { BatchesService } = useContext(ServicesLayerContext);

  const {
    data: batch,
    loading,
    error
  } = useService(BatchesService.getSingle, { batchId });

  return (
    <div className="BatchOffer">
      <HeaderBreadcrumbs
        onPrev={() => history.push("/v2/entrepreneur/batches")}
        title="Batches"
        name={batch?.name} />
      <Section className="NewBatch__Publish">
        <HeroPublishBatch
          batch={batch}
          error={error}
          loading={loading}
          className="NewBatch__Publish-Hero" />
        <div id="focus" />
        <BatchOfferSmartTabs
          batch={batch}
          error={error}
          defaultActiveKey={defaultActiveTab} />
      </Section>
    </div>
  );
};

