import gql from "graphql-tag";

export const getInvitationById = gql`
  query GetInvitationById($id: ID!) {
    invitation(where: { id: $id }) {
      id
      email
      redeemed
      systemRole
    }
  }
`;
