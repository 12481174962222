export const EXPERIENCE_LEVEL_LABELS = {
  KNOW: "Conocer",
  LEARN: "Aprender",
  ACT: "Accionar"
};

export const getShareComparisonChartCongif = ({
  involvementNationalData = null, attendances: personData = []
}) => {

  const nationalAverageDatasetData = Object.keys(EXPERIENCE_LEVEL_LABELS)
    .map(key => involvementNationalData?.nationalAverage[key] || 0);

  const nationalAverageDataset = {
    fill: false,
    type: "line",
    borderWidth: 2,
    borderColor: "#95E7DF",
    label: "Promedio Nacional",
    backgroundColor: "#95E7DF",
    data: nationalAverageDatasetData || []
  };

  const maxRecordDatasetData = Object.keys(EXPERIENCE_LEVEL_LABELS)
    .map(key => involvementNationalData?.maxRecord[key] || 0);

  const maxRecordDataset = {
    fill: false,
    type: "line",
    borderWidth: 2,
    borderColor: "#9DD7FB",
    label: "Máximo alcazado",
    backgroundColor: "#9DD7FB",
    data: maxRecordDatasetData || []
  };

  const totalExperiences = personData
    ?.flatMap(attendance => attendance?.experience);


  const getLevelExperienceCount = totalExperiences
    ?.reduce((acc, experience) => {
      const { involvementLevel } = experience;

      if (!involvementLevel) return acc;

      return {
        ...acc,
        [involvementLevel]:
        (acc[involvementLevel] || 0) + 1
      };
    }, {});

  const personDataset = {
    type: "bar",
    label: "Persona",
    barThickness: 50,
    backgroundColor: "rgba(213, 181, 244, .9)",
    data:
      Object.keys(EXPERIENCE_LEVEL_LABELS)
        ?.map(key => getLevelExperienceCount?.[key])
  };

  return {
    data:{
      labels: Object.values(EXPERIENCE_LEVEL_LABELS),
      datasets: [
        nationalAverageDataset,
        maxRecordDataset,
        personDataset
      ]
    },
    options: SHARE_COMPARISON_CHART_OPTIONS
  };
};

const SHARE_COMPARISON_CHART_OPTIONS = {
  scales: {
    x: {
      grid: {
        display: false
      }
    },
    y: {
      grid: {
        borderDash: [2, 2],
        z: 1
      }
    }
  },
  maintainAspectRatio: false
};