import React, { useContext } from "react";
import { Button, List, Row } from "antd";
import {
  CompanyMilestonesModalContext
} from "../../../../../../../contexts/shared/company-milestones-modal";
import { SubTitle } from "../subtitle";
import { MilestoneItem } from "../milestone-list-item";
import { Visibility } from "../../../../..";

export const AchievementsMilestones = ({ data, allowEdit }) => {
  const {
    onCreateOpen,
    onEdit,
    onDelete
  } = useContext(CompanyMilestonesModalContext);

  return (
    <div>
      <Row justify="space-between">
        <SubTitle>Logros</SubTitle>
        <Visibility visible={allowEdit}>
          <Button
            ghost
            onClick={() => onCreateOpen("ACHIEVEMENT")}>Agregar</Button>
        </Visibility>
      </Row>
      {data.length > 0 ?
        <List
          style={{ padding: "16px" }}
          dataSource={data}
          renderItem={achievement => (
            <MilestoneItem
              id={achievement.id}
              title={achievement.name}
              subTitle={achievement.description}
              type={achievement.milestoneType}
              date={achievement.date}
              allowEdit={allowEdit}
              onDelete={onDelete}
              onEdit={() =>
                onEdit(achievement.milestoneType, achievement.id, achievement)}>
              <br />
            </MilestoneItem>
          )} />
        : null
      }
    </div>
  );
};
