import { expert } from "../../../../graphql/expert";

export async function reject(scheduledService, expertId, cancelledBy) {
  return await this.client.mutate({
    mutation: expert.mutations.rejectScheduledService,
    variables: {
      data: {
        scheduledExpertServiceId: scheduledService.id,
        cancelledBy,
        expertId
      }
    }
  });
}
