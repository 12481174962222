import React from "react";
import { Space, Select } from "antd";
import { remove as removeAccents } from "remove-accents";
import { debounce } from "lodash";
import { expertPaymentMethods } from "../../../../../helpers/select-options";
import {
  SearchToolbar,
  ServiceCategoriesDropdown
} from "../../../../../components/shared";

export function FilterControls({ onChange }) {
  const selectStyle = { width: "150px" };

  const handleOnChange = (filter = {}) =>
    onChange && onChange(filter);

  const handleOnSearchChange = debounce(text => {
    const stringValue = removeAccents(text).toLowerCase();
    const value = stringValue || undefined;
    onChange && onChange({ searchString: value });
  }, 500);

  return (
    <Space>
      <ServiceCategoriesDropdown
        onChange={category => handleOnChange({ category })} />
      <Select
        allowClear
        showSearch
        optionFilterProp="label"
        style={selectStyle}
        onChange={method => handleOnChange({ method })}
        options={expertPaymentMethods}
        placeholder="Método de cobro" />
      <SearchToolbar
        onSearchChange={e => handleOnSearchChange(e.target.value)} />
    </Space>
  );
}
