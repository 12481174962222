import React, { useContext } from "react";
import { Form } from "antd";
import { Section, Visibility } from "../../..";
import { EditOdeContext } from "../../../../../contexts/shared/edit-ode";
import { ActionButton } from "../../action-button";
import { PitchForm } from "../form";

export function MiPitchEditSection({ editable = false }) {
  const {
    saveChanges,
    editing,
    setEditing
  } = useContext(EditOdeContext);

  const formatPitchValues = values => {
    return{
      pitch: {
        upsert:{
          update: values,
          create: values
        }
      }
    };
  };

  const onSave = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    const formatedValues = formatPitchValues(values);
    saveChanges(formatedValues, "pitch");
  };

  const [form] = Form.useForm();

  return(
    <Section
      title="Pitch"
      extra={
        <Visibility visible={editable}>
          <ActionButton
            editing={editing.pitch}
            setEditing={setEditing}
            formName="pitch"
            visible
            onSave={onSave}>
          </ActionButton>
        </Visibility>
      }>
      <PitchForm
        form={form}
        editable={editing.pitch && editable} />
    </Section>
  );
}