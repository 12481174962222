import mixpanel from "mixpanel-browser";

const mixPanelToken = process.env.REACT_APP_ENVIRONMENT === "production"
  ? process.env.REACT_APP_MIXPANEL_TOKEN
  : process.env.REACT_APP_MIXPANEL_TOKEN_QA;

mixpanel.init(
  mixPanelToken, {
  debug: process.env.ENVIRONMENT !== "PRODUCTION",
  ignore_dnt: true
});

function track(event = "", metadata = {}) {
  metadata.date = new Date().toISOString();
  mixpanel.track(event, metadata);
}

function setPrefix(obj = {}, preFix = "") {
  const objWithPrefix = {};
  Object.keys(obj).forEach(key => objWithPrefix[`${preFix}_${key}`] = obj[key]);
  return objWithPrefix;
}

/**
 * @param {object} args
 * @param {Product} args.product
 * @param {User} args.user
 */
function trackAssignedService({
  service = {},
  user = {}
}) {
  const event = {
    ...setPrefix(service, "service"),
    ...setPrefix(user, "user")
  };
  track("Servicio asignado", { ...event });
}

/**
 * @param {object} args
 * @param {Content} args.content
 * @param {User} args.user
 */
function trackVisitedContent({
  company = {},
  content = {},
  user = {} }) {
  const event = {
    ...setPrefix(company, "company"),
    ...setPrefix(content, "content"),
    ...setPrefix(user, "user")
  };
  track("Contenido visitado", { ...event });
}

/**
 * @param {object} args
 * @param {Product} args.product
 * @param {User} args.user
 */
function trackConsumedProduct({ //not used
  product = {},
  user = {}
}) {
  const event = {
    ...setPrefix(product, "product"),
    ...setPrefix(user, "user")
  };
  track("Producto consumido", { ...event });
}

/**
 * @param {object} args
 * @param {Product} args.product
 * @param {User} args.user
 */
function trackScheduledProduct({
  product = {},
  user = {}
}) {
  const event = {
    ...setPrefix(product, "product"),
    ...setPrefix(user, "user")
  };
  track(
    "Producto agendado",
    { ...event });
}

/**
 * @param {object} args
 * @param {Company} args.company
 * @param {User} args.createdBy
 * @param {Batch} args.batch
 * @param {"INVITATION" | "MANUAL_CREATION" | "ONBOARDING" | "IMPORTED" } args.creationType
 */
function trackNewCompany({
  company = {},
  createdBy = {},
  batch = {},
  creationType
}) {
  const event = {
    ...setPrefix(company, "company"),
    ...setPrefix(createdBy, "createdBy"),
    ...setPrefix(batch, "batch")
  };
  track(
    "Compañía agregada",
    { ...event, creationType });
}

/**
 * @param {object} args
 * @param {object} args.companies
 * @param {User} args.createdBy
 * @param {Batch} args.batch
 */
function trackCompaniesLinkedToBatch({
  batch = {},
  createdBy = {},
  companies = {}
}) {
  const event = {
    ...setPrefix(batch, "batch"),
    ...setPrefix(createdBy, "createdBy"),
    ...setPrefix(companies, "companies")
  };
  track(
    "Compañía agregada a Batch",
    {
      ...event
    });
}

/**
 * @param {object} args
 * @param {Product} args.product
 * @param {User} args.addedBy
 */
function trackNewProduct({
  product = {},
  addedBy = {}
}) {
  const event = {
    ...setPrefix(product, "product"),
    ...setPrefix(addedBy, "addedBy")
  };
  track(
    "Producto agregado",
    { ...event }
  );
}

/**
 * @param {object} args
 * @param {Batch} args.batch
 * @param {User} args.addedBy
 */
async function trackNewBatch({
  batch = {},
  addedBy = {}
}) {
  const event = {
    ...setPrefix(batch, "batch"),
    ...setPrefix(addedBy, "addedBy")
  };
  track(
    "Batch agregado",
    { ...event }
  );
}

async function trackUpdatedBatch({
  batch = {},
  updatedBy = {}
}) {
  const event = {
    ...setPrefix(batch, "batch"),
    ...setPrefix(updatedBy, "updatedBy")
  };
  track(
    "Batch actualizado",
    { ...event }
  );
}

/**
 * @param {object} args
 * @param {Program} args.program
 * @param {User} args.addedBy
 */
function trackNewProgram({
  program = {},
  addedBy = {}
}) {
  const event = {
    ...setPrefix(program, "program"),
    ...setPrefix(addedBy, "addedBy")
  };
  track(
    "Programa creado",
    { ...event }
  );
}

/**
 * @param {object} args
 * @param {Session} args.session
 * @param {User} args.user
 */
function trackAssignedSession({
  session = {},
  user = {}
}) {
  const event = {
    ...setPrefix(session, "session"),
    ...setPrefix(user, "user")
  };
  track(
    "Sesión asignada",
    { ...event }
  );
}

/**
 * @param {object} args
 * @param {Session} args.session
 * @param {User} args.user
 */
function trackScheduledSession({
  session = {},
  user = {}
}) {
  const event = {
    ...setPrefix(session, "session"),
    ...setPrefix(user, "user")
  };
  track("Sesión agendada", { ...event });
}

/**
 * @param {object} args
 * @param {Session} args.session
 * @param {User} args.deliveredBy
 */
function trackDeliveredSession({
  session = {},
  deliveredBy = {}
}) {
  const event = {
    ...setPrefix(session, "session"),
    ...setPrefix(deliveredBy, "deliveredBy")
  };
  track("Sesión entregada", { ...event });
}

/**
 * @param {object} args
 * @param {Session} args.session
 * @param {User} args.updatedBy
 */
function trackPaidSession({
  session = {},
  updatedBy = {}
}) {
  const event = {
    ...setPrefix(session, "session"),
    ...setPrefix(updatedBy, "updatedBy")
  };
  track("Sesión pagada", { ...event });
}


/**
 * @param {object} args
 * @param {User} args.expert
 * @param {User} args.addedBy
 */
function trackNewExpert({
  expert = {},
  addedBy = {}
}) {
  const event = {
    ...setPrefix(expert, "expert"),
    ...setPrefix(addedBy, "addedBy")
  };
  track(
    "Experto agregado",
    { ...event }
  );
}

/**
 * @param {object} args
 * @param {User} args.entrepreneur
 * @param {User} args.addedBy
 */
function trackNewEntrepreneur({
  entrepreneur = {},
  addedBy = {}
}) {
  const event = {
    ...setPrefix(addedBy, "addedBy"),
    ...setPrefix(entrepreneur, "entrepreneur")
  };
  track(
    "Emprendedor agregado",
    { ...event }
  );
}

async function trackNewUser({
  user = {}
}) {
  user = JSON.parse(JSON.stringify(user));
  delete user.password;

  const event = {
    $created: (new Date()).toISOString(),
    created_at: (new Date()).toISOString(),
    ...setPrefix(user, "user")
  };
  track("Usuario creado", { ...event });
  await registerUser(user);
}

async function trackAcceptedInvitationUser({
  user = {}
}) {
  user = JSON.parse(JSON.stringify(user));
  delete user.password;

  const event = {
    ...setPrefix(user, "user"),
    accepted_at: (new Date()).toISOString()
  };
  track("Invitación aceptada", { ...event });
  await registerUser(user);
}

async function registerUser({
  id,
  email,
  fullName,
  systemRole,
  ...customFields
}) {
  customFields = JSON.parse(JSON.stringify(customFields));
  delete customFields.password;

  await mixpanel.identify(id);
  await mixpanel.people.set(
    {
      $id: id,
      $email: email,
      $name: fullName,
      $systemRole: systemRole,
      created_at: (new Date()).toISOString(),
      ...(customFields || {})
    }
  );
}

async function updateRegisteredUser({
  id,
  ...fields
}) {
  fields = JSON.parse(JSON.stringify(fields));
  delete fields.password;

  const updated_at = (new Date()).toISOString();
  await mixpanel.identify(id);
  await mixpanel.people.set(
    {
      $id: id,
      updated_at,
      ...(fields || {})
    }
  );
}

export const UsageTrackingService = {
  track,
  registerUser,
  updateRegisteredUser,
  trackVisitedContent,
  trackAssignedService,
  trackConsumedProduct, // not used
  trackScheduledProduct,
  trackNewCompany,
  trackCompaniesLinkedToBatch,
  trackNewUser,
  trackNewExpert,
  trackNewEntrepreneur,
  trackAcceptedInvitationUser,
  trackNewProduct,
  trackNewBatch,
  trackUpdatedBatch,
  trackNewProgram,
  trackAssignedSession,
  trackScheduledSession,
  trackDeliveredSession,
  trackPaidSession
};

/**
 * @typedef {Object} Product
 * @property {string} product_id
 * @property {string} product_name
 */

/**
 * @typedef {Object} Session
 * @property {string} session_id
 * @property {string} session_name
 * @property {string} session_type
 */

/**
 * @typedef {Object} User
 * @property {string} id
 * @property {string} fullName
 */

/**
 * @typedef {Object} Batch
 * @property {string} batch_id
 * @property {string} batch_name
 */

/**
 * @typedef {Object} Company
 * @property {string} company_id
 * @property {string} company_name
 */

/**
 * @typedef {Object} Content
 * @property {string} content_id
 * @property {string} content_name
 */
