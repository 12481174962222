import { message } from "antd";
import { CompanyService } from "../services/company-service";

export const useCompany = () => {

  const createCompany = async ({ data, afterSuccess }) => {
    try {
      await CompanyService.createCompany({ company: data });
      afterSuccess && await afterSuccess();
    } catch (e) {
      message.error("Sucedio un error, intentelo de nuevo");
      console.error(e);
    }
  };

  const updateCompany = async ({ data, odeId, afterSuccess }) => {
    try {
      await CompanyService.updateCompany({ company: data, odeId });
      afterSuccess && await afterSuccess();
    } catch (e) {
      message.error("Sucedio un error, intentelo de nuevo");
      console.error(e);
    }
  };

  const createMilestone = async ({ data, odeId, afterSuccess }) => {
    try {
      await CompanyService.createMilestone({ milestone: data, odeId });
      afterSuccess && await afterSuccess();
    } catch (e) {
      message.error("Sucedio un error, intentelo de nuevo");
      console.error(e);
    }
  };

  const validateOriginalName = async ({ name }) => {
    try {
      const resp = await CompanyService.getCompaniesWithSameName({ name });
      return resp.data.oDEs.length === 0;
    } catch (e) {
      message.error("Sucedio un error, intentelo de nuevo");
      console.error(e);
    }
  };

  const updateMilestone = async ({ data, odeId, milestoneId, afterSuccess }) => {
    try {
      await CompanyService.updateMilestone({ milestone: data, odeId, milestoneId });
      afterSuccess && await afterSuccess();
    } catch (e) {
      message.error("Sucedio un error, intentelo de nuevo");
      console.error(e);
    }
  };

  const deleteMilestone = async ({ milestoneId, odeId, afterSuccess }) => {
    try {
      await CompanyService.deleteMilestone({ milestoneId, odeId });
      afterSuccess && await afterSuccess();
    } catch (e){
      message.error("Sucedio un error, intentelo de nuevo");
      console.error(e);
    }
  };

  const transformOdeToCompany = async ({ odeId }) => {
    try {
      await CompanyService.transformOdeToCompany({ odeId });
    } catch(e) {
      message.error("Sucedio un error, intentelo de nuevo");
      console.error(e);
    }
  };

  return {
    createCompany,
    updateCompany,
    updateMilestone,
    createMilestone,
    deleteMilestone,
    validateOriginalName,
    transformOdeToCompany
  };
};
