import * as XLSX from "xlsx";

export const getJsonFromExcel = file => new Promise(resolve => {
  const fileReader = new FileReader();

  fileReader.onload = e => {
    const data = e.target.result;
    const workbook = XLSX.read(data, { type: "binary" });
    const sheetsAsJson = workbook.SheetNames.map(sheet => {
      return XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
    });
    resolve(sheetsAsJson);
  };

  fileReader.readAsBinaryString(file);
});
