import React, { useState } from "react";
import ReactExport from "react-export-excel";
import { Button, Form, Input, message } from "antd";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function ReportingTool() {
  const [state, setState] = useState({
    datasource: [],
    columns: [],
    sheetName: "Report generated with the EOS Reporting Tool"
  });

  const [form] = Form.useForm();

  const onProccessData = () => {
    try {
      const values = form.getFieldsValue();

      const columns = values.columns
        ? JSON.parse(values.columns) : state.columns;
      const datasource = values.datasource
        ? JSON.parse(values.datasource) : state.datasource;

      setState({
        ...values,
        columns,
        datasource
      });

      message.success("datasource processed successfully");
    } catch (e) {
      console.error(e);
      message.error(e.message);
    }
  };

  return (
    <div style={{ minHeight: "100vh" }}>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          name="sheetName"
          label="Sheet Name">
          <Input placeholder="Sheet Name" />
        </Form.Item>
        <Form.Item
          name="columns"
          label="Columns definition">
          <Input.TextArea
            placeholder={"[{ \"field\": \"keyValue\", \"label\": \"column name\" }]"} />
        </Form.Item>
        <Form.Item
          name="datasource"
          label="Data Source">
          <Input.TextArea
            placeholder="Paste a well formatted json array" />
        </Form.Item>
        <Button onClick={onProccessData}>
          Procesar
        </Button>
      </Form>
      <ExcelFile>
        <ExcelSheet data={state.datasource} name={state.sheetName}>
          {state.columns.map((col, idx) => (
            <ExcelColumn
              key={idx.toString()}
              label={col.label}
              value={col.field} />
          ))}
        </ExcelSheet>
      </ExcelFile>
    </div>
  );
}
