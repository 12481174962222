import { value } from "numeral";
import React, { useState } from "react";
import { ContentListAlternativeViewContext } from "./context";

export function ContentListAlternativeViewProvider({ children }) {

  const [state, setState] = useState({
    listView: true,
    tableView: false
  });

  const handleRadioClick = viewType => {
    switch (viewType) {
      case "list":
        setState({
          listView: true,
          tableView: false
        });
        break;

      case "table":
        setState({
          listView: false,
          tableView: true
        });
        break;

      default:
        setState({
          listView: true,
          tableView: false
        });
    }
  };

  return (
    <ContentListAlternativeViewContext.Provider
      value={{
        listViewEnabled: state.listView,
        tableViewEnabled: state.tableView,
        handleRadioClick
      }}>
      { children }
    </ContentListAlternativeViewContext.Provider>
  );
}