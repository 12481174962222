import React from "react";
import { DatePicker, TimePicker, Row, Form, Input } from "antd";

export const ScheduleExpertServiceForm = ({
  form,
  readOnly = false
}) => {
  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: "100%", marginTop: "1rem" }}>
      <Row>
        <Form.Item
          label="Fecha"
          name="serviceDate"
          style={{ marginRight: "27px" }}
          rules={[{ required: true }]}>
          <DatePicker
            disabled={readOnly}
            style={{ width: "235px" }}
            format="DD/MM/YYYY" />
        </Form.Item>
        <Form.Item
          label="Hora"
          name="serviceHour"
          style={{ marginRight: "27px" }}
          rules={[{ required: true }]}>
          <TimePicker
            disabled={readOnly}
            showSecond={false}
            showNow={false}
            style={{ width: "235px" }}
            format="HH:mm" />
        </Form.Item>
      </Row>
      <Form.Item
        name="comments"
        label="Cuéntanos un poco más sobre tu necesidad.">
        <Input.TextArea
          disabled={readOnly}
          placeholder="Por ejemplo: Ayuda para entender y segmentar mejor el mercado."
          rows={4} />
      </Form.Item>
    </Form>
  );
};
