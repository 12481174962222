import React, { useEffect, useContext } from "react";
import { SidebarLayout, Section } from "../../../../../components/shared";
import { Summary } from "../../../../../components/admin/batches/show/summary";
import {
  default as Navbar
} from "../../../../../components/admin/batches/show/navbar";
import {
  default as ExpertsList
} from "../../../../../components/admin/batches/show/experts/list";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import { admin } from "../../../../../graphql/admin";
import { useParams } from "react-router";
import { BatchDetailsContextProvider } from "../../../../../contexts/admin/batches/batch-details";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { ConnectUserToBatchProvider } from "../../../../../contexts/shared";
import { MainMenu } from "../../../../../components/coordinator/shared";

export function ExpertsIndex() {
  const { id } = useParams();

  const { loading, error, data } = useQuery(
    admin.queries.getExpertsByBatchId,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      client
    }
  );

  const { setMenuState } = useContext(MenuContext);

  useEffect(() => {
    /* set menu's current state */
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  /* TODO: Create a batch/program detail context provider to set the layout and
   * load and manage the data */

  const {
    loading: batchLoading,
    error: batchError,
    data: batchData
  } = useQuery(
    admin.queries.getBatchById,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      client
    }
  );

  return (
    <BatchDetailsContextProvider>
      <ConnectUserToBatchProvider systemRole="ADVISER">
        <SidebarLayout menu={<MainMenu />}>
          <Summary
            isLoading={batchLoading}
            error={batchError}
            data={batchData} />
          <Navbar selectedKeys={["experts"]} />
          <Section title="Expertos" spaced>
            <ExpertsList
              data={data}
              error={error}
              isLoading={loading} />
          </Section>
        </SidebarLayout>
      </ConnectUserToBatchProvider>
    </BatchDetailsContextProvider>
  );
}
