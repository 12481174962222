import React, { useContext } from "react";
import { List, Form, Button, Modal } from "antd";
import { SendOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { withLoader } from "../../../../../helpers";
import { DraggerUploadArea } from "../../../dragger-upload-area";
import { EditOdeContext } from "../../../../../contexts/shared/edit-ode";
import { convertFromFileList } from "../../../../../helpers/convert-from-file-list";
import { FileItem } from "./file-item";

function MyDocuments() {

  const {
    data,
    saveChanges
  } = useContext(EditOdeContext);

  const formatFilesValues = fileList => {
    return {
      files: {
        create: fileList
      }
    };
  };

  const handleSaveWithoutFiles = () => {
    Modal.info({
      icon: <ExclamationCircleOutlined />,
      title: "Advertencia",
      content: "No se puede enviar si no hay archivos"
    });
  };

  const onSave = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();
    if (!values.files) {
      handleSaveWithoutFiles();
    } else {
      const fileList = convertFromFileList(values?.files.fileList);
      const files = formatFilesValues(fileList);
      saveChanges(files, "files");
      form.resetFields();
    }
  };

  const onDelete = async id => {
    saveChanges({
      files: {
        delete: { id: id }
      }
    }, "files");
  };

  const [form] = Form.useForm();

  return (
    <>
      <Form
        form={form}
        layout="vertical">
        <Form.Item
          name="files">
          <DraggerUploadArea>
            <p>Haga clic o arrastre el documento a esta área para cargar</p>
          </DraggerUploadArea>
        </Form.Item>
      </Form>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end"
        }}>
        <Button
          type="secundary"
          style={{ marginBottom: "20px" }}
          icon={<SendOutlined />}
          onClick={onSave}>
          Enviar documentos
        </Button>
      </div>
      <List
        dataSource={data?.oDE?.files}
        renderItem={file =>
          <FileItem
            item={file}
            onDelete={onDelete} />
        } />
    </>
  );
}

export default withLoader(MyDocuments);
