import React from "react";
import {
  ProgramSummary
} from "../../../../../components/admin/programs/program-summary";
import { useQuery } from "@apollo/react-hooks";
import { admin } from "../../../../../graphql/admin";
import {
  default as ObjetivesList
} from "../../../../../components/admin/programs/objectives/list";
import { client } from "../../../../../../graphql";
import { useParams } from "react-router";
import { ObjectiveModalProvider } from "../../../../../contexts/shared/objective-modal";
import { SidebarLayout } from "../../../../../components/shared";

export function ProgramObjectivesIndex() {
  const { id } = useParams();
  const { loading, error, data } = useQuery(
    admin.queries.getObjetivesByProgramId,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      client
    }
  );


  return (
    <SidebarLayout>
      <ObjectiveModalProvider>
        <ProgramSummary selectedKeys={["objectives"]} />
        <ObjetivesList
          data={data}
          isLoading={loading}
          error={error} />
      </ObjectiveModalProvider>
    </SidebarLayout>
  );
}
