import React, { useContext } from "react";
import { Form } from "antd";
import {
  CurrentUserContext,
  EditProfileContext
} from "../../../../../contexts/shared";
import { EditPersonalInfoForm } from "../form";
import { Details } from "../summary-details";
import { Section } from "../../../section";
import { Visibility } from "../../../visibility";
import { ActionButtonForm } from "../../action-button-form";
import { formatValues } from "../form/format-values";
import { EditButton } from "../../../edit-button";
import { MissingProfileDataAlert } from "../../../missing-profile-data-alert";
import {
  getUncompletedEntrepreneurProfileData
} from "../../../../../helpers";

export function SummaryEditSection({ editable = false }) {
  const [form] = Form.useForm();
  const {
    data,
    saveChanges,
    editing,
    setEditing
  } = useContext(EditProfileContext);
  const { refetchCurrentUser } = useContext(CurrentUserContext);

  const save = async () => {
    await form.validateFields();
    const values = form?.getFieldsValue();
    const dataToSave = formatValues({
      values,
      hasExpertProfile: !!data?.user?.expertProfile
    });

    await saveChanges(dataToSave, "summary");
    await refetchCurrentUser();
  };

  const missingData = getUncompletedEntrepreneurProfileData(data?.user);

  return (
    <Section
      spaced
      title={<MissingProfileDataAlert
        missingInfo={missingData.personalInfo}>
        Información personal
      </MissingProfileDataAlert>}
      extra={
        <Visibility visible={editable && !editing?.summary}>
          <EditButton
            onClick={() => setEditing(true, "summary")}>
            Editar
          </EditButton>
        </Visibility>
      }
      bottom={
        <Visibility visible={editable && editing?.summary}>
          <ActionButtonForm
            setEditing={setEditing}
            formName="summary"
            onSave={save} />
        </Visibility>}>
      {editing?.summary
          ? <EditPersonalInfoForm form={form} />
          : <Details />}
    </Section>
  );
}
