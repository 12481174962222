import { expert } from "../../../../graphql/expert";

export async function refundAssignedUnits({
  assignedServiceId,
  units
}) {
  return await this.client.mutate({
    mutation: expert.mutations.refundScheduledUnits,
    variables: {
      id: assignedServiceId,
      units: Number(units)
    }
  });
}
