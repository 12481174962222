export function entrepreneursOccupationToChart (items = []) {
  const datasets = [];

  items.forEach((item, idx) => {
    const data = [];
    data.push(parseInt(item["Company.entrepreneursCount"]));

    datasets.push({
      label: item["Company.teamMemberOccupation"],
      data,
      backgroundColor: PALE_COLORS_SERIES[idx],
      barThickness: 50
    });
  });

  return {
    labels: ["Ocupación"],
    datasets
  };
}

const PALE_COLORS_SERIES = [
  "#5b8ff9",
  "#5ad8a6",
  "#5e7092",
  "#f6bd18",
  "#6f5efa",
  "#6ec8ec",
  "#945fb9",
  "#ff9845",
  "#299796",
  "#fe99c3",
  "#5bdff9",
  "#5ad87a",
  "#5e8692",
  "#f68318",
  "#5e6efa",
  "#6eecdf",
  "#b95f8b",
  "#45ff48",
  "#177170",
  "#52152e"
];