import gql from "graphql-tag";

export const upsertTask = gql`
  mutation UpsertTask(
    $id: ID
    $create: TaskCreateInput!,
    $update: TaskUpdateInput!
  ) {
    upsertTask(
      where: { id: $id }
      update: $update
      create: $create
    ) {
      id
    }
  }
`;
