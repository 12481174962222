export const TECHNOLOGIES_CHART_OPTIONS = {
  barPercentage: 0.7,
  categoryPercentage: .7,
  indexAxis: "y",
  borderWidth: 1,
  borderColor: "#fff",
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      color: "#000"
    }
  },
  scales: {
    x: {
      padding: 5,
      stacked: true,
      ticks: {
        stepSize: 20,
        font: {
        }
      }
    },
    y: {
      stacked: true,
      fontSize: 5
    }
  },
  maintainAspectRatio: false
};

