import React, { useContext } from "react";
import { Layout, Button, Space } from "antd";
import { UserMenu } from "../user-menu";
import { Visibility } from "../visibility";
import { WorkspacesMenu } from "../workspaces-menu";
import Icon from "@ant-design/icons";
import { ReactComponent as Logo } from "../../../images/logo.svg";
import { NotificationCenter } from "../notification-center";
import { CurrentUserContext } from "../../../contexts/shared";
import { MainMenu as AdminMenu } from "../../../components/admin/shared";
import { MainMenu as CoordMenu } from "../../../components/coordinator/shared";
import { MainMenu as ExpertMenu } from "../../../components/expert/shared";
import {
  MainMenu as EntrepreneurMenu
} from "../../../components/entrepreneur/shared";

export function SidebarLayout({
  children,
  hideSidebar,
  hideOptions = false,
  showLogo = false,
  ...props
}) {
  const { currentUser } = useContext(CurrentUserContext);
  const menus = {
    ADVISER: <ExpertMenu />,
    SUPER_ADMIN: <AdminMenu />,
    COORDINATOR: <CoordMenu />,
    ENTREPRENEUR: <EntrepreneurMenu />
  };

  const menu = menus[currentUser.systemRole];

  return (
    <Layout
      style={{height: "100%", overflow: "hidden"}}
      {...props}>
      <Visibility visible={!hideSidebar}>
        <Layout.Sider>
          <div className="sidebar-menu">
            <div>
              <Icon
                component={Logo}
                style={{
                  fontSize: "55px",
                  display: "block",
                  marginBottom: "10px"
                }} />
            </div>
            <div><WorkspacesMenu /></div>
            <div style={{ flexGrow: "1" }}>{menu}</div>
            <Visibility
              visible={["SUPER_ADMIN"].includes(currentUser.systemRole)}>
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={() =>
                    window.location.href = "https://v1.eossolution.io/"
                  }
                  type="link">Ir a la versión 1</Button>
              </div>
            </Visibility>
          </div>
        </Layout.Sider>
      </Visibility>
      <Layout>
        <Layout.Header className="userbar" style={{textAlign: "right"}}>
          <Visibility visible={showLogo}>
            <Logo />
          </Visibility>
          <Visibility visible={!hideOptions}>
            <div>
              <Space>
                <NotificationCenter />
                <UserMenu />
              </Space>
            </div>
          </Visibility>
        </Layout.Header>
        <Layout.Content
          className="main-content"
          style={{overflow: "hidden auto"}}>
          { children }
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
