import React, { useCallback, useMemo } from "react";
import { useService } from "../../../hooks/shared";
import { OdesService } from "../../../services";
import { BatchOdesContext } from "../../../contexts/admin/batches";
import {
  ContentDetailsModalContextProvider,
  ContentGroupModalContextProvider,
  ContentModalContextProvider,
  ContentViewSwitchProvider, DraggableListProvider
} from "../../../contexts/shared";
import BatchOdesContents from "../../admin/batches/show/odes/contents";
import { useMutation } from "@apollo/react-hooks";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql";
import { message } from "antd";

export function OdeContents({ batchId, companyId }) {
  const { data: published, refetch: refetchPublished } = useService(
    OdesService.getPublishedContentsService,
    { batchId, companyId }
  );

  const { data: custom, loading, error, refetch: refetchCustom } = useService(
    OdesService.getCustomOdeContentsService,
    { batchId, companyId }
  );

  const [createCustomOdeContent] = useMutation(
    shared.mutations.createCustomOdeContent, { client }
  );

  const [updateCustomOdeContent] = useMutation(
    shared.mutations.updateCustomOdeContent, { client }
  );

  const [updateContentGroup] = useMutation(
    shared.mutations.updateContentGroup, { client }
  );

  const onUpdateCustomOdeContent = useCallback(async create => {
    if (custom?.customOdeContents?.[0]) {
      await updateCustomOdeContent({
        variables: {
          where: { id: custom?.customOdeContents?.[0]?.id },
          data: { contentGroups: { create } }
        }
      });
    } else {
      await createCustomOdeContent({
        variables: {
          data: {
            contentGroups: { create },
            batch: { connect: { id: batchId } },
            ode: { connect: { id: companyId } }
          }
        }
      });
    }

    await refetch();
  }, [custom]);

  const onUpdateContentGroup = useCallback(async (groupId, values) => {
    await updateContentGroup({
      variables: { where: { id: groupId }, data: values }
    });
    await refetch();
  }, [custom]);

  const onUpdateContentSorted = useCallback(async (uuids, groupId) => {
    if (!groupId) {
      await updateCustomOdeContent({
        variables: {
          where: { id: custom?.customOdeContents?.[0]?.id },
          data: { contentGroupsSort: { set: uuids } }
        }
      });
    } else {
      await updateContentGroup({
        variables: {
          where: { id: groupId },
          data: { contentsSort: { set: uuids } }
        }
      });
    }
    await refetch();
    message.success("Ordenamiento guardado.");
  }, [custom]);

  const refetch = async () => {
    await refetchCustom();
    await refetchPublished();
  };

  const injectActions = useMemo(() => ({
    data: custom,
    error,
    loading,
    refetch,
    published,
    batchId: batchId,
    isDragable: false
  }), [custom, loading, published]);

  return (
    <DraggableListProvider onUpdate={onUpdateContentSorted}>
      <BatchOdesContext.Provider value={injectActions}>
        <ContentGroupModalContextProvider
          onSave={onUpdateCustomOdeContent}
          onUpdate={onUpdateContentGroup}>
          <ContentModalContextProvider batchId={batchId} refetch={refetch}>
            <ContentDetailsModalContextProvider
              batchId={batchId}
              odeId={companyId}
              refetch={refetch}>
              <ContentViewSwitchProvider>
                <BatchOdesContents />
              </ContentViewSwitchProvider>
            </ContentDetailsModalContextProvider>
          </ContentModalContextProvider>
        </ContentGroupModalContextProvider>
      </BatchOdesContext.Provider>
    </DraggableListProvider>
  );
}
