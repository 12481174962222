import React from "react";
import { Col, Statistic, Row, Space } from "antd";
import numeral from "../../../../../helpers/numeral";
import { getReadableValue } from "../../../../../helpers/select-options";
import { annualSalesOptions } from "../../../company-forms/general/select-options/annual-sales";
import { technologyLevelStatusOptions } from "../../../../../helpers/select-options/company/latest/technology-level-status";

export function CompanyStatisticsIndex({ data }) {
  const { employeesAmount = 0, annualSales = 0, milestones = []  } = data || {};

  const amounts = milestones.reduce((prev, current) => {
    if (
      (current.milestoneType === "FUND" && current.fundType !== "AWARD")
      || current.milestoneType === "COMPETENCE"
    ) {
      prev[`founds${current.currency}`] += parseInt(current.amount);
    } else {
      prev[`awards${current.currency}`] += parseInt(current.amount);
    }

    return prev;
  }, { foundsMXN: 0, foundsUSD: 0, awardsMXN: 0, awardsUSD: 0 });

  const getTitle = (mxn = 0, usd = 0) => {
    mxn = numeral(mxn).format("0a");
    usd = numeral(usd).format("0a");
    return <p>${mxn}<span> MXN</span> ${usd}<span> USD</span></p>;
  };

  const getSales = (mxn = 0) => {
    mxn = numeral(mxn).format("0a");
    return <p>${mxn}<span> MXN</span></p>;
  } ;

  return (
    <Space className="Statistic">
      <Row gutter={24}>
        <Col span={6}>
          <Statistic
            title={getReadableValue(annualSalesOptions, annualSales, getSales(0))}
            value="Ventas anuales" />
        </Col>
        <Col span={6}>
          <Statistic
            title={getTitle(amounts?.foundsMXN, amounts?.foundsUSD)}
            value="Fondos y premios acumulados" />
        </Col>
        <Col span={6}>
          <Statistic title={employeesAmount || "0"} value="Empleados" />
        </Col>
        <Col span={6}>
          <Statistic
            title={getReadableValue(technologyLevelStatusOptions, data?.technologyLevel)}
            value="Nivel de TRL" />
        </Col>
      </Row>
    </Space>
  );
}
