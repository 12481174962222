import React from "react";
import { Space } from "antd";
import { remove as removeAccents } from "remove-accents";
import { debounce } from "lodash";
import { SearchToolbar, ProgramsSelect } from "../../../../components/shared";

export function FilterControls({ onChange }) {

  const handleOnSearchChange = debounce((iname = "") => {
    const stringValue = removeAccents(iname).toLowerCase();
    const value = stringValue || undefined;

    onChange && onChange({ iname: value });
  }, 500);

  const handleOnProgramChange = value => {
    onChange && onChange({ programId: value });
  };

  return (
    <Space>
      <ProgramsSelect
        onChange={handleOnProgramChange}
        style={{ width: "300px" }} />
      <SearchToolbar
        onSearchChange={e => handleOnSearchChange(e.target.value)} />
    </Space>
  );
}
