import React from "react";
import { Card, Col, Typography } from "antd";
import { useNumeraliaByPerson } from "../../../../../hooks";

export const ResumeByPersonNumeralia = ({
  windowSize,
  attendances,
  selectedPerson
}) => {
  const {
    know,
    learn,
    act
  } = useNumeraliaByPerson(attendances || []);

  return(
    <>
      <Col xs={12} lg={6}>
        <Card
          bordered
          style={cardStyles}
          bodyStyle={bodyStyle}>
          <Typography.Text
            type="secondary"
            style={{ fontSize: titleFontSizes[windowSize] }}>
            Nombre
          </Typography.Text>
          <Typography.Title
            level={5}
            style={{ marginTop: 0, fontSize: contentFontSizes[windowSize] }}>
            {selectedPerson || "-"}
          </Typography.Title>
        </Card>
      </Col>
      <Col xs={12} lg={6}>
        <Card
          style={cardStyles}
          bodyStyle={bodyStyle}>
          <Typography.Text
            type="secondary"
            style={{ fontSize: titleFontSizes[windowSize] }}>
            Conocer
          </Typography.Text>
          <Typography.Title
            level={5}
            style={{ marginTop: 0, fontSize: contentFontSizes[windowSize] }}>
            {know}
          </Typography.Title>
        </Card>
      </Col>
      <Col xs={12} lg={6}>
        <Card
          style={cardStyles}
          bodyStyle={bodyStyle}>
          <Typography.Text
            type="secondary"
            style={{ fontSize: titleFontSizes[windowSize] }}>
            Aprender
          </Typography.Text>
          <Typography.Title
            level={5}
            style={{ marginTop: 0, fontSize: contentFontSizes[windowSize] }}>
            {learn}
          </Typography.Title>
        </Card>
      </Col>
      <Col xs={12} lg={6}>
        <Card
          style={cardStyles}
          bodyStyle={bodyStyle}>
          <Typography.Text
            type="secondary"
            style={{ fontSize: titleFontSizes[windowSize] }}>
            Accionar
          </Typography.Text>
          <Typography.Title
            level={5}
            style={{ marginTop: 0, fontSize: contentFontSizes[windowSize] }}>
            {act}
          </Typography.Title>
        </Card>
      </Col>
    </>
  );
};

const cardStyles = {
  height: 120,
  border: "solid 1px #5F39DE",
  fontSize: "12px"
};

const bodyStyle = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly"
};

const titleFontSizes = {
  "MEDIUM": "14px",
  "LARGE": "14px",
  "EXTRA_LARGE": "14px",
  "EXTRA_EXTRA_LARGE": "14px"
};

const contentFontSizes = {
  "MEDIUM": "14px",
  "LARGE": "13px",
  "EXTRA_LARGE": "15px",
  "EXTRA_EXTRA_LARGE": "21px"
};