import gql from "graphql-tag";

export const deliverManyScheduledServices = gql`
  mutation DeliverManyScheduledServices(
    $where: ScheduledExpertServiceWhereInput!,
    $data: ScheduledExpertServicesUpdateManyWithNestedEntitiesInput!
  ) {
    deliverManyScheduledServices (
      where: $where,
      data: $data
    ) {
      count
    }
  }
`;
