import gql from "graphql-tag";

export const resetPassword = gql`
  mutation ResetPassword($token: ID!, $password: String!) {
    resetPassword(
      where: {
        passwordResetRequest: {
          id: $token
        }
      },
      data: {
        password: $password
      }
    ) {
      count
    }
  }
`;
