import React from "react";
import { OnboardingLayout } from "../../../../components/shared";
import { OnBoardingContextProvider } from "../../../../contexts/expert/onboarding";
import { Success } from "../../../../components/expert/onboarding";

export function SuccessIndex() {
  return (
    <OnBoardingContextProvider>
      <OnboardingLayout>
        <Success />
      </OnboardingLayout>
    </OnBoardingContextProvider>
  );
}
