import React, { useState, useEffect } from "react";
import { CurrentUserContext } from "./context";
import { shared } from "../../../graphql/shared";
import { DataConnectionService } from "../../../services";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { UsageTrackingService } from "../../../services";
const { graphqlApolloClient } = DataConnectionService;

export function CurrentUserProvider({ children }) {
  const history = useHistory();

  const [state, setState] = useState({
    currentUser: {
      id: window.localStorage.getItem("id")
    },
    currentWorkspace: {
      id: window.localStorage.getItem("workspace")
    },
    loading: true,
    error: false
  });

  const [currentUserState, setCurrentUserState] = useState({
    error: null,
    data: null,
    loading: false
  });

  const loadCurrentUser = async () => {
    const storedUserId = window.localStorage.getItem("id");
    if (!storedUserId) {
      setState(prevState => ({
        ...prevState,
        error: null,
        loading: false
      }));
      return;
    }

    setCurrentUserState(prev => ({ ...prev, loading: true }));
    try {
      const { data } = await graphqlApolloClient.query({
        query: shared.queries.getUserById,
        fetchPolicy: "network-only",
        variables: { id: storedUserId }
      });
      setCurrentUserState(prev => ({
        ...prev,
        data,
        loading: true
      }));

      setCurrentUserDataToState({ data, loading: false, error: null });
    } catch (e) {
      setCurrentUserState(prev => ({
        ...prev,
        error: e,
        loading: false
      }));
      setCurrentUserDataToState({ data: null, loading: false, error: e });
    }
  };

  useEffect(() => {
    loadCurrentUser();
  }, []);

  const signout = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };

  const setWorkspace = id => {
    window.localStorage.setItem("workspace", id);

    const newWorkspace = state.currentUser.workspaces.find(workspace => {
      return workspace.id === id;
    });

    setState({ ...state, currentWorkspace: newWorkspace });
  };

  const setCurrentUserDataToState = ({ data, loading, error }) => {
    setState(prevState => {
      const newState = {
        ...prevState,
        loading,
        error
      };

      if (data?.user) {
        try {
          UsageTrackingService.registerUser(data.user);
        } catch (e) {
          console.error(e);
        }
        newState.currentUser = data.user;
        newState.currentUser.completedProfile =
          data?.userProfileCompletion?.completed;
        newState.currentUser.odes = data?.odes;

        newState.currentUser.workspaces = data?.workspaces?.map(workspace => {
          const roles = workspace.memberships
            .map(membership => membership.role);

          workspace.roles = roles;

          return workspace;
        }) || [];

        if(newState.currentWorkspace.id !== null) {
          const workspace = newState.currentUser.workspaces.find(workspace => {
            return workspace.id === newState.currentWorkspace.id;
          });

          const role = _.first(workspace.roles);

          if(role) {
            newState.currentUser.systemRole = role;
          }

          newState.currentWorkspace = workspace;
        } else if(newState.currentUser.workspaces.length) {
          newState.currentWorkspace = _.first(newState.currentUser.workspaces);
          window.localStorage.setItem("workspace", newState.currentWorkspace.id);
        }
      }

      return newState;
    });
  };

  useEffect(() => {
    const data = currentUserState.data;

    if (data) {
      const { completedProfile, odes } = data;
      const userId = data.user?.id;

      const allowedRoutesUncompletedProfile = [
        "/v2/entrepreneur/profile/personal-information",
        "/v2/entrepreneur/odes",
        `/v2/invitations/entrepreneur/${userId}/company`,
        `/v2/invitations/entrepreneur/${userId}/update-information`,
        `/v2/invitations/entrepreneur/${userId}/welcome`
      ];

      if (// redirect the entrepreneur to the company selector
        // if has not an =y company
        data.user.systemRole === "ENTREPRENEUR"
        && !data.user.teamOdes.length
        && !allowedRoutesUncompletedProfile.includes(history.location?.pathname)
      ) {
        return history
          .push(`/v2/invitations/entrepreneur/${data.user.id}/company`);
      }
      // scape if there is no data to be validated yet
      if ([completedProfile, odes].includes(undefined)) return;

      const uncompletedProfile =
        !data?.user?.completedProfile || !data?.user?.odes?.length;

      if (
        data?.user?.systemRole === "ENTREPRENEUR"
        && uncompletedProfile
        && !allowedRoutesUncompletedProfile.includes(history.location?.pathname)
      ) {
        history.push("/v2/entrepreneur/profile/uncompleted-profile-warning");
      }
    }
  }, [currentUserState.data, history.location?.pathname]);

  const providerValue = {
    ...state,
    signout,
    refetchCurrentUser: loadCurrentUser,
    setWorkspace
  };

  return (
    <CurrentUserContext.Provider value={providerValue}>
      { children }
    </CurrentUserContext.Provider>
  );
}
