import React from "react";
import { Space, Button, List } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";

const Item = ({ children, onRemove }) =>
  <List.Item
    extra={
      onRemove ?
        <MinusCircleOutlined onClick={onRemove} />
        : null
    }>
    {children}
  </List.Item>;

export const ArrayItem = ({
  value,
  displayFieldName,
  onRemoveItem,
  onClickAdd,
  buttonLabel,
  renderItem,
  editable = true
}) => {
  const defaultRenderer = item => {
    const displayValue = typeof(item) === "string" ?
      item : (displayFieldName && item[displayFieldName]);

    return (
      <Item
        key={item.id || item.uuid}
        onRemove={editable && (() => onRemoveItem(item))}>
        {displayValue}
      </Item>
    );
  };

  const renderer = renderItem || defaultRenderer;

  return (
    <Space
      direction="vertical"
      style={{ width: "100%" }}>
      <List
        size="small"
        bordered
        dataSource={value}
        renderItem={renderer} />
      { editable ?
        <Button
          icon={<PlusOutlined />}
          onClick={onClickAdd}
          type="dashed"
          block>
          {buttonLabel}
        </Button>
        : null }
    </Space>
  );
};
