import React from "react";
import { Space, Button, Avatar } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Visibility } from "../visibility";
import ColorHash from "color-hash";

/**
 * @params props
 * @param {string} className
 * @param {array} covers
 * @param {number} maxCount
 * @param {object} maxStyle
 * @param {function} onClick
 * @returns {JSX.Element}
 * @constructor
 */
export const AvatarList = ({
  className = "",
  covers = [],
  maxCount = 3,
  maxStyle = { color: "#f56a00", backgroundColor: "#fde3cf" },
  onClick = undefined
}) => {
  const colorHash = new ColorHash();

  const subs = text => text
    .split(" ")
    .slice(0, 2)
    .map(s => s[0])
    .join("")
    .toUpperCase();

  return (
    <Space
      className={className}>
      <Avatar.Group
        size="large"
        maxCount={maxCount}
        maxStyle={maxStyle}>
        {covers.map(({ name, url }) => (
          <Avatar
            key={name}
            style={!url && { backgroundColor: colorHash.hex(name) }}
            src={url}>
            {subs(name)}
          </Avatar>
        ))}
      </Avatar.Group>
      <Visibility visible={onClick !== undefined}>
        <Button
          type="grey"
          shape="circle"
          icon={<PlusOutlined />}
          size="large"
          onClick={onClick} />
      </Visibility>
    </Space>
  );
};
