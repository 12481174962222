import React, { useContext, useEffect, useState } from "react";
import { CurrentUserContext } from "../../../contexts/shared";
import { Visibility } from "../visibility";

export function RoleGuard({ roles, check, children }) {
  const [state, setState] = useState({ pass: false });
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    if(check) {
      setState({ pass: check(currentUser) });
    }
    else {
      setState({ pass: roles.includes(currentUser.systemRole) });
    }
  }, [roles, check]);


  return (
    <Visibility visible={state.pass}>
      {children}
    </Visibility>
  );
}
