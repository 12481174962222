import React, { useState, useContext, useEffect } from "react";
import { Modal, Form, message, Skeleton } from "antd";
import moment from "moment";
import { OdeUpdateForm } from "./form";
import { OdeUpdateModalContext } from "../context";
import { Visibility } from "../../../../components/shared";
import { client } from "../../../../../graphql";
import { shared } from "../../../../graphql/shared";

export function OdeUpdateModal({
  onSave,
  onCancel,
  odeUpdateId,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const [form] = Form.useForm();

  const {
    odeId,
    batchId
  } = useContext(OdeUpdateModalContext);

  useEffect(() => {
    const fetchOdeUpdate = async (odeUpdateId = "") => {
      setLoadingData(true);
      const { data } = await client.query({
        query: shared.queries.getOdeUpdate,
        variables: { id: odeUpdateId },
        fetchPolicy: "network-only"
      });

      const odeUpdate = data.odeUpdate;

      form.setFieldsValue({
        ...odeUpdate,
        ode: odeUpdate.ode.id,
        batch: odeUpdate.batch.id,
        period: odeUpdate.period ? moment(odeUpdate.period) : null
      });

      setLoadingData(false);
    };

    if (odeUpdateId)
      fetchOdeUpdate(odeUpdateId);

  }, [odeUpdateId]);

  const onOk = async() => {
    await form.validateFields();

    setLoading(true);
    const dismissLoading = message.loading("Guardando...");
    try {
      const values = form.getFieldsValue();
      if (batchId) // created from Batch details page
        values.batch = batchId;

      if (odeId) // created from ODE details page
        values.ode = odeId;

      values.period = values.period.toISOString();

      onSave && await onSave(values);
      message.success("Actualización de la Compañía guardada.");
      form.resetFields();
    }
    catch(e) {
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
      console.error(e);
    }
    finally {
      setLoading(false);
      dismissLoading();
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel && onCancel();
  };

  return (
    <Modal
      confirmLoading={loading}
      cancelButtonProps={{ disabled: loading }}
      width={700}
      destroyOnClose
      okText="Guardar"
      cancelText="Cancelar"
      title={odeUpdateId ? "Editar actualización" : "Nueva actualización"}
      onOk={onOk}
      onCancel={onCancelModal}
      {...props}>
      <Visibility visible={!loadingData}>
        <OdeUpdateForm
          odeId={odeId}
          batchId={batchId}
          selectType={odeId ? "batch" : batchId ? "ode" : undefined}
          form={form} />
      </Visibility>
      <Visibility visible={loadingData}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Visibility>
    </Modal>
  );
}
