import React from "react";
import { View, Text, StyleSheet, Link } from "@react-pdf/renderer";
import { Title } from "../../../components/title";
import { Line } from "../../../components/line";

export const Evidence = ({ data }) => {
  return (
    <View style={styles.body}>
      <View style={{ ...styles.row, marginTop: 13 }}>
        <Title>EVIDENCIA</Title>
        <Title style={{ marginRight: 20 }}>FECHA</Title>
      </View>
      <Line style={{ marginTop: 5, marginBottom: 16 }} />
      {data?.map(evidence => (
        <View style={{...styles.row, marginBottom: 16}}>
          <View>
            <Text style={styles.title}>{evidence?.name}</Text>
            <Link src={evidence?.url}>
              <Text style={styles.url}>{evidence?.fileName}</Text>
            </Link>
          </View>
          <View>
            <Text>{evidence?.date}</Text>
            <Text>{evidence?.time}</Text>
          </View>
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  body: {
    fontSize: 12,
    fontWeight: 400
  },
  title: {
    fontWeight: 700
  },
  url: {
    width: 250
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  }
});