import { client } from "../../../graphql";
import {
  getAssignedServicesByBatchQuery
} from "./graphql/get-assigned-services-by-batch-query";

export async function getAssignedServicesByBatch({
  types,
  batchId,
  companyId,
  skipExpired = false,
  withAvailableUnits = false
}) {
  let todayDate = undefined;

  if (skipExpired) {
    const yesterday = new Date().setDate(new Date().getDate() - 1);
    const yesterdayZeroHours = new Date(yesterday)
      .setHours(0, 0, 0, 0);
    todayDate = new Date(yesterdayZeroHours).toISOString();
  }

  const result = await client.query({
    query: getAssignedServicesByBatchQuery,
    fetchPolicy: "network-only",
    variables: {
      types,
      batchId,
      companyId,
      todayDate,
      availableUnits_gt: withAvailableUnits ? 0 : undefined
    }});

  return result.data?.assignedExpertServices || [];
}