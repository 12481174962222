import React from "react";
import { Breadcrumb as ABreadcrumb, Space, Card } from "antd";
import {
  ArrowLeftOutlined
} from "@ant-design/icons";

const spaceStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%"
};

export function Breadcrumbs({ history, extra, odeName, batchName }) {
  return (
    <Card
      bordered={false}
      className="batch-actions-bar"
      style={{ backgroundColor: "rgba(0,0,0,0)" }}>
      <Space style={spaceStyle} size="small">
        <Space>
          <ArrowLeftOutlined onClick={() => history?.goBack()} />
          <ABreadcrumb>
            <ABreadcrumb.Item>{odeName}</ABreadcrumb.Item>
            <ABreadcrumb.Item>{batchName}</ABreadcrumb.Item>
            <ABreadcrumb.Item>Contenidos</ABreadcrumb.Item>
          </ABreadcrumb>
        </Space>
        { extra }
      </Space>
    </Card>
  );
}
