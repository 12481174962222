import React from "react";
import { List, Tooltip } from "antd";
import {
  PhoneOutlined,
  MailOutlined,
  CrownTwoTone,
  ExclamationCircleTwoTone
} from "@ant-design/icons";
import {
  RowListItem
} from "../../../../list-item/row-list-item";
import { FlexContainer } from "../../../../flex-container";
import { IconLabel } from "../../../../icon-label";
import { Visibility } from "../../../../visibility";

export function TeamMembersList({
  teamMembers = [],
  customOnRemove,
  allowEdit = true
}) {

  const renderCrown = ({ systemRole, fullName}) => {
    if(systemRole.includes("ADVISER")) {
      return (
        <>
          <Tooltip
            color="#FFF"
            placement="top"
            title={
              <p style={{ color: "black" }}>
                <ExclamationCircleTwoTone
                  twoToneColor="#FFB61D" /> Mentor líder
              </p>
            }>
            <CrownTwoTone
              twoToneColor="#FFB61D" />
          </Tooltip> {fullName}
        </>
      );
    }

    return fullName;
  };

  return (
    <List
      pagination={{ defaultPageSize: 10 }}
      itemLayout="horizontal"
      dataSource={teamMembers}
      renderItem={user => (
        <RowListItem
          title={renderCrown(user)}
          user={user}
          blockActions={!allowEdit}
          extraActions={customOnRemove
            ? { onRemove: {
                label: "Remover",
                action: () => customOnRemove(user)
              } }
            : undefined}
          contentExtra={
            <FlexContainer
              direction="horizontal"
              xAlign="start"
              yAlign="center"
              style={{ gap: "16px" }}>
              <Visibility visible={!!user.email}>
                <IconLabel icon={<MailOutlined />}>
                  {user.email}
                </IconLabel>
              </Visibility>
              <Visibility visible={!!user.phone}>
                <IconLabel icon={<PhoneOutlined />}>
                  {user.phone}
                </IconLabel>
              </Visibility>
            </FlexContainer>
          } />
      )} />
  );
}
