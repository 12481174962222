export const removeUnnusedFields = value => {
  delete value.id;
  delete value.__typename;
  delete value.uuid;

  return value;
};

export const mapName = name => ({ name });
export const purgeString = string => string ? string : "";
