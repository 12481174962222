import React from "react";
import { Card, Table } from "antd";
import { getImpactedCommunityTableData } from "./helpers";

export const ImpactedCommunityTable = ({ tableData }) => {

  const { dataSource, columns } = getImpactedCommunityTableData({tableData});

  return (
    <Card
      className="entrepreneur-spirit-impacted-community-table-card"
      style={{ width: "50%" }}
      title="Comunidad impactada por tipo">
      <Table
        style={{ width: "100%" }}
        dataSource={dataSource}
        columns={columns}
        pagination={false} />
    </Card>
  );
};
