import {
  getDeletedIdsFromTwoArrays
} from "../../../../../helpers/get-deleted-ids-from-two-arrays";

export const formatValues = (originalValues = [], oldEducations = []) => {
  const values = JSON.parse(JSON.stringify(originalValues));

  const upsertValues = values.map(education => {
    const id = education.id || "";
    delete education.uuid;
    delete education.uid;
    delete education.__typename;
    delete education.id;

    return ({
      where: { id },
      create: { ...education },
      update: { ...education }
    });
  });

  const createValues = upsertValues.map(v => v.create);

  const deletedEducationIds = getDeletedIdsFromTwoArrays(
    originalValues,
    oldEducations
  );

  return ({
    expertProfile: {
      update: {
        professionalExperience: {
          upsert: {
            update: {
              education: {
                upsert: upsertValues,
                deleteMany: { id_in: deletedEducationIds }
              }
            },
            create: {
              education: { create: createValues }
            }
          }
        }
      }
    }
  });
};