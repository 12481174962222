import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { client } from "../../../../../../graphql";
import { shared } from "../../../../../graphql/shared";
import { AnnouncementsSection } from "../../../../shared";
import {
  CreateAnnouncementModalProvider
} from "../../../../../contexts/shared/create-annoucement-modal";

/**
 * @param {object} props
 * @param {string} props.batchId
 * @returns {JSX.Element}
 */
export function AnnouncementsList({ batchId }) {
  const { data, loading, error, refetch } = useQuery(
    shared.queries.getAnnouncements,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          batch: {
            id: batchId
          }
        }
      },
      client
    }
  );

  return (
    <CreateAnnouncementModalProvider batchId={batchId} refetch={refetch}>
      <AnnouncementsSection
        refetch={refetch}
        data={data}
        error={error}
        loading={loading} />
    </CreateAnnouncementModalProvider>
  );
}
