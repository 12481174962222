import gql from "graphql-tag";

export const registerContentDeliveryDateQuery = gql`
  mutation RegisterContentDeliveryDate(
    $odeContentId: ID!,
    $deliveryDate: DateTime!
  ) {
    updateOdeContent(
      where: { id: $odeContentId }
      data: { deliveryDate: $deliveryDate }
    ) {
      id
      deliveryDate
    }
  }
`;
