import React, { useState } from "react";
import { FlexContainer } from "../../flex-container";
import { InfoCircleTwoTone } from "@ant-design/icons";
import {
  Modal,
  Button,
  Checkbox,
  Typography
} from "antd";

export const TermsAndConditionsModal = ({
  loading,
  visible,
  onSave}) => {

  const [state, setState] = useState({accepted: false});

  const onChange = accepted => {
    setState({accepted});
  };

  return (
    <Modal
      centered={true}
      footer={null}
      closable={false}
      visible={visible}>
      <FlexContainer style={{gap: "1rem"}}>
        <InfoCircleTwoTone
          style={{
            fontSize: "23px"
        }}
          twoToneColor="#FAAD14" />
        <FlexContainer
          xAlign="start"
          style={{gap: "1rem"}}
          direction="vertical">
          <Typography.Title level={5}>
            Hay un cambio en nuestros términos y políticas de privacidad.
          </Typography.Title>
          <p>
            Apruébalos para seguir navegando
          </p>
          <Checkbox
            style={{
              color: "#00000080"
            }}
            onChange={e => onChange(e.target.checked)}>
            Acepto&nbsp;
            <a
              style={{
                textDecoration: "underline",
                color: "#00000080"
              }}
              target="_blank"
              href="https://tec.mx/es/aviso-de-privacidad-instituto-de-emprendimiento">
              <strong>términos y condiciones</strong>
            </a> y&nbsp;
            <a
              style={{
                textDecoration: "underline",
                color: "#00000080"
              }}
              target="_blank"
              href="https://tec.mx/es/aviso-de-privacidad-instituto-de-emprendimiento">
              <strong>políticas de privacidad.</strong>
            </a>
          </Checkbox>
          <FlexContainer
            style={{marginTop: "1rem"}}
            xAlign="end">
            <Button
              loading={loading}
              type="primary"
              onClick={() => onSave(state.accepted)}>
              Enviar aprobación
            </Button>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Modal>
  );
};
