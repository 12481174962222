import gql from "graphql-tag";

export const GET_LIGHT_BATCH_COMPANIES = gql`
  query GET_LIGHT_BATCH_COMPANIES($batchId: ID!) {
    batch (where: { id: $batchId }) {
      id
      odes {
        id
        name
      }
    }
  }
`;
