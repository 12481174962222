import React, { useContext } from "react";
import { Radio } from "antd";
import {
  TableOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
import { ContentViewSwitchContext } from "../../../contexts/shared";
import "./styles.sass";

export function ListViewButton() {
  const { isListView, setListView } = useContext(ContentViewSwitchContext);

  return (
    <div className="list-view-button">
      <Radio.Group
        onChange={e => setListView(e.target.value)}
        defaultValue={isListView}>
        <Radio.Button value={true}>
          <UnorderedListOutlined />
        </Radio.Button>
        <Radio.Button value={false}>
          <TableOutlined />
        </Radio.Button>
      </Radio.Group>
    </div>
  );
}
