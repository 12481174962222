import React, { useState, useEffect, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useParams } from "react-router-dom";
import { Summary } from "../../../../../components/admin/batches/show/summary";
import { MainMenu } from "../../../../../components/entrepreneur/shared";
import { Section, SidebarLayout } from "../../../../../components/shared";
import { BatchDetailsContextProvider } from "../../../../../contexts/admin/batches/batch-details";
import {
  default as Navbar
} from "../../../../../components/entrepreneur/batches/show/navbar";
import {
  default as TaskTable
} from "../../../../../components/shared/task-table";
import { client } from "../../../../../../graphql";
import { admin } from "../../../../../graphql/admin";
import { MenuContext } from "../../../../../contexts/shared/menu";
import { shared } from "../../../../../graphql/shared";
import { CurrentUserContext } from "../../../../../contexts/shared";
import { TaskDetailsModalContextProvider } from "../../../../../contexts/shared/task-details-modal/provider";
import { CreateTaskModalProvider } from "../../../../../contexts/shared/create-task-modal";
import { FilterControls } from "../../../../../components/admin/batches/show/tasks/filter-controls";

export function TasksIndex() {
  const { id } = useParams();
  const { setMenuState } = useContext(MenuContext);
  const { currentUser } = useContext(CurrentUserContext);

  const [ filters, setSelectedFilters ] = useState({
    selectedState: "all",
    inputSearch: ""
  });

  const { data, loading, error } = useQuery(
    shared.queries.getTasks,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          batch: { id: id },
          ode: {
            team_some: {
              id: currentUser.id
            }
          }
        }
      },
      client
    }
  );

  const {
    loading: batchLoading,
    error: batchError,
    data: batchData
  } = useQuery(
    admin.queries.getBatchById,
    {
      fetchPolicy: "network-only",
      variables: { id: id },
      client
    }
  );

  useEffect(() => {
    setMenuState({
      openKeys: ["programs"],
      selectedKeys: ["batches"]
    });
  }, []);

  const handleSelectorChange = value => {
    setSelectedFilters(state => ({
      ...state,
      selectedState: value
    }));
  };

  const handleInputSearch = value => {
    setSelectedFilters(state => ({
      ...state,
      inputSearch: value
    }));
  };

  const handleSearchChange = e => {
    if(e?.target?.value === ""){
      setSelectedFilters(state => ({
        ...state,
        inputSearch: ""
      }));
    }
  };

  return (
    <BatchDetailsContextProvider>
      <TaskDetailsModalContextProvider>
        <CreateTaskModalProvider>
          <SidebarLayout menu={<MainMenu />}>
            <Summary
              isLoading={batchLoading}
              error={batchError}
              data={batchData} />
            <Navbar selectedKeys={["tasks"]} />
            <Section
              title="Tareas"
              spaced
              extra={<FilterControls
                hideFilters={{odeSelect: true, batchesSelect: true}}
                handleSelectorChange={handleSelectorChange}
                handleInputSearch={handleInputSearch}
                resetTableContent={handleSearchChange} />}>
              <TaskTable
                data={data}
                error={error}
                isLoading={loading}
                selectedState={filters.selectedState}
                inputSearch={filters.inputSearch} />
            </Section>
          </SidebarLayout>
        </CreateTaskModalProvider>
      </TaskDetailsModalContextProvider>
    </BatchDetailsContextProvider>
  );
}