import gql from "graphql-tag";

export const createProgram = gql`
  mutation CreateProgram($data: ProgramCreateInput!) {
    createProgram(data: $data) {
      id
      name
      batches{
        id
        name
        odes{
          id
          name
        }
      }
    }
  }
`;
