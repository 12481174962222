import React from "react";
import Success from "../../../../components/coordinator/onboarding/success";
import { OnboardingLayout } from "../../../../components/shared";

export function SuccessIndex(){
  return(
    <OnboardingLayout>
      <Success />
    </OnboardingLayout>
  );
}