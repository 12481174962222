import React from "react";
import { Section } from "../section";
import { Typography } from "antd";

export const BatchSectionHeading = ({
  title = "",
  subTitle = "",
  ...props
}) => {
  return (
    <Section {...props}>
      <Typography.Title level={2}>
        {title}
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        {subTitle}
      </Typography.Paragraph>
    </Section>
  );
};
