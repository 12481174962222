import gql from "graphql-tag";

export const rejectOrganizationRequest = gql`
  mutation RejectOrganizationRequest(
    $organizationId: ID!
    $reviewerId: ID!
    $rejectionReason: String!
  ){
    rejectOrganizationRequest(
      organizationId: $organizationId
      reviewerId: $reviewerId
      rejectionReason: $rejectionReason
    )
  }
`;