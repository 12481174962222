import React, { useState } from "react";
import { ImportOdeModalContext } from "./context";
import { ImportOdeModal } from "./modal";
import { message, Modal, Space, Typography } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { shared } from "../../../graphql/shared";
import { client } from "../../../../graphql";
import { Visibility } from "../../../components/shared";
import { difference } from "lodash";
import * as XLSX from "xlsx";

export function ImportOdeModalProvider({ refetch, children }) {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const baseModalConfig = {
    className: "ConfirmModal",
    width: 600,
    centered: true,
    closable: true,
    okText: "Aceptar",
    title: "Importación de compañías",
    icon: null
  };

  const [createOdesByImport] = useMutation(
    shared.mutations.createOdesByImport, { client }
  );

  const openModalImport = () => {
    setVisible(true);
  };

  const onCancelImport = () => {
    setVisible(false);
  };

  const readFile = attachment => new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = e => {
        const data = e?.target?.result?.trim();
        const workbook = XLSX.read(data, { type: "binary" });
        const [sheetsAsJson] = workbook.SheetNames.map(sheet => {
          return XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        });
        const companies = sheetsAsJson?.map(el => ({
          name: el?.name?.trim(),
          description: el?.description?.trim()
        }));

        resolve(companies);
      };

      reader.readAsBinaryString(attachment);
    } catch (error) {
      reject(error);
    }
  });

  const checkTemplate = async template =>  {
    if (!template.length) {
      return false;
    }

    const fieldsValidate = ["name", "description"];
    const fields = [];

    for (const tpl of template) {
      const keys = Object.keys(tpl);
      for (const key of keys) {
        if (!fields.includes(key)) {
          fields.push(key);
        }
      }
    }

    const isValid = difference(fields, fieldsValidate);
    return !isValid.length;
  };

  const showOdesCreatedWithFailed = (created, failed) => Modal.warning({
    ...baseModalConfig,
    content: (
      <Space direction="vertical">
        <Typography.Text>
          Compañías creadas correctamente: <strong>{created.length}</strong>
        </Typography.Text>
        <Visibility visible={failed.length}>
          <Typography.Text>
            No se
            {failed.length > 1
              ? " crearon las compañías de las filas: "
              : " creó la compañía de la fila: "
            }
            <strong>{failed.map(el => el.index + 2).join(", ")}</strong>
          </Typography.Text>
        </Visibility>
      </Space>
    )
  });

  const showOdesFailed = failed => Modal.warning({
    ...baseModalConfig,
    content: (
      <Space direction="vertical">
        <Typography.Text>
          La compañía <strong>"{failed.map(el => el.name).join(", ")}" </strong>
          ya se encuentra registrada en EOS, elimínala de tu archivo y vuélvelo a cargar.
        </Typography.Text>
        <Typography.Text type="secondary">
          Si necesitas ayuda ponte en contacto a support@esossolution.io
        </Typography.Text>
      </Space>
    )
  });

  const onImport = async odes => {
    const dismissLoader = message.loading("Guardando...", 0);
    try {
      const { data } = await createOdesByImport({
        variables: { data: { odes } }
      });

      if (data?.createOdesByImport?.odesFailed) {
        showOdesCreatedWithFailed(
          data?.createOdesByImport?.odesCreated,
          data?.createOdesByImport?.odesFailed
        );
      } else {
        message.success("Compañías guardadas correctamente.");
      }
    } catch (error) {
      if (error.graphQLErrors.some(
        error => error?.extensions?.code === "SOME_ODES_EXIST"
      )) {
        showOdesFailed(error.graphQLErrors[0]?.extensions?.data);
      } else {
        message.error("Ha ocurrido un error, inténtalo de nuevo en unos segundos.");
      }
    } finally {
      dismissLoader();
      await refetch?.();
    }
  };

  const onSave = async (attachment, handleOnCancel) => {
    const odes = await readFile(attachment);
    const isValid = await checkTemplate(odes);

    if (isValid) {
      handleOnCancel();
      return Modal.confirm({
        ...baseModalConfig,
        content: (
          <Typography.Text>
            Vas a importar <strong>{odes.length}</strong> compañías.
          </Typography.Text>
        ),
        onOk: () => onImport(odes)
      });
    }

    return message.error("El archivo importado no es valido o esta vacío.");
  };

  const value = {
    openModalImport,
    onCancelImport,
    loading,
    setLoading,
    onSave
  };

  return(
    <ImportOdeModalContext.Provider value={value}>
      <ImportOdeModal onCancel={onCancelImport} visible={visible} />
      {children}
    </ImportOdeModalContext.Provider>
  );
}
