import gql from "graphql-tag";

export const updateOde = gql`
  mutation UpdateODE($id: ID!, $data: ODEUpdateInput! ) {
    updateODE(
      where: { id: $id },
      data: $data
    ) {
      id
    }
  }
`;
