import React from "react";
import { Breadcrumb as ABreadcrumb, Space, Card } from "antd";
import {
  ArrowLeftOutlined
} from "@ant-design/icons";
import { withRouter } from "../../../../../helpers";

const spaceStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%"
};

function Breadcrumbs({ navigate, history, extra, odeName }) {
  return (
    <Card
      bordered={false}
      className="batch-actions-bar"
      style={{ backgroundColor: "rgba(0,0,0,0)" }}>
      <Space style={spaceStyle} size="small">
        <Space>
          <ArrowLeftOutlined onClick={() => history?.goBack()} />
          <ABreadcrumb>
            <ABreadcrumb.Item
              className="clickable"
              onClick={() => navigate("../../odes")}>
              Compañías
            </ABreadcrumb.Item>
            <ABreadcrumb.Item>{odeName}</ABreadcrumb.Item>
          </ABreadcrumb>
        </Space>
        { extra }
      </Space>
    </Card>
  );
}

export default withRouter(Breadcrumbs);
