import React from "react";
import { BatchSection } from "./batch-section";
import { OdeSection } from "./ode-section";

export const AssignmentForm = ({
  batches,
  odes,
  selectedProgram,
  viewMode,
  saveAssignments,
  removedAssigned
}) => {
  return (
    <>
      <BatchSection
        batches={batches}
        selectedProgram={selectedProgram}
        viewMode={viewMode}
        saveAssignments={saveAssignments}
        removedAssigned={removedAssigned} />
      <OdeSection
        odes={odes}
        selectedProgram={selectedProgram}
        viewMode={viewMode}
        saveAssignments={saveAssignments}
        removedAssigned={removedAssigned} />
    </>
  );
};
