import { cubeJSClient } from "../../api-clients";

export const getEntrepreneursCubeDimensionValues = async dimensionName => {
  const resultSet = await cubeJSClient.load({
    "measures": [
      "Company.entrepreneursCount"
    ],
    "order": {
      "Company.entrepreneursCount": "desc"
    },
    "dimensions": [
      `Company.${dimensionName}`
    ],
    "filters": [
      {
        "member": `Company.${dimensionName}`,
        "operator": "set"
      }
    ]
  });

  const [result] = resultSet.loadResponse.results;
  const { data } = result;

  return (data || []).map(datum => datum[`Company.${dimensionName}`]);
};
