import React from "react";
import { Row, Col, Card, Typography } from "antd";
import { Section } from "../../../index";
import { CurrencyLabel } from "./currency-label";
import { useMetricsCalculation } from "./use-metrics-calculation";
import { FilterControls } from "./filter-controls";

export function Resume({ reports = [], loading = false, onFiltersChange }) {
  const metrics = useMetricsCalculation({ reports });

  return (
    <Section
      spaced
      title="Métricas"
      extra={<FilterControls onChange={onFiltersChange} />}>
      <Row gutter={16} style={{ marginBottom: "16px" }}>
        <Col span={8}>
          <Card
            loading={loading || metrics.calculating}
            style={{ borderLeftColor: "#5F39DE" }}
            className="metrics-card">
            <h4>Preventas acumulado</h4>
            <CurrencyLabel prefix="MX">
              {metrics.accumulatedPresalesMX}
            </CurrencyLabel>
            <br />
            <CurrencyLabel prefix="US">
              {metrics.accumulatedPresalesUS}
            </CurrencyLabel>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            loading={loading || metrics.calculating}
            style={{ borderLeftColor: "#AFEA94" }}
            className="metrics-card">
            <h4>Ventas mensuales (promedio)</h4>
            <CurrencyLabel prefix="MX">
              {metrics.averageMonthlySalesMX}
            </CurrencyLabel>
            <br />
            <CurrencyLabel prefix="US">
              {metrics.averageMonthlySalesUS}
            </CurrencyLabel>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            loading={loading || metrics.calculating}
            style={{ borderLeftColor: "#FA8E7D" }}
            className="metrics-card">
            <h4>Fondos obtenidos</h4>
            <CurrencyLabel prefix="MX">
              {metrics.fundsRaisedMX}
            </CurrencyLabel>
            <br />
            <CurrencyLabel prefix="US">
              US {metrics.fundsRaisedUS}
            </CurrencyLabel>
          </Card>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <Card
            loading={loading || metrics.calculating}
            style={{ borderLeftColor: "#F9AFFF" }}
            className="metrics-card">
            <h4>Concursos</h4>
            <Typography.Text
              style={{ fontSize: "2rem" }}
              type="secondary">
              {metrics.overallContests.toLocaleString()}
            </Typography.Text>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            loading={loading || metrics.calculating}
            style={{ borderLeftColor: "#1890FF" }}
            className="metrics-card">
            <h4>Empleos generados</h4>
            <Typography.Text
              style={{ fontSize: "2rem" }}
              type="secondary">
              {metrics.newJobs.toLocaleString()}
            </Typography.Text>
          </Card>
        </Col>
      </Row>
    </Section>
  );
}
