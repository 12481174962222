import gql from "graphql-tag";

export const getBatchServices = gql`
  query GetBatchServices($id: ID!,  $type_in: [String!]) {
    expertServices(
      where: {
        disabled: false
        type_in: $type_in
        batches_some: {
          id: $id
        }
      }
    ) {
      id
      name
      coverPicUrl
      type
      category
      expert {
        id
        fullName
      }
    }
  }
`;
