import React from "react";
import { Tag } from "antd";
import { statusTagColors } from "./status-tag-colors";
import {
  getReadableValue,
  scheduledServicesStatus
} from "../../../helpers";

export function StatusTag({ status }) {
  // NOTE: This is used for admins monitoring table by the moment.
  // Update it if it's necessary to use for another user roles

  const statusesWithAssigned = Array.from(scheduledServicesStatus);

  statusesWithAssigned.push(
    { label: "Asignado", value: "ASSIGNED" },
    { label: "Asignación vencida", value: "EXPIRED" },
    { label: "Asignación cancelada", value: "CANCELLED_ASSIGNATION" },
    { label: "Consumida", value: "CONSUMED" },
    { label: "En consumo", value: "CONSUMING" }
  );


  const props = status === "EXPIRED" ?
    { className: "tag-expired" }
    : { color: statusTagColors[status] };

  return (
    <Tag
      style={{ margin: 0 }}
      {...props}>
      {getReadableValue(statusesWithAssigned, status)}
    </Tag>
  );
}
