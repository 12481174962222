import { Card } from "antd";
import { Bar } from "react-chartjs-2";
import React, { useCallback, useMemo } from "react";
import { technologiesToChart } from "./technologies-to-chart";
import { ChartDataFeedback } from "../../chart-data-feedback";
import { useCompaniesByDimensions } from "../use-companies-by-dimensions";
import { TECHNOLOGIES_CHART_OPTIONS } from "./technologies-chart-options";

export const TechnologyChart = ({ filters = {} }) => {
  const { loading, results } = useCompaniesByDimensions({
    dimensions: ["companyTechnology"],
    mustBeSet: ["companyTechnology"],
    ...filters
  });

  const chartData = useMemo(() => {
    if (!results) return ({ labels: [], datasets: [] });

    const [result] = results;
    return technologiesToChart(result.data);
  }, [results]);

  const getFontSize = useCallback(() => {
    if (chartData?.labels?.length > 40) return 5;
    return undefined;
  }, [chartData]);

  TECHNOLOGIES_CHART_OPTIONS.scales.x.ticks.font.size = getFontSize();

  return (
    <Card
      spaced
      bordered
      style={sectionStyle}
      title="Compañías por tecnología"
      bodyStyle={{ height: "max-content", padding: 0 }}>
      <ChartDataFeedback
        loading={loading}
        isEmpty={!chartData?.datasets?.length}>
        <Bar
          type={"bar"}
          height={500}
          data={chartData}
          options={TECHNOLOGIES_CHART_OPTIONS} />
      </ChartDataFeedback>
    </Card>
  );
};

const sectionStyle = {
  marginTop: 16,
  boxShadow: "none",
  borderRadius: "0"
};
