import React from "react";
import { Input, Form, Row, Col } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Visibility } from "../../../../../components/shared";

export function TrlField({ onChange, value, idx, removeItem, viewMode = false }) {

  const [form] = Form.useForm();

  const handleOnChange = (_, allValues) => {
    onChange(allValues);
  };

  return (
    <Form
      style={{ marginBottom: "10px" }}
      form={form}
      className="wide-input"
      name={`trl-fiel-form-${idx}`}
      onValuesChange={handleOnChange}>
      <Row
        gutter={[10, 10]}
        align="middle"
        style={{ width: "100%" }}>
        <Visibility
          visible={!viewMode}>
          <Col span={2} style={{ textAlign: "center" }}>
            <CloseCircleOutlined onClick={removeItem(idx)} />
          </Col>
        </Visibility>
        <Col span={14}>
          <Form.Item
            style={{ margin: "0px" }}
            name="level"
            initialValue={value?.level || 0}>
            <Input
              disabled={viewMode}
              min={0}
              max={9}
              placeholder="Nivel"
              type="number" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            style={{ margin: "0px" }}
            name="percentage"
            initialValue={value?.percentage}>
            <Input
              disabled={viewMode}
              min={0}
              max={100}
              placeholder="Porcentaje"
              suffix="%"
              type="number" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}