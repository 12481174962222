/* eslint-disable max-lines,max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Space,
  Typography,
  Form,
  Select,
  Divider,
  Input,
  message
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { UsersTeamMemberSelect, Visibility } from "../../../../shared";
import { TeamList } from "../team-list";
import { verifyOdeNameAvailability } from "../../../../../helpers";
import { v4 as uuid } from "uuid";
import { LoadingWrapper } from "./loading-wrapper";
import { ControllerButtons } from "./controller-buttons";

export const CompanyForm = ({
  form,
  odes = [],
  odeId,
  onNext,
  onPrev,
  onAgree,
  onAddOde,
  onDisagree,
  onSelectOde,
  odesLoading = false,
  loadingButtons,
  isInvitedFromOde
}) => {
  const [newOdeName, setNewOdeName] = useState(null);
  const [teamList, setTeamList] = useState([]);
  const [emailSelectStatus, setEmailSelectStatus] = useState({
    validateStatus: "",
    help: ""
  });
  const [options, setOptions] = useState([]);

  const companySelectorRef = useRef();

  const onUserSelectWarning = () => {
    setEmailSelectStatus({
      validateStatus: "warning",
      help: "Alguno de los correos ingresados no es válido"
    });
  };

  const onUserSelectChange = value => {
    setEmailSelectStatus({
      validateStatus: "",
      help: ""
    });
    return value;
  };

  const onNewOdeNameChange = e => {
    setNewOdeName(e.target.value);
  };

  const handleOnAddOde = async () => {
    const dismissLoader = message
      .loading("Verificando nombre de la compañía...");

    try {
      const nameTaken  = await verifyOdeNameAvailability(newOdeName);
      const odeWithSameName = odes.find(ode => ode.name === newOdeName);

      if (nameTaken || odeWithSameName) {
        message.warning("El nombre de la Compañía ya está en uso");
        return;
      }

      const newId = uuid();
      const newOde = { id: newId, name: newOdeName };
      onAddOde(newOde);

      setNewOdeName(null);
      form.setFieldsValue({ ode: newId });
      companySelectorRef.current.blur();
    } catch (e) {
      console.error(e);
    } finally {
      dismissLoader();
    }
  };

  useEffect(() => {
    if (odes.length) {
      const options = odes ?
        odes.map(ode => ({ label: ode?.name, value: ode?.id }))
        : [];
      setOptions(options);

      if (isInvitedFromOde) {
        const odeData = odes?.find(ode => ode.id === odeId);
        setTeamList(odeData?.team);
      }
    }
  }, [odes, isInvitedFromOde]);

  useEffect(() => {
    if (isInvitedFromOde) {
      form.setFieldsValue({ ode: odeId });
    } else {
      setTeamList([]);
    }
  }, [isInvitedFromOde]);

  const onOdeInputChange = value => {
    onSelectOde(value);
    return value;
  };

  return (
    <Card bordered={false} style={{ maxWidth: "719px", margin: "0 auto" }}>
      <Space direction="vertical" className="wide-input">
        <div>
          <Typography.Title
            style={{ fontSize: "24px", fontWeight: "300" }}>Asóciate a tu compañía 💼</Typography.Title>
          <Typography.Text style={{ fontSize: "16px" }}>
            Tu compañía es la empresa con la que vas a emprender por eso es importante asociarte a ella para que puedas registrar tu avance.
          </Typography.Text>
        </div>
        <Form
          form={form}
          layout="vertical"
          style={{ marginTop: "19px" }}>
          <LoadingWrapper loading={odesLoading}>
            <Form.Item
              label="Nombre de tu compañia"
              name="ode"
              getValueFromEvent={onOdeInputChange}
              rules={[{ required: true }]}>
              <Select
                showSearch
                options={options}
                loading={odesLoading}
                className="wide-input"
                optionFilterProp="label"
                ref={companySelectorRef}
                disabled={isInvitedFromOde}
                placeholder={"Busca y selecciona tu compañía"}
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: "4px 0" }} />
                    <div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
                      <Input
                        value={newOdeName}
                        style={{ flex: "auto" }}
                        onChange={onNewOdeNameChange}
                        onPressEnter={handleOnAddOde}
                        placeholder="Si no encuentras tu compañía, escribe su nombre aquí y agrégala" />
                      <a
                        style={{ flex: "none", padding: "8px", display: "block", cursor: "pointer" }}
                        onClick={handleOnAddOde}>
                        <PlusOutlined /> Agregar compañía
                      </a>
                    </div>
                  </div>
                )} />
            </Form.Item>
          </LoadingWrapper>
          <Visibility visible={!isInvitedFromOde}>
            <Form.Item
              label="Integrantes"
              name="team"
              hasFeedback
              // we are using this prop to intercept the onChange event
              getValueFromEvent={onUserSelectChange}
              validateStatus={emailSelectStatus.validateStatus}
              help={emailSelectStatus.help}>
              <UsersTeamMemberSelect
                systemRole="ENTREPRENEUR"
                mode="tags"
                emailLabel={true}
                placeholder="Agrega los correos para enviar la invitación a tus socios"
                showWarning={onUserSelectWarning} />
            </Form.Item>
          </Visibility>
          <Visibility visible={isInvitedFromOde}>
            <Typography.Text>Integrantes</Typography.Text>
            <TeamList loading={loadingButtons.next} team={teamList} />
          </Visibility>
        </Form>
        <ControllerButtons
          onNext={onNext}
          onAgree={onAgree}
          onPrev={onPrev}
          onDisagree={onDisagree}
          disableNext={emailSelectStatus.validateStatus !== ""}
          loading={loadingButtons}
          isInvitedFromOde={isInvitedFromOde} />
      </Space>
    </Card>
  );
};
