import React, { useContext } from "react";
import { List } from "antd";
import { CardListLayout } from "../card-list-layout";
import {AssignedMentoringCard} from "../assigned-card";
import { useService } from "../../../../hooks/shared";
import { BatchMentorService } from "../../../../services";
import { Loader } from "../../loader";
import { BatchDetailsContext } from "../../../../contexts/admin/batches";

export function AssignedMentoring ({ companyId, batchId }) {
  const { data: { batch = {} } } = useContext(BatchDetailsContext);

  const {
    data: assigned,
    loading: assignedLoading,
    error: assignedError
  } = useService(
    BatchMentorService.getAssignedForOde,
    { companyId, batchId, type: "MENTORSHIP" }
  );

  const {
    data: scheduled,
    loading: scheduledLoading,
    error: scheduledError
  } = useService(
    BatchMentorService.getScheduledForOde,
    { companyId, batchId, type: "MENTORSHIP" }
  );

  const newScheduled = scheduled?.map(scheduled => {
    return {
      ...scheduled.assignedExpertService,
      scheduledExpertService: scheduled
    };
  });

  const virtualAssigned = [
    ...assigned || [],
    ...newScheduled || []
  ];

  return (
    <Loader
      loading={assignedLoading || scheduledLoading}
      data={virtualAssigned}
      error={assignedError || scheduledError}>
      <CardListLayout title={"Mentorías asignadas"}>
        <List
          style={{width: "100%"}}
          grid={{
            gutter: 16,
            xl: 3,
            xxl: 4
          }}
          dataSource={virtualAssigned || []}
          renderItem={item => (
            <List.Item>
              <AssignedMentoringCard
                leader={batch?.leader}
                assignedService={item} />
            </List.Item>
          )} />
      </CardListLayout>
    </Loader>
  );
}
