export function salesStageToChart (salesStage = []) {
  const labels = [];
  const datasets = [];

  salesStage.forEach((stage, idx) => {
    labels.push(stage["Company.companySalesStage"]);

    const data = new Array(salesStage.length);
    data.fill(0, 0, salesStage.length - 1);
    data[idx] = parseInt(stage["Company.companiesCount"]);

    datasets.push({
      label: stage["Company.companySalesStage"],
      data,
      backgroundColor: '#1a6575',
      barThickness: 50
    });
  });

  return {
    labels,
    datasets
  };
}
