import React, { useEffect, useContext } from "react";
import { message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { entrepreneur } from "../../../../../../graphql/entrepreneur";
import { ServiceDetailModalContext } from "../../../../../../contexts/shared/service-detail-modal";
import { client } from "../../../../../../../graphql";
import { MonitoringServicesTable } from "./table";
import {
  cancelledByEnum as CANCELLED_BY,
  eventTypeEnum
} from "../../../../../../helpers";
import { ScheduledExpertServiceService } from "../../../../../../services/deprecated/scheduled-expert-service-service";

export function MonitoringServicesList({
  data,
  loading,
  currentPage,
  onChangePage,
  refetch
}) {
  const {
    addOnCancelRequestListener,
    closeModal: closeDetailModal
  } = useContext(ServiceDetailModalContext);

  useEffect(() => {
    addOnCancelRequestListener(onCancelRequest);
  });

  const [cancelScheduledExpertService] = useMutation(
    entrepreneur.mutations.cancelScheduledExpertService,
    { client }
  );

  const onCancelRequest = async ({
    scheduledExpertService,
    currentUserId
  }) => {
    const dismissLoader = message.loading("Guardando...", 0);

    try {
      const scheduledExpertServiceService = new ScheduledExpertServiceService();

      const assignedServiceId = scheduledExpertService.assignedExpertService.id;
      const units = Number(scheduledExpertService.duration);

      await cancelScheduledExpertService({
        variables: {
          id: scheduledExpertService.id,
          cancelledBy: CANCELLED_BY.ADMIN,
          events: { // log event
            create: {
              event: eventTypeEnum.STATUS_CHANGED,
              description: "Status changed to CANCELLED",
              blame: { connect: { id: currentUserId } }
            }
          }
        }
      });

      await scheduledExpertServiceService.refundAssignedUnits({
        assignedServiceId,
        units
      });

      refetch && await refetch();
      message.success("Solicitud cancelada.");
      closeDetailModal();
    } catch (e) {
      console.error(e);
      message.error("Ocurrio un error.\
        Por favor intenta de nuevo en unos momentos.");
    } finally {
      dismissLoader();
    }
  };

  return (
    <MonitoringServicesTable
      currentPage={currentPage}
      onChangePage={onChangePage}
      loading={loading}
      count={data?.monitoringServices?.count}
      monitoringExpertServices={data?.monitoringServices?.scheduledExpertServices} />
  );
}
