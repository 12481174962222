import gql from "graphql-tag";

export const getOdeReportById = gql`  
  query getOdeReportById($id: ID!) {
    odeReport(
      where: { id: $id }
    ) {
      id
      startDate
      endDate
      companyStatus
      presales
      monthlySales
      productInDevelopment
      patentStatus
      productPhase
      trlLevels
      fundings
      contests
      hasEmployees
      currentEmployeesNumber
      terminatedEmployees
      hiredEmployees
      achievements
      ode {
        id
        name
        iname
      }
    }
  }
`;

