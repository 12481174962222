import React, { useState, useEffect } from "react";
import { Form, Typography, Input, DatePicker } from "antd";
import moment from "moment";
import {
  UploadSection
} from "../../../../shared";
import { genericFieldValidationMsgs } from "../../../../../utils";
import { LinksField } from "../../../../shared/links-field";

export function DeliveryForm({ form, scheduledService }) {
  const [state, setState] = useState({
    serviceDate: undefined
  });

  useEffect(() => {
    setState({
      serviceDate: scheduledService?.serviceDate
    });
  }, [scheduledService]);

  const getResultAttachments = async e => {
    const attachments = await form.getFieldValue("attachments") || {};

    if (!attachments.create) {
      attachments.create = [];
    }
    attachments.create.push(
      {
        fileName: e.file.name,
        url: e.url,
        type: e.file.type,
        uploadDate: new Date().toISOString()
      }
    );
    form.setFieldsValue({ attachments });
  };

  const disabledDate = current => {
    return current && current > moment().endOf("day");
  };

  return (
    <Form
      validateMessages={genericFieldValidationMsgs}
      layout="vertical"
      form={form}>
      <Form.Item
        label="Archivos"
        getValueFromEvent={getResultAttachments}
        name="attachments">
        <UploadSection>
          <Typography.Text style={{ display: "block" }}>
            Adjunta los archivos resultado del servicio
          </Typography.Text>
          <Typography.Text type="secondary">
            Puedes adjuntar: .rar, .zip, .doc, .docx, .pdf, .jpg, .png
          </Typography.Text>
        </UploadSection>
      </Form.Item>
      <Form.Item
        label="Enlaces"
        name="links">
        <LinksField showUploadArea={false} />
      </Form.Item>
      <Form.Item
        name="deliveredAt"
        rules={[{ required: true }]}
        label="Fecha de realización">
        <DatePicker
          disabledDate={disabledDate}
          className="wide-input"
          format="DD [de] MMMM [de] YYYY" />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        name="expertComments"
        label="Comentarios para la Compañía">
        <Input.TextArea
          placeholder="Comentarios"
          rows={3} />
      </Form.Item>
    </Form>
  );
}
