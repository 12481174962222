import React from "react";
import { Route, Switch } from "react-router";
import { AnalyticsIndex } from "../../../pages/admin/analytics";

export const CoordinatorAnalyticsRoutes = (
  <Switch>
    <Route
      exact
      path="/v2/coordinator/analytics"
      component={AnalyticsIndex} />
  </Switch>
);
