import React, { useState } from "react";
import {
  Alert, Col, Modal, Form, Input, message, Row, DatePicker, Select
} from "antd";
import { client } from "../../../../graphql";
import { admin } from "../../../graphql/admin";
import { useMutation } from "@apollo/react-hooks";
import { FileLinkField } from "../../../components/shared/file-link-field";
import { cloneDeep } from "lodash";

export function BatchEventModal({ onSave, onCancel, batchId, ...props }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [createBatchEvent] = useMutation(
    admin.mutations.createBatchEvent, { client }
  );

  const mergeDateAndTime = (d, t) => {
    const time = new Date(t.toISOString());
    const hours = time.getHours();
    const minutes = time.getMinutes();
    d.set({ h: hours, m: minutes });
    return d;
  };

  const onOk = async () => {
    setLoading(true);
    const dismissLoading = message.loading("Guardando...");
    try {
      const formValues = await form.getFieldsValue();
      const values = cloneDeep(formValues);

      if (values.materials) {
        values.materials = {
          create: values.materials.map(({ uuid, ...el }) => el)
        };
      }
      values.date = mergeDateAndTime(values.date, values.time);
      delete values.time;

      values.batch = { connect: { id: batchId } };
      await createBatchEvent({
        variables: { data: values }
      });

      message.success("Evento creado.");
      onSave();
      form.resetFields();
    } catch (e) {
      console.error(e);
      message.error("Ha ocurrido un error, por favor inténtalo de nuevo\
      en unos segundos.");
    } finally {
      dismissLoading();
      setLoading(false);
    }
  };

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      destroyOnClose
      okText="Guardar"
      cancelText="Cancelar"
      title="Añadir evento"
      onOk={onOk}
      onCancel={onCancelModal}
      okButtonProps={{ loading }}
      width={650}
      {...props}>
      <Form
        form={form}
        layout="vertical">
        <Row gutter={[10, 0]}>
          <Col span={24}>
            <Form.Item>
              <Alert
                message="Los eventos que agregues serán asignados a todas las
                Compañías de la edición."
                showIcon />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Nombre del evento"
              rules={[{ required: true }]}>
              <Input placeholder="Seleccionar" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Descripción"
              rules={[{ required: true }]}>
              <Input.TextArea
                rows={4}
                placeholder="Agrega la descripción del evento" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="date"
              label="Fecha"
              rules={[{ required: true }]}>
              <DatePicker
                className="wide-input" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="time"
              label="Hora"
              rules={[{ required: true }]}>
              <DatePicker
                picker="time"
                className="wide-input" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="location"
              label="Lugar"
              rules={[{ required: true }]}>
              <Select placeholder="Seleccionar">
                <Select.Option value="DIGITAL">Digital</Select.Option>
                <Select.Option value="PRESENCIAL">Físico</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              name="link"
              label="Link o ubicación"
              rules={[{ required: true }]}>
              <Input
                placeholder="Inserta la liga o describe la ubicación física" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={<b>Recursos</b>} name="materials">
              <FileLinkField />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
