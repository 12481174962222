import gql from "graphql-tag";

export const acceptTermsAndConditionsMutation = gql`
  mutation AcceptTermsAndConditions(
      $id: ID!
  ){
      updateUser(
          where: { id: $id }
          data: {
              acceptedTermsAndConditions: true
          }
      ){
          id
      }
  }
`;
