
export const SALES_STAGE_CHART_OPTIONS = {
  plugins: {
    legend: {
      position: "bottom"
    },
    datalabels: {
      color: "#000"
    }
  },
  scales: {
    x: {
      padding: 5,
      stacked: true
    },
    y: {
      stacked: true
    }
  },
  maintainAspectRatio: false
};
