import gql from "graphql-tag";

export const disconnectOdeByBatchId = gql`
  mutation DisconnectOdeByBatchId(
    $batchId: ID!, 
    $data: BatchUpdateInput!
  ) {
    updateBatch(
      where: { id: $batchId }
      data: $data
    ) {
      id
    }
  }
`;
