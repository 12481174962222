import { Button, Typography } from "antd";
import React from "react";
import { TagList } from "../..";
import { CardListItem } from "../card-list-item";
import {
  getReadableValue,
  expertServiceTypes,
  expertServiceCategories
} from "../../../../helpers/select-options";

export function BatchServiceListItem({ batchService, onRemove }) {
  batchService = batchService || {};

  const getTags = () => {
    return [
      getReadableValue(expertServiceTypes, batchService.type),
      getReadableValue(expertServiceCategories, batchService.category)
    ];
  };

  return (
    <CardListItem
      title={batchService.name}
      coverUrl={batchService.coverPicUrl}
      onRemove={() => onRemove()}
      footerItems={[
        <Button
          ghost
          type="primary"
          key="assign-btn"
          title="Asignar">
          Asignar
        </Button>
      ]}>
      <>
        <Typography.Text type="secondary">
          {batchService.expert?.fullName}
        </Typography.Text>
        <div style={{ display: "block",  marginTop: "1rem" }}>
          <TagList
            maxCount={2}
            className="tags"
            tags={getTags()} />
        </div>
      </>
    </CardListItem>
  );
}
