export function financingStageToChart (financingStages = []) {
  const labels = [];
  const datasets = [];

  financingStages.forEach((financingStage, idx) => {
    labels.push(financingStage["Company.companyFinancingStage"]);

    const data = new Array(financingStages.length);
    data.fill(0, 0, financingStages.length - 1);
    data[idx] = parseInt(financingStage["Company.companiesCount"]);

    datasets.push({
      label: financingStage["Company.companyFinancingStage"],
      data,
      backgroundColor: '#893288',
      barThickness: 10
    });
  });

  return {
    labels,
    datasets
  };
}
