import React from "react";
import { Button, Modal, Popover } from "antd";
import {
  MinusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  CopyOutlined,
  InfoCircleFilled, ExclamationCircleOutlined
} from "@ant-design/icons";
import { ActionButton } from "../../index";

const icons = {
  onRemove: MinusCircleOutlined,
  onDelete: DeleteOutlined,
  onEdit: EditOutlined,
  onDuplicate: CopyOutlined
};

export const renderOptions = ({
  customOptionsComponent,
  onRemove,
  onDelete,
  onEdit,
  onDuplicate,
  extraActions,
  labels
}) => {
  if (customOptionsComponent) return customOptionsComponent;

  const actions = {
    onRemove,
    onDelete,
    onEdit,
    onDuplicate
  };

  const handleOnRemove = action =>  Modal.confirm({
    okText: "Remover",
    cancelText: "Cancelar",
    title: "Advertencia",
    icon: <ExclamationCircleOutlined />,
    content: "¿Deseas remover este elemento?",
    onOk: action
  });

  const actionsCount = Object
    .values(actions)
    .filter(action => !!action)?.length || 0;

  if (actionsCount < 1 && !extraActions?.length) return null;

  const actionsMap = {};

  Object
    .keys(actions)
    .filter(key => !!actions[key])
    .forEach(key => {
      actionsMap[key] = actions[key];
    });

  if (actionsCount === 1 && !extraActions?.length) {
    const [key] = Object.keys(actionsMap);

    const action = actionsMap[key];
    const Icon = icons[key];
    return (
      <Popover
        trigger="hover"
        content={<>
          <InfoCircleFilled
            style={{ color: "#FAAD14", marginRight: "1rem" }} />
          <span>{labels[key]}</span>
        </>}>
        <Button
          type="text"
          shape="circle"
          icon={<Icon />}
          onClick={() => {
            key === "onRemove"
              ? handleOnRemove(action)
              : action();
          }} />
      </Popover>
    );
  }

  if (onRemove !== undefined) {
    if (extraActions === undefined)
      extraActions = [];

    extraActions.push({ label: "Remover", action: onRemove });
  }

  return (
    <ActionButton
      onDelete={onDelete}
      onEdit={onEdit}
      onDuplicate={onDuplicate}
      extra={[
        ...extraActions || []
      ]} />
  );
};
