import gql from "graphql-tag";

export const getPublishedContents = gql`
  query GetPublishedContents($where: PublishedContentWhereInput, $odeId: ID){
    publishedContents(where: $where){
      customOdeContent {
        id
        contentGroupsSort
        contentGroups {
          id
          title
          draft
          description
          contentsSort
          contents {
            id
            type
            name
            draft
            materials {
              id
              url,
              type,
              uploadDate,
              fileName
            }
            description
            dueDate
            instructions
            placeType
            placeDirection
            odeContents(
              where: {
                ode: { id: $odeId }
              }
            ) {
              id
              status
              deliveryDate
              deliverables {
                id
                fileName
                url
                type
                uploadDate
              }
              events {
                id
                event
                description
                createdAt
                blame {
                  id
                  fullName
                  profilePicUrl
                }
              }
            }
          }
        }
      }
      masterContent {
        id
        contentGroupsSort
        contentGroups {
          id
          title
          draft
          description
          contentsSort
          contents {
            id
            type
            name
            draft
            materials {
              id
              url,
              type,
              uploadDate,
              fileName
            }
            description
            dueDate
            instructions
            odeContents(
              where: {
                ode: { id: $odeId }
              }
            ) {
              id
              status
              finishDate
              deliverables {
                id
                fileName
                url
                type
                uploadDate
              }
              events {
                id
                event
                description
                createdAt
                blame {
                  id
                  fullName
                  profilePicUrl
                }
              }
            }
          }
        }
      }
    }
  }
`;
