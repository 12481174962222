import { sortedBatchContent } from "../../../../../../../helpers";
import {
  getFinalSort
} from "../../../../../../shared/batch-content/helpers/get-final-sort";

export const sortContentGroups = (contentGroups, sort) => {
  const finalSort = getFinalSort(sort, contentGroups);
  return sortedBatchContent(contentGroups, finalSort);
};

export const sortContents = (contents, contentGroup) => {
  const sort = getFinalSort(contentGroup.contentsSort, contents);
  return sortedBatchContent(contents, sort);
};