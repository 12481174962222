import React, { useContext, useEffect } from "react";
import { Form, Input, Radio } from "antd";
import { DatePicker, FormContainer, InputTooltip } from "../../../../../shared";
import { fieldValidationMessages } from "../../../../../../utils";
import {
  EditProfileContext
} from "../../../../../../contexts/shared/edit-profile";

export function ContactForm({ form }) {
  const { data } = useContext(EditProfileContext);

  useEffect(() => {
    if (data?.user) {
      const { expertProfile, ...values } = data?.user;
      form.setFieldsValue({
        ...values,
        resume: expertProfile?.resume
      });
    }
  }, [data]);

  return (
    <FormContainer>
      <Form
        layout="vertical"
        form={form}
        validateMessages={fieldValidationMessages}>
        <Form.Item
          name="resume"
          label="Semblanza"
          rules={[{ required: true }]}>
          <Input.TextArea
            rows={3}
            placeholder="Describe brevemente" />
        </Form.Item>
        <Form.Item
          name="firstName"
          label="Nombre(s)"
          rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="middleName"
          label="Apellido paterno"
          rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Apellido materno"
          rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Correo electrónico"
          rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <InputTooltip
          title="Contraseña que utilizará en EOS">
          <Form.Item
            name="password"
            label="Contraseña"
            rules={[{ required: true }]}>
            <Input.Password placeholder="Contraseña que utilizará en EOS" />
          </Form.Item>
        </InputTooltip>
        <Form.Item
          name="phone"
          label="Teléfono"
          rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="gender"
          label="Género"
          rules={[{ required: true }]}>
          <Radio.Group>
            <Radio value="FEMALE">Femenino</Radio>
            <Radio value="MALE">Masculino</Radio>
            <Radio value="NOT_SAY">Prefiero no decirlo</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="birthday"
          label="Fecha de nacimiento"
          rules={[{ required: true }]}>
          <DatePicker />
        </Form.Item>
      </Form>
    </FormContainer>
  );
}
