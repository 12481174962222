import {
  ExpertNewRoutes,
  ExpertShowRoutes,
  ExpertODEsRoutes,
  ExpertServicesRoutes,
  ExpertOnboardingRoutes,
  ExpertMonitoringRoutes
} from "./v2/routes/expert";
import { InvitationsRoutes } from "./v2/routes/invitations";
import { AuthRoutes } from "./v2/routes/login";
import {
  EntrepreneurODEsRoutes,
  EntrepreneurBatchesRoutes,
  EntrepreneurExpertsRoutes,
  EntrepreneurProfileRoutes,
  EntrepreneurCalendarRoutes,
  EntrepreneurSessionsRoutes
} from "./v2/routes/entrepreneur";
import "moment/locale/es";
import "antd/dist/antd.css";
import "./v2/styles/index.sass";
import React from "react";
import { ConfigProvider } from "antd";
import esES from "antd/es/locale/es_ES";
import moment from "moment";
import { MenuContextProvider } from "./v2/contexts/shared";
import { CurrentUserProvider } from "./v2/contexts/shared";
import {
  RouteGuard,
  TermsAndConditionsModalWrapper
} from "./v2/components/shared";
import { hasRole } from "./v2/helpers";
import { PublicRoutes } from "./v2/routes/public";
import {
  AdminODEsRoutes,
  AdminPerksRoutes,
  AdminBatchesRoutes,
  AdminExpertsRoutes,
  AdminProgramsRoutes,
  AdminEntrepreneursRoutes,
  AdminConfigurationRoutes,
  AdminAnalyticsRoutes
} from "./v2/routes/admin";
import {
  CoordinatorODEsRoutes,
  CoordinatorPerksRoutes,
  CoordinatorProfileRoutes,
  CoordinatorExpertsRoutes,
  CoordinatorBatchesRoutes,
  CoordinatorProgramsRoutes,
  CoordinatorAnalyticsRoutes,
  CoordinatorOnboardingRoutes,
  CoordinatorEntrepreneursRoutes,
  CoordinatorEntrepreneurSpiritRoutes
} from "./v2/routes/coordinator";
import {
  NotificationsContextProvider
} from "./v2/contexts/shared/notifications/provider";
import { AsemRoutes } from "./v2/routes/asem";

moment.locale("es");

export const V2 = () => (
  <ConfigProvider locale={esES}>
    <CurrentUserProvider>
      <TermsAndConditionsModalWrapper>
        <NotificationsContextProvider>
          <MenuContextProvider>
            {AuthRoutes}
            {PublicRoutes}
            <RouteGuard check={hasRole("ASEM_ADMIN")}>
              {AsemRoutes}
            </RouteGuard>
            <RouteGuard check={hasRole("ADVISER")}>
              {ExpertServicesRoutes}
              {ExpertShowRoutes}
              {ExpertOnboardingRoutes}
              {ExpertODEsRoutes}
              {ExpertMonitoringRoutes}
            </RouteGuard>
            <RouteGuard check={hasRole(["SUPER_ADMIN", "COORDINATOR"])}>
              {ExpertNewRoutes}
            </RouteGuard>
            <RouteGuard check={hasRole("SUPER_ADMIN")}>
              {AdminEntrepreneursRoutes}
              {AdminProgramsRoutes}
              {AdminBatchesRoutes}
              {AdminExpertsRoutes}
              {AdminODEsRoutes}
              {AdminConfigurationRoutes}
              {AdminPerksRoutes}
              {AdminAnalyticsRoutes}
            </RouteGuard>
            <RouteGuard check={hasRole("COORDINATOR")}>
              {CoordinatorExpertsRoutes}
              {CoordinatorBatchesRoutes}
              {ExpertNewRoutes}
              {CoordinatorProgramsRoutes}
              {CoordinatorAnalyticsRoutes}
              {CoordinatorODEsRoutes}
              {CoordinatorOnboardingRoutes}
              {CoordinatorProfileRoutes}
              {CoordinatorPerksRoutes}
              {CoordinatorEntrepreneursRoutes}
              {CoordinatorEntrepreneurSpiritRoutes}
            </RouteGuard>
            <RouteGuard check={hasRole("ENTREPRENEUR")}>
              {EntrepreneurProfileRoutes}
              {EntrepreneurExpertsRoutes}
              {EntrepreneurBatchesRoutes}
              {EntrepreneurCalendarRoutes}
              {EntrepreneurODEsRoutes}
              {EntrepreneurSessionsRoutes}
            </RouteGuard>
            {InvitationsRoutes}
          </MenuContextProvider>
        </NotificationsContextProvider>
      </TermsAndConditionsModalWrapper>
    </CurrentUserProvider>
  </ConfigProvider>
);
