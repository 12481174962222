import React from "react";
import { Typography } from "antd";
import { Section } from "../../section";
import { Visibility } from "../../visibility";
import {FlexContainer} from "../../flex-container";

export const SectionHeading = ({
  title = "",
  subtitle = "",
  rightComponent,
  leftComponent,
  ...props
}) => {
  return (
    <Section
      {...props}
      style={{
        textAlign: "center",
        ...(props.style || {})
      }}>
      <FlexContainer
        yAlign="center"
        direction="horizontal"
        style={{ gap: "2rem" }}>
        <Visibility visible={!!leftComponent}>
          <FlexContainer
            xAlign="end"
            direction="horizontal"
            style={{ width: "76px" }}>
            {leftComponent}
          </FlexContainer>
        </Visibility>
        <Typography.Title level={2}>
          {title}
        </Typography.Title>
        <Visibility visible={!!rightComponent}>
          {rightComponent}
        </Visibility>
      </FlexContainer>
      <Typography.Paragraph type="secondary">
        {subtitle}
      </Typography.Paragraph>
    </Section>
  );
};
