import React from "react";
import { Space } from "antd";
import { SearchField } from "../";

export function SearchToolbar({
  onSearchChange,
  style = {},
  containerStyle = {},
  ...props
}) {
  return (
    <Space style={containerStyle}>
      <SearchField
        style={{ minWidth: "435px", ...style }}
        onChange={onSearchChange}
        {...props} />
    </Space>
  );
}
