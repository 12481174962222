import React, { useContext, useEffect } from "react";
import { useQueryFilters } from "../../../helpers";
import { useService } from "../../../hooks/shared";
import { GlobalCalendarService } from "../../../services";
import { Section, SidebarLayout } from "../../../components/shared";
import { MenuContext, CurrentUserContext } from "../../../contexts/shared";
import {
  CalendarFilter,
  EntrepreneurCalendar
} from "../../../components/entrepreneur/calendar";
import { Space, Spin } from "antd";

export function CalendarIndex() {
  const { setMenuState } = useContext(MenuContext);
  const { currentUser } = useContext(CurrentUserContext);

  useEffect(() => {
    setMenuState({ selectedKeys: ["entrepreneur/calendar"] });
  }, []);

  const today = new Date();
  const defaultFilters = {
    entrepreneurId: currentUser.id,
    month: today.getMonth(),
    year: today.getFullYear()
  };

  const { data: events, loading, error, refetch } = useService(
    GlobalCalendarService.getEntrepreneurEvents,
    defaultFilters,
    { shouldFetch: currentUser.id }
  );

  const { applyFilters } = useQueryFilters({ refetch, defaultFilters });

  const handleOnFilter = async filters => {
    applyFilters(prev => ({ ...prev, ...filters }));
  };

  return (
    <SidebarLayout>
      <Section
        spaced
        title={
          <Space size="large">
            Calendario
            {loading ? <Spin /> : undefined}
          </Space>
        }
        extra={<CalendarFilter
          odes={currentUser.odes ?? []}
          onChange={handleOnFilter} />}>
        <EntrepreneurCalendar
          error={error}
          loading={loading}
          events={events ?? []}
          onChange={date => handleOnFilter({
            year: date.year(),
            month: date.month()
          })} />
      </Section>
    </SidebarLayout>
  );
}
