import React, { useState, useEffect } from "react";
import { Section } from "../section";
import { Alert, List, Typography } from "antd";
import { CardService } from "../card-service";
import { Visibility } from "../visibility";
import { getFinalSort } from "../batch-content/helpers/get-final-sort";
import { sortedBatchContent } from "../../../helpers";

export const PublishBatchLearn = ({
  contentGroups,
  sort,
  hideAlertContent = false,
  ...props
}) => {
  const [items, setItems] = useState(contentGroups);

  useEffect(() => {
    if (sort?.length) {
      const finalSort = getFinalSort(sort, contentGroups);
      setItems(sortedBatchContent(contentGroups, finalSort));
    } else {
      setItems(contentGroups);
    }
  }, [sort, contentGroups]);

  return (
    <Section {...props}>
      <Visibility visible={contentGroups.length || !hideAlertContent}>
        <Typography.Title level={2}>
          ¿Qué vas a aprender?
        </Typography.Title>
        {!contentGroups.length
          ? <Alert
              style={{ width: "80%", margin: "0 auto 1rem auto" }}
              message={"Contenido faltante. Da click en el botón “Anterior” para agregarlo."}
              type="warning"
              showIcon />
          : <List
              className="NewBatch__Publish-Learn__Wrapper-List"
              dataSource={items}
              renderItem={item =>
                <CardService
                  title={item?.title}
                  description={item?.description} />
            } />
        }
      </Visibility>
    </Section>
  );
};
