import gql from "graphql-tag";

export const getDashboardInvolvementLevelsQuery = gql`
  query GetDashboardInvolvementLevels(
      $filters: DashboardInvolvementLevelsFiltersInput
  ){
    dashboardInvolvementLevels (
      filters: $filters
    ){
      nationalAverage {
        KNOW
        LEARN
        ACT
      }
      maxRecord {
        KNOW
        LEARN
        ACT
      }
    }
  }
`;