/**
 * @legacy from v1
 * @param items
 * @param sort
 * @returns {*[]|*}
 */
export const sortedBatchContent = (items, sort) => {
  const response = [];
  for (const uuid of sort) {
    const findElement = items.find(item => item.id === uuid);
    if (findElement) {
      response.push(findElement);
    }
  }
  return items.length === response.length ? response : items;
};
