import gql from "graphql-tag";

export const getAnnouncements = gql`
  query GetAnnouncements(
    $where: AnnouncementWhereInput!
  ) {
    announcements(
      where: $where
    ) {
      id
      title
      body
      date
      image
      documents {
        url
      }
      createdBy {
        fullName
        profilePicUrl
      }
    }
  }
`;
