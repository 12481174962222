const  unorderedOdeEconomicActivities = [
  // primary
  { sector: "Extracción de materias primas", label: "Agricultura", value: "Agricultura" },
  { sector: "Extracción de materias primas", label: "Explotación de animales", value: "Explotación de animales" },
  { sector: "Extracción de materias primas", label: "Aprovechamiento forestal", value: "Aprovechamiento forestal" },
  { sector: "Extracción de materias primas", label: "Pesca y caza", value: "Pesca y caza" },
  { sector: "Extracción de materias primas", label: "Minería", value: "Minería" },
  { sector: "Extracción de materias primas", label: "Energía eléctrica, agua y gas", value: "Energía eléctrica, agua y gas" },

  // secondary
  { sector: "Construcción", label: "Edificación", value: "Edificación" },
  { sector: "Construcción", label: "Obras de ingeniería civil", value: "Obras de ingeniería civil" },
  { sector: "Construcción", label: "Trabajos especializados de construcción", value: "Trabajos especializados de construcción" },

  { sector: "Manufactura", label: "Curtido y acabado de cuero y piel", value: "Curtido y acabado de cuero y piel" },
  { sector: "Manufactura", label: "Fabricación de aparatos eléctricos y equipo de generación de energía eléctrica", value: "Fabricación de aparatos eléctricos y equipo de generación de energía eléctrica" },
  { sector: "Manufactura", label: "Fabricación de equipo de computación, comunicación y componentes electrónicos", value: "Fabricación de equipo de computación, comunicación y componentes electrónicos" },
  { sector: "Manufactura", label: "Fabricación de equipo de transporte", value: "Fabricación de equipo de transporte" },
  { sector: "Manufactura", label: "Fabricación de insumos textiles", value: "Fabricación de insumos textiles" },
  { sector: "Manufactura", label: "Fabricación de maquinaria y equipo", value: "Fabricación de maquinaria y equipo" },
  { sector: "Manufactura", label: "Fabricación de muebles, colchones y persianas", value: "Fabricación de muebles, colchones y persianas" },
  { sector: "Manufactura", label: "Fabricación de prendas de vestir", value: "Fabricación de prendas de vestir" },
  { sector: "Manufactura", label: "Fabricación de productos a base de minerales no metálicos", value: "Fabricación de productos a base de minerales no metálicos" },
  { sector: "Manufactura", label: "Fabricación de productos derivados del petróleo y del carbón", value: "Fabricación de productos derivados del petróleo y del carbón" },
  { sector: "Manufactura", label: "Fabricación de productos metálicos", value: "Fabricación de productos metálicos" },
  { sector: "Manufactura", label: "Fabricación de productos textiles, excepto prendas de vestir", value: "Fabricación de productos textiles, excepto prendas de vestir" },
  { sector: "Manufactura", label: "Impresión e industrias relacionadas", value: "Impresión e industrias relacionadas" },
  { sector: "Manufactura", label: "Industria alimentaria", value: "Industria alimentaria" },
  { sector: "Manufactura", label: "Industria de la madera", value: "Industria de la madera" },
  { sector: "Manufactura", label: "Industria de las bebidas y del tabaco", value: "Industria de las bebidas y del tabaco" },
  { sector: "Manufactura", label: "Industria del papel", value: "Industria del papel" },
  { sector: "Manufactura", label: "Industria del plástico y del hule", value: "Industria del plástico y del hule" },
  { sector: "Manufactura", label: "Industria química", value: "Industria química" },
  { sector: "Manufactura", label: "Industrias metálicas básicas", value: "Industrias metálicas básicas" },
  { sector: "Manufactura", label: "Otras industrias manufactureras", value: "Otras industrias manufactureras" },

  // tertiary
  { sector: "Comercio", label: "Comercio al por mayor", value: "Comercio al por mayor" },
  { sector: "Comercio", label: "Comercio al por menor", value: "Comercio al por menor" },
  { sector: "Comercio", label: "Comercio electrónico", value: "Comercio electrónico" },

  { sector: "Transporte", label: "Almacenamiento", value: "Almacenamiento" },
  { sector: "Transporte", label: "Postal, mensajería y paquetería", value: "Postal, mensajería y paquetería" },
  { sector: "Transporte", label: "Transporte de carga", value: "Transporte de carga" },
  { sector: "Transporte", label: "Transporte de pasajeros", value: "Transporte de pasajeros" },

  { sector: "Servicios", label: "Actividades legislativas, gubernamentales, de justicia y organismos internacionales", value: "Actividades legislativas, gubernamentales, de justicia y organismos internacionales" },
  { sector: "Servicios", label: "Información en medios masivos", value: "  Información en medios masivos" },
  { sector: "Servicios", label: "Servicios científicos y técnicos", value: "  Servicios científicos y técnicos" },
  { sector: "Servicios", label: "Servicios de alojamiento", value: "  Servicios de alojamiento" },
  { sector: "Servicios", label: "Servicios de manejo de residuos y remediación", value: "  Servicios de manejo de residuos y remediación" },
  { sector: "Servicios", label: "Servicios de preparación de alimentos y bebidas", value: "  Servicios de preparación de alimentos y bebidas" },
  { sector: "Servicios", label: "Servicios de salud y de asistencia social", value: "  Servicios de salud y de asistencia social" },
  { sector: "Servicios", label: "Servicios de soporte a los negocios: limpieza, seguridad, contratación", value: "  Servicios de soporte a los negocios: limpieza, seguridad, contratación" },
  { sector: "Servicios", label: "Servicios educativos", value: "  Servicios educativos" },
  { sector: "Servicios", label: "Servicios financieros y de seguros", value: "  Servicios financieros y de seguros" },
  { sector: "Servicios", label: "Servicios inmobiliarios y de alquiler de bienes muebles e intangibles", value: "  Servicios inmobiliarios y de alquiler de bienes muebles e intangibles" },
  { sector: "Servicios", label: "Servicios profesionales", value: "  Servicios profesionales" },
  { sector: "Servicios", label: "Servicios recreativos, culturales y deportivos", value: "  Servicios recreativos, culturales y deportivos" },
  { sector: "Servicios", label: "Otros servicios", value: "  Otros servicios" }
];

export const odeEconomicActivities = unorderedOdeEconomicActivities
  .sort((a, b) => a.label.localeCompare(b.label));
