import { formatEvent } from "./helpers/format-event";
import { client } from "../../../graphql";
import { updateOdeContent } from "./graphql";

/**
 * @param {string}  odeId
 * @param {string} userId
 * @param {string} batchId
 * @param {string} contentId
 * @param {string} odeContentId
 * @param {string} status
 * @returns {Promise<OdeContent>} Updated OdeContent
 */
export const changeCompanyContentStatus = async ({
  odeId,
  userId,
  batchId,
  contentId,
  odeContentId,
  status
}) => {
  const variables = {
    id: odeContentId || "", // need to pass an empty string to the graphql query
    create: {
      status,
      ode: { connect: { id: odeId } },
      content: { connect: { id: contentId } },
      batch: { connect: { id: batchId } },
      events: formatEvent(userId, "STATUS_CHANGE", status)
    },
    update: {
      status,
      events: formatEvent(userId, "STATUS_CHANGE", status)
    }
  };

  const result = await client.mutate({
    mutation: updateOdeContent,
    variables
  });

  return result.data.upsertOdeContent;
};
