import React, { useContext, useState } from "react";
import { List, Alert, message } from "antd";
import { useMutation } from "@apollo/react-hooks";
import { default as OdeItem } from "./item";
import {
  BatchDetailsContext,
  BatchOdesContext
} from "../../../../../../contexts/admin/batches";
import { Section, Visibility } from "../../../../../shared";
import { FilterControls } from "../filter-controls";
import { AddOdeButton } from "../add-ode-button";
import { admin } from "../../../../../../graphql/admin";
import { client } from "../../../../../../../graphql";
import { CurrentUserContext } from "../../../../../../contexts/shared";
import { paginationConfig } from "../../../../../../helpers";
import { CompanyService } from "../../../../../../services";
import { DownloadOutlined } from "@ant-design/icons";
import { ModalButton } from "../../../../../shared/buttons";
import {
  DataExportModal
} from "../../../../../shared/modals";
import { reportService } from "../../../../../../services/reports";

export function BatchOdesList({ onClick }) {
  const { data, loading, error, applyFilters } = useContext(BatchOdesContext);
  const { data: batchData } = useContext(BatchDetailsContext);
  const { currentUser } = useContext(CurrentUserContext);
  const [stateExport, setStateExport] = useState({
    exportLoading: false,
    exportUrl: null
  });

  const [disconnectOdeFromBatch] = useMutation(
    admin.mutations.disconnectOdeFromBatch,
    {
      client,
      awaitRefetchQueries: true,
      refetchQueries: [{
        query: admin.queries.getOdesByBatchId,
        variables: { id: batchData?.batch?.id }
      }]
    });

  if (error) {
    return (
      <Alert
        type="error"
        message="Ha ocurrido un error al cargar las compañías.
        Por favor intentalo de nuevo en unos segundos."
        showIcon />
    );
  }

  const handleOnDelete = async odeId => {
    const dismissLoader = message.loading("Guardando...", 0);
    const batchId = batchData?.batch?.id;
    try {
      await disconnectOdeFromBatch({
        variables: {
          batchId,
          data: { odes: { disconnect: { id: odeId } } }
        }
      });

      await CompanyService
        .registerCompanyDroppedOutFromBatchEvent({ odeId, batchId });

      message.success("Cambios guardados");
    } catch(error) {
      message.error("Ha ocurrido un error, inténtalo de nuevo\
        en unos segundos.");
    } finally {
      dismissLoader();
    }
  };

  const onFilter = (filter = {}) => {
    applyFilters(prevFilters => ({
      ...prevFilters,
      ...filter
    }));
  };

  const onExportBatchInfo = async () => {
    try {
      setStateExport(prevState => ({ ...prevState, exportLoading: true }));
      const urlExport =
        await reportService.exportBatchInfo({ batchId: batchData?.batch?.id });
      setStateExport(prevState => ({ ...prevState, exportUrl: urlExport }));
    } catch (error) {
      setStateExport({ exportLoading: false, exportUrl: null });
    } finally {
      setStateExport(prevState => ({ ...prevState, exportLoading: false }));
    }
  };

  return (
    <Section
      spaced
      title="Compañías"
      extra={<FilterControls onChange={onFilter} />}
      style={{ marginBottom: "11px", minHeight: "600px"}}>
      <Visibility
        visible={!["ENTREPRENEUR"]
          .includes(currentUser.systemRole)}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ModalButton
            type="primary"
            key="btn-details"
            icon={<DownloadOutlined />}
            title="Exportar"
            item={data?.id}
            onCustomClick={onExportBatchInfo}
            modalProps={
              stateExport
            }
            modal={DataExportModal} />
        </div>
        <AddOdeButton />
      </Visibility>
      <List
        renderItem={ode =>
          <OdeItem
            onClick={onClick}
            onDelete={handleOnDelete}
            ode={ode} /> }
        itemLayout="vertical"
        pagination={paginationConfig}
        dataSource={data?.oDEs}
        loading={loading} />
    </Section>
  );
}
