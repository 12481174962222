import React, { useContext } from "react";
import { Button } from "antd";
import { Section } from "../section";
import { RoleGuard } from "../role-guard";
import {
  CreateAnnouncementModalContext
} from "../../../contexts/shared/create-annoucement-modal";
import { default as AnnouncementsTable } from "../announcements-table";

export const AnnouncementsSection = ({ data, error, loading, refetch}) => {
  const { openModal } = useContext(CreateAnnouncementModalContext);

  return (
    <Section
      spaced
      title="Avisos">
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <RoleGuard roles={["SUPER_ADMIN", "COORDINATOR"]}>
          <Button
            type="primary"
            onClick={() => openModal()}>
            Nuevo aviso
          </Button>
        </RoleGuard>
      </div>
      <AnnouncementsTable
        refetch={refetch}
        data={data}
        error={error}
        isLoading={loading} />
    </Section>
  );
};
