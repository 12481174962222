import { client } from "../../../graphql";
import { createScheduledExpertService } from "./graphql";

/**
 * @todo Pending implementation
 * @returns
 */
const getById = () => {
  return null;
};

/**
 * @param {string} serviceDate
 * @param {string} duration
 * @param {string} requestedById
 * @param {string} assignedExpertServiceId
 * @returns {Promise<FetchResult<any>>}
 */
const scheduleExpertService = ({
  serviceDate,
  duration,
  requestedById,
  assignedExpertServiceId
}) => {
  const events = {
    create: {
      event: "STATUS_CHANGED",
      description: "Status changed to SCHEDULED",
      blame: {
        connect: {
          id: requestedById
        }
      }
    }
  };

  return client.mutate({
    mutation: createScheduledExpertService,
    variables: {
      events,
      duration,
      serviceDate,
      requestedById,
      assignedExpertServiceId
    }
  });
};

export const ExpertServiceService = {
  getById,
  scheduleExpertService
};
