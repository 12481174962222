import React from "react";
import { Typography } from "antd";

export const AchievementStatus = ({ status, style = {} }) => {

  return (
    <div style={style}>
      {
        status
          ? <Typography.Text>
            ¡Logrado!
          </Typography.Text>
          : <Typography.Text>
            Por lograr
          </Typography.Text>
      }
    </div>
  );
};
