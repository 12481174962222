import React from "react";
import { withLoader } from "../../../../../helpers";
import {
  GeneralInfoEditSection,
  AddressEditSection,
  SocialNetworksEditSection,
  FiscalInfoEditSection
} from "../../../../shared/ode-forms";

function GeneralInformation({ editable = false }) {
  return (
    <>
      <GeneralInfoEditSection editable={editable} />
      <AddressEditSection editable={editable} />
      <SocialNetworksEditSection editable={editable} />
      <FiscalInfoEditSection editable={editable} />
    </>
  );
}

export default withLoader(GeneralInformation);
