import React from "react";
import { Steps } from "antd";

export function FormSteps({ current = 0 }) {
  return (
    <Steps
      type="navigation"
      size="small"
      current={current}>
      <Steps.Step
        title="General" />
      <Steps.Step
        title="Fondos" />
      <Steps.Step
        title="Concursos" />
      <Steps.Step
        title="Empleados" />
      <Steps.Step
        title="Logros" />
    </Steps>
  );
}
