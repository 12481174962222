import React, { useContext, useState, useEffect } from "react";
import { Form, Input, Descriptions, Select, DatePicker } from "antd";
import moment from "moment";
import { EditProfileContext } from "../../../../../contexts/shared/edit-profile";
import { FormContainer } from "../../../";
import { fieldValidationMessages } from "../../../../../utils";
import { genders } from "../../../../../helpers/select-options/shared";
import { Format } from "../../../format";
import { getReadableValue } from "../../../../../helpers/select-options";
import { CurrentUserContext } from "../../../../../contexts/shared";

export function ContactForm({ editable = false, form }) {
  const { currentUser } = useContext(CurrentUserContext);
  const { data } = useContext(EditProfileContext);

  const [state, setState] = useState({});

  useEffect(() => {
    const contactFields = {
      firstName: data?.user?.firstName,
      middleName: data?.user?.middleName,
      lastName: data?.user?.lastName,
      birthday: data?.user?.birthday ? moment(data?.user?.birthday) : null,
      email: data?.user?.email,
      phone: data?.user?.phone,
      gender: data?.user?.gender
    };

    setState(contactFields);

    form.setFieldsValue({
      ...contactFields
    });
  }, [data]);

  if (editable) {
    return (
      <FormContainer>
        <Form
          validateMessages={fieldValidationMessages}
          layout="vertical"
          form={form}>
          <Form.Item
            rules={[{ required: true }]}
            name="phone"
            label="Teléfono">
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            label="Género"
            name="gender">
            <Select
              style={{ width: "217px" }}
              options={genders} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            label="Fecha de nacimiento"
            name="birthday">
            <DatePicker
              style={{ width: "217px" }} />
          </Form.Item>
        </Form>
      </FormContainer>
    );
  }

  return (
    <FormContainer>
      <Descriptions column={1}>
        <Descriptions.Item label="Género">
          {getReadableValue(genders, state.gender)}
        </Descriptions.Item>
        <Descriptions.Item label="Fecha de nacimiento">
          <Format type="date">
            {state.birthday}
          </Format>
        </Descriptions.Item>
      </Descriptions>
    </FormContainer>
  );
}
