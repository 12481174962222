/**
 * It returns true if the email address is valid, and false if it's not
 * @param {string} email - The email address to validate.
 * @returns A boolean value.
 */
export const validateEmail = email => {
  return Boolean(email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ));
};
