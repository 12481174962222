import gql from "graphql-tag";
export const getOdesByUserId = gql`
  query GetOdesByUserId(
    $id: ID
    $searchString: String
    $batches_some: BatchWhereInput
  ){
    oDEs(
      where: {
        iname_contains: $searchString
        batches_some: $batches_some
        OR:[
          { team_some: { id: $id } }
          {
            batches_some: {
              users_some: {
                id: $id
              }
            }
          }
        ],
      }
    ){
      id
      iname
      name
      logoUrl
      sector
      team {
        fullName
        profilePicUrl
      }
      network {
        email
      }
      batches {
        program {
          type
        }
      }
    }
    
    meta: oDEsConnection(
      where: {
        iname_contains: $searchString
        batches_some: $batches_some
        OR:[
          { team_some: { id: $id } }
          { network_some: {id: $id } }
          {
            batches_some: {
              users_some: {
                id: $id
              }
            }
          }
        ],
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
