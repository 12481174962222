import React from "react";
import { Tag, Space, Tooltip } from "antd";
import Text from "antd/es/typography/Text";
import {
  HiddenTagsPopover
} from "../../../../../shared/tag-list/hidden-tags-popover";


export function OrganizationTableServiceLabels({
    services = []
  }) {

  const labelsList = services.slice(0, 2);

  return (
    <Space>
      {labelsList.map(service => (
        <Text
          key={service}>
          {service.length > 20
            ?
              <Tooltip title={service}>
                {service.substring(0,20)}...
              </Tooltip>
            : service
          }
        </Text>
      ))}
      {services.length > 2 && (
        <HiddenTagsPopover
          tags={services?.slice(2)}>
          <Tag
            style={{ padding: "1px 8px" }}>
            + {services.length - 2}
          </Tag>
        </HiddenTagsPopover>
      )}
    </Space>
  );
}
