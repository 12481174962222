import React, { useContext } from "react";
import { Skeleton } from "antd";
import { Footer } from "./footer";
import { DetailContent } from "./detail-content";
import { ScheduledMentoringHeader } from "../components/headers";
import { useService } from "../../../../../hooks/shared";
import { BaseServiceDetailModal } from "../../../../shared/modals";
import { ServicesLayerContext } from "../../../../../contexts/shared";

export function EntrepreneurScheduledMentoringDetailModal({
  // Could be injected by the modal provider
  selectedItem, // selected scheduled service
  ...props
}) {
  const { ScheduledExpertServiceService } = useContext(ServicesLayerContext);

  const {
    loading,
    data: scheduledExpertService
  } = useService(
    ScheduledExpertServiceService.getById,
    { id: selectedItem?.id },
    { shouldFetch: !!selectedItem?.id && props.visible }
  );

  const cancelScheduledServiceRequest = () => {
    alert("Se cancela");
  };

  return (
    <BaseServiceDetailModal
      header={<ScheduledMentoringHeader
        scheduledExpertService={scheduledExpertService} />}
      footer={<Footer
        onCancelScheduledService={cancelScheduledServiceRequest} />}
      {...props}>
      {loading ?
        <Skeleton paragraph={{ rows: 3 }} />
        : <DetailContent scheduledExpertService={scheduledExpertService} /> }
    </BaseServiceDetailModal>
  );
}
