import gql from "graphql-tag";

export const inviteEntrepreneurs = gql`
  mutation InviteEntrepreneurs(
    $data: InviteEntrepreneursInput
  ) {
     inviteEntrepreneurs( data: $data ){
       count
     }
  }
`;