import { merge } from "lodash";

import {
  formatValues as workExperienceFormatValues
} from "../../../../../components/shared/profile-forms/work-experience/section/format-values";
import {
  formatValues as educationFormatValues
} from "../../../../../components/shared/profile-forms/education/section/format-values";
import {
  formatValues as coursesFormatValues
} from "../../../../../components/shared/profile-forms/courses/section/format-values";
import {
  formatValues as papersFormatValues
} from "../../../../../components/shared/profile-forms/papers/section/format-values";
import {
  formatValues as languagesFormatValues
} from "../../../../../components/shared/profile-forms/languages/section/formatValues";
import {
  formatValues as tecFormatValues
} from "../../../../../components/shared/profile-forms/tec-relationship/section/format-values";

export const formatValues = formsValues => {
  const values = {
    workExperienceForm: workExperienceFormatValues(formsValues.workExperience),
    education: educationFormatValues(formsValues.education.education),
    courses: coursesFormatValues(formsValues.courses.courses),
    papers: papersFormatValues(formsValues.papers.papers),
    languages: languagesFormatValues(formsValues.languages.languages),
    tecRelationship: tecFormatValues(formsValues.tecRelationship)
  };

  const reducedValues = Object.values(values)
    .reduce((prev, curr) => merge(curr, prev), {});

  return reducedValues;
};
