import { Card } from "antd";
import { Bar } from "react-chartjs-2";
import React, { useMemo } from "react";
import { ChartDataFeedback } from "../../chart-data-feedback";
import {
  ENTREPRENEURS_OCCUPATION_OPTIONS
} from "./entrepreneurs-occupation-options";
import {
  useEntrepreneursByDimensions
} from "../use-entrepreneurs-by-dimensions";
import {
  entrepreneursOccupationToChart
} from "./entrepreneurs-occupation-to-chart";

export const OccupationChart = ({ filters = {} }) => {
  const { loading, results } = useEntrepreneursByDimensions({
    dimensions: ["teamMemberOccupation"],
    mustBeSet: ["teamMemberOccupation"],
    ...filters
  });

  const chartData = useMemo(() => {
    if (!results) return ({ labels: [], datasets: [] });

    const [result] = results;
    return entrepreneursOccupationToChart(result.data);
  }, [results]);

  return (
    <Card
      spaced
      bordered
      style={sectionStyle}
      bodyStyle={{ height: "max-content" }}
      title="Emprendedores por ocupación">
      <ChartDataFeedback
        loading={loading}
        isEmpty={!chartData?.datasets?.length}>
        <Bar
          height={500}
          data={chartData}
          type={"bar"}
          options={ENTREPRENEURS_OCCUPATION_OPTIONS} />
      </ChartDataFeedback>
    </Card>
  );
};

const sectionStyle = {
  marginTop: 16,
  boxShadow: "none",
  borderRadius: "0",
  padding: "1rem"
};
