import React, { useContext } from "react";
import { Radio } from "antd";
import { CurrentUserContext } from "../../../contexts/shared";

export const ContentStatusRadio = ({ onChange, value, loading }) => {
  const { currentUser } = useContext(CurrentUserContext);

  const getVerifiedDisability = () => {
    if(currentUser.systemRole === "ENTREPRENEUR"){
      if(value === "VERIFIED") {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  let verifiedDisabled = getVerifiedDisability();
  
  return (
    <Radio.Group
      buttonStyle="solid"
      onChange={onChange}
      value={value}
      disable={loading}
      defaultValue="PENDING">
      <div className="content-status-radio">
        <Radio.Button
          value="PENDING"
          style={{
            backgroundColor: value === "PENDING" ? "#FF7875" : "white"}}>
          Pendiente</Radio.Button>
        <Radio.Button
          value="COMPLETED"
          style={{
            backgroundColor: value === "COMPLETED" ? "#5F39DE" : "white"}}>
          Completado</Radio.Button>
        <Radio.Button
          value="VERIFIED"
          disabled={verifiedDisabled}
          style={{
            backgroundColor: value === "VERIFIED" ? "#52C41A" : "white"}}>
          Verificado</Radio.Button>
      </div>
    </Radio.Group>
  )
}
